// This file is autogenerated by @graphql-codegen/cli
// Do not edit / add anything manually, since it will be overridden by codegen

export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string, output: string }
    String: { input: string, output: string }
    Boolean: { input: boolean, output: boolean }
    Int: { input: number, output: number }
    Float: { input: number, output: number }
    /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
    JSON: { input: any, output: any }
    /** The `Upload` scalar type represents a file upload. */
    Upload: { input: any, output: any }
}

export type AcceptOrRejectOrganizationInviteInput = {
    dv: Scalars['Int']['input']
    isAccepted?: InputMaybe<Scalars['Boolean']['input']>
    isRejected?: InputMaybe<Scalars['Boolean']['input']>
    sender: SenderFieldInput
}

/**  Information about `acquiring component` which will generate `billing receipts` and `payments`  */
export type AcquiringIntegration = {
    __typename?: 'AcquiringIntegration'
    _accessRightsMeta?: Maybe<_QueryMeta>
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the AcquiringIntegration List config, or
   *  2. As an alias to the field set on 'labelField' in the AcquiringIntegration List config, or
   *  3. As an alias to a 'name' field on the AcquiringIntegration List (if one exists), or
   *  4. As an alias to the 'id' field on the AcquiringIntegration List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    accessRights: Array<AcquiringIntegrationAccessRight>
    /**  Can multiple receipts be united through this acquiring  */
    canGroupReceipts?: Maybe<Scalars['Boolean']['output']>
    /**  Status, which context will have by default after creation if no overwriting option provided  */
    contextDefaultStatus?: Maybe<AcquiringIntegrationContextDefaultStatusType>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  Contains information about the default distribution of explicit fee. Each part is paid by the user on top of original amount if there is no part with the same name in the integration context. Otherwise, the part is ignored as it is paid by recipient  */
    explicitFeeDistributionSchema?: Maybe<Array<FeeDistributionField>>
    /**  Url to acquiring integration service. Mobile devices will use it communicate with external acquiring. List of endpoints is the same for all of them.  */
    hostUrl?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    /**  Indicates whether the integration or app is hidden inside the CRM. Used if integration is active by default or not ready to appear inside CRM  */
    isHidden?: Maybe<Scalars['Boolean']['output']>
    /**  The maximum payment amount that can be accepted  */
    maximumPaymentAmount?: Maybe<Scalars['String']['output']>
    /**  The minimum payment amount that can be accepted  */
    minimumPaymentAmount?: Maybe<Scalars['String']['output']>
    /**  Name of `acquiring component`, which is set up by developer  */
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Url to setup page of acquiring integration  */
    setupUrl?: Maybe<Scalars['String']['output']>
    /**  Supported billing integrations group. Useful when you need to restrict this acquiring to accept payment only from certain billing.  */
    supportedBillingIntegrationsGroup?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
    /**  Comma separated values of VAT. Set by system administrators.  */
    vatPercentOptions?: Maybe<Scalars['String']['output']>
}


/**  Information about `acquiring component` which will generate `billing receipts` and `payments`  */
export type AcquiringIntegration_AccessRightsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortAcquiringIntegrationAccessRightsBy>>
    where?: InputMaybe<AcquiringIntegrationAccessRightWhereInput>
}


/**  Information about `acquiring component` which will generate `billing receipts` and `payments`  */
export type AcquiringIntegrationAccessRightsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortAcquiringIntegrationAccessRightsBy>>
    where?: InputMaybe<AcquiringIntegrationAccessRightWhereInput>
}

/**  Link between Acquiring integration and user, the existence of this connection means that this user has the rights to perform actions on behalf of the integration  */
export type AcquiringIntegrationAccessRight = {
    __typename?: 'AcquiringIntegrationAccessRight'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the AcquiringIntegrationAccessRight List config, or
   *  2. As an alias to the field set on 'labelField' in the AcquiringIntegrationAccessRight List config, or
   *  3. As an alias to a 'name' field on the AcquiringIntegrationAccessRight List (if one exists), or
   *  4. As an alias to the 'id' field on the AcquiringIntegrationAccessRight List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  Acquiring integration. Determines way of user's payment  */
    integration?: Maybe<AcquiringIntegration>
    newId?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    /**  Link to user. Note, that user must be of "service" type  */
    user?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type AcquiringIntegrationAccessRightCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    integration?: InputMaybe<AcquiringIntegrationRelateToOneInput>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type AcquiringIntegrationAccessRightHistoryRecord = {
    __typename?: 'AcquiringIntegrationAccessRightHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the AcquiringIntegrationAccessRightHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the AcquiringIntegrationAccessRightHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the AcquiringIntegrationAccessRightHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the AcquiringIntegrationAccessRightHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<AcquiringIntegrationAccessRightHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    integration?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    user?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type AcquiringIntegrationAccessRightHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<AcquiringIntegrationAccessRightHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    integration?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum AcquiringIntegrationAccessRightHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type AcquiringIntegrationAccessRightHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<AcquiringIntegrationAccessRightHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    integration?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type AcquiringIntegrationAccessRightHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<AcquiringIntegrationAccessRightHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<AcquiringIntegrationAccessRightHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<AcquiringIntegrationAccessRightHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<AcquiringIntegrationAccessRightHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<AcquiringIntegrationAccessRightHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<AcquiringIntegrationAccessRightHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    integration?: InputMaybe<Scalars['String']['input']>
    integration_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    integration_not?: InputMaybe<Scalars['String']['input']>
    integration_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user?: InputMaybe<Scalars['String']['input']>
    user_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user_not?: InputMaybe<Scalars['String']['input']>
    user_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type AcquiringIntegrationAccessRightHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type AcquiringIntegrationAccessRightHistoryRecordsCreateInput = {
    data?: InputMaybe<AcquiringIntegrationAccessRightHistoryRecordCreateInput>
}

export type AcquiringIntegrationAccessRightHistoryRecordsUpdateInput = {
    data?: InputMaybe<AcquiringIntegrationAccessRightHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type AcquiringIntegrationAccessRightRelateToManyInput = {
    connect?: InputMaybe<Array<InputMaybe<AcquiringIntegrationAccessRightWhereUniqueInput>>>
    create?: InputMaybe<Array<InputMaybe<AcquiringIntegrationAccessRightCreateInput>>>
    disconnect?: InputMaybe<Array<InputMaybe<AcquiringIntegrationAccessRightWhereUniqueInput>>>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type AcquiringIntegrationAccessRightUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    integration?: InputMaybe<AcquiringIntegrationRelateToOneInput>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type AcquiringIntegrationAccessRightWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<AcquiringIntegrationAccessRightWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<AcquiringIntegrationAccessRightWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    integration?: InputMaybe<AcquiringIntegrationWhereInput>
    integration_is_null?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    user?: InputMaybe<UserWhereInput>
    user_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type AcquiringIntegrationAccessRightWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type AcquiringIntegrationAccessRightsCreateInput = {
    data?: InputMaybe<AcquiringIntegrationAccessRightCreateInput>
}

export type AcquiringIntegrationAccessRightsUpdateInput = {
    data?: InputMaybe<AcquiringIntegrationAccessRightUpdateInput>
    id: Scalars['ID']['input']
}

/**  Object, which links `acquiring integration` with `service provider`, and stores additional data for it's proper work  */
export type AcquiringIntegrationContext = {
    __typename?: 'AcquiringIntegrationContext'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the AcquiringIntegrationContext List config, or
   *  2. As an alias to the field set on 'labelField' in the AcquiringIntegrationContext List config, or
   *  3. As an alias to a 'name' field on the AcquiringIntegrationContext List (if one exists), or
   *  4. As an alias to the 'id' field on the AcquiringIntegrationContext List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  Acquiring payments report will be sent to this email  */
    email?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    /**  Contains information about the default distribution of implicit fee. Each part is paid by the recipient organization on deducted from payment amount. If part exists then explicit part with the same name from AcquiringIntegration.explicitFeeDistributionSchema is ignored  */
    implicitFeeDistributionSchema?: Maybe<Array<FeeDistributionField>>
    /**  Acquiring integration. Determines way of user's payment  */
    integration?: Maybe<AcquiringIntegration>
    /**  Email addresses separated by `,` or `;` Acquiring payments report will be sent to this emails  */
    invoiceEmails?: Maybe<Scalars['String']['output']>
    /**  Contains information about the default distribution of implicit fee. Each part is paid by the recipient organization on deducted from payment amount. If part exists then explicit part with the same name from AcquiringIntegration.explicitFeeDistributionSchema is ignored  */
    invoiceImplicitFeeDistributionSchema?: Maybe<Array<FeeDistributionField>>
    /**  Contract number and date. Basis for invoicing  */
    invoiceReason?: Maybe<Scalars['String']['output']>
    /**  Requisites from agreement. Are used for invoicing of invoices.  */
    invoiceRecipient?: Maybe<RecipientField>
    /**  The percent value  */
    invoiceSalesTaxPercent?: Maybe<Scalars['String']['output']>
    /**  Status of AcquiringIntegration connection, Can be one of the following: ["InProgress", "Error", "Finished"]. If not specified explicitly on creation, uses default value from related AcquiringIntegration model  */
    invoiceStatus?: Maybe<Scalars['String']['output']>
    /**  The regime of counting taxes for company  */
    invoiceTaxRegime?: Maybe<AcquiringIntegrationContextInvoiceTaxRegimeType>
    /**  The percentage of VAT. Depends of integrations settings (see AcquiringIntegration.vatPercentOptions)  */
    invoiceVatPercent?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Service provider (organization)  */
    organization?: Maybe<Organization>
    /**  Contract number and date. Basis for invoicing  */
    reason?: Maybe<Scalars['String']['output']>
    /**  Requisites from agreement. Are used for invoicing in case of not confirmed billing recipient in receipt  */
    recipient?: Maybe<RecipientField>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Settings that are required for acquiring to work properly. The data structure depends on the integration and defined here  */
    settings?: Maybe<Scalars['JSON']['output']>
    /**  The current state of the integration process. Some integration need to store past state here, additional data and etc.  */
    state?: Maybe<Scalars['JSON']['output']>
    /**  Status of AcquiringIntegration connection, Can be one of the following: ["InProgress", "Error", "Finished"]. If not specified explicitly on creation, uses default value from related AcquiringIntegration model  */
    status?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type AcquiringIntegrationContextCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    email?: InputMaybe<Scalars['String']['input']>
    implicitFeeDistributionSchema?: InputMaybe<Array<FeeDistributionFieldInput>>
    integration?: InputMaybe<AcquiringIntegrationRelateToOneInput>
    invoiceEmails?: InputMaybe<Scalars['String']['input']>
    invoiceImplicitFeeDistributionSchema?: InputMaybe<Array<FeeDistributionFieldInput>>
    invoiceReason?: InputMaybe<Scalars['String']['input']>
    invoiceRecipient?: InputMaybe<RecipientFieldInput>
    invoiceSalesTaxPercent?: InputMaybe<Scalars['String']['input']>
    invoiceStatus?: InputMaybe<Scalars['String']['input']>
    invoiceTaxRegime?: InputMaybe<AcquiringIntegrationContextInvoiceTaxRegimeType>
    invoiceVatPercent?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    reason?: InputMaybe<Scalars['String']['input']>
    recipient?: InputMaybe<RecipientFieldInput>
    sender?: InputMaybe<SenderFieldInput>
    settings?: InputMaybe<Scalars['JSON']['input']>
    state?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum AcquiringIntegrationContextDefaultStatusType {
    Error = 'Error',
    Finished = 'Finished',
    InProgress = 'InProgress',
    Verification = 'Verification',
}

/**  A keystone list  */
export type AcquiringIntegrationContextHistoryRecord = {
    __typename?: 'AcquiringIntegrationContextHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the AcquiringIntegrationContextHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the AcquiringIntegrationContextHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the AcquiringIntegrationContextHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the AcquiringIntegrationContextHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    email?: Maybe<Scalars['String']['output']>
    history_action?: Maybe<AcquiringIntegrationContextHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    implicitFeeDistributionSchema?: Maybe<Scalars['JSON']['output']>
    integration?: Maybe<Scalars['String']['output']>
    invoiceEmails?: Maybe<Scalars['String']['output']>
    invoiceImplicitFeeDistributionSchema?: Maybe<Scalars['JSON']['output']>
    invoiceReason?: Maybe<Scalars['String']['output']>
    invoiceRecipient?: Maybe<Scalars['JSON']['output']>
    invoiceSalesTaxPercent?: Maybe<Scalars['String']['output']>
    invoiceStatus?: Maybe<Scalars['String']['output']>
    invoiceTaxRegime?: Maybe<Scalars['String']['output']>
    invoiceVatPercent?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    reason?: Maybe<Scalars['String']['output']>
    recipient?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    settings?: Maybe<Scalars['JSON']['output']>
    state?: Maybe<Scalars['JSON']['output']>
    status?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type AcquiringIntegrationContextHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    email?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<AcquiringIntegrationContextHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    implicitFeeDistributionSchema?: InputMaybe<Scalars['JSON']['input']>
    integration?: InputMaybe<Scalars['String']['input']>
    invoiceEmails?: InputMaybe<Scalars['String']['input']>
    invoiceImplicitFeeDistributionSchema?: InputMaybe<Scalars['JSON']['input']>
    invoiceReason?: InputMaybe<Scalars['String']['input']>
    invoiceRecipient?: InputMaybe<Scalars['JSON']['input']>
    invoiceSalesTaxPercent?: InputMaybe<Scalars['String']['input']>
    invoiceStatus?: InputMaybe<Scalars['String']['input']>
    invoiceTaxRegime?: InputMaybe<Scalars['String']['input']>
    invoiceVatPercent?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    reason?: InputMaybe<Scalars['String']['input']>
    recipient?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    settings?: InputMaybe<Scalars['JSON']['input']>
    state?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum AcquiringIntegrationContextHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type AcquiringIntegrationContextHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    email?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<AcquiringIntegrationContextHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    implicitFeeDistributionSchema?: InputMaybe<Scalars['JSON']['input']>
    integration?: InputMaybe<Scalars['String']['input']>
    invoiceEmails?: InputMaybe<Scalars['String']['input']>
    invoiceImplicitFeeDistributionSchema?: InputMaybe<Scalars['JSON']['input']>
    invoiceReason?: InputMaybe<Scalars['String']['input']>
    invoiceRecipient?: InputMaybe<Scalars['JSON']['input']>
    invoiceSalesTaxPercent?: InputMaybe<Scalars['String']['input']>
    invoiceStatus?: InputMaybe<Scalars['String']['input']>
    invoiceTaxRegime?: InputMaybe<Scalars['String']['input']>
    invoiceVatPercent?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    reason?: InputMaybe<Scalars['String']['input']>
    recipient?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    settings?: InputMaybe<Scalars['JSON']['input']>
    state?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type AcquiringIntegrationContextHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<AcquiringIntegrationContextHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<AcquiringIntegrationContextHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    email?: InputMaybe<Scalars['String']['input']>
    email_contains?: InputMaybe<Scalars['String']['input']>
    email_contains_i?: InputMaybe<Scalars['String']['input']>
    email_ends_with?: InputMaybe<Scalars['String']['input']>
    email_ends_with_i?: InputMaybe<Scalars['String']['input']>
    email_i?: InputMaybe<Scalars['String']['input']>
    email_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    email_not?: InputMaybe<Scalars['String']['input']>
    email_not_contains?: InputMaybe<Scalars['String']['input']>
    email_not_contains_i?: InputMaybe<Scalars['String']['input']>
    email_not_ends_with?: InputMaybe<Scalars['String']['input']>
    email_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    email_not_i?: InputMaybe<Scalars['String']['input']>
    email_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    email_not_starts_with?: InputMaybe<Scalars['String']['input']>
    email_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    email_starts_with?: InputMaybe<Scalars['String']['input']>
    email_starts_with_i?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<AcquiringIntegrationContextHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<AcquiringIntegrationContextHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<AcquiringIntegrationContextHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<AcquiringIntegrationContextHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    implicitFeeDistributionSchema?: InputMaybe<Scalars['JSON']['input']>
    implicitFeeDistributionSchema_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    implicitFeeDistributionSchema_not?: InputMaybe<Scalars['JSON']['input']>
    implicitFeeDistributionSchema_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    integration?: InputMaybe<Scalars['String']['input']>
    integration_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    integration_not?: InputMaybe<Scalars['String']['input']>
    integration_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    invoiceEmails?: InputMaybe<Scalars['String']['input']>
    invoiceEmails_contains?: InputMaybe<Scalars['String']['input']>
    invoiceEmails_contains_i?: InputMaybe<Scalars['String']['input']>
    invoiceEmails_ends_with?: InputMaybe<Scalars['String']['input']>
    invoiceEmails_ends_with_i?: InputMaybe<Scalars['String']['input']>
    invoiceEmails_i?: InputMaybe<Scalars['String']['input']>
    invoiceEmails_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    invoiceEmails_not?: InputMaybe<Scalars['String']['input']>
    invoiceEmails_not_contains?: InputMaybe<Scalars['String']['input']>
    invoiceEmails_not_contains_i?: InputMaybe<Scalars['String']['input']>
    invoiceEmails_not_ends_with?: InputMaybe<Scalars['String']['input']>
    invoiceEmails_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    invoiceEmails_not_i?: InputMaybe<Scalars['String']['input']>
    invoiceEmails_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    invoiceEmails_not_starts_with?: InputMaybe<Scalars['String']['input']>
    invoiceEmails_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    invoiceEmails_starts_with?: InputMaybe<Scalars['String']['input']>
    invoiceEmails_starts_with_i?: InputMaybe<Scalars['String']['input']>
    invoiceImplicitFeeDistributionSchema?: InputMaybe<Scalars['JSON']['input']>
    invoiceImplicitFeeDistributionSchema_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    invoiceImplicitFeeDistributionSchema_not?: InputMaybe<Scalars['JSON']['input']>
    invoiceImplicitFeeDistributionSchema_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    invoiceReason?: InputMaybe<Scalars['String']['input']>
    invoiceReason_contains?: InputMaybe<Scalars['String']['input']>
    invoiceReason_contains_i?: InputMaybe<Scalars['String']['input']>
    invoiceReason_ends_with?: InputMaybe<Scalars['String']['input']>
    invoiceReason_ends_with_i?: InputMaybe<Scalars['String']['input']>
    invoiceReason_i?: InputMaybe<Scalars['String']['input']>
    invoiceReason_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    invoiceReason_not?: InputMaybe<Scalars['String']['input']>
    invoiceReason_not_contains?: InputMaybe<Scalars['String']['input']>
    invoiceReason_not_contains_i?: InputMaybe<Scalars['String']['input']>
    invoiceReason_not_ends_with?: InputMaybe<Scalars['String']['input']>
    invoiceReason_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    invoiceReason_not_i?: InputMaybe<Scalars['String']['input']>
    invoiceReason_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    invoiceReason_not_starts_with?: InputMaybe<Scalars['String']['input']>
    invoiceReason_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    invoiceReason_starts_with?: InputMaybe<Scalars['String']['input']>
    invoiceReason_starts_with_i?: InputMaybe<Scalars['String']['input']>
    invoiceRecipient?: InputMaybe<Scalars['JSON']['input']>
    invoiceRecipient_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    invoiceRecipient_not?: InputMaybe<Scalars['JSON']['input']>
    invoiceRecipient_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    invoiceSalesTaxPercent?: InputMaybe<Scalars['String']['input']>
    invoiceSalesTaxPercent_gt?: InputMaybe<Scalars['String']['input']>
    invoiceSalesTaxPercent_gte?: InputMaybe<Scalars['String']['input']>
    invoiceSalesTaxPercent_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    invoiceSalesTaxPercent_lt?: InputMaybe<Scalars['String']['input']>
    invoiceSalesTaxPercent_lte?: InputMaybe<Scalars['String']['input']>
    invoiceSalesTaxPercent_not?: InputMaybe<Scalars['String']['input']>
    invoiceSalesTaxPercent_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    invoiceStatus?: InputMaybe<Scalars['String']['input']>
    invoiceStatus_contains?: InputMaybe<Scalars['String']['input']>
    invoiceStatus_contains_i?: InputMaybe<Scalars['String']['input']>
    invoiceStatus_ends_with?: InputMaybe<Scalars['String']['input']>
    invoiceStatus_ends_with_i?: InputMaybe<Scalars['String']['input']>
    invoiceStatus_i?: InputMaybe<Scalars['String']['input']>
    invoiceStatus_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    invoiceStatus_not?: InputMaybe<Scalars['String']['input']>
    invoiceStatus_not_contains?: InputMaybe<Scalars['String']['input']>
    invoiceStatus_not_contains_i?: InputMaybe<Scalars['String']['input']>
    invoiceStatus_not_ends_with?: InputMaybe<Scalars['String']['input']>
    invoiceStatus_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    invoiceStatus_not_i?: InputMaybe<Scalars['String']['input']>
    invoiceStatus_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    invoiceStatus_not_starts_with?: InputMaybe<Scalars['String']['input']>
    invoiceStatus_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    invoiceStatus_starts_with?: InputMaybe<Scalars['String']['input']>
    invoiceStatus_starts_with_i?: InputMaybe<Scalars['String']['input']>
    invoiceTaxRegime?: InputMaybe<Scalars['String']['input']>
    invoiceTaxRegime_contains?: InputMaybe<Scalars['String']['input']>
    invoiceTaxRegime_contains_i?: InputMaybe<Scalars['String']['input']>
    invoiceTaxRegime_ends_with?: InputMaybe<Scalars['String']['input']>
    invoiceTaxRegime_ends_with_i?: InputMaybe<Scalars['String']['input']>
    invoiceTaxRegime_i?: InputMaybe<Scalars['String']['input']>
    invoiceTaxRegime_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    invoiceTaxRegime_not?: InputMaybe<Scalars['String']['input']>
    invoiceTaxRegime_not_contains?: InputMaybe<Scalars['String']['input']>
    invoiceTaxRegime_not_contains_i?: InputMaybe<Scalars['String']['input']>
    invoiceTaxRegime_not_ends_with?: InputMaybe<Scalars['String']['input']>
    invoiceTaxRegime_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    invoiceTaxRegime_not_i?: InputMaybe<Scalars['String']['input']>
    invoiceTaxRegime_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    invoiceTaxRegime_not_starts_with?: InputMaybe<Scalars['String']['input']>
    invoiceTaxRegime_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    invoiceTaxRegime_starts_with?: InputMaybe<Scalars['String']['input']>
    invoiceTaxRegime_starts_with_i?: InputMaybe<Scalars['String']['input']>
    invoiceVatPercent?: InputMaybe<Scalars['String']['input']>
    invoiceVatPercent_gt?: InputMaybe<Scalars['String']['input']>
    invoiceVatPercent_gte?: InputMaybe<Scalars['String']['input']>
    invoiceVatPercent_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    invoiceVatPercent_lt?: InputMaybe<Scalars['String']['input']>
    invoiceVatPercent_lte?: InputMaybe<Scalars['String']['input']>
    invoiceVatPercent_not?: InputMaybe<Scalars['String']['input']>
    invoiceVatPercent_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    reason?: InputMaybe<Scalars['String']['input']>
    reason_contains?: InputMaybe<Scalars['String']['input']>
    reason_contains_i?: InputMaybe<Scalars['String']['input']>
    reason_ends_with?: InputMaybe<Scalars['String']['input']>
    reason_ends_with_i?: InputMaybe<Scalars['String']['input']>
    reason_i?: InputMaybe<Scalars['String']['input']>
    reason_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    reason_not?: InputMaybe<Scalars['String']['input']>
    reason_not_contains?: InputMaybe<Scalars['String']['input']>
    reason_not_contains_i?: InputMaybe<Scalars['String']['input']>
    reason_not_ends_with?: InputMaybe<Scalars['String']['input']>
    reason_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    reason_not_i?: InputMaybe<Scalars['String']['input']>
    reason_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    reason_not_starts_with?: InputMaybe<Scalars['String']['input']>
    reason_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    reason_starts_with?: InputMaybe<Scalars['String']['input']>
    reason_starts_with_i?: InputMaybe<Scalars['String']['input']>
    recipient?: InputMaybe<Scalars['JSON']['input']>
    recipient_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    recipient_not?: InputMaybe<Scalars['JSON']['input']>
    recipient_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    settings?: InputMaybe<Scalars['JSON']['input']>
    settings_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    settings_not?: InputMaybe<Scalars['JSON']['input']>
    settings_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    state?: InputMaybe<Scalars['JSON']['input']>
    state_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    state_not?: InputMaybe<Scalars['JSON']['input']>
    state_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    status?: InputMaybe<Scalars['String']['input']>
    status_contains?: InputMaybe<Scalars['String']['input']>
    status_contains_i?: InputMaybe<Scalars['String']['input']>
    status_ends_with?: InputMaybe<Scalars['String']['input']>
    status_ends_with_i?: InputMaybe<Scalars['String']['input']>
    status_i?: InputMaybe<Scalars['String']['input']>
    status_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status_not?: InputMaybe<Scalars['String']['input']>
    status_not_contains?: InputMaybe<Scalars['String']['input']>
    status_not_contains_i?: InputMaybe<Scalars['String']['input']>
    status_not_ends_with?: InputMaybe<Scalars['String']['input']>
    status_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    status_not_i?: InputMaybe<Scalars['String']['input']>
    status_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status_not_starts_with?: InputMaybe<Scalars['String']['input']>
    status_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    status_starts_with?: InputMaybe<Scalars['String']['input']>
    status_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type AcquiringIntegrationContextHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type AcquiringIntegrationContextHistoryRecordsCreateInput = {
    data?: InputMaybe<AcquiringIntegrationContextHistoryRecordCreateInput>
}

export type AcquiringIntegrationContextHistoryRecordsUpdateInput = {
    data?: InputMaybe<AcquiringIntegrationContextHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export enum AcquiringIntegrationContextInvoiceTaxRegimeType {
    General = 'general',
    Simple = 'simple',
}

export type AcquiringIntegrationContextRelateToOneInput = {
    connect?: InputMaybe<AcquiringIntegrationContextWhereUniqueInput>
    create?: InputMaybe<AcquiringIntegrationContextCreateInput>
    disconnect?: InputMaybe<AcquiringIntegrationContextWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type AcquiringIntegrationContextUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    email?: InputMaybe<Scalars['String']['input']>
    implicitFeeDistributionSchema?: InputMaybe<Array<FeeDistributionFieldInput>>
    integration?: InputMaybe<AcquiringIntegrationRelateToOneInput>
    invoiceEmails?: InputMaybe<Scalars['String']['input']>
    invoiceImplicitFeeDistributionSchema?: InputMaybe<Array<FeeDistributionFieldInput>>
    invoiceReason?: InputMaybe<Scalars['String']['input']>
    invoiceRecipient?: InputMaybe<RecipientFieldInput>
    invoiceSalesTaxPercent?: InputMaybe<Scalars['String']['input']>
    invoiceStatus?: InputMaybe<Scalars['String']['input']>
    invoiceTaxRegime?: InputMaybe<AcquiringIntegrationContextInvoiceTaxRegimeType>
    invoiceVatPercent?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    reason?: InputMaybe<Scalars['String']['input']>
    recipient?: InputMaybe<RecipientFieldInput>
    sender?: InputMaybe<SenderFieldInput>
    settings?: InputMaybe<Scalars['JSON']['input']>
    state?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type AcquiringIntegrationContextWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<AcquiringIntegrationContextWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<AcquiringIntegrationContextWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    email?: InputMaybe<Scalars['String']['input']>
    email_contains?: InputMaybe<Scalars['String']['input']>
    email_contains_i?: InputMaybe<Scalars['String']['input']>
    email_ends_with?: InputMaybe<Scalars['String']['input']>
    email_ends_with_i?: InputMaybe<Scalars['String']['input']>
    email_i?: InputMaybe<Scalars['String']['input']>
    email_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    email_not?: InputMaybe<Scalars['String']['input']>
    email_not_contains?: InputMaybe<Scalars['String']['input']>
    email_not_contains_i?: InputMaybe<Scalars['String']['input']>
    email_not_ends_with?: InputMaybe<Scalars['String']['input']>
    email_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    email_not_i?: InputMaybe<Scalars['String']['input']>
    email_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    email_not_starts_with?: InputMaybe<Scalars['String']['input']>
    email_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    email_starts_with?: InputMaybe<Scalars['String']['input']>
    email_starts_with_i?: InputMaybe<Scalars['String']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    implicitFeeDistributionSchema?: InputMaybe<Array<FeeDistributionFieldInput>>
    implicitFeeDistributionSchema_in?: InputMaybe<Array<InputMaybe<Array<FeeDistributionFieldInput>>>>
    implicitFeeDistributionSchema_not?: InputMaybe<Array<FeeDistributionFieldInput>>
    implicitFeeDistributionSchema_not_in?: InputMaybe<Array<InputMaybe<Array<FeeDistributionFieldInput>>>>
    integration?: InputMaybe<AcquiringIntegrationWhereInput>
    integration_is_null?: InputMaybe<Scalars['Boolean']['input']>
    invoiceEmails?: InputMaybe<Scalars['String']['input']>
    invoiceEmails_contains?: InputMaybe<Scalars['String']['input']>
    invoiceEmails_contains_i?: InputMaybe<Scalars['String']['input']>
    invoiceEmails_ends_with?: InputMaybe<Scalars['String']['input']>
    invoiceEmails_ends_with_i?: InputMaybe<Scalars['String']['input']>
    invoiceEmails_i?: InputMaybe<Scalars['String']['input']>
    invoiceEmails_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    invoiceEmails_not?: InputMaybe<Scalars['String']['input']>
    invoiceEmails_not_contains?: InputMaybe<Scalars['String']['input']>
    invoiceEmails_not_contains_i?: InputMaybe<Scalars['String']['input']>
    invoiceEmails_not_ends_with?: InputMaybe<Scalars['String']['input']>
    invoiceEmails_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    invoiceEmails_not_i?: InputMaybe<Scalars['String']['input']>
    invoiceEmails_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    invoiceEmails_not_starts_with?: InputMaybe<Scalars['String']['input']>
    invoiceEmails_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    invoiceEmails_starts_with?: InputMaybe<Scalars['String']['input']>
    invoiceEmails_starts_with_i?: InputMaybe<Scalars['String']['input']>
    invoiceImplicitFeeDistributionSchema?: InputMaybe<Array<FeeDistributionFieldInput>>
    invoiceImplicitFeeDistributionSchema_in?: InputMaybe<Array<InputMaybe<Array<FeeDistributionFieldInput>>>>
    invoiceImplicitFeeDistributionSchema_not?: InputMaybe<Array<FeeDistributionFieldInput>>
    invoiceImplicitFeeDistributionSchema_not_in?: InputMaybe<Array<InputMaybe<Array<FeeDistributionFieldInput>>>>
    invoiceReason?: InputMaybe<Scalars['String']['input']>
    invoiceReason_contains?: InputMaybe<Scalars['String']['input']>
    invoiceReason_contains_i?: InputMaybe<Scalars['String']['input']>
    invoiceReason_ends_with?: InputMaybe<Scalars['String']['input']>
    invoiceReason_ends_with_i?: InputMaybe<Scalars['String']['input']>
    invoiceReason_i?: InputMaybe<Scalars['String']['input']>
    invoiceReason_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    invoiceReason_not?: InputMaybe<Scalars['String']['input']>
    invoiceReason_not_contains?: InputMaybe<Scalars['String']['input']>
    invoiceReason_not_contains_i?: InputMaybe<Scalars['String']['input']>
    invoiceReason_not_ends_with?: InputMaybe<Scalars['String']['input']>
    invoiceReason_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    invoiceReason_not_i?: InputMaybe<Scalars['String']['input']>
    invoiceReason_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    invoiceReason_not_starts_with?: InputMaybe<Scalars['String']['input']>
    invoiceReason_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    invoiceReason_starts_with?: InputMaybe<Scalars['String']['input']>
    invoiceReason_starts_with_i?: InputMaybe<Scalars['String']['input']>
    invoiceRecipient?: InputMaybe<RecipientFieldInput>
    invoiceRecipient_in?: InputMaybe<Array<InputMaybe<RecipientFieldInput>>>
    invoiceRecipient_not?: InputMaybe<RecipientFieldInput>
    invoiceRecipient_not_in?: InputMaybe<Array<InputMaybe<RecipientFieldInput>>>
    invoiceSalesTaxPercent?: InputMaybe<Scalars['String']['input']>
    invoiceSalesTaxPercent_gt?: InputMaybe<Scalars['String']['input']>
    invoiceSalesTaxPercent_gte?: InputMaybe<Scalars['String']['input']>
    invoiceSalesTaxPercent_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    invoiceSalesTaxPercent_lt?: InputMaybe<Scalars['String']['input']>
    invoiceSalesTaxPercent_lte?: InputMaybe<Scalars['String']['input']>
    invoiceSalesTaxPercent_not?: InputMaybe<Scalars['String']['input']>
    invoiceSalesTaxPercent_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    invoiceStatus?: InputMaybe<Scalars['String']['input']>
    invoiceStatus_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    invoiceStatus_not?: InputMaybe<Scalars['String']['input']>
    invoiceStatus_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    invoiceTaxRegime?: InputMaybe<AcquiringIntegrationContextInvoiceTaxRegimeType>
    invoiceTaxRegime_in?: InputMaybe<Array<InputMaybe<AcquiringIntegrationContextInvoiceTaxRegimeType>>>
    invoiceTaxRegime_not?: InputMaybe<AcquiringIntegrationContextInvoiceTaxRegimeType>
    invoiceTaxRegime_not_in?: InputMaybe<Array<InputMaybe<AcquiringIntegrationContextInvoiceTaxRegimeType>>>
    invoiceVatPercent?: InputMaybe<Scalars['String']['input']>
    invoiceVatPercent_gt?: InputMaybe<Scalars['String']['input']>
    invoiceVatPercent_gte?: InputMaybe<Scalars['String']['input']>
    invoiceVatPercent_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    invoiceVatPercent_lt?: InputMaybe<Scalars['String']['input']>
    invoiceVatPercent_lte?: InputMaybe<Scalars['String']['input']>
    invoiceVatPercent_not?: InputMaybe<Scalars['String']['input']>
    invoiceVatPercent_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    reason?: InputMaybe<Scalars['String']['input']>
    reason_contains?: InputMaybe<Scalars['String']['input']>
    reason_contains_i?: InputMaybe<Scalars['String']['input']>
    reason_ends_with?: InputMaybe<Scalars['String']['input']>
    reason_ends_with_i?: InputMaybe<Scalars['String']['input']>
    reason_i?: InputMaybe<Scalars['String']['input']>
    reason_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    reason_not?: InputMaybe<Scalars['String']['input']>
    reason_not_contains?: InputMaybe<Scalars['String']['input']>
    reason_not_contains_i?: InputMaybe<Scalars['String']['input']>
    reason_not_ends_with?: InputMaybe<Scalars['String']['input']>
    reason_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    reason_not_i?: InputMaybe<Scalars['String']['input']>
    reason_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    reason_not_starts_with?: InputMaybe<Scalars['String']['input']>
    reason_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    reason_starts_with?: InputMaybe<Scalars['String']['input']>
    reason_starts_with_i?: InputMaybe<Scalars['String']['input']>
    recipient?: InputMaybe<RecipientFieldInput>
    recipient_in?: InputMaybe<Array<InputMaybe<RecipientFieldInput>>>
    recipient_not?: InputMaybe<RecipientFieldInput>
    recipient_not_in?: InputMaybe<Array<InputMaybe<RecipientFieldInput>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    settings?: InputMaybe<Scalars['JSON']['input']>
    settings_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    settings_not?: InputMaybe<Scalars['JSON']['input']>
    settings_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    state?: InputMaybe<Scalars['JSON']['input']>
    state_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    state_not?: InputMaybe<Scalars['JSON']['input']>
    state_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    status?: InputMaybe<Scalars['String']['input']>
    status_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status_not?: InputMaybe<Scalars['String']['input']>
    status_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type AcquiringIntegrationContextWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type AcquiringIntegrationContextsCreateInput = {
    data?: InputMaybe<AcquiringIntegrationContextCreateInput>
}

export type AcquiringIntegrationContextsUpdateInput = {
    data?: InputMaybe<AcquiringIntegrationContextUpdateInput>
    id: Scalars['ID']['input']
}

export type AcquiringIntegrationCreateInput = {
    accessRights?: InputMaybe<AcquiringIntegrationAccessRightRelateToManyInput>
    canGroupReceipts?: InputMaybe<Scalars['Boolean']['input']>
    contextDefaultStatus?: InputMaybe<AcquiringIntegrationContextDefaultStatusType>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    explicitFeeDistributionSchema?: InputMaybe<Array<FeeDistributionFieldInput>>
    hostUrl?: InputMaybe<Scalars['String']['input']>
    isHidden?: InputMaybe<Scalars['Boolean']['input']>
    maximumPaymentAmount?: InputMaybe<Scalars['String']['input']>
    minimumPaymentAmount?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    setupUrl?: InputMaybe<Scalars['String']['input']>
    supportedBillingIntegrationsGroup?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
    vatPercentOptions?: InputMaybe<Scalars['String']['input']>
}

/**  A keystone list  */
export type AcquiringIntegrationHistoryRecord = {
    __typename?: 'AcquiringIntegrationHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the AcquiringIntegrationHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the AcquiringIntegrationHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the AcquiringIntegrationHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the AcquiringIntegrationHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    canGroupReceipts?: Maybe<Scalars['Boolean']['output']>
    contextDefaultStatus?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    explicitFeeDistributionSchema?: Maybe<Scalars['JSON']['output']>
    history_action?: Maybe<AcquiringIntegrationHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    hostUrl?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    isHidden?: Maybe<Scalars['Boolean']['output']>
    maximumPaymentAmount?: Maybe<Scalars['String']['output']>
    minimumPaymentAmount?: Maybe<Scalars['String']['output']>
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    setupUrl?: Maybe<Scalars['String']['output']>
    supportedBillingIntegrationsGroup?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
    vatPercentOptions?: Maybe<Scalars['String']['output']>
}

export type AcquiringIntegrationHistoryRecordCreateInput = {
    canGroupReceipts?: InputMaybe<Scalars['Boolean']['input']>
    contextDefaultStatus?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    explicitFeeDistributionSchema?: InputMaybe<Scalars['JSON']['input']>
    history_action?: InputMaybe<AcquiringIntegrationHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    hostUrl?: InputMaybe<Scalars['String']['input']>
    isHidden?: InputMaybe<Scalars['Boolean']['input']>
    maximumPaymentAmount?: InputMaybe<Scalars['String']['input']>
    minimumPaymentAmount?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    setupUrl?: InputMaybe<Scalars['String']['input']>
    supportedBillingIntegrationsGroup?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    vatPercentOptions?: InputMaybe<Scalars['String']['input']>
}

export enum AcquiringIntegrationHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type AcquiringIntegrationHistoryRecordUpdateInput = {
    canGroupReceipts?: InputMaybe<Scalars['Boolean']['input']>
    contextDefaultStatus?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    explicitFeeDistributionSchema?: InputMaybe<Scalars['JSON']['input']>
    history_action?: InputMaybe<AcquiringIntegrationHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    hostUrl?: InputMaybe<Scalars['String']['input']>
    isHidden?: InputMaybe<Scalars['Boolean']['input']>
    maximumPaymentAmount?: InputMaybe<Scalars['String']['input']>
    minimumPaymentAmount?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    setupUrl?: InputMaybe<Scalars['String']['input']>
    supportedBillingIntegrationsGroup?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    vatPercentOptions?: InputMaybe<Scalars['String']['input']>
}

export type AcquiringIntegrationHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<AcquiringIntegrationHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<AcquiringIntegrationHistoryRecordWhereInput>>>
    canGroupReceipts?: InputMaybe<Scalars['Boolean']['input']>
    canGroupReceipts_not?: InputMaybe<Scalars['Boolean']['input']>
    contextDefaultStatus?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus_contains?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus_contains_i?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus_ends_with?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus_ends_with_i?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus_i?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    contextDefaultStatus_not?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus_not_contains?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus_not_contains_i?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus_not_ends_with?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus_not_i?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    contextDefaultStatus_not_starts_with?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus_starts_with?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus_starts_with_i?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    explicitFeeDistributionSchema?: InputMaybe<Scalars['JSON']['input']>
    explicitFeeDistributionSchema_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    explicitFeeDistributionSchema_not?: InputMaybe<Scalars['JSON']['input']>
    explicitFeeDistributionSchema_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    history_action?: InputMaybe<AcquiringIntegrationHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<AcquiringIntegrationHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<AcquiringIntegrationHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<AcquiringIntegrationHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    hostUrl?: InputMaybe<Scalars['String']['input']>
    hostUrl_contains?: InputMaybe<Scalars['String']['input']>
    hostUrl_contains_i?: InputMaybe<Scalars['String']['input']>
    hostUrl_ends_with?: InputMaybe<Scalars['String']['input']>
    hostUrl_ends_with_i?: InputMaybe<Scalars['String']['input']>
    hostUrl_i?: InputMaybe<Scalars['String']['input']>
    hostUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    hostUrl_not?: InputMaybe<Scalars['String']['input']>
    hostUrl_not_contains?: InputMaybe<Scalars['String']['input']>
    hostUrl_not_contains_i?: InputMaybe<Scalars['String']['input']>
    hostUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
    hostUrl_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    hostUrl_not_i?: InputMaybe<Scalars['String']['input']>
    hostUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    hostUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
    hostUrl_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    hostUrl_starts_with?: InputMaybe<Scalars['String']['input']>
    hostUrl_starts_with_i?: InputMaybe<Scalars['String']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    isHidden?: InputMaybe<Scalars['Boolean']['input']>
    isHidden_not?: InputMaybe<Scalars['Boolean']['input']>
    maximumPaymentAmount?: InputMaybe<Scalars['String']['input']>
    maximumPaymentAmount_gt?: InputMaybe<Scalars['String']['input']>
    maximumPaymentAmount_gte?: InputMaybe<Scalars['String']['input']>
    maximumPaymentAmount_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    maximumPaymentAmount_lt?: InputMaybe<Scalars['String']['input']>
    maximumPaymentAmount_lte?: InputMaybe<Scalars['String']['input']>
    maximumPaymentAmount_not?: InputMaybe<Scalars['String']['input']>
    maximumPaymentAmount_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    minimumPaymentAmount?: InputMaybe<Scalars['String']['input']>
    minimumPaymentAmount_gt?: InputMaybe<Scalars['String']['input']>
    minimumPaymentAmount_gte?: InputMaybe<Scalars['String']['input']>
    minimumPaymentAmount_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    minimumPaymentAmount_lt?: InputMaybe<Scalars['String']['input']>
    minimumPaymentAmount_lte?: InputMaybe<Scalars['String']['input']>
    minimumPaymentAmount_not?: InputMaybe<Scalars['String']['input']>
    minimumPaymentAmount_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    setupUrl?: InputMaybe<Scalars['String']['input']>
    setupUrl_contains?: InputMaybe<Scalars['String']['input']>
    setupUrl_contains_i?: InputMaybe<Scalars['String']['input']>
    setupUrl_ends_with?: InputMaybe<Scalars['String']['input']>
    setupUrl_ends_with_i?: InputMaybe<Scalars['String']['input']>
    setupUrl_i?: InputMaybe<Scalars['String']['input']>
    setupUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    setupUrl_not?: InputMaybe<Scalars['String']['input']>
    setupUrl_not_contains?: InputMaybe<Scalars['String']['input']>
    setupUrl_not_contains_i?: InputMaybe<Scalars['String']['input']>
    setupUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
    setupUrl_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    setupUrl_not_i?: InputMaybe<Scalars['String']['input']>
    setupUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    setupUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
    setupUrl_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    setupUrl_starts_with?: InputMaybe<Scalars['String']['input']>
    setupUrl_starts_with_i?: InputMaybe<Scalars['String']['input']>
    supportedBillingIntegrationsGroup?: InputMaybe<Scalars['String']['input']>
    supportedBillingIntegrationsGroup_contains?: InputMaybe<Scalars['String']['input']>
    supportedBillingIntegrationsGroup_contains_i?: InputMaybe<Scalars['String']['input']>
    supportedBillingIntegrationsGroup_ends_with?: InputMaybe<Scalars['String']['input']>
    supportedBillingIntegrationsGroup_ends_with_i?: InputMaybe<Scalars['String']['input']>
    supportedBillingIntegrationsGroup_i?: InputMaybe<Scalars['String']['input']>
    supportedBillingIntegrationsGroup_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    supportedBillingIntegrationsGroup_not?: InputMaybe<Scalars['String']['input']>
    supportedBillingIntegrationsGroup_not_contains?: InputMaybe<Scalars['String']['input']>
    supportedBillingIntegrationsGroup_not_contains_i?: InputMaybe<Scalars['String']['input']>
    supportedBillingIntegrationsGroup_not_ends_with?: InputMaybe<Scalars['String']['input']>
    supportedBillingIntegrationsGroup_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    supportedBillingIntegrationsGroup_not_i?: InputMaybe<Scalars['String']['input']>
    supportedBillingIntegrationsGroup_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    supportedBillingIntegrationsGroup_not_starts_with?: InputMaybe<Scalars['String']['input']>
    supportedBillingIntegrationsGroup_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    supportedBillingIntegrationsGroup_starts_with?: InputMaybe<Scalars['String']['input']>
    supportedBillingIntegrationsGroup_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    vatPercentOptions?: InputMaybe<Scalars['String']['input']>
    vatPercentOptions_contains?: InputMaybe<Scalars['String']['input']>
    vatPercentOptions_contains_i?: InputMaybe<Scalars['String']['input']>
    vatPercentOptions_ends_with?: InputMaybe<Scalars['String']['input']>
    vatPercentOptions_ends_with_i?: InputMaybe<Scalars['String']['input']>
    vatPercentOptions_i?: InputMaybe<Scalars['String']['input']>
    vatPercentOptions_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    vatPercentOptions_not?: InputMaybe<Scalars['String']['input']>
    vatPercentOptions_not_contains?: InputMaybe<Scalars['String']['input']>
    vatPercentOptions_not_contains_i?: InputMaybe<Scalars['String']['input']>
    vatPercentOptions_not_ends_with?: InputMaybe<Scalars['String']['input']>
    vatPercentOptions_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    vatPercentOptions_not_i?: InputMaybe<Scalars['String']['input']>
    vatPercentOptions_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    vatPercentOptions_not_starts_with?: InputMaybe<Scalars['String']['input']>
    vatPercentOptions_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    vatPercentOptions_starts_with?: InputMaybe<Scalars['String']['input']>
    vatPercentOptions_starts_with_i?: InputMaybe<Scalars['String']['input']>
}

export type AcquiringIntegrationHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type AcquiringIntegrationHistoryRecordsCreateInput = {
    data?: InputMaybe<AcquiringIntegrationHistoryRecordCreateInput>
}

export type AcquiringIntegrationHistoryRecordsUpdateInput = {
    data?: InputMaybe<AcquiringIntegrationHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type AcquiringIntegrationRelateToOneInput = {
    connect?: InputMaybe<AcquiringIntegrationWhereUniqueInput>
    create?: InputMaybe<AcquiringIntegrationCreateInput>
    disconnect?: InputMaybe<AcquiringIntegrationWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type AcquiringIntegrationUpdateInput = {
    accessRights?: InputMaybe<AcquiringIntegrationAccessRightRelateToManyInput>
    canGroupReceipts?: InputMaybe<Scalars['Boolean']['input']>
    contextDefaultStatus?: InputMaybe<AcquiringIntegrationContextDefaultStatusType>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    explicitFeeDistributionSchema?: InputMaybe<Array<FeeDistributionFieldInput>>
    hostUrl?: InputMaybe<Scalars['String']['input']>
    isHidden?: InputMaybe<Scalars['Boolean']['input']>
    maximumPaymentAmount?: InputMaybe<Scalars['String']['input']>
    minimumPaymentAmount?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    setupUrl?: InputMaybe<Scalars['String']['input']>
    supportedBillingIntegrationsGroup?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
    vatPercentOptions?: InputMaybe<Scalars['String']['input']>
}

export type AcquiringIntegrationWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<AcquiringIntegrationWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<AcquiringIntegrationWhereInput>>>
    /**  condition must be true for all nodes  */
    accessRights_every?: InputMaybe<AcquiringIntegrationAccessRightWhereInput>
    /**  condition must be false for all nodes  */
    accessRights_none?: InputMaybe<AcquiringIntegrationAccessRightWhereInput>
    /**  condition must be true for at least 1 node  */
    accessRights_some?: InputMaybe<AcquiringIntegrationAccessRightWhereInput>
    canGroupReceipts?: InputMaybe<Scalars['Boolean']['input']>
    canGroupReceipts_not?: InputMaybe<Scalars['Boolean']['input']>
    contextDefaultStatus?: InputMaybe<AcquiringIntegrationContextDefaultStatusType>
    contextDefaultStatus_in?: InputMaybe<Array<InputMaybe<AcquiringIntegrationContextDefaultStatusType>>>
    contextDefaultStatus_not?: InputMaybe<AcquiringIntegrationContextDefaultStatusType>
    contextDefaultStatus_not_in?: InputMaybe<Array<InputMaybe<AcquiringIntegrationContextDefaultStatusType>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    explicitFeeDistributionSchema?: InputMaybe<Array<FeeDistributionFieldInput>>
    explicitFeeDistributionSchema_in?: InputMaybe<Array<InputMaybe<Array<FeeDistributionFieldInput>>>>
    explicitFeeDistributionSchema_not?: InputMaybe<Array<FeeDistributionFieldInput>>
    explicitFeeDistributionSchema_not_in?: InputMaybe<Array<InputMaybe<Array<FeeDistributionFieldInput>>>>
    hostUrl?: InputMaybe<Scalars['String']['input']>
    hostUrl_contains?: InputMaybe<Scalars['String']['input']>
    hostUrl_contains_i?: InputMaybe<Scalars['String']['input']>
    hostUrl_ends_with?: InputMaybe<Scalars['String']['input']>
    hostUrl_ends_with_i?: InputMaybe<Scalars['String']['input']>
    hostUrl_i?: InputMaybe<Scalars['String']['input']>
    hostUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    hostUrl_not?: InputMaybe<Scalars['String']['input']>
    hostUrl_not_contains?: InputMaybe<Scalars['String']['input']>
    hostUrl_not_contains_i?: InputMaybe<Scalars['String']['input']>
    hostUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
    hostUrl_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    hostUrl_not_i?: InputMaybe<Scalars['String']['input']>
    hostUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    hostUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
    hostUrl_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    hostUrl_starts_with?: InputMaybe<Scalars['String']['input']>
    hostUrl_starts_with_i?: InputMaybe<Scalars['String']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    isHidden?: InputMaybe<Scalars['Boolean']['input']>
    isHidden_not?: InputMaybe<Scalars['Boolean']['input']>
    maximumPaymentAmount?: InputMaybe<Scalars['String']['input']>
    maximumPaymentAmount_gt?: InputMaybe<Scalars['String']['input']>
    maximumPaymentAmount_gte?: InputMaybe<Scalars['String']['input']>
    maximumPaymentAmount_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    maximumPaymentAmount_lt?: InputMaybe<Scalars['String']['input']>
    maximumPaymentAmount_lte?: InputMaybe<Scalars['String']['input']>
    maximumPaymentAmount_not?: InputMaybe<Scalars['String']['input']>
    maximumPaymentAmount_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    minimumPaymentAmount?: InputMaybe<Scalars['String']['input']>
    minimumPaymentAmount_gt?: InputMaybe<Scalars['String']['input']>
    minimumPaymentAmount_gte?: InputMaybe<Scalars['String']['input']>
    minimumPaymentAmount_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    minimumPaymentAmount_lt?: InputMaybe<Scalars['String']['input']>
    minimumPaymentAmount_lte?: InputMaybe<Scalars['String']['input']>
    minimumPaymentAmount_not?: InputMaybe<Scalars['String']['input']>
    minimumPaymentAmount_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    setupUrl?: InputMaybe<Scalars['String']['input']>
    setupUrl_contains?: InputMaybe<Scalars['String']['input']>
    setupUrl_contains_i?: InputMaybe<Scalars['String']['input']>
    setupUrl_ends_with?: InputMaybe<Scalars['String']['input']>
    setupUrl_ends_with_i?: InputMaybe<Scalars['String']['input']>
    setupUrl_i?: InputMaybe<Scalars['String']['input']>
    setupUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    setupUrl_not?: InputMaybe<Scalars['String']['input']>
    setupUrl_not_contains?: InputMaybe<Scalars['String']['input']>
    setupUrl_not_contains_i?: InputMaybe<Scalars['String']['input']>
    setupUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
    setupUrl_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    setupUrl_not_i?: InputMaybe<Scalars['String']['input']>
    setupUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    setupUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
    setupUrl_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    setupUrl_starts_with?: InputMaybe<Scalars['String']['input']>
    setupUrl_starts_with_i?: InputMaybe<Scalars['String']['input']>
    supportedBillingIntegrationsGroup?: InputMaybe<Scalars['String']['input']>
    supportedBillingIntegrationsGroup_contains?: InputMaybe<Scalars['String']['input']>
    supportedBillingIntegrationsGroup_contains_i?: InputMaybe<Scalars['String']['input']>
    supportedBillingIntegrationsGroup_ends_with?: InputMaybe<Scalars['String']['input']>
    supportedBillingIntegrationsGroup_ends_with_i?: InputMaybe<Scalars['String']['input']>
    supportedBillingIntegrationsGroup_i?: InputMaybe<Scalars['String']['input']>
    supportedBillingIntegrationsGroup_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    supportedBillingIntegrationsGroup_not?: InputMaybe<Scalars['String']['input']>
    supportedBillingIntegrationsGroup_not_contains?: InputMaybe<Scalars['String']['input']>
    supportedBillingIntegrationsGroup_not_contains_i?: InputMaybe<Scalars['String']['input']>
    supportedBillingIntegrationsGroup_not_ends_with?: InputMaybe<Scalars['String']['input']>
    supportedBillingIntegrationsGroup_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    supportedBillingIntegrationsGroup_not_i?: InputMaybe<Scalars['String']['input']>
    supportedBillingIntegrationsGroup_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    supportedBillingIntegrationsGroup_not_starts_with?: InputMaybe<Scalars['String']['input']>
    supportedBillingIntegrationsGroup_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    supportedBillingIntegrationsGroup_starts_with?: InputMaybe<Scalars['String']['input']>
    supportedBillingIntegrationsGroup_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    vatPercentOptions?: InputMaybe<Scalars['String']['input']>
    vatPercentOptions_contains?: InputMaybe<Scalars['String']['input']>
    vatPercentOptions_contains_i?: InputMaybe<Scalars['String']['input']>
    vatPercentOptions_ends_with?: InputMaybe<Scalars['String']['input']>
    vatPercentOptions_ends_with_i?: InputMaybe<Scalars['String']['input']>
    vatPercentOptions_i?: InputMaybe<Scalars['String']['input']>
    vatPercentOptions_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    vatPercentOptions_not?: InputMaybe<Scalars['String']['input']>
    vatPercentOptions_not_contains?: InputMaybe<Scalars['String']['input']>
    vatPercentOptions_not_contains_i?: InputMaybe<Scalars['String']['input']>
    vatPercentOptions_not_ends_with?: InputMaybe<Scalars['String']['input']>
    vatPercentOptions_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    vatPercentOptions_not_i?: InputMaybe<Scalars['String']['input']>
    vatPercentOptions_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    vatPercentOptions_not_starts_with?: InputMaybe<Scalars['String']['input']>
    vatPercentOptions_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    vatPercentOptions_starts_with?: InputMaybe<Scalars['String']['input']>
    vatPercentOptions_starts_with_i?: InputMaybe<Scalars['String']['input']>
}

export type AcquiringIntegrationWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type AcquiringIntegrationsCreateInput = {
    data?: InputMaybe<AcquiringIntegrationCreateInput>
}

export type AcquiringIntegrationsUpdateInput = {
    data?: InputMaybe<AcquiringIntegrationUpdateInput>
    id: Scalars['ID']['input']
}

export type AddressMetaDataField = {
    __typename?: 'AddressMetaDataField'
    area?: Maybe<Scalars['String']['output']>
    area_fias_id?: Maybe<Scalars['String']['output']>
    area_kladr_id?: Maybe<Scalars['String']['output']>
    area_type?: Maybe<Scalars['String']['output']>
    area_type_full?: Maybe<Scalars['String']['output']>
    area_with_type?: Maybe<Scalars['String']['output']>
    beltway_distance?: Maybe<Scalars['String']['output']>
    beltway_hit?: Maybe<Scalars['String']['output']>
    block?: Maybe<Scalars['String']['output']>
    block_type?: Maybe<Scalars['String']['output']>
    block_type_full?: Maybe<Scalars['String']['output']>
    capital_marker?: Maybe<Scalars['String']['output']>
    city?: Maybe<Scalars['String']['output']>
    city_area?: Maybe<Scalars['String']['output']>
    city_district?: Maybe<Scalars['String']['output']>
    city_district_fias_id?: Maybe<Scalars['String']['output']>
    city_district_kladr_id?: Maybe<Scalars['String']['output']>
    city_district_type?: Maybe<Scalars['String']['output']>
    city_district_type_full?: Maybe<Scalars['String']['output']>
    city_district_with_type?: Maybe<Scalars['String']['output']>
    city_fias_id?: Maybe<Scalars['String']['output']>
    city_kladr_id?: Maybe<Scalars['String']['output']>
    city_type?: Maybe<Scalars['String']['output']>
    city_type_full?: Maybe<Scalars['String']['output']>
    city_with_type?: Maybe<Scalars['String']['output']>
    country: Scalars['String']['output']
    country_iso_code?: Maybe<Scalars['String']['output']>
    entrance?: Maybe<Scalars['String']['output']>
    federal_district?: Maybe<Scalars['String']['output']>
    fias_actuality_state?: Maybe<Scalars['String']['output']>
    fias_code?: Maybe<Scalars['String']['output']>
    fias_id?: Maybe<Scalars['String']['output']>
    fias_level?: Maybe<Scalars['String']['output']>
    flat?: Maybe<Scalars['String']['output']>
    flat_area?: Maybe<Scalars['String']['output']>
    flat_fias_id?: Maybe<Scalars['String']['output']>
    flat_price?: Maybe<Scalars['String']['output']>
    flat_type?: Maybe<Scalars['String']['output']>
    flat_type_full?: Maybe<Scalars['String']['output']>
    floor?: Maybe<Scalars['String']['output']>
    geo_lat?: Maybe<Scalars['String']['output']>
    geo_lon?: Maybe<Scalars['String']['output']>
    geoname_id?: Maybe<Scalars['String']['output']>
    history_values?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    house?: Maybe<Scalars['String']['output']>
    house_fias_id?: Maybe<Scalars['String']['output']>
    house_kladr_id?: Maybe<Scalars['String']['output']>
    house_type?: Maybe<Scalars['String']['output']>
    house_type_full?: Maybe<Scalars['String']['output']>
    kladr_id?: Maybe<Scalars['String']['output']>
    metro?: Maybe<Array<Maybe<AddressMetaDataMetroField>>>
    okato?: Maybe<Scalars['String']['output']>
    oktmo?: Maybe<Scalars['String']['output']>
    postal_box?: Maybe<Scalars['String']['output']>
    postal_code?: Maybe<Scalars['String']['output']>
    qc?: Maybe<Scalars['String']['output']>
    qc_complete?: Maybe<Scalars['String']['output']>
    qc_geo?: Maybe<Scalars['String']['output']>
    qc_house?: Maybe<Scalars['String']['output']>
    region: Scalars['String']['output']
    region_fias_id?: Maybe<Scalars['String']['output']>
    region_iso_code?: Maybe<Scalars['String']['output']>
    region_kladr_id?: Maybe<Scalars['String']['output']>
    region_type?: Maybe<Scalars['String']['output']>
    region_type_full?: Maybe<Scalars['String']['output']>
    region_with_type?: Maybe<Scalars['String']['output']>
    settlement?: Maybe<Scalars['String']['output']>
    settlement_fias_id?: Maybe<Scalars['String']['output']>
    settlement_kladr_id?: Maybe<Scalars['String']['output']>
    settlement_type?: Maybe<Scalars['String']['output']>
    settlement_type_full?: Maybe<Scalars['String']['output']>
    settlement_with_type?: Maybe<Scalars['String']['output']>
    source?: Maybe<Scalars['String']['output']>
    square_meter_price?: Maybe<Scalars['String']['output']>
    street?: Maybe<Scalars['String']['output']>
    street_fias_id?: Maybe<Scalars['String']['output']>
    street_kladr_id?: Maybe<Scalars['String']['output']>
    street_type?: Maybe<Scalars['String']['output']>
    street_type_full?: Maybe<Scalars['String']['output']>
    street_with_type?: Maybe<Scalars['String']['output']>
    tax_office?: Maybe<Scalars['String']['output']>
    tax_office_legal?: Maybe<Scalars['String']['output']>
    timezone?: Maybe<Scalars['String']['output']>
    unparsed_parts?: Maybe<Scalars['String']['output']>
}

export type AddressMetaDataFieldInput = {
    area?: InputMaybe<Scalars['String']['input']>
    area_fias_id?: InputMaybe<Scalars['String']['input']>
    area_kladr_id?: InputMaybe<Scalars['String']['input']>
    area_type?: InputMaybe<Scalars['String']['input']>
    area_type_full?: InputMaybe<Scalars['String']['input']>
    area_with_type?: InputMaybe<Scalars['String']['input']>
    beltway_distance?: InputMaybe<Scalars['String']['input']>
    beltway_hit?: InputMaybe<Scalars['String']['input']>
    block?: InputMaybe<Scalars['String']['input']>
    block_type?: InputMaybe<Scalars['String']['input']>
    block_type_full?: InputMaybe<Scalars['String']['input']>
    capital_marker?: InputMaybe<Scalars['String']['input']>
    city?: InputMaybe<Scalars['String']['input']>
    city_area?: InputMaybe<Scalars['String']['input']>
    city_district?: InputMaybe<Scalars['String']['input']>
    city_district_fias_id?: InputMaybe<Scalars['String']['input']>
    city_district_kladr_id?: InputMaybe<Scalars['String']['input']>
    city_district_type?: InputMaybe<Scalars['String']['input']>
    city_district_type_full?: InputMaybe<Scalars['String']['input']>
    city_district_with_type?: InputMaybe<Scalars['String']['input']>
    city_fias_id?: InputMaybe<Scalars['String']['input']>
    city_kladr_id?: InputMaybe<Scalars['String']['input']>
    city_type?: InputMaybe<Scalars['String']['input']>
    city_type_full?: InputMaybe<Scalars['String']['input']>
    city_with_type?: InputMaybe<Scalars['String']['input']>
    country: Scalars['String']['input']
    country_iso_code?: InputMaybe<Scalars['String']['input']>
    entrance?: InputMaybe<Scalars['String']['input']>
    federal_district?: InputMaybe<Scalars['String']['input']>
    fias_actuality_state?: InputMaybe<Scalars['String']['input']>
    fias_code?: InputMaybe<Scalars['String']['input']>
    fias_id?: InputMaybe<Scalars['String']['input']>
    fias_level?: InputMaybe<Scalars['String']['input']>
    flat?: InputMaybe<Scalars['String']['input']>
    flat_area?: InputMaybe<Scalars['String']['input']>
    flat_fias_id?: InputMaybe<Scalars['String']['input']>
    flat_price?: InputMaybe<Scalars['String']['input']>
    flat_type?: InputMaybe<Scalars['String']['input']>
    flat_type_full?: InputMaybe<Scalars['String']['input']>
    floor?: InputMaybe<Scalars['String']['input']>
    geo_lat?: InputMaybe<Scalars['String']['input']>
    geo_lon?: InputMaybe<Scalars['String']['input']>
    geoname_id?: InputMaybe<Scalars['String']['input']>
    history_values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    house?: InputMaybe<Scalars['String']['input']>
    house_fias_id?: InputMaybe<Scalars['String']['input']>
    house_kladr_id?: InputMaybe<Scalars['String']['input']>
    house_type?: InputMaybe<Scalars['String']['input']>
    house_type_full?: InputMaybe<Scalars['String']['input']>
    kladr_id?: InputMaybe<Scalars['String']['input']>
    metro?: InputMaybe<Array<InputMaybe<AddressMetaDataMetroFieldInput>>>
    okato?: InputMaybe<Scalars['String']['input']>
    oktmo?: InputMaybe<Scalars['String']['input']>
    postal_box?: InputMaybe<Scalars['String']['input']>
    postal_code?: InputMaybe<Scalars['String']['input']>
    qc?: InputMaybe<Scalars['String']['input']>
    qc_complete?: InputMaybe<Scalars['String']['input']>
    qc_geo?: InputMaybe<Scalars['String']['input']>
    qc_house?: InputMaybe<Scalars['String']['input']>
    region: Scalars['String']['input']
    region_fias_id?: InputMaybe<Scalars['String']['input']>
    region_iso_code?: InputMaybe<Scalars['String']['input']>
    region_kladr_id?: InputMaybe<Scalars['String']['input']>
    region_type?: InputMaybe<Scalars['String']['input']>
    region_type_full?: InputMaybe<Scalars['String']['input']>
    region_with_type?: InputMaybe<Scalars['String']['input']>
    settlement?: InputMaybe<Scalars['String']['input']>
    settlement_fias_id?: InputMaybe<Scalars['String']['input']>
    settlement_kladr_id?: InputMaybe<Scalars['String']['input']>
    settlement_type?: InputMaybe<Scalars['String']['input']>
    settlement_type_full?: InputMaybe<Scalars['String']['input']>
    settlement_with_type?: InputMaybe<Scalars['String']['input']>
    source?: InputMaybe<Scalars['String']['input']>
    square_meter_price?: InputMaybe<Scalars['String']['input']>
    street?: InputMaybe<Scalars['String']['input']>
    street_fias_id?: InputMaybe<Scalars['String']['input']>
    street_kladr_id?: InputMaybe<Scalars['String']['input']>
    street_type?: InputMaybe<Scalars['String']['input']>
    street_type_full?: InputMaybe<Scalars['String']['input']>
    street_with_type?: InputMaybe<Scalars['String']['input']>
    tax_office?: InputMaybe<Scalars['String']['input']>
    tax_office_legal?: InputMaybe<Scalars['String']['input']>
    timezone?: InputMaybe<Scalars['String']['input']>
    unparsed_parts?: InputMaybe<Scalars['String']['input']>
}

export type AddressMetaDataMetroField = {
    __typename?: 'AddressMetaDataMetroField'
    distance?: Maybe<Scalars['String']['output']>
    line?: Maybe<Scalars['String']['output']>
    name?: Maybe<Scalars['String']['output']>
}

export type AddressMetaDataMetroFieldInput = {
    distance?: InputMaybe<Scalars['String']['input']>
    line?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
}

export type AddressMetaField = {
    __typename?: 'AddressMetaField'
    data: AddressMetaDataField
    dv?: Maybe<Scalars['Int']['output']>
    unrestricted_value: Scalars['String']['output']
    value: Scalars['String']['output']
}

export type AddressMetaFieldInput = {
    data: AddressMetaDataFieldInput
    dv: Scalars['Int']['input']
    unrestricted_value: Scalars['String']['input']
    value: Scalars['String']['input']
}

export type AllMiniAppsInput = {
    dv: Scalars['Int']['input']
    organization: OrganizationWhereUniqueInput
    sender: SenderFieldInput
    sortBy?: InputMaybe<SortAllMiniAppsBy>
    where?: InputMaybe<AllMiniAppsWhereInput>
}

export type AllMiniAppsWhereInput = {
    accessible?: InputMaybe<Scalars['Boolean']['input']>
    app?: InputMaybe<B2BAppWhereInput>
    connected?: InputMaybe<Scalars['Boolean']['input']>
}

export enum AppCategory {
    BusinessDevelopment = 'BUSINESS_DEVELOPMENT',
    Dispatching = 'DISPATCHING',
    Finance = 'FINANCE',
    Gis = 'GIS',
    Other = 'OTHER',
    SmartHome = 'SMART_HOME',
}

export type AppColorSchemaField = {
    __typename?: 'AppColorSchemaField'
    main: Scalars['String']['output']
    secondary: Scalars['String']['output']
}

export type AppColorSchemaFieldInput = {
    main: Scalars['String']['input']
    secondary: Scalars['String']['input']
}

export type AuthenticateUserWithPhoneAndPasswordInput = {
    password: Scalars['String']['input']
    phone: Scalars['String']['input']
}

export type AuthenticateUserWithPhoneAndPasswordOutput = {
    __typename?: 'AuthenticateUserWithPhoneAndPasswordOutput'
    item?: Maybe<User>
    token: Scalars['String']['output']
}

/**  B2B app  */
export type B2BApp = {
    __typename?: 'B2BApp'
    _accessRightsMeta?: Maybe<_QueryMeta>
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2BApp List config, or
   *  2. As an alias to the field set on 'labelField' in the B2BApp List config, or
   *  3. As an alias to a 'name' field on the B2BApp List (if one exists), or
   *  4. As an alias to the 'id' field on the B2BApp List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  Specifies set of service users, who can access app's contexts related as well as perform actions on behalf of the application  */
    accessRights: Array<B2BAppAccessRight>
    /**  Url to app page, which is the app starting point and will be opened in iframe  */
    appUrl?: Maybe<Scalars['String']['output']>
    /**  Category of app. Can be one of the following: ["DISPATCHING", "GIS", "SMART_HOME", "BUSINESS_DEVELOPMENT", "FINANCE", "OTHER"] By default set to "OTHER"  */
    category?: Maybe<B2BAppCategoryType>
    /**  Status, which context will have by default after creation if no overwriting option provided  */
    contextDefaultStatus?: Maybe<B2BAppContextDefaultStatusType>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Text describing app functionality, connection process and pricing in full detail. Written in markdown without html tags. Will be shown on app's page  */
    detailedDescription?: Maybe<Scalars['String']['output']>
    /**  Developer company name  */
    developer?: Maybe<Scalars['String']['output']>
    /**  The number used to determine the position of the app among the others. App with higher priority appear earlier in "All" category, as well as in it's own category. Apps with the same priority are sorted from newest to oldest. The default value is 1.  */
    displayPriority?: Maybe<Scalars['Int']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  List of features that this global mini-app implements. Can be one or more of the following: [PropertyMapGeneration, AttachCallRecordToTicket]  */
    features?: Maybe<Array<B2BAppGlobalFeature>>
    /**  Array containing links to promotional images, which will be shown to user on app's page  */
    gallery?: Maybe<Array<Scalars['String']['output']>>
    /**  Indicates whether the miniapp has its own dynamic title. If so, the miniapp page will have no default title, shifting the responsibility for displaying it to the app itself. Otherwise, there will be a static title above the app iframe that corresponds to the application name.  */
    hasDynamicTitle?: Maybe<Scalars['Boolean']['output']>
    /**  App icon. The presence of this field means that this app will be pinned to the CRM menu after the connection.  */
    icon?: Maybe<B2BAppIconType>
    id: Scalars['ID']['output']
    /**  Indicates whether the app is global or not. If so, then the application will be opened in hidden mode and receive various notifications from the condo. It's also possible to trigger some condo IFrame methods via global app outside of miniapps CRM section  */
    isGlobal?: Maybe<Scalars['Boolean']['output']>
    /**  Indicates whether the integration or app is hidden inside the CRM. Used if integration is active by default or not ready to appear inside CRM  */
    isHidden?: Maybe<Scalars['Boolean']['output']>
    /**  Indicates visibility of the app on the "services" page. If so, app will be shown. Otherwise it will be hidden until it's context become to "connected" status  */
    isPublic?: Maybe<Scalars['Boolean']['output']>
    /**  App can be marked with one of the following labels in order to visually stand out from other applications: [FREE, DISCOUNT, POPULAR, NEW]  */
    label?: Maybe<B2BAppLabelType>
    /**  Logo of app's company or app itself  */
    logo?: Maybe<File>
    /**  This field is responsible for which category of the CRM menu the application icon will fall into when connected. If not specified - then connected with the icon will be displayed in category "MINIAPPS" by default.  */
    menuCategory?: Maybe<B2BAppMenuCategoryType>
    /**  Name of B2B App  */
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Configuration for News Sharing feature. If specified, then this B2BApp is embeddable to the /news page and supports News Sharing API  */
    newsSharingConfig?: Maybe<B2BAppNewsSharingConfig>
    /**  Link to the website of the developer company, where the user can find out detailed information about the partner  */
    partnerUrl?: Maybe<Scalars['String']['output']>
    /**  String representing a base price of app. Usually it's something like "Free", "Individual", "$50 / year"  */
    price?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Short description / catch phrase providing information about integration functionality. Will be shown on App's card  */
    shortDescription?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}


/**  B2B app  */
export type B2BApp_AccessRightsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2BAppAccessRightsBy>>
    where?: InputMaybe<B2BAppAccessRightWhereInput>
}


/**  B2B app  */
export type B2BAppAccessRightsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2BAppAccessRightsBy>>
    where?: InputMaybe<B2BAppAccessRightWhereInput>
}

/**  Link between service user and B2B App. The existence of this connection means that this user has the rights to perform actions on behalf of the integration  */
export type B2BAppAccessRight = {
    __typename?: 'B2BAppAccessRight'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2BAppAccessRight List config, or
   *  2. As an alias to the field set on 'labelField' in the B2BAppAccessRight List config, or
   *  3. As an alias to a 'name' field on the B2BAppAccessRight List (if one exists), or
   *  4. As an alias to the 'id' field on the B2BAppAccessRight List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**
   *  Link to the set of access rights. This set of access right will be used to check your service user access to schemas that are linked to "Organization" schema (such as "Organization", "Ticket" and others).
   * These accesses will only apply to entities that belong to organizations that connected your app
   */
    accessRightSet?: Maybe<B2BAppAccessRightSet>
    /**  Link to B2BApp.accessRights  */
    app?: Maybe<B2BApp>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    /**  Link to user. Note, that user must be of "service" type  */
    user?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type B2BAppAccessRightCreateInput = {
    accessRightSet?: InputMaybe<B2BAppAccessRightSetRelateToOneInput>
    app?: InputMaybe<B2BAppRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type B2BAppAccessRightHistoryRecord = {
    __typename?: 'B2BAppAccessRightHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2BAppAccessRightHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the B2BAppAccessRightHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the B2BAppAccessRightHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the B2BAppAccessRightHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    accessRightSet?: Maybe<Scalars['String']['output']>
    app?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<B2BAppAccessRightHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    user?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type B2BAppAccessRightHistoryRecordCreateInput = {
    accessRightSet?: InputMaybe<Scalars['String']['input']>
    app?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<B2BAppAccessRightHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum B2BAppAccessRightHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type B2BAppAccessRightHistoryRecordUpdateInput = {
    accessRightSet?: InputMaybe<Scalars['String']['input']>
    app?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<B2BAppAccessRightHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type B2BAppAccessRightHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<B2BAppAccessRightHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<B2BAppAccessRightHistoryRecordWhereInput>>>
    accessRightSet?: InputMaybe<Scalars['String']['input']>
    accessRightSet_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    accessRightSet_not?: InputMaybe<Scalars['String']['input']>
    accessRightSet_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    app?: InputMaybe<Scalars['String']['input']>
    app_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    app_not?: InputMaybe<Scalars['String']['input']>
    app_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<B2BAppAccessRightHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<B2BAppAccessRightHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<B2BAppAccessRightHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<B2BAppAccessRightHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user?: InputMaybe<Scalars['String']['input']>
    user_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user_not?: InputMaybe<Scalars['String']['input']>
    user_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type B2BAppAccessRightHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type B2BAppAccessRightHistoryRecordsCreateInput = {
    data?: InputMaybe<B2BAppAccessRightHistoryRecordCreateInput>
}

export type B2BAppAccessRightHistoryRecordsUpdateInput = {
    data?: InputMaybe<B2BAppAccessRightHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type B2BAppAccessRightRelateToManyInput = {
    connect?: InputMaybe<Array<InputMaybe<B2BAppAccessRightWhereUniqueInput>>>
    create?: InputMaybe<Array<InputMaybe<B2BAppAccessRightCreateInput>>>
    disconnect?: InputMaybe<Array<InputMaybe<B2BAppAccessRightWhereUniqueInput>>>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

/**
 *  A set of access rights for a service user integrated with your B2BApp (exist B2BAppAccessRight with links to your service user and your B2BApp).
 * This set of access right will be used to check your service user access to schemas that are linked to "Organization" schema (such as "Organization", "Ticket" and others).
 * These accesses will only apply to entities that belong to organizations that connected your app.
 * This schema contains fields corresponding to "can<Action><Scheme name in plural>" format (e.g. "canReadOrganizations", "canManageContacts" and etc.)
 * NOTE: Some schemas have links with other models. Therefore, it is necessary to take this into account when specifying the necessary rights.
 * For example, to create a contact, in addition to having access to managing properties, you also need to have access to read organization
 */
export type B2BAppAccessRightSet = {
    __typename?: 'B2BAppAccessRightSet'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2BAppAccessRightSet List config, or
   *  2. As an alias to the field set on 'labelField' in the B2BAppAccessRightSet List config, or
   *  3. As an alias to a 'name' field on the B2BAppAccessRightSet List (if one exists), or
   *  4. As an alias to the 'id' field on the B2BAppAccessRightSet List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  Link to B2BApp  */
    app?: Maybe<B2BApp>
    canExecuteRegisterMetersReadings?: Maybe<Scalars['Boolean']['output']>
    canManageContacts?: Maybe<Scalars['Boolean']['output']>
    canManageMeterReadings?: Maybe<Scalars['Boolean']['output']>
    canManageMeters?: Maybe<Scalars['Boolean']['output']>
    /**  Currently, this field is read-only. You cannot get manage access for the specified schema.  */
    canManageOrganizations?: Maybe<Scalars['Boolean']['output']>
    canManageProperties?: Maybe<Scalars['Boolean']['output']>
    canManageTicketCommentFiles?: Maybe<Scalars['Boolean']['output']>
    canManageTicketComments?: Maybe<Scalars['Boolean']['output']>
    canManageTicketFiles?: Maybe<Scalars['Boolean']['output']>
    canManageTickets?: Maybe<Scalars['Boolean']['output']>
    canReadContacts?: Maybe<Scalars['Boolean']['output']>
    canReadMeterReadings?: Maybe<Scalars['Boolean']['output']>
    canReadMeters?: Maybe<Scalars['Boolean']['output']>
    canReadOrganizations?: Maybe<Scalars['Boolean']['output']>
    canReadProperties?: Maybe<Scalars['Boolean']['output']>
    canReadTicketCommentFiles?: Maybe<Scalars['Boolean']['output']>
    canReadTicketComments?: Maybe<Scalars['Boolean']['output']>
    canReadTicketFiles?: Maybe<Scalars['Boolean']['output']>
    canReadTickets?: Maybe<Scalars['Boolean']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type B2BAppAccessRightSetCreateInput = {
    app?: InputMaybe<B2BAppRelateToOneInput>
    canExecuteRegisterMetersReadings?: InputMaybe<Scalars['Boolean']['input']>
    canManageContacts?: InputMaybe<Scalars['Boolean']['input']>
    canManageMeterReadings?: InputMaybe<Scalars['Boolean']['input']>
    canManageMeters?: InputMaybe<Scalars['Boolean']['input']>
    canManageOrganizations?: InputMaybe<Scalars['Boolean']['input']>
    canManageProperties?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketCommentFiles?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketComments?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketFiles?: InputMaybe<Scalars['Boolean']['input']>
    canManageTickets?: InputMaybe<Scalars['Boolean']['input']>
    canReadContacts?: InputMaybe<Scalars['Boolean']['input']>
    canReadMeterReadings?: InputMaybe<Scalars['Boolean']['input']>
    canReadMeters?: InputMaybe<Scalars['Boolean']['input']>
    canReadOrganizations?: InputMaybe<Scalars['Boolean']['input']>
    canReadProperties?: InputMaybe<Scalars['Boolean']['input']>
    canReadTicketCommentFiles?: InputMaybe<Scalars['Boolean']['input']>
    canReadTicketComments?: InputMaybe<Scalars['Boolean']['input']>
    canReadTicketFiles?: InputMaybe<Scalars['Boolean']['input']>
    canReadTickets?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type B2BAppAccessRightSetHistoryRecord = {
    __typename?: 'B2BAppAccessRightSetHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2BAppAccessRightSetHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the B2BAppAccessRightSetHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the B2BAppAccessRightSetHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the B2BAppAccessRightSetHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    app?: Maybe<Scalars['String']['output']>
    canExecuteRegisterMetersReadings?: Maybe<Scalars['Boolean']['output']>
    canManageContacts?: Maybe<Scalars['Boolean']['output']>
    canManageMeterReadings?: Maybe<Scalars['Boolean']['output']>
    canManageMeters?: Maybe<Scalars['Boolean']['output']>
    canManageOrganizations?: Maybe<Scalars['Boolean']['output']>
    canManageProperties?: Maybe<Scalars['Boolean']['output']>
    canManageTicketCommentFiles?: Maybe<Scalars['Boolean']['output']>
    canManageTicketComments?: Maybe<Scalars['Boolean']['output']>
    canManageTicketFiles?: Maybe<Scalars['Boolean']['output']>
    canManageTickets?: Maybe<Scalars['Boolean']['output']>
    canReadContacts?: Maybe<Scalars['Boolean']['output']>
    canReadMeterReadings?: Maybe<Scalars['Boolean']['output']>
    canReadMeters?: Maybe<Scalars['Boolean']['output']>
    canReadOrganizations?: Maybe<Scalars['Boolean']['output']>
    canReadProperties?: Maybe<Scalars['Boolean']['output']>
    canReadTicketCommentFiles?: Maybe<Scalars['Boolean']['output']>
    canReadTicketComments?: Maybe<Scalars['Boolean']['output']>
    canReadTicketFiles?: Maybe<Scalars['Boolean']['output']>
    canReadTickets?: Maybe<Scalars['Boolean']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<B2BAppAccessRightSetHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type B2BAppAccessRightSetHistoryRecordCreateInput = {
    app?: InputMaybe<Scalars['String']['input']>
    canExecuteRegisterMetersReadings?: InputMaybe<Scalars['Boolean']['input']>
    canManageContacts?: InputMaybe<Scalars['Boolean']['input']>
    canManageMeterReadings?: InputMaybe<Scalars['Boolean']['input']>
    canManageMeters?: InputMaybe<Scalars['Boolean']['input']>
    canManageOrganizations?: InputMaybe<Scalars['Boolean']['input']>
    canManageProperties?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketCommentFiles?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketComments?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketFiles?: InputMaybe<Scalars['Boolean']['input']>
    canManageTickets?: InputMaybe<Scalars['Boolean']['input']>
    canReadContacts?: InputMaybe<Scalars['Boolean']['input']>
    canReadMeterReadings?: InputMaybe<Scalars['Boolean']['input']>
    canReadMeters?: InputMaybe<Scalars['Boolean']['input']>
    canReadOrganizations?: InputMaybe<Scalars['Boolean']['input']>
    canReadProperties?: InputMaybe<Scalars['Boolean']['input']>
    canReadTicketCommentFiles?: InputMaybe<Scalars['Boolean']['input']>
    canReadTicketComments?: InputMaybe<Scalars['Boolean']['input']>
    canReadTicketFiles?: InputMaybe<Scalars['Boolean']['input']>
    canReadTickets?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<B2BAppAccessRightSetHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum B2BAppAccessRightSetHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type B2BAppAccessRightSetHistoryRecordUpdateInput = {
    app?: InputMaybe<Scalars['String']['input']>
    canExecuteRegisterMetersReadings?: InputMaybe<Scalars['Boolean']['input']>
    canManageContacts?: InputMaybe<Scalars['Boolean']['input']>
    canManageMeterReadings?: InputMaybe<Scalars['Boolean']['input']>
    canManageMeters?: InputMaybe<Scalars['Boolean']['input']>
    canManageOrganizations?: InputMaybe<Scalars['Boolean']['input']>
    canManageProperties?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketCommentFiles?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketComments?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketFiles?: InputMaybe<Scalars['Boolean']['input']>
    canManageTickets?: InputMaybe<Scalars['Boolean']['input']>
    canReadContacts?: InputMaybe<Scalars['Boolean']['input']>
    canReadMeterReadings?: InputMaybe<Scalars['Boolean']['input']>
    canReadMeters?: InputMaybe<Scalars['Boolean']['input']>
    canReadOrganizations?: InputMaybe<Scalars['Boolean']['input']>
    canReadProperties?: InputMaybe<Scalars['Boolean']['input']>
    canReadTicketCommentFiles?: InputMaybe<Scalars['Boolean']['input']>
    canReadTicketComments?: InputMaybe<Scalars['Boolean']['input']>
    canReadTicketFiles?: InputMaybe<Scalars['Boolean']['input']>
    canReadTickets?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<B2BAppAccessRightSetHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type B2BAppAccessRightSetHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<B2BAppAccessRightSetHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<B2BAppAccessRightSetHistoryRecordWhereInput>>>
    app?: InputMaybe<Scalars['String']['input']>
    app_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    app_not?: InputMaybe<Scalars['String']['input']>
    app_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    canExecuteRegisterMetersReadings?: InputMaybe<Scalars['Boolean']['input']>
    canExecuteRegisterMetersReadings_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageContacts?: InputMaybe<Scalars['Boolean']['input']>
    canManageContacts_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageMeterReadings?: InputMaybe<Scalars['Boolean']['input']>
    canManageMeterReadings_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageMeters?: InputMaybe<Scalars['Boolean']['input']>
    canManageMeters_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageOrganizations?: InputMaybe<Scalars['Boolean']['input']>
    canManageOrganizations_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageProperties?: InputMaybe<Scalars['Boolean']['input']>
    canManageProperties_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketCommentFiles?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketCommentFiles_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketComments?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketComments_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketFiles?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketFiles_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageTickets?: InputMaybe<Scalars['Boolean']['input']>
    canManageTickets_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadContacts?: InputMaybe<Scalars['Boolean']['input']>
    canReadContacts_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadMeterReadings?: InputMaybe<Scalars['Boolean']['input']>
    canReadMeterReadings_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadMeters?: InputMaybe<Scalars['Boolean']['input']>
    canReadMeters_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadOrganizations?: InputMaybe<Scalars['Boolean']['input']>
    canReadOrganizations_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadProperties?: InputMaybe<Scalars['Boolean']['input']>
    canReadProperties_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadTicketCommentFiles?: InputMaybe<Scalars['Boolean']['input']>
    canReadTicketCommentFiles_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadTicketComments?: InputMaybe<Scalars['Boolean']['input']>
    canReadTicketComments_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadTicketFiles?: InputMaybe<Scalars['Boolean']['input']>
    canReadTicketFiles_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadTickets?: InputMaybe<Scalars['Boolean']['input']>
    canReadTickets_not?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<B2BAppAccessRightSetHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<B2BAppAccessRightSetHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<B2BAppAccessRightSetHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<B2BAppAccessRightSetHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type B2BAppAccessRightSetHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type B2BAppAccessRightSetHistoryRecordsCreateInput = {
    data?: InputMaybe<B2BAppAccessRightSetHistoryRecordCreateInput>
}

export type B2BAppAccessRightSetHistoryRecordsUpdateInput = {
    data?: InputMaybe<B2BAppAccessRightSetHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type B2BAppAccessRightSetRelateToOneInput = {
    connect?: InputMaybe<B2BAppAccessRightSetWhereUniqueInput>
    create?: InputMaybe<B2BAppAccessRightSetCreateInput>
    disconnect?: InputMaybe<B2BAppAccessRightSetWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type B2BAppAccessRightSetUpdateInput = {
    app?: InputMaybe<B2BAppRelateToOneInput>
    canExecuteRegisterMetersReadings?: InputMaybe<Scalars['Boolean']['input']>
    canManageContacts?: InputMaybe<Scalars['Boolean']['input']>
    canManageMeterReadings?: InputMaybe<Scalars['Boolean']['input']>
    canManageMeters?: InputMaybe<Scalars['Boolean']['input']>
    canManageOrganizations?: InputMaybe<Scalars['Boolean']['input']>
    canManageProperties?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketCommentFiles?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketComments?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketFiles?: InputMaybe<Scalars['Boolean']['input']>
    canManageTickets?: InputMaybe<Scalars['Boolean']['input']>
    canReadContacts?: InputMaybe<Scalars['Boolean']['input']>
    canReadMeterReadings?: InputMaybe<Scalars['Boolean']['input']>
    canReadMeters?: InputMaybe<Scalars['Boolean']['input']>
    canReadOrganizations?: InputMaybe<Scalars['Boolean']['input']>
    canReadProperties?: InputMaybe<Scalars['Boolean']['input']>
    canReadTicketCommentFiles?: InputMaybe<Scalars['Boolean']['input']>
    canReadTicketComments?: InputMaybe<Scalars['Boolean']['input']>
    canReadTicketFiles?: InputMaybe<Scalars['Boolean']['input']>
    canReadTickets?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type B2BAppAccessRightSetWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<B2BAppAccessRightSetWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<B2BAppAccessRightSetWhereInput>>>
    app?: InputMaybe<B2BAppWhereInput>
    app_is_null?: InputMaybe<Scalars['Boolean']['input']>
    canExecuteRegisterMetersReadings?: InputMaybe<Scalars['Boolean']['input']>
    canExecuteRegisterMetersReadings_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageContacts?: InputMaybe<Scalars['Boolean']['input']>
    canManageContacts_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageMeterReadings?: InputMaybe<Scalars['Boolean']['input']>
    canManageMeterReadings_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageMeters?: InputMaybe<Scalars['Boolean']['input']>
    canManageMeters_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageOrganizations?: InputMaybe<Scalars['Boolean']['input']>
    canManageOrganizations_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageProperties?: InputMaybe<Scalars['Boolean']['input']>
    canManageProperties_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketCommentFiles?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketCommentFiles_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketComments?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketComments_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketFiles?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketFiles_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageTickets?: InputMaybe<Scalars['Boolean']['input']>
    canManageTickets_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadContacts?: InputMaybe<Scalars['Boolean']['input']>
    canReadContacts_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadMeterReadings?: InputMaybe<Scalars['Boolean']['input']>
    canReadMeterReadings_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadMeters?: InputMaybe<Scalars['Boolean']['input']>
    canReadMeters_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadOrganizations?: InputMaybe<Scalars['Boolean']['input']>
    canReadOrganizations_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadProperties?: InputMaybe<Scalars['Boolean']['input']>
    canReadProperties_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadTicketCommentFiles?: InputMaybe<Scalars['Boolean']['input']>
    canReadTicketCommentFiles_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadTicketComments?: InputMaybe<Scalars['Boolean']['input']>
    canReadTicketComments_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadTicketFiles?: InputMaybe<Scalars['Boolean']['input']>
    canReadTicketFiles_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadTickets?: InputMaybe<Scalars['Boolean']['input']>
    canReadTickets_not?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type B2BAppAccessRightSetWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type B2BAppAccessRightSetsCreateInput = {
    data?: InputMaybe<B2BAppAccessRightSetCreateInput>
}

export type B2BAppAccessRightSetsUpdateInput = {
    data?: InputMaybe<B2BAppAccessRightSetUpdateInput>
    id: Scalars['ID']['input']
}

export type B2BAppAccessRightUpdateInput = {
    accessRightSet?: InputMaybe<B2BAppAccessRightSetRelateToOneInput>
    app?: InputMaybe<B2BAppRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type B2BAppAccessRightWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<B2BAppAccessRightWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<B2BAppAccessRightWhereInput>>>
    accessRightSet?: InputMaybe<B2BAppAccessRightSetWhereInput>
    accessRightSet_is_null?: InputMaybe<Scalars['Boolean']['input']>
    app?: InputMaybe<B2BAppWhereInput>
    app_is_null?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    user?: InputMaybe<UserWhereInput>
    user_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type B2BAppAccessRightWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type B2BAppAccessRightsCreateInput = {
    data?: InputMaybe<B2BAppAccessRightCreateInput>
}

export type B2BAppAccessRightsUpdateInput = {
    data?: InputMaybe<B2BAppAccessRightUpdateInput>
    id: Scalars['ID']['input']
}

export enum B2BAppCategoryType {
    BusinessDevelopment = 'BUSINESS_DEVELOPMENT',
    Dispatching = 'DISPATCHING',
    Finance = 'FINANCE',
    Gis = 'GIS',
    Other = 'OTHER',
    SmartHome = 'SMART_HOME',
}

/**  Object which connects B2B App and Organization. Used to determine if app is connected or not, and store settings / state of app for specific organization  */
export type B2BAppContext = {
    __typename?: 'B2BAppContext'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2BAppContext List config, or
   *  2. As an alias to the field set on 'labelField' in the B2BAppContext List config, or
   *  3. As an alias to a 'name' field on the B2BAppContext List (if one exists), or
   *  4. As an alias to the 'id' field on the B2BAppContext List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  B2B App  */
    app?: Maybe<B2BApp>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  Data that is required for specified app to work with specified organization. Filled by app's service account / support and can have any JSON structure  */
    meta?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Organization  */
    organization?: Maybe<Organization>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Status of B2BApp connection, Can be one of the following: ["InProgress", "Error", "Finished"]. If not specified explicitly on creation, uses default value from related B2BApp model  */
    status?: Maybe<B2BAppContextStatusType>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type B2BAppContextCreateInput = {
    app?: InputMaybe<B2BAppRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    status?: InputMaybe<B2BAppContextStatusType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum B2BAppContextDefaultStatusType {
    Error = 'Error',
    Finished = 'Finished',
    InProgress = 'InProgress',
}

/**  A keystone list  */
export type B2BAppContextHistoryRecord = {
    __typename?: 'B2BAppContextHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2BAppContextHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the B2BAppContextHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the B2BAppContextHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the B2BAppContextHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    app?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<B2BAppContextHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    meta?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    status?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type B2BAppContextHistoryRecordCreateInput = {
    app?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<B2BAppContextHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum B2BAppContextHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type B2BAppContextHistoryRecordUpdateInput = {
    app?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<B2BAppContextHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type B2BAppContextHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<B2BAppContextHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<B2BAppContextHistoryRecordWhereInput>>>
    app?: InputMaybe<Scalars['String']['input']>
    app_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    app_not?: InputMaybe<Scalars['String']['input']>
    app_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<B2BAppContextHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<B2BAppContextHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<B2BAppContextHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<B2BAppContextHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    status?: InputMaybe<Scalars['String']['input']>
    status_contains?: InputMaybe<Scalars['String']['input']>
    status_contains_i?: InputMaybe<Scalars['String']['input']>
    status_ends_with?: InputMaybe<Scalars['String']['input']>
    status_ends_with_i?: InputMaybe<Scalars['String']['input']>
    status_i?: InputMaybe<Scalars['String']['input']>
    status_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status_not?: InputMaybe<Scalars['String']['input']>
    status_not_contains?: InputMaybe<Scalars['String']['input']>
    status_not_contains_i?: InputMaybe<Scalars['String']['input']>
    status_not_ends_with?: InputMaybe<Scalars['String']['input']>
    status_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    status_not_i?: InputMaybe<Scalars['String']['input']>
    status_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status_not_starts_with?: InputMaybe<Scalars['String']['input']>
    status_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    status_starts_with?: InputMaybe<Scalars['String']['input']>
    status_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type B2BAppContextHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type B2BAppContextHistoryRecordsCreateInput = {
    data?: InputMaybe<B2BAppContextHistoryRecordCreateInput>
}

export type B2BAppContextHistoryRecordsUpdateInput = {
    data?: InputMaybe<B2BAppContextHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type B2BAppContextRelateToOneInput = {
    connect?: InputMaybe<B2BAppContextWhereUniqueInput>
    create?: InputMaybe<B2BAppContextCreateInput>
    disconnect?: InputMaybe<B2BAppContextWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export enum B2BAppContextStatusType {
    Error = 'Error',
    Finished = 'Finished',
    InProgress = 'InProgress',
}

export type B2BAppContextUpdateInput = {
    app?: InputMaybe<B2BAppRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    status?: InputMaybe<B2BAppContextStatusType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type B2BAppContextWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<B2BAppContextWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<B2BAppContextWhereInput>>>
    app?: InputMaybe<B2BAppWhereInput>
    app_is_null?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    status?: InputMaybe<B2BAppContextStatusType>
    status_in?: InputMaybe<Array<InputMaybe<B2BAppContextStatusType>>>
    status_not?: InputMaybe<B2BAppContextStatusType>
    status_not_in?: InputMaybe<Array<InputMaybe<B2BAppContextStatusType>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type B2BAppContextWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type B2BAppContextsCreateInput = {
    data?: InputMaybe<B2BAppContextCreateInput>
}

export type B2BAppContextsUpdateInput = {
    data?: InputMaybe<B2BAppContextUpdateInput>
    id: Scalars['ID']['input']
}

export type B2BAppCreateInput = {
    accessRights?: InputMaybe<B2BAppAccessRightRelateToManyInput>
    appUrl?: InputMaybe<Scalars['String']['input']>
    category?: InputMaybe<B2BAppCategoryType>
    contextDefaultStatus?: InputMaybe<B2BAppContextDefaultStatusType>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    detailedDescription?: InputMaybe<Scalars['String']['input']>
    developer?: InputMaybe<Scalars['String']['input']>
    displayPriority?: InputMaybe<Scalars['Int']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    features?: InputMaybe<Array<B2BAppGlobalFeature>>
    gallery?: InputMaybe<Array<Scalars['String']['input']>>
    hasDynamicTitle?: InputMaybe<Scalars['Boolean']['input']>
    icon?: InputMaybe<B2BAppIconType>
    isGlobal?: InputMaybe<Scalars['Boolean']['input']>
    isHidden?: InputMaybe<Scalars['Boolean']['input']>
    isPublic?: InputMaybe<Scalars['Boolean']['input']>
    label?: InputMaybe<B2BAppLabelType>
    logo?: InputMaybe<Scalars['Upload']['input']>
    menuCategory?: InputMaybe<B2BAppMenuCategoryType>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newsSharingConfig?: InputMaybe<B2BAppNewsSharingConfigRelateToOneInput>
    partnerUrl?: InputMaybe<Scalars['String']['input']>
    price?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    shortDescription?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum B2BAppGlobalFeature {
    AttachCallRecordToTicket = 'AttachCallRecordToTicket',
    PropertyMapGeneration = 'PropertyMapGeneration',
}

/**  A keystone list  */
export type B2BAppHistoryRecord = {
    __typename?: 'B2BAppHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2BAppHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the B2BAppHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the B2BAppHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the B2BAppHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    appUrl?: Maybe<Scalars['String']['output']>
    category?: Maybe<Scalars['String']['output']>
    contextDefaultStatus?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    detailedDescription?: Maybe<Scalars['JSON']['output']>
    developer?: Maybe<Scalars['String']['output']>
    displayPriority?: Maybe<Scalars['Int']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    features?: Maybe<Scalars['JSON']['output']>
    gallery?: Maybe<Scalars['JSON']['output']>
    hasDynamicTitle?: Maybe<Scalars['Boolean']['output']>
    history_action?: Maybe<B2BAppHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    icon?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    isGlobal?: Maybe<Scalars['Boolean']['output']>
    isHidden?: Maybe<Scalars['Boolean']['output']>
    isPublic?: Maybe<Scalars['Boolean']['output']>
    label?: Maybe<Scalars['String']['output']>
    logo?: Maybe<Scalars['JSON']['output']>
    menuCategory?: Maybe<Scalars['String']['output']>
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    newsSharingConfig?: Maybe<Scalars['String']['output']>
    partnerUrl?: Maybe<Scalars['String']['output']>
    price?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    shortDescription?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type B2BAppHistoryRecordCreateInput = {
    appUrl?: InputMaybe<Scalars['String']['input']>
    category?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    detailedDescription?: InputMaybe<Scalars['JSON']['input']>
    developer?: InputMaybe<Scalars['String']['input']>
    displayPriority?: InputMaybe<Scalars['Int']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    features?: InputMaybe<Scalars['JSON']['input']>
    gallery?: InputMaybe<Scalars['JSON']['input']>
    hasDynamicTitle?: InputMaybe<Scalars['Boolean']['input']>
    history_action?: InputMaybe<B2BAppHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    icon?: InputMaybe<Scalars['String']['input']>
    isGlobal?: InputMaybe<Scalars['Boolean']['input']>
    isHidden?: InputMaybe<Scalars['Boolean']['input']>
    isPublic?: InputMaybe<Scalars['Boolean']['input']>
    label?: InputMaybe<Scalars['String']['input']>
    logo?: InputMaybe<Scalars['JSON']['input']>
    menuCategory?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newsSharingConfig?: InputMaybe<Scalars['String']['input']>
    partnerUrl?: InputMaybe<Scalars['String']['input']>
    price?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    shortDescription?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum B2BAppHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type B2BAppHistoryRecordUpdateInput = {
    appUrl?: InputMaybe<Scalars['String']['input']>
    category?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    detailedDescription?: InputMaybe<Scalars['JSON']['input']>
    developer?: InputMaybe<Scalars['String']['input']>
    displayPriority?: InputMaybe<Scalars['Int']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    features?: InputMaybe<Scalars['JSON']['input']>
    gallery?: InputMaybe<Scalars['JSON']['input']>
    hasDynamicTitle?: InputMaybe<Scalars['Boolean']['input']>
    history_action?: InputMaybe<B2BAppHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    icon?: InputMaybe<Scalars['String']['input']>
    isGlobal?: InputMaybe<Scalars['Boolean']['input']>
    isHidden?: InputMaybe<Scalars['Boolean']['input']>
    isPublic?: InputMaybe<Scalars['Boolean']['input']>
    label?: InputMaybe<Scalars['String']['input']>
    logo?: InputMaybe<Scalars['JSON']['input']>
    menuCategory?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newsSharingConfig?: InputMaybe<Scalars['String']['input']>
    partnerUrl?: InputMaybe<Scalars['String']['input']>
    price?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    shortDescription?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type B2BAppHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<B2BAppHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<B2BAppHistoryRecordWhereInput>>>
    appUrl?: InputMaybe<Scalars['String']['input']>
    appUrl_contains?: InputMaybe<Scalars['String']['input']>
    appUrl_contains_i?: InputMaybe<Scalars['String']['input']>
    appUrl_ends_with?: InputMaybe<Scalars['String']['input']>
    appUrl_ends_with_i?: InputMaybe<Scalars['String']['input']>
    appUrl_i?: InputMaybe<Scalars['String']['input']>
    appUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    appUrl_not?: InputMaybe<Scalars['String']['input']>
    appUrl_not_contains?: InputMaybe<Scalars['String']['input']>
    appUrl_not_contains_i?: InputMaybe<Scalars['String']['input']>
    appUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
    appUrl_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    appUrl_not_i?: InputMaybe<Scalars['String']['input']>
    appUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    appUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
    appUrl_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    appUrl_starts_with?: InputMaybe<Scalars['String']['input']>
    appUrl_starts_with_i?: InputMaybe<Scalars['String']['input']>
    category?: InputMaybe<Scalars['String']['input']>
    category_contains?: InputMaybe<Scalars['String']['input']>
    category_contains_i?: InputMaybe<Scalars['String']['input']>
    category_ends_with?: InputMaybe<Scalars['String']['input']>
    category_ends_with_i?: InputMaybe<Scalars['String']['input']>
    category_i?: InputMaybe<Scalars['String']['input']>
    category_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    category_not?: InputMaybe<Scalars['String']['input']>
    category_not_contains?: InputMaybe<Scalars['String']['input']>
    category_not_contains_i?: InputMaybe<Scalars['String']['input']>
    category_not_ends_with?: InputMaybe<Scalars['String']['input']>
    category_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    category_not_i?: InputMaybe<Scalars['String']['input']>
    category_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    category_not_starts_with?: InputMaybe<Scalars['String']['input']>
    category_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    category_starts_with?: InputMaybe<Scalars['String']['input']>
    category_starts_with_i?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus_contains?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus_contains_i?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus_ends_with?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus_ends_with_i?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus_i?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    contextDefaultStatus_not?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus_not_contains?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus_not_contains_i?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus_not_ends_with?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus_not_i?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    contextDefaultStatus_not_starts_with?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus_starts_with?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus_starts_with_i?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    detailedDescription?: InputMaybe<Scalars['JSON']['input']>
    detailedDescription_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    detailedDescription_not?: InputMaybe<Scalars['JSON']['input']>
    detailedDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    developer?: InputMaybe<Scalars['String']['input']>
    developer_contains?: InputMaybe<Scalars['String']['input']>
    developer_contains_i?: InputMaybe<Scalars['String']['input']>
    developer_ends_with?: InputMaybe<Scalars['String']['input']>
    developer_ends_with_i?: InputMaybe<Scalars['String']['input']>
    developer_i?: InputMaybe<Scalars['String']['input']>
    developer_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    developer_not?: InputMaybe<Scalars['String']['input']>
    developer_not_contains?: InputMaybe<Scalars['String']['input']>
    developer_not_contains_i?: InputMaybe<Scalars['String']['input']>
    developer_not_ends_with?: InputMaybe<Scalars['String']['input']>
    developer_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    developer_not_i?: InputMaybe<Scalars['String']['input']>
    developer_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    developer_not_starts_with?: InputMaybe<Scalars['String']['input']>
    developer_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    developer_starts_with?: InputMaybe<Scalars['String']['input']>
    developer_starts_with_i?: InputMaybe<Scalars['String']['input']>
    displayPriority?: InputMaybe<Scalars['Int']['input']>
    displayPriority_gt?: InputMaybe<Scalars['Int']['input']>
    displayPriority_gte?: InputMaybe<Scalars['Int']['input']>
    displayPriority_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    displayPriority_lt?: InputMaybe<Scalars['Int']['input']>
    displayPriority_lte?: InputMaybe<Scalars['Int']['input']>
    displayPriority_not?: InputMaybe<Scalars['Int']['input']>
    displayPriority_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    features?: InputMaybe<Scalars['JSON']['input']>
    features_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    features_not?: InputMaybe<Scalars['JSON']['input']>
    features_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    gallery?: InputMaybe<Scalars['JSON']['input']>
    gallery_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    gallery_not?: InputMaybe<Scalars['JSON']['input']>
    gallery_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    hasDynamicTitle?: InputMaybe<Scalars['Boolean']['input']>
    hasDynamicTitle_not?: InputMaybe<Scalars['Boolean']['input']>
    history_action?: InputMaybe<B2BAppHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<B2BAppHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<B2BAppHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<B2BAppHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    icon?: InputMaybe<Scalars['String']['input']>
    icon_contains?: InputMaybe<Scalars['String']['input']>
    icon_contains_i?: InputMaybe<Scalars['String']['input']>
    icon_ends_with?: InputMaybe<Scalars['String']['input']>
    icon_ends_with_i?: InputMaybe<Scalars['String']['input']>
    icon_i?: InputMaybe<Scalars['String']['input']>
    icon_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    icon_not?: InputMaybe<Scalars['String']['input']>
    icon_not_contains?: InputMaybe<Scalars['String']['input']>
    icon_not_contains_i?: InputMaybe<Scalars['String']['input']>
    icon_not_ends_with?: InputMaybe<Scalars['String']['input']>
    icon_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    icon_not_i?: InputMaybe<Scalars['String']['input']>
    icon_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    icon_not_starts_with?: InputMaybe<Scalars['String']['input']>
    icon_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    icon_starts_with?: InputMaybe<Scalars['String']['input']>
    icon_starts_with_i?: InputMaybe<Scalars['String']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    isGlobal?: InputMaybe<Scalars['Boolean']['input']>
    isGlobal_not?: InputMaybe<Scalars['Boolean']['input']>
    isHidden?: InputMaybe<Scalars['Boolean']['input']>
    isHidden_not?: InputMaybe<Scalars['Boolean']['input']>
    isPublic?: InputMaybe<Scalars['Boolean']['input']>
    isPublic_not?: InputMaybe<Scalars['Boolean']['input']>
    label?: InputMaybe<Scalars['String']['input']>
    label_contains?: InputMaybe<Scalars['String']['input']>
    label_contains_i?: InputMaybe<Scalars['String']['input']>
    label_ends_with?: InputMaybe<Scalars['String']['input']>
    label_ends_with_i?: InputMaybe<Scalars['String']['input']>
    label_i?: InputMaybe<Scalars['String']['input']>
    label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    label_not?: InputMaybe<Scalars['String']['input']>
    label_not_contains?: InputMaybe<Scalars['String']['input']>
    label_not_contains_i?: InputMaybe<Scalars['String']['input']>
    label_not_ends_with?: InputMaybe<Scalars['String']['input']>
    label_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    label_not_i?: InputMaybe<Scalars['String']['input']>
    label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    label_not_starts_with?: InputMaybe<Scalars['String']['input']>
    label_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    label_starts_with?: InputMaybe<Scalars['String']['input']>
    label_starts_with_i?: InputMaybe<Scalars['String']['input']>
    logo?: InputMaybe<Scalars['JSON']['input']>
    logo_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    logo_not?: InputMaybe<Scalars['JSON']['input']>
    logo_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    menuCategory?: InputMaybe<Scalars['String']['input']>
    menuCategory_contains?: InputMaybe<Scalars['String']['input']>
    menuCategory_contains_i?: InputMaybe<Scalars['String']['input']>
    menuCategory_ends_with?: InputMaybe<Scalars['String']['input']>
    menuCategory_ends_with_i?: InputMaybe<Scalars['String']['input']>
    menuCategory_i?: InputMaybe<Scalars['String']['input']>
    menuCategory_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    menuCategory_not?: InputMaybe<Scalars['String']['input']>
    menuCategory_not_contains?: InputMaybe<Scalars['String']['input']>
    menuCategory_not_contains_i?: InputMaybe<Scalars['String']['input']>
    menuCategory_not_ends_with?: InputMaybe<Scalars['String']['input']>
    menuCategory_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    menuCategory_not_i?: InputMaybe<Scalars['String']['input']>
    menuCategory_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    menuCategory_not_starts_with?: InputMaybe<Scalars['String']['input']>
    menuCategory_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    menuCategory_starts_with?: InputMaybe<Scalars['String']['input']>
    menuCategory_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newsSharingConfig?: InputMaybe<Scalars['String']['input']>
    newsSharingConfig_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newsSharingConfig_not?: InputMaybe<Scalars['String']['input']>
    newsSharingConfig_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    partnerUrl?: InputMaybe<Scalars['String']['input']>
    partnerUrl_contains?: InputMaybe<Scalars['String']['input']>
    partnerUrl_contains_i?: InputMaybe<Scalars['String']['input']>
    partnerUrl_ends_with?: InputMaybe<Scalars['String']['input']>
    partnerUrl_ends_with_i?: InputMaybe<Scalars['String']['input']>
    partnerUrl_i?: InputMaybe<Scalars['String']['input']>
    partnerUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    partnerUrl_not?: InputMaybe<Scalars['String']['input']>
    partnerUrl_not_contains?: InputMaybe<Scalars['String']['input']>
    partnerUrl_not_contains_i?: InputMaybe<Scalars['String']['input']>
    partnerUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
    partnerUrl_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    partnerUrl_not_i?: InputMaybe<Scalars['String']['input']>
    partnerUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    partnerUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
    partnerUrl_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    partnerUrl_starts_with?: InputMaybe<Scalars['String']['input']>
    partnerUrl_starts_with_i?: InputMaybe<Scalars['String']['input']>
    price?: InputMaybe<Scalars['String']['input']>
    price_contains?: InputMaybe<Scalars['String']['input']>
    price_contains_i?: InputMaybe<Scalars['String']['input']>
    price_ends_with?: InputMaybe<Scalars['String']['input']>
    price_ends_with_i?: InputMaybe<Scalars['String']['input']>
    price_i?: InputMaybe<Scalars['String']['input']>
    price_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    price_not?: InputMaybe<Scalars['String']['input']>
    price_not_contains?: InputMaybe<Scalars['String']['input']>
    price_not_contains_i?: InputMaybe<Scalars['String']['input']>
    price_not_ends_with?: InputMaybe<Scalars['String']['input']>
    price_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    price_not_i?: InputMaybe<Scalars['String']['input']>
    price_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    price_not_starts_with?: InputMaybe<Scalars['String']['input']>
    price_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    price_starts_with?: InputMaybe<Scalars['String']['input']>
    price_starts_with_i?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    shortDescription?: InputMaybe<Scalars['String']['input']>
    shortDescription_contains?: InputMaybe<Scalars['String']['input']>
    shortDescription_contains_i?: InputMaybe<Scalars['String']['input']>
    shortDescription_ends_with?: InputMaybe<Scalars['String']['input']>
    shortDescription_ends_with_i?: InputMaybe<Scalars['String']['input']>
    shortDescription_i?: InputMaybe<Scalars['String']['input']>
    shortDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    shortDescription_not?: InputMaybe<Scalars['String']['input']>
    shortDescription_not_contains?: InputMaybe<Scalars['String']['input']>
    shortDescription_not_contains_i?: InputMaybe<Scalars['String']['input']>
    shortDescription_not_ends_with?: InputMaybe<Scalars['String']['input']>
    shortDescription_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    shortDescription_not_i?: InputMaybe<Scalars['String']['input']>
    shortDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    shortDescription_not_starts_with?: InputMaybe<Scalars['String']['input']>
    shortDescription_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    shortDescription_starts_with?: InputMaybe<Scalars['String']['input']>
    shortDescription_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type B2BAppHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type B2BAppHistoryRecordsCreateInput = {
    data?: InputMaybe<B2BAppHistoryRecordCreateInput>
}

export type B2BAppHistoryRecordsUpdateInput = {
    data?: InputMaybe<B2BAppHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export enum B2BAppIconType {
    AlertCircle = 'AlertCircle',
    ArrowLeft = 'ArrowLeft',
    ArrowRight = 'ArrowRight',
    BarChartHorizontal = 'BarChartHorizontal',
    BarChartVertical = 'BarChartVertical',
    Bill = 'Bill',
    Briefcase = 'Briefcase',
    Building = 'Building',
    CalendarDays = 'CalendarDays',
    Car = 'Car',
    Check = 'Check',
    CheckCircle = 'CheckCircle',
    CheckSquare = 'CheckSquare',
    ChevronDown = 'ChevronDown',
    ChevronLeft = 'ChevronLeft',
    ChevronRight = 'ChevronRight',
    ChevronUp = 'ChevronUp',
    CircleEllipsis = 'CircleEllipsis',
    Clock = 'Clock',
    Close = 'Close',
    Contacts = 'Contacts',
    Copy = 'Copy',
    CreditCard = 'CreditCard',
    DoorOpen = 'DoorOpen',
    Doors = 'Doors',
    Download = 'Download',
    Edit = 'Edit',
    Employee = 'Employee',
    ExternalLink = 'ExternalLink',
    Eye = 'Eye',
    EyeOff = 'EyeOff',
    FileCheck = 'FileCheck',
    FileDown = 'FileDown',
    FileEdit = 'FileEdit',
    FileText = 'FileText',
    FileUp = 'FileUp',
    Filter = 'Filter',
    Flat = 'Flat',
    Floor = 'Floor',
    Frown = 'Frown',
    Globe = 'Globe',
    Guide = 'Guide',
    History = 'History',
    Home = 'Home',
    House = 'House',
    Inbox = 'Inbox',
    Info = 'Info',
    Interfloor = 'Interfloor',
    Key = 'Key',
    Layers = 'Layers',
    LayoutList = 'LayoutList',
    Link = 'Link',
    List = 'List',
    Lock = 'Lock',
    LogOut = 'LogOut',
    Mail = 'Mail',
    Market = 'Market',
    Menu = 'Menu',
    Meters = 'Meters',
    MinusCircle = 'MinusCircle',
    MoreHorizontal = 'MoreHorizontal',
    MoreVertical = 'MoreVertical',
    NewAppeal = 'NewAppeal',
    Newspaper = 'Newspaper',
    OnOff = 'OnOff',
    Paperclip = 'Paperclip',
    Parking = 'Parking',
    Pause = 'Pause',
    PauseFilled = 'PauseFilled',
    Phone = 'Phone',
    PhoneIncoming = 'PhoneIncoming',
    PhoneOutgoing = 'PhoneOutgoing',
    PieChart = 'PieChart',
    Play = 'Play',
    PlayFilled = 'PlayFilled',
    Plus = 'Plus',
    PlusCircle = 'PlusCircle',
    Print = 'Print',
    QuestionCircle = 'QuestionCircle',
    Readings = 'Readings',
    RefreshCw = 'RefreshCw',
    Rocket = 'Rocket',
    Ruble = 'Ruble',
    Sber = 'Sber',
    Search = 'Search',
    Send = 'Send',
    Services = 'Services',
    Settings = 'Settings',
    Share = 'Share',
    Sheet = 'Sheet',
    Slash = 'Slash',
    SmartHome = 'SmartHome',
    Smartphone = 'Smartphone',
    Smile = 'Smile',
    SortAsc = 'SortAsc',
    SortDesc = 'SortDesc',
    Star = 'Star',
    StarFilled = 'StarFilled',
    Subtitles = 'Subtitles',
    Trash = 'Trash',
    Unlock = 'Unlock',
    User = 'User',
    UserSquare = 'UserSquare',
    Wallet = 'Wallet',
    XCircle = 'XCircle',
}

export enum B2BAppLabelType {
    Discount = 'DISCOUNT',
    Free = 'FREE',
    New = 'NEW',
    Popular = 'POPULAR',
}

export enum B2BAppMenuCategoryType {
    Billing = 'BILLING',
    Communication = 'COMMUNICATION',
    Dashboard = 'DASHBOARD',
    Employees = 'EMPLOYEES',
    Market = 'MARKET',
    Meters = 'METERS',
    Miniapps = 'MINIAPPS',
    Properties = 'PROPERTIES',
    Residents = 'RESIDENTS',
    Settings = 'SETTINGS',
    Tour = 'TOUR',
}

/**  News Sharing B2BApp allow b2b users to share their NewsItem to external source (like Telegram) from /news page  */
export type B2BAppNewsSharingConfig = {
    __typename?: 'B2BAppNewsSharingConfig'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2BAppNewsSharingConfig List config, or
   *  2. As an alias to the field set on 'labelField' in the B2BAppNewsSharingConfig List config, or
   *  3. As an alias to a 'name' field on the B2BAppNewsSharingConfig List (if one exists), or
   *  4. As an alias to the 'id' field on the B2BAppNewsSharingConfig List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    /**  URL that implements customForm. Use only if you need custom NewsItemSharing data structure, for example if . Allows to provide custom UI for sending news. If not provided app will use condo news form  */
    customFormUrl?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  URL that returns number of subscribers for condo scopes. Should implement POST customGetRecipientsCounters method. Allows to provide custom values for recipients counter. If not provided app will use data from getRecipients. If getRecipients is not provided, recipients counter will not be rendered  */
    getRecipientsCountersUrl?: Maybe<Scalars['String']['output']>
    /**  URL that returns chats and/or channels. Should implement POST getRecipients method. If provided Select control with data from this endpoint will be used in /news/create page, If not provided, condo control will be used  */
    getRecipientsUrl?: Maybe<Scalars['String']['output']>
    /**  App icon. For example: Telegram app icon  */
    icon?: Maybe<File>
    id: Scalars['ID']['output']
    /**  Short and simple name of the external source. For example: Telegram  */
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Preview image. For example: Telegram app screenshot  */
    previewPicture?: Maybe<File>
    /**  URL that returns rendered HTML preview of News Item. Used to render NewsItem preview. If not provided, app preview will not be rendered  */
    previewUrl?: Maybe<Scalars['String']['output']>
    /**  URL that publishes NewsItem. Should implement POST publish method. It will be called once news item is ready to be published. Check News domain for reference  */
    publishUrl?: Maybe<Scalars['String']['output']>
    /**  Push notification preferences for each B2BApp. The available options include: notifications are sent only for emergency alerts, all notifications are turned off, or all notifications are enabled  */
    pushNotificationSettings?: Maybe<B2BAppNewsSharingConfigPushNotificationSettingType>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type B2BAppNewsSharingConfigCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    customFormUrl?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    getRecipientsCountersUrl?: InputMaybe<Scalars['String']['input']>
    getRecipientsUrl?: InputMaybe<Scalars['String']['input']>
    icon?: InputMaybe<Scalars['Upload']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    previewPicture?: InputMaybe<Scalars['Upload']['input']>
    previewUrl?: InputMaybe<Scalars['String']['input']>
    publishUrl?: InputMaybe<Scalars['String']['input']>
    pushNotificationSettings?: InputMaybe<B2BAppNewsSharingConfigPushNotificationSettingType>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type B2BAppNewsSharingConfigHistoryRecord = {
    __typename?: 'B2BAppNewsSharingConfigHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2BAppNewsSharingConfigHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the B2BAppNewsSharingConfigHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the B2BAppNewsSharingConfigHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the B2BAppNewsSharingConfigHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    customFormUrl?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    getRecipientsCountersUrl?: Maybe<Scalars['String']['output']>
    getRecipientsUrl?: Maybe<Scalars['String']['output']>
    history_action?: Maybe<B2BAppNewsSharingConfigHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    icon?: Maybe<Scalars['JSON']['output']>
    id: Scalars['ID']['output']
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    previewPicture?: Maybe<Scalars['JSON']['output']>
    previewUrl?: Maybe<Scalars['String']['output']>
    publishUrl?: Maybe<Scalars['String']['output']>
    pushNotificationSettings?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type B2BAppNewsSharingConfigHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    customFormUrl?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    getRecipientsCountersUrl?: InputMaybe<Scalars['String']['input']>
    getRecipientsUrl?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<B2BAppNewsSharingConfigHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    icon?: InputMaybe<Scalars['JSON']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    previewPicture?: InputMaybe<Scalars['JSON']['input']>
    previewUrl?: InputMaybe<Scalars['String']['input']>
    publishUrl?: InputMaybe<Scalars['String']['input']>
    pushNotificationSettings?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum B2BAppNewsSharingConfigHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type B2BAppNewsSharingConfigHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    customFormUrl?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    getRecipientsCountersUrl?: InputMaybe<Scalars['String']['input']>
    getRecipientsUrl?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<B2BAppNewsSharingConfigHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    icon?: InputMaybe<Scalars['JSON']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    previewPicture?: InputMaybe<Scalars['JSON']['input']>
    previewUrl?: InputMaybe<Scalars['String']['input']>
    publishUrl?: InputMaybe<Scalars['String']['input']>
    pushNotificationSettings?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type B2BAppNewsSharingConfigHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<B2BAppNewsSharingConfigHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<B2BAppNewsSharingConfigHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    customFormUrl?: InputMaybe<Scalars['String']['input']>
    customFormUrl_contains?: InputMaybe<Scalars['String']['input']>
    customFormUrl_contains_i?: InputMaybe<Scalars['String']['input']>
    customFormUrl_ends_with?: InputMaybe<Scalars['String']['input']>
    customFormUrl_ends_with_i?: InputMaybe<Scalars['String']['input']>
    customFormUrl_i?: InputMaybe<Scalars['String']['input']>
    customFormUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    customFormUrl_not?: InputMaybe<Scalars['String']['input']>
    customFormUrl_not_contains?: InputMaybe<Scalars['String']['input']>
    customFormUrl_not_contains_i?: InputMaybe<Scalars['String']['input']>
    customFormUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
    customFormUrl_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    customFormUrl_not_i?: InputMaybe<Scalars['String']['input']>
    customFormUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    customFormUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
    customFormUrl_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    customFormUrl_starts_with?: InputMaybe<Scalars['String']['input']>
    customFormUrl_starts_with_i?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    getRecipientsCountersUrl?: InputMaybe<Scalars['String']['input']>
    getRecipientsCountersUrl_contains?: InputMaybe<Scalars['String']['input']>
    getRecipientsCountersUrl_contains_i?: InputMaybe<Scalars['String']['input']>
    getRecipientsCountersUrl_ends_with?: InputMaybe<Scalars['String']['input']>
    getRecipientsCountersUrl_ends_with_i?: InputMaybe<Scalars['String']['input']>
    getRecipientsCountersUrl_i?: InputMaybe<Scalars['String']['input']>
    getRecipientsCountersUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    getRecipientsCountersUrl_not?: InputMaybe<Scalars['String']['input']>
    getRecipientsCountersUrl_not_contains?: InputMaybe<Scalars['String']['input']>
    getRecipientsCountersUrl_not_contains_i?: InputMaybe<Scalars['String']['input']>
    getRecipientsCountersUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
    getRecipientsCountersUrl_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    getRecipientsCountersUrl_not_i?: InputMaybe<Scalars['String']['input']>
    getRecipientsCountersUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    getRecipientsCountersUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
    getRecipientsCountersUrl_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    getRecipientsCountersUrl_starts_with?: InputMaybe<Scalars['String']['input']>
    getRecipientsCountersUrl_starts_with_i?: InputMaybe<Scalars['String']['input']>
    getRecipientsUrl?: InputMaybe<Scalars['String']['input']>
    getRecipientsUrl_contains?: InputMaybe<Scalars['String']['input']>
    getRecipientsUrl_contains_i?: InputMaybe<Scalars['String']['input']>
    getRecipientsUrl_ends_with?: InputMaybe<Scalars['String']['input']>
    getRecipientsUrl_ends_with_i?: InputMaybe<Scalars['String']['input']>
    getRecipientsUrl_i?: InputMaybe<Scalars['String']['input']>
    getRecipientsUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    getRecipientsUrl_not?: InputMaybe<Scalars['String']['input']>
    getRecipientsUrl_not_contains?: InputMaybe<Scalars['String']['input']>
    getRecipientsUrl_not_contains_i?: InputMaybe<Scalars['String']['input']>
    getRecipientsUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
    getRecipientsUrl_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    getRecipientsUrl_not_i?: InputMaybe<Scalars['String']['input']>
    getRecipientsUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    getRecipientsUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
    getRecipientsUrl_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    getRecipientsUrl_starts_with?: InputMaybe<Scalars['String']['input']>
    getRecipientsUrl_starts_with_i?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<B2BAppNewsSharingConfigHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<B2BAppNewsSharingConfigHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<B2BAppNewsSharingConfigHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<B2BAppNewsSharingConfigHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    icon?: InputMaybe<Scalars['JSON']['input']>
    icon_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    icon_not?: InputMaybe<Scalars['JSON']['input']>
    icon_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    previewPicture?: InputMaybe<Scalars['JSON']['input']>
    previewPicture_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    previewPicture_not?: InputMaybe<Scalars['JSON']['input']>
    previewPicture_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    previewUrl?: InputMaybe<Scalars['String']['input']>
    previewUrl_contains?: InputMaybe<Scalars['String']['input']>
    previewUrl_contains_i?: InputMaybe<Scalars['String']['input']>
    previewUrl_ends_with?: InputMaybe<Scalars['String']['input']>
    previewUrl_ends_with_i?: InputMaybe<Scalars['String']['input']>
    previewUrl_i?: InputMaybe<Scalars['String']['input']>
    previewUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    previewUrl_not?: InputMaybe<Scalars['String']['input']>
    previewUrl_not_contains?: InputMaybe<Scalars['String']['input']>
    previewUrl_not_contains_i?: InputMaybe<Scalars['String']['input']>
    previewUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
    previewUrl_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    previewUrl_not_i?: InputMaybe<Scalars['String']['input']>
    previewUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    previewUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
    previewUrl_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    previewUrl_starts_with?: InputMaybe<Scalars['String']['input']>
    previewUrl_starts_with_i?: InputMaybe<Scalars['String']['input']>
    publishUrl?: InputMaybe<Scalars['String']['input']>
    publishUrl_contains?: InputMaybe<Scalars['String']['input']>
    publishUrl_contains_i?: InputMaybe<Scalars['String']['input']>
    publishUrl_ends_with?: InputMaybe<Scalars['String']['input']>
    publishUrl_ends_with_i?: InputMaybe<Scalars['String']['input']>
    publishUrl_i?: InputMaybe<Scalars['String']['input']>
    publishUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    publishUrl_not?: InputMaybe<Scalars['String']['input']>
    publishUrl_not_contains?: InputMaybe<Scalars['String']['input']>
    publishUrl_not_contains_i?: InputMaybe<Scalars['String']['input']>
    publishUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
    publishUrl_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    publishUrl_not_i?: InputMaybe<Scalars['String']['input']>
    publishUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    publishUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
    publishUrl_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    publishUrl_starts_with?: InputMaybe<Scalars['String']['input']>
    publishUrl_starts_with_i?: InputMaybe<Scalars['String']['input']>
    pushNotificationSettings?: InputMaybe<Scalars['String']['input']>
    pushNotificationSettings_contains?: InputMaybe<Scalars['String']['input']>
    pushNotificationSettings_contains_i?: InputMaybe<Scalars['String']['input']>
    pushNotificationSettings_ends_with?: InputMaybe<Scalars['String']['input']>
    pushNotificationSettings_ends_with_i?: InputMaybe<Scalars['String']['input']>
    pushNotificationSettings_i?: InputMaybe<Scalars['String']['input']>
    pushNotificationSettings_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    pushNotificationSettings_not?: InputMaybe<Scalars['String']['input']>
    pushNotificationSettings_not_contains?: InputMaybe<Scalars['String']['input']>
    pushNotificationSettings_not_contains_i?: InputMaybe<Scalars['String']['input']>
    pushNotificationSettings_not_ends_with?: InputMaybe<Scalars['String']['input']>
    pushNotificationSettings_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    pushNotificationSettings_not_i?: InputMaybe<Scalars['String']['input']>
    pushNotificationSettings_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    pushNotificationSettings_not_starts_with?: InputMaybe<Scalars['String']['input']>
    pushNotificationSettings_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    pushNotificationSettings_starts_with?: InputMaybe<Scalars['String']['input']>
    pushNotificationSettings_starts_with_i?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type B2BAppNewsSharingConfigHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type B2BAppNewsSharingConfigHistoryRecordsCreateInput = {
    data?: InputMaybe<B2BAppNewsSharingConfigHistoryRecordCreateInput>
}

export type B2BAppNewsSharingConfigHistoryRecordsUpdateInput = {
    data?: InputMaybe<B2BAppNewsSharingConfigHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export enum B2BAppNewsSharingConfigPushNotificationSettingType {
    Disabled = 'DISABLED',
    Enabled = 'ENABLED',
    OnlyEmergency = 'ONLY_EMERGENCY',
}

export type B2BAppNewsSharingConfigRelateToOneInput = {
    connect?: InputMaybe<B2BAppNewsSharingConfigWhereUniqueInput>
    create?: InputMaybe<B2BAppNewsSharingConfigCreateInput>
    disconnect?: InputMaybe<B2BAppNewsSharingConfigWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type B2BAppNewsSharingConfigUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    customFormUrl?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    getRecipientsCountersUrl?: InputMaybe<Scalars['String']['input']>
    getRecipientsUrl?: InputMaybe<Scalars['String']['input']>
    icon?: InputMaybe<Scalars['Upload']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    previewPicture?: InputMaybe<Scalars['Upload']['input']>
    previewUrl?: InputMaybe<Scalars['String']['input']>
    publishUrl?: InputMaybe<Scalars['String']['input']>
    pushNotificationSettings?: InputMaybe<B2BAppNewsSharingConfigPushNotificationSettingType>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type B2BAppNewsSharingConfigWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<B2BAppNewsSharingConfigWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<B2BAppNewsSharingConfigWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    customFormUrl?: InputMaybe<Scalars['String']['input']>
    customFormUrl_contains?: InputMaybe<Scalars['String']['input']>
    customFormUrl_contains_i?: InputMaybe<Scalars['String']['input']>
    customFormUrl_ends_with?: InputMaybe<Scalars['String']['input']>
    customFormUrl_ends_with_i?: InputMaybe<Scalars['String']['input']>
    customFormUrl_i?: InputMaybe<Scalars['String']['input']>
    customFormUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    customFormUrl_not?: InputMaybe<Scalars['String']['input']>
    customFormUrl_not_contains?: InputMaybe<Scalars['String']['input']>
    customFormUrl_not_contains_i?: InputMaybe<Scalars['String']['input']>
    customFormUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
    customFormUrl_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    customFormUrl_not_i?: InputMaybe<Scalars['String']['input']>
    customFormUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    customFormUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
    customFormUrl_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    customFormUrl_starts_with?: InputMaybe<Scalars['String']['input']>
    customFormUrl_starts_with_i?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    getRecipientsCountersUrl?: InputMaybe<Scalars['String']['input']>
    getRecipientsCountersUrl_contains?: InputMaybe<Scalars['String']['input']>
    getRecipientsCountersUrl_contains_i?: InputMaybe<Scalars['String']['input']>
    getRecipientsCountersUrl_ends_with?: InputMaybe<Scalars['String']['input']>
    getRecipientsCountersUrl_ends_with_i?: InputMaybe<Scalars['String']['input']>
    getRecipientsCountersUrl_i?: InputMaybe<Scalars['String']['input']>
    getRecipientsCountersUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    getRecipientsCountersUrl_not?: InputMaybe<Scalars['String']['input']>
    getRecipientsCountersUrl_not_contains?: InputMaybe<Scalars['String']['input']>
    getRecipientsCountersUrl_not_contains_i?: InputMaybe<Scalars['String']['input']>
    getRecipientsCountersUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
    getRecipientsCountersUrl_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    getRecipientsCountersUrl_not_i?: InputMaybe<Scalars['String']['input']>
    getRecipientsCountersUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    getRecipientsCountersUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
    getRecipientsCountersUrl_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    getRecipientsCountersUrl_starts_with?: InputMaybe<Scalars['String']['input']>
    getRecipientsCountersUrl_starts_with_i?: InputMaybe<Scalars['String']['input']>
    getRecipientsUrl?: InputMaybe<Scalars['String']['input']>
    getRecipientsUrl_contains?: InputMaybe<Scalars['String']['input']>
    getRecipientsUrl_contains_i?: InputMaybe<Scalars['String']['input']>
    getRecipientsUrl_ends_with?: InputMaybe<Scalars['String']['input']>
    getRecipientsUrl_ends_with_i?: InputMaybe<Scalars['String']['input']>
    getRecipientsUrl_i?: InputMaybe<Scalars['String']['input']>
    getRecipientsUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    getRecipientsUrl_not?: InputMaybe<Scalars['String']['input']>
    getRecipientsUrl_not_contains?: InputMaybe<Scalars['String']['input']>
    getRecipientsUrl_not_contains_i?: InputMaybe<Scalars['String']['input']>
    getRecipientsUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
    getRecipientsUrl_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    getRecipientsUrl_not_i?: InputMaybe<Scalars['String']['input']>
    getRecipientsUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    getRecipientsUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
    getRecipientsUrl_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    getRecipientsUrl_starts_with?: InputMaybe<Scalars['String']['input']>
    getRecipientsUrl_starts_with_i?: InputMaybe<Scalars['String']['input']>
    icon?: InputMaybe<Scalars['String']['input']>
    icon_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    icon_not?: InputMaybe<Scalars['String']['input']>
    icon_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    previewPicture?: InputMaybe<Scalars['String']['input']>
    previewPicture_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    previewPicture_not?: InputMaybe<Scalars['String']['input']>
    previewPicture_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    previewUrl?: InputMaybe<Scalars['String']['input']>
    previewUrl_contains?: InputMaybe<Scalars['String']['input']>
    previewUrl_contains_i?: InputMaybe<Scalars['String']['input']>
    previewUrl_ends_with?: InputMaybe<Scalars['String']['input']>
    previewUrl_ends_with_i?: InputMaybe<Scalars['String']['input']>
    previewUrl_i?: InputMaybe<Scalars['String']['input']>
    previewUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    previewUrl_not?: InputMaybe<Scalars['String']['input']>
    previewUrl_not_contains?: InputMaybe<Scalars['String']['input']>
    previewUrl_not_contains_i?: InputMaybe<Scalars['String']['input']>
    previewUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
    previewUrl_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    previewUrl_not_i?: InputMaybe<Scalars['String']['input']>
    previewUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    previewUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
    previewUrl_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    previewUrl_starts_with?: InputMaybe<Scalars['String']['input']>
    previewUrl_starts_with_i?: InputMaybe<Scalars['String']['input']>
    publishUrl?: InputMaybe<Scalars['String']['input']>
    publishUrl_contains?: InputMaybe<Scalars['String']['input']>
    publishUrl_contains_i?: InputMaybe<Scalars['String']['input']>
    publishUrl_ends_with?: InputMaybe<Scalars['String']['input']>
    publishUrl_ends_with_i?: InputMaybe<Scalars['String']['input']>
    publishUrl_i?: InputMaybe<Scalars['String']['input']>
    publishUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    publishUrl_not?: InputMaybe<Scalars['String']['input']>
    publishUrl_not_contains?: InputMaybe<Scalars['String']['input']>
    publishUrl_not_contains_i?: InputMaybe<Scalars['String']['input']>
    publishUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
    publishUrl_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    publishUrl_not_i?: InputMaybe<Scalars['String']['input']>
    publishUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    publishUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
    publishUrl_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    publishUrl_starts_with?: InputMaybe<Scalars['String']['input']>
    publishUrl_starts_with_i?: InputMaybe<Scalars['String']['input']>
    pushNotificationSettings?: InputMaybe<B2BAppNewsSharingConfigPushNotificationSettingType>
    pushNotificationSettings_in?: InputMaybe<Array<InputMaybe<B2BAppNewsSharingConfigPushNotificationSettingType>>>
    pushNotificationSettings_not?: InputMaybe<B2BAppNewsSharingConfigPushNotificationSettingType>
    pushNotificationSettings_not_in?: InputMaybe<Array<InputMaybe<B2BAppNewsSharingConfigPushNotificationSettingType>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type B2BAppNewsSharingConfigWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type B2BAppNewsSharingConfigsCreateInput = {
    data?: InputMaybe<B2BAppNewsSharingConfigCreateInput>
}

export type B2BAppNewsSharingConfigsUpdateInput = {
    data?: InputMaybe<B2BAppNewsSharingConfigUpdateInput>
    id: Scalars['ID']['input']
}

/**  B2BApp permissions that describe additional capabilities within the mini-application and allow the organization administration to manage accesses within the miniapp depending on the employee's role by toggling the B2BAppPermissions of a particular application for each role. By default, for all employees with "canManageB2BApps" in their role, all permissions will be set to True. For all other roles with access to the application the default value is False.  */
export type B2BAppPermission = {
    __typename?: 'B2BAppPermission'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2BAppPermission List config, or
   *  2. As an alias to the field set on 'labelField' in the B2BAppPermission List config, or
   *  3. As an alias to a 'name' field on the B2BAppPermission List (if one exists), or
   *  4. As an alias to the 'id' field on the B2BAppPermission List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  Link to the application to which the permission belongs  */
    app?: Maybe<B2BApp>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  The permission key. Must be written in lowerCamelCase, start with the prefix "can", and answer the question, "What can a user with this permission do?". Examples: "canManagePasses", "canReadPrivateData", etc.  */
    key?: Maybe<Scalars['String']['output']>
    /**  Name of permission. Must be very brief (from 1 to 50 characters) and describe the capabilities that the employee will get with this permission. Must use nouns that indicate actions with objects or sections of the mini-application. Examples: "Passes management", "Access to settings", "Contacts viewing".  */
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type B2BAppPermissionCreateInput = {
    app?: InputMaybe<B2BAppRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    key?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type B2BAppPermissionHistoryRecord = {
    __typename?: 'B2BAppPermissionHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2BAppPermissionHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the B2BAppPermissionHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the B2BAppPermissionHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the B2BAppPermissionHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    app?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<B2BAppPermissionHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    key?: Maybe<Scalars['String']['output']>
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type B2BAppPermissionHistoryRecordCreateInput = {
    app?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<B2BAppPermissionHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    key?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum B2BAppPermissionHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type B2BAppPermissionHistoryRecordUpdateInput = {
    app?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<B2BAppPermissionHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    key?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type B2BAppPermissionHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<B2BAppPermissionHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<B2BAppPermissionHistoryRecordWhereInput>>>
    app?: InputMaybe<Scalars['String']['input']>
    app_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    app_not?: InputMaybe<Scalars['String']['input']>
    app_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<B2BAppPermissionHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<B2BAppPermissionHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<B2BAppPermissionHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<B2BAppPermissionHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    key?: InputMaybe<Scalars['String']['input']>
    key_contains?: InputMaybe<Scalars['String']['input']>
    key_contains_i?: InputMaybe<Scalars['String']['input']>
    key_ends_with?: InputMaybe<Scalars['String']['input']>
    key_ends_with_i?: InputMaybe<Scalars['String']['input']>
    key_i?: InputMaybe<Scalars['String']['input']>
    key_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    key_not?: InputMaybe<Scalars['String']['input']>
    key_not_contains?: InputMaybe<Scalars['String']['input']>
    key_not_contains_i?: InputMaybe<Scalars['String']['input']>
    key_not_ends_with?: InputMaybe<Scalars['String']['input']>
    key_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    key_not_i?: InputMaybe<Scalars['String']['input']>
    key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    key_not_starts_with?: InputMaybe<Scalars['String']['input']>
    key_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    key_starts_with?: InputMaybe<Scalars['String']['input']>
    key_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type B2BAppPermissionHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type B2BAppPermissionHistoryRecordsCreateInput = {
    data?: InputMaybe<B2BAppPermissionHistoryRecordCreateInput>
}

export type B2BAppPermissionHistoryRecordsUpdateInput = {
    data?: InputMaybe<B2BAppPermissionHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type B2BAppPermissionUpdateInput = {
    app?: InputMaybe<B2BAppRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    key?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type B2BAppPermissionWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<B2BAppPermissionWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<B2BAppPermissionWhereInput>>>
    app?: InputMaybe<B2BAppWhereInput>
    app_is_null?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    key?: InputMaybe<Scalars['String']['input']>
    key_contains?: InputMaybe<Scalars['String']['input']>
    key_contains_i?: InputMaybe<Scalars['String']['input']>
    key_ends_with?: InputMaybe<Scalars['String']['input']>
    key_ends_with_i?: InputMaybe<Scalars['String']['input']>
    key_i?: InputMaybe<Scalars['String']['input']>
    key_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    key_not?: InputMaybe<Scalars['String']['input']>
    key_not_contains?: InputMaybe<Scalars['String']['input']>
    key_not_contains_i?: InputMaybe<Scalars['String']['input']>
    key_not_ends_with?: InputMaybe<Scalars['String']['input']>
    key_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    key_not_i?: InputMaybe<Scalars['String']['input']>
    key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    key_not_starts_with?: InputMaybe<Scalars['String']['input']>
    key_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    key_starts_with?: InputMaybe<Scalars['String']['input']>
    key_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type B2BAppPermissionWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type B2BAppPermissionsCreateInput = {
    data?: InputMaybe<B2BAppPermissionCreateInput>
}

export type B2BAppPermissionsUpdateInput = {
    data?: InputMaybe<B2BAppPermissionUpdateInput>
    id: Scalars['ID']['input']
}

/**  Promotion banner which appears in "Miniapps" section of CRM. Used to promote B2BApps, discounts, collaborations and so on  */
export type B2BAppPromoBlock = {
    __typename?: 'B2BAppPromoBlock'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2BAppPromoBlock List config, or
   *  2. As an alias to the field set on 'labelField' in the B2BAppPromoBlock List config, or
   *  3. As an alias to a 'name' field on the B2BAppPromoBlock List (if one exists), or
   *  4. As an alias to the 'id' field on the B2BAppPromoBlock List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  Background color of promo block. Can be hex code or linear gradient.  */
    backgroundColor?: Maybe<Scalars['String']['output']>
    /**  PNG image which appears next to text on large devices  */
    backgroundImage?: Maybe<File>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  Determines whether the banner leads to an external resource or not. If external, interaction with block will lead to new tab. Otherwise user will stay in current tab  */
    external?: Maybe<Scalars['Boolean']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['String']['output']>
    /**  The number used to determine the position of the block among the others. Blocks with higher priority appear earlier. Blocks with the same priority are sorted from newest to oldest. The default value is 1.  */
    priority?: Maybe<Scalars['Int']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Secondary text of promotion banner. Some additional info goes here. Must contain no more than 40 characters per line (including spaces) and no more than 2 lines in total.  */
    subtitle?: Maybe<Scalars['String']['output']>
    /**  Link to the resource that this B2BAppPromoBlock promotes  */
    targetUrl?: Maybe<Scalars['String']['output']>
    /**  Variant of texts inside block. Can be one of the following: [BLACK, WHITE]  */
    textVariant?: Maybe<B2BAppPromoBlockTextVariantType>
    /**  Title of promotion banner. Main catch phrase is placed here. Must contain no more than 27 characters per line (including spaces) and no more than 2 lines in total.  */
    title?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type B2BAppPromoBlockCreateInput = {
    backgroundColor?: InputMaybe<Scalars['String']['input']>
    backgroundImage?: InputMaybe<Scalars['Upload']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    external?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    priority?: InputMaybe<Scalars['Int']['input']>
    sender?: InputMaybe<SenderFieldInput>
    subtitle?: InputMaybe<Scalars['String']['input']>
    targetUrl?: InputMaybe<Scalars['String']['input']>
    textVariant?: InputMaybe<B2BAppPromoBlockTextVariantType>
    title?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type B2BAppPromoBlockHistoryRecord = {
    __typename?: 'B2BAppPromoBlockHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2BAppPromoBlockHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the B2BAppPromoBlockHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the B2BAppPromoBlockHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the B2BAppPromoBlockHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    backgroundColor?: Maybe<Scalars['String']['output']>
    backgroundImage?: Maybe<Scalars['JSON']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    external?: Maybe<Scalars['Boolean']['output']>
    history_action?: Maybe<B2BAppPromoBlockHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['JSON']['output']>
    priority?: Maybe<Scalars['Int']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    subtitle?: Maybe<Scalars['String']['output']>
    targetUrl?: Maybe<Scalars['String']['output']>
    textVariant?: Maybe<Scalars['String']['output']>
    title?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type B2BAppPromoBlockHistoryRecordCreateInput = {
    backgroundColor?: InputMaybe<Scalars['String']['input']>
    backgroundImage?: InputMaybe<Scalars['JSON']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    external?: InputMaybe<Scalars['Boolean']['input']>
    history_action?: InputMaybe<B2BAppPromoBlockHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    priority?: InputMaybe<Scalars['Int']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    subtitle?: InputMaybe<Scalars['String']['input']>
    targetUrl?: InputMaybe<Scalars['String']['input']>
    textVariant?: InputMaybe<Scalars['String']['input']>
    title?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum B2BAppPromoBlockHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type B2BAppPromoBlockHistoryRecordUpdateInput = {
    backgroundColor?: InputMaybe<Scalars['String']['input']>
    backgroundImage?: InputMaybe<Scalars['JSON']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    external?: InputMaybe<Scalars['Boolean']['input']>
    history_action?: InputMaybe<B2BAppPromoBlockHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    priority?: InputMaybe<Scalars['Int']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    subtitle?: InputMaybe<Scalars['String']['input']>
    targetUrl?: InputMaybe<Scalars['String']['input']>
    textVariant?: InputMaybe<Scalars['String']['input']>
    title?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type B2BAppPromoBlockHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<B2BAppPromoBlockHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<B2BAppPromoBlockHistoryRecordWhereInput>>>
    backgroundColor?: InputMaybe<Scalars['String']['input']>
    backgroundColor_contains?: InputMaybe<Scalars['String']['input']>
    backgroundColor_contains_i?: InputMaybe<Scalars['String']['input']>
    backgroundColor_ends_with?: InputMaybe<Scalars['String']['input']>
    backgroundColor_ends_with_i?: InputMaybe<Scalars['String']['input']>
    backgroundColor_i?: InputMaybe<Scalars['String']['input']>
    backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    backgroundColor_not?: InputMaybe<Scalars['String']['input']>
    backgroundColor_not_contains?: InputMaybe<Scalars['String']['input']>
    backgroundColor_not_contains_i?: InputMaybe<Scalars['String']['input']>
    backgroundColor_not_ends_with?: InputMaybe<Scalars['String']['input']>
    backgroundColor_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    backgroundColor_not_i?: InputMaybe<Scalars['String']['input']>
    backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    backgroundColor_not_starts_with?: InputMaybe<Scalars['String']['input']>
    backgroundColor_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    backgroundColor_starts_with?: InputMaybe<Scalars['String']['input']>
    backgroundColor_starts_with_i?: InputMaybe<Scalars['String']['input']>
    backgroundImage?: InputMaybe<Scalars['JSON']['input']>
    backgroundImage_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    backgroundImage_not?: InputMaybe<Scalars['JSON']['input']>
    backgroundImage_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    external?: InputMaybe<Scalars['Boolean']['input']>
    external_not?: InputMaybe<Scalars['Boolean']['input']>
    history_action?: InputMaybe<B2BAppPromoBlockHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<B2BAppPromoBlockHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<B2BAppPromoBlockHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<B2BAppPromoBlockHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    priority?: InputMaybe<Scalars['Int']['input']>
    priority_gt?: InputMaybe<Scalars['Int']['input']>
    priority_gte?: InputMaybe<Scalars['Int']['input']>
    priority_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    priority_lt?: InputMaybe<Scalars['Int']['input']>
    priority_lte?: InputMaybe<Scalars['Int']['input']>
    priority_not?: InputMaybe<Scalars['Int']['input']>
    priority_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    subtitle?: InputMaybe<Scalars['String']['input']>
    subtitle_contains?: InputMaybe<Scalars['String']['input']>
    subtitle_contains_i?: InputMaybe<Scalars['String']['input']>
    subtitle_ends_with?: InputMaybe<Scalars['String']['input']>
    subtitle_ends_with_i?: InputMaybe<Scalars['String']['input']>
    subtitle_i?: InputMaybe<Scalars['String']['input']>
    subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    subtitle_not?: InputMaybe<Scalars['String']['input']>
    subtitle_not_contains?: InputMaybe<Scalars['String']['input']>
    subtitle_not_contains_i?: InputMaybe<Scalars['String']['input']>
    subtitle_not_ends_with?: InputMaybe<Scalars['String']['input']>
    subtitle_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    subtitle_not_i?: InputMaybe<Scalars['String']['input']>
    subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    subtitle_not_starts_with?: InputMaybe<Scalars['String']['input']>
    subtitle_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    subtitle_starts_with?: InputMaybe<Scalars['String']['input']>
    subtitle_starts_with_i?: InputMaybe<Scalars['String']['input']>
    targetUrl?: InputMaybe<Scalars['String']['input']>
    targetUrl_contains?: InputMaybe<Scalars['String']['input']>
    targetUrl_contains_i?: InputMaybe<Scalars['String']['input']>
    targetUrl_ends_with?: InputMaybe<Scalars['String']['input']>
    targetUrl_ends_with_i?: InputMaybe<Scalars['String']['input']>
    targetUrl_i?: InputMaybe<Scalars['String']['input']>
    targetUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    targetUrl_not?: InputMaybe<Scalars['String']['input']>
    targetUrl_not_contains?: InputMaybe<Scalars['String']['input']>
    targetUrl_not_contains_i?: InputMaybe<Scalars['String']['input']>
    targetUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
    targetUrl_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    targetUrl_not_i?: InputMaybe<Scalars['String']['input']>
    targetUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    targetUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
    targetUrl_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    targetUrl_starts_with?: InputMaybe<Scalars['String']['input']>
    targetUrl_starts_with_i?: InputMaybe<Scalars['String']['input']>
    textVariant?: InputMaybe<Scalars['String']['input']>
    textVariant_contains?: InputMaybe<Scalars['String']['input']>
    textVariant_contains_i?: InputMaybe<Scalars['String']['input']>
    textVariant_ends_with?: InputMaybe<Scalars['String']['input']>
    textVariant_ends_with_i?: InputMaybe<Scalars['String']['input']>
    textVariant_i?: InputMaybe<Scalars['String']['input']>
    textVariant_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    textVariant_not?: InputMaybe<Scalars['String']['input']>
    textVariant_not_contains?: InputMaybe<Scalars['String']['input']>
    textVariant_not_contains_i?: InputMaybe<Scalars['String']['input']>
    textVariant_not_ends_with?: InputMaybe<Scalars['String']['input']>
    textVariant_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    textVariant_not_i?: InputMaybe<Scalars['String']['input']>
    textVariant_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    textVariant_not_starts_with?: InputMaybe<Scalars['String']['input']>
    textVariant_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    textVariant_starts_with?: InputMaybe<Scalars['String']['input']>
    textVariant_starts_with_i?: InputMaybe<Scalars['String']['input']>
    title?: InputMaybe<Scalars['String']['input']>
    title_contains?: InputMaybe<Scalars['String']['input']>
    title_contains_i?: InputMaybe<Scalars['String']['input']>
    title_ends_with?: InputMaybe<Scalars['String']['input']>
    title_ends_with_i?: InputMaybe<Scalars['String']['input']>
    title_i?: InputMaybe<Scalars['String']['input']>
    title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    title_not?: InputMaybe<Scalars['String']['input']>
    title_not_contains?: InputMaybe<Scalars['String']['input']>
    title_not_contains_i?: InputMaybe<Scalars['String']['input']>
    title_not_ends_with?: InputMaybe<Scalars['String']['input']>
    title_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    title_not_i?: InputMaybe<Scalars['String']['input']>
    title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    title_not_starts_with?: InputMaybe<Scalars['String']['input']>
    title_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    title_starts_with?: InputMaybe<Scalars['String']['input']>
    title_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type B2BAppPromoBlockHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type B2BAppPromoBlockHistoryRecordsCreateInput = {
    data?: InputMaybe<B2BAppPromoBlockHistoryRecordCreateInput>
}

export type B2BAppPromoBlockHistoryRecordsUpdateInput = {
    data?: InputMaybe<B2BAppPromoBlockHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export enum B2BAppPromoBlockTextVariantType {
    Black = 'BLACK',
    White = 'WHITE',
}

export type B2BAppPromoBlockUpdateInput = {
    backgroundColor?: InputMaybe<Scalars['String']['input']>
    backgroundImage?: InputMaybe<Scalars['Upload']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    external?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    priority?: InputMaybe<Scalars['Int']['input']>
    sender?: InputMaybe<SenderFieldInput>
    subtitle?: InputMaybe<Scalars['String']['input']>
    targetUrl?: InputMaybe<Scalars['String']['input']>
    textVariant?: InputMaybe<B2BAppPromoBlockTextVariantType>
    title?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type B2BAppPromoBlockWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<B2BAppPromoBlockWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<B2BAppPromoBlockWhereInput>>>
    backgroundColor?: InputMaybe<Scalars['String']['input']>
    backgroundColor_contains?: InputMaybe<Scalars['String']['input']>
    backgroundColor_contains_i?: InputMaybe<Scalars['String']['input']>
    backgroundColor_ends_with?: InputMaybe<Scalars['String']['input']>
    backgroundColor_ends_with_i?: InputMaybe<Scalars['String']['input']>
    backgroundColor_i?: InputMaybe<Scalars['String']['input']>
    backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    backgroundColor_not?: InputMaybe<Scalars['String']['input']>
    backgroundColor_not_contains?: InputMaybe<Scalars['String']['input']>
    backgroundColor_not_contains_i?: InputMaybe<Scalars['String']['input']>
    backgroundColor_not_ends_with?: InputMaybe<Scalars['String']['input']>
    backgroundColor_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    backgroundColor_not_i?: InputMaybe<Scalars['String']['input']>
    backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    backgroundColor_not_starts_with?: InputMaybe<Scalars['String']['input']>
    backgroundColor_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    backgroundColor_starts_with?: InputMaybe<Scalars['String']['input']>
    backgroundColor_starts_with_i?: InputMaybe<Scalars['String']['input']>
    backgroundImage?: InputMaybe<Scalars['String']['input']>
    backgroundImage_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    backgroundImage_not?: InputMaybe<Scalars['String']['input']>
    backgroundImage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    external?: InputMaybe<Scalars['Boolean']['input']>
    external_not?: InputMaybe<Scalars['Boolean']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    priority?: InputMaybe<Scalars['Int']['input']>
    priority_gt?: InputMaybe<Scalars['Int']['input']>
    priority_gte?: InputMaybe<Scalars['Int']['input']>
    priority_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    priority_lt?: InputMaybe<Scalars['Int']['input']>
    priority_lte?: InputMaybe<Scalars['Int']['input']>
    priority_not?: InputMaybe<Scalars['Int']['input']>
    priority_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    subtitle?: InputMaybe<Scalars['String']['input']>
    subtitle_contains?: InputMaybe<Scalars['String']['input']>
    subtitle_contains_i?: InputMaybe<Scalars['String']['input']>
    subtitle_ends_with?: InputMaybe<Scalars['String']['input']>
    subtitle_ends_with_i?: InputMaybe<Scalars['String']['input']>
    subtitle_i?: InputMaybe<Scalars['String']['input']>
    subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    subtitle_not?: InputMaybe<Scalars['String']['input']>
    subtitle_not_contains?: InputMaybe<Scalars['String']['input']>
    subtitle_not_contains_i?: InputMaybe<Scalars['String']['input']>
    subtitle_not_ends_with?: InputMaybe<Scalars['String']['input']>
    subtitle_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    subtitle_not_i?: InputMaybe<Scalars['String']['input']>
    subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    subtitle_not_starts_with?: InputMaybe<Scalars['String']['input']>
    subtitle_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    subtitle_starts_with?: InputMaybe<Scalars['String']['input']>
    subtitle_starts_with_i?: InputMaybe<Scalars['String']['input']>
    targetUrl?: InputMaybe<Scalars['String']['input']>
    targetUrl_contains?: InputMaybe<Scalars['String']['input']>
    targetUrl_contains_i?: InputMaybe<Scalars['String']['input']>
    targetUrl_ends_with?: InputMaybe<Scalars['String']['input']>
    targetUrl_ends_with_i?: InputMaybe<Scalars['String']['input']>
    targetUrl_i?: InputMaybe<Scalars['String']['input']>
    targetUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    targetUrl_not?: InputMaybe<Scalars['String']['input']>
    targetUrl_not_contains?: InputMaybe<Scalars['String']['input']>
    targetUrl_not_contains_i?: InputMaybe<Scalars['String']['input']>
    targetUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
    targetUrl_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    targetUrl_not_i?: InputMaybe<Scalars['String']['input']>
    targetUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    targetUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
    targetUrl_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    targetUrl_starts_with?: InputMaybe<Scalars['String']['input']>
    targetUrl_starts_with_i?: InputMaybe<Scalars['String']['input']>
    textVariant?: InputMaybe<B2BAppPromoBlockTextVariantType>
    textVariant_in?: InputMaybe<Array<InputMaybe<B2BAppPromoBlockTextVariantType>>>
    textVariant_not?: InputMaybe<B2BAppPromoBlockTextVariantType>
    textVariant_not_in?: InputMaybe<Array<InputMaybe<B2BAppPromoBlockTextVariantType>>>
    title?: InputMaybe<Scalars['String']['input']>
    title_contains?: InputMaybe<Scalars['String']['input']>
    title_contains_i?: InputMaybe<Scalars['String']['input']>
    title_ends_with?: InputMaybe<Scalars['String']['input']>
    title_ends_with_i?: InputMaybe<Scalars['String']['input']>
    title_i?: InputMaybe<Scalars['String']['input']>
    title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    title_not?: InputMaybe<Scalars['String']['input']>
    title_not_contains?: InputMaybe<Scalars['String']['input']>
    title_not_contains_i?: InputMaybe<Scalars['String']['input']>
    title_not_ends_with?: InputMaybe<Scalars['String']['input']>
    title_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    title_not_i?: InputMaybe<Scalars['String']['input']>
    title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    title_not_starts_with?: InputMaybe<Scalars['String']['input']>
    title_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    title_starts_with?: InputMaybe<Scalars['String']['input']>
    title_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type B2BAppPromoBlockWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type B2BAppPromoBlocksCreateInput = {
    data?: InputMaybe<B2BAppPromoBlockCreateInput>
}

export type B2BAppPromoBlocksUpdateInput = {
    data?: InputMaybe<B2BAppPromoBlockUpdateInput>
    id: Scalars['ID']['input']
}

export type B2BAppRelateToOneInput = {
    connect?: InputMaybe<B2BAppWhereUniqueInput>
    create?: InputMaybe<B2BAppCreateInput>
    disconnect?: InputMaybe<B2BAppWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

/**  This model links the role of the organization and mini-apps, allowing mini-apps to extend the main role template. Having this mapping between role A and mini-app B means that all employees in the organization with role A can access mini-app B. In addition, a mini-app may want to differentiate access within itself. To do this, it can create B2BAppPermissions via service user, the keys of which will appear as properties in the "permissions" field on all B2BAppRole associated with the mini-app.  By default, all existing and new permissions will be set to "true" for all roles in the organization that have "canManageB2BApps" checked, false for other employees. When connecting the miniapp will be automatically created B2BAppRole for all roles that have the "canManageB2BApps" checkbox. B2BAppRole can be created and updated manually by employees with the "canManageRoles" permission for other roles. When deleting B2BAppPermission, its key is also removed from the permissions field of all corresponding B2BAppRole, and when adding it - it is added to all roles, and the value is set according to the rules described above.  */
export type B2BAppRole = {
    __typename?: 'B2BAppRole'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2BAppRole List config, or
   *  2. As an alias to the field set on 'labelField' in the B2BAppRole List config, or
   *  3. As an alias to a 'name' field on the B2BAppRole List (if one exists), or
   *  4. As an alias to the 'id' field on the B2BAppRole List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  Link to the application to which the role belongs  */
    app?: Maybe<B2BApp>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['String']['output']>
    /**  A set of specific permissions within a mini-app for a specific role. Is a Json object where the key is the "key" field from B2BAppPermission and the value is Boolean. Example: "{"canManagePasses": true, "canReadConfig": false}"  */
    permissions?: Maybe<Scalars['JSON']['output']>
    /**  Link to the role of the organization that B2BAppRole extends  */
    role?: Maybe<OrganizationEmployeeRole>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type B2BAppRoleCreateInput = {
    app?: InputMaybe<B2BAppRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    permissions?: InputMaybe<Scalars['JSON']['input']>
    role?: InputMaybe<OrganizationEmployeeRoleRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type B2BAppRoleHistoryRecord = {
    __typename?: 'B2BAppRoleHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2BAppRoleHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the B2BAppRoleHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the B2BAppRoleHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the B2BAppRoleHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    app?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<B2BAppRoleHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['JSON']['output']>
    permissions?: Maybe<Scalars['JSON']['output']>
    role?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type B2BAppRoleHistoryRecordCreateInput = {
    app?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<B2BAppRoleHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    permissions?: InputMaybe<Scalars['JSON']['input']>
    role?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum B2BAppRoleHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type B2BAppRoleHistoryRecordUpdateInput = {
    app?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<B2BAppRoleHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    permissions?: InputMaybe<Scalars['JSON']['input']>
    role?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type B2BAppRoleHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<B2BAppRoleHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<B2BAppRoleHistoryRecordWhereInput>>>
    app?: InputMaybe<Scalars['String']['input']>
    app_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    app_not?: InputMaybe<Scalars['String']['input']>
    app_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<B2BAppRoleHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<B2BAppRoleHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<B2BAppRoleHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<B2BAppRoleHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    permissions?: InputMaybe<Scalars['JSON']['input']>
    permissions_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    permissions_not?: InputMaybe<Scalars['JSON']['input']>
    permissions_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    role?: InputMaybe<Scalars['String']['input']>
    role_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    role_not?: InputMaybe<Scalars['String']['input']>
    role_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type B2BAppRoleHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type B2BAppRoleHistoryRecordsCreateInput = {
    data?: InputMaybe<B2BAppRoleHistoryRecordCreateInput>
}

export type B2BAppRoleHistoryRecordsUpdateInput = {
    data?: InputMaybe<B2BAppRoleHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type B2BAppRoleUpdateInput = {
    app?: InputMaybe<B2BAppRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    permissions?: InputMaybe<Scalars['JSON']['input']>
    role?: InputMaybe<OrganizationEmployeeRoleRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type B2BAppRoleWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<B2BAppRoleWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<B2BAppRoleWhereInput>>>
    app?: InputMaybe<B2BAppWhereInput>
    app_is_null?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    permissions?: InputMaybe<Scalars['JSON']['input']>
    permissions_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    permissions_not?: InputMaybe<Scalars['JSON']['input']>
    permissions_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    role?: InputMaybe<OrganizationEmployeeRoleWhereInput>
    role_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type B2BAppRoleWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type B2BAppRolesCreateInput = {
    data?: InputMaybe<B2BAppRoleCreateInput>
}

export type B2BAppRolesUpdateInput = {
    data?: InputMaybe<B2BAppRoleUpdateInput>
    id: Scalars['ID']['input']
}

export type B2BAppUpdateInput = {
    accessRights?: InputMaybe<B2BAppAccessRightRelateToManyInput>
    appUrl?: InputMaybe<Scalars['String']['input']>
    category?: InputMaybe<B2BAppCategoryType>
    contextDefaultStatus?: InputMaybe<B2BAppContextDefaultStatusType>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    detailedDescription?: InputMaybe<Scalars['String']['input']>
    developer?: InputMaybe<Scalars['String']['input']>
    displayPriority?: InputMaybe<Scalars['Int']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    features?: InputMaybe<Array<B2BAppGlobalFeature>>
    gallery?: InputMaybe<Array<Scalars['String']['input']>>
    hasDynamicTitle?: InputMaybe<Scalars['Boolean']['input']>
    icon?: InputMaybe<B2BAppIconType>
    isGlobal?: InputMaybe<Scalars['Boolean']['input']>
    isHidden?: InputMaybe<Scalars['Boolean']['input']>
    isPublic?: InputMaybe<Scalars['Boolean']['input']>
    label?: InputMaybe<B2BAppLabelType>
    logo?: InputMaybe<Scalars['Upload']['input']>
    menuCategory?: InputMaybe<B2BAppMenuCategoryType>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newsSharingConfig?: InputMaybe<B2BAppNewsSharingConfigRelateToOneInput>
    partnerUrl?: InputMaybe<Scalars['String']['input']>
    price?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    shortDescription?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type B2BAppWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<B2BAppWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<B2BAppWhereInput>>>
    /**  condition must be true for all nodes  */
    accessRights_every?: InputMaybe<B2BAppAccessRightWhereInput>
    /**  condition must be false for all nodes  */
    accessRights_none?: InputMaybe<B2BAppAccessRightWhereInput>
    /**  condition must be true for at least 1 node  */
    accessRights_some?: InputMaybe<B2BAppAccessRightWhereInput>
    appUrl?: InputMaybe<Scalars['String']['input']>
    appUrl_contains?: InputMaybe<Scalars['String']['input']>
    appUrl_contains_i?: InputMaybe<Scalars['String']['input']>
    appUrl_ends_with?: InputMaybe<Scalars['String']['input']>
    appUrl_ends_with_i?: InputMaybe<Scalars['String']['input']>
    appUrl_i?: InputMaybe<Scalars['String']['input']>
    appUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    appUrl_not?: InputMaybe<Scalars['String']['input']>
    appUrl_not_contains?: InputMaybe<Scalars['String']['input']>
    appUrl_not_contains_i?: InputMaybe<Scalars['String']['input']>
    appUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
    appUrl_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    appUrl_not_i?: InputMaybe<Scalars['String']['input']>
    appUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    appUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
    appUrl_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    appUrl_starts_with?: InputMaybe<Scalars['String']['input']>
    appUrl_starts_with_i?: InputMaybe<Scalars['String']['input']>
    category?: InputMaybe<B2BAppCategoryType>
    category_in?: InputMaybe<Array<InputMaybe<B2BAppCategoryType>>>
    category_not?: InputMaybe<B2BAppCategoryType>
    category_not_in?: InputMaybe<Array<InputMaybe<B2BAppCategoryType>>>
    contextDefaultStatus?: InputMaybe<B2BAppContextDefaultStatusType>
    contextDefaultStatus_in?: InputMaybe<Array<InputMaybe<B2BAppContextDefaultStatusType>>>
    contextDefaultStatus_not?: InputMaybe<B2BAppContextDefaultStatusType>
    contextDefaultStatus_not_in?: InputMaybe<Array<InputMaybe<B2BAppContextDefaultStatusType>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    detailedDescription?: InputMaybe<Scalars['String']['input']>
    detailedDescription_contains?: InputMaybe<Scalars['String']['input']>
    detailedDescription_contains_i?: InputMaybe<Scalars['String']['input']>
    detailedDescription_ends_with?: InputMaybe<Scalars['String']['input']>
    detailedDescription_ends_with_i?: InputMaybe<Scalars['String']['input']>
    detailedDescription_i?: InputMaybe<Scalars['String']['input']>
    detailedDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    detailedDescription_not?: InputMaybe<Scalars['String']['input']>
    detailedDescription_not_contains?: InputMaybe<Scalars['String']['input']>
    detailedDescription_not_contains_i?: InputMaybe<Scalars['String']['input']>
    detailedDescription_not_ends_with?: InputMaybe<Scalars['String']['input']>
    detailedDescription_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    detailedDescription_not_i?: InputMaybe<Scalars['String']['input']>
    detailedDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    detailedDescription_not_starts_with?: InputMaybe<Scalars['String']['input']>
    detailedDescription_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    detailedDescription_starts_with?: InputMaybe<Scalars['String']['input']>
    detailedDescription_starts_with_i?: InputMaybe<Scalars['String']['input']>
    developer?: InputMaybe<Scalars['String']['input']>
    developer_contains?: InputMaybe<Scalars['String']['input']>
    developer_contains_i?: InputMaybe<Scalars['String']['input']>
    developer_ends_with?: InputMaybe<Scalars['String']['input']>
    developer_ends_with_i?: InputMaybe<Scalars['String']['input']>
    developer_i?: InputMaybe<Scalars['String']['input']>
    developer_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    developer_not?: InputMaybe<Scalars['String']['input']>
    developer_not_contains?: InputMaybe<Scalars['String']['input']>
    developer_not_contains_i?: InputMaybe<Scalars['String']['input']>
    developer_not_ends_with?: InputMaybe<Scalars['String']['input']>
    developer_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    developer_not_i?: InputMaybe<Scalars['String']['input']>
    developer_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    developer_not_starts_with?: InputMaybe<Scalars['String']['input']>
    developer_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    developer_starts_with?: InputMaybe<Scalars['String']['input']>
    developer_starts_with_i?: InputMaybe<Scalars['String']['input']>
    displayPriority?: InputMaybe<Scalars['Int']['input']>
    displayPriority_gt?: InputMaybe<Scalars['Int']['input']>
    displayPriority_gte?: InputMaybe<Scalars['Int']['input']>
    displayPriority_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    displayPriority_lt?: InputMaybe<Scalars['Int']['input']>
    displayPriority_lte?: InputMaybe<Scalars['Int']['input']>
    displayPriority_not?: InputMaybe<Scalars['Int']['input']>
    displayPriority_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    features?: InputMaybe<Array<B2BAppGlobalFeature>>
    features_in?: InputMaybe<Array<InputMaybe<Array<B2BAppGlobalFeature>>>>
    features_not?: InputMaybe<Array<B2BAppGlobalFeature>>
    features_not_in?: InputMaybe<Array<InputMaybe<Array<B2BAppGlobalFeature>>>>
    gallery?: InputMaybe<Array<Scalars['String']['input']>>
    gallery_in?: InputMaybe<Array<InputMaybe<Array<Scalars['String']['input']>>>>
    gallery_not?: InputMaybe<Array<Scalars['String']['input']>>
    gallery_not_in?: InputMaybe<Array<InputMaybe<Array<Scalars['String']['input']>>>>
    hasDynamicTitle?: InputMaybe<Scalars['Boolean']['input']>
    hasDynamicTitle_not?: InputMaybe<Scalars['Boolean']['input']>
    icon?: InputMaybe<B2BAppIconType>
    icon_in?: InputMaybe<Array<InputMaybe<B2BAppIconType>>>
    icon_not?: InputMaybe<B2BAppIconType>
    icon_not_in?: InputMaybe<Array<InputMaybe<B2BAppIconType>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    isGlobal?: InputMaybe<Scalars['Boolean']['input']>
    isGlobal_not?: InputMaybe<Scalars['Boolean']['input']>
    isHidden?: InputMaybe<Scalars['Boolean']['input']>
    isHidden_not?: InputMaybe<Scalars['Boolean']['input']>
    isPublic?: InputMaybe<Scalars['Boolean']['input']>
    isPublic_not?: InputMaybe<Scalars['Boolean']['input']>
    label?: InputMaybe<B2BAppLabelType>
    label_in?: InputMaybe<Array<InputMaybe<B2BAppLabelType>>>
    label_not?: InputMaybe<B2BAppLabelType>
    label_not_in?: InputMaybe<Array<InputMaybe<B2BAppLabelType>>>
    logo?: InputMaybe<Scalars['String']['input']>
    logo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    logo_not?: InputMaybe<Scalars['String']['input']>
    logo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    menuCategory?: InputMaybe<B2BAppMenuCategoryType>
    menuCategory_in?: InputMaybe<Array<InputMaybe<B2BAppMenuCategoryType>>>
    menuCategory_not?: InputMaybe<B2BAppMenuCategoryType>
    menuCategory_not_in?: InputMaybe<Array<InputMaybe<B2BAppMenuCategoryType>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newsSharingConfig?: InputMaybe<B2BAppNewsSharingConfigWhereInput>
    newsSharingConfig_is_null?: InputMaybe<Scalars['Boolean']['input']>
    partnerUrl?: InputMaybe<Scalars['String']['input']>
    partnerUrl_contains?: InputMaybe<Scalars['String']['input']>
    partnerUrl_contains_i?: InputMaybe<Scalars['String']['input']>
    partnerUrl_ends_with?: InputMaybe<Scalars['String']['input']>
    partnerUrl_ends_with_i?: InputMaybe<Scalars['String']['input']>
    partnerUrl_i?: InputMaybe<Scalars['String']['input']>
    partnerUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    partnerUrl_not?: InputMaybe<Scalars['String']['input']>
    partnerUrl_not_contains?: InputMaybe<Scalars['String']['input']>
    partnerUrl_not_contains_i?: InputMaybe<Scalars['String']['input']>
    partnerUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
    partnerUrl_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    partnerUrl_not_i?: InputMaybe<Scalars['String']['input']>
    partnerUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    partnerUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
    partnerUrl_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    partnerUrl_starts_with?: InputMaybe<Scalars['String']['input']>
    partnerUrl_starts_with_i?: InputMaybe<Scalars['String']['input']>
    price?: InputMaybe<Scalars['String']['input']>
    price_contains?: InputMaybe<Scalars['String']['input']>
    price_contains_i?: InputMaybe<Scalars['String']['input']>
    price_ends_with?: InputMaybe<Scalars['String']['input']>
    price_ends_with_i?: InputMaybe<Scalars['String']['input']>
    price_i?: InputMaybe<Scalars['String']['input']>
    price_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    price_not?: InputMaybe<Scalars['String']['input']>
    price_not_contains?: InputMaybe<Scalars['String']['input']>
    price_not_contains_i?: InputMaybe<Scalars['String']['input']>
    price_not_ends_with?: InputMaybe<Scalars['String']['input']>
    price_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    price_not_i?: InputMaybe<Scalars['String']['input']>
    price_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    price_not_starts_with?: InputMaybe<Scalars['String']['input']>
    price_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    price_starts_with?: InputMaybe<Scalars['String']['input']>
    price_starts_with_i?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    shortDescription?: InputMaybe<Scalars['String']['input']>
    shortDescription_contains?: InputMaybe<Scalars['String']['input']>
    shortDescription_contains_i?: InputMaybe<Scalars['String']['input']>
    shortDescription_ends_with?: InputMaybe<Scalars['String']['input']>
    shortDescription_ends_with_i?: InputMaybe<Scalars['String']['input']>
    shortDescription_i?: InputMaybe<Scalars['String']['input']>
    shortDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    shortDescription_not?: InputMaybe<Scalars['String']['input']>
    shortDescription_not_contains?: InputMaybe<Scalars['String']['input']>
    shortDescription_not_contains_i?: InputMaybe<Scalars['String']['input']>
    shortDescription_not_ends_with?: InputMaybe<Scalars['String']['input']>
    shortDescription_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    shortDescription_not_i?: InputMaybe<Scalars['String']['input']>
    shortDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    shortDescription_not_starts_with?: InputMaybe<Scalars['String']['input']>
    shortDescription_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    shortDescription_starts_with?: InputMaybe<Scalars['String']['input']>
    shortDescription_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type B2BAppWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type B2BAppsCreateInput = {
    data?: InputMaybe<B2BAppCreateInput>
}

export type B2BAppsUpdateInput = {
    data?: InputMaybe<B2BAppUpdateInput>
    id: Scalars['ID']['input']
}

/**  B2C App  */
export type B2CApp = {
    __typename?: 'B2CApp'
    _accessRightsMeta?: Maybe<_QueryMeta>
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2CApp List config, or
   *  2. As an alias to the field set on 'labelField' in the B2CApp List config, or
   *  3. As an alias to a 'name' field on the B2CApp List (if one exists), or
   *  4. As an alias to the 'id' field on the B2CApp List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  Specifies set of service users, who can modify B2CAppProperties of the app as well as perform actions on behalf of the application  */
    accessRights: Array<B2CAppAccessRight>
    /**  The color schema of the B2C application used to display it correctly. The main color is used for texts, icons etc. The secondary color is used for the background  */
    colorSchema?: Maybe<AppColorSchemaField>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    /**  Link to current active app build  */
    currentBuild?: Maybe<B2CAppBuild>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Developer company name  */
    developer?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  ID of the object in the system from which it was imported  */
    importId?: Maybe<Scalars['String']['output']>
    /**  Name of the system from which object was imported  */
    importRemoteSystem?: Maybe<Scalars['String']['output']>
    /**  Indicates whether the integration or app is hidden inside the CRM. Used if integration is active by default or not ready to appear inside CRM  */
    isHidden?: Maybe<Scalars['Boolean']['output']>
    /**  Logo of app's company or app itself  */
    logo?: Maybe<File>
    /**  Name of B2C App  */
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  [DEPRECATED] This field is not used by anyone but fetched from some clients  */
    shortDescription?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}


/**  B2C App  */
export type B2CApp_AccessRightsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2CAppAccessRightsBy>>
    where?: InputMaybe<B2CAppAccessRightWhereInput>
}


/**  B2C App  */
export type B2CAppAccessRightsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2CAppAccessRightsBy>>
    where?: InputMaybe<B2CAppAccessRightWhereInput>
}

/**  Link between service user and B2C App. The existence of this connection means that this user has the rights to perform actions on behalf of the integration and modify some B2CApp-related models  */
export type B2CAppAccessRight = {
    __typename?: 'B2CAppAccessRight'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2CAppAccessRight List config, or
   *  2. As an alias to the field set on 'labelField' in the B2CAppAccessRight List config, or
   *  3. As an alias to a 'name' field on the B2CAppAccessRight List (if one exists), or
   *  4. As an alias to the 'id' field on the B2CAppAccessRight List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  Link to B2СApp.accessRights  */
    app?: Maybe<B2CApp>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  ID of the object in the system from which it was imported  */
    importId?: Maybe<Scalars['String']['output']>
    /**  Name of the system from which object was imported  */
    importRemoteSystem?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    /**  Link to user. Note, that user must be of "service" type  */
    user?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type B2CAppAccessRightCreateInput = {
    app?: InputMaybe<B2CAppRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type B2CAppAccessRightHistoryRecord = {
    __typename?: 'B2CAppAccessRightHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2CAppAccessRightHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the B2CAppAccessRightHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the B2CAppAccessRightHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the B2CAppAccessRightHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    app?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<B2CAppAccessRightHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    importId?: Maybe<Scalars['String']['output']>
    importRemoteSystem?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    user?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type B2CAppAccessRightHistoryRecordCreateInput = {
    app?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<B2CAppAccessRightHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum B2CAppAccessRightHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type B2CAppAccessRightHistoryRecordUpdateInput = {
    app?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<B2CAppAccessRightHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type B2CAppAccessRightHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<B2CAppAccessRightHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<B2CAppAccessRightHistoryRecordWhereInput>>>
    app?: InputMaybe<Scalars['String']['input']>
    app_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    app_not?: InputMaybe<Scalars['String']['input']>
    app_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<B2CAppAccessRightHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<B2CAppAccessRightHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<B2CAppAccessRightHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<B2CAppAccessRightHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    importId?: InputMaybe<Scalars['String']['input']>
    importId_contains?: InputMaybe<Scalars['String']['input']>
    importId_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_i?: InputMaybe<Scalars['String']['input']>
    importId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not?: InputMaybe<Scalars['String']['input']>
    importId_not_contains?: InputMaybe<Scalars['String']['input']>
    importId_not_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_not_i?: InputMaybe<Scalars['String']['input']>
    importId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importId_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_contains?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_contains_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_ends_with?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importRemoteSystem_not?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_contains?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_contains_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_ends_with?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importRemoteSystem_not_starts_with?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_starts_with?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user?: InputMaybe<Scalars['String']['input']>
    user_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user_not?: InputMaybe<Scalars['String']['input']>
    user_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type B2CAppAccessRightHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type B2CAppAccessRightHistoryRecordsCreateInput = {
    data?: InputMaybe<B2CAppAccessRightHistoryRecordCreateInput>
}

export type B2CAppAccessRightHistoryRecordsUpdateInput = {
    data?: InputMaybe<B2CAppAccessRightHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type B2CAppAccessRightRelateToManyInput = {
    connect?: InputMaybe<Array<InputMaybe<B2CAppAccessRightWhereUniqueInput>>>
    create?: InputMaybe<Array<InputMaybe<B2CAppAccessRightCreateInput>>>
    disconnect?: InputMaybe<Array<InputMaybe<B2CAppAccessRightWhereUniqueInput>>>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type B2CAppAccessRightUpdateInput = {
    app?: InputMaybe<B2CAppRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type B2CAppAccessRightWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<B2CAppAccessRightWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<B2CAppAccessRightWhereInput>>>
    app?: InputMaybe<B2CAppWhereInput>
    app_is_null?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    importId?: InputMaybe<Scalars['String']['input']>
    importId_contains?: InputMaybe<Scalars['String']['input']>
    importId_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_i?: InputMaybe<Scalars['String']['input']>
    importId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not?: InputMaybe<Scalars['String']['input']>
    importId_not_contains?: InputMaybe<Scalars['String']['input']>
    importId_not_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_not_i?: InputMaybe<Scalars['String']['input']>
    importId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importId_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_contains?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_contains_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_ends_with?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importRemoteSystem_not?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_contains?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_contains_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_ends_with?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importRemoteSystem_not_starts_with?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_starts_with?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    user?: InputMaybe<UserWhereInput>
    user_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type B2CAppAccessRightWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type B2CAppAccessRightsCreateInput = {
    data?: InputMaybe<B2CAppAccessRightCreateInput>
}

export type B2CAppAccessRightsUpdateInput = {
    data?: InputMaybe<B2CAppAccessRightUpdateInput>
    id: Scalars['ID']['input']
}

/**  Cordova build of B2C Application  */
export type B2CAppBuild = {
    __typename?: 'B2CAppBuild'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2CAppBuild List config, or
   *  2. As an alias to the field set on 'labelField' in the B2CAppBuild List config, or
   *  3. As an alias to a 'name' field on the B2CAppBuild List (if one exists), or
   *  4. As an alias to the 'id' field on the B2CAppBuild List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  Link to B2C application  */
    app?: Maybe<B2CApp>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    /**  B2C app cordova build compressed to single .zip file  */
    data?: Maybe<File>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  ID of the object in the system from which it was imported  */
    importId?: Maybe<Scalars['String']['output']>
    /**  Name of the system from which object was imported  */
    importRemoteSystem?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
    /**  Version of build which used to control builds inside B2CApp model  */
    version?: Maybe<Scalars['String']['output']>
}

export type B2CAppBuildCreateInput = {
    app?: InputMaybe<B2CAppRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    data?: InputMaybe<Scalars['Upload']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
    version?: InputMaybe<Scalars['String']['input']>
}

/**  A keystone list  */
export type B2CAppBuildHistoryRecord = {
    __typename?: 'B2CAppBuildHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2CAppBuildHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the B2CAppBuildHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the B2CAppBuildHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the B2CAppBuildHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    app?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    data?: Maybe<Scalars['JSON']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<B2CAppBuildHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    importId?: Maybe<Scalars['String']['output']>
    importRemoteSystem?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
    version?: Maybe<Scalars['String']['output']>
}

export type B2CAppBuildHistoryRecordCreateInput = {
    app?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    data?: InputMaybe<Scalars['JSON']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<B2CAppBuildHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    version?: InputMaybe<Scalars['String']['input']>
}

export enum B2CAppBuildHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type B2CAppBuildHistoryRecordUpdateInput = {
    app?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    data?: InputMaybe<Scalars['JSON']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<B2CAppBuildHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    version?: InputMaybe<Scalars['String']['input']>
}

export type B2CAppBuildHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<B2CAppBuildHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<B2CAppBuildHistoryRecordWhereInput>>>
    app?: InputMaybe<Scalars['String']['input']>
    app_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    app_not?: InputMaybe<Scalars['String']['input']>
    app_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    data?: InputMaybe<Scalars['JSON']['input']>
    data_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    data_not?: InputMaybe<Scalars['JSON']['input']>
    data_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<B2CAppBuildHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<B2CAppBuildHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<B2CAppBuildHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<B2CAppBuildHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    importId?: InputMaybe<Scalars['String']['input']>
    importId_contains?: InputMaybe<Scalars['String']['input']>
    importId_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_i?: InputMaybe<Scalars['String']['input']>
    importId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not?: InputMaybe<Scalars['String']['input']>
    importId_not_contains?: InputMaybe<Scalars['String']['input']>
    importId_not_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_not_i?: InputMaybe<Scalars['String']['input']>
    importId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importId_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_contains?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_contains_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_ends_with?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importRemoteSystem_not?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_contains?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_contains_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_ends_with?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importRemoteSystem_not_starts_with?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_starts_with?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    version?: InputMaybe<Scalars['String']['input']>
    version_contains?: InputMaybe<Scalars['String']['input']>
    version_contains_i?: InputMaybe<Scalars['String']['input']>
    version_ends_with?: InputMaybe<Scalars['String']['input']>
    version_ends_with_i?: InputMaybe<Scalars['String']['input']>
    version_i?: InputMaybe<Scalars['String']['input']>
    version_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    version_not?: InputMaybe<Scalars['String']['input']>
    version_not_contains?: InputMaybe<Scalars['String']['input']>
    version_not_contains_i?: InputMaybe<Scalars['String']['input']>
    version_not_ends_with?: InputMaybe<Scalars['String']['input']>
    version_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    version_not_i?: InputMaybe<Scalars['String']['input']>
    version_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    version_not_starts_with?: InputMaybe<Scalars['String']['input']>
    version_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    version_starts_with?: InputMaybe<Scalars['String']['input']>
    version_starts_with_i?: InputMaybe<Scalars['String']['input']>
}

export type B2CAppBuildHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type B2CAppBuildHistoryRecordsCreateInput = {
    data?: InputMaybe<B2CAppBuildHistoryRecordCreateInput>
}

export type B2CAppBuildHistoryRecordsUpdateInput = {
    data?: InputMaybe<B2CAppBuildHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type B2CAppBuildRelateToOneInput = {
    connect?: InputMaybe<B2CAppBuildWhereUniqueInput>
    create?: InputMaybe<B2CAppBuildCreateInput>
    disconnect?: InputMaybe<B2CAppBuildWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type B2CAppBuildUpdateInput = {
    app?: InputMaybe<B2CAppRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    data?: InputMaybe<Scalars['Upload']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
    version?: InputMaybe<Scalars['String']['input']>
}

export type B2CAppBuildWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<B2CAppBuildWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<B2CAppBuildWhereInput>>>
    app?: InputMaybe<B2CAppWhereInput>
    app_is_null?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    data?: InputMaybe<Scalars['String']['input']>
    data_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    data_not?: InputMaybe<Scalars['String']['input']>
    data_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    importId?: InputMaybe<Scalars['String']['input']>
    importId_contains?: InputMaybe<Scalars['String']['input']>
    importId_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_i?: InputMaybe<Scalars['String']['input']>
    importId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not?: InputMaybe<Scalars['String']['input']>
    importId_not_contains?: InputMaybe<Scalars['String']['input']>
    importId_not_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_not_i?: InputMaybe<Scalars['String']['input']>
    importId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importId_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_contains?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_contains_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_ends_with?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importRemoteSystem_not?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_contains?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_contains_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_ends_with?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importRemoteSystem_not_starts_with?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_starts_with?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    version?: InputMaybe<Scalars['String']['input']>
    version_contains?: InputMaybe<Scalars['String']['input']>
    version_contains_i?: InputMaybe<Scalars['String']['input']>
    version_ends_with?: InputMaybe<Scalars['String']['input']>
    version_ends_with_i?: InputMaybe<Scalars['String']['input']>
    version_i?: InputMaybe<Scalars['String']['input']>
    version_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    version_not?: InputMaybe<Scalars['String']['input']>
    version_not_contains?: InputMaybe<Scalars['String']['input']>
    version_not_contains_i?: InputMaybe<Scalars['String']['input']>
    version_not_ends_with?: InputMaybe<Scalars['String']['input']>
    version_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    version_not_i?: InputMaybe<Scalars['String']['input']>
    version_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    version_not_starts_with?: InputMaybe<Scalars['String']['input']>
    version_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    version_starts_with?: InputMaybe<Scalars['String']['input']>
    version_starts_with_i?: InputMaybe<Scalars['String']['input']>
}

export type B2CAppBuildWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type B2CAppBuildsCreateInput = {
    data?: InputMaybe<B2CAppBuildCreateInput>
}

export type B2CAppBuildsUpdateInput = {
    data?: InputMaybe<B2CAppBuildUpdateInput>
    id: Scalars['ID']['input']
}

export type B2CAppCreateInput = {
    accessRights?: InputMaybe<B2CAppAccessRightRelateToManyInput>
    colorSchema?: InputMaybe<AppColorSchemaFieldInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    currentBuild?: InputMaybe<B2CAppBuildRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    developer?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem?: InputMaybe<Scalars['String']['input']>
    isHidden?: InputMaybe<Scalars['Boolean']['input']>
    logo?: InputMaybe<Scalars['Upload']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    shortDescription?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type B2CAppHistoryRecord = {
    __typename?: 'B2CAppHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2CAppHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the B2CAppHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the B2CAppHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the B2CAppHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    colorSchema?: Maybe<Scalars['JSON']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    currentBuild?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    developer?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<B2CAppHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    importId?: Maybe<Scalars['String']['output']>
    importRemoteSystem?: Maybe<Scalars['String']['output']>
    isHidden?: Maybe<Scalars['Boolean']['output']>
    logo?: Maybe<Scalars['JSON']['output']>
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    shortDescription?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type B2CAppHistoryRecordCreateInput = {
    colorSchema?: InputMaybe<Scalars['JSON']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    currentBuild?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    developer?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<B2CAppHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem?: InputMaybe<Scalars['String']['input']>
    isHidden?: InputMaybe<Scalars['Boolean']['input']>
    logo?: InputMaybe<Scalars['JSON']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    shortDescription?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum B2CAppHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type B2CAppHistoryRecordUpdateInput = {
    colorSchema?: InputMaybe<Scalars['JSON']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    currentBuild?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    developer?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<B2CAppHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem?: InputMaybe<Scalars['String']['input']>
    isHidden?: InputMaybe<Scalars['Boolean']['input']>
    logo?: InputMaybe<Scalars['JSON']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    shortDescription?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type B2CAppHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<B2CAppHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<B2CAppHistoryRecordWhereInput>>>
    colorSchema?: InputMaybe<Scalars['JSON']['input']>
    colorSchema_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    colorSchema_not?: InputMaybe<Scalars['JSON']['input']>
    colorSchema_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    currentBuild?: InputMaybe<Scalars['String']['input']>
    currentBuild_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    currentBuild_not?: InputMaybe<Scalars['String']['input']>
    currentBuild_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    developer?: InputMaybe<Scalars['String']['input']>
    developer_contains?: InputMaybe<Scalars['String']['input']>
    developer_contains_i?: InputMaybe<Scalars['String']['input']>
    developer_ends_with?: InputMaybe<Scalars['String']['input']>
    developer_ends_with_i?: InputMaybe<Scalars['String']['input']>
    developer_i?: InputMaybe<Scalars['String']['input']>
    developer_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    developer_not?: InputMaybe<Scalars['String']['input']>
    developer_not_contains?: InputMaybe<Scalars['String']['input']>
    developer_not_contains_i?: InputMaybe<Scalars['String']['input']>
    developer_not_ends_with?: InputMaybe<Scalars['String']['input']>
    developer_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    developer_not_i?: InputMaybe<Scalars['String']['input']>
    developer_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    developer_not_starts_with?: InputMaybe<Scalars['String']['input']>
    developer_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    developer_starts_with?: InputMaybe<Scalars['String']['input']>
    developer_starts_with_i?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<B2CAppHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<B2CAppHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<B2CAppHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<B2CAppHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    importId?: InputMaybe<Scalars['String']['input']>
    importId_contains?: InputMaybe<Scalars['String']['input']>
    importId_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_i?: InputMaybe<Scalars['String']['input']>
    importId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not?: InputMaybe<Scalars['String']['input']>
    importId_not_contains?: InputMaybe<Scalars['String']['input']>
    importId_not_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_not_i?: InputMaybe<Scalars['String']['input']>
    importId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importId_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_contains?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_contains_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_ends_with?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importRemoteSystem_not?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_contains?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_contains_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_ends_with?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importRemoteSystem_not_starts_with?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_starts_with?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_starts_with_i?: InputMaybe<Scalars['String']['input']>
    isHidden?: InputMaybe<Scalars['Boolean']['input']>
    isHidden_not?: InputMaybe<Scalars['Boolean']['input']>
    logo?: InputMaybe<Scalars['JSON']['input']>
    logo_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    logo_not?: InputMaybe<Scalars['JSON']['input']>
    logo_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    shortDescription?: InputMaybe<Scalars['String']['input']>
    shortDescription_contains?: InputMaybe<Scalars['String']['input']>
    shortDescription_contains_i?: InputMaybe<Scalars['String']['input']>
    shortDescription_ends_with?: InputMaybe<Scalars['String']['input']>
    shortDescription_ends_with_i?: InputMaybe<Scalars['String']['input']>
    shortDescription_i?: InputMaybe<Scalars['String']['input']>
    shortDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    shortDescription_not?: InputMaybe<Scalars['String']['input']>
    shortDescription_not_contains?: InputMaybe<Scalars['String']['input']>
    shortDescription_not_contains_i?: InputMaybe<Scalars['String']['input']>
    shortDescription_not_ends_with?: InputMaybe<Scalars['String']['input']>
    shortDescription_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    shortDescription_not_i?: InputMaybe<Scalars['String']['input']>
    shortDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    shortDescription_not_starts_with?: InputMaybe<Scalars['String']['input']>
    shortDescription_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    shortDescription_starts_with?: InputMaybe<Scalars['String']['input']>
    shortDescription_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type B2CAppHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type B2CAppHistoryRecordsCreateInput = {
    data?: InputMaybe<B2CAppHistoryRecordCreateInput>
}

export type B2CAppHistoryRecordsUpdateInput = {
    data?: InputMaybe<B2CAppHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

/**  Configuration of sending push notifications for specific miniapps.Used to block certain types of push notifications and control throttling of notifications of a certain miniapp  */
export type B2CAppMessageSetting = {
    __typename?: 'B2CAppMessageSetting'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2CAppMessageSetting List config, or
   *  2. As an alias to the field set on 'labelField' in the B2CAppMessageSetting List config, or
   *  3. As an alias to a 'name' field on the B2CAppMessageSetting List (if one exists), or
   *  4. As an alias to the 'id' field on the B2CAppMessageSetting List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  Link to B2CApp to which this config applies  */
    app?: Maybe<B2CApp>
    /**  Reason for blocking messages with types from type  */
    blockReason?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  Is message type in black list for this B2CApp  */
    isBlacklisted?: Maybe<Scalars['Boolean']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Time window for sending push notifications in sec  */
    notificationWindowSize?: Maybe<Scalars['Int']['output']>
    /**  The number of notifications that can be sent in one time window in sec  */
    numberOfNotificationInWindow?: Maybe<Scalars['Int']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  The type of message we want to configure  */
    type?: Maybe<MessageType>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type B2CAppMessageSettingCreateInput = {
    app?: InputMaybe<B2CAppRelateToOneInput>
    blockReason?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    isBlacklisted?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    notificationWindowSize?: InputMaybe<Scalars['Int']['input']>
    numberOfNotificationInWindow?: InputMaybe<Scalars['Int']['input']>
    sender?: InputMaybe<SenderFieldInput>
    type?: InputMaybe<MessageType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type B2CAppMessageSettingHistoryRecord = {
    __typename?: 'B2CAppMessageSettingHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2CAppMessageSettingHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the B2CAppMessageSettingHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the B2CAppMessageSettingHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the B2CAppMessageSettingHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    app?: Maybe<Scalars['String']['output']>
    blockReason?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<B2CAppMessageSettingHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    isBlacklisted?: Maybe<Scalars['Boolean']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    notificationWindowSize?: Maybe<Scalars['Int']['output']>
    numberOfNotificationInWindow?: Maybe<Scalars['Int']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    type?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type B2CAppMessageSettingHistoryRecordCreateInput = {
    app?: InputMaybe<Scalars['String']['input']>
    blockReason?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<B2CAppMessageSettingHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    isBlacklisted?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    notificationWindowSize?: InputMaybe<Scalars['Int']['input']>
    numberOfNotificationInWindow?: InputMaybe<Scalars['Int']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    type?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum B2CAppMessageSettingHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type B2CAppMessageSettingHistoryRecordUpdateInput = {
    app?: InputMaybe<Scalars['String']['input']>
    blockReason?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<B2CAppMessageSettingHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    isBlacklisted?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    notificationWindowSize?: InputMaybe<Scalars['Int']['input']>
    numberOfNotificationInWindow?: InputMaybe<Scalars['Int']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    type?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type B2CAppMessageSettingHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<B2CAppMessageSettingHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<B2CAppMessageSettingHistoryRecordWhereInput>>>
    app?: InputMaybe<Scalars['String']['input']>
    app_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    app_not?: InputMaybe<Scalars['String']['input']>
    app_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    blockReason?: InputMaybe<Scalars['String']['input']>
    blockReason_contains?: InputMaybe<Scalars['String']['input']>
    blockReason_contains_i?: InputMaybe<Scalars['String']['input']>
    blockReason_ends_with?: InputMaybe<Scalars['String']['input']>
    blockReason_ends_with_i?: InputMaybe<Scalars['String']['input']>
    blockReason_i?: InputMaybe<Scalars['String']['input']>
    blockReason_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    blockReason_not?: InputMaybe<Scalars['String']['input']>
    blockReason_not_contains?: InputMaybe<Scalars['String']['input']>
    blockReason_not_contains_i?: InputMaybe<Scalars['String']['input']>
    blockReason_not_ends_with?: InputMaybe<Scalars['String']['input']>
    blockReason_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    blockReason_not_i?: InputMaybe<Scalars['String']['input']>
    blockReason_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    blockReason_not_starts_with?: InputMaybe<Scalars['String']['input']>
    blockReason_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    blockReason_starts_with?: InputMaybe<Scalars['String']['input']>
    blockReason_starts_with_i?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<B2CAppMessageSettingHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<B2CAppMessageSettingHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<B2CAppMessageSettingHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<B2CAppMessageSettingHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    isBlacklisted?: InputMaybe<Scalars['Boolean']['input']>
    isBlacklisted_not?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    notificationWindowSize?: InputMaybe<Scalars['Int']['input']>
    notificationWindowSize_gt?: InputMaybe<Scalars['Int']['input']>
    notificationWindowSize_gte?: InputMaybe<Scalars['Int']['input']>
    notificationWindowSize_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    notificationWindowSize_lt?: InputMaybe<Scalars['Int']['input']>
    notificationWindowSize_lte?: InputMaybe<Scalars['Int']['input']>
    notificationWindowSize_not?: InputMaybe<Scalars['Int']['input']>
    notificationWindowSize_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    numberOfNotificationInWindow?: InputMaybe<Scalars['Int']['input']>
    numberOfNotificationInWindow_gt?: InputMaybe<Scalars['Int']['input']>
    numberOfNotificationInWindow_gte?: InputMaybe<Scalars['Int']['input']>
    numberOfNotificationInWindow_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    numberOfNotificationInWindow_lt?: InputMaybe<Scalars['Int']['input']>
    numberOfNotificationInWindow_lte?: InputMaybe<Scalars['Int']['input']>
    numberOfNotificationInWindow_not?: InputMaybe<Scalars['Int']['input']>
    numberOfNotificationInWindow_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    type?: InputMaybe<Scalars['String']['input']>
    type_contains?: InputMaybe<Scalars['String']['input']>
    type_contains_i?: InputMaybe<Scalars['String']['input']>
    type_ends_with?: InputMaybe<Scalars['String']['input']>
    type_ends_with_i?: InputMaybe<Scalars['String']['input']>
    type_i?: InputMaybe<Scalars['String']['input']>
    type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    type_not?: InputMaybe<Scalars['String']['input']>
    type_not_contains?: InputMaybe<Scalars['String']['input']>
    type_not_contains_i?: InputMaybe<Scalars['String']['input']>
    type_not_ends_with?: InputMaybe<Scalars['String']['input']>
    type_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    type_not_i?: InputMaybe<Scalars['String']['input']>
    type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    type_not_starts_with?: InputMaybe<Scalars['String']['input']>
    type_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    type_starts_with?: InputMaybe<Scalars['String']['input']>
    type_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type B2CAppMessageSettingHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type B2CAppMessageSettingHistoryRecordsCreateInput = {
    data?: InputMaybe<B2CAppMessageSettingHistoryRecordCreateInput>
}

export type B2CAppMessageSettingHistoryRecordsUpdateInput = {
    data?: InputMaybe<B2CAppMessageSettingHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type B2CAppMessageSettingUpdateInput = {
    app?: InputMaybe<B2CAppRelateToOneInput>
    blockReason?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    isBlacklisted?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    notificationWindowSize?: InputMaybe<Scalars['Int']['input']>
    numberOfNotificationInWindow?: InputMaybe<Scalars['Int']['input']>
    sender?: InputMaybe<SenderFieldInput>
    type?: InputMaybe<MessageType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type B2CAppMessageSettingWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<B2CAppMessageSettingWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<B2CAppMessageSettingWhereInput>>>
    app?: InputMaybe<B2CAppWhereInput>
    app_is_null?: InputMaybe<Scalars['Boolean']['input']>
    blockReason?: InputMaybe<Scalars['String']['input']>
    blockReason_contains?: InputMaybe<Scalars['String']['input']>
    blockReason_contains_i?: InputMaybe<Scalars['String']['input']>
    blockReason_ends_with?: InputMaybe<Scalars['String']['input']>
    blockReason_ends_with_i?: InputMaybe<Scalars['String']['input']>
    blockReason_i?: InputMaybe<Scalars['String']['input']>
    blockReason_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    blockReason_not?: InputMaybe<Scalars['String']['input']>
    blockReason_not_contains?: InputMaybe<Scalars['String']['input']>
    blockReason_not_contains_i?: InputMaybe<Scalars['String']['input']>
    blockReason_not_ends_with?: InputMaybe<Scalars['String']['input']>
    blockReason_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    blockReason_not_i?: InputMaybe<Scalars['String']['input']>
    blockReason_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    blockReason_not_starts_with?: InputMaybe<Scalars['String']['input']>
    blockReason_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    blockReason_starts_with?: InputMaybe<Scalars['String']['input']>
    blockReason_starts_with_i?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    isBlacklisted?: InputMaybe<Scalars['Boolean']['input']>
    isBlacklisted_not?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    notificationWindowSize?: InputMaybe<Scalars['Int']['input']>
    notificationWindowSize_gt?: InputMaybe<Scalars['Int']['input']>
    notificationWindowSize_gte?: InputMaybe<Scalars['Int']['input']>
    notificationWindowSize_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    notificationWindowSize_lt?: InputMaybe<Scalars['Int']['input']>
    notificationWindowSize_lte?: InputMaybe<Scalars['Int']['input']>
    notificationWindowSize_not?: InputMaybe<Scalars['Int']['input']>
    notificationWindowSize_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    numberOfNotificationInWindow?: InputMaybe<Scalars['Int']['input']>
    numberOfNotificationInWindow_gt?: InputMaybe<Scalars['Int']['input']>
    numberOfNotificationInWindow_gte?: InputMaybe<Scalars['Int']['input']>
    numberOfNotificationInWindow_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    numberOfNotificationInWindow_lt?: InputMaybe<Scalars['Int']['input']>
    numberOfNotificationInWindow_lte?: InputMaybe<Scalars['Int']['input']>
    numberOfNotificationInWindow_not?: InputMaybe<Scalars['Int']['input']>
    numberOfNotificationInWindow_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    type?: InputMaybe<MessageType>
    type_in?: InputMaybe<Array<InputMaybe<MessageType>>>
    type_not?: InputMaybe<MessageType>
    type_not_in?: InputMaybe<Array<InputMaybe<MessageType>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type B2CAppMessageSettingWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type B2CAppMessageSettingsCreateInput = {
    data?: InputMaybe<B2CAppMessageSettingCreateInput>
}

export type B2CAppMessageSettingsUpdateInput = {
    data?: InputMaybe<B2CAppMessageSettingUpdateInput>
    id: Scalars['ID']['input']
}

export type B2CAppPropertiesCreateInput = {
    data?: InputMaybe<B2CAppPropertyCreateInput>
}

export type B2CAppPropertiesUpdateInput = {
    data?: InputMaybe<B2CAppPropertyUpdateInput>
    id: Scalars['ID']['input']
}

/**  Link between specific home address and B2C App. used to filter B2C applications that can be run on a specific address  */
export type B2CAppProperty = {
    __typename?: 'B2CAppProperty'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2CAppProperty List config, or
   *  2. As an alias to the field set on 'labelField' in the B2CAppProperty List config, or
   *  3. As an alias to a 'name' field on the B2CAppProperty List (if one exists), or
   *  4. As an alias to the 'id' field on the B2CAppProperty List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  Normalized address  */
    address?: Maybe<Scalars['String']['output']>
    /**  The unique key of the address  */
    addressKey?: Maybe<Scalars['String']['output']>
    /**  Property address components  */
    addressMeta?: Maybe<AddressMetaField>
    /**  The origins of the address (some strings which may looks like real address or some id)  */
    addressSources?: Maybe<Scalars['JSON']['output']>
    /**  Link to B2C App  */
    app?: Maybe<B2CApp>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type B2CAppPropertyCreateInput = {
    address?: InputMaybe<Scalars['String']['input']>
    addressKey?: InputMaybe<Scalars['String']['input']>
    addressMeta?: InputMaybe<Scalars['JSON']['input']>
    addressSources?: InputMaybe<Scalars['JSON']['input']>
    app?: InputMaybe<B2CAppRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type B2CAppPropertyHistoryRecord = {
    __typename?: 'B2CAppPropertyHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2CAppPropertyHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the B2CAppPropertyHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the B2CAppPropertyHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the B2CAppPropertyHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    address?: Maybe<Scalars['String']['output']>
    addressKey?: Maybe<Scalars['String']['output']>
    addressMeta?: Maybe<Scalars['JSON']['output']>
    addressSources?: Maybe<Scalars['JSON']['output']>
    app?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<B2CAppPropertyHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type B2CAppPropertyHistoryRecordCreateInput = {
    address?: InputMaybe<Scalars['String']['input']>
    addressKey?: InputMaybe<Scalars['String']['input']>
    addressMeta?: InputMaybe<Scalars['JSON']['input']>
    addressSources?: InputMaybe<Scalars['JSON']['input']>
    app?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<B2CAppPropertyHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum B2CAppPropertyHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type B2CAppPropertyHistoryRecordUpdateInput = {
    address?: InputMaybe<Scalars['String']['input']>
    addressKey?: InputMaybe<Scalars['String']['input']>
    addressMeta?: InputMaybe<Scalars['JSON']['input']>
    addressSources?: InputMaybe<Scalars['JSON']['input']>
    app?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<B2CAppPropertyHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type B2CAppPropertyHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<B2CAppPropertyHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<B2CAppPropertyHistoryRecordWhereInput>>>
    address?: InputMaybe<Scalars['String']['input']>
    addressKey?: InputMaybe<Scalars['String']['input']>
    addressKey_contains?: InputMaybe<Scalars['String']['input']>
    addressKey_contains_i?: InputMaybe<Scalars['String']['input']>
    addressKey_ends_with?: InputMaybe<Scalars['String']['input']>
    addressKey_ends_with_i?: InputMaybe<Scalars['String']['input']>
    addressKey_i?: InputMaybe<Scalars['String']['input']>
    addressKey_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    addressKey_not?: InputMaybe<Scalars['String']['input']>
    addressKey_not_contains?: InputMaybe<Scalars['String']['input']>
    addressKey_not_contains_i?: InputMaybe<Scalars['String']['input']>
    addressKey_not_ends_with?: InputMaybe<Scalars['String']['input']>
    addressKey_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    addressKey_not_i?: InputMaybe<Scalars['String']['input']>
    addressKey_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    addressKey_not_starts_with?: InputMaybe<Scalars['String']['input']>
    addressKey_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    addressKey_starts_with?: InputMaybe<Scalars['String']['input']>
    addressKey_starts_with_i?: InputMaybe<Scalars['String']['input']>
    addressMeta?: InputMaybe<Scalars['JSON']['input']>
    addressMeta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    addressMeta_not?: InputMaybe<Scalars['JSON']['input']>
    addressMeta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    addressSources?: InputMaybe<Scalars['JSON']['input']>
    addressSources_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    addressSources_not?: InputMaybe<Scalars['JSON']['input']>
    addressSources_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    address_contains?: InputMaybe<Scalars['String']['input']>
    address_contains_i?: InputMaybe<Scalars['String']['input']>
    address_ends_with?: InputMaybe<Scalars['String']['input']>
    address_ends_with_i?: InputMaybe<Scalars['String']['input']>
    address_i?: InputMaybe<Scalars['String']['input']>
    address_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    address_not?: InputMaybe<Scalars['String']['input']>
    address_not_contains?: InputMaybe<Scalars['String']['input']>
    address_not_contains_i?: InputMaybe<Scalars['String']['input']>
    address_not_ends_with?: InputMaybe<Scalars['String']['input']>
    address_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    address_not_i?: InputMaybe<Scalars['String']['input']>
    address_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    address_not_starts_with?: InputMaybe<Scalars['String']['input']>
    address_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    address_starts_with?: InputMaybe<Scalars['String']['input']>
    address_starts_with_i?: InputMaybe<Scalars['String']['input']>
    app?: InputMaybe<Scalars['String']['input']>
    app_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    app_not?: InputMaybe<Scalars['String']['input']>
    app_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<B2CAppPropertyHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<B2CAppPropertyHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<B2CAppPropertyHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<B2CAppPropertyHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type B2CAppPropertyHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type B2CAppPropertyHistoryRecordsCreateInput = {
    data?: InputMaybe<B2CAppPropertyHistoryRecordCreateInput>
}

export type B2CAppPropertyHistoryRecordsUpdateInput = {
    data?: InputMaybe<B2CAppPropertyHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type B2CAppPropertyUpdateInput = {
    address?: InputMaybe<Scalars['String']['input']>
    addressKey?: InputMaybe<Scalars['String']['input']>
    addressMeta?: InputMaybe<Scalars['JSON']['input']>
    addressSources?: InputMaybe<Scalars['JSON']['input']>
    app?: InputMaybe<B2CAppRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type B2CAppPropertyWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<B2CAppPropertyWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<B2CAppPropertyWhereInput>>>
    address?: InputMaybe<Scalars['String']['input']>
    addressKey?: InputMaybe<Scalars['String']['input']>
    addressKey_contains?: InputMaybe<Scalars['String']['input']>
    addressKey_contains_i?: InputMaybe<Scalars['String']['input']>
    addressKey_ends_with?: InputMaybe<Scalars['String']['input']>
    addressKey_ends_with_i?: InputMaybe<Scalars['String']['input']>
    addressKey_i?: InputMaybe<Scalars['String']['input']>
    addressKey_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    addressKey_not?: InputMaybe<Scalars['String']['input']>
    addressKey_not_contains?: InputMaybe<Scalars['String']['input']>
    addressKey_not_contains_i?: InputMaybe<Scalars['String']['input']>
    addressKey_not_ends_with?: InputMaybe<Scalars['String']['input']>
    addressKey_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    addressKey_not_i?: InputMaybe<Scalars['String']['input']>
    addressKey_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    addressKey_not_starts_with?: InputMaybe<Scalars['String']['input']>
    addressKey_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    addressKey_starts_with?: InputMaybe<Scalars['String']['input']>
    addressKey_starts_with_i?: InputMaybe<Scalars['String']['input']>
    addressMeta?: InputMaybe<Scalars['JSON']['input']>
    addressMeta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    addressMeta_not?: InputMaybe<Scalars['JSON']['input']>
    addressMeta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    addressSources?: InputMaybe<Scalars['JSON']['input']>
    addressSources_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    addressSources_not?: InputMaybe<Scalars['JSON']['input']>
    addressSources_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    address_contains?: InputMaybe<Scalars['String']['input']>
    address_contains_i?: InputMaybe<Scalars['String']['input']>
    address_ends_with?: InputMaybe<Scalars['String']['input']>
    address_ends_with_i?: InputMaybe<Scalars['String']['input']>
    address_i?: InputMaybe<Scalars['String']['input']>
    address_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    address_not?: InputMaybe<Scalars['String']['input']>
    address_not_contains?: InputMaybe<Scalars['String']['input']>
    address_not_contains_i?: InputMaybe<Scalars['String']['input']>
    address_not_ends_with?: InputMaybe<Scalars['String']['input']>
    address_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    address_not_i?: InputMaybe<Scalars['String']['input']>
    address_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    address_not_starts_with?: InputMaybe<Scalars['String']['input']>
    address_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    address_starts_with?: InputMaybe<Scalars['String']['input']>
    address_starts_with_i?: InputMaybe<Scalars['String']['input']>
    app?: InputMaybe<B2CAppWhereInput>
    app_is_null?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type B2CAppPropertyWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type B2CAppRelateToOneInput = {
    connect?: InputMaybe<B2CAppWhereUniqueInput>
    create?: InputMaybe<B2CAppCreateInput>
    disconnect?: InputMaybe<B2CAppWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type B2CAppUpdateInput = {
    accessRights?: InputMaybe<B2CAppAccessRightRelateToManyInput>
    colorSchema?: InputMaybe<AppColorSchemaFieldInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    currentBuild?: InputMaybe<B2CAppBuildRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    developer?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem?: InputMaybe<Scalars['String']['input']>
    isHidden?: InputMaybe<Scalars['Boolean']['input']>
    logo?: InputMaybe<Scalars['Upload']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    shortDescription?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type B2CAppWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<B2CAppWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<B2CAppWhereInput>>>
    /**  condition must be true for all nodes  */
    accessRights_every?: InputMaybe<B2CAppAccessRightWhereInput>
    /**  condition must be false for all nodes  */
    accessRights_none?: InputMaybe<B2CAppAccessRightWhereInput>
    /**  condition must be true for at least 1 node  */
    accessRights_some?: InputMaybe<B2CAppAccessRightWhereInput>
    colorSchema?: InputMaybe<AppColorSchemaFieldInput>
    colorSchema_in?: InputMaybe<Array<InputMaybe<AppColorSchemaFieldInput>>>
    colorSchema_not?: InputMaybe<AppColorSchemaFieldInput>
    colorSchema_not_in?: InputMaybe<Array<InputMaybe<AppColorSchemaFieldInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    currentBuild?: InputMaybe<B2CAppBuildWhereInput>
    currentBuild_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    developer?: InputMaybe<Scalars['String']['input']>
    developer_contains?: InputMaybe<Scalars['String']['input']>
    developer_contains_i?: InputMaybe<Scalars['String']['input']>
    developer_ends_with?: InputMaybe<Scalars['String']['input']>
    developer_ends_with_i?: InputMaybe<Scalars['String']['input']>
    developer_i?: InputMaybe<Scalars['String']['input']>
    developer_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    developer_not?: InputMaybe<Scalars['String']['input']>
    developer_not_contains?: InputMaybe<Scalars['String']['input']>
    developer_not_contains_i?: InputMaybe<Scalars['String']['input']>
    developer_not_ends_with?: InputMaybe<Scalars['String']['input']>
    developer_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    developer_not_i?: InputMaybe<Scalars['String']['input']>
    developer_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    developer_not_starts_with?: InputMaybe<Scalars['String']['input']>
    developer_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    developer_starts_with?: InputMaybe<Scalars['String']['input']>
    developer_starts_with_i?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    importId?: InputMaybe<Scalars['String']['input']>
    importId_contains?: InputMaybe<Scalars['String']['input']>
    importId_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_i?: InputMaybe<Scalars['String']['input']>
    importId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not?: InputMaybe<Scalars['String']['input']>
    importId_not_contains?: InputMaybe<Scalars['String']['input']>
    importId_not_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_not_i?: InputMaybe<Scalars['String']['input']>
    importId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importId_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_contains?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_contains_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_ends_with?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importRemoteSystem_not?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_contains?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_contains_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_ends_with?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importRemoteSystem_not_starts_with?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_starts_with?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_starts_with_i?: InputMaybe<Scalars['String']['input']>
    isHidden?: InputMaybe<Scalars['Boolean']['input']>
    isHidden_not?: InputMaybe<Scalars['Boolean']['input']>
    logo?: InputMaybe<Scalars['String']['input']>
    logo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    logo_not?: InputMaybe<Scalars['String']['input']>
    logo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    shortDescription?: InputMaybe<Scalars['String']['input']>
    shortDescription_contains?: InputMaybe<Scalars['String']['input']>
    shortDescription_contains_i?: InputMaybe<Scalars['String']['input']>
    shortDescription_ends_with?: InputMaybe<Scalars['String']['input']>
    shortDescription_ends_with_i?: InputMaybe<Scalars['String']['input']>
    shortDescription_i?: InputMaybe<Scalars['String']['input']>
    shortDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    shortDescription_not?: InputMaybe<Scalars['String']['input']>
    shortDescription_not_contains?: InputMaybe<Scalars['String']['input']>
    shortDescription_not_contains_i?: InputMaybe<Scalars['String']['input']>
    shortDescription_not_ends_with?: InputMaybe<Scalars['String']['input']>
    shortDescription_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    shortDescription_not_i?: InputMaybe<Scalars['String']['input']>
    shortDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    shortDescription_not_starts_with?: InputMaybe<Scalars['String']['input']>
    shortDescription_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    shortDescription_starts_with?: InputMaybe<Scalars['String']['input']>
    shortDescription_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type B2CAppWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type B2CAppsCreateInput = {
    data?: InputMaybe<B2CAppCreateInput>
}

export type B2CAppsUpdateInput = {
    data?: InputMaybe<B2CAppUpdateInput>
    id: Scalars['ID']['input']
}

/**  Bank account, that will have transactions, pulled from various integrated data sources  */
export type BankAccount = {
    __typename?: 'BankAccount'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankAccount List config, or
   *  2. As an alias to the field set on 'labelField' in the BankAccount List config, or
   *  3. As an alias to a 'name' field on the BankAccount List (if one exists), or
   *  4. As an alias to the 'id' field on the BankAccount List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  When the bank account received the status of approved  */
    approvedAt?: Maybe<Scalars['String']['output']>
    /**  Who set the approved status for the bank account  */
    approvedBy?: Maybe<User>
    /**  Bank name  */
    bankName?: Maybe<Scalars['String']['output']>
    /**  Budget classification code, used for state-funded organizations  */
    classificationCode?: Maybe<Scalars['String']['output']>
    /**  Country where the bank is located  */
    country?: Maybe<BankAccountCountryType>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    /**  Code of currency in ISO-4217 format  */
    currencyCode?: Maybe<BankAccountCurrencyCodeType>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  Id of object in external service which represents current item. Mostly used for internal needs of integration services for matching our objects with theirs  */
    importId?: Maybe<Scalars['String']['output']>
    /**  Used data source to obtain transactions from  */
    integrationContext?: Maybe<BankIntegrationAccountContext>
    /**  Shows whether the bank account approved or not  */
    isApproved?: Maybe<Scalars['Boolean']['output']>
    /**  Structured non-typed metadata, can be used by mini-apps or external services to store information  */
    meta?: Maybe<Scalars['JSON']['output']>
    /**  Bank account name to display  */
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Bank account number  */
    number?: Maybe<Scalars['String']['output']>
    /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
    organization?: Maybe<Organization>
    /**  Property to which this bank account is connected  */
    property?: Maybe<Property>
    /**  The routing transit number for the bank account.  */
    routingNumber?: Maybe<Scalars['String']['output']>
    /**  Structured metadata found by routing number  */
    routingNumberMeta?: Maybe<Scalars['JSON']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Location of the holder of this bank account. It depends on a country. In Russia it is OKTMO  */
    territoryCode?: Maybe<Scalars['String']['output']>
    /**  Tax Identification Number  */
    tin?: Maybe<Scalars['String']['output']>
    /**  Structured metadata found by tin  */
    tinMeta?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export enum BankAccountCountryType {
    En = 'en',
    Ru = 'ru',
}

export type BankAccountCreateInput = {
    approvedAt?: InputMaybe<Scalars['String']['input']>
    approvedBy?: InputMaybe<UserRelateToOneInput>
    bankName?: InputMaybe<Scalars['String']['input']>
    classificationCode?: InputMaybe<Scalars['String']['input']>
    country?: InputMaybe<BankAccountCountryType>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    currencyCode?: InputMaybe<BankAccountCurrencyCodeType>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    integrationContext?: InputMaybe<BankIntegrationAccountContextRelateToOneInput>
    isApproved?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    number?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    property?: InputMaybe<PropertyRelateToOneInput>
    routingNumber?: InputMaybe<Scalars['String']['input']>
    routingNumberMeta?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<SenderFieldInput>
    territoryCode?: InputMaybe<Scalars['String']['input']>
    tin?: InputMaybe<Scalars['String']['input']>
    tinMeta?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum BankAccountCurrencyCodeType {
    Aed = 'AED',
    Afn = 'AFN',
    All = 'ALL',
    Amd = 'AMD',
    Ang = 'ANG',
    Aoa = 'AOA',
    Ars = 'ARS',
    Aud = 'AUD',
    Awg = 'AWG',
    Azn = 'AZN',
    Bam = 'BAM',
    Bbd = 'BBD',
    Bdt = 'BDT',
    Bgn = 'BGN',
    Bhd = 'BHD',
    Bif = 'BIF',
    Bmd = 'BMD',
    Bnd = 'BND',
    Bob = 'BOB',
    Bov = 'BOV',
    Brl = 'BRL',
    Bsd = 'BSD',
    Btn = 'BTN',
    Bwp = 'BWP',
    Byn = 'BYN',
    Bzd = 'BZD',
    Cad = 'CAD',
    Cdf = 'CDF',
    Che = 'CHE',
    Chf = 'CHF',
    Chw = 'CHW',
    Clf = 'CLF',
    Clp = 'CLP',
    Cny = 'CNY',
    Cop = 'COP',
    Cou = 'COU',
    Crc = 'CRC',
    Cuc = 'CUC',
    Cup = 'CUP',
    Cve = 'CVE',
    Czk = 'CZK',
    Djf = 'DJF',
    Dkk = 'DKK',
    Dop = 'DOP',
    Dzd = 'DZD',
    Egp = 'EGP',
    Ern = 'ERN',
    Etb = 'ETB',
    Eur = 'EUR',
    Fjd = 'FJD',
    Fkp = 'FKP',
    Gbp = 'GBP',
    Gel = 'GEL',
    Ghs = 'GHS',
    Gip = 'GIP',
    Gmd = 'GMD',
    Gnf = 'GNF',
    Gtq = 'GTQ',
    Gyd = 'GYD',
    Hkd = 'HKD',
    Hnl = 'HNL',
    Hrk = 'HRK',
    Htg = 'HTG',
    Huf = 'HUF',
    Idr = 'IDR',
    Ils = 'ILS',
    Inr = 'INR',
    Iqd = 'IQD',
    Irr = 'IRR',
    Isk = 'ISK',
    Jmd = 'JMD',
    Jod = 'JOD',
    Jpy = 'JPY',
    Kes = 'KES',
    Kgs = 'KGS',
    Khr = 'KHR',
    Kmf = 'KMF',
    Kpw = 'KPW',
    Krw = 'KRW',
    Kwd = 'KWD',
    Kyd = 'KYD',
    Kzt = 'KZT',
    Lak = 'LAK',
    Lbp = 'LBP',
    Lkr = 'LKR',
    Lrd = 'LRD',
    Lsl = 'LSL',
    Lyd = 'LYD',
    Mad = 'MAD',
    Mdl = 'MDL',
    Mga = 'MGA',
    Mkd = 'MKD',
    Mmk = 'MMK',
    Mnt = 'MNT',
    Mop = 'MOP',
    Mru = 'MRU',
    Mur = 'MUR',
    Mvr = 'MVR',
    Mwk = 'MWK',
    Mxn = 'MXN',
    Mxv = 'MXV',
    Myr = 'MYR',
    Mzn = 'MZN',
    Nad = 'NAD',
    Ngn = 'NGN',
    Nio = 'NIO',
    Nok = 'NOK',
    Npr = 'NPR',
    Nzd = 'NZD',
    Omr = 'OMR',
    Pab = 'PAB',
    Pen = 'PEN',
    Pgk = 'PGK',
    Php = 'PHP',
    Pkr = 'PKR',
    Pln = 'PLN',
    Pyg = 'PYG',
    Qar = 'QAR',
    Ron = 'RON',
    Rsd = 'RSD',
    Rub = 'RUB',
    Rwf = 'RWF',
    Sar = 'SAR',
    Sbd = 'SBD',
    Scr = 'SCR',
    Sdg = 'SDG',
    Sek = 'SEK',
    Sgd = 'SGD',
    Shp = 'SHP',
    Sll = 'SLL',
    Sos = 'SOS',
    Srd = 'SRD',
    Ssp = 'SSP',
    Stn = 'STN',
    Svc = 'SVC',
    Syp = 'SYP',
    Szl = 'SZL',
    Thb = 'THB',
    Tjs = 'TJS',
    Tmt = 'TMT',
    Tnd = 'TND',
    Top = 'TOP',
    Try = 'TRY',
    Ttd = 'TTD',
    Twd = 'TWD',
    Tzs = 'TZS',
    Uah = 'UAH',
    Ugx = 'UGX',
    Usd = 'USD',
    Usn = 'USN',
    Uyi = 'UYI',
    Uyu = 'UYU',
    Uyw = 'UYW',
    Uzs = 'UZS',
    Ves = 'VES',
    Vnd = 'VND',
    Vuv = 'VUV',
    Wst = 'WST',
    Xaf = 'XAF',
    Xag = 'XAG',
    Xau = 'XAU',
    Xba = 'XBA',
    Xbb = 'XBB',
    Xbc = 'XBC',
    Xbd = 'XBD',
    Xcd = 'XCD',
    Xdr = 'XDR',
    Xof = 'XOF',
    Xpd = 'XPD',
    Xpf = 'XPF',
    Xpt = 'XPT',
    Xsu = 'XSU',
    Xts = 'XTS',
    Xua = 'XUA',
    Yer = 'YER',
    Zar = 'ZAR',
    Zmw = 'ZMW',
    Zwl = 'ZWL',
}

/**  A keystone list  */
export type BankAccountHistoryRecord = {
    __typename?: 'BankAccountHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankAccountHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the BankAccountHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the BankAccountHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the BankAccountHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    approvedAt?: Maybe<Scalars['String']['output']>
    approvedBy?: Maybe<Scalars['String']['output']>
    bankName?: Maybe<Scalars['String']['output']>
    classificationCode?: Maybe<Scalars['String']['output']>
    country?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    currencyCode?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<BankAccountHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    importId?: Maybe<Scalars['String']['output']>
    integrationContext?: Maybe<Scalars['String']['output']>
    isApproved?: Maybe<Scalars['Boolean']['output']>
    meta?: Maybe<Scalars['JSON']['output']>
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    number?: Maybe<Scalars['String']['output']>
    organization?: Maybe<Scalars['String']['output']>
    property?: Maybe<Scalars['String']['output']>
    routingNumber?: Maybe<Scalars['String']['output']>
    routingNumberMeta?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    territoryCode?: Maybe<Scalars['String']['output']>
    tin?: Maybe<Scalars['String']['output']>
    tinMeta?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type BankAccountHistoryRecordCreateInput = {
    approvedAt?: InputMaybe<Scalars['String']['input']>
    approvedBy?: InputMaybe<Scalars['String']['input']>
    bankName?: InputMaybe<Scalars['String']['input']>
    classificationCode?: InputMaybe<Scalars['String']['input']>
    country?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    currencyCode?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<BankAccountHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    integrationContext?: InputMaybe<Scalars['String']['input']>
    isApproved?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    number?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    property?: InputMaybe<Scalars['String']['input']>
    routingNumber?: InputMaybe<Scalars['String']['input']>
    routingNumberMeta?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    territoryCode?: InputMaybe<Scalars['String']['input']>
    tin?: InputMaybe<Scalars['String']['input']>
    tinMeta?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum BankAccountHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type BankAccountHistoryRecordUpdateInput = {
    approvedAt?: InputMaybe<Scalars['String']['input']>
    approvedBy?: InputMaybe<Scalars['String']['input']>
    bankName?: InputMaybe<Scalars['String']['input']>
    classificationCode?: InputMaybe<Scalars['String']['input']>
    country?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    currencyCode?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<BankAccountHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    integrationContext?: InputMaybe<Scalars['String']['input']>
    isApproved?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    number?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    property?: InputMaybe<Scalars['String']['input']>
    routingNumber?: InputMaybe<Scalars['String']['input']>
    routingNumberMeta?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    territoryCode?: InputMaybe<Scalars['String']['input']>
    tin?: InputMaybe<Scalars['String']['input']>
    tinMeta?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type BankAccountHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<BankAccountHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<BankAccountHistoryRecordWhereInput>>>
    approvedAt?: InputMaybe<Scalars['String']['input']>
    approvedAt_gt?: InputMaybe<Scalars['String']['input']>
    approvedAt_gte?: InputMaybe<Scalars['String']['input']>
    approvedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    approvedAt_lt?: InputMaybe<Scalars['String']['input']>
    approvedAt_lte?: InputMaybe<Scalars['String']['input']>
    approvedAt_not?: InputMaybe<Scalars['String']['input']>
    approvedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    approvedBy?: InputMaybe<Scalars['String']['input']>
    approvedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    approvedBy_not?: InputMaybe<Scalars['String']['input']>
    approvedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    bankName?: InputMaybe<Scalars['String']['input']>
    bankName_contains?: InputMaybe<Scalars['String']['input']>
    bankName_contains_i?: InputMaybe<Scalars['String']['input']>
    bankName_ends_with?: InputMaybe<Scalars['String']['input']>
    bankName_ends_with_i?: InputMaybe<Scalars['String']['input']>
    bankName_i?: InputMaybe<Scalars['String']['input']>
    bankName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    bankName_not?: InputMaybe<Scalars['String']['input']>
    bankName_not_contains?: InputMaybe<Scalars['String']['input']>
    bankName_not_contains_i?: InputMaybe<Scalars['String']['input']>
    bankName_not_ends_with?: InputMaybe<Scalars['String']['input']>
    bankName_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    bankName_not_i?: InputMaybe<Scalars['String']['input']>
    bankName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    bankName_not_starts_with?: InputMaybe<Scalars['String']['input']>
    bankName_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    bankName_starts_with?: InputMaybe<Scalars['String']['input']>
    bankName_starts_with_i?: InputMaybe<Scalars['String']['input']>
    classificationCode?: InputMaybe<Scalars['String']['input']>
    classificationCode_contains?: InputMaybe<Scalars['String']['input']>
    classificationCode_contains_i?: InputMaybe<Scalars['String']['input']>
    classificationCode_ends_with?: InputMaybe<Scalars['String']['input']>
    classificationCode_ends_with_i?: InputMaybe<Scalars['String']['input']>
    classificationCode_i?: InputMaybe<Scalars['String']['input']>
    classificationCode_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    classificationCode_not?: InputMaybe<Scalars['String']['input']>
    classificationCode_not_contains?: InputMaybe<Scalars['String']['input']>
    classificationCode_not_contains_i?: InputMaybe<Scalars['String']['input']>
    classificationCode_not_ends_with?: InputMaybe<Scalars['String']['input']>
    classificationCode_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    classificationCode_not_i?: InputMaybe<Scalars['String']['input']>
    classificationCode_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    classificationCode_not_starts_with?: InputMaybe<Scalars['String']['input']>
    classificationCode_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    classificationCode_starts_with?: InputMaybe<Scalars['String']['input']>
    classificationCode_starts_with_i?: InputMaybe<Scalars['String']['input']>
    country?: InputMaybe<Scalars['String']['input']>
    country_contains?: InputMaybe<Scalars['String']['input']>
    country_contains_i?: InputMaybe<Scalars['String']['input']>
    country_ends_with?: InputMaybe<Scalars['String']['input']>
    country_ends_with_i?: InputMaybe<Scalars['String']['input']>
    country_i?: InputMaybe<Scalars['String']['input']>
    country_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    country_not?: InputMaybe<Scalars['String']['input']>
    country_not_contains?: InputMaybe<Scalars['String']['input']>
    country_not_contains_i?: InputMaybe<Scalars['String']['input']>
    country_not_ends_with?: InputMaybe<Scalars['String']['input']>
    country_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    country_not_i?: InputMaybe<Scalars['String']['input']>
    country_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    country_not_starts_with?: InputMaybe<Scalars['String']['input']>
    country_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    country_starts_with?: InputMaybe<Scalars['String']['input']>
    country_starts_with_i?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    currencyCode?: InputMaybe<Scalars['String']['input']>
    currencyCode_contains?: InputMaybe<Scalars['String']['input']>
    currencyCode_contains_i?: InputMaybe<Scalars['String']['input']>
    currencyCode_ends_with?: InputMaybe<Scalars['String']['input']>
    currencyCode_ends_with_i?: InputMaybe<Scalars['String']['input']>
    currencyCode_i?: InputMaybe<Scalars['String']['input']>
    currencyCode_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    currencyCode_not?: InputMaybe<Scalars['String']['input']>
    currencyCode_not_contains?: InputMaybe<Scalars['String']['input']>
    currencyCode_not_contains_i?: InputMaybe<Scalars['String']['input']>
    currencyCode_not_ends_with?: InputMaybe<Scalars['String']['input']>
    currencyCode_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    currencyCode_not_i?: InputMaybe<Scalars['String']['input']>
    currencyCode_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    currencyCode_not_starts_with?: InputMaybe<Scalars['String']['input']>
    currencyCode_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    currencyCode_starts_with?: InputMaybe<Scalars['String']['input']>
    currencyCode_starts_with_i?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<BankAccountHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<BankAccountHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<BankAccountHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<BankAccountHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    importId?: InputMaybe<Scalars['String']['input']>
    importId_contains?: InputMaybe<Scalars['String']['input']>
    importId_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_i?: InputMaybe<Scalars['String']['input']>
    importId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not?: InputMaybe<Scalars['String']['input']>
    importId_not_contains?: InputMaybe<Scalars['String']['input']>
    importId_not_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_not_i?: InputMaybe<Scalars['String']['input']>
    importId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importId_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_starts_with_i?: InputMaybe<Scalars['String']['input']>
    integrationContext?: InputMaybe<Scalars['String']['input']>
    integrationContext_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    integrationContext_not?: InputMaybe<Scalars['String']['input']>
    integrationContext_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    isApproved?: InputMaybe<Scalars['Boolean']['input']>
    isApproved_not?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    number?: InputMaybe<Scalars['String']['input']>
    number_contains?: InputMaybe<Scalars['String']['input']>
    number_contains_i?: InputMaybe<Scalars['String']['input']>
    number_ends_with?: InputMaybe<Scalars['String']['input']>
    number_ends_with_i?: InputMaybe<Scalars['String']['input']>
    number_i?: InputMaybe<Scalars['String']['input']>
    number_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    number_not?: InputMaybe<Scalars['String']['input']>
    number_not_contains?: InputMaybe<Scalars['String']['input']>
    number_not_contains_i?: InputMaybe<Scalars['String']['input']>
    number_not_ends_with?: InputMaybe<Scalars['String']['input']>
    number_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    number_not_i?: InputMaybe<Scalars['String']['input']>
    number_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    number_not_starts_with?: InputMaybe<Scalars['String']['input']>
    number_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    number_starts_with?: InputMaybe<Scalars['String']['input']>
    number_starts_with_i?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    property?: InputMaybe<Scalars['String']['input']>
    property_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    property_not?: InputMaybe<Scalars['String']['input']>
    property_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    routingNumber?: InputMaybe<Scalars['String']['input']>
    routingNumberMeta?: InputMaybe<Scalars['JSON']['input']>
    routingNumberMeta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    routingNumberMeta_not?: InputMaybe<Scalars['JSON']['input']>
    routingNumberMeta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    routingNumber_contains?: InputMaybe<Scalars['String']['input']>
    routingNumber_contains_i?: InputMaybe<Scalars['String']['input']>
    routingNumber_ends_with?: InputMaybe<Scalars['String']['input']>
    routingNumber_ends_with_i?: InputMaybe<Scalars['String']['input']>
    routingNumber_i?: InputMaybe<Scalars['String']['input']>
    routingNumber_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    routingNumber_not?: InputMaybe<Scalars['String']['input']>
    routingNumber_not_contains?: InputMaybe<Scalars['String']['input']>
    routingNumber_not_contains_i?: InputMaybe<Scalars['String']['input']>
    routingNumber_not_ends_with?: InputMaybe<Scalars['String']['input']>
    routingNumber_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    routingNumber_not_i?: InputMaybe<Scalars['String']['input']>
    routingNumber_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    routingNumber_not_starts_with?: InputMaybe<Scalars['String']['input']>
    routingNumber_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    routingNumber_starts_with?: InputMaybe<Scalars['String']['input']>
    routingNumber_starts_with_i?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    territoryCode?: InputMaybe<Scalars['String']['input']>
    territoryCode_contains?: InputMaybe<Scalars['String']['input']>
    territoryCode_contains_i?: InputMaybe<Scalars['String']['input']>
    territoryCode_ends_with?: InputMaybe<Scalars['String']['input']>
    territoryCode_ends_with_i?: InputMaybe<Scalars['String']['input']>
    territoryCode_i?: InputMaybe<Scalars['String']['input']>
    territoryCode_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    territoryCode_not?: InputMaybe<Scalars['String']['input']>
    territoryCode_not_contains?: InputMaybe<Scalars['String']['input']>
    territoryCode_not_contains_i?: InputMaybe<Scalars['String']['input']>
    territoryCode_not_ends_with?: InputMaybe<Scalars['String']['input']>
    territoryCode_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    territoryCode_not_i?: InputMaybe<Scalars['String']['input']>
    territoryCode_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    territoryCode_not_starts_with?: InputMaybe<Scalars['String']['input']>
    territoryCode_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    territoryCode_starts_with?: InputMaybe<Scalars['String']['input']>
    territoryCode_starts_with_i?: InputMaybe<Scalars['String']['input']>
    tin?: InputMaybe<Scalars['String']['input']>
    tinMeta?: InputMaybe<Scalars['JSON']['input']>
    tinMeta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    tinMeta_not?: InputMaybe<Scalars['JSON']['input']>
    tinMeta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    tin_contains?: InputMaybe<Scalars['String']['input']>
    tin_contains_i?: InputMaybe<Scalars['String']['input']>
    tin_ends_with?: InputMaybe<Scalars['String']['input']>
    tin_ends_with_i?: InputMaybe<Scalars['String']['input']>
    tin_i?: InputMaybe<Scalars['String']['input']>
    tin_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    tin_not?: InputMaybe<Scalars['String']['input']>
    tin_not_contains?: InputMaybe<Scalars['String']['input']>
    tin_not_contains_i?: InputMaybe<Scalars['String']['input']>
    tin_not_ends_with?: InputMaybe<Scalars['String']['input']>
    tin_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    tin_not_i?: InputMaybe<Scalars['String']['input']>
    tin_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    tin_not_starts_with?: InputMaybe<Scalars['String']['input']>
    tin_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    tin_starts_with?: InputMaybe<Scalars['String']['input']>
    tin_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type BankAccountHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type BankAccountHistoryRecordsCreateInput = {
    data?: InputMaybe<BankAccountHistoryRecordCreateInput>
}

export type BankAccountHistoryRecordsUpdateInput = {
    data?: InputMaybe<BankAccountHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type BankAccountRelateToOneInput = {
    connect?: InputMaybe<BankAccountWhereUniqueInput>
    create?: InputMaybe<BankAccountCreateInput>
    disconnect?: InputMaybe<BankAccountWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

/**  Calculated data for financial report of specified form, period and version.  */
export type BankAccountReport = {
    __typename?: 'BankAccountReport'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankAccountReport List config, or
   *  2. As an alias to the field set on 'labelField' in the BankAccountReport List config, or
   *  3. As an alias to a 'name' field on the BankAccountReport List (if one exists), or
   *  4. As an alias to the 'id' field on the BankAccountReport List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  Transactions of specified bank account will be used to calculate this report  */
    account?: Maybe<BankAccount>
    /**  Total amount for BankAccount on end of specified period of this report  */
    amount?: Maybe<Scalars['String']['output']>
    /**  Date on that the specified amount is actual  */
    amountAt?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    /**  Calculated data of this report, that will be used to display  */
    data?: Maybe<Scalars['JSON']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  Whether the report version is the latest  */
    isLatest?: Maybe<Scalars['Boolean']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
    organization?: Maybe<Organization>
    /**  Specified time frame to calculate this report. Can be month, quarter, year. Has specified format  */
    period?: Maybe<Scalars['String']['output']>
    /**  Report will be available for public view if this field will have a value, otherwise in case of null value this report will not be available for public view  */
    publishedAt?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Form of this report  */
    template?: Maybe<BankAccountReportTemplateType>
    /**  Total income amount over period of this report  */
    totalIncome?: Maybe<Scalars['String']['output']>
    /**  Total outcome amount over period of this report  */
    totalOutcome?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
    /**  When manual changes in transactions covers specified period,   */
    version?: Maybe<Scalars['Int']['output']>
}

export type BankAccountReportCreateInput = {
    account?: InputMaybe<BankAccountRelateToOneInput>
    amount?: InputMaybe<Scalars['String']['input']>
    amountAt?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    data?: InputMaybe<Scalars['JSON']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    isLatest?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    period?: InputMaybe<Scalars['String']['input']>
    publishedAt?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    template?: InputMaybe<BankAccountReportTemplateType>
    totalIncome?: InputMaybe<Scalars['String']['input']>
    totalOutcome?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
    version?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type BankAccountReportHistoryRecord = {
    __typename?: 'BankAccountReportHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankAccountReportHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the BankAccountReportHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the BankAccountReportHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the BankAccountReportHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    account?: Maybe<Scalars['String']['output']>
    amount?: Maybe<Scalars['String']['output']>
    amountAt?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    data?: Maybe<Scalars['JSON']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<BankAccountReportHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    isLatest?: Maybe<Scalars['Boolean']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    period?: Maybe<Scalars['String']['output']>
    publishedAt?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    template?: Maybe<Scalars['String']['output']>
    totalIncome?: Maybe<Scalars['String']['output']>
    totalOutcome?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
    version?: Maybe<Scalars['Int']['output']>
}

export type BankAccountReportHistoryRecordCreateInput = {
    account?: InputMaybe<Scalars['String']['input']>
    amount?: InputMaybe<Scalars['String']['input']>
    amountAt?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    data?: InputMaybe<Scalars['JSON']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<BankAccountReportHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    isLatest?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    period?: InputMaybe<Scalars['String']['input']>
    publishedAt?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    template?: InputMaybe<Scalars['String']['input']>
    totalIncome?: InputMaybe<Scalars['String']['input']>
    totalOutcome?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    version?: InputMaybe<Scalars['Int']['input']>
}

export enum BankAccountReportHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type BankAccountReportHistoryRecordUpdateInput = {
    account?: InputMaybe<Scalars['String']['input']>
    amount?: InputMaybe<Scalars['String']['input']>
    amountAt?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    data?: InputMaybe<Scalars['JSON']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<BankAccountReportHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    isLatest?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    period?: InputMaybe<Scalars['String']['input']>
    publishedAt?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    template?: InputMaybe<Scalars['String']['input']>
    totalIncome?: InputMaybe<Scalars['String']['input']>
    totalOutcome?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    version?: InputMaybe<Scalars['Int']['input']>
}

export type BankAccountReportHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<BankAccountReportHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<BankAccountReportHistoryRecordWhereInput>>>
    account?: InputMaybe<Scalars['String']['input']>
    account_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    account_not?: InputMaybe<Scalars['String']['input']>
    account_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    amount?: InputMaybe<Scalars['String']['input']>
    amountAt?: InputMaybe<Scalars['String']['input']>
    amountAt_gt?: InputMaybe<Scalars['String']['input']>
    amountAt_gte?: InputMaybe<Scalars['String']['input']>
    amountAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    amountAt_lt?: InputMaybe<Scalars['String']['input']>
    amountAt_lte?: InputMaybe<Scalars['String']['input']>
    amountAt_not?: InputMaybe<Scalars['String']['input']>
    amountAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    amount_gt?: InputMaybe<Scalars['String']['input']>
    amount_gte?: InputMaybe<Scalars['String']['input']>
    amount_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    amount_lt?: InputMaybe<Scalars['String']['input']>
    amount_lte?: InputMaybe<Scalars['String']['input']>
    amount_not?: InputMaybe<Scalars['String']['input']>
    amount_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    data?: InputMaybe<Scalars['JSON']['input']>
    data_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    data_not?: InputMaybe<Scalars['JSON']['input']>
    data_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<BankAccountReportHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<BankAccountReportHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<BankAccountReportHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<BankAccountReportHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    isLatest?: InputMaybe<Scalars['Boolean']['input']>
    isLatest_not?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    period?: InputMaybe<Scalars['String']['input']>
    period_contains?: InputMaybe<Scalars['String']['input']>
    period_contains_i?: InputMaybe<Scalars['String']['input']>
    period_ends_with?: InputMaybe<Scalars['String']['input']>
    period_ends_with_i?: InputMaybe<Scalars['String']['input']>
    period_i?: InputMaybe<Scalars['String']['input']>
    period_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    period_not?: InputMaybe<Scalars['String']['input']>
    period_not_contains?: InputMaybe<Scalars['String']['input']>
    period_not_contains_i?: InputMaybe<Scalars['String']['input']>
    period_not_ends_with?: InputMaybe<Scalars['String']['input']>
    period_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    period_not_i?: InputMaybe<Scalars['String']['input']>
    period_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    period_not_starts_with?: InputMaybe<Scalars['String']['input']>
    period_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    period_starts_with?: InputMaybe<Scalars['String']['input']>
    period_starts_with_i?: InputMaybe<Scalars['String']['input']>
    publishedAt?: InputMaybe<Scalars['String']['input']>
    publishedAt_gt?: InputMaybe<Scalars['String']['input']>
    publishedAt_gte?: InputMaybe<Scalars['String']['input']>
    publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    publishedAt_lt?: InputMaybe<Scalars['String']['input']>
    publishedAt_lte?: InputMaybe<Scalars['String']['input']>
    publishedAt_not?: InputMaybe<Scalars['String']['input']>
    publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    template?: InputMaybe<Scalars['String']['input']>
    template_contains?: InputMaybe<Scalars['String']['input']>
    template_contains_i?: InputMaybe<Scalars['String']['input']>
    template_ends_with?: InputMaybe<Scalars['String']['input']>
    template_ends_with_i?: InputMaybe<Scalars['String']['input']>
    template_i?: InputMaybe<Scalars['String']['input']>
    template_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    template_not?: InputMaybe<Scalars['String']['input']>
    template_not_contains?: InputMaybe<Scalars['String']['input']>
    template_not_contains_i?: InputMaybe<Scalars['String']['input']>
    template_not_ends_with?: InputMaybe<Scalars['String']['input']>
    template_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    template_not_i?: InputMaybe<Scalars['String']['input']>
    template_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    template_not_starts_with?: InputMaybe<Scalars['String']['input']>
    template_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    template_starts_with?: InputMaybe<Scalars['String']['input']>
    template_starts_with_i?: InputMaybe<Scalars['String']['input']>
    totalIncome?: InputMaybe<Scalars['String']['input']>
    totalIncome_gt?: InputMaybe<Scalars['String']['input']>
    totalIncome_gte?: InputMaybe<Scalars['String']['input']>
    totalIncome_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    totalIncome_lt?: InputMaybe<Scalars['String']['input']>
    totalIncome_lte?: InputMaybe<Scalars['String']['input']>
    totalIncome_not?: InputMaybe<Scalars['String']['input']>
    totalIncome_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    totalOutcome?: InputMaybe<Scalars['String']['input']>
    totalOutcome_gt?: InputMaybe<Scalars['String']['input']>
    totalOutcome_gte?: InputMaybe<Scalars['String']['input']>
    totalOutcome_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    totalOutcome_lt?: InputMaybe<Scalars['String']['input']>
    totalOutcome_lte?: InputMaybe<Scalars['String']['input']>
    totalOutcome_not?: InputMaybe<Scalars['String']['input']>
    totalOutcome_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    version?: InputMaybe<Scalars['Int']['input']>
    version_gt?: InputMaybe<Scalars['Int']['input']>
    version_gte?: InputMaybe<Scalars['Int']['input']>
    version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    version_lt?: InputMaybe<Scalars['Int']['input']>
    version_lte?: InputMaybe<Scalars['Int']['input']>
    version_not?: InputMaybe<Scalars['Int']['input']>
    version_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type BankAccountReportHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type BankAccountReportHistoryRecordsCreateInput = {
    data?: InputMaybe<BankAccountReportHistoryRecordCreateInput>
}

export type BankAccountReportHistoryRecordsUpdateInput = {
    data?: InputMaybe<BankAccountReportHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

/**  Information about the report generation process  */
export type BankAccountReportTask = {
    __typename?: 'BankAccountReportTask'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankAccountReportTask List config, or
   *  2. As an alias to the field set on 'labelField' in the BankAccountReportTask List config, or
   *  3. As an alias to a 'name' field on the BankAccountReportTask List (if one exists), or
   *  4. As an alias to the 'id' field on the BankAccountReportTask List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  Bank account for which the current report generation operation is running.  */
    account?: Maybe<BankAccount>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  Additional data, specific to used integration  */
    meta?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
    organization?: Maybe<Organization>
    /**  Progress of current generation operation  */
    progress?: Maybe<Scalars['Int']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Status of current generation operation  */
    status?: Maybe<BankAccountReportTaskStatusType>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    /**  User that requested this operation. Will be used for read access checks to display all tasks somewhere and to display progress indicator of ongoing generating task for current user  */
    user?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type BankAccountReportTaskCreateInput = {
    account?: InputMaybe<BankAccountRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    progress?: InputMaybe<Scalars['Int']['input']>
    sender?: InputMaybe<SenderFieldInput>
    status?: InputMaybe<BankAccountReportTaskStatusType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type BankAccountReportTaskHistoryRecord = {
    __typename?: 'BankAccountReportTaskHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankAccountReportTaskHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the BankAccountReportTaskHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the BankAccountReportTaskHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the BankAccountReportTaskHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    account?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<BankAccountReportTaskHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    meta?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    progress?: Maybe<Scalars['Int']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    status?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    user?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type BankAccountReportTaskHistoryRecordCreateInput = {
    account?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<BankAccountReportTaskHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    progress?: InputMaybe<Scalars['Int']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum BankAccountReportTaskHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type BankAccountReportTaskHistoryRecordUpdateInput = {
    account?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<BankAccountReportTaskHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    progress?: InputMaybe<Scalars['Int']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type BankAccountReportTaskHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<BankAccountReportTaskHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<BankAccountReportTaskHistoryRecordWhereInput>>>
    account?: InputMaybe<Scalars['String']['input']>
    account_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    account_not?: InputMaybe<Scalars['String']['input']>
    account_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<BankAccountReportTaskHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<BankAccountReportTaskHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<BankAccountReportTaskHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<BankAccountReportTaskHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    progress?: InputMaybe<Scalars['Int']['input']>
    progress_gt?: InputMaybe<Scalars['Int']['input']>
    progress_gte?: InputMaybe<Scalars['Int']['input']>
    progress_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    progress_lt?: InputMaybe<Scalars['Int']['input']>
    progress_lte?: InputMaybe<Scalars['Int']['input']>
    progress_not?: InputMaybe<Scalars['Int']['input']>
    progress_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    status?: InputMaybe<Scalars['String']['input']>
    status_contains?: InputMaybe<Scalars['String']['input']>
    status_contains_i?: InputMaybe<Scalars['String']['input']>
    status_ends_with?: InputMaybe<Scalars['String']['input']>
    status_ends_with_i?: InputMaybe<Scalars['String']['input']>
    status_i?: InputMaybe<Scalars['String']['input']>
    status_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status_not?: InputMaybe<Scalars['String']['input']>
    status_not_contains?: InputMaybe<Scalars['String']['input']>
    status_not_contains_i?: InputMaybe<Scalars['String']['input']>
    status_not_ends_with?: InputMaybe<Scalars['String']['input']>
    status_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    status_not_i?: InputMaybe<Scalars['String']['input']>
    status_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status_not_starts_with?: InputMaybe<Scalars['String']['input']>
    status_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    status_starts_with?: InputMaybe<Scalars['String']['input']>
    status_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user?: InputMaybe<Scalars['String']['input']>
    user_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user_not?: InputMaybe<Scalars['String']['input']>
    user_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type BankAccountReportTaskHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type BankAccountReportTaskHistoryRecordsCreateInput = {
    data?: InputMaybe<BankAccountReportTaskHistoryRecordCreateInput>
}

export type BankAccountReportTaskHistoryRecordsUpdateInput = {
    data?: InputMaybe<BankAccountReportTaskHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export enum BankAccountReportTaskStatusType {
    Cancelled = 'cancelled',
    Completed = 'completed',
    Error = 'error',
    Processing = 'processing',
}

export type BankAccountReportTaskUpdateInput = {
    account?: InputMaybe<BankAccountRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    progress?: InputMaybe<Scalars['Int']['input']>
    sender?: InputMaybe<SenderFieldInput>
    status?: InputMaybe<BankAccountReportTaskStatusType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type BankAccountReportTaskWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<BankAccountReportTaskWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<BankAccountReportTaskWhereInput>>>
    account?: InputMaybe<BankAccountWhereInput>
    account_is_null?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    progress?: InputMaybe<Scalars['Int']['input']>
    progress_gt?: InputMaybe<Scalars['Int']['input']>
    progress_gte?: InputMaybe<Scalars['Int']['input']>
    progress_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    progress_lt?: InputMaybe<Scalars['Int']['input']>
    progress_lte?: InputMaybe<Scalars['Int']['input']>
    progress_not?: InputMaybe<Scalars['Int']['input']>
    progress_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    status?: InputMaybe<BankAccountReportTaskStatusType>
    status_in?: InputMaybe<Array<InputMaybe<BankAccountReportTaskStatusType>>>
    status_not?: InputMaybe<BankAccountReportTaskStatusType>
    status_not_in?: InputMaybe<Array<InputMaybe<BankAccountReportTaskStatusType>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    user?: InputMaybe<UserWhereInput>
    user_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type BankAccountReportTaskWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type BankAccountReportTasksCreateInput = {
    data?: InputMaybe<BankAccountReportTaskCreateInput>
}

export type BankAccountReportTasksUpdateInput = {
    data?: InputMaybe<BankAccountReportTaskUpdateInput>
    id: Scalars['ID']['input']
}

export enum BankAccountReportTemplateType {
    ExpensesGroupedByCategoryAndCostItem = 'expenses_grouped_by_category_and_cost_item',
}

export type BankAccountReportUpdateInput = {
    account?: InputMaybe<BankAccountRelateToOneInput>
    amount?: InputMaybe<Scalars['String']['input']>
    amountAt?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    data?: InputMaybe<Scalars['JSON']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    isLatest?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    period?: InputMaybe<Scalars['String']['input']>
    publishedAt?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    template?: InputMaybe<BankAccountReportTemplateType>
    totalIncome?: InputMaybe<Scalars['String']['input']>
    totalOutcome?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
    version?: InputMaybe<Scalars['Int']['input']>
}

export type BankAccountReportWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<BankAccountReportWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<BankAccountReportWhereInput>>>
    account?: InputMaybe<BankAccountWhereInput>
    account_is_null?: InputMaybe<Scalars['Boolean']['input']>
    amount?: InputMaybe<Scalars['String']['input']>
    amountAt?: InputMaybe<Scalars['String']['input']>
    amountAt_gt?: InputMaybe<Scalars['String']['input']>
    amountAt_gte?: InputMaybe<Scalars['String']['input']>
    amountAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    amountAt_lt?: InputMaybe<Scalars['String']['input']>
    amountAt_lte?: InputMaybe<Scalars['String']['input']>
    amountAt_not?: InputMaybe<Scalars['String']['input']>
    amountAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    amount_gt?: InputMaybe<Scalars['String']['input']>
    amount_gte?: InputMaybe<Scalars['String']['input']>
    amount_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    amount_lt?: InputMaybe<Scalars['String']['input']>
    amount_lte?: InputMaybe<Scalars['String']['input']>
    amount_not?: InputMaybe<Scalars['String']['input']>
    amount_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    data?: InputMaybe<Scalars['JSON']['input']>
    data_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    data_not?: InputMaybe<Scalars['JSON']['input']>
    data_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    isLatest?: InputMaybe<Scalars['Boolean']['input']>
    isLatest_not?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    period?: InputMaybe<Scalars['String']['input']>
    period_contains?: InputMaybe<Scalars['String']['input']>
    period_contains_i?: InputMaybe<Scalars['String']['input']>
    period_ends_with?: InputMaybe<Scalars['String']['input']>
    period_ends_with_i?: InputMaybe<Scalars['String']['input']>
    period_i?: InputMaybe<Scalars['String']['input']>
    period_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    period_not?: InputMaybe<Scalars['String']['input']>
    period_not_contains?: InputMaybe<Scalars['String']['input']>
    period_not_contains_i?: InputMaybe<Scalars['String']['input']>
    period_not_ends_with?: InputMaybe<Scalars['String']['input']>
    period_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    period_not_i?: InputMaybe<Scalars['String']['input']>
    period_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    period_not_starts_with?: InputMaybe<Scalars['String']['input']>
    period_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    period_starts_with?: InputMaybe<Scalars['String']['input']>
    period_starts_with_i?: InputMaybe<Scalars['String']['input']>
    publishedAt?: InputMaybe<Scalars['String']['input']>
    publishedAt_gt?: InputMaybe<Scalars['String']['input']>
    publishedAt_gte?: InputMaybe<Scalars['String']['input']>
    publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    publishedAt_lt?: InputMaybe<Scalars['String']['input']>
    publishedAt_lte?: InputMaybe<Scalars['String']['input']>
    publishedAt_not?: InputMaybe<Scalars['String']['input']>
    publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    template?: InputMaybe<BankAccountReportTemplateType>
    template_in?: InputMaybe<Array<InputMaybe<BankAccountReportTemplateType>>>
    template_not?: InputMaybe<BankAccountReportTemplateType>
    template_not_in?: InputMaybe<Array<InputMaybe<BankAccountReportTemplateType>>>
    totalIncome?: InputMaybe<Scalars['String']['input']>
    totalIncome_gt?: InputMaybe<Scalars['String']['input']>
    totalIncome_gte?: InputMaybe<Scalars['String']['input']>
    totalIncome_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    totalIncome_lt?: InputMaybe<Scalars['String']['input']>
    totalIncome_lte?: InputMaybe<Scalars['String']['input']>
    totalIncome_not?: InputMaybe<Scalars['String']['input']>
    totalIncome_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    totalOutcome?: InputMaybe<Scalars['String']['input']>
    totalOutcome_gt?: InputMaybe<Scalars['String']['input']>
    totalOutcome_gte?: InputMaybe<Scalars['String']['input']>
    totalOutcome_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    totalOutcome_lt?: InputMaybe<Scalars['String']['input']>
    totalOutcome_lte?: InputMaybe<Scalars['String']['input']>
    totalOutcome_not?: InputMaybe<Scalars['String']['input']>
    totalOutcome_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    version?: InputMaybe<Scalars['Int']['input']>
    version_gt?: InputMaybe<Scalars['Int']['input']>
    version_gte?: InputMaybe<Scalars['Int']['input']>
    version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    version_lt?: InputMaybe<Scalars['Int']['input']>
    version_lte?: InputMaybe<Scalars['Int']['input']>
    version_not?: InputMaybe<Scalars['Int']['input']>
    version_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type BankAccountReportWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type BankAccountReportsCreateInput = {
    data?: InputMaybe<BankAccountReportCreateInput>
}

export type BankAccountReportsUpdateInput = {
    data?: InputMaybe<BankAccountReportUpdateInput>
    id: Scalars['ID']['input']
}

export type BankAccountUpdateInput = {
    approvedAt?: InputMaybe<Scalars['String']['input']>
    approvedBy?: InputMaybe<UserRelateToOneInput>
    bankName?: InputMaybe<Scalars['String']['input']>
    classificationCode?: InputMaybe<Scalars['String']['input']>
    country?: InputMaybe<BankAccountCountryType>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    currencyCode?: InputMaybe<BankAccountCurrencyCodeType>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    integrationContext?: InputMaybe<BankIntegrationAccountContextRelateToOneInput>
    isApproved?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    number?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    property?: InputMaybe<PropertyRelateToOneInput>
    routingNumber?: InputMaybe<Scalars['String']['input']>
    routingNumberMeta?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<SenderFieldInput>
    territoryCode?: InputMaybe<Scalars['String']['input']>
    tin?: InputMaybe<Scalars['String']['input']>
    tinMeta?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type BankAccountWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<BankAccountWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<BankAccountWhereInput>>>
    approvedAt?: InputMaybe<Scalars['String']['input']>
    approvedAt_gt?: InputMaybe<Scalars['String']['input']>
    approvedAt_gte?: InputMaybe<Scalars['String']['input']>
    approvedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    approvedAt_lt?: InputMaybe<Scalars['String']['input']>
    approvedAt_lte?: InputMaybe<Scalars['String']['input']>
    approvedAt_not?: InputMaybe<Scalars['String']['input']>
    approvedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    approvedBy?: InputMaybe<UserWhereInput>
    approvedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    bankName?: InputMaybe<Scalars['String']['input']>
    bankName_contains?: InputMaybe<Scalars['String']['input']>
    bankName_contains_i?: InputMaybe<Scalars['String']['input']>
    bankName_ends_with?: InputMaybe<Scalars['String']['input']>
    bankName_ends_with_i?: InputMaybe<Scalars['String']['input']>
    bankName_i?: InputMaybe<Scalars['String']['input']>
    bankName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    bankName_not?: InputMaybe<Scalars['String']['input']>
    bankName_not_contains?: InputMaybe<Scalars['String']['input']>
    bankName_not_contains_i?: InputMaybe<Scalars['String']['input']>
    bankName_not_ends_with?: InputMaybe<Scalars['String']['input']>
    bankName_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    bankName_not_i?: InputMaybe<Scalars['String']['input']>
    bankName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    bankName_not_starts_with?: InputMaybe<Scalars['String']['input']>
    bankName_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    bankName_starts_with?: InputMaybe<Scalars['String']['input']>
    bankName_starts_with_i?: InputMaybe<Scalars['String']['input']>
    classificationCode?: InputMaybe<Scalars['String']['input']>
    classificationCode_contains?: InputMaybe<Scalars['String']['input']>
    classificationCode_contains_i?: InputMaybe<Scalars['String']['input']>
    classificationCode_ends_with?: InputMaybe<Scalars['String']['input']>
    classificationCode_ends_with_i?: InputMaybe<Scalars['String']['input']>
    classificationCode_i?: InputMaybe<Scalars['String']['input']>
    classificationCode_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    classificationCode_not?: InputMaybe<Scalars['String']['input']>
    classificationCode_not_contains?: InputMaybe<Scalars['String']['input']>
    classificationCode_not_contains_i?: InputMaybe<Scalars['String']['input']>
    classificationCode_not_ends_with?: InputMaybe<Scalars['String']['input']>
    classificationCode_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    classificationCode_not_i?: InputMaybe<Scalars['String']['input']>
    classificationCode_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    classificationCode_not_starts_with?: InputMaybe<Scalars['String']['input']>
    classificationCode_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    classificationCode_starts_with?: InputMaybe<Scalars['String']['input']>
    classificationCode_starts_with_i?: InputMaybe<Scalars['String']['input']>
    country?: InputMaybe<BankAccountCountryType>
    country_in?: InputMaybe<Array<InputMaybe<BankAccountCountryType>>>
    country_not?: InputMaybe<BankAccountCountryType>
    country_not_in?: InputMaybe<Array<InputMaybe<BankAccountCountryType>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    currencyCode?: InputMaybe<BankAccountCurrencyCodeType>
    currencyCode_in?: InputMaybe<Array<InputMaybe<BankAccountCurrencyCodeType>>>
    currencyCode_not?: InputMaybe<BankAccountCurrencyCodeType>
    currencyCode_not_in?: InputMaybe<Array<InputMaybe<BankAccountCurrencyCodeType>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    importId?: InputMaybe<Scalars['String']['input']>
    importId_contains?: InputMaybe<Scalars['String']['input']>
    importId_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_i?: InputMaybe<Scalars['String']['input']>
    importId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not?: InputMaybe<Scalars['String']['input']>
    importId_not_contains?: InputMaybe<Scalars['String']['input']>
    importId_not_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_not_i?: InputMaybe<Scalars['String']['input']>
    importId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importId_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_starts_with_i?: InputMaybe<Scalars['String']['input']>
    integrationContext?: InputMaybe<BankIntegrationAccountContextWhereInput>
    integrationContext_is_null?: InputMaybe<Scalars['Boolean']['input']>
    isApproved?: InputMaybe<Scalars['Boolean']['input']>
    isApproved_not?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    number?: InputMaybe<Scalars['String']['input']>
    number_contains?: InputMaybe<Scalars['String']['input']>
    number_contains_i?: InputMaybe<Scalars['String']['input']>
    number_ends_with?: InputMaybe<Scalars['String']['input']>
    number_ends_with_i?: InputMaybe<Scalars['String']['input']>
    number_i?: InputMaybe<Scalars['String']['input']>
    number_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    number_not?: InputMaybe<Scalars['String']['input']>
    number_not_contains?: InputMaybe<Scalars['String']['input']>
    number_not_contains_i?: InputMaybe<Scalars['String']['input']>
    number_not_ends_with?: InputMaybe<Scalars['String']['input']>
    number_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    number_not_i?: InputMaybe<Scalars['String']['input']>
    number_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    number_not_starts_with?: InputMaybe<Scalars['String']['input']>
    number_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    number_starts_with?: InputMaybe<Scalars['String']['input']>
    number_starts_with_i?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    property?: InputMaybe<PropertyWhereInput>
    property_is_null?: InputMaybe<Scalars['Boolean']['input']>
    routingNumber?: InputMaybe<Scalars['String']['input']>
    routingNumberMeta?: InputMaybe<Scalars['JSON']['input']>
    routingNumberMeta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    routingNumberMeta_not?: InputMaybe<Scalars['JSON']['input']>
    routingNumberMeta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    routingNumber_contains?: InputMaybe<Scalars['String']['input']>
    routingNumber_contains_i?: InputMaybe<Scalars['String']['input']>
    routingNumber_ends_with?: InputMaybe<Scalars['String']['input']>
    routingNumber_ends_with_i?: InputMaybe<Scalars['String']['input']>
    routingNumber_i?: InputMaybe<Scalars['String']['input']>
    routingNumber_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    routingNumber_not?: InputMaybe<Scalars['String']['input']>
    routingNumber_not_contains?: InputMaybe<Scalars['String']['input']>
    routingNumber_not_contains_i?: InputMaybe<Scalars['String']['input']>
    routingNumber_not_ends_with?: InputMaybe<Scalars['String']['input']>
    routingNumber_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    routingNumber_not_i?: InputMaybe<Scalars['String']['input']>
    routingNumber_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    routingNumber_not_starts_with?: InputMaybe<Scalars['String']['input']>
    routingNumber_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    routingNumber_starts_with?: InputMaybe<Scalars['String']['input']>
    routingNumber_starts_with_i?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    territoryCode?: InputMaybe<Scalars['String']['input']>
    territoryCode_contains?: InputMaybe<Scalars['String']['input']>
    territoryCode_contains_i?: InputMaybe<Scalars['String']['input']>
    territoryCode_ends_with?: InputMaybe<Scalars['String']['input']>
    territoryCode_ends_with_i?: InputMaybe<Scalars['String']['input']>
    territoryCode_i?: InputMaybe<Scalars['String']['input']>
    territoryCode_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    territoryCode_not?: InputMaybe<Scalars['String']['input']>
    territoryCode_not_contains?: InputMaybe<Scalars['String']['input']>
    territoryCode_not_contains_i?: InputMaybe<Scalars['String']['input']>
    territoryCode_not_ends_with?: InputMaybe<Scalars['String']['input']>
    territoryCode_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    territoryCode_not_i?: InputMaybe<Scalars['String']['input']>
    territoryCode_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    territoryCode_not_starts_with?: InputMaybe<Scalars['String']['input']>
    territoryCode_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    territoryCode_starts_with?: InputMaybe<Scalars['String']['input']>
    territoryCode_starts_with_i?: InputMaybe<Scalars['String']['input']>
    tin?: InputMaybe<Scalars['String']['input']>
    tinMeta?: InputMaybe<Scalars['JSON']['input']>
    tinMeta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    tinMeta_not?: InputMaybe<Scalars['JSON']['input']>
    tinMeta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    tin_contains?: InputMaybe<Scalars['String']['input']>
    tin_contains_i?: InputMaybe<Scalars['String']['input']>
    tin_ends_with?: InputMaybe<Scalars['String']['input']>
    tin_ends_with_i?: InputMaybe<Scalars['String']['input']>
    tin_i?: InputMaybe<Scalars['String']['input']>
    tin_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    tin_not?: InputMaybe<Scalars['String']['input']>
    tin_not_contains?: InputMaybe<Scalars['String']['input']>
    tin_not_contains_i?: InputMaybe<Scalars['String']['input']>
    tin_not_ends_with?: InputMaybe<Scalars['String']['input']>
    tin_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    tin_not_i?: InputMaybe<Scalars['String']['input']>
    tin_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    tin_not_starts_with?: InputMaybe<Scalars['String']['input']>
    tin_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    tin_starts_with?: InputMaybe<Scalars['String']['input']>
    tin_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type BankAccountWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type BankAccountsCreateInput = {
    data?: InputMaybe<BankAccountCreateInput>
}

export type BankAccountsUpdateInput = {
    data?: InputMaybe<BankAccountUpdateInput>
    id: Scalars['ID']['input']
}

export type BankCategoriesCreateInput = {
    data?: InputMaybe<BankCategoryCreateInput>
}

export type BankCategoriesUpdateInput = {
    data?: InputMaybe<BankCategoryUpdateInput>
    id: Scalars['ID']['input']
}

/**  Expenses category, that will be associated with transactions and contractors  */
export type BankCategory = {
    __typename?: 'BankCategory'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankCategory List config, or
   *  2. As an alias to the field set on 'labelField' in the BankCategory List config, or
   *  3. As an alias to a 'name' field on the BankCategory List (if one exists), or
   *  4. As an alias to the 'id' field on the BankCategory List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  Key for i18n  */
    name?: Maybe<Scalars['String']['output']>
    nameNonLocalized?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type BankCategoryCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type BankCategoryHistoryRecord = {
    __typename?: 'BankCategoryHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankCategoryHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the BankCategoryHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the BankCategoryHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the BankCategoryHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<BankCategoryHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type BankCategoryHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<BankCategoryHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum BankCategoryHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type BankCategoryHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<BankCategoryHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type BankCategoryHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<BankCategoryHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<BankCategoryHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<BankCategoryHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<BankCategoryHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<BankCategoryHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<BankCategoryHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type BankCategoryHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type BankCategoryHistoryRecordsCreateInput = {
    data?: InputMaybe<BankCategoryHistoryRecordCreateInput>
}

export type BankCategoryHistoryRecordsUpdateInput = {
    data?: InputMaybe<BankCategoryHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type BankCategoryRelateToOneInput = {
    connect?: InputMaybe<BankCategoryWhereUniqueInput>
    create?: InputMaybe<BankCategoryCreateInput>
    disconnect?: InputMaybe<BankCategoryWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type BankCategoryUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type BankCategoryWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<BankCategoryWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<BankCategoryWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type BankCategoryWhereUniqueInput = {
    id: Scalars['ID']['input']
}

/**  Account for external contractor, which organization is not stored in condo. Contractor is related to expenses transactions of an Organization. Will be obtained from export files with bank transactions or from banking API  */
export type BankContractorAccount = {
    __typename?: 'BankContractorAccount'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankContractorAccount List config, or
   *  2. As an alias to the field set on 'labelField' in the BankContractorAccount List config, or
   *  3. As an alias to a 'name' field on the BankContractorAccount List (if one exists), or
   *  4. As an alias to the 'id' field on the BankContractorAccount List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  Name of bank  */
    bankName?: Maybe<Scalars['String']['output']>
    /**  Related cost item, that will determine cost items for transactions belonging to this contractor  */
    costItem?: Maybe<BankCostItem>
    /**  Country where the bank is located  */
    country?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    /**  Code of currency in ISO-4217 format  */
    currencyCode?: Maybe<BankContractorAccountCurrencyCodeType>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  Id of object in external service which represents current item. Mostly used for internal needs of integration services for matching our objects with theirs  */
    importId?: Maybe<Scalars['String']['output']>
    /**  Structured non-typed metadata, can be used by mini-apps or external services to store information  */
    meta?: Maybe<Scalars['JSON']['output']>
    /**  Name of contractor organization  */
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Bank account number  */
    number?: Maybe<Scalars['String']['output']>
    /**  Technical link to property management organization, that has expenses transactions with this contractor. Used for access rights and cascade behaviour of data. Contractor organization is not stored in condo. The object will be deleted if the related organization ceases to exist  */
    organization?: Maybe<Organization>
    /**  Number of related transactions without costItem for this contractor account  */
    relatedTransactions?: Maybe<Scalars['Int']['output']>
    /**  The routing transit number for the bank account.  */
    routingNumber?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Location of the holder of this bank account. It depends on a country. In Russia it is OKTMO  */
    territoryCode?: Maybe<Scalars['String']['output']>
    /**  Tax Identification Number  */
    tin?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type BankContractorAccountCreateInput = {
    bankName?: InputMaybe<Scalars['String']['input']>
    costItem?: InputMaybe<BankCostItemRelateToOneInput>
    country?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    currencyCode?: InputMaybe<BankContractorAccountCurrencyCodeType>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    number?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    routingNumber?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    territoryCode?: InputMaybe<Scalars['String']['input']>
    tin?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum BankContractorAccountCurrencyCodeType {
    Aed = 'AED',
    Afn = 'AFN',
    All = 'ALL',
    Amd = 'AMD',
    Ang = 'ANG',
    Aoa = 'AOA',
    Ars = 'ARS',
    Aud = 'AUD',
    Awg = 'AWG',
    Azn = 'AZN',
    Bam = 'BAM',
    Bbd = 'BBD',
    Bdt = 'BDT',
    Bgn = 'BGN',
    Bhd = 'BHD',
    Bif = 'BIF',
    Bmd = 'BMD',
    Bnd = 'BND',
    Bob = 'BOB',
    Bov = 'BOV',
    Brl = 'BRL',
    Bsd = 'BSD',
    Btn = 'BTN',
    Bwp = 'BWP',
    Byn = 'BYN',
    Bzd = 'BZD',
    Cad = 'CAD',
    Cdf = 'CDF',
    Che = 'CHE',
    Chf = 'CHF',
    Chw = 'CHW',
    Clf = 'CLF',
    Clp = 'CLP',
    Cny = 'CNY',
    Cop = 'COP',
    Cou = 'COU',
    Crc = 'CRC',
    Cuc = 'CUC',
    Cup = 'CUP',
    Cve = 'CVE',
    Czk = 'CZK',
    Djf = 'DJF',
    Dkk = 'DKK',
    Dop = 'DOP',
    Dzd = 'DZD',
    Egp = 'EGP',
    Ern = 'ERN',
    Etb = 'ETB',
    Eur = 'EUR',
    Fjd = 'FJD',
    Fkp = 'FKP',
    Gbp = 'GBP',
    Gel = 'GEL',
    Ghs = 'GHS',
    Gip = 'GIP',
    Gmd = 'GMD',
    Gnf = 'GNF',
    Gtq = 'GTQ',
    Gyd = 'GYD',
    Hkd = 'HKD',
    Hnl = 'HNL',
    Hrk = 'HRK',
    Htg = 'HTG',
    Huf = 'HUF',
    Idr = 'IDR',
    Ils = 'ILS',
    Inr = 'INR',
    Iqd = 'IQD',
    Irr = 'IRR',
    Isk = 'ISK',
    Jmd = 'JMD',
    Jod = 'JOD',
    Jpy = 'JPY',
    Kes = 'KES',
    Kgs = 'KGS',
    Khr = 'KHR',
    Kmf = 'KMF',
    Kpw = 'KPW',
    Krw = 'KRW',
    Kwd = 'KWD',
    Kyd = 'KYD',
    Kzt = 'KZT',
    Lak = 'LAK',
    Lbp = 'LBP',
    Lkr = 'LKR',
    Lrd = 'LRD',
    Lsl = 'LSL',
    Lyd = 'LYD',
    Mad = 'MAD',
    Mdl = 'MDL',
    Mga = 'MGA',
    Mkd = 'MKD',
    Mmk = 'MMK',
    Mnt = 'MNT',
    Mop = 'MOP',
    Mru = 'MRU',
    Mur = 'MUR',
    Mvr = 'MVR',
    Mwk = 'MWK',
    Mxn = 'MXN',
    Mxv = 'MXV',
    Myr = 'MYR',
    Mzn = 'MZN',
    Nad = 'NAD',
    Ngn = 'NGN',
    Nio = 'NIO',
    Nok = 'NOK',
    Npr = 'NPR',
    Nzd = 'NZD',
    Omr = 'OMR',
    Pab = 'PAB',
    Pen = 'PEN',
    Pgk = 'PGK',
    Php = 'PHP',
    Pkr = 'PKR',
    Pln = 'PLN',
    Pyg = 'PYG',
    Qar = 'QAR',
    Ron = 'RON',
    Rsd = 'RSD',
    Rub = 'RUB',
    Rwf = 'RWF',
    Sar = 'SAR',
    Sbd = 'SBD',
    Scr = 'SCR',
    Sdg = 'SDG',
    Sek = 'SEK',
    Sgd = 'SGD',
    Shp = 'SHP',
    Sll = 'SLL',
    Sos = 'SOS',
    Srd = 'SRD',
    Ssp = 'SSP',
    Stn = 'STN',
    Svc = 'SVC',
    Syp = 'SYP',
    Szl = 'SZL',
    Thb = 'THB',
    Tjs = 'TJS',
    Tmt = 'TMT',
    Tnd = 'TND',
    Top = 'TOP',
    Try = 'TRY',
    Ttd = 'TTD',
    Twd = 'TWD',
    Tzs = 'TZS',
    Uah = 'UAH',
    Ugx = 'UGX',
    Usd = 'USD',
    Usn = 'USN',
    Uyi = 'UYI',
    Uyu = 'UYU',
    Uyw = 'UYW',
    Uzs = 'UZS',
    Ves = 'VES',
    Vnd = 'VND',
    Vuv = 'VUV',
    Wst = 'WST',
    Xaf = 'XAF',
    Xag = 'XAG',
    Xau = 'XAU',
    Xba = 'XBA',
    Xbb = 'XBB',
    Xbc = 'XBC',
    Xbd = 'XBD',
    Xcd = 'XCD',
    Xdr = 'XDR',
    Xof = 'XOF',
    Xpd = 'XPD',
    Xpf = 'XPF',
    Xpt = 'XPT',
    Xsu = 'XSU',
    Xts = 'XTS',
    Xua = 'XUA',
    Yer = 'YER',
    Zar = 'ZAR',
    Zmw = 'ZMW',
    Zwl = 'ZWL',
}

/**  A keystone list  */
export type BankContractorAccountHistoryRecord = {
    __typename?: 'BankContractorAccountHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankContractorAccountHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the BankContractorAccountHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the BankContractorAccountHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the BankContractorAccountHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    bankName?: Maybe<Scalars['String']['output']>
    costItem?: Maybe<Scalars['String']['output']>
    country?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    currencyCode?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<BankContractorAccountHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    importId?: Maybe<Scalars['String']['output']>
    meta?: Maybe<Scalars['JSON']['output']>
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    number?: Maybe<Scalars['String']['output']>
    organization?: Maybe<Scalars['String']['output']>
    routingNumber?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    territoryCode?: Maybe<Scalars['String']['output']>
    tin?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type BankContractorAccountHistoryRecordCreateInput = {
    bankName?: InputMaybe<Scalars['String']['input']>
    costItem?: InputMaybe<Scalars['String']['input']>
    country?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    currencyCode?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<BankContractorAccountHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    number?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    routingNumber?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    territoryCode?: InputMaybe<Scalars['String']['input']>
    tin?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum BankContractorAccountHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type BankContractorAccountHistoryRecordUpdateInput = {
    bankName?: InputMaybe<Scalars['String']['input']>
    costItem?: InputMaybe<Scalars['String']['input']>
    country?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    currencyCode?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<BankContractorAccountHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    number?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    routingNumber?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    territoryCode?: InputMaybe<Scalars['String']['input']>
    tin?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type BankContractorAccountHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<BankContractorAccountHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<BankContractorAccountHistoryRecordWhereInput>>>
    bankName?: InputMaybe<Scalars['String']['input']>
    bankName_contains?: InputMaybe<Scalars['String']['input']>
    bankName_contains_i?: InputMaybe<Scalars['String']['input']>
    bankName_ends_with?: InputMaybe<Scalars['String']['input']>
    bankName_ends_with_i?: InputMaybe<Scalars['String']['input']>
    bankName_i?: InputMaybe<Scalars['String']['input']>
    bankName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    bankName_not?: InputMaybe<Scalars['String']['input']>
    bankName_not_contains?: InputMaybe<Scalars['String']['input']>
    bankName_not_contains_i?: InputMaybe<Scalars['String']['input']>
    bankName_not_ends_with?: InputMaybe<Scalars['String']['input']>
    bankName_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    bankName_not_i?: InputMaybe<Scalars['String']['input']>
    bankName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    bankName_not_starts_with?: InputMaybe<Scalars['String']['input']>
    bankName_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    bankName_starts_with?: InputMaybe<Scalars['String']['input']>
    bankName_starts_with_i?: InputMaybe<Scalars['String']['input']>
    costItem?: InputMaybe<Scalars['String']['input']>
    costItem_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    costItem_not?: InputMaybe<Scalars['String']['input']>
    costItem_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    country?: InputMaybe<Scalars['String']['input']>
    country_contains?: InputMaybe<Scalars['String']['input']>
    country_contains_i?: InputMaybe<Scalars['String']['input']>
    country_ends_with?: InputMaybe<Scalars['String']['input']>
    country_ends_with_i?: InputMaybe<Scalars['String']['input']>
    country_i?: InputMaybe<Scalars['String']['input']>
    country_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    country_not?: InputMaybe<Scalars['String']['input']>
    country_not_contains?: InputMaybe<Scalars['String']['input']>
    country_not_contains_i?: InputMaybe<Scalars['String']['input']>
    country_not_ends_with?: InputMaybe<Scalars['String']['input']>
    country_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    country_not_i?: InputMaybe<Scalars['String']['input']>
    country_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    country_not_starts_with?: InputMaybe<Scalars['String']['input']>
    country_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    country_starts_with?: InputMaybe<Scalars['String']['input']>
    country_starts_with_i?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    currencyCode?: InputMaybe<Scalars['String']['input']>
    currencyCode_contains?: InputMaybe<Scalars['String']['input']>
    currencyCode_contains_i?: InputMaybe<Scalars['String']['input']>
    currencyCode_ends_with?: InputMaybe<Scalars['String']['input']>
    currencyCode_ends_with_i?: InputMaybe<Scalars['String']['input']>
    currencyCode_i?: InputMaybe<Scalars['String']['input']>
    currencyCode_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    currencyCode_not?: InputMaybe<Scalars['String']['input']>
    currencyCode_not_contains?: InputMaybe<Scalars['String']['input']>
    currencyCode_not_contains_i?: InputMaybe<Scalars['String']['input']>
    currencyCode_not_ends_with?: InputMaybe<Scalars['String']['input']>
    currencyCode_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    currencyCode_not_i?: InputMaybe<Scalars['String']['input']>
    currencyCode_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    currencyCode_not_starts_with?: InputMaybe<Scalars['String']['input']>
    currencyCode_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    currencyCode_starts_with?: InputMaybe<Scalars['String']['input']>
    currencyCode_starts_with_i?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<BankContractorAccountHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<BankContractorAccountHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<BankContractorAccountHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<BankContractorAccountHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    importId?: InputMaybe<Scalars['String']['input']>
    importId_contains?: InputMaybe<Scalars['String']['input']>
    importId_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_i?: InputMaybe<Scalars['String']['input']>
    importId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not?: InputMaybe<Scalars['String']['input']>
    importId_not_contains?: InputMaybe<Scalars['String']['input']>
    importId_not_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_not_i?: InputMaybe<Scalars['String']['input']>
    importId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importId_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_starts_with_i?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    number?: InputMaybe<Scalars['String']['input']>
    number_contains?: InputMaybe<Scalars['String']['input']>
    number_contains_i?: InputMaybe<Scalars['String']['input']>
    number_ends_with?: InputMaybe<Scalars['String']['input']>
    number_ends_with_i?: InputMaybe<Scalars['String']['input']>
    number_i?: InputMaybe<Scalars['String']['input']>
    number_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    number_not?: InputMaybe<Scalars['String']['input']>
    number_not_contains?: InputMaybe<Scalars['String']['input']>
    number_not_contains_i?: InputMaybe<Scalars['String']['input']>
    number_not_ends_with?: InputMaybe<Scalars['String']['input']>
    number_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    number_not_i?: InputMaybe<Scalars['String']['input']>
    number_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    number_not_starts_with?: InputMaybe<Scalars['String']['input']>
    number_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    number_starts_with?: InputMaybe<Scalars['String']['input']>
    number_starts_with_i?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    routingNumber?: InputMaybe<Scalars['String']['input']>
    routingNumber_contains?: InputMaybe<Scalars['String']['input']>
    routingNumber_contains_i?: InputMaybe<Scalars['String']['input']>
    routingNumber_ends_with?: InputMaybe<Scalars['String']['input']>
    routingNumber_ends_with_i?: InputMaybe<Scalars['String']['input']>
    routingNumber_i?: InputMaybe<Scalars['String']['input']>
    routingNumber_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    routingNumber_not?: InputMaybe<Scalars['String']['input']>
    routingNumber_not_contains?: InputMaybe<Scalars['String']['input']>
    routingNumber_not_contains_i?: InputMaybe<Scalars['String']['input']>
    routingNumber_not_ends_with?: InputMaybe<Scalars['String']['input']>
    routingNumber_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    routingNumber_not_i?: InputMaybe<Scalars['String']['input']>
    routingNumber_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    routingNumber_not_starts_with?: InputMaybe<Scalars['String']['input']>
    routingNumber_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    routingNumber_starts_with?: InputMaybe<Scalars['String']['input']>
    routingNumber_starts_with_i?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    territoryCode?: InputMaybe<Scalars['String']['input']>
    territoryCode_contains?: InputMaybe<Scalars['String']['input']>
    territoryCode_contains_i?: InputMaybe<Scalars['String']['input']>
    territoryCode_ends_with?: InputMaybe<Scalars['String']['input']>
    territoryCode_ends_with_i?: InputMaybe<Scalars['String']['input']>
    territoryCode_i?: InputMaybe<Scalars['String']['input']>
    territoryCode_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    territoryCode_not?: InputMaybe<Scalars['String']['input']>
    territoryCode_not_contains?: InputMaybe<Scalars['String']['input']>
    territoryCode_not_contains_i?: InputMaybe<Scalars['String']['input']>
    territoryCode_not_ends_with?: InputMaybe<Scalars['String']['input']>
    territoryCode_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    territoryCode_not_i?: InputMaybe<Scalars['String']['input']>
    territoryCode_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    territoryCode_not_starts_with?: InputMaybe<Scalars['String']['input']>
    territoryCode_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    territoryCode_starts_with?: InputMaybe<Scalars['String']['input']>
    territoryCode_starts_with_i?: InputMaybe<Scalars['String']['input']>
    tin?: InputMaybe<Scalars['String']['input']>
    tin_contains?: InputMaybe<Scalars['String']['input']>
    tin_contains_i?: InputMaybe<Scalars['String']['input']>
    tin_ends_with?: InputMaybe<Scalars['String']['input']>
    tin_ends_with_i?: InputMaybe<Scalars['String']['input']>
    tin_i?: InputMaybe<Scalars['String']['input']>
    tin_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    tin_not?: InputMaybe<Scalars['String']['input']>
    tin_not_contains?: InputMaybe<Scalars['String']['input']>
    tin_not_contains_i?: InputMaybe<Scalars['String']['input']>
    tin_not_ends_with?: InputMaybe<Scalars['String']['input']>
    tin_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    tin_not_i?: InputMaybe<Scalars['String']['input']>
    tin_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    tin_not_starts_with?: InputMaybe<Scalars['String']['input']>
    tin_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    tin_starts_with?: InputMaybe<Scalars['String']['input']>
    tin_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type BankContractorAccountHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type BankContractorAccountHistoryRecordsCreateInput = {
    data?: InputMaybe<BankContractorAccountHistoryRecordCreateInput>
}

export type BankContractorAccountHistoryRecordsUpdateInput = {
    data?: InputMaybe<BankContractorAccountHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type BankContractorAccountRelateToOneInput = {
    connect?: InputMaybe<BankContractorAccountWhereUniqueInput>
    create?: InputMaybe<BankContractorAccountCreateInput>
    disconnect?: InputMaybe<BankContractorAccountWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type BankContractorAccountUpdateInput = {
    bankName?: InputMaybe<Scalars['String']['input']>
    costItem?: InputMaybe<BankCostItemRelateToOneInput>
    country?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    currencyCode?: InputMaybe<BankContractorAccountCurrencyCodeType>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    number?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    routingNumber?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    territoryCode?: InputMaybe<Scalars['String']['input']>
    tin?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type BankContractorAccountWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<BankContractorAccountWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<BankContractorAccountWhereInput>>>
    bankName?: InputMaybe<Scalars['String']['input']>
    bankName_contains?: InputMaybe<Scalars['String']['input']>
    bankName_contains_i?: InputMaybe<Scalars['String']['input']>
    bankName_ends_with?: InputMaybe<Scalars['String']['input']>
    bankName_ends_with_i?: InputMaybe<Scalars['String']['input']>
    bankName_i?: InputMaybe<Scalars['String']['input']>
    bankName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    bankName_not?: InputMaybe<Scalars['String']['input']>
    bankName_not_contains?: InputMaybe<Scalars['String']['input']>
    bankName_not_contains_i?: InputMaybe<Scalars['String']['input']>
    bankName_not_ends_with?: InputMaybe<Scalars['String']['input']>
    bankName_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    bankName_not_i?: InputMaybe<Scalars['String']['input']>
    bankName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    bankName_not_starts_with?: InputMaybe<Scalars['String']['input']>
    bankName_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    bankName_starts_with?: InputMaybe<Scalars['String']['input']>
    bankName_starts_with_i?: InputMaybe<Scalars['String']['input']>
    costItem?: InputMaybe<BankCostItemWhereInput>
    costItem_is_null?: InputMaybe<Scalars['Boolean']['input']>
    country?: InputMaybe<Scalars['String']['input']>
    country_contains?: InputMaybe<Scalars['String']['input']>
    country_contains_i?: InputMaybe<Scalars['String']['input']>
    country_ends_with?: InputMaybe<Scalars['String']['input']>
    country_ends_with_i?: InputMaybe<Scalars['String']['input']>
    country_i?: InputMaybe<Scalars['String']['input']>
    country_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    country_not?: InputMaybe<Scalars['String']['input']>
    country_not_contains?: InputMaybe<Scalars['String']['input']>
    country_not_contains_i?: InputMaybe<Scalars['String']['input']>
    country_not_ends_with?: InputMaybe<Scalars['String']['input']>
    country_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    country_not_i?: InputMaybe<Scalars['String']['input']>
    country_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    country_not_starts_with?: InputMaybe<Scalars['String']['input']>
    country_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    country_starts_with?: InputMaybe<Scalars['String']['input']>
    country_starts_with_i?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    currencyCode?: InputMaybe<BankContractorAccountCurrencyCodeType>
    currencyCode_in?: InputMaybe<Array<InputMaybe<BankContractorAccountCurrencyCodeType>>>
    currencyCode_not?: InputMaybe<BankContractorAccountCurrencyCodeType>
    currencyCode_not_in?: InputMaybe<Array<InputMaybe<BankContractorAccountCurrencyCodeType>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    importId?: InputMaybe<Scalars['String']['input']>
    importId_contains?: InputMaybe<Scalars['String']['input']>
    importId_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_i?: InputMaybe<Scalars['String']['input']>
    importId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not?: InputMaybe<Scalars['String']['input']>
    importId_not_contains?: InputMaybe<Scalars['String']['input']>
    importId_not_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_not_i?: InputMaybe<Scalars['String']['input']>
    importId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importId_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_starts_with_i?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    number?: InputMaybe<Scalars['String']['input']>
    number_contains?: InputMaybe<Scalars['String']['input']>
    number_contains_i?: InputMaybe<Scalars['String']['input']>
    number_ends_with?: InputMaybe<Scalars['String']['input']>
    number_ends_with_i?: InputMaybe<Scalars['String']['input']>
    number_i?: InputMaybe<Scalars['String']['input']>
    number_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    number_not?: InputMaybe<Scalars['String']['input']>
    number_not_contains?: InputMaybe<Scalars['String']['input']>
    number_not_contains_i?: InputMaybe<Scalars['String']['input']>
    number_not_ends_with?: InputMaybe<Scalars['String']['input']>
    number_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    number_not_i?: InputMaybe<Scalars['String']['input']>
    number_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    number_not_starts_with?: InputMaybe<Scalars['String']['input']>
    number_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    number_starts_with?: InputMaybe<Scalars['String']['input']>
    number_starts_with_i?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    routingNumber?: InputMaybe<Scalars['String']['input']>
    routingNumber_contains?: InputMaybe<Scalars['String']['input']>
    routingNumber_contains_i?: InputMaybe<Scalars['String']['input']>
    routingNumber_ends_with?: InputMaybe<Scalars['String']['input']>
    routingNumber_ends_with_i?: InputMaybe<Scalars['String']['input']>
    routingNumber_i?: InputMaybe<Scalars['String']['input']>
    routingNumber_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    routingNumber_not?: InputMaybe<Scalars['String']['input']>
    routingNumber_not_contains?: InputMaybe<Scalars['String']['input']>
    routingNumber_not_contains_i?: InputMaybe<Scalars['String']['input']>
    routingNumber_not_ends_with?: InputMaybe<Scalars['String']['input']>
    routingNumber_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    routingNumber_not_i?: InputMaybe<Scalars['String']['input']>
    routingNumber_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    routingNumber_not_starts_with?: InputMaybe<Scalars['String']['input']>
    routingNumber_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    routingNumber_starts_with?: InputMaybe<Scalars['String']['input']>
    routingNumber_starts_with_i?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    territoryCode?: InputMaybe<Scalars['String']['input']>
    territoryCode_contains?: InputMaybe<Scalars['String']['input']>
    territoryCode_contains_i?: InputMaybe<Scalars['String']['input']>
    territoryCode_ends_with?: InputMaybe<Scalars['String']['input']>
    territoryCode_ends_with_i?: InputMaybe<Scalars['String']['input']>
    territoryCode_i?: InputMaybe<Scalars['String']['input']>
    territoryCode_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    territoryCode_not?: InputMaybe<Scalars['String']['input']>
    territoryCode_not_contains?: InputMaybe<Scalars['String']['input']>
    territoryCode_not_contains_i?: InputMaybe<Scalars['String']['input']>
    territoryCode_not_ends_with?: InputMaybe<Scalars['String']['input']>
    territoryCode_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    territoryCode_not_i?: InputMaybe<Scalars['String']['input']>
    territoryCode_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    territoryCode_not_starts_with?: InputMaybe<Scalars['String']['input']>
    territoryCode_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    territoryCode_starts_with?: InputMaybe<Scalars['String']['input']>
    territoryCode_starts_with_i?: InputMaybe<Scalars['String']['input']>
    tin?: InputMaybe<Scalars['String']['input']>
    tin_contains?: InputMaybe<Scalars['String']['input']>
    tin_contains_i?: InputMaybe<Scalars['String']['input']>
    tin_ends_with?: InputMaybe<Scalars['String']['input']>
    tin_ends_with_i?: InputMaybe<Scalars['String']['input']>
    tin_i?: InputMaybe<Scalars['String']['input']>
    tin_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    tin_not?: InputMaybe<Scalars['String']['input']>
    tin_not_contains?: InputMaybe<Scalars['String']['input']>
    tin_not_contains_i?: InputMaybe<Scalars['String']['input']>
    tin_not_ends_with?: InputMaybe<Scalars['String']['input']>
    tin_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    tin_not_i?: InputMaybe<Scalars['String']['input']>
    tin_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    tin_not_starts_with?: InputMaybe<Scalars['String']['input']>
    tin_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    tin_starts_with?: InputMaybe<Scalars['String']['input']>
    tin_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type BankContractorAccountWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type BankContractorAccountsCreateInput = {
    data?: InputMaybe<BankContractorAccountCreateInput>
}

export type BankContractorAccountsUpdateInput = {
    data?: InputMaybe<BankContractorAccountUpdateInput>
    id: Scalars['ID']['input']
}

/**  Expenses classification item for BankTransaction. Will be determined by automatic classification feature for each transaction  */
export type BankCostItem = {
    __typename?: 'BankCostItem'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankCostItem List config, or
   *  2. As an alias to the field set on 'labelField' in the BankCostItem List config, or
   *  3. As an alias to a 'name' field on the BankCostItem List (if one exists), or
   *  4. As an alias to the 'id' field on the BankCostItem List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  Used only for grouping to display in UI. Does not used in automatic classification feature  */
    category?: Maybe<BankCategory>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  Indicator of cost item, corresponding to outcome transactions. Outcome-related cost item cannot be assigned to income-transaction  */
    isOutcome?: Maybe<Scalars['Boolean']['output']>
    /**  Name of expenses item as key for i18n  */
    name?: Maybe<Scalars['String']['output']>
    nameNonLocalized?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type BankCostItemCreateInput = {
    category?: InputMaybe<BankCategoryRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    isOutcome?: InputMaybe<Scalars['Boolean']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type BankCostItemHistoryRecord = {
    __typename?: 'BankCostItemHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankCostItemHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the BankCostItemHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the BankCostItemHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the BankCostItemHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    category?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<BankCostItemHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    isOutcome?: Maybe<Scalars['Boolean']['output']>
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type BankCostItemHistoryRecordCreateInput = {
    category?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<BankCostItemHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    isOutcome?: InputMaybe<Scalars['Boolean']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum BankCostItemHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type BankCostItemHistoryRecordUpdateInput = {
    category?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<BankCostItemHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    isOutcome?: InputMaybe<Scalars['Boolean']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type BankCostItemHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<BankCostItemHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<BankCostItemHistoryRecordWhereInput>>>
    category?: InputMaybe<Scalars['String']['input']>
    category_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    category_not?: InputMaybe<Scalars['String']['input']>
    category_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<BankCostItemHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<BankCostItemHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<BankCostItemHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<BankCostItemHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    isOutcome?: InputMaybe<Scalars['Boolean']['input']>
    isOutcome_not?: InputMaybe<Scalars['Boolean']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type BankCostItemHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type BankCostItemHistoryRecordsCreateInput = {
    data?: InputMaybe<BankCostItemHistoryRecordCreateInput>
}

export type BankCostItemHistoryRecordsUpdateInput = {
    data?: InputMaybe<BankCostItemHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type BankCostItemRelateToOneInput = {
    connect?: InputMaybe<BankCostItemWhereUniqueInput>
    create?: InputMaybe<BankCostItemCreateInput>
    disconnect?: InputMaybe<BankCostItemWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type BankCostItemUpdateInput = {
    category?: InputMaybe<BankCategoryRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    isOutcome?: InputMaybe<Scalars['Boolean']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type BankCostItemWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<BankCostItemWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<BankCostItemWhereInput>>>
    category?: InputMaybe<BankCategoryWhereInput>
    category_is_null?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    isOutcome?: InputMaybe<Scalars['Boolean']['input']>
    isOutcome_not?: InputMaybe<Scalars['Boolean']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type BankCostItemWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type BankCostItemsCreateInput = {
    data?: InputMaybe<BankCostItemCreateInput>
}

export type BankCostItemsUpdateInput = {
    data?: InputMaybe<BankCostItemUpdateInput>
    id: Scalars['ID']['input']
}

/**  Determines way of obtaining banking data  */
export type BankIntegration = {
    __typename?: 'BankIntegration'
    _accessRightsMeta?: Maybe<_QueryMeta>
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankIntegration List config, or
   *  2. As an alias to the field set on 'labelField' in the BankIntegration List config, or
   *  3. As an alias to a 'name' field on the BankIntegration List (if one exists), or
   *  4. As an alias to the 'id' field on the BankIntegration List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    accessRights: Array<BankIntegrationAccessRight>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  Name of integration  */
    name?: Maybe<Scalars['String']['output']>
    nameNonLocalized?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}


/**  Determines way of obtaining banking data  */
export type BankIntegration_AccessRightsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankIntegrationAccessRightsBy>>
    where?: InputMaybe<BankIntegrationAccessRightWhereInput>
}


/**  Determines way of obtaining banking data  */
export type BankIntegrationAccessRightsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankIntegrationAccessRightsBy>>
    where?: InputMaybe<BankIntegrationAccessRightWhereInput>
}

/**  Gives ability to service-user to access all schema records, connected to specified integration  */
export type BankIntegrationAccessRight = {
    __typename?: 'BankIntegrationAccessRight'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankIntegrationAccessRight List config, or
   *  2. As an alias to the field set on 'labelField' in the BankIntegrationAccessRight List config, or
   *  3. As an alias to a 'name' field on the BankIntegrationAccessRight List (if one exists), or
   *  4. As an alias to the 'id' field on the BankIntegrationAccessRight List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  Specified integration, whose connected entities will be available for specified service user  */
    integration?: Maybe<BankIntegration>
    newId?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    /**  Link to user. Note, that user must be of "service" type  */
    user?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type BankIntegrationAccessRightCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    integration?: InputMaybe<BankIntegrationRelateToOneInput>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type BankIntegrationAccessRightHistoryRecord = {
    __typename?: 'BankIntegrationAccessRightHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankIntegrationAccessRightHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the BankIntegrationAccessRightHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the BankIntegrationAccessRightHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the BankIntegrationAccessRightHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<BankIntegrationAccessRightHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    integration?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    user?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type BankIntegrationAccessRightHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<BankIntegrationAccessRightHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    integration?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum BankIntegrationAccessRightHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type BankIntegrationAccessRightHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<BankIntegrationAccessRightHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    integration?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type BankIntegrationAccessRightHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<BankIntegrationAccessRightHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<BankIntegrationAccessRightHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<BankIntegrationAccessRightHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<BankIntegrationAccessRightHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<BankIntegrationAccessRightHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<BankIntegrationAccessRightHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    integration?: InputMaybe<Scalars['String']['input']>
    integration_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    integration_not?: InputMaybe<Scalars['String']['input']>
    integration_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user?: InputMaybe<Scalars['String']['input']>
    user_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user_not?: InputMaybe<Scalars['String']['input']>
    user_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type BankIntegrationAccessRightHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type BankIntegrationAccessRightHistoryRecordsCreateInput = {
    data?: InputMaybe<BankIntegrationAccessRightHistoryRecordCreateInput>
}

export type BankIntegrationAccessRightHistoryRecordsUpdateInput = {
    data?: InputMaybe<BankIntegrationAccessRightHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type BankIntegrationAccessRightRelateToManyInput = {
    connect?: InputMaybe<Array<InputMaybe<BankIntegrationAccessRightWhereUniqueInput>>>
    create?: InputMaybe<Array<InputMaybe<BankIntegrationAccessRightCreateInput>>>
    disconnect?: InputMaybe<Array<InputMaybe<BankIntegrationAccessRightWhereUniqueInput>>>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type BankIntegrationAccessRightUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    integration?: InputMaybe<BankIntegrationRelateToOneInput>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type BankIntegrationAccessRightWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<BankIntegrationAccessRightWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<BankIntegrationAccessRightWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    integration?: InputMaybe<BankIntegrationWhereInput>
    integration_is_null?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    user?: InputMaybe<UserWhereInput>
    user_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type BankIntegrationAccessRightWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type BankIntegrationAccessRightsCreateInput = {
    data?: InputMaybe<BankIntegrationAccessRightCreateInput>
}

export type BankIntegrationAccessRightsUpdateInput = {
    data?: InputMaybe<BankIntegrationAccessRightUpdateInput>
    id: Scalars['ID']['input']
}

/**  Usage of specific integration by specific organization. Contains summary information about last synchronization with data source (integration)  */
export type BankIntegrationAccountContext = {
    __typename?: 'BankIntegrationAccountContext'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankIntegrationAccountContext List config, or
   *  2. As an alias to the field set on 'labelField' in the BankIntegrationAccountContext List config, or
   *  3. As an alias to a 'name' field on the BankIntegrationAccountContext List (if one exists), or
   *  4. As an alias to the 'id' field on the BankIntegrationAccountContext List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  Controls availability of sync operation for this integration. Can be disabled by support in some cases, when client dont wants to have automatic synchronization via API, for example  */
    enabled?: Maybe<Scalars['Boolean']['output']>
    id: Scalars['ID']['output']
    /**  Data source, used for this integration.  */
    integration?: Maybe<BankIntegration>
    /**  Structured non-typed metadata, can be used by mini-apps or external services to store information  */
    meta?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
    organization?: Maybe<Organization>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type BankIntegrationAccountContextCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    enabled?: InputMaybe<Scalars['Boolean']['input']>
    integration?: InputMaybe<BankIntegrationRelateToOneInput>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type BankIntegrationAccountContextHistoryRecord = {
    __typename?: 'BankIntegrationAccountContextHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankIntegrationAccountContextHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the BankIntegrationAccountContextHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the BankIntegrationAccountContextHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the BankIntegrationAccountContextHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    enabled?: Maybe<Scalars['Boolean']['output']>
    history_action?: Maybe<BankIntegrationAccountContextHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    integration?: Maybe<Scalars['String']['output']>
    meta?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type BankIntegrationAccountContextHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    enabled?: InputMaybe<Scalars['Boolean']['input']>
    history_action?: InputMaybe<BankIntegrationAccountContextHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    integration?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum BankIntegrationAccountContextHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type BankIntegrationAccountContextHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    enabled?: InputMaybe<Scalars['Boolean']['input']>
    history_action?: InputMaybe<BankIntegrationAccountContextHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    integration?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type BankIntegrationAccountContextHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<BankIntegrationAccountContextHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<BankIntegrationAccountContextHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    enabled?: InputMaybe<Scalars['Boolean']['input']>
    enabled_not?: InputMaybe<Scalars['Boolean']['input']>
    history_action?: InputMaybe<BankIntegrationAccountContextHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<BankIntegrationAccountContextHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<BankIntegrationAccountContextHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<BankIntegrationAccountContextHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    integration?: InputMaybe<Scalars['String']['input']>
    integration_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    integration_not?: InputMaybe<Scalars['String']['input']>
    integration_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type BankIntegrationAccountContextHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type BankIntegrationAccountContextHistoryRecordsCreateInput = {
    data?: InputMaybe<BankIntegrationAccountContextHistoryRecordCreateInput>
}

export type BankIntegrationAccountContextHistoryRecordsUpdateInput = {
    data?: InputMaybe<BankIntegrationAccountContextHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type BankIntegrationAccountContextRelateToOneInput = {
    connect?: InputMaybe<BankIntegrationAccountContextWhereUniqueInput>
    create?: InputMaybe<BankIntegrationAccountContextCreateInput>
    disconnect?: InputMaybe<BankIntegrationAccountContextWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type BankIntegrationAccountContextUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    enabled?: InputMaybe<Scalars['Boolean']['input']>
    integration?: InputMaybe<BankIntegrationRelateToOneInput>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type BankIntegrationAccountContextWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<BankIntegrationAccountContextWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<BankIntegrationAccountContextWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    enabled?: InputMaybe<Scalars['Boolean']['input']>
    enabled_not?: InputMaybe<Scalars['Boolean']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    integration?: InputMaybe<BankIntegrationWhereInput>
    integration_is_null?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type BankIntegrationAccountContextWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type BankIntegrationAccountContextsCreateInput = {
    data?: InputMaybe<BankIntegrationAccountContextCreateInput>
}

export type BankIntegrationAccountContextsUpdateInput = {
    data?: InputMaybe<BankIntegrationAccountContextUpdateInput>
    id: Scalars['ID']['input']
}

export type BankIntegrationCreateInput = {
    accessRights?: InputMaybe<BankIntegrationAccessRightRelateToManyInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type BankIntegrationHistoryRecord = {
    __typename?: 'BankIntegrationHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankIntegrationHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the BankIntegrationHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the BankIntegrationHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the BankIntegrationHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<BankIntegrationHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type BankIntegrationHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<BankIntegrationHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum BankIntegrationHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type BankIntegrationHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<BankIntegrationHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type BankIntegrationHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<BankIntegrationHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<BankIntegrationHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<BankIntegrationHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<BankIntegrationHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<BankIntegrationHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<BankIntegrationHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type BankIntegrationHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type BankIntegrationHistoryRecordsCreateInput = {
    data?: InputMaybe<BankIntegrationHistoryRecordCreateInput>
}

export type BankIntegrationHistoryRecordsUpdateInput = {
    data?: InputMaybe<BankIntegrationHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

/**  Stores the integrations connected to the organization  */
export type BankIntegrationOrganizationContext = {
    __typename?: 'BankIntegrationOrganizationContext'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankIntegrationOrganizationContext List config, or
   *  2. As an alias to the field set on 'labelField' in the BankIntegrationOrganizationContext List config, or
   *  3. As an alias to a 'name' field on the BankIntegrationOrganizationContext List (if one exists), or
   *  4. As an alias to the 'id' field on the BankIntegrationOrganizationContext List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  Controls availability of sync operation for this integration in scope of organization. Can be disabled by support in some cases, when client dont wants to have automatic synchronization via API, for example  */
    enabled?: Maybe<Scalars['Boolean']['output']>
    id: Scalars['ID']['output']
    /**  Data source, used for this integration.  */
    integration?: Maybe<BankIntegration>
    newId?: Maybe<Scalars['String']['output']>
    /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
    organization?: Maybe<Organization>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type BankIntegrationOrganizationContextCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    enabled?: InputMaybe<Scalars['Boolean']['input']>
    integration?: InputMaybe<BankIntegrationRelateToOneInput>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type BankIntegrationOrganizationContextHistoryRecord = {
    __typename?: 'BankIntegrationOrganizationContextHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankIntegrationOrganizationContextHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the BankIntegrationOrganizationContextHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the BankIntegrationOrganizationContextHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the BankIntegrationOrganizationContextHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    enabled?: Maybe<Scalars['Boolean']['output']>
    history_action?: Maybe<BankIntegrationOrganizationContextHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    integration?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type BankIntegrationOrganizationContextHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    enabled?: InputMaybe<Scalars['Boolean']['input']>
    history_action?: InputMaybe<BankIntegrationOrganizationContextHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    integration?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum BankIntegrationOrganizationContextHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type BankIntegrationOrganizationContextHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    enabled?: InputMaybe<Scalars['Boolean']['input']>
    history_action?: InputMaybe<BankIntegrationOrganizationContextHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    integration?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type BankIntegrationOrganizationContextHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<BankIntegrationOrganizationContextHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<BankIntegrationOrganizationContextHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    enabled?: InputMaybe<Scalars['Boolean']['input']>
    enabled_not?: InputMaybe<Scalars['Boolean']['input']>
    history_action?: InputMaybe<BankIntegrationOrganizationContextHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<BankIntegrationOrganizationContextHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<BankIntegrationOrganizationContextHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<BankIntegrationOrganizationContextHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    integration?: InputMaybe<Scalars['String']['input']>
    integration_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    integration_not?: InputMaybe<Scalars['String']['input']>
    integration_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type BankIntegrationOrganizationContextHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type BankIntegrationOrganizationContextHistoryRecordsCreateInput = {
    data?: InputMaybe<BankIntegrationOrganizationContextHistoryRecordCreateInput>
}

export type BankIntegrationOrganizationContextHistoryRecordsUpdateInput = {
    data?: InputMaybe<BankIntegrationOrganizationContextHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type BankIntegrationOrganizationContextUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    enabled?: InputMaybe<Scalars['Boolean']['input']>
    integration?: InputMaybe<BankIntegrationRelateToOneInput>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type BankIntegrationOrganizationContextWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<BankIntegrationOrganizationContextWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<BankIntegrationOrganizationContextWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    enabled?: InputMaybe<Scalars['Boolean']['input']>
    enabled_not?: InputMaybe<Scalars['Boolean']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    integration?: InputMaybe<BankIntegrationWhereInput>
    integration_is_null?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type BankIntegrationOrganizationContextWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type BankIntegrationOrganizationContextsCreateInput = {
    data?: InputMaybe<BankIntegrationOrganizationContextCreateInput>
}

export type BankIntegrationOrganizationContextsUpdateInput = {
    data?: InputMaybe<BankIntegrationOrganizationContextUpdateInput>
    id: Scalars['ID']['input']
}

export type BankIntegrationRelateToOneInput = {
    connect?: InputMaybe<BankIntegrationWhereUniqueInput>
    create?: InputMaybe<BankIntegrationCreateInput>
    disconnect?: InputMaybe<BankIntegrationWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type BankIntegrationUpdateInput = {
    accessRights?: InputMaybe<BankIntegrationAccessRightRelateToManyInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type BankIntegrationWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<BankIntegrationWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<BankIntegrationWhereInput>>>
    /**  condition must be true for all nodes  */
    accessRights_every?: InputMaybe<BankIntegrationAccessRightWhereInput>
    /**  condition must be false for all nodes  */
    accessRights_none?: InputMaybe<BankIntegrationAccessRightWhereInput>
    /**  condition must be true for at least 1 node  */
    accessRights_some?: InputMaybe<BankIntegrationAccessRightWhereInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type BankIntegrationWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type BankIntegrationsCreateInput = {
    data?: InputMaybe<BankIntegrationCreateInput>
}

export type BankIntegrationsUpdateInput = {
    data?: InputMaybe<BankIntegrationUpdateInput>
    id: Scalars['ID']['input']
}

/**  information about synchronization process of transactions with external source of from uploaded file  */
export type BankSyncTask = {
    __typename?: 'BankSyncTask'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankSyncTask List config, or
   *  2. As an alias to the field set on 'labelField' in the BankSyncTask List config, or
   *  3. As an alias to a 'name' field on the BankSyncTask List (if one exists), or
   *  4. As an alias to the 'id' field on the BankSyncTask List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  Bank account for which current synchronization operation is performed. Can be unknown when account does not exist before import  */
    account?: Maybe<BankAccount>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  File from which transactions should be imported. Currently only 1CClientBankExchange format is supported  */
    file?: Maybe<File>
    id: Scalars['ID']['output']
    /**  Integration context of account for which current synchronization operation is performed. Can be unknown when account and integration does not exist before import  */
    integrationContext?: Maybe<BankIntegrationAccountContext>
    /**  Additional data, specific to used integration  */
    meta?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Options for launching tasks for banking domain  */
    options?: Maybe<BankSyncTaskOptions>
    /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
    organization?: Maybe<Organization>
    /**  Count of transactions, that has been actually created in our database based in data from external source, determined by integration context  */
    processedCount?: Maybe<Scalars['Int']['output']>
    /**  Property to which sync operation in this task should be related. When property is specified, new BankAccount will be connected to it if it does not exists  */
    property?: Maybe<Property>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Status of current synchronization operation  */
    status?: Maybe<BankSyncTaskStatusType>
    /**  Count of transactions to import. Can be unknown in advance  */
    totalCount?: Maybe<Scalars['Int']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    /**  User that requested this operation. Will be used for read access checks to display all tasks somewhere and to display progress indicator of ongoing exporting task for current user  */
    user?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type BankSyncTaskCreateInput = {
    account?: InputMaybe<BankAccountRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['Upload']['input']>
    integrationContext?: InputMaybe<BankIntegrationAccountContextRelateToOneInput>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    options?: InputMaybe<BankSyncTaskOptionsInput>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    processedCount?: InputMaybe<Scalars['Int']['input']>
    property?: InputMaybe<PropertyRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    status?: InputMaybe<BankSyncTaskStatusType>
    totalCount?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type BankSyncTaskHistoryRecord = {
    __typename?: 'BankSyncTaskHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankSyncTaskHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the BankSyncTaskHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the BankSyncTaskHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the BankSyncTaskHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    account?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    file?: Maybe<Scalars['JSON']['output']>
    history_action?: Maybe<BankSyncTaskHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    integrationContext?: Maybe<Scalars['String']['output']>
    meta?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    options?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    processedCount?: Maybe<Scalars['Int']['output']>
    property?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    status?: Maybe<Scalars['String']['output']>
    totalCount?: Maybe<Scalars['Int']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    user?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type BankSyncTaskHistoryRecordCreateInput = {
    account?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['JSON']['input']>
    history_action?: InputMaybe<BankSyncTaskHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    integrationContext?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    options?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    processedCount?: InputMaybe<Scalars['Int']['input']>
    property?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    totalCount?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum BankSyncTaskHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type BankSyncTaskHistoryRecordUpdateInput = {
    account?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['JSON']['input']>
    history_action?: InputMaybe<BankSyncTaskHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    integrationContext?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    options?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    processedCount?: InputMaybe<Scalars['Int']['input']>
    property?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    totalCount?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type BankSyncTaskHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<BankSyncTaskHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<BankSyncTaskHistoryRecordWhereInput>>>
    account?: InputMaybe<Scalars['String']['input']>
    account_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    account_not?: InputMaybe<Scalars['String']['input']>
    account_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    file?: InputMaybe<Scalars['JSON']['input']>
    file_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    file_not?: InputMaybe<Scalars['JSON']['input']>
    file_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    history_action?: InputMaybe<BankSyncTaskHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<BankSyncTaskHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<BankSyncTaskHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<BankSyncTaskHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    integrationContext?: InputMaybe<Scalars['String']['input']>
    integrationContext_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    integrationContext_not?: InputMaybe<Scalars['String']['input']>
    integrationContext_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    options?: InputMaybe<Scalars['JSON']['input']>
    options_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    options_not?: InputMaybe<Scalars['JSON']['input']>
    options_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    processedCount?: InputMaybe<Scalars['Int']['input']>
    processedCount_gt?: InputMaybe<Scalars['Int']['input']>
    processedCount_gte?: InputMaybe<Scalars['Int']['input']>
    processedCount_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    processedCount_lt?: InputMaybe<Scalars['Int']['input']>
    processedCount_lte?: InputMaybe<Scalars['Int']['input']>
    processedCount_not?: InputMaybe<Scalars['Int']['input']>
    processedCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    property?: InputMaybe<Scalars['String']['input']>
    property_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    property_not?: InputMaybe<Scalars['String']['input']>
    property_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    status?: InputMaybe<Scalars['String']['input']>
    status_contains?: InputMaybe<Scalars['String']['input']>
    status_contains_i?: InputMaybe<Scalars['String']['input']>
    status_ends_with?: InputMaybe<Scalars['String']['input']>
    status_ends_with_i?: InputMaybe<Scalars['String']['input']>
    status_i?: InputMaybe<Scalars['String']['input']>
    status_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status_not?: InputMaybe<Scalars['String']['input']>
    status_not_contains?: InputMaybe<Scalars['String']['input']>
    status_not_contains_i?: InputMaybe<Scalars['String']['input']>
    status_not_ends_with?: InputMaybe<Scalars['String']['input']>
    status_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    status_not_i?: InputMaybe<Scalars['String']['input']>
    status_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status_not_starts_with?: InputMaybe<Scalars['String']['input']>
    status_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    status_starts_with?: InputMaybe<Scalars['String']['input']>
    status_starts_with_i?: InputMaybe<Scalars['String']['input']>
    totalCount?: InputMaybe<Scalars['Int']['input']>
    totalCount_gt?: InputMaybe<Scalars['Int']['input']>
    totalCount_gte?: InputMaybe<Scalars['Int']['input']>
    totalCount_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    totalCount_lt?: InputMaybe<Scalars['Int']['input']>
    totalCount_lte?: InputMaybe<Scalars['Int']['input']>
    totalCount_not?: InputMaybe<Scalars['Int']['input']>
    totalCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user?: InputMaybe<Scalars['String']['input']>
    user_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user_not?: InputMaybe<Scalars['String']['input']>
    user_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type BankSyncTaskHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type BankSyncTaskHistoryRecordsCreateInput = {
    data?: InputMaybe<BankSyncTaskHistoryRecordCreateInput>
}

export type BankSyncTaskHistoryRecordsUpdateInput = {
    data?: InputMaybe<BankSyncTaskHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type BankSyncTaskOptions = {
    __typename?: 'BankSyncTaskOptions'
    dateFrom?: Maybe<Scalars['String']['output']>
    dateTo?: Maybe<Scalars['String']['output']>
    type?: Maybe<Scalars['String']['output']>
}

export type BankSyncTaskOptionsInput = {
    dateFrom?: InputMaybe<Scalars['String']['input']>
    dateTo?: InputMaybe<Scalars['String']['input']>
    type?: InputMaybe<Scalars['String']['input']>
}

export enum BankSyncTaskStatusType {
    Cancelled = 'cancelled',
    Completed = 'completed',
    Error = 'error',
    Processing = 'processing',
}

export type BankSyncTaskUpdateInput = {
    account?: InputMaybe<BankAccountRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['Upload']['input']>
    integrationContext?: InputMaybe<BankIntegrationAccountContextRelateToOneInput>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    options?: InputMaybe<BankSyncTaskOptionsInput>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    processedCount?: InputMaybe<Scalars['Int']['input']>
    property?: InputMaybe<PropertyRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    status?: InputMaybe<BankSyncTaskStatusType>
    totalCount?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type BankSyncTaskWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<BankSyncTaskWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<BankSyncTaskWhereInput>>>
    account?: InputMaybe<BankAccountWhereInput>
    account_is_null?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    file?: InputMaybe<Scalars['String']['input']>
    file_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    file_not?: InputMaybe<Scalars['String']['input']>
    file_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    integrationContext?: InputMaybe<BankIntegrationAccountContextWhereInput>
    integrationContext_is_null?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    options?: InputMaybe<BankSyncTaskOptionsInput>
    options_in?: InputMaybe<Array<InputMaybe<BankSyncTaskOptionsInput>>>
    options_not?: InputMaybe<BankSyncTaskOptionsInput>
    options_not_in?: InputMaybe<Array<InputMaybe<BankSyncTaskOptionsInput>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    processedCount?: InputMaybe<Scalars['Int']['input']>
    processedCount_gt?: InputMaybe<Scalars['Int']['input']>
    processedCount_gte?: InputMaybe<Scalars['Int']['input']>
    processedCount_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    processedCount_lt?: InputMaybe<Scalars['Int']['input']>
    processedCount_lte?: InputMaybe<Scalars['Int']['input']>
    processedCount_not?: InputMaybe<Scalars['Int']['input']>
    processedCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    property?: InputMaybe<PropertyWhereInput>
    property_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    status?: InputMaybe<BankSyncTaskStatusType>
    status_in?: InputMaybe<Array<InputMaybe<BankSyncTaskStatusType>>>
    status_not?: InputMaybe<BankSyncTaskStatusType>
    status_not_in?: InputMaybe<Array<InputMaybe<BankSyncTaskStatusType>>>
    totalCount?: InputMaybe<Scalars['Int']['input']>
    totalCount_gt?: InputMaybe<Scalars['Int']['input']>
    totalCount_gte?: InputMaybe<Scalars['Int']['input']>
    totalCount_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    totalCount_lt?: InputMaybe<Scalars['Int']['input']>
    totalCount_lte?: InputMaybe<Scalars['Int']['input']>
    totalCount_not?: InputMaybe<Scalars['Int']['input']>
    totalCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    user?: InputMaybe<UserWhereInput>
    user_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type BankSyncTaskWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type BankSyncTasksCreateInput = {
    data?: InputMaybe<BankSyncTaskCreateInput>
}

export type BankSyncTasksUpdateInput = {
    data?: InputMaybe<BankSyncTaskUpdateInput>
    id: Scalars['ID']['input']
}

/**  Transaction related to costs of Organization with BankAccount. Full fields set from data import will be stored in "meta"  */
export type BankTransaction = {
    __typename?: 'BankTransaction'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankTransaction List config, or
   *  2. As an alias to the field set on 'labelField' in the BankTransaction List config, or
   *  3. As an alias to a 'name' field on the BankTransaction List (if one exists), or
   *  4. As an alias to the 'id' field on the BankTransaction List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  Related BankAccount of Organization which payed  */
    account?: Maybe<BankAccount>
    /**  Amount of transaction in specified currency. Always positive number. Look at "isOutcome" field to determine whether this transaction commits negative or positive change to balance  */
    amount?: Maybe<Scalars['String']['output']>
    /**  Related account of contractor, which has received the payment via this transaction  */
    contractorAccount?: Maybe<BankContractorAccount>
    /**  Related costs class  */
    costItem?: Maybe<BankCostItem>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    /**  Code of currency in ISO-4217 format  */
    currencyCode?: Maybe<BankTransactionCurrencyCodeType>
    /**  When payment order was created  */
    date?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  Identifier of corresponding record in external system, from that this record was copied  */
    importId?: Maybe<Scalars['String']['output']>
    /**  Name of external system, from that this record was copied  */
    importRemoteSystem?: Maybe<Scalars['String']['output']>
    /**  Data source from which this transaction was created  */
    integrationContext?: Maybe<BankIntegrationAccountContext>
    /**  Indicator of outcome transaction which commits negative change to balance  */
    isOutcome?: Maybe<Scalars['Boolean']['output']>
    /**  Stores data, obtained from external source  */
    meta?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Number of transaction, obtained from external system  */
    number?: Maybe<Scalars['String']['output']>
    /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
    organization?: Maybe<Organization>
    /**  Textual description of payment purpose in free form  */
    purpose?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type BankTransactionCreateInput = {
    account?: InputMaybe<BankAccountRelateToOneInput>
    amount?: InputMaybe<Scalars['String']['input']>
    contractorAccount?: InputMaybe<BankContractorAccountRelateToOneInput>
    costItem?: InputMaybe<BankCostItemRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    currencyCode?: InputMaybe<BankTransactionCurrencyCodeType>
    date?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem?: InputMaybe<Scalars['String']['input']>
    integrationContext?: InputMaybe<BankIntegrationAccountContextRelateToOneInput>
    isOutcome?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    number?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    purpose?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum BankTransactionCurrencyCodeType {
    Aed = 'AED',
    Afn = 'AFN',
    All = 'ALL',
    Amd = 'AMD',
    Ang = 'ANG',
    Aoa = 'AOA',
    Ars = 'ARS',
    Aud = 'AUD',
    Awg = 'AWG',
    Azn = 'AZN',
    Bam = 'BAM',
    Bbd = 'BBD',
    Bdt = 'BDT',
    Bgn = 'BGN',
    Bhd = 'BHD',
    Bif = 'BIF',
    Bmd = 'BMD',
    Bnd = 'BND',
    Bob = 'BOB',
    Bov = 'BOV',
    Brl = 'BRL',
    Bsd = 'BSD',
    Btn = 'BTN',
    Bwp = 'BWP',
    Byn = 'BYN',
    Bzd = 'BZD',
    Cad = 'CAD',
    Cdf = 'CDF',
    Che = 'CHE',
    Chf = 'CHF',
    Chw = 'CHW',
    Clf = 'CLF',
    Clp = 'CLP',
    Cny = 'CNY',
    Cop = 'COP',
    Cou = 'COU',
    Crc = 'CRC',
    Cuc = 'CUC',
    Cup = 'CUP',
    Cve = 'CVE',
    Czk = 'CZK',
    Djf = 'DJF',
    Dkk = 'DKK',
    Dop = 'DOP',
    Dzd = 'DZD',
    Egp = 'EGP',
    Ern = 'ERN',
    Etb = 'ETB',
    Eur = 'EUR',
    Fjd = 'FJD',
    Fkp = 'FKP',
    Gbp = 'GBP',
    Gel = 'GEL',
    Ghs = 'GHS',
    Gip = 'GIP',
    Gmd = 'GMD',
    Gnf = 'GNF',
    Gtq = 'GTQ',
    Gyd = 'GYD',
    Hkd = 'HKD',
    Hnl = 'HNL',
    Hrk = 'HRK',
    Htg = 'HTG',
    Huf = 'HUF',
    Idr = 'IDR',
    Ils = 'ILS',
    Inr = 'INR',
    Iqd = 'IQD',
    Irr = 'IRR',
    Isk = 'ISK',
    Jmd = 'JMD',
    Jod = 'JOD',
    Jpy = 'JPY',
    Kes = 'KES',
    Kgs = 'KGS',
    Khr = 'KHR',
    Kmf = 'KMF',
    Kpw = 'KPW',
    Krw = 'KRW',
    Kwd = 'KWD',
    Kyd = 'KYD',
    Kzt = 'KZT',
    Lak = 'LAK',
    Lbp = 'LBP',
    Lkr = 'LKR',
    Lrd = 'LRD',
    Lsl = 'LSL',
    Lyd = 'LYD',
    Mad = 'MAD',
    Mdl = 'MDL',
    Mga = 'MGA',
    Mkd = 'MKD',
    Mmk = 'MMK',
    Mnt = 'MNT',
    Mop = 'MOP',
    Mru = 'MRU',
    Mur = 'MUR',
    Mvr = 'MVR',
    Mwk = 'MWK',
    Mxn = 'MXN',
    Mxv = 'MXV',
    Myr = 'MYR',
    Mzn = 'MZN',
    Nad = 'NAD',
    Ngn = 'NGN',
    Nio = 'NIO',
    Nok = 'NOK',
    Npr = 'NPR',
    Nzd = 'NZD',
    Omr = 'OMR',
    Pab = 'PAB',
    Pen = 'PEN',
    Pgk = 'PGK',
    Php = 'PHP',
    Pkr = 'PKR',
    Pln = 'PLN',
    Pyg = 'PYG',
    Qar = 'QAR',
    Ron = 'RON',
    Rsd = 'RSD',
    Rub = 'RUB',
    Rwf = 'RWF',
    Sar = 'SAR',
    Sbd = 'SBD',
    Scr = 'SCR',
    Sdg = 'SDG',
    Sek = 'SEK',
    Sgd = 'SGD',
    Shp = 'SHP',
    Sll = 'SLL',
    Sos = 'SOS',
    Srd = 'SRD',
    Ssp = 'SSP',
    Stn = 'STN',
    Svc = 'SVC',
    Syp = 'SYP',
    Szl = 'SZL',
    Thb = 'THB',
    Tjs = 'TJS',
    Tmt = 'TMT',
    Tnd = 'TND',
    Top = 'TOP',
    Try = 'TRY',
    Ttd = 'TTD',
    Twd = 'TWD',
    Tzs = 'TZS',
    Uah = 'UAH',
    Ugx = 'UGX',
    Usd = 'USD',
    Usn = 'USN',
    Uyi = 'UYI',
    Uyu = 'UYU',
    Uyw = 'UYW',
    Uzs = 'UZS',
    Ves = 'VES',
    Vnd = 'VND',
    Vuv = 'VUV',
    Wst = 'WST',
    Xaf = 'XAF',
    Xag = 'XAG',
    Xau = 'XAU',
    Xba = 'XBA',
    Xbb = 'XBB',
    Xbc = 'XBC',
    Xbd = 'XBD',
    Xcd = 'XCD',
    Xdr = 'XDR',
    Xof = 'XOF',
    Xpd = 'XPD',
    Xpf = 'XPF',
    Xpt = 'XPT',
    Xsu = 'XSU',
    Xts = 'XTS',
    Xua = 'XUA',
    Yer = 'YER',
    Zar = 'ZAR',
    Zmw = 'ZMW',
    Zwl = 'ZWL',
}

/**  A keystone list  */
export type BankTransactionHistoryRecord = {
    __typename?: 'BankTransactionHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankTransactionHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the BankTransactionHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the BankTransactionHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the BankTransactionHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    account?: Maybe<Scalars['String']['output']>
    amount?: Maybe<Scalars['String']['output']>
    contractorAccount?: Maybe<Scalars['String']['output']>
    costItem?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    currencyCode?: Maybe<Scalars['String']['output']>
    date?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<BankTransactionHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    importId?: Maybe<Scalars['String']['output']>
    importRemoteSystem?: Maybe<Scalars['String']['output']>
    integrationContext?: Maybe<Scalars['String']['output']>
    isOutcome?: Maybe<Scalars['Boolean']['output']>
    meta?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    number?: Maybe<Scalars['String']['output']>
    organization?: Maybe<Scalars['String']['output']>
    purpose?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type BankTransactionHistoryRecordCreateInput = {
    account?: InputMaybe<Scalars['String']['input']>
    amount?: InputMaybe<Scalars['String']['input']>
    contractorAccount?: InputMaybe<Scalars['String']['input']>
    costItem?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    currencyCode?: InputMaybe<Scalars['String']['input']>
    date?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<BankTransactionHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem?: InputMaybe<Scalars['String']['input']>
    integrationContext?: InputMaybe<Scalars['String']['input']>
    isOutcome?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    number?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    purpose?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum BankTransactionHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type BankTransactionHistoryRecordUpdateInput = {
    account?: InputMaybe<Scalars['String']['input']>
    amount?: InputMaybe<Scalars['String']['input']>
    contractorAccount?: InputMaybe<Scalars['String']['input']>
    costItem?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    currencyCode?: InputMaybe<Scalars['String']['input']>
    date?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<BankTransactionHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem?: InputMaybe<Scalars['String']['input']>
    integrationContext?: InputMaybe<Scalars['String']['input']>
    isOutcome?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    number?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    purpose?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type BankTransactionHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<BankTransactionHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<BankTransactionHistoryRecordWhereInput>>>
    account?: InputMaybe<Scalars['String']['input']>
    account_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    account_not?: InputMaybe<Scalars['String']['input']>
    account_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    amount?: InputMaybe<Scalars['String']['input']>
    amount_gt?: InputMaybe<Scalars['String']['input']>
    amount_gte?: InputMaybe<Scalars['String']['input']>
    amount_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    amount_lt?: InputMaybe<Scalars['String']['input']>
    amount_lte?: InputMaybe<Scalars['String']['input']>
    amount_not?: InputMaybe<Scalars['String']['input']>
    amount_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    contractorAccount?: InputMaybe<Scalars['String']['input']>
    contractorAccount_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    contractorAccount_not?: InputMaybe<Scalars['String']['input']>
    contractorAccount_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    costItem?: InputMaybe<Scalars['String']['input']>
    costItem_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    costItem_not?: InputMaybe<Scalars['String']['input']>
    costItem_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    currencyCode?: InputMaybe<Scalars['String']['input']>
    currencyCode_contains?: InputMaybe<Scalars['String']['input']>
    currencyCode_contains_i?: InputMaybe<Scalars['String']['input']>
    currencyCode_ends_with?: InputMaybe<Scalars['String']['input']>
    currencyCode_ends_with_i?: InputMaybe<Scalars['String']['input']>
    currencyCode_i?: InputMaybe<Scalars['String']['input']>
    currencyCode_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    currencyCode_not?: InputMaybe<Scalars['String']['input']>
    currencyCode_not_contains?: InputMaybe<Scalars['String']['input']>
    currencyCode_not_contains_i?: InputMaybe<Scalars['String']['input']>
    currencyCode_not_ends_with?: InputMaybe<Scalars['String']['input']>
    currencyCode_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    currencyCode_not_i?: InputMaybe<Scalars['String']['input']>
    currencyCode_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    currencyCode_not_starts_with?: InputMaybe<Scalars['String']['input']>
    currencyCode_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    currencyCode_starts_with?: InputMaybe<Scalars['String']['input']>
    currencyCode_starts_with_i?: InputMaybe<Scalars['String']['input']>
    date?: InputMaybe<Scalars['String']['input']>
    date_gt?: InputMaybe<Scalars['String']['input']>
    date_gte?: InputMaybe<Scalars['String']['input']>
    date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    date_lt?: InputMaybe<Scalars['String']['input']>
    date_lte?: InputMaybe<Scalars['String']['input']>
    date_not?: InputMaybe<Scalars['String']['input']>
    date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<BankTransactionHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<BankTransactionHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<BankTransactionHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<BankTransactionHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    importId?: InputMaybe<Scalars['String']['input']>
    importId_contains?: InputMaybe<Scalars['String']['input']>
    importId_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_i?: InputMaybe<Scalars['String']['input']>
    importId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not?: InputMaybe<Scalars['String']['input']>
    importId_not_contains?: InputMaybe<Scalars['String']['input']>
    importId_not_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_not_i?: InputMaybe<Scalars['String']['input']>
    importId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importId_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_contains?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_contains_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_ends_with?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importRemoteSystem_not?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_contains?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_contains_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_ends_with?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importRemoteSystem_not_starts_with?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_starts_with?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_starts_with_i?: InputMaybe<Scalars['String']['input']>
    integrationContext?: InputMaybe<Scalars['String']['input']>
    integrationContext_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    integrationContext_not?: InputMaybe<Scalars['String']['input']>
    integrationContext_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    isOutcome?: InputMaybe<Scalars['Boolean']['input']>
    isOutcome_not?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    number?: InputMaybe<Scalars['String']['input']>
    number_contains?: InputMaybe<Scalars['String']['input']>
    number_contains_i?: InputMaybe<Scalars['String']['input']>
    number_ends_with?: InputMaybe<Scalars['String']['input']>
    number_ends_with_i?: InputMaybe<Scalars['String']['input']>
    number_i?: InputMaybe<Scalars['String']['input']>
    number_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    number_not?: InputMaybe<Scalars['String']['input']>
    number_not_contains?: InputMaybe<Scalars['String']['input']>
    number_not_contains_i?: InputMaybe<Scalars['String']['input']>
    number_not_ends_with?: InputMaybe<Scalars['String']['input']>
    number_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    number_not_i?: InputMaybe<Scalars['String']['input']>
    number_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    number_not_starts_with?: InputMaybe<Scalars['String']['input']>
    number_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    number_starts_with?: InputMaybe<Scalars['String']['input']>
    number_starts_with_i?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    purpose?: InputMaybe<Scalars['String']['input']>
    purpose_contains?: InputMaybe<Scalars['String']['input']>
    purpose_contains_i?: InputMaybe<Scalars['String']['input']>
    purpose_ends_with?: InputMaybe<Scalars['String']['input']>
    purpose_ends_with_i?: InputMaybe<Scalars['String']['input']>
    purpose_i?: InputMaybe<Scalars['String']['input']>
    purpose_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    purpose_not?: InputMaybe<Scalars['String']['input']>
    purpose_not_contains?: InputMaybe<Scalars['String']['input']>
    purpose_not_contains_i?: InputMaybe<Scalars['String']['input']>
    purpose_not_ends_with?: InputMaybe<Scalars['String']['input']>
    purpose_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    purpose_not_i?: InputMaybe<Scalars['String']['input']>
    purpose_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    purpose_not_starts_with?: InputMaybe<Scalars['String']['input']>
    purpose_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    purpose_starts_with?: InputMaybe<Scalars['String']['input']>
    purpose_starts_with_i?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type BankTransactionHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type BankTransactionHistoryRecordsCreateInput = {
    data?: InputMaybe<BankTransactionHistoryRecordCreateInput>
}

export type BankTransactionHistoryRecordsUpdateInput = {
    data?: InputMaybe<BankTransactionHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type BankTransactionUpdateInput = {
    account?: InputMaybe<BankAccountRelateToOneInput>
    amount?: InputMaybe<Scalars['String']['input']>
    contractorAccount?: InputMaybe<BankContractorAccountRelateToOneInput>
    costItem?: InputMaybe<BankCostItemRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    currencyCode?: InputMaybe<BankTransactionCurrencyCodeType>
    date?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem?: InputMaybe<Scalars['String']['input']>
    integrationContext?: InputMaybe<BankIntegrationAccountContextRelateToOneInput>
    isOutcome?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    number?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    purpose?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type BankTransactionWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<BankTransactionWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<BankTransactionWhereInput>>>
    account?: InputMaybe<BankAccountWhereInput>
    account_is_null?: InputMaybe<Scalars['Boolean']['input']>
    amount?: InputMaybe<Scalars['String']['input']>
    amount_gt?: InputMaybe<Scalars['String']['input']>
    amount_gte?: InputMaybe<Scalars['String']['input']>
    amount_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    amount_lt?: InputMaybe<Scalars['String']['input']>
    amount_lte?: InputMaybe<Scalars['String']['input']>
    amount_not?: InputMaybe<Scalars['String']['input']>
    amount_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    contractorAccount?: InputMaybe<BankContractorAccountWhereInput>
    contractorAccount_is_null?: InputMaybe<Scalars['Boolean']['input']>
    costItem?: InputMaybe<BankCostItemWhereInput>
    costItem_is_null?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    currencyCode?: InputMaybe<BankTransactionCurrencyCodeType>
    currencyCode_in?: InputMaybe<Array<InputMaybe<BankTransactionCurrencyCodeType>>>
    currencyCode_not?: InputMaybe<BankTransactionCurrencyCodeType>
    currencyCode_not_in?: InputMaybe<Array<InputMaybe<BankTransactionCurrencyCodeType>>>
    date?: InputMaybe<Scalars['String']['input']>
    date_gt?: InputMaybe<Scalars['String']['input']>
    date_gte?: InputMaybe<Scalars['String']['input']>
    date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    date_lt?: InputMaybe<Scalars['String']['input']>
    date_lte?: InputMaybe<Scalars['String']['input']>
    date_not?: InputMaybe<Scalars['String']['input']>
    date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    importId?: InputMaybe<Scalars['String']['input']>
    importId_contains?: InputMaybe<Scalars['String']['input']>
    importId_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_i?: InputMaybe<Scalars['String']['input']>
    importId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not?: InputMaybe<Scalars['String']['input']>
    importId_not_contains?: InputMaybe<Scalars['String']['input']>
    importId_not_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_not_i?: InputMaybe<Scalars['String']['input']>
    importId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importId_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importRemoteSystem_not?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    integrationContext?: InputMaybe<BankIntegrationAccountContextWhereInput>
    integrationContext_is_null?: InputMaybe<Scalars['Boolean']['input']>
    isOutcome?: InputMaybe<Scalars['Boolean']['input']>
    isOutcome_not?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    number?: InputMaybe<Scalars['String']['input']>
    number_contains?: InputMaybe<Scalars['String']['input']>
    number_contains_i?: InputMaybe<Scalars['String']['input']>
    number_ends_with?: InputMaybe<Scalars['String']['input']>
    number_ends_with_i?: InputMaybe<Scalars['String']['input']>
    number_i?: InputMaybe<Scalars['String']['input']>
    number_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    number_not?: InputMaybe<Scalars['String']['input']>
    number_not_contains?: InputMaybe<Scalars['String']['input']>
    number_not_contains_i?: InputMaybe<Scalars['String']['input']>
    number_not_ends_with?: InputMaybe<Scalars['String']['input']>
    number_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    number_not_i?: InputMaybe<Scalars['String']['input']>
    number_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    number_not_starts_with?: InputMaybe<Scalars['String']['input']>
    number_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    number_starts_with?: InputMaybe<Scalars['String']['input']>
    number_starts_with_i?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    purpose?: InputMaybe<Scalars['String']['input']>
    purpose_contains?: InputMaybe<Scalars['String']['input']>
    purpose_contains_i?: InputMaybe<Scalars['String']['input']>
    purpose_ends_with?: InputMaybe<Scalars['String']['input']>
    purpose_ends_with_i?: InputMaybe<Scalars['String']['input']>
    purpose_i?: InputMaybe<Scalars['String']['input']>
    purpose_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    purpose_not?: InputMaybe<Scalars['String']['input']>
    purpose_not_contains?: InputMaybe<Scalars['String']['input']>
    purpose_not_contains_i?: InputMaybe<Scalars['String']['input']>
    purpose_not_ends_with?: InputMaybe<Scalars['String']['input']>
    purpose_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    purpose_not_i?: InputMaybe<Scalars['String']['input']>
    purpose_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    purpose_not_starts_with?: InputMaybe<Scalars['String']['input']>
    purpose_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    purpose_starts_with?: InputMaybe<Scalars['String']['input']>
    purpose_starts_with_i?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type BankTransactionWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type BankTransactionsCreateInput = {
    data?: InputMaybe<BankTransactionCreateInput>
}

export type BankTransactionsUpdateInput = {
    data?: InputMaybe<BankTransactionUpdateInput>
    id: Scalars['ID']['input']
}

/**  All `account` objects from `billing data source`. In close account cases, these objects should be soft deleted  */
export type BillingAccount = {
    __typename?: 'BillingAccount'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BillingAccount List config, or
   *  2. As an alias to the field set on 'labelField' in the BillingAccount List config, or
   *  3. As an alias to a 'name' field on the BillingAccount List (if one exists), or
   *  4. As an alias to the 'id' field on the BillingAccount List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  Integration context  */
    context?: Maybe<BillingIntegrationOrganizationContext>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  Full name of the account holder  */
    fullName?: Maybe<Scalars['String']['output']>
    /**  A well-known universal identifier that allows you to identify the same objects in different systems. It may differ in different countries. Example: for Russia, the dom.gosuslugi.ru account number is used  */
    globalId?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    /**  Id of object in external service which represents current item. Mostly used for internal needs of integration services for matching our objects with theirs  */
    importId?: Maybe<Scalars['String']['output']>
    /**  Shows whether the billing account closed or not. When one resident leaves unit and another one went in we need to close hte old billing account.  */
    isClosed?: Maybe<Scalars['Boolean']['output']>
    /**  Structured metadata obtained from the `billing data source`. Some of this data is required for use in the `receipt template`. Examples of data keys: `property unit number`, `floor`, `entrance`, `is parking`  */
    meta?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Account number  */
    number?: Maybe<Scalars['String']['output']>
    /**  The account owner's type  */
    ownerType?: Maybe<BillingAccountOwnerTypeType>
    /**  Billing property  */
    property?: Maybe<BillingProperty>
    /**  Raw non-structured data obtained from the `billing data source`. Used only for the internal needs of the `integration component`.  */
    raw?: Maybe<Scalars['JSON']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Flat number / door number of an apartment building (property)  */
    unitName?: Maybe<Scalars['String']['output']>
    /**  Type of unit, such as parking lot or flat. Default value: "flat"  */
    unitType?: Maybe<BillingAccountUnitTypeType>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type BillingAccountCreateInput = {
    context?: InputMaybe<BillingIntegrationOrganizationContextRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    fullName?: InputMaybe<Scalars['String']['input']>
    globalId?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    isClosed?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    number?: InputMaybe<Scalars['String']['input']>
    ownerType?: InputMaybe<BillingAccountOwnerTypeType>
    property?: InputMaybe<BillingPropertyRelateToOneInput>
    raw?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<SenderFieldInput>
    unitName?: InputMaybe<Scalars['String']['input']>
    unitType?: InputMaybe<BillingAccountUnitTypeType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type BillingAccountHistoryRecord = {
    __typename?: 'BillingAccountHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BillingAccountHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the BillingAccountHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the BillingAccountHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the BillingAccountHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    context?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    fullName?: Maybe<Scalars['String']['output']>
    globalId?: Maybe<Scalars['String']['output']>
    history_action?: Maybe<BillingAccountHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    importId?: Maybe<Scalars['String']['output']>
    isClosed?: Maybe<Scalars['Boolean']['output']>
    meta?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    number?: Maybe<Scalars['String']['output']>
    ownerType?: Maybe<Scalars['String']['output']>
    property?: Maybe<Scalars['String']['output']>
    raw?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    unitName?: Maybe<Scalars['String']['output']>
    unitType?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type BillingAccountHistoryRecordCreateInput = {
    context?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    fullName?: InputMaybe<Scalars['String']['input']>
    globalId?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<BillingAccountHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    isClosed?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    number?: InputMaybe<Scalars['String']['input']>
    ownerType?: InputMaybe<Scalars['String']['input']>
    property?: InputMaybe<Scalars['String']['input']>
    raw?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    unitName?: InputMaybe<Scalars['String']['input']>
    unitType?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum BillingAccountHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type BillingAccountHistoryRecordUpdateInput = {
    context?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    fullName?: InputMaybe<Scalars['String']['input']>
    globalId?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<BillingAccountHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    isClosed?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    number?: InputMaybe<Scalars['String']['input']>
    ownerType?: InputMaybe<Scalars['String']['input']>
    property?: InputMaybe<Scalars['String']['input']>
    raw?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    unitName?: InputMaybe<Scalars['String']['input']>
    unitType?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type BillingAccountHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<BillingAccountHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<BillingAccountHistoryRecordWhereInput>>>
    context?: InputMaybe<Scalars['String']['input']>
    context_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    context_not?: InputMaybe<Scalars['String']['input']>
    context_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    fullName?: InputMaybe<Scalars['String']['input']>
    fullName_contains?: InputMaybe<Scalars['String']['input']>
    fullName_contains_i?: InputMaybe<Scalars['String']['input']>
    fullName_ends_with?: InputMaybe<Scalars['String']['input']>
    fullName_ends_with_i?: InputMaybe<Scalars['String']['input']>
    fullName_i?: InputMaybe<Scalars['String']['input']>
    fullName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    fullName_not?: InputMaybe<Scalars['String']['input']>
    fullName_not_contains?: InputMaybe<Scalars['String']['input']>
    fullName_not_contains_i?: InputMaybe<Scalars['String']['input']>
    fullName_not_ends_with?: InputMaybe<Scalars['String']['input']>
    fullName_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    fullName_not_i?: InputMaybe<Scalars['String']['input']>
    fullName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    fullName_not_starts_with?: InputMaybe<Scalars['String']['input']>
    fullName_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    fullName_starts_with?: InputMaybe<Scalars['String']['input']>
    fullName_starts_with_i?: InputMaybe<Scalars['String']['input']>
    globalId?: InputMaybe<Scalars['String']['input']>
    globalId_contains?: InputMaybe<Scalars['String']['input']>
    globalId_contains_i?: InputMaybe<Scalars['String']['input']>
    globalId_ends_with?: InputMaybe<Scalars['String']['input']>
    globalId_ends_with_i?: InputMaybe<Scalars['String']['input']>
    globalId_i?: InputMaybe<Scalars['String']['input']>
    globalId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    globalId_not?: InputMaybe<Scalars['String']['input']>
    globalId_not_contains?: InputMaybe<Scalars['String']['input']>
    globalId_not_contains_i?: InputMaybe<Scalars['String']['input']>
    globalId_not_ends_with?: InputMaybe<Scalars['String']['input']>
    globalId_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    globalId_not_i?: InputMaybe<Scalars['String']['input']>
    globalId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    globalId_not_starts_with?: InputMaybe<Scalars['String']['input']>
    globalId_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    globalId_starts_with?: InputMaybe<Scalars['String']['input']>
    globalId_starts_with_i?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<BillingAccountHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<BillingAccountHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<BillingAccountHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<BillingAccountHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    importId?: InputMaybe<Scalars['String']['input']>
    importId_contains?: InputMaybe<Scalars['String']['input']>
    importId_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_i?: InputMaybe<Scalars['String']['input']>
    importId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not?: InputMaybe<Scalars['String']['input']>
    importId_not_contains?: InputMaybe<Scalars['String']['input']>
    importId_not_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_not_i?: InputMaybe<Scalars['String']['input']>
    importId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importId_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_starts_with_i?: InputMaybe<Scalars['String']['input']>
    isClosed?: InputMaybe<Scalars['Boolean']['input']>
    isClosed_not?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    number?: InputMaybe<Scalars['String']['input']>
    number_contains?: InputMaybe<Scalars['String']['input']>
    number_contains_i?: InputMaybe<Scalars['String']['input']>
    number_ends_with?: InputMaybe<Scalars['String']['input']>
    number_ends_with_i?: InputMaybe<Scalars['String']['input']>
    number_i?: InputMaybe<Scalars['String']['input']>
    number_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    number_not?: InputMaybe<Scalars['String']['input']>
    number_not_contains?: InputMaybe<Scalars['String']['input']>
    number_not_contains_i?: InputMaybe<Scalars['String']['input']>
    number_not_ends_with?: InputMaybe<Scalars['String']['input']>
    number_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    number_not_i?: InputMaybe<Scalars['String']['input']>
    number_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    number_not_starts_with?: InputMaybe<Scalars['String']['input']>
    number_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    number_starts_with?: InputMaybe<Scalars['String']['input']>
    number_starts_with_i?: InputMaybe<Scalars['String']['input']>
    ownerType?: InputMaybe<Scalars['String']['input']>
    ownerType_contains?: InputMaybe<Scalars['String']['input']>
    ownerType_contains_i?: InputMaybe<Scalars['String']['input']>
    ownerType_ends_with?: InputMaybe<Scalars['String']['input']>
    ownerType_ends_with_i?: InputMaybe<Scalars['String']['input']>
    ownerType_i?: InputMaybe<Scalars['String']['input']>
    ownerType_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    ownerType_not?: InputMaybe<Scalars['String']['input']>
    ownerType_not_contains?: InputMaybe<Scalars['String']['input']>
    ownerType_not_contains_i?: InputMaybe<Scalars['String']['input']>
    ownerType_not_ends_with?: InputMaybe<Scalars['String']['input']>
    ownerType_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    ownerType_not_i?: InputMaybe<Scalars['String']['input']>
    ownerType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    ownerType_not_starts_with?: InputMaybe<Scalars['String']['input']>
    ownerType_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    ownerType_starts_with?: InputMaybe<Scalars['String']['input']>
    ownerType_starts_with_i?: InputMaybe<Scalars['String']['input']>
    property?: InputMaybe<Scalars['String']['input']>
    property_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    property_not?: InputMaybe<Scalars['String']['input']>
    property_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    raw?: InputMaybe<Scalars['JSON']['input']>
    raw_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    raw_not?: InputMaybe<Scalars['JSON']['input']>
    raw_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    unitName?: InputMaybe<Scalars['String']['input']>
    unitName_contains?: InputMaybe<Scalars['String']['input']>
    unitName_contains_i?: InputMaybe<Scalars['String']['input']>
    unitName_ends_with?: InputMaybe<Scalars['String']['input']>
    unitName_ends_with_i?: InputMaybe<Scalars['String']['input']>
    unitName_i?: InputMaybe<Scalars['String']['input']>
    unitName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitName_not?: InputMaybe<Scalars['String']['input']>
    unitName_not_contains?: InputMaybe<Scalars['String']['input']>
    unitName_not_contains_i?: InputMaybe<Scalars['String']['input']>
    unitName_not_ends_with?: InputMaybe<Scalars['String']['input']>
    unitName_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    unitName_not_i?: InputMaybe<Scalars['String']['input']>
    unitName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitName_not_starts_with?: InputMaybe<Scalars['String']['input']>
    unitName_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    unitName_starts_with?: InputMaybe<Scalars['String']['input']>
    unitName_starts_with_i?: InputMaybe<Scalars['String']['input']>
    unitType?: InputMaybe<Scalars['String']['input']>
    unitType_contains?: InputMaybe<Scalars['String']['input']>
    unitType_contains_i?: InputMaybe<Scalars['String']['input']>
    unitType_ends_with?: InputMaybe<Scalars['String']['input']>
    unitType_ends_with_i?: InputMaybe<Scalars['String']['input']>
    unitType_i?: InputMaybe<Scalars['String']['input']>
    unitType_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitType_not?: InputMaybe<Scalars['String']['input']>
    unitType_not_contains?: InputMaybe<Scalars['String']['input']>
    unitType_not_contains_i?: InputMaybe<Scalars['String']['input']>
    unitType_not_ends_with?: InputMaybe<Scalars['String']['input']>
    unitType_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    unitType_not_i?: InputMaybe<Scalars['String']['input']>
    unitType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitType_not_starts_with?: InputMaybe<Scalars['String']['input']>
    unitType_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    unitType_starts_with?: InputMaybe<Scalars['String']['input']>
    unitType_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type BillingAccountHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type BillingAccountHistoryRecordsCreateInput = {
    data?: InputMaybe<BillingAccountHistoryRecordCreateInput>
}

export type BillingAccountHistoryRecordsUpdateInput = {
    data?: InputMaybe<BillingAccountHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export enum BillingAccountOwnerTypeType {
    Company = 'company',
    Person = 'person',
}

export type BillingAccountRelateToOneInput = {
    connect?: InputMaybe<BillingAccountWhereUniqueInput>
    create?: InputMaybe<BillingAccountCreateInput>
    disconnect?: InputMaybe<BillingAccountWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export enum BillingAccountUnitTypeType {
    Apartment = 'apartment',
    Commercial = 'commercial',
    Flat = 'flat',
    Parking = 'parking',
    Warehouse = 'warehouse',
}

export type BillingAccountUpdateInput = {
    context?: InputMaybe<BillingIntegrationOrganizationContextRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    fullName?: InputMaybe<Scalars['String']['input']>
    globalId?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    isClosed?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    number?: InputMaybe<Scalars['String']['input']>
    ownerType?: InputMaybe<BillingAccountOwnerTypeType>
    property?: InputMaybe<BillingPropertyRelateToOneInput>
    raw?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<SenderFieldInput>
    unitName?: InputMaybe<Scalars['String']['input']>
    unitType?: InputMaybe<BillingAccountUnitTypeType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type BillingAccountWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<BillingAccountWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<BillingAccountWhereInput>>>
    context?: InputMaybe<BillingIntegrationOrganizationContextWhereInput>
    context_is_null?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    fullName?: InputMaybe<Scalars['String']['input']>
    fullName_contains?: InputMaybe<Scalars['String']['input']>
    fullName_contains_i?: InputMaybe<Scalars['String']['input']>
    fullName_ends_with?: InputMaybe<Scalars['String']['input']>
    fullName_ends_with_i?: InputMaybe<Scalars['String']['input']>
    fullName_i?: InputMaybe<Scalars['String']['input']>
    fullName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    fullName_not?: InputMaybe<Scalars['String']['input']>
    fullName_not_contains?: InputMaybe<Scalars['String']['input']>
    fullName_not_contains_i?: InputMaybe<Scalars['String']['input']>
    fullName_not_ends_with?: InputMaybe<Scalars['String']['input']>
    fullName_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    fullName_not_i?: InputMaybe<Scalars['String']['input']>
    fullName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    fullName_not_starts_with?: InputMaybe<Scalars['String']['input']>
    fullName_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    fullName_starts_with?: InputMaybe<Scalars['String']['input']>
    fullName_starts_with_i?: InputMaybe<Scalars['String']['input']>
    globalId?: InputMaybe<Scalars['String']['input']>
    globalId_contains?: InputMaybe<Scalars['String']['input']>
    globalId_contains_i?: InputMaybe<Scalars['String']['input']>
    globalId_ends_with?: InputMaybe<Scalars['String']['input']>
    globalId_ends_with_i?: InputMaybe<Scalars['String']['input']>
    globalId_i?: InputMaybe<Scalars['String']['input']>
    globalId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    globalId_not?: InputMaybe<Scalars['String']['input']>
    globalId_not_contains?: InputMaybe<Scalars['String']['input']>
    globalId_not_contains_i?: InputMaybe<Scalars['String']['input']>
    globalId_not_ends_with?: InputMaybe<Scalars['String']['input']>
    globalId_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    globalId_not_i?: InputMaybe<Scalars['String']['input']>
    globalId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    globalId_not_starts_with?: InputMaybe<Scalars['String']['input']>
    globalId_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    globalId_starts_with?: InputMaybe<Scalars['String']['input']>
    globalId_starts_with_i?: InputMaybe<Scalars['String']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    importId?: InputMaybe<Scalars['String']['input']>
    importId_contains?: InputMaybe<Scalars['String']['input']>
    importId_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_i?: InputMaybe<Scalars['String']['input']>
    importId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not?: InputMaybe<Scalars['String']['input']>
    importId_not_contains?: InputMaybe<Scalars['String']['input']>
    importId_not_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_not_i?: InputMaybe<Scalars['String']['input']>
    importId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importId_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_starts_with_i?: InputMaybe<Scalars['String']['input']>
    isClosed?: InputMaybe<Scalars['Boolean']['input']>
    isClosed_not?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    number?: InputMaybe<Scalars['String']['input']>
    number_contains?: InputMaybe<Scalars['String']['input']>
    number_contains_i?: InputMaybe<Scalars['String']['input']>
    number_ends_with?: InputMaybe<Scalars['String']['input']>
    number_ends_with_i?: InputMaybe<Scalars['String']['input']>
    number_i?: InputMaybe<Scalars['String']['input']>
    number_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    number_not?: InputMaybe<Scalars['String']['input']>
    number_not_contains?: InputMaybe<Scalars['String']['input']>
    number_not_contains_i?: InputMaybe<Scalars['String']['input']>
    number_not_ends_with?: InputMaybe<Scalars['String']['input']>
    number_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    number_not_i?: InputMaybe<Scalars['String']['input']>
    number_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    number_not_starts_with?: InputMaybe<Scalars['String']['input']>
    number_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    number_starts_with?: InputMaybe<Scalars['String']['input']>
    number_starts_with_i?: InputMaybe<Scalars['String']['input']>
    ownerType?: InputMaybe<BillingAccountOwnerTypeType>
    ownerType_in?: InputMaybe<Array<InputMaybe<BillingAccountOwnerTypeType>>>
    ownerType_not?: InputMaybe<BillingAccountOwnerTypeType>
    ownerType_not_in?: InputMaybe<Array<InputMaybe<BillingAccountOwnerTypeType>>>
    property?: InputMaybe<BillingPropertyWhereInput>
    property_is_null?: InputMaybe<Scalars['Boolean']['input']>
    raw?: InputMaybe<Scalars['JSON']['input']>
    raw_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    raw_not?: InputMaybe<Scalars['JSON']['input']>
    raw_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    unitName?: InputMaybe<Scalars['String']['input']>
    unitName_contains?: InputMaybe<Scalars['String']['input']>
    unitName_contains_i?: InputMaybe<Scalars['String']['input']>
    unitName_ends_with?: InputMaybe<Scalars['String']['input']>
    unitName_ends_with_i?: InputMaybe<Scalars['String']['input']>
    unitName_i?: InputMaybe<Scalars['String']['input']>
    unitName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitName_not?: InputMaybe<Scalars['String']['input']>
    unitName_not_contains?: InputMaybe<Scalars['String']['input']>
    unitName_not_contains_i?: InputMaybe<Scalars['String']['input']>
    unitName_not_ends_with?: InputMaybe<Scalars['String']['input']>
    unitName_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    unitName_not_i?: InputMaybe<Scalars['String']['input']>
    unitName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitName_not_starts_with?: InputMaybe<Scalars['String']['input']>
    unitName_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    unitName_starts_with?: InputMaybe<Scalars['String']['input']>
    unitName_starts_with_i?: InputMaybe<Scalars['String']['input']>
    unitType?: InputMaybe<BillingAccountUnitTypeType>
    unitType_in?: InputMaybe<Array<InputMaybe<BillingAccountUnitTypeType>>>
    unitType_not?: InputMaybe<BillingAccountUnitTypeType>
    unitType_not_in?: InputMaybe<Array<InputMaybe<BillingAccountUnitTypeType>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type BillingAccountWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type BillingAccountsCreateInput = {
    data?: InputMaybe<BillingAccountCreateInput>
}

export type BillingAccountsUpdateInput = {
    data?: InputMaybe<BillingAccountUpdateInput>
    id: Scalars['ID']['input']
}

export type BillingCategoriesCreateInput = {
    data?: InputMaybe<BillingCategoryCreateInput>
}

export type BillingCategoriesUpdateInput = {
    data?: InputMaybe<BillingCategoryUpdateInput>
    id: Scalars['ID']['input']
}

/**  Receipt category - used primarily in display purposes  */
export type BillingCategory = {
    __typename?: 'BillingCategory'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BillingCategory List config, or
   *  2. As an alias to the field set on 'labelField' in the BillingCategory List config, or
   *  3. As an alias to a 'name' field on the BillingCategory List (if one exists), or
   *  4. As an alias to the 'id' field on the BillingCategory List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  Localized name of billing category: Hot water, Cold water, Housing Services  */
    name?: Maybe<Scalars['String']['output']>
    nameNonLocalized?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Possible names of services to auto-detect receipt category  */
    serviceNames?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type BillingCategoryCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    serviceNames?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type BillingCategoryHistoryRecord = {
    __typename?: 'BillingCategoryHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BillingCategoryHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the BillingCategoryHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the BillingCategoryHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the BillingCategoryHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<BillingCategoryHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    serviceNames?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type BillingCategoryHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<BillingCategoryHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    serviceNames?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum BillingCategoryHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type BillingCategoryHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<BillingCategoryHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    serviceNames?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type BillingCategoryHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<BillingCategoryHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<BillingCategoryHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<BillingCategoryHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<BillingCategoryHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<BillingCategoryHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<BillingCategoryHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    serviceNames?: InputMaybe<Scalars['JSON']['input']>
    serviceNames_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    serviceNames_not?: InputMaybe<Scalars['JSON']['input']>
    serviceNames_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type BillingCategoryHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type BillingCategoryHistoryRecordsCreateInput = {
    data?: InputMaybe<BillingCategoryHistoryRecordCreateInput>
}

export type BillingCategoryHistoryRecordsUpdateInput = {
    data?: InputMaybe<BillingCategoryHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type BillingCategoryRelateToOneInput = {
    connect?: InputMaybe<BillingCategoryWhereUniqueInput>
    create?: InputMaybe<BillingCategoryCreateInput>
    disconnect?: InputMaybe<BillingCategoryWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type BillingCategoryUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    serviceNames?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type BillingCategoryWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<BillingCategoryWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<BillingCategoryWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    serviceNames?: InputMaybe<Scalars['JSON']['input']>
    serviceNames_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    serviceNames_not?: InputMaybe<Scalars['JSON']['input']>
    serviceNames_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type BillingCategoryWhereUniqueInput = {
    id: Scalars['ID']['input']
}

/**  Identification of the `integration component` which responsible for getting data from the `billing data source` and delivering the data to `this API`. Examples: tap-1c, ...   */
export type BillingIntegration = {
    __typename?: 'BillingIntegration'
    _accessRightsMeta?: Maybe<_QueryMeta>
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BillingIntegration List config, or
   *  2. As an alias to the field set on 'labelField' in the BillingIntegration List config, or
   *  3. As an alias to a 'name' field on the BillingIntegration List (if one exists), or
   *  4. As an alias to the 'id' field on the BillingIntegration List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    accessRights: Array<BillingIntegrationAccessRight>
    /**  Url to the application page that extends the "Accruals and Payments" section and opens in an iframe inside an additional tab named from the "billingPageTitle" or "name" field and controlled by "extendsBillingPage" flag  */
    appUrl?: Maybe<Scalars['String']['output']>
    /**  Background color of banner in the integration modal. Can be hex code or linear gradient.  */
    bannerColor?: Maybe<Scalars['String']['output']>
    /**  Image shown in the integration banner  */
    bannerPromoImage?: Maybe<File>
    /**  Controls text appearance in the integration banner  */
    bannerTextColor?: Maybe<BillingIntegrationBannerTextColorType>
    /**  Used in billing section to override name of app tab  */
    billingPageTitle?: Maybe<Scalars['String']['output']>
    /**  Online request for validation of the account number  */
    checkAccountNumberUrl?: Maybe<Scalars['String']['output']>
    /**  Online request to search organizations for the address  */
    checkAddressUrl?: Maybe<Scalars['String']['output']>
    /**  The message shown to the user after the integration is connected before the first receipts are downloaded. Appeared while lastReport field of context is null  */
    connectedMessage?: Maybe<Scalars['String']['output']>
    /**  Status, which context will have by default after creation if no overwriting option provided  */
    contextDefaultStatus?: Maybe<BillingIntegrationContextDefaultStatusType>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    /**  Currency which this billing uses  */
    currencyCode?: Maybe<BillingIntegrationCurrencyCodeType>
    /**  Format of the data, that is output of this integration. This field specifies the detail and size of columns. If not specified we can only show first level of detail (address, account, toPay)  */
    dataFormat?: Maybe<BillingIntegrationDataFormatField>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Text describing app functionality, connection process and pricing in full detail. Written in markdown without html tags. Will be shown on app's page  */
    detailedDescription?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  If this flag is set to true, then in the "Accruals and Payments" section will appear an additional tab with the "billingPageTitle" or "name" field and iframe from "appUrl" inside  */
    extendsBillingPage?: Maybe<Scalars['Boolean']['output']>
    /**  Billing group which this billing is part of. Used to restrict certain billings from certain acquirings"  */
    group?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    /**  Short instruction for connecting the service written in markdown. Used in cases where integration has no frontend  */
    instruction?: Maybe<Scalars['String']['output']>
    /**  Used in cases when integration need to link external instruction or article.  */
    instructionExtraLink?: Maybe<Scalars['String']['output']>
    /**  Indicates whether the integration or app is hidden inside the CRM. Used if integration is active by default or not ready to appear inside CRM  */
    isHidden?: Maybe<Scalars['Boolean']['output']>
    /**  If checked, then bank account objects created by this billing are automatically approved. E.g government-controlled billing  */
    isTrustedBankAccountSource?: Maybe<Scalars['Boolean']['output']>
    /**  Logo of integration's company or integration itself  */
    logo?: Maybe<File>
    /**  The name of the `integration component` that the developer remembers  */
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  A short sentence describing how long it usually takes for integration to upload receipts. Appears under correlated title in the integration modal  */
    receiptsLoadingTime?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Url to app page, which will be opened during app connection to setup the integration. One of setupUrl and instruction fields must be filled  */
    setupUrl?: Maybe<Scalars['String']['output']>
    /**  Short description / catch phrase providing information about integration functionality. Will be shown on App's card  */
    shortDescription?: Maybe<Scalars['String']['output']>
    /**  If checked, then this integration's contexts' billing receipts will be skipped for BILLING_RECEIPT_AVAILABLE_NO_ACCOUNT_TYPE notifications handling logic  */
    skipNoAccountNotifications?: Maybe<Scalars['Boolean']['output']>
    /**  Short text describing integration target group. Like The type of organization for which this integration is best suited.  */
    targetDescription?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    /**  Overrides default "Upload receipts" message on call-to-action button  */
    uploadMessage?: Maybe<Scalars['String']['output']>
    /**  If specified, billing app will have a call-to-action button, which opens iframe with specified url to start receipts-uploading process. Text of a button can be overridden via "uploadMessage"  */
    uploadUrl?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}


/**  Identification of the `integration component` which responsible for getting data from the `billing data source` and delivering the data to `this API`. Examples: tap-1c, ...   */
export type BillingIntegration_AccessRightsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBillingIntegrationAccessRightsBy>>
    where?: InputMaybe<BillingIntegrationAccessRightWhereInput>
}


/**  Identification of the `integration component` which responsible for getting data from the `billing data source` and delivering the data to `this API`. Examples: tap-1c, ...   */
export type BillingIntegrationAccessRightsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBillingIntegrationAccessRightsBy>>
    where?: InputMaybe<BillingIntegrationAccessRightWhereInput>
}

/**  Link between billing integrations and users. The existence of the object means that there is user has access to integration  */
export type BillingIntegrationAccessRight = {
    __typename?: 'BillingIntegrationAccessRight'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BillingIntegrationAccessRight List config, or
   *  2. As an alias to the field set on 'labelField' in the BillingIntegrationAccessRight List config, or
   *  3. As an alias to a 'name' field on the BillingIntegrationAccessRight List (if one exists), or
   *  4. As an alias to the 'id' field on the BillingIntegrationAccessRight List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  Integration  */
    integration?: Maybe<BillingIntegration>
    newId?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    /**  Link to user. Note, that user must be of "service" type  */
    user?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type BillingIntegrationAccessRightCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    integration?: InputMaybe<BillingIntegrationRelateToOneInput>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type BillingIntegrationAccessRightHistoryRecord = {
    __typename?: 'BillingIntegrationAccessRightHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BillingIntegrationAccessRightHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the BillingIntegrationAccessRightHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the BillingIntegrationAccessRightHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the BillingIntegrationAccessRightHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<BillingIntegrationAccessRightHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    integration?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    user?: Maybe<Scalars['String']['output']>
}

export type BillingIntegrationAccessRightHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<BillingIntegrationAccessRightHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    integration?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
}

export enum BillingIntegrationAccessRightHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type BillingIntegrationAccessRightHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<BillingIntegrationAccessRightHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    integration?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
}

export type BillingIntegrationAccessRightHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<BillingIntegrationAccessRightHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<BillingIntegrationAccessRightHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<BillingIntegrationAccessRightHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<BillingIntegrationAccessRightHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<BillingIntegrationAccessRightHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<BillingIntegrationAccessRightHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    integration?: InputMaybe<Scalars['String']['input']>
    integration_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    integration_not?: InputMaybe<Scalars['String']['input']>
    integration_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user?: InputMaybe<Scalars['String']['input']>
    user_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user_not?: InputMaybe<Scalars['String']['input']>
    user_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type BillingIntegrationAccessRightHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type BillingIntegrationAccessRightHistoryRecordsCreateInput = {
    data?: InputMaybe<BillingIntegrationAccessRightHistoryRecordCreateInput>
}

export type BillingIntegrationAccessRightHistoryRecordsUpdateInput = {
    data?: InputMaybe<BillingIntegrationAccessRightHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type BillingIntegrationAccessRightRelateToManyInput = {
    connect?: InputMaybe<Array<InputMaybe<BillingIntegrationAccessRightWhereUniqueInput>>>
    create?: InputMaybe<Array<InputMaybe<BillingIntegrationAccessRightCreateInput>>>
    disconnect?: InputMaybe<Array<InputMaybe<BillingIntegrationAccessRightWhereUniqueInput>>>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type BillingIntegrationAccessRightUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    integration?: InputMaybe<BillingIntegrationRelateToOneInput>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type BillingIntegrationAccessRightWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<BillingIntegrationAccessRightWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<BillingIntegrationAccessRightWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    integration?: InputMaybe<BillingIntegrationWhereInput>
    integration_is_null?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    user?: InputMaybe<UserWhereInput>
    user_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type BillingIntegrationAccessRightWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type BillingIntegrationAccessRightsCreateInput = {
    data?: InputMaybe<BillingIntegrationAccessRightCreateInput>
}

export type BillingIntegrationAccessRightsUpdateInput = {
    data?: InputMaybe<BillingIntegrationAccessRightUpdateInput>
    id: Scalars['ID']['input']
}

export enum BillingIntegrationBannerTextColorType {
    Black = 'BLACK',
    White = 'WHITE',
}

export enum BillingIntegrationContextDefaultStatusType {
    Error = 'Error',
    Finished = 'Finished',
    InProgress = 'InProgress',
}

export type BillingIntegrationCreateInput = {
    accessRights?: InputMaybe<BillingIntegrationAccessRightRelateToManyInput>
    appUrl?: InputMaybe<Scalars['String']['input']>
    bannerColor?: InputMaybe<Scalars['String']['input']>
    bannerPromoImage?: InputMaybe<Scalars['Upload']['input']>
    bannerTextColor?: InputMaybe<BillingIntegrationBannerTextColorType>
    billingPageTitle?: InputMaybe<Scalars['String']['input']>
    checkAccountNumberUrl?: InputMaybe<Scalars['String']['input']>
    checkAddressUrl?: InputMaybe<Scalars['String']['input']>
    connectedMessage?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus?: InputMaybe<BillingIntegrationContextDefaultStatusType>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    currencyCode?: InputMaybe<BillingIntegrationCurrencyCodeType>
    dataFormat?: InputMaybe<BillingIntegrationDataFormatFieldInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    detailedDescription?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    extendsBillingPage?: InputMaybe<Scalars['Boolean']['input']>
    group?: InputMaybe<Scalars['String']['input']>
    instruction?: InputMaybe<Scalars['String']['input']>
    instructionExtraLink?: InputMaybe<Scalars['String']['input']>
    isHidden?: InputMaybe<Scalars['Boolean']['input']>
    isTrustedBankAccountSource?: InputMaybe<Scalars['Boolean']['input']>
    logo?: InputMaybe<Scalars['Upload']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    receiptsLoadingTime?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    setupUrl?: InputMaybe<Scalars['String']['input']>
    shortDescription?: InputMaybe<Scalars['String']['input']>
    skipNoAccountNotifications?: InputMaybe<Scalars['Boolean']['input']>
    targetDescription?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    uploadMessage?: InputMaybe<Scalars['String']['input']>
    uploadUrl?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum BillingIntegrationCurrencyCodeType {
    Aed = 'AED',
    Afn = 'AFN',
    All = 'ALL',
    Amd = 'AMD',
    Ang = 'ANG',
    Aoa = 'AOA',
    Ars = 'ARS',
    Aud = 'AUD',
    Awg = 'AWG',
    Azn = 'AZN',
    Bam = 'BAM',
    Bbd = 'BBD',
    Bdt = 'BDT',
    Bgn = 'BGN',
    Bhd = 'BHD',
    Bif = 'BIF',
    Bmd = 'BMD',
    Bnd = 'BND',
    Bob = 'BOB',
    Bov = 'BOV',
    Brl = 'BRL',
    Bsd = 'BSD',
    Btn = 'BTN',
    Bwp = 'BWP',
    Byn = 'BYN',
    Bzd = 'BZD',
    Cad = 'CAD',
    Cdf = 'CDF',
    Che = 'CHE',
    Chf = 'CHF',
    Chw = 'CHW',
    Clf = 'CLF',
    Clp = 'CLP',
    Cny = 'CNY',
    Cop = 'COP',
    Cou = 'COU',
    Crc = 'CRC',
    Cuc = 'CUC',
    Cup = 'CUP',
    Cve = 'CVE',
    Czk = 'CZK',
    Djf = 'DJF',
    Dkk = 'DKK',
    Dop = 'DOP',
    Dzd = 'DZD',
    Egp = 'EGP',
    Ern = 'ERN',
    Etb = 'ETB',
    Eur = 'EUR',
    Fjd = 'FJD',
    Fkp = 'FKP',
    Gbp = 'GBP',
    Gel = 'GEL',
    Ghs = 'GHS',
    Gip = 'GIP',
    Gmd = 'GMD',
    Gnf = 'GNF',
    Gtq = 'GTQ',
    Gyd = 'GYD',
    Hkd = 'HKD',
    Hnl = 'HNL',
    Hrk = 'HRK',
    Htg = 'HTG',
    Huf = 'HUF',
    Idr = 'IDR',
    Ils = 'ILS',
    Inr = 'INR',
    Iqd = 'IQD',
    Irr = 'IRR',
    Isk = 'ISK',
    Jmd = 'JMD',
    Jod = 'JOD',
    Jpy = 'JPY',
    Kes = 'KES',
    Kgs = 'KGS',
    Khr = 'KHR',
    Kmf = 'KMF',
    Kpw = 'KPW',
    Krw = 'KRW',
    Kwd = 'KWD',
    Kyd = 'KYD',
    Kzt = 'KZT',
    Lak = 'LAK',
    Lbp = 'LBP',
    Lkr = 'LKR',
    Lrd = 'LRD',
    Lsl = 'LSL',
    Lyd = 'LYD',
    Mad = 'MAD',
    Mdl = 'MDL',
    Mga = 'MGA',
    Mkd = 'MKD',
    Mmk = 'MMK',
    Mnt = 'MNT',
    Mop = 'MOP',
    Mru = 'MRU',
    Mur = 'MUR',
    Mvr = 'MVR',
    Mwk = 'MWK',
    Mxn = 'MXN',
    Mxv = 'MXV',
    Myr = 'MYR',
    Mzn = 'MZN',
    Nad = 'NAD',
    Ngn = 'NGN',
    Nio = 'NIO',
    Nok = 'NOK',
    Npr = 'NPR',
    Nzd = 'NZD',
    Omr = 'OMR',
    Pab = 'PAB',
    Pen = 'PEN',
    Pgk = 'PGK',
    Php = 'PHP',
    Pkr = 'PKR',
    Pln = 'PLN',
    Pyg = 'PYG',
    Qar = 'QAR',
    Ron = 'RON',
    Rsd = 'RSD',
    Rub = 'RUB',
    Rwf = 'RWF',
    Sar = 'SAR',
    Sbd = 'SBD',
    Scr = 'SCR',
    Sdg = 'SDG',
    Sek = 'SEK',
    Sgd = 'SGD',
    Shp = 'SHP',
    Sll = 'SLL',
    Sos = 'SOS',
    Srd = 'SRD',
    Ssp = 'SSP',
    Stn = 'STN',
    Svc = 'SVC',
    Syp = 'SYP',
    Szl = 'SZL',
    Thb = 'THB',
    Tjs = 'TJS',
    Tmt = 'TMT',
    Tnd = 'TND',
    Top = 'TOP',
    Try = 'TRY',
    Ttd = 'TTD',
    Twd = 'TWD',
    Tzs = 'TZS',
    Uah = 'UAH',
    Ugx = 'UGX',
    Usd = 'USD',
    Usn = 'USN',
    Uyi = 'UYI',
    Uyu = 'UYU',
    Uyw = 'UYW',
    Uzs = 'UZS',
    Ves = 'VES',
    Vnd = 'VND',
    Vuv = 'VUV',
    Wst = 'WST',
    Xaf = 'XAF',
    Xag = 'XAG',
    Xau = 'XAU',
    Xba = 'XBA',
    Xbb = 'XBB',
    Xbc = 'XBC',
    Xbd = 'XBD',
    Xcd = 'XCD',
    Xdr = 'XDR',
    Xof = 'XOF',
    Xpd = 'XPD',
    Xpf = 'XPF',
    Xpt = 'XPT',
    Xsu = 'XSU',
    Xts = 'XTS',
    Xua = 'XUA',
    Yer = 'YER',
    Zar = 'ZAR',
    Zmw = 'ZMW',
    Zwl = 'ZWL',
}

export type BillingIntegrationDataFormatField = {
    __typename?: 'BillingIntegrationDataFormatField'
    hasServices: Scalars['Boolean']['output']
    hasServicesDetails: Scalars['Boolean']['output']
    hasToPayDetails: Scalars['Boolean']['output']
}

export type BillingIntegrationDataFormatFieldInput = {
    hasServices: Scalars['Boolean']['input']
    hasServicesDetails: Scalars['Boolean']['input']
    hasToPayDetails: Scalars['Boolean']['input']
}

/**  A keystone list  */
export type BillingIntegrationHistoryRecord = {
    __typename?: 'BillingIntegrationHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BillingIntegrationHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the BillingIntegrationHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the BillingIntegrationHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the BillingIntegrationHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    appUrl?: Maybe<Scalars['String']['output']>
    bannerColor?: Maybe<Scalars['String']['output']>
    bannerPromoImage?: Maybe<Scalars['JSON']['output']>
    bannerTextColor?: Maybe<Scalars['String']['output']>
    billingPageTitle?: Maybe<Scalars['String']['output']>
    checkAccountNumberUrl?: Maybe<Scalars['String']['output']>
    checkAddressUrl?: Maybe<Scalars['String']['output']>
    connectedMessage?: Maybe<Scalars['String']['output']>
    contextDefaultStatus?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    currencyCode?: Maybe<Scalars['String']['output']>
    dataFormat?: Maybe<Scalars['JSON']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    detailedDescription?: Maybe<Scalars['JSON']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    extendsBillingPage?: Maybe<Scalars['Boolean']['output']>
    group?: Maybe<Scalars['String']['output']>
    history_action?: Maybe<BillingIntegrationHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    instruction?: Maybe<Scalars['JSON']['output']>
    instructionExtraLink?: Maybe<Scalars['String']['output']>
    isHidden?: Maybe<Scalars['Boolean']['output']>
    isTrustedBankAccountSource?: Maybe<Scalars['Boolean']['output']>
    logo?: Maybe<Scalars['JSON']['output']>
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    receiptsLoadingTime?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    setupUrl?: Maybe<Scalars['String']['output']>
    shortDescription?: Maybe<Scalars['String']['output']>
    skipNoAccountNotifications?: Maybe<Scalars['Boolean']['output']>
    targetDescription?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    uploadMessage?: Maybe<Scalars['String']['output']>
    uploadUrl?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type BillingIntegrationHistoryRecordCreateInput = {
    appUrl?: InputMaybe<Scalars['String']['input']>
    bannerColor?: InputMaybe<Scalars['String']['input']>
    bannerPromoImage?: InputMaybe<Scalars['JSON']['input']>
    bannerTextColor?: InputMaybe<Scalars['String']['input']>
    billingPageTitle?: InputMaybe<Scalars['String']['input']>
    checkAccountNumberUrl?: InputMaybe<Scalars['String']['input']>
    checkAddressUrl?: InputMaybe<Scalars['String']['input']>
    connectedMessage?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    currencyCode?: InputMaybe<Scalars['String']['input']>
    dataFormat?: InputMaybe<Scalars['JSON']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    detailedDescription?: InputMaybe<Scalars['JSON']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    extendsBillingPage?: InputMaybe<Scalars['Boolean']['input']>
    group?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<BillingIntegrationHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    instruction?: InputMaybe<Scalars['JSON']['input']>
    instructionExtraLink?: InputMaybe<Scalars['String']['input']>
    isHidden?: InputMaybe<Scalars['Boolean']['input']>
    isTrustedBankAccountSource?: InputMaybe<Scalars['Boolean']['input']>
    logo?: InputMaybe<Scalars['JSON']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    receiptsLoadingTime?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    setupUrl?: InputMaybe<Scalars['String']['input']>
    shortDescription?: InputMaybe<Scalars['String']['input']>
    skipNoAccountNotifications?: InputMaybe<Scalars['Boolean']['input']>
    targetDescription?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    uploadMessage?: InputMaybe<Scalars['String']['input']>
    uploadUrl?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum BillingIntegrationHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type BillingIntegrationHistoryRecordUpdateInput = {
    appUrl?: InputMaybe<Scalars['String']['input']>
    bannerColor?: InputMaybe<Scalars['String']['input']>
    bannerPromoImage?: InputMaybe<Scalars['JSON']['input']>
    bannerTextColor?: InputMaybe<Scalars['String']['input']>
    billingPageTitle?: InputMaybe<Scalars['String']['input']>
    checkAccountNumberUrl?: InputMaybe<Scalars['String']['input']>
    checkAddressUrl?: InputMaybe<Scalars['String']['input']>
    connectedMessage?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    currencyCode?: InputMaybe<Scalars['String']['input']>
    dataFormat?: InputMaybe<Scalars['JSON']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    detailedDescription?: InputMaybe<Scalars['JSON']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    extendsBillingPage?: InputMaybe<Scalars['Boolean']['input']>
    group?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<BillingIntegrationHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    instruction?: InputMaybe<Scalars['JSON']['input']>
    instructionExtraLink?: InputMaybe<Scalars['String']['input']>
    isHidden?: InputMaybe<Scalars['Boolean']['input']>
    isTrustedBankAccountSource?: InputMaybe<Scalars['Boolean']['input']>
    logo?: InputMaybe<Scalars['JSON']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    receiptsLoadingTime?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    setupUrl?: InputMaybe<Scalars['String']['input']>
    shortDescription?: InputMaybe<Scalars['String']['input']>
    skipNoAccountNotifications?: InputMaybe<Scalars['Boolean']['input']>
    targetDescription?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    uploadMessage?: InputMaybe<Scalars['String']['input']>
    uploadUrl?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type BillingIntegrationHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<BillingIntegrationHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<BillingIntegrationHistoryRecordWhereInput>>>
    appUrl?: InputMaybe<Scalars['String']['input']>
    appUrl_contains?: InputMaybe<Scalars['String']['input']>
    appUrl_contains_i?: InputMaybe<Scalars['String']['input']>
    appUrl_ends_with?: InputMaybe<Scalars['String']['input']>
    appUrl_ends_with_i?: InputMaybe<Scalars['String']['input']>
    appUrl_i?: InputMaybe<Scalars['String']['input']>
    appUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    appUrl_not?: InputMaybe<Scalars['String']['input']>
    appUrl_not_contains?: InputMaybe<Scalars['String']['input']>
    appUrl_not_contains_i?: InputMaybe<Scalars['String']['input']>
    appUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
    appUrl_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    appUrl_not_i?: InputMaybe<Scalars['String']['input']>
    appUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    appUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
    appUrl_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    appUrl_starts_with?: InputMaybe<Scalars['String']['input']>
    appUrl_starts_with_i?: InputMaybe<Scalars['String']['input']>
    bannerColor?: InputMaybe<Scalars['String']['input']>
    bannerColor_contains?: InputMaybe<Scalars['String']['input']>
    bannerColor_contains_i?: InputMaybe<Scalars['String']['input']>
    bannerColor_ends_with?: InputMaybe<Scalars['String']['input']>
    bannerColor_ends_with_i?: InputMaybe<Scalars['String']['input']>
    bannerColor_i?: InputMaybe<Scalars['String']['input']>
    bannerColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    bannerColor_not?: InputMaybe<Scalars['String']['input']>
    bannerColor_not_contains?: InputMaybe<Scalars['String']['input']>
    bannerColor_not_contains_i?: InputMaybe<Scalars['String']['input']>
    bannerColor_not_ends_with?: InputMaybe<Scalars['String']['input']>
    bannerColor_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    bannerColor_not_i?: InputMaybe<Scalars['String']['input']>
    bannerColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    bannerColor_not_starts_with?: InputMaybe<Scalars['String']['input']>
    bannerColor_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    bannerColor_starts_with?: InputMaybe<Scalars['String']['input']>
    bannerColor_starts_with_i?: InputMaybe<Scalars['String']['input']>
    bannerPromoImage?: InputMaybe<Scalars['JSON']['input']>
    bannerPromoImage_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    bannerPromoImage_not?: InputMaybe<Scalars['JSON']['input']>
    bannerPromoImage_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    bannerTextColor?: InputMaybe<Scalars['String']['input']>
    bannerTextColor_contains?: InputMaybe<Scalars['String']['input']>
    bannerTextColor_contains_i?: InputMaybe<Scalars['String']['input']>
    bannerTextColor_ends_with?: InputMaybe<Scalars['String']['input']>
    bannerTextColor_ends_with_i?: InputMaybe<Scalars['String']['input']>
    bannerTextColor_i?: InputMaybe<Scalars['String']['input']>
    bannerTextColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    bannerTextColor_not?: InputMaybe<Scalars['String']['input']>
    bannerTextColor_not_contains?: InputMaybe<Scalars['String']['input']>
    bannerTextColor_not_contains_i?: InputMaybe<Scalars['String']['input']>
    bannerTextColor_not_ends_with?: InputMaybe<Scalars['String']['input']>
    bannerTextColor_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    bannerTextColor_not_i?: InputMaybe<Scalars['String']['input']>
    bannerTextColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    bannerTextColor_not_starts_with?: InputMaybe<Scalars['String']['input']>
    bannerTextColor_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    bannerTextColor_starts_with?: InputMaybe<Scalars['String']['input']>
    bannerTextColor_starts_with_i?: InputMaybe<Scalars['String']['input']>
    billingPageTitle?: InputMaybe<Scalars['String']['input']>
    billingPageTitle_contains?: InputMaybe<Scalars['String']['input']>
    billingPageTitle_contains_i?: InputMaybe<Scalars['String']['input']>
    billingPageTitle_ends_with?: InputMaybe<Scalars['String']['input']>
    billingPageTitle_ends_with_i?: InputMaybe<Scalars['String']['input']>
    billingPageTitle_i?: InputMaybe<Scalars['String']['input']>
    billingPageTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    billingPageTitle_not?: InputMaybe<Scalars['String']['input']>
    billingPageTitle_not_contains?: InputMaybe<Scalars['String']['input']>
    billingPageTitle_not_contains_i?: InputMaybe<Scalars['String']['input']>
    billingPageTitle_not_ends_with?: InputMaybe<Scalars['String']['input']>
    billingPageTitle_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    billingPageTitle_not_i?: InputMaybe<Scalars['String']['input']>
    billingPageTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    billingPageTitle_not_starts_with?: InputMaybe<Scalars['String']['input']>
    billingPageTitle_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    billingPageTitle_starts_with?: InputMaybe<Scalars['String']['input']>
    billingPageTitle_starts_with_i?: InputMaybe<Scalars['String']['input']>
    checkAccountNumberUrl?: InputMaybe<Scalars['String']['input']>
    checkAccountNumberUrl_contains?: InputMaybe<Scalars['String']['input']>
    checkAccountNumberUrl_contains_i?: InputMaybe<Scalars['String']['input']>
    checkAccountNumberUrl_ends_with?: InputMaybe<Scalars['String']['input']>
    checkAccountNumberUrl_ends_with_i?: InputMaybe<Scalars['String']['input']>
    checkAccountNumberUrl_i?: InputMaybe<Scalars['String']['input']>
    checkAccountNumberUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    checkAccountNumberUrl_not?: InputMaybe<Scalars['String']['input']>
    checkAccountNumberUrl_not_contains?: InputMaybe<Scalars['String']['input']>
    checkAccountNumberUrl_not_contains_i?: InputMaybe<Scalars['String']['input']>
    checkAccountNumberUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
    checkAccountNumberUrl_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    checkAccountNumberUrl_not_i?: InputMaybe<Scalars['String']['input']>
    checkAccountNumberUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    checkAccountNumberUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
    checkAccountNumberUrl_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    checkAccountNumberUrl_starts_with?: InputMaybe<Scalars['String']['input']>
    checkAccountNumberUrl_starts_with_i?: InputMaybe<Scalars['String']['input']>
    checkAddressUrl?: InputMaybe<Scalars['String']['input']>
    checkAddressUrl_contains?: InputMaybe<Scalars['String']['input']>
    checkAddressUrl_contains_i?: InputMaybe<Scalars['String']['input']>
    checkAddressUrl_ends_with?: InputMaybe<Scalars['String']['input']>
    checkAddressUrl_ends_with_i?: InputMaybe<Scalars['String']['input']>
    checkAddressUrl_i?: InputMaybe<Scalars['String']['input']>
    checkAddressUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    checkAddressUrl_not?: InputMaybe<Scalars['String']['input']>
    checkAddressUrl_not_contains?: InputMaybe<Scalars['String']['input']>
    checkAddressUrl_not_contains_i?: InputMaybe<Scalars['String']['input']>
    checkAddressUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
    checkAddressUrl_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    checkAddressUrl_not_i?: InputMaybe<Scalars['String']['input']>
    checkAddressUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    checkAddressUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
    checkAddressUrl_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    checkAddressUrl_starts_with?: InputMaybe<Scalars['String']['input']>
    checkAddressUrl_starts_with_i?: InputMaybe<Scalars['String']['input']>
    connectedMessage?: InputMaybe<Scalars['String']['input']>
    connectedMessage_contains?: InputMaybe<Scalars['String']['input']>
    connectedMessage_contains_i?: InputMaybe<Scalars['String']['input']>
    connectedMessage_ends_with?: InputMaybe<Scalars['String']['input']>
    connectedMessage_ends_with_i?: InputMaybe<Scalars['String']['input']>
    connectedMessage_i?: InputMaybe<Scalars['String']['input']>
    connectedMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    connectedMessage_not?: InputMaybe<Scalars['String']['input']>
    connectedMessage_not_contains?: InputMaybe<Scalars['String']['input']>
    connectedMessage_not_contains_i?: InputMaybe<Scalars['String']['input']>
    connectedMessage_not_ends_with?: InputMaybe<Scalars['String']['input']>
    connectedMessage_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    connectedMessage_not_i?: InputMaybe<Scalars['String']['input']>
    connectedMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    connectedMessage_not_starts_with?: InputMaybe<Scalars['String']['input']>
    connectedMessage_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    connectedMessage_starts_with?: InputMaybe<Scalars['String']['input']>
    connectedMessage_starts_with_i?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus_contains?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus_contains_i?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus_ends_with?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus_ends_with_i?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus_i?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    contextDefaultStatus_not?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus_not_contains?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus_not_contains_i?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus_not_ends_with?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus_not_i?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    contextDefaultStatus_not_starts_with?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus_starts_with?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus_starts_with_i?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    currencyCode?: InputMaybe<Scalars['String']['input']>
    currencyCode_contains?: InputMaybe<Scalars['String']['input']>
    currencyCode_contains_i?: InputMaybe<Scalars['String']['input']>
    currencyCode_ends_with?: InputMaybe<Scalars['String']['input']>
    currencyCode_ends_with_i?: InputMaybe<Scalars['String']['input']>
    currencyCode_i?: InputMaybe<Scalars['String']['input']>
    currencyCode_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    currencyCode_not?: InputMaybe<Scalars['String']['input']>
    currencyCode_not_contains?: InputMaybe<Scalars['String']['input']>
    currencyCode_not_contains_i?: InputMaybe<Scalars['String']['input']>
    currencyCode_not_ends_with?: InputMaybe<Scalars['String']['input']>
    currencyCode_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    currencyCode_not_i?: InputMaybe<Scalars['String']['input']>
    currencyCode_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    currencyCode_not_starts_with?: InputMaybe<Scalars['String']['input']>
    currencyCode_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    currencyCode_starts_with?: InputMaybe<Scalars['String']['input']>
    currencyCode_starts_with_i?: InputMaybe<Scalars['String']['input']>
    dataFormat?: InputMaybe<Scalars['JSON']['input']>
    dataFormat_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    dataFormat_not?: InputMaybe<Scalars['JSON']['input']>
    dataFormat_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    detailedDescription?: InputMaybe<Scalars['JSON']['input']>
    detailedDescription_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    detailedDescription_not?: InputMaybe<Scalars['JSON']['input']>
    detailedDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    extendsBillingPage?: InputMaybe<Scalars['Boolean']['input']>
    extendsBillingPage_not?: InputMaybe<Scalars['Boolean']['input']>
    group?: InputMaybe<Scalars['String']['input']>
    group_contains?: InputMaybe<Scalars['String']['input']>
    group_contains_i?: InputMaybe<Scalars['String']['input']>
    group_ends_with?: InputMaybe<Scalars['String']['input']>
    group_ends_with_i?: InputMaybe<Scalars['String']['input']>
    group_i?: InputMaybe<Scalars['String']['input']>
    group_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    group_not?: InputMaybe<Scalars['String']['input']>
    group_not_contains?: InputMaybe<Scalars['String']['input']>
    group_not_contains_i?: InputMaybe<Scalars['String']['input']>
    group_not_ends_with?: InputMaybe<Scalars['String']['input']>
    group_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    group_not_i?: InputMaybe<Scalars['String']['input']>
    group_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    group_not_starts_with?: InputMaybe<Scalars['String']['input']>
    group_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    group_starts_with?: InputMaybe<Scalars['String']['input']>
    group_starts_with_i?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<BillingIntegrationHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<BillingIntegrationHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<BillingIntegrationHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<BillingIntegrationHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    instruction?: InputMaybe<Scalars['JSON']['input']>
    instructionExtraLink?: InputMaybe<Scalars['String']['input']>
    instructionExtraLink_contains?: InputMaybe<Scalars['String']['input']>
    instructionExtraLink_contains_i?: InputMaybe<Scalars['String']['input']>
    instructionExtraLink_ends_with?: InputMaybe<Scalars['String']['input']>
    instructionExtraLink_ends_with_i?: InputMaybe<Scalars['String']['input']>
    instructionExtraLink_i?: InputMaybe<Scalars['String']['input']>
    instructionExtraLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    instructionExtraLink_not?: InputMaybe<Scalars['String']['input']>
    instructionExtraLink_not_contains?: InputMaybe<Scalars['String']['input']>
    instructionExtraLink_not_contains_i?: InputMaybe<Scalars['String']['input']>
    instructionExtraLink_not_ends_with?: InputMaybe<Scalars['String']['input']>
    instructionExtraLink_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    instructionExtraLink_not_i?: InputMaybe<Scalars['String']['input']>
    instructionExtraLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    instructionExtraLink_not_starts_with?: InputMaybe<Scalars['String']['input']>
    instructionExtraLink_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    instructionExtraLink_starts_with?: InputMaybe<Scalars['String']['input']>
    instructionExtraLink_starts_with_i?: InputMaybe<Scalars['String']['input']>
    instruction_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    instruction_not?: InputMaybe<Scalars['JSON']['input']>
    instruction_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    isHidden?: InputMaybe<Scalars['Boolean']['input']>
    isHidden_not?: InputMaybe<Scalars['Boolean']['input']>
    isTrustedBankAccountSource?: InputMaybe<Scalars['Boolean']['input']>
    isTrustedBankAccountSource_not?: InputMaybe<Scalars['Boolean']['input']>
    logo?: InputMaybe<Scalars['JSON']['input']>
    logo_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    logo_not?: InputMaybe<Scalars['JSON']['input']>
    logo_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    receiptsLoadingTime?: InputMaybe<Scalars['String']['input']>
    receiptsLoadingTime_contains?: InputMaybe<Scalars['String']['input']>
    receiptsLoadingTime_contains_i?: InputMaybe<Scalars['String']['input']>
    receiptsLoadingTime_ends_with?: InputMaybe<Scalars['String']['input']>
    receiptsLoadingTime_ends_with_i?: InputMaybe<Scalars['String']['input']>
    receiptsLoadingTime_i?: InputMaybe<Scalars['String']['input']>
    receiptsLoadingTime_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    receiptsLoadingTime_not?: InputMaybe<Scalars['String']['input']>
    receiptsLoadingTime_not_contains?: InputMaybe<Scalars['String']['input']>
    receiptsLoadingTime_not_contains_i?: InputMaybe<Scalars['String']['input']>
    receiptsLoadingTime_not_ends_with?: InputMaybe<Scalars['String']['input']>
    receiptsLoadingTime_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    receiptsLoadingTime_not_i?: InputMaybe<Scalars['String']['input']>
    receiptsLoadingTime_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    receiptsLoadingTime_not_starts_with?: InputMaybe<Scalars['String']['input']>
    receiptsLoadingTime_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    receiptsLoadingTime_starts_with?: InputMaybe<Scalars['String']['input']>
    receiptsLoadingTime_starts_with_i?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    setupUrl?: InputMaybe<Scalars['String']['input']>
    setupUrl_contains?: InputMaybe<Scalars['String']['input']>
    setupUrl_contains_i?: InputMaybe<Scalars['String']['input']>
    setupUrl_ends_with?: InputMaybe<Scalars['String']['input']>
    setupUrl_ends_with_i?: InputMaybe<Scalars['String']['input']>
    setupUrl_i?: InputMaybe<Scalars['String']['input']>
    setupUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    setupUrl_not?: InputMaybe<Scalars['String']['input']>
    setupUrl_not_contains?: InputMaybe<Scalars['String']['input']>
    setupUrl_not_contains_i?: InputMaybe<Scalars['String']['input']>
    setupUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
    setupUrl_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    setupUrl_not_i?: InputMaybe<Scalars['String']['input']>
    setupUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    setupUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
    setupUrl_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    setupUrl_starts_with?: InputMaybe<Scalars['String']['input']>
    setupUrl_starts_with_i?: InputMaybe<Scalars['String']['input']>
    shortDescription?: InputMaybe<Scalars['String']['input']>
    shortDescription_contains?: InputMaybe<Scalars['String']['input']>
    shortDescription_contains_i?: InputMaybe<Scalars['String']['input']>
    shortDescription_ends_with?: InputMaybe<Scalars['String']['input']>
    shortDescription_ends_with_i?: InputMaybe<Scalars['String']['input']>
    shortDescription_i?: InputMaybe<Scalars['String']['input']>
    shortDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    shortDescription_not?: InputMaybe<Scalars['String']['input']>
    shortDescription_not_contains?: InputMaybe<Scalars['String']['input']>
    shortDescription_not_contains_i?: InputMaybe<Scalars['String']['input']>
    shortDescription_not_ends_with?: InputMaybe<Scalars['String']['input']>
    shortDescription_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    shortDescription_not_i?: InputMaybe<Scalars['String']['input']>
    shortDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    shortDescription_not_starts_with?: InputMaybe<Scalars['String']['input']>
    shortDescription_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    shortDescription_starts_with?: InputMaybe<Scalars['String']['input']>
    shortDescription_starts_with_i?: InputMaybe<Scalars['String']['input']>
    skipNoAccountNotifications?: InputMaybe<Scalars['Boolean']['input']>
    skipNoAccountNotifications_not?: InputMaybe<Scalars['Boolean']['input']>
    targetDescription?: InputMaybe<Scalars['String']['input']>
    targetDescription_contains?: InputMaybe<Scalars['String']['input']>
    targetDescription_contains_i?: InputMaybe<Scalars['String']['input']>
    targetDescription_ends_with?: InputMaybe<Scalars['String']['input']>
    targetDescription_ends_with_i?: InputMaybe<Scalars['String']['input']>
    targetDescription_i?: InputMaybe<Scalars['String']['input']>
    targetDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    targetDescription_not?: InputMaybe<Scalars['String']['input']>
    targetDescription_not_contains?: InputMaybe<Scalars['String']['input']>
    targetDescription_not_contains_i?: InputMaybe<Scalars['String']['input']>
    targetDescription_not_ends_with?: InputMaybe<Scalars['String']['input']>
    targetDescription_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    targetDescription_not_i?: InputMaybe<Scalars['String']['input']>
    targetDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    targetDescription_not_starts_with?: InputMaybe<Scalars['String']['input']>
    targetDescription_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    targetDescription_starts_with?: InputMaybe<Scalars['String']['input']>
    targetDescription_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    uploadMessage?: InputMaybe<Scalars['String']['input']>
    uploadMessage_contains?: InputMaybe<Scalars['String']['input']>
    uploadMessage_contains_i?: InputMaybe<Scalars['String']['input']>
    uploadMessage_ends_with?: InputMaybe<Scalars['String']['input']>
    uploadMessage_ends_with_i?: InputMaybe<Scalars['String']['input']>
    uploadMessage_i?: InputMaybe<Scalars['String']['input']>
    uploadMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    uploadMessage_not?: InputMaybe<Scalars['String']['input']>
    uploadMessage_not_contains?: InputMaybe<Scalars['String']['input']>
    uploadMessage_not_contains_i?: InputMaybe<Scalars['String']['input']>
    uploadMessage_not_ends_with?: InputMaybe<Scalars['String']['input']>
    uploadMessage_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    uploadMessage_not_i?: InputMaybe<Scalars['String']['input']>
    uploadMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    uploadMessage_not_starts_with?: InputMaybe<Scalars['String']['input']>
    uploadMessage_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    uploadMessage_starts_with?: InputMaybe<Scalars['String']['input']>
    uploadMessage_starts_with_i?: InputMaybe<Scalars['String']['input']>
    uploadUrl?: InputMaybe<Scalars['String']['input']>
    uploadUrl_contains?: InputMaybe<Scalars['String']['input']>
    uploadUrl_contains_i?: InputMaybe<Scalars['String']['input']>
    uploadUrl_ends_with?: InputMaybe<Scalars['String']['input']>
    uploadUrl_ends_with_i?: InputMaybe<Scalars['String']['input']>
    uploadUrl_i?: InputMaybe<Scalars['String']['input']>
    uploadUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    uploadUrl_not?: InputMaybe<Scalars['String']['input']>
    uploadUrl_not_contains?: InputMaybe<Scalars['String']['input']>
    uploadUrl_not_contains_i?: InputMaybe<Scalars['String']['input']>
    uploadUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
    uploadUrl_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    uploadUrl_not_i?: InputMaybe<Scalars['String']['input']>
    uploadUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    uploadUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
    uploadUrl_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    uploadUrl_starts_with?: InputMaybe<Scalars['String']['input']>
    uploadUrl_starts_with_i?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type BillingIntegrationHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type BillingIntegrationHistoryRecordsCreateInput = {
    data?: InputMaybe<BillingIntegrationHistoryRecordCreateInput>
}

export type BillingIntegrationHistoryRecordsUpdateInput = {
    data?: InputMaybe<BillingIntegrationHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

/**  Integration state and settings for all organizations. The existence of this object means that there is a configured integration between the `billing data source` and `this API`  */
export type BillingIntegrationOrganizationContext = {
    __typename?: 'BillingIntegrationOrganizationContext'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BillingIntegrationOrganizationContext List config, or
   *  2. As an alias to the field set on 'labelField' in the BillingIntegrationOrganizationContext List config, or
   *  3. As an alias to a 'name' field on the BillingIntegrationOrganizationContext List (if one exists), or
   *  4. As an alias to the 'id' field on the BillingIntegrationOrganizationContext List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    /**  Link to a problem occurred during last integration process. Filled automatically, can only be resolved to null manually.  */
    currentProblem?: Maybe<BillingIntegrationProblem>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  ID of the integration that is configured to receive data for the organization  */
    integration?: Maybe<BillingIntegration>
    /**  Information about last report, such as time of report, period of report, amount of loaded data and etc  */
    lastReport?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
    organization?: Maybe<Organization>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Settings that are required to get data from the `billing data source`. It can also contain fine-tuning optional integration settings. The data structure depends on the integration and defined there  */
    settings?: Maybe<Scalars['JSON']['output']>
    /**  The current state of the integration process. Some integration need to store past state or data related to cache files/folders for past state. The data structure depends on the integration and defined there  */
    state?: Maybe<Scalars['JSON']['output']>
    /**  Status of BillingIntegration connection, Can be one of the following: ["InProgress", "Error", "Finished"]. If not specified explicitly on creation, uses default value from related BillingIntegration model  */
    status?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type BillingIntegrationOrganizationContextCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    currentProblem?: InputMaybe<BillingIntegrationProblemRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    integration?: InputMaybe<BillingIntegrationRelateToOneInput>
    lastReport?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    settings?: InputMaybe<Scalars['JSON']['input']>
    state?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type BillingIntegrationOrganizationContextHistoryRecord = {
    __typename?: 'BillingIntegrationOrganizationContextHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BillingIntegrationOrganizationContextHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the BillingIntegrationOrganizationContextHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the BillingIntegrationOrganizationContextHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the BillingIntegrationOrganizationContextHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    currentProblem?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<BillingIntegrationOrganizationContextHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    integration?: Maybe<Scalars['String']['output']>
    lastReport?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    settings?: Maybe<Scalars['JSON']['output']>
    state?: Maybe<Scalars['JSON']['output']>
    status?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type BillingIntegrationOrganizationContextHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    currentProblem?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<BillingIntegrationOrganizationContextHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    integration?: InputMaybe<Scalars['String']['input']>
    lastReport?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    settings?: InputMaybe<Scalars['JSON']['input']>
    state?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum BillingIntegrationOrganizationContextHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type BillingIntegrationOrganizationContextHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    currentProblem?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<BillingIntegrationOrganizationContextHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    integration?: InputMaybe<Scalars['String']['input']>
    lastReport?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    settings?: InputMaybe<Scalars['JSON']['input']>
    state?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type BillingIntegrationOrganizationContextHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<BillingIntegrationOrganizationContextHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<BillingIntegrationOrganizationContextHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    currentProblem?: InputMaybe<Scalars['String']['input']>
    currentProblem_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    currentProblem_not?: InputMaybe<Scalars['String']['input']>
    currentProblem_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<BillingIntegrationOrganizationContextHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<BillingIntegrationOrganizationContextHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<BillingIntegrationOrganizationContextHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<BillingIntegrationOrganizationContextHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    integration?: InputMaybe<Scalars['String']['input']>
    integration_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    integration_not?: InputMaybe<Scalars['String']['input']>
    integration_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    lastReport?: InputMaybe<Scalars['JSON']['input']>
    lastReport_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    lastReport_not?: InputMaybe<Scalars['JSON']['input']>
    lastReport_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    settings?: InputMaybe<Scalars['JSON']['input']>
    settings_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    settings_not?: InputMaybe<Scalars['JSON']['input']>
    settings_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    state?: InputMaybe<Scalars['JSON']['input']>
    state_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    state_not?: InputMaybe<Scalars['JSON']['input']>
    state_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    status?: InputMaybe<Scalars['String']['input']>
    status_contains?: InputMaybe<Scalars['String']['input']>
    status_contains_i?: InputMaybe<Scalars['String']['input']>
    status_ends_with?: InputMaybe<Scalars['String']['input']>
    status_ends_with_i?: InputMaybe<Scalars['String']['input']>
    status_i?: InputMaybe<Scalars['String']['input']>
    status_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status_not?: InputMaybe<Scalars['String']['input']>
    status_not_contains?: InputMaybe<Scalars['String']['input']>
    status_not_contains_i?: InputMaybe<Scalars['String']['input']>
    status_not_ends_with?: InputMaybe<Scalars['String']['input']>
    status_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    status_not_i?: InputMaybe<Scalars['String']['input']>
    status_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status_not_starts_with?: InputMaybe<Scalars['String']['input']>
    status_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    status_starts_with?: InputMaybe<Scalars['String']['input']>
    status_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type BillingIntegrationOrganizationContextHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type BillingIntegrationOrganizationContextHistoryRecordsCreateInput = {
    data?: InputMaybe<BillingIntegrationOrganizationContextHistoryRecordCreateInput>
}

export type BillingIntegrationOrganizationContextHistoryRecordsUpdateInput = {
    data?: InputMaybe<BillingIntegrationOrganizationContextHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type BillingIntegrationOrganizationContextRelateToOneInput = {
    connect?: InputMaybe<BillingIntegrationOrganizationContextWhereUniqueInput>
    create?: InputMaybe<BillingIntegrationOrganizationContextCreateInput>
    disconnect?: InputMaybe<BillingIntegrationOrganizationContextWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type BillingIntegrationOrganizationContextUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    currentProblem?: InputMaybe<BillingIntegrationProblemRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    integration?: InputMaybe<BillingIntegrationRelateToOneInput>
    lastReport?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    settings?: InputMaybe<Scalars['JSON']['input']>
    state?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type BillingIntegrationOrganizationContextWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<BillingIntegrationOrganizationContextWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<BillingIntegrationOrganizationContextWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    currentProblem?: InputMaybe<BillingIntegrationProblemWhereInput>
    currentProblem_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    integration?: InputMaybe<BillingIntegrationWhereInput>
    integration_is_null?: InputMaybe<Scalars['Boolean']['input']>
    lastReport?: InputMaybe<Scalars['JSON']['input']>
    lastReport_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    lastReport_not?: InputMaybe<Scalars['JSON']['input']>
    lastReport_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    settings?: InputMaybe<Scalars['JSON']['input']>
    settings_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    settings_not?: InputMaybe<Scalars['JSON']['input']>
    settings_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    state?: InputMaybe<Scalars['JSON']['input']>
    state_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    state_not?: InputMaybe<Scalars['JSON']['input']>
    state_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    status?: InputMaybe<Scalars['String']['input']>
    status_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status_not?: InputMaybe<Scalars['String']['input']>
    status_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type BillingIntegrationOrganizationContextWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type BillingIntegrationOrganizationContextsCreateInput = {
    data?: InputMaybe<BillingIntegrationOrganizationContextCreateInput>
}

export type BillingIntegrationOrganizationContextsUpdateInput = {
    data?: InputMaybe<BillingIntegrationOrganizationContextUpdateInput>
    id: Scalars['ID']['input']
}

/**  Report about an error that occurred during integration process. There's 2 target groups for it: Organization employees will see title and message of last problem, so they can be notified, that something went wrong. Support can also read problem messages as well as metadata, which helps them to resolve an issue.  */
export type BillingIntegrationProblem = {
    __typename?: 'BillingIntegrationProblem'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BillingIntegrationProblem List config, or
   *  2. As an alias to the field set on 'labelField' in the BillingIntegrationProblem List config, or
   *  3. As an alias to a 'name' field on the BillingIntegrationProblem List (if one exists), or
   *  4. As an alias to the 'id' field on the BillingIntegrationProblem List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  Integration context  */
    context?: Maybe<BillingIntegrationOrganizationContext>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  Client understandable message, describing what should client do to fix a problem  */
    message?: Maybe<Scalars['String']['output']>
    /**  The message metadata, which can help support to resolve an issue  */
    meta?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Problem summary, like "Wrong requisites", "No access provided" and so on  */
    title?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type BillingIntegrationProblemCreateInput = {
    context?: InputMaybe<BillingIntegrationOrganizationContextRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    message?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    title?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type BillingIntegrationProblemRelateToOneInput = {
    connect?: InputMaybe<BillingIntegrationProblemWhereUniqueInput>
    create?: InputMaybe<BillingIntegrationProblemCreateInput>
    disconnect?: InputMaybe<BillingIntegrationProblemWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type BillingIntegrationProblemUpdateInput = {
    context?: InputMaybe<BillingIntegrationOrganizationContextRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    message?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    title?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type BillingIntegrationProblemWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<BillingIntegrationProblemWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<BillingIntegrationProblemWhereInput>>>
    context?: InputMaybe<BillingIntegrationOrganizationContextWhereInput>
    context_is_null?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    message?: InputMaybe<Scalars['String']['input']>
    message_contains?: InputMaybe<Scalars['String']['input']>
    message_contains_i?: InputMaybe<Scalars['String']['input']>
    message_ends_with?: InputMaybe<Scalars['String']['input']>
    message_ends_with_i?: InputMaybe<Scalars['String']['input']>
    message_i?: InputMaybe<Scalars['String']['input']>
    message_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    message_not?: InputMaybe<Scalars['String']['input']>
    message_not_contains?: InputMaybe<Scalars['String']['input']>
    message_not_contains_i?: InputMaybe<Scalars['String']['input']>
    message_not_ends_with?: InputMaybe<Scalars['String']['input']>
    message_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    message_not_i?: InputMaybe<Scalars['String']['input']>
    message_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    message_not_starts_with?: InputMaybe<Scalars['String']['input']>
    message_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    message_starts_with?: InputMaybe<Scalars['String']['input']>
    message_starts_with_i?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    title?: InputMaybe<Scalars['String']['input']>
    title_contains?: InputMaybe<Scalars['String']['input']>
    title_contains_i?: InputMaybe<Scalars['String']['input']>
    title_ends_with?: InputMaybe<Scalars['String']['input']>
    title_ends_with_i?: InputMaybe<Scalars['String']['input']>
    title_i?: InputMaybe<Scalars['String']['input']>
    title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    title_not?: InputMaybe<Scalars['String']['input']>
    title_not_contains?: InputMaybe<Scalars['String']['input']>
    title_not_contains_i?: InputMaybe<Scalars['String']['input']>
    title_not_ends_with?: InputMaybe<Scalars['String']['input']>
    title_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    title_not_i?: InputMaybe<Scalars['String']['input']>
    title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    title_not_starts_with?: InputMaybe<Scalars['String']['input']>
    title_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    title_starts_with?: InputMaybe<Scalars['String']['input']>
    title_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type BillingIntegrationProblemWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type BillingIntegrationProblemsCreateInput = {
    data?: InputMaybe<BillingIntegrationProblemCreateInput>
}

export type BillingIntegrationProblemsUpdateInput = {
    data?: InputMaybe<BillingIntegrationProblemUpdateInput>
    id: Scalars['ID']['input']
}

export type BillingIntegrationRelateToOneInput = {
    connect?: InputMaybe<BillingIntegrationWhereUniqueInput>
    create?: InputMaybe<BillingIntegrationCreateInput>
    disconnect?: InputMaybe<BillingIntegrationWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type BillingIntegrationUpdateInput = {
    accessRights?: InputMaybe<BillingIntegrationAccessRightRelateToManyInput>
    appUrl?: InputMaybe<Scalars['String']['input']>
    bannerColor?: InputMaybe<Scalars['String']['input']>
    bannerPromoImage?: InputMaybe<Scalars['Upload']['input']>
    bannerTextColor?: InputMaybe<BillingIntegrationBannerTextColorType>
    billingPageTitle?: InputMaybe<Scalars['String']['input']>
    checkAccountNumberUrl?: InputMaybe<Scalars['String']['input']>
    checkAddressUrl?: InputMaybe<Scalars['String']['input']>
    connectedMessage?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus?: InputMaybe<BillingIntegrationContextDefaultStatusType>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    currencyCode?: InputMaybe<BillingIntegrationCurrencyCodeType>
    dataFormat?: InputMaybe<BillingIntegrationDataFormatFieldInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    detailedDescription?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    extendsBillingPage?: InputMaybe<Scalars['Boolean']['input']>
    group?: InputMaybe<Scalars['String']['input']>
    instruction?: InputMaybe<Scalars['String']['input']>
    instructionExtraLink?: InputMaybe<Scalars['String']['input']>
    isHidden?: InputMaybe<Scalars['Boolean']['input']>
    isTrustedBankAccountSource?: InputMaybe<Scalars['Boolean']['input']>
    logo?: InputMaybe<Scalars['Upload']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    receiptsLoadingTime?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    setupUrl?: InputMaybe<Scalars['String']['input']>
    shortDescription?: InputMaybe<Scalars['String']['input']>
    skipNoAccountNotifications?: InputMaybe<Scalars['Boolean']['input']>
    targetDescription?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    uploadMessage?: InputMaybe<Scalars['String']['input']>
    uploadUrl?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type BillingIntegrationWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<BillingIntegrationWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<BillingIntegrationWhereInput>>>
    /**  condition must be true for all nodes  */
    accessRights_every?: InputMaybe<BillingIntegrationAccessRightWhereInput>
    /**  condition must be false for all nodes  */
    accessRights_none?: InputMaybe<BillingIntegrationAccessRightWhereInput>
    /**  condition must be true for at least 1 node  */
    accessRights_some?: InputMaybe<BillingIntegrationAccessRightWhereInput>
    appUrl?: InputMaybe<Scalars['String']['input']>
    appUrl_contains?: InputMaybe<Scalars['String']['input']>
    appUrl_contains_i?: InputMaybe<Scalars['String']['input']>
    appUrl_ends_with?: InputMaybe<Scalars['String']['input']>
    appUrl_ends_with_i?: InputMaybe<Scalars['String']['input']>
    appUrl_i?: InputMaybe<Scalars['String']['input']>
    appUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    appUrl_not?: InputMaybe<Scalars['String']['input']>
    appUrl_not_contains?: InputMaybe<Scalars['String']['input']>
    appUrl_not_contains_i?: InputMaybe<Scalars['String']['input']>
    appUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
    appUrl_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    appUrl_not_i?: InputMaybe<Scalars['String']['input']>
    appUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    appUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
    appUrl_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    appUrl_starts_with?: InputMaybe<Scalars['String']['input']>
    appUrl_starts_with_i?: InputMaybe<Scalars['String']['input']>
    bannerColor?: InputMaybe<Scalars['String']['input']>
    bannerColor_contains?: InputMaybe<Scalars['String']['input']>
    bannerColor_contains_i?: InputMaybe<Scalars['String']['input']>
    bannerColor_ends_with?: InputMaybe<Scalars['String']['input']>
    bannerColor_ends_with_i?: InputMaybe<Scalars['String']['input']>
    bannerColor_i?: InputMaybe<Scalars['String']['input']>
    bannerColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    bannerColor_not?: InputMaybe<Scalars['String']['input']>
    bannerColor_not_contains?: InputMaybe<Scalars['String']['input']>
    bannerColor_not_contains_i?: InputMaybe<Scalars['String']['input']>
    bannerColor_not_ends_with?: InputMaybe<Scalars['String']['input']>
    bannerColor_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    bannerColor_not_i?: InputMaybe<Scalars['String']['input']>
    bannerColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    bannerColor_not_starts_with?: InputMaybe<Scalars['String']['input']>
    bannerColor_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    bannerColor_starts_with?: InputMaybe<Scalars['String']['input']>
    bannerColor_starts_with_i?: InputMaybe<Scalars['String']['input']>
    bannerPromoImage?: InputMaybe<Scalars['String']['input']>
    bannerPromoImage_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    bannerPromoImage_not?: InputMaybe<Scalars['String']['input']>
    bannerPromoImage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    bannerTextColor?: InputMaybe<BillingIntegrationBannerTextColorType>
    bannerTextColor_in?: InputMaybe<Array<InputMaybe<BillingIntegrationBannerTextColorType>>>
    bannerTextColor_not?: InputMaybe<BillingIntegrationBannerTextColorType>
    bannerTextColor_not_in?: InputMaybe<Array<InputMaybe<BillingIntegrationBannerTextColorType>>>
    billingPageTitle?: InputMaybe<Scalars['String']['input']>
    billingPageTitle_contains?: InputMaybe<Scalars['String']['input']>
    billingPageTitle_contains_i?: InputMaybe<Scalars['String']['input']>
    billingPageTitle_ends_with?: InputMaybe<Scalars['String']['input']>
    billingPageTitle_ends_with_i?: InputMaybe<Scalars['String']['input']>
    billingPageTitle_i?: InputMaybe<Scalars['String']['input']>
    billingPageTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    billingPageTitle_not?: InputMaybe<Scalars['String']['input']>
    billingPageTitle_not_contains?: InputMaybe<Scalars['String']['input']>
    billingPageTitle_not_contains_i?: InputMaybe<Scalars['String']['input']>
    billingPageTitle_not_ends_with?: InputMaybe<Scalars['String']['input']>
    billingPageTitle_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    billingPageTitle_not_i?: InputMaybe<Scalars['String']['input']>
    billingPageTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    billingPageTitle_not_starts_with?: InputMaybe<Scalars['String']['input']>
    billingPageTitle_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    billingPageTitle_starts_with?: InputMaybe<Scalars['String']['input']>
    billingPageTitle_starts_with_i?: InputMaybe<Scalars['String']['input']>
    checkAccountNumberUrl?: InputMaybe<Scalars['String']['input']>
    checkAccountNumberUrl_contains?: InputMaybe<Scalars['String']['input']>
    checkAccountNumberUrl_contains_i?: InputMaybe<Scalars['String']['input']>
    checkAccountNumberUrl_ends_with?: InputMaybe<Scalars['String']['input']>
    checkAccountNumberUrl_ends_with_i?: InputMaybe<Scalars['String']['input']>
    checkAccountNumberUrl_i?: InputMaybe<Scalars['String']['input']>
    checkAccountNumberUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    checkAccountNumberUrl_not?: InputMaybe<Scalars['String']['input']>
    checkAccountNumberUrl_not_contains?: InputMaybe<Scalars['String']['input']>
    checkAccountNumberUrl_not_contains_i?: InputMaybe<Scalars['String']['input']>
    checkAccountNumberUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
    checkAccountNumberUrl_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    checkAccountNumberUrl_not_i?: InputMaybe<Scalars['String']['input']>
    checkAccountNumberUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    checkAccountNumberUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
    checkAccountNumberUrl_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    checkAccountNumberUrl_starts_with?: InputMaybe<Scalars['String']['input']>
    checkAccountNumberUrl_starts_with_i?: InputMaybe<Scalars['String']['input']>
    checkAddressUrl?: InputMaybe<Scalars['String']['input']>
    checkAddressUrl_contains?: InputMaybe<Scalars['String']['input']>
    checkAddressUrl_contains_i?: InputMaybe<Scalars['String']['input']>
    checkAddressUrl_ends_with?: InputMaybe<Scalars['String']['input']>
    checkAddressUrl_ends_with_i?: InputMaybe<Scalars['String']['input']>
    checkAddressUrl_i?: InputMaybe<Scalars['String']['input']>
    checkAddressUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    checkAddressUrl_not?: InputMaybe<Scalars['String']['input']>
    checkAddressUrl_not_contains?: InputMaybe<Scalars['String']['input']>
    checkAddressUrl_not_contains_i?: InputMaybe<Scalars['String']['input']>
    checkAddressUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
    checkAddressUrl_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    checkAddressUrl_not_i?: InputMaybe<Scalars['String']['input']>
    checkAddressUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    checkAddressUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
    checkAddressUrl_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    checkAddressUrl_starts_with?: InputMaybe<Scalars['String']['input']>
    checkAddressUrl_starts_with_i?: InputMaybe<Scalars['String']['input']>
    connectedMessage?: InputMaybe<Scalars['String']['input']>
    connectedMessage_contains?: InputMaybe<Scalars['String']['input']>
    connectedMessage_contains_i?: InputMaybe<Scalars['String']['input']>
    connectedMessage_ends_with?: InputMaybe<Scalars['String']['input']>
    connectedMessage_ends_with_i?: InputMaybe<Scalars['String']['input']>
    connectedMessage_i?: InputMaybe<Scalars['String']['input']>
    connectedMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    connectedMessage_not?: InputMaybe<Scalars['String']['input']>
    connectedMessage_not_contains?: InputMaybe<Scalars['String']['input']>
    connectedMessage_not_contains_i?: InputMaybe<Scalars['String']['input']>
    connectedMessage_not_ends_with?: InputMaybe<Scalars['String']['input']>
    connectedMessage_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    connectedMessage_not_i?: InputMaybe<Scalars['String']['input']>
    connectedMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    connectedMessage_not_starts_with?: InputMaybe<Scalars['String']['input']>
    connectedMessage_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    connectedMessage_starts_with?: InputMaybe<Scalars['String']['input']>
    connectedMessage_starts_with_i?: InputMaybe<Scalars['String']['input']>
    contextDefaultStatus?: InputMaybe<BillingIntegrationContextDefaultStatusType>
    contextDefaultStatus_in?: InputMaybe<Array<InputMaybe<BillingIntegrationContextDefaultStatusType>>>
    contextDefaultStatus_not?: InputMaybe<BillingIntegrationContextDefaultStatusType>
    contextDefaultStatus_not_in?: InputMaybe<Array<InputMaybe<BillingIntegrationContextDefaultStatusType>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    currencyCode?: InputMaybe<BillingIntegrationCurrencyCodeType>
    currencyCode_in?: InputMaybe<Array<InputMaybe<BillingIntegrationCurrencyCodeType>>>
    currencyCode_not?: InputMaybe<BillingIntegrationCurrencyCodeType>
    currencyCode_not_in?: InputMaybe<Array<InputMaybe<BillingIntegrationCurrencyCodeType>>>
    dataFormat?: InputMaybe<BillingIntegrationDataFormatFieldInput>
    dataFormat_in?: InputMaybe<Array<InputMaybe<BillingIntegrationDataFormatFieldInput>>>
    dataFormat_not?: InputMaybe<BillingIntegrationDataFormatFieldInput>
    dataFormat_not_in?: InputMaybe<Array<InputMaybe<BillingIntegrationDataFormatFieldInput>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    detailedDescription?: InputMaybe<Scalars['String']['input']>
    detailedDescription_contains?: InputMaybe<Scalars['String']['input']>
    detailedDescription_contains_i?: InputMaybe<Scalars['String']['input']>
    detailedDescription_ends_with?: InputMaybe<Scalars['String']['input']>
    detailedDescription_ends_with_i?: InputMaybe<Scalars['String']['input']>
    detailedDescription_i?: InputMaybe<Scalars['String']['input']>
    detailedDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    detailedDescription_not?: InputMaybe<Scalars['String']['input']>
    detailedDescription_not_contains?: InputMaybe<Scalars['String']['input']>
    detailedDescription_not_contains_i?: InputMaybe<Scalars['String']['input']>
    detailedDescription_not_ends_with?: InputMaybe<Scalars['String']['input']>
    detailedDescription_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    detailedDescription_not_i?: InputMaybe<Scalars['String']['input']>
    detailedDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    detailedDescription_not_starts_with?: InputMaybe<Scalars['String']['input']>
    detailedDescription_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    detailedDescription_starts_with?: InputMaybe<Scalars['String']['input']>
    detailedDescription_starts_with_i?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    extendsBillingPage?: InputMaybe<Scalars['Boolean']['input']>
    extendsBillingPage_not?: InputMaybe<Scalars['Boolean']['input']>
    group?: InputMaybe<Scalars['String']['input']>
    group_contains?: InputMaybe<Scalars['String']['input']>
    group_contains_i?: InputMaybe<Scalars['String']['input']>
    group_ends_with?: InputMaybe<Scalars['String']['input']>
    group_ends_with_i?: InputMaybe<Scalars['String']['input']>
    group_i?: InputMaybe<Scalars['String']['input']>
    group_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    group_not?: InputMaybe<Scalars['String']['input']>
    group_not_contains?: InputMaybe<Scalars['String']['input']>
    group_not_contains_i?: InputMaybe<Scalars['String']['input']>
    group_not_ends_with?: InputMaybe<Scalars['String']['input']>
    group_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    group_not_i?: InputMaybe<Scalars['String']['input']>
    group_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    group_not_starts_with?: InputMaybe<Scalars['String']['input']>
    group_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    group_starts_with?: InputMaybe<Scalars['String']['input']>
    group_starts_with_i?: InputMaybe<Scalars['String']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    instruction?: InputMaybe<Scalars['String']['input']>
    instructionExtraLink?: InputMaybe<Scalars['String']['input']>
    instructionExtraLink_contains?: InputMaybe<Scalars['String']['input']>
    instructionExtraLink_contains_i?: InputMaybe<Scalars['String']['input']>
    instructionExtraLink_ends_with?: InputMaybe<Scalars['String']['input']>
    instructionExtraLink_ends_with_i?: InputMaybe<Scalars['String']['input']>
    instructionExtraLink_i?: InputMaybe<Scalars['String']['input']>
    instructionExtraLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    instructionExtraLink_not?: InputMaybe<Scalars['String']['input']>
    instructionExtraLink_not_contains?: InputMaybe<Scalars['String']['input']>
    instructionExtraLink_not_contains_i?: InputMaybe<Scalars['String']['input']>
    instructionExtraLink_not_ends_with?: InputMaybe<Scalars['String']['input']>
    instructionExtraLink_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    instructionExtraLink_not_i?: InputMaybe<Scalars['String']['input']>
    instructionExtraLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    instructionExtraLink_not_starts_with?: InputMaybe<Scalars['String']['input']>
    instructionExtraLink_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    instructionExtraLink_starts_with?: InputMaybe<Scalars['String']['input']>
    instructionExtraLink_starts_with_i?: InputMaybe<Scalars['String']['input']>
    instruction_contains?: InputMaybe<Scalars['String']['input']>
    instruction_contains_i?: InputMaybe<Scalars['String']['input']>
    instruction_ends_with?: InputMaybe<Scalars['String']['input']>
    instruction_ends_with_i?: InputMaybe<Scalars['String']['input']>
    instruction_i?: InputMaybe<Scalars['String']['input']>
    instruction_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    instruction_not?: InputMaybe<Scalars['String']['input']>
    instruction_not_contains?: InputMaybe<Scalars['String']['input']>
    instruction_not_contains_i?: InputMaybe<Scalars['String']['input']>
    instruction_not_ends_with?: InputMaybe<Scalars['String']['input']>
    instruction_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    instruction_not_i?: InputMaybe<Scalars['String']['input']>
    instruction_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    instruction_not_starts_with?: InputMaybe<Scalars['String']['input']>
    instruction_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    instruction_starts_with?: InputMaybe<Scalars['String']['input']>
    instruction_starts_with_i?: InputMaybe<Scalars['String']['input']>
    isHidden?: InputMaybe<Scalars['Boolean']['input']>
    isHidden_not?: InputMaybe<Scalars['Boolean']['input']>
    isTrustedBankAccountSource?: InputMaybe<Scalars['Boolean']['input']>
    isTrustedBankAccountSource_not?: InputMaybe<Scalars['Boolean']['input']>
    logo?: InputMaybe<Scalars['String']['input']>
    logo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    logo_not?: InputMaybe<Scalars['String']['input']>
    logo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    receiptsLoadingTime?: InputMaybe<Scalars['String']['input']>
    receiptsLoadingTime_contains?: InputMaybe<Scalars['String']['input']>
    receiptsLoadingTime_contains_i?: InputMaybe<Scalars['String']['input']>
    receiptsLoadingTime_ends_with?: InputMaybe<Scalars['String']['input']>
    receiptsLoadingTime_ends_with_i?: InputMaybe<Scalars['String']['input']>
    receiptsLoadingTime_i?: InputMaybe<Scalars['String']['input']>
    receiptsLoadingTime_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    receiptsLoadingTime_not?: InputMaybe<Scalars['String']['input']>
    receiptsLoadingTime_not_contains?: InputMaybe<Scalars['String']['input']>
    receiptsLoadingTime_not_contains_i?: InputMaybe<Scalars['String']['input']>
    receiptsLoadingTime_not_ends_with?: InputMaybe<Scalars['String']['input']>
    receiptsLoadingTime_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    receiptsLoadingTime_not_i?: InputMaybe<Scalars['String']['input']>
    receiptsLoadingTime_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    receiptsLoadingTime_not_starts_with?: InputMaybe<Scalars['String']['input']>
    receiptsLoadingTime_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    receiptsLoadingTime_starts_with?: InputMaybe<Scalars['String']['input']>
    receiptsLoadingTime_starts_with_i?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    setupUrl?: InputMaybe<Scalars['String']['input']>
    setupUrl_contains?: InputMaybe<Scalars['String']['input']>
    setupUrl_contains_i?: InputMaybe<Scalars['String']['input']>
    setupUrl_ends_with?: InputMaybe<Scalars['String']['input']>
    setupUrl_ends_with_i?: InputMaybe<Scalars['String']['input']>
    setupUrl_i?: InputMaybe<Scalars['String']['input']>
    setupUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    setupUrl_not?: InputMaybe<Scalars['String']['input']>
    setupUrl_not_contains?: InputMaybe<Scalars['String']['input']>
    setupUrl_not_contains_i?: InputMaybe<Scalars['String']['input']>
    setupUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
    setupUrl_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    setupUrl_not_i?: InputMaybe<Scalars['String']['input']>
    setupUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    setupUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
    setupUrl_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    setupUrl_starts_with?: InputMaybe<Scalars['String']['input']>
    setupUrl_starts_with_i?: InputMaybe<Scalars['String']['input']>
    shortDescription?: InputMaybe<Scalars['String']['input']>
    shortDescription_contains?: InputMaybe<Scalars['String']['input']>
    shortDescription_contains_i?: InputMaybe<Scalars['String']['input']>
    shortDescription_ends_with?: InputMaybe<Scalars['String']['input']>
    shortDescription_ends_with_i?: InputMaybe<Scalars['String']['input']>
    shortDescription_i?: InputMaybe<Scalars['String']['input']>
    shortDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    shortDescription_not?: InputMaybe<Scalars['String']['input']>
    shortDescription_not_contains?: InputMaybe<Scalars['String']['input']>
    shortDescription_not_contains_i?: InputMaybe<Scalars['String']['input']>
    shortDescription_not_ends_with?: InputMaybe<Scalars['String']['input']>
    shortDescription_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    shortDescription_not_i?: InputMaybe<Scalars['String']['input']>
    shortDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    shortDescription_not_starts_with?: InputMaybe<Scalars['String']['input']>
    shortDescription_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    shortDescription_starts_with?: InputMaybe<Scalars['String']['input']>
    shortDescription_starts_with_i?: InputMaybe<Scalars['String']['input']>
    skipNoAccountNotifications?: InputMaybe<Scalars['Boolean']['input']>
    skipNoAccountNotifications_not?: InputMaybe<Scalars['Boolean']['input']>
    targetDescription?: InputMaybe<Scalars['String']['input']>
    targetDescription_contains?: InputMaybe<Scalars['String']['input']>
    targetDescription_contains_i?: InputMaybe<Scalars['String']['input']>
    targetDescription_ends_with?: InputMaybe<Scalars['String']['input']>
    targetDescription_ends_with_i?: InputMaybe<Scalars['String']['input']>
    targetDescription_i?: InputMaybe<Scalars['String']['input']>
    targetDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    targetDescription_not?: InputMaybe<Scalars['String']['input']>
    targetDescription_not_contains?: InputMaybe<Scalars['String']['input']>
    targetDescription_not_contains_i?: InputMaybe<Scalars['String']['input']>
    targetDescription_not_ends_with?: InputMaybe<Scalars['String']['input']>
    targetDescription_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    targetDescription_not_i?: InputMaybe<Scalars['String']['input']>
    targetDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    targetDescription_not_starts_with?: InputMaybe<Scalars['String']['input']>
    targetDescription_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    targetDescription_starts_with?: InputMaybe<Scalars['String']['input']>
    targetDescription_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    uploadMessage?: InputMaybe<Scalars['String']['input']>
    uploadMessage_contains?: InputMaybe<Scalars['String']['input']>
    uploadMessage_contains_i?: InputMaybe<Scalars['String']['input']>
    uploadMessage_ends_with?: InputMaybe<Scalars['String']['input']>
    uploadMessage_ends_with_i?: InputMaybe<Scalars['String']['input']>
    uploadMessage_i?: InputMaybe<Scalars['String']['input']>
    uploadMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    uploadMessage_not?: InputMaybe<Scalars['String']['input']>
    uploadMessage_not_contains?: InputMaybe<Scalars['String']['input']>
    uploadMessage_not_contains_i?: InputMaybe<Scalars['String']['input']>
    uploadMessage_not_ends_with?: InputMaybe<Scalars['String']['input']>
    uploadMessage_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    uploadMessage_not_i?: InputMaybe<Scalars['String']['input']>
    uploadMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    uploadMessage_not_starts_with?: InputMaybe<Scalars['String']['input']>
    uploadMessage_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    uploadMessage_starts_with?: InputMaybe<Scalars['String']['input']>
    uploadMessage_starts_with_i?: InputMaybe<Scalars['String']['input']>
    uploadUrl?: InputMaybe<Scalars['String']['input']>
    uploadUrl_contains?: InputMaybe<Scalars['String']['input']>
    uploadUrl_contains_i?: InputMaybe<Scalars['String']['input']>
    uploadUrl_ends_with?: InputMaybe<Scalars['String']['input']>
    uploadUrl_ends_with_i?: InputMaybe<Scalars['String']['input']>
    uploadUrl_i?: InputMaybe<Scalars['String']['input']>
    uploadUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    uploadUrl_not?: InputMaybe<Scalars['String']['input']>
    uploadUrl_not_contains?: InputMaybe<Scalars['String']['input']>
    uploadUrl_not_contains_i?: InputMaybe<Scalars['String']['input']>
    uploadUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
    uploadUrl_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    uploadUrl_not_i?: InputMaybe<Scalars['String']['input']>
    uploadUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    uploadUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
    uploadUrl_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    uploadUrl_starts_with?: InputMaybe<Scalars['String']['input']>
    uploadUrl_starts_with_i?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type BillingIntegrationWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type BillingIntegrationsCreateInput = {
    data?: InputMaybe<BillingIntegrationCreateInput>
}

export type BillingIntegrationsUpdateInput = {
    data?: InputMaybe<BillingIntegrationUpdateInput>
    id: Scalars['ID']['input']
}

export type BillingPropertiesCreateInput = {
    data?: InputMaybe<BillingPropertyCreateInput>
}

export type BillingPropertiesUpdateInput = {
    data?: InputMaybe<BillingPropertyUpdateInput>
    id: Scalars['ID']['input']
}

/**  All `property` objects from `billing data source`  */
export type BillingProperty = {
    __typename?: 'BillingProperty'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BillingProperty List config, or
   *  2. As an alias to the field set on 'labelField' in the BillingProperty List config, or
   *  3. As an alias to a 'name' field on the BillingProperty List (if one exists), or
   *  4. As an alias to the 'id' field on the BillingProperty List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  Normalized address  */
    address?: Maybe<Scalars['String']['output']>
    /**  The unique key of the address  */
    addressKey?: Maybe<Scalars['String']['output']>
    /**  Property address components  */
    addressMeta?: Maybe<AddressMetaField>
    /**  The origins of the address (some strings which may looks like real address or some id)  */
    addressSources?: Maybe<Scalars['JSON']['output']>
    /**  Integration context  */
    context?: Maybe<BillingIntegrationOrganizationContext>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  A well-known universal identifier that allows you to identify the same objects in different systems. It may differ in different countries. Example: for Russia, the FIAS ID is used  */
    globalId?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    /**  Id of object in external service which represents current item. Mostly used for internal needs of integration services for matching our objects with theirs  */
    importId?: Maybe<Scalars['String']['output']>
    /**  Structured metadata obtained from the `billing data source`. Some of this data is required for use in the `receipt template`. Examples of data keys: `total space of building`, `property beginning of exploitation year`, `has cultural heritage status`, `number of underground floors`, `number of above-ground floors`  */
    meta?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  [DEPRECATED] Normalized address from `billing data source`. Used to map Properties to BillingProperties  */
    normalizedAddress?: Maybe<Scalars['String']['output']>
    /**  Link to the property model  */
    property?: Maybe<Property>
    /**  Raw non-structured data obtained from the `billing data source`. Used only for the internal needs of the `integration component`.  */
    raw?: Maybe<Scalars['JSON']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type BillingPropertyCreateInput = {
    address?: InputMaybe<Scalars['String']['input']>
    addressKey?: InputMaybe<Scalars['String']['input']>
    addressMeta?: InputMaybe<Scalars['JSON']['input']>
    addressSources?: InputMaybe<Scalars['JSON']['input']>
    context?: InputMaybe<BillingIntegrationOrganizationContextRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    globalId?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    normalizedAddress?: InputMaybe<Scalars['String']['input']>
    raw?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type BillingPropertyHistoryRecord = {
    __typename?: 'BillingPropertyHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BillingPropertyHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the BillingPropertyHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the BillingPropertyHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the BillingPropertyHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    address?: Maybe<Scalars['String']['output']>
    addressKey?: Maybe<Scalars['String']['output']>
    addressMeta?: Maybe<Scalars['JSON']['output']>
    addressSources?: Maybe<Scalars['JSON']['output']>
    context?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    globalId?: Maybe<Scalars['String']['output']>
    history_action?: Maybe<BillingPropertyHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    importId?: Maybe<Scalars['String']['output']>
    meta?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    normalizedAddress?: Maybe<Scalars['String']['output']>
    raw?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type BillingPropertyHistoryRecordCreateInput = {
    address?: InputMaybe<Scalars['String']['input']>
    addressKey?: InputMaybe<Scalars['String']['input']>
    addressMeta?: InputMaybe<Scalars['JSON']['input']>
    addressSources?: InputMaybe<Scalars['JSON']['input']>
    context?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    globalId?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<BillingPropertyHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    normalizedAddress?: InputMaybe<Scalars['String']['input']>
    raw?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum BillingPropertyHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type BillingPropertyHistoryRecordUpdateInput = {
    address?: InputMaybe<Scalars['String']['input']>
    addressKey?: InputMaybe<Scalars['String']['input']>
    addressMeta?: InputMaybe<Scalars['JSON']['input']>
    addressSources?: InputMaybe<Scalars['JSON']['input']>
    context?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    globalId?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<BillingPropertyHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    normalizedAddress?: InputMaybe<Scalars['String']['input']>
    raw?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type BillingPropertyHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<BillingPropertyHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<BillingPropertyHistoryRecordWhereInput>>>
    address?: InputMaybe<Scalars['String']['input']>
    addressKey?: InputMaybe<Scalars['String']['input']>
    addressKey_contains?: InputMaybe<Scalars['String']['input']>
    addressKey_contains_i?: InputMaybe<Scalars['String']['input']>
    addressKey_ends_with?: InputMaybe<Scalars['String']['input']>
    addressKey_ends_with_i?: InputMaybe<Scalars['String']['input']>
    addressKey_i?: InputMaybe<Scalars['String']['input']>
    addressKey_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    addressKey_not?: InputMaybe<Scalars['String']['input']>
    addressKey_not_contains?: InputMaybe<Scalars['String']['input']>
    addressKey_not_contains_i?: InputMaybe<Scalars['String']['input']>
    addressKey_not_ends_with?: InputMaybe<Scalars['String']['input']>
    addressKey_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    addressKey_not_i?: InputMaybe<Scalars['String']['input']>
    addressKey_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    addressKey_not_starts_with?: InputMaybe<Scalars['String']['input']>
    addressKey_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    addressKey_starts_with?: InputMaybe<Scalars['String']['input']>
    addressKey_starts_with_i?: InputMaybe<Scalars['String']['input']>
    addressMeta?: InputMaybe<Scalars['JSON']['input']>
    addressMeta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    addressMeta_not?: InputMaybe<Scalars['JSON']['input']>
    addressMeta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    addressSources?: InputMaybe<Scalars['JSON']['input']>
    addressSources_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    addressSources_not?: InputMaybe<Scalars['JSON']['input']>
    addressSources_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    address_contains?: InputMaybe<Scalars['String']['input']>
    address_contains_i?: InputMaybe<Scalars['String']['input']>
    address_ends_with?: InputMaybe<Scalars['String']['input']>
    address_ends_with_i?: InputMaybe<Scalars['String']['input']>
    address_i?: InputMaybe<Scalars['String']['input']>
    address_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    address_not?: InputMaybe<Scalars['String']['input']>
    address_not_contains?: InputMaybe<Scalars['String']['input']>
    address_not_contains_i?: InputMaybe<Scalars['String']['input']>
    address_not_ends_with?: InputMaybe<Scalars['String']['input']>
    address_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    address_not_i?: InputMaybe<Scalars['String']['input']>
    address_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    address_not_starts_with?: InputMaybe<Scalars['String']['input']>
    address_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    address_starts_with?: InputMaybe<Scalars['String']['input']>
    address_starts_with_i?: InputMaybe<Scalars['String']['input']>
    context?: InputMaybe<Scalars['String']['input']>
    context_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    context_not?: InputMaybe<Scalars['String']['input']>
    context_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    globalId?: InputMaybe<Scalars['String']['input']>
    globalId_contains?: InputMaybe<Scalars['String']['input']>
    globalId_contains_i?: InputMaybe<Scalars['String']['input']>
    globalId_ends_with?: InputMaybe<Scalars['String']['input']>
    globalId_ends_with_i?: InputMaybe<Scalars['String']['input']>
    globalId_i?: InputMaybe<Scalars['String']['input']>
    globalId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    globalId_not?: InputMaybe<Scalars['String']['input']>
    globalId_not_contains?: InputMaybe<Scalars['String']['input']>
    globalId_not_contains_i?: InputMaybe<Scalars['String']['input']>
    globalId_not_ends_with?: InputMaybe<Scalars['String']['input']>
    globalId_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    globalId_not_i?: InputMaybe<Scalars['String']['input']>
    globalId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    globalId_not_starts_with?: InputMaybe<Scalars['String']['input']>
    globalId_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    globalId_starts_with?: InputMaybe<Scalars['String']['input']>
    globalId_starts_with_i?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<BillingPropertyHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<BillingPropertyHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<BillingPropertyHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<BillingPropertyHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    importId?: InputMaybe<Scalars['String']['input']>
    importId_contains?: InputMaybe<Scalars['String']['input']>
    importId_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_i?: InputMaybe<Scalars['String']['input']>
    importId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not?: InputMaybe<Scalars['String']['input']>
    importId_not_contains?: InputMaybe<Scalars['String']['input']>
    importId_not_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_not_i?: InputMaybe<Scalars['String']['input']>
    importId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importId_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_starts_with_i?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    normalizedAddress?: InputMaybe<Scalars['String']['input']>
    normalizedAddress_contains?: InputMaybe<Scalars['String']['input']>
    normalizedAddress_contains_i?: InputMaybe<Scalars['String']['input']>
    normalizedAddress_ends_with?: InputMaybe<Scalars['String']['input']>
    normalizedAddress_ends_with_i?: InputMaybe<Scalars['String']['input']>
    normalizedAddress_i?: InputMaybe<Scalars['String']['input']>
    normalizedAddress_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    normalizedAddress_not?: InputMaybe<Scalars['String']['input']>
    normalizedAddress_not_contains?: InputMaybe<Scalars['String']['input']>
    normalizedAddress_not_contains_i?: InputMaybe<Scalars['String']['input']>
    normalizedAddress_not_ends_with?: InputMaybe<Scalars['String']['input']>
    normalizedAddress_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    normalizedAddress_not_i?: InputMaybe<Scalars['String']['input']>
    normalizedAddress_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    normalizedAddress_not_starts_with?: InputMaybe<Scalars['String']['input']>
    normalizedAddress_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    normalizedAddress_starts_with?: InputMaybe<Scalars['String']['input']>
    normalizedAddress_starts_with_i?: InputMaybe<Scalars['String']['input']>
    raw?: InputMaybe<Scalars['JSON']['input']>
    raw_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    raw_not?: InputMaybe<Scalars['JSON']['input']>
    raw_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type BillingPropertyHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type BillingPropertyHistoryRecordsCreateInput = {
    data?: InputMaybe<BillingPropertyHistoryRecordCreateInput>
}

export type BillingPropertyHistoryRecordsUpdateInput = {
    data?: InputMaybe<BillingPropertyHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type BillingPropertyRelateToOneInput = {
    connect?: InputMaybe<BillingPropertyWhereUniqueInput>
    create?: InputMaybe<BillingPropertyCreateInput>
    disconnect?: InputMaybe<BillingPropertyWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type BillingPropertyUpdateInput = {
    address?: InputMaybe<Scalars['String']['input']>
    addressKey?: InputMaybe<Scalars['String']['input']>
    addressMeta?: InputMaybe<Scalars['JSON']['input']>
    addressSources?: InputMaybe<Scalars['JSON']['input']>
    context?: InputMaybe<BillingIntegrationOrganizationContextRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    globalId?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    normalizedAddress?: InputMaybe<Scalars['String']['input']>
    raw?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type BillingPropertyWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<BillingPropertyWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<BillingPropertyWhereInput>>>
    address?: InputMaybe<Scalars['String']['input']>
    addressKey?: InputMaybe<Scalars['String']['input']>
    addressKey_contains?: InputMaybe<Scalars['String']['input']>
    addressKey_contains_i?: InputMaybe<Scalars['String']['input']>
    addressKey_ends_with?: InputMaybe<Scalars['String']['input']>
    addressKey_ends_with_i?: InputMaybe<Scalars['String']['input']>
    addressKey_i?: InputMaybe<Scalars['String']['input']>
    addressKey_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    addressKey_not?: InputMaybe<Scalars['String']['input']>
    addressKey_not_contains?: InputMaybe<Scalars['String']['input']>
    addressKey_not_contains_i?: InputMaybe<Scalars['String']['input']>
    addressKey_not_ends_with?: InputMaybe<Scalars['String']['input']>
    addressKey_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    addressKey_not_i?: InputMaybe<Scalars['String']['input']>
    addressKey_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    addressKey_not_starts_with?: InputMaybe<Scalars['String']['input']>
    addressKey_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    addressKey_starts_with?: InputMaybe<Scalars['String']['input']>
    addressKey_starts_with_i?: InputMaybe<Scalars['String']['input']>
    addressMeta?: InputMaybe<Scalars['JSON']['input']>
    addressMeta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    addressMeta_not?: InputMaybe<Scalars['JSON']['input']>
    addressMeta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    addressSources?: InputMaybe<Scalars['JSON']['input']>
    addressSources_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    addressSources_not?: InputMaybe<Scalars['JSON']['input']>
    addressSources_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    address_contains?: InputMaybe<Scalars['String']['input']>
    address_contains_i?: InputMaybe<Scalars['String']['input']>
    address_ends_with?: InputMaybe<Scalars['String']['input']>
    address_ends_with_i?: InputMaybe<Scalars['String']['input']>
    address_i?: InputMaybe<Scalars['String']['input']>
    address_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    address_not?: InputMaybe<Scalars['String']['input']>
    address_not_contains?: InputMaybe<Scalars['String']['input']>
    address_not_contains_i?: InputMaybe<Scalars['String']['input']>
    address_not_ends_with?: InputMaybe<Scalars['String']['input']>
    address_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    address_not_i?: InputMaybe<Scalars['String']['input']>
    address_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    address_not_starts_with?: InputMaybe<Scalars['String']['input']>
    address_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    address_starts_with?: InputMaybe<Scalars['String']['input']>
    address_starts_with_i?: InputMaybe<Scalars['String']['input']>
    context?: InputMaybe<BillingIntegrationOrganizationContextWhereInput>
    context_is_null?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    globalId?: InputMaybe<Scalars['String']['input']>
    globalId_contains?: InputMaybe<Scalars['String']['input']>
    globalId_contains_i?: InputMaybe<Scalars['String']['input']>
    globalId_ends_with?: InputMaybe<Scalars['String']['input']>
    globalId_ends_with_i?: InputMaybe<Scalars['String']['input']>
    globalId_i?: InputMaybe<Scalars['String']['input']>
    globalId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    globalId_not?: InputMaybe<Scalars['String']['input']>
    globalId_not_contains?: InputMaybe<Scalars['String']['input']>
    globalId_not_contains_i?: InputMaybe<Scalars['String']['input']>
    globalId_not_ends_with?: InputMaybe<Scalars['String']['input']>
    globalId_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    globalId_not_i?: InputMaybe<Scalars['String']['input']>
    globalId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    globalId_not_starts_with?: InputMaybe<Scalars['String']['input']>
    globalId_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    globalId_starts_with?: InputMaybe<Scalars['String']['input']>
    globalId_starts_with_i?: InputMaybe<Scalars['String']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    importId?: InputMaybe<Scalars['String']['input']>
    importId_contains?: InputMaybe<Scalars['String']['input']>
    importId_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_i?: InputMaybe<Scalars['String']['input']>
    importId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not?: InputMaybe<Scalars['String']['input']>
    importId_not_contains?: InputMaybe<Scalars['String']['input']>
    importId_not_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_not_i?: InputMaybe<Scalars['String']['input']>
    importId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importId_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_starts_with_i?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    normalizedAddress?: InputMaybe<Scalars['String']['input']>
    normalizedAddress_contains?: InputMaybe<Scalars['String']['input']>
    normalizedAddress_contains_i?: InputMaybe<Scalars['String']['input']>
    normalizedAddress_ends_with?: InputMaybe<Scalars['String']['input']>
    normalizedAddress_ends_with_i?: InputMaybe<Scalars['String']['input']>
    normalizedAddress_i?: InputMaybe<Scalars['String']['input']>
    normalizedAddress_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    normalizedAddress_not?: InputMaybe<Scalars['String']['input']>
    normalizedAddress_not_contains?: InputMaybe<Scalars['String']['input']>
    normalizedAddress_not_contains_i?: InputMaybe<Scalars['String']['input']>
    normalizedAddress_not_ends_with?: InputMaybe<Scalars['String']['input']>
    normalizedAddress_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    normalizedAddress_not_i?: InputMaybe<Scalars['String']['input']>
    normalizedAddress_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    normalizedAddress_not_starts_with?: InputMaybe<Scalars['String']['input']>
    normalizedAddress_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    normalizedAddress_starts_with?: InputMaybe<Scalars['String']['input']>
    normalizedAddress_starts_with_i?: InputMaybe<Scalars['String']['input']>
    raw?: InputMaybe<Scalars['JSON']['input']>
    raw_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    raw_not?: InputMaybe<Scalars['JSON']['input']>
    raw_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type BillingPropertyWhereUniqueInput = {
    id: Scalars['ID']['input']
}

/**  Account monthly invoice document  */
export type BillingReceipt = {
    __typename?: 'BillingReceipt'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BillingReceipt List config, or
   *  2. As an alias to the field set on 'labelField' in the BillingReceipt List config, or
   *  3. As an alias to a 'name' field on the BillingReceipt List (if one exists), or
   *  4. As an alias to the 'id' field on the BillingReceipt List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  Billing account  */
    account?: Maybe<BillingAccount>
    /**  Url to acquiring integration service. Mobile devices will use it communicate with external acquiring. List of endpoints is the same for all of them.  */
    acquiringHostUrl?: Maybe<Scalars['String']['output']>
    /**  Integration ID through which this receipt can be paid  */
    acquiringIntegrationId?: Maybe<Scalars['ID']['output']>
    /**  Recipient balance on the receipt creation moment. Example: "50.00", "-50.00"  */
    balance?: Maybe<Scalars['String']['output']>
    /**  Can multiple receipts be united through this acquiring  */
    canGroupReceipts?: Maybe<Scalars['Boolean']['output']>
    /**  A category to print on display on the payment document.  */
    category?: Maybe<BillingCategory>
    /**  Amount of money charged by paid period. Example: "50.00", "-50.00"  */
    charge?: Maybe<Scalars['String']['output']>
    /**  Integration context  */
    context?: Maybe<BillingIntegrationOrganizationContext>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    /**  Code of currency in ISO-4217 format  */
    currencyCode?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  A BillingReceiptFile that related to this billing receipt (filled up by integration)  */
    file?: Maybe<BillingReceiptFile>
    /**  Calculation formula. Example: balance + charge + recalculation + privilege + penalty  */
    formula?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    /**  `billing receipt` local object ID. Unique up to billing context. It is unique up to the context. The constrain is a combination of contextId and importId.  */
    importId?: Maybe<Scalars['String']['output']>
    /**  Indicates if services are valid and add up to total sum toPay.  */
    invalidServicesError?: Maybe<Scalars['String']['output']>
    /**  Indicator that this invoice document is latest for its account and receiver/category  */
    isPayable?: Maybe<Scalars['Boolean']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Amount of money that recipient already paid by current receipt. Example: "50.00", "-50.00"  */
    paid?: Maybe<Scalars['String']['output']>
    /**  Amount of money that recipient doesn't pay for previous receipt. Example: "50.00", "-50.00"  */
    penalty?: Maybe<Scalars['String']['output']>
    /**  Period date: Generated on template <year>-<month>-01  */
    period?: Maybe<Scalars['String']['output']>
    /**  A number to print on the payment document.  */
    printableNumber?: Maybe<Scalars['String']['output']>
    /**  Special privileges for recipient. Example: "50.00", "-50.00"  */
    privilege?: Maybe<Scalars['String']['output']>
    /**  Billing property  */
    property?: Maybe<BillingProperty>
    /**  Raw non-structured data obtained from the `billing data source`. Used only for the internal needs of the `integration component`.  */
    raw?: Maybe<Scalars['JSON']['output']>
    /**  Recipient balance recalculation in case of overpaid or etc. Example: "50.00", "-50.00"  */
    recalculation?: Maybe<Scalars['String']['output']>
    /**  Relation to the BillingRecipient. Going to override recipient field, has the same meaning  */
    receiver?: Maybe<BillingRecipient>
    /**  Recipient. Should contain all meta information to identify the organization  */
    recipient?: Maybe<BillingReceiptsRecipientField>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Services to pay for. Every service has id, name and toPay. Service may or may not have toPay detail. Detail level 3 and 4  */
    services?: Maybe<Array<BillingReceiptServiceField>>
    /**  Total sum to pay. Usually counts as the sum of all services.  */
    toPay?: Maybe<Scalars['String']['output']>
    /**  @deprecated Sum to pay details. Detail level 2. This field will be removed in the future. All data is saved in the corresponding fields of the BillingReceipt (charge formula balance recalculation privilege penalty paid). After toPayDetails field removal you should update it content explicitly  */
    toPayDetails?: Maybe<BillingReceiptToPayDetailsField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type BillingReceiptCreateInput = {
    account?: InputMaybe<BillingAccountRelateToOneInput>
    balance?: InputMaybe<Scalars['String']['input']>
    category?: InputMaybe<BillingCategoryRelateToOneInput>
    charge?: InputMaybe<Scalars['String']['input']>
    context?: InputMaybe<BillingIntegrationOrganizationContextRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<BillingReceiptFileRelateToOneInput>
    formula?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    paid?: InputMaybe<Scalars['String']['input']>
    penalty?: InputMaybe<Scalars['String']['input']>
    period?: InputMaybe<Scalars['String']['input']>
    printableNumber?: InputMaybe<Scalars['String']['input']>
    privilege?: InputMaybe<Scalars['String']['input']>
    property?: InputMaybe<BillingPropertyRelateToOneInput>
    raw?: InputMaybe<Scalars['JSON']['input']>
    recalculation?: InputMaybe<Scalars['String']['input']>
    receiver?: InputMaybe<BillingRecipientRelateToOneInput>
    recipient?: InputMaybe<BillingReceiptsRecipientFieldInput>
    sender?: InputMaybe<SenderFieldInput>
    services?: InputMaybe<Array<BillingReceiptServiceFieldInput>>
    toPay?: InputMaybe<Scalars['String']['input']>
    toPayDetails?: InputMaybe<BillingReceiptToPayDetailsFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  File for billing receipt  */
export type BillingReceiptFile = {
    __typename?: 'BillingReceiptFile'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BillingReceiptFile List config, or
   *  2. As an alias to the field set on 'labelField' in the BillingReceiptFile List config, or
   *  3. As an alias to a 'name' field on the BillingReceiptFile List (if one exists), or
   *  4. As an alias to the 'id' field on the BillingReceiptFile List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  Link to Context  */
    context?: Maybe<BillingIntegrationOrganizationContext>
    /**  Meta information about the file  */
    controlSum?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  Wrapper to return file version of the receipt (usually PDF) with personal information or without  */
    file?: Maybe<File>
    id: Scalars['ID']['output']
    /**  Unique import id for each file  */
    importId?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  File version of the receipt without personal information  */
    publicDataFile?: Maybe<File>
    /**  Link to Billing Receipt  */
    receipt?: Maybe<BillingReceipt>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  File version of the receipt with personal information  */
    sensitiveDataFile?: Maybe<File>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type BillingReceiptFileCreateInput = {
    context?: InputMaybe<BillingIntegrationOrganizationContextRelateToOneInput>
    controlSum?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    publicDataFile?: InputMaybe<Scalars['Upload']['input']>
    receipt?: InputMaybe<BillingReceiptRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    sensitiveDataFile?: InputMaybe<Scalars['Upload']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type BillingReceiptFileHistoryRecord = {
    __typename?: 'BillingReceiptFileHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BillingReceiptFileHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the BillingReceiptFileHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the BillingReceiptFileHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the BillingReceiptFileHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    context?: Maybe<Scalars['String']['output']>
    controlSum?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<BillingReceiptFileHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    importId?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    publicDataFile?: Maybe<Scalars['JSON']['output']>
    receipt?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    sensitiveDataFile?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type BillingReceiptFileHistoryRecordCreateInput = {
    context?: InputMaybe<Scalars['String']['input']>
    controlSum?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<BillingReceiptFileHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    publicDataFile?: InputMaybe<Scalars['JSON']['input']>
    receipt?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sensitiveDataFile?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum BillingReceiptFileHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type BillingReceiptFileHistoryRecordUpdateInput = {
    context?: InputMaybe<Scalars['String']['input']>
    controlSum?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<BillingReceiptFileHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    publicDataFile?: InputMaybe<Scalars['JSON']['input']>
    receipt?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sensitiveDataFile?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type BillingReceiptFileHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<BillingReceiptFileHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<BillingReceiptFileHistoryRecordWhereInput>>>
    context?: InputMaybe<Scalars['String']['input']>
    context_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    context_not?: InputMaybe<Scalars['String']['input']>
    context_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    controlSum?: InputMaybe<Scalars['String']['input']>
    controlSum_contains?: InputMaybe<Scalars['String']['input']>
    controlSum_contains_i?: InputMaybe<Scalars['String']['input']>
    controlSum_ends_with?: InputMaybe<Scalars['String']['input']>
    controlSum_ends_with_i?: InputMaybe<Scalars['String']['input']>
    controlSum_i?: InputMaybe<Scalars['String']['input']>
    controlSum_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    controlSum_not?: InputMaybe<Scalars['String']['input']>
    controlSum_not_contains?: InputMaybe<Scalars['String']['input']>
    controlSum_not_contains_i?: InputMaybe<Scalars['String']['input']>
    controlSum_not_ends_with?: InputMaybe<Scalars['String']['input']>
    controlSum_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    controlSum_not_i?: InputMaybe<Scalars['String']['input']>
    controlSum_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    controlSum_not_starts_with?: InputMaybe<Scalars['String']['input']>
    controlSum_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    controlSum_starts_with?: InputMaybe<Scalars['String']['input']>
    controlSum_starts_with_i?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<BillingReceiptFileHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<BillingReceiptFileHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<BillingReceiptFileHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<BillingReceiptFileHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    importId?: InputMaybe<Scalars['String']['input']>
    importId_contains?: InputMaybe<Scalars['String']['input']>
    importId_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_i?: InputMaybe<Scalars['String']['input']>
    importId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not?: InputMaybe<Scalars['String']['input']>
    importId_not_contains?: InputMaybe<Scalars['String']['input']>
    importId_not_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_not_i?: InputMaybe<Scalars['String']['input']>
    importId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importId_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    publicDataFile?: InputMaybe<Scalars['JSON']['input']>
    publicDataFile_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    publicDataFile_not?: InputMaybe<Scalars['JSON']['input']>
    publicDataFile_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    receipt?: InputMaybe<Scalars['String']['input']>
    receipt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    receipt_not?: InputMaybe<Scalars['String']['input']>
    receipt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sensitiveDataFile?: InputMaybe<Scalars['JSON']['input']>
    sensitiveDataFile_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sensitiveDataFile_not?: InputMaybe<Scalars['JSON']['input']>
    sensitiveDataFile_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type BillingReceiptFileHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type BillingReceiptFileHistoryRecordsCreateInput = {
    data?: InputMaybe<BillingReceiptFileHistoryRecordCreateInput>
}

export type BillingReceiptFileHistoryRecordsUpdateInput = {
    data?: InputMaybe<BillingReceiptFileHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type BillingReceiptFileRelateToOneInput = {
    connect?: InputMaybe<BillingReceiptFileWhereUniqueInput>
    create?: InputMaybe<BillingReceiptFileCreateInput>
    disconnect?: InputMaybe<BillingReceiptFileWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type BillingReceiptFileUpdateInput = {
    context?: InputMaybe<BillingIntegrationOrganizationContextRelateToOneInput>
    controlSum?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    publicDataFile?: InputMaybe<Scalars['Upload']['input']>
    receipt?: InputMaybe<BillingReceiptRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    sensitiveDataFile?: InputMaybe<Scalars['Upload']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type BillingReceiptFileWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<BillingReceiptFileWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<BillingReceiptFileWhereInput>>>
    context?: InputMaybe<BillingIntegrationOrganizationContextWhereInput>
    context_is_null?: InputMaybe<Scalars['Boolean']['input']>
    controlSum?: InputMaybe<Scalars['String']['input']>
    controlSum_contains?: InputMaybe<Scalars['String']['input']>
    controlSum_contains_i?: InputMaybe<Scalars['String']['input']>
    controlSum_ends_with?: InputMaybe<Scalars['String']['input']>
    controlSum_ends_with_i?: InputMaybe<Scalars['String']['input']>
    controlSum_i?: InputMaybe<Scalars['String']['input']>
    controlSum_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    controlSum_not?: InputMaybe<Scalars['String']['input']>
    controlSum_not_contains?: InputMaybe<Scalars['String']['input']>
    controlSum_not_contains_i?: InputMaybe<Scalars['String']['input']>
    controlSum_not_ends_with?: InputMaybe<Scalars['String']['input']>
    controlSum_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    controlSum_not_i?: InputMaybe<Scalars['String']['input']>
    controlSum_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    controlSum_not_starts_with?: InputMaybe<Scalars['String']['input']>
    controlSum_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    controlSum_starts_with?: InputMaybe<Scalars['String']['input']>
    controlSum_starts_with_i?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    importId?: InputMaybe<Scalars['String']['input']>
    importId_contains?: InputMaybe<Scalars['String']['input']>
    importId_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_i?: InputMaybe<Scalars['String']['input']>
    importId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not?: InputMaybe<Scalars['String']['input']>
    importId_not_contains?: InputMaybe<Scalars['String']['input']>
    importId_not_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_not_i?: InputMaybe<Scalars['String']['input']>
    importId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importId_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    publicDataFile?: InputMaybe<Scalars['String']['input']>
    publicDataFile_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    publicDataFile_not?: InputMaybe<Scalars['String']['input']>
    publicDataFile_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    receipt?: InputMaybe<BillingReceiptWhereInput>
    receipt_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sensitiveDataFile?: InputMaybe<Scalars['String']['input']>
    sensitiveDataFile_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sensitiveDataFile_not?: InputMaybe<Scalars['String']['input']>
    sensitiveDataFile_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type BillingReceiptFileWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type BillingReceiptFilesCreateInput = {
    data?: InputMaybe<BillingReceiptFileCreateInput>
}

export type BillingReceiptFilesUpdateInput = {
    data?: InputMaybe<BillingReceiptFileUpdateInput>
    id: Scalars['ID']['input']
}

/**  A keystone list  */
export type BillingReceiptHistoryRecord = {
    __typename?: 'BillingReceiptHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BillingReceiptHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the BillingReceiptHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the BillingReceiptHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the BillingReceiptHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    account?: Maybe<Scalars['String']['output']>
    balance?: Maybe<Scalars['String']['output']>
    category?: Maybe<Scalars['String']['output']>
    charge?: Maybe<Scalars['String']['output']>
    context?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    file?: Maybe<Scalars['String']['output']>
    formula?: Maybe<Scalars['String']['output']>
    history_action?: Maybe<BillingReceiptHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    importId?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    paid?: Maybe<Scalars['String']['output']>
    penalty?: Maybe<Scalars['String']['output']>
    period?: Maybe<Scalars['String']['output']>
    printableNumber?: Maybe<Scalars['String']['output']>
    privilege?: Maybe<Scalars['String']['output']>
    property?: Maybe<Scalars['String']['output']>
    raw?: Maybe<Scalars['JSON']['output']>
    recalculation?: Maybe<Scalars['String']['output']>
    receiver?: Maybe<Scalars['String']['output']>
    recipient?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    services?: Maybe<Scalars['JSON']['output']>
    toPay?: Maybe<Scalars['String']['output']>
    toPayDetails?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type BillingReceiptHistoryRecordCreateInput = {
    account?: InputMaybe<Scalars['String']['input']>
    balance?: InputMaybe<Scalars['String']['input']>
    category?: InputMaybe<Scalars['String']['input']>
    charge?: InputMaybe<Scalars['String']['input']>
    context?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['String']['input']>
    formula?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<BillingReceiptHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    paid?: InputMaybe<Scalars['String']['input']>
    penalty?: InputMaybe<Scalars['String']['input']>
    period?: InputMaybe<Scalars['String']['input']>
    printableNumber?: InputMaybe<Scalars['String']['input']>
    privilege?: InputMaybe<Scalars['String']['input']>
    property?: InputMaybe<Scalars['String']['input']>
    raw?: InputMaybe<Scalars['JSON']['input']>
    recalculation?: InputMaybe<Scalars['String']['input']>
    receiver?: InputMaybe<Scalars['String']['input']>
    recipient?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    services?: InputMaybe<Scalars['JSON']['input']>
    toPay?: InputMaybe<Scalars['String']['input']>
    toPayDetails?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum BillingReceiptHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type BillingReceiptHistoryRecordUpdateInput = {
    account?: InputMaybe<Scalars['String']['input']>
    balance?: InputMaybe<Scalars['String']['input']>
    category?: InputMaybe<Scalars['String']['input']>
    charge?: InputMaybe<Scalars['String']['input']>
    context?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['String']['input']>
    formula?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<BillingReceiptHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    paid?: InputMaybe<Scalars['String']['input']>
    penalty?: InputMaybe<Scalars['String']['input']>
    period?: InputMaybe<Scalars['String']['input']>
    printableNumber?: InputMaybe<Scalars['String']['input']>
    privilege?: InputMaybe<Scalars['String']['input']>
    property?: InputMaybe<Scalars['String']['input']>
    raw?: InputMaybe<Scalars['JSON']['input']>
    recalculation?: InputMaybe<Scalars['String']['input']>
    receiver?: InputMaybe<Scalars['String']['input']>
    recipient?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    services?: InputMaybe<Scalars['JSON']['input']>
    toPay?: InputMaybe<Scalars['String']['input']>
    toPayDetails?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type BillingReceiptHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<BillingReceiptHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<BillingReceiptHistoryRecordWhereInput>>>
    account?: InputMaybe<Scalars['String']['input']>
    account_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    account_not?: InputMaybe<Scalars['String']['input']>
    account_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    balance?: InputMaybe<Scalars['String']['input']>
    balance_gt?: InputMaybe<Scalars['String']['input']>
    balance_gte?: InputMaybe<Scalars['String']['input']>
    balance_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    balance_lt?: InputMaybe<Scalars['String']['input']>
    balance_lte?: InputMaybe<Scalars['String']['input']>
    balance_not?: InputMaybe<Scalars['String']['input']>
    balance_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    category?: InputMaybe<Scalars['String']['input']>
    category_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    category_not?: InputMaybe<Scalars['String']['input']>
    category_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    charge?: InputMaybe<Scalars['String']['input']>
    charge_gt?: InputMaybe<Scalars['String']['input']>
    charge_gte?: InputMaybe<Scalars['String']['input']>
    charge_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    charge_lt?: InputMaybe<Scalars['String']['input']>
    charge_lte?: InputMaybe<Scalars['String']['input']>
    charge_not?: InputMaybe<Scalars['String']['input']>
    charge_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    context?: InputMaybe<Scalars['String']['input']>
    context_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    context_not?: InputMaybe<Scalars['String']['input']>
    context_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    file?: InputMaybe<Scalars['String']['input']>
    file_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    file_not?: InputMaybe<Scalars['String']['input']>
    file_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    formula?: InputMaybe<Scalars['String']['input']>
    formula_contains?: InputMaybe<Scalars['String']['input']>
    formula_contains_i?: InputMaybe<Scalars['String']['input']>
    formula_ends_with?: InputMaybe<Scalars['String']['input']>
    formula_ends_with_i?: InputMaybe<Scalars['String']['input']>
    formula_i?: InputMaybe<Scalars['String']['input']>
    formula_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    formula_not?: InputMaybe<Scalars['String']['input']>
    formula_not_contains?: InputMaybe<Scalars['String']['input']>
    formula_not_contains_i?: InputMaybe<Scalars['String']['input']>
    formula_not_ends_with?: InputMaybe<Scalars['String']['input']>
    formula_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    formula_not_i?: InputMaybe<Scalars['String']['input']>
    formula_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    formula_not_starts_with?: InputMaybe<Scalars['String']['input']>
    formula_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    formula_starts_with?: InputMaybe<Scalars['String']['input']>
    formula_starts_with_i?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<BillingReceiptHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<BillingReceiptHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<BillingReceiptHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<BillingReceiptHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    importId?: InputMaybe<Scalars['String']['input']>
    importId_contains?: InputMaybe<Scalars['String']['input']>
    importId_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_i?: InputMaybe<Scalars['String']['input']>
    importId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not?: InputMaybe<Scalars['String']['input']>
    importId_not_contains?: InputMaybe<Scalars['String']['input']>
    importId_not_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_not_i?: InputMaybe<Scalars['String']['input']>
    importId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importId_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    paid?: InputMaybe<Scalars['String']['input']>
    paid_gt?: InputMaybe<Scalars['String']['input']>
    paid_gte?: InputMaybe<Scalars['String']['input']>
    paid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    paid_lt?: InputMaybe<Scalars['String']['input']>
    paid_lte?: InputMaybe<Scalars['String']['input']>
    paid_not?: InputMaybe<Scalars['String']['input']>
    paid_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    penalty?: InputMaybe<Scalars['String']['input']>
    penalty_gt?: InputMaybe<Scalars['String']['input']>
    penalty_gte?: InputMaybe<Scalars['String']['input']>
    penalty_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    penalty_lt?: InputMaybe<Scalars['String']['input']>
    penalty_lte?: InputMaybe<Scalars['String']['input']>
    penalty_not?: InputMaybe<Scalars['String']['input']>
    penalty_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    period?: InputMaybe<Scalars['String']['input']>
    period_gt?: InputMaybe<Scalars['String']['input']>
    period_gte?: InputMaybe<Scalars['String']['input']>
    period_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    period_lt?: InputMaybe<Scalars['String']['input']>
    period_lte?: InputMaybe<Scalars['String']['input']>
    period_not?: InputMaybe<Scalars['String']['input']>
    period_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    printableNumber?: InputMaybe<Scalars['String']['input']>
    printableNumber_contains?: InputMaybe<Scalars['String']['input']>
    printableNumber_contains_i?: InputMaybe<Scalars['String']['input']>
    printableNumber_ends_with?: InputMaybe<Scalars['String']['input']>
    printableNumber_ends_with_i?: InputMaybe<Scalars['String']['input']>
    printableNumber_i?: InputMaybe<Scalars['String']['input']>
    printableNumber_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    printableNumber_not?: InputMaybe<Scalars['String']['input']>
    printableNumber_not_contains?: InputMaybe<Scalars['String']['input']>
    printableNumber_not_contains_i?: InputMaybe<Scalars['String']['input']>
    printableNumber_not_ends_with?: InputMaybe<Scalars['String']['input']>
    printableNumber_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    printableNumber_not_i?: InputMaybe<Scalars['String']['input']>
    printableNumber_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    printableNumber_not_starts_with?: InputMaybe<Scalars['String']['input']>
    printableNumber_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    printableNumber_starts_with?: InputMaybe<Scalars['String']['input']>
    printableNumber_starts_with_i?: InputMaybe<Scalars['String']['input']>
    privilege?: InputMaybe<Scalars['String']['input']>
    privilege_gt?: InputMaybe<Scalars['String']['input']>
    privilege_gte?: InputMaybe<Scalars['String']['input']>
    privilege_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    privilege_lt?: InputMaybe<Scalars['String']['input']>
    privilege_lte?: InputMaybe<Scalars['String']['input']>
    privilege_not?: InputMaybe<Scalars['String']['input']>
    privilege_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    property?: InputMaybe<Scalars['String']['input']>
    property_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    property_not?: InputMaybe<Scalars['String']['input']>
    property_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    raw?: InputMaybe<Scalars['JSON']['input']>
    raw_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    raw_not?: InputMaybe<Scalars['JSON']['input']>
    raw_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    recalculation?: InputMaybe<Scalars['String']['input']>
    recalculation_gt?: InputMaybe<Scalars['String']['input']>
    recalculation_gte?: InputMaybe<Scalars['String']['input']>
    recalculation_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    recalculation_lt?: InputMaybe<Scalars['String']['input']>
    recalculation_lte?: InputMaybe<Scalars['String']['input']>
    recalculation_not?: InputMaybe<Scalars['String']['input']>
    recalculation_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    receiver?: InputMaybe<Scalars['String']['input']>
    receiver_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    receiver_not?: InputMaybe<Scalars['String']['input']>
    receiver_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    recipient?: InputMaybe<Scalars['JSON']['input']>
    recipient_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    recipient_not?: InputMaybe<Scalars['JSON']['input']>
    recipient_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    services?: InputMaybe<Scalars['JSON']['input']>
    services_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    services_not?: InputMaybe<Scalars['JSON']['input']>
    services_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    toPay?: InputMaybe<Scalars['String']['input']>
    toPayDetails?: InputMaybe<Scalars['JSON']['input']>
    toPayDetails_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    toPayDetails_not?: InputMaybe<Scalars['JSON']['input']>
    toPayDetails_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    toPay_gt?: InputMaybe<Scalars['String']['input']>
    toPay_gte?: InputMaybe<Scalars['String']['input']>
    toPay_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    toPay_lt?: InputMaybe<Scalars['String']['input']>
    toPay_lte?: InputMaybe<Scalars['String']['input']>
    toPay_not?: InputMaybe<Scalars['String']['input']>
    toPay_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type BillingReceiptHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type BillingReceiptHistoryRecordsCreateInput = {
    data?: InputMaybe<BillingReceiptHistoryRecordCreateInput>
}

export type BillingReceiptHistoryRecordsUpdateInput = {
    data?: InputMaybe<BillingReceiptHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type BillingReceiptRelateToOneInput = {
    connect?: InputMaybe<BillingReceiptWhereUniqueInput>
    create?: InputMaybe<BillingReceiptCreateInput>
    disconnect?: InputMaybe<BillingReceiptWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type BillingReceiptServiceField = {
    __typename?: 'BillingReceiptServiceField'
    id?: Maybe<Scalars['String']['output']>
    name: Scalars['String']['output']
    toPay: Scalars['String']['output']
    toPayDetails?: Maybe<BillingReceiptServiceToPayDetailsField>
}

export type BillingReceiptServiceFieldInput = {
    id?: InputMaybe<Scalars['String']['input']>
    name: Scalars['String']['input']
    toPay: Scalars['String']['input']
    toPayDetails?: InputMaybe<BillingReceiptServiceToPayDetailsFieldInput>
}

export type BillingReceiptServiceToPayDetailsField = {
    __typename?: 'BillingReceiptServiceToPayDetailsField'
    balance?: Maybe<Scalars['String']['output']>
    charge?: Maybe<Scalars['String']['output']>
    formula?: Maybe<Scalars['String']['output']>
    measure?: Maybe<Scalars['String']['output']>
    paid?: Maybe<Scalars['String']['output']>
    penalty?: Maybe<Scalars['String']['output']>
    privilege?: Maybe<Scalars['String']['output']>
    recalculation?: Maybe<Scalars['String']['output']>
    tariff?: Maybe<Scalars['String']['output']>
    volume?: Maybe<Scalars['String']['output']>
}

export type BillingReceiptServiceToPayDetailsFieldInput = {
    balance?: InputMaybe<Scalars['String']['input']>
    charge?: InputMaybe<Scalars['String']['input']>
    formula?: InputMaybe<Scalars['String']['input']>
    measure?: InputMaybe<Scalars['String']['input']>
    paid?: InputMaybe<Scalars['String']['input']>
    penalty?: InputMaybe<Scalars['String']['input']>
    privilege?: InputMaybe<Scalars['String']['input']>
    recalculation?: InputMaybe<Scalars['String']['input']>
    tariff?: InputMaybe<Scalars['String']['input']>
    volume?: InputMaybe<Scalars['String']['input']>
}

export type BillingReceiptToPayDetailsField = {
    __typename?: 'BillingReceiptToPayDetailsField'
    balance?: Maybe<Scalars['String']['output']>
    charge?: Maybe<Scalars['String']['output']>
    formula?: Maybe<Scalars['String']['output']>
    paid?: Maybe<Scalars['String']['output']>
    penalty?: Maybe<Scalars['String']['output']>
    privilege?: Maybe<Scalars['String']['output']>
    recalculation?: Maybe<Scalars['String']['output']>
}

export type BillingReceiptToPayDetailsFieldInput = {
    balance?: InputMaybe<Scalars['String']['input']>
    charge?: InputMaybe<Scalars['String']['input']>
    formula?: InputMaybe<Scalars['String']['input']>
    paid?: InputMaybe<Scalars['String']['input']>
    penalty?: InputMaybe<Scalars['String']['input']>
    privilege?: InputMaybe<Scalars['String']['input']>
    recalculation?: InputMaybe<Scalars['String']['input']>
}

export type BillingReceiptUpdateInput = {
    account?: InputMaybe<BillingAccountRelateToOneInput>
    balance?: InputMaybe<Scalars['String']['input']>
    category?: InputMaybe<BillingCategoryRelateToOneInput>
    charge?: InputMaybe<Scalars['String']['input']>
    context?: InputMaybe<BillingIntegrationOrganizationContextRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<BillingReceiptFileRelateToOneInput>
    formula?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    paid?: InputMaybe<Scalars['String']['input']>
    penalty?: InputMaybe<Scalars['String']['input']>
    period?: InputMaybe<Scalars['String']['input']>
    printableNumber?: InputMaybe<Scalars['String']['input']>
    privilege?: InputMaybe<Scalars['String']['input']>
    property?: InputMaybe<BillingPropertyRelateToOneInput>
    raw?: InputMaybe<Scalars['JSON']['input']>
    recalculation?: InputMaybe<Scalars['String']['input']>
    receiver?: InputMaybe<BillingRecipientRelateToOneInput>
    recipient?: InputMaybe<BillingReceiptsRecipientFieldInput>
    sender?: InputMaybe<SenderFieldInput>
    services?: InputMaybe<Array<BillingReceiptServiceFieldInput>>
    toPay?: InputMaybe<Scalars['String']['input']>
    toPayDetails?: InputMaybe<BillingReceiptToPayDetailsFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type BillingReceiptWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<BillingReceiptWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<BillingReceiptWhereInput>>>
    account?: InputMaybe<BillingAccountWhereInput>
    account_is_null?: InputMaybe<Scalars['Boolean']['input']>
    balance?: InputMaybe<Scalars['String']['input']>
    balance_gt?: InputMaybe<Scalars['String']['input']>
    balance_gte?: InputMaybe<Scalars['String']['input']>
    balance_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    balance_lt?: InputMaybe<Scalars['String']['input']>
    balance_lte?: InputMaybe<Scalars['String']['input']>
    balance_not?: InputMaybe<Scalars['String']['input']>
    balance_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    category?: InputMaybe<BillingCategoryWhereInput>
    category_is_null?: InputMaybe<Scalars['Boolean']['input']>
    charge?: InputMaybe<Scalars['String']['input']>
    charge_gt?: InputMaybe<Scalars['String']['input']>
    charge_gte?: InputMaybe<Scalars['String']['input']>
    charge_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    charge_lt?: InputMaybe<Scalars['String']['input']>
    charge_lte?: InputMaybe<Scalars['String']['input']>
    charge_not?: InputMaybe<Scalars['String']['input']>
    charge_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    context?: InputMaybe<BillingIntegrationOrganizationContextWhereInput>
    context_is_null?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    file?: InputMaybe<BillingReceiptFileWhereInput>
    file_is_null?: InputMaybe<Scalars['Boolean']['input']>
    formula?: InputMaybe<Scalars['String']['input']>
    formula_contains?: InputMaybe<Scalars['String']['input']>
    formula_contains_i?: InputMaybe<Scalars['String']['input']>
    formula_ends_with?: InputMaybe<Scalars['String']['input']>
    formula_ends_with_i?: InputMaybe<Scalars['String']['input']>
    formula_i?: InputMaybe<Scalars['String']['input']>
    formula_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    formula_not?: InputMaybe<Scalars['String']['input']>
    formula_not_contains?: InputMaybe<Scalars['String']['input']>
    formula_not_contains_i?: InputMaybe<Scalars['String']['input']>
    formula_not_ends_with?: InputMaybe<Scalars['String']['input']>
    formula_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    formula_not_i?: InputMaybe<Scalars['String']['input']>
    formula_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    formula_not_starts_with?: InputMaybe<Scalars['String']['input']>
    formula_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    formula_starts_with?: InputMaybe<Scalars['String']['input']>
    formula_starts_with_i?: InputMaybe<Scalars['String']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    importId?: InputMaybe<Scalars['String']['input']>
    importId_contains?: InputMaybe<Scalars['String']['input']>
    importId_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_i?: InputMaybe<Scalars['String']['input']>
    importId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not?: InputMaybe<Scalars['String']['input']>
    importId_not_contains?: InputMaybe<Scalars['String']['input']>
    importId_not_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_not_i?: InputMaybe<Scalars['String']['input']>
    importId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importId_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    paid?: InputMaybe<Scalars['String']['input']>
    paid_gt?: InputMaybe<Scalars['String']['input']>
    paid_gte?: InputMaybe<Scalars['String']['input']>
    paid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    paid_lt?: InputMaybe<Scalars['String']['input']>
    paid_lte?: InputMaybe<Scalars['String']['input']>
    paid_not?: InputMaybe<Scalars['String']['input']>
    paid_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    penalty?: InputMaybe<Scalars['String']['input']>
    penalty_gt?: InputMaybe<Scalars['String']['input']>
    penalty_gte?: InputMaybe<Scalars['String']['input']>
    penalty_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    penalty_lt?: InputMaybe<Scalars['String']['input']>
    penalty_lte?: InputMaybe<Scalars['String']['input']>
    penalty_not?: InputMaybe<Scalars['String']['input']>
    penalty_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    period?: InputMaybe<Scalars['String']['input']>
    period_gt?: InputMaybe<Scalars['String']['input']>
    period_gte?: InputMaybe<Scalars['String']['input']>
    period_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    period_lt?: InputMaybe<Scalars['String']['input']>
    period_lte?: InputMaybe<Scalars['String']['input']>
    period_not?: InputMaybe<Scalars['String']['input']>
    period_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    printableNumber?: InputMaybe<Scalars['String']['input']>
    printableNumber_contains?: InputMaybe<Scalars['String']['input']>
    printableNumber_contains_i?: InputMaybe<Scalars['String']['input']>
    printableNumber_ends_with?: InputMaybe<Scalars['String']['input']>
    printableNumber_ends_with_i?: InputMaybe<Scalars['String']['input']>
    printableNumber_i?: InputMaybe<Scalars['String']['input']>
    printableNumber_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    printableNumber_not?: InputMaybe<Scalars['String']['input']>
    printableNumber_not_contains?: InputMaybe<Scalars['String']['input']>
    printableNumber_not_contains_i?: InputMaybe<Scalars['String']['input']>
    printableNumber_not_ends_with?: InputMaybe<Scalars['String']['input']>
    printableNumber_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    printableNumber_not_i?: InputMaybe<Scalars['String']['input']>
    printableNumber_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    printableNumber_not_starts_with?: InputMaybe<Scalars['String']['input']>
    printableNumber_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    printableNumber_starts_with?: InputMaybe<Scalars['String']['input']>
    printableNumber_starts_with_i?: InputMaybe<Scalars['String']['input']>
    privilege?: InputMaybe<Scalars['String']['input']>
    privilege_gt?: InputMaybe<Scalars['String']['input']>
    privilege_gte?: InputMaybe<Scalars['String']['input']>
    privilege_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    privilege_lt?: InputMaybe<Scalars['String']['input']>
    privilege_lte?: InputMaybe<Scalars['String']['input']>
    privilege_not?: InputMaybe<Scalars['String']['input']>
    privilege_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    property?: InputMaybe<BillingPropertyWhereInput>
    property_is_null?: InputMaybe<Scalars['Boolean']['input']>
    raw?: InputMaybe<Scalars['JSON']['input']>
    raw_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    raw_not?: InputMaybe<Scalars['JSON']['input']>
    raw_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    recalculation?: InputMaybe<Scalars['String']['input']>
    recalculation_gt?: InputMaybe<Scalars['String']['input']>
    recalculation_gte?: InputMaybe<Scalars['String']['input']>
    recalculation_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    recalculation_lt?: InputMaybe<Scalars['String']['input']>
    recalculation_lte?: InputMaybe<Scalars['String']['input']>
    recalculation_not?: InputMaybe<Scalars['String']['input']>
    recalculation_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    receiver?: InputMaybe<BillingRecipientWhereInput>
    receiver_is_null?: InputMaybe<Scalars['Boolean']['input']>
    recipient?: InputMaybe<BillingReceiptsRecipientFieldInput>
    recipient_in?: InputMaybe<Array<InputMaybe<BillingReceiptsRecipientFieldInput>>>
    recipient_not?: InputMaybe<BillingReceiptsRecipientFieldInput>
    recipient_not_in?: InputMaybe<Array<InputMaybe<BillingReceiptsRecipientFieldInput>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    services?: InputMaybe<Array<BillingReceiptServiceFieldInput>>
    services_in?: InputMaybe<Array<InputMaybe<Array<BillingReceiptServiceFieldInput>>>>
    services_not?: InputMaybe<Array<BillingReceiptServiceFieldInput>>
    services_not_in?: InputMaybe<Array<InputMaybe<Array<BillingReceiptServiceFieldInput>>>>
    toPay?: InputMaybe<Scalars['String']['input']>
    toPayDetails?: InputMaybe<BillingReceiptToPayDetailsFieldInput>
    toPayDetails_in?: InputMaybe<Array<InputMaybe<BillingReceiptToPayDetailsFieldInput>>>
    toPayDetails_not?: InputMaybe<BillingReceiptToPayDetailsFieldInput>
    toPayDetails_not_in?: InputMaybe<Array<InputMaybe<BillingReceiptToPayDetailsFieldInput>>>
    toPay_gt?: InputMaybe<Scalars['String']['input']>
    toPay_gte?: InputMaybe<Scalars['String']['input']>
    toPay_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    toPay_lt?: InputMaybe<Scalars['String']['input']>
    toPay_lte?: InputMaybe<Scalars['String']['input']>
    toPay_not?: InputMaybe<Scalars['String']['input']>
    toPay_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type BillingReceiptWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type BillingReceiptsCreateInput = {
    data?: InputMaybe<BillingReceiptCreateInput>
}

export type BillingReceiptsField = {
    __typename?: 'BillingReceiptsField'
    id: Scalars['String']['output']
}

export type BillingReceiptsFieldInput = {
    id: Scalars['String']['input']
}

export type BillingReceiptsRecipientField = {
    __typename?: 'BillingReceiptsRecipientField'
    bankAccount: Scalars['String']['output']
    bankName?: Maybe<Scalars['String']['output']>
    bic: Scalars['String']['output']
    classificationCode?: Maybe<Scalars['String']['output']>
    iec?: Maybe<Scalars['String']['output']>
    name?: Maybe<Scalars['String']['output']>
    offsettingAccount?: Maybe<Scalars['String']['output']>
    territoryCode?: Maybe<Scalars['String']['output']>
    tin: Scalars['String']['output']
}

export type BillingReceiptsRecipientFieldInput = {
    bankAccount: Scalars['String']['input']
    bankName?: InputMaybe<Scalars['String']['input']>
    bic: Scalars['String']['input']
    classificationCode?: InputMaybe<Scalars['String']['input']>
    iec?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    offsettingAccount?: InputMaybe<Scalars['String']['input']>
    territoryCode?: InputMaybe<Scalars['String']['input']>
    tin: Scalars['String']['input']
}

export type BillingReceiptsSumInput = {
    importRemoteSystem?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationWhereUniqueInput>
    period: Scalars['String']['input']
    tin?: InputMaybe<Scalars['String']['input']>
}

export type BillingReceiptsSumOutput = {
    __typename?: 'BillingReceiptsSumOutput'
    sum: Scalars['String']['output']
}

export type BillingReceiptsUpdateInput = {
    data?: InputMaybe<BillingReceiptUpdateInput>
    id: Scalars['ID']['input']
}

/**  Organization' billing information: bank account, bic, and so on  */
export type BillingRecipient = {
    __typename?: 'BillingRecipient'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BillingRecipient List config, or
   *  2. As an alias to the field set on 'labelField' in the BillingRecipient List config, or
   *  3. As an alias to a 'name' field on the BillingRecipient List (if one exists), or
   *  4. As an alias to the 'id' field on the BillingRecipient List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  Number of bank account of this recipient  */
    bankAccount?: Maybe<Scalars['String']['output']>
    /**  Bank name  */
    bankName?: Maybe<Scalars['String']['output']>
    /**  Bank Identification Code  */
    bic?: Maybe<Scalars['String']['output']>
    /**  Budget classification code, used for state-funded organizations  */
    classificationCode?: Maybe<Scalars['String']['output']>
    /**  Integration context  */
    context?: Maybe<BillingIntegrationOrganizationContext>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  Importer-Exporter Code  */
    iec?: Maybe<Scalars['String']['output']>
    /**  Id of object in external service which represents current item. Mostly used for internal needs of integration services for matching our objects with theirs  */
    importId?: Maybe<Scalars['String']['output']>
    /**  If set to True, then this billing recipient info is considered allowed and users are allowed to pay for receipts with this recipient  */
    isApproved?: Maybe<Scalars['Boolean']['output']>
    /**  Structured metadata obtained from the `billing data source`. The structure depends on the integration system.  */
    meta?: Maybe<Scalars['JSON']['output']>
    /**  Billing Recipient name. Usually the juristic name of the organization  */
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Bank account  */
    offsettingAccount?: Maybe<Scalars['String']['output']>
    /**  For what reason is this recipient is able to collect payments.  */
    purpose?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Location code (Classifier of Territories of Municipal Units - OKTMO)  */
    territoryCode?: Maybe<Scalars['String']['output']>
    /**  Tax Identification Number  */
    tin?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type BillingRecipientCreateInput = {
    bankAccount?: InputMaybe<Scalars['String']['input']>
    bankName?: InputMaybe<Scalars['String']['input']>
    bic?: InputMaybe<Scalars['String']['input']>
    classificationCode?: InputMaybe<Scalars['String']['input']>
    context?: InputMaybe<BillingIntegrationOrganizationContextRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    iec?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    offsettingAccount?: InputMaybe<Scalars['String']['input']>
    purpose?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    territoryCode?: InputMaybe<Scalars['String']['input']>
    tin?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type BillingRecipientHistoryRecord = {
    __typename?: 'BillingRecipientHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BillingRecipientHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the BillingRecipientHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the BillingRecipientHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the BillingRecipientHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    bankAccount?: Maybe<Scalars['String']['output']>
    bankName?: Maybe<Scalars['String']['output']>
    bic?: Maybe<Scalars['String']['output']>
    classificationCode?: Maybe<Scalars['String']['output']>
    context?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<BillingRecipientHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    iec?: Maybe<Scalars['String']['output']>
    importId?: Maybe<Scalars['String']['output']>
    meta?: Maybe<Scalars['JSON']['output']>
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    offsettingAccount?: Maybe<Scalars['String']['output']>
    purpose?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    territoryCode?: Maybe<Scalars['String']['output']>
    tin?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type BillingRecipientHistoryRecordCreateInput = {
    bankAccount?: InputMaybe<Scalars['String']['input']>
    bankName?: InputMaybe<Scalars['String']['input']>
    bic?: InputMaybe<Scalars['String']['input']>
    classificationCode?: InputMaybe<Scalars['String']['input']>
    context?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<BillingRecipientHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    iec?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    offsettingAccount?: InputMaybe<Scalars['String']['input']>
    purpose?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    territoryCode?: InputMaybe<Scalars['String']['input']>
    tin?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum BillingRecipientHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type BillingRecipientHistoryRecordUpdateInput = {
    bankAccount?: InputMaybe<Scalars['String']['input']>
    bankName?: InputMaybe<Scalars['String']['input']>
    bic?: InputMaybe<Scalars['String']['input']>
    classificationCode?: InputMaybe<Scalars['String']['input']>
    context?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<BillingRecipientHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    iec?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    offsettingAccount?: InputMaybe<Scalars['String']['input']>
    purpose?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    territoryCode?: InputMaybe<Scalars['String']['input']>
    tin?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type BillingRecipientHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<BillingRecipientHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<BillingRecipientHistoryRecordWhereInput>>>
    bankAccount?: InputMaybe<Scalars['String']['input']>
    bankAccount_contains?: InputMaybe<Scalars['String']['input']>
    bankAccount_contains_i?: InputMaybe<Scalars['String']['input']>
    bankAccount_ends_with?: InputMaybe<Scalars['String']['input']>
    bankAccount_ends_with_i?: InputMaybe<Scalars['String']['input']>
    bankAccount_i?: InputMaybe<Scalars['String']['input']>
    bankAccount_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    bankAccount_not?: InputMaybe<Scalars['String']['input']>
    bankAccount_not_contains?: InputMaybe<Scalars['String']['input']>
    bankAccount_not_contains_i?: InputMaybe<Scalars['String']['input']>
    bankAccount_not_ends_with?: InputMaybe<Scalars['String']['input']>
    bankAccount_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    bankAccount_not_i?: InputMaybe<Scalars['String']['input']>
    bankAccount_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    bankAccount_not_starts_with?: InputMaybe<Scalars['String']['input']>
    bankAccount_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    bankAccount_starts_with?: InputMaybe<Scalars['String']['input']>
    bankAccount_starts_with_i?: InputMaybe<Scalars['String']['input']>
    bankName?: InputMaybe<Scalars['String']['input']>
    bankName_contains?: InputMaybe<Scalars['String']['input']>
    bankName_contains_i?: InputMaybe<Scalars['String']['input']>
    bankName_ends_with?: InputMaybe<Scalars['String']['input']>
    bankName_ends_with_i?: InputMaybe<Scalars['String']['input']>
    bankName_i?: InputMaybe<Scalars['String']['input']>
    bankName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    bankName_not?: InputMaybe<Scalars['String']['input']>
    bankName_not_contains?: InputMaybe<Scalars['String']['input']>
    bankName_not_contains_i?: InputMaybe<Scalars['String']['input']>
    bankName_not_ends_with?: InputMaybe<Scalars['String']['input']>
    bankName_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    bankName_not_i?: InputMaybe<Scalars['String']['input']>
    bankName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    bankName_not_starts_with?: InputMaybe<Scalars['String']['input']>
    bankName_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    bankName_starts_with?: InputMaybe<Scalars['String']['input']>
    bankName_starts_with_i?: InputMaybe<Scalars['String']['input']>
    bic?: InputMaybe<Scalars['String']['input']>
    bic_contains?: InputMaybe<Scalars['String']['input']>
    bic_contains_i?: InputMaybe<Scalars['String']['input']>
    bic_ends_with?: InputMaybe<Scalars['String']['input']>
    bic_ends_with_i?: InputMaybe<Scalars['String']['input']>
    bic_i?: InputMaybe<Scalars['String']['input']>
    bic_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    bic_not?: InputMaybe<Scalars['String']['input']>
    bic_not_contains?: InputMaybe<Scalars['String']['input']>
    bic_not_contains_i?: InputMaybe<Scalars['String']['input']>
    bic_not_ends_with?: InputMaybe<Scalars['String']['input']>
    bic_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    bic_not_i?: InputMaybe<Scalars['String']['input']>
    bic_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    bic_not_starts_with?: InputMaybe<Scalars['String']['input']>
    bic_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    bic_starts_with?: InputMaybe<Scalars['String']['input']>
    bic_starts_with_i?: InputMaybe<Scalars['String']['input']>
    classificationCode?: InputMaybe<Scalars['String']['input']>
    classificationCode_contains?: InputMaybe<Scalars['String']['input']>
    classificationCode_contains_i?: InputMaybe<Scalars['String']['input']>
    classificationCode_ends_with?: InputMaybe<Scalars['String']['input']>
    classificationCode_ends_with_i?: InputMaybe<Scalars['String']['input']>
    classificationCode_i?: InputMaybe<Scalars['String']['input']>
    classificationCode_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    classificationCode_not?: InputMaybe<Scalars['String']['input']>
    classificationCode_not_contains?: InputMaybe<Scalars['String']['input']>
    classificationCode_not_contains_i?: InputMaybe<Scalars['String']['input']>
    classificationCode_not_ends_with?: InputMaybe<Scalars['String']['input']>
    classificationCode_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    classificationCode_not_i?: InputMaybe<Scalars['String']['input']>
    classificationCode_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    classificationCode_not_starts_with?: InputMaybe<Scalars['String']['input']>
    classificationCode_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    classificationCode_starts_with?: InputMaybe<Scalars['String']['input']>
    classificationCode_starts_with_i?: InputMaybe<Scalars['String']['input']>
    context?: InputMaybe<Scalars['String']['input']>
    context_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    context_not?: InputMaybe<Scalars['String']['input']>
    context_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<BillingRecipientHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<BillingRecipientHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<BillingRecipientHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<BillingRecipientHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    iec?: InputMaybe<Scalars['String']['input']>
    iec_contains?: InputMaybe<Scalars['String']['input']>
    iec_contains_i?: InputMaybe<Scalars['String']['input']>
    iec_ends_with?: InputMaybe<Scalars['String']['input']>
    iec_ends_with_i?: InputMaybe<Scalars['String']['input']>
    iec_i?: InputMaybe<Scalars['String']['input']>
    iec_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    iec_not?: InputMaybe<Scalars['String']['input']>
    iec_not_contains?: InputMaybe<Scalars['String']['input']>
    iec_not_contains_i?: InputMaybe<Scalars['String']['input']>
    iec_not_ends_with?: InputMaybe<Scalars['String']['input']>
    iec_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    iec_not_i?: InputMaybe<Scalars['String']['input']>
    iec_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    iec_not_starts_with?: InputMaybe<Scalars['String']['input']>
    iec_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    iec_starts_with?: InputMaybe<Scalars['String']['input']>
    iec_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    importId_contains?: InputMaybe<Scalars['String']['input']>
    importId_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_i?: InputMaybe<Scalars['String']['input']>
    importId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not?: InputMaybe<Scalars['String']['input']>
    importId_not_contains?: InputMaybe<Scalars['String']['input']>
    importId_not_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_not_i?: InputMaybe<Scalars['String']['input']>
    importId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importId_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_starts_with_i?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    offsettingAccount?: InputMaybe<Scalars['String']['input']>
    offsettingAccount_contains?: InputMaybe<Scalars['String']['input']>
    offsettingAccount_contains_i?: InputMaybe<Scalars['String']['input']>
    offsettingAccount_ends_with?: InputMaybe<Scalars['String']['input']>
    offsettingAccount_ends_with_i?: InputMaybe<Scalars['String']['input']>
    offsettingAccount_i?: InputMaybe<Scalars['String']['input']>
    offsettingAccount_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    offsettingAccount_not?: InputMaybe<Scalars['String']['input']>
    offsettingAccount_not_contains?: InputMaybe<Scalars['String']['input']>
    offsettingAccount_not_contains_i?: InputMaybe<Scalars['String']['input']>
    offsettingAccount_not_ends_with?: InputMaybe<Scalars['String']['input']>
    offsettingAccount_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    offsettingAccount_not_i?: InputMaybe<Scalars['String']['input']>
    offsettingAccount_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    offsettingAccount_not_starts_with?: InputMaybe<Scalars['String']['input']>
    offsettingAccount_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    offsettingAccount_starts_with?: InputMaybe<Scalars['String']['input']>
    offsettingAccount_starts_with_i?: InputMaybe<Scalars['String']['input']>
    purpose?: InputMaybe<Scalars['String']['input']>
    purpose_contains?: InputMaybe<Scalars['String']['input']>
    purpose_contains_i?: InputMaybe<Scalars['String']['input']>
    purpose_ends_with?: InputMaybe<Scalars['String']['input']>
    purpose_ends_with_i?: InputMaybe<Scalars['String']['input']>
    purpose_i?: InputMaybe<Scalars['String']['input']>
    purpose_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    purpose_not?: InputMaybe<Scalars['String']['input']>
    purpose_not_contains?: InputMaybe<Scalars['String']['input']>
    purpose_not_contains_i?: InputMaybe<Scalars['String']['input']>
    purpose_not_ends_with?: InputMaybe<Scalars['String']['input']>
    purpose_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    purpose_not_i?: InputMaybe<Scalars['String']['input']>
    purpose_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    purpose_not_starts_with?: InputMaybe<Scalars['String']['input']>
    purpose_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    purpose_starts_with?: InputMaybe<Scalars['String']['input']>
    purpose_starts_with_i?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    territoryCode?: InputMaybe<Scalars['String']['input']>
    territoryCode_contains?: InputMaybe<Scalars['String']['input']>
    territoryCode_contains_i?: InputMaybe<Scalars['String']['input']>
    territoryCode_ends_with?: InputMaybe<Scalars['String']['input']>
    territoryCode_ends_with_i?: InputMaybe<Scalars['String']['input']>
    territoryCode_i?: InputMaybe<Scalars['String']['input']>
    territoryCode_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    territoryCode_not?: InputMaybe<Scalars['String']['input']>
    territoryCode_not_contains?: InputMaybe<Scalars['String']['input']>
    territoryCode_not_contains_i?: InputMaybe<Scalars['String']['input']>
    territoryCode_not_ends_with?: InputMaybe<Scalars['String']['input']>
    territoryCode_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    territoryCode_not_i?: InputMaybe<Scalars['String']['input']>
    territoryCode_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    territoryCode_not_starts_with?: InputMaybe<Scalars['String']['input']>
    territoryCode_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    territoryCode_starts_with?: InputMaybe<Scalars['String']['input']>
    territoryCode_starts_with_i?: InputMaybe<Scalars['String']['input']>
    tin?: InputMaybe<Scalars['String']['input']>
    tin_contains?: InputMaybe<Scalars['String']['input']>
    tin_contains_i?: InputMaybe<Scalars['String']['input']>
    tin_ends_with?: InputMaybe<Scalars['String']['input']>
    tin_ends_with_i?: InputMaybe<Scalars['String']['input']>
    tin_i?: InputMaybe<Scalars['String']['input']>
    tin_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    tin_not?: InputMaybe<Scalars['String']['input']>
    tin_not_contains?: InputMaybe<Scalars['String']['input']>
    tin_not_contains_i?: InputMaybe<Scalars['String']['input']>
    tin_not_ends_with?: InputMaybe<Scalars['String']['input']>
    tin_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    tin_not_i?: InputMaybe<Scalars['String']['input']>
    tin_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    tin_not_starts_with?: InputMaybe<Scalars['String']['input']>
    tin_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    tin_starts_with?: InputMaybe<Scalars['String']['input']>
    tin_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type BillingRecipientHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type BillingRecipientHistoryRecordsCreateInput = {
    data?: InputMaybe<BillingRecipientHistoryRecordCreateInput>
}

export type BillingRecipientHistoryRecordsUpdateInput = {
    data?: InputMaybe<BillingRecipientHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type BillingRecipientRelateToOneInput = {
    connect?: InputMaybe<BillingRecipientWhereUniqueInput>
    create?: InputMaybe<BillingRecipientCreateInput>
    disconnect?: InputMaybe<BillingRecipientWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type BillingRecipientUpdateInput = {
    bankAccount?: InputMaybe<Scalars['String']['input']>
    bankName?: InputMaybe<Scalars['String']['input']>
    bic?: InputMaybe<Scalars['String']['input']>
    classificationCode?: InputMaybe<Scalars['String']['input']>
    context?: InputMaybe<BillingIntegrationOrganizationContextRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    iec?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    offsettingAccount?: InputMaybe<Scalars['String']['input']>
    purpose?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    territoryCode?: InputMaybe<Scalars['String']['input']>
    tin?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type BillingRecipientWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<BillingRecipientWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<BillingRecipientWhereInput>>>
    bankAccount?: InputMaybe<Scalars['String']['input']>
    bankAccount_contains?: InputMaybe<Scalars['String']['input']>
    bankAccount_contains_i?: InputMaybe<Scalars['String']['input']>
    bankAccount_ends_with?: InputMaybe<Scalars['String']['input']>
    bankAccount_ends_with_i?: InputMaybe<Scalars['String']['input']>
    bankAccount_i?: InputMaybe<Scalars['String']['input']>
    bankAccount_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    bankAccount_not?: InputMaybe<Scalars['String']['input']>
    bankAccount_not_contains?: InputMaybe<Scalars['String']['input']>
    bankAccount_not_contains_i?: InputMaybe<Scalars['String']['input']>
    bankAccount_not_ends_with?: InputMaybe<Scalars['String']['input']>
    bankAccount_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    bankAccount_not_i?: InputMaybe<Scalars['String']['input']>
    bankAccount_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    bankAccount_not_starts_with?: InputMaybe<Scalars['String']['input']>
    bankAccount_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    bankAccount_starts_with?: InputMaybe<Scalars['String']['input']>
    bankAccount_starts_with_i?: InputMaybe<Scalars['String']['input']>
    bankName?: InputMaybe<Scalars['String']['input']>
    bankName_contains?: InputMaybe<Scalars['String']['input']>
    bankName_contains_i?: InputMaybe<Scalars['String']['input']>
    bankName_ends_with?: InputMaybe<Scalars['String']['input']>
    bankName_ends_with_i?: InputMaybe<Scalars['String']['input']>
    bankName_i?: InputMaybe<Scalars['String']['input']>
    bankName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    bankName_not?: InputMaybe<Scalars['String']['input']>
    bankName_not_contains?: InputMaybe<Scalars['String']['input']>
    bankName_not_contains_i?: InputMaybe<Scalars['String']['input']>
    bankName_not_ends_with?: InputMaybe<Scalars['String']['input']>
    bankName_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    bankName_not_i?: InputMaybe<Scalars['String']['input']>
    bankName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    bankName_not_starts_with?: InputMaybe<Scalars['String']['input']>
    bankName_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    bankName_starts_with?: InputMaybe<Scalars['String']['input']>
    bankName_starts_with_i?: InputMaybe<Scalars['String']['input']>
    bic?: InputMaybe<Scalars['String']['input']>
    bic_contains?: InputMaybe<Scalars['String']['input']>
    bic_contains_i?: InputMaybe<Scalars['String']['input']>
    bic_ends_with?: InputMaybe<Scalars['String']['input']>
    bic_ends_with_i?: InputMaybe<Scalars['String']['input']>
    bic_i?: InputMaybe<Scalars['String']['input']>
    bic_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    bic_not?: InputMaybe<Scalars['String']['input']>
    bic_not_contains?: InputMaybe<Scalars['String']['input']>
    bic_not_contains_i?: InputMaybe<Scalars['String']['input']>
    bic_not_ends_with?: InputMaybe<Scalars['String']['input']>
    bic_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    bic_not_i?: InputMaybe<Scalars['String']['input']>
    bic_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    bic_not_starts_with?: InputMaybe<Scalars['String']['input']>
    bic_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    bic_starts_with?: InputMaybe<Scalars['String']['input']>
    bic_starts_with_i?: InputMaybe<Scalars['String']['input']>
    classificationCode?: InputMaybe<Scalars['String']['input']>
    classificationCode_contains?: InputMaybe<Scalars['String']['input']>
    classificationCode_contains_i?: InputMaybe<Scalars['String']['input']>
    classificationCode_ends_with?: InputMaybe<Scalars['String']['input']>
    classificationCode_ends_with_i?: InputMaybe<Scalars['String']['input']>
    classificationCode_i?: InputMaybe<Scalars['String']['input']>
    classificationCode_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    classificationCode_not?: InputMaybe<Scalars['String']['input']>
    classificationCode_not_contains?: InputMaybe<Scalars['String']['input']>
    classificationCode_not_contains_i?: InputMaybe<Scalars['String']['input']>
    classificationCode_not_ends_with?: InputMaybe<Scalars['String']['input']>
    classificationCode_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    classificationCode_not_i?: InputMaybe<Scalars['String']['input']>
    classificationCode_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    classificationCode_not_starts_with?: InputMaybe<Scalars['String']['input']>
    classificationCode_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    classificationCode_starts_with?: InputMaybe<Scalars['String']['input']>
    classificationCode_starts_with_i?: InputMaybe<Scalars['String']['input']>
    context?: InputMaybe<BillingIntegrationOrganizationContextWhereInput>
    context_is_null?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    iec?: InputMaybe<Scalars['String']['input']>
    iec_contains?: InputMaybe<Scalars['String']['input']>
    iec_contains_i?: InputMaybe<Scalars['String']['input']>
    iec_ends_with?: InputMaybe<Scalars['String']['input']>
    iec_ends_with_i?: InputMaybe<Scalars['String']['input']>
    iec_i?: InputMaybe<Scalars['String']['input']>
    iec_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    iec_not?: InputMaybe<Scalars['String']['input']>
    iec_not_contains?: InputMaybe<Scalars['String']['input']>
    iec_not_contains_i?: InputMaybe<Scalars['String']['input']>
    iec_not_ends_with?: InputMaybe<Scalars['String']['input']>
    iec_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    iec_not_i?: InputMaybe<Scalars['String']['input']>
    iec_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    iec_not_starts_with?: InputMaybe<Scalars['String']['input']>
    iec_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    iec_starts_with?: InputMaybe<Scalars['String']['input']>
    iec_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    importId_contains?: InputMaybe<Scalars['String']['input']>
    importId_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_i?: InputMaybe<Scalars['String']['input']>
    importId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not?: InputMaybe<Scalars['String']['input']>
    importId_not_contains?: InputMaybe<Scalars['String']['input']>
    importId_not_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_not_i?: InputMaybe<Scalars['String']['input']>
    importId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importId_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_starts_with_i?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    offsettingAccount?: InputMaybe<Scalars['String']['input']>
    offsettingAccount_contains?: InputMaybe<Scalars['String']['input']>
    offsettingAccount_contains_i?: InputMaybe<Scalars['String']['input']>
    offsettingAccount_ends_with?: InputMaybe<Scalars['String']['input']>
    offsettingAccount_ends_with_i?: InputMaybe<Scalars['String']['input']>
    offsettingAccount_i?: InputMaybe<Scalars['String']['input']>
    offsettingAccount_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    offsettingAccount_not?: InputMaybe<Scalars['String']['input']>
    offsettingAccount_not_contains?: InputMaybe<Scalars['String']['input']>
    offsettingAccount_not_contains_i?: InputMaybe<Scalars['String']['input']>
    offsettingAccount_not_ends_with?: InputMaybe<Scalars['String']['input']>
    offsettingAccount_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    offsettingAccount_not_i?: InputMaybe<Scalars['String']['input']>
    offsettingAccount_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    offsettingAccount_not_starts_with?: InputMaybe<Scalars['String']['input']>
    offsettingAccount_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    offsettingAccount_starts_with?: InputMaybe<Scalars['String']['input']>
    offsettingAccount_starts_with_i?: InputMaybe<Scalars['String']['input']>
    purpose?: InputMaybe<Scalars['String']['input']>
    purpose_contains?: InputMaybe<Scalars['String']['input']>
    purpose_contains_i?: InputMaybe<Scalars['String']['input']>
    purpose_ends_with?: InputMaybe<Scalars['String']['input']>
    purpose_ends_with_i?: InputMaybe<Scalars['String']['input']>
    purpose_i?: InputMaybe<Scalars['String']['input']>
    purpose_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    purpose_not?: InputMaybe<Scalars['String']['input']>
    purpose_not_contains?: InputMaybe<Scalars['String']['input']>
    purpose_not_contains_i?: InputMaybe<Scalars['String']['input']>
    purpose_not_ends_with?: InputMaybe<Scalars['String']['input']>
    purpose_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    purpose_not_i?: InputMaybe<Scalars['String']['input']>
    purpose_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    purpose_not_starts_with?: InputMaybe<Scalars['String']['input']>
    purpose_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    purpose_starts_with?: InputMaybe<Scalars['String']['input']>
    purpose_starts_with_i?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    territoryCode?: InputMaybe<Scalars['String']['input']>
    territoryCode_contains?: InputMaybe<Scalars['String']['input']>
    territoryCode_contains_i?: InputMaybe<Scalars['String']['input']>
    territoryCode_ends_with?: InputMaybe<Scalars['String']['input']>
    territoryCode_ends_with_i?: InputMaybe<Scalars['String']['input']>
    territoryCode_i?: InputMaybe<Scalars['String']['input']>
    territoryCode_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    territoryCode_not?: InputMaybe<Scalars['String']['input']>
    territoryCode_not_contains?: InputMaybe<Scalars['String']['input']>
    territoryCode_not_contains_i?: InputMaybe<Scalars['String']['input']>
    territoryCode_not_ends_with?: InputMaybe<Scalars['String']['input']>
    territoryCode_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    territoryCode_not_i?: InputMaybe<Scalars['String']['input']>
    territoryCode_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    territoryCode_not_starts_with?: InputMaybe<Scalars['String']['input']>
    territoryCode_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    territoryCode_starts_with?: InputMaybe<Scalars['String']['input']>
    territoryCode_starts_with_i?: InputMaybe<Scalars['String']['input']>
    tin?: InputMaybe<Scalars['String']['input']>
    tin_contains?: InputMaybe<Scalars['String']['input']>
    tin_contains_i?: InputMaybe<Scalars['String']['input']>
    tin_ends_with?: InputMaybe<Scalars['String']['input']>
    tin_ends_with_i?: InputMaybe<Scalars['String']['input']>
    tin_i?: InputMaybe<Scalars['String']['input']>
    tin_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    tin_not?: InputMaybe<Scalars['String']['input']>
    tin_not_contains?: InputMaybe<Scalars['String']['input']>
    tin_not_contains_i?: InputMaybe<Scalars['String']['input']>
    tin_not_ends_with?: InputMaybe<Scalars['String']['input']>
    tin_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    tin_not_i?: InputMaybe<Scalars['String']['input']>
    tin_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    tin_not_starts_with?: InputMaybe<Scalars['String']['input']>
    tin_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    tin_starts_with?: InputMaybe<Scalars['String']['input']>
    tin_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type BillingRecipientWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type BillingRecipientsCreateInput = {
    data?: InputMaybe<BillingRecipientCreateInput>
}

export type BillingRecipientsUpdateInput = {
    data?: InputMaybe<BillingRecipientUpdateInput>
    id: Scalars['ID']['input']
}

export type BuildingFloor = {
    __typename?: 'BuildingFloor'
    id: Scalars['String']['output']
    index: Scalars['Int']['output']
    name: Scalars['String']['output']
    type: BuildingFloorType
    units: Array<Maybe<BuildingUnit>>
}

export enum BuildingFloorType {
    Floor = 'floor',
}

/** Technical map of the 'building' type Property object. We assume that there will be different maps for different property types.  */
export type BuildingMap = {
    __typename?: 'BuildingMap'
    dv: Scalars['Int']['output']
    parking?: Maybe<Array<Maybe<BuildingSection>>>
    sections?: Maybe<Array<Maybe<BuildingSection>>>
    type?: Maybe<BuildingMapType>
}

export enum BuildingMapEntityType {
    Building = 'building',
    Floor = 'floor',
    Section = 'section',
    Unit = 'unit',
    Village = 'village',
}

export enum BuildingMapType {
    Building = 'building',
}

export type BuildingSection = {
    __typename?: 'BuildingSection'
    floors: Array<Maybe<BuildingFloor>>
    id: Scalars['String']['output']
    index: Scalars['Int']['output']
    name: Scalars['String']['output']
    preview?: Maybe<Scalars['Boolean']['output']>
    type: BuildingSectionType
}

export enum BuildingSectionType {
    Section = 'section',
}

export type BuildingUnit = {
    __typename?: 'BuildingUnit'
    id: Scalars['String']['output']
    label: Scalars['String']['output']
    name?: Maybe<Scalars['String']['output']>
    preview?: Maybe<Scalars['Boolean']['output']>
    type: BuildingUnitType
    unitType?: Maybe<BuildingUnitSubType>
}

export enum BuildingUnitSubType {
    Apartment = 'apartment',
    Commercial = 'commercial',
    Flat = 'flat',
    Parking = 'parking',
    Warehouse = 'warehouse',
}

export enum BuildingUnitType {
    Unit = 'unit',
}

export enum CacheControlScope {
    Private = 'PRIVATE',
    Public = 'PUBLIC',
}

export type CalculateFeeForReceiptInput = {
    amount: Scalars['String']['input']
    receipt: BillingReceiptWhereUniqueInput
}

export type CalculateFeeForReceiptOutput = {
    __typename?: 'CalculateFeeForReceiptOutput'
    amountWithoutExplicitFee: Scalars['String']['output']
    explicitFee: Scalars['String']['output']
    explicitServiceCharge: Scalars['String']['output']
}

/**  Conversation record between operator and client  */
export type CallRecord = {
    __typename?: 'CallRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the CallRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the CallRecord List config, or
   *  3. As an alias to a 'name' field on the CallRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the CallRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  Phone number of the person who called  */
    callerPhone?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Phone number of the person to whom called  */
    destCallerPhone?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  Conversation file  */
    file?: Maybe<File>
    id: Scalars['ID']['output']
    /**  Call unique identifier  */
    importId?: Maybe<Scalars['String']['output']>
    /**  Incoming or outgoing call for operator  */
    isIncomingCall?: Maybe<Scalars['Boolean']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Organization of the operator  */
    organization?: Maybe<Organization>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Call start date in UTC  */
    startedAt?: Maybe<Scalars['String']['output']>
    /**  Time of conversation between operator and client (in seconds)  */
    talkTime?: Maybe<Scalars['Int']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type CallRecordCreateInput = {
    callerPhone?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    destCallerPhone?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['Upload']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    isIncomingCall?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    startedAt?: InputMaybe<Scalars['String']['input']>
    talkTime?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  M2M relationship between ticket and call record  */
export type CallRecordFragment = {
    __typename?: 'CallRecordFragment'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the CallRecordFragment List config, or
   *  2. As an alias to the field set on 'labelField' in the CallRecordFragment List config, or
   *  3. As an alias to a 'name' field on the CallRecordFragment List (if one exists), or
   *  4. As an alias to the 'id' field on the CallRecordFragment List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  Call record to which this call fragment belongs  */
    callRecord?: Maybe<CallRecord>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['String']['output']>
    /**  Ref to the organization. It is filled in on the server and is read-only  */
    organization?: Maybe<Organization>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Call fragment start date in UTC  */
    startedAt?: Maybe<Scalars['String']['output']>
    /**  Ticket to which this call fragment belongs  */
    ticket?: Maybe<Ticket>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type CallRecordFragmentCreateInput = {
    callRecord?: InputMaybe<CallRecordRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    startedAt?: InputMaybe<Scalars['String']['input']>
    ticket?: InputMaybe<TicketRelateToOneInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type CallRecordFragmentHistoryRecord = {
    __typename?: 'CallRecordFragmentHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the CallRecordFragmentHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the CallRecordFragmentHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the CallRecordFragmentHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the CallRecordFragmentHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    callRecord?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<CallRecordFragmentHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    startedAt?: Maybe<Scalars['String']['output']>
    ticket?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type CallRecordFragmentHistoryRecordCreateInput = {
    callRecord?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<CallRecordFragmentHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    startedAt?: InputMaybe<Scalars['String']['input']>
    ticket?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum CallRecordFragmentHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type CallRecordFragmentHistoryRecordUpdateInput = {
    callRecord?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<CallRecordFragmentHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    startedAt?: InputMaybe<Scalars['String']['input']>
    ticket?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type CallRecordFragmentHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<CallRecordFragmentHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<CallRecordFragmentHistoryRecordWhereInput>>>
    callRecord?: InputMaybe<Scalars['String']['input']>
    callRecord_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    callRecord_not?: InputMaybe<Scalars['String']['input']>
    callRecord_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<CallRecordFragmentHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<CallRecordFragmentHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<CallRecordFragmentHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<CallRecordFragmentHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    startedAt?: InputMaybe<Scalars['String']['input']>
    startedAt_gt?: InputMaybe<Scalars['String']['input']>
    startedAt_gte?: InputMaybe<Scalars['String']['input']>
    startedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    startedAt_lt?: InputMaybe<Scalars['String']['input']>
    startedAt_lte?: InputMaybe<Scalars['String']['input']>
    startedAt_not?: InputMaybe<Scalars['String']['input']>
    startedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    ticket?: InputMaybe<Scalars['String']['input']>
    ticket_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    ticket_not?: InputMaybe<Scalars['String']['input']>
    ticket_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type CallRecordFragmentHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type CallRecordFragmentHistoryRecordsCreateInput = {
    data?: InputMaybe<CallRecordFragmentHistoryRecordCreateInput>
}

export type CallRecordFragmentHistoryRecordsUpdateInput = {
    data?: InputMaybe<CallRecordFragmentHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type CallRecordFragmentUpdateInput = {
    callRecord?: InputMaybe<CallRecordRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    startedAt?: InputMaybe<Scalars['String']['input']>
    ticket?: InputMaybe<TicketRelateToOneInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type CallRecordFragmentWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<CallRecordFragmentWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<CallRecordFragmentWhereInput>>>
    callRecord?: InputMaybe<CallRecordWhereInput>
    callRecord_is_null?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    startedAt?: InputMaybe<Scalars['String']['input']>
    startedAt_gt?: InputMaybe<Scalars['String']['input']>
    startedAt_gte?: InputMaybe<Scalars['String']['input']>
    startedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    startedAt_lt?: InputMaybe<Scalars['String']['input']>
    startedAt_lte?: InputMaybe<Scalars['String']['input']>
    startedAt_not?: InputMaybe<Scalars['String']['input']>
    startedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    ticket?: InputMaybe<TicketWhereInput>
    ticket_is_null?: InputMaybe<Scalars['Boolean']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type CallRecordFragmentWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type CallRecordFragmentsCreateInput = {
    data?: InputMaybe<CallRecordFragmentCreateInput>
}

export type CallRecordFragmentsUpdateInput = {
    data?: InputMaybe<CallRecordFragmentUpdateInput>
    id: Scalars['ID']['input']
}

/**  A keystone list  */
export type CallRecordHistoryRecord = {
    __typename?: 'CallRecordHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the CallRecordHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the CallRecordHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the CallRecordHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the CallRecordHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    callerPhone?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    destCallerPhone?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    file?: Maybe<Scalars['JSON']['output']>
    history_action?: Maybe<CallRecordHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    importId?: Maybe<Scalars['String']['output']>
    isIncomingCall?: Maybe<Scalars['Boolean']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    startedAt?: Maybe<Scalars['String']['output']>
    talkTime?: Maybe<Scalars['Int']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type CallRecordHistoryRecordCreateInput = {
    callerPhone?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    destCallerPhone?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['JSON']['input']>
    history_action?: InputMaybe<CallRecordHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    isIncomingCall?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    startedAt?: InputMaybe<Scalars['String']['input']>
    talkTime?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum CallRecordHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type CallRecordHistoryRecordUpdateInput = {
    callerPhone?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    destCallerPhone?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['JSON']['input']>
    history_action?: InputMaybe<CallRecordHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    isIncomingCall?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    startedAt?: InputMaybe<Scalars['String']['input']>
    talkTime?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type CallRecordHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<CallRecordHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<CallRecordHistoryRecordWhereInput>>>
    callerPhone?: InputMaybe<Scalars['String']['input']>
    callerPhone_contains?: InputMaybe<Scalars['String']['input']>
    callerPhone_contains_i?: InputMaybe<Scalars['String']['input']>
    callerPhone_ends_with?: InputMaybe<Scalars['String']['input']>
    callerPhone_ends_with_i?: InputMaybe<Scalars['String']['input']>
    callerPhone_i?: InputMaybe<Scalars['String']['input']>
    callerPhone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    callerPhone_not?: InputMaybe<Scalars['String']['input']>
    callerPhone_not_contains?: InputMaybe<Scalars['String']['input']>
    callerPhone_not_contains_i?: InputMaybe<Scalars['String']['input']>
    callerPhone_not_ends_with?: InputMaybe<Scalars['String']['input']>
    callerPhone_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    callerPhone_not_i?: InputMaybe<Scalars['String']['input']>
    callerPhone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    callerPhone_not_starts_with?: InputMaybe<Scalars['String']['input']>
    callerPhone_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    callerPhone_starts_with?: InputMaybe<Scalars['String']['input']>
    callerPhone_starts_with_i?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    destCallerPhone?: InputMaybe<Scalars['String']['input']>
    destCallerPhone_contains?: InputMaybe<Scalars['String']['input']>
    destCallerPhone_contains_i?: InputMaybe<Scalars['String']['input']>
    destCallerPhone_ends_with?: InputMaybe<Scalars['String']['input']>
    destCallerPhone_ends_with_i?: InputMaybe<Scalars['String']['input']>
    destCallerPhone_i?: InputMaybe<Scalars['String']['input']>
    destCallerPhone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    destCallerPhone_not?: InputMaybe<Scalars['String']['input']>
    destCallerPhone_not_contains?: InputMaybe<Scalars['String']['input']>
    destCallerPhone_not_contains_i?: InputMaybe<Scalars['String']['input']>
    destCallerPhone_not_ends_with?: InputMaybe<Scalars['String']['input']>
    destCallerPhone_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    destCallerPhone_not_i?: InputMaybe<Scalars['String']['input']>
    destCallerPhone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    destCallerPhone_not_starts_with?: InputMaybe<Scalars['String']['input']>
    destCallerPhone_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    destCallerPhone_starts_with?: InputMaybe<Scalars['String']['input']>
    destCallerPhone_starts_with_i?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    file?: InputMaybe<Scalars['JSON']['input']>
    file_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    file_not?: InputMaybe<Scalars['JSON']['input']>
    file_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    history_action?: InputMaybe<CallRecordHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<CallRecordHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<CallRecordHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<CallRecordHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    importId?: InputMaybe<Scalars['String']['input']>
    importId_contains?: InputMaybe<Scalars['String']['input']>
    importId_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_i?: InputMaybe<Scalars['String']['input']>
    importId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not?: InputMaybe<Scalars['String']['input']>
    importId_not_contains?: InputMaybe<Scalars['String']['input']>
    importId_not_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_not_i?: InputMaybe<Scalars['String']['input']>
    importId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importId_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_starts_with_i?: InputMaybe<Scalars['String']['input']>
    isIncomingCall?: InputMaybe<Scalars['Boolean']['input']>
    isIncomingCall_not?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    startedAt?: InputMaybe<Scalars['String']['input']>
    startedAt_gt?: InputMaybe<Scalars['String']['input']>
    startedAt_gte?: InputMaybe<Scalars['String']['input']>
    startedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    startedAt_lt?: InputMaybe<Scalars['String']['input']>
    startedAt_lte?: InputMaybe<Scalars['String']['input']>
    startedAt_not?: InputMaybe<Scalars['String']['input']>
    startedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    talkTime?: InputMaybe<Scalars['Int']['input']>
    talkTime_gt?: InputMaybe<Scalars['Int']['input']>
    talkTime_gte?: InputMaybe<Scalars['Int']['input']>
    talkTime_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    talkTime_lt?: InputMaybe<Scalars['Int']['input']>
    talkTime_lte?: InputMaybe<Scalars['Int']['input']>
    talkTime_not?: InputMaybe<Scalars['Int']['input']>
    talkTime_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type CallRecordHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type CallRecordHistoryRecordsCreateInput = {
    data?: InputMaybe<CallRecordHistoryRecordCreateInput>
}

export type CallRecordHistoryRecordsUpdateInput = {
    data?: InputMaybe<CallRecordHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type CallRecordRelateToOneInput = {
    connect?: InputMaybe<CallRecordWhereUniqueInput>
    create?: InputMaybe<CallRecordCreateInput>
    disconnect?: InputMaybe<CallRecordWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type CallRecordUpdateInput = {
    callerPhone?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    destCallerPhone?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['Upload']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    isIncomingCall?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    startedAt?: InputMaybe<Scalars['String']['input']>
    talkTime?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type CallRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<CallRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<CallRecordWhereInput>>>
    callerPhone?: InputMaybe<Scalars['String']['input']>
    callerPhone_contains?: InputMaybe<Scalars['String']['input']>
    callerPhone_contains_i?: InputMaybe<Scalars['String']['input']>
    callerPhone_ends_with?: InputMaybe<Scalars['String']['input']>
    callerPhone_ends_with_i?: InputMaybe<Scalars['String']['input']>
    callerPhone_i?: InputMaybe<Scalars['String']['input']>
    callerPhone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    callerPhone_not?: InputMaybe<Scalars['String']['input']>
    callerPhone_not_contains?: InputMaybe<Scalars['String']['input']>
    callerPhone_not_contains_i?: InputMaybe<Scalars['String']['input']>
    callerPhone_not_ends_with?: InputMaybe<Scalars['String']['input']>
    callerPhone_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    callerPhone_not_i?: InputMaybe<Scalars['String']['input']>
    callerPhone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    callerPhone_not_starts_with?: InputMaybe<Scalars['String']['input']>
    callerPhone_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    callerPhone_starts_with?: InputMaybe<Scalars['String']['input']>
    callerPhone_starts_with_i?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    destCallerPhone?: InputMaybe<Scalars['String']['input']>
    destCallerPhone_contains?: InputMaybe<Scalars['String']['input']>
    destCallerPhone_contains_i?: InputMaybe<Scalars['String']['input']>
    destCallerPhone_ends_with?: InputMaybe<Scalars['String']['input']>
    destCallerPhone_ends_with_i?: InputMaybe<Scalars['String']['input']>
    destCallerPhone_i?: InputMaybe<Scalars['String']['input']>
    destCallerPhone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    destCallerPhone_not?: InputMaybe<Scalars['String']['input']>
    destCallerPhone_not_contains?: InputMaybe<Scalars['String']['input']>
    destCallerPhone_not_contains_i?: InputMaybe<Scalars['String']['input']>
    destCallerPhone_not_ends_with?: InputMaybe<Scalars['String']['input']>
    destCallerPhone_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    destCallerPhone_not_i?: InputMaybe<Scalars['String']['input']>
    destCallerPhone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    destCallerPhone_not_starts_with?: InputMaybe<Scalars['String']['input']>
    destCallerPhone_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    destCallerPhone_starts_with?: InputMaybe<Scalars['String']['input']>
    destCallerPhone_starts_with_i?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    file?: InputMaybe<Scalars['String']['input']>
    file_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    file_not?: InputMaybe<Scalars['String']['input']>
    file_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    importId?: InputMaybe<Scalars['String']['input']>
    importId_contains?: InputMaybe<Scalars['String']['input']>
    importId_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_i?: InputMaybe<Scalars['String']['input']>
    importId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not?: InputMaybe<Scalars['String']['input']>
    importId_not_contains?: InputMaybe<Scalars['String']['input']>
    importId_not_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_not_i?: InputMaybe<Scalars['String']['input']>
    importId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importId_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_starts_with_i?: InputMaybe<Scalars['String']['input']>
    isIncomingCall?: InputMaybe<Scalars['Boolean']['input']>
    isIncomingCall_not?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    startedAt?: InputMaybe<Scalars['String']['input']>
    startedAt_gt?: InputMaybe<Scalars['String']['input']>
    startedAt_gte?: InputMaybe<Scalars['String']['input']>
    startedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    startedAt_lt?: InputMaybe<Scalars['String']['input']>
    startedAt_lte?: InputMaybe<Scalars['String']['input']>
    startedAt_not?: InputMaybe<Scalars['String']['input']>
    startedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    talkTime?: InputMaybe<Scalars['Int']['input']>
    talkTime_gt?: InputMaybe<Scalars['Int']['input']>
    talkTime_gte?: InputMaybe<Scalars['Int']['input']>
    talkTime_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    talkTime_lt?: InputMaybe<Scalars['Int']['input']>
    talkTime_lte?: InputMaybe<Scalars['Int']['input']>
    talkTime_not?: InputMaybe<Scalars['Int']['input']>
    talkTime_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type CallRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type CallRecordsCreateInput = {
    data?: InputMaybe<CallRecordCreateInput>
}

export type CallRecordsUpdateInput = {
    data?: InputMaybe<CallRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type ChangePasswordWithTokenInput = {
    dv: Scalars['Int']['input']
    password: Scalars['String']['input']
    sender: SenderFieldInput
    token: Scalars['String']['input']
}

export type ChangePasswordWithTokenOutput = {
    __typename?: 'ChangePasswordWithTokenOutput'
    phone: Scalars['String']['output']
    status: Scalars['String']['output']
}

export type ChangePhoneNumberResidentUserInput = {
    dv: Scalars['Int']['input']
    removeUserExternalIdentitiesIfPhoneDifferent?: InputMaybe<Scalars['Boolean']['input']>
    sender: SenderFieldInput
    token: Scalars['String']['input']
}

export type ChangePhoneNumberResidentUserOutput = {
    __typename?: 'ChangePhoneNumberResidentUserOutput'
    status: Scalars['String']['output']
}

export type CheckUserExistenceInput = {
    confirmActionToken: Scalars['ID']['input']
    dv: Scalars['Int']['input']
    sender: SenderFieldInput
    userType: UserTypeType
}

export type CheckUserExistenceOutput = {
    __typename?: 'CheckUserExistenceOutput'
    emailSet: Scalars['Boolean']['output']
    nameSet: Scalars['Boolean']['output']
    passwordSet: Scalars['Boolean']['output']
    phoneSet: Scalars['Boolean']['output']
    userExists: Scalars['Boolean']['output']
}

export type CompleteConfirmPhoneActionInput = {
    captcha: Scalars['String']['input']
    dv: Scalars['Int']['input']
    sender: SenderFieldInput
    smsCode: Scalars['Int']['input']
    token: Scalars['String']['input']
}

export type CompleteConfirmPhoneActionOutput = {
    __typename?: 'CompleteConfirmPhoneActionOutput'
    status: Scalars['String']['output']
}

/**  User confirm phone actions is used before registration starts  */
export type ConfirmPhoneAction = {
    __typename?: 'ConfirmPhoneAction'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the ConfirmPhoneAction List config, or
   *  2. As an alias to the field set on 'labelField' in the ConfirmPhoneAction List config, or
   *  3. As an alias to a 'name' field on the ConfirmPhoneAction List (if one exists), or
   *  4. As an alias to the 'id' field on the ConfirmPhoneAction List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  When confirm phone action was completed  */
    completedAt?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  When confirm phone action becomes invalid  */
    expiresAt?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    /**  Phone verification flag. User verify phone by access to secret sms message  */
    isPhoneVerified?: Maybe<Scalars['Boolean']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Normalized phone in E.164 format without spaces  */
    phone?: Maybe<Scalars['String']['output']>
    /**  DateTime when confirm phone action was started  */
    requestedAt?: Maybe<Scalars['String']['output']>
    /**  Number of times sms code input from user failed  */
    retries?: Maybe<Scalars['Int']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Last sms code sent to user  */
    smsCode?: Maybe<Scalars['Int']['output']>
    /**  Time when sms code becomes not valid  */
    smsCodeExpiresAt?: Maybe<Scalars['String']['output']>
    /**  Time when sms code was requested  */
    smsCodeRequestedAt?: Maybe<Scalars['String']['output']>
    /**  Unique token to complete confirmation  */
    token?: Maybe<Scalars['String']['output']>
}

export type ConfirmPhoneActionCreateInput = {
    completedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    expiresAt?: InputMaybe<Scalars['String']['input']>
    isPhoneVerified?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    phone?: InputMaybe<Scalars['String']['input']>
    requestedAt?: InputMaybe<Scalars['String']['input']>
    retries?: InputMaybe<Scalars['Int']['input']>
    sender?: InputMaybe<SenderFieldInput>
    smsCode?: InputMaybe<Scalars['Int']['input']>
    smsCodeExpiresAt?: InputMaybe<Scalars['String']['input']>
    smsCodeRequestedAt?: InputMaybe<Scalars['String']['input']>
    token?: InputMaybe<Scalars['String']['input']>
}

/**  A keystone list  */
export type ConfirmPhoneActionHistoryRecord = {
    __typename?: 'ConfirmPhoneActionHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the ConfirmPhoneActionHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the ConfirmPhoneActionHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the ConfirmPhoneActionHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the ConfirmPhoneActionHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    completedAt?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    expiresAt?: Maybe<Scalars['String']['output']>
    history_action?: Maybe<ConfirmPhoneActionHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    isPhoneVerified?: Maybe<Scalars['Boolean']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    phone?: Maybe<Scalars['String']['output']>
    requestedAt?: Maybe<Scalars['String']['output']>
    retries?: Maybe<Scalars['Int']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    smsCode?: Maybe<Scalars['Int']['output']>
    smsCodeExpiresAt?: Maybe<Scalars['String']['output']>
    smsCodeRequestedAt?: Maybe<Scalars['String']['output']>
    token?: Maybe<Scalars['String']['output']>
}

export type ConfirmPhoneActionHistoryRecordCreateInput = {
    completedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    expiresAt?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<ConfirmPhoneActionHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    isPhoneVerified?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    phone?: InputMaybe<Scalars['String']['input']>
    requestedAt?: InputMaybe<Scalars['String']['input']>
    retries?: InputMaybe<Scalars['Int']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    smsCode?: InputMaybe<Scalars['Int']['input']>
    smsCodeExpiresAt?: InputMaybe<Scalars['String']['input']>
    smsCodeRequestedAt?: InputMaybe<Scalars['String']['input']>
    token?: InputMaybe<Scalars['String']['input']>
}

export enum ConfirmPhoneActionHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type ConfirmPhoneActionHistoryRecordUpdateInput = {
    completedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    expiresAt?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<ConfirmPhoneActionHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    isPhoneVerified?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    phone?: InputMaybe<Scalars['String']['input']>
    requestedAt?: InputMaybe<Scalars['String']['input']>
    retries?: InputMaybe<Scalars['Int']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    smsCode?: InputMaybe<Scalars['Int']['input']>
    smsCodeExpiresAt?: InputMaybe<Scalars['String']['input']>
    smsCodeRequestedAt?: InputMaybe<Scalars['String']['input']>
    token?: InputMaybe<Scalars['String']['input']>
}

export type ConfirmPhoneActionHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<ConfirmPhoneActionHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<ConfirmPhoneActionHistoryRecordWhereInput>>>
    completedAt?: InputMaybe<Scalars['String']['input']>
    completedAt_gt?: InputMaybe<Scalars['String']['input']>
    completedAt_gte?: InputMaybe<Scalars['String']['input']>
    completedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    completedAt_lt?: InputMaybe<Scalars['String']['input']>
    completedAt_lte?: InputMaybe<Scalars['String']['input']>
    completedAt_not?: InputMaybe<Scalars['String']['input']>
    completedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    expiresAt?: InputMaybe<Scalars['String']['input']>
    expiresAt_gt?: InputMaybe<Scalars['String']['input']>
    expiresAt_gte?: InputMaybe<Scalars['String']['input']>
    expiresAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    expiresAt_lt?: InputMaybe<Scalars['String']['input']>
    expiresAt_lte?: InputMaybe<Scalars['String']['input']>
    expiresAt_not?: InputMaybe<Scalars['String']['input']>
    expiresAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_action?: InputMaybe<ConfirmPhoneActionHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<ConfirmPhoneActionHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<ConfirmPhoneActionHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<ConfirmPhoneActionHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    isPhoneVerified?: InputMaybe<Scalars['Boolean']['input']>
    isPhoneVerified_not?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    phone?: InputMaybe<Scalars['String']['input']>
    phone_contains?: InputMaybe<Scalars['String']['input']>
    phone_contains_i?: InputMaybe<Scalars['String']['input']>
    phone_ends_with?: InputMaybe<Scalars['String']['input']>
    phone_ends_with_i?: InputMaybe<Scalars['String']['input']>
    phone_i?: InputMaybe<Scalars['String']['input']>
    phone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phone_not?: InputMaybe<Scalars['String']['input']>
    phone_not_contains?: InputMaybe<Scalars['String']['input']>
    phone_not_contains_i?: InputMaybe<Scalars['String']['input']>
    phone_not_ends_with?: InputMaybe<Scalars['String']['input']>
    phone_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    phone_not_i?: InputMaybe<Scalars['String']['input']>
    phone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phone_not_starts_with?: InputMaybe<Scalars['String']['input']>
    phone_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    phone_starts_with?: InputMaybe<Scalars['String']['input']>
    phone_starts_with_i?: InputMaybe<Scalars['String']['input']>
    requestedAt?: InputMaybe<Scalars['String']['input']>
    requestedAt_gt?: InputMaybe<Scalars['String']['input']>
    requestedAt_gte?: InputMaybe<Scalars['String']['input']>
    requestedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    requestedAt_lt?: InputMaybe<Scalars['String']['input']>
    requestedAt_lte?: InputMaybe<Scalars['String']['input']>
    requestedAt_not?: InputMaybe<Scalars['String']['input']>
    requestedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    retries?: InputMaybe<Scalars['Int']['input']>
    retries_gt?: InputMaybe<Scalars['Int']['input']>
    retries_gte?: InputMaybe<Scalars['Int']['input']>
    retries_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    retries_lt?: InputMaybe<Scalars['Int']['input']>
    retries_lte?: InputMaybe<Scalars['Int']['input']>
    retries_not?: InputMaybe<Scalars['Int']['input']>
    retries_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    smsCode?: InputMaybe<Scalars['Int']['input']>
    smsCodeExpiresAt?: InputMaybe<Scalars['String']['input']>
    smsCodeExpiresAt_gt?: InputMaybe<Scalars['String']['input']>
    smsCodeExpiresAt_gte?: InputMaybe<Scalars['String']['input']>
    smsCodeExpiresAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    smsCodeExpiresAt_lt?: InputMaybe<Scalars['String']['input']>
    smsCodeExpiresAt_lte?: InputMaybe<Scalars['String']['input']>
    smsCodeExpiresAt_not?: InputMaybe<Scalars['String']['input']>
    smsCodeExpiresAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    smsCodeRequestedAt?: InputMaybe<Scalars['String']['input']>
    smsCodeRequestedAt_gt?: InputMaybe<Scalars['String']['input']>
    smsCodeRequestedAt_gte?: InputMaybe<Scalars['String']['input']>
    smsCodeRequestedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    smsCodeRequestedAt_lt?: InputMaybe<Scalars['String']['input']>
    smsCodeRequestedAt_lte?: InputMaybe<Scalars['String']['input']>
    smsCodeRequestedAt_not?: InputMaybe<Scalars['String']['input']>
    smsCodeRequestedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    smsCode_gt?: InputMaybe<Scalars['Int']['input']>
    smsCode_gte?: InputMaybe<Scalars['Int']['input']>
    smsCode_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    smsCode_lt?: InputMaybe<Scalars['Int']['input']>
    smsCode_lte?: InputMaybe<Scalars['Int']['input']>
    smsCode_not?: InputMaybe<Scalars['Int']['input']>
    smsCode_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    token?: InputMaybe<Scalars['String']['input']>
    token_contains?: InputMaybe<Scalars['String']['input']>
    token_contains_i?: InputMaybe<Scalars['String']['input']>
    token_ends_with?: InputMaybe<Scalars['String']['input']>
    token_ends_with_i?: InputMaybe<Scalars['String']['input']>
    token_i?: InputMaybe<Scalars['String']['input']>
    token_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    token_not?: InputMaybe<Scalars['String']['input']>
    token_not_contains?: InputMaybe<Scalars['String']['input']>
    token_not_contains_i?: InputMaybe<Scalars['String']['input']>
    token_not_ends_with?: InputMaybe<Scalars['String']['input']>
    token_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    token_not_i?: InputMaybe<Scalars['String']['input']>
    token_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    token_not_starts_with?: InputMaybe<Scalars['String']['input']>
    token_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    token_starts_with?: InputMaybe<Scalars['String']['input']>
    token_starts_with_i?: InputMaybe<Scalars['String']['input']>
}

export type ConfirmPhoneActionHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type ConfirmPhoneActionHistoryRecordsCreateInput = {
    data?: InputMaybe<ConfirmPhoneActionHistoryRecordCreateInput>
}

export type ConfirmPhoneActionHistoryRecordsUpdateInput = {
    data?: InputMaybe<ConfirmPhoneActionHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type ConfirmPhoneActionUpdateInput = {
    completedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    expiresAt?: InputMaybe<Scalars['String']['input']>
    isPhoneVerified?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    phone?: InputMaybe<Scalars['String']['input']>
    requestedAt?: InputMaybe<Scalars['String']['input']>
    retries?: InputMaybe<Scalars['Int']['input']>
    sender?: InputMaybe<SenderFieldInput>
    smsCode?: InputMaybe<Scalars['Int']['input']>
    smsCodeExpiresAt?: InputMaybe<Scalars['String']['input']>
    smsCodeRequestedAt?: InputMaybe<Scalars['String']['input']>
    token?: InputMaybe<Scalars['String']['input']>
}

export type ConfirmPhoneActionWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<ConfirmPhoneActionWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<ConfirmPhoneActionWhereInput>>>
    completedAt?: InputMaybe<Scalars['String']['input']>
    completedAt_gt?: InputMaybe<Scalars['String']['input']>
    completedAt_gte?: InputMaybe<Scalars['String']['input']>
    completedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    completedAt_lt?: InputMaybe<Scalars['String']['input']>
    completedAt_lte?: InputMaybe<Scalars['String']['input']>
    completedAt_not?: InputMaybe<Scalars['String']['input']>
    completedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    expiresAt?: InputMaybe<Scalars['String']['input']>
    expiresAt_gt?: InputMaybe<Scalars['String']['input']>
    expiresAt_gte?: InputMaybe<Scalars['String']['input']>
    expiresAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    expiresAt_lt?: InputMaybe<Scalars['String']['input']>
    expiresAt_lte?: InputMaybe<Scalars['String']['input']>
    expiresAt_not?: InputMaybe<Scalars['String']['input']>
    expiresAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    isPhoneVerified?: InputMaybe<Scalars['Boolean']['input']>
    isPhoneVerified_not?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phone?: InputMaybe<Scalars['String']['input']>
    phone_contains?: InputMaybe<Scalars['String']['input']>
    phone_contains_i?: InputMaybe<Scalars['String']['input']>
    phone_ends_with?: InputMaybe<Scalars['String']['input']>
    phone_ends_with_i?: InputMaybe<Scalars['String']['input']>
    phone_i?: InputMaybe<Scalars['String']['input']>
    phone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phone_not?: InputMaybe<Scalars['String']['input']>
    phone_not_contains?: InputMaybe<Scalars['String']['input']>
    phone_not_contains_i?: InputMaybe<Scalars['String']['input']>
    phone_not_ends_with?: InputMaybe<Scalars['String']['input']>
    phone_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    phone_not_i?: InputMaybe<Scalars['String']['input']>
    phone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phone_not_starts_with?: InputMaybe<Scalars['String']['input']>
    phone_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    phone_starts_with?: InputMaybe<Scalars['String']['input']>
    phone_starts_with_i?: InputMaybe<Scalars['String']['input']>
    requestedAt?: InputMaybe<Scalars['String']['input']>
    requestedAt_gt?: InputMaybe<Scalars['String']['input']>
    requestedAt_gte?: InputMaybe<Scalars['String']['input']>
    requestedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    requestedAt_lt?: InputMaybe<Scalars['String']['input']>
    requestedAt_lte?: InputMaybe<Scalars['String']['input']>
    requestedAt_not?: InputMaybe<Scalars['String']['input']>
    requestedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    retries?: InputMaybe<Scalars['Int']['input']>
    retries_gt?: InputMaybe<Scalars['Int']['input']>
    retries_gte?: InputMaybe<Scalars['Int']['input']>
    retries_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    retries_lt?: InputMaybe<Scalars['Int']['input']>
    retries_lte?: InputMaybe<Scalars['Int']['input']>
    retries_not?: InputMaybe<Scalars['Int']['input']>
    retries_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    smsCode?: InputMaybe<Scalars['Int']['input']>
    smsCodeExpiresAt?: InputMaybe<Scalars['String']['input']>
    smsCodeExpiresAt_gt?: InputMaybe<Scalars['String']['input']>
    smsCodeExpiresAt_gte?: InputMaybe<Scalars['String']['input']>
    smsCodeExpiresAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    smsCodeExpiresAt_lt?: InputMaybe<Scalars['String']['input']>
    smsCodeExpiresAt_lte?: InputMaybe<Scalars['String']['input']>
    smsCodeExpiresAt_not?: InputMaybe<Scalars['String']['input']>
    smsCodeExpiresAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    smsCodeRequestedAt?: InputMaybe<Scalars['String']['input']>
    smsCodeRequestedAt_gt?: InputMaybe<Scalars['String']['input']>
    smsCodeRequestedAt_gte?: InputMaybe<Scalars['String']['input']>
    smsCodeRequestedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    smsCodeRequestedAt_lt?: InputMaybe<Scalars['String']['input']>
    smsCodeRequestedAt_lte?: InputMaybe<Scalars['String']['input']>
    smsCodeRequestedAt_not?: InputMaybe<Scalars['String']['input']>
    smsCodeRequestedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    smsCode_gt?: InputMaybe<Scalars['Int']['input']>
    smsCode_gte?: InputMaybe<Scalars['Int']['input']>
    smsCode_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    smsCode_lt?: InputMaybe<Scalars['Int']['input']>
    smsCode_lte?: InputMaybe<Scalars['Int']['input']>
    smsCode_not?: InputMaybe<Scalars['Int']['input']>
    smsCode_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    token?: InputMaybe<Scalars['String']['input']>
    token_contains?: InputMaybe<Scalars['String']['input']>
    token_contains_i?: InputMaybe<Scalars['String']['input']>
    token_ends_with?: InputMaybe<Scalars['String']['input']>
    token_ends_with_i?: InputMaybe<Scalars['String']['input']>
    token_i?: InputMaybe<Scalars['String']['input']>
    token_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    token_not?: InputMaybe<Scalars['String']['input']>
    token_not_contains?: InputMaybe<Scalars['String']['input']>
    token_not_contains_i?: InputMaybe<Scalars['String']['input']>
    token_not_ends_with?: InputMaybe<Scalars['String']['input']>
    token_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    token_not_i?: InputMaybe<Scalars['String']['input']>
    token_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    token_not_starts_with?: InputMaybe<Scalars['String']['input']>
    token_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    token_starts_with?: InputMaybe<Scalars['String']['input']>
    token_starts_with_i?: InputMaybe<Scalars['String']['input']>
}

export type ConfirmPhoneActionWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type ConfirmPhoneActionsCreateInput = {
    data?: InputMaybe<ConfirmPhoneActionCreateInput>
}

export type ConfirmPhoneActionsUpdateInput = {
    data?: InputMaybe<ConfirmPhoneActionUpdateInput>
    id: Scalars['ID']['input']
}

/**  Contact information of a person. Currently it will be related to a ticket, but in the future, it will be associated with more things  */
export type Contact = {
    __typename?: 'Contact'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the Contact List config, or
   *  2. As an alias to the field set on 'labelField' in the Contact List config, or
   *  3. As an alias to a 'name' field on the Contact List (if one exists), or
   *  4. As an alias to the 'id' field on the Contact List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  Normalized contact email of this person  */
    email?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    /**  Contact verification flag.  */
    isVerified?: Maybe<Scalars['Boolean']['output']>
    /**  Name or full name of this person  */
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
    organization?: Maybe<Organization>
    /**  Normalized contact phone of this person in E.164 format without spaces  */
    phone?: Maybe<Scalars['String']['output']>
    /**  Property, that is a subject of an issue, reported by this person in first ticket. Meaning of this field will be revised in the future  */
    property?: Maybe<Property>
    /**  Percentage ownership of an apartment  */
    quota?: Maybe<Scalars['String']['output']>
    /**  The contact's role  */
    role?: Maybe<ContactRole>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Property unit, that is a subject of an issue, reported by this person in first ticket. Meaning of this field will be revised in the future  */
    unitName?: Maybe<Scalars['String']['output']>
    /**  Type of unit, such as parking lot or flat. Default value: "flat"  */
    unitType?: Maybe<ContactUnitTypeType>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type ContactCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    email?: InputMaybe<Scalars['String']['input']>
    isVerified?: InputMaybe<Scalars['Boolean']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    phone?: InputMaybe<Scalars['String']['input']>
    property?: InputMaybe<PropertyRelateToOneInput>
    quota?: InputMaybe<Scalars['String']['input']>
    role?: InputMaybe<ContactRoleRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    unitName?: InputMaybe<Scalars['String']['input']>
    unitType?: InputMaybe<ContactUnitTypeType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  Stores requested export format, status of export job, link to exported file and information about progress of export job  */
export type ContactExportTask = {
    __typename?: 'ContactExportTask'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the ContactExportTask List config, or
   *  2. As an alias to the field set on 'labelField' in the ContactExportTask List config, or
   *  3. As an alias to a 'name' field on the ContactExportTask List (if one exists), or
   *  4. As an alias to the 'id' field on the ContactExportTask List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  How many records at the moment are exported  */
    exportedRecordsCount?: Maybe<Scalars['Int']['output']>
    /**  Meta information about file, saved outside of database somewhere. Shape of meta information JSON object is specific to file adapter, used by saving a file.  */
    file?: Maybe<File>
    /**  Requested export file format  */
    format?: Maybe<ContactExportTaskFormatType>
    id: Scalars['ID']['output']
    /**  Requested export locale, in that the resulting file will be rendered  */
    locale?: Maybe<Scalars['String']['output']>
    /**  Stores information about query and ids of exported and failed records  */
    meta?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Sorting parameters for records to export  */
    sortBy?: Maybe<Scalars['JSON']['output']>
    /**  Status of the job. Used at client side progress information component  */
    status?: Maybe<ContactExportTaskStatusType>
    /**  To requested timeZone all datetime fields will be converted  */
    timeZone?: Maybe<Scalars['String']['output']>
    /**  Total records to export that will be determined at server side in export operation  */
    totalRecordsCount?: Maybe<Scalars['Int']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    /**  User that requested this exporting operation. Will be used for read access checks to display all exported tasks somewhere and to display progress indicator of ongoing exporting task for current user  */
    user?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
    /**  Filtering conditions for records to export  */
    where?: Maybe<Scalars['JSON']['output']>
}

export type ContactExportTaskCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['Upload']['input']>
    format?: InputMaybe<ContactExportTaskFormatType>
    locale?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sortBy?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<ContactExportTaskStatusType>
    timeZone?: InputMaybe<Scalars['String']['input']>
    totalRecordsCount?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
    where?: InputMaybe<Scalars['JSON']['input']>
}

export enum ContactExportTaskFormatType {
    Excel = 'excel',
}

/**  A keystone list  */
export type ContactExportTaskHistoryRecord = {
    __typename?: 'ContactExportTaskHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the ContactExportTaskHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the ContactExportTaskHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the ContactExportTaskHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the ContactExportTaskHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    exportedRecordsCount?: Maybe<Scalars['Int']['output']>
    file?: Maybe<Scalars['JSON']['output']>
    format?: Maybe<Scalars['String']['output']>
    history_action?: Maybe<ContactExportTaskHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    locale?: Maybe<Scalars['String']['output']>
    meta?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    sortBy?: Maybe<Scalars['JSON']['output']>
    status?: Maybe<Scalars['String']['output']>
    timeZone?: Maybe<Scalars['String']['output']>
    totalRecordsCount?: Maybe<Scalars['Int']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    user?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
    where?: Maybe<Scalars['JSON']['output']>
}

export type ContactExportTaskHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['JSON']['input']>
    format?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<ContactExportTaskHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    locale?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sortBy?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    timeZone?: InputMaybe<Scalars['String']['input']>
    totalRecordsCount?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    where?: InputMaybe<Scalars['JSON']['input']>
}

export enum ContactExportTaskHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type ContactExportTaskHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['JSON']['input']>
    format?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<ContactExportTaskHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    locale?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sortBy?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    timeZone?: InputMaybe<Scalars['String']['input']>
    totalRecordsCount?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    where?: InputMaybe<Scalars['JSON']['input']>
}

export type ContactExportTaskHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<ContactExportTaskHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<ContactExportTaskHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    exportedRecordsCount?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_gt?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_gte?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    exportedRecordsCount_lt?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_lte?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_not?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    file?: InputMaybe<Scalars['JSON']['input']>
    file_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    file_not?: InputMaybe<Scalars['JSON']['input']>
    file_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    format?: InputMaybe<Scalars['String']['input']>
    format_contains?: InputMaybe<Scalars['String']['input']>
    format_contains_i?: InputMaybe<Scalars['String']['input']>
    format_ends_with?: InputMaybe<Scalars['String']['input']>
    format_ends_with_i?: InputMaybe<Scalars['String']['input']>
    format_i?: InputMaybe<Scalars['String']['input']>
    format_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    format_not?: InputMaybe<Scalars['String']['input']>
    format_not_contains?: InputMaybe<Scalars['String']['input']>
    format_not_contains_i?: InputMaybe<Scalars['String']['input']>
    format_not_ends_with?: InputMaybe<Scalars['String']['input']>
    format_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    format_not_i?: InputMaybe<Scalars['String']['input']>
    format_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    format_not_starts_with?: InputMaybe<Scalars['String']['input']>
    format_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    format_starts_with?: InputMaybe<Scalars['String']['input']>
    format_starts_with_i?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<ContactExportTaskHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<ContactExportTaskHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<ContactExportTaskHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<ContactExportTaskHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    locale?: InputMaybe<Scalars['String']['input']>
    locale_contains?: InputMaybe<Scalars['String']['input']>
    locale_contains_i?: InputMaybe<Scalars['String']['input']>
    locale_ends_with?: InputMaybe<Scalars['String']['input']>
    locale_ends_with_i?: InputMaybe<Scalars['String']['input']>
    locale_i?: InputMaybe<Scalars['String']['input']>
    locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    locale_not?: InputMaybe<Scalars['String']['input']>
    locale_not_contains?: InputMaybe<Scalars['String']['input']>
    locale_not_contains_i?: InputMaybe<Scalars['String']['input']>
    locale_not_ends_with?: InputMaybe<Scalars['String']['input']>
    locale_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    locale_not_i?: InputMaybe<Scalars['String']['input']>
    locale_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    locale_not_starts_with?: InputMaybe<Scalars['String']['input']>
    locale_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    locale_starts_with?: InputMaybe<Scalars['String']['input']>
    locale_starts_with_i?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sortBy?: InputMaybe<Scalars['JSON']['input']>
    sortBy_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sortBy_not?: InputMaybe<Scalars['JSON']['input']>
    sortBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    status?: InputMaybe<Scalars['String']['input']>
    status_contains?: InputMaybe<Scalars['String']['input']>
    status_contains_i?: InputMaybe<Scalars['String']['input']>
    status_ends_with?: InputMaybe<Scalars['String']['input']>
    status_ends_with_i?: InputMaybe<Scalars['String']['input']>
    status_i?: InputMaybe<Scalars['String']['input']>
    status_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status_not?: InputMaybe<Scalars['String']['input']>
    status_not_contains?: InputMaybe<Scalars['String']['input']>
    status_not_contains_i?: InputMaybe<Scalars['String']['input']>
    status_not_ends_with?: InputMaybe<Scalars['String']['input']>
    status_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    status_not_i?: InputMaybe<Scalars['String']['input']>
    status_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status_not_starts_with?: InputMaybe<Scalars['String']['input']>
    status_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    status_starts_with?: InputMaybe<Scalars['String']['input']>
    status_starts_with_i?: InputMaybe<Scalars['String']['input']>
    timeZone?: InputMaybe<Scalars['String']['input']>
    timeZone_contains?: InputMaybe<Scalars['String']['input']>
    timeZone_contains_i?: InputMaybe<Scalars['String']['input']>
    timeZone_ends_with?: InputMaybe<Scalars['String']['input']>
    timeZone_ends_with_i?: InputMaybe<Scalars['String']['input']>
    timeZone_i?: InputMaybe<Scalars['String']['input']>
    timeZone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    timeZone_not?: InputMaybe<Scalars['String']['input']>
    timeZone_not_contains?: InputMaybe<Scalars['String']['input']>
    timeZone_not_contains_i?: InputMaybe<Scalars['String']['input']>
    timeZone_not_ends_with?: InputMaybe<Scalars['String']['input']>
    timeZone_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    timeZone_not_i?: InputMaybe<Scalars['String']['input']>
    timeZone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    timeZone_not_starts_with?: InputMaybe<Scalars['String']['input']>
    timeZone_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    timeZone_starts_with?: InputMaybe<Scalars['String']['input']>
    timeZone_starts_with_i?: InputMaybe<Scalars['String']['input']>
    totalRecordsCount?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_gt?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_gte?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    totalRecordsCount_lt?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_lte?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_not?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user?: InputMaybe<Scalars['String']['input']>
    user_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user_not?: InputMaybe<Scalars['String']['input']>
    user_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    where?: InputMaybe<Scalars['JSON']['input']>
    where_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    where_not?: InputMaybe<Scalars['JSON']['input']>
    where_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
}

export type ContactExportTaskHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type ContactExportTaskHistoryRecordsCreateInput = {
    data?: InputMaybe<ContactExportTaskHistoryRecordCreateInput>
}

export type ContactExportTaskHistoryRecordsUpdateInput = {
    data?: InputMaybe<ContactExportTaskHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export enum ContactExportTaskStatusType {
    Cancelled = 'cancelled',
    Completed = 'completed',
    Error = 'error',
    Processing = 'processing',
}

export type ContactExportTaskUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['Upload']['input']>
    format?: InputMaybe<ContactExportTaskFormatType>
    locale?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sortBy?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<ContactExportTaskStatusType>
    timeZone?: InputMaybe<Scalars['String']['input']>
    totalRecordsCount?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
    where?: InputMaybe<Scalars['JSON']['input']>
}

export type ContactExportTaskWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<ContactExportTaskWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<ContactExportTaskWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    exportedRecordsCount?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_gt?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_gte?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    exportedRecordsCount_lt?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_lte?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_not?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    file?: InputMaybe<Scalars['String']['input']>
    file_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    file_not?: InputMaybe<Scalars['String']['input']>
    file_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    format?: InputMaybe<ContactExportTaskFormatType>
    format_in?: InputMaybe<Array<InputMaybe<ContactExportTaskFormatType>>>
    format_not?: InputMaybe<ContactExportTaskFormatType>
    format_not_in?: InputMaybe<Array<InputMaybe<ContactExportTaskFormatType>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    locale?: InputMaybe<Scalars['String']['input']>
    locale_contains?: InputMaybe<Scalars['String']['input']>
    locale_contains_i?: InputMaybe<Scalars['String']['input']>
    locale_ends_with?: InputMaybe<Scalars['String']['input']>
    locale_ends_with_i?: InputMaybe<Scalars['String']['input']>
    locale_i?: InputMaybe<Scalars['String']['input']>
    locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    locale_not?: InputMaybe<Scalars['String']['input']>
    locale_not_contains?: InputMaybe<Scalars['String']['input']>
    locale_not_contains_i?: InputMaybe<Scalars['String']['input']>
    locale_not_ends_with?: InputMaybe<Scalars['String']['input']>
    locale_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    locale_not_i?: InputMaybe<Scalars['String']['input']>
    locale_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    locale_not_starts_with?: InputMaybe<Scalars['String']['input']>
    locale_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    locale_starts_with?: InputMaybe<Scalars['String']['input']>
    locale_starts_with_i?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sortBy?: InputMaybe<Scalars['JSON']['input']>
    sortBy_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sortBy_not?: InputMaybe<Scalars['JSON']['input']>
    sortBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    status?: InputMaybe<ContactExportTaskStatusType>
    status_in?: InputMaybe<Array<InputMaybe<ContactExportTaskStatusType>>>
    status_not?: InputMaybe<ContactExportTaskStatusType>
    status_not_in?: InputMaybe<Array<InputMaybe<ContactExportTaskStatusType>>>
    timeZone?: InputMaybe<Scalars['String']['input']>
    timeZone_contains?: InputMaybe<Scalars['String']['input']>
    timeZone_contains_i?: InputMaybe<Scalars['String']['input']>
    timeZone_ends_with?: InputMaybe<Scalars['String']['input']>
    timeZone_ends_with_i?: InputMaybe<Scalars['String']['input']>
    timeZone_i?: InputMaybe<Scalars['String']['input']>
    timeZone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    timeZone_not?: InputMaybe<Scalars['String']['input']>
    timeZone_not_contains?: InputMaybe<Scalars['String']['input']>
    timeZone_not_contains_i?: InputMaybe<Scalars['String']['input']>
    timeZone_not_ends_with?: InputMaybe<Scalars['String']['input']>
    timeZone_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    timeZone_not_i?: InputMaybe<Scalars['String']['input']>
    timeZone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    timeZone_not_starts_with?: InputMaybe<Scalars['String']['input']>
    timeZone_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    timeZone_starts_with?: InputMaybe<Scalars['String']['input']>
    timeZone_starts_with_i?: InputMaybe<Scalars['String']['input']>
    totalRecordsCount?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_gt?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_gte?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    totalRecordsCount_lt?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_lte?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_not?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    user?: InputMaybe<UserWhereInput>
    user_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    where?: InputMaybe<Scalars['JSON']['input']>
    where_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    where_not?: InputMaybe<Scalars['JSON']['input']>
    where_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
}

export type ContactExportTaskWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type ContactExportTasksCreateInput = {
    data?: InputMaybe<ContactExportTaskCreateInput>
}

export type ContactExportTasksUpdateInput = {
    data?: InputMaybe<ContactExportTaskUpdateInput>
    id: Scalars['ID']['input']
}

/**  A keystone list  */
export type ContactHistoryRecord = {
    __typename?: 'ContactHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the ContactHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the ContactHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the ContactHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the ContactHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    email?: Maybe<Scalars['String']['output']>
    history_action?: Maybe<ContactHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    isVerified?: Maybe<Scalars['Boolean']['output']>
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    phone?: Maybe<Scalars['String']['output']>
    property?: Maybe<Scalars['String']['output']>
    quota?: Maybe<Scalars['String']['output']>
    role?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    unitName?: Maybe<Scalars['String']['output']>
    unitType?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type ContactHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    email?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<ContactHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    isVerified?: InputMaybe<Scalars['Boolean']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    phone?: InputMaybe<Scalars['String']['input']>
    property?: InputMaybe<Scalars['String']['input']>
    quota?: InputMaybe<Scalars['String']['input']>
    role?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    unitName?: InputMaybe<Scalars['String']['input']>
    unitType?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum ContactHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type ContactHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    email?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<ContactHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    isVerified?: InputMaybe<Scalars['Boolean']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    phone?: InputMaybe<Scalars['String']['input']>
    property?: InputMaybe<Scalars['String']['input']>
    quota?: InputMaybe<Scalars['String']['input']>
    role?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    unitName?: InputMaybe<Scalars['String']['input']>
    unitType?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type ContactHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<ContactHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<ContactHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    email?: InputMaybe<Scalars['String']['input']>
    email_contains?: InputMaybe<Scalars['String']['input']>
    email_contains_i?: InputMaybe<Scalars['String']['input']>
    email_ends_with?: InputMaybe<Scalars['String']['input']>
    email_ends_with_i?: InputMaybe<Scalars['String']['input']>
    email_i?: InputMaybe<Scalars['String']['input']>
    email_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    email_not?: InputMaybe<Scalars['String']['input']>
    email_not_contains?: InputMaybe<Scalars['String']['input']>
    email_not_contains_i?: InputMaybe<Scalars['String']['input']>
    email_not_ends_with?: InputMaybe<Scalars['String']['input']>
    email_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    email_not_i?: InputMaybe<Scalars['String']['input']>
    email_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    email_not_starts_with?: InputMaybe<Scalars['String']['input']>
    email_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    email_starts_with?: InputMaybe<Scalars['String']['input']>
    email_starts_with_i?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<ContactHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<ContactHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<ContactHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<ContactHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    isVerified?: InputMaybe<Scalars['Boolean']['input']>
    isVerified_not?: InputMaybe<Scalars['Boolean']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phone?: InputMaybe<Scalars['String']['input']>
    phone_contains?: InputMaybe<Scalars['String']['input']>
    phone_contains_i?: InputMaybe<Scalars['String']['input']>
    phone_ends_with?: InputMaybe<Scalars['String']['input']>
    phone_ends_with_i?: InputMaybe<Scalars['String']['input']>
    phone_i?: InputMaybe<Scalars['String']['input']>
    phone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phone_not?: InputMaybe<Scalars['String']['input']>
    phone_not_contains?: InputMaybe<Scalars['String']['input']>
    phone_not_contains_i?: InputMaybe<Scalars['String']['input']>
    phone_not_ends_with?: InputMaybe<Scalars['String']['input']>
    phone_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    phone_not_i?: InputMaybe<Scalars['String']['input']>
    phone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phone_not_starts_with?: InputMaybe<Scalars['String']['input']>
    phone_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    phone_starts_with?: InputMaybe<Scalars['String']['input']>
    phone_starts_with_i?: InputMaybe<Scalars['String']['input']>
    property?: InputMaybe<Scalars['String']['input']>
    property_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    property_not?: InputMaybe<Scalars['String']['input']>
    property_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    quota?: InputMaybe<Scalars['String']['input']>
    quota_gt?: InputMaybe<Scalars['String']['input']>
    quota_gte?: InputMaybe<Scalars['String']['input']>
    quota_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    quota_lt?: InputMaybe<Scalars['String']['input']>
    quota_lte?: InputMaybe<Scalars['String']['input']>
    quota_not?: InputMaybe<Scalars['String']['input']>
    quota_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    role?: InputMaybe<Scalars['String']['input']>
    role_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    role_not?: InputMaybe<Scalars['String']['input']>
    role_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    unitName?: InputMaybe<Scalars['String']['input']>
    unitName_contains?: InputMaybe<Scalars['String']['input']>
    unitName_contains_i?: InputMaybe<Scalars['String']['input']>
    unitName_ends_with?: InputMaybe<Scalars['String']['input']>
    unitName_ends_with_i?: InputMaybe<Scalars['String']['input']>
    unitName_i?: InputMaybe<Scalars['String']['input']>
    unitName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitName_not?: InputMaybe<Scalars['String']['input']>
    unitName_not_contains?: InputMaybe<Scalars['String']['input']>
    unitName_not_contains_i?: InputMaybe<Scalars['String']['input']>
    unitName_not_ends_with?: InputMaybe<Scalars['String']['input']>
    unitName_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    unitName_not_i?: InputMaybe<Scalars['String']['input']>
    unitName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitName_not_starts_with?: InputMaybe<Scalars['String']['input']>
    unitName_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    unitName_starts_with?: InputMaybe<Scalars['String']['input']>
    unitName_starts_with_i?: InputMaybe<Scalars['String']['input']>
    unitType?: InputMaybe<Scalars['String']['input']>
    unitType_contains?: InputMaybe<Scalars['String']['input']>
    unitType_contains_i?: InputMaybe<Scalars['String']['input']>
    unitType_ends_with?: InputMaybe<Scalars['String']['input']>
    unitType_ends_with_i?: InputMaybe<Scalars['String']['input']>
    unitType_i?: InputMaybe<Scalars['String']['input']>
    unitType_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitType_not?: InputMaybe<Scalars['String']['input']>
    unitType_not_contains?: InputMaybe<Scalars['String']['input']>
    unitType_not_contains_i?: InputMaybe<Scalars['String']['input']>
    unitType_not_ends_with?: InputMaybe<Scalars['String']['input']>
    unitType_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    unitType_not_i?: InputMaybe<Scalars['String']['input']>
    unitType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitType_not_starts_with?: InputMaybe<Scalars['String']['input']>
    unitType_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    unitType_starts_with?: InputMaybe<Scalars['String']['input']>
    unitType_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type ContactHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type ContactHistoryRecordsCreateInput = {
    data?: InputMaybe<ContactHistoryRecordCreateInput>
}

export type ContactHistoryRecordsUpdateInput = {
    data?: InputMaybe<ContactHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type ContactRelateToOneInput = {
    connect?: InputMaybe<ContactWhereUniqueInput>
    create?: InputMaybe<ContactCreateInput>
    disconnect?: InputMaybe<ContactWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

/**  Role for contact  */
export type ContactRole = {
    __typename?: 'ContactRole'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the ContactRole List config, or
   *  2. As an alias to the field set on 'labelField' in the ContactRole List config, or
   *  3. As an alias to a 'name' field on the ContactRole List (if one exists), or
   *  4. As an alias to the 'id' field on the ContactRole List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  The role's name  */
    name?: Maybe<Scalars['String']['output']>
    nameNonLocalized?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Ref to the organization. If this ref is null the object is common for all organizations  */
    organization?: Maybe<Organization>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type ContactRoleCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type ContactRoleHistoryRecord = {
    __typename?: 'ContactRoleHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the ContactRoleHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the ContactRoleHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the ContactRoleHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the ContactRoleHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<ContactRoleHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type ContactRoleHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<ContactRoleHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum ContactRoleHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type ContactRoleHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<ContactRoleHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type ContactRoleHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<ContactRoleHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<ContactRoleHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<ContactRoleHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<ContactRoleHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<ContactRoleHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<ContactRoleHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type ContactRoleHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type ContactRoleHistoryRecordsCreateInput = {
    data?: InputMaybe<ContactRoleHistoryRecordCreateInput>
}

export type ContactRoleHistoryRecordsUpdateInput = {
    data?: InputMaybe<ContactRoleHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type ContactRoleRelateToOneInput = {
    connect?: InputMaybe<ContactRoleWhereUniqueInput>
    create?: InputMaybe<ContactRoleCreateInput>
    disconnect?: InputMaybe<ContactRoleWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type ContactRoleUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type ContactRoleWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<ContactRoleWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<ContactRoleWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type ContactRoleWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type ContactRolesCreateInput = {
    data?: InputMaybe<ContactRoleCreateInput>
}

export type ContactRolesUpdateInput = {
    data?: InputMaybe<ContactRoleUpdateInput>
    id: Scalars['ID']['input']
}

export enum ContactUnitTypeType {
    Apartment = 'apartment',
    Commercial = 'commercial',
    Flat = 'flat',
    Parking = 'parking',
    Warehouse = 'warehouse',
}

export type ContactUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    email?: InputMaybe<Scalars['String']['input']>
    isVerified?: InputMaybe<Scalars['Boolean']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    phone?: InputMaybe<Scalars['String']['input']>
    property?: InputMaybe<PropertyRelateToOneInput>
    quota?: InputMaybe<Scalars['String']['input']>
    role?: InputMaybe<ContactRoleRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    unitName?: InputMaybe<Scalars['String']['input']>
    unitType?: InputMaybe<ContactUnitTypeType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type ContactWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<ContactWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<ContactWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    email?: InputMaybe<Scalars['String']['input']>
    email_contains?: InputMaybe<Scalars['String']['input']>
    email_contains_i?: InputMaybe<Scalars['String']['input']>
    email_ends_with?: InputMaybe<Scalars['String']['input']>
    email_ends_with_i?: InputMaybe<Scalars['String']['input']>
    email_i?: InputMaybe<Scalars['String']['input']>
    email_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    email_not?: InputMaybe<Scalars['String']['input']>
    email_not_contains?: InputMaybe<Scalars['String']['input']>
    email_not_contains_i?: InputMaybe<Scalars['String']['input']>
    email_not_ends_with?: InputMaybe<Scalars['String']['input']>
    email_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    email_not_i?: InputMaybe<Scalars['String']['input']>
    email_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    email_not_starts_with?: InputMaybe<Scalars['String']['input']>
    email_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    email_starts_with?: InputMaybe<Scalars['String']['input']>
    email_starts_with_i?: InputMaybe<Scalars['String']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    isVerified?: InputMaybe<Scalars['Boolean']['input']>
    isVerified_not?: InputMaybe<Scalars['Boolean']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    phone?: InputMaybe<Scalars['String']['input']>
    phone_contains?: InputMaybe<Scalars['String']['input']>
    phone_contains_i?: InputMaybe<Scalars['String']['input']>
    phone_ends_with?: InputMaybe<Scalars['String']['input']>
    phone_ends_with_i?: InputMaybe<Scalars['String']['input']>
    phone_i?: InputMaybe<Scalars['String']['input']>
    phone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phone_not?: InputMaybe<Scalars['String']['input']>
    phone_not_contains?: InputMaybe<Scalars['String']['input']>
    phone_not_contains_i?: InputMaybe<Scalars['String']['input']>
    phone_not_ends_with?: InputMaybe<Scalars['String']['input']>
    phone_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    phone_not_i?: InputMaybe<Scalars['String']['input']>
    phone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phone_not_starts_with?: InputMaybe<Scalars['String']['input']>
    phone_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    phone_starts_with?: InputMaybe<Scalars['String']['input']>
    phone_starts_with_i?: InputMaybe<Scalars['String']['input']>
    property?: InputMaybe<PropertyWhereInput>
    property_is_null?: InputMaybe<Scalars['Boolean']['input']>
    quota?: InputMaybe<Scalars['String']['input']>
    quota_gt?: InputMaybe<Scalars['String']['input']>
    quota_gte?: InputMaybe<Scalars['String']['input']>
    quota_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    quota_lt?: InputMaybe<Scalars['String']['input']>
    quota_lte?: InputMaybe<Scalars['String']['input']>
    quota_not?: InputMaybe<Scalars['String']['input']>
    quota_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    role?: InputMaybe<ContactRoleWhereInput>
    role_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    unitName?: InputMaybe<Scalars['String']['input']>
    unitName_contains?: InputMaybe<Scalars['String']['input']>
    unitName_contains_i?: InputMaybe<Scalars['String']['input']>
    unitName_ends_with?: InputMaybe<Scalars['String']['input']>
    unitName_ends_with_i?: InputMaybe<Scalars['String']['input']>
    unitName_i?: InputMaybe<Scalars['String']['input']>
    unitName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitName_not?: InputMaybe<Scalars['String']['input']>
    unitName_not_contains?: InputMaybe<Scalars['String']['input']>
    unitName_not_contains_i?: InputMaybe<Scalars['String']['input']>
    unitName_not_ends_with?: InputMaybe<Scalars['String']['input']>
    unitName_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    unitName_not_i?: InputMaybe<Scalars['String']['input']>
    unitName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitName_not_starts_with?: InputMaybe<Scalars['String']['input']>
    unitName_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    unitName_starts_with?: InputMaybe<Scalars['String']['input']>
    unitName_starts_with_i?: InputMaybe<Scalars['String']['input']>
    unitType?: InputMaybe<ContactUnitTypeType>
    unitType_in?: InputMaybe<Array<InputMaybe<ContactUnitTypeType>>>
    unitType_not?: InputMaybe<ContactUnitTypeType>
    unitType_not_in?: InputMaybe<Array<InputMaybe<ContactUnitTypeType>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type ContactWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type ContactsCreateInput = {
    data?: InputMaybe<ContactCreateInput>
}

export type ContactsUpdateInput = {
    data?: InputMaybe<ContactUpdateInput>
    id: Scalars['ID']['input']
}

export type CreateBankAccountRequestInput = {
    dv: Scalars['Int']['input']
    organizationId: Scalars['ID']['input']
    propertyId: Scalars['ID']['input']
    sender: Scalars['JSON']['input']
}

export type CreateBankAccountRequestOutput = {
    __typename?: 'CreateBankAccountRequestOutput'
    id: Scalars['ID']['output']
    status: Scalars['String']['output']
}

export type CreatePaymentByLinkInput = {
    dv: Scalars['Int']['input']
    qrCode: Scalars['String']['input']
    sender: SenderFieldInput
}

export type CreatePaymentByLinkOutput = {
    __typename?: 'CreatePaymentByLinkOutput'
    accountNumber: Scalars['String']['output']
    acquiringIntegrationHostUrl: Scalars['String']['output']
    address: Scalars['String']['output']
    addressMeta: AddressMetaField
    amount: Scalars['String']['output']
    currencyCode: Scalars['String']['output']
    explicitFee: Scalars['String']['output']
    multiPaymentId: Scalars['ID']['output']
    period: Scalars['String']['output']
    totalAmount: Scalars['String']['output']
    unitName: Scalars['String']['output']
    unitType: Scalars['String']['output']
}

export type CustomAccess = {
    __typename?: 'CustomAccess'
    accessRules?: Maybe<Array<Maybe<CustomAccessListRule>>>
}

export type CustomAccessFieldRule = {
    __typename?: 'CustomAccessFieldRule'
    create?: Maybe<Scalars['Boolean']['output']>
    field: Scalars['String']['output']
    read?: Maybe<Scalars['Boolean']['output']>
    update?: Maybe<Scalars['Boolean']['output']>
}

export type CustomAccessFieldRuleInput = {
    create?: InputMaybe<Scalars['Boolean']['input']>
    field: Scalars['String']['input']
    read?: InputMaybe<Scalars['Boolean']['input']>
    update?: InputMaybe<Scalars['Boolean']['input']>
}

export type CustomAccessInput = {
    accessRules?: InputMaybe<Array<InputMaybe<CustomAccessListRuleInput>>>
}

export type CustomAccessListRule = {
    __typename?: 'CustomAccessListRule'
    create?: Maybe<Scalars['Boolean']['output']>
    fields?: Maybe<Array<Maybe<CustomAccessFieldRule>>>
    list: Scalars['String']['output']
    read?: Maybe<Scalars['Boolean']['output']>
    update?: Maybe<Scalars['Boolean']['output']>
}

export type CustomAccessListRuleInput = {
    create?: InputMaybe<Scalars['Boolean']['input']>
    fields?: InputMaybe<Array<InputMaybe<CustomAccessFieldRuleInput>>>
    list: Scalars['String']['input']
    read?: InputMaybe<Scalars['Boolean']['input']>
    update?: InputMaybe<Scalars['Boolean']['input']>
}

export enum DevicePlatformType {
    Android = 'android',
    Ios = 'ios',
    Web = 'web',
}

export type DisconnectUserFromRemoteClientInput = {
    appId: Scalars['String']['input']
    deviceId: Scalars['String']['input']
    dv: Scalars['Int']['input']
    sender: SenderFieldInput
}

export type DisconnectUserFromRemoteClientOutput = {
    __typename?: 'DisconnectUserFromRemoteClientOutput'
    status: Scalars['String']['output']
}

export type DiscoverServiceConsumersInput = {
    billingAccountsIds: Array<Scalars['ID']['input']>
    dv: Scalars['Int']['input']
    filters?: InputMaybe<DiscoverServiceConsumersInputFilters>
    sender: SenderFieldInput
}

export type DiscoverServiceConsumersInputFilters = {
    residentsIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type DiscoverServiceConsumersOutput = {
    __typename?: 'DiscoverServiceConsumersOutput'
    statistics: DiscoverServiceConsumersOutputStatistics
    status: Scalars['String']['output']
}

export type DiscoverServiceConsumersOutputStatistics = {
    __typename?: 'DiscoverServiceConsumersOutputStatistics'
    billingAccountsFound: Scalars['Int']['output']
    created: Scalars['Int']['output']
    residentsFound: Scalars['Int']['output']
}

/**  Document with file attached to organization or property. It could be some kind of property documentation, inspection reports or other documents  */
export type Document = {
    __typename?: 'Document'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the Document List config, or
   *  2. As an alias to the field set on 'labelField' in the Document List config, or
   *  3. As an alias to a 'name' field on the Document List (if one exists), or
   *  4. As an alias to the 'id' field on the Document List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  Document type  */
    category?: Maybe<DocumentCategory>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  File attached to document  */
    file?: Maybe<File>
    id: Scalars['ID']['output']
    /**  Name of the document. By default it's file name  */
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
    organization?: Maybe<Organization>
    /**  Property to which the document is attached  */
    property?: Maybe<Property>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type DocumentCategoriesCreateInput = {
    data?: InputMaybe<DocumentCategoryCreateInput>
}

export type DocumentCategoriesUpdateInput = {
    data?: InputMaybe<DocumentCategoryUpdateInput>
    id: Scalars['ID']['input']
}

/**  Document category, for example, cleaning acts, works performed, design or technical documentation and other categories  */
export type DocumentCategory = {
    __typename?: 'DocumentCategory'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the DocumentCategory List config, or
   *  2. As an alias to the field set on 'labelField' in the DocumentCategory List config, or
   *  3. As an alias to a 'name' field on the DocumentCategory List (if one exists), or
   *  4. As an alias to the 'id' field on the DocumentCategory List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  Name of the category  */
    name?: Maybe<Scalars['String']['output']>
    nameNonLocalized?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type DocumentCategoryCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type DocumentCategoryHistoryRecord = {
    __typename?: 'DocumentCategoryHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the DocumentCategoryHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the DocumentCategoryHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the DocumentCategoryHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the DocumentCategoryHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<DocumentCategoryHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type DocumentCategoryHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<DocumentCategoryHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum DocumentCategoryHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type DocumentCategoryHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<DocumentCategoryHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type DocumentCategoryHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<DocumentCategoryHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<DocumentCategoryHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<DocumentCategoryHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<DocumentCategoryHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<DocumentCategoryHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<DocumentCategoryHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type DocumentCategoryHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type DocumentCategoryHistoryRecordsCreateInput = {
    data?: InputMaybe<DocumentCategoryHistoryRecordCreateInput>
}

export type DocumentCategoryHistoryRecordsUpdateInput = {
    data?: InputMaybe<DocumentCategoryHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type DocumentCategoryRelateToOneInput = {
    connect?: InputMaybe<DocumentCategoryWhereUniqueInput>
    create?: InputMaybe<DocumentCategoryCreateInput>
    disconnect?: InputMaybe<DocumentCategoryWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type DocumentCategoryUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type DocumentCategoryWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<DocumentCategoryWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<DocumentCategoryWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type DocumentCategoryWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type DocumentCreateInput = {
    category?: InputMaybe<DocumentCategoryRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['Upload']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    property?: InputMaybe<PropertyRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type DocumentHistoryRecord = {
    __typename?: 'DocumentHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the DocumentHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the DocumentHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the DocumentHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the DocumentHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    category?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    file?: Maybe<Scalars['JSON']['output']>
    history_action?: Maybe<DocumentHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    property?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type DocumentHistoryRecordCreateInput = {
    category?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['JSON']['input']>
    history_action?: InputMaybe<DocumentHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    property?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum DocumentHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type DocumentHistoryRecordUpdateInput = {
    category?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['JSON']['input']>
    history_action?: InputMaybe<DocumentHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    property?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type DocumentHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<DocumentHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<DocumentHistoryRecordWhereInput>>>
    category?: InputMaybe<Scalars['String']['input']>
    category_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    category_not?: InputMaybe<Scalars['String']['input']>
    category_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    file?: InputMaybe<Scalars['JSON']['input']>
    file_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    file_not?: InputMaybe<Scalars['JSON']['input']>
    file_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    history_action?: InputMaybe<DocumentHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<DocumentHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<DocumentHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<DocumentHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    property?: InputMaybe<Scalars['String']['input']>
    property_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    property_not?: InputMaybe<Scalars['String']['input']>
    property_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type DocumentHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type DocumentHistoryRecordsCreateInput = {
    data?: InputMaybe<DocumentHistoryRecordCreateInput>
}

export type DocumentHistoryRecordsUpdateInput = {
    data?: InputMaybe<DocumentHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type DocumentUpdateInput = {
    category?: InputMaybe<DocumentCategoryRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['Upload']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    property?: InputMaybe<PropertyRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type DocumentWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<DocumentWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<DocumentWhereInput>>>
    category?: InputMaybe<DocumentCategoryWhereInput>
    category_is_null?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    file?: InputMaybe<Scalars['String']['input']>
    file_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    file_not?: InputMaybe<Scalars['String']['input']>
    file_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    property?: InputMaybe<PropertyWhereInput>
    property_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type DocumentWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type DocumentsCreateInput = {
    data?: InputMaybe<DocumentCreateInput>
}

export type DocumentsUpdateInput = {
    data?: InputMaybe<DocumentUpdateInput>
    id: Scalars['ID']['input']
}

export type ExportPaymentsToExcelInput = {
    dv: Scalars['Int']['input']
    sender: SenderFieldInput
    sortBy?: InputMaybe<Array<SortPaymentsBy>>
    timeZone: Scalars['String']['input']
    where: PaymentWhereInput
}

export type ExportPaymentsToExcelOutput = {
    __typename?: 'ExportPaymentsToExcelOutput'
    linkToFile: Scalars['String']['output']
    status: Scalars['String']['output']
}

export type ExportPropertiesToExcelInput = {
    sortBy?: InputMaybe<Array<SortPropertiesBy>>
    where: PropertyWhereInput
}

export type ExportPropertiesToExcelOutput = {
    __typename?: 'ExportPropertiesToExcelOutput'
    linkToFile: Scalars['String']['output']
    status: Scalars['String']['output']
}

export type ExportPropertyMeterReadingsInput = {
    dv: Scalars['Int']['input']
    sender: SenderFieldInput
    sortBy?: InputMaybe<Array<SortPropertyMeterReadingsBy>>
    timeZone: Scalars['String']['input']
    where: PropertyMeterReadingWhereInput
}

export type ExportPropertyMeterReadingsOutput = {
    __typename?: 'ExportPropertyMeterReadingsOutput'
    linkToFile: Scalars['String']['output']
    status: Scalars['String']['output']
}

export type ExportPropertyScopeToExcelInput = {
    dv: Scalars['Int']['input']
    sender: SenderFieldInput
    sortBy?: InputMaybe<Array<SortPropertyScopesBy>>
    where: PropertyScopeWhereInput
}

export type ExportPropertyScopeToExcelOutput = {
    __typename?: 'ExportPropertyScopeToExcelOutput'
    linkToFile: Scalars['String']['output']
    status: Scalars['String']['output']
}

export type ExportTicketAnalyticsToExcelInput = {
    groupBy?: InputMaybe<Array<TicketAnalyticsGroupBy>>
    nullReplaces: TicketAnalyticsNullReplaces
    translates: ExportTicketAnalyticsToExcelTranslates
    where: TicketWhereInput
}

export type ExportTicketAnalyticsToExcelOutput = {
    __typename?: 'ExportTicketAnalyticsToExcelOutput'
    link: Scalars['String']['output']
}

export type ExportTicketAnalyticsToExcelTranslates = {
    assignee?: InputMaybe<Scalars['String']['input']>
    categoryClassifier?: InputMaybe<Scalars['String']['input']>
    executor?: InputMaybe<Scalars['String']['input']>
    property?: InputMaybe<Scalars['String']['input']>
}

/**
 *  The scheme allows the service user to get read access to all user access tokens.
 * Used to check permissions in the getAccessTokenByUserId query
 */
export type ExternalTokenAccessRight = {
    __typename?: 'ExternalTokenAccessRight'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the ExternalTokenAccessRight List config, or
   *  2. As an alias to the field set on 'labelField' in the ExternalTokenAccessRight List config, or
   *  3. As an alias to a 'name' field on the ExternalTokenAccessRight List (if one exists), or
   *  4. As an alias to the 'id' field on the ExternalTokenAccessRight List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Which service token the user has access to  */
    type?: Maybe<ExternalTokenAccessRightTypeType>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    /**  Link to user. Note, that user must be of "service" type  */
    user?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type ExternalTokenAccessRightCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    type?: InputMaybe<ExternalTokenAccessRightTypeType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type ExternalTokenAccessRightHistoryRecord = {
    __typename?: 'ExternalTokenAccessRightHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the ExternalTokenAccessRightHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the ExternalTokenAccessRightHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the ExternalTokenAccessRightHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the ExternalTokenAccessRightHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<ExternalTokenAccessRightHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    type?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    user?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type ExternalTokenAccessRightHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<ExternalTokenAccessRightHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    type?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum ExternalTokenAccessRightHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type ExternalTokenAccessRightHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<ExternalTokenAccessRightHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    type?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type ExternalTokenAccessRightHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<ExternalTokenAccessRightHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<ExternalTokenAccessRightHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<ExternalTokenAccessRightHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<ExternalTokenAccessRightHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<ExternalTokenAccessRightHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<ExternalTokenAccessRightHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    type?: InputMaybe<Scalars['String']['input']>
    type_contains?: InputMaybe<Scalars['String']['input']>
    type_contains_i?: InputMaybe<Scalars['String']['input']>
    type_ends_with?: InputMaybe<Scalars['String']['input']>
    type_ends_with_i?: InputMaybe<Scalars['String']['input']>
    type_i?: InputMaybe<Scalars['String']['input']>
    type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    type_not?: InputMaybe<Scalars['String']['input']>
    type_not_contains?: InputMaybe<Scalars['String']['input']>
    type_not_contains_i?: InputMaybe<Scalars['String']['input']>
    type_not_ends_with?: InputMaybe<Scalars['String']['input']>
    type_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    type_not_i?: InputMaybe<Scalars['String']['input']>
    type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    type_not_starts_with?: InputMaybe<Scalars['String']['input']>
    type_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    type_starts_with?: InputMaybe<Scalars['String']['input']>
    type_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user?: InputMaybe<Scalars['String']['input']>
    user_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user_not?: InputMaybe<Scalars['String']['input']>
    user_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type ExternalTokenAccessRightHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type ExternalTokenAccessRightHistoryRecordsCreateInput = {
    data?: InputMaybe<ExternalTokenAccessRightHistoryRecordCreateInput>
}

export type ExternalTokenAccessRightHistoryRecordsUpdateInput = {
    data?: InputMaybe<ExternalTokenAccessRightHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export enum ExternalTokenAccessRightTypeType {
    Sbbol = 'sbbol',
}

export type ExternalTokenAccessRightUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    type?: InputMaybe<ExternalTokenAccessRightTypeType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type ExternalTokenAccessRightWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<ExternalTokenAccessRightWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<ExternalTokenAccessRightWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    type?: InputMaybe<ExternalTokenAccessRightTypeType>
    type_in?: InputMaybe<Array<InputMaybe<ExternalTokenAccessRightTypeType>>>
    type_not?: InputMaybe<ExternalTokenAccessRightTypeType>
    type_not_in?: InputMaybe<Array<InputMaybe<ExternalTokenAccessRightTypeType>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    user?: InputMaybe<UserWhereInput>
    user_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type ExternalTokenAccessRightWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type ExternalTokenAccessRightsCreateInput = {
    data?: InputMaybe<ExternalTokenAccessRightCreateInput>
}

export type ExternalTokenAccessRightsUpdateInput = {
    data?: InputMaybe<ExternalTokenAccessRightUpdateInput>
    id: Scalars['ID']['input']
}

export type FeeDistributionField = {
    __typename?: 'FeeDistributionField'
    category?: Maybe<Scalars['String']['output']>
    maxAmount?: Maybe<Scalars['String']['output']>
    minAmount?: Maybe<Scalars['String']['output']>
    percent: Scalars['String']['output']
    recipient: Scalars['String']['output']
}

export type FeeDistributionFieldInput = {
    category?: InputMaybe<Scalars['String']['input']>
    maxAmount?: InputMaybe<Scalars['String']['input']>
    minAmount?: InputMaybe<Scalars['String']['input']>
    percent: Scalars['String']['input']
    recipient: Scalars['String']['input']
}

export enum FeedbackAdditionalOptionsType {
    HighQuality = 'highQuality',
    LowQuality = 'lowQuality',
    Quickly = 'quickly',
    Slowly = 'slowly',
}

export type File = {
    __typename?: 'File'
    encoding?: Maybe<Scalars['String']['output']>
    filename?: Maybe<Scalars['String']['output']>
    id?: Maybe<Scalars['ID']['output']>
    mimetype?: Maybe<Scalars['String']['output']>
    originalFilename?: Maybe<Scalars['String']['output']>
    path?: Maybe<Scalars['String']['output']>
    publicUrl?: Maybe<Scalars['String']['output']>
}

export type FindOrganizationByAddressAccountType = {
    __typename?: 'FindOrganizationByAddressAccountType'
    balance?: Maybe<Scalars['String']['output']>
    bankAccountNumber: Scalars['String']['output']
    category: Scalars['ID']['output']
    number: Scalars['String']['output']
    routingNumber: Scalars['String']['output']
}

export type FindOrganizationByAddressOrganizationType = {
    __typename?: 'FindOrganizationByAddressOrganizationType'
    id: Scalars['ID']['output']
    name: Scalars['String']['output']
    tin: Scalars['String']['output']
    type: OrganizationTypeType
}

export type FindOrganizationByAddressOutput = {
    __typename?: 'FindOrganizationByAddressOutput'
    account?: Maybe<FindOrganizationByAddressAccountType>
    hasBillingData: Scalars['Boolean']['output']
    hasMeters: Scalars['Boolean']['output']
    organization: FindOrganizationByAddressOrganizationType
}

export type FindOrganizationsByAddressInput = {
    accountNumber?: InputMaybe<Scalars['String']['input']>
    addressKey: Scalars['String']['input']
    tin?: InputMaybe<Scalars['String']['input']>
    unitName?: InputMaybe<Scalars['String']['input']>
    unitType?: InputMaybe<Scalars['String']['input']>
}

export type GeneratePaymentLinkCallbacksInput = {
    failureUrl: Scalars['String']['input']
    successUrl: Scalars['String']['input']
}

export type GeneratePaymentLinkInput = {
    acquiringIntegrationContext?: InputMaybe<AcquiringIntegrationContextWhereUniqueInput>
    callbacks: GeneratePaymentLinkCallbacksInput
    dv: Scalars['Int']['input']
    invoices?: InputMaybe<Array<InvoiceWhereUniqueInput>>
    receipt?: InputMaybe<BillingReceiptWhereUniqueInput>
    receiptData?: InputMaybe<GeneratePaymentLinkReceiptDataInput>
    sender: SenderFieldInput
}

export type GeneratePaymentLinkOutput = {
    __typename?: 'GeneratePaymentLinkOutput'
    dv: Scalars['Int']['output']
    paymentUrl: Scalars['String']['output']
}

export type GeneratePaymentLinkReceiptDataInput = {
    accountNumber: Scalars['String']['input']
    amount: Scalars['String']['input']
    currencyCode: Scalars['String']['input']
    periodMonth: Scalars['Int']['input']
    periodYear: Scalars['Int']['input']
}

export type GetAccessTokenByUserIdInput = {
    type: Scalars['String']['input']
    userId: Scalars['ID']['input']
}

export type GetAccessTokenByUserIdOutput = {
    __typename?: 'GetAccessTokenByUserIdOutput'
    accessToken: Scalars['ID']['output']
    ttl: Scalars['Int']['output']
}

export type GetNewsItemsRecipientsCountersInput = {
    dv: Scalars['Int']['input']
    newsItemScopes: Array<NewsItemScopeWhereInput>
    organization: OrganizationWhereUniqueInput
    sender: Scalars['JSON']['input']
}

export type GetNewsItemsRecipientsCountersOutput = {
    __typename?: 'GetNewsItemsRecipientsCountersOutput'
    propertiesCount: Scalars['Int']['output']
    receiversCount: Scalars['Int']['output']
    unitsCount: Scalars['Int']['output']
}

export type GetNewsSharingRecipientsCountersInput = {
    b2bAppContext: B2BAppContextWhereUniqueInput
    dv: Scalars['Int']['input']
    newsItemScopes: Array<NewsItemScopeWhereInput>
    sender: Scalars['JSON']['input']
}

export type GetNewsSharingRecipientsCountersOutput = {
    __typename?: 'GetNewsSharingRecipientsCountersOutput'
    receiversCount: Scalars['Int']['output']
}

export type GetNewsSharingRecipientsInput = {
    b2bAppContext: B2BAppContextWhereUniqueInput
    dv: Scalars['Int']['input']
    sender: Scalars['JSON']['input']
}

export type GetNewsSharingRecipientsOutput = {
    __typename?: 'GetNewsSharingRecipientsOutput'
    id: Scalars['String']['output']
    name: Scalars['String']['output']
    receiversCount?: Maybe<Scalars['Int']['output']>
}

export enum GetOverviewDashboardAggregatePeriod {
    Day = 'day',
    Week = 'week',
}

export type GetOverviewDashboardGroupByInput = {
    aggregatePeriod: GetOverviewDashboardAggregatePeriod
}

export type GetOverviewDashboardInput = {
    dv: Scalars['Int']['input']
    entities?: InputMaybe<Array<InputMaybe<OverviewDashboardEntities>>>
    groupBy: GetOverviewDashboardGroupByInput
    sender: Scalars['JSON']['input']
    where: GetOverviewDashboardWhereInput
}

export type GetOverviewDashboardOutput = {
    __typename?: 'GetOverviewDashboardOutput'
    overview: OverviewData
}

export type GetOverviewDashboardWhereInput = {
    dateFrom: Scalars['String']['input']
    dateTo: Scalars['String']['input']
    executorIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    organization: Scalars['String']['input']
    propertyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

export type GetPhoneByConfirmPhoneActionTokenInput = {
    captcha: Scalars['String']['input']
    token: Scalars['String']['input']
}

export type GetPhoneByConfirmPhoneActionTokenOutput = {
    __typename?: 'GetPhoneByConfirmPhoneActionTokenOutput'
    isPhoneVerified: Scalars['Boolean']['output']
    phone: Scalars['String']['output']
}

export type GetResidentExistenceByPhoneAndAddressInput = {
    dv: Scalars['Int']['input']
    phone: Scalars['String']['input']
    propertyId: Scalars['ID']['input']
    sender: SenderFieldInput
    unitName: Scalars['String']['input']
    unitType: BuildingUnitSubType
}

export type GetResidentExistenceByPhoneAndAddressOutput = {
    __typename?: 'GetResidentExistenceByPhoneAndAddressOutput'
    hasResident: Scalars['Boolean']['output']
    hasResidentOnAddress: Scalars['Boolean']['output']
}

/**  Entries of mass planned and emergency incidents with water, electricity, etc.  */
export type Incident = {
    __typename?: 'Incident'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the Incident List config, or
   *  2. As an alias to the field set on 'labelField' in the Incident List config, or
   *  3. As an alias to a 'name' field on the Incident List (if one exists), or
   *  4. As an alias to the 'id' field on the Incident List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Text description of the incident  */
    details?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  True if incident includes all properties in organization  */
    hasAllProperties?: Maybe<Scalars['Boolean']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['String']['output']>
    /**  Autogenerated incident human readable ID  */
    number?: Maybe<Scalars['Int']['output']>
    /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
    organization?: Maybe<Organization>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Incident status indicates the actuality of the incident  */
    status?: Maybe<IncidentStatusType>
    /**  Text that employees should say to residents  */
    textForResident?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
    /**  Finish date of work related to the incident (seconds and milliseconds are forced to zero)  */
    workFinish?: Maybe<Scalars['String']['output']>
    /**  Start date of work related to the incident (seconds and milliseconds are forced to zero)  */
    workStart?: Maybe<Scalars['String']['output']>
    /**  Type of work that is carried out during the incident: emergency or scheduled  */
    workType?: Maybe<IncidentWorkTypeType>
}

/**  Incremental changes of Incident  */
export type IncidentChange = {
    __typename?: 'IncidentChange'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the IncidentChange List config, or
   *  2. As an alias to the field set on 'labelField' in the IncidentChange List config, or
   *  3. As an alias to a 'name' field on the IncidentChange List (if one exists), or
   *  4. As an alias to the 'id' field on the IncidentChange List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  Type of user who changed the incident, can be employee role from same organization or related, resident or deleted employee  */
    changedByRole?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    /**  Text description of the incident  */
    detailsFrom?: Maybe<Scalars['String']['output']>
    /**  Text description of the incident  */
    detailsTo?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  Related incident, whose change is logged in this entity  */
    incident?: Maybe<Incident>
    /**  Old display name of related entity. Ref to the organization. The object will be deleted if the organization ceases to exist  */
    organizationDisplayNameFrom?: Maybe<Scalars['String']['output']>
    /**  New display name of related entity. Ref to the organization. The object will be deleted if the organization ceases to exist  */
    organizationDisplayNameTo?: Maybe<Scalars['String']['output']>
    /**  Old id of related entity. Ref to the organization. The object will be deleted if the organization ceases to exist  */
    organizationIdFrom?: Maybe<Scalars['ID']['output']>
    /**  New id of related entity. Ref to the organization. The object will be deleted if the organization ceases to exist  */
    organizationIdTo?: Maybe<Scalars['ID']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Incident status indicates the actuality of the incident  */
    statusFrom?: Maybe<IncidentChangeStatusFromType>
    /**  Incident status indicates the actuality of the incident  */
    statusTo?: Maybe<IncidentChangeStatusToType>
    /**  Text that employees should say to residents  */
    textForResidentFrom?: Maybe<Scalars['String']['output']>
    /**  Text that employees should say to residents  */
    textForResidentTo?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
    /**  Finish date of work related to the incident (seconds and milliseconds are forced to zero)  */
    workFinishFrom?: Maybe<Scalars['String']['output']>
    /**  Finish date of work related to the incident (seconds and milliseconds are forced to zero)  */
    workFinishTo?: Maybe<Scalars['String']['output']>
    /**  Start date of work related to the incident (seconds and milliseconds are forced to zero)  */
    workStartFrom?: Maybe<Scalars['String']['output']>
    /**  Start date of work related to the incident (seconds and milliseconds are forced to zero)  */
    workStartTo?: Maybe<Scalars['String']['output']>
    /**  Type of work that is carried out during the incident: emergency or scheduled  */
    workTypeFrom?: Maybe<IncidentChangeWorkTypeFromType>
    /**  Type of work that is carried out during the incident: emergency or scheduled  */
    workTypeTo?: Maybe<IncidentChangeWorkTypeToType>
}

export type IncidentChangeCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    detailsFrom?: InputMaybe<Scalars['String']['input']>
    detailsTo?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    incident?: InputMaybe<IncidentRelateToOneInput>
    organizationDisplayNameFrom?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameTo?: InputMaybe<Scalars['String']['input']>
    organizationIdFrom?: InputMaybe<Scalars['ID']['input']>
    organizationIdTo?: InputMaybe<Scalars['ID']['input']>
    sender?: InputMaybe<SenderFieldInput>
    statusFrom?: InputMaybe<IncidentChangeStatusFromType>
    statusTo?: InputMaybe<IncidentChangeStatusToType>
    textForResidentFrom?: InputMaybe<Scalars['String']['input']>
    textForResidentTo?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
    workFinishFrom?: InputMaybe<Scalars['String']['input']>
    workFinishTo?: InputMaybe<Scalars['String']['input']>
    workStartFrom?: InputMaybe<Scalars['String']['input']>
    workStartTo?: InputMaybe<Scalars['String']['input']>
    workTypeFrom?: InputMaybe<IncidentChangeWorkTypeFromType>
    workTypeTo?: InputMaybe<IncidentChangeWorkTypeToType>
}

export enum IncidentChangeStatusFromType {
    Actual = 'actual',
    NotActual = 'not_actual',
}

export enum IncidentChangeStatusToType {
    Actual = 'actual',
    NotActual = 'not_actual',
}

export type IncidentChangeUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    detailsFrom?: InputMaybe<Scalars['String']['input']>
    detailsTo?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    incident?: InputMaybe<IncidentRelateToOneInput>
    organizationDisplayNameFrom?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameTo?: InputMaybe<Scalars['String']['input']>
    organizationIdFrom?: InputMaybe<Scalars['ID']['input']>
    organizationIdTo?: InputMaybe<Scalars['ID']['input']>
    sender?: InputMaybe<SenderFieldInput>
    statusFrom?: InputMaybe<IncidentChangeStatusFromType>
    statusTo?: InputMaybe<IncidentChangeStatusToType>
    textForResidentFrom?: InputMaybe<Scalars['String']['input']>
    textForResidentTo?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
    workFinishFrom?: InputMaybe<Scalars['String']['input']>
    workFinishTo?: InputMaybe<Scalars['String']['input']>
    workStartFrom?: InputMaybe<Scalars['String']['input']>
    workStartTo?: InputMaybe<Scalars['String']['input']>
    workTypeFrom?: InputMaybe<IncidentChangeWorkTypeFromType>
    workTypeTo?: InputMaybe<IncidentChangeWorkTypeToType>
}

export type IncidentChangeWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<IncidentChangeWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<IncidentChangeWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    detailsFrom?: InputMaybe<Scalars['String']['input']>
    detailsFrom_contains?: InputMaybe<Scalars['String']['input']>
    detailsFrom_contains_i?: InputMaybe<Scalars['String']['input']>
    detailsFrom_ends_with?: InputMaybe<Scalars['String']['input']>
    detailsFrom_ends_with_i?: InputMaybe<Scalars['String']['input']>
    detailsFrom_i?: InputMaybe<Scalars['String']['input']>
    detailsFrom_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    detailsFrom_not?: InputMaybe<Scalars['String']['input']>
    detailsFrom_not_contains?: InputMaybe<Scalars['String']['input']>
    detailsFrom_not_contains_i?: InputMaybe<Scalars['String']['input']>
    detailsFrom_not_ends_with?: InputMaybe<Scalars['String']['input']>
    detailsFrom_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    detailsFrom_not_i?: InputMaybe<Scalars['String']['input']>
    detailsFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    detailsFrom_not_starts_with?: InputMaybe<Scalars['String']['input']>
    detailsFrom_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    detailsFrom_starts_with?: InputMaybe<Scalars['String']['input']>
    detailsFrom_starts_with_i?: InputMaybe<Scalars['String']['input']>
    detailsTo?: InputMaybe<Scalars['String']['input']>
    detailsTo_contains?: InputMaybe<Scalars['String']['input']>
    detailsTo_contains_i?: InputMaybe<Scalars['String']['input']>
    detailsTo_ends_with?: InputMaybe<Scalars['String']['input']>
    detailsTo_ends_with_i?: InputMaybe<Scalars['String']['input']>
    detailsTo_i?: InputMaybe<Scalars['String']['input']>
    detailsTo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    detailsTo_not?: InputMaybe<Scalars['String']['input']>
    detailsTo_not_contains?: InputMaybe<Scalars['String']['input']>
    detailsTo_not_contains_i?: InputMaybe<Scalars['String']['input']>
    detailsTo_not_ends_with?: InputMaybe<Scalars['String']['input']>
    detailsTo_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    detailsTo_not_i?: InputMaybe<Scalars['String']['input']>
    detailsTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    detailsTo_not_starts_with?: InputMaybe<Scalars['String']['input']>
    detailsTo_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    detailsTo_starts_with?: InputMaybe<Scalars['String']['input']>
    detailsTo_starts_with_i?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    incident?: InputMaybe<IncidentWhereInput>
    incident_is_null?: InputMaybe<Scalars['Boolean']['input']>
    organizationDisplayNameFrom?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameFrom_contains?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameFrom_contains_i?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameFrom_ends_with?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameFrom_ends_with_i?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameFrom_i?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameFrom_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organizationDisplayNameFrom_not?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameFrom_not_contains?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameFrom_not_contains_i?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameFrom_not_ends_with?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameFrom_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameFrom_not_i?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organizationDisplayNameFrom_not_starts_with?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameFrom_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameFrom_starts_with?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameFrom_starts_with_i?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameTo?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameTo_contains?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameTo_contains_i?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameTo_ends_with?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameTo_ends_with_i?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameTo_i?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameTo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organizationDisplayNameTo_not?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameTo_not_contains?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameTo_not_contains_i?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameTo_not_ends_with?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameTo_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameTo_not_i?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organizationDisplayNameTo_not_starts_with?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameTo_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameTo_starts_with?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameTo_starts_with_i?: InputMaybe<Scalars['String']['input']>
    organizationIdFrom?: InputMaybe<Scalars['ID']['input']>
    organizationIdFrom_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    organizationIdFrom_not?: InputMaybe<Scalars['ID']['input']>
    organizationIdFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    organizationIdTo?: InputMaybe<Scalars['ID']['input']>
    organizationIdTo_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    organizationIdTo_not?: InputMaybe<Scalars['ID']['input']>
    organizationIdTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    statusFrom?: InputMaybe<IncidentChangeStatusFromType>
    statusFrom_in?: InputMaybe<Array<InputMaybe<IncidentChangeStatusFromType>>>
    statusFrom_not?: InputMaybe<IncidentChangeStatusFromType>
    statusFrom_not_in?: InputMaybe<Array<InputMaybe<IncidentChangeStatusFromType>>>
    statusTo?: InputMaybe<IncidentChangeStatusToType>
    statusTo_in?: InputMaybe<Array<InputMaybe<IncidentChangeStatusToType>>>
    statusTo_not?: InputMaybe<IncidentChangeStatusToType>
    statusTo_not_in?: InputMaybe<Array<InputMaybe<IncidentChangeStatusToType>>>
    textForResidentFrom?: InputMaybe<Scalars['String']['input']>
    textForResidentFrom_contains?: InputMaybe<Scalars['String']['input']>
    textForResidentFrom_contains_i?: InputMaybe<Scalars['String']['input']>
    textForResidentFrom_ends_with?: InputMaybe<Scalars['String']['input']>
    textForResidentFrom_ends_with_i?: InputMaybe<Scalars['String']['input']>
    textForResidentFrom_i?: InputMaybe<Scalars['String']['input']>
    textForResidentFrom_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    textForResidentFrom_not?: InputMaybe<Scalars['String']['input']>
    textForResidentFrom_not_contains?: InputMaybe<Scalars['String']['input']>
    textForResidentFrom_not_contains_i?: InputMaybe<Scalars['String']['input']>
    textForResidentFrom_not_ends_with?: InputMaybe<Scalars['String']['input']>
    textForResidentFrom_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    textForResidentFrom_not_i?: InputMaybe<Scalars['String']['input']>
    textForResidentFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    textForResidentFrom_not_starts_with?: InputMaybe<Scalars['String']['input']>
    textForResidentFrom_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    textForResidentFrom_starts_with?: InputMaybe<Scalars['String']['input']>
    textForResidentFrom_starts_with_i?: InputMaybe<Scalars['String']['input']>
    textForResidentTo?: InputMaybe<Scalars['String']['input']>
    textForResidentTo_contains?: InputMaybe<Scalars['String']['input']>
    textForResidentTo_contains_i?: InputMaybe<Scalars['String']['input']>
    textForResidentTo_ends_with?: InputMaybe<Scalars['String']['input']>
    textForResidentTo_ends_with_i?: InputMaybe<Scalars['String']['input']>
    textForResidentTo_i?: InputMaybe<Scalars['String']['input']>
    textForResidentTo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    textForResidentTo_not?: InputMaybe<Scalars['String']['input']>
    textForResidentTo_not_contains?: InputMaybe<Scalars['String']['input']>
    textForResidentTo_not_contains_i?: InputMaybe<Scalars['String']['input']>
    textForResidentTo_not_ends_with?: InputMaybe<Scalars['String']['input']>
    textForResidentTo_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    textForResidentTo_not_i?: InputMaybe<Scalars['String']['input']>
    textForResidentTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    textForResidentTo_not_starts_with?: InputMaybe<Scalars['String']['input']>
    textForResidentTo_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    textForResidentTo_starts_with?: InputMaybe<Scalars['String']['input']>
    textForResidentTo_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    workFinishFrom?: InputMaybe<Scalars['String']['input']>
    workFinishFrom_gt?: InputMaybe<Scalars['String']['input']>
    workFinishFrom_gte?: InputMaybe<Scalars['String']['input']>
    workFinishFrom_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    workFinishFrom_lt?: InputMaybe<Scalars['String']['input']>
    workFinishFrom_lte?: InputMaybe<Scalars['String']['input']>
    workFinishFrom_not?: InputMaybe<Scalars['String']['input']>
    workFinishFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    workFinishTo?: InputMaybe<Scalars['String']['input']>
    workFinishTo_gt?: InputMaybe<Scalars['String']['input']>
    workFinishTo_gte?: InputMaybe<Scalars['String']['input']>
    workFinishTo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    workFinishTo_lt?: InputMaybe<Scalars['String']['input']>
    workFinishTo_lte?: InputMaybe<Scalars['String']['input']>
    workFinishTo_not?: InputMaybe<Scalars['String']['input']>
    workFinishTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    workStartFrom?: InputMaybe<Scalars['String']['input']>
    workStartFrom_gt?: InputMaybe<Scalars['String']['input']>
    workStartFrom_gte?: InputMaybe<Scalars['String']['input']>
    workStartFrom_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    workStartFrom_lt?: InputMaybe<Scalars['String']['input']>
    workStartFrom_lte?: InputMaybe<Scalars['String']['input']>
    workStartFrom_not?: InputMaybe<Scalars['String']['input']>
    workStartFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    workStartTo?: InputMaybe<Scalars['String']['input']>
    workStartTo_gt?: InputMaybe<Scalars['String']['input']>
    workStartTo_gte?: InputMaybe<Scalars['String']['input']>
    workStartTo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    workStartTo_lt?: InputMaybe<Scalars['String']['input']>
    workStartTo_lte?: InputMaybe<Scalars['String']['input']>
    workStartTo_not?: InputMaybe<Scalars['String']['input']>
    workStartTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    workTypeFrom?: InputMaybe<IncidentChangeWorkTypeFromType>
    workTypeFrom_in?: InputMaybe<Array<InputMaybe<IncidentChangeWorkTypeFromType>>>
    workTypeFrom_not?: InputMaybe<IncidentChangeWorkTypeFromType>
    workTypeFrom_not_in?: InputMaybe<Array<InputMaybe<IncidentChangeWorkTypeFromType>>>
    workTypeTo?: InputMaybe<IncidentChangeWorkTypeToType>
    workTypeTo_in?: InputMaybe<Array<InputMaybe<IncidentChangeWorkTypeToType>>>
    workTypeTo_not?: InputMaybe<IncidentChangeWorkTypeToType>
    workTypeTo_not_in?: InputMaybe<Array<InputMaybe<IncidentChangeWorkTypeToType>>>
}

export type IncidentChangeWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export enum IncidentChangeWorkTypeFromType {
    Emergency = 'emergency',
    Scheduled = 'scheduled',
}

export enum IncidentChangeWorkTypeToType {
    Emergency = 'emergency',
    Scheduled = 'scheduled',
}

export type IncidentChangesCreateInput = {
    data?: InputMaybe<IncidentChangeCreateInput>
}

export type IncidentChangesUpdateInput = {
    data?: InputMaybe<IncidentChangeUpdateInput>
    id: Scalars['ID']['input']
}

/**  Rules for all possible valid combinations of classifiers for incidents  */
export type IncidentClassifier = {
    __typename?: 'IncidentClassifier'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the IncidentClassifier List config, or
   *  2. As an alias to the field set on 'labelField' in the IncidentClassifier List config, or
   *  3. As an alias to a 'name' field on the IncidentClassifier List (if one exists), or
   *  4. As an alias to the 'id' field on the IncidentClassifier List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  Type of work to fix incident  */
    category?: Maybe<TicketCategoryClassifier>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['String']['output']>
    /**  Ref to the organization. If this ref is null the object is common for all organizations  */
    organization?: Maybe<Organization>
    /**  What needs to be done  */
    problem?: Maybe<TicketProblemClassifier>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type IncidentClassifierCreateInput = {
    category?: InputMaybe<TicketCategoryClassifierRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    problem?: InputMaybe<TicketProblemClassifierRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type IncidentClassifierHistoryRecord = {
    __typename?: 'IncidentClassifierHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the IncidentClassifierHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the IncidentClassifierHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the IncidentClassifierHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the IncidentClassifierHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    category?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<IncidentClassifierHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    problem?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type IncidentClassifierHistoryRecordCreateInput = {
    category?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<IncidentClassifierHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    problem?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum IncidentClassifierHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type IncidentClassifierHistoryRecordUpdateInput = {
    category?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<IncidentClassifierHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    problem?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type IncidentClassifierHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<IncidentClassifierHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<IncidentClassifierHistoryRecordWhereInput>>>
    category?: InputMaybe<Scalars['String']['input']>
    category_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    category_not?: InputMaybe<Scalars['String']['input']>
    category_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<IncidentClassifierHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<IncidentClassifierHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<IncidentClassifierHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<IncidentClassifierHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    problem?: InputMaybe<Scalars['String']['input']>
    problem_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    problem_not?: InputMaybe<Scalars['String']['input']>
    problem_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type IncidentClassifierHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type IncidentClassifierHistoryRecordsCreateInput = {
    data?: InputMaybe<IncidentClassifierHistoryRecordCreateInput>
}

export type IncidentClassifierHistoryRecordsUpdateInput = {
    data?: InputMaybe<IncidentClassifierHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

/**  Many-to-many relationship between Incident and IncidentClassifier  */
export type IncidentClassifierIncident = {
    __typename?: 'IncidentClassifierIncident'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the IncidentClassifierIncident List config, or
   *  2. As an alias to the field set on 'labelField' in the IncidentClassifierIncident List config, or
   *  3. As an alias to a 'name' field on the IncidentClassifierIncident List (if one exists), or
   *  4. As an alias to the 'id' field on the IncidentClassifierIncident List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  A classifier which is in the incident entry  */
    classifier?: Maybe<IncidentClassifier>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  Incident which has a classifier  */
    incident?: Maybe<Incident>
    newId?: Maybe<Scalars['String']['output']>
    /**  Ref to the organization. It is filled in on the server and is read-only  */
    organization?: Maybe<Organization>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type IncidentClassifierIncidentCreateInput = {
    classifier?: InputMaybe<IncidentClassifierRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    incident?: InputMaybe<IncidentRelateToOneInput>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type IncidentClassifierIncidentHistoryRecord = {
    __typename?: 'IncidentClassifierIncidentHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the IncidentClassifierIncidentHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the IncidentClassifierIncidentHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the IncidentClassifierIncidentHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the IncidentClassifierIncidentHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    classifier?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<IncidentClassifierIncidentHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    incident?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type IncidentClassifierIncidentHistoryRecordCreateInput = {
    classifier?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<IncidentClassifierIncidentHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    incident?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum IncidentClassifierIncidentHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type IncidentClassifierIncidentHistoryRecordUpdateInput = {
    classifier?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<IncidentClassifierIncidentHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    incident?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type IncidentClassifierIncidentHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<IncidentClassifierIncidentHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<IncidentClassifierIncidentHistoryRecordWhereInput>>>
    classifier?: InputMaybe<Scalars['String']['input']>
    classifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    classifier_not?: InputMaybe<Scalars['String']['input']>
    classifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<IncidentClassifierIncidentHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<IncidentClassifierIncidentHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<IncidentClassifierIncidentHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<IncidentClassifierIncidentHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    incident?: InputMaybe<Scalars['String']['input']>
    incident_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    incident_not?: InputMaybe<Scalars['String']['input']>
    incident_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type IncidentClassifierIncidentHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type IncidentClassifierIncidentHistoryRecordsCreateInput = {
    data?: InputMaybe<IncidentClassifierIncidentHistoryRecordCreateInput>
}

export type IncidentClassifierIncidentHistoryRecordsUpdateInput = {
    data?: InputMaybe<IncidentClassifierIncidentHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type IncidentClassifierIncidentUpdateInput = {
    classifier?: InputMaybe<IncidentClassifierRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    incident?: InputMaybe<IncidentRelateToOneInput>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type IncidentClassifierIncidentWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<IncidentClassifierIncidentWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<IncidentClassifierIncidentWhereInput>>>
    classifier?: InputMaybe<IncidentClassifierWhereInput>
    classifier_is_null?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    incident?: InputMaybe<IncidentWhereInput>
    incident_is_null?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type IncidentClassifierIncidentWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type IncidentClassifierIncidentsCreateInput = {
    data?: InputMaybe<IncidentClassifierIncidentCreateInput>
}

export type IncidentClassifierIncidentsUpdateInput = {
    data?: InputMaybe<IncidentClassifierIncidentUpdateInput>
    id: Scalars['ID']['input']
}

export type IncidentClassifierRelateToOneInput = {
    connect?: InputMaybe<IncidentClassifierWhereUniqueInput>
    create?: InputMaybe<IncidentClassifierCreateInput>
    disconnect?: InputMaybe<IncidentClassifierWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type IncidentClassifierUpdateInput = {
    category?: InputMaybe<TicketCategoryClassifierRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    problem?: InputMaybe<TicketProblemClassifierRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type IncidentClassifierWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<IncidentClassifierWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<IncidentClassifierWhereInput>>>
    category?: InputMaybe<TicketCategoryClassifierWhereInput>
    category_is_null?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    problem?: InputMaybe<TicketProblemClassifierWhereInput>
    problem_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type IncidentClassifierWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type IncidentClassifiersCreateInput = {
    data?: InputMaybe<IncidentClassifierCreateInput>
}

export type IncidentClassifiersUpdateInput = {
    data?: InputMaybe<IncidentClassifierUpdateInput>
    id: Scalars['ID']['input']
}

export type IncidentCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    details?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    hasAllProperties?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    number?: InputMaybe<Scalars['Int']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    status?: InputMaybe<IncidentStatusType>
    textForResident?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
    workFinish?: InputMaybe<Scalars['String']['input']>
    workStart?: InputMaybe<Scalars['String']['input']>
    workType?: InputMaybe<IncidentWorkTypeType>
}

/**  Stores requested export format, status of export job, link to exported file and information about progress of export job  */
export type IncidentExportTask = {
    __typename?: 'IncidentExportTask'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the IncidentExportTask List config, or
   *  2. As an alias to the field set on 'labelField' in the IncidentExportTask List config, or
   *  3. As an alias to a 'name' field on the IncidentExportTask List (if one exists), or
   *  4. As an alias to the 'id' field on the IncidentExportTask List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  How many records at the moment are exported  */
    exportedRecordsCount?: Maybe<Scalars['Int']['output']>
    /**  Meta information about file, saved outside of database somewhere. Shape of meta information JSON object is specific to file adapter, used by saving a file.  */
    file?: Maybe<File>
    /**  Requested export file format  */
    format?: Maybe<IncidentExportTaskFormatType>
    id: Scalars['ID']['output']
    /**  Requested export locale, in that the resulting file will be rendered  */
    locale?: Maybe<Scalars['String']['output']>
    /**  Stores information about query and ids of exported and failed records  */
    meta?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Sorting parameters for records to export  */
    sortBy?: Maybe<Scalars['JSON']['output']>
    /**  Status of the job. Used at client side progress information component  */
    status?: Maybe<IncidentExportTaskStatusType>
    /**  To requested timeZone all datetime fields will be converted  */
    timeZone?: Maybe<Scalars['String']['output']>
    /**  Total records to export that will be determined at server side in export operation  */
    totalRecordsCount?: Maybe<Scalars['Int']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    /**  User that requested this exporting operation. Will be used for read access checks to display all exported tasks somewhere and to display progress indicator of ongoing exporting task for current user  */
    user?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
    /**  Filtering conditions for records to export  */
    where?: Maybe<Scalars['JSON']['output']>
}

export type IncidentExportTaskCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['Upload']['input']>
    format?: InputMaybe<IncidentExportTaskFormatType>
    locale?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sortBy?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<IncidentExportTaskStatusType>
    timeZone?: InputMaybe<Scalars['String']['input']>
    totalRecordsCount?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
    where?: InputMaybe<Scalars['JSON']['input']>
}

export enum IncidentExportTaskFormatType {
    Excel = 'excel',
}

/**  A keystone list  */
export type IncidentExportTaskHistoryRecord = {
    __typename?: 'IncidentExportTaskHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the IncidentExportTaskHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the IncidentExportTaskHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the IncidentExportTaskHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the IncidentExportTaskHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    exportedRecordsCount?: Maybe<Scalars['Int']['output']>
    file?: Maybe<Scalars['JSON']['output']>
    format?: Maybe<Scalars['String']['output']>
    history_action?: Maybe<IncidentExportTaskHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    locale?: Maybe<Scalars['String']['output']>
    meta?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    sortBy?: Maybe<Scalars['JSON']['output']>
    status?: Maybe<Scalars['String']['output']>
    timeZone?: Maybe<Scalars['String']['output']>
    totalRecordsCount?: Maybe<Scalars['Int']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    user?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
    where?: Maybe<Scalars['JSON']['output']>
}

export type IncidentExportTaskHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['JSON']['input']>
    format?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<IncidentExportTaskHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    locale?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sortBy?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    timeZone?: InputMaybe<Scalars['String']['input']>
    totalRecordsCount?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    where?: InputMaybe<Scalars['JSON']['input']>
}

export enum IncidentExportTaskHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type IncidentExportTaskHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['JSON']['input']>
    format?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<IncidentExportTaskHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    locale?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sortBy?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    timeZone?: InputMaybe<Scalars['String']['input']>
    totalRecordsCount?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    where?: InputMaybe<Scalars['JSON']['input']>
}

export type IncidentExportTaskHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<IncidentExportTaskHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<IncidentExportTaskHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    exportedRecordsCount?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_gt?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_gte?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    exportedRecordsCount_lt?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_lte?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_not?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    file?: InputMaybe<Scalars['JSON']['input']>
    file_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    file_not?: InputMaybe<Scalars['JSON']['input']>
    file_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    format?: InputMaybe<Scalars['String']['input']>
    format_contains?: InputMaybe<Scalars['String']['input']>
    format_contains_i?: InputMaybe<Scalars['String']['input']>
    format_ends_with?: InputMaybe<Scalars['String']['input']>
    format_ends_with_i?: InputMaybe<Scalars['String']['input']>
    format_i?: InputMaybe<Scalars['String']['input']>
    format_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    format_not?: InputMaybe<Scalars['String']['input']>
    format_not_contains?: InputMaybe<Scalars['String']['input']>
    format_not_contains_i?: InputMaybe<Scalars['String']['input']>
    format_not_ends_with?: InputMaybe<Scalars['String']['input']>
    format_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    format_not_i?: InputMaybe<Scalars['String']['input']>
    format_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    format_not_starts_with?: InputMaybe<Scalars['String']['input']>
    format_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    format_starts_with?: InputMaybe<Scalars['String']['input']>
    format_starts_with_i?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<IncidentExportTaskHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<IncidentExportTaskHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<IncidentExportTaskHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<IncidentExportTaskHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    locale?: InputMaybe<Scalars['String']['input']>
    locale_contains?: InputMaybe<Scalars['String']['input']>
    locale_contains_i?: InputMaybe<Scalars['String']['input']>
    locale_ends_with?: InputMaybe<Scalars['String']['input']>
    locale_ends_with_i?: InputMaybe<Scalars['String']['input']>
    locale_i?: InputMaybe<Scalars['String']['input']>
    locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    locale_not?: InputMaybe<Scalars['String']['input']>
    locale_not_contains?: InputMaybe<Scalars['String']['input']>
    locale_not_contains_i?: InputMaybe<Scalars['String']['input']>
    locale_not_ends_with?: InputMaybe<Scalars['String']['input']>
    locale_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    locale_not_i?: InputMaybe<Scalars['String']['input']>
    locale_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    locale_not_starts_with?: InputMaybe<Scalars['String']['input']>
    locale_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    locale_starts_with?: InputMaybe<Scalars['String']['input']>
    locale_starts_with_i?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sortBy?: InputMaybe<Scalars['JSON']['input']>
    sortBy_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sortBy_not?: InputMaybe<Scalars['JSON']['input']>
    sortBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    status?: InputMaybe<Scalars['String']['input']>
    status_contains?: InputMaybe<Scalars['String']['input']>
    status_contains_i?: InputMaybe<Scalars['String']['input']>
    status_ends_with?: InputMaybe<Scalars['String']['input']>
    status_ends_with_i?: InputMaybe<Scalars['String']['input']>
    status_i?: InputMaybe<Scalars['String']['input']>
    status_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status_not?: InputMaybe<Scalars['String']['input']>
    status_not_contains?: InputMaybe<Scalars['String']['input']>
    status_not_contains_i?: InputMaybe<Scalars['String']['input']>
    status_not_ends_with?: InputMaybe<Scalars['String']['input']>
    status_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    status_not_i?: InputMaybe<Scalars['String']['input']>
    status_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status_not_starts_with?: InputMaybe<Scalars['String']['input']>
    status_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    status_starts_with?: InputMaybe<Scalars['String']['input']>
    status_starts_with_i?: InputMaybe<Scalars['String']['input']>
    timeZone?: InputMaybe<Scalars['String']['input']>
    timeZone_contains?: InputMaybe<Scalars['String']['input']>
    timeZone_contains_i?: InputMaybe<Scalars['String']['input']>
    timeZone_ends_with?: InputMaybe<Scalars['String']['input']>
    timeZone_ends_with_i?: InputMaybe<Scalars['String']['input']>
    timeZone_i?: InputMaybe<Scalars['String']['input']>
    timeZone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    timeZone_not?: InputMaybe<Scalars['String']['input']>
    timeZone_not_contains?: InputMaybe<Scalars['String']['input']>
    timeZone_not_contains_i?: InputMaybe<Scalars['String']['input']>
    timeZone_not_ends_with?: InputMaybe<Scalars['String']['input']>
    timeZone_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    timeZone_not_i?: InputMaybe<Scalars['String']['input']>
    timeZone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    timeZone_not_starts_with?: InputMaybe<Scalars['String']['input']>
    timeZone_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    timeZone_starts_with?: InputMaybe<Scalars['String']['input']>
    timeZone_starts_with_i?: InputMaybe<Scalars['String']['input']>
    totalRecordsCount?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_gt?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_gte?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    totalRecordsCount_lt?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_lte?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_not?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user?: InputMaybe<Scalars['String']['input']>
    user_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user_not?: InputMaybe<Scalars['String']['input']>
    user_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    where?: InputMaybe<Scalars['JSON']['input']>
    where_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    where_not?: InputMaybe<Scalars['JSON']['input']>
    where_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
}

export type IncidentExportTaskHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type IncidentExportTaskHistoryRecordsCreateInput = {
    data?: InputMaybe<IncidentExportTaskHistoryRecordCreateInput>
}

export type IncidentExportTaskHistoryRecordsUpdateInput = {
    data?: InputMaybe<IncidentExportTaskHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export enum IncidentExportTaskStatusType {
    Cancelled = 'cancelled',
    Completed = 'completed',
    Error = 'error',
    Processing = 'processing',
}

export type IncidentExportTaskUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['Upload']['input']>
    format?: InputMaybe<IncidentExportTaskFormatType>
    locale?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sortBy?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<IncidentExportTaskStatusType>
    timeZone?: InputMaybe<Scalars['String']['input']>
    totalRecordsCount?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
    where?: InputMaybe<Scalars['JSON']['input']>
}

export type IncidentExportTaskWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<IncidentExportTaskWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<IncidentExportTaskWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    exportedRecordsCount?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_gt?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_gte?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    exportedRecordsCount_lt?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_lte?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_not?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    file?: InputMaybe<Scalars['String']['input']>
    file_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    file_not?: InputMaybe<Scalars['String']['input']>
    file_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    format?: InputMaybe<IncidentExportTaskFormatType>
    format_in?: InputMaybe<Array<InputMaybe<IncidentExportTaskFormatType>>>
    format_not?: InputMaybe<IncidentExportTaskFormatType>
    format_not_in?: InputMaybe<Array<InputMaybe<IncidentExportTaskFormatType>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    locale?: InputMaybe<Scalars['String']['input']>
    locale_contains?: InputMaybe<Scalars['String']['input']>
    locale_contains_i?: InputMaybe<Scalars['String']['input']>
    locale_ends_with?: InputMaybe<Scalars['String']['input']>
    locale_ends_with_i?: InputMaybe<Scalars['String']['input']>
    locale_i?: InputMaybe<Scalars['String']['input']>
    locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    locale_not?: InputMaybe<Scalars['String']['input']>
    locale_not_contains?: InputMaybe<Scalars['String']['input']>
    locale_not_contains_i?: InputMaybe<Scalars['String']['input']>
    locale_not_ends_with?: InputMaybe<Scalars['String']['input']>
    locale_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    locale_not_i?: InputMaybe<Scalars['String']['input']>
    locale_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    locale_not_starts_with?: InputMaybe<Scalars['String']['input']>
    locale_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    locale_starts_with?: InputMaybe<Scalars['String']['input']>
    locale_starts_with_i?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sortBy?: InputMaybe<Scalars['JSON']['input']>
    sortBy_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sortBy_not?: InputMaybe<Scalars['JSON']['input']>
    sortBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    status?: InputMaybe<IncidentExportTaskStatusType>
    status_in?: InputMaybe<Array<InputMaybe<IncidentExportTaskStatusType>>>
    status_not?: InputMaybe<IncidentExportTaskStatusType>
    status_not_in?: InputMaybe<Array<InputMaybe<IncidentExportTaskStatusType>>>
    timeZone?: InputMaybe<Scalars['String']['input']>
    timeZone_contains?: InputMaybe<Scalars['String']['input']>
    timeZone_contains_i?: InputMaybe<Scalars['String']['input']>
    timeZone_ends_with?: InputMaybe<Scalars['String']['input']>
    timeZone_ends_with_i?: InputMaybe<Scalars['String']['input']>
    timeZone_i?: InputMaybe<Scalars['String']['input']>
    timeZone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    timeZone_not?: InputMaybe<Scalars['String']['input']>
    timeZone_not_contains?: InputMaybe<Scalars['String']['input']>
    timeZone_not_contains_i?: InputMaybe<Scalars['String']['input']>
    timeZone_not_ends_with?: InputMaybe<Scalars['String']['input']>
    timeZone_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    timeZone_not_i?: InputMaybe<Scalars['String']['input']>
    timeZone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    timeZone_not_starts_with?: InputMaybe<Scalars['String']['input']>
    timeZone_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    timeZone_starts_with?: InputMaybe<Scalars['String']['input']>
    timeZone_starts_with_i?: InputMaybe<Scalars['String']['input']>
    totalRecordsCount?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_gt?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_gte?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    totalRecordsCount_lt?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_lte?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_not?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    user?: InputMaybe<UserWhereInput>
    user_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    where?: InputMaybe<Scalars['JSON']['input']>
    where_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    where_not?: InputMaybe<Scalars['JSON']['input']>
    where_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
}

export type IncidentExportTaskWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type IncidentExportTasksCreateInput = {
    data?: InputMaybe<IncidentExportTaskCreateInput>
}

export type IncidentExportTasksUpdateInput = {
    data?: InputMaybe<IncidentExportTaskUpdateInput>
    id: Scalars['ID']['input']
}

/**  A keystone list  */
export type IncidentHistoryRecord = {
    __typename?: 'IncidentHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the IncidentHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the IncidentHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the IncidentHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the IncidentHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    details?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    hasAllProperties?: Maybe<Scalars['Boolean']['output']>
    history_action?: Maybe<IncidentHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['JSON']['output']>
    number?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    status?: Maybe<Scalars['String']['output']>
    textForResident?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
    workFinish?: Maybe<Scalars['String']['output']>
    workStart?: Maybe<Scalars['String']['output']>
    workType?: Maybe<Scalars['String']['output']>
}

export type IncidentHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    details?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    hasAllProperties?: InputMaybe<Scalars['Boolean']['input']>
    history_action?: InputMaybe<IncidentHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    number?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    textForResident?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    workFinish?: InputMaybe<Scalars['String']['input']>
    workStart?: InputMaybe<Scalars['String']['input']>
    workType?: InputMaybe<Scalars['String']['input']>
}

export enum IncidentHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type IncidentHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    details?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    hasAllProperties?: InputMaybe<Scalars['Boolean']['input']>
    history_action?: InputMaybe<IncidentHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    number?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    textForResident?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    workFinish?: InputMaybe<Scalars['String']['input']>
    workStart?: InputMaybe<Scalars['String']['input']>
    workType?: InputMaybe<Scalars['String']['input']>
}

export type IncidentHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<IncidentHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<IncidentHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    details?: InputMaybe<Scalars['String']['input']>
    details_contains?: InputMaybe<Scalars['String']['input']>
    details_contains_i?: InputMaybe<Scalars['String']['input']>
    details_ends_with?: InputMaybe<Scalars['String']['input']>
    details_ends_with_i?: InputMaybe<Scalars['String']['input']>
    details_i?: InputMaybe<Scalars['String']['input']>
    details_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    details_not?: InputMaybe<Scalars['String']['input']>
    details_not_contains?: InputMaybe<Scalars['String']['input']>
    details_not_contains_i?: InputMaybe<Scalars['String']['input']>
    details_not_ends_with?: InputMaybe<Scalars['String']['input']>
    details_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    details_not_i?: InputMaybe<Scalars['String']['input']>
    details_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    details_not_starts_with?: InputMaybe<Scalars['String']['input']>
    details_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    details_starts_with?: InputMaybe<Scalars['String']['input']>
    details_starts_with_i?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    hasAllProperties?: InputMaybe<Scalars['Boolean']['input']>
    hasAllProperties_not?: InputMaybe<Scalars['Boolean']['input']>
    history_action?: InputMaybe<IncidentHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<IncidentHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<IncidentHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<IncidentHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    number?: InputMaybe<Scalars['JSON']['input']>
    number_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    number_not?: InputMaybe<Scalars['JSON']['input']>
    number_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    status?: InputMaybe<Scalars['String']['input']>
    status_contains?: InputMaybe<Scalars['String']['input']>
    status_contains_i?: InputMaybe<Scalars['String']['input']>
    status_ends_with?: InputMaybe<Scalars['String']['input']>
    status_ends_with_i?: InputMaybe<Scalars['String']['input']>
    status_i?: InputMaybe<Scalars['String']['input']>
    status_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status_not?: InputMaybe<Scalars['String']['input']>
    status_not_contains?: InputMaybe<Scalars['String']['input']>
    status_not_contains_i?: InputMaybe<Scalars['String']['input']>
    status_not_ends_with?: InputMaybe<Scalars['String']['input']>
    status_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    status_not_i?: InputMaybe<Scalars['String']['input']>
    status_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status_not_starts_with?: InputMaybe<Scalars['String']['input']>
    status_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    status_starts_with?: InputMaybe<Scalars['String']['input']>
    status_starts_with_i?: InputMaybe<Scalars['String']['input']>
    textForResident?: InputMaybe<Scalars['String']['input']>
    textForResident_contains?: InputMaybe<Scalars['String']['input']>
    textForResident_contains_i?: InputMaybe<Scalars['String']['input']>
    textForResident_ends_with?: InputMaybe<Scalars['String']['input']>
    textForResident_ends_with_i?: InputMaybe<Scalars['String']['input']>
    textForResident_i?: InputMaybe<Scalars['String']['input']>
    textForResident_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    textForResident_not?: InputMaybe<Scalars['String']['input']>
    textForResident_not_contains?: InputMaybe<Scalars['String']['input']>
    textForResident_not_contains_i?: InputMaybe<Scalars['String']['input']>
    textForResident_not_ends_with?: InputMaybe<Scalars['String']['input']>
    textForResident_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    textForResident_not_i?: InputMaybe<Scalars['String']['input']>
    textForResident_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    textForResident_not_starts_with?: InputMaybe<Scalars['String']['input']>
    textForResident_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    textForResident_starts_with?: InputMaybe<Scalars['String']['input']>
    textForResident_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    workFinish?: InputMaybe<Scalars['String']['input']>
    workFinish_gt?: InputMaybe<Scalars['String']['input']>
    workFinish_gte?: InputMaybe<Scalars['String']['input']>
    workFinish_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    workFinish_lt?: InputMaybe<Scalars['String']['input']>
    workFinish_lte?: InputMaybe<Scalars['String']['input']>
    workFinish_not?: InputMaybe<Scalars['String']['input']>
    workFinish_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    workStart?: InputMaybe<Scalars['String']['input']>
    workStart_gt?: InputMaybe<Scalars['String']['input']>
    workStart_gte?: InputMaybe<Scalars['String']['input']>
    workStart_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    workStart_lt?: InputMaybe<Scalars['String']['input']>
    workStart_lte?: InputMaybe<Scalars['String']['input']>
    workStart_not?: InputMaybe<Scalars['String']['input']>
    workStart_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    workType?: InputMaybe<Scalars['String']['input']>
    workType_contains?: InputMaybe<Scalars['String']['input']>
    workType_contains_i?: InputMaybe<Scalars['String']['input']>
    workType_ends_with?: InputMaybe<Scalars['String']['input']>
    workType_ends_with_i?: InputMaybe<Scalars['String']['input']>
    workType_i?: InputMaybe<Scalars['String']['input']>
    workType_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    workType_not?: InputMaybe<Scalars['String']['input']>
    workType_not_contains?: InputMaybe<Scalars['String']['input']>
    workType_not_contains_i?: InputMaybe<Scalars['String']['input']>
    workType_not_ends_with?: InputMaybe<Scalars['String']['input']>
    workType_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    workType_not_i?: InputMaybe<Scalars['String']['input']>
    workType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    workType_not_starts_with?: InputMaybe<Scalars['String']['input']>
    workType_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    workType_starts_with?: InputMaybe<Scalars['String']['input']>
    workType_starts_with_i?: InputMaybe<Scalars['String']['input']>
}

export type IncidentHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type IncidentHistoryRecordsCreateInput = {
    data?: InputMaybe<IncidentHistoryRecordCreateInput>
}

export type IncidentHistoryRecordsUpdateInput = {
    data?: InputMaybe<IncidentHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type IncidentOverviewResult = {
    __typename?: 'IncidentOverviewResult'
    count: Scalars['String']['output']
}

export type IncidentPropertiesCreateInput = {
    data?: InputMaybe<IncidentPropertyCreateInput>
}

export type IncidentPropertiesUpdateInput = {
    data?: InputMaybe<IncidentPropertyUpdateInput>
    id: Scalars['ID']['input']
}

/**  Many-to-many relationship between Incident and Property  */
export type IncidentProperty = {
    __typename?: 'IncidentProperty'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the IncidentProperty List config, or
   *  2. As an alias to the field set on 'labelField' in the IncidentProperty List config, or
   *  3. As an alias to a 'name' field on the IncidentProperty List (if one exists), or
   *  4. As an alias to the 'id' field on the IncidentProperty List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  Incident which has a property  */
    incident?: Maybe<Incident>
    newId?: Maybe<Scalars['String']['output']>
    /**  Ref to the organization. It is filled in on the server and is read-only  */
    organization?: Maybe<Organization>
    /**  A property which is in the incident entry  */
    property?: Maybe<Property>
    /**  Address of property, which synced with property and displayed, if property is deleted  */
    propertyAddress?: Maybe<Scalars['String']['output']>
    /**  Address meta of property, which synced with property and used to form view of address, if property is deleted  */
    propertyAddressMeta?: Maybe<AddressMetaField>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type IncidentPropertyCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    incident?: InputMaybe<IncidentRelateToOneInput>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    property?: InputMaybe<PropertyRelateToOneInput>
    propertyAddress?: InputMaybe<Scalars['String']['input']>
    propertyAddressMeta?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type IncidentPropertyHistoryRecord = {
    __typename?: 'IncidentPropertyHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the IncidentPropertyHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the IncidentPropertyHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the IncidentPropertyHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the IncidentPropertyHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<IncidentPropertyHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    incident?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    property?: Maybe<Scalars['String']['output']>
    propertyAddress?: Maybe<Scalars['String']['output']>
    propertyAddressMeta?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type IncidentPropertyHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<IncidentPropertyHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    incident?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    property?: InputMaybe<Scalars['String']['input']>
    propertyAddress?: InputMaybe<Scalars['String']['input']>
    propertyAddressMeta?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum IncidentPropertyHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type IncidentPropertyHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<IncidentPropertyHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    incident?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    property?: InputMaybe<Scalars['String']['input']>
    propertyAddress?: InputMaybe<Scalars['String']['input']>
    propertyAddressMeta?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type IncidentPropertyHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<IncidentPropertyHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<IncidentPropertyHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<IncidentPropertyHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<IncidentPropertyHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<IncidentPropertyHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<IncidentPropertyHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    incident?: InputMaybe<Scalars['String']['input']>
    incident_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    incident_not?: InputMaybe<Scalars['String']['input']>
    incident_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    property?: InputMaybe<Scalars['String']['input']>
    propertyAddress?: InputMaybe<Scalars['String']['input']>
    propertyAddressMeta?: InputMaybe<Scalars['JSON']['input']>
    propertyAddressMeta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    propertyAddressMeta_not?: InputMaybe<Scalars['JSON']['input']>
    propertyAddressMeta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    propertyAddress_contains?: InputMaybe<Scalars['String']['input']>
    propertyAddress_contains_i?: InputMaybe<Scalars['String']['input']>
    propertyAddress_ends_with?: InputMaybe<Scalars['String']['input']>
    propertyAddress_ends_with_i?: InputMaybe<Scalars['String']['input']>
    propertyAddress_i?: InputMaybe<Scalars['String']['input']>
    propertyAddress_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    propertyAddress_not?: InputMaybe<Scalars['String']['input']>
    propertyAddress_not_contains?: InputMaybe<Scalars['String']['input']>
    propertyAddress_not_contains_i?: InputMaybe<Scalars['String']['input']>
    propertyAddress_not_ends_with?: InputMaybe<Scalars['String']['input']>
    propertyAddress_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    propertyAddress_not_i?: InputMaybe<Scalars['String']['input']>
    propertyAddress_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    propertyAddress_not_starts_with?: InputMaybe<Scalars['String']['input']>
    propertyAddress_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    propertyAddress_starts_with?: InputMaybe<Scalars['String']['input']>
    propertyAddress_starts_with_i?: InputMaybe<Scalars['String']['input']>
    property_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    property_not?: InputMaybe<Scalars['String']['input']>
    property_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type IncidentPropertyHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type IncidentPropertyHistoryRecordsCreateInput = {
    data?: InputMaybe<IncidentPropertyHistoryRecordCreateInput>
}

export type IncidentPropertyHistoryRecordsUpdateInput = {
    data?: InputMaybe<IncidentPropertyHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type IncidentPropertyUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    incident?: InputMaybe<IncidentRelateToOneInput>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    property?: InputMaybe<PropertyRelateToOneInput>
    propertyAddress?: InputMaybe<Scalars['String']['input']>
    propertyAddressMeta?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type IncidentPropertyWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<IncidentPropertyWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<IncidentPropertyWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    incident?: InputMaybe<IncidentWhereInput>
    incident_is_null?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    property?: InputMaybe<PropertyWhereInput>
    propertyAddress?: InputMaybe<Scalars['String']['input']>
    propertyAddressMeta?: InputMaybe<Scalars['JSON']['input']>
    propertyAddressMeta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    propertyAddressMeta_not?: InputMaybe<Scalars['JSON']['input']>
    propertyAddressMeta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    propertyAddress_contains?: InputMaybe<Scalars['String']['input']>
    propertyAddress_contains_i?: InputMaybe<Scalars['String']['input']>
    propertyAddress_ends_with?: InputMaybe<Scalars['String']['input']>
    propertyAddress_ends_with_i?: InputMaybe<Scalars['String']['input']>
    propertyAddress_i?: InputMaybe<Scalars['String']['input']>
    propertyAddress_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    propertyAddress_not?: InputMaybe<Scalars['String']['input']>
    propertyAddress_not_contains?: InputMaybe<Scalars['String']['input']>
    propertyAddress_not_contains_i?: InputMaybe<Scalars['String']['input']>
    propertyAddress_not_ends_with?: InputMaybe<Scalars['String']['input']>
    propertyAddress_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    propertyAddress_not_i?: InputMaybe<Scalars['String']['input']>
    propertyAddress_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    propertyAddress_not_starts_with?: InputMaybe<Scalars['String']['input']>
    propertyAddress_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    propertyAddress_starts_with?: InputMaybe<Scalars['String']['input']>
    propertyAddress_starts_with_i?: InputMaybe<Scalars['String']['input']>
    property_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type IncidentPropertyWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type IncidentRelateToOneInput = {
    connect?: InputMaybe<IncidentWhereUniqueInput>
    create?: InputMaybe<IncidentCreateInput>
    disconnect?: InputMaybe<IncidentWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export enum IncidentStatusType {
    Actual = 'actual',
    NotActual = 'not_actual',
}

export type IncidentUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    details?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    hasAllProperties?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    number?: InputMaybe<Scalars['Int']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    status?: InputMaybe<IncidentStatusType>
    textForResident?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
    workFinish?: InputMaybe<Scalars['String']['input']>
    workStart?: InputMaybe<Scalars['String']['input']>
    workType?: InputMaybe<IncidentWorkTypeType>
}

export type IncidentWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<IncidentWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<IncidentWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    details?: InputMaybe<Scalars['String']['input']>
    details_contains?: InputMaybe<Scalars['String']['input']>
    details_contains_i?: InputMaybe<Scalars['String']['input']>
    details_ends_with?: InputMaybe<Scalars['String']['input']>
    details_ends_with_i?: InputMaybe<Scalars['String']['input']>
    details_i?: InputMaybe<Scalars['String']['input']>
    details_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    details_not?: InputMaybe<Scalars['String']['input']>
    details_not_contains?: InputMaybe<Scalars['String']['input']>
    details_not_contains_i?: InputMaybe<Scalars['String']['input']>
    details_not_ends_with?: InputMaybe<Scalars['String']['input']>
    details_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    details_not_i?: InputMaybe<Scalars['String']['input']>
    details_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    details_not_starts_with?: InputMaybe<Scalars['String']['input']>
    details_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    details_starts_with?: InputMaybe<Scalars['String']['input']>
    details_starts_with_i?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    hasAllProperties?: InputMaybe<Scalars['Boolean']['input']>
    hasAllProperties_not?: InputMaybe<Scalars['Boolean']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    number?: InputMaybe<Scalars['Int']['input']>
    number_gt?: InputMaybe<Scalars['Int']['input']>
    number_gte?: InputMaybe<Scalars['Int']['input']>
    number_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    number_lt?: InputMaybe<Scalars['Int']['input']>
    number_lte?: InputMaybe<Scalars['Int']['input']>
    number_not?: InputMaybe<Scalars['Int']['input']>
    number_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    status?: InputMaybe<IncidentStatusType>
    status_in?: InputMaybe<Array<InputMaybe<IncidentStatusType>>>
    status_not?: InputMaybe<IncidentStatusType>
    status_not_in?: InputMaybe<Array<InputMaybe<IncidentStatusType>>>
    textForResident?: InputMaybe<Scalars['String']['input']>
    textForResident_contains?: InputMaybe<Scalars['String']['input']>
    textForResident_contains_i?: InputMaybe<Scalars['String']['input']>
    textForResident_ends_with?: InputMaybe<Scalars['String']['input']>
    textForResident_ends_with_i?: InputMaybe<Scalars['String']['input']>
    textForResident_i?: InputMaybe<Scalars['String']['input']>
    textForResident_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    textForResident_not?: InputMaybe<Scalars['String']['input']>
    textForResident_not_contains?: InputMaybe<Scalars['String']['input']>
    textForResident_not_contains_i?: InputMaybe<Scalars['String']['input']>
    textForResident_not_ends_with?: InputMaybe<Scalars['String']['input']>
    textForResident_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    textForResident_not_i?: InputMaybe<Scalars['String']['input']>
    textForResident_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    textForResident_not_starts_with?: InputMaybe<Scalars['String']['input']>
    textForResident_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    textForResident_starts_with?: InputMaybe<Scalars['String']['input']>
    textForResident_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    workFinish?: InputMaybe<Scalars['String']['input']>
    workFinish_gt?: InputMaybe<Scalars['String']['input']>
    workFinish_gte?: InputMaybe<Scalars['String']['input']>
    workFinish_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    workFinish_lt?: InputMaybe<Scalars['String']['input']>
    workFinish_lte?: InputMaybe<Scalars['String']['input']>
    workFinish_not?: InputMaybe<Scalars['String']['input']>
    workFinish_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    workStart?: InputMaybe<Scalars['String']['input']>
    workStart_gt?: InputMaybe<Scalars['String']['input']>
    workStart_gte?: InputMaybe<Scalars['String']['input']>
    workStart_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    workStart_lt?: InputMaybe<Scalars['String']['input']>
    workStart_lte?: InputMaybe<Scalars['String']['input']>
    workStart_not?: InputMaybe<Scalars['String']['input']>
    workStart_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    workType?: InputMaybe<IncidentWorkTypeType>
    workType_in?: InputMaybe<Array<InputMaybe<IncidentWorkTypeType>>>
    workType_not?: InputMaybe<IncidentWorkTypeType>
    workType_not_in?: InputMaybe<Array<InputMaybe<IncidentWorkTypeType>>>
}

export type IncidentWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export enum IncidentWorkTypeType {
    Emergency = 'emergency',
    Scheduled = 'scheduled',
}

export type IncidentsCreateInput = {
    data?: InputMaybe<IncidentCreateInput>
}

export type IncidentsUpdateInput = {
    data?: InputMaybe<IncidentUpdateInput>
    id: Scalars['ID']['input']
}

export type InviteNewOrganizationEmployeeInput = {
    dv: Scalars['Int']['input']
    email?: InputMaybe<Scalars['String']['input']>
    hasAllSpecializations?: InputMaybe<Scalars['Boolean']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    organization: OrganizationWhereUniqueInput
    phone: Scalars['String']['input']
    position?: InputMaybe<Scalars['String']['input']>
    role: OrganizationEmployeeRoleWhereUniqueInput
    sender: SenderFieldInput
    specializations?: InputMaybe<Array<InputMaybe<TicketCategoryClassifierWhereUniqueInput>>>
}

/**  Invoice model contains information about paid items and payer  */
export type Invoice = {
    __typename?: 'Invoice'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the Invoice List config, or
   *  2. As an alias to the field set on 'labelField' in the Invoice List config, or
   *  3. As an alias to a 'name' field on the Invoice List (if one exists), or
   *  4. As an alias to the 'id' field on the Invoice List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  The payer's accountNumber within organization  */
    accountNumber?: Maybe<Scalars['String']['output']>
    /**  Url to acquiring integration service. Mobile devices will use it communicate with external acquiring. List of endpoints is the same for all of them.  */
    acquiringHostUrl?: Maybe<Scalars['String']['output']>
    /**  Integration ID through which this invoice can be paid  */
    acquiringIntegrationId?: Maybe<Scalars['ID']['output']>
    /**  Can multiple receipts be united through this acquiring  */
    canGroupReceipts?: Maybe<Scalars['Boolean']['output']>
    /**  When status of the invoice was changed to canceled  */
    canceledAt?: Maybe<Scalars['String']['output']>
    /**  This field indicates, that the Invoice is visible to a Resident and it has access to it. This field will be set to User of corresponding Resident in following cases: 1) the Invoice was created by Resident from mobile app;2) the Invoice was created by OrganizationEmployee with phone number, that matches some Resident;  */
    client?: Maybe<User>
    /**  Inhabitant/customer/person who has a problem. Sometimes we get a problem from an unregistered client, in such cases we have a null inside the `client` and just have something here. Or sometimes clients want to change it  */
    clientName?: Maybe<Scalars['String']['output']>
    /**  Inhabitant/customer/person who has a problem. Sometimes we get a problem from an unregistered client, in such cases we have a null inside the `client` and just have something here. Or sometimes clients want to change it  */
    clientPhone?: Maybe<Scalars['String']['output']>
    /**  The contact the invoice created for. Fill by organization  */
    contact?: Maybe<Contact>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    /**  Code of currency in ISO-4217 format  */
    currencyCode?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['String']['output']>
    /**  The invoice number within organization  */
    number?: Maybe<Scalars['Int']['output']>
    /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
    organization?: Maybe<Organization>
    /**  When status of the invoice was changed to paid  */
    paidAt?: Maybe<Scalars['String']['output']>
    /**  Shows which payment type chosen: online or cash or something else  */
    paymentType?: Maybe<InvoicePaymentTypeType>
    /**  The payer's property  */
    property?: Maybe<Property>
    /**  When status of the invoice was changed to published (ready to pay)  */
    publishedAt?: Maybe<Scalars['String']['output']>
    /**  The recipient's requisites  */
    recipient?: Maybe<RecipientField>
    /**  The list of paid items  */
    rows: Array<InvoiceRowSchemaField>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Invoice status affects which invoices can be read by residents and which invoices can be managed. The newly created invoice has status "draft"; the "published" invoice may be paid by resident; "paid" means that invoice already paid; "canceled" means no modifications allowed. Each status, except draft, has related timestamp.  */
    status?: Maybe<InvoiceStatusType>
    /**  The ticket related to this invoice  */
    ticket?: Maybe<Ticket>
    /**  Money field  */
    toPay?: Maybe<Scalars['String']['output']>
    /**  The payer's unitName  */
    unitName?: Maybe<Scalars['String']['output']>
    /**  Type of unit, such as parking lot or flat. Default value: "flat"  */
    unitType?: Maybe<InvoiceUnitTypeType>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type InvoiceCreateInput = {
    accountNumber?: InputMaybe<Scalars['String']['input']>
    canceledAt?: InputMaybe<Scalars['String']['input']>
    client?: InputMaybe<UserRelateToOneInput>
    clientName?: InputMaybe<Scalars['String']['input']>
    clientPhone?: InputMaybe<Scalars['String']['input']>
    contact?: InputMaybe<ContactRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    number?: InputMaybe<Scalars['Int']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    paidAt?: InputMaybe<Scalars['String']['input']>
    paymentType?: InputMaybe<InvoicePaymentTypeType>
    property?: InputMaybe<PropertyRelateToOneInput>
    publishedAt?: InputMaybe<Scalars['String']['input']>
    rows?: InputMaybe<Array<InvoiceRowSchemaFieldInput>>
    sender?: InputMaybe<SenderFieldInput>
    status?: InputMaybe<InvoiceStatusType>
    ticket?: InputMaybe<TicketRelateToOneInput>
    toPay?: InputMaybe<Scalars['String']['input']>
    unitName?: InputMaybe<Scalars['String']['input']>
    unitType?: InputMaybe<InvoiceUnitTypeType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type InvoiceHistoryRecord = {
    __typename?: 'InvoiceHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the InvoiceHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the InvoiceHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the InvoiceHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the InvoiceHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    accountNumber?: Maybe<Scalars['String']['output']>
    canceledAt?: Maybe<Scalars['String']['output']>
    client?: Maybe<Scalars['String']['output']>
    clientName?: Maybe<Scalars['String']['output']>
    clientPhone?: Maybe<Scalars['String']['output']>
    contact?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<InvoiceHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['JSON']['output']>
    number?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    paidAt?: Maybe<Scalars['String']['output']>
    paymentType?: Maybe<Scalars['String']['output']>
    property?: Maybe<Scalars['String']['output']>
    publishedAt?: Maybe<Scalars['String']['output']>
    rows?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    status?: Maybe<Scalars['String']['output']>
    ticket?: Maybe<Scalars['String']['output']>
    toPay?: Maybe<Scalars['String']['output']>
    unitName?: Maybe<Scalars['String']['output']>
    unitType?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type InvoiceHistoryRecordCreateInput = {
    accountNumber?: InputMaybe<Scalars['String']['input']>
    canceledAt?: InputMaybe<Scalars['String']['input']>
    client?: InputMaybe<Scalars['String']['input']>
    clientName?: InputMaybe<Scalars['String']['input']>
    clientPhone?: InputMaybe<Scalars['String']['input']>
    contact?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<InvoiceHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    number?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    paidAt?: InputMaybe<Scalars['String']['input']>
    paymentType?: InputMaybe<Scalars['String']['input']>
    property?: InputMaybe<Scalars['String']['input']>
    publishedAt?: InputMaybe<Scalars['String']['input']>
    rows?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    ticket?: InputMaybe<Scalars['String']['input']>
    toPay?: InputMaybe<Scalars['String']['input']>
    unitName?: InputMaybe<Scalars['String']['input']>
    unitType?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum InvoiceHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type InvoiceHistoryRecordUpdateInput = {
    accountNumber?: InputMaybe<Scalars['String']['input']>
    canceledAt?: InputMaybe<Scalars['String']['input']>
    client?: InputMaybe<Scalars['String']['input']>
    clientName?: InputMaybe<Scalars['String']['input']>
    clientPhone?: InputMaybe<Scalars['String']['input']>
    contact?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<InvoiceHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    number?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    paidAt?: InputMaybe<Scalars['String']['input']>
    paymentType?: InputMaybe<Scalars['String']['input']>
    property?: InputMaybe<Scalars['String']['input']>
    publishedAt?: InputMaybe<Scalars['String']['input']>
    rows?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    ticket?: InputMaybe<Scalars['String']['input']>
    toPay?: InputMaybe<Scalars['String']['input']>
    unitName?: InputMaybe<Scalars['String']['input']>
    unitType?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type InvoiceHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<InvoiceHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<InvoiceHistoryRecordWhereInput>>>
    accountNumber?: InputMaybe<Scalars['String']['input']>
    accountNumber_contains?: InputMaybe<Scalars['String']['input']>
    accountNumber_contains_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_ends_with?: InputMaybe<Scalars['String']['input']>
    accountNumber_ends_with_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    accountNumber_not?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_contains?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_contains_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_ends_with?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    accountNumber_not_starts_with?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_starts_with?: InputMaybe<Scalars['String']['input']>
    accountNumber_starts_with_i?: InputMaybe<Scalars['String']['input']>
    canceledAt?: InputMaybe<Scalars['String']['input']>
    canceledAt_gt?: InputMaybe<Scalars['String']['input']>
    canceledAt_gte?: InputMaybe<Scalars['String']['input']>
    canceledAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    canceledAt_lt?: InputMaybe<Scalars['String']['input']>
    canceledAt_lte?: InputMaybe<Scalars['String']['input']>
    canceledAt_not?: InputMaybe<Scalars['String']['input']>
    canceledAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    client?: InputMaybe<Scalars['String']['input']>
    clientName?: InputMaybe<Scalars['String']['input']>
    clientName_contains?: InputMaybe<Scalars['String']['input']>
    clientName_contains_i?: InputMaybe<Scalars['String']['input']>
    clientName_ends_with?: InputMaybe<Scalars['String']['input']>
    clientName_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientName_i?: InputMaybe<Scalars['String']['input']>
    clientName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientName_not?: InputMaybe<Scalars['String']['input']>
    clientName_not_contains?: InputMaybe<Scalars['String']['input']>
    clientName_not_contains_i?: InputMaybe<Scalars['String']['input']>
    clientName_not_ends_with?: InputMaybe<Scalars['String']['input']>
    clientName_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientName_not_i?: InputMaybe<Scalars['String']['input']>
    clientName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientName_not_starts_with?: InputMaybe<Scalars['String']['input']>
    clientName_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    clientName_starts_with?: InputMaybe<Scalars['String']['input']>
    clientName_starts_with_i?: InputMaybe<Scalars['String']['input']>
    clientPhone?: InputMaybe<Scalars['String']['input']>
    clientPhone_contains?: InputMaybe<Scalars['String']['input']>
    clientPhone_contains_i?: InputMaybe<Scalars['String']['input']>
    clientPhone_ends_with?: InputMaybe<Scalars['String']['input']>
    clientPhone_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientPhone_i?: InputMaybe<Scalars['String']['input']>
    clientPhone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientPhone_not?: InputMaybe<Scalars['String']['input']>
    clientPhone_not_contains?: InputMaybe<Scalars['String']['input']>
    clientPhone_not_contains_i?: InputMaybe<Scalars['String']['input']>
    clientPhone_not_ends_with?: InputMaybe<Scalars['String']['input']>
    clientPhone_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientPhone_not_i?: InputMaybe<Scalars['String']['input']>
    clientPhone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientPhone_not_starts_with?: InputMaybe<Scalars['String']['input']>
    clientPhone_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    clientPhone_starts_with?: InputMaybe<Scalars['String']['input']>
    clientPhone_starts_with_i?: InputMaybe<Scalars['String']['input']>
    client_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    client_not?: InputMaybe<Scalars['String']['input']>
    client_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    contact?: InputMaybe<Scalars['String']['input']>
    contact_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    contact_not?: InputMaybe<Scalars['String']['input']>
    contact_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<InvoiceHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<InvoiceHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<InvoiceHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<InvoiceHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    number?: InputMaybe<Scalars['JSON']['input']>
    number_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    number_not?: InputMaybe<Scalars['JSON']['input']>
    number_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    paidAt?: InputMaybe<Scalars['String']['input']>
    paidAt_gt?: InputMaybe<Scalars['String']['input']>
    paidAt_gte?: InputMaybe<Scalars['String']['input']>
    paidAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    paidAt_lt?: InputMaybe<Scalars['String']['input']>
    paidAt_lte?: InputMaybe<Scalars['String']['input']>
    paidAt_not?: InputMaybe<Scalars['String']['input']>
    paidAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    paymentType?: InputMaybe<Scalars['String']['input']>
    paymentType_contains?: InputMaybe<Scalars['String']['input']>
    paymentType_contains_i?: InputMaybe<Scalars['String']['input']>
    paymentType_ends_with?: InputMaybe<Scalars['String']['input']>
    paymentType_ends_with_i?: InputMaybe<Scalars['String']['input']>
    paymentType_i?: InputMaybe<Scalars['String']['input']>
    paymentType_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    paymentType_not?: InputMaybe<Scalars['String']['input']>
    paymentType_not_contains?: InputMaybe<Scalars['String']['input']>
    paymentType_not_contains_i?: InputMaybe<Scalars['String']['input']>
    paymentType_not_ends_with?: InputMaybe<Scalars['String']['input']>
    paymentType_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    paymentType_not_i?: InputMaybe<Scalars['String']['input']>
    paymentType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    paymentType_not_starts_with?: InputMaybe<Scalars['String']['input']>
    paymentType_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    paymentType_starts_with?: InputMaybe<Scalars['String']['input']>
    paymentType_starts_with_i?: InputMaybe<Scalars['String']['input']>
    property?: InputMaybe<Scalars['String']['input']>
    property_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    property_not?: InputMaybe<Scalars['String']['input']>
    property_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    publishedAt?: InputMaybe<Scalars['String']['input']>
    publishedAt_gt?: InputMaybe<Scalars['String']['input']>
    publishedAt_gte?: InputMaybe<Scalars['String']['input']>
    publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    publishedAt_lt?: InputMaybe<Scalars['String']['input']>
    publishedAt_lte?: InputMaybe<Scalars['String']['input']>
    publishedAt_not?: InputMaybe<Scalars['String']['input']>
    publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    rows?: InputMaybe<Scalars['JSON']['input']>
    rows_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    rows_not?: InputMaybe<Scalars['JSON']['input']>
    rows_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    status?: InputMaybe<Scalars['String']['input']>
    status_contains?: InputMaybe<Scalars['String']['input']>
    status_contains_i?: InputMaybe<Scalars['String']['input']>
    status_ends_with?: InputMaybe<Scalars['String']['input']>
    status_ends_with_i?: InputMaybe<Scalars['String']['input']>
    status_i?: InputMaybe<Scalars['String']['input']>
    status_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status_not?: InputMaybe<Scalars['String']['input']>
    status_not_contains?: InputMaybe<Scalars['String']['input']>
    status_not_contains_i?: InputMaybe<Scalars['String']['input']>
    status_not_ends_with?: InputMaybe<Scalars['String']['input']>
    status_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    status_not_i?: InputMaybe<Scalars['String']['input']>
    status_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status_not_starts_with?: InputMaybe<Scalars['String']['input']>
    status_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    status_starts_with?: InputMaybe<Scalars['String']['input']>
    status_starts_with_i?: InputMaybe<Scalars['String']['input']>
    ticket?: InputMaybe<Scalars['String']['input']>
    ticket_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    ticket_not?: InputMaybe<Scalars['String']['input']>
    ticket_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    toPay?: InputMaybe<Scalars['String']['input']>
    toPay_gt?: InputMaybe<Scalars['String']['input']>
    toPay_gte?: InputMaybe<Scalars['String']['input']>
    toPay_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    toPay_lt?: InputMaybe<Scalars['String']['input']>
    toPay_lte?: InputMaybe<Scalars['String']['input']>
    toPay_not?: InputMaybe<Scalars['String']['input']>
    toPay_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitName?: InputMaybe<Scalars['String']['input']>
    unitName_contains?: InputMaybe<Scalars['String']['input']>
    unitName_contains_i?: InputMaybe<Scalars['String']['input']>
    unitName_ends_with?: InputMaybe<Scalars['String']['input']>
    unitName_ends_with_i?: InputMaybe<Scalars['String']['input']>
    unitName_i?: InputMaybe<Scalars['String']['input']>
    unitName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitName_not?: InputMaybe<Scalars['String']['input']>
    unitName_not_contains?: InputMaybe<Scalars['String']['input']>
    unitName_not_contains_i?: InputMaybe<Scalars['String']['input']>
    unitName_not_ends_with?: InputMaybe<Scalars['String']['input']>
    unitName_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    unitName_not_i?: InputMaybe<Scalars['String']['input']>
    unitName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitName_not_starts_with?: InputMaybe<Scalars['String']['input']>
    unitName_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    unitName_starts_with?: InputMaybe<Scalars['String']['input']>
    unitName_starts_with_i?: InputMaybe<Scalars['String']['input']>
    unitType?: InputMaybe<Scalars['String']['input']>
    unitType_contains?: InputMaybe<Scalars['String']['input']>
    unitType_contains_i?: InputMaybe<Scalars['String']['input']>
    unitType_ends_with?: InputMaybe<Scalars['String']['input']>
    unitType_ends_with_i?: InputMaybe<Scalars['String']['input']>
    unitType_i?: InputMaybe<Scalars['String']['input']>
    unitType_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitType_not?: InputMaybe<Scalars['String']['input']>
    unitType_not_contains?: InputMaybe<Scalars['String']['input']>
    unitType_not_contains_i?: InputMaybe<Scalars['String']['input']>
    unitType_not_ends_with?: InputMaybe<Scalars['String']['input']>
    unitType_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    unitType_not_i?: InputMaybe<Scalars['String']['input']>
    unitType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitType_not_starts_with?: InputMaybe<Scalars['String']['input']>
    unitType_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    unitType_starts_with?: InputMaybe<Scalars['String']['input']>
    unitType_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type InvoiceHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type InvoiceHistoryRecordsCreateInput = {
    data?: InputMaybe<InvoiceHistoryRecordCreateInput>
}

export type InvoiceHistoryRecordsUpdateInput = {
    data?: InputMaybe<InvoiceHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export enum InvoicePaymentType {
    Cash = 'cash',
    Online = 'online',
}

export enum InvoicePaymentTypeType {
    Cash = 'cash',
    Online = 'online',
}

export type InvoiceRelateToOneInput = {
    connect?: InputMaybe<InvoiceWhereUniqueInput>
    create?: InputMaybe<InvoiceCreateInput>
    disconnect?: InputMaybe<InvoiceWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type InvoiceRowMetaSchemaField = {
    __typename?: 'InvoiceRowMetaSchemaField'
    categoryBgColor?: Maybe<Scalars['String']['output']>
    imageUrl?: Maybe<Scalars['String']['output']>
}

export type InvoiceRowMetaSchemaFieldInput = {
    categoryBgColor?: InputMaybe<Scalars['String']['input']>
    imageUrl?: InputMaybe<Scalars['String']['input']>
}

export type InvoiceRowSchemaField = {
    __typename?: 'InvoiceRowSchemaField'
    count: Scalars['Int']['output']
    currencyCode?: Maybe<Scalars['String']['output']>
    isMin: Scalars['Boolean']['output']
    meta?: Maybe<InvoiceRowMetaSchemaField>
    name: Scalars['String']['output']
    salesTaxPercent?: Maybe<Scalars['String']['output']>
    sku?: Maybe<Scalars['String']['output']>
    toPay: Scalars['String']['output']
    vatPercent?: Maybe<Scalars['String']['output']>
}

export type InvoiceRowSchemaFieldInput = {
    count: Scalars['Int']['input']
    currencyCode?: InputMaybe<Scalars['String']['input']>
    isMin: Scalars['Boolean']['input']
    meta?: InputMaybe<InvoiceRowMetaSchemaFieldInput>
    name: Scalars['String']['input']
    salesTaxPercent?: InputMaybe<Scalars['String']['input']>
    sku?: InputMaybe<Scalars['String']['input']>
    toPay: Scalars['String']['input']
    vatPercent?: InputMaybe<Scalars['String']['input']>
}

export type InvoiceRowsInput = {
    count: Scalars['Int']['input']
    priceScope: MarketPriceScopeWhereUniqueInput
}

export enum InvoiceStatusType {
    Canceled = 'canceled',
    Draft = 'draft',
    Paid = 'paid',
    Published = 'published',
}

export enum InvoiceUnitTypeType {
    Apartment = 'apartment',
    Commercial = 'commercial',
    Flat = 'flat',
    Parking = 'parking',
    Warehouse = 'warehouse',
}

export type InvoiceUpdateInput = {
    accountNumber?: InputMaybe<Scalars['String']['input']>
    canceledAt?: InputMaybe<Scalars['String']['input']>
    client?: InputMaybe<UserRelateToOneInput>
    clientName?: InputMaybe<Scalars['String']['input']>
    clientPhone?: InputMaybe<Scalars['String']['input']>
    contact?: InputMaybe<ContactRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    number?: InputMaybe<Scalars['Int']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    paidAt?: InputMaybe<Scalars['String']['input']>
    paymentType?: InputMaybe<InvoicePaymentTypeType>
    property?: InputMaybe<PropertyRelateToOneInput>
    publishedAt?: InputMaybe<Scalars['String']['input']>
    rows?: InputMaybe<Array<InvoiceRowSchemaFieldInput>>
    sender?: InputMaybe<SenderFieldInput>
    status?: InputMaybe<InvoiceStatusType>
    ticket?: InputMaybe<TicketRelateToOneInput>
    toPay?: InputMaybe<Scalars['String']['input']>
    unitName?: InputMaybe<Scalars['String']['input']>
    unitType?: InputMaybe<InvoiceUnitTypeType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type InvoiceWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<InvoiceWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<InvoiceWhereInput>>>
    accountNumber?: InputMaybe<Scalars['String']['input']>
    accountNumber_contains?: InputMaybe<Scalars['String']['input']>
    accountNumber_contains_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_ends_with?: InputMaybe<Scalars['String']['input']>
    accountNumber_ends_with_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    accountNumber_not?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_contains?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_contains_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_ends_with?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    accountNumber_not_starts_with?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_starts_with?: InputMaybe<Scalars['String']['input']>
    accountNumber_starts_with_i?: InputMaybe<Scalars['String']['input']>
    canceledAt?: InputMaybe<Scalars['String']['input']>
    canceledAt_gt?: InputMaybe<Scalars['String']['input']>
    canceledAt_gte?: InputMaybe<Scalars['String']['input']>
    canceledAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    canceledAt_lt?: InputMaybe<Scalars['String']['input']>
    canceledAt_lte?: InputMaybe<Scalars['String']['input']>
    canceledAt_not?: InputMaybe<Scalars['String']['input']>
    canceledAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    client?: InputMaybe<UserWhereInput>
    clientName?: InputMaybe<Scalars['String']['input']>
    clientName_contains?: InputMaybe<Scalars['String']['input']>
    clientName_contains_i?: InputMaybe<Scalars['String']['input']>
    clientName_ends_with?: InputMaybe<Scalars['String']['input']>
    clientName_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientName_i?: InputMaybe<Scalars['String']['input']>
    clientName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientName_not?: InputMaybe<Scalars['String']['input']>
    clientName_not_contains?: InputMaybe<Scalars['String']['input']>
    clientName_not_contains_i?: InputMaybe<Scalars['String']['input']>
    clientName_not_ends_with?: InputMaybe<Scalars['String']['input']>
    clientName_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientName_not_i?: InputMaybe<Scalars['String']['input']>
    clientName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientName_not_starts_with?: InputMaybe<Scalars['String']['input']>
    clientName_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    clientName_starts_with?: InputMaybe<Scalars['String']['input']>
    clientName_starts_with_i?: InputMaybe<Scalars['String']['input']>
    clientPhone?: InputMaybe<Scalars['String']['input']>
    clientPhone_contains?: InputMaybe<Scalars['String']['input']>
    clientPhone_contains_i?: InputMaybe<Scalars['String']['input']>
    clientPhone_ends_with?: InputMaybe<Scalars['String']['input']>
    clientPhone_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientPhone_i?: InputMaybe<Scalars['String']['input']>
    clientPhone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientPhone_not?: InputMaybe<Scalars['String']['input']>
    clientPhone_not_contains?: InputMaybe<Scalars['String']['input']>
    clientPhone_not_contains_i?: InputMaybe<Scalars['String']['input']>
    clientPhone_not_ends_with?: InputMaybe<Scalars['String']['input']>
    clientPhone_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientPhone_not_i?: InputMaybe<Scalars['String']['input']>
    clientPhone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientPhone_not_starts_with?: InputMaybe<Scalars['String']['input']>
    clientPhone_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    clientPhone_starts_with?: InputMaybe<Scalars['String']['input']>
    clientPhone_starts_with_i?: InputMaybe<Scalars['String']['input']>
    client_is_null?: InputMaybe<Scalars['Boolean']['input']>
    contact?: InputMaybe<ContactWhereInput>
    contact_is_null?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    number?: InputMaybe<Scalars['Int']['input']>
    number_gt?: InputMaybe<Scalars['Int']['input']>
    number_gte?: InputMaybe<Scalars['Int']['input']>
    number_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    number_lt?: InputMaybe<Scalars['Int']['input']>
    number_lte?: InputMaybe<Scalars['Int']['input']>
    number_not?: InputMaybe<Scalars['Int']['input']>
    number_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    paidAt?: InputMaybe<Scalars['String']['input']>
    paidAt_gt?: InputMaybe<Scalars['String']['input']>
    paidAt_gte?: InputMaybe<Scalars['String']['input']>
    paidAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    paidAt_lt?: InputMaybe<Scalars['String']['input']>
    paidAt_lte?: InputMaybe<Scalars['String']['input']>
    paidAt_not?: InputMaybe<Scalars['String']['input']>
    paidAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    paymentType?: InputMaybe<InvoicePaymentTypeType>
    paymentType_in?: InputMaybe<Array<InputMaybe<InvoicePaymentTypeType>>>
    paymentType_not?: InputMaybe<InvoicePaymentTypeType>
    paymentType_not_in?: InputMaybe<Array<InputMaybe<InvoicePaymentTypeType>>>
    property?: InputMaybe<PropertyWhereInput>
    property_is_null?: InputMaybe<Scalars['Boolean']['input']>
    publishedAt?: InputMaybe<Scalars['String']['input']>
    publishedAt_gt?: InputMaybe<Scalars['String']['input']>
    publishedAt_gte?: InputMaybe<Scalars['String']['input']>
    publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    publishedAt_lt?: InputMaybe<Scalars['String']['input']>
    publishedAt_lte?: InputMaybe<Scalars['String']['input']>
    publishedAt_not?: InputMaybe<Scalars['String']['input']>
    publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    rows?: InputMaybe<Array<InvoiceRowSchemaFieldInput>>
    rows_in?: InputMaybe<Array<InputMaybe<Array<InvoiceRowSchemaFieldInput>>>>
    rows_not?: InputMaybe<Array<InvoiceRowSchemaFieldInput>>
    rows_not_in?: InputMaybe<Array<InputMaybe<Array<InvoiceRowSchemaFieldInput>>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    status?: InputMaybe<InvoiceStatusType>
    status_in?: InputMaybe<Array<InputMaybe<InvoiceStatusType>>>
    status_not?: InputMaybe<InvoiceStatusType>
    status_not_in?: InputMaybe<Array<InputMaybe<InvoiceStatusType>>>
    ticket?: InputMaybe<TicketWhereInput>
    ticket_is_null?: InputMaybe<Scalars['Boolean']['input']>
    toPay?: InputMaybe<Scalars['String']['input']>
    toPay_gt?: InputMaybe<Scalars['String']['input']>
    toPay_gte?: InputMaybe<Scalars['String']['input']>
    toPay_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    toPay_lt?: InputMaybe<Scalars['String']['input']>
    toPay_lte?: InputMaybe<Scalars['String']['input']>
    toPay_not?: InputMaybe<Scalars['String']['input']>
    toPay_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitName?: InputMaybe<Scalars['String']['input']>
    unitName_contains?: InputMaybe<Scalars['String']['input']>
    unitName_contains_i?: InputMaybe<Scalars['String']['input']>
    unitName_ends_with?: InputMaybe<Scalars['String']['input']>
    unitName_ends_with_i?: InputMaybe<Scalars['String']['input']>
    unitName_i?: InputMaybe<Scalars['String']['input']>
    unitName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitName_not?: InputMaybe<Scalars['String']['input']>
    unitName_not_contains?: InputMaybe<Scalars['String']['input']>
    unitName_not_contains_i?: InputMaybe<Scalars['String']['input']>
    unitName_not_ends_with?: InputMaybe<Scalars['String']['input']>
    unitName_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    unitName_not_i?: InputMaybe<Scalars['String']['input']>
    unitName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitName_not_starts_with?: InputMaybe<Scalars['String']['input']>
    unitName_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    unitName_starts_with?: InputMaybe<Scalars['String']['input']>
    unitName_starts_with_i?: InputMaybe<Scalars['String']['input']>
    unitType?: InputMaybe<InvoiceUnitTypeType>
    unitType_in?: InputMaybe<Array<InputMaybe<InvoiceUnitTypeType>>>
    unitType_not?: InputMaybe<InvoiceUnitTypeType>
    unitType_not_in?: InputMaybe<Array<InputMaybe<InvoiceUnitTypeType>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type InvoiceWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type InvoicesCreateInput = {
    data?: InputMaybe<InvoiceCreateInput>
}

export type InvoicesUpdateInput = {
    data?: InputMaybe<InvoiceUpdateInput>
    id: Scalars['ID']['input']
}

export type MarketCategoriesCreateInput = {
    data?: InputMaybe<MarketCategoryCreateInput>
}

export type MarketCategoriesUpdateInput = {
    data?: InputMaybe<MarketCategoryUpdateInput>
    id: Scalars['ID']['input']
}

/**  An entity that allows you to define a category tree in the marketplace  */
export type MarketCategory = {
    __typename?: 'MarketCategory'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MarketCategory List config, or
   *  2. As an alias to the field set on 'labelField' in the MarketCategory List config, or
   *  3. As an alias to a 'name' field on the MarketCategory List (if one exists), or
   *  4. As an alias to the 'id' field on the MarketCategory List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  Preview image  */
    image?: Maybe<File>
    /**  Settings for mobile ui  */
    mobileSettings?: Maybe<MarketCategoryMobileSettingsSchemaFieldInput>
    /**  The category name  */
    name?: Maybe<Scalars['String']['output']>
    nameNonLocalized?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  The number used for sorting at the client. It's possible to sort categories any manner. For example, set some category to the bottom by setting order=100 and sorting asc.  */
    order?: Maybe<Scalars['Int']['output']>
    /**  Which category does this subcategory belong to  */
    parentCategory?: Maybe<MarketCategory>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type MarketCategoryCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    image?: InputMaybe<Scalars['Upload']['input']>
    mobileSettings?: InputMaybe<MarketCategoryMobileSettingsSchemaField>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    order?: InputMaybe<Scalars['Int']['input']>
    parentCategory?: InputMaybe<MarketCategoryRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type MarketCategoryHistoryRecord = {
    __typename?: 'MarketCategoryHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MarketCategoryHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the MarketCategoryHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the MarketCategoryHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the MarketCategoryHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<MarketCategoryHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    image?: Maybe<Scalars['JSON']['output']>
    mobileSettings?: Maybe<Scalars['JSON']['output']>
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    order?: Maybe<Scalars['Int']['output']>
    parentCategory?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type MarketCategoryHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<MarketCategoryHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    image?: InputMaybe<Scalars['JSON']['input']>
    mobileSettings?: InputMaybe<Scalars['JSON']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    order?: InputMaybe<Scalars['Int']['input']>
    parentCategory?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum MarketCategoryHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type MarketCategoryHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<MarketCategoryHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    image?: InputMaybe<Scalars['JSON']['input']>
    mobileSettings?: InputMaybe<Scalars['JSON']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    order?: InputMaybe<Scalars['Int']['input']>
    parentCategory?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type MarketCategoryHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<MarketCategoryHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<MarketCategoryHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<MarketCategoryHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<MarketCategoryHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<MarketCategoryHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<MarketCategoryHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    image?: InputMaybe<Scalars['JSON']['input']>
    image_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    image_not?: InputMaybe<Scalars['JSON']['input']>
    image_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    mobileSettings?: InputMaybe<Scalars['JSON']['input']>
    mobileSettings_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    mobileSettings_not?: InputMaybe<Scalars['JSON']['input']>
    mobileSettings_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    order?: InputMaybe<Scalars['Int']['input']>
    order_gt?: InputMaybe<Scalars['Int']['input']>
    order_gte?: InputMaybe<Scalars['Int']['input']>
    order_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    order_lt?: InputMaybe<Scalars['Int']['input']>
    order_lte?: InputMaybe<Scalars['Int']['input']>
    order_not?: InputMaybe<Scalars['Int']['input']>
    order_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    parentCategory?: InputMaybe<Scalars['String']['input']>
    parentCategory_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    parentCategory_not?: InputMaybe<Scalars['String']['input']>
    parentCategory_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type MarketCategoryHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type MarketCategoryHistoryRecordsCreateInput = {
    data?: InputMaybe<MarketCategoryHistoryRecordCreateInput>
}

export type MarketCategoryHistoryRecordsUpdateInput = {
    data?: InputMaybe<MarketCategoryHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type MarketCategoryMobileSettingsSchemaField = {
    bgColor: Scalars['String']['input']
    titleColor: Scalars['String']['input']
}

export type MarketCategoryMobileSettingsSchemaFieldInput = {
    __typename?: 'MarketCategoryMobileSettingsSchemaFieldInput'
    bgColor: Scalars['String']['output']
    titleColor: Scalars['String']['output']
}

export type MarketCategoryRelateToOneInput = {
    connect?: InputMaybe<MarketCategoryWhereUniqueInput>
    create?: InputMaybe<MarketCategoryCreateInput>
    disconnect?: InputMaybe<MarketCategoryWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type MarketCategoryUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    image?: InputMaybe<Scalars['Upload']['input']>
    mobileSettings?: InputMaybe<MarketCategoryMobileSettingsSchemaField>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    order?: InputMaybe<Scalars['Int']['input']>
    parentCategory?: InputMaybe<MarketCategoryRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type MarketCategoryWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<MarketCategoryWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<MarketCategoryWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    image?: InputMaybe<Scalars['String']['input']>
    image_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    image_not?: InputMaybe<Scalars['String']['input']>
    image_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    mobileSettings?: InputMaybe<MarketCategoryMobileSettingsSchemaField>
    mobileSettings_in?: InputMaybe<Array<InputMaybe<MarketCategoryMobileSettingsSchemaField>>>
    mobileSettings_not?: InputMaybe<MarketCategoryMobileSettingsSchemaField>
    mobileSettings_not_in?: InputMaybe<Array<InputMaybe<MarketCategoryMobileSettingsSchemaField>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    order?: InputMaybe<Scalars['Int']['input']>
    order_gt?: InputMaybe<Scalars['Int']['input']>
    order_gte?: InputMaybe<Scalars['Int']['input']>
    order_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    order_lt?: InputMaybe<Scalars['Int']['input']>
    order_lte?: InputMaybe<Scalars['Int']['input']>
    order_not?: InputMaybe<Scalars['Int']['input']>
    order_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    parentCategory?: InputMaybe<MarketCategoryWhereInput>
    parentCategory_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type MarketCategoryWhereUniqueInput = {
    id: Scalars['ID']['input']
}

/**  Information about the market product  */
export type MarketItem = {
    __typename?: 'MarketItem'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MarketItem List config, or
   *  2. As an alias to the field set on 'labelField' in the MarketItem List config, or
   *  3. As an alias to a 'name' field on the MarketItem List (if one exists), or
   *  4. As an alias to the 'id' field on the MarketItem List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Description of market item  */
    description?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  Category to which the item belongs  */
    marketCategory?: Maybe<MarketCategory>
    /**  Name of market item  */
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Organization to which the item belongs  */
    organization?: Maybe<Organization>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Stock keeping unit  */
    sku?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type MarketItemCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    description?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    marketCategory?: InputMaybe<MarketCategoryRelateToOneInput>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    sku?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  Image file attached to the market item  */
export type MarketItemFile = {
    __typename?: 'MarketItemFile'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MarketItemFile List config, or
   *  2. As an alias to the field set on 'labelField' in the MarketItemFile List config, or
   *  3. As an alias to a 'name' field on the MarketItemFile List (if one exists), or
   *  4. As an alias to the 'id' field on the MarketItemFile List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  File object with meta information and publicUrl  */
    file?: Maybe<File>
    id: Scalars['ID']['output']
    /**  Link to MarketItem  */
    marketItem?: Maybe<MarketItem>
    newId?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type MarketItemFileCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['Upload']['input']>
    marketItem?: InputMaybe<MarketItemRelateToOneInput>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type MarketItemFileHistoryRecord = {
    __typename?: 'MarketItemFileHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MarketItemFileHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the MarketItemFileHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the MarketItemFileHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the MarketItemFileHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    file?: Maybe<Scalars['JSON']['output']>
    history_action?: Maybe<MarketItemFileHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    marketItem?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type MarketItemFileHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['JSON']['input']>
    history_action?: InputMaybe<MarketItemFileHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    marketItem?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum MarketItemFileHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type MarketItemFileHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['JSON']['input']>
    history_action?: InputMaybe<MarketItemFileHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    marketItem?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type MarketItemFileHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<MarketItemFileHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<MarketItemFileHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    file?: InputMaybe<Scalars['JSON']['input']>
    file_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    file_not?: InputMaybe<Scalars['JSON']['input']>
    file_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    history_action?: InputMaybe<MarketItemFileHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<MarketItemFileHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<MarketItemFileHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<MarketItemFileHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    marketItem?: InputMaybe<Scalars['String']['input']>
    marketItem_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    marketItem_not?: InputMaybe<Scalars['String']['input']>
    marketItem_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type MarketItemFileHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type MarketItemFileHistoryRecordsCreateInput = {
    data?: InputMaybe<MarketItemFileHistoryRecordCreateInput>
}

export type MarketItemFileHistoryRecordsUpdateInput = {
    data?: InputMaybe<MarketItemFileHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type MarketItemFileUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['Upload']['input']>
    marketItem?: InputMaybe<MarketItemRelateToOneInput>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type MarketItemFileWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<MarketItemFileWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<MarketItemFileWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    file?: InputMaybe<Scalars['String']['input']>
    file_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    file_not?: InputMaybe<Scalars['String']['input']>
    file_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    marketItem?: InputMaybe<MarketItemWhereInput>
    marketItem_is_null?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type MarketItemFileWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type MarketItemFilesCreateInput = {
    data?: InputMaybe<MarketItemFileCreateInput>
}

export type MarketItemFilesUpdateInput = {
    data?: InputMaybe<MarketItemFileUpdateInput>
    id: Scalars['ID']['input']
}

/**  A keystone list  */
export type MarketItemHistoryRecord = {
    __typename?: 'MarketItemHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MarketItemHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the MarketItemHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the MarketItemHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the MarketItemHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    description?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<MarketItemHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    marketCategory?: Maybe<Scalars['String']['output']>
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    sku?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type MarketItemHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    description?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<MarketItemHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    marketCategory?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sku?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum MarketItemHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type MarketItemHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    description?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<MarketItemHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    marketCategory?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sku?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type MarketItemHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<MarketItemHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<MarketItemHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    description?: InputMaybe<Scalars['String']['input']>
    description_contains?: InputMaybe<Scalars['String']['input']>
    description_contains_i?: InputMaybe<Scalars['String']['input']>
    description_ends_with?: InputMaybe<Scalars['String']['input']>
    description_ends_with_i?: InputMaybe<Scalars['String']['input']>
    description_i?: InputMaybe<Scalars['String']['input']>
    description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    description_not?: InputMaybe<Scalars['String']['input']>
    description_not_contains?: InputMaybe<Scalars['String']['input']>
    description_not_contains_i?: InputMaybe<Scalars['String']['input']>
    description_not_ends_with?: InputMaybe<Scalars['String']['input']>
    description_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    description_not_i?: InputMaybe<Scalars['String']['input']>
    description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    description_not_starts_with?: InputMaybe<Scalars['String']['input']>
    description_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    description_starts_with?: InputMaybe<Scalars['String']['input']>
    description_starts_with_i?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<MarketItemHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<MarketItemHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<MarketItemHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<MarketItemHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    marketCategory?: InputMaybe<Scalars['String']['input']>
    marketCategory_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    marketCategory_not?: InputMaybe<Scalars['String']['input']>
    marketCategory_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sku?: InputMaybe<Scalars['String']['input']>
    sku_contains?: InputMaybe<Scalars['String']['input']>
    sku_contains_i?: InputMaybe<Scalars['String']['input']>
    sku_ends_with?: InputMaybe<Scalars['String']['input']>
    sku_ends_with_i?: InputMaybe<Scalars['String']['input']>
    sku_i?: InputMaybe<Scalars['String']['input']>
    sku_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sku_not?: InputMaybe<Scalars['String']['input']>
    sku_not_contains?: InputMaybe<Scalars['String']['input']>
    sku_not_contains_i?: InputMaybe<Scalars['String']['input']>
    sku_not_ends_with?: InputMaybe<Scalars['String']['input']>
    sku_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    sku_not_i?: InputMaybe<Scalars['String']['input']>
    sku_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sku_not_starts_with?: InputMaybe<Scalars['String']['input']>
    sku_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    sku_starts_with?: InputMaybe<Scalars['String']['input']>
    sku_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type MarketItemHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type MarketItemHistoryRecordsCreateInput = {
    data?: InputMaybe<MarketItemHistoryRecordCreateInput>
}

export type MarketItemHistoryRecordsUpdateInput = {
    data?: InputMaybe<MarketItemHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

/**  MarketItemPrice is set separately from MarketItem and indicates the price of the service.  */
export type MarketItemPrice = {
    __typename?: 'MarketItemPrice'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MarketItemPrice List config, or
   *  2. As an alias to the field set on 'labelField' in the MarketItemPrice List config, or
   *  3. As an alias to a 'name' field on the MarketItemPrice List (if one exists), or
   *  4. As an alias to the 'id' field on the MarketItemPrice List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  Link to MarketItem  */
    marketItem?: Maybe<MarketItem>
    newId?: Maybe<Scalars['String']['output']>
    /**  The price field contains information about options (type=variant) and additional services (type=extra). Options are grouped by the group field and offer the choice of one option from the group. Extras are not grouped in any way and can be selected independently. The price may not be final but minimum. To do this, we use the isMin field.  */
    price: Array<MarketItemPricePriceSchemaField>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type MarketItemPriceCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    marketItem?: InputMaybe<MarketItemRelateToOneInput>
    newId?: InputMaybe<Scalars['String']['input']>
    price?: InputMaybe<Array<MarketItemPricePriceSchemaFieldInput>>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type MarketItemPriceHistoryRecord = {
    __typename?: 'MarketItemPriceHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MarketItemPriceHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the MarketItemPriceHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the MarketItemPriceHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the MarketItemPriceHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<MarketItemPriceHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    marketItem?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    price?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type MarketItemPriceHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<MarketItemPriceHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    marketItem?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    price?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum MarketItemPriceHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type MarketItemPriceHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<MarketItemPriceHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    marketItem?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    price?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type MarketItemPriceHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<MarketItemPriceHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<MarketItemPriceHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<MarketItemPriceHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<MarketItemPriceHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<MarketItemPriceHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<MarketItemPriceHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    marketItem?: InputMaybe<Scalars['String']['input']>
    marketItem_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    marketItem_not?: InputMaybe<Scalars['String']['input']>
    marketItem_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    price?: InputMaybe<Scalars['JSON']['input']>
    price_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    price_not?: InputMaybe<Scalars['JSON']['input']>
    price_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type MarketItemPriceHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type MarketItemPriceHistoryRecordsCreateInput = {
    data?: InputMaybe<MarketItemPriceHistoryRecordCreateInput>
}

export type MarketItemPriceHistoryRecordsUpdateInput = {
    data?: InputMaybe<MarketItemPriceHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type MarketItemPricePriceSchemaField = {
    __typename?: 'MarketItemPricePriceSchemaField'
    currencyCode?: Maybe<Scalars['String']['output']>
    isMin: Scalars['Boolean']['output']
    name: Scalars['String']['output']
    price: Scalars['String']['output']
    salesTaxPercent?: Maybe<Scalars['String']['output']>
    type: Scalars['String']['output']
    vatPercent?: Maybe<Scalars['String']['output']>
}

export type MarketItemPricePriceSchemaFieldInput = {
    currencyCode?: InputMaybe<Scalars['String']['input']>
    isMin: Scalars['Boolean']['input']
    name: Scalars['String']['input']
    price: Scalars['String']['input']
    salesTaxPercent?: InputMaybe<Scalars['String']['input']>
    type: Scalars['String']['input']
    vatPercent?: InputMaybe<Scalars['String']['input']>
}

export type MarketItemPriceRelateToOneInput = {
    connect?: InputMaybe<MarketItemPriceWhereUniqueInput>
    create?: InputMaybe<MarketItemPriceCreateInput>
    disconnect?: InputMaybe<MarketItemPriceWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type MarketItemPriceUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    marketItem?: InputMaybe<MarketItemRelateToOneInput>
    newId?: InputMaybe<Scalars['String']['input']>
    price?: InputMaybe<Array<MarketItemPricePriceSchemaFieldInput>>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type MarketItemPriceWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<MarketItemPriceWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<MarketItemPriceWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    marketItem?: InputMaybe<MarketItemWhereInput>
    marketItem_is_null?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    price?: InputMaybe<Array<MarketItemPricePriceSchemaFieldInput>>
    price_in?: InputMaybe<Array<InputMaybe<Array<MarketItemPricePriceSchemaFieldInput>>>>
    price_not?: InputMaybe<Array<MarketItemPricePriceSchemaFieldInput>>
    price_not_in?: InputMaybe<Array<InputMaybe<Array<MarketItemPricePriceSchemaFieldInput>>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type MarketItemPriceWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type MarketItemPricesCreateInput = {
    data?: InputMaybe<MarketItemPriceCreateInput>
}

export type MarketItemPricesUpdateInput = {
    data?: InputMaybe<MarketItemPriceUpdateInput>
    id: Scalars['ID']['input']
}

export type MarketItemRelateToOneInput = {
    connect?: InputMaybe<MarketItemWhereUniqueInput>
    create?: InputMaybe<MarketItemCreateInput>
    disconnect?: InputMaybe<MarketItemWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type MarketItemUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    description?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    marketCategory?: InputMaybe<MarketCategoryRelateToOneInput>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    sku?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type MarketItemWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<MarketItemWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<MarketItemWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    description?: InputMaybe<Scalars['String']['input']>
    description_contains?: InputMaybe<Scalars['String']['input']>
    description_contains_i?: InputMaybe<Scalars['String']['input']>
    description_ends_with?: InputMaybe<Scalars['String']['input']>
    description_ends_with_i?: InputMaybe<Scalars['String']['input']>
    description_i?: InputMaybe<Scalars['String']['input']>
    description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    description_not?: InputMaybe<Scalars['String']['input']>
    description_not_contains?: InputMaybe<Scalars['String']['input']>
    description_not_contains_i?: InputMaybe<Scalars['String']['input']>
    description_not_ends_with?: InputMaybe<Scalars['String']['input']>
    description_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    description_not_i?: InputMaybe<Scalars['String']['input']>
    description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    description_not_starts_with?: InputMaybe<Scalars['String']['input']>
    description_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    description_starts_with?: InputMaybe<Scalars['String']['input']>
    description_starts_with_i?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    marketCategory?: InputMaybe<MarketCategoryWhereInput>
    marketCategory_is_null?: InputMaybe<Scalars['Boolean']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sku?: InputMaybe<Scalars['String']['input']>
    sku_contains?: InputMaybe<Scalars['String']['input']>
    sku_contains_i?: InputMaybe<Scalars['String']['input']>
    sku_ends_with?: InputMaybe<Scalars['String']['input']>
    sku_ends_with_i?: InputMaybe<Scalars['String']['input']>
    sku_i?: InputMaybe<Scalars['String']['input']>
    sku_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sku_not?: InputMaybe<Scalars['String']['input']>
    sku_not_contains?: InputMaybe<Scalars['String']['input']>
    sku_not_contains_i?: InputMaybe<Scalars['String']['input']>
    sku_not_ends_with?: InputMaybe<Scalars['String']['input']>
    sku_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    sku_not_i?: InputMaybe<Scalars['String']['input']>
    sku_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sku_not_starts_with?: InputMaybe<Scalars['String']['input']>
    sku_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    sku_starts_with?: InputMaybe<Scalars['String']['input']>
    sku_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type MarketItemWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type MarketItemsCreateInput = {
    data?: InputMaybe<MarketItemCreateInput>
}

export type MarketItemsUpdateInput = {
    data?: InputMaybe<MarketItemUpdateInput>
    id: Scalars['ID']['input']
}

/**  Which residents can see the particular market item price instance  */
export type MarketPriceScope = {
    __typename?: 'MarketPriceScope'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MarketPriceScope List config, or
   *  2. As an alias to the field set on 'labelField' in the MarketPriceScope List config, or
   *  3. As an alias to a 'name' field on the MarketPriceScope List (if one exists), or
   *  4. As an alias to the 'id' field on the MarketPriceScope List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  Link to market item price  */
    marketItemPrice?: Maybe<MarketItemPrice>
    newId?: Maybe<Scalars['String']['output']>
    /**  Link to property  */
    property?: Maybe<Property>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  The scope type. This is an auto-calculated field. Used to find items by scopes filled with some set of attributes.  */
    type?: Maybe<MarketPriceScopeTypeType>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type MarketPriceScopeCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    marketItemPrice?: InputMaybe<MarketItemPriceRelateToOneInput>
    newId?: InputMaybe<Scalars['String']['input']>
    property?: InputMaybe<PropertyRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    type?: InputMaybe<MarketPriceScopeTypeType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type MarketPriceScopeHistoryRecord = {
    __typename?: 'MarketPriceScopeHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MarketPriceScopeHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the MarketPriceScopeHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the MarketPriceScopeHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the MarketPriceScopeHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<MarketPriceScopeHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    marketItemPrice?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    property?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    type?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type MarketPriceScopeHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<MarketPriceScopeHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    marketItemPrice?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    property?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    type?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum MarketPriceScopeHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type MarketPriceScopeHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<MarketPriceScopeHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    marketItemPrice?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    property?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    type?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type MarketPriceScopeHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<MarketPriceScopeHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<MarketPriceScopeHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<MarketPriceScopeHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<MarketPriceScopeHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<MarketPriceScopeHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<MarketPriceScopeHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    marketItemPrice?: InputMaybe<Scalars['String']['input']>
    marketItemPrice_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    marketItemPrice_not?: InputMaybe<Scalars['String']['input']>
    marketItemPrice_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    property?: InputMaybe<Scalars['String']['input']>
    property_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    property_not?: InputMaybe<Scalars['String']['input']>
    property_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    type?: InputMaybe<Scalars['String']['input']>
    type_contains?: InputMaybe<Scalars['String']['input']>
    type_contains_i?: InputMaybe<Scalars['String']['input']>
    type_ends_with?: InputMaybe<Scalars['String']['input']>
    type_ends_with_i?: InputMaybe<Scalars['String']['input']>
    type_i?: InputMaybe<Scalars['String']['input']>
    type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    type_not?: InputMaybe<Scalars['String']['input']>
    type_not_contains?: InputMaybe<Scalars['String']['input']>
    type_not_contains_i?: InputMaybe<Scalars['String']['input']>
    type_not_ends_with?: InputMaybe<Scalars['String']['input']>
    type_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    type_not_i?: InputMaybe<Scalars['String']['input']>
    type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    type_not_starts_with?: InputMaybe<Scalars['String']['input']>
    type_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    type_starts_with?: InputMaybe<Scalars['String']['input']>
    type_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type MarketPriceScopeHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type MarketPriceScopeHistoryRecordsCreateInput = {
    data?: InputMaybe<MarketPriceScopeHistoryRecordCreateInput>
}

export type MarketPriceScopeHistoryRecordsUpdateInput = {
    data?: InputMaybe<MarketPriceScopeHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export enum MarketPriceScopeTypeType {
    Organization = 'organization',
    Property = 'property',
    Unknown = 'unknown',
}

export type MarketPriceScopeUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    marketItemPrice?: InputMaybe<MarketItemPriceRelateToOneInput>
    newId?: InputMaybe<Scalars['String']['input']>
    property?: InputMaybe<PropertyRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    type?: InputMaybe<MarketPriceScopeTypeType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type MarketPriceScopeWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<MarketPriceScopeWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<MarketPriceScopeWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    marketItemPrice?: InputMaybe<MarketItemPriceWhereInput>
    marketItemPrice_is_null?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    property?: InputMaybe<PropertyWhereInput>
    property_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    type?: InputMaybe<MarketPriceScopeTypeType>
    type_in?: InputMaybe<Array<InputMaybe<MarketPriceScopeTypeType>>>
    type_not?: InputMaybe<MarketPriceScopeTypeType>
    type_not_in?: InputMaybe<Array<InputMaybe<MarketPriceScopeTypeType>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type MarketPriceScopeWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type MarketPriceScopesCreateInput = {
    data?: InputMaybe<MarketPriceScopeCreateInput>
}

export type MarketPriceScopesUpdateInput = {
    data?: InputMaybe<MarketPriceScopeUpdateInput>
    id: Scalars['ID']['input']
}

/**  Setting up special rules for the marketplace  */
export type MarketSetting = {
    __typename?: 'MarketSetting'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MarketSetting List config, or
   *  2. As an alias to the field set on 'labelField' in the MarketSetting List config, or
   *  3. As an alias to a 'name' field on the MarketSetting List (if one exists), or
   *  4. As an alias to the 'id' field on the MarketSetting List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['String']['output']>
    /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
    organization?: Maybe<Organization>
    /**  Types of payments are allowed for selection on the resident’s side  */
    residentAllowedPaymentTypes?: Maybe<Array<InvoicePaymentTypeType>>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type MarketSettingCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    residentAllowedPaymentTypes?: InputMaybe<Array<InvoicePaymentTypeType>>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type MarketSettingHistoryRecord = {
    __typename?: 'MarketSettingHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MarketSettingHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the MarketSettingHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the MarketSettingHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the MarketSettingHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<MarketSettingHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    residentAllowedPaymentTypes?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type MarketSettingHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<MarketSettingHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    residentAllowedPaymentTypes?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum MarketSettingHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type MarketSettingHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<MarketSettingHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    residentAllowedPaymentTypes?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type MarketSettingHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<MarketSettingHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<MarketSettingHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<MarketSettingHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<MarketSettingHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<MarketSettingHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<MarketSettingHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    residentAllowedPaymentTypes?: InputMaybe<Scalars['JSON']['input']>
    residentAllowedPaymentTypes_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    residentAllowedPaymentTypes_not?: InputMaybe<Scalars['JSON']['input']>
    residentAllowedPaymentTypes_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type MarketSettingHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type MarketSettingHistoryRecordsCreateInput = {
    data?: InputMaybe<MarketSettingHistoryRecordCreateInput>
}

export type MarketSettingHistoryRecordsUpdateInput = {
    data?: InputMaybe<MarketSettingHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type MarketSettingUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    residentAllowedPaymentTypes?: InputMaybe<Array<InvoicePaymentTypeType>>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type MarketSettingWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<MarketSettingWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<MarketSettingWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    residentAllowedPaymentTypes?: InputMaybe<Array<InvoicePaymentTypeType>>
    residentAllowedPaymentTypes_in?: InputMaybe<Array<InputMaybe<Array<InvoicePaymentTypeType>>>>
    residentAllowedPaymentTypes_not?: InputMaybe<Array<InvoicePaymentTypeType>>
    residentAllowedPaymentTypes_not_in?: InputMaybe<Array<InputMaybe<Array<InvoicePaymentTypeType>>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type MarketSettingWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type MarketSettingsCreateInput = {
    data?: InputMaybe<MarketSettingCreateInput>
}

export type MarketSettingsUpdateInput = {
    data?: InputMaybe<MarketSettingUpdateInput>
    id: Scalars['ID']['input']
}

/**  Notification message  */
export type Message = {
    __typename?: 'Message'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the Message List config, or
   *  2. As an alias to the field set on 'labelField' in the Message List config, or
   *  3. As an alias to a 'name' field on the Message List (if one exists), or
   *  4. As an alias to the 'id' field on the Message List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Delivered (received) at time  */
    deliveredAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  to Email  */
    email?: Maybe<Scalars['String']['output']>
    /**  from Email  */
    emailFrom?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    /**  Message status  */
    lang?: Maybe<MessageLangType>
    /**  Message context  */
    meta?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  This message is related to some organization. Organization can manage their messages  */
    organization?: Maybe<Organization>
    /**  to Phone  */
    phone?: Maybe<Scalars['String']['output']>
    /**  Task processing metadata. Just for debug purpose. You can see exactly what and where the message was sent  */
    processingMeta?: Maybe<Scalars['JSON']['output']>
    /**  Read at time  */
    readAt?: Maybe<Scalars['String']['output']>
    /**  to Remote client  */
    remoteClient?: Maybe<RemoteClient>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Sent at time  */
    sentAt?: Maybe<Scalars['String']['output']>
    /**  Message status  */
    status?: Maybe<MessageStatusType>
    /**  Message type  */
    type?: Maybe<MessageType>
    /**  Unique message key. You can use it if you need to make sure that the message you are trying to create has not been created before. Fields `user`, `type` and `uniqkey` is to be unique. If you don't have a `user`, the fields `type` and `uniqkey` is to be unique  */
    uniqKey?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    /**  to User  */
    user?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

/**  [Deprecated] Rule for blocking specific type of messages for apps. Use B2CAppMessageSetting  */
export type MessageAppBlackList = {
    __typename?: 'MessageAppBlackList'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MessageAppBlackList List config, or
   *  2. As an alias to the field set on 'labelField' in the MessageAppBlackList List config, or
   *  3. As an alias to a 'name' field on the MessageAppBlackList List (if one exists), or
   *  4. As an alias to the 'id' field on the MessageAppBlackList List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  the app we want to block from sending messages to (null - all apps)  */
    app?: Maybe<B2CApp>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  The reason why the entry was added to the MessageAppBlackList  */
    description?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  The type of message we want to block  */
    type?: Maybe<MessageType>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type MessageAppBlackListCreateInput = {
    app?: InputMaybe<B2CAppRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    description?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    type?: InputMaybe<MessageType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type MessageAppBlackListHistoryRecord = {
    __typename?: 'MessageAppBlackListHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MessageAppBlackListHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the MessageAppBlackListHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the MessageAppBlackListHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the MessageAppBlackListHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    app?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    description?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<MessageAppBlackListHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    type?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type MessageAppBlackListHistoryRecordCreateInput = {
    app?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    description?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<MessageAppBlackListHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    type?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum MessageAppBlackListHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type MessageAppBlackListHistoryRecordUpdateInput = {
    app?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    description?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<MessageAppBlackListHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    type?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type MessageAppBlackListHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<MessageAppBlackListHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<MessageAppBlackListHistoryRecordWhereInput>>>
    app?: InputMaybe<Scalars['String']['input']>
    app_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    app_not?: InputMaybe<Scalars['String']['input']>
    app_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    description?: InputMaybe<Scalars['String']['input']>
    description_contains?: InputMaybe<Scalars['String']['input']>
    description_contains_i?: InputMaybe<Scalars['String']['input']>
    description_ends_with?: InputMaybe<Scalars['String']['input']>
    description_ends_with_i?: InputMaybe<Scalars['String']['input']>
    description_i?: InputMaybe<Scalars['String']['input']>
    description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    description_not?: InputMaybe<Scalars['String']['input']>
    description_not_contains?: InputMaybe<Scalars['String']['input']>
    description_not_contains_i?: InputMaybe<Scalars['String']['input']>
    description_not_ends_with?: InputMaybe<Scalars['String']['input']>
    description_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    description_not_i?: InputMaybe<Scalars['String']['input']>
    description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    description_not_starts_with?: InputMaybe<Scalars['String']['input']>
    description_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    description_starts_with?: InputMaybe<Scalars['String']['input']>
    description_starts_with_i?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<MessageAppBlackListHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<MessageAppBlackListHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<MessageAppBlackListHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<MessageAppBlackListHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    type?: InputMaybe<Scalars['String']['input']>
    type_contains?: InputMaybe<Scalars['String']['input']>
    type_contains_i?: InputMaybe<Scalars['String']['input']>
    type_ends_with?: InputMaybe<Scalars['String']['input']>
    type_ends_with_i?: InputMaybe<Scalars['String']['input']>
    type_i?: InputMaybe<Scalars['String']['input']>
    type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    type_not?: InputMaybe<Scalars['String']['input']>
    type_not_contains?: InputMaybe<Scalars['String']['input']>
    type_not_contains_i?: InputMaybe<Scalars['String']['input']>
    type_not_ends_with?: InputMaybe<Scalars['String']['input']>
    type_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    type_not_i?: InputMaybe<Scalars['String']['input']>
    type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    type_not_starts_with?: InputMaybe<Scalars['String']['input']>
    type_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    type_starts_with?: InputMaybe<Scalars['String']['input']>
    type_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type MessageAppBlackListHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type MessageAppBlackListHistoryRecordsCreateInput = {
    data?: InputMaybe<MessageAppBlackListHistoryRecordCreateInput>
}

export type MessageAppBlackListHistoryRecordsUpdateInput = {
    data?: InputMaybe<MessageAppBlackListHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type MessageAppBlackListUpdateInput = {
    app?: InputMaybe<B2CAppRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    description?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    type?: InputMaybe<MessageType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type MessageAppBlackListWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<MessageAppBlackListWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<MessageAppBlackListWhereInput>>>
    app?: InputMaybe<B2CAppWhereInput>
    app_is_null?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    description?: InputMaybe<Scalars['String']['input']>
    description_contains?: InputMaybe<Scalars['String']['input']>
    description_contains_i?: InputMaybe<Scalars['String']['input']>
    description_ends_with?: InputMaybe<Scalars['String']['input']>
    description_ends_with_i?: InputMaybe<Scalars['String']['input']>
    description_i?: InputMaybe<Scalars['String']['input']>
    description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    description_not?: InputMaybe<Scalars['String']['input']>
    description_not_contains?: InputMaybe<Scalars['String']['input']>
    description_not_contains_i?: InputMaybe<Scalars['String']['input']>
    description_not_ends_with?: InputMaybe<Scalars['String']['input']>
    description_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    description_not_i?: InputMaybe<Scalars['String']['input']>
    description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    description_not_starts_with?: InputMaybe<Scalars['String']['input']>
    description_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    description_starts_with?: InputMaybe<Scalars['String']['input']>
    description_starts_with_i?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    type?: InputMaybe<MessageType>
    type_in?: InputMaybe<Array<InputMaybe<MessageType>>>
    type_not?: InputMaybe<MessageType>
    type_not_in?: InputMaybe<Array<InputMaybe<MessageType>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type MessageAppBlackListWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type MessageAppBlackListsCreateInput = {
    data?: InputMaybe<MessageAppBlackListCreateInput>
}

export type MessageAppBlackListsUpdateInput = {
    data?: InputMaybe<MessageAppBlackListUpdateInput>
    id: Scalars['ID']['input']
}

/**  Message batch. Adding record here will cause sending message to all targets (user, phone or email) listed in targets via transport detected based on target type.  */
export type MessageBatch = {
    __typename?: 'MessageBatch'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MessageBatch List config, or
   *  2. As an alias to the field set on 'labelField' in the MessageBatch List config, or
   *  3. As an alias to a 'name' field on the MessageBatch List (if one exists), or
   *  4. As an alias to the 'id' field on the MessageBatch List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    /**  A link to bring user to specified position within a mobile app. Used ONLY for push notifications  */
    deepLink?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  Common body for messages to be sent. Can be multiline, but shouldn't be very long in case of SMS or Push.  */
    message?: Maybe<Scalars['String']['output']>
    /**  Message type to use for sending notification  */
    messageType?: Maybe<MessageBatchMessageTypeType>
    newId?: Maybe<Scalars['String']['output']>
    /**  Batch processing results will be stored here  */
    processingMeta?: Maybe<Scalars['JSON']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Batch processing status  */
    status?: Maybe<MessageBatchStatusType>
    /**  List of ids for "push", "email", "sms" message types; list of emails for "email" message types; list of phone numbers for "sms" message types. Can be mixed. For each entry an appropriate message type will be used.  */
    targets?: Maybe<Scalars['JSON']['output']>
    /**  Common title for messages to be sent. Single line, shouldn't be very long.  */
    title?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type MessageBatchCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deepLink?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    message?: InputMaybe<Scalars['String']['input']>
    messageType?: InputMaybe<MessageBatchMessageTypeType>
    newId?: InputMaybe<Scalars['String']['input']>
    processingMeta?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<SenderFieldInput>
    status?: InputMaybe<MessageBatchStatusType>
    targets?: InputMaybe<Scalars['JSON']['input']>
    title?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type MessageBatchHistoryRecord = {
    __typename?: 'MessageBatchHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MessageBatchHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the MessageBatchHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the MessageBatchHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the MessageBatchHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deepLink?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<MessageBatchHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    message?: Maybe<Scalars['String']['output']>
    messageType?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    processingMeta?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    status?: Maybe<Scalars['String']['output']>
    targets?: Maybe<Scalars['JSON']['output']>
    title?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type MessageBatchHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deepLink?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<MessageBatchHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    message?: InputMaybe<Scalars['String']['input']>
    messageType?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    processingMeta?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    targets?: InputMaybe<Scalars['JSON']['input']>
    title?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum MessageBatchHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type MessageBatchHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deepLink?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<MessageBatchHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    message?: InputMaybe<Scalars['String']['input']>
    messageType?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    processingMeta?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    targets?: InputMaybe<Scalars['JSON']['input']>
    title?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type MessageBatchHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<MessageBatchHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<MessageBatchHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deepLink?: InputMaybe<Scalars['String']['input']>
    deepLink_contains?: InputMaybe<Scalars['String']['input']>
    deepLink_contains_i?: InputMaybe<Scalars['String']['input']>
    deepLink_ends_with?: InputMaybe<Scalars['String']['input']>
    deepLink_ends_with_i?: InputMaybe<Scalars['String']['input']>
    deepLink_i?: InputMaybe<Scalars['String']['input']>
    deepLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deepLink_not?: InputMaybe<Scalars['String']['input']>
    deepLink_not_contains?: InputMaybe<Scalars['String']['input']>
    deepLink_not_contains_i?: InputMaybe<Scalars['String']['input']>
    deepLink_not_ends_with?: InputMaybe<Scalars['String']['input']>
    deepLink_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    deepLink_not_i?: InputMaybe<Scalars['String']['input']>
    deepLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deepLink_not_starts_with?: InputMaybe<Scalars['String']['input']>
    deepLink_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    deepLink_starts_with?: InputMaybe<Scalars['String']['input']>
    deepLink_starts_with_i?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<MessageBatchHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<MessageBatchHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<MessageBatchHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<MessageBatchHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    message?: InputMaybe<Scalars['String']['input']>
    messageType?: InputMaybe<Scalars['String']['input']>
    messageType_contains?: InputMaybe<Scalars['String']['input']>
    messageType_contains_i?: InputMaybe<Scalars['String']['input']>
    messageType_ends_with?: InputMaybe<Scalars['String']['input']>
    messageType_ends_with_i?: InputMaybe<Scalars['String']['input']>
    messageType_i?: InputMaybe<Scalars['String']['input']>
    messageType_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    messageType_not?: InputMaybe<Scalars['String']['input']>
    messageType_not_contains?: InputMaybe<Scalars['String']['input']>
    messageType_not_contains_i?: InputMaybe<Scalars['String']['input']>
    messageType_not_ends_with?: InputMaybe<Scalars['String']['input']>
    messageType_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    messageType_not_i?: InputMaybe<Scalars['String']['input']>
    messageType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    messageType_not_starts_with?: InputMaybe<Scalars['String']['input']>
    messageType_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    messageType_starts_with?: InputMaybe<Scalars['String']['input']>
    messageType_starts_with_i?: InputMaybe<Scalars['String']['input']>
    message_contains?: InputMaybe<Scalars['String']['input']>
    message_contains_i?: InputMaybe<Scalars['String']['input']>
    message_ends_with?: InputMaybe<Scalars['String']['input']>
    message_ends_with_i?: InputMaybe<Scalars['String']['input']>
    message_i?: InputMaybe<Scalars['String']['input']>
    message_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    message_not?: InputMaybe<Scalars['String']['input']>
    message_not_contains?: InputMaybe<Scalars['String']['input']>
    message_not_contains_i?: InputMaybe<Scalars['String']['input']>
    message_not_ends_with?: InputMaybe<Scalars['String']['input']>
    message_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    message_not_i?: InputMaybe<Scalars['String']['input']>
    message_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    message_not_starts_with?: InputMaybe<Scalars['String']['input']>
    message_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    message_starts_with?: InputMaybe<Scalars['String']['input']>
    message_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    processingMeta?: InputMaybe<Scalars['JSON']['input']>
    processingMeta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    processingMeta_not?: InputMaybe<Scalars['JSON']['input']>
    processingMeta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    status?: InputMaybe<Scalars['String']['input']>
    status_contains?: InputMaybe<Scalars['String']['input']>
    status_contains_i?: InputMaybe<Scalars['String']['input']>
    status_ends_with?: InputMaybe<Scalars['String']['input']>
    status_ends_with_i?: InputMaybe<Scalars['String']['input']>
    status_i?: InputMaybe<Scalars['String']['input']>
    status_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status_not?: InputMaybe<Scalars['String']['input']>
    status_not_contains?: InputMaybe<Scalars['String']['input']>
    status_not_contains_i?: InputMaybe<Scalars['String']['input']>
    status_not_ends_with?: InputMaybe<Scalars['String']['input']>
    status_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    status_not_i?: InputMaybe<Scalars['String']['input']>
    status_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status_not_starts_with?: InputMaybe<Scalars['String']['input']>
    status_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    status_starts_with?: InputMaybe<Scalars['String']['input']>
    status_starts_with_i?: InputMaybe<Scalars['String']['input']>
    targets?: InputMaybe<Scalars['JSON']['input']>
    targets_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    targets_not?: InputMaybe<Scalars['JSON']['input']>
    targets_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    title?: InputMaybe<Scalars['String']['input']>
    title_contains?: InputMaybe<Scalars['String']['input']>
    title_contains_i?: InputMaybe<Scalars['String']['input']>
    title_ends_with?: InputMaybe<Scalars['String']['input']>
    title_ends_with_i?: InputMaybe<Scalars['String']['input']>
    title_i?: InputMaybe<Scalars['String']['input']>
    title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    title_not?: InputMaybe<Scalars['String']['input']>
    title_not_contains?: InputMaybe<Scalars['String']['input']>
    title_not_contains_i?: InputMaybe<Scalars['String']['input']>
    title_not_ends_with?: InputMaybe<Scalars['String']['input']>
    title_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    title_not_i?: InputMaybe<Scalars['String']['input']>
    title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    title_not_starts_with?: InputMaybe<Scalars['String']['input']>
    title_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    title_starts_with?: InputMaybe<Scalars['String']['input']>
    title_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type MessageBatchHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type MessageBatchHistoryRecordsCreateInput = {
    data?: InputMaybe<MessageBatchHistoryRecordCreateInput>
}

export type MessageBatchHistoryRecordsUpdateInput = {
    data?: InputMaybe<MessageBatchHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export enum MessageBatchMessageTypeType {
    CustomContentMessage = 'CUSTOM_CONTENT_MESSAGE',
    CustomContentMessageEmail = 'CUSTOM_CONTENT_MESSAGE_EMAIL',
    CustomContentMessagePush = 'CUSTOM_CONTENT_MESSAGE_PUSH',
    CustomContentMessageSms = 'CUSTOM_CONTENT_MESSAGE_SMS',
    MobileAppUpdateAvailableMessagePush = 'MOBILE_APP_UPDATE_AVAILABLE_MESSAGE_PUSH',
}

export enum MessageBatchStatusType {
    Created = 'created',
    Done = 'done',
    Failed = 'failed',
    Processing = 'processing',
}

export type MessageBatchUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deepLink?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    message?: InputMaybe<Scalars['String']['input']>
    messageType?: InputMaybe<MessageBatchMessageTypeType>
    newId?: InputMaybe<Scalars['String']['input']>
    processingMeta?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<SenderFieldInput>
    status?: InputMaybe<MessageBatchStatusType>
    targets?: InputMaybe<Scalars['JSON']['input']>
    title?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type MessageBatchWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<MessageBatchWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<MessageBatchWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deepLink?: InputMaybe<Scalars['String']['input']>
    deepLink_contains?: InputMaybe<Scalars['String']['input']>
    deepLink_contains_i?: InputMaybe<Scalars['String']['input']>
    deepLink_ends_with?: InputMaybe<Scalars['String']['input']>
    deepLink_ends_with_i?: InputMaybe<Scalars['String']['input']>
    deepLink_i?: InputMaybe<Scalars['String']['input']>
    deepLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deepLink_not?: InputMaybe<Scalars['String']['input']>
    deepLink_not_contains?: InputMaybe<Scalars['String']['input']>
    deepLink_not_contains_i?: InputMaybe<Scalars['String']['input']>
    deepLink_not_ends_with?: InputMaybe<Scalars['String']['input']>
    deepLink_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    deepLink_not_i?: InputMaybe<Scalars['String']['input']>
    deepLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deepLink_not_starts_with?: InputMaybe<Scalars['String']['input']>
    deepLink_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    deepLink_starts_with?: InputMaybe<Scalars['String']['input']>
    deepLink_starts_with_i?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    message?: InputMaybe<Scalars['String']['input']>
    messageType?: InputMaybe<MessageBatchMessageTypeType>
    messageType_in?: InputMaybe<Array<InputMaybe<MessageBatchMessageTypeType>>>
    messageType_not?: InputMaybe<MessageBatchMessageTypeType>
    messageType_not_in?: InputMaybe<Array<InputMaybe<MessageBatchMessageTypeType>>>
    message_contains?: InputMaybe<Scalars['String']['input']>
    message_contains_i?: InputMaybe<Scalars['String']['input']>
    message_ends_with?: InputMaybe<Scalars['String']['input']>
    message_ends_with_i?: InputMaybe<Scalars['String']['input']>
    message_i?: InputMaybe<Scalars['String']['input']>
    message_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    message_not?: InputMaybe<Scalars['String']['input']>
    message_not_contains?: InputMaybe<Scalars['String']['input']>
    message_not_contains_i?: InputMaybe<Scalars['String']['input']>
    message_not_ends_with?: InputMaybe<Scalars['String']['input']>
    message_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    message_not_i?: InputMaybe<Scalars['String']['input']>
    message_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    message_not_starts_with?: InputMaybe<Scalars['String']['input']>
    message_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    message_starts_with?: InputMaybe<Scalars['String']['input']>
    message_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    processingMeta?: InputMaybe<Scalars['JSON']['input']>
    processingMeta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    processingMeta_not?: InputMaybe<Scalars['JSON']['input']>
    processingMeta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    status?: InputMaybe<MessageBatchStatusType>
    status_in?: InputMaybe<Array<InputMaybe<MessageBatchStatusType>>>
    status_not?: InputMaybe<MessageBatchStatusType>
    status_not_in?: InputMaybe<Array<InputMaybe<MessageBatchStatusType>>>
    targets?: InputMaybe<Scalars['JSON']['input']>
    targets_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    targets_not?: InputMaybe<Scalars['JSON']['input']>
    targets_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    title?: InputMaybe<Scalars['String']['input']>
    title_contains?: InputMaybe<Scalars['String']['input']>
    title_contains_i?: InputMaybe<Scalars['String']['input']>
    title_ends_with?: InputMaybe<Scalars['String']['input']>
    title_ends_with_i?: InputMaybe<Scalars['String']['input']>
    title_i?: InputMaybe<Scalars['String']['input']>
    title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    title_not?: InputMaybe<Scalars['String']['input']>
    title_not_contains?: InputMaybe<Scalars['String']['input']>
    title_not_contains_i?: InputMaybe<Scalars['String']['input']>
    title_not_ends_with?: InputMaybe<Scalars['String']['input']>
    title_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    title_not_i?: InputMaybe<Scalars['String']['input']>
    title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    title_not_starts_with?: InputMaybe<Scalars['String']['input']>
    title_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    title_starts_with?: InputMaybe<Scalars['String']['input']>
    title_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type MessageBatchWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type MessageBatchesCreateInput = {
    data?: InputMaybe<MessageBatchCreateInput>
}

export type MessageBatchesUpdateInput = {
    data?: InputMaybe<MessageBatchUpdateInput>
    id: Scalars['ID']['input']
}

export type MessageCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deliveredAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    email?: InputMaybe<Scalars['String']['input']>
    emailFrom?: InputMaybe<Scalars['String']['input']>
    lang?: InputMaybe<MessageLangType>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    phone?: InputMaybe<Scalars['String']['input']>
    processingMeta?: InputMaybe<Scalars['JSON']['input']>
    readAt?: InputMaybe<Scalars['String']['input']>
    remoteClient?: InputMaybe<RemoteClientRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    sentAt?: InputMaybe<Scalars['String']['input']>
    status?: InputMaybe<MessageStatusType>
    type?: InputMaybe<MessageType>
    uniqKey?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type MessageHistoryRecord = {
    __typename?: 'MessageHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MessageHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the MessageHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the MessageHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the MessageHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    deliveredAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    email?: Maybe<Scalars['String']['output']>
    emailFrom?: Maybe<Scalars['String']['output']>
    history_action?: Maybe<MessageHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    lang?: Maybe<Scalars['String']['output']>
    meta?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    phone?: Maybe<Scalars['String']['output']>
    processingMeta?: Maybe<Scalars['JSON']['output']>
    readAt?: Maybe<Scalars['String']['output']>
    remoteClient?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    sentAt?: Maybe<Scalars['String']['output']>
    status?: Maybe<Scalars['String']['output']>
    type?: Maybe<Scalars['String']['output']>
    uniqKey?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    user?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type MessageHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deliveredAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    email?: InputMaybe<Scalars['String']['input']>
    emailFrom?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<MessageHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    lang?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    phone?: InputMaybe<Scalars['String']['input']>
    processingMeta?: InputMaybe<Scalars['JSON']['input']>
    readAt?: InputMaybe<Scalars['String']['input']>
    remoteClient?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sentAt?: InputMaybe<Scalars['String']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    type?: InputMaybe<Scalars['String']['input']>
    uniqKey?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum MessageHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type MessageHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deliveredAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    email?: InputMaybe<Scalars['String']['input']>
    emailFrom?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<MessageHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    lang?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    phone?: InputMaybe<Scalars['String']['input']>
    processingMeta?: InputMaybe<Scalars['JSON']['input']>
    readAt?: InputMaybe<Scalars['String']['input']>
    remoteClient?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sentAt?: InputMaybe<Scalars['String']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    type?: InputMaybe<Scalars['String']['input']>
    uniqKey?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type MessageHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<MessageHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<MessageHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deliveredAt?: InputMaybe<Scalars['String']['input']>
    deliveredAt_gt?: InputMaybe<Scalars['String']['input']>
    deliveredAt_gte?: InputMaybe<Scalars['String']['input']>
    deliveredAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deliveredAt_lt?: InputMaybe<Scalars['String']['input']>
    deliveredAt_lte?: InputMaybe<Scalars['String']['input']>
    deliveredAt_not?: InputMaybe<Scalars['String']['input']>
    deliveredAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    email?: InputMaybe<Scalars['String']['input']>
    emailFrom?: InputMaybe<Scalars['String']['input']>
    emailFrom_contains?: InputMaybe<Scalars['String']['input']>
    emailFrom_contains_i?: InputMaybe<Scalars['String']['input']>
    emailFrom_ends_with?: InputMaybe<Scalars['String']['input']>
    emailFrom_ends_with_i?: InputMaybe<Scalars['String']['input']>
    emailFrom_i?: InputMaybe<Scalars['String']['input']>
    emailFrom_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    emailFrom_not?: InputMaybe<Scalars['String']['input']>
    emailFrom_not_contains?: InputMaybe<Scalars['String']['input']>
    emailFrom_not_contains_i?: InputMaybe<Scalars['String']['input']>
    emailFrom_not_ends_with?: InputMaybe<Scalars['String']['input']>
    emailFrom_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    emailFrom_not_i?: InputMaybe<Scalars['String']['input']>
    emailFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    emailFrom_not_starts_with?: InputMaybe<Scalars['String']['input']>
    emailFrom_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    emailFrom_starts_with?: InputMaybe<Scalars['String']['input']>
    emailFrom_starts_with_i?: InputMaybe<Scalars['String']['input']>
    email_contains?: InputMaybe<Scalars['String']['input']>
    email_contains_i?: InputMaybe<Scalars['String']['input']>
    email_ends_with?: InputMaybe<Scalars['String']['input']>
    email_ends_with_i?: InputMaybe<Scalars['String']['input']>
    email_i?: InputMaybe<Scalars['String']['input']>
    email_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    email_not?: InputMaybe<Scalars['String']['input']>
    email_not_contains?: InputMaybe<Scalars['String']['input']>
    email_not_contains_i?: InputMaybe<Scalars['String']['input']>
    email_not_ends_with?: InputMaybe<Scalars['String']['input']>
    email_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    email_not_i?: InputMaybe<Scalars['String']['input']>
    email_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    email_not_starts_with?: InputMaybe<Scalars['String']['input']>
    email_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    email_starts_with?: InputMaybe<Scalars['String']['input']>
    email_starts_with_i?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<MessageHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<MessageHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<MessageHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<MessageHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    lang?: InputMaybe<Scalars['String']['input']>
    lang_contains?: InputMaybe<Scalars['String']['input']>
    lang_contains_i?: InputMaybe<Scalars['String']['input']>
    lang_ends_with?: InputMaybe<Scalars['String']['input']>
    lang_ends_with_i?: InputMaybe<Scalars['String']['input']>
    lang_i?: InputMaybe<Scalars['String']['input']>
    lang_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    lang_not?: InputMaybe<Scalars['String']['input']>
    lang_not_contains?: InputMaybe<Scalars['String']['input']>
    lang_not_contains_i?: InputMaybe<Scalars['String']['input']>
    lang_not_ends_with?: InputMaybe<Scalars['String']['input']>
    lang_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    lang_not_i?: InputMaybe<Scalars['String']['input']>
    lang_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    lang_not_starts_with?: InputMaybe<Scalars['String']['input']>
    lang_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    lang_starts_with?: InputMaybe<Scalars['String']['input']>
    lang_starts_with_i?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phone?: InputMaybe<Scalars['String']['input']>
    phone_contains?: InputMaybe<Scalars['String']['input']>
    phone_contains_i?: InputMaybe<Scalars['String']['input']>
    phone_ends_with?: InputMaybe<Scalars['String']['input']>
    phone_ends_with_i?: InputMaybe<Scalars['String']['input']>
    phone_i?: InputMaybe<Scalars['String']['input']>
    phone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phone_not?: InputMaybe<Scalars['String']['input']>
    phone_not_contains?: InputMaybe<Scalars['String']['input']>
    phone_not_contains_i?: InputMaybe<Scalars['String']['input']>
    phone_not_ends_with?: InputMaybe<Scalars['String']['input']>
    phone_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    phone_not_i?: InputMaybe<Scalars['String']['input']>
    phone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phone_not_starts_with?: InputMaybe<Scalars['String']['input']>
    phone_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    phone_starts_with?: InputMaybe<Scalars['String']['input']>
    phone_starts_with_i?: InputMaybe<Scalars['String']['input']>
    processingMeta?: InputMaybe<Scalars['JSON']['input']>
    processingMeta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    processingMeta_not?: InputMaybe<Scalars['JSON']['input']>
    processingMeta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    readAt?: InputMaybe<Scalars['String']['input']>
    readAt_gt?: InputMaybe<Scalars['String']['input']>
    readAt_gte?: InputMaybe<Scalars['String']['input']>
    readAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    readAt_lt?: InputMaybe<Scalars['String']['input']>
    readAt_lte?: InputMaybe<Scalars['String']['input']>
    readAt_not?: InputMaybe<Scalars['String']['input']>
    readAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    remoteClient?: InputMaybe<Scalars['String']['input']>
    remoteClient_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    remoteClient_not?: InputMaybe<Scalars['String']['input']>
    remoteClient_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sentAt?: InputMaybe<Scalars['String']['input']>
    sentAt_gt?: InputMaybe<Scalars['String']['input']>
    sentAt_gte?: InputMaybe<Scalars['String']['input']>
    sentAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sentAt_lt?: InputMaybe<Scalars['String']['input']>
    sentAt_lte?: InputMaybe<Scalars['String']['input']>
    sentAt_not?: InputMaybe<Scalars['String']['input']>
    sentAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status?: InputMaybe<Scalars['String']['input']>
    status_contains?: InputMaybe<Scalars['String']['input']>
    status_contains_i?: InputMaybe<Scalars['String']['input']>
    status_ends_with?: InputMaybe<Scalars['String']['input']>
    status_ends_with_i?: InputMaybe<Scalars['String']['input']>
    status_i?: InputMaybe<Scalars['String']['input']>
    status_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status_not?: InputMaybe<Scalars['String']['input']>
    status_not_contains?: InputMaybe<Scalars['String']['input']>
    status_not_contains_i?: InputMaybe<Scalars['String']['input']>
    status_not_ends_with?: InputMaybe<Scalars['String']['input']>
    status_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    status_not_i?: InputMaybe<Scalars['String']['input']>
    status_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status_not_starts_with?: InputMaybe<Scalars['String']['input']>
    status_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    status_starts_with?: InputMaybe<Scalars['String']['input']>
    status_starts_with_i?: InputMaybe<Scalars['String']['input']>
    type?: InputMaybe<Scalars['String']['input']>
    type_contains?: InputMaybe<Scalars['String']['input']>
    type_contains_i?: InputMaybe<Scalars['String']['input']>
    type_ends_with?: InputMaybe<Scalars['String']['input']>
    type_ends_with_i?: InputMaybe<Scalars['String']['input']>
    type_i?: InputMaybe<Scalars['String']['input']>
    type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    type_not?: InputMaybe<Scalars['String']['input']>
    type_not_contains?: InputMaybe<Scalars['String']['input']>
    type_not_contains_i?: InputMaybe<Scalars['String']['input']>
    type_not_ends_with?: InputMaybe<Scalars['String']['input']>
    type_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    type_not_i?: InputMaybe<Scalars['String']['input']>
    type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    type_not_starts_with?: InputMaybe<Scalars['String']['input']>
    type_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    type_starts_with?: InputMaybe<Scalars['String']['input']>
    type_starts_with_i?: InputMaybe<Scalars['String']['input']>
    uniqKey?: InputMaybe<Scalars['String']['input']>
    uniqKey_contains?: InputMaybe<Scalars['String']['input']>
    uniqKey_contains_i?: InputMaybe<Scalars['String']['input']>
    uniqKey_ends_with?: InputMaybe<Scalars['String']['input']>
    uniqKey_ends_with_i?: InputMaybe<Scalars['String']['input']>
    uniqKey_i?: InputMaybe<Scalars['String']['input']>
    uniqKey_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    uniqKey_not?: InputMaybe<Scalars['String']['input']>
    uniqKey_not_contains?: InputMaybe<Scalars['String']['input']>
    uniqKey_not_contains_i?: InputMaybe<Scalars['String']['input']>
    uniqKey_not_ends_with?: InputMaybe<Scalars['String']['input']>
    uniqKey_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    uniqKey_not_i?: InputMaybe<Scalars['String']['input']>
    uniqKey_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    uniqKey_not_starts_with?: InputMaybe<Scalars['String']['input']>
    uniqKey_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    uniqKey_starts_with?: InputMaybe<Scalars['String']['input']>
    uniqKey_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user?: InputMaybe<Scalars['String']['input']>
    user_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user_not?: InputMaybe<Scalars['String']['input']>
    user_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type MessageHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type MessageHistoryRecordsCreateInput = {
    data?: InputMaybe<MessageHistoryRecordCreateInput>
}

export type MessageHistoryRecordsUpdateInput = {
    data?: InputMaybe<MessageHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export enum MessageLangType {
    En = 'en',
    Ru = 'ru',
}

/**  Rule for blocking specific type of messages for organization  */
export type MessageOrganizationBlackList = {
    __typename?: 'MessageOrganizationBlackList'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MessageOrganizationBlackList List config, or
   *  2. As an alias to the field set on 'labelField' in the MessageOrganizationBlackList List config, or
   *  3. As an alias to a 'name' field on the MessageOrganizationBlackList List (if one exists), or
   *  4. As an alias to the 'id' field on the MessageOrganizationBlackList List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  The reason why the entry was added to the MessageOrganizationBlackList  */
    description?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['String']['output']>
    /**  The organization we want to block from sending messages to (null - all organizations)  */
    organization?: Maybe<Organization>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  The type of message we want to block  */
    type?: Maybe<MessageType>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type MessageOrganizationBlackListCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    description?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    type?: InputMaybe<MessageType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type MessageOrganizationBlackListHistoryRecord = {
    __typename?: 'MessageOrganizationBlackListHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MessageOrganizationBlackListHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the MessageOrganizationBlackListHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the MessageOrganizationBlackListHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the MessageOrganizationBlackListHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    description?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<MessageOrganizationBlackListHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    type?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type MessageOrganizationBlackListHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    description?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<MessageOrganizationBlackListHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    type?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum MessageOrganizationBlackListHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type MessageOrganizationBlackListHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    description?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<MessageOrganizationBlackListHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    type?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type MessageOrganizationBlackListHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<MessageOrganizationBlackListHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<MessageOrganizationBlackListHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    description?: InputMaybe<Scalars['String']['input']>
    description_contains?: InputMaybe<Scalars['String']['input']>
    description_contains_i?: InputMaybe<Scalars['String']['input']>
    description_ends_with?: InputMaybe<Scalars['String']['input']>
    description_ends_with_i?: InputMaybe<Scalars['String']['input']>
    description_i?: InputMaybe<Scalars['String']['input']>
    description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    description_not?: InputMaybe<Scalars['String']['input']>
    description_not_contains?: InputMaybe<Scalars['String']['input']>
    description_not_contains_i?: InputMaybe<Scalars['String']['input']>
    description_not_ends_with?: InputMaybe<Scalars['String']['input']>
    description_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    description_not_i?: InputMaybe<Scalars['String']['input']>
    description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    description_not_starts_with?: InputMaybe<Scalars['String']['input']>
    description_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    description_starts_with?: InputMaybe<Scalars['String']['input']>
    description_starts_with_i?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<MessageOrganizationBlackListHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<MessageOrganizationBlackListHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<MessageOrganizationBlackListHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<MessageOrganizationBlackListHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    type?: InputMaybe<Scalars['String']['input']>
    type_contains?: InputMaybe<Scalars['String']['input']>
    type_contains_i?: InputMaybe<Scalars['String']['input']>
    type_ends_with?: InputMaybe<Scalars['String']['input']>
    type_ends_with_i?: InputMaybe<Scalars['String']['input']>
    type_i?: InputMaybe<Scalars['String']['input']>
    type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    type_not?: InputMaybe<Scalars['String']['input']>
    type_not_contains?: InputMaybe<Scalars['String']['input']>
    type_not_contains_i?: InputMaybe<Scalars['String']['input']>
    type_not_ends_with?: InputMaybe<Scalars['String']['input']>
    type_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    type_not_i?: InputMaybe<Scalars['String']['input']>
    type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    type_not_starts_with?: InputMaybe<Scalars['String']['input']>
    type_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    type_starts_with?: InputMaybe<Scalars['String']['input']>
    type_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type MessageOrganizationBlackListHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type MessageOrganizationBlackListHistoryRecordsCreateInput = {
    data?: InputMaybe<MessageOrganizationBlackListHistoryRecordCreateInput>
}

export type MessageOrganizationBlackListHistoryRecordsUpdateInput = {
    data?: InputMaybe<MessageOrganizationBlackListHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type MessageOrganizationBlackListUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    description?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    type?: InputMaybe<MessageType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type MessageOrganizationBlackListWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<MessageOrganizationBlackListWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<MessageOrganizationBlackListWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    description?: InputMaybe<Scalars['String']['input']>
    description_contains?: InputMaybe<Scalars['String']['input']>
    description_contains_i?: InputMaybe<Scalars['String']['input']>
    description_ends_with?: InputMaybe<Scalars['String']['input']>
    description_ends_with_i?: InputMaybe<Scalars['String']['input']>
    description_i?: InputMaybe<Scalars['String']['input']>
    description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    description_not?: InputMaybe<Scalars['String']['input']>
    description_not_contains?: InputMaybe<Scalars['String']['input']>
    description_not_contains_i?: InputMaybe<Scalars['String']['input']>
    description_not_ends_with?: InputMaybe<Scalars['String']['input']>
    description_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    description_not_i?: InputMaybe<Scalars['String']['input']>
    description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    description_not_starts_with?: InputMaybe<Scalars['String']['input']>
    description_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    description_starts_with?: InputMaybe<Scalars['String']['input']>
    description_starts_with_i?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    type?: InputMaybe<MessageType>
    type_in?: InputMaybe<Array<InputMaybe<MessageType>>>
    type_not?: InputMaybe<MessageType>
    type_not_in?: InputMaybe<Array<InputMaybe<MessageType>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type MessageOrganizationBlackListWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type MessageOrganizationBlackListsCreateInput = {
    data?: InputMaybe<MessageOrganizationBlackListCreateInput>
}

export type MessageOrganizationBlackListsUpdateInput = {
    data?: InputMaybe<MessageOrganizationBlackListUpdateInput>
    id: Scalars['ID']['input']
}

export enum MessageStatusType {
    Blacklisted = 'blacklisted',
    Canceled = 'canceled',
    Delivered = 'delivered',
    DisabledByUser = 'disabledByUser',
    Error = 'error',
    Processing = 'processing',
    Read = 'read',
    Resending = 'resending',
    Sending = 'sending',
    Sent = 'sent',
    Throttled = 'throttled',
}

export enum MessageType {
    B2CAppMessagePush = 'B2C_APP_MESSAGE_PUSH',
    BankAccountCreationRequest = 'BANK_ACCOUNT_CREATION_REQUEST',
    BillingReceiptAdded = 'BILLING_RECEIPT_ADDED',
    BillingReceiptAddedWithDebt = 'BILLING_RECEIPT_ADDED_WITH_DEBT',
    BillingReceiptAddedWithNoDebt = 'BILLING_RECEIPT_ADDED_WITH_NO_DEBT',
    BillingReceiptAvailable = 'BILLING_RECEIPT_AVAILABLE',
    BillingReceiptAvailableNoAccount = 'BILLING_RECEIPT_AVAILABLE_NO_ACCOUNT',
    BillingReceiptCategoryAvailable = 'BILLING_RECEIPT_CATEGORY_AVAILABLE',
    BillingReceiptFileAdded = 'BILLING_RECEIPT_FILE_ADDED',
    CustomerImportantNoteType = 'CUSTOMER_IMPORTANT_NOTE_TYPE',
    CustomContentMessageEmail = 'CUSTOM_CONTENT_MESSAGE_EMAIL',
    CustomContentMessagePush = 'CUSTOM_CONTENT_MESSAGE_PUSH',
    CustomContentMessageSms = 'CUSTOM_CONTENT_MESSAGE_SMS',
    DeveloperImportantNoteType = 'DEVELOPER_IMPORTANT_NOTE_TYPE',
    DevPortalMessage = 'DEV_PORTAL_MESSAGE',
    DirtyInviteNewEmployeeEmail = 'DIRTY_INVITE_NEW_EMPLOYEE_EMAIL',
    DirtyInviteNewEmployeeSms = 'DIRTY_INVITE_NEW_EMPLOYEE_SMS',
    InviteNewEmployee = 'INVITE_NEW_EMPLOYEE',
    MarketplaceInvoiceCashPublishedMessage = 'MARKETPLACE_INVOICE_CASH_PUBLISHED_MESSAGE',
    MarketplaceInvoiceCashWithTicketPublishedMessage = 'MARKETPLACE_INVOICE_CASH_WITH_TICKET_PUBLISHED_MESSAGE',
    MarketplaceInvoicePublishedMessage = 'MARKETPLACE_INVOICE_PUBLISHED_MESSAGE',
    MarketplaceInvoiceWithTicketPublishedMessage = 'MARKETPLACE_INVOICE_WITH_TICKET_PUBLISHED_MESSAGE',
    MessageForwardedToSupport = 'MESSAGE_FORWARDED_TO_SUPPORT',
    MeterSubmitReadingsReminder = 'METER_SUBMIT_READINGS_REMINDER',
    MeterSubmitReadingsReminderEndPeriod = 'METER_SUBMIT_READINGS_REMINDER_END_PERIOD',
    MeterSubmitReadingsReminderStartPeriod = 'METER_SUBMIT_READINGS_REMINDER_START_PERIOD',
    MeterVerificationDateExpired = 'METER_VERIFICATION_DATE_EXPIRED',
    MeterVerificationDateReminder = 'METER_VERIFICATION_DATE_REMINDER',
    MobileAppUpdateAvailableMessagePush = 'MOBILE_APP_UPDATE_AVAILABLE_MESSAGE_PUSH',
    NewsItemCommonMessageType = 'NEWS_ITEM_COMMON_MESSAGE_TYPE',
    NewsItemEmergencyMessageType = 'NEWS_ITEM_EMERGENCY_MESSAGE_TYPE',
    RecurrentPaymentProceedingAcquiringPaymentProceedErrorMessage = 'RECURRENT_PAYMENT_PROCEEDING_ACQUIRING_PAYMENT_PROCEED_ERROR_MESSAGE',
    RecurrentPaymentProceedingCanNotRegisterMultiPaymentErrorMessage = 'RECURRENT_PAYMENT_PROCEEDING_CAN_NOT_REGISTER_MULTI_PAYMENT_ERROR_MESSAGE',
    RecurrentPaymentProceedingCardTokenNotValidErrorMessage = 'RECURRENT_PAYMENT_PROCEEDING_CARD_TOKEN_NOT_VALID_ERROR_MESSAGE',
    RecurrentPaymentProceedingContextDisabledErrorMessage = 'RECURRENT_PAYMENT_PROCEEDING_CONTEXT_DISABLED_ERROR_MESSAGE',
    RecurrentPaymentProceedingContextNotFoundErrorMessage = 'RECURRENT_PAYMENT_PROCEEDING_CONTEXT_NOT_FOUND_ERROR_MESSAGE',
    RecurrentPaymentProceedingLimitExceededErrorMessage = 'RECURRENT_PAYMENT_PROCEEDING_LIMIT_EXCEEDED_ERROR_MESSAGE',
    RecurrentPaymentProceedingNoReceiptsToProceedErrorMessage = 'RECURRENT_PAYMENT_PROCEEDING_NO_RECEIPTS_TO_PROCEED_ERROR_MESSAGE',
    RecurrentPaymentProceedingServiceConsumerNotFoundErrorMessage = 'RECURRENT_PAYMENT_PROCEEDING_SERVICE_CONSUMER_NOT_FOUND_ERROR_MESSAGE',
    RecurrentPaymentProceedingSuccessResultMessage = 'RECURRENT_PAYMENT_PROCEEDING_SUCCESS_RESULT_MESSAGE',
    RecurrentPaymentProceedingUnknownErrorMessage = 'RECURRENT_PAYMENT_PROCEEDING_UNKNOWN_ERROR_MESSAGE',
    RecurrentPaymentTomorrowPaymentLimitExceedMessage = 'RECURRENT_PAYMENT_TOMORROW_PAYMENT_LIMIT_EXCEED_MESSAGE',
    RecurrentPaymentTomorrowPaymentMessage = 'RECURRENT_PAYMENT_TOMORROW_PAYMENT_MESSAGE',
    RecurrentPaymentTomorrowPaymentNoReceiptsMessage = 'RECURRENT_PAYMENT_TOMORROW_PAYMENT_NO_RECEIPTS_MESSAGE',
    RegisterNewUser = 'REGISTER_NEW_USER',
    ResidentAddBillingAccount = 'RESIDENT_ADD_BILLING_ACCOUNT',
    ResidentUpgradeApp = 'RESIDENT_UPGRADE_APP',
    SendBillingReceiptsOnPaydayReminderMessage = 'SEND_BILLING_RECEIPTS_ON_PAYDAY_REMINDER_MESSAGE',
    SendDailyStatistics = 'SEND_DAILY_STATISTICS',
    ServiceUserCreated = 'SERVICE_USER_CREATED',
    ShareTicket = 'SHARE_TICKET',
    SmsVerify = 'SMS_VERIFY',
    StaffUpgradeApp = 'STAFF_UPGRADE_APP',
    TicketAssigneeConnected = 'TICKET_ASSIGNEE_CONNECTED',
    TicketCommentAdded = 'TICKET_COMMENT_ADDED',
    TicketCommentCreated = 'TICKET_COMMENT_CREATED',
    TicketCreated = 'TICKET_CREATED',
    TicketExecutorConnected = 'TICKET_EXECUTOR_CONNECTED',
    TicketStatusCompleted = 'TICKET_STATUS_COMPLETED',
    TicketStatusDeclined = 'TICKET_STATUS_DECLINED',
    TicketStatusInProgress = 'TICKET_STATUS_IN_PROGRESS',
    TicketStatusOpened = 'TICKET_STATUS_OPENED',
    TicketStatusReturned = 'TICKET_STATUS_RETURNED',
    TrackTicketInDomaApp = 'TRACK_TICKET_IN_DOMA_APP',
    VoipIncomingCallMessage = 'VOIP_INCOMING_CALL_MESSAGE',
}

export type MessageUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deliveredAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    email?: InputMaybe<Scalars['String']['input']>
    emailFrom?: InputMaybe<Scalars['String']['input']>
    lang?: InputMaybe<MessageLangType>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    phone?: InputMaybe<Scalars['String']['input']>
    processingMeta?: InputMaybe<Scalars['JSON']['input']>
    readAt?: InputMaybe<Scalars['String']['input']>
    remoteClient?: InputMaybe<RemoteClientRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    sentAt?: InputMaybe<Scalars['String']['input']>
    status?: InputMaybe<MessageStatusType>
    type?: InputMaybe<MessageType>
    uniqKey?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  Rule for blocking messages (specific type or all) for user, phone or email  */
export type MessageUserBlackList = {
    __typename?: 'MessageUserBlackList'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MessageUserBlackList List config, or
   *  2. As an alias to the field set on 'labelField' in the MessageUserBlackList List config, or
   *  3. As an alias to a 'name' field on the MessageUserBlackList List (if one exists), or
   *  4. As an alias to the 'id' field on the MessageUserBlackList List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  The reason why the entry was added to the MessageUserBlackList  */
    description?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  Email to which we want to block the sending of messages  */
    email?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['String']['output']>
    /**  The phone number to which we want to block sending messages  */
    phone?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  The type of message we want to block (null means all types)  */
    type?: Maybe<MessageType>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    /**  The user to whom we want to block sending messages  */
    user?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type MessageUserBlackListCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    description?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    email?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    phone?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    type?: InputMaybe<MessageType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type MessageUserBlackListHistoryRecord = {
    __typename?: 'MessageUserBlackListHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MessageUserBlackListHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the MessageUserBlackListHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the MessageUserBlackListHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the MessageUserBlackListHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    description?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    email?: Maybe<Scalars['String']['output']>
    history_action?: Maybe<MessageUserBlackListHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['JSON']['output']>
    phone?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    type?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    user?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type MessageUserBlackListHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    description?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    email?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<MessageUserBlackListHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    phone?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    type?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum MessageUserBlackListHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type MessageUserBlackListHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    description?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    email?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<MessageUserBlackListHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    phone?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    type?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type MessageUserBlackListHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<MessageUserBlackListHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<MessageUserBlackListHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    description?: InputMaybe<Scalars['String']['input']>
    description_contains?: InputMaybe<Scalars['String']['input']>
    description_contains_i?: InputMaybe<Scalars['String']['input']>
    description_ends_with?: InputMaybe<Scalars['String']['input']>
    description_ends_with_i?: InputMaybe<Scalars['String']['input']>
    description_i?: InputMaybe<Scalars['String']['input']>
    description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    description_not?: InputMaybe<Scalars['String']['input']>
    description_not_contains?: InputMaybe<Scalars['String']['input']>
    description_not_contains_i?: InputMaybe<Scalars['String']['input']>
    description_not_ends_with?: InputMaybe<Scalars['String']['input']>
    description_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    description_not_i?: InputMaybe<Scalars['String']['input']>
    description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    description_not_starts_with?: InputMaybe<Scalars['String']['input']>
    description_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    description_starts_with?: InputMaybe<Scalars['String']['input']>
    description_starts_with_i?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    email?: InputMaybe<Scalars['String']['input']>
    email_contains?: InputMaybe<Scalars['String']['input']>
    email_contains_i?: InputMaybe<Scalars['String']['input']>
    email_ends_with?: InputMaybe<Scalars['String']['input']>
    email_ends_with_i?: InputMaybe<Scalars['String']['input']>
    email_i?: InputMaybe<Scalars['String']['input']>
    email_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    email_not?: InputMaybe<Scalars['String']['input']>
    email_not_contains?: InputMaybe<Scalars['String']['input']>
    email_not_contains_i?: InputMaybe<Scalars['String']['input']>
    email_not_ends_with?: InputMaybe<Scalars['String']['input']>
    email_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    email_not_i?: InputMaybe<Scalars['String']['input']>
    email_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    email_not_starts_with?: InputMaybe<Scalars['String']['input']>
    email_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    email_starts_with?: InputMaybe<Scalars['String']['input']>
    email_starts_with_i?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<MessageUserBlackListHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<MessageUserBlackListHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<MessageUserBlackListHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<MessageUserBlackListHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    phone?: InputMaybe<Scalars['String']['input']>
    phone_contains?: InputMaybe<Scalars['String']['input']>
    phone_contains_i?: InputMaybe<Scalars['String']['input']>
    phone_ends_with?: InputMaybe<Scalars['String']['input']>
    phone_ends_with_i?: InputMaybe<Scalars['String']['input']>
    phone_i?: InputMaybe<Scalars['String']['input']>
    phone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phone_not?: InputMaybe<Scalars['String']['input']>
    phone_not_contains?: InputMaybe<Scalars['String']['input']>
    phone_not_contains_i?: InputMaybe<Scalars['String']['input']>
    phone_not_ends_with?: InputMaybe<Scalars['String']['input']>
    phone_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    phone_not_i?: InputMaybe<Scalars['String']['input']>
    phone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phone_not_starts_with?: InputMaybe<Scalars['String']['input']>
    phone_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    phone_starts_with?: InputMaybe<Scalars['String']['input']>
    phone_starts_with_i?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    type?: InputMaybe<Scalars['String']['input']>
    type_contains?: InputMaybe<Scalars['String']['input']>
    type_contains_i?: InputMaybe<Scalars['String']['input']>
    type_ends_with?: InputMaybe<Scalars['String']['input']>
    type_ends_with_i?: InputMaybe<Scalars['String']['input']>
    type_i?: InputMaybe<Scalars['String']['input']>
    type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    type_not?: InputMaybe<Scalars['String']['input']>
    type_not_contains?: InputMaybe<Scalars['String']['input']>
    type_not_contains_i?: InputMaybe<Scalars['String']['input']>
    type_not_ends_with?: InputMaybe<Scalars['String']['input']>
    type_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    type_not_i?: InputMaybe<Scalars['String']['input']>
    type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    type_not_starts_with?: InputMaybe<Scalars['String']['input']>
    type_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    type_starts_with?: InputMaybe<Scalars['String']['input']>
    type_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user?: InputMaybe<Scalars['String']['input']>
    user_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user_not?: InputMaybe<Scalars['String']['input']>
    user_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type MessageUserBlackListHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type MessageUserBlackListHistoryRecordsCreateInput = {
    data?: InputMaybe<MessageUserBlackListHistoryRecordCreateInput>
}

export type MessageUserBlackListHistoryRecordsUpdateInput = {
    data?: InputMaybe<MessageUserBlackListHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type MessageUserBlackListUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    description?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    email?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    phone?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    type?: InputMaybe<MessageType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type MessageUserBlackListWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<MessageUserBlackListWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<MessageUserBlackListWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    description?: InputMaybe<Scalars['String']['input']>
    description_contains?: InputMaybe<Scalars['String']['input']>
    description_contains_i?: InputMaybe<Scalars['String']['input']>
    description_ends_with?: InputMaybe<Scalars['String']['input']>
    description_ends_with_i?: InputMaybe<Scalars['String']['input']>
    description_i?: InputMaybe<Scalars['String']['input']>
    description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    description_not?: InputMaybe<Scalars['String']['input']>
    description_not_contains?: InputMaybe<Scalars['String']['input']>
    description_not_contains_i?: InputMaybe<Scalars['String']['input']>
    description_not_ends_with?: InputMaybe<Scalars['String']['input']>
    description_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    description_not_i?: InputMaybe<Scalars['String']['input']>
    description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    description_not_starts_with?: InputMaybe<Scalars['String']['input']>
    description_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    description_starts_with?: InputMaybe<Scalars['String']['input']>
    description_starts_with_i?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    email?: InputMaybe<Scalars['String']['input']>
    email_contains?: InputMaybe<Scalars['String']['input']>
    email_contains_i?: InputMaybe<Scalars['String']['input']>
    email_ends_with?: InputMaybe<Scalars['String']['input']>
    email_ends_with_i?: InputMaybe<Scalars['String']['input']>
    email_i?: InputMaybe<Scalars['String']['input']>
    email_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    email_not?: InputMaybe<Scalars['String']['input']>
    email_not_contains?: InputMaybe<Scalars['String']['input']>
    email_not_contains_i?: InputMaybe<Scalars['String']['input']>
    email_not_ends_with?: InputMaybe<Scalars['String']['input']>
    email_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    email_not_i?: InputMaybe<Scalars['String']['input']>
    email_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    email_not_starts_with?: InputMaybe<Scalars['String']['input']>
    email_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    email_starts_with?: InputMaybe<Scalars['String']['input']>
    email_starts_with_i?: InputMaybe<Scalars['String']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phone?: InputMaybe<Scalars['String']['input']>
    phone_contains?: InputMaybe<Scalars['String']['input']>
    phone_contains_i?: InputMaybe<Scalars['String']['input']>
    phone_ends_with?: InputMaybe<Scalars['String']['input']>
    phone_ends_with_i?: InputMaybe<Scalars['String']['input']>
    phone_i?: InputMaybe<Scalars['String']['input']>
    phone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phone_not?: InputMaybe<Scalars['String']['input']>
    phone_not_contains?: InputMaybe<Scalars['String']['input']>
    phone_not_contains_i?: InputMaybe<Scalars['String']['input']>
    phone_not_ends_with?: InputMaybe<Scalars['String']['input']>
    phone_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    phone_not_i?: InputMaybe<Scalars['String']['input']>
    phone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phone_not_starts_with?: InputMaybe<Scalars['String']['input']>
    phone_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    phone_starts_with?: InputMaybe<Scalars['String']['input']>
    phone_starts_with_i?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    type?: InputMaybe<MessageType>
    type_in?: InputMaybe<Array<InputMaybe<MessageType>>>
    type_not?: InputMaybe<MessageType>
    type_not_in?: InputMaybe<Array<InputMaybe<MessageType>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    user?: InputMaybe<UserWhereInput>
    user_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type MessageUserBlackListWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type MessageUserBlackListsCreateInput = {
    data?: InputMaybe<MessageUserBlackListCreateInput>
}

export type MessageUserBlackListsUpdateInput = {
    data?: InputMaybe<MessageUserBlackListUpdateInput>
    id: Scalars['ID']['input']
}

export type MessageWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<MessageWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<MessageWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deliveredAt?: InputMaybe<Scalars['String']['input']>
    deliveredAt_gt?: InputMaybe<Scalars['String']['input']>
    deliveredAt_gte?: InputMaybe<Scalars['String']['input']>
    deliveredAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deliveredAt_lt?: InputMaybe<Scalars['String']['input']>
    deliveredAt_lte?: InputMaybe<Scalars['String']['input']>
    deliveredAt_not?: InputMaybe<Scalars['String']['input']>
    deliveredAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    email?: InputMaybe<Scalars['String']['input']>
    emailFrom?: InputMaybe<Scalars['String']['input']>
    emailFrom_contains?: InputMaybe<Scalars['String']['input']>
    emailFrom_contains_i?: InputMaybe<Scalars['String']['input']>
    emailFrom_ends_with?: InputMaybe<Scalars['String']['input']>
    emailFrom_ends_with_i?: InputMaybe<Scalars['String']['input']>
    emailFrom_i?: InputMaybe<Scalars['String']['input']>
    emailFrom_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    emailFrom_not?: InputMaybe<Scalars['String']['input']>
    emailFrom_not_contains?: InputMaybe<Scalars['String']['input']>
    emailFrom_not_contains_i?: InputMaybe<Scalars['String']['input']>
    emailFrom_not_ends_with?: InputMaybe<Scalars['String']['input']>
    emailFrom_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    emailFrom_not_i?: InputMaybe<Scalars['String']['input']>
    emailFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    emailFrom_not_starts_with?: InputMaybe<Scalars['String']['input']>
    emailFrom_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    emailFrom_starts_with?: InputMaybe<Scalars['String']['input']>
    emailFrom_starts_with_i?: InputMaybe<Scalars['String']['input']>
    email_contains?: InputMaybe<Scalars['String']['input']>
    email_contains_i?: InputMaybe<Scalars['String']['input']>
    email_ends_with?: InputMaybe<Scalars['String']['input']>
    email_ends_with_i?: InputMaybe<Scalars['String']['input']>
    email_i?: InputMaybe<Scalars['String']['input']>
    email_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    email_not?: InputMaybe<Scalars['String']['input']>
    email_not_contains?: InputMaybe<Scalars['String']['input']>
    email_not_contains_i?: InputMaybe<Scalars['String']['input']>
    email_not_ends_with?: InputMaybe<Scalars['String']['input']>
    email_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    email_not_i?: InputMaybe<Scalars['String']['input']>
    email_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    email_not_starts_with?: InputMaybe<Scalars['String']['input']>
    email_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    email_starts_with?: InputMaybe<Scalars['String']['input']>
    email_starts_with_i?: InputMaybe<Scalars['String']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    lang?: InputMaybe<MessageLangType>
    lang_in?: InputMaybe<Array<InputMaybe<MessageLangType>>>
    lang_not?: InputMaybe<MessageLangType>
    lang_not_in?: InputMaybe<Array<InputMaybe<MessageLangType>>>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    phone?: InputMaybe<Scalars['String']['input']>
    phone_contains?: InputMaybe<Scalars['String']['input']>
    phone_contains_i?: InputMaybe<Scalars['String']['input']>
    phone_ends_with?: InputMaybe<Scalars['String']['input']>
    phone_ends_with_i?: InputMaybe<Scalars['String']['input']>
    phone_i?: InputMaybe<Scalars['String']['input']>
    phone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phone_not?: InputMaybe<Scalars['String']['input']>
    phone_not_contains?: InputMaybe<Scalars['String']['input']>
    phone_not_contains_i?: InputMaybe<Scalars['String']['input']>
    phone_not_ends_with?: InputMaybe<Scalars['String']['input']>
    phone_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    phone_not_i?: InputMaybe<Scalars['String']['input']>
    phone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phone_not_starts_with?: InputMaybe<Scalars['String']['input']>
    phone_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    phone_starts_with?: InputMaybe<Scalars['String']['input']>
    phone_starts_with_i?: InputMaybe<Scalars['String']['input']>
    processingMeta?: InputMaybe<Scalars['JSON']['input']>
    processingMeta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    processingMeta_not?: InputMaybe<Scalars['JSON']['input']>
    processingMeta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    readAt?: InputMaybe<Scalars['String']['input']>
    readAt_gt?: InputMaybe<Scalars['String']['input']>
    readAt_gte?: InputMaybe<Scalars['String']['input']>
    readAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    readAt_lt?: InputMaybe<Scalars['String']['input']>
    readAt_lte?: InputMaybe<Scalars['String']['input']>
    readAt_not?: InputMaybe<Scalars['String']['input']>
    readAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    remoteClient?: InputMaybe<RemoteClientWhereInput>
    remoteClient_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sentAt?: InputMaybe<Scalars['String']['input']>
    sentAt_gt?: InputMaybe<Scalars['String']['input']>
    sentAt_gte?: InputMaybe<Scalars['String']['input']>
    sentAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sentAt_lt?: InputMaybe<Scalars['String']['input']>
    sentAt_lte?: InputMaybe<Scalars['String']['input']>
    sentAt_not?: InputMaybe<Scalars['String']['input']>
    sentAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status?: InputMaybe<MessageStatusType>
    status_in?: InputMaybe<Array<InputMaybe<MessageStatusType>>>
    status_not?: InputMaybe<MessageStatusType>
    status_not_in?: InputMaybe<Array<InputMaybe<MessageStatusType>>>
    type?: InputMaybe<MessageType>
    type_in?: InputMaybe<Array<InputMaybe<MessageType>>>
    type_not?: InputMaybe<MessageType>
    type_not_in?: InputMaybe<Array<InputMaybe<MessageType>>>
    uniqKey?: InputMaybe<Scalars['String']['input']>
    uniqKey_contains?: InputMaybe<Scalars['String']['input']>
    uniqKey_contains_i?: InputMaybe<Scalars['String']['input']>
    uniqKey_ends_with?: InputMaybe<Scalars['String']['input']>
    uniqKey_ends_with_i?: InputMaybe<Scalars['String']['input']>
    uniqKey_i?: InputMaybe<Scalars['String']['input']>
    uniqKey_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    uniqKey_not?: InputMaybe<Scalars['String']['input']>
    uniqKey_not_contains?: InputMaybe<Scalars['String']['input']>
    uniqKey_not_contains_i?: InputMaybe<Scalars['String']['input']>
    uniqKey_not_ends_with?: InputMaybe<Scalars['String']['input']>
    uniqKey_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    uniqKey_not_i?: InputMaybe<Scalars['String']['input']>
    uniqKey_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    uniqKey_not_starts_with?: InputMaybe<Scalars['String']['input']>
    uniqKey_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    uniqKey_starts_with?: InputMaybe<Scalars['String']['input']>
    uniqKey_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    user?: InputMaybe<UserWhereInput>
    user_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type MessageWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type MessagesCreateInput = {
    data?: InputMaybe<MessageCreateInput>
}

export type MessagesUpdateInput = {
    data?: InputMaybe<MessageUpdateInput>
    id: Scalars['ID']['input']
}

/**  Resource meter at a certain place in the unitName  */
export type Meter = {
    __typename?: 'Meter'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the Meter List config, or
   *  2. As an alias to the field set on 'labelField' in the Meter List config, or
   *  3. As an alias to a 'name' field on the Meter List (if one exists), or
   *  4. As an alias to the 'id' field on the Meter List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  Client's billing account  */
    accountNumber?: Maybe<Scalars['String']['output']>
    /**  The date when the meter was disabled and no longer in use  */
    archiveDate?: Maybe<Scalars['String']['output']>
    /**  Ref to B2BApp, which is used as a master system for this meter. Specified organization must connect this app.  */
    b2bApp?: Maybe<B2BApp>
    /**  Ref to the B2CApp which used to replace default integration with meter by resident's user in resident's app  */
    b2cApp?: Maybe<B2CApp>
    /**  Date when the meter was commissioned.Commissioning - documentation of the meter as a billing meter  */
    commissioningDate?: Maybe<Scalars['String']['output']>
    /**  The date when the employee came and took readings from the meter  */
    controlReadingsDate?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  Date when the meter was installed  */
    installationDate?: Maybe<Scalars['String']['output']>
    /**  Determines, if Meter is automatic or not. False by default. If set to True - prevents user with type "resident" from creating MeterReading.So MeterReadings only be acquired through external integration or adjusted by organization employee  */
    isAutomatic?: Maybe<Scalars['Boolean']['output']>
    /**  Meter metadata. Can be used to store additional settings from external sources, such as billing integrations or mini apps  */
    meta?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  The date of the next meter verification.For example, for a cold water meter - usually 6 years after the verification date  */
    nextVerificationDate?: Maybe<Scalars['String']['output']>
    /**  Number of resource meter, such as "А03 9908"  */
    number?: Maybe<Scalars['String']['output']>
    numberOfTariffs?: Maybe<Scalars['Int']['output']>
    /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
    organization?: Maybe<Organization>
    /**  Certain place in unit where meter is, such as kitchen  */
    place?: Maybe<Scalars['String']['output']>
    /**  Link to property which contains unit with this meter  */
    property?: Maybe<Property>
    /**  Meter resource, such as hot water or electricity  */
    resource?: Maybe<MeterResource>
    /**  The date when meter was sealed.Sealing is the installation of a unique single-use device (directly a seal and a sealing rope)on the metering device, which is designed to control unauthorized access to the equipment.  */
    sealingDate?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Unit with this meter  */
    unitName?: Maybe<Scalars['String']['output']>
    /**  Type of unit, such as parking lot or flat. Default value: "flat"  */
    unitType?: Maybe<MeterUnitTypeType>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
    /**  The date when the employee came and checked how accurately the meter counts the resource  */
    verificationDate?: Maybe<Scalars['String']['output']>
}

export type MeterCreateInput = {
    accountNumber?: InputMaybe<Scalars['String']['input']>
    archiveDate?: InputMaybe<Scalars['String']['input']>
    b2bApp?: InputMaybe<B2BAppRelateToOneInput>
    b2cApp?: InputMaybe<B2CAppRelateToOneInput>
    commissioningDate?: InputMaybe<Scalars['String']['input']>
    controlReadingsDate?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    installationDate?: InputMaybe<Scalars['String']['input']>
    isAutomatic?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    nextVerificationDate?: InputMaybe<Scalars['String']['input']>
    number?: InputMaybe<Scalars['String']['input']>
    numberOfTariffs?: InputMaybe<Scalars['Int']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    place?: InputMaybe<Scalars['String']['input']>
    property?: InputMaybe<PropertyRelateToOneInput>
    resource?: InputMaybe<MeterResourceRelateToOneInput>
    sealingDate?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    unitName?: InputMaybe<Scalars['String']['input']>
    unitType?: InputMaybe<MeterUnitTypeType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
    verificationDate?: InputMaybe<Scalars['String']['input']>
}

/**  A keystone list  */
export type MeterHistoryRecord = {
    __typename?: 'MeterHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MeterHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the MeterHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the MeterHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the MeterHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    accountNumber?: Maybe<Scalars['String']['output']>
    archiveDate?: Maybe<Scalars['String']['output']>
    b2bApp?: Maybe<Scalars['String']['output']>
    b2cApp?: Maybe<Scalars['String']['output']>
    commissioningDate?: Maybe<Scalars['String']['output']>
    controlReadingsDate?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<MeterHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    installationDate?: Maybe<Scalars['String']['output']>
    isAutomatic?: Maybe<Scalars['Boolean']['output']>
    meta?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    nextVerificationDate?: Maybe<Scalars['String']['output']>
    number?: Maybe<Scalars['String']['output']>
    numberOfTariffs?: Maybe<Scalars['Int']['output']>
    organization?: Maybe<Scalars['String']['output']>
    place?: Maybe<Scalars['String']['output']>
    property?: Maybe<Scalars['String']['output']>
    resource?: Maybe<Scalars['String']['output']>
    sealingDate?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    unitName?: Maybe<Scalars['String']['output']>
    unitType?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
    verificationDate?: Maybe<Scalars['String']['output']>
}

export type MeterHistoryRecordCreateInput = {
    accountNumber?: InputMaybe<Scalars['String']['input']>
    archiveDate?: InputMaybe<Scalars['String']['input']>
    b2bApp?: InputMaybe<Scalars['String']['input']>
    b2cApp?: InputMaybe<Scalars['String']['input']>
    commissioningDate?: InputMaybe<Scalars['String']['input']>
    controlReadingsDate?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<MeterHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    installationDate?: InputMaybe<Scalars['String']['input']>
    isAutomatic?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    nextVerificationDate?: InputMaybe<Scalars['String']['input']>
    number?: InputMaybe<Scalars['String']['input']>
    numberOfTariffs?: InputMaybe<Scalars['Int']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    place?: InputMaybe<Scalars['String']['input']>
    property?: InputMaybe<Scalars['String']['input']>
    resource?: InputMaybe<Scalars['String']['input']>
    sealingDate?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    unitName?: InputMaybe<Scalars['String']['input']>
    unitType?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    verificationDate?: InputMaybe<Scalars['String']['input']>
}

export enum MeterHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type MeterHistoryRecordUpdateInput = {
    accountNumber?: InputMaybe<Scalars['String']['input']>
    archiveDate?: InputMaybe<Scalars['String']['input']>
    b2bApp?: InputMaybe<Scalars['String']['input']>
    b2cApp?: InputMaybe<Scalars['String']['input']>
    commissioningDate?: InputMaybe<Scalars['String']['input']>
    controlReadingsDate?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<MeterHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    installationDate?: InputMaybe<Scalars['String']['input']>
    isAutomatic?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    nextVerificationDate?: InputMaybe<Scalars['String']['input']>
    number?: InputMaybe<Scalars['String']['input']>
    numberOfTariffs?: InputMaybe<Scalars['Int']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    place?: InputMaybe<Scalars['String']['input']>
    property?: InputMaybe<Scalars['String']['input']>
    resource?: InputMaybe<Scalars['String']['input']>
    sealingDate?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    unitName?: InputMaybe<Scalars['String']['input']>
    unitType?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    verificationDate?: InputMaybe<Scalars['String']['input']>
}

export type MeterHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<MeterHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<MeterHistoryRecordWhereInput>>>
    accountNumber?: InputMaybe<Scalars['String']['input']>
    accountNumber_contains?: InputMaybe<Scalars['String']['input']>
    accountNumber_contains_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_ends_with?: InputMaybe<Scalars['String']['input']>
    accountNumber_ends_with_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    accountNumber_not?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_contains?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_contains_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_ends_with?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    accountNumber_not_starts_with?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_starts_with?: InputMaybe<Scalars['String']['input']>
    accountNumber_starts_with_i?: InputMaybe<Scalars['String']['input']>
    archiveDate?: InputMaybe<Scalars['String']['input']>
    archiveDate_gt?: InputMaybe<Scalars['String']['input']>
    archiveDate_gte?: InputMaybe<Scalars['String']['input']>
    archiveDate_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    archiveDate_lt?: InputMaybe<Scalars['String']['input']>
    archiveDate_lte?: InputMaybe<Scalars['String']['input']>
    archiveDate_not?: InputMaybe<Scalars['String']['input']>
    archiveDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    b2bApp?: InputMaybe<Scalars['String']['input']>
    b2bApp_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    b2bApp_not?: InputMaybe<Scalars['String']['input']>
    b2bApp_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    b2cApp?: InputMaybe<Scalars['String']['input']>
    b2cApp_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    b2cApp_not?: InputMaybe<Scalars['String']['input']>
    b2cApp_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    commissioningDate?: InputMaybe<Scalars['String']['input']>
    commissioningDate_gt?: InputMaybe<Scalars['String']['input']>
    commissioningDate_gte?: InputMaybe<Scalars['String']['input']>
    commissioningDate_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    commissioningDate_lt?: InputMaybe<Scalars['String']['input']>
    commissioningDate_lte?: InputMaybe<Scalars['String']['input']>
    commissioningDate_not?: InputMaybe<Scalars['String']['input']>
    commissioningDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    controlReadingsDate?: InputMaybe<Scalars['String']['input']>
    controlReadingsDate_gt?: InputMaybe<Scalars['String']['input']>
    controlReadingsDate_gte?: InputMaybe<Scalars['String']['input']>
    controlReadingsDate_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    controlReadingsDate_lt?: InputMaybe<Scalars['String']['input']>
    controlReadingsDate_lte?: InputMaybe<Scalars['String']['input']>
    controlReadingsDate_not?: InputMaybe<Scalars['String']['input']>
    controlReadingsDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<MeterHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<MeterHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<MeterHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<MeterHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    installationDate?: InputMaybe<Scalars['String']['input']>
    installationDate_gt?: InputMaybe<Scalars['String']['input']>
    installationDate_gte?: InputMaybe<Scalars['String']['input']>
    installationDate_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    installationDate_lt?: InputMaybe<Scalars['String']['input']>
    installationDate_lte?: InputMaybe<Scalars['String']['input']>
    installationDate_not?: InputMaybe<Scalars['String']['input']>
    installationDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    isAutomatic?: InputMaybe<Scalars['Boolean']['input']>
    isAutomatic_not?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    nextVerificationDate?: InputMaybe<Scalars['String']['input']>
    nextVerificationDate_gt?: InputMaybe<Scalars['String']['input']>
    nextVerificationDate_gte?: InputMaybe<Scalars['String']['input']>
    nextVerificationDate_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    nextVerificationDate_lt?: InputMaybe<Scalars['String']['input']>
    nextVerificationDate_lte?: InputMaybe<Scalars['String']['input']>
    nextVerificationDate_not?: InputMaybe<Scalars['String']['input']>
    nextVerificationDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    number?: InputMaybe<Scalars['String']['input']>
    numberOfTariffs?: InputMaybe<Scalars['Int']['input']>
    numberOfTariffs_gt?: InputMaybe<Scalars['Int']['input']>
    numberOfTariffs_gte?: InputMaybe<Scalars['Int']['input']>
    numberOfTariffs_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    numberOfTariffs_lt?: InputMaybe<Scalars['Int']['input']>
    numberOfTariffs_lte?: InputMaybe<Scalars['Int']['input']>
    numberOfTariffs_not?: InputMaybe<Scalars['Int']['input']>
    numberOfTariffs_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    number_contains?: InputMaybe<Scalars['String']['input']>
    number_contains_i?: InputMaybe<Scalars['String']['input']>
    number_ends_with?: InputMaybe<Scalars['String']['input']>
    number_ends_with_i?: InputMaybe<Scalars['String']['input']>
    number_i?: InputMaybe<Scalars['String']['input']>
    number_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    number_not?: InputMaybe<Scalars['String']['input']>
    number_not_contains?: InputMaybe<Scalars['String']['input']>
    number_not_contains_i?: InputMaybe<Scalars['String']['input']>
    number_not_ends_with?: InputMaybe<Scalars['String']['input']>
    number_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    number_not_i?: InputMaybe<Scalars['String']['input']>
    number_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    number_not_starts_with?: InputMaybe<Scalars['String']['input']>
    number_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    number_starts_with?: InputMaybe<Scalars['String']['input']>
    number_starts_with_i?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    place?: InputMaybe<Scalars['String']['input']>
    place_contains?: InputMaybe<Scalars['String']['input']>
    place_contains_i?: InputMaybe<Scalars['String']['input']>
    place_ends_with?: InputMaybe<Scalars['String']['input']>
    place_ends_with_i?: InputMaybe<Scalars['String']['input']>
    place_i?: InputMaybe<Scalars['String']['input']>
    place_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    place_not?: InputMaybe<Scalars['String']['input']>
    place_not_contains?: InputMaybe<Scalars['String']['input']>
    place_not_contains_i?: InputMaybe<Scalars['String']['input']>
    place_not_ends_with?: InputMaybe<Scalars['String']['input']>
    place_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    place_not_i?: InputMaybe<Scalars['String']['input']>
    place_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    place_not_starts_with?: InputMaybe<Scalars['String']['input']>
    place_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    place_starts_with?: InputMaybe<Scalars['String']['input']>
    place_starts_with_i?: InputMaybe<Scalars['String']['input']>
    property?: InputMaybe<Scalars['String']['input']>
    property_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    property_not?: InputMaybe<Scalars['String']['input']>
    property_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    resource?: InputMaybe<Scalars['String']['input']>
    resource_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    resource_not?: InputMaybe<Scalars['String']['input']>
    resource_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sealingDate?: InputMaybe<Scalars['String']['input']>
    sealingDate_gt?: InputMaybe<Scalars['String']['input']>
    sealingDate_gte?: InputMaybe<Scalars['String']['input']>
    sealingDate_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sealingDate_lt?: InputMaybe<Scalars['String']['input']>
    sealingDate_lte?: InputMaybe<Scalars['String']['input']>
    sealingDate_not?: InputMaybe<Scalars['String']['input']>
    sealingDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    unitName?: InputMaybe<Scalars['String']['input']>
    unitName_contains?: InputMaybe<Scalars['String']['input']>
    unitName_contains_i?: InputMaybe<Scalars['String']['input']>
    unitName_ends_with?: InputMaybe<Scalars['String']['input']>
    unitName_ends_with_i?: InputMaybe<Scalars['String']['input']>
    unitName_i?: InputMaybe<Scalars['String']['input']>
    unitName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitName_not?: InputMaybe<Scalars['String']['input']>
    unitName_not_contains?: InputMaybe<Scalars['String']['input']>
    unitName_not_contains_i?: InputMaybe<Scalars['String']['input']>
    unitName_not_ends_with?: InputMaybe<Scalars['String']['input']>
    unitName_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    unitName_not_i?: InputMaybe<Scalars['String']['input']>
    unitName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitName_not_starts_with?: InputMaybe<Scalars['String']['input']>
    unitName_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    unitName_starts_with?: InputMaybe<Scalars['String']['input']>
    unitName_starts_with_i?: InputMaybe<Scalars['String']['input']>
    unitType?: InputMaybe<Scalars['String']['input']>
    unitType_contains?: InputMaybe<Scalars['String']['input']>
    unitType_contains_i?: InputMaybe<Scalars['String']['input']>
    unitType_ends_with?: InputMaybe<Scalars['String']['input']>
    unitType_ends_with_i?: InputMaybe<Scalars['String']['input']>
    unitType_i?: InputMaybe<Scalars['String']['input']>
    unitType_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitType_not?: InputMaybe<Scalars['String']['input']>
    unitType_not_contains?: InputMaybe<Scalars['String']['input']>
    unitType_not_contains_i?: InputMaybe<Scalars['String']['input']>
    unitType_not_ends_with?: InputMaybe<Scalars['String']['input']>
    unitType_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    unitType_not_i?: InputMaybe<Scalars['String']['input']>
    unitType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitType_not_starts_with?: InputMaybe<Scalars['String']['input']>
    unitType_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    unitType_starts_with?: InputMaybe<Scalars['String']['input']>
    unitType_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    verificationDate?: InputMaybe<Scalars['String']['input']>
    verificationDate_gt?: InputMaybe<Scalars['String']['input']>
    verificationDate_gte?: InputMaybe<Scalars['String']['input']>
    verificationDate_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    verificationDate_lt?: InputMaybe<Scalars['String']['input']>
    verificationDate_lte?: InputMaybe<Scalars['String']['input']>
    verificationDate_not?: InputMaybe<Scalars['String']['input']>
    verificationDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type MeterHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type MeterHistoryRecordsCreateInput = {
    data?: InputMaybe<MeterHistoryRecordCreateInput>
}

export type MeterHistoryRecordsUpdateInput = {
    data?: InputMaybe<MeterHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

/**  Meter reading taken from a client or billing  */
export type MeterReading = {
    __typename?: 'MeterReading'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MeterReading List config, or
   *  2. As an alias to the field set on 'labelField' in the MeterReading List config, or
   *  3. As an alias to a 'name' field on the MeterReading List (if one exists), or
   *  4. As an alias to the 'id' field on the MeterReading List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  Account number of the related Meter at the moment of creation. Filled once.  */
    accountNumber?: Maybe<Scalars['String']['output']>
    /**  A status from external billing system. Changing during processing the reading in external system. This field can be changed only by service user.  */
    billingStatus?: Maybe<MeterReadingBillingStatusType>
    /**  A message from external billing system. Set to null if billing status is `approved`. This field can be changed only by service user.  */
    billingStatusText?: Maybe<Scalars['String']['output']>
    /**  Inhabitant/customer/person who has a problem or want to improve/order something. Not null if we have a registered client.  */
    client?: Maybe<User>
    /**  Inhabitant/customer/person who has a problem. Sometimes we get a problem from an unregistered client, in such cases we have a null inside the `client` and just have something here. Or sometimes clients want to change it  */
    clientEmail?: Maybe<Scalars['String']['output']>
    /**  Inhabitant/customer/person who has a problem. Sometimes we get a problem from an unregistered client, in such cases we have a null inside the `client` and just have something here. Or sometimes clients want to change it  */
    clientName?: Maybe<Scalars['String']['output']>
    /**  Inhabitant/customer/person who has a problem. Sometimes we get a problem from an unregistered client, in such cases we have a null inside the `client` and just have something here. Or sometimes clients want to change it  */
    clientPhone?: Maybe<Scalars['String']['output']>
    /**  Contact, that reported issue, described in this ticket  */
    contact?: Maybe<Contact>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    /**  Date when the readings were taken  */
    date?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  Meter from which readings were taken  */
    meter?: Maybe<Meter>
    newId?: Maybe<Scalars['String']['output']>
    /**  Ref to the organization. It is filled in on the server and is read-only  */
    organization?: Maybe<Organization>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Meter reading source channel/system. Examples: call, mobile_app, billing, ...  */
    source?: Maybe<MeterReadingSource>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
    /**  If the meter is single-tariff, then only this value will be filled in;If multi-tariff, then the value of the first tariff will be in this field  */
    value1?: Maybe<Scalars['String']['output']>
    /**  If the meter is multi-tariff, then the value of the second tariff is stored here  */
    value2?: Maybe<Scalars['String']['output']>
    /**  If the meter is multi-tariff, then the value of the third tariff is stored here  */
    value3?: Maybe<Scalars['String']['output']>
    /**  If the meter is multi-tariff, then the value of the fourth tariff is stored here  */
    value4?: Maybe<Scalars['String']['output']>
}

export enum MeterReadingBillingStatusType {
    Approved = 'approved',
    Declined = 'declined',
}

export type MeterReadingCreateInput = {
    accountNumber?: InputMaybe<Scalars['String']['input']>
    billingStatus?: InputMaybe<MeterReadingBillingStatusType>
    billingStatusText?: InputMaybe<Scalars['String']['input']>
    client?: InputMaybe<UserRelateToOneInput>
    clientEmail?: InputMaybe<Scalars['String']['input']>
    clientName?: InputMaybe<Scalars['String']['input']>
    clientPhone?: InputMaybe<Scalars['String']['input']>
    contact?: InputMaybe<ContactRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    date?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    meter?: InputMaybe<MeterRelateToOneInput>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    source?: InputMaybe<MeterReadingSourceRelateToOneInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
    value1?: InputMaybe<Scalars['String']['input']>
    value2?: InputMaybe<Scalars['String']['input']>
    value3?: InputMaybe<Scalars['String']['input']>
    value4?: InputMaybe<Scalars['String']['input']>
}

/**  Stores requested export format, status of export job, link to exported file and information about progress of export job  */
export type MeterReadingExportTask = {
    __typename?: 'MeterReadingExportTask'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MeterReadingExportTask List config, or
   *  2. As an alias to the field set on 'labelField' in the MeterReadingExportTask List config, or
   *  3. As an alias to a 'name' field on the MeterReadingExportTask List (if one exists), or
   *  4. As an alias to the 'id' field on the MeterReadingExportTask List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  How many records at the moment are exported  */
    exportedRecordsCount?: Maybe<Scalars['Int']['output']>
    /**  Meta information about file, saved outside of database somewhere. Shape of meta information JSON object is specific to file adapter, used by saving a file.  */
    file?: Maybe<File>
    /**  Requested export file format  */
    format?: Maybe<MeterReadingExportTaskFormatType>
    id: Scalars['ID']['output']
    /**  Requested export locale, in that the resulting file will be rendered  */
    locale?: Maybe<MeterReadingExportTaskLocaleType>
    /**  Stores information about query and ids of exported and failed records  */
    meta?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Sorting parameters for records to export  */
    sortBy?: Maybe<Array<Maybe<SortMeterReadingsBy>>>
    /**  Status of the job. Used at client side progress information component  */
    status?: Maybe<MeterReadingExportTaskStatusType>
    /**  To requested timeZone all datetime fields will be converted  */
    timeZone?: Maybe<Scalars['String']['output']>
    /**  Total records to export that will be determined at server side in export operation  */
    totalRecordsCount?: Maybe<Scalars['Int']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    /**  User that requested this exporting operation. Will be used for read access checks to display all exported tasks somewhere and to display progress indicator of ongoing exporting task for current user  */
    user?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
    /**  Filtering conditions for records to export  */
    where?: Maybe<Scalars['JSON']['output']>
}

export type MeterReadingExportTaskCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['Upload']['input']>
    format?: InputMaybe<MeterReadingExportTaskFormatType>
    locale?: InputMaybe<MeterReadingExportTaskLocaleType>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sortBy?: InputMaybe<Array<InputMaybe<SortMeterReadingsBy>>>
    status?: InputMaybe<MeterReadingExportTaskStatusType>
    timeZone?: InputMaybe<Scalars['String']['input']>
    totalRecordsCount?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
    where?: InputMaybe<MeterReadingWhereInput>
}

export enum MeterReadingExportTaskFormatType {
    Excel = 'excel',
}

/**  A keystone list  */
export type MeterReadingExportTaskHistoryRecord = {
    __typename?: 'MeterReadingExportTaskHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MeterReadingExportTaskHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the MeterReadingExportTaskHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the MeterReadingExportTaskHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the MeterReadingExportTaskHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    exportedRecordsCount?: Maybe<Scalars['Int']['output']>
    file?: Maybe<Scalars['JSON']['output']>
    format?: Maybe<Scalars['String']['output']>
    history_action?: Maybe<MeterReadingExportTaskHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    locale?: Maybe<Scalars['String']['output']>
    meta?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    sortBy?: Maybe<Scalars['JSON']['output']>
    status?: Maybe<Scalars['String']['output']>
    timeZone?: Maybe<Scalars['String']['output']>
    totalRecordsCount?: Maybe<Scalars['Int']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    user?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
    where?: Maybe<Scalars['JSON']['output']>
}

export type MeterReadingExportTaskHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['JSON']['input']>
    format?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<MeterReadingExportTaskHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    locale?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sortBy?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    timeZone?: InputMaybe<Scalars['String']['input']>
    totalRecordsCount?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    where?: InputMaybe<Scalars['JSON']['input']>
}

export enum MeterReadingExportTaskHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type MeterReadingExportTaskHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['JSON']['input']>
    format?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<MeterReadingExportTaskHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    locale?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sortBy?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    timeZone?: InputMaybe<Scalars['String']['input']>
    totalRecordsCount?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    where?: InputMaybe<Scalars['JSON']['input']>
}

export type MeterReadingExportTaskHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<MeterReadingExportTaskHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<MeterReadingExportTaskHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    exportedRecordsCount?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_gt?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_gte?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    exportedRecordsCount_lt?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_lte?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_not?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    file?: InputMaybe<Scalars['JSON']['input']>
    file_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    file_not?: InputMaybe<Scalars['JSON']['input']>
    file_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    format?: InputMaybe<Scalars['String']['input']>
    format_contains?: InputMaybe<Scalars['String']['input']>
    format_contains_i?: InputMaybe<Scalars['String']['input']>
    format_ends_with?: InputMaybe<Scalars['String']['input']>
    format_ends_with_i?: InputMaybe<Scalars['String']['input']>
    format_i?: InputMaybe<Scalars['String']['input']>
    format_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    format_not?: InputMaybe<Scalars['String']['input']>
    format_not_contains?: InputMaybe<Scalars['String']['input']>
    format_not_contains_i?: InputMaybe<Scalars['String']['input']>
    format_not_ends_with?: InputMaybe<Scalars['String']['input']>
    format_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    format_not_i?: InputMaybe<Scalars['String']['input']>
    format_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    format_not_starts_with?: InputMaybe<Scalars['String']['input']>
    format_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    format_starts_with?: InputMaybe<Scalars['String']['input']>
    format_starts_with_i?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<MeterReadingExportTaskHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<MeterReadingExportTaskHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<MeterReadingExportTaskHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<MeterReadingExportTaskHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    locale?: InputMaybe<Scalars['String']['input']>
    locale_contains?: InputMaybe<Scalars['String']['input']>
    locale_contains_i?: InputMaybe<Scalars['String']['input']>
    locale_ends_with?: InputMaybe<Scalars['String']['input']>
    locale_ends_with_i?: InputMaybe<Scalars['String']['input']>
    locale_i?: InputMaybe<Scalars['String']['input']>
    locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    locale_not?: InputMaybe<Scalars['String']['input']>
    locale_not_contains?: InputMaybe<Scalars['String']['input']>
    locale_not_contains_i?: InputMaybe<Scalars['String']['input']>
    locale_not_ends_with?: InputMaybe<Scalars['String']['input']>
    locale_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    locale_not_i?: InputMaybe<Scalars['String']['input']>
    locale_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    locale_not_starts_with?: InputMaybe<Scalars['String']['input']>
    locale_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    locale_starts_with?: InputMaybe<Scalars['String']['input']>
    locale_starts_with_i?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sortBy?: InputMaybe<Scalars['JSON']['input']>
    sortBy_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sortBy_not?: InputMaybe<Scalars['JSON']['input']>
    sortBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    status?: InputMaybe<Scalars['String']['input']>
    status_contains?: InputMaybe<Scalars['String']['input']>
    status_contains_i?: InputMaybe<Scalars['String']['input']>
    status_ends_with?: InputMaybe<Scalars['String']['input']>
    status_ends_with_i?: InputMaybe<Scalars['String']['input']>
    status_i?: InputMaybe<Scalars['String']['input']>
    status_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status_not?: InputMaybe<Scalars['String']['input']>
    status_not_contains?: InputMaybe<Scalars['String']['input']>
    status_not_contains_i?: InputMaybe<Scalars['String']['input']>
    status_not_ends_with?: InputMaybe<Scalars['String']['input']>
    status_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    status_not_i?: InputMaybe<Scalars['String']['input']>
    status_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status_not_starts_with?: InputMaybe<Scalars['String']['input']>
    status_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    status_starts_with?: InputMaybe<Scalars['String']['input']>
    status_starts_with_i?: InputMaybe<Scalars['String']['input']>
    timeZone?: InputMaybe<Scalars['String']['input']>
    timeZone_contains?: InputMaybe<Scalars['String']['input']>
    timeZone_contains_i?: InputMaybe<Scalars['String']['input']>
    timeZone_ends_with?: InputMaybe<Scalars['String']['input']>
    timeZone_ends_with_i?: InputMaybe<Scalars['String']['input']>
    timeZone_i?: InputMaybe<Scalars['String']['input']>
    timeZone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    timeZone_not?: InputMaybe<Scalars['String']['input']>
    timeZone_not_contains?: InputMaybe<Scalars['String']['input']>
    timeZone_not_contains_i?: InputMaybe<Scalars['String']['input']>
    timeZone_not_ends_with?: InputMaybe<Scalars['String']['input']>
    timeZone_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    timeZone_not_i?: InputMaybe<Scalars['String']['input']>
    timeZone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    timeZone_not_starts_with?: InputMaybe<Scalars['String']['input']>
    timeZone_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    timeZone_starts_with?: InputMaybe<Scalars['String']['input']>
    timeZone_starts_with_i?: InputMaybe<Scalars['String']['input']>
    totalRecordsCount?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_gt?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_gte?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    totalRecordsCount_lt?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_lte?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_not?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user?: InputMaybe<Scalars['String']['input']>
    user_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user_not?: InputMaybe<Scalars['String']['input']>
    user_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    where?: InputMaybe<Scalars['JSON']['input']>
    where_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    where_not?: InputMaybe<Scalars['JSON']['input']>
    where_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
}

export type MeterReadingExportTaskHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type MeterReadingExportTaskHistoryRecordsCreateInput = {
    data?: InputMaybe<MeterReadingExportTaskHistoryRecordCreateInput>
}

export type MeterReadingExportTaskHistoryRecordsUpdateInput = {
    data?: InputMaybe<MeterReadingExportTaskHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export enum MeterReadingExportTaskLocaleType {
    En = 'en',
    Ru = 'ru',
}

export enum MeterReadingExportTaskStatusType {
    Cancelled = 'cancelled',
    Completed = 'completed',
    Error = 'error',
    Processing = 'processing',
}

export type MeterReadingExportTaskUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['Upload']['input']>
    format?: InputMaybe<MeterReadingExportTaskFormatType>
    locale?: InputMaybe<MeterReadingExportTaskLocaleType>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sortBy?: InputMaybe<Array<InputMaybe<SortMeterReadingsBy>>>
    status?: InputMaybe<MeterReadingExportTaskStatusType>
    timeZone?: InputMaybe<Scalars['String']['input']>
    totalRecordsCount?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
    where?: InputMaybe<MeterReadingWhereInput>
}

export type MeterReadingExportTaskWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<MeterReadingExportTaskWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<MeterReadingExportTaskWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    exportedRecordsCount?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_gt?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_gte?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    exportedRecordsCount_lt?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_lte?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_not?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    file?: InputMaybe<Scalars['String']['input']>
    file_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    file_not?: InputMaybe<Scalars['String']['input']>
    file_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    format?: InputMaybe<MeterReadingExportTaskFormatType>
    format_in?: InputMaybe<Array<InputMaybe<MeterReadingExportTaskFormatType>>>
    format_not?: InputMaybe<MeterReadingExportTaskFormatType>
    format_not_in?: InputMaybe<Array<InputMaybe<MeterReadingExportTaskFormatType>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    locale?: InputMaybe<MeterReadingExportTaskLocaleType>
    locale_in?: InputMaybe<Array<InputMaybe<MeterReadingExportTaskLocaleType>>>
    locale_not?: InputMaybe<MeterReadingExportTaskLocaleType>
    locale_not_in?: InputMaybe<Array<InputMaybe<MeterReadingExportTaskLocaleType>>>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sortBy?: InputMaybe<Array<InputMaybe<SortMeterReadingsBy>>>
    sortBy_in?: InputMaybe<Array<InputMaybe<Array<InputMaybe<SortMeterReadingsBy>>>>>
    sortBy_not?: InputMaybe<Array<InputMaybe<SortMeterReadingsBy>>>
    sortBy_not_in?: InputMaybe<Array<InputMaybe<Array<InputMaybe<SortMeterReadingsBy>>>>>
    status?: InputMaybe<MeterReadingExportTaskStatusType>
    status_in?: InputMaybe<Array<InputMaybe<MeterReadingExportTaskStatusType>>>
    status_not?: InputMaybe<MeterReadingExportTaskStatusType>
    status_not_in?: InputMaybe<Array<InputMaybe<MeterReadingExportTaskStatusType>>>
    timeZone?: InputMaybe<Scalars['String']['input']>
    timeZone_contains?: InputMaybe<Scalars['String']['input']>
    timeZone_contains_i?: InputMaybe<Scalars['String']['input']>
    timeZone_ends_with?: InputMaybe<Scalars['String']['input']>
    timeZone_ends_with_i?: InputMaybe<Scalars['String']['input']>
    timeZone_i?: InputMaybe<Scalars['String']['input']>
    timeZone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    timeZone_not?: InputMaybe<Scalars['String']['input']>
    timeZone_not_contains?: InputMaybe<Scalars['String']['input']>
    timeZone_not_contains_i?: InputMaybe<Scalars['String']['input']>
    timeZone_not_ends_with?: InputMaybe<Scalars['String']['input']>
    timeZone_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    timeZone_not_i?: InputMaybe<Scalars['String']['input']>
    timeZone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    timeZone_not_starts_with?: InputMaybe<Scalars['String']['input']>
    timeZone_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    timeZone_starts_with?: InputMaybe<Scalars['String']['input']>
    timeZone_starts_with_i?: InputMaybe<Scalars['String']['input']>
    totalRecordsCount?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_gt?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_gte?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    totalRecordsCount_lt?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_lte?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_not?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    user?: InputMaybe<UserWhereInput>
    user_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    where?: InputMaybe<MeterReadingWhereInput>
    where_in?: InputMaybe<Array<InputMaybe<MeterReadingWhereInput>>>
    where_not?: InputMaybe<MeterReadingWhereInput>
    where_not_in?: InputMaybe<Array<InputMaybe<MeterReadingWhereInput>>>
}

export type MeterReadingExportTaskWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type MeterReadingExportTasksCreateInput = {
    data?: InputMaybe<MeterReadingExportTaskCreateInput>
}

export type MeterReadingExportTasksUpdateInput = {
    data?: InputMaybe<MeterReadingExportTaskUpdateInput>
    id: Scalars['ID']['input']
}

/**  Employee specific meter reading filter template  */
export type MeterReadingFilterTemplate = {
    __typename?: 'MeterReadingFilterTemplate'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MeterReadingFilterTemplate List config, or
   *  2. As an alias to the field set on 'labelField' in the MeterReadingFilterTemplate List config, or
   *  3. As an alias to a 'name' field on the MeterReadingFilterTemplate List (if one exists), or
   *  4. As an alias to the 'id' field on the MeterReadingFilterTemplate List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  Link to employee, who created this template  */
    employee?: Maybe<OrganizationEmployee>
    /**  Filter that match the given template  */
    fields?: Maybe<MeterReadingFilters>
    id: Scalars['ID']['output']
    /**  Meter reading filter template name  */
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type MeterReadingFilterTemplateCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    employee?: InputMaybe<OrganizationEmployeeRelateToOneInput>
    fields?: InputMaybe<Scalars['JSON']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type MeterReadingFilterTemplateHistoryRecord = {
    __typename?: 'MeterReadingFilterTemplateHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MeterReadingFilterTemplateHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the MeterReadingFilterTemplateHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the MeterReadingFilterTemplateHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the MeterReadingFilterTemplateHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    employee?: Maybe<Scalars['String']['output']>
    fields?: Maybe<Scalars['JSON']['output']>
    history_action?: Maybe<MeterReadingFilterTemplateHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type MeterReadingFilterTemplateHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    employee?: InputMaybe<Scalars['String']['input']>
    fields?: InputMaybe<Scalars['JSON']['input']>
    history_action?: InputMaybe<MeterReadingFilterTemplateHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum MeterReadingFilterTemplateHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type MeterReadingFilterTemplateHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    employee?: InputMaybe<Scalars['String']['input']>
    fields?: InputMaybe<Scalars['JSON']['input']>
    history_action?: InputMaybe<MeterReadingFilterTemplateHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type MeterReadingFilterTemplateHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<MeterReadingFilterTemplateHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<MeterReadingFilterTemplateHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    employee?: InputMaybe<Scalars['String']['input']>
    employee_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    employee_not?: InputMaybe<Scalars['String']['input']>
    employee_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    fields?: InputMaybe<Scalars['JSON']['input']>
    fields_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    fields_not?: InputMaybe<Scalars['JSON']['input']>
    fields_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    history_action?: InputMaybe<MeterReadingFilterTemplateHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<MeterReadingFilterTemplateHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<MeterReadingFilterTemplateHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<MeterReadingFilterTemplateHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type MeterReadingFilterTemplateHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type MeterReadingFilterTemplateHistoryRecordsCreateInput = {
    data?: InputMaybe<MeterReadingFilterTemplateHistoryRecordCreateInput>
}

export type MeterReadingFilterTemplateHistoryRecordsUpdateInput = {
    data?: InputMaybe<MeterReadingFilterTemplateHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type MeterReadingFilterTemplateUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    employee?: InputMaybe<OrganizationEmployeeRelateToOneInput>
    fields?: InputMaybe<Scalars['JSON']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type MeterReadingFilterTemplateWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<MeterReadingFilterTemplateWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<MeterReadingFilterTemplateWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    employee?: InputMaybe<OrganizationEmployeeWhereInput>
    employee_is_null?: InputMaybe<Scalars['Boolean']['input']>
    fields?: InputMaybe<Scalars['JSON']['input']>
    fields_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    fields_not?: InputMaybe<Scalars['JSON']['input']>
    fields_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type MeterReadingFilterTemplateWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type MeterReadingFilterTemplatesCreateInput = {
    data?: InputMaybe<MeterReadingFilterTemplateCreateInput>
}

export type MeterReadingFilterTemplatesUpdateInput = {
    data?: InputMaybe<MeterReadingFilterTemplateUpdateInput>
    id: Scalars['ID']['input']
}

export type MeterReadingFilters = {
    __typename?: 'MeterReadingFilters'
    accountNumber?: Maybe<Scalars['String']['output']>
    address?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    clientName?: Maybe<Scalars['String']['output']>
    commissioningDate?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    controlReadingsDate?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    createdAt?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    date?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    installationDate?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    number?: Maybe<Scalars['String']['output']>
    organization?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    place?: Maybe<Scalars['String']['output']>
    resource?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    sealingDate?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    unitName?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    verificationDate?: Maybe<Array<Maybe<Scalars['String']['output']>>>
}

/**  A keystone list  */
export type MeterReadingHistoryRecord = {
    __typename?: 'MeterReadingHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MeterReadingHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the MeterReadingHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the MeterReadingHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the MeterReadingHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    accountNumber?: Maybe<Scalars['String']['output']>
    billingStatus?: Maybe<Scalars['String']['output']>
    billingStatusText?: Maybe<Scalars['String']['output']>
    client?: Maybe<Scalars['String']['output']>
    clientEmail?: Maybe<Scalars['String']['output']>
    clientName?: Maybe<Scalars['String']['output']>
    clientPhone?: Maybe<Scalars['String']['output']>
    contact?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    date?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<MeterReadingHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    meter?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    source?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
    value1?: Maybe<Scalars['String']['output']>
    value2?: Maybe<Scalars['String']['output']>
    value3?: Maybe<Scalars['String']['output']>
    value4?: Maybe<Scalars['String']['output']>
}

export type MeterReadingHistoryRecordCreateInput = {
    accountNumber?: InputMaybe<Scalars['String']['input']>
    billingStatus?: InputMaybe<Scalars['String']['input']>
    billingStatusText?: InputMaybe<Scalars['String']['input']>
    client?: InputMaybe<Scalars['String']['input']>
    clientEmail?: InputMaybe<Scalars['String']['input']>
    clientName?: InputMaybe<Scalars['String']['input']>
    clientPhone?: InputMaybe<Scalars['String']['input']>
    contact?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    date?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<MeterReadingHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    meter?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    source?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    value1?: InputMaybe<Scalars['String']['input']>
    value2?: InputMaybe<Scalars['String']['input']>
    value3?: InputMaybe<Scalars['String']['input']>
    value4?: InputMaybe<Scalars['String']['input']>
}

export enum MeterReadingHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type MeterReadingHistoryRecordUpdateInput = {
    accountNumber?: InputMaybe<Scalars['String']['input']>
    billingStatus?: InputMaybe<Scalars['String']['input']>
    billingStatusText?: InputMaybe<Scalars['String']['input']>
    client?: InputMaybe<Scalars['String']['input']>
    clientEmail?: InputMaybe<Scalars['String']['input']>
    clientName?: InputMaybe<Scalars['String']['input']>
    clientPhone?: InputMaybe<Scalars['String']['input']>
    contact?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    date?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<MeterReadingHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    meter?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    source?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    value1?: InputMaybe<Scalars['String']['input']>
    value2?: InputMaybe<Scalars['String']['input']>
    value3?: InputMaybe<Scalars['String']['input']>
    value4?: InputMaybe<Scalars['String']['input']>
}

export type MeterReadingHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<MeterReadingHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<MeterReadingHistoryRecordWhereInput>>>
    accountNumber?: InputMaybe<Scalars['String']['input']>
    accountNumber_contains?: InputMaybe<Scalars['String']['input']>
    accountNumber_contains_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_ends_with?: InputMaybe<Scalars['String']['input']>
    accountNumber_ends_with_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    accountNumber_not?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_contains?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_contains_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_ends_with?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    accountNumber_not_starts_with?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_starts_with?: InputMaybe<Scalars['String']['input']>
    accountNumber_starts_with_i?: InputMaybe<Scalars['String']['input']>
    billingStatus?: InputMaybe<Scalars['String']['input']>
    billingStatusText?: InputMaybe<Scalars['String']['input']>
    billingStatusText_contains?: InputMaybe<Scalars['String']['input']>
    billingStatusText_contains_i?: InputMaybe<Scalars['String']['input']>
    billingStatusText_ends_with?: InputMaybe<Scalars['String']['input']>
    billingStatusText_ends_with_i?: InputMaybe<Scalars['String']['input']>
    billingStatusText_i?: InputMaybe<Scalars['String']['input']>
    billingStatusText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    billingStatusText_not?: InputMaybe<Scalars['String']['input']>
    billingStatusText_not_contains?: InputMaybe<Scalars['String']['input']>
    billingStatusText_not_contains_i?: InputMaybe<Scalars['String']['input']>
    billingStatusText_not_ends_with?: InputMaybe<Scalars['String']['input']>
    billingStatusText_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    billingStatusText_not_i?: InputMaybe<Scalars['String']['input']>
    billingStatusText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    billingStatusText_not_starts_with?: InputMaybe<Scalars['String']['input']>
    billingStatusText_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    billingStatusText_starts_with?: InputMaybe<Scalars['String']['input']>
    billingStatusText_starts_with_i?: InputMaybe<Scalars['String']['input']>
    billingStatus_contains?: InputMaybe<Scalars['String']['input']>
    billingStatus_contains_i?: InputMaybe<Scalars['String']['input']>
    billingStatus_ends_with?: InputMaybe<Scalars['String']['input']>
    billingStatus_ends_with_i?: InputMaybe<Scalars['String']['input']>
    billingStatus_i?: InputMaybe<Scalars['String']['input']>
    billingStatus_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    billingStatus_not?: InputMaybe<Scalars['String']['input']>
    billingStatus_not_contains?: InputMaybe<Scalars['String']['input']>
    billingStatus_not_contains_i?: InputMaybe<Scalars['String']['input']>
    billingStatus_not_ends_with?: InputMaybe<Scalars['String']['input']>
    billingStatus_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    billingStatus_not_i?: InputMaybe<Scalars['String']['input']>
    billingStatus_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    billingStatus_not_starts_with?: InputMaybe<Scalars['String']['input']>
    billingStatus_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    billingStatus_starts_with?: InputMaybe<Scalars['String']['input']>
    billingStatus_starts_with_i?: InputMaybe<Scalars['String']['input']>
    client?: InputMaybe<Scalars['String']['input']>
    clientEmail?: InputMaybe<Scalars['String']['input']>
    clientEmail_contains?: InputMaybe<Scalars['String']['input']>
    clientEmail_contains_i?: InputMaybe<Scalars['String']['input']>
    clientEmail_ends_with?: InputMaybe<Scalars['String']['input']>
    clientEmail_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientEmail_i?: InputMaybe<Scalars['String']['input']>
    clientEmail_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientEmail_not?: InputMaybe<Scalars['String']['input']>
    clientEmail_not_contains?: InputMaybe<Scalars['String']['input']>
    clientEmail_not_contains_i?: InputMaybe<Scalars['String']['input']>
    clientEmail_not_ends_with?: InputMaybe<Scalars['String']['input']>
    clientEmail_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientEmail_not_i?: InputMaybe<Scalars['String']['input']>
    clientEmail_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientEmail_not_starts_with?: InputMaybe<Scalars['String']['input']>
    clientEmail_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    clientEmail_starts_with?: InputMaybe<Scalars['String']['input']>
    clientEmail_starts_with_i?: InputMaybe<Scalars['String']['input']>
    clientName?: InputMaybe<Scalars['String']['input']>
    clientName_contains?: InputMaybe<Scalars['String']['input']>
    clientName_contains_i?: InputMaybe<Scalars['String']['input']>
    clientName_ends_with?: InputMaybe<Scalars['String']['input']>
    clientName_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientName_i?: InputMaybe<Scalars['String']['input']>
    clientName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientName_not?: InputMaybe<Scalars['String']['input']>
    clientName_not_contains?: InputMaybe<Scalars['String']['input']>
    clientName_not_contains_i?: InputMaybe<Scalars['String']['input']>
    clientName_not_ends_with?: InputMaybe<Scalars['String']['input']>
    clientName_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientName_not_i?: InputMaybe<Scalars['String']['input']>
    clientName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientName_not_starts_with?: InputMaybe<Scalars['String']['input']>
    clientName_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    clientName_starts_with?: InputMaybe<Scalars['String']['input']>
    clientName_starts_with_i?: InputMaybe<Scalars['String']['input']>
    clientPhone?: InputMaybe<Scalars['String']['input']>
    clientPhone_contains?: InputMaybe<Scalars['String']['input']>
    clientPhone_contains_i?: InputMaybe<Scalars['String']['input']>
    clientPhone_ends_with?: InputMaybe<Scalars['String']['input']>
    clientPhone_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientPhone_i?: InputMaybe<Scalars['String']['input']>
    clientPhone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientPhone_not?: InputMaybe<Scalars['String']['input']>
    clientPhone_not_contains?: InputMaybe<Scalars['String']['input']>
    clientPhone_not_contains_i?: InputMaybe<Scalars['String']['input']>
    clientPhone_not_ends_with?: InputMaybe<Scalars['String']['input']>
    clientPhone_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientPhone_not_i?: InputMaybe<Scalars['String']['input']>
    clientPhone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientPhone_not_starts_with?: InputMaybe<Scalars['String']['input']>
    clientPhone_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    clientPhone_starts_with?: InputMaybe<Scalars['String']['input']>
    clientPhone_starts_with_i?: InputMaybe<Scalars['String']['input']>
    client_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    client_not?: InputMaybe<Scalars['String']['input']>
    client_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    contact?: InputMaybe<Scalars['String']['input']>
    contact_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    contact_not?: InputMaybe<Scalars['String']['input']>
    contact_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    date?: InputMaybe<Scalars['String']['input']>
    date_gt?: InputMaybe<Scalars['String']['input']>
    date_gte?: InputMaybe<Scalars['String']['input']>
    date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    date_lt?: InputMaybe<Scalars['String']['input']>
    date_lte?: InputMaybe<Scalars['String']['input']>
    date_not?: InputMaybe<Scalars['String']['input']>
    date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<MeterReadingHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<MeterReadingHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<MeterReadingHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<MeterReadingHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    meter?: InputMaybe<Scalars['String']['input']>
    meter_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    meter_not?: InputMaybe<Scalars['String']['input']>
    meter_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    source?: InputMaybe<Scalars['String']['input']>
    source_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    source_not?: InputMaybe<Scalars['String']['input']>
    source_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    value1?: InputMaybe<Scalars['String']['input']>
    value1_gt?: InputMaybe<Scalars['String']['input']>
    value1_gte?: InputMaybe<Scalars['String']['input']>
    value1_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    value1_lt?: InputMaybe<Scalars['String']['input']>
    value1_lte?: InputMaybe<Scalars['String']['input']>
    value1_not?: InputMaybe<Scalars['String']['input']>
    value1_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    value2?: InputMaybe<Scalars['String']['input']>
    value2_gt?: InputMaybe<Scalars['String']['input']>
    value2_gte?: InputMaybe<Scalars['String']['input']>
    value2_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    value2_lt?: InputMaybe<Scalars['String']['input']>
    value2_lte?: InputMaybe<Scalars['String']['input']>
    value2_not?: InputMaybe<Scalars['String']['input']>
    value2_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    value3?: InputMaybe<Scalars['String']['input']>
    value3_gt?: InputMaybe<Scalars['String']['input']>
    value3_gte?: InputMaybe<Scalars['String']['input']>
    value3_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    value3_lt?: InputMaybe<Scalars['String']['input']>
    value3_lte?: InputMaybe<Scalars['String']['input']>
    value3_not?: InputMaybe<Scalars['String']['input']>
    value3_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    value4?: InputMaybe<Scalars['String']['input']>
    value4_gt?: InputMaybe<Scalars['String']['input']>
    value4_gte?: InputMaybe<Scalars['String']['input']>
    value4_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    value4_lt?: InputMaybe<Scalars['String']['input']>
    value4_lte?: InputMaybe<Scalars['String']['input']>
    value4_not?: InputMaybe<Scalars['String']['input']>
    value4_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type MeterReadingHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type MeterReadingHistoryRecordsCreateInput = {
    data?: InputMaybe<MeterReadingHistoryRecordCreateInput>
}

export type MeterReadingHistoryRecordsUpdateInput = {
    data?: InputMaybe<MeterReadingHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

/**  Ticket source. Income call, mobile_app, ...  */
export type MeterReadingSource = {
    __typename?: 'MeterReadingSource'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MeterReadingSource List config, or
   *  2. As an alias to the field set on 'labelField' in the MeterReadingSource List config, or
   *  3. As an alias to a 'name' field on the MeterReadingSource List (if one exists), or
   *  4. As an alias to the 'id' field on the MeterReadingSource List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    name?: Maybe<Scalars['String']['output']>
    nameNonLocalized?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    type?: Maybe<MeterReadingSourceTypeType>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type MeterReadingSourceCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    type?: InputMaybe<MeterReadingSourceTypeType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type MeterReadingSourceHistoryRecord = {
    __typename?: 'MeterReadingSourceHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MeterReadingSourceHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the MeterReadingSourceHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the MeterReadingSourceHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the MeterReadingSourceHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<MeterReadingSourceHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    type?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type MeterReadingSourceHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<MeterReadingSourceHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    type?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum MeterReadingSourceHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type MeterReadingSourceHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<MeterReadingSourceHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    type?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type MeterReadingSourceHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<MeterReadingSourceHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<MeterReadingSourceHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<MeterReadingSourceHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<MeterReadingSourceHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<MeterReadingSourceHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<MeterReadingSourceHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    type?: InputMaybe<Scalars['String']['input']>
    type_contains?: InputMaybe<Scalars['String']['input']>
    type_contains_i?: InputMaybe<Scalars['String']['input']>
    type_ends_with?: InputMaybe<Scalars['String']['input']>
    type_ends_with_i?: InputMaybe<Scalars['String']['input']>
    type_i?: InputMaybe<Scalars['String']['input']>
    type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    type_not?: InputMaybe<Scalars['String']['input']>
    type_not_contains?: InputMaybe<Scalars['String']['input']>
    type_not_contains_i?: InputMaybe<Scalars['String']['input']>
    type_not_ends_with?: InputMaybe<Scalars['String']['input']>
    type_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    type_not_i?: InputMaybe<Scalars['String']['input']>
    type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    type_not_starts_with?: InputMaybe<Scalars['String']['input']>
    type_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    type_starts_with?: InputMaybe<Scalars['String']['input']>
    type_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type MeterReadingSourceHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type MeterReadingSourceHistoryRecordsCreateInput = {
    data?: InputMaybe<MeterReadingSourceHistoryRecordCreateInput>
}

export type MeterReadingSourceHistoryRecordsUpdateInput = {
    data?: InputMaybe<MeterReadingSourceHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type MeterReadingSourceRelateToOneInput = {
    connect?: InputMaybe<MeterReadingSourceWhereUniqueInput>
    create?: InputMaybe<MeterReadingSourceCreateInput>
    disconnect?: InputMaybe<MeterReadingSourceWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export enum MeterReadingSourceTypeType {
    Call = 'call',
    Crm = 'crm',
    Email = 'email',
    ExternalImport = 'external_import',
    ExternalIntegration = 'external_integration',
    ImportCondo = 'import_condo',
    Messenger = 'messenger',
    MobileApp = 'mobile_app',
    MobileAppStaff = 'mobile_app_staff',
    OrganizationSite = 'organization_site',
    Other = 'other',
    RemoteSystem = 'remote_system',
    SocialNetwork = 'social_network',
    Visit = 'visit',
    WebApp = 'web_app',
}

export type MeterReadingSourceUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    type?: InputMaybe<MeterReadingSourceTypeType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type MeterReadingSourceWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<MeterReadingSourceWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<MeterReadingSourceWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    type?: InputMaybe<MeterReadingSourceTypeType>
    type_in?: InputMaybe<Array<InputMaybe<MeterReadingSourceTypeType>>>
    type_not?: InputMaybe<MeterReadingSourceTypeType>
    type_not_in?: InputMaybe<Array<InputMaybe<MeterReadingSourceTypeType>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type MeterReadingSourceWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type MeterReadingSourcesCreateInput = {
    data?: InputMaybe<MeterReadingSourceCreateInput>
}

export type MeterReadingSourcesUpdateInput = {
    data?: InputMaybe<MeterReadingSourceUpdateInput>
    id: Scalars['ID']['input']
}

export type MeterReadingUpdateInput = {
    accountNumber?: InputMaybe<Scalars['String']['input']>
    billingStatus?: InputMaybe<MeterReadingBillingStatusType>
    billingStatusText?: InputMaybe<Scalars['String']['input']>
    client?: InputMaybe<UserRelateToOneInput>
    clientEmail?: InputMaybe<Scalars['String']['input']>
    clientName?: InputMaybe<Scalars['String']['input']>
    clientPhone?: InputMaybe<Scalars['String']['input']>
    contact?: InputMaybe<ContactRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    date?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    meter?: InputMaybe<MeterRelateToOneInput>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    source?: InputMaybe<MeterReadingSourceRelateToOneInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
    value1?: InputMaybe<Scalars['String']['input']>
    value2?: InputMaybe<Scalars['String']['input']>
    value3?: InputMaybe<Scalars['String']['input']>
    value4?: InputMaybe<Scalars['String']['input']>
}

export type MeterReadingWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<MeterReadingWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<MeterReadingWhereInput>>>
    accountNumber?: InputMaybe<Scalars['String']['input']>
    accountNumber_contains?: InputMaybe<Scalars['String']['input']>
    accountNumber_contains_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_ends_with?: InputMaybe<Scalars['String']['input']>
    accountNumber_ends_with_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    accountNumber_not?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_contains?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_contains_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_ends_with?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    accountNumber_not_starts_with?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_starts_with?: InputMaybe<Scalars['String']['input']>
    accountNumber_starts_with_i?: InputMaybe<Scalars['String']['input']>
    billingStatus?: InputMaybe<MeterReadingBillingStatusType>
    billingStatusText?: InputMaybe<Scalars['String']['input']>
    billingStatusText_contains?: InputMaybe<Scalars['String']['input']>
    billingStatusText_contains_i?: InputMaybe<Scalars['String']['input']>
    billingStatusText_ends_with?: InputMaybe<Scalars['String']['input']>
    billingStatusText_ends_with_i?: InputMaybe<Scalars['String']['input']>
    billingStatusText_i?: InputMaybe<Scalars['String']['input']>
    billingStatusText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    billingStatusText_not?: InputMaybe<Scalars['String']['input']>
    billingStatusText_not_contains?: InputMaybe<Scalars['String']['input']>
    billingStatusText_not_contains_i?: InputMaybe<Scalars['String']['input']>
    billingStatusText_not_ends_with?: InputMaybe<Scalars['String']['input']>
    billingStatusText_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    billingStatusText_not_i?: InputMaybe<Scalars['String']['input']>
    billingStatusText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    billingStatusText_not_starts_with?: InputMaybe<Scalars['String']['input']>
    billingStatusText_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    billingStatusText_starts_with?: InputMaybe<Scalars['String']['input']>
    billingStatusText_starts_with_i?: InputMaybe<Scalars['String']['input']>
    billingStatus_in?: InputMaybe<Array<InputMaybe<MeterReadingBillingStatusType>>>
    billingStatus_not?: InputMaybe<MeterReadingBillingStatusType>
    billingStatus_not_in?: InputMaybe<Array<InputMaybe<MeterReadingBillingStatusType>>>
    client?: InputMaybe<UserWhereInput>
    clientEmail?: InputMaybe<Scalars['String']['input']>
    clientEmail_contains?: InputMaybe<Scalars['String']['input']>
    clientEmail_contains_i?: InputMaybe<Scalars['String']['input']>
    clientEmail_ends_with?: InputMaybe<Scalars['String']['input']>
    clientEmail_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientEmail_i?: InputMaybe<Scalars['String']['input']>
    clientEmail_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientEmail_not?: InputMaybe<Scalars['String']['input']>
    clientEmail_not_contains?: InputMaybe<Scalars['String']['input']>
    clientEmail_not_contains_i?: InputMaybe<Scalars['String']['input']>
    clientEmail_not_ends_with?: InputMaybe<Scalars['String']['input']>
    clientEmail_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientEmail_not_i?: InputMaybe<Scalars['String']['input']>
    clientEmail_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientEmail_not_starts_with?: InputMaybe<Scalars['String']['input']>
    clientEmail_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    clientEmail_starts_with?: InputMaybe<Scalars['String']['input']>
    clientEmail_starts_with_i?: InputMaybe<Scalars['String']['input']>
    clientName?: InputMaybe<Scalars['String']['input']>
    clientName_contains?: InputMaybe<Scalars['String']['input']>
    clientName_contains_i?: InputMaybe<Scalars['String']['input']>
    clientName_ends_with?: InputMaybe<Scalars['String']['input']>
    clientName_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientName_i?: InputMaybe<Scalars['String']['input']>
    clientName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientName_not?: InputMaybe<Scalars['String']['input']>
    clientName_not_contains?: InputMaybe<Scalars['String']['input']>
    clientName_not_contains_i?: InputMaybe<Scalars['String']['input']>
    clientName_not_ends_with?: InputMaybe<Scalars['String']['input']>
    clientName_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientName_not_i?: InputMaybe<Scalars['String']['input']>
    clientName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientName_not_starts_with?: InputMaybe<Scalars['String']['input']>
    clientName_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    clientName_starts_with?: InputMaybe<Scalars['String']['input']>
    clientName_starts_with_i?: InputMaybe<Scalars['String']['input']>
    clientPhone?: InputMaybe<Scalars['String']['input']>
    clientPhone_contains?: InputMaybe<Scalars['String']['input']>
    clientPhone_contains_i?: InputMaybe<Scalars['String']['input']>
    clientPhone_ends_with?: InputMaybe<Scalars['String']['input']>
    clientPhone_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientPhone_i?: InputMaybe<Scalars['String']['input']>
    clientPhone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientPhone_not?: InputMaybe<Scalars['String']['input']>
    clientPhone_not_contains?: InputMaybe<Scalars['String']['input']>
    clientPhone_not_contains_i?: InputMaybe<Scalars['String']['input']>
    clientPhone_not_ends_with?: InputMaybe<Scalars['String']['input']>
    clientPhone_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientPhone_not_i?: InputMaybe<Scalars['String']['input']>
    clientPhone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientPhone_not_starts_with?: InputMaybe<Scalars['String']['input']>
    clientPhone_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    clientPhone_starts_with?: InputMaybe<Scalars['String']['input']>
    clientPhone_starts_with_i?: InputMaybe<Scalars['String']['input']>
    client_is_null?: InputMaybe<Scalars['Boolean']['input']>
    contact?: InputMaybe<ContactWhereInput>
    contact_is_null?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    date?: InputMaybe<Scalars['String']['input']>
    date_gt?: InputMaybe<Scalars['String']['input']>
    date_gte?: InputMaybe<Scalars['String']['input']>
    date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    date_lt?: InputMaybe<Scalars['String']['input']>
    date_lte?: InputMaybe<Scalars['String']['input']>
    date_not?: InputMaybe<Scalars['String']['input']>
    date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    meter?: InputMaybe<MeterWhereInput>
    meter_is_null?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    source?: InputMaybe<MeterReadingSourceWhereInput>
    source_is_null?: InputMaybe<Scalars['Boolean']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    value1?: InputMaybe<Scalars['String']['input']>
    value1_gt?: InputMaybe<Scalars['String']['input']>
    value1_gte?: InputMaybe<Scalars['String']['input']>
    value1_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    value1_lt?: InputMaybe<Scalars['String']['input']>
    value1_lte?: InputMaybe<Scalars['String']['input']>
    value1_not?: InputMaybe<Scalars['String']['input']>
    value1_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    value2?: InputMaybe<Scalars['String']['input']>
    value2_gt?: InputMaybe<Scalars['String']['input']>
    value2_gte?: InputMaybe<Scalars['String']['input']>
    value2_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    value2_lt?: InputMaybe<Scalars['String']['input']>
    value2_lte?: InputMaybe<Scalars['String']['input']>
    value2_not?: InputMaybe<Scalars['String']['input']>
    value2_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    value3?: InputMaybe<Scalars['String']['input']>
    value3_gt?: InputMaybe<Scalars['String']['input']>
    value3_gte?: InputMaybe<Scalars['String']['input']>
    value3_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    value3_lt?: InputMaybe<Scalars['String']['input']>
    value3_lte?: InputMaybe<Scalars['String']['input']>
    value3_not?: InputMaybe<Scalars['String']['input']>
    value3_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    value4?: InputMaybe<Scalars['String']['input']>
    value4_gt?: InputMaybe<Scalars['String']['input']>
    value4_gte?: InputMaybe<Scalars['String']['input']>
    value4_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    value4_lt?: InputMaybe<Scalars['String']['input']>
    value4_lte?: InputMaybe<Scalars['String']['input']>
    value4_not?: InputMaybe<Scalars['String']['input']>
    value4_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type MeterReadingWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type MeterReadingsCreateInput = {
    data?: InputMaybe<MeterReadingCreateInput>
}

/**  Stores requested import job parameters, link to error file (if exists) and information about progress of import job  */
export type MeterReadingsImportTask = {
    __typename?: 'MeterReadingsImportTask'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MeterReadingsImportTask List config, or
   *  2. As an alias to the field set on 'labelField' in the MeterReadingsImportTask List config, or
   *  3. As an alias to a 'name' field on the MeterReadingsImportTask List (if one exists), or
   *  4. As an alias to the 'id' field on the MeterReadingsImportTask List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  Meta information about error file. Shape of meta information JSON object is specific to file adapter, used by saving a file.  */
    errorFile?: Maybe<File>
    /**  Proceeding error information message  */
    errorMessage?: Maybe<Scalars['String']['output']>
    /**  Meta information about file, saved outside of database somewhere. Shape of meta information JSON object is specific to file adapter, used by saving a file.  */
    file?: Maybe<File>
    /**  Requested import file format  */
    format?: Maybe<MeterReadingsImportTaskFormatType>
    id: Scalars['ID']['output']
    /**  How many records at the moment are successfully imported  */
    importedRecordsCount?: Maybe<Scalars['Int']['output']>
    /**  Requested import locale  */
    locale?: Maybe<Scalars['String']['output']>
    /**  Stores information about query and ids of exported and failed records  */
    meta?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Direct link to organization, since meters import requires to specify target organization  */
    organization?: Maybe<Organization>
    /**  How many records were processed at the moment  */
    processedRecordsCount?: Maybe<Scalars['Int']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Status of import job  */
    status?: Maybe<MeterReadingsImportTaskStatusType>
    /**  Total records to import that will be determined at server side in import operation  */
    totalRecordsCount?: Maybe<Scalars['Int']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    /**  User that requested this exporting operation. Will be used for read access checks to display all exported tasks somewhere and to display progress indicator of ongoing exporting task for current user  */
    user?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type MeterReadingsImportTaskCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    errorFile?: InputMaybe<Scalars['Upload']['input']>
    errorMessage?: InputMaybe<Scalars['String']['input']>
    file?: InputMaybe<Scalars['Upload']['input']>
    format?: InputMaybe<MeterReadingsImportTaskFormatType>
    importedRecordsCount?: InputMaybe<Scalars['Int']['input']>
    locale?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    processedRecordsCount?: InputMaybe<Scalars['Int']['input']>
    sender?: InputMaybe<SenderFieldInput>
    status?: InputMaybe<MeterReadingsImportTaskStatusType>
    totalRecordsCount?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum MeterReadingsImportTaskFormatType {
    Csv_1s = 'csv_1s',
    ExcelDoma = 'excel_doma',
}

/**  A keystone list  */
export type MeterReadingsImportTaskHistoryRecord = {
    __typename?: 'MeterReadingsImportTaskHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MeterReadingsImportTaskHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the MeterReadingsImportTaskHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the MeterReadingsImportTaskHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the MeterReadingsImportTaskHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    errorFile?: Maybe<Scalars['JSON']['output']>
    errorMessage?: Maybe<Scalars['String']['output']>
    file?: Maybe<Scalars['JSON']['output']>
    format?: Maybe<Scalars['String']['output']>
    history_action?: Maybe<MeterReadingsImportTaskHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    importedRecordsCount?: Maybe<Scalars['Int']['output']>
    locale?: Maybe<Scalars['String']['output']>
    meta?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    processedRecordsCount?: Maybe<Scalars['Int']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    status?: Maybe<Scalars['String']['output']>
    totalRecordsCount?: Maybe<Scalars['Int']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    user?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type MeterReadingsImportTaskHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    errorFile?: InputMaybe<Scalars['JSON']['input']>
    errorMessage?: InputMaybe<Scalars['String']['input']>
    file?: InputMaybe<Scalars['JSON']['input']>
    format?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<MeterReadingsImportTaskHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    importedRecordsCount?: InputMaybe<Scalars['Int']['input']>
    locale?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    processedRecordsCount?: InputMaybe<Scalars['Int']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    totalRecordsCount?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum MeterReadingsImportTaskHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type MeterReadingsImportTaskHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    errorFile?: InputMaybe<Scalars['JSON']['input']>
    errorMessage?: InputMaybe<Scalars['String']['input']>
    file?: InputMaybe<Scalars['JSON']['input']>
    format?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<MeterReadingsImportTaskHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    importedRecordsCount?: InputMaybe<Scalars['Int']['input']>
    locale?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    processedRecordsCount?: InputMaybe<Scalars['Int']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    totalRecordsCount?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type MeterReadingsImportTaskHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<MeterReadingsImportTaskHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<MeterReadingsImportTaskHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    errorFile?: InputMaybe<Scalars['JSON']['input']>
    errorFile_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    errorFile_not?: InputMaybe<Scalars['JSON']['input']>
    errorFile_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    errorMessage?: InputMaybe<Scalars['String']['input']>
    errorMessage_contains?: InputMaybe<Scalars['String']['input']>
    errorMessage_contains_i?: InputMaybe<Scalars['String']['input']>
    errorMessage_ends_with?: InputMaybe<Scalars['String']['input']>
    errorMessage_ends_with_i?: InputMaybe<Scalars['String']['input']>
    errorMessage_i?: InputMaybe<Scalars['String']['input']>
    errorMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    errorMessage_not?: InputMaybe<Scalars['String']['input']>
    errorMessage_not_contains?: InputMaybe<Scalars['String']['input']>
    errorMessage_not_contains_i?: InputMaybe<Scalars['String']['input']>
    errorMessage_not_ends_with?: InputMaybe<Scalars['String']['input']>
    errorMessage_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    errorMessage_not_i?: InputMaybe<Scalars['String']['input']>
    errorMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    errorMessage_not_starts_with?: InputMaybe<Scalars['String']['input']>
    errorMessage_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    errorMessage_starts_with?: InputMaybe<Scalars['String']['input']>
    errorMessage_starts_with_i?: InputMaybe<Scalars['String']['input']>
    file?: InputMaybe<Scalars['JSON']['input']>
    file_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    file_not?: InputMaybe<Scalars['JSON']['input']>
    file_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    format?: InputMaybe<Scalars['String']['input']>
    format_contains?: InputMaybe<Scalars['String']['input']>
    format_contains_i?: InputMaybe<Scalars['String']['input']>
    format_ends_with?: InputMaybe<Scalars['String']['input']>
    format_ends_with_i?: InputMaybe<Scalars['String']['input']>
    format_i?: InputMaybe<Scalars['String']['input']>
    format_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    format_not?: InputMaybe<Scalars['String']['input']>
    format_not_contains?: InputMaybe<Scalars['String']['input']>
    format_not_contains_i?: InputMaybe<Scalars['String']['input']>
    format_not_ends_with?: InputMaybe<Scalars['String']['input']>
    format_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    format_not_i?: InputMaybe<Scalars['String']['input']>
    format_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    format_not_starts_with?: InputMaybe<Scalars['String']['input']>
    format_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    format_starts_with?: InputMaybe<Scalars['String']['input']>
    format_starts_with_i?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<MeterReadingsImportTaskHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<MeterReadingsImportTaskHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<MeterReadingsImportTaskHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<MeterReadingsImportTaskHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    importedRecordsCount?: InputMaybe<Scalars['Int']['input']>
    importedRecordsCount_gt?: InputMaybe<Scalars['Int']['input']>
    importedRecordsCount_gte?: InputMaybe<Scalars['Int']['input']>
    importedRecordsCount_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    importedRecordsCount_lt?: InputMaybe<Scalars['Int']['input']>
    importedRecordsCount_lte?: InputMaybe<Scalars['Int']['input']>
    importedRecordsCount_not?: InputMaybe<Scalars['Int']['input']>
    importedRecordsCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    locale?: InputMaybe<Scalars['String']['input']>
    locale_contains?: InputMaybe<Scalars['String']['input']>
    locale_contains_i?: InputMaybe<Scalars['String']['input']>
    locale_ends_with?: InputMaybe<Scalars['String']['input']>
    locale_ends_with_i?: InputMaybe<Scalars['String']['input']>
    locale_i?: InputMaybe<Scalars['String']['input']>
    locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    locale_not?: InputMaybe<Scalars['String']['input']>
    locale_not_contains?: InputMaybe<Scalars['String']['input']>
    locale_not_contains_i?: InputMaybe<Scalars['String']['input']>
    locale_not_ends_with?: InputMaybe<Scalars['String']['input']>
    locale_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    locale_not_i?: InputMaybe<Scalars['String']['input']>
    locale_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    locale_not_starts_with?: InputMaybe<Scalars['String']['input']>
    locale_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    locale_starts_with?: InputMaybe<Scalars['String']['input']>
    locale_starts_with_i?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    processedRecordsCount?: InputMaybe<Scalars['Int']['input']>
    processedRecordsCount_gt?: InputMaybe<Scalars['Int']['input']>
    processedRecordsCount_gte?: InputMaybe<Scalars['Int']['input']>
    processedRecordsCount_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    processedRecordsCount_lt?: InputMaybe<Scalars['Int']['input']>
    processedRecordsCount_lte?: InputMaybe<Scalars['Int']['input']>
    processedRecordsCount_not?: InputMaybe<Scalars['Int']['input']>
    processedRecordsCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    status?: InputMaybe<Scalars['String']['input']>
    status_contains?: InputMaybe<Scalars['String']['input']>
    status_contains_i?: InputMaybe<Scalars['String']['input']>
    status_ends_with?: InputMaybe<Scalars['String']['input']>
    status_ends_with_i?: InputMaybe<Scalars['String']['input']>
    status_i?: InputMaybe<Scalars['String']['input']>
    status_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status_not?: InputMaybe<Scalars['String']['input']>
    status_not_contains?: InputMaybe<Scalars['String']['input']>
    status_not_contains_i?: InputMaybe<Scalars['String']['input']>
    status_not_ends_with?: InputMaybe<Scalars['String']['input']>
    status_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    status_not_i?: InputMaybe<Scalars['String']['input']>
    status_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status_not_starts_with?: InputMaybe<Scalars['String']['input']>
    status_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    status_starts_with?: InputMaybe<Scalars['String']['input']>
    status_starts_with_i?: InputMaybe<Scalars['String']['input']>
    totalRecordsCount?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_gt?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_gte?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    totalRecordsCount_lt?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_lte?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_not?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user?: InputMaybe<Scalars['String']['input']>
    user_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user_not?: InputMaybe<Scalars['String']['input']>
    user_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type MeterReadingsImportTaskHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type MeterReadingsImportTaskHistoryRecordsCreateInput = {
    data?: InputMaybe<MeterReadingsImportTaskHistoryRecordCreateInput>
}

export type MeterReadingsImportTaskHistoryRecordsUpdateInput = {
    data?: InputMaybe<MeterReadingsImportTaskHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export enum MeterReadingsImportTaskStatusType {
    Cancelled = 'cancelled',
    Completed = 'completed',
    Error = 'error',
    Processing = 'processing',
}

export type MeterReadingsImportTaskUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    errorFile?: InputMaybe<Scalars['Upload']['input']>
    errorMessage?: InputMaybe<Scalars['String']['input']>
    file?: InputMaybe<Scalars['Upload']['input']>
    format?: InputMaybe<MeterReadingsImportTaskFormatType>
    importedRecordsCount?: InputMaybe<Scalars['Int']['input']>
    locale?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    processedRecordsCount?: InputMaybe<Scalars['Int']['input']>
    sender?: InputMaybe<SenderFieldInput>
    status?: InputMaybe<MeterReadingsImportTaskStatusType>
    totalRecordsCount?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type MeterReadingsImportTaskWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<MeterReadingsImportTaskWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<MeterReadingsImportTaskWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    errorFile?: InputMaybe<Scalars['String']['input']>
    errorFile_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    errorFile_not?: InputMaybe<Scalars['String']['input']>
    errorFile_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    errorMessage?: InputMaybe<Scalars['String']['input']>
    errorMessage_contains?: InputMaybe<Scalars['String']['input']>
    errorMessage_contains_i?: InputMaybe<Scalars['String']['input']>
    errorMessage_ends_with?: InputMaybe<Scalars['String']['input']>
    errorMessage_ends_with_i?: InputMaybe<Scalars['String']['input']>
    errorMessage_i?: InputMaybe<Scalars['String']['input']>
    errorMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    errorMessage_not?: InputMaybe<Scalars['String']['input']>
    errorMessage_not_contains?: InputMaybe<Scalars['String']['input']>
    errorMessage_not_contains_i?: InputMaybe<Scalars['String']['input']>
    errorMessage_not_ends_with?: InputMaybe<Scalars['String']['input']>
    errorMessage_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    errorMessage_not_i?: InputMaybe<Scalars['String']['input']>
    errorMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    errorMessage_not_starts_with?: InputMaybe<Scalars['String']['input']>
    errorMessage_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    errorMessage_starts_with?: InputMaybe<Scalars['String']['input']>
    errorMessage_starts_with_i?: InputMaybe<Scalars['String']['input']>
    file?: InputMaybe<Scalars['String']['input']>
    file_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    file_not?: InputMaybe<Scalars['String']['input']>
    file_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    format?: InputMaybe<MeterReadingsImportTaskFormatType>
    format_in?: InputMaybe<Array<InputMaybe<MeterReadingsImportTaskFormatType>>>
    format_not?: InputMaybe<MeterReadingsImportTaskFormatType>
    format_not_in?: InputMaybe<Array<InputMaybe<MeterReadingsImportTaskFormatType>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    importedRecordsCount?: InputMaybe<Scalars['Int']['input']>
    importedRecordsCount_gt?: InputMaybe<Scalars['Int']['input']>
    importedRecordsCount_gte?: InputMaybe<Scalars['Int']['input']>
    importedRecordsCount_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    importedRecordsCount_lt?: InputMaybe<Scalars['Int']['input']>
    importedRecordsCount_lte?: InputMaybe<Scalars['Int']['input']>
    importedRecordsCount_not?: InputMaybe<Scalars['Int']['input']>
    importedRecordsCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    locale?: InputMaybe<Scalars['String']['input']>
    locale_contains?: InputMaybe<Scalars['String']['input']>
    locale_contains_i?: InputMaybe<Scalars['String']['input']>
    locale_ends_with?: InputMaybe<Scalars['String']['input']>
    locale_ends_with_i?: InputMaybe<Scalars['String']['input']>
    locale_i?: InputMaybe<Scalars['String']['input']>
    locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    locale_not?: InputMaybe<Scalars['String']['input']>
    locale_not_contains?: InputMaybe<Scalars['String']['input']>
    locale_not_contains_i?: InputMaybe<Scalars['String']['input']>
    locale_not_ends_with?: InputMaybe<Scalars['String']['input']>
    locale_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    locale_not_i?: InputMaybe<Scalars['String']['input']>
    locale_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    locale_not_starts_with?: InputMaybe<Scalars['String']['input']>
    locale_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    locale_starts_with?: InputMaybe<Scalars['String']['input']>
    locale_starts_with_i?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    processedRecordsCount?: InputMaybe<Scalars['Int']['input']>
    processedRecordsCount_gt?: InputMaybe<Scalars['Int']['input']>
    processedRecordsCount_gte?: InputMaybe<Scalars['Int']['input']>
    processedRecordsCount_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    processedRecordsCount_lt?: InputMaybe<Scalars['Int']['input']>
    processedRecordsCount_lte?: InputMaybe<Scalars['Int']['input']>
    processedRecordsCount_not?: InputMaybe<Scalars['Int']['input']>
    processedRecordsCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    status?: InputMaybe<MeterReadingsImportTaskStatusType>
    status_in?: InputMaybe<Array<InputMaybe<MeterReadingsImportTaskStatusType>>>
    status_not?: InputMaybe<MeterReadingsImportTaskStatusType>
    status_not_in?: InputMaybe<Array<InputMaybe<MeterReadingsImportTaskStatusType>>>
    totalRecordsCount?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_gt?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_gte?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    totalRecordsCount_lt?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_lte?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_not?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    user?: InputMaybe<UserWhereInput>
    user_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type MeterReadingsImportTaskWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type MeterReadingsImportTasksCreateInput = {
    data?: InputMaybe<MeterReadingsImportTaskCreateInput>
}

export type MeterReadingsImportTasksUpdateInput = {
    data?: InputMaybe<MeterReadingsImportTaskUpdateInput>
    id: Scalars['ID']['input']
}

export type MeterReadingsUpdateInput = {
    data?: InputMaybe<MeterReadingUpdateInput>
    id: Scalars['ID']['input']
}

export type MeterRelateToOneInput = {
    connect?: InputMaybe<MeterWhereUniqueInput>
    create?: InputMaybe<MeterCreateInput>
    disconnect?: InputMaybe<MeterWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

/**  Settings for the meter reading report period. Readings can be transferred at any time. The scheme is needed for notifications on the client side  */
export type MeterReportingPeriod = {
    __typename?: 'MeterReportingPeriod'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MeterReportingPeriod List config, or
   *  2. As an alias to the field set on 'labelField' in the MeterReportingPeriod List config, or
   *  3. As an alias to a 'name' field on the MeterReportingPeriod List (if one exists), or
   *  4. As an alias to the 'id' field on the MeterReportingPeriod List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['String']['output']>
    /**  Finish day of the month for sending a push about the need to submit meter readings  */
    notifyEndDay?: Maybe<Scalars['Int']['output']>
    /**  Starting day of the month for sending a push about the need to submit meter readings  */
    notifyStartDay?: Maybe<Scalars['Int']['output']>
    /**  Ref to the organization. Will be null if the instance is the default installation for all users  */
    organization?: Maybe<Organization>
    /**  Ref to the property. Will be null if the instance is an installation for all users in the organization  */
    property?: Maybe<Property>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type MeterReportingPeriodCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    notifyEndDay?: InputMaybe<Scalars['Int']['input']>
    notifyStartDay?: InputMaybe<Scalars['Int']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    property?: InputMaybe<PropertyRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type MeterReportingPeriodHistoryRecord = {
    __typename?: 'MeterReportingPeriodHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MeterReportingPeriodHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the MeterReportingPeriodHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the MeterReportingPeriodHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the MeterReportingPeriodHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<MeterReportingPeriodHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['JSON']['output']>
    notifyEndDay?: Maybe<Scalars['Int']['output']>
    notifyStartDay?: Maybe<Scalars['Int']['output']>
    organization?: Maybe<Scalars['String']['output']>
    property?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type MeterReportingPeriodHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<MeterReportingPeriodHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    notifyEndDay?: InputMaybe<Scalars['Int']['input']>
    notifyStartDay?: InputMaybe<Scalars['Int']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    property?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum MeterReportingPeriodHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type MeterReportingPeriodHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<MeterReportingPeriodHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    notifyEndDay?: InputMaybe<Scalars['Int']['input']>
    notifyStartDay?: InputMaybe<Scalars['Int']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    property?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type MeterReportingPeriodHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<MeterReportingPeriodHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<MeterReportingPeriodHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<MeterReportingPeriodHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<MeterReportingPeriodHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<MeterReportingPeriodHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<MeterReportingPeriodHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    notifyEndDay?: InputMaybe<Scalars['Int']['input']>
    notifyEndDay_gt?: InputMaybe<Scalars['Int']['input']>
    notifyEndDay_gte?: InputMaybe<Scalars['Int']['input']>
    notifyEndDay_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    notifyEndDay_lt?: InputMaybe<Scalars['Int']['input']>
    notifyEndDay_lte?: InputMaybe<Scalars['Int']['input']>
    notifyEndDay_not?: InputMaybe<Scalars['Int']['input']>
    notifyEndDay_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    notifyStartDay?: InputMaybe<Scalars['Int']['input']>
    notifyStartDay_gt?: InputMaybe<Scalars['Int']['input']>
    notifyStartDay_gte?: InputMaybe<Scalars['Int']['input']>
    notifyStartDay_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    notifyStartDay_lt?: InputMaybe<Scalars['Int']['input']>
    notifyStartDay_lte?: InputMaybe<Scalars['Int']['input']>
    notifyStartDay_not?: InputMaybe<Scalars['Int']['input']>
    notifyStartDay_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    property?: InputMaybe<Scalars['String']['input']>
    property_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    property_not?: InputMaybe<Scalars['String']['input']>
    property_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type MeterReportingPeriodHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type MeterReportingPeriodHistoryRecordsCreateInput = {
    data?: InputMaybe<MeterReportingPeriodHistoryRecordCreateInput>
}

export type MeterReportingPeriodHistoryRecordsUpdateInput = {
    data?: InputMaybe<MeterReportingPeriodHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type MeterReportingPeriodUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    notifyEndDay?: InputMaybe<Scalars['Int']['input']>
    notifyStartDay?: InputMaybe<Scalars['Int']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    property?: InputMaybe<PropertyRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type MeterReportingPeriodWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<MeterReportingPeriodWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<MeterReportingPeriodWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    notifyEndDay?: InputMaybe<Scalars['Int']['input']>
    notifyEndDay_gt?: InputMaybe<Scalars['Int']['input']>
    notifyEndDay_gte?: InputMaybe<Scalars['Int']['input']>
    notifyEndDay_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    notifyEndDay_lt?: InputMaybe<Scalars['Int']['input']>
    notifyEndDay_lte?: InputMaybe<Scalars['Int']['input']>
    notifyEndDay_not?: InputMaybe<Scalars['Int']['input']>
    notifyEndDay_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    notifyStartDay?: InputMaybe<Scalars['Int']['input']>
    notifyStartDay_gt?: InputMaybe<Scalars['Int']['input']>
    notifyStartDay_gte?: InputMaybe<Scalars['Int']['input']>
    notifyStartDay_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    notifyStartDay_lt?: InputMaybe<Scalars['Int']['input']>
    notifyStartDay_lte?: InputMaybe<Scalars['Int']['input']>
    notifyStartDay_not?: InputMaybe<Scalars['Int']['input']>
    notifyStartDay_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    property?: InputMaybe<PropertyWhereInput>
    property_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type MeterReportingPeriodWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type MeterReportingPeriodsCreateInput = {
    data?: InputMaybe<MeterReportingPeriodCreateInput>
}

export type MeterReportingPeriodsUpdateInput = {
    data?: InputMaybe<MeterReportingPeriodUpdateInput>
    id: Scalars['ID']['input']
}

/**  Kind of consumed resource, measured by meter  */
export type MeterResource = {
    __typename?: 'MeterResource'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MeterResource List config, or
   *  2. As an alias to the field set on 'labelField' in the MeterResource List config, or
   *  3. As an alias to a 'name' field on the MeterResource List (if one exists), or
   *  4. As an alias to the 'id' field on the MeterResource List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    measure?: Maybe<Scalars['String']['output']>
    measureNonLocalized?: Maybe<Scalars['String']['output']>
    name?: Maybe<Scalars['String']['output']>
    nameNonLocalized?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type MeterResourceCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    measure?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type MeterResourceHistoryRecord = {
    __typename?: 'MeterResourceHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MeterResourceHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the MeterResourceHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the MeterResourceHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the MeterResourceHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<MeterResourceHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    measure?: Maybe<Scalars['String']['output']>
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type MeterResourceHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<MeterResourceHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    measure?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum MeterResourceHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type MeterResourceHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<MeterResourceHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    measure?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type MeterResourceHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<MeterResourceHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<MeterResourceHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<MeterResourceHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<MeterResourceHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<MeterResourceHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<MeterResourceHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    measure?: InputMaybe<Scalars['String']['input']>
    measure_contains?: InputMaybe<Scalars['String']['input']>
    measure_contains_i?: InputMaybe<Scalars['String']['input']>
    measure_ends_with?: InputMaybe<Scalars['String']['input']>
    measure_ends_with_i?: InputMaybe<Scalars['String']['input']>
    measure_i?: InputMaybe<Scalars['String']['input']>
    measure_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    measure_not?: InputMaybe<Scalars['String']['input']>
    measure_not_contains?: InputMaybe<Scalars['String']['input']>
    measure_not_contains_i?: InputMaybe<Scalars['String']['input']>
    measure_not_ends_with?: InputMaybe<Scalars['String']['input']>
    measure_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    measure_not_i?: InputMaybe<Scalars['String']['input']>
    measure_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    measure_not_starts_with?: InputMaybe<Scalars['String']['input']>
    measure_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    measure_starts_with?: InputMaybe<Scalars['String']['input']>
    measure_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type MeterResourceHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type MeterResourceHistoryRecordsCreateInput = {
    data?: InputMaybe<MeterResourceHistoryRecordCreateInput>
}

export type MeterResourceHistoryRecordsUpdateInput = {
    data?: InputMaybe<MeterResourceHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

/**  Determines whether the meter belongs to the organization for a specific apartment  */
export type MeterResourceOwner = {
    __typename?: 'MeterResourceOwner'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MeterResourceOwner List config, or
   *  2. As an alias to the field set on 'labelField' in the MeterResourceOwner List config, or
   *  3. As an alias to a 'name' field on the MeterResourceOwner List (if one exists), or
   *  4. As an alias to the 'id' field on the MeterResourceOwner List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  Normalized address  */
    address?: Maybe<Scalars['String']['output']>
    /**  The unique key of the address  */
    addressKey?: Maybe<Scalars['String']['output']>
    /**  Property address components  */
    addressMeta?: Maybe<AddressMetaField>
    /**  The origins of the address (some strings which may looks like real address or some id)  */
    addressSources?: Maybe<Scalars['JSON']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['String']['output']>
    /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
    organization?: Maybe<Organization>
    /**  Meter resource that organization is owned by  */
    resource?: Maybe<MeterResource>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type MeterResourceOwnerCreateInput = {
    address?: InputMaybe<Scalars['String']['input']>
    addressKey?: InputMaybe<Scalars['String']['input']>
    addressMeta?: InputMaybe<Scalars['JSON']['input']>
    addressSources?: InputMaybe<Scalars['JSON']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    resource?: InputMaybe<MeterResourceRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type MeterResourceOwnerHistoryRecord = {
    __typename?: 'MeterResourceOwnerHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MeterResourceOwnerHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the MeterResourceOwnerHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the MeterResourceOwnerHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the MeterResourceOwnerHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    address?: Maybe<Scalars['String']['output']>
    addressKey?: Maybe<Scalars['String']['output']>
    addressMeta?: Maybe<Scalars['JSON']['output']>
    addressSources?: Maybe<Scalars['JSON']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<MeterResourceOwnerHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    resource?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type MeterResourceOwnerHistoryRecordCreateInput = {
    address?: InputMaybe<Scalars['String']['input']>
    addressKey?: InputMaybe<Scalars['String']['input']>
    addressMeta?: InputMaybe<Scalars['JSON']['input']>
    addressSources?: InputMaybe<Scalars['JSON']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<MeterResourceOwnerHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    resource?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum MeterResourceOwnerHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type MeterResourceOwnerHistoryRecordUpdateInput = {
    address?: InputMaybe<Scalars['String']['input']>
    addressKey?: InputMaybe<Scalars['String']['input']>
    addressMeta?: InputMaybe<Scalars['JSON']['input']>
    addressSources?: InputMaybe<Scalars['JSON']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<MeterResourceOwnerHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    resource?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type MeterResourceOwnerHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<MeterResourceOwnerHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<MeterResourceOwnerHistoryRecordWhereInput>>>
    address?: InputMaybe<Scalars['String']['input']>
    addressKey?: InputMaybe<Scalars['String']['input']>
    addressKey_contains?: InputMaybe<Scalars['String']['input']>
    addressKey_contains_i?: InputMaybe<Scalars['String']['input']>
    addressKey_ends_with?: InputMaybe<Scalars['String']['input']>
    addressKey_ends_with_i?: InputMaybe<Scalars['String']['input']>
    addressKey_i?: InputMaybe<Scalars['String']['input']>
    addressKey_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    addressKey_not?: InputMaybe<Scalars['String']['input']>
    addressKey_not_contains?: InputMaybe<Scalars['String']['input']>
    addressKey_not_contains_i?: InputMaybe<Scalars['String']['input']>
    addressKey_not_ends_with?: InputMaybe<Scalars['String']['input']>
    addressKey_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    addressKey_not_i?: InputMaybe<Scalars['String']['input']>
    addressKey_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    addressKey_not_starts_with?: InputMaybe<Scalars['String']['input']>
    addressKey_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    addressKey_starts_with?: InputMaybe<Scalars['String']['input']>
    addressKey_starts_with_i?: InputMaybe<Scalars['String']['input']>
    addressMeta?: InputMaybe<Scalars['JSON']['input']>
    addressMeta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    addressMeta_not?: InputMaybe<Scalars['JSON']['input']>
    addressMeta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    addressSources?: InputMaybe<Scalars['JSON']['input']>
    addressSources_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    addressSources_not?: InputMaybe<Scalars['JSON']['input']>
    addressSources_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    address_contains?: InputMaybe<Scalars['String']['input']>
    address_contains_i?: InputMaybe<Scalars['String']['input']>
    address_ends_with?: InputMaybe<Scalars['String']['input']>
    address_ends_with_i?: InputMaybe<Scalars['String']['input']>
    address_i?: InputMaybe<Scalars['String']['input']>
    address_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    address_not?: InputMaybe<Scalars['String']['input']>
    address_not_contains?: InputMaybe<Scalars['String']['input']>
    address_not_contains_i?: InputMaybe<Scalars['String']['input']>
    address_not_ends_with?: InputMaybe<Scalars['String']['input']>
    address_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    address_not_i?: InputMaybe<Scalars['String']['input']>
    address_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    address_not_starts_with?: InputMaybe<Scalars['String']['input']>
    address_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    address_starts_with?: InputMaybe<Scalars['String']['input']>
    address_starts_with_i?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<MeterResourceOwnerHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<MeterResourceOwnerHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<MeterResourceOwnerHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<MeterResourceOwnerHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    resource?: InputMaybe<Scalars['String']['input']>
    resource_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    resource_not?: InputMaybe<Scalars['String']['input']>
    resource_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type MeterResourceOwnerHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type MeterResourceOwnerHistoryRecordsCreateInput = {
    data?: InputMaybe<MeterResourceOwnerHistoryRecordCreateInput>
}

export type MeterResourceOwnerHistoryRecordsUpdateInput = {
    data?: InputMaybe<MeterResourceOwnerHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type MeterResourceOwnerUpdateInput = {
    address?: InputMaybe<Scalars['String']['input']>
    addressKey?: InputMaybe<Scalars['String']['input']>
    addressMeta?: InputMaybe<Scalars['JSON']['input']>
    addressSources?: InputMaybe<Scalars['JSON']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    resource?: InputMaybe<MeterResourceRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type MeterResourceOwnerWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<MeterResourceOwnerWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<MeterResourceOwnerWhereInput>>>
    address?: InputMaybe<Scalars['String']['input']>
    addressKey?: InputMaybe<Scalars['String']['input']>
    addressKey_contains?: InputMaybe<Scalars['String']['input']>
    addressKey_contains_i?: InputMaybe<Scalars['String']['input']>
    addressKey_ends_with?: InputMaybe<Scalars['String']['input']>
    addressKey_ends_with_i?: InputMaybe<Scalars['String']['input']>
    addressKey_i?: InputMaybe<Scalars['String']['input']>
    addressKey_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    addressKey_not?: InputMaybe<Scalars['String']['input']>
    addressKey_not_contains?: InputMaybe<Scalars['String']['input']>
    addressKey_not_contains_i?: InputMaybe<Scalars['String']['input']>
    addressKey_not_ends_with?: InputMaybe<Scalars['String']['input']>
    addressKey_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    addressKey_not_i?: InputMaybe<Scalars['String']['input']>
    addressKey_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    addressKey_not_starts_with?: InputMaybe<Scalars['String']['input']>
    addressKey_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    addressKey_starts_with?: InputMaybe<Scalars['String']['input']>
    addressKey_starts_with_i?: InputMaybe<Scalars['String']['input']>
    addressMeta?: InputMaybe<Scalars['JSON']['input']>
    addressMeta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    addressMeta_not?: InputMaybe<Scalars['JSON']['input']>
    addressMeta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    addressSources?: InputMaybe<Scalars['JSON']['input']>
    addressSources_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    addressSources_not?: InputMaybe<Scalars['JSON']['input']>
    addressSources_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    address_contains?: InputMaybe<Scalars['String']['input']>
    address_contains_i?: InputMaybe<Scalars['String']['input']>
    address_ends_with?: InputMaybe<Scalars['String']['input']>
    address_ends_with_i?: InputMaybe<Scalars['String']['input']>
    address_i?: InputMaybe<Scalars['String']['input']>
    address_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    address_not?: InputMaybe<Scalars['String']['input']>
    address_not_contains?: InputMaybe<Scalars['String']['input']>
    address_not_contains_i?: InputMaybe<Scalars['String']['input']>
    address_not_ends_with?: InputMaybe<Scalars['String']['input']>
    address_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    address_not_i?: InputMaybe<Scalars['String']['input']>
    address_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    address_not_starts_with?: InputMaybe<Scalars['String']['input']>
    address_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    address_starts_with?: InputMaybe<Scalars['String']['input']>
    address_starts_with_i?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    resource?: InputMaybe<MeterResourceWhereInput>
    resource_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type MeterResourceOwnerWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type MeterResourceOwnersCreateInput = {
    data?: InputMaybe<MeterResourceOwnerCreateInput>
}

export type MeterResourceOwnersUpdateInput = {
    data?: InputMaybe<MeterResourceOwnerUpdateInput>
    id: Scalars['ID']['input']
}

export type MeterResourceRelateToOneInput = {
    connect?: InputMaybe<MeterResourceWhereUniqueInput>
    create?: InputMaybe<MeterResourceCreateInput>
    disconnect?: InputMaybe<MeterResourceWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type MeterResourceUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    measure?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type MeterResourceWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<MeterResourceWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<MeterResourceWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    measure?: InputMaybe<Scalars['String']['input']>
    measure_contains?: InputMaybe<Scalars['String']['input']>
    measure_contains_i?: InputMaybe<Scalars['String']['input']>
    measure_ends_with?: InputMaybe<Scalars['String']['input']>
    measure_ends_with_i?: InputMaybe<Scalars['String']['input']>
    measure_i?: InputMaybe<Scalars['String']['input']>
    measure_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    measure_not?: InputMaybe<Scalars['String']['input']>
    measure_not_contains?: InputMaybe<Scalars['String']['input']>
    measure_not_contains_i?: InputMaybe<Scalars['String']['input']>
    measure_not_ends_with?: InputMaybe<Scalars['String']['input']>
    measure_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    measure_not_i?: InputMaybe<Scalars['String']['input']>
    measure_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    measure_not_starts_with?: InputMaybe<Scalars['String']['input']>
    measure_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    measure_starts_with?: InputMaybe<Scalars['String']['input']>
    measure_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type MeterResourceWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type MeterResourcesCreateInput = {
    data?: InputMaybe<MeterResourceCreateInput>
}

export type MeterResourcesUpdateInput = {
    data?: InputMaybe<MeterResourceUpdateInput>
    id: Scalars['ID']['input']
}

export enum MeterUnitTypeType {
    Apartment = 'apartment',
    Commercial = 'commercial',
    Flat = 'flat',
    Parking = 'parking',
    Warehouse = 'warehouse',
}

export type MeterUpdateInput = {
    accountNumber?: InputMaybe<Scalars['String']['input']>
    archiveDate?: InputMaybe<Scalars['String']['input']>
    b2bApp?: InputMaybe<B2BAppRelateToOneInput>
    b2cApp?: InputMaybe<B2CAppRelateToOneInput>
    commissioningDate?: InputMaybe<Scalars['String']['input']>
    controlReadingsDate?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    installationDate?: InputMaybe<Scalars['String']['input']>
    isAutomatic?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    nextVerificationDate?: InputMaybe<Scalars['String']['input']>
    number?: InputMaybe<Scalars['String']['input']>
    numberOfTariffs?: InputMaybe<Scalars['Int']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    place?: InputMaybe<Scalars['String']['input']>
    property?: InputMaybe<PropertyRelateToOneInput>
    resource?: InputMaybe<MeterResourceRelateToOneInput>
    sealingDate?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    unitName?: InputMaybe<Scalars['String']['input']>
    unitType?: InputMaybe<MeterUnitTypeType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
    verificationDate?: InputMaybe<Scalars['String']['input']>
}

export type MeterWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<MeterWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<MeterWhereInput>>>
    accountNumber?: InputMaybe<Scalars['String']['input']>
    accountNumber_contains?: InputMaybe<Scalars['String']['input']>
    accountNumber_contains_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_ends_with?: InputMaybe<Scalars['String']['input']>
    accountNumber_ends_with_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    accountNumber_not?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_contains?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_contains_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_ends_with?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    accountNumber_not_starts_with?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_starts_with?: InputMaybe<Scalars['String']['input']>
    accountNumber_starts_with_i?: InputMaybe<Scalars['String']['input']>
    archiveDate?: InputMaybe<Scalars['String']['input']>
    archiveDate_gt?: InputMaybe<Scalars['String']['input']>
    archiveDate_gte?: InputMaybe<Scalars['String']['input']>
    archiveDate_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    archiveDate_lt?: InputMaybe<Scalars['String']['input']>
    archiveDate_lte?: InputMaybe<Scalars['String']['input']>
    archiveDate_not?: InputMaybe<Scalars['String']['input']>
    archiveDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    b2bApp?: InputMaybe<B2BAppWhereInput>
    b2bApp_is_null?: InputMaybe<Scalars['Boolean']['input']>
    b2cApp?: InputMaybe<B2CAppWhereInput>
    b2cApp_is_null?: InputMaybe<Scalars['Boolean']['input']>
    commissioningDate?: InputMaybe<Scalars['String']['input']>
    commissioningDate_gt?: InputMaybe<Scalars['String']['input']>
    commissioningDate_gte?: InputMaybe<Scalars['String']['input']>
    commissioningDate_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    commissioningDate_lt?: InputMaybe<Scalars['String']['input']>
    commissioningDate_lte?: InputMaybe<Scalars['String']['input']>
    commissioningDate_not?: InputMaybe<Scalars['String']['input']>
    commissioningDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    controlReadingsDate?: InputMaybe<Scalars['String']['input']>
    controlReadingsDate_gt?: InputMaybe<Scalars['String']['input']>
    controlReadingsDate_gte?: InputMaybe<Scalars['String']['input']>
    controlReadingsDate_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    controlReadingsDate_lt?: InputMaybe<Scalars['String']['input']>
    controlReadingsDate_lte?: InputMaybe<Scalars['String']['input']>
    controlReadingsDate_not?: InputMaybe<Scalars['String']['input']>
    controlReadingsDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    installationDate?: InputMaybe<Scalars['String']['input']>
    installationDate_gt?: InputMaybe<Scalars['String']['input']>
    installationDate_gte?: InputMaybe<Scalars['String']['input']>
    installationDate_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    installationDate_lt?: InputMaybe<Scalars['String']['input']>
    installationDate_lte?: InputMaybe<Scalars['String']['input']>
    installationDate_not?: InputMaybe<Scalars['String']['input']>
    installationDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    isAutomatic?: InputMaybe<Scalars['Boolean']['input']>
    isAutomatic_not?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    nextVerificationDate?: InputMaybe<Scalars['String']['input']>
    nextVerificationDate_gt?: InputMaybe<Scalars['String']['input']>
    nextVerificationDate_gte?: InputMaybe<Scalars['String']['input']>
    nextVerificationDate_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    nextVerificationDate_lt?: InputMaybe<Scalars['String']['input']>
    nextVerificationDate_lte?: InputMaybe<Scalars['String']['input']>
    nextVerificationDate_not?: InputMaybe<Scalars['String']['input']>
    nextVerificationDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    number?: InputMaybe<Scalars['String']['input']>
    numberOfTariffs?: InputMaybe<Scalars['Int']['input']>
    numberOfTariffs_gt?: InputMaybe<Scalars['Int']['input']>
    numberOfTariffs_gte?: InputMaybe<Scalars['Int']['input']>
    numberOfTariffs_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    numberOfTariffs_lt?: InputMaybe<Scalars['Int']['input']>
    numberOfTariffs_lte?: InputMaybe<Scalars['Int']['input']>
    numberOfTariffs_not?: InputMaybe<Scalars['Int']['input']>
    numberOfTariffs_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    number_contains?: InputMaybe<Scalars['String']['input']>
    number_contains_i?: InputMaybe<Scalars['String']['input']>
    number_ends_with?: InputMaybe<Scalars['String']['input']>
    number_ends_with_i?: InputMaybe<Scalars['String']['input']>
    number_i?: InputMaybe<Scalars['String']['input']>
    number_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    number_not?: InputMaybe<Scalars['String']['input']>
    number_not_contains?: InputMaybe<Scalars['String']['input']>
    number_not_contains_i?: InputMaybe<Scalars['String']['input']>
    number_not_ends_with?: InputMaybe<Scalars['String']['input']>
    number_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    number_not_i?: InputMaybe<Scalars['String']['input']>
    number_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    number_not_starts_with?: InputMaybe<Scalars['String']['input']>
    number_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    number_starts_with?: InputMaybe<Scalars['String']['input']>
    number_starts_with_i?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    place?: InputMaybe<Scalars['String']['input']>
    place_contains?: InputMaybe<Scalars['String']['input']>
    place_contains_i?: InputMaybe<Scalars['String']['input']>
    place_ends_with?: InputMaybe<Scalars['String']['input']>
    place_ends_with_i?: InputMaybe<Scalars['String']['input']>
    place_i?: InputMaybe<Scalars['String']['input']>
    place_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    place_not?: InputMaybe<Scalars['String']['input']>
    place_not_contains?: InputMaybe<Scalars['String']['input']>
    place_not_contains_i?: InputMaybe<Scalars['String']['input']>
    place_not_ends_with?: InputMaybe<Scalars['String']['input']>
    place_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    place_not_i?: InputMaybe<Scalars['String']['input']>
    place_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    place_not_starts_with?: InputMaybe<Scalars['String']['input']>
    place_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    place_starts_with?: InputMaybe<Scalars['String']['input']>
    place_starts_with_i?: InputMaybe<Scalars['String']['input']>
    property?: InputMaybe<PropertyWhereInput>
    property_is_null?: InputMaybe<Scalars['Boolean']['input']>
    resource?: InputMaybe<MeterResourceWhereInput>
    resource_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sealingDate?: InputMaybe<Scalars['String']['input']>
    sealingDate_gt?: InputMaybe<Scalars['String']['input']>
    sealingDate_gte?: InputMaybe<Scalars['String']['input']>
    sealingDate_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sealingDate_lt?: InputMaybe<Scalars['String']['input']>
    sealingDate_lte?: InputMaybe<Scalars['String']['input']>
    sealingDate_not?: InputMaybe<Scalars['String']['input']>
    sealingDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    unitName?: InputMaybe<Scalars['String']['input']>
    unitName_contains?: InputMaybe<Scalars['String']['input']>
    unitName_contains_i?: InputMaybe<Scalars['String']['input']>
    unitName_ends_with?: InputMaybe<Scalars['String']['input']>
    unitName_ends_with_i?: InputMaybe<Scalars['String']['input']>
    unitName_i?: InputMaybe<Scalars['String']['input']>
    unitName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitName_not?: InputMaybe<Scalars['String']['input']>
    unitName_not_contains?: InputMaybe<Scalars['String']['input']>
    unitName_not_contains_i?: InputMaybe<Scalars['String']['input']>
    unitName_not_ends_with?: InputMaybe<Scalars['String']['input']>
    unitName_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    unitName_not_i?: InputMaybe<Scalars['String']['input']>
    unitName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitName_not_starts_with?: InputMaybe<Scalars['String']['input']>
    unitName_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    unitName_starts_with?: InputMaybe<Scalars['String']['input']>
    unitName_starts_with_i?: InputMaybe<Scalars['String']['input']>
    unitType?: InputMaybe<MeterUnitTypeType>
    unitType_in?: InputMaybe<Array<InputMaybe<MeterUnitTypeType>>>
    unitType_not?: InputMaybe<MeterUnitTypeType>
    unitType_not_in?: InputMaybe<Array<InputMaybe<MeterUnitTypeType>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    verificationDate?: InputMaybe<Scalars['String']['input']>
    verificationDate_gt?: InputMaybe<Scalars['String']['input']>
    verificationDate_gte?: InputMaybe<Scalars['String']['input']>
    verificationDate_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    verificationDate_lt?: InputMaybe<Scalars['String']['input']>
    verificationDate_lte?: InputMaybe<Scalars['String']['input']>
    verificationDate_not?: InputMaybe<Scalars['String']['input']>
    verificationDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type MeterWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type MetersCreateInput = {
    data?: InputMaybe<MeterCreateInput>
}

export type MetersUpdateInput = {
    data?: InputMaybe<MeterUpdateInput>
    id: Scalars['ID']['input']
}

export type MiniAppOutput = {
    __typename?: 'MiniAppOutput'
    accessible: Scalars['Boolean']['output']
    category: AppCategory
    connected: Scalars['Boolean']['output']
    icon?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    label?: Maybe<Scalars['String']['output']>
    logo?: Maybe<Scalars['String']['output']>
    menuCategory?: Maybe<Scalars['String']['output']>
    name: Scalars['String']['output']
    shortDescription?: Maybe<Scalars['String']['output']>
}

export enum MobileApp {
    Master = 'master',
    Resident = 'resident',
}

/**  Manages availability of some features in mobile application, stores data required in disabled state of a feature.  */
export type MobileFeatureConfig = {
    __typename?: 'MobileFeatureConfig'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MobileFeatureConfig List config, or
   *  2. As an alias to the field set on 'labelField' in the MobileFeatureConfig List config, or
   *  3. As an alias to a 'name' field on the MobileFeatureConfig List (if one exists), or
   *  4. As an alias to the 'id' field on the MobileFeatureConfig List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  Phone number where the organization wants to receive common calls  */
    commonPhone?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  Can be used to store some meta information for mobile applications.  */
    meta?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Restricts residents to submit only meter readings, that are greater than previous. Restrictions apply to mobile phones only.  */
    onlyGreaterThanPreviousMeterReadingIsEnabled?: Maybe<Scalars['Boolean']['output']>
    /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
    organization?: Maybe<Organization>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Disabling the ability to send tickets by the user of the resident's mobile application. Instead, he will be shown a screen with phones where he can contact to send a request  */
    ticketSubmittingIsDisabled?: Maybe<Scalars['Boolean']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type MobileFeatureConfigCreateInput = {
    commonPhone?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    onlyGreaterThanPreviousMeterReadingIsEnabled?: InputMaybe<Scalars['Boolean']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    ticketSubmittingIsDisabled?: InputMaybe<Scalars['Boolean']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type MobileFeatureConfigHistoryRecord = {
    __typename?: 'MobileFeatureConfigHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MobileFeatureConfigHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the MobileFeatureConfigHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the MobileFeatureConfigHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the MobileFeatureConfigHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    commonPhone?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<MobileFeatureConfigHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    meta?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    onlyGreaterThanPreviousMeterReadingIsEnabled?: Maybe<Scalars['Boolean']['output']>
    organization?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    ticketSubmittingIsDisabled?: Maybe<Scalars['Boolean']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type MobileFeatureConfigHistoryRecordCreateInput = {
    commonPhone?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<MobileFeatureConfigHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    onlyGreaterThanPreviousMeterReadingIsEnabled?: InputMaybe<Scalars['Boolean']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    ticketSubmittingIsDisabled?: InputMaybe<Scalars['Boolean']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum MobileFeatureConfigHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type MobileFeatureConfigHistoryRecordUpdateInput = {
    commonPhone?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<MobileFeatureConfigHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    onlyGreaterThanPreviousMeterReadingIsEnabled?: InputMaybe<Scalars['Boolean']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    ticketSubmittingIsDisabled?: InputMaybe<Scalars['Boolean']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type MobileFeatureConfigHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<MobileFeatureConfigHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<MobileFeatureConfigHistoryRecordWhereInput>>>
    commonPhone?: InputMaybe<Scalars['String']['input']>
    commonPhone_contains?: InputMaybe<Scalars['String']['input']>
    commonPhone_contains_i?: InputMaybe<Scalars['String']['input']>
    commonPhone_ends_with?: InputMaybe<Scalars['String']['input']>
    commonPhone_ends_with_i?: InputMaybe<Scalars['String']['input']>
    commonPhone_i?: InputMaybe<Scalars['String']['input']>
    commonPhone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    commonPhone_not?: InputMaybe<Scalars['String']['input']>
    commonPhone_not_contains?: InputMaybe<Scalars['String']['input']>
    commonPhone_not_contains_i?: InputMaybe<Scalars['String']['input']>
    commonPhone_not_ends_with?: InputMaybe<Scalars['String']['input']>
    commonPhone_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    commonPhone_not_i?: InputMaybe<Scalars['String']['input']>
    commonPhone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    commonPhone_not_starts_with?: InputMaybe<Scalars['String']['input']>
    commonPhone_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    commonPhone_starts_with?: InputMaybe<Scalars['String']['input']>
    commonPhone_starts_with_i?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<MobileFeatureConfigHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<MobileFeatureConfigHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<MobileFeatureConfigHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<MobileFeatureConfigHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    onlyGreaterThanPreviousMeterReadingIsEnabled?: InputMaybe<Scalars['Boolean']['input']>
    onlyGreaterThanPreviousMeterReadingIsEnabled_not?: InputMaybe<Scalars['Boolean']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    ticketSubmittingIsDisabled?: InputMaybe<Scalars['Boolean']['input']>
    ticketSubmittingIsDisabled_not?: InputMaybe<Scalars['Boolean']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type MobileFeatureConfigHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type MobileFeatureConfigHistoryRecordsCreateInput = {
    data?: InputMaybe<MobileFeatureConfigHistoryRecordCreateInput>
}

export type MobileFeatureConfigHistoryRecordsUpdateInput = {
    data?: InputMaybe<MobileFeatureConfigHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type MobileFeatureConfigUpdateInput = {
    commonPhone?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    onlyGreaterThanPreviousMeterReadingIsEnabled?: InputMaybe<Scalars['Boolean']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    ticketSubmittingIsDisabled?: InputMaybe<Scalars['Boolean']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type MobileFeatureConfigWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<MobileFeatureConfigWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<MobileFeatureConfigWhereInput>>>
    commonPhone?: InputMaybe<Scalars['String']['input']>
    commonPhone_contains?: InputMaybe<Scalars['String']['input']>
    commonPhone_contains_i?: InputMaybe<Scalars['String']['input']>
    commonPhone_ends_with?: InputMaybe<Scalars['String']['input']>
    commonPhone_ends_with_i?: InputMaybe<Scalars['String']['input']>
    commonPhone_i?: InputMaybe<Scalars['String']['input']>
    commonPhone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    commonPhone_not?: InputMaybe<Scalars['String']['input']>
    commonPhone_not_contains?: InputMaybe<Scalars['String']['input']>
    commonPhone_not_contains_i?: InputMaybe<Scalars['String']['input']>
    commonPhone_not_ends_with?: InputMaybe<Scalars['String']['input']>
    commonPhone_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    commonPhone_not_i?: InputMaybe<Scalars['String']['input']>
    commonPhone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    commonPhone_not_starts_with?: InputMaybe<Scalars['String']['input']>
    commonPhone_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    commonPhone_starts_with?: InputMaybe<Scalars['String']['input']>
    commonPhone_starts_with_i?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    onlyGreaterThanPreviousMeterReadingIsEnabled?: InputMaybe<Scalars['Boolean']['input']>
    onlyGreaterThanPreviousMeterReadingIsEnabled_not?: InputMaybe<Scalars['Boolean']['input']>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    ticketSubmittingIsDisabled?: InputMaybe<Scalars['Boolean']['input']>
    ticketSubmittingIsDisabled_not?: InputMaybe<Scalars['Boolean']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type MobileFeatureConfigWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type MobileFeatureConfigsCreateInput = {
    data?: InputMaybe<MobileFeatureConfigCreateInput>
}

export type MobileFeatureConfigsUpdateInput = {
    data?: InputMaybe<MobileFeatureConfigUpdateInput>
    id: Scalars['ID']['input']
}

export enum MobilePlatform {
    Android = 'android',
    Ios = 'ios',
}

/**  Information about resident's payment for single or multiple services/receipts  */
export type MultiPayment = {
    __typename?: 'MultiPayment'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MultiPayment List config, or
   *  2. As an alias to the field set on 'labelField' in the MultiPayment List config, or
   *  3. As an alias to a 'name' field on the MultiPayment List (if one exists), or
   *  4. As an alias to the 'id' field on the MultiPayment List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    _paymentsMeta?: Maybe<_QueryMeta>
    /**  Total amount of withdraw. amount = amountWithoutExplicitFee + explicitFee + explicitServiceCharge  */
    amount?: Maybe<Scalars['String']['output']>
    /**  The amount of money used to pay bills, initialized by resident.  */
    amountWithoutExplicitFee?: Maybe<Scalars['String']['output']>
    /**  Number of the card (masked) from which the money was withdrawn. Needed for creating receipt  */
    cardNumber?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    /**  Code of currency in ISO-4217 format  */
    currencyCode?: Maybe<MultiPaymentCurrencyCodeType>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  Amount of money which payer pays on top of initial "amount", which counts as fee for total "amount"  */
    explicitFee?: Maybe<Scalars['String']['output']>
    /**  Amount of money which payer pays on top of initial "amount", which counts as internal service charge for all payments  */
    explicitServiceCharge?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    /**  Total amount of money charged to recipients from amountWithoutExplicitFee of multipayment as fee for transaction  */
    implicitFee?: Maybe<Scalars['String']['output']>
    /**  Id of object in external service which represents current item. Mostly used for internal needs of integration services for matching our objects with theirs  */
    importId?: Maybe<Scalars['String']['output']>
    /**  Acquiring integration. Determines way of user's payment  */
    integration?: Maybe<AcquiringIntegration>
    /**  Additional acquiring-specific information  */
    meta?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Payer email address (optional). Can be used by support to find MultiPayment faster or to send digital receipt  */
    payerEmail?: Maybe<Scalars['String']['output']>
    /**  Payment way, such as `CARD` or `APPLE_PAY`  */
    paymentWay?: Maybe<MultiPaymentPaymentWayType>
    /**  Link to all related payments  */
    payments: Array<Payment>
    /**  Link to RecurrentPaymentContext  */
    recurrentPaymentContext?: Maybe<RecurrentPaymentContext>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Name of the payment document, such as `Квитанция`, `Штраф`  */
    serviceCategory?: Maybe<Scalars['String']['output']>
    /**  The amount of money charged by service (Doma) for the provision of service after subtracting from it the shares of all participants in the process. Can be part of explicit fee, implicit fee or explicit service charge  */
    serviceFee?: Maybe<Scalars['String']['output']>
    /**  Status of multipayment. Can be: "CREATED", "DONE", "PROCESSING", "ERROR", "WITHDRAWN"  */
    status?: Maybe<MultiPaymentStatusType>
    /**  Transaction ID of money withdraw  */
    transactionId?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    /**  Link to user  */
    user?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
    /**  Time of money withdraw (UTC)  */
    withdrawnAt?: Maybe<Scalars['String']['output']>
}


/**  Information about resident's payment for single or multiple services/receipts  */
export type MultiPayment_PaymentsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortPaymentsBy>>
    where?: InputMaybe<PaymentWhereInput>
}


/**  Information about resident's payment for single or multiple services/receipts  */
export type MultiPaymentPaymentsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortPaymentsBy>>
    where?: InputMaybe<PaymentWhereInput>
}

export type MultiPaymentCreateInput = {
    amountWithoutExplicitFee?: InputMaybe<Scalars['String']['input']>
    cardNumber?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    currencyCode?: InputMaybe<MultiPaymentCurrencyCodeType>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    explicitFee?: InputMaybe<Scalars['String']['input']>
    explicitServiceCharge?: InputMaybe<Scalars['String']['input']>
    implicitFee?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    integration?: InputMaybe<AcquiringIntegrationRelateToOneInput>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    payerEmail?: InputMaybe<Scalars['String']['input']>
    paymentWay?: InputMaybe<MultiPaymentPaymentWayType>
    payments?: InputMaybe<PaymentRelateToManyInput>
    recurrentPaymentContext?: InputMaybe<RecurrentPaymentContextRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    serviceCategory?: InputMaybe<Scalars['String']['input']>
    serviceFee?: InputMaybe<Scalars['String']['input']>
    status?: InputMaybe<MultiPaymentStatusType>
    transactionId?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
    withdrawnAt?: InputMaybe<Scalars['String']['input']>
}

export enum MultiPaymentCurrencyCodeType {
    Aed = 'AED',
    Afn = 'AFN',
    All = 'ALL',
    Amd = 'AMD',
    Ang = 'ANG',
    Aoa = 'AOA',
    Ars = 'ARS',
    Aud = 'AUD',
    Awg = 'AWG',
    Azn = 'AZN',
    Bam = 'BAM',
    Bbd = 'BBD',
    Bdt = 'BDT',
    Bgn = 'BGN',
    Bhd = 'BHD',
    Bif = 'BIF',
    Bmd = 'BMD',
    Bnd = 'BND',
    Bob = 'BOB',
    Bov = 'BOV',
    Brl = 'BRL',
    Bsd = 'BSD',
    Btn = 'BTN',
    Bwp = 'BWP',
    Byn = 'BYN',
    Bzd = 'BZD',
    Cad = 'CAD',
    Cdf = 'CDF',
    Che = 'CHE',
    Chf = 'CHF',
    Chw = 'CHW',
    Clf = 'CLF',
    Clp = 'CLP',
    Cny = 'CNY',
    Cop = 'COP',
    Cou = 'COU',
    Crc = 'CRC',
    Cuc = 'CUC',
    Cup = 'CUP',
    Cve = 'CVE',
    Czk = 'CZK',
    Djf = 'DJF',
    Dkk = 'DKK',
    Dop = 'DOP',
    Dzd = 'DZD',
    Egp = 'EGP',
    Ern = 'ERN',
    Etb = 'ETB',
    Eur = 'EUR',
    Fjd = 'FJD',
    Fkp = 'FKP',
    Gbp = 'GBP',
    Gel = 'GEL',
    Ghs = 'GHS',
    Gip = 'GIP',
    Gmd = 'GMD',
    Gnf = 'GNF',
    Gtq = 'GTQ',
    Gyd = 'GYD',
    Hkd = 'HKD',
    Hnl = 'HNL',
    Hrk = 'HRK',
    Htg = 'HTG',
    Huf = 'HUF',
    Idr = 'IDR',
    Ils = 'ILS',
    Inr = 'INR',
    Iqd = 'IQD',
    Irr = 'IRR',
    Isk = 'ISK',
    Jmd = 'JMD',
    Jod = 'JOD',
    Jpy = 'JPY',
    Kes = 'KES',
    Kgs = 'KGS',
    Khr = 'KHR',
    Kmf = 'KMF',
    Kpw = 'KPW',
    Krw = 'KRW',
    Kwd = 'KWD',
    Kyd = 'KYD',
    Kzt = 'KZT',
    Lak = 'LAK',
    Lbp = 'LBP',
    Lkr = 'LKR',
    Lrd = 'LRD',
    Lsl = 'LSL',
    Lyd = 'LYD',
    Mad = 'MAD',
    Mdl = 'MDL',
    Mga = 'MGA',
    Mkd = 'MKD',
    Mmk = 'MMK',
    Mnt = 'MNT',
    Mop = 'MOP',
    Mru = 'MRU',
    Mur = 'MUR',
    Mvr = 'MVR',
    Mwk = 'MWK',
    Mxn = 'MXN',
    Mxv = 'MXV',
    Myr = 'MYR',
    Mzn = 'MZN',
    Nad = 'NAD',
    Ngn = 'NGN',
    Nio = 'NIO',
    Nok = 'NOK',
    Npr = 'NPR',
    Nzd = 'NZD',
    Omr = 'OMR',
    Pab = 'PAB',
    Pen = 'PEN',
    Pgk = 'PGK',
    Php = 'PHP',
    Pkr = 'PKR',
    Pln = 'PLN',
    Pyg = 'PYG',
    Qar = 'QAR',
    Ron = 'RON',
    Rsd = 'RSD',
    Rub = 'RUB',
    Rwf = 'RWF',
    Sar = 'SAR',
    Sbd = 'SBD',
    Scr = 'SCR',
    Sdg = 'SDG',
    Sek = 'SEK',
    Sgd = 'SGD',
    Shp = 'SHP',
    Sll = 'SLL',
    Sos = 'SOS',
    Srd = 'SRD',
    Ssp = 'SSP',
    Stn = 'STN',
    Svc = 'SVC',
    Syp = 'SYP',
    Szl = 'SZL',
    Thb = 'THB',
    Tjs = 'TJS',
    Tmt = 'TMT',
    Tnd = 'TND',
    Top = 'TOP',
    Try = 'TRY',
    Ttd = 'TTD',
    Twd = 'TWD',
    Tzs = 'TZS',
    Uah = 'UAH',
    Ugx = 'UGX',
    Usd = 'USD',
    Usn = 'USN',
    Uyi = 'UYI',
    Uyu = 'UYU',
    Uyw = 'UYW',
    Uzs = 'UZS',
    Ves = 'VES',
    Vnd = 'VND',
    Vuv = 'VUV',
    Wst = 'WST',
    Xaf = 'XAF',
    Xag = 'XAG',
    Xau = 'XAU',
    Xba = 'XBA',
    Xbb = 'XBB',
    Xbc = 'XBC',
    Xbd = 'XBD',
    Xcd = 'XCD',
    Xdr = 'XDR',
    Xof = 'XOF',
    Xpd = 'XPD',
    Xpf = 'XPF',
    Xpt = 'XPT',
    Xsu = 'XSU',
    Xts = 'XTS',
    Xua = 'XUA',
    Yer = 'YER',
    Zar = 'ZAR',
    Zmw = 'ZMW',
    Zwl = 'ZWL',
}

/**  A keystone list  */
export type MultiPaymentHistoryRecord = {
    __typename?: 'MultiPaymentHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MultiPaymentHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the MultiPaymentHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the MultiPaymentHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the MultiPaymentHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    amountWithoutExplicitFee?: Maybe<Scalars['String']['output']>
    cardNumber?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    currencyCode?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    explicitFee?: Maybe<Scalars['String']['output']>
    explicitServiceCharge?: Maybe<Scalars['String']['output']>
    history_action?: Maybe<MultiPaymentHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    implicitFee?: Maybe<Scalars['String']['output']>
    importId?: Maybe<Scalars['String']['output']>
    integration?: Maybe<Scalars['String']['output']>
    meta?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    payerEmail?: Maybe<Scalars['String']['output']>
    paymentWay?: Maybe<Scalars['String']['output']>
    recurrentPaymentContext?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    serviceCategory?: Maybe<Scalars['String']['output']>
    serviceFee?: Maybe<Scalars['String']['output']>
    status?: Maybe<Scalars['String']['output']>
    transactionId?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    user?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
    withdrawnAt?: Maybe<Scalars['String']['output']>
}

export type MultiPaymentHistoryRecordCreateInput = {
    amountWithoutExplicitFee?: InputMaybe<Scalars['String']['input']>
    cardNumber?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    currencyCode?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    explicitFee?: InputMaybe<Scalars['String']['input']>
    explicitServiceCharge?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<MultiPaymentHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    implicitFee?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    integration?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    payerEmail?: InputMaybe<Scalars['String']['input']>
    paymentWay?: InputMaybe<Scalars['String']['input']>
    recurrentPaymentContext?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    serviceCategory?: InputMaybe<Scalars['String']['input']>
    serviceFee?: InputMaybe<Scalars['String']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    transactionId?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    withdrawnAt?: InputMaybe<Scalars['String']['input']>
}

export enum MultiPaymentHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type MultiPaymentHistoryRecordUpdateInput = {
    amountWithoutExplicitFee?: InputMaybe<Scalars['String']['input']>
    cardNumber?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    currencyCode?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    explicitFee?: InputMaybe<Scalars['String']['input']>
    explicitServiceCharge?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<MultiPaymentHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    implicitFee?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    integration?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    payerEmail?: InputMaybe<Scalars['String']['input']>
    paymentWay?: InputMaybe<Scalars['String']['input']>
    recurrentPaymentContext?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    serviceCategory?: InputMaybe<Scalars['String']['input']>
    serviceFee?: InputMaybe<Scalars['String']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    transactionId?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    withdrawnAt?: InputMaybe<Scalars['String']['input']>
}

export type MultiPaymentHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<MultiPaymentHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<MultiPaymentHistoryRecordWhereInput>>>
    amountWithoutExplicitFee?: InputMaybe<Scalars['String']['input']>
    amountWithoutExplicitFee_gt?: InputMaybe<Scalars['String']['input']>
    amountWithoutExplicitFee_gte?: InputMaybe<Scalars['String']['input']>
    amountWithoutExplicitFee_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    amountWithoutExplicitFee_lt?: InputMaybe<Scalars['String']['input']>
    amountWithoutExplicitFee_lte?: InputMaybe<Scalars['String']['input']>
    amountWithoutExplicitFee_not?: InputMaybe<Scalars['String']['input']>
    amountWithoutExplicitFee_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    cardNumber?: InputMaybe<Scalars['String']['input']>
    cardNumber_contains?: InputMaybe<Scalars['String']['input']>
    cardNumber_contains_i?: InputMaybe<Scalars['String']['input']>
    cardNumber_ends_with?: InputMaybe<Scalars['String']['input']>
    cardNumber_ends_with_i?: InputMaybe<Scalars['String']['input']>
    cardNumber_i?: InputMaybe<Scalars['String']['input']>
    cardNumber_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    cardNumber_not?: InputMaybe<Scalars['String']['input']>
    cardNumber_not_contains?: InputMaybe<Scalars['String']['input']>
    cardNumber_not_contains_i?: InputMaybe<Scalars['String']['input']>
    cardNumber_not_ends_with?: InputMaybe<Scalars['String']['input']>
    cardNumber_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    cardNumber_not_i?: InputMaybe<Scalars['String']['input']>
    cardNumber_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    cardNumber_not_starts_with?: InputMaybe<Scalars['String']['input']>
    cardNumber_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    cardNumber_starts_with?: InputMaybe<Scalars['String']['input']>
    cardNumber_starts_with_i?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    currencyCode?: InputMaybe<Scalars['String']['input']>
    currencyCode_contains?: InputMaybe<Scalars['String']['input']>
    currencyCode_contains_i?: InputMaybe<Scalars['String']['input']>
    currencyCode_ends_with?: InputMaybe<Scalars['String']['input']>
    currencyCode_ends_with_i?: InputMaybe<Scalars['String']['input']>
    currencyCode_i?: InputMaybe<Scalars['String']['input']>
    currencyCode_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    currencyCode_not?: InputMaybe<Scalars['String']['input']>
    currencyCode_not_contains?: InputMaybe<Scalars['String']['input']>
    currencyCode_not_contains_i?: InputMaybe<Scalars['String']['input']>
    currencyCode_not_ends_with?: InputMaybe<Scalars['String']['input']>
    currencyCode_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    currencyCode_not_i?: InputMaybe<Scalars['String']['input']>
    currencyCode_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    currencyCode_not_starts_with?: InputMaybe<Scalars['String']['input']>
    currencyCode_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    currencyCode_starts_with?: InputMaybe<Scalars['String']['input']>
    currencyCode_starts_with_i?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    explicitFee?: InputMaybe<Scalars['String']['input']>
    explicitFee_gt?: InputMaybe<Scalars['String']['input']>
    explicitFee_gte?: InputMaybe<Scalars['String']['input']>
    explicitFee_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    explicitFee_lt?: InputMaybe<Scalars['String']['input']>
    explicitFee_lte?: InputMaybe<Scalars['String']['input']>
    explicitFee_not?: InputMaybe<Scalars['String']['input']>
    explicitFee_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    explicitServiceCharge?: InputMaybe<Scalars['String']['input']>
    explicitServiceCharge_gt?: InputMaybe<Scalars['String']['input']>
    explicitServiceCharge_gte?: InputMaybe<Scalars['String']['input']>
    explicitServiceCharge_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    explicitServiceCharge_lt?: InputMaybe<Scalars['String']['input']>
    explicitServiceCharge_lte?: InputMaybe<Scalars['String']['input']>
    explicitServiceCharge_not?: InputMaybe<Scalars['String']['input']>
    explicitServiceCharge_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_action?: InputMaybe<MultiPaymentHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<MultiPaymentHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<MultiPaymentHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<MultiPaymentHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    implicitFee?: InputMaybe<Scalars['String']['input']>
    implicitFee_gt?: InputMaybe<Scalars['String']['input']>
    implicitFee_gte?: InputMaybe<Scalars['String']['input']>
    implicitFee_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    implicitFee_lt?: InputMaybe<Scalars['String']['input']>
    implicitFee_lte?: InputMaybe<Scalars['String']['input']>
    implicitFee_not?: InputMaybe<Scalars['String']['input']>
    implicitFee_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId?: InputMaybe<Scalars['String']['input']>
    importId_contains?: InputMaybe<Scalars['String']['input']>
    importId_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_i?: InputMaybe<Scalars['String']['input']>
    importId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not?: InputMaybe<Scalars['String']['input']>
    importId_not_contains?: InputMaybe<Scalars['String']['input']>
    importId_not_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_not_i?: InputMaybe<Scalars['String']['input']>
    importId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importId_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_starts_with_i?: InputMaybe<Scalars['String']['input']>
    integration?: InputMaybe<Scalars['String']['input']>
    integration_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    integration_not?: InputMaybe<Scalars['String']['input']>
    integration_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    payerEmail?: InputMaybe<Scalars['String']['input']>
    payerEmail_contains?: InputMaybe<Scalars['String']['input']>
    payerEmail_contains_i?: InputMaybe<Scalars['String']['input']>
    payerEmail_ends_with?: InputMaybe<Scalars['String']['input']>
    payerEmail_ends_with_i?: InputMaybe<Scalars['String']['input']>
    payerEmail_i?: InputMaybe<Scalars['String']['input']>
    payerEmail_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    payerEmail_not?: InputMaybe<Scalars['String']['input']>
    payerEmail_not_contains?: InputMaybe<Scalars['String']['input']>
    payerEmail_not_contains_i?: InputMaybe<Scalars['String']['input']>
    payerEmail_not_ends_with?: InputMaybe<Scalars['String']['input']>
    payerEmail_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    payerEmail_not_i?: InputMaybe<Scalars['String']['input']>
    payerEmail_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    payerEmail_not_starts_with?: InputMaybe<Scalars['String']['input']>
    payerEmail_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    payerEmail_starts_with?: InputMaybe<Scalars['String']['input']>
    payerEmail_starts_with_i?: InputMaybe<Scalars['String']['input']>
    paymentWay?: InputMaybe<Scalars['String']['input']>
    paymentWay_contains?: InputMaybe<Scalars['String']['input']>
    paymentWay_contains_i?: InputMaybe<Scalars['String']['input']>
    paymentWay_ends_with?: InputMaybe<Scalars['String']['input']>
    paymentWay_ends_with_i?: InputMaybe<Scalars['String']['input']>
    paymentWay_i?: InputMaybe<Scalars['String']['input']>
    paymentWay_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    paymentWay_not?: InputMaybe<Scalars['String']['input']>
    paymentWay_not_contains?: InputMaybe<Scalars['String']['input']>
    paymentWay_not_contains_i?: InputMaybe<Scalars['String']['input']>
    paymentWay_not_ends_with?: InputMaybe<Scalars['String']['input']>
    paymentWay_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    paymentWay_not_i?: InputMaybe<Scalars['String']['input']>
    paymentWay_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    paymentWay_not_starts_with?: InputMaybe<Scalars['String']['input']>
    paymentWay_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    paymentWay_starts_with?: InputMaybe<Scalars['String']['input']>
    paymentWay_starts_with_i?: InputMaybe<Scalars['String']['input']>
    recurrentPaymentContext?: InputMaybe<Scalars['String']['input']>
    recurrentPaymentContext_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    recurrentPaymentContext_not?: InputMaybe<Scalars['String']['input']>
    recurrentPaymentContext_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    serviceCategory?: InputMaybe<Scalars['String']['input']>
    serviceCategory_contains?: InputMaybe<Scalars['String']['input']>
    serviceCategory_contains_i?: InputMaybe<Scalars['String']['input']>
    serviceCategory_ends_with?: InputMaybe<Scalars['String']['input']>
    serviceCategory_ends_with_i?: InputMaybe<Scalars['String']['input']>
    serviceCategory_i?: InputMaybe<Scalars['String']['input']>
    serviceCategory_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    serviceCategory_not?: InputMaybe<Scalars['String']['input']>
    serviceCategory_not_contains?: InputMaybe<Scalars['String']['input']>
    serviceCategory_not_contains_i?: InputMaybe<Scalars['String']['input']>
    serviceCategory_not_ends_with?: InputMaybe<Scalars['String']['input']>
    serviceCategory_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    serviceCategory_not_i?: InputMaybe<Scalars['String']['input']>
    serviceCategory_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    serviceCategory_not_starts_with?: InputMaybe<Scalars['String']['input']>
    serviceCategory_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    serviceCategory_starts_with?: InputMaybe<Scalars['String']['input']>
    serviceCategory_starts_with_i?: InputMaybe<Scalars['String']['input']>
    serviceFee?: InputMaybe<Scalars['String']['input']>
    serviceFee_gt?: InputMaybe<Scalars['String']['input']>
    serviceFee_gte?: InputMaybe<Scalars['String']['input']>
    serviceFee_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    serviceFee_lt?: InputMaybe<Scalars['String']['input']>
    serviceFee_lte?: InputMaybe<Scalars['String']['input']>
    serviceFee_not?: InputMaybe<Scalars['String']['input']>
    serviceFee_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status?: InputMaybe<Scalars['String']['input']>
    status_contains?: InputMaybe<Scalars['String']['input']>
    status_contains_i?: InputMaybe<Scalars['String']['input']>
    status_ends_with?: InputMaybe<Scalars['String']['input']>
    status_ends_with_i?: InputMaybe<Scalars['String']['input']>
    status_i?: InputMaybe<Scalars['String']['input']>
    status_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status_not?: InputMaybe<Scalars['String']['input']>
    status_not_contains?: InputMaybe<Scalars['String']['input']>
    status_not_contains_i?: InputMaybe<Scalars['String']['input']>
    status_not_ends_with?: InputMaybe<Scalars['String']['input']>
    status_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    status_not_i?: InputMaybe<Scalars['String']['input']>
    status_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status_not_starts_with?: InputMaybe<Scalars['String']['input']>
    status_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    status_starts_with?: InputMaybe<Scalars['String']['input']>
    status_starts_with_i?: InputMaybe<Scalars['String']['input']>
    transactionId?: InputMaybe<Scalars['String']['input']>
    transactionId_contains?: InputMaybe<Scalars['String']['input']>
    transactionId_contains_i?: InputMaybe<Scalars['String']['input']>
    transactionId_ends_with?: InputMaybe<Scalars['String']['input']>
    transactionId_ends_with_i?: InputMaybe<Scalars['String']['input']>
    transactionId_i?: InputMaybe<Scalars['String']['input']>
    transactionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    transactionId_not?: InputMaybe<Scalars['String']['input']>
    transactionId_not_contains?: InputMaybe<Scalars['String']['input']>
    transactionId_not_contains_i?: InputMaybe<Scalars['String']['input']>
    transactionId_not_ends_with?: InputMaybe<Scalars['String']['input']>
    transactionId_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    transactionId_not_i?: InputMaybe<Scalars['String']['input']>
    transactionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    transactionId_not_starts_with?: InputMaybe<Scalars['String']['input']>
    transactionId_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    transactionId_starts_with?: InputMaybe<Scalars['String']['input']>
    transactionId_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user?: InputMaybe<Scalars['String']['input']>
    user_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user_not?: InputMaybe<Scalars['String']['input']>
    user_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    withdrawnAt?: InputMaybe<Scalars['String']['input']>
    withdrawnAt_gt?: InputMaybe<Scalars['String']['input']>
    withdrawnAt_gte?: InputMaybe<Scalars['String']['input']>
    withdrawnAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    withdrawnAt_lt?: InputMaybe<Scalars['String']['input']>
    withdrawnAt_lte?: InputMaybe<Scalars['String']['input']>
    withdrawnAt_not?: InputMaybe<Scalars['String']['input']>
    withdrawnAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type MultiPaymentHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type MultiPaymentHistoryRecordsCreateInput = {
    data?: InputMaybe<MultiPaymentHistoryRecordCreateInput>
}

export type MultiPaymentHistoryRecordsUpdateInput = {
    data?: InputMaybe<MultiPaymentHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export enum MultiPaymentPaymentWayType {
    AndroidPay = 'ANDROID_PAY',
    AndroidPayBinding = 'ANDROID_PAY_BINDING',
    ApplePay = 'APPLE_PAY',
    ApplePayBinding = 'APPLE_PAY_BINDING',
    Card = 'CARD',
    CardBinding = 'CARD_BINDING',
    CardMoto = 'CARD_MOTO',
    CardPresent = 'CARD_PRESENT',
    FileBinding = 'FILE_BINDING',
    GooglePayCard = 'GOOGLE_PAY_CARD',
    GooglePayCardBinding = 'GOOGLE_PAY_CARD_BINDING',
    GooglePayTokenized = 'GOOGLE_PAY_TOKENIZED',
    GooglePayTokenizedBinding = 'GOOGLE_PAY_TOKENIZED_BINDING',
    Ipos = 'IPOS',
    Mts = 'MTS',
    P2P = 'P2P',
    P2PBinding = 'P2P_BINDING',
    Paypal = 'PAYPAL',
    SamsungPay = 'SAMSUNG_PAY',
    SamsungPayBinding = 'SAMSUNG_PAY_BINDING',
    Sberid = 'SBERID',
    Sberpay = 'SBERPAY',
    SbrfSbol = 'SBRF_SBOL',
    SbrfSbolBinding = 'SBRF_SBOL_BINDING',
    SmsBinding = 'SMS_BINDING',
    Upop = 'UPOP',
}

export type MultiPaymentRelateToOneInput = {
    connect?: InputMaybe<MultiPaymentWhereUniqueInput>
    create?: InputMaybe<MultiPaymentCreateInput>
    disconnect?: InputMaybe<MultiPaymentWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export enum MultiPaymentStatusType {
    Created = 'CREATED',
    Done = 'DONE',
    Error = 'ERROR',
    Processing = 'PROCESSING',
    Withdrawn = 'WITHDRAWN',
}

export type MultiPaymentUpdateInput = {
    amountWithoutExplicitFee?: InputMaybe<Scalars['String']['input']>
    cardNumber?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    currencyCode?: InputMaybe<MultiPaymentCurrencyCodeType>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    explicitFee?: InputMaybe<Scalars['String']['input']>
    explicitServiceCharge?: InputMaybe<Scalars['String']['input']>
    implicitFee?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    integration?: InputMaybe<AcquiringIntegrationRelateToOneInput>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    payerEmail?: InputMaybe<Scalars['String']['input']>
    paymentWay?: InputMaybe<MultiPaymentPaymentWayType>
    payments?: InputMaybe<PaymentRelateToManyInput>
    recurrentPaymentContext?: InputMaybe<RecurrentPaymentContextRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    serviceCategory?: InputMaybe<Scalars['String']['input']>
    serviceFee?: InputMaybe<Scalars['String']['input']>
    status?: InputMaybe<MultiPaymentStatusType>
    transactionId?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
    withdrawnAt?: InputMaybe<Scalars['String']['input']>
}

export type MultiPaymentWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<MultiPaymentWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<MultiPaymentWhereInput>>>
    amountWithoutExplicitFee?: InputMaybe<Scalars['String']['input']>
    amountWithoutExplicitFee_gt?: InputMaybe<Scalars['String']['input']>
    amountWithoutExplicitFee_gte?: InputMaybe<Scalars['String']['input']>
    amountWithoutExplicitFee_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    amountWithoutExplicitFee_lt?: InputMaybe<Scalars['String']['input']>
    amountWithoutExplicitFee_lte?: InputMaybe<Scalars['String']['input']>
    amountWithoutExplicitFee_not?: InputMaybe<Scalars['String']['input']>
    amountWithoutExplicitFee_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    cardNumber?: InputMaybe<Scalars['String']['input']>
    cardNumber_contains?: InputMaybe<Scalars['String']['input']>
    cardNumber_contains_i?: InputMaybe<Scalars['String']['input']>
    cardNumber_ends_with?: InputMaybe<Scalars['String']['input']>
    cardNumber_ends_with_i?: InputMaybe<Scalars['String']['input']>
    cardNumber_i?: InputMaybe<Scalars['String']['input']>
    cardNumber_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    cardNumber_not?: InputMaybe<Scalars['String']['input']>
    cardNumber_not_contains?: InputMaybe<Scalars['String']['input']>
    cardNumber_not_contains_i?: InputMaybe<Scalars['String']['input']>
    cardNumber_not_ends_with?: InputMaybe<Scalars['String']['input']>
    cardNumber_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    cardNumber_not_i?: InputMaybe<Scalars['String']['input']>
    cardNumber_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    cardNumber_not_starts_with?: InputMaybe<Scalars['String']['input']>
    cardNumber_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    cardNumber_starts_with?: InputMaybe<Scalars['String']['input']>
    cardNumber_starts_with_i?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    currencyCode?: InputMaybe<MultiPaymentCurrencyCodeType>
    currencyCode_in?: InputMaybe<Array<InputMaybe<MultiPaymentCurrencyCodeType>>>
    currencyCode_not?: InputMaybe<MultiPaymentCurrencyCodeType>
    currencyCode_not_in?: InputMaybe<Array<InputMaybe<MultiPaymentCurrencyCodeType>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    explicitFee?: InputMaybe<Scalars['String']['input']>
    explicitFee_gt?: InputMaybe<Scalars['String']['input']>
    explicitFee_gte?: InputMaybe<Scalars['String']['input']>
    explicitFee_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    explicitFee_lt?: InputMaybe<Scalars['String']['input']>
    explicitFee_lte?: InputMaybe<Scalars['String']['input']>
    explicitFee_not?: InputMaybe<Scalars['String']['input']>
    explicitFee_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    explicitServiceCharge?: InputMaybe<Scalars['String']['input']>
    explicitServiceCharge_gt?: InputMaybe<Scalars['String']['input']>
    explicitServiceCharge_gte?: InputMaybe<Scalars['String']['input']>
    explicitServiceCharge_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    explicitServiceCharge_lt?: InputMaybe<Scalars['String']['input']>
    explicitServiceCharge_lte?: InputMaybe<Scalars['String']['input']>
    explicitServiceCharge_not?: InputMaybe<Scalars['String']['input']>
    explicitServiceCharge_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    implicitFee?: InputMaybe<Scalars['String']['input']>
    implicitFee_gt?: InputMaybe<Scalars['String']['input']>
    implicitFee_gte?: InputMaybe<Scalars['String']['input']>
    implicitFee_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    implicitFee_lt?: InputMaybe<Scalars['String']['input']>
    implicitFee_lte?: InputMaybe<Scalars['String']['input']>
    implicitFee_not?: InputMaybe<Scalars['String']['input']>
    implicitFee_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId?: InputMaybe<Scalars['String']['input']>
    importId_contains?: InputMaybe<Scalars['String']['input']>
    importId_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_i?: InputMaybe<Scalars['String']['input']>
    importId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not?: InputMaybe<Scalars['String']['input']>
    importId_not_contains?: InputMaybe<Scalars['String']['input']>
    importId_not_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_not_i?: InputMaybe<Scalars['String']['input']>
    importId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importId_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_starts_with_i?: InputMaybe<Scalars['String']['input']>
    integration?: InputMaybe<AcquiringIntegrationWhereInput>
    integration_is_null?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    payerEmail?: InputMaybe<Scalars['String']['input']>
    payerEmail_contains?: InputMaybe<Scalars['String']['input']>
    payerEmail_contains_i?: InputMaybe<Scalars['String']['input']>
    payerEmail_ends_with?: InputMaybe<Scalars['String']['input']>
    payerEmail_ends_with_i?: InputMaybe<Scalars['String']['input']>
    payerEmail_i?: InputMaybe<Scalars['String']['input']>
    payerEmail_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    payerEmail_not?: InputMaybe<Scalars['String']['input']>
    payerEmail_not_contains?: InputMaybe<Scalars['String']['input']>
    payerEmail_not_contains_i?: InputMaybe<Scalars['String']['input']>
    payerEmail_not_ends_with?: InputMaybe<Scalars['String']['input']>
    payerEmail_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    payerEmail_not_i?: InputMaybe<Scalars['String']['input']>
    payerEmail_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    payerEmail_not_starts_with?: InputMaybe<Scalars['String']['input']>
    payerEmail_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    payerEmail_starts_with?: InputMaybe<Scalars['String']['input']>
    payerEmail_starts_with_i?: InputMaybe<Scalars['String']['input']>
    paymentWay?: InputMaybe<MultiPaymentPaymentWayType>
    paymentWay_in?: InputMaybe<Array<InputMaybe<MultiPaymentPaymentWayType>>>
    paymentWay_not?: InputMaybe<MultiPaymentPaymentWayType>
    paymentWay_not_in?: InputMaybe<Array<InputMaybe<MultiPaymentPaymentWayType>>>
    /**  condition must be true for all nodes  */
    payments_every?: InputMaybe<PaymentWhereInput>
    /**  condition must be false for all nodes  */
    payments_none?: InputMaybe<PaymentWhereInput>
    /**  condition must be true for at least 1 node  */
    payments_some?: InputMaybe<PaymentWhereInput>
    recurrentPaymentContext?: InputMaybe<RecurrentPaymentContextWhereInput>
    recurrentPaymentContext_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    serviceCategory?: InputMaybe<Scalars['String']['input']>
    serviceCategory_contains?: InputMaybe<Scalars['String']['input']>
    serviceCategory_contains_i?: InputMaybe<Scalars['String']['input']>
    serviceCategory_ends_with?: InputMaybe<Scalars['String']['input']>
    serviceCategory_ends_with_i?: InputMaybe<Scalars['String']['input']>
    serviceCategory_i?: InputMaybe<Scalars['String']['input']>
    serviceCategory_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    serviceCategory_not?: InputMaybe<Scalars['String']['input']>
    serviceCategory_not_contains?: InputMaybe<Scalars['String']['input']>
    serviceCategory_not_contains_i?: InputMaybe<Scalars['String']['input']>
    serviceCategory_not_ends_with?: InputMaybe<Scalars['String']['input']>
    serviceCategory_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    serviceCategory_not_i?: InputMaybe<Scalars['String']['input']>
    serviceCategory_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    serviceCategory_not_starts_with?: InputMaybe<Scalars['String']['input']>
    serviceCategory_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    serviceCategory_starts_with?: InputMaybe<Scalars['String']['input']>
    serviceCategory_starts_with_i?: InputMaybe<Scalars['String']['input']>
    serviceFee?: InputMaybe<Scalars['String']['input']>
    serviceFee_gt?: InputMaybe<Scalars['String']['input']>
    serviceFee_gte?: InputMaybe<Scalars['String']['input']>
    serviceFee_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    serviceFee_lt?: InputMaybe<Scalars['String']['input']>
    serviceFee_lte?: InputMaybe<Scalars['String']['input']>
    serviceFee_not?: InputMaybe<Scalars['String']['input']>
    serviceFee_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status?: InputMaybe<MultiPaymentStatusType>
    status_in?: InputMaybe<Array<InputMaybe<MultiPaymentStatusType>>>
    status_not?: InputMaybe<MultiPaymentStatusType>
    status_not_in?: InputMaybe<Array<InputMaybe<MultiPaymentStatusType>>>
    transactionId?: InputMaybe<Scalars['String']['input']>
    transactionId_contains?: InputMaybe<Scalars['String']['input']>
    transactionId_contains_i?: InputMaybe<Scalars['String']['input']>
    transactionId_ends_with?: InputMaybe<Scalars['String']['input']>
    transactionId_ends_with_i?: InputMaybe<Scalars['String']['input']>
    transactionId_i?: InputMaybe<Scalars['String']['input']>
    transactionId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    transactionId_not?: InputMaybe<Scalars['String']['input']>
    transactionId_not_contains?: InputMaybe<Scalars['String']['input']>
    transactionId_not_contains_i?: InputMaybe<Scalars['String']['input']>
    transactionId_not_ends_with?: InputMaybe<Scalars['String']['input']>
    transactionId_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    transactionId_not_i?: InputMaybe<Scalars['String']['input']>
    transactionId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    transactionId_not_starts_with?: InputMaybe<Scalars['String']['input']>
    transactionId_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    transactionId_starts_with?: InputMaybe<Scalars['String']['input']>
    transactionId_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    user?: InputMaybe<UserWhereInput>
    user_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    withdrawnAt?: InputMaybe<Scalars['String']['input']>
    withdrawnAt_gt?: InputMaybe<Scalars['String']['input']>
    withdrawnAt_gte?: InputMaybe<Scalars['String']['input']>
    withdrawnAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    withdrawnAt_lt?: InputMaybe<Scalars['String']['input']>
    withdrawnAt_lte?: InputMaybe<Scalars['String']['input']>
    withdrawnAt_not?: InputMaybe<Scalars['String']['input']>
    withdrawnAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type MultiPaymentWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type MultiPaymentsCreateInput = {
    data?: InputMaybe<MultiPaymentCreateInput>
}

export type MultiPaymentsUpdateInput = {
    data?: InputMaybe<MultiPaymentUpdateInput>
    id: Scalars['ID']['input']
}

export type Mutation = {
    __typename?: 'Mutation'
    /**
   * This mutation deletes meters and meter readings in specified organization.
   *
   * This mutation deletes meters in specified organization for specified period.
   *  Readings are deleted automatically in a deferred task.
   *  You can also specify properties in which meters need to be deleted.
   *  The response will return the status of the operation: “success” if all meters for the specified filter were deleted, otherwise “error”.
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "DV_VERSION_MISMATCH",
   *   "message": "Wrong value for data version number"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "WRONG_FORMAT",
   *   "message": "Invalid format of \"sender\" field value",
   *   "correctExample": "{ \"dv\": 1, \"fingerprint\": \"uniq-device-or-container-id\" }"
   * }`
   */
    _internalDeleteMeterAndMeterReadings?: Maybe<_InternalDeleteMeterAndMeterReadingsOutput>
    /**
   * This mutation deletes readings in specified organization for specified period.
   *
   * This mutation deletes readings in specified organization for specified period.
   *  You can also specify properties in which readings need to be deleted.
   *  The response will return the status of the operation: “success” if all readings for the specified filter were deleted, otherwise “error”.
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "DV_VERSION_MISMATCH",
   *   "message": "Wrong value for data version number"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "WRONG_FORMAT",
   *   "message": "Invalid format of \"sender\" field value",
   *   "correctExample": "{ \"dv\": 1, \"fingerprint\": \"uniq-device-or-container-id\" }"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "INVALID_START_DATE_TIME",
   *   "message": "Invalid startDateTime. Should be in format DD.MM.YYYY HH:mm:ss"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "INVALID_END_DATE_TIME",
   *   "message": "invalid endDateTime. Should be in format DD.MM.YYYY HH:mm:ss"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "INVALID_PERIOD",
   *   "message": "The start date cannot be greater than the end date"
   * }`
   */
    _internalDeleteMeterReadings?: Maybe<_InternalDeleteMeterReadingsOutput>
    _internalScheduleTaskByName?: Maybe<_InternalScheduleTaskByNameOutput>
    _internalSendHashedResidentPhones?: Maybe<_InternalSendHashedResidentPhonesOutput>
    _internalSendNotificationNewMobileAppVersion?: Maybe<_InternalSendNotificationNewMobileAppVersionOutput>
    _internalSyncContactsWithResidentsForOrganization?: Maybe<Array<_InternalSyncContactsWithResidentsForOrganizationOutputRow>>
    acceptOrRejectOrganizationInviteByCode?: Maybe<OrganizationEmployee>
    acceptOrRejectOrganizationInviteById?: Maybe<OrganizationEmployee>
    /**  Authenticate and generate a token for a User with the Password Authentication Strategy.  */
    authenticateUserWithPassword?: Maybe<AuthenticateUserOutput>
    authenticateUserWithPhoneAndPassword?: Maybe<AuthenticateUserWithPhoneAndPasswordOutput>
    /**
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "WRONG_PASSWORD_FORMAT",
   *   "message": "Password must be in string format",
   *   "messageForUser": "Password must be in string format"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "INVALID_PASSWORD_LENGTH",
   *   "message": "Password length must be between 8 and 128 characters",
   *   "messageForUser": "Password length must be between 8 and 128 characters"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "PASSWORD_CONTAINS_EMAIL",
   *   "message": "Password must not contain email",
   *   "messageForUser": "Password must not contain email"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "PASSWORD_CONTAINS_PHONE",
   *   "message": "Password must not contain phone",
   *   "messageForUser": "Password must not contain phone"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "PASSWORD_IS_FREQUENTLY_USED",
   *   "message": "The password is too simple. We found it in the list of stolen passwords. You need to use something more secure",
   *   "messageForUser": "This password is weak or too obvious please choose something more secure"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "PASSWORD_CONSISTS_OF_SMALL_SET_OF_CHARACTERS",
   *   "message": "Password must contain at least 4 different characters",
   *   "messageForUser": "Password must contain at least 4 different characters"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "TOKEN_NOT_FOUND",
   *   "message": "Unable to find non-expired ConfirmPhoneAction by specified token",
   *   "messageForUser": "Unable to find non-expired ConfirmPhoneAction by specified token"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "USER_NOT_FOUND",
   *   "message": "Unable to find user with specified phone",
   *   "messageForUser": "Unable to find user with specified phone"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "DV_VERSION_MISMATCH",
   *   "message": "Wrong value for data version number"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "WRONG_FORMAT",
   *   "message": "Invalid format of \"sender\" field value",
   *   "correctExample": "{ \"dv\": 1, \"fingerprint\": \"uniq-device-or-container-id\" }"
   * }`
   */
    changePasswordWithToken?: Maybe<ChangePasswordWithTokenOutput>
    /**
   * Changes a phone of a resident, that corresponds to confirmed phone number, specified via token
   *
   *
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "DV_VERSION_MISMATCH",
   *   "message": "Wrong value for data version number"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "WRONG_FORMAT",
   *   "message": "Invalid format of \"sender\" field value. Please, check the example for details",
   *   "correctExample": "{ \"dv\": 1, \"fingerprint\": \"uniq-device-or-container-id\" }"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "NOT_FOUND",
   *   "message": "Unable to find a non-expired confirm phone action, that corresponds to provided token",
   *   "messageForUser": "Could not find token to change phone or it was expired. Try to start phone changing procedure from scratch."
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "WRONG_VALUE",
   *   "message": "Unable to change phone number since user has external identity and phone number are different",
   *   "messageForUser": "Unable to change phone number since user has authorization with different phone number."
   * }`
   */
    changePhoneNumberResidentUser?: Maybe<ChangePhoneNumberResidentUserOutput>
    /**
   * The final step of a phone number confirmation. You should use the token from startConfirmPhoneAction and a secret code from the confirm phone SMS message. After success call, you can use the token in other mutations to prove that you have access to the phone number
   *
   *
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "CAPTCHA_CHECK_FAILED",
   *   "message": "Failed to check CAPTCHA",
   *   "messageForUser": "The operation could not be completed. Please update the application"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "UNABLE_TO_FIND_CONFIRM_PHONE_ACTION",
   *   "message": "Confirm phone action was expired or it could not be found. Try to initiate phone confirmation again",
   *   "messageForUser": "Unable to verify your phone number. Try again or ask our support"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "SMS_CODE_EXPIRED",
   *   "message": "SMS code expired. Try to initiate phone confirmation again",
   *   "messageForUser": "SMS code expired, try to verify phone number again"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "SMS_CODE_MAX_RETRIES_REACHED",
   *   "message": "Max retries reached for SMS code confirmation. Try to initiate phone confirmation again",
   *   "messageForUser": "Max retries reached for SMS code confirmation. Try to initiate phone confirmation again"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "SMS_CODE_VERIFICATION_FAILED",
   *   "message": "SMS code verification mismatch",
   *   "messageForUser": "SMS code verification mismatch"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "TOO_MANY_REQUESTS",
   *   "message": "You have to wait 5000 seconds to be able to send request again",
   *   "messageForUser": "You have to wait 5000 sec. to be able to send request again"
   * }`
   */
    completeConfirmPhoneAction?: Maybe<CompleteConfirmPhoneActionOutput>
    /**  Create a single AcquiringIntegration item.  */
    createAcquiringIntegration?: Maybe<AcquiringIntegration>
    /**  Create a single AcquiringIntegrationAccessRight item.  */
    createAcquiringIntegrationAccessRight?: Maybe<AcquiringIntegrationAccessRight>
    /**  Create a single AcquiringIntegrationAccessRightHistoryRecord item.  */
    createAcquiringIntegrationAccessRightHistoryRecord?: Maybe<AcquiringIntegrationAccessRightHistoryRecord>
    /**  Create multiple AcquiringIntegrationAccessRightHistoryRecord items.  */
    createAcquiringIntegrationAccessRightHistoryRecords?: Maybe<Array<Maybe<AcquiringIntegrationAccessRightHistoryRecord>>>
    /**  Create multiple AcquiringIntegrationAccessRight items.  */
    createAcquiringIntegrationAccessRights?: Maybe<Array<Maybe<AcquiringIntegrationAccessRight>>>
    /**  Create a single AcquiringIntegrationContext item.  */
    createAcquiringIntegrationContext?: Maybe<AcquiringIntegrationContext>
    /**  Create a single AcquiringIntegrationContextHistoryRecord item.  */
    createAcquiringIntegrationContextHistoryRecord?: Maybe<AcquiringIntegrationContextHistoryRecord>
    /**  Create multiple AcquiringIntegrationContextHistoryRecord items.  */
    createAcquiringIntegrationContextHistoryRecords?: Maybe<Array<Maybe<AcquiringIntegrationContextHistoryRecord>>>
    /**  Create multiple AcquiringIntegrationContext items.  */
    createAcquiringIntegrationContexts?: Maybe<Array<Maybe<AcquiringIntegrationContext>>>
    /**  Create a single AcquiringIntegrationHistoryRecord item.  */
    createAcquiringIntegrationHistoryRecord?: Maybe<AcquiringIntegrationHistoryRecord>
    /**  Create multiple AcquiringIntegrationHistoryRecord items.  */
    createAcquiringIntegrationHistoryRecords?: Maybe<Array<Maybe<AcquiringIntegrationHistoryRecord>>>
    /**  Create multiple AcquiringIntegration items.  */
    createAcquiringIntegrations?: Maybe<Array<Maybe<AcquiringIntegration>>>
    /**  Create a single B2BApp item.  */
    createB2BApp?: Maybe<B2BApp>
    /**  Create a single B2BAppAccessRight item.  */
    createB2BAppAccessRight?: Maybe<B2BAppAccessRight>
    /**  Create a single B2BAppAccessRightHistoryRecord item.  */
    createB2BAppAccessRightHistoryRecord?: Maybe<B2BAppAccessRightHistoryRecord>
    /**  Create multiple B2BAppAccessRightHistoryRecord items.  */
    createB2BAppAccessRightHistoryRecords?: Maybe<Array<Maybe<B2BAppAccessRightHistoryRecord>>>
    /**  Create a single B2BAppAccessRightSet item.  */
    createB2BAppAccessRightSet?: Maybe<B2BAppAccessRightSet>
    /**  Create a single B2BAppAccessRightSetHistoryRecord item.  */
    createB2BAppAccessRightSetHistoryRecord?: Maybe<B2BAppAccessRightSetHistoryRecord>
    /**  Create multiple B2BAppAccessRightSetHistoryRecord items.  */
    createB2BAppAccessRightSetHistoryRecords?: Maybe<Array<Maybe<B2BAppAccessRightSetHistoryRecord>>>
    /**  Create multiple B2BAppAccessRightSet items.  */
    createB2BAppAccessRightSets?: Maybe<Array<Maybe<B2BAppAccessRightSet>>>
    /**  Create multiple B2BAppAccessRight items.  */
    createB2BAppAccessRights?: Maybe<Array<Maybe<B2BAppAccessRight>>>
    /**  Create a single B2BAppContext item.  */
    createB2BAppContext?: Maybe<B2BAppContext>
    /**  Create a single B2BAppContextHistoryRecord item.  */
    createB2BAppContextHistoryRecord?: Maybe<B2BAppContextHistoryRecord>
    /**  Create multiple B2BAppContextHistoryRecord items.  */
    createB2BAppContextHistoryRecords?: Maybe<Array<Maybe<B2BAppContextHistoryRecord>>>
    /**  Create multiple B2BAppContext items.  */
    createB2BAppContexts?: Maybe<Array<Maybe<B2BAppContext>>>
    /**  Create a single B2BAppHistoryRecord item.  */
    createB2BAppHistoryRecord?: Maybe<B2BAppHistoryRecord>
    /**  Create multiple B2BAppHistoryRecord items.  */
    createB2BAppHistoryRecords?: Maybe<Array<Maybe<B2BAppHistoryRecord>>>
    /**  Create a single B2BAppNewsSharingConfig item.  */
    createB2BAppNewsSharingConfig?: Maybe<B2BAppNewsSharingConfig>
    /**  Create a single B2BAppNewsSharingConfigHistoryRecord item.  */
    createB2BAppNewsSharingConfigHistoryRecord?: Maybe<B2BAppNewsSharingConfigHistoryRecord>
    /**  Create multiple B2BAppNewsSharingConfigHistoryRecord items.  */
    createB2BAppNewsSharingConfigHistoryRecords?: Maybe<Array<Maybe<B2BAppNewsSharingConfigHistoryRecord>>>
    /**  Create multiple B2BAppNewsSharingConfig items.  */
    createB2BAppNewsSharingConfigs?: Maybe<Array<Maybe<B2BAppNewsSharingConfig>>>
    /**  Create a single B2BAppPermission item.  */
    createB2BAppPermission?: Maybe<B2BAppPermission>
    /**  Create a single B2BAppPermissionHistoryRecord item.  */
    createB2BAppPermissionHistoryRecord?: Maybe<B2BAppPermissionHistoryRecord>
    /**  Create multiple B2BAppPermissionHistoryRecord items.  */
    createB2BAppPermissionHistoryRecords?: Maybe<Array<Maybe<B2BAppPermissionHistoryRecord>>>
    /**  Create multiple B2BAppPermission items.  */
    createB2BAppPermissions?: Maybe<Array<Maybe<B2BAppPermission>>>
    /**  Create a single B2BAppPromoBlock item.  */
    createB2BAppPromoBlock?: Maybe<B2BAppPromoBlock>
    /**  Create a single B2BAppPromoBlockHistoryRecord item.  */
    createB2BAppPromoBlockHistoryRecord?: Maybe<B2BAppPromoBlockHistoryRecord>
    /**  Create multiple B2BAppPromoBlockHistoryRecord items.  */
    createB2BAppPromoBlockHistoryRecords?: Maybe<Array<Maybe<B2BAppPromoBlockHistoryRecord>>>
    /**  Create multiple B2BAppPromoBlock items.  */
    createB2BAppPromoBlocks?: Maybe<Array<Maybe<B2BAppPromoBlock>>>
    /**  Create a single B2BAppRole item.  */
    createB2BAppRole?: Maybe<B2BAppRole>
    /**  Create a single B2BAppRoleHistoryRecord item.  */
    createB2BAppRoleHistoryRecord?: Maybe<B2BAppRoleHistoryRecord>
    /**  Create multiple B2BAppRoleHistoryRecord items.  */
    createB2BAppRoleHistoryRecords?: Maybe<Array<Maybe<B2BAppRoleHistoryRecord>>>
    /**  Create multiple B2BAppRole items.  */
    createB2BAppRoles?: Maybe<Array<Maybe<B2BAppRole>>>
    /**  Create multiple B2BApp items.  */
    createB2BApps?: Maybe<Array<Maybe<B2BApp>>>
    /**  Create a single B2CApp item.  */
    createB2CApp?: Maybe<B2CApp>
    /**  Create a single B2CAppAccessRight item.  */
    createB2CAppAccessRight?: Maybe<B2CAppAccessRight>
    /**  Create a single B2CAppAccessRightHistoryRecord item.  */
    createB2CAppAccessRightHistoryRecord?: Maybe<B2CAppAccessRightHistoryRecord>
    /**  Create multiple B2CAppAccessRightHistoryRecord items.  */
    createB2CAppAccessRightHistoryRecords?: Maybe<Array<Maybe<B2CAppAccessRightHistoryRecord>>>
    /**  Create multiple B2CAppAccessRight items.  */
    createB2CAppAccessRights?: Maybe<Array<Maybe<B2CAppAccessRight>>>
    /**  Create a single B2CAppBuild item.  */
    createB2CAppBuild?: Maybe<B2CAppBuild>
    /**  Create a single B2CAppBuildHistoryRecord item.  */
    createB2CAppBuildHistoryRecord?: Maybe<B2CAppBuildHistoryRecord>
    /**  Create multiple B2CAppBuildHistoryRecord items.  */
    createB2CAppBuildHistoryRecords?: Maybe<Array<Maybe<B2CAppBuildHistoryRecord>>>
    /**  Create multiple B2CAppBuild items.  */
    createB2CAppBuilds?: Maybe<Array<Maybe<B2CAppBuild>>>
    /**  Create a single B2CAppHistoryRecord item.  */
    createB2CAppHistoryRecord?: Maybe<B2CAppHistoryRecord>
    /**  Create multiple B2CAppHistoryRecord items.  */
    createB2CAppHistoryRecords?: Maybe<Array<Maybe<B2CAppHistoryRecord>>>
    /**  Create a single B2CAppMessageSetting item.  */
    createB2CAppMessageSetting?: Maybe<B2CAppMessageSetting>
    /**  Create a single B2CAppMessageSettingHistoryRecord item.  */
    createB2CAppMessageSettingHistoryRecord?: Maybe<B2CAppMessageSettingHistoryRecord>
    /**  Create multiple B2CAppMessageSettingHistoryRecord items.  */
    createB2CAppMessageSettingHistoryRecords?: Maybe<Array<Maybe<B2CAppMessageSettingHistoryRecord>>>
    /**  Create multiple B2CAppMessageSetting items.  */
    createB2CAppMessageSettings?: Maybe<Array<Maybe<B2CAppMessageSetting>>>
    /**  Create multiple B2CAppProperty items.  */
    createB2CAppProperties?: Maybe<Array<Maybe<B2CAppProperty>>>
    /**  Create a single B2CAppProperty item.  */
    createB2CAppProperty?: Maybe<B2CAppProperty>
    /**  Create a single B2CAppPropertyHistoryRecord item.  */
    createB2CAppPropertyHistoryRecord?: Maybe<B2CAppPropertyHistoryRecord>
    /**  Create multiple B2CAppPropertyHistoryRecord items.  */
    createB2CAppPropertyHistoryRecords?: Maybe<Array<Maybe<B2CAppPropertyHistoryRecord>>>
    /**  Create multiple B2CApp items.  */
    createB2CApps?: Maybe<Array<Maybe<B2CApp>>>
    /**  Create a single BankAccount item.  */
    createBankAccount?: Maybe<BankAccount>
    /**  Create a single BankAccountHistoryRecord item.  */
    createBankAccountHistoryRecord?: Maybe<BankAccountHistoryRecord>
    /**  Create multiple BankAccountHistoryRecord items.  */
    createBankAccountHistoryRecords?: Maybe<Array<Maybe<BankAccountHistoryRecord>>>
    /**  Create a single BankAccountReport item.  */
    createBankAccountReport?: Maybe<BankAccountReport>
    /**  Create a single BankAccountReportHistoryRecord item.  */
    createBankAccountReportHistoryRecord?: Maybe<BankAccountReportHistoryRecord>
    /**  Create multiple BankAccountReportHistoryRecord items.  */
    createBankAccountReportHistoryRecords?: Maybe<Array<Maybe<BankAccountReportHistoryRecord>>>
    /**  Create a single BankAccountReportTask item.  */
    createBankAccountReportTask?: Maybe<BankAccountReportTask>
    /**  Create a single BankAccountReportTaskHistoryRecord item.  */
    createBankAccountReportTaskHistoryRecord?: Maybe<BankAccountReportTaskHistoryRecord>
    /**  Create multiple BankAccountReportTaskHistoryRecord items.  */
    createBankAccountReportTaskHistoryRecords?: Maybe<Array<Maybe<BankAccountReportTaskHistoryRecord>>>
    /**  Create multiple BankAccountReportTask items.  */
    createBankAccountReportTasks?: Maybe<Array<Maybe<BankAccountReportTask>>>
    /**  Create multiple BankAccountReport items.  */
    createBankAccountReports?: Maybe<Array<Maybe<BankAccountReport>>>
    createBankAccountRequest?: Maybe<CreateBankAccountRequestOutput>
    /**  Create multiple BankAccount items.  */
    createBankAccounts?: Maybe<Array<Maybe<BankAccount>>>
    /**  Create multiple BankCategory items.  */
    createBankCategories?: Maybe<Array<Maybe<BankCategory>>>
    /**  Create a single BankCategory item.  */
    createBankCategory?: Maybe<BankCategory>
    /**  Create a single BankCategoryHistoryRecord item.  */
    createBankCategoryHistoryRecord?: Maybe<BankCategoryHistoryRecord>
    /**  Create multiple BankCategoryHistoryRecord items.  */
    createBankCategoryHistoryRecords?: Maybe<Array<Maybe<BankCategoryHistoryRecord>>>
    /**  Create a single BankContractorAccount item.  */
    createBankContractorAccount?: Maybe<BankContractorAccount>
    /**  Create a single BankContractorAccountHistoryRecord item.  */
    createBankContractorAccountHistoryRecord?: Maybe<BankContractorAccountHistoryRecord>
    /**  Create multiple BankContractorAccountHistoryRecord items.  */
    createBankContractorAccountHistoryRecords?: Maybe<Array<Maybe<BankContractorAccountHistoryRecord>>>
    /**  Create multiple BankContractorAccount items.  */
    createBankContractorAccounts?: Maybe<Array<Maybe<BankContractorAccount>>>
    /**  Create a single BankCostItem item.  */
    createBankCostItem?: Maybe<BankCostItem>
    /**  Create a single BankCostItemHistoryRecord item.  */
    createBankCostItemHistoryRecord?: Maybe<BankCostItemHistoryRecord>
    /**  Create multiple BankCostItemHistoryRecord items.  */
    createBankCostItemHistoryRecords?: Maybe<Array<Maybe<BankCostItemHistoryRecord>>>
    /**  Create multiple BankCostItem items.  */
    createBankCostItems?: Maybe<Array<Maybe<BankCostItem>>>
    /**  Create a single BankIntegration item.  */
    createBankIntegration?: Maybe<BankIntegration>
    /**  Create a single BankIntegrationAccessRight item.  */
    createBankIntegrationAccessRight?: Maybe<BankIntegrationAccessRight>
    /**  Create a single BankIntegrationAccessRightHistoryRecord item.  */
    createBankIntegrationAccessRightHistoryRecord?: Maybe<BankIntegrationAccessRightHistoryRecord>
    /**  Create multiple BankIntegrationAccessRightHistoryRecord items.  */
    createBankIntegrationAccessRightHistoryRecords?: Maybe<Array<Maybe<BankIntegrationAccessRightHistoryRecord>>>
    /**  Create multiple BankIntegrationAccessRight items.  */
    createBankIntegrationAccessRights?: Maybe<Array<Maybe<BankIntegrationAccessRight>>>
    /**  Create a single BankIntegrationAccountContext item.  */
    createBankIntegrationAccountContext?: Maybe<BankIntegrationAccountContext>
    /**  Create a single BankIntegrationAccountContextHistoryRecord item.  */
    createBankIntegrationAccountContextHistoryRecord?: Maybe<BankIntegrationAccountContextHistoryRecord>
    /**  Create multiple BankIntegrationAccountContextHistoryRecord items.  */
    createBankIntegrationAccountContextHistoryRecords?: Maybe<Array<Maybe<BankIntegrationAccountContextHistoryRecord>>>
    /**  Create multiple BankIntegrationAccountContext items.  */
    createBankIntegrationAccountContexts?: Maybe<Array<Maybe<BankIntegrationAccountContext>>>
    /**  Create a single BankIntegrationHistoryRecord item.  */
    createBankIntegrationHistoryRecord?: Maybe<BankIntegrationHistoryRecord>
    /**  Create multiple BankIntegrationHistoryRecord items.  */
    createBankIntegrationHistoryRecords?: Maybe<Array<Maybe<BankIntegrationHistoryRecord>>>
    /**  Create a single BankIntegrationOrganizationContext item.  */
    createBankIntegrationOrganizationContext?: Maybe<BankIntegrationOrganizationContext>
    /**  Create a single BankIntegrationOrganizationContextHistoryRecord item.  */
    createBankIntegrationOrganizationContextHistoryRecord?: Maybe<BankIntegrationOrganizationContextHistoryRecord>
    /**  Create multiple BankIntegrationOrganizationContextHistoryRecord items.  */
    createBankIntegrationOrganizationContextHistoryRecords?: Maybe<Array<Maybe<BankIntegrationOrganizationContextHistoryRecord>>>
    /**  Create multiple BankIntegrationOrganizationContext items.  */
    createBankIntegrationOrganizationContexts?: Maybe<Array<Maybe<BankIntegrationOrganizationContext>>>
    /**  Create multiple BankIntegration items.  */
    createBankIntegrations?: Maybe<Array<Maybe<BankIntegration>>>
    /**  Create a single BankSyncTask item.  */
    createBankSyncTask?: Maybe<BankSyncTask>
    /**  Create a single BankSyncTaskHistoryRecord item.  */
    createBankSyncTaskHistoryRecord?: Maybe<BankSyncTaskHistoryRecord>
    /**  Create multiple BankSyncTaskHistoryRecord items.  */
    createBankSyncTaskHistoryRecords?: Maybe<Array<Maybe<BankSyncTaskHistoryRecord>>>
    /**  Create multiple BankSyncTask items.  */
    createBankSyncTasks?: Maybe<Array<Maybe<BankSyncTask>>>
    /**  Create a single BankTransaction item.  */
    createBankTransaction?: Maybe<BankTransaction>
    /**  Create a single BankTransactionHistoryRecord item.  */
    createBankTransactionHistoryRecord?: Maybe<BankTransactionHistoryRecord>
    /**  Create multiple BankTransactionHistoryRecord items.  */
    createBankTransactionHistoryRecords?: Maybe<Array<Maybe<BankTransactionHistoryRecord>>>
    /**  Create multiple BankTransaction items.  */
    createBankTransactions?: Maybe<Array<Maybe<BankTransaction>>>
    /**  Create a single BillingAccount item.  */
    createBillingAccount?: Maybe<BillingAccount>
    /**  Create a single BillingAccountHistoryRecord item.  */
    createBillingAccountHistoryRecord?: Maybe<BillingAccountHistoryRecord>
    /**  Create multiple BillingAccountHistoryRecord items.  */
    createBillingAccountHistoryRecords?: Maybe<Array<Maybe<BillingAccountHistoryRecord>>>
    /**  Create multiple BillingAccount items.  */
    createBillingAccounts?: Maybe<Array<Maybe<BillingAccount>>>
    /**  Create multiple BillingCategory items.  */
    createBillingCategories?: Maybe<Array<Maybe<BillingCategory>>>
    /**  Create a single BillingCategory item.  */
    createBillingCategory?: Maybe<BillingCategory>
    /**  Create a single BillingCategoryHistoryRecord item.  */
    createBillingCategoryHistoryRecord?: Maybe<BillingCategoryHistoryRecord>
    /**  Create multiple BillingCategoryHistoryRecord items.  */
    createBillingCategoryHistoryRecords?: Maybe<Array<Maybe<BillingCategoryHistoryRecord>>>
    /**  Create a single BillingIntegration item.  */
    createBillingIntegration?: Maybe<BillingIntegration>
    /**  Create a single BillingIntegrationAccessRight item.  */
    createBillingIntegrationAccessRight?: Maybe<BillingIntegrationAccessRight>
    /**  Create a single BillingIntegrationAccessRightHistoryRecord item.  */
    createBillingIntegrationAccessRightHistoryRecord?: Maybe<BillingIntegrationAccessRightHistoryRecord>
    /**  Create multiple BillingIntegrationAccessRightHistoryRecord items.  */
    createBillingIntegrationAccessRightHistoryRecords?: Maybe<Array<Maybe<BillingIntegrationAccessRightHistoryRecord>>>
    /**  Create multiple BillingIntegrationAccessRight items.  */
    createBillingIntegrationAccessRights?: Maybe<Array<Maybe<BillingIntegrationAccessRight>>>
    /**  Create a single BillingIntegrationHistoryRecord item.  */
    createBillingIntegrationHistoryRecord?: Maybe<BillingIntegrationHistoryRecord>
    /**  Create multiple BillingIntegrationHistoryRecord items.  */
    createBillingIntegrationHistoryRecords?: Maybe<Array<Maybe<BillingIntegrationHistoryRecord>>>
    /**  Create a single BillingIntegrationOrganizationContext item.  */
    createBillingIntegrationOrganizationContext?: Maybe<BillingIntegrationOrganizationContext>
    /**  Create a single BillingIntegrationOrganizationContextHistoryRecord item.  */
    createBillingIntegrationOrganizationContextHistoryRecord?: Maybe<BillingIntegrationOrganizationContextHistoryRecord>
    /**  Create multiple BillingIntegrationOrganizationContextHistoryRecord items.  */
    createBillingIntegrationOrganizationContextHistoryRecords?: Maybe<Array<Maybe<BillingIntegrationOrganizationContextHistoryRecord>>>
    /**  Create multiple BillingIntegrationOrganizationContext items.  */
    createBillingIntegrationOrganizationContexts?: Maybe<Array<Maybe<BillingIntegrationOrganizationContext>>>
    /**  Create a single BillingIntegrationProblem item.  */
    createBillingIntegrationProblem?: Maybe<BillingIntegrationProblem>
    /**  Create multiple BillingIntegrationProblem items.  */
    createBillingIntegrationProblems?: Maybe<Array<Maybe<BillingIntegrationProblem>>>
    /**  Create multiple BillingIntegration items.  */
    createBillingIntegrations?: Maybe<Array<Maybe<BillingIntegration>>>
    /**  Create multiple BillingProperty items.  */
    createBillingProperties?: Maybe<Array<Maybe<BillingProperty>>>
    /**  Create a single BillingProperty item.  */
    createBillingProperty?: Maybe<BillingProperty>
    /**  Create a single BillingPropertyHistoryRecord item.  */
    createBillingPropertyHistoryRecord?: Maybe<BillingPropertyHistoryRecord>
    /**  Create multiple BillingPropertyHistoryRecord items.  */
    createBillingPropertyHistoryRecords?: Maybe<Array<Maybe<BillingPropertyHistoryRecord>>>
    /**  Create a single BillingReceipt item.  */
    createBillingReceipt?: Maybe<BillingReceipt>
    /**  Create a single BillingReceiptFile item.  */
    createBillingReceiptFile?: Maybe<BillingReceiptFile>
    /**  Create a single BillingReceiptFileHistoryRecord item.  */
    createBillingReceiptFileHistoryRecord?: Maybe<BillingReceiptFileHistoryRecord>
    /**  Create multiple BillingReceiptFileHistoryRecord items.  */
    createBillingReceiptFileHistoryRecords?: Maybe<Array<Maybe<BillingReceiptFileHistoryRecord>>>
    /**  Create multiple BillingReceiptFile items.  */
    createBillingReceiptFiles?: Maybe<Array<Maybe<BillingReceiptFile>>>
    /**  Create a single BillingReceiptHistoryRecord item.  */
    createBillingReceiptHistoryRecord?: Maybe<BillingReceiptHistoryRecord>
    /**  Create multiple BillingReceiptHistoryRecord items.  */
    createBillingReceiptHistoryRecords?: Maybe<Array<Maybe<BillingReceiptHistoryRecord>>>
    /**  Create multiple BillingReceipt items.  */
    createBillingReceipts?: Maybe<Array<Maybe<BillingReceipt>>>
    /**  Create a single BillingRecipient item.  */
    createBillingRecipient?: Maybe<BillingRecipient>
    /**  Create a single BillingRecipientHistoryRecord item.  */
    createBillingRecipientHistoryRecord?: Maybe<BillingRecipientHistoryRecord>
    /**  Create multiple BillingRecipientHistoryRecord items.  */
    createBillingRecipientHistoryRecords?: Maybe<Array<Maybe<BillingRecipientHistoryRecord>>>
    /**  Create multiple BillingRecipient items.  */
    createBillingRecipients?: Maybe<Array<Maybe<BillingRecipient>>>
    /**  Create a single CallRecord item.  */
    createCallRecord?: Maybe<CallRecord>
    /**  Create a single CallRecordFragment item.  */
    createCallRecordFragment?: Maybe<CallRecordFragment>
    /**  Create a single CallRecordFragmentHistoryRecord item.  */
    createCallRecordFragmentHistoryRecord?: Maybe<CallRecordFragmentHistoryRecord>
    /**  Create multiple CallRecordFragmentHistoryRecord items.  */
    createCallRecordFragmentHistoryRecords?: Maybe<Array<Maybe<CallRecordFragmentHistoryRecord>>>
    /**  Create multiple CallRecordFragment items.  */
    createCallRecordFragments?: Maybe<Array<Maybe<CallRecordFragment>>>
    /**  Create a single CallRecordHistoryRecord item.  */
    createCallRecordHistoryRecord?: Maybe<CallRecordHistoryRecord>
    /**  Create multiple CallRecordHistoryRecord items.  */
    createCallRecordHistoryRecords?: Maybe<Array<Maybe<CallRecordHistoryRecord>>>
    /**  Create multiple CallRecord items.  */
    createCallRecords?: Maybe<Array<Maybe<CallRecord>>>
    /**  Create a single ConfirmPhoneAction item.  */
    createConfirmPhoneAction?: Maybe<ConfirmPhoneAction>
    /**  Create a single ConfirmPhoneActionHistoryRecord item.  */
    createConfirmPhoneActionHistoryRecord?: Maybe<ConfirmPhoneActionHistoryRecord>
    /**  Create multiple ConfirmPhoneActionHistoryRecord items.  */
    createConfirmPhoneActionHistoryRecords?: Maybe<Array<Maybe<ConfirmPhoneActionHistoryRecord>>>
    /**  Create multiple ConfirmPhoneAction items.  */
    createConfirmPhoneActions?: Maybe<Array<Maybe<ConfirmPhoneAction>>>
    /**  Create a single Contact item.  */
    createContact?: Maybe<Contact>
    /**  Create a single ContactExportTask item.  */
    createContactExportTask?: Maybe<ContactExportTask>
    /**  Create a single ContactExportTaskHistoryRecord item.  */
    createContactExportTaskHistoryRecord?: Maybe<ContactExportTaskHistoryRecord>
    /**  Create multiple ContactExportTaskHistoryRecord items.  */
    createContactExportTaskHistoryRecords?: Maybe<Array<Maybe<ContactExportTaskHistoryRecord>>>
    /**  Create multiple ContactExportTask items.  */
    createContactExportTasks?: Maybe<Array<Maybe<ContactExportTask>>>
    /**  Create a single ContactHistoryRecord item.  */
    createContactHistoryRecord?: Maybe<ContactHistoryRecord>
    /**  Create multiple ContactHistoryRecord items.  */
    createContactHistoryRecords?: Maybe<Array<Maybe<ContactHistoryRecord>>>
    /**  Create a single ContactRole item.  */
    createContactRole?: Maybe<ContactRole>
    /**  Create a single ContactRoleHistoryRecord item.  */
    createContactRoleHistoryRecord?: Maybe<ContactRoleHistoryRecord>
    /**  Create multiple ContactRoleHistoryRecord items.  */
    createContactRoleHistoryRecords?: Maybe<Array<Maybe<ContactRoleHistoryRecord>>>
    /**  Create multiple ContactRole items.  */
    createContactRoles?: Maybe<Array<Maybe<ContactRole>>>
    /**  Create multiple Contact items.  */
    createContacts?: Maybe<Array<Maybe<Contact>>>
    /**  Create a single Document item.  */
    createDocument?: Maybe<Document>
    /**  Create multiple DocumentCategory items.  */
    createDocumentCategories?: Maybe<Array<Maybe<DocumentCategory>>>
    /**  Create a single DocumentCategory item.  */
    createDocumentCategory?: Maybe<DocumentCategory>
    /**  Create a single DocumentCategoryHistoryRecord item.  */
    createDocumentCategoryHistoryRecord?: Maybe<DocumentCategoryHistoryRecord>
    /**  Create multiple DocumentCategoryHistoryRecord items.  */
    createDocumentCategoryHistoryRecords?: Maybe<Array<Maybe<DocumentCategoryHistoryRecord>>>
    /**  Create a single DocumentHistoryRecord item.  */
    createDocumentHistoryRecord?: Maybe<DocumentHistoryRecord>
    /**  Create multiple DocumentHistoryRecord items.  */
    createDocumentHistoryRecords?: Maybe<Array<Maybe<DocumentHistoryRecord>>>
    /**  Create multiple Document items.  */
    createDocuments?: Maybe<Array<Maybe<Document>>>
    /**  Create a single ExternalTokenAccessRight item.  */
    createExternalTokenAccessRight?: Maybe<ExternalTokenAccessRight>
    /**  Create a single ExternalTokenAccessRightHistoryRecord item.  */
    createExternalTokenAccessRightHistoryRecord?: Maybe<ExternalTokenAccessRightHistoryRecord>
    /**  Create multiple ExternalTokenAccessRightHistoryRecord items.  */
    createExternalTokenAccessRightHistoryRecords?: Maybe<Array<Maybe<ExternalTokenAccessRightHistoryRecord>>>
    /**  Create multiple ExternalTokenAccessRight items.  */
    createExternalTokenAccessRights?: Maybe<Array<Maybe<ExternalTokenAccessRight>>>
    /**  Create a single Incident item.  */
    createIncident?: Maybe<Incident>
    /**  Create a single IncidentChange item.  */
    createIncidentChange?: Maybe<IncidentChange>
    /**  Create multiple IncidentChange items.  */
    createIncidentChanges?: Maybe<Array<Maybe<IncidentChange>>>
    /**  Create a single IncidentClassifier item.  */
    createIncidentClassifier?: Maybe<IncidentClassifier>
    /**  Create a single IncidentClassifierHistoryRecord item.  */
    createIncidentClassifierHistoryRecord?: Maybe<IncidentClassifierHistoryRecord>
    /**  Create multiple IncidentClassifierHistoryRecord items.  */
    createIncidentClassifierHistoryRecords?: Maybe<Array<Maybe<IncidentClassifierHistoryRecord>>>
    /**  Create a single IncidentClassifierIncident item.  */
    createIncidentClassifierIncident?: Maybe<IncidentClassifierIncident>
    /**  Create a single IncidentClassifierIncidentHistoryRecord item.  */
    createIncidentClassifierIncidentHistoryRecord?: Maybe<IncidentClassifierIncidentHistoryRecord>
    /**  Create multiple IncidentClassifierIncidentHistoryRecord items.  */
    createIncidentClassifierIncidentHistoryRecords?: Maybe<Array<Maybe<IncidentClassifierIncidentHistoryRecord>>>
    /**  Create multiple IncidentClassifierIncident items.  */
    createIncidentClassifierIncidents?: Maybe<Array<Maybe<IncidentClassifierIncident>>>
    /**  Create multiple IncidentClassifier items.  */
    createIncidentClassifiers?: Maybe<Array<Maybe<IncidentClassifier>>>
    /**  Create a single IncidentExportTask item.  */
    createIncidentExportTask?: Maybe<IncidentExportTask>
    /**  Create a single IncidentExportTaskHistoryRecord item.  */
    createIncidentExportTaskHistoryRecord?: Maybe<IncidentExportTaskHistoryRecord>
    /**  Create multiple IncidentExportTaskHistoryRecord items.  */
    createIncidentExportTaskHistoryRecords?: Maybe<Array<Maybe<IncidentExportTaskHistoryRecord>>>
    /**  Create multiple IncidentExportTask items.  */
    createIncidentExportTasks?: Maybe<Array<Maybe<IncidentExportTask>>>
    /**  Create a single IncidentHistoryRecord item.  */
    createIncidentHistoryRecord?: Maybe<IncidentHistoryRecord>
    /**  Create multiple IncidentHistoryRecord items.  */
    createIncidentHistoryRecords?: Maybe<Array<Maybe<IncidentHistoryRecord>>>
    /**  Create multiple IncidentProperty items.  */
    createIncidentProperties?: Maybe<Array<Maybe<IncidentProperty>>>
    /**  Create a single IncidentProperty item.  */
    createIncidentProperty?: Maybe<IncidentProperty>
    /**  Create a single IncidentPropertyHistoryRecord item.  */
    createIncidentPropertyHistoryRecord?: Maybe<IncidentPropertyHistoryRecord>
    /**  Create multiple IncidentPropertyHistoryRecord items.  */
    createIncidentPropertyHistoryRecords?: Maybe<Array<Maybe<IncidentPropertyHistoryRecord>>>
    /**  Create multiple Incident items.  */
    createIncidents?: Maybe<Array<Maybe<Incident>>>
    /**  Create a single Invoice item.  */
    createInvoice?: Maybe<Invoice>
    /**  Create a single InvoiceHistoryRecord item.  */
    createInvoiceHistoryRecord?: Maybe<InvoiceHistoryRecord>
    /**  Create multiple InvoiceHistoryRecord items.  */
    createInvoiceHistoryRecords?: Maybe<Array<Maybe<InvoiceHistoryRecord>>>
    /**  Create multiple Invoice items.  */
    createInvoices?: Maybe<Array<Maybe<Invoice>>>
    /**  Create multiple MarketCategory items.  */
    createMarketCategories?: Maybe<Array<Maybe<MarketCategory>>>
    /**  Create a single MarketCategory item.  */
    createMarketCategory?: Maybe<MarketCategory>
    /**  Create a single MarketCategoryHistoryRecord item.  */
    createMarketCategoryHistoryRecord?: Maybe<MarketCategoryHistoryRecord>
    /**  Create multiple MarketCategoryHistoryRecord items.  */
    createMarketCategoryHistoryRecords?: Maybe<Array<Maybe<MarketCategoryHistoryRecord>>>
    /**  Create a single MarketItem item.  */
    createMarketItem?: Maybe<MarketItem>
    /**  Create a single MarketItemFile item.  */
    createMarketItemFile?: Maybe<MarketItemFile>
    /**  Create a single MarketItemFileHistoryRecord item.  */
    createMarketItemFileHistoryRecord?: Maybe<MarketItemFileHistoryRecord>
    /**  Create multiple MarketItemFileHistoryRecord items.  */
    createMarketItemFileHistoryRecords?: Maybe<Array<Maybe<MarketItemFileHistoryRecord>>>
    /**  Create multiple MarketItemFile items.  */
    createMarketItemFiles?: Maybe<Array<Maybe<MarketItemFile>>>
    /**  Create a single MarketItemHistoryRecord item.  */
    createMarketItemHistoryRecord?: Maybe<MarketItemHistoryRecord>
    /**  Create multiple MarketItemHistoryRecord items.  */
    createMarketItemHistoryRecords?: Maybe<Array<Maybe<MarketItemHistoryRecord>>>
    /**  Create a single MarketItemPrice item.  */
    createMarketItemPrice?: Maybe<MarketItemPrice>
    /**  Create a single MarketItemPriceHistoryRecord item.  */
    createMarketItemPriceHistoryRecord?: Maybe<MarketItemPriceHistoryRecord>
    /**  Create multiple MarketItemPriceHistoryRecord items.  */
    createMarketItemPriceHistoryRecords?: Maybe<Array<Maybe<MarketItemPriceHistoryRecord>>>
    /**  Create multiple MarketItemPrice items.  */
    createMarketItemPrices?: Maybe<Array<Maybe<MarketItemPrice>>>
    /**  Create multiple MarketItem items.  */
    createMarketItems?: Maybe<Array<Maybe<MarketItem>>>
    /**  Create a single MarketPriceScope item.  */
    createMarketPriceScope?: Maybe<MarketPriceScope>
    /**  Create a single MarketPriceScopeHistoryRecord item.  */
    createMarketPriceScopeHistoryRecord?: Maybe<MarketPriceScopeHistoryRecord>
    /**  Create multiple MarketPriceScopeHistoryRecord items.  */
    createMarketPriceScopeHistoryRecords?: Maybe<Array<Maybe<MarketPriceScopeHistoryRecord>>>
    /**  Create multiple MarketPriceScope items.  */
    createMarketPriceScopes?: Maybe<Array<Maybe<MarketPriceScope>>>
    /**  Create a single MarketSetting item.  */
    createMarketSetting?: Maybe<MarketSetting>
    /**  Create a single MarketSettingHistoryRecord item.  */
    createMarketSettingHistoryRecord?: Maybe<MarketSettingHistoryRecord>
    /**  Create multiple MarketSettingHistoryRecord items.  */
    createMarketSettingHistoryRecords?: Maybe<Array<Maybe<MarketSettingHistoryRecord>>>
    /**  Create multiple MarketSetting items.  */
    createMarketSettings?: Maybe<Array<Maybe<MarketSetting>>>
    /**  Create a single Message item.  */
    createMessage?: Maybe<Message>
    /**  Create a single MessageAppBlackList item.  */
    createMessageAppBlackList?: Maybe<MessageAppBlackList>
    /**  Create a single MessageAppBlackListHistoryRecord item.  */
    createMessageAppBlackListHistoryRecord?: Maybe<MessageAppBlackListHistoryRecord>
    /**  Create multiple MessageAppBlackListHistoryRecord items.  */
    createMessageAppBlackListHistoryRecords?: Maybe<Array<Maybe<MessageAppBlackListHistoryRecord>>>
    /**  Create multiple MessageAppBlackList items.  */
    createMessageAppBlackLists?: Maybe<Array<Maybe<MessageAppBlackList>>>
    /**  Create a single MessageBatch item.  */
    createMessageBatch?: Maybe<MessageBatch>
    /**  Create a single MessageBatchHistoryRecord item.  */
    createMessageBatchHistoryRecord?: Maybe<MessageBatchHistoryRecord>
    /**  Create multiple MessageBatchHistoryRecord items.  */
    createMessageBatchHistoryRecords?: Maybe<Array<Maybe<MessageBatchHistoryRecord>>>
    /**  Create multiple MessageBatch items.  */
    createMessageBatches?: Maybe<Array<Maybe<MessageBatch>>>
    /**  Create a single MessageHistoryRecord item.  */
    createMessageHistoryRecord?: Maybe<MessageHistoryRecord>
    /**  Create multiple MessageHistoryRecord items.  */
    createMessageHistoryRecords?: Maybe<Array<Maybe<MessageHistoryRecord>>>
    /**  Create a single MessageOrganizationBlackList item.  */
    createMessageOrganizationBlackList?: Maybe<MessageOrganizationBlackList>
    /**  Create a single MessageOrganizationBlackListHistoryRecord item.  */
    createMessageOrganizationBlackListHistoryRecord?: Maybe<MessageOrganizationBlackListHistoryRecord>
    /**  Create multiple MessageOrganizationBlackListHistoryRecord items.  */
    createMessageOrganizationBlackListHistoryRecords?: Maybe<Array<Maybe<MessageOrganizationBlackListHistoryRecord>>>
    /**  Create multiple MessageOrganizationBlackList items.  */
    createMessageOrganizationBlackLists?: Maybe<Array<Maybe<MessageOrganizationBlackList>>>
    /**  Create a single MessageUserBlackList item.  */
    createMessageUserBlackList?: Maybe<MessageUserBlackList>
    /**  Create a single MessageUserBlackListHistoryRecord item.  */
    createMessageUserBlackListHistoryRecord?: Maybe<MessageUserBlackListHistoryRecord>
    /**  Create multiple MessageUserBlackListHistoryRecord items.  */
    createMessageUserBlackListHistoryRecords?: Maybe<Array<Maybe<MessageUserBlackListHistoryRecord>>>
    /**  Create multiple MessageUserBlackList items.  */
    createMessageUserBlackLists?: Maybe<Array<Maybe<MessageUserBlackList>>>
    /**  Create multiple Message items.  */
    createMessages?: Maybe<Array<Maybe<Message>>>
    /**  Create a single Meter item.  */
    createMeter?: Maybe<Meter>
    /**  Create a single MeterHistoryRecord item.  */
    createMeterHistoryRecord?: Maybe<MeterHistoryRecord>
    /**  Create multiple MeterHistoryRecord items.  */
    createMeterHistoryRecords?: Maybe<Array<Maybe<MeterHistoryRecord>>>
    /**  Create a single MeterReading item.  */
    createMeterReading?: Maybe<MeterReading>
    /**  Create a single MeterReadingExportTask item.  */
    createMeterReadingExportTask?: Maybe<MeterReadingExportTask>
    /**  Create a single MeterReadingExportTaskHistoryRecord item.  */
    createMeterReadingExportTaskHistoryRecord?: Maybe<MeterReadingExportTaskHistoryRecord>
    /**  Create multiple MeterReadingExportTaskHistoryRecord items.  */
    createMeterReadingExportTaskHistoryRecords?: Maybe<Array<Maybe<MeterReadingExportTaskHistoryRecord>>>
    /**  Create multiple MeterReadingExportTask items.  */
    createMeterReadingExportTasks?: Maybe<Array<Maybe<MeterReadingExportTask>>>
    /**  Create a single MeterReadingFilterTemplate item.  */
    createMeterReadingFilterTemplate?: Maybe<MeterReadingFilterTemplate>
    /**  Create a single MeterReadingFilterTemplateHistoryRecord item.  */
    createMeterReadingFilterTemplateHistoryRecord?: Maybe<MeterReadingFilterTemplateHistoryRecord>
    /**  Create multiple MeterReadingFilterTemplateHistoryRecord items.  */
    createMeterReadingFilterTemplateHistoryRecords?: Maybe<Array<Maybe<MeterReadingFilterTemplateHistoryRecord>>>
    /**  Create multiple MeterReadingFilterTemplate items.  */
    createMeterReadingFilterTemplates?: Maybe<Array<Maybe<MeterReadingFilterTemplate>>>
    /**  Create a single MeterReadingHistoryRecord item.  */
    createMeterReadingHistoryRecord?: Maybe<MeterReadingHistoryRecord>
    /**  Create multiple MeterReadingHistoryRecord items.  */
    createMeterReadingHistoryRecords?: Maybe<Array<Maybe<MeterReadingHistoryRecord>>>
    /**  Create a single MeterReadingSource item.  */
    createMeterReadingSource?: Maybe<MeterReadingSource>
    /**  Create a single MeterReadingSourceHistoryRecord item.  */
    createMeterReadingSourceHistoryRecord?: Maybe<MeterReadingSourceHistoryRecord>
    /**  Create multiple MeterReadingSourceHistoryRecord items.  */
    createMeterReadingSourceHistoryRecords?: Maybe<Array<Maybe<MeterReadingSourceHistoryRecord>>>
    /**  Create multiple MeterReadingSource items.  */
    createMeterReadingSources?: Maybe<Array<Maybe<MeterReadingSource>>>
    /**  Create multiple MeterReading items.  */
    createMeterReadings?: Maybe<Array<Maybe<MeterReading>>>
    /**  Create a single MeterReadingsImportTask item.  */
    createMeterReadingsImportTask?: Maybe<MeterReadingsImportTask>
    /**  Create a single MeterReadingsImportTaskHistoryRecord item.  */
    createMeterReadingsImportTaskHistoryRecord?: Maybe<MeterReadingsImportTaskHistoryRecord>
    /**  Create multiple MeterReadingsImportTaskHistoryRecord items.  */
    createMeterReadingsImportTaskHistoryRecords?: Maybe<Array<Maybe<MeterReadingsImportTaskHistoryRecord>>>
    /**  Create multiple MeterReadingsImportTask items.  */
    createMeterReadingsImportTasks?: Maybe<Array<Maybe<MeterReadingsImportTask>>>
    /**  Create a single MeterReportingPeriod item.  */
    createMeterReportingPeriod?: Maybe<MeterReportingPeriod>
    /**  Create a single MeterReportingPeriodHistoryRecord item.  */
    createMeterReportingPeriodHistoryRecord?: Maybe<MeterReportingPeriodHistoryRecord>
    /**  Create multiple MeterReportingPeriodHistoryRecord items.  */
    createMeterReportingPeriodHistoryRecords?: Maybe<Array<Maybe<MeterReportingPeriodHistoryRecord>>>
    /**  Create multiple MeterReportingPeriod items.  */
    createMeterReportingPeriods?: Maybe<Array<Maybe<MeterReportingPeriod>>>
    /**  Create a single MeterResource item.  */
    createMeterResource?: Maybe<MeterResource>
    /**  Create a single MeterResourceHistoryRecord item.  */
    createMeterResourceHistoryRecord?: Maybe<MeterResourceHistoryRecord>
    /**  Create multiple MeterResourceHistoryRecord items.  */
    createMeterResourceHistoryRecords?: Maybe<Array<Maybe<MeterResourceHistoryRecord>>>
    /**  Create a single MeterResourceOwner item.  */
    createMeterResourceOwner?: Maybe<MeterResourceOwner>
    /**  Create a single MeterResourceOwnerHistoryRecord item.  */
    createMeterResourceOwnerHistoryRecord?: Maybe<MeterResourceOwnerHistoryRecord>
    /**  Create multiple MeterResourceOwnerHistoryRecord items.  */
    createMeterResourceOwnerHistoryRecords?: Maybe<Array<Maybe<MeterResourceOwnerHistoryRecord>>>
    /**  Create multiple MeterResourceOwner items.  */
    createMeterResourceOwners?: Maybe<Array<Maybe<MeterResourceOwner>>>
    /**  Create multiple MeterResource items.  */
    createMeterResources?: Maybe<Array<Maybe<MeterResource>>>
    /**  Create multiple Meter items.  */
    createMeters?: Maybe<Array<Maybe<Meter>>>
    /**  Create a single MobileFeatureConfig item.  */
    createMobileFeatureConfig?: Maybe<MobileFeatureConfig>
    /**  Create a single MobileFeatureConfigHistoryRecord item.  */
    createMobileFeatureConfigHistoryRecord?: Maybe<MobileFeatureConfigHistoryRecord>
    /**  Create multiple MobileFeatureConfigHistoryRecord items.  */
    createMobileFeatureConfigHistoryRecords?: Maybe<Array<Maybe<MobileFeatureConfigHistoryRecord>>>
    /**  Create multiple MobileFeatureConfig items.  */
    createMobileFeatureConfigs?: Maybe<Array<Maybe<MobileFeatureConfig>>>
    /**  Create a single MultiPayment item.  */
    createMultiPayment?: Maybe<MultiPayment>
    /**  Create a single MultiPaymentHistoryRecord item.  */
    createMultiPaymentHistoryRecord?: Maybe<MultiPaymentHistoryRecord>
    /**  Create multiple MultiPaymentHistoryRecord items.  */
    createMultiPaymentHistoryRecords?: Maybe<Array<Maybe<MultiPaymentHistoryRecord>>>
    /**  Create multiple MultiPayment items.  */
    createMultiPayments?: Maybe<Array<Maybe<MultiPayment>>>
    /**  Create a single NewsItem item.  */
    createNewsItem?: Maybe<NewsItem>
    /**  Create a single NewsItemHistoryRecord item.  */
    createNewsItemHistoryRecord?: Maybe<NewsItemHistoryRecord>
    /**  Create multiple NewsItemHistoryRecord items.  */
    createNewsItemHistoryRecords?: Maybe<Array<Maybe<NewsItemHistoryRecord>>>
    /**  Create a single NewsItemRecipientsExportTask item.  */
    createNewsItemRecipientsExportTask?: Maybe<NewsItemRecipientsExportTask>
    /**  Create a single NewsItemRecipientsExportTaskHistoryRecord item.  */
    createNewsItemRecipientsExportTaskHistoryRecord?: Maybe<NewsItemRecipientsExportTaskHistoryRecord>
    /**  Create multiple NewsItemRecipientsExportTaskHistoryRecord items.  */
    createNewsItemRecipientsExportTaskHistoryRecords?: Maybe<Array<Maybe<NewsItemRecipientsExportTaskHistoryRecord>>>
    /**  Create multiple NewsItemRecipientsExportTask items.  */
    createNewsItemRecipientsExportTasks?: Maybe<Array<Maybe<NewsItemRecipientsExportTask>>>
    /**  Create a single NewsItemScope item.  */
    createNewsItemScope?: Maybe<NewsItemScope>
    /**  Create a single NewsItemScopeHistoryRecord item.  */
    createNewsItemScopeHistoryRecord?: Maybe<NewsItemScopeHistoryRecord>
    /**  Create multiple NewsItemScopeHistoryRecord items.  */
    createNewsItemScopeHistoryRecords?: Maybe<Array<Maybe<NewsItemScopeHistoryRecord>>>
    /**  Create multiple NewsItemScope items.  */
    createNewsItemScopes?: Maybe<Array<Maybe<NewsItemScope>>>
    /**  Create a single NewsItemSharing item.  */
    createNewsItemSharing?: Maybe<NewsItemSharing>
    /**  Create a single NewsItemSharingHistoryRecord item.  */
    createNewsItemSharingHistoryRecord?: Maybe<NewsItemSharingHistoryRecord>
    /**  Create multiple NewsItemSharingHistoryRecord items.  */
    createNewsItemSharingHistoryRecords?: Maybe<Array<Maybe<NewsItemSharingHistoryRecord>>>
    /**  Create multiple NewsItemSharing items.  */
    createNewsItemSharings?: Maybe<Array<Maybe<NewsItemSharing>>>
    /**  Create a single NewsItemTemplate item.  */
    createNewsItemTemplate?: Maybe<NewsItemTemplate>
    /**  Create a single NewsItemTemplateHistoryRecord item.  */
    createNewsItemTemplateHistoryRecord?: Maybe<NewsItemTemplateHistoryRecord>
    /**  Create multiple NewsItemTemplateHistoryRecord items.  */
    createNewsItemTemplateHistoryRecords?: Maybe<Array<Maybe<NewsItemTemplateHistoryRecord>>>
    /**  Create multiple NewsItemTemplate items.  */
    createNewsItemTemplates?: Maybe<Array<Maybe<NewsItemTemplate>>>
    /**  Create a single NewsItemUserRead item.  */
    createNewsItemUserRead?: Maybe<NewsItemUserRead>
    /**  Create a single NewsItemUserReadHistoryRecord item.  */
    createNewsItemUserReadHistoryRecord?: Maybe<NewsItemUserReadHistoryRecord>
    /**  Create multiple NewsItemUserReadHistoryRecord items.  */
    createNewsItemUserReadHistoryRecords?: Maybe<Array<Maybe<NewsItemUserReadHistoryRecord>>>
    /**  Create multiple NewsItemUserRead items.  */
    createNewsItemUserReads?: Maybe<Array<Maybe<NewsItemUserRead>>>
    /**  Create multiple NewsItem items.  */
    createNewsItems?: Maybe<Array<Maybe<NewsItem>>>
    /**  Create a single NotificationAnonymousSetting item.  */
    createNotificationAnonymousSetting?: Maybe<NotificationAnonymousSetting>
    /**  Create a single NotificationAnonymousSettingHistoryRecord item.  */
    createNotificationAnonymousSettingHistoryRecord?: Maybe<NotificationAnonymousSettingHistoryRecord>
    /**  Create multiple NotificationAnonymousSettingHistoryRecord items.  */
    createNotificationAnonymousSettingHistoryRecords?: Maybe<Array<Maybe<NotificationAnonymousSettingHistoryRecord>>>
    /**  Create multiple NotificationAnonymousSetting items.  */
    createNotificationAnonymousSettings?: Maybe<Array<Maybe<NotificationAnonymousSetting>>>
    /**  Create a single NotificationUserSetting item.  */
    createNotificationUserSetting?: Maybe<NotificationUserSetting>
    /**  Create a single NotificationUserSettingHistoryRecord item.  */
    createNotificationUserSettingHistoryRecord?: Maybe<NotificationUserSettingHistoryRecord>
    /**  Create multiple NotificationUserSettingHistoryRecord items.  */
    createNotificationUserSettingHistoryRecords?: Maybe<Array<Maybe<NotificationUserSettingHistoryRecord>>>
    /**  Create multiple NotificationUserSetting items.  */
    createNotificationUserSettings?: Maybe<Array<Maybe<NotificationUserSetting>>>
    /**  Create a single OidcClient item.  */
    createOidcClient?: Maybe<OidcClient>
    /**  Create a single OidcClientHistoryRecord item.  */
    createOidcClientHistoryRecord?: Maybe<OidcClientHistoryRecord>
    /**  Create multiple OidcClientHistoryRecord items.  */
    createOidcClientHistoryRecords?: Maybe<Array<Maybe<OidcClientHistoryRecord>>>
    /**  Create multiple OidcClient items.  */
    createOidcClients?: Maybe<Array<Maybe<OidcClient>>>
    /**  Create a single Organization item.  */
    createOrganization?: Maybe<Organization>
    /**  Create a single OrganizationEmployee item.  */
    createOrganizationEmployee?: Maybe<OrganizationEmployee>
    /**  Create a single OrganizationEmployeeHistoryRecord item.  */
    createOrganizationEmployeeHistoryRecord?: Maybe<OrganizationEmployeeHistoryRecord>
    /**  Create multiple OrganizationEmployeeHistoryRecord items.  */
    createOrganizationEmployeeHistoryRecords?: Maybe<Array<Maybe<OrganizationEmployeeHistoryRecord>>>
    /**  Create a single OrganizationEmployeeRole item.  */
    createOrganizationEmployeeRole?: Maybe<OrganizationEmployeeRole>
    /**  Create a single OrganizationEmployeeRoleHistoryRecord item.  */
    createOrganizationEmployeeRoleHistoryRecord?: Maybe<OrganizationEmployeeRoleHistoryRecord>
    /**  Create multiple OrganizationEmployeeRoleHistoryRecord items.  */
    createOrganizationEmployeeRoleHistoryRecords?: Maybe<Array<Maybe<OrganizationEmployeeRoleHistoryRecord>>>
    /**  Create multiple OrganizationEmployeeRole items.  */
    createOrganizationEmployeeRoles?: Maybe<Array<Maybe<OrganizationEmployeeRole>>>
    /**  Create a single OrganizationEmployeeSpecialization item.  */
    createOrganizationEmployeeSpecialization?: Maybe<OrganizationEmployeeSpecialization>
    /**  Create a single OrganizationEmployeeSpecializationHistoryRecord item.  */
    createOrganizationEmployeeSpecializationHistoryRecord?: Maybe<OrganizationEmployeeSpecializationHistoryRecord>
    /**  Create multiple OrganizationEmployeeSpecializationHistoryRecord items.  */
    createOrganizationEmployeeSpecializationHistoryRecords?: Maybe<Array<Maybe<OrganizationEmployeeSpecializationHistoryRecord>>>
    /**  Create multiple OrganizationEmployeeSpecialization items.  */
    createOrganizationEmployeeSpecializations?: Maybe<Array<Maybe<OrganizationEmployeeSpecialization>>>
    /**  Create multiple OrganizationEmployee items.  */
    createOrganizationEmployees?: Maybe<Array<Maybe<OrganizationEmployee>>>
    /**  Create a single OrganizationHistoryRecord item.  */
    createOrganizationHistoryRecord?: Maybe<OrganizationHistoryRecord>
    /**  Create multiple OrganizationHistoryRecord items.  */
    createOrganizationHistoryRecords?: Maybe<Array<Maybe<OrganizationHistoryRecord>>>
    /**  Create a single OrganizationLink item.  */
    createOrganizationLink?: Maybe<OrganizationLink>
    /**  Create a single OrganizationLinkHistoryRecord item.  */
    createOrganizationLinkHistoryRecord?: Maybe<OrganizationLinkHistoryRecord>
    /**  Create multiple OrganizationLinkHistoryRecord items.  */
    createOrganizationLinkHistoryRecords?: Maybe<Array<Maybe<OrganizationLinkHistoryRecord>>>
    /**  Create multiple OrganizationLink items.  */
    createOrganizationLinks?: Maybe<Array<Maybe<OrganizationLink>>>
    /**  Create multiple Organization items.  */
    createOrganizations?: Maybe<Array<Maybe<Organization>>>
    /**  Create a single Payment item.  */
    createPayment?: Maybe<Payment>
    createPaymentByLink?: Maybe<CreatePaymentByLinkOutput>
    /**  Create a single PaymentHistoryRecord item.  */
    createPaymentHistoryRecord?: Maybe<PaymentHistoryRecord>
    /**  Create multiple PaymentHistoryRecord items.  */
    createPaymentHistoryRecords?: Maybe<Array<Maybe<PaymentHistoryRecord>>>
    /**  Create multiple Payment items.  */
    createPayments?: Maybe<Array<Maybe<Payment>>>
    /**  Create a single PaymentsFilterTemplate item.  */
    createPaymentsFilterTemplate?: Maybe<PaymentsFilterTemplate>
    /**  Create a single PaymentsFilterTemplateHistoryRecord item.  */
    createPaymentsFilterTemplateHistoryRecord?: Maybe<PaymentsFilterTemplateHistoryRecord>
    /**  Create multiple PaymentsFilterTemplateHistoryRecord items.  */
    createPaymentsFilterTemplateHistoryRecords?: Maybe<Array<Maybe<PaymentsFilterTemplateHistoryRecord>>>
    /**  Create multiple PaymentsFilterTemplate items.  */
    createPaymentsFilterTemplates?: Maybe<Array<Maybe<PaymentsFilterTemplate>>>
    /**  Create multiple Property items.  */
    createProperties?: Maybe<Array<Maybe<Property>>>
    /**  Create a single Property item.  */
    createProperty?: Maybe<Property>
    /**  Create a single PropertyHistoryRecord item.  */
    createPropertyHistoryRecord?: Maybe<PropertyHistoryRecord>
    /**  Create multiple PropertyHistoryRecord items.  */
    createPropertyHistoryRecords?: Maybe<Array<Maybe<PropertyHistoryRecord>>>
    /**  Create a single PropertyMeter item.  */
    createPropertyMeter?: Maybe<PropertyMeter>
    /**  Create a single PropertyMeterHistoryRecord item.  */
    createPropertyMeterHistoryRecord?: Maybe<PropertyMeterHistoryRecord>
    /**  Create multiple PropertyMeterHistoryRecord items.  */
    createPropertyMeterHistoryRecords?: Maybe<Array<Maybe<PropertyMeterHistoryRecord>>>
    /**  Create a single PropertyMeterReading item.  */
    createPropertyMeterReading?: Maybe<PropertyMeterReading>
    /**  Create a single PropertyMeterReadingHistoryRecord item.  */
    createPropertyMeterReadingHistoryRecord?: Maybe<PropertyMeterReadingHistoryRecord>
    /**  Create multiple PropertyMeterReadingHistoryRecord items.  */
    createPropertyMeterReadingHistoryRecords?: Maybe<Array<Maybe<PropertyMeterReadingHistoryRecord>>>
    /**  Create multiple PropertyMeterReading items.  */
    createPropertyMeterReadings?: Maybe<Array<Maybe<PropertyMeterReading>>>
    /**  Create multiple PropertyMeter items.  */
    createPropertyMeters?: Maybe<Array<Maybe<PropertyMeter>>>
    /**  Create a single PropertyScope item.  */
    createPropertyScope?: Maybe<PropertyScope>
    /**  Create a single PropertyScopeHistoryRecord item.  */
    createPropertyScopeHistoryRecord?: Maybe<PropertyScopeHistoryRecord>
    /**  Create multiple PropertyScopeHistoryRecord items.  */
    createPropertyScopeHistoryRecords?: Maybe<Array<Maybe<PropertyScopeHistoryRecord>>>
    /**  Create a single PropertyScopeOrganizationEmployee item.  */
    createPropertyScopeOrganizationEmployee?: Maybe<PropertyScopeOrganizationEmployee>
    /**  Create a single PropertyScopeOrganizationEmployeeHistoryRecord item.  */
    createPropertyScopeOrganizationEmployeeHistoryRecord?: Maybe<PropertyScopeOrganizationEmployeeHistoryRecord>
    /**  Create multiple PropertyScopeOrganizationEmployeeHistoryRecord items.  */
    createPropertyScopeOrganizationEmployeeHistoryRecords?: Maybe<Array<Maybe<PropertyScopeOrganizationEmployeeHistoryRecord>>>
    /**  Create multiple PropertyScopeOrganizationEmployee items.  */
    createPropertyScopeOrganizationEmployees?: Maybe<Array<Maybe<PropertyScopeOrganizationEmployee>>>
    /**  Create multiple PropertyScopeProperty items.  */
    createPropertyScopeProperties?: Maybe<Array<Maybe<PropertyScopeProperty>>>
    /**  Create a single PropertyScopeProperty item.  */
    createPropertyScopeProperty?: Maybe<PropertyScopeProperty>
    /**  Create a single PropertyScopePropertyHistoryRecord item.  */
    createPropertyScopePropertyHistoryRecord?: Maybe<PropertyScopePropertyHistoryRecord>
    /**  Create multiple PropertyScopePropertyHistoryRecord items.  */
    createPropertyScopePropertyHistoryRecords?: Maybe<Array<Maybe<PropertyScopePropertyHistoryRecord>>>
    /**  Create multiple PropertyScope items.  */
    createPropertyScopes?: Maybe<Array<Maybe<PropertyScope>>>
    /**  Create a single RecurrentPayment item.  */
    createRecurrentPayment?: Maybe<RecurrentPayment>
    /**  Create a single RecurrentPaymentContext item.  */
    createRecurrentPaymentContext?: Maybe<RecurrentPaymentContext>
    /**  Create a single RecurrentPaymentContextHistoryRecord item.  */
    createRecurrentPaymentContextHistoryRecord?: Maybe<RecurrentPaymentContextHistoryRecord>
    /**  Create multiple RecurrentPaymentContextHistoryRecord items.  */
    createRecurrentPaymentContextHistoryRecords?: Maybe<Array<Maybe<RecurrentPaymentContextHistoryRecord>>>
    /**  Create multiple RecurrentPaymentContext items.  */
    createRecurrentPaymentContexts?: Maybe<Array<Maybe<RecurrentPaymentContext>>>
    /**  Create a single RecurrentPaymentHistoryRecord item.  */
    createRecurrentPaymentHistoryRecord?: Maybe<RecurrentPaymentHistoryRecord>
    /**  Create multiple RecurrentPaymentHistoryRecord items.  */
    createRecurrentPaymentHistoryRecords?: Maybe<Array<Maybe<RecurrentPaymentHistoryRecord>>>
    /**  Create multiple RecurrentPayment items.  */
    createRecurrentPayments?: Maybe<Array<Maybe<RecurrentPayment>>>
    /**  Create a single RemoteClient item.  */
    createRemoteClient?: Maybe<RemoteClient>
    /**  Create a single RemoteClientHistoryRecord item.  */
    createRemoteClientHistoryRecord?: Maybe<RemoteClientHistoryRecord>
    /**  Create multiple RemoteClientHistoryRecord items.  */
    createRemoteClientHistoryRecords?: Maybe<Array<Maybe<RemoteClientHistoryRecord>>>
    /**  Create multiple RemoteClient items.  */
    createRemoteClients?: Maybe<Array<Maybe<RemoteClient>>>
    /**  Create a single Resident item.  */
    createResident?: Maybe<Resident>
    /**  Create a single ResidentHistoryRecord item.  */
    createResidentHistoryRecord?: Maybe<ResidentHistoryRecord>
    /**  Create multiple ResidentHistoryRecord items.  */
    createResidentHistoryRecords?: Maybe<Array<Maybe<ResidentHistoryRecord>>>
    /**  Create multiple Resident items.  */
    createResidents?: Maybe<Array<Maybe<Resident>>>
    /**  Create a single ServiceConsumer item.  */
    createServiceConsumer?: Maybe<ServiceConsumer>
    /**  Create a single ServiceConsumerHistoryRecord item.  */
    createServiceConsumerHistoryRecord?: Maybe<ServiceConsumerHistoryRecord>
    /**  Create multiple ServiceConsumerHistoryRecord items.  */
    createServiceConsumerHistoryRecords?: Maybe<Array<Maybe<ServiceConsumerHistoryRecord>>>
    /**  Create multiple ServiceConsumer items.  */
    createServiceConsumers?: Maybe<Array<Maybe<ServiceConsumer>>>
    /**  Create a single ServiceSubscription item.  */
    createServiceSubscription?: Maybe<ServiceSubscription>
    /**  Create a single ServiceSubscriptionHistoryRecord item.  */
    createServiceSubscriptionHistoryRecord?: Maybe<ServiceSubscriptionHistoryRecord>
    /**  Create multiple ServiceSubscriptionHistoryRecord items.  */
    createServiceSubscriptionHistoryRecords?: Maybe<Array<Maybe<ServiceSubscriptionHistoryRecord>>>
    /**  Create multiple ServiceSubscription items.  */
    createServiceSubscriptions?: Maybe<Array<Maybe<ServiceSubscription>>>
    /**  Create a single TelegramUserChat item.  */
    createTelegramUserChat?: Maybe<TelegramUserChat>
    /**  Create a single TelegramUserChatHistoryRecord item.  */
    createTelegramUserChatHistoryRecord?: Maybe<TelegramUserChatHistoryRecord>
    /**  Create multiple TelegramUserChatHistoryRecord items.  */
    createTelegramUserChatHistoryRecords?: Maybe<Array<Maybe<TelegramUserChatHistoryRecord>>>
    /**  Create multiple TelegramUserChat items.  */
    createTelegramUserChats?: Maybe<Array<Maybe<TelegramUserChat>>>
    /**  Create a single Ticket item.  */
    createTicket?: Maybe<Ticket>
    /**  Create a single TicketAutoAssignment item.  */
    createTicketAutoAssignment?: Maybe<TicketAutoAssignment>
    /**  Create a single TicketAutoAssignmentHistoryRecord item.  */
    createTicketAutoAssignmentHistoryRecord?: Maybe<TicketAutoAssignmentHistoryRecord>
    /**  Create multiple TicketAutoAssignmentHistoryRecord items.  */
    createTicketAutoAssignmentHistoryRecords?: Maybe<Array<Maybe<TicketAutoAssignmentHistoryRecord>>>
    /**  Create multiple TicketAutoAssignment items.  */
    createTicketAutoAssignments?: Maybe<Array<Maybe<TicketAutoAssignment>>>
    /**  Create a single TicketCategoryClassifier item.  */
    createTicketCategoryClassifier?: Maybe<TicketCategoryClassifier>
    /**  Create a single TicketCategoryClassifierHistoryRecord item.  */
    createTicketCategoryClassifierHistoryRecord?: Maybe<TicketCategoryClassifierHistoryRecord>
    /**  Create multiple TicketCategoryClassifierHistoryRecord items.  */
    createTicketCategoryClassifierHistoryRecords?: Maybe<Array<Maybe<TicketCategoryClassifierHistoryRecord>>>
    /**  Create multiple TicketCategoryClassifier items.  */
    createTicketCategoryClassifiers?: Maybe<Array<Maybe<TicketCategoryClassifier>>>
    /**  Create a single TicketChange item.  */
    createTicketChange?: Maybe<TicketChange>
    /**  Create multiple TicketChange items.  */
    createTicketChanges?: Maybe<Array<Maybe<TicketChange>>>
    /**  Create a single TicketClassifier item.  */
    createTicketClassifier?: Maybe<TicketClassifier>
    /**  Create a single TicketClassifierHistoryRecord item.  */
    createTicketClassifierHistoryRecord?: Maybe<TicketClassifierHistoryRecord>
    /**  Create multiple TicketClassifierHistoryRecord items.  */
    createTicketClassifierHistoryRecords?: Maybe<Array<Maybe<TicketClassifierHistoryRecord>>>
    /**  Create multiple TicketClassifier items.  */
    createTicketClassifiers?: Maybe<Array<Maybe<TicketClassifier>>>
    /**  Create a single TicketComment item.  */
    createTicketComment?: Maybe<TicketComment>
    /**  Create a single TicketCommentFile item.  */
    createTicketCommentFile?: Maybe<TicketCommentFile>
    /**  Create a single TicketCommentFileHistoryRecord item.  */
    createTicketCommentFileHistoryRecord?: Maybe<TicketCommentFileHistoryRecord>
    /**  Create multiple TicketCommentFileHistoryRecord items.  */
    createTicketCommentFileHistoryRecords?: Maybe<Array<Maybe<TicketCommentFileHistoryRecord>>>
    /**  Create multiple TicketCommentFile items.  */
    createTicketCommentFiles?: Maybe<Array<Maybe<TicketCommentFile>>>
    /**  Create a single TicketCommentHistoryRecord item.  */
    createTicketCommentHistoryRecord?: Maybe<TicketCommentHistoryRecord>
    /**  Create multiple TicketCommentHistoryRecord items.  */
    createTicketCommentHistoryRecords?: Maybe<Array<Maybe<TicketCommentHistoryRecord>>>
    /**  Create multiple TicketComment items.  */
    createTicketComments?: Maybe<Array<Maybe<TicketComment>>>
    /**  Create a single TicketDocumentGenerationTask item.  */
    createTicketDocumentGenerationTask?: Maybe<TicketDocumentGenerationTask>
    /**  Create a single TicketDocumentGenerationTaskHistoryRecord item.  */
    createTicketDocumentGenerationTaskHistoryRecord?: Maybe<TicketDocumentGenerationTaskHistoryRecord>
    /**  Create multiple TicketDocumentGenerationTaskHistoryRecord items.  */
    createTicketDocumentGenerationTaskHistoryRecords?: Maybe<Array<Maybe<TicketDocumentGenerationTaskHistoryRecord>>>
    /**  Create multiple TicketDocumentGenerationTask items.  */
    createTicketDocumentGenerationTasks?: Maybe<Array<Maybe<TicketDocumentGenerationTask>>>
    /**  Create a single TicketExportTask item.  */
    createTicketExportTask?: Maybe<TicketExportTask>
    /**  Create a single TicketExportTaskHistoryRecord item.  */
    createTicketExportTaskHistoryRecord?: Maybe<TicketExportTaskHistoryRecord>
    /**  Create multiple TicketExportTaskHistoryRecord items.  */
    createTicketExportTaskHistoryRecords?: Maybe<Array<Maybe<TicketExportTaskHistoryRecord>>>
    /**  Create multiple TicketExportTask items.  */
    createTicketExportTasks?: Maybe<Array<Maybe<TicketExportTask>>>
    /**  Create a single TicketFile item.  */
    createTicketFile?: Maybe<TicketFile>
    /**  Create a single TicketFileHistoryRecord item.  */
    createTicketFileHistoryRecord?: Maybe<TicketFileHistoryRecord>
    /**  Create multiple TicketFileHistoryRecord items.  */
    createTicketFileHistoryRecords?: Maybe<Array<Maybe<TicketFileHistoryRecord>>>
    /**  Create multiple TicketFile items.  */
    createTicketFiles?: Maybe<Array<Maybe<TicketFile>>>
    /**  Create a single TicketFilterTemplate item.  */
    createTicketFilterTemplate?: Maybe<TicketFilterTemplate>
    /**  Create a single TicketFilterTemplateHistoryRecord item.  */
    createTicketFilterTemplateHistoryRecord?: Maybe<TicketFilterTemplateHistoryRecord>
    /**  Create multiple TicketFilterTemplateHistoryRecord items.  */
    createTicketFilterTemplateHistoryRecords?: Maybe<Array<Maybe<TicketFilterTemplateHistoryRecord>>>
    /**  Create multiple TicketFilterTemplate items.  */
    createTicketFilterTemplates?: Maybe<Array<Maybe<TicketFilterTemplate>>>
    /**  Create a single TicketHistoryRecord item.  */
    createTicketHistoryRecord?: Maybe<TicketHistoryRecord>
    /**  Create multiple TicketHistoryRecord items.  */
    createTicketHistoryRecords?: Maybe<Array<Maybe<TicketHistoryRecord>>>
    /**  Create a single TicketOrganizationSetting item.  */
    createTicketOrganizationSetting?: Maybe<TicketOrganizationSetting>
    /**  Create a single TicketOrganizationSettingHistoryRecord item.  */
    createTicketOrganizationSettingHistoryRecord?: Maybe<TicketOrganizationSettingHistoryRecord>
    /**  Create multiple TicketOrganizationSettingHistoryRecord items.  */
    createTicketOrganizationSettingHistoryRecords?: Maybe<Array<Maybe<TicketOrganizationSettingHistoryRecord>>>
    /**  Create multiple TicketOrganizationSetting items.  */
    createTicketOrganizationSettings?: Maybe<Array<Maybe<TicketOrganizationSetting>>>
    /**  Create a single TicketPlaceClassifier item.  */
    createTicketPlaceClassifier?: Maybe<TicketPlaceClassifier>
    /**  Create a single TicketPlaceClassifierHistoryRecord item.  */
    createTicketPlaceClassifierHistoryRecord?: Maybe<TicketPlaceClassifierHistoryRecord>
    /**  Create multiple TicketPlaceClassifierHistoryRecord items.  */
    createTicketPlaceClassifierHistoryRecords?: Maybe<Array<Maybe<TicketPlaceClassifierHistoryRecord>>>
    /**  Create multiple TicketPlaceClassifier items.  */
    createTicketPlaceClassifiers?: Maybe<Array<Maybe<TicketPlaceClassifier>>>
    /**  Create a single TicketProblemClassifier item.  */
    createTicketProblemClassifier?: Maybe<TicketProblemClassifier>
    /**  Create a single TicketProblemClassifierHistoryRecord item.  */
    createTicketProblemClassifierHistoryRecord?: Maybe<TicketProblemClassifierHistoryRecord>
    /**  Create multiple TicketProblemClassifierHistoryRecord items.  */
    createTicketProblemClassifierHistoryRecords?: Maybe<Array<Maybe<TicketProblemClassifierHistoryRecord>>>
    /**  Create multiple TicketProblemClassifier items.  */
    createTicketProblemClassifiers?: Maybe<Array<Maybe<TicketProblemClassifier>>>
    /**  Create a single TicketPropertyHint item.  */
    createTicketPropertyHint?: Maybe<TicketPropertyHint>
    /**  Create a single TicketPropertyHintHistoryRecord item.  */
    createTicketPropertyHintHistoryRecord?: Maybe<TicketPropertyHintHistoryRecord>
    /**  Create multiple TicketPropertyHintHistoryRecord items.  */
    createTicketPropertyHintHistoryRecords?: Maybe<Array<Maybe<TicketPropertyHintHistoryRecord>>>
    /**  Create multiple TicketPropertyHintProperty items.  */
    createTicketPropertyHintProperties?: Maybe<Array<Maybe<TicketPropertyHintProperty>>>
    /**  Create a single TicketPropertyHintProperty item.  */
    createTicketPropertyHintProperty?: Maybe<TicketPropertyHintProperty>
    /**  Create a single TicketPropertyHintPropertyHistoryRecord item.  */
    createTicketPropertyHintPropertyHistoryRecord?: Maybe<TicketPropertyHintPropertyHistoryRecord>
    /**  Create multiple TicketPropertyHintPropertyHistoryRecord items.  */
    createTicketPropertyHintPropertyHistoryRecords?: Maybe<Array<Maybe<TicketPropertyHintPropertyHistoryRecord>>>
    /**  Create multiple TicketPropertyHint items.  */
    createTicketPropertyHints?: Maybe<Array<Maybe<TicketPropertyHint>>>
    /**  Create a single TicketSource item.  */
    createTicketSource?: Maybe<TicketSource>
    /**  Create a single TicketSourceHistoryRecord item.  */
    createTicketSourceHistoryRecord?: Maybe<TicketSourceHistoryRecord>
    /**  Create multiple TicketSourceHistoryRecord items.  */
    createTicketSourceHistoryRecords?: Maybe<Array<Maybe<TicketSourceHistoryRecord>>>
    /**  Create multiple TicketSource items.  */
    createTicketSources?: Maybe<Array<Maybe<TicketSource>>>
    /**  Create a single TicketStatus item.  */
    createTicketStatus?: Maybe<TicketStatus>
    /**  Create a single TicketStatusHistoryRecord item.  */
    createTicketStatusHistoryRecord?: Maybe<TicketStatusHistoryRecord>
    /**  Create multiple TicketStatusHistoryRecord items.  */
    createTicketStatusHistoryRecords?: Maybe<Array<Maybe<TicketStatusHistoryRecord>>>
    /**  Create multiple TicketStatus items.  */
    createTicketStatuses?: Maybe<Array<Maybe<TicketStatus>>>
    /**  Create multiple Ticket items.  */
    createTickets?: Maybe<Array<Maybe<Ticket>>>
    /**  Create a single TourStep item.  */
    createTourStep?: Maybe<TourStep>
    /**  Create a single TourStepHistoryRecord item.  */
    createTourStepHistoryRecord?: Maybe<TourStepHistoryRecord>
    /**  Create multiple TourStepHistoryRecord items.  */
    createTourStepHistoryRecords?: Maybe<Array<Maybe<TourStepHistoryRecord>>>
    /**  Create multiple TourStep items.  */
    createTourSteps?: Maybe<Array<Maybe<TourStep>>>
    /**  Create a single User item.  */
    createUser?: Maybe<User>
    /**  Create multiple UserExternalIdentity items.  */
    createUserExternalIdentities?: Maybe<Array<Maybe<UserExternalIdentity>>>
    /**  Create a single UserExternalIdentity item.  */
    createUserExternalIdentity?: Maybe<UserExternalIdentity>
    /**  Create a single UserExternalIdentityHistoryRecord item.  */
    createUserExternalIdentityHistoryRecord?: Maybe<UserExternalIdentityHistoryRecord>
    /**  Create multiple UserExternalIdentityHistoryRecord items.  */
    createUserExternalIdentityHistoryRecords?: Maybe<Array<Maybe<UserExternalIdentityHistoryRecord>>>
    /**  Create a single UserFavoriteTicket item.  */
    createUserFavoriteTicket?: Maybe<UserFavoriteTicket>
    /**  Create a single UserFavoriteTicketHistoryRecord item.  */
    createUserFavoriteTicketHistoryRecord?: Maybe<UserFavoriteTicketHistoryRecord>
    /**  Create multiple UserFavoriteTicketHistoryRecord items.  */
    createUserFavoriteTicketHistoryRecords?: Maybe<Array<Maybe<UserFavoriteTicketHistoryRecord>>>
    /**  Create multiple UserFavoriteTicket items.  */
    createUserFavoriteTickets?: Maybe<Array<Maybe<UserFavoriteTicket>>>
    /**  Create a single UserHelpRequest item.  */
    createUserHelpRequest?: Maybe<UserHelpRequest>
    /**  Create a single UserHelpRequestFile item.  */
    createUserHelpRequestFile?: Maybe<UserHelpRequestFile>
    /**  Create a single UserHelpRequestFileHistoryRecord item.  */
    createUserHelpRequestFileHistoryRecord?: Maybe<UserHelpRequestFileHistoryRecord>
    /**  Create multiple UserHelpRequestFileHistoryRecord items.  */
    createUserHelpRequestFileHistoryRecords?: Maybe<Array<Maybe<UserHelpRequestFileHistoryRecord>>>
    /**  Create multiple UserHelpRequestFile items.  */
    createUserHelpRequestFiles?: Maybe<Array<Maybe<UserHelpRequestFile>>>
    /**  Create a single UserHelpRequestHistoryRecord item.  */
    createUserHelpRequestHistoryRecord?: Maybe<UserHelpRequestHistoryRecord>
    /**  Create multiple UserHelpRequestHistoryRecord items.  */
    createUserHelpRequestHistoryRecords?: Maybe<Array<Maybe<UserHelpRequestHistoryRecord>>>
    /**  Create multiple UserHelpRequest items.  */
    createUserHelpRequests?: Maybe<Array<Maybe<UserHelpRequest>>>
    /**  Create a single UserHistoryRecord item.  */
    createUserHistoryRecord?: Maybe<UserHistoryRecord>
    /**  Create multiple UserHistoryRecord items.  */
    createUserHistoryRecords?: Maybe<Array<Maybe<UserHistoryRecord>>>
    /**  Create a single UserRightsSet item.  */
    createUserRightsSet?: Maybe<UserRightsSet>
    /**  Create a single UserRightsSetHistoryRecord item.  */
    createUserRightsSetHistoryRecord?: Maybe<UserRightsSetHistoryRecord>
    /**  Create multiple UserRightsSetHistoryRecord items.  */
    createUserRightsSetHistoryRecords?: Maybe<Array<Maybe<UserRightsSetHistoryRecord>>>
    /**  Create multiple UserRightsSet items.  */
    createUserRightsSets?: Maybe<Array<Maybe<UserRightsSet>>>
    /**  Create a single UserTicketCommentReadTime item.  */
    createUserTicketCommentReadTime?: Maybe<UserTicketCommentReadTime>
    /**  Create a single UserTicketCommentReadTimeHistoryRecord item.  */
    createUserTicketCommentReadTimeHistoryRecord?: Maybe<UserTicketCommentReadTimeHistoryRecord>
    /**  Create multiple UserTicketCommentReadTimeHistoryRecord items.  */
    createUserTicketCommentReadTimeHistoryRecords?: Maybe<Array<Maybe<UserTicketCommentReadTimeHistoryRecord>>>
    /**  Create multiple UserTicketCommentReadTime items.  */
    createUserTicketCommentReadTimes?: Maybe<Array<Maybe<UserTicketCommentReadTime>>>
    /**  Create multiple User items.  */
    createUsers?: Maybe<Array<Maybe<User>>>
    /**  Create a single Webhook item.  */
    createWebhook?: Maybe<Webhook>
    /**  Create a single WebhookHistoryRecord item.  */
    createWebhookHistoryRecord?: Maybe<WebhookHistoryRecord>
    /**  Create multiple WebhookHistoryRecord items.  */
    createWebhookHistoryRecords?: Maybe<Array<Maybe<WebhookHistoryRecord>>>
    /**  Create a single WebhookSubscription item.  */
    createWebhookSubscription?: Maybe<WebhookSubscription>
    /**  Create a single WebhookSubscriptionHistoryRecord item.  */
    createWebhookSubscriptionHistoryRecord?: Maybe<WebhookSubscriptionHistoryRecord>
    /**  Create multiple WebhookSubscriptionHistoryRecord items.  */
    createWebhookSubscriptionHistoryRecords?: Maybe<Array<Maybe<WebhookSubscriptionHistoryRecord>>>
    /**  Create multiple WebhookSubscription items.  */
    createWebhookSubscriptions?: Maybe<Array<Maybe<WebhookSubscription>>>
    /**  Create multiple Webhook items.  */
    createWebhooks?: Maybe<Array<Maybe<Webhook>>>
    /**  Delete a single AcquiringIntegration item by ID.  */
    deleteAcquiringIntegration?: Maybe<AcquiringIntegration>
    /**  Delete a single AcquiringIntegrationAccessRight item by ID.  */
    deleteAcquiringIntegrationAccessRight?: Maybe<AcquiringIntegrationAccessRight>
    /**  Delete a single AcquiringIntegrationAccessRightHistoryRecord item by ID.  */
    deleteAcquiringIntegrationAccessRightHistoryRecord?: Maybe<AcquiringIntegrationAccessRightHistoryRecord>
    /**  Delete multiple AcquiringIntegrationAccessRightHistoryRecord items by ID.  */
    deleteAcquiringIntegrationAccessRightHistoryRecords?: Maybe<Array<Maybe<AcquiringIntegrationAccessRightHistoryRecord>>>
    /**  Delete multiple AcquiringIntegrationAccessRight items by ID.  */
    deleteAcquiringIntegrationAccessRights?: Maybe<Array<Maybe<AcquiringIntegrationAccessRight>>>
    /**  Delete a single AcquiringIntegrationContext item by ID.  */
    deleteAcquiringIntegrationContext?: Maybe<AcquiringIntegrationContext>
    /**  Delete a single AcquiringIntegrationContextHistoryRecord item by ID.  */
    deleteAcquiringIntegrationContextHistoryRecord?: Maybe<AcquiringIntegrationContextHistoryRecord>
    /**  Delete multiple AcquiringIntegrationContextHistoryRecord items by ID.  */
    deleteAcquiringIntegrationContextHistoryRecords?: Maybe<Array<Maybe<AcquiringIntegrationContextHistoryRecord>>>
    /**  Delete multiple AcquiringIntegrationContext items by ID.  */
    deleteAcquiringIntegrationContexts?: Maybe<Array<Maybe<AcquiringIntegrationContext>>>
    /**  Delete a single AcquiringIntegrationHistoryRecord item by ID.  */
    deleteAcquiringIntegrationHistoryRecord?: Maybe<AcquiringIntegrationHistoryRecord>
    /**  Delete multiple AcquiringIntegrationHistoryRecord items by ID.  */
    deleteAcquiringIntegrationHistoryRecords?: Maybe<Array<Maybe<AcquiringIntegrationHistoryRecord>>>
    /**  Delete multiple AcquiringIntegration items by ID.  */
    deleteAcquiringIntegrations?: Maybe<Array<Maybe<AcquiringIntegration>>>
    /**  Delete a single B2BApp item by ID.  */
    deleteB2BApp?: Maybe<B2BApp>
    /**  Delete a single B2BAppAccessRight item by ID.  */
    deleteB2BAppAccessRight?: Maybe<B2BAppAccessRight>
    /**  Delete a single B2BAppAccessRightHistoryRecord item by ID.  */
    deleteB2BAppAccessRightHistoryRecord?: Maybe<B2BAppAccessRightHistoryRecord>
    /**  Delete multiple B2BAppAccessRightHistoryRecord items by ID.  */
    deleteB2BAppAccessRightHistoryRecords?: Maybe<Array<Maybe<B2BAppAccessRightHistoryRecord>>>
    /**  Delete a single B2BAppAccessRightSet item by ID.  */
    deleteB2BAppAccessRightSet?: Maybe<B2BAppAccessRightSet>
    /**  Delete a single B2BAppAccessRightSetHistoryRecord item by ID.  */
    deleteB2BAppAccessRightSetHistoryRecord?: Maybe<B2BAppAccessRightSetHistoryRecord>
    /**  Delete multiple B2BAppAccessRightSetHistoryRecord items by ID.  */
    deleteB2BAppAccessRightSetHistoryRecords?: Maybe<Array<Maybe<B2BAppAccessRightSetHistoryRecord>>>
    /**  Delete multiple B2BAppAccessRightSet items by ID.  */
    deleteB2BAppAccessRightSets?: Maybe<Array<Maybe<B2BAppAccessRightSet>>>
    /**  Delete multiple B2BAppAccessRight items by ID.  */
    deleteB2BAppAccessRights?: Maybe<Array<Maybe<B2BAppAccessRight>>>
    /**  Delete a single B2BAppContext item by ID.  */
    deleteB2BAppContext?: Maybe<B2BAppContext>
    /**  Delete a single B2BAppContextHistoryRecord item by ID.  */
    deleteB2BAppContextHistoryRecord?: Maybe<B2BAppContextHistoryRecord>
    /**  Delete multiple B2BAppContextHistoryRecord items by ID.  */
    deleteB2BAppContextHistoryRecords?: Maybe<Array<Maybe<B2BAppContextHistoryRecord>>>
    /**  Delete multiple B2BAppContext items by ID.  */
    deleteB2BAppContexts?: Maybe<Array<Maybe<B2BAppContext>>>
    /**  Delete a single B2BAppHistoryRecord item by ID.  */
    deleteB2BAppHistoryRecord?: Maybe<B2BAppHistoryRecord>
    /**  Delete multiple B2BAppHistoryRecord items by ID.  */
    deleteB2BAppHistoryRecords?: Maybe<Array<Maybe<B2BAppHistoryRecord>>>
    /**  Delete a single B2BAppNewsSharingConfig item by ID.  */
    deleteB2BAppNewsSharingConfig?: Maybe<B2BAppNewsSharingConfig>
    /**  Delete a single B2BAppNewsSharingConfigHistoryRecord item by ID.  */
    deleteB2BAppNewsSharingConfigHistoryRecord?: Maybe<B2BAppNewsSharingConfigHistoryRecord>
    /**  Delete multiple B2BAppNewsSharingConfigHistoryRecord items by ID.  */
    deleteB2BAppNewsSharingConfigHistoryRecords?: Maybe<Array<Maybe<B2BAppNewsSharingConfigHistoryRecord>>>
    /**  Delete multiple B2BAppNewsSharingConfig items by ID.  */
    deleteB2BAppNewsSharingConfigs?: Maybe<Array<Maybe<B2BAppNewsSharingConfig>>>
    /**  Delete a single B2BAppPermission item by ID.  */
    deleteB2BAppPermission?: Maybe<B2BAppPermission>
    /**  Delete a single B2BAppPermissionHistoryRecord item by ID.  */
    deleteB2BAppPermissionHistoryRecord?: Maybe<B2BAppPermissionHistoryRecord>
    /**  Delete multiple B2BAppPermissionHistoryRecord items by ID.  */
    deleteB2BAppPermissionHistoryRecords?: Maybe<Array<Maybe<B2BAppPermissionHistoryRecord>>>
    /**  Delete multiple B2BAppPermission items by ID.  */
    deleteB2BAppPermissions?: Maybe<Array<Maybe<B2BAppPermission>>>
    /**  Delete a single B2BAppPromoBlock item by ID.  */
    deleteB2BAppPromoBlock?: Maybe<B2BAppPromoBlock>
    /**  Delete a single B2BAppPromoBlockHistoryRecord item by ID.  */
    deleteB2BAppPromoBlockHistoryRecord?: Maybe<B2BAppPromoBlockHistoryRecord>
    /**  Delete multiple B2BAppPromoBlockHistoryRecord items by ID.  */
    deleteB2BAppPromoBlockHistoryRecords?: Maybe<Array<Maybe<B2BAppPromoBlockHistoryRecord>>>
    /**  Delete multiple B2BAppPromoBlock items by ID.  */
    deleteB2BAppPromoBlocks?: Maybe<Array<Maybe<B2BAppPromoBlock>>>
    /**  Delete a single B2BAppRole item by ID.  */
    deleteB2BAppRole?: Maybe<B2BAppRole>
    /**  Delete a single B2BAppRoleHistoryRecord item by ID.  */
    deleteB2BAppRoleHistoryRecord?: Maybe<B2BAppRoleHistoryRecord>
    /**  Delete multiple B2BAppRoleHistoryRecord items by ID.  */
    deleteB2BAppRoleHistoryRecords?: Maybe<Array<Maybe<B2BAppRoleHistoryRecord>>>
    /**  Delete multiple B2BAppRole items by ID.  */
    deleteB2BAppRoles?: Maybe<Array<Maybe<B2BAppRole>>>
    /**  Delete multiple B2BApp items by ID.  */
    deleteB2BApps?: Maybe<Array<Maybe<B2BApp>>>
    /**  Delete a single B2CApp item by ID.  */
    deleteB2CApp?: Maybe<B2CApp>
    /**  Delete a single B2CAppAccessRight item by ID.  */
    deleteB2CAppAccessRight?: Maybe<B2CAppAccessRight>
    /**  Delete a single B2CAppAccessRightHistoryRecord item by ID.  */
    deleteB2CAppAccessRightHistoryRecord?: Maybe<B2CAppAccessRightHistoryRecord>
    /**  Delete multiple B2CAppAccessRightHistoryRecord items by ID.  */
    deleteB2CAppAccessRightHistoryRecords?: Maybe<Array<Maybe<B2CAppAccessRightHistoryRecord>>>
    /**  Delete multiple B2CAppAccessRight items by ID.  */
    deleteB2CAppAccessRights?: Maybe<Array<Maybe<B2CAppAccessRight>>>
    /**  Delete a single B2CAppBuild item by ID.  */
    deleteB2CAppBuild?: Maybe<B2CAppBuild>
    /**  Delete a single B2CAppBuildHistoryRecord item by ID.  */
    deleteB2CAppBuildHistoryRecord?: Maybe<B2CAppBuildHistoryRecord>
    /**  Delete multiple B2CAppBuildHistoryRecord items by ID.  */
    deleteB2CAppBuildHistoryRecords?: Maybe<Array<Maybe<B2CAppBuildHistoryRecord>>>
    /**  Delete multiple B2CAppBuild items by ID.  */
    deleteB2CAppBuilds?: Maybe<Array<Maybe<B2CAppBuild>>>
    /**  Delete a single B2CAppHistoryRecord item by ID.  */
    deleteB2CAppHistoryRecord?: Maybe<B2CAppHistoryRecord>
    /**  Delete multiple B2CAppHistoryRecord items by ID.  */
    deleteB2CAppHistoryRecords?: Maybe<Array<Maybe<B2CAppHistoryRecord>>>
    /**  Delete a single B2CAppMessageSetting item by ID.  */
    deleteB2CAppMessageSetting?: Maybe<B2CAppMessageSetting>
    /**  Delete a single B2CAppMessageSettingHistoryRecord item by ID.  */
    deleteB2CAppMessageSettingHistoryRecord?: Maybe<B2CAppMessageSettingHistoryRecord>
    /**  Delete multiple B2CAppMessageSettingHistoryRecord items by ID.  */
    deleteB2CAppMessageSettingHistoryRecords?: Maybe<Array<Maybe<B2CAppMessageSettingHistoryRecord>>>
    /**  Delete multiple B2CAppMessageSetting items by ID.  */
    deleteB2CAppMessageSettings?: Maybe<Array<Maybe<B2CAppMessageSetting>>>
    /**  Delete multiple B2CAppProperty items by ID.  */
    deleteB2CAppProperties?: Maybe<Array<Maybe<B2CAppProperty>>>
    /**  Delete a single B2CAppProperty item by ID.  */
    deleteB2CAppProperty?: Maybe<B2CAppProperty>
    /**  Delete a single B2CAppPropertyHistoryRecord item by ID.  */
    deleteB2CAppPropertyHistoryRecord?: Maybe<B2CAppPropertyHistoryRecord>
    /**  Delete multiple B2CAppPropertyHistoryRecord items by ID.  */
    deleteB2CAppPropertyHistoryRecords?: Maybe<Array<Maybe<B2CAppPropertyHistoryRecord>>>
    /**  Delete multiple B2CApp items by ID.  */
    deleteB2CApps?: Maybe<Array<Maybe<B2CApp>>>
    /**  Delete a single BankAccount item by ID.  */
    deleteBankAccount?: Maybe<BankAccount>
    /**  Delete a single BankAccountHistoryRecord item by ID.  */
    deleteBankAccountHistoryRecord?: Maybe<BankAccountHistoryRecord>
    /**  Delete multiple BankAccountHistoryRecord items by ID.  */
    deleteBankAccountHistoryRecords?: Maybe<Array<Maybe<BankAccountHistoryRecord>>>
    /**  Delete a single BankAccountReport item by ID.  */
    deleteBankAccountReport?: Maybe<BankAccountReport>
    /**  Delete a single BankAccountReportHistoryRecord item by ID.  */
    deleteBankAccountReportHistoryRecord?: Maybe<BankAccountReportHistoryRecord>
    /**  Delete multiple BankAccountReportHistoryRecord items by ID.  */
    deleteBankAccountReportHistoryRecords?: Maybe<Array<Maybe<BankAccountReportHistoryRecord>>>
    /**  Delete a single BankAccountReportTask item by ID.  */
    deleteBankAccountReportTask?: Maybe<BankAccountReportTask>
    /**  Delete a single BankAccountReportTaskHistoryRecord item by ID.  */
    deleteBankAccountReportTaskHistoryRecord?: Maybe<BankAccountReportTaskHistoryRecord>
    /**  Delete multiple BankAccountReportTaskHistoryRecord items by ID.  */
    deleteBankAccountReportTaskHistoryRecords?: Maybe<Array<Maybe<BankAccountReportTaskHistoryRecord>>>
    /**  Delete multiple BankAccountReportTask items by ID.  */
    deleteBankAccountReportTasks?: Maybe<Array<Maybe<BankAccountReportTask>>>
    /**  Delete multiple BankAccountReport items by ID.  */
    deleteBankAccountReports?: Maybe<Array<Maybe<BankAccountReport>>>
    /**  Delete multiple BankAccount items by ID.  */
    deleteBankAccounts?: Maybe<Array<Maybe<BankAccount>>>
    /**  Delete multiple BankCategory items by ID.  */
    deleteBankCategories?: Maybe<Array<Maybe<BankCategory>>>
    /**  Delete a single BankCategory item by ID.  */
    deleteBankCategory?: Maybe<BankCategory>
    /**  Delete a single BankCategoryHistoryRecord item by ID.  */
    deleteBankCategoryHistoryRecord?: Maybe<BankCategoryHistoryRecord>
    /**  Delete multiple BankCategoryHistoryRecord items by ID.  */
    deleteBankCategoryHistoryRecords?: Maybe<Array<Maybe<BankCategoryHistoryRecord>>>
    /**  Delete a single BankContractorAccount item by ID.  */
    deleteBankContractorAccount?: Maybe<BankContractorAccount>
    /**  Delete a single BankContractorAccountHistoryRecord item by ID.  */
    deleteBankContractorAccountHistoryRecord?: Maybe<BankContractorAccountHistoryRecord>
    /**  Delete multiple BankContractorAccountHistoryRecord items by ID.  */
    deleteBankContractorAccountHistoryRecords?: Maybe<Array<Maybe<BankContractorAccountHistoryRecord>>>
    /**  Delete multiple BankContractorAccount items by ID.  */
    deleteBankContractorAccounts?: Maybe<Array<Maybe<BankContractorAccount>>>
    /**  Delete a single BankCostItem item by ID.  */
    deleteBankCostItem?: Maybe<BankCostItem>
    /**  Delete a single BankCostItemHistoryRecord item by ID.  */
    deleteBankCostItemHistoryRecord?: Maybe<BankCostItemHistoryRecord>
    /**  Delete multiple BankCostItemHistoryRecord items by ID.  */
    deleteBankCostItemHistoryRecords?: Maybe<Array<Maybe<BankCostItemHistoryRecord>>>
    /**  Delete multiple BankCostItem items by ID.  */
    deleteBankCostItems?: Maybe<Array<Maybe<BankCostItem>>>
    /**  Delete a single BankIntegration item by ID.  */
    deleteBankIntegration?: Maybe<BankIntegration>
    /**  Delete a single BankIntegrationAccessRight item by ID.  */
    deleteBankIntegrationAccessRight?: Maybe<BankIntegrationAccessRight>
    /**  Delete a single BankIntegrationAccessRightHistoryRecord item by ID.  */
    deleteBankIntegrationAccessRightHistoryRecord?: Maybe<BankIntegrationAccessRightHistoryRecord>
    /**  Delete multiple BankIntegrationAccessRightHistoryRecord items by ID.  */
    deleteBankIntegrationAccessRightHistoryRecords?: Maybe<Array<Maybe<BankIntegrationAccessRightHistoryRecord>>>
    /**  Delete multiple BankIntegrationAccessRight items by ID.  */
    deleteBankIntegrationAccessRights?: Maybe<Array<Maybe<BankIntegrationAccessRight>>>
    /**  Delete a single BankIntegrationAccountContext item by ID.  */
    deleteBankIntegrationAccountContext?: Maybe<BankIntegrationAccountContext>
    /**  Delete a single BankIntegrationAccountContextHistoryRecord item by ID.  */
    deleteBankIntegrationAccountContextHistoryRecord?: Maybe<BankIntegrationAccountContextHistoryRecord>
    /**  Delete multiple BankIntegrationAccountContextHistoryRecord items by ID.  */
    deleteBankIntegrationAccountContextHistoryRecords?: Maybe<Array<Maybe<BankIntegrationAccountContextHistoryRecord>>>
    /**  Delete multiple BankIntegrationAccountContext items by ID.  */
    deleteBankIntegrationAccountContexts?: Maybe<Array<Maybe<BankIntegrationAccountContext>>>
    /**  Delete a single BankIntegrationHistoryRecord item by ID.  */
    deleteBankIntegrationHistoryRecord?: Maybe<BankIntegrationHistoryRecord>
    /**  Delete multiple BankIntegrationHistoryRecord items by ID.  */
    deleteBankIntegrationHistoryRecords?: Maybe<Array<Maybe<BankIntegrationHistoryRecord>>>
    /**  Delete a single BankIntegrationOrganizationContext item by ID.  */
    deleteBankIntegrationOrganizationContext?: Maybe<BankIntegrationOrganizationContext>
    /**  Delete a single BankIntegrationOrganizationContextHistoryRecord item by ID.  */
    deleteBankIntegrationOrganizationContextHistoryRecord?: Maybe<BankIntegrationOrganizationContextHistoryRecord>
    /**  Delete multiple BankIntegrationOrganizationContextHistoryRecord items by ID.  */
    deleteBankIntegrationOrganizationContextHistoryRecords?: Maybe<Array<Maybe<BankIntegrationOrganizationContextHistoryRecord>>>
    /**  Delete multiple BankIntegrationOrganizationContext items by ID.  */
    deleteBankIntegrationOrganizationContexts?: Maybe<Array<Maybe<BankIntegrationOrganizationContext>>>
    /**  Delete multiple BankIntegration items by ID.  */
    deleteBankIntegrations?: Maybe<Array<Maybe<BankIntegration>>>
    /**  Delete a single BankSyncTask item by ID.  */
    deleteBankSyncTask?: Maybe<BankSyncTask>
    /**  Delete a single BankSyncTaskHistoryRecord item by ID.  */
    deleteBankSyncTaskHistoryRecord?: Maybe<BankSyncTaskHistoryRecord>
    /**  Delete multiple BankSyncTaskHistoryRecord items by ID.  */
    deleteBankSyncTaskHistoryRecords?: Maybe<Array<Maybe<BankSyncTaskHistoryRecord>>>
    /**  Delete multiple BankSyncTask items by ID.  */
    deleteBankSyncTasks?: Maybe<Array<Maybe<BankSyncTask>>>
    /**  Delete a single BankTransaction item by ID.  */
    deleteBankTransaction?: Maybe<BankTransaction>
    /**  Delete a single BankTransactionHistoryRecord item by ID.  */
    deleteBankTransactionHistoryRecord?: Maybe<BankTransactionHistoryRecord>
    /**  Delete multiple BankTransactionHistoryRecord items by ID.  */
    deleteBankTransactionHistoryRecords?: Maybe<Array<Maybe<BankTransactionHistoryRecord>>>
    /**  Delete multiple BankTransaction items by ID.  */
    deleteBankTransactions?: Maybe<Array<Maybe<BankTransaction>>>
    /**  Delete a single BillingAccount item by ID.  */
    deleteBillingAccount?: Maybe<BillingAccount>
    /**  Delete a single BillingAccountHistoryRecord item by ID.  */
    deleteBillingAccountHistoryRecord?: Maybe<BillingAccountHistoryRecord>
    /**  Delete multiple BillingAccountHistoryRecord items by ID.  */
    deleteBillingAccountHistoryRecords?: Maybe<Array<Maybe<BillingAccountHistoryRecord>>>
    /**  Delete multiple BillingAccount items by ID.  */
    deleteBillingAccounts?: Maybe<Array<Maybe<BillingAccount>>>
    /**  Delete multiple BillingCategory items by ID.  */
    deleteBillingCategories?: Maybe<Array<Maybe<BillingCategory>>>
    /**  Delete a single BillingCategory item by ID.  */
    deleteBillingCategory?: Maybe<BillingCategory>
    /**  Delete a single BillingCategoryHistoryRecord item by ID.  */
    deleteBillingCategoryHistoryRecord?: Maybe<BillingCategoryHistoryRecord>
    /**  Delete multiple BillingCategoryHistoryRecord items by ID.  */
    deleteBillingCategoryHistoryRecords?: Maybe<Array<Maybe<BillingCategoryHistoryRecord>>>
    /**  Delete a single BillingIntegration item by ID.  */
    deleteBillingIntegration?: Maybe<BillingIntegration>
    /**  Delete a single BillingIntegrationAccessRight item by ID.  */
    deleteBillingIntegrationAccessRight?: Maybe<BillingIntegrationAccessRight>
    /**  Delete a single BillingIntegrationAccessRightHistoryRecord item by ID.  */
    deleteBillingIntegrationAccessRightHistoryRecord?: Maybe<BillingIntegrationAccessRightHistoryRecord>
    /**  Delete multiple BillingIntegrationAccessRightHistoryRecord items by ID.  */
    deleteBillingIntegrationAccessRightHistoryRecords?: Maybe<Array<Maybe<BillingIntegrationAccessRightHistoryRecord>>>
    /**  Delete multiple BillingIntegrationAccessRight items by ID.  */
    deleteBillingIntegrationAccessRights?: Maybe<Array<Maybe<BillingIntegrationAccessRight>>>
    /**  Delete a single BillingIntegrationHistoryRecord item by ID.  */
    deleteBillingIntegrationHistoryRecord?: Maybe<BillingIntegrationHistoryRecord>
    /**  Delete multiple BillingIntegrationHistoryRecord items by ID.  */
    deleteBillingIntegrationHistoryRecords?: Maybe<Array<Maybe<BillingIntegrationHistoryRecord>>>
    /**  Delete a single BillingIntegrationOrganizationContext item by ID.  */
    deleteBillingIntegrationOrganizationContext?: Maybe<BillingIntegrationOrganizationContext>
    /**  Delete a single BillingIntegrationOrganizationContextHistoryRecord item by ID.  */
    deleteBillingIntegrationOrganizationContextHistoryRecord?: Maybe<BillingIntegrationOrganizationContextHistoryRecord>
    /**  Delete multiple BillingIntegrationOrganizationContextHistoryRecord items by ID.  */
    deleteBillingIntegrationOrganizationContextHistoryRecords?: Maybe<Array<Maybe<BillingIntegrationOrganizationContextHistoryRecord>>>
    /**  Delete multiple BillingIntegrationOrganizationContext items by ID.  */
    deleteBillingIntegrationOrganizationContexts?: Maybe<Array<Maybe<BillingIntegrationOrganizationContext>>>
    /**  Delete a single BillingIntegrationProblem item by ID.  */
    deleteBillingIntegrationProblem?: Maybe<BillingIntegrationProblem>
    /**  Delete multiple BillingIntegrationProblem items by ID.  */
    deleteBillingIntegrationProblems?: Maybe<Array<Maybe<BillingIntegrationProblem>>>
    /**  Delete multiple BillingIntegration items by ID.  */
    deleteBillingIntegrations?: Maybe<Array<Maybe<BillingIntegration>>>
    /**  Delete multiple BillingProperty items by ID.  */
    deleteBillingProperties?: Maybe<Array<Maybe<BillingProperty>>>
    /**  Delete a single BillingProperty item by ID.  */
    deleteBillingProperty?: Maybe<BillingProperty>
    /**  Delete a single BillingPropertyHistoryRecord item by ID.  */
    deleteBillingPropertyHistoryRecord?: Maybe<BillingPropertyHistoryRecord>
    /**  Delete multiple BillingPropertyHistoryRecord items by ID.  */
    deleteBillingPropertyHistoryRecords?: Maybe<Array<Maybe<BillingPropertyHistoryRecord>>>
    /**  Delete a single BillingReceipt item by ID.  */
    deleteBillingReceipt?: Maybe<BillingReceipt>
    /**  Delete a single BillingReceiptFile item by ID.  */
    deleteBillingReceiptFile?: Maybe<BillingReceiptFile>
    /**  Delete a single BillingReceiptFileHistoryRecord item by ID.  */
    deleteBillingReceiptFileHistoryRecord?: Maybe<BillingReceiptFileHistoryRecord>
    /**  Delete multiple BillingReceiptFileHistoryRecord items by ID.  */
    deleteBillingReceiptFileHistoryRecords?: Maybe<Array<Maybe<BillingReceiptFileHistoryRecord>>>
    /**  Delete multiple BillingReceiptFile items by ID.  */
    deleteBillingReceiptFiles?: Maybe<Array<Maybe<BillingReceiptFile>>>
    /**  Delete a single BillingReceiptHistoryRecord item by ID.  */
    deleteBillingReceiptHistoryRecord?: Maybe<BillingReceiptHistoryRecord>
    /**  Delete multiple BillingReceiptHistoryRecord items by ID.  */
    deleteBillingReceiptHistoryRecords?: Maybe<Array<Maybe<BillingReceiptHistoryRecord>>>
    /**  Delete multiple BillingReceipt items by ID.  */
    deleteBillingReceipts?: Maybe<Array<Maybe<BillingReceipt>>>
    /**  Delete a single BillingRecipient item by ID.  */
    deleteBillingRecipient?: Maybe<BillingRecipient>
    /**  Delete a single BillingRecipientHistoryRecord item by ID.  */
    deleteBillingRecipientHistoryRecord?: Maybe<BillingRecipientHistoryRecord>
    /**  Delete multiple BillingRecipientHistoryRecord items by ID.  */
    deleteBillingRecipientHistoryRecords?: Maybe<Array<Maybe<BillingRecipientHistoryRecord>>>
    /**  Delete multiple BillingRecipient items by ID.  */
    deleteBillingRecipients?: Maybe<Array<Maybe<BillingRecipient>>>
    /**  Delete a single CallRecord item by ID.  */
    deleteCallRecord?: Maybe<CallRecord>
    /**  Delete a single CallRecordFragment item by ID.  */
    deleteCallRecordFragment?: Maybe<CallRecordFragment>
    /**  Delete a single CallRecordFragmentHistoryRecord item by ID.  */
    deleteCallRecordFragmentHistoryRecord?: Maybe<CallRecordFragmentHistoryRecord>
    /**  Delete multiple CallRecordFragmentHistoryRecord items by ID.  */
    deleteCallRecordFragmentHistoryRecords?: Maybe<Array<Maybe<CallRecordFragmentHistoryRecord>>>
    /**  Delete multiple CallRecordFragment items by ID.  */
    deleteCallRecordFragments?: Maybe<Array<Maybe<CallRecordFragment>>>
    /**  Delete a single CallRecordHistoryRecord item by ID.  */
    deleteCallRecordHistoryRecord?: Maybe<CallRecordHistoryRecord>
    /**  Delete multiple CallRecordHistoryRecord items by ID.  */
    deleteCallRecordHistoryRecords?: Maybe<Array<Maybe<CallRecordHistoryRecord>>>
    /**  Delete multiple CallRecord items by ID.  */
    deleteCallRecords?: Maybe<Array<Maybe<CallRecord>>>
    /**  Delete a single ConfirmPhoneAction item by ID.  */
    deleteConfirmPhoneAction?: Maybe<ConfirmPhoneAction>
    /**  Delete a single ConfirmPhoneActionHistoryRecord item by ID.  */
    deleteConfirmPhoneActionHistoryRecord?: Maybe<ConfirmPhoneActionHistoryRecord>
    /**  Delete multiple ConfirmPhoneActionHistoryRecord items by ID.  */
    deleteConfirmPhoneActionHistoryRecords?: Maybe<Array<Maybe<ConfirmPhoneActionHistoryRecord>>>
    /**  Delete multiple ConfirmPhoneAction items by ID.  */
    deleteConfirmPhoneActions?: Maybe<Array<Maybe<ConfirmPhoneAction>>>
    /**  Delete a single Contact item by ID.  */
    deleteContact?: Maybe<Contact>
    /**  Delete a single ContactExportTask item by ID.  */
    deleteContactExportTask?: Maybe<ContactExportTask>
    /**  Delete a single ContactExportTaskHistoryRecord item by ID.  */
    deleteContactExportTaskHistoryRecord?: Maybe<ContactExportTaskHistoryRecord>
    /**  Delete multiple ContactExportTaskHistoryRecord items by ID.  */
    deleteContactExportTaskHistoryRecords?: Maybe<Array<Maybe<ContactExportTaskHistoryRecord>>>
    /**  Delete multiple ContactExportTask items by ID.  */
    deleteContactExportTasks?: Maybe<Array<Maybe<ContactExportTask>>>
    /**  Delete a single ContactHistoryRecord item by ID.  */
    deleteContactHistoryRecord?: Maybe<ContactHistoryRecord>
    /**  Delete multiple ContactHistoryRecord items by ID.  */
    deleteContactHistoryRecords?: Maybe<Array<Maybe<ContactHistoryRecord>>>
    /**  Delete a single ContactRole item by ID.  */
    deleteContactRole?: Maybe<ContactRole>
    /**  Delete a single ContactRoleHistoryRecord item by ID.  */
    deleteContactRoleHistoryRecord?: Maybe<ContactRoleHistoryRecord>
    /**  Delete multiple ContactRoleHistoryRecord items by ID.  */
    deleteContactRoleHistoryRecords?: Maybe<Array<Maybe<ContactRoleHistoryRecord>>>
    /**  Delete multiple ContactRole items by ID.  */
    deleteContactRoles?: Maybe<Array<Maybe<ContactRole>>>
    /**  Delete multiple Contact items by ID.  */
    deleteContacts?: Maybe<Array<Maybe<Contact>>>
    /**  Delete a single Document item by ID.  */
    deleteDocument?: Maybe<Document>
    /**  Delete multiple DocumentCategory items by ID.  */
    deleteDocumentCategories?: Maybe<Array<Maybe<DocumentCategory>>>
    /**  Delete a single DocumentCategory item by ID.  */
    deleteDocumentCategory?: Maybe<DocumentCategory>
    /**  Delete a single DocumentCategoryHistoryRecord item by ID.  */
    deleteDocumentCategoryHistoryRecord?: Maybe<DocumentCategoryHistoryRecord>
    /**  Delete multiple DocumentCategoryHistoryRecord items by ID.  */
    deleteDocumentCategoryHistoryRecords?: Maybe<Array<Maybe<DocumentCategoryHistoryRecord>>>
    /**  Delete a single DocumentHistoryRecord item by ID.  */
    deleteDocumentHistoryRecord?: Maybe<DocumentHistoryRecord>
    /**  Delete multiple DocumentHistoryRecord items by ID.  */
    deleteDocumentHistoryRecords?: Maybe<Array<Maybe<DocumentHistoryRecord>>>
    /**  Delete multiple Document items by ID.  */
    deleteDocuments?: Maybe<Array<Maybe<Document>>>
    /**  Delete a single ExternalTokenAccessRight item by ID.  */
    deleteExternalTokenAccessRight?: Maybe<ExternalTokenAccessRight>
    /**  Delete a single ExternalTokenAccessRightHistoryRecord item by ID.  */
    deleteExternalTokenAccessRightHistoryRecord?: Maybe<ExternalTokenAccessRightHistoryRecord>
    /**  Delete multiple ExternalTokenAccessRightHistoryRecord items by ID.  */
    deleteExternalTokenAccessRightHistoryRecords?: Maybe<Array<Maybe<ExternalTokenAccessRightHistoryRecord>>>
    /**  Delete multiple ExternalTokenAccessRight items by ID.  */
    deleteExternalTokenAccessRights?: Maybe<Array<Maybe<ExternalTokenAccessRight>>>
    /**  Delete a single Incident item by ID.  */
    deleteIncident?: Maybe<Incident>
    /**  Delete a single IncidentChange item by ID.  */
    deleteIncidentChange?: Maybe<IncidentChange>
    /**  Delete multiple IncidentChange items by ID.  */
    deleteIncidentChanges?: Maybe<Array<Maybe<IncidentChange>>>
    /**  Delete a single IncidentClassifier item by ID.  */
    deleteIncidentClassifier?: Maybe<IncidentClassifier>
    /**  Delete a single IncidentClassifierHistoryRecord item by ID.  */
    deleteIncidentClassifierHistoryRecord?: Maybe<IncidentClassifierHistoryRecord>
    /**  Delete multiple IncidentClassifierHistoryRecord items by ID.  */
    deleteIncidentClassifierHistoryRecords?: Maybe<Array<Maybe<IncidentClassifierHistoryRecord>>>
    /**  Delete a single IncidentClassifierIncident item by ID.  */
    deleteIncidentClassifierIncident?: Maybe<IncidentClassifierIncident>
    /**  Delete a single IncidentClassifierIncidentHistoryRecord item by ID.  */
    deleteIncidentClassifierIncidentHistoryRecord?: Maybe<IncidentClassifierIncidentHistoryRecord>
    /**  Delete multiple IncidentClassifierIncidentHistoryRecord items by ID.  */
    deleteIncidentClassifierIncidentHistoryRecords?: Maybe<Array<Maybe<IncidentClassifierIncidentHistoryRecord>>>
    /**  Delete multiple IncidentClassifierIncident items by ID.  */
    deleteIncidentClassifierIncidents?: Maybe<Array<Maybe<IncidentClassifierIncident>>>
    /**  Delete multiple IncidentClassifier items by ID.  */
    deleteIncidentClassifiers?: Maybe<Array<Maybe<IncidentClassifier>>>
    /**  Delete a single IncidentExportTask item by ID.  */
    deleteIncidentExportTask?: Maybe<IncidentExportTask>
    /**  Delete a single IncidentExportTaskHistoryRecord item by ID.  */
    deleteIncidentExportTaskHistoryRecord?: Maybe<IncidentExportTaskHistoryRecord>
    /**  Delete multiple IncidentExportTaskHistoryRecord items by ID.  */
    deleteIncidentExportTaskHistoryRecords?: Maybe<Array<Maybe<IncidentExportTaskHistoryRecord>>>
    /**  Delete multiple IncidentExportTask items by ID.  */
    deleteIncidentExportTasks?: Maybe<Array<Maybe<IncidentExportTask>>>
    /**  Delete a single IncidentHistoryRecord item by ID.  */
    deleteIncidentHistoryRecord?: Maybe<IncidentHistoryRecord>
    /**  Delete multiple IncidentHistoryRecord items by ID.  */
    deleteIncidentHistoryRecords?: Maybe<Array<Maybe<IncidentHistoryRecord>>>
    /**  Delete multiple IncidentProperty items by ID.  */
    deleteIncidentProperties?: Maybe<Array<Maybe<IncidentProperty>>>
    /**  Delete a single IncidentProperty item by ID.  */
    deleteIncidentProperty?: Maybe<IncidentProperty>
    /**  Delete a single IncidentPropertyHistoryRecord item by ID.  */
    deleteIncidentPropertyHistoryRecord?: Maybe<IncidentPropertyHistoryRecord>
    /**  Delete multiple IncidentPropertyHistoryRecord items by ID.  */
    deleteIncidentPropertyHistoryRecords?: Maybe<Array<Maybe<IncidentPropertyHistoryRecord>>>
    /**  Delete multiple Incident items by ID.  */
    deleteIncidents?: Maybe<Array<Maybe<Incident>>>
    /**  Delete a single Invoice item by ID.  */
    deleteInvoice?: Maybe<Invoice>
    /**  Delete a single InvoiceHistoryRecord item by ID.  */
    deleteInvoiceHistoryRecord?: Maybe<InvoiceHistoryRecord>
    /**  Delete multiple InvoiceHistoryRecord items by ID.  */
    deleteInvoiceHistoryRecords?: Maybe<Array<Maybe<InvoiceHistoryRecord>>>
    /**  Delete multiple Invoice items by ID.  */
    deleteInvoices?: Maybe<Array<Maybe<Invoice>>>
    /**  Delete multiple MarketCategory items by ID.  */
    deleteMarketCategories?: Maybe<Array<Maybe<MarketCategory>>>
    /**  Delete a single MarketCategory item by ID.  */
    deleteMarketCategory?: Maybe<MarketCategory>
    /**  Delete a single MarketCategoryHistoryRecord item by ID.  */
    deleteMarketCategoryHistoryRecord?: Maybe<MarketCategoryHistoryRecord>
    /**  Delete multiple MarketCategoryHistoryRecord items by ID.  */
    deleteMarketCategoryHistoryRecords?: Maybe<Array<Maybe<MarketCategoryHistoryRecord>>>
    /**  Delete a single MarketItem item by ID.  */
    deleteMarketItem?: Maybe<MarketItem>
    /**  Delete a single MarketItemFile item by ID.  */
    deleteMarketItemFile?: Maybe<MarketItemFile>
    /**  Delete a single MarketItemFileHistoryRecord item by ID.  */
    deleteMarketItemFileHistoryRecord?: Maybe<MarketItemFileHistoryRecord>
    /**  Delete multiple MarketItemFileHistoryRecord items by ID.  */
    deleteMarketItemFileHistoryRecords?: Maybe<Array<Maybe<MarketItemFileHistoryRecord>>>
    /**  Delete multiple MarketItemFile items by ID.  */
    deleteMarketItemFiles?: Maybe<Array<Maybe<MarketItemFile>>>
    /**  Delete a single MarketItemHistoryRecord item by ID.  */
    deleteMarketItemHistoryRecord?: Maybe<MarketItemHistoryRecord>
    /**  Delete multiple MarketItemHistoryRecord items by ID.  */
    deleteMarketItemHistoryRecords?: Maybe<Array<Maybe<MarketItemHistoryRecord>>>
    /**  Delete a single MarketItemPrice item by ID.  */
    deleteMarketItemPrice?: Maybe<MarketItemPrice>
    /**  Delete a single MarketItemPriceHistoryRecord item by ID.  */
    deleteMarketItemPriceHistoryRecord?: Maybe<MarketItemPriceHistoryRecord>
    /**  Delete multiple MarketItemPriceHistoryRecord items by ID.  */
    deleteMarketItemPriceHistoryRecords?: Maybe<Array<Maybe<MarketItemPriceHistoryRecord>>>
    /**  Delete multiple MarketItemPrice items by ID.  */
    deleteMarketItemPrices?: Maybe<Array<Maybe<MarketItemPrice>>>
    /**  Delete multiple MarketItem items by ID.  */
    deleteMarketItems?: Maybe<Array<Maybe<MarketItem>>>
    /**  Delete a single MarketPriceScope item by ID.  */
    deleteMarketPriceScope?: Maybe<MarketPriceScope>
    /**  Delete a single MarketPriceScopeHistoryRecord item by ID.  */
    deleteMarketPriceScopeHistoryRecord?: Maybe<MarketPriceScopeHistoryRecord>
    /**  Delete multiple MarketPriceScopeHistoryRecord items by ID.  */
    deleteMarketPriceScopeHistoryRecords?: Maybe<Array<Maybe<MarketPriceScopeHistoryRecord>>>
    /**  Delete multiple MarketPriceScope items by ID.  */
    deleteMarketPriceScopes?: Maybe<Array<Maybe<MarketPriceScope>>>
    /**  Delete a single MarketSetting item by ID.  */
    deleteMarketSetting?: Maybe<MarketSetting>
    /**  Delete a single MarketSettingHistoryRecord item by ID.  */
    deleteMarketSettingHistoryRecord?: Maybe<MarketSettingHistoryRecord>
    /**  Delete multiple MarketSettingHistoryRecord items by ID.  */
    deleteMarketSettingHistoryRecords?: Maybe<Array<Maybe<MarketSettingHistoryRecord>>>
    /**  Delete multiple MarketSetting items by ID.  */
    deleteMarketSettings?: Maybe<Array<Maybe<MarketSetting>>>
    /**  Delete a single Message item by ID.  */
    deleteMessage?: Maybe<Message>
    /**  Delete a single MessageAppBlackList item by ID.  */
    deleteMessageAppBlackList?: Maybe<MessageAppBlackList>
    /**  Delete a single MessageAppBlackListHistoryRecord item by ID.  */
    deleteMessageAppBlackListHistoryRecord?: Maybe<MessageAppBlackListHistoryRecord>
    /**  Delete multiple MessageAppBlackListHistoryRecord items by ID.  */
    deleteMessageAppBlackListHistoryRecords?: Maybe<Array<Maybe<MessageAppBlackListHistoryRecord>>>
    /**  Delete multiple MessageAppBlackList items by ID.  */
    deleteMessageAppBlackLists?: Maybe<Array<Maybe<MessageAppBlackList>>>
    /**  Delete a single MessageBatch item by ID.  */
    deleteMessageBatch?: Maybe<MessageBatch>
    /**  Delete a single MessageBatchHistoryRecord item by ID.  */
    deleteMessageBatchHistoryRecord?: Maybe<MessageBatchHistoryRecord>
    /**  Delete multiple MessageBatchHistoryRecord items by ID.  */
    deleteMessageBatchHistoryRecords?: Maybe<Array<Maybe<MessageBatchHistoryRecord>>>
    /**  Delete multiple MessageBatch items by ID.  */
    deleteMessageBatches?: Maybe<Array<Maybe<MessageBatch>>>
    /**  Delete a single MessageHistoryRecord item by ID.  */
    deleteMessageHistoryRecord?: Maybe<MessageHistoryRecord>
    /**  Delete multiple MessageHistoryRecord items by ID.  */
    deleteMessageHistoryRecords?: Maybe<Array<Maybe<MessageHistoryRecord>>>
    /**  Delete a single MessageOrganizationBlackList item by ID.  */
    deleteMessageOrganizationBlackList?: Maybe<MessageOrganizationBlackList>
    /**  Delete a single MessageOrganizationBlackListHistoryRecord item by ID.  */
    deleteMessageOrganizationBlackListHistoryRecord?: Maybe<MessageOrganizationBlackListHistoryRecord>
    /**  Delete multiple MessageOrganizationBlackListHistoryRecord items by ID.  */
    deleteMessageOrganizationBlackListHistoryRecords?: Maybe<Array<Maybe<MessageOrganizationBlackListHistoryRecord>>>
    /**  Delete multiple MessageOrganizationBlackList items by ID.  */
    deleteMessageOrganizationBlackLists?: Maybe<Array<Maybe<MessageOrganizationBlackList>>>
    /**  Delete a single MessageUserBlackList item by ID.  */
    deleteMessageUserBlackList?: Maybe<MessageUserBlackList>
    /**  Delete a single MessageUserBlackListHistoryRecord item by ID.  */
    deleteMessageUserBlackListHistoryRecord?: Maybe<MessageUserBlackListHistoryRecord>
    /**  Delete multiple MessageUserBlackListHistoryRecord items by ID.  */
    deleteMessageUserBlackListHistoryRecords?: Maybe<Array<Maybe<MessageUserBlackListHistoryRecord>>>
    /**  Delete multiple MessageUserBlackList items by ID.  */
    deleteMessageUserBlackLists?: Maybe<Array<Maybe<MessageUserBlackList>>>
    /**  Delete multiple Message items by ID.  */
    deleteMessages?: Maybe<Array<Maybe<Message>>>
    /**  Delete a single Meter item by ID.  */
    deleteMeter?: Maybe<Meter>
    /**  Delete a single MeterHistoryRecord item by ID.  */
    deleteMeterHistoryRecord?: Maybe<MeterHistoryRecord>
    /**  Delete multiple MeterHistoryRecord items by ID.  */
    deleteMeterHistoryRecords?: Maybe<Array<Maybe<MeterHistoryRecord>>>
    /**  Delete a single MeterReading item by ID.  */
    deleteMeterReading?: Maybe<MeterReading>
    /**  Delete a single MeterReadingExportTask item by ID.  */
    deleteMeterReadingExportTask?: Maybe<MeterReadingExportTask>
    /**  Delete a single MeterReadingExportTaskHistoryRecord item by ID.  */
    deleteMeterReadingExportTaskHistoryRecord?: Maybe<MeterReadingExportTaskHistoryRecord>
    /**  Delete multiple MeterReadingExportTaskHistoryRecord items by ID.  */
    deleteMeterReadingExportTaskHistoryRecords?: Maybe<Array<Maybe<MeterReadingExportTaskHistoryRecord>>>
    /**  Delete multiple MeterReadingExportTask items by ID.  */
    deleteMeterReadingExportTasks?: Maybe<Array<Maybe<MeterReadingExportTask>>>
    /**  Delete a single MeterReadingFilterTemplate item by ID.  */
    deleteMeterReadingFilterTemplate?: Maybe<MeterReadingFilterTemplate>
    /**  Delete a single MeterReadingFilterTemplateHistoryRecord item by ID.  */
    deleteMeterReadingFilterTemplateHistoryRecord?: Maybe<MeterReadingFilterTemplateHistoryRecord>
    /**  Delete multiple MeterReadingFilterTemplateHistoryRecord items by ID.  */
    deleteMeterReadingFilterTemplateHistoryRecords?: Maybe<Array<Maybe<MeterReadingFilterTemplateHistoryRecord>>>
    /**  Delete multiple MeterReadingFilterTemplate items by ID.  */
    deleteMeterReadingFilterTemplates?: Maybe<Array<Maybe<MeterReadingFilterTemplate>>>
    /**  Delete a single MeterReadingHistoryRecord item by ID.  */
    deleteMeterReadingHistoryRecord?: Maybe<MeterReadingHistoryRecord>
    /**  Delete multiple MeterReadingHistoryRecord items by ID.  */
    deleteMeterReadingHistoryRecords?: Maybe<Array<Maybe<MeterReadingHistoryRecord>>>
    /**  Delete a single MeterReadingSource item by ID.  */
    deleteMeterReadingSource?: Maybe<MeterReadingSource>
    /**  Delete a single MeterReadingSourceHistoryRecord item by ID.  */
    deleteMeterReadingSourceHistoryRecord?: Maybe<MeterReadingSourceHistoryRecord>
    /**  Delete multiple MeterReadingSourceHistoryRecord items by ID.  */
    deleteMeterReadingSourceHistoryRecords?: Maybe<Array<Maybe<MeterReadingSourceHistoryRecord>>>
    /**  Delete multiple MeterReadingSource items by ID.  */
    deleteMeterReadingSources?: Maybe<Array<Maybe<MeterReadingSource>>>
    /**  Delete multiple MeterReading items by ID.  */
    deleteMeterReadings?: Maybe<Array<Maybe<MeterReading>>>
    /**  Delete a single MeterReadingsImportTask item by ID.  */
    deleteMeterReadingsImportTask?: Maybe<MeterReadingsImportTask>
    /**  Delete a single MeterReadingsImportTaskHistoryRecord item by ID.  */
    deleteMeterReadingsImportTaskHistoryRecord?: Maybe<MeterReadingsImportTaskHistoryRecord>
    /**  Delete multiple MeterReadingsImportTaskHistoryRecord items by ID.  */
    deleteMeterReadingsImportTaskHistoryRecords?: Maybe<Array<Maybe<MeterReadingsImportTaskHistoryRecord>>>
    /**  Delete multiple MeterReadingsImportTask items by ID.  */
    deleteMeterReadingsImportTasks?: Maybe<Array<Maybe<MeterReadingsImportTask>>>
    /**  Delete a single MeterReportingPeriod item by ID.  */
    deleteMeterReportingPeriod?: Maybe<MeterReportingPeriod>
    /**  Delete a single MeterReportingPeriodHistoryRecord item by ID.  */
    deleteMeterReportingPeriodHistoryRecord?: Maybe<MeterReportingPeriodHistoryRecord>
    /**  Delete multiple MeterReportingPeriodHistoryRecord items by ID.  */
    deleteMeterReportingPeriodHistoryRecords?: Maybe<Array<Maybe<MeterReportingPeriodHistoryRecord>>>
    /**  Delete multiple MeterReportingPeriod items by ID.  */
    deleteMeterReportingPeriods?: Maybe<Array<Maybe<MeterReportingPeriod>>>
    /**  Delete a single MeterResource item by ID.  */
    deleteMeterResource?: Maybe<MeterResource>
    /**  Delete a single MeterResourceHistoryRecord item by ID.  */
    deleteMeterResourceHistoryRecord?: Maybe<MeterResourceHistoryRecord>
    /**  Delete multiple MeterResourceHistoryRecord items by ID.  */
    deleteMeterResourceHistoryRecords?: Maybe<Array<Maybe<MeterResourceHistoryRecord>>>
    /**  Delete a single MeterResourceOwner item by ID.  */
    deleteMeterResourceOwner?: Maybe<MeterResourceOwner>
    /**  Delete a single MeterResourceOwnerHistoryRecord item by ID.  */
    deleteMeterResourceOwnerHistoryRecord?: Maybe<MeterResourceOwnerHistoryRecord>
    /**  Delete multiple MeterResourceOwnerHistoryRecord items by ID.  */
    deleteMeterResourceOwnerHistoryRecords?: Maybe<Array<Maybe<MeterResourceOwnerHistoryRecord>>>
    /**  Delete multiple MeterResourceOwner items by ID.  */
    deleteMeterResourceOwners?: Maybe<Array<Maybe<MeterResourceOwner>>>
    /**  Delete multiple MeterResource items by ID.  */
    deleteMeterResources?: Maybe<Array<Maybe<MeterResource>>>
    /**  Delete multiple Meter items by ID.  */
    deleteMeters?: Maybe<Array<Maybe<Meter>>>
    /**  Delete a single MobileFeatureConfig item by ID.  */
    deleteMobileFeatureConfig?: Maybe<MobileFeatureConfig>
    /**  Delete a single MobileFeatureConfigHistoryRecord item by ID.  */
    deleteMobileFeatureConfigHistoryRecord?: Maybe<MobileFeatureConfigHistoryRecord>
    /**  Delete multiple MobileFeatureConfigHistoryRecord items by ID.  */
    deleteMobileFeatureConfigHistoryRecords?: Maybe<Array<Maybe<MobileFeatureConfigHistoryRecord>>>
    /**  Delete multiple MobileFeatureConfig items by ID.  */
    deleteMobileFeatureConfigs?: Maybe<Array<Maybe<MobileFeatureConfig>>>
    /**  Delete a single MultiPayment item by ID.  */
    deleteMultiPayment?: Maybe<MultiPayment>
    /**  Delete a single MultiPaymentHistoryRecord item by ID.  */
    deleteMultiPaymentHistoryRecord?: Maybe<MultiPaymentHistoryRecord>
    /**  Delete multiple MultiPaymentHistoryRecord items by ID.  */
    deleteMultiPaymentHistoryRecords?: Maybe<Array<Maybe<MultiPaymentHistoryRecord>>>
    /**  Delete multiple MultiPayment items by ID.  */
    deleteMultiPayments?: Maybe<Array<Maybe<MultiPayment>>>
    /**  Delete a single NewsItem item by ID.  */
    deleteNewsItem?: Maybe<NewsItem>
    /**  Delete a single NewsItemHistoryRecord item by ID.  */
    deleteNewsItemHistoryRecord?: Maybe<NewsItemHistoryRecord>
    /**  Delete multiple NewsItemHistoryRecord items by ID.  */
    deleteNewsItemHistoryRecords?: Maybe<Array<Maybe<NewsItemHistoryRecord>>>
    /**  Delete a single NewsItemRecipientsExportTask item by ID.  */
    deleteNewsItemRecipientsExportTask?: Maybe<NewsItemRecipientsExportTask>
    /**  Delete a single NewsItemRecipientsExportTaskHistoryRecord item by ID.  */
    deleteNewsItemRecipientsExportTaskHistoryRecord?: Maybe<NewsItemRecipientsExportTaskHistoryRecord>
    /**  Delete multiple NewsItemRecipientsExportTaskHistoryRecord items by ID.  */
    deleteNewsItemRecipientsExportTaskHistoryRecords?: Maybe<Array<Maybe<NewsItemRecipientsExportTaskHistoryRecord>>>
    /**  Delete multiple NewsItemRecipientsExportTask items by ID.  */
    deleteNewsItemRecipientsExportTasks?: Maybe<Array<Maybe<NewsItemRecipientsExportTask>>>
    /**  Delete a single NewsItemScope item by ID.  */
    deleteNewsItemScope?: Maybe<NewsItemScope>
    /**  Delete a single NewsItemScopeHistoryRecord item by ID.  */
    deleteNewsItemScopeHistoryRecord?: Maybe<NewsItemScopeHistoryRecord>
    /**  Delete multiple NewsItemScopeHistoryRecord items by ID.  */
    deleteNewsItemScopeHistoryRecords?: Maybe<Array<Maybe<NewsItemScopeHistoryRecord>>>
    /**  Delete multiple NewsItemScope items by ID.  */
    deleteNewsItemScopes?: Maybe<Array<Maybe<NewsItemScope>>>
    /**  Delete a single NewsItemSharing item by ID.  */
    deleteNewsItemSharing?: Maybe<NewsItemSharing>
    /**  Delete a single NewsItemSharingHistoryRecord item by ID.  */
    deleteNewsItemSharingHistoryRecord?: Maybe<NewsItemSharingHistoryRecord>
    /**  Delete multiple NewsItemSharingHistoryRecord items by ID.  */
    deleteNewsItemSharingHistoryRecords?: Maybe<Array<Maybe<NewsItemSharingHistoryRecord>>>
    /**  Delete multiple NewsItemSharing items by ID.  */
    deleteNewsItemSharings?: Maybe<Array<Maybe<NewsItemSharing>>>
    /**  Delete a single NewsItemTemplate item by ID.  */
    deleteNewsItemTemplate?: Maybe<NewsItemTemplate>
    /**  Delete a single NewsItemTemplateHistoryRecord item by ID.  */
    deleteNewsItemTemplateHistoryRecord?: Maybe<NewsItemTemplateHistoryRecord>
    /**  Delete multiple NewsItemTemplateHistoryRecord items by ID.  */
    deleteNewsItemTemplateHistoryRecords?: Maybe<Array<Maybe<NewsItemTemplateHistoryRecord>>>
    /**  Delete multiple NewsItemTemplate items by ID.  */
    deleteNewsItemTemplates?: Maybe<Array<Maybe<NewsItemTemplate>>>
    /**  Delete a single NewsItemUserRead item by ID.  */
    deleteNewsItemUserRead?: Maybe<NewsItemUserRead>
    /**  Delete a single NewsItemUserReadHistoryRecord item by ID.  */
    deleteNewsItemUserReadHistoryRecord?: Maybe<NewsItemUserReadHistoryRecord>
    /**  Delete multiple NewsItemUserReadHistoryRecord items by ID.  */
    deleteNewsItemUserReadHistoryRecords?: Maybe<Array<Maybe<NewsItemUserReadHistoryRecord>>>
    /**  Delete multiple NewsItemUserRead items by ID.  */
    deleteNewsItemUserReads?: Maybe<Array<Maybe<NewsItemUserRead>>>
    /**  Delete multiple NewsItem items by ID.  */
    deleteNewsItems?: Maybe<Array<Maybe<NewsItem>>>
    /**  Delete a single NotificationAnonymousSetting item by ID.  */
    deleteNotificationAnonymousSetting?: Maybe<NotificationAnonymousSetting>
    /**  Delete a single NotificationAnonymousSettingHistoryRecord item by ID.  */
    deleteNotificationAnonymousSettingHistoryRecord?: Maybe<NotificationAnonymousSettingHistoryRecord>
    /**  Delete multiple NotificationAnonymousSettingHistoryRecord items by ID.  */
    deleteNotificationAnonymousSettingHistoryRecords?: Maybe<Array<Maybe<NotificationAnonymousSettingHistoryRecord>>>
    /**  Delete multiple NotificationAnonymousSetting items by ID.  */
    deleteNotificationAnonymousSettings?: Maybe<Array<Maybe<NotificationAnonymousSetting>>>
    /**  Delete a single NotificationUserSetting item by ID.  */
    deleteNotificationUserSetting?: Maybe<NotificationUserSetting>
    /**  Delete a single NotificationUserSettingHistoryRecord item by ID.  */
    deleteNotificationUserSettingHistoryRecord?: Maybe<NotificationUserSettingHistoryRecord>
    /**  Delete multiple NotificationUserSettingHistoryRecord items by ID.  */
    deleteNotificationUserSettingHistoryRecords?: Maybe<Array<Maybe<NotificationUserSettingHistoryRecord>>>
    /**  Delete multiple NotificationUserSetting items by ID.  */
    deleteNotificationUserSettings?: Maybe<Array<Maybe<NotificationUserSetting>>>
    /**  Delete a single OidcClient item by ID.  */
    deleteOidcClient?: Maybe<OidcClient>
    /**  Delete a single OidcClientHistoryRecord item by ID.  */
    deleteOidcClientHistoryRecord?: Maybe<OidcClientHistoryRecord>
    /**  Delete multiple OidcClientHistoryRecord items by ID.  */
    deleteOidcClientHistoryRecords?: Maybe<Array<Maybe<OidcClientHistoryRecord>>>
    /**  Delete multiple OidcClient items by ID.  */
    deleteOidcClients?: Maybe<Array<Maybe<OidcClient>>>
    /**  Delete a single Organization item by ID.  */
    deleteOrganization?: Maybe<Organization>
    /**  Delete a single OrganizationEmployee item by ID.  */
    deleteOrganizationEmployee?: Maybe<OrganizationEmployee>
    /**  Delete a single OrganizationEmployeeHistoryRecord item by ID.  */
    deleteOrganizationEmployeeHistoryRecord?: Maybe<OrganizationEmployeeHistoryRecord>
    /**  Delete multiple OrganizationEmployeeHistoryRecord items by ID.  */
    deleteOrganizationEmployeeHistoryRecords?: Maybe<Array<Maybe<OrganizationEmployeeHistoryRecord>>>
    /**  Delete a single OrganizationEmployeeRole item by ID.  */
    deleteOrganizationEmployeeRole?: Maybe<OrganizationEmployeeRole>
    /**  Delete a single OrganizationEmployeeRoleHistoryRecord item by ID.  */
    deleteOrganizationEmployeeRoleHistoryRecord?: Maybe<OrganizationEmployeeRoleHistoryRecord>
    /**  Delete multiple OrganizationEmployeeRoleHistoryRecord items by ID.  */
    deleteOrganizationEmployeeRoleHistoryRecords?: Maybe<Array<Maybe<OrganizationEmployeeRoleHistoryRecord>>>
    /**  Delete multiple OrganizationEmployeeRole items by ID.  */
    deleteOrganizationEmployeeRoles?: Maybe<Array<Maybe<OrganizationEmployeeRole>>>
    /**  Delete a single OrganizationEmployeeSpecialization item by ID.  */
    deleteOrganizationEmployeeSpecialization?: Maybe<OrganizationEmployeeSpecialization>
    /**  Delete a single OrganizationEmployeeSpecializationHistoryRecord item by ID.  */
    deleteOrganizationEmployeeSpecializationHistoryRecord?: Maybe<OrganizationEmployeeSpecializationHistoryRecord>
    /**  Delete multiple OrganizationEmployeeSpecializationHistoryRecord items by ID.  */
    deleteOrganizationEmployeeSpecializationHistoryRecords?: Maybe<Array<Maybe<OrganizationEmployeeSpecializationHistoryRecord>>>
    /**  Delete multiple OrganizationEmployeeSpecialization items by ID.  */
    deleteOrganizationEmployeeSpecializations?: Maybe<Array<Maybe<OrganizationEmployeeSpecialization>>>
    /**  Delete multiple OrganizationEmployee items by ID.  */
    deleteOrganizationEmployees?: Maybe<Array<Maybe<OrganizationEmployee>>>
    /**  Delete a single OrganizationHistoryRecord item by ID.  */
    deleteOrganizationHistoryRecord?: Maybe<OrganizationHistoryRecord>
    /**  Delete multiple OrganizationHistoryRecord items by ID.  */
    deleteOrganizationHistoryRecords?: Maybe<Array<Maybe<OrganizationHistoryRecord>>>
    /**  Delete a single OrganizationLink item by ID.  */
    deleteOrganizationLink?: Maybe<OrganizationLink>
    /**  Delete a single OrganizationLinkHistoryRecord item by ID.  */
    deleteOrganizationLinkHistoryRecord?: Maybe<OrganizationLinkHistoryRecord>
    /**  Delete multiple OrganizationLinkHistoryRecord items by ID.  */
    deleteOrganizationLinkHistoryRecords?: Maybe<Array<Maybe<OrganizationLinkHistoryRecord>>>
    /**  Delete multiple OrganizationLink items by ID.  */
    deleteOrganizationLinks?: Maybe<Array<Maybe<OrganizationLink>>>
    /**  Delete multiple Organization items by ID.  */
    deleteOrganizations?: Maybe<Array<Maybe<Organization>>>
    /**  Delete a single Payment item by ID.  */
    deletePayment?: Maybe<Payment>
    /**  Delete a single PaymentHistoryRecord item by ID.  */
    deletePaymentHistoryRecord?: Maybe<PaymentHistoryRecord>
    /**  Delete multiple PaymentHistoryRecord items by ID.  */
    deletePaymentHistoryRecords?: Maybe<Array<Maybe<PaymentHistoryRecord>>>
    /**  Delete multiple Payment items by ID.  */
    deletePayments?: Maybe<Array<Maybe<Payment>>>
    /**  Delete a single PaymentsFilterTemplate item by ID.  */
    deletePaymentsFilterTemplate?: Maybe<PaymentsFilterTemplate>
    /**  Delete a single PaymentsFilterTemplateHistoryRecord item by ID.  */
    deletePaymentsFilterTemplateHistoryRecord?: Maybe<PaymentsFilterTemplateHistoryRecord>
    /**  Delete multiple PaymentsFilterTemplateHistoryRecord items by ID.  */
    deletePaymentsFilterTemplateHistoryRecords?: Maybe<Array<Maybe<PaymentsFilterTemplateHistoryRecord>>>
    /**  Delete multiple PaymentsFilterTemplate items by ID.  */
    deletePaymentsFilterTemplates?: Maybe<Array<Maybe<PaymentsFilterTemplate>>>
    /**  Delete multiple Property items by ID.  */
    deleteProperties?: Maybe<Array<Maybe<Property>>>
    /**  Delete a single Property item by ID.  */
    deleteProperty?: Maybe<Property>
    /**  Delete a single PropertyHistoryRecord item by ID.  */
    deletePropertyHistoryRecord?: Maybe<PropertyHistoryRecord>
    /**  Delete multiple PropertyHistoryRecord items by ID.  */
    deletePropertyHistoryRecords?: Maybe<Array<Maybe<PropertyHistoryRecord>>>
    /**  Delete a single PropertyMeter item by ID.  */
    deletePropertyMeter?: Maybe<PropertyMeter>
    /**  Delete a single PropertyMeterHistoryRecord item by ID.  */
    deletePropertyMeterHistoryRecord?: Maybe<PropertyMeterHistoryRecord>
    /**  Delete multiple PropertyMeterHistoryRecord items by ID.  */
    deletePropertyMeterHistoryRecords?: Maybe<Array<Maybe<PropertyMeterHistoryRecord>>>
    /**  Delete a single PropertyMeterReading item by ID.  */
    deletePropertyMeterReading?: Maybe<PropertyMeterReading>
    /**  Delete a single PropertyMeterReadingHistoryRecord item by ID.  */
    deletePropertyMeterReadingHistoryRecord?: Maybe<PropertyMeterReadingHistoryRecord>
    /**  Delete multiple PropertyMeterReadingHistoryRecord items by ID.  */
    deletePropertyMeterReadingHistoryRecords?: Maybe<Array<Maybe<PropertyMeterReadingHistoryRecord>>>
    /**  Delete multiple PropertyMeterReading items by ID.  */
    deletePropertyMeterReadings?: Maybe<Array<Maybe<PropertyMeterReading>>>
    /**  Delete multiple PropertyMeter items by ID.  */
    deletePropertyMeters?: Maybe<Array<Maybe<PropertyMeter>>>
    /**  Delete a single PropertyScope item by ID.  */
    deletePropertyScope?: Maybe<PropertyScope>
    /**  Delete a single PropertyScopeHistoryRecord item by ID.  */
    deletePropertyScopeHistoryRecord?: Maybe<PropertyScopeHistoryRecord>
    /**  Delete multiple PropertyScopeHistoryRecord items by ID.  */
    deletePropertyScopeHistoryRecords?: Maybe<Array<Maybe<PropertyScopeHistoryRecord>>>
    /**  Delete a single PropertyScopeOrganizationEmployee item by ID.  */
    deletePropertyScopeOrganizationEmployee?: Maybe<PropertyScopeOrganizationEmployee>
    /**  Delete a single PropertyScopeOrganizationEmployeeHistoryRecord item by ID.  */
    deletePropertyScopeOrganizationEmployeeHistoryRecord?: Maybe<PropertyScopeOrganizationEmployeeHistoryRecord>
    /**  Delete multiple PropertyScopeOrganizationEmployeeHistoryRecord items by ID.  */
    deletePropertyScopeOrganizationEmployeeHistoryRecords?: Maybe<Array<Maybe<PropertyScopeOrganizationEmployeeHistoryRecord>>>
    /**  Delete multiple PropertyScopeOrganizationEmployee items by ID.  */
    deletePropertyScopeOrganizationEmployees?: Maybe<Array<Maybe<PropertyScopeOrganizationEmployee>>>
    /**  Delete multiple PropertyScopeProperty items by ID.  */
    deletePropertyScopeProperties?: Maybe<Array<Maybe<PropertyScopeProperty>>>
    /**  Delete a single PropertyScopeProperty item by ID.  */
    deletePropertyScopeProperty?: Maybe<PropertyScopeProperty>
    /**  Delete a single PropertyScopePropertyHistoryRecord item by ID.  */
    deletePropertyScopePropertyHistoryRecord?: Maybe<PropertyScopePropertyHistoryRecord>
    /**  Delete multiple PropertyScopePropertyHistoryRecord items by ID.  */
    deletePropertyScopePropertyHistoryRecords?: Maybe<Array<Maybe<PropertyScopePropertyHistoryRecord>>>
    /**  Delete multiple PropertyScope items by ID.  */
    deletePropertyScopes?: Maybe<Array<Maybe<PropertyScope>>>
    /**  Delete a single RecurrentPayment item by ID.  */
    deleteRecurrentPayment?: Maybe<RecurrentPayment>
    /**  Delete a single RecurrentPaymentContext item by ID.  */
    deleteRecurrentPaymentContext?: Maybe<RecurrentPaymentContext>
    /**  Delete a single RecurrentPaymentContextHistoryRecord item by ID.  */
    deleteRecurrentPaymentContextHistoryRecord?: Maybe<RecurrentPaymentContextHistoryRecord>
    /**  Delete multiple RecurrentPaymentContextHistoryRecord items by ID.  */
    deleteRecurrentPaymentContextHistoryRecords?: Maybe<Array<Maybe<RecurrentPaymentContextHistoryRecord>>>
    /**  Delete multiple RecurrentPaymentContext items by ID.  */
    deleteRecurrentPaymentContexts?: Maybe<Array<Maybe<RecurrentPaymentContext>>>
    /**  Delete a single RecurrentPaymentHistoryRecord item by ID.  */
    deleteRecurrentPaymentHistoryRecord?: Maybe<RecurrentPaymentHistoryRecord>
    /**  Delete multiple RecurrentPaymentHistoryRecord items by ID.  */
    deleteRecurrentPaymentHistoryRecords?: Maybe<Array<Maybe<RecurrentPaymentHistoryRecord>>>
    /**  Delete multiple RecurrentPayment items by ID.  */
    deleteRecurrentPayments?: Maybe<Array<Maybe<RecurrentPayment>>>
    /**  Delete a single RemoteClient item by ID.  */
    deleteRemoteClient?: Maybe<RemoteClient>
    /**  Delete a single RemoteClientHistoryRecord item by ID.  */
    deleteRemoteClientHistoryRecord?: Maybe<RemoteClientHistoryRecord>
    /**  Delete multiple RemoteClientHistoryRecord items by ID.  */
    deleteRemoteClientHistoryRecords?: Maybe<Array<Maybe<RemoteClientHistoryRecord>>>
    /**  Delete multiple RemoteClient items by ID.  */
    deleteRemoteClients?: Maybe<Array<Maybe<RemoteClient>>>
    /**  Delete a single Resident item by ID.  */
    deleteResident?: Maybe<Resident>
    /**  Delete a single ResidentHistoryRecord item by ID.  */
    deleteResidentHistoryRecord?: Maybe<ResidentHistoryRecord>
    /**  Delete multiple ResidentHistoryRecord items by ID.  */
    deleteResidentHistoryRecords?: Maybe<Array<Maybe<ResidentHistoryRecord>>>
    /**  Delete multiple Resident items by ID.  */
    deleteResidents?: Maybe<Array<Maybe<Resident>>>
    /**  Delete a single ServiceConsumer item by ID.  */
    deleteServiceConsumer?: Maybe<ServiceConsumer>
    /**  Delete a single ServiceConsumerHistoryRecord item by ID.  */
    deleteServiceConsumerHistoryRecord?: Maybe<ServiceConsumerHistoryRecord>
    /**  Delete multiple ServiceConsumerHistoryRecord items by ID.  */
    deleteServiceConsumerHistoryRecords?: Maybe<Array<Maybe<ServiceConsumerHistoryRecord>>>
    /**  Delete multiple ServiceConsumer items by ID.  */
    deleteServiceConsumers?: Maybe<Array<Maybe<ServiceConsumer>>>
    /**  Delete a single ServiceSubscription item by ID.  */
    deleteServiceSubscription?: Maybe<ServiceSubscription>
    /**  Delete a single ServiceSubscriptionHistoryRecord item by ID.  */
    deleteServiceSubscriptionHistoryRecord?: Maybe<ServiceSubscriptionHistoryRecord>
    /**  Delete multiple ServiceSubscriptionHistoryRecord items by ID.  */
    deleteServiceSubscriptionHistoryRecords?: Maybe<Array<Maybe<ServiceSubscriptionHistoryRecord>>>
    /**  Delete multiple ServiceSubscription items by ID.  */
    deleteServiceSubscriptions?: Maybe<Array<Maybe<ServiceSubscription>>>
    /**  Delete a single TelegramUserChat item by ID.  */
    deleteTelegramUserChat?: Maybe<TelegramUserChat>
    /**  Delete a single TelegramUserChatHistoryRecord item by ID.  */
    deleteTelegramUserChatHistoryRecord?: Maybe<TelegramUserChatHistoryRecord>
    /**  Delete multiple TelegramUserChatHistoryRecord items by ID.  */
    deleteTelegramUserChatHistoryRecords?: Maybe<Array<Maybe<TelegramUserChatHistoryRecord>>>
    /**  Delete multiple TelegramUserChat items by ID.  */
    deleteTelegramUserChats?: Maybe<Array<Maybe<TelegramUserChat>>>
    /**  Delete a single Ticket item by ID.  */
    deleteTicket?: Maybe<Ticket>
    /**  Delete a single TicketAutoAssignment item by ID.  */
    deleteTicketAutoAssignment?: Maybe<TicketAutoAssignment>
    /**  Delete a single TicketAutoAssignmentHistoryRecord item by ID.  */
    deleteTicketAutoAssignmentHistoryRecord?: Maybe<TicketAutoAssignmentHistoryRecord>
    /**  Delete multiple TicketAutoAssignmentHistoryRecord items by ID.  */
    deleteTicketAutoAssignmentHistoryRecords?: Maybe<Array<Maybe<TicketAutoAssignmentHistoryRecord>>>
    /**  Delete multiple TicketAutoAssignment items by ID.  */
    deleteTicketAutoAssignments?: Maybe<Array<Maybe<TicketAutoAssignment>>>
    /**  Delete a single TicketCategoryClassifier item by ID.  */
    deleteTicketCategoryClassifier?: Maybe<TicketCategoryClassifier>
    /**  Delete a single TicketCategoryClassifierHistoryRecord item by ID.  */
    deleteTicketCategoryClassifierHistoryRecord?: Maybe<TicketCategoryClassifierHistoryRecord>
    /**  Delete multiple TicketCategoryClassifierHistoryRecord items by ID.  */
    deleteTicketCategoryClassifierHistoryRecords?: Maybe<Array<Maybe<TicketCategoryClassifierHistoryRecord>>>
    /**  Delete multiple TicketCategoryClassifier items by ID.  */
    deleteTicketCategoryClassifiers?: Maybe<Array<Maybe<TicketCategoryClassifier>>>
    /**  Delete a single TicketChange item by ID.  */
    deleteTicketChange?: Maybe<TicketChange>
    /**  Delete multiple TicketChange items by ID.  */
    deleteTicketChanges?: Maybe<Array<Maybe<TicketChange>>>
    /**  Delete a single TicketClassifier item by ID.  */
    deleteTicketClassifier?: Maybe<TicketClassifier>
    /**  Delete a single TicketClassifierHistoryRecord item by ID.  */
    deleteTicketClassifierHistoryRecord?: Maybe<TicketClassifierHistoryRecord>
    /**  Delete multiple TicketClassifierHistoryRecord items by ID.  */
    deleteTicketClassifierHistoryRecords?: Maybe<Array<Maybe<TicketClassifierHistoryRecord>>>
    /**  Delete multiple TicketClassifier items by ID.  */
    deleteTicketClassifiers?: Maybe<Array<Maybe<TicketClassifier>>>
    /**  Delete a single TicketComment item by ID.  */
    deleteTicketComment?: Maybe<TicketComment>
    /**  Delete a single TicketCommentFile item by ID.  */
    deleteTicketCommentFile?: Maybe<TicketCommentFile>
    /**  Delete a single TicketCommentFileHistoryRecord item by ID.  */
    deleteTicketCommentFileHistoryRecord?: Maybe<TicketCommentFileHistoryRecord>
    /**  Delete multiple TicketCommentFileHistoryRecord items by ID.  */
    deleteTicketCommentFileHistoryRecords?: Maybe<Array<Maybe<TicketCommentFileHistoryRecord>>>
    /**  Delete multiple TicketCommentFile items by ID.  */
    deleteTicketCommentFiles?: Maybe<Array<Maybe<TicketCommentFile>>>
    /**  Delete a single TicketCommentHistoryRecord item by ID.  */
    deleteTicketCommentHistoryRecord?: Maybe<TicketCommentHistoryRecord>
    /**  Delete multiple TicketCommentHistoryRecord items by ID.  */
    deleteTicketCommentHistoryRecords?: Maybe<Array<Maybe<TicketCommentHistoryRecord>>>
    /**  Delete multiple TicketComment items by ID.  */
    deleteTicketComments?: Maybe<Array<Maybe<TicketComment>>>
    /**  Delete a single TicketDocumentGenerationTask item by ID.  */
    deleteTicketDocumentGenerationTask?: Maybe<TicketDocumentGenerationTask>
    /**  Delete a single TicketDocumentGenerationTaskHistoryRecord item by ID.  */
    deleteTicketDocumentGenerationTaskHistoryRecord?: Maybe<TicketDocumentGenerationTaskHistoryRecord>
    /**  Delete multiple TicketDocumentGenerationTaskHistoryRecord items by ID.  */
    deleteTicketDocumentGenerationTaskHistoryRecords?: Maybe<Array<Maybe<TicketDocumentGenerationTaskHistoryRecord>>>
    /**  Delete multiple TicketDocumentGenerationTask items by ID.  */
    deleteTicketDocumentGenerationTasks?: Maybe<Array<Maybe<TicketDocumentGenerationTask>>>
    /**  Delete a single TicketExportTask item by ID.  */
    deleteTicketExportTask?: Maybe<TicketExportTask>
    /**  Delete a single TicketExportTaskHistoryRecord item by ID.  */
    deleteTicketExportTaskHistoryRecord?: Maybe<TicketExportTaskHistoryRecord>
    /**  Delete multiple TicketExportTaskHistoryRecord items by ID.  */
    deleteTicketExportTaskHistoryRecords?: Maybe<Array<Maybe<TicketExportTaskHistoryRecord>>>
    /**  Delete multiple TicketExportTask items by ID.  */
    deleteTicketExportTasks?: Maybe<Array<Maybe<TicketExportTask>>>
    /**  Delete a single TicketFile item by ID.  */
    deleteTicketFile?: Maybe<TicketFile>
    /**  Delete a single TicketFileHistoryRecord item by ID.  */
    deleteTicketFileHistoryRecord?: Maybe<TicketFileHistoryRecord>
    /**  Delete multiple TicketFileHistoryRecord items by ID.  */
    deleteTicketFileHistoryRecords?: Maybe<Array<Maybe<TicketFileHistoryRecord>>>
    /**  Delete multiple TicketFile items by ID.  */
    deleteTicketFiles?: Maybe<Array<Maybe<TicketFile>>>
    /**  Delete a single TicketFilterTemplate item by ID.  */
    deleteTicketFilterTemplate?: Maybe<TicketFilterTemplate>
    /**  Delete a single TicketFilterTemplateHistoryRecord item by ID.  */
    deleteTicketFilterTemplateHistoryRecord?: Maybe<TicketFilterTemplateHistoryRecord>
    /**  Delete multiple TicketFilterTemplateHistoryRecord items by ID.  */
    deleteTicketFilterTemplateHistoryRecords?: Maybe<Array<Maybe<TicketFilterTemplateHistoryRecord>>>
    /**  Delete multiple TicketFilterTemplate items by ID.  */
    deleteTicketFilterTemplates?: Maybe<Array<Maybe<TicketFilterTemplate>>>
    /**  Delete a single TicketHistoryRecord item by ID.  */
    deleteTicketHistoryRecord?: Maybe<TicketHistoryRecord>
    /**  Delete multiple TicketHistoryRecord items by ID.  */
    deleteTicketHistoryRecords?: Maybe<Array<Maybe<TicketHistoryRecord>>>
    /**  Delete a single TicketOrganizationSetting item by ID.  */
    deleteTicketOrganizationSetting?: Maybe<TicketOrganizationSetting>
    /**  Delete a single TicketOrganizationSettingHistoryRecord item by ID.  */
    deleteTicketOrganizationSettingHistoryRecord?: Maybe<TicketOrganizationSettingHistoryRecord>
    /**  Delete multiple TicketOrganizationSettingHistoryRecord items by ID.  */
    deleteTicketOrganizationSettingHistoryRecords?: Maybe<Array<Maybe<TicketOrganizationSettingHistoryRecord>>>
    /**  Delete multiple TicketOrganizationSetting items by ID.  */
    deleteTicketOrganizationSettings?: Maybe<Array<Maybe<TicketOrganizationSetting>>>
    /**  Delete a single TicketPlaceClassifier item by ID.  */
    deleteTicketPlaceClassifier?: Maybe<TicketPlaceClassifier>
    /**  Delete a single TicketPlaceClassifierHistoryRecord item by ID.  */
    deleteTicketPlaceClassifierHistoryRecord?: Maybe<TicketPlaceClassifierHistoryRecord>
    /**  Delete multiple TicketPlaceClassifierHistoryRecord items by ID.  */
    deleteTicketPlaceClassifierHistoryRecords?: Maybe<Array<Maybe<TicketPlaceClassifierHistoryRecord>>>
    /**  Delete multiple TicketPlaceClassifier items by ID.  */
    deleteTicketPlaceClassifiers?: Maybe<Array<Maybe<TicketPlaceClassifier>>>
    /**  Delete a single TicketProblemClassifier item by ID.  */
    deleteTicketProblemClassifier?: Maybe<TicketProblemClassifier>
    /**  Delete a single TicketProblemClassifierHistoryRecord item by ID.  */
    deleteTicketProblemClassifierHistoryRecord?: Maybe<TicketProblemClassifierHistoryRecord>
    /**  Delete multiple TicketProblemClassifierHistoryRecord items by ID.  */
    deleteTicketProblemClassifierHistoryRecords?: Maybe<Array<Maybe<TicketProblemClassifierHistoryRecord>>>
    /**  Delete multiple TicketProblemClassifier items by ID.  */
    deleteTicketProblemClassifiers?: Maybe<Array<Maybe<TicketProblemClassifier>>>
    /**  Delete a single TicketPropertyHint item by ID.  */
    deleteTicketPropertyHint?: Maybe<TicketPropertyHint>
    /**  Delete a single TicketPropertyHintHistoryRecord item by ID.  */
    deleteTicketPropertyHintHistoryRecord?: Maybe<TicketPropertyHintHistoryRecord>
    /**  Delete multiple TicketPropertyHintHistoryRecord items by ID.  */
    deleteTicketPropertyHintHistoryRecords?: Maybe<Array<Maybe<TicketPropertyHintHistoryRecord>>>
    /**  Delete multiple TicketPropertyHintProperty items by ID.  */
    deleteTicketPropertyHintProperties?: Maybe<Array<Maybe<TicketPropertyHintProperty>>>
    /**  Delete a single TicketPropertyHintProperty item by ID.  */
    deleteTicketPropertyHintProperty?: Maybe<TicketPropertyHintProperty>
    /**  Delete a single TicketPropertyHintPropertyHistoryRecord item by ID.  */
    deleteTicketPropertyHintPropertyHistoryRecord?: Maybe<TicketPropertyHintPropertyHistoryRecord>
    /**  Delete multiple TicketPropertyHintPropertyHistoryRecord items by ID.  */
    deleteTicketPropertyHintPropertyHistoryRecords?: Maybe<Array<Maybe<TicketPropertyHintPropertyHistoryRecord>>>
    /**  Delete multiple TicketPropertyHint items by ID.  */
    deleteTicketPropertyHints?: Maybe<Array<Maybe<TicketPropertyHint>>>
    /**  Delete a single TicketSource item by ID.  */
    deleteTicketSource?: Maybe<TicketSource>
    /**  Delete a single TicketSourceHistoryRecord item by ID.  */
    deleteTicketSourceHistoryRecord?: Maybe<TicketSourceHistoryRecord>
    /**  Delete multiple TicketSourceHistoryRecord items by ID.  */
    deleteTicketSourceHistoryRecords?: Maybe<Array<Maybe<TicketSourceHistoryRecord>>>
    /**  Delete multiple TicketSource items by ID.  */
    deleteTicketSources?: Maybe<Array<Maybe<TicketSource>>>
    /**  Delete a single TicketStatus item by ID.  */
    deleteTicketStatus?: Maybe<TicketStatus>
    /**  Delete a single TicketStatusHistoryRecord item by ID.  */
    deleteTicketStatusHistoryRecord?: Maybe<TicketStatusHistoryRecord>
    /**  Delete multiple TicketStatusHistoryRecord items by ID.  */
    deleteTicketStatusHistoryRecords?: Maybe<Array<Maybe<TicketStatusHistoryRecord>>>
    /**  Delete multiple TicketStatus items by ID.  */
    deleteTicketStatuses?: Maybe<Array<Maybe<TicketStatus>>>
    /**  Delete multiple Ticket items by ID.  */
    deleteTickets?: Maybe<Array<Maybe<Ticket>>>
    /**  Delete a single TourStep item by ID.  */
    deleteTourStep?: Maybe<TourStep>
    /**  Delete a single TourStepHistoryRecord item by ID.  */
    deleteTourStepHistoryRecord?: Maybe<TourStepHistoryRecord>
    /**  Delete multiple TourStepHistoryRecord items by ID.  */
    deleteTourStepHistoryRecords?: Maybe<Array<Maybe<TourStepHistoryRecord>>>
    /**  Delete multiple TourStep items by ID.  */
    deleteTourSteps?: Maybe<Array<Maybe<TourStep>>>
    /**  Delete a single User item by ID.  */
    deleteUser?: Maybe<User>
    /**  Delete multiple UserExternalIdentity items by ID.  */
    deleteUserExternalIdentities?: Maybe<Array<Maybe<UserExternalIdentity>>>
    /**  Delete a single UserExternalIdentity item by ID.  */
    deleteUserExternalIdentity?: Maybe<UserExternalIdentity>
    /**  Delete a single UserExternalIdentityHistoryRecord item by ID.  */
    deleteUserExternalIdentityHistoryRecord?: Maybe<UserExternalIdentityHistoryRecord>
    /**  Delete multiple UserExternalIdentityHistoryRecord items by ID.  */
    deleteUserExternalIdentityHistoryRecords?: Maybe<Array<Maybe<UserExternalIdentityHistoryRecord>>>
    /**  Delete a single UserFavoriteTicket item by ID.  */
    deleteUserFavoriteTicket?: Maybe<UserFavoriteTicket>
    /**  Delete a single UserFavoriteTicketHistoryRecord item by ID.  */
    deleteUserFavoriteTicketHistoryRecord?: Maybe<UserFavoriteTicketHistoryRecord>
    /**  Delete multiple UserFavoriteTicketHistoryRecord items by ID.  */
    deleteUserFavoriteTicketHistoryRecords?: Maybe<Array<Maybe<UserFavoriteTicketHistoryRecord>>>
    /**  Delete multiple UserFavoriteTicket items by ID.  */
    deleteUserFavoriteTickets?: Maybe<Array<Maybe<UserFavoriteTicket>>>
    /**  Delete a single UserHelpRequest item by ID.  */
    deleteUserHelpRequest?: Maybe<UserHelpRequest>
    /**  Delete a single UserHelpRequestFile item by ID.  */
    deleteUserHelpRequestFile?: Maybe<UserHelpRequestFile>
    /**  Delete a single UserHelpRequestFileHistoryRecord item by ID.  */
    deleteUserHelpRequestFileHistoryRecord?: Maybe<UserHelpRequestFileHistoryRecord>
    /**  Delete multiple UserHelpRequestFileHistoryRecord items by ID.  */
    deleteUserHelpRequestFileHistoryRecords?: Maybe<Array<Maybe<UserHelpRequestFileHistoryRecord>>>
    /**  Delete multiple UserHelpRequestFile items by ID.  */
    deleteUserHelpRequestFiles?: Maybe<Array<Maybe<UserHelpRequestFile>>>
    /**  Delete a single UserHelpRequestHistoryRecord item by ID.  */
    deleteUserHelpRequestHistoryRecord?: Maybe<UserHelpRequestHistoryRecord>
    /**  Delete multiple UserHelpRequestHistoryRecord items by ID.  */
    deleteUserHelpRequestHistoryRecords?: Maybe<Array<Maybe<UserHelpRequestHistoryRecord>>>
    /**  Delete multiple UserHelpRequest items by ID.  */
    deleteUserHelpRequests?: Maybe<Array<Maybe<UserHelpRequest>>>
    /**  Delete a single UserHistoryRecord item by ID.  */
    deleteUserHistoryRecord?: Maybe<UserHistoryRecord>
    /**  Delete multiple UserHistoryRecord items by ID.  */
    deleteUserHistoryRecords?: Maybe<Array<Maybe<UserHistoryRecord>>>
    /**  Delete a single UserRightsSet item by ID.  */
    deleteUserRightsSet?: Maybe<UserRightsSet>
    /**  Delete a single UserRightsSetHistoryRecord item by ID.  */
    deleteUserRightsSetHistoryRecord?: Maybe<UserRightsSetHistoryRecord>
    /**  Delete multiple UserRightsSetHistoryRecord items by ID.  */
    deleteUserRightsSetHistoryRecords?: Maybe<Array<Maybe<UserRightsSetHistoryRecord>>>
    /**  Delete multiple UserRightsSet items by ID.  */
    deleteUserRightsSets?: Maybe<Array<Maybe<UserRightsSet>>>
    /**  Delete a single UserTicketCommentReadTime item by ID.  */
    deleteUserTicketCommentReadTime?: Maybe<UserTicketCommentReadTime>
    /**  Delete a single UserTicketCommentReadTimeHistoryRecord item by ID.  */
    deleteUserTicketCommentReadTimeHistoryRecord?: Maybe<UserTicketCommentReadTimeHistoryRecord>
    /**  Delete multiple UserTicketCommentReadTimeHistoryRecord items by ID.  */
    deleteUserTicketCommentReadTimeHistoryRecords?: Maybe<Array<Maybe<UserTicketCommentReadTimeHistoryRecord>>>
    /**  Delete multiple UserTicketCommentReadTime items by ID.  */
    deleteUserTicketCommentReadTimes?: Maybe<Array<Maybe<UserTicketCommentReadTime>>>
    /**  Delete multiple User items by ID.  */
    deleteUsers?: Maybe<Array<Maybe<User>>>
    /**  Delete a single Webhook item by ID.  */
    deleteWebhook?: Maybe<Webhook>
    /**  Delete a single WebhookHistoryRecord item by ID.  */
    deleteWebhookHistoryRecord?: Maybe<WebhookHistoryRecord>
    /**  Delete multiple WebhookHistoryRecord items by ID.  */
    deleteWebhookHistoryRecords?: Maybe<Array<Maybe<WebhookHistoryRecord>>>
    /**  Delete a single WebhookSubscription item by ID.  */
    deleteWebhookSubscription?: Maybe<WebhookSubscription>
    /**  Delete a single WebhookSubscriptionHistoryRecord item by ID.  */
    deleteWebhookSubscriptionHistoryRecord?: Maybe<WebhookSubscriptionHistoryRecord>
    /**  Delete multiple WebhookSubscriptionHistoryRecord items by ID.  */
    deleteWebhookSubscriptionHistoryRecords?: Maybe<Array<Maybe<WebhookSubscriptionHistoryRecord>>>
    /**  Delete multiple WebhookSubscription items by ID.  */
    deleteWebhookSubscriptions?: Maybe<Array<Maybe<WebhookSubscription>>>
    /**  Delete multiple Webhook items by ID.  */
    deleteWebhooks?: Maybe<Array<Maybe<Webhook>>>
    disconnectUserFromRemoteClient?: Maybe<DisconnectUserFromRemoteClientOutput>
    discoverServiceConsumers?: Maybe<DiscoverServiceConsumersOutput>
    /**
   * Invites staff-user into specified Organization
   *
   * For corresponding User record it creates a new OrganizationEmployee and sends message with notification about invitation
   * It tries to find already existing User with type "staff" first by phone, then by email.
   * If User is not found, it will be registered.
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "ALREADY_INVITED_EMAIL",
   *   "message": "Employee with same email already invited into the organization",
   *   "messageForUser": "Employee with same email is already in the organization"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "ALREADY_INVITED_PHONE",
   *   "message": "Employee with same phone already invited into the organization",
   *   "messageForUser": "Employee with same phone is already in the organization"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "WRONG_PHONE_FORMAT",
   *   "message": "Wrong phone format",
   *   "messageForUser": "Wrong phone number format"
   * }`
   *
   * `{
   *   "code": "INTERNAL_ERROR",
   *   "type": "UNABLE_TO_REGISTER_USER",
   *   "message": "Unable to register user",
   *   "messageForUser": "Unable to register user"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "DV_VERSION_MISMATCH",
   *   "message": "Wrong value for data version number"
   * }`
   */
    inviteNewOrganizationEmployee?: Maybe<OrganizationEmployee>
    /**
   * Tries to send notification message again to already invited user
   *
   *
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "WRONG_FORMAT",
   *   "message": "Wrong phone format",
   *   "messageForUser": "Wrong phone number format"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "NOT_FOUND",
   *   "message": "Could not find Organization by specified search criteria",
   *   "messageForUser": "Could not find organization to which this employee belongs"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "NOT_FOUND",
   *   "message": "Could not find User by specified phone or email",
   *   "messageForUser": "Could not find user using specified contacts"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "NOT_FOUND",
   *   "message": "Could not find OrganizationEmployee that has not accepted invitation for found User",
   *   "messageForUser": "Could not employee using specified contacts"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "ALREADY_ACCEPTED_INVITATION",
   *   "message": "Corresponding OrganizationEmployee has already accepted invitation",
   *   "messageForUser": "Specified employee has already accepted invitation"
   * }`
   */
    reInviteOrganizationEmployee?: Maybe<OrganizationEmployee>
    registerBillingReceiptFile?: Maybe<RegisterBillingReceiptFileOutput>
    registerBillingReceipts?: Maybe<Array<Maybe<BillingReceipt>>>
    /**
   * Create meter readings and, if not exists, meters.
   *
   * Use dates in UTC format (YYYY-MM-DDTHH:mm:ss.SSSZ) or in YYYY-MM-DD. You should prefer UTC.
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "TOO_MUCH_READINGS",
   *   "message": "Too much readings. ?? sent, limit is 500.",
   *   "messageForUser": "Too much readings. ?? sent, limit is 500."
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "ORGANIZATION_NOT_FOUND",
   *   "message": "Organization not found",
   *   "messageForUser": "Organization not found"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "PROPERTY_NOT_FOUND",
   *   "message": "Property not found",
   *   "messageForUser": "Property not found"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "INVALID_METER_VALUES",
   *   "message": "Invalid meter values",
   *   "messageForUser": "Invalid values: \"{column}\"=\"{errorValue}\""
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "MULTIPLE_METERS_FOUND",
   *   "message": "Multiple meters found",
   *   "messageForUser": "Multiple meters found (??)"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "INVALID_ACCOUNT_NUMBER",
   *   "message": "Invalid account number",
   *   "messageForUser": "Account number field is filled incorrectly"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "INVALID_METER_NUMBER",
   *   "message": "Invalid meter number",
   *   "messageForUser": "Meter number field is filled incorrectly"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "INVALID_DATE",
   *   "message": "Invalid date",
   *   "messageForUser": "Incorrect date format in column \"columnName\". Use value of \"Text\" type in format: \"YYYY-MM-DDTHH:mm:ss.SSS[Z]\", \"YYYY-MM-DD\"."
   * }`
   */
    registerMetersReadings?: Maybe<Array<Maybe<RegisterMetersReadingsOutput>>>
    registerMultiPayment?: Maybe<RegisterMultiPaymentOutput>
    registerMultiPaymentForInvoices?: Maybe<RegisterMultiPaymentForInvoicesOutput>
    registerMultiPaymentForOneReceipt?: Maybe<RegisterMultiPaymentForOneReceiptOutput>
    registerMultiPaymentForVirtualReceipt?: Maybe<RegisterMultiPaymentForOneReceiptOutput>
    /**
   * Registers new Organization for current user
   *
   * Creates new Organization, new OrganizationEmployee for current user, creates a set of default OrganizationEmployeeRole for organization and connects created OrganizationEmployee to "Admin" OrganizationEmployeeRole, creates trial ServiceSubscription for organization
   */
    registerNewOrganization?: Maybe<Organization>
    registerNewServiceUser?: Maybe<RegisterNewServiceUserOutput>
    /**
   * Registers new user and sends notification
   *
   * User will be registered only in case of correct provided token of phone confirmation action. After successful registration, phone confirmation action will be marked as completed and will not be allowed for further usage
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "UNABLE_TO_FIND_CONFIRM_PHONE_ACTION",
   *   "message": "Unable to find confirm phone action",
   *   "messageForUser": "Something went wrong during phone confirmation :( Try again or ask our support"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "WRONG_PHONE_FORMAT",
   *   "message": "Wrong format of provided phone number",
   *   "messageForUser": "Wrong phone number format",
   *   "correctExample": "+79991234567"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "NO_CONFIRM_PHONE_ACTION_TOKEN",
   *   "message": "\"confirmPhoneActionToken\" cannot be empty",
   *   "messageForUser": "Something went wrong during phone confirmation :( Try again or ask our support"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "INVALID_PASSWORD_LENGTH",
   *   "message": "Password length must be between 8 and 128 characters",
   *   "messageForUser": "Password length must be between 8 and 128 characters"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "PASSWORD_CONTAINS_EMAIL",
   *   "message": "Password must not contain email",
   *   "messageForUser": "Password must not contain email"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "PASSWORD_CONTAINS_PHONE",
   *   "message": "Password must not contain phone",
   *   "messageForUser": "Password must not contain phone"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "PASSWORD_IS_FREQUENTLY_USED",
   *   "message": "The password is too simple. We found it in the list of stolen passwords. You need to use something more secure",
   *   "messageForUser": "This password is weak or too obvious please choose something more secure"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "PASSWORD_CONSISTS_OF_SMALL_SET_OF_CHARACTERS",
   *   "message": "Password must contain at least 4 different characters",
   *   "messageForUser": "Password must contain at least 4 different characters"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "NOT_UNIQUE",
   *   "message": "User with specified phone already exists",
   *   "messageForUser": "User with specified phone number already exists. Try to login or reset password"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "NOT_UNIQUE",
   *   "message": "User with specified email already exists",
   *   "messageForUser": "User with specified email number already exists. Try to login or reset password"
   * }`
   *
   * `{
   *   "code": "INTERNAL_ERROR",
   *   "type": "UNABLE_TO_CREATE_USER",
   *   "message": "Unable to create user",
   *   "messageForUser": "Something went wrong during registration :( Try again or ask our support"
   * }`
   */
    registerNewUser?: Maybe<User>
    registerResident?: Maybe<Resident>
    registerResidentInvoice?: Maybe<Invoice>
    /**
   * Creates service consumers with default data, and automatically populates the optional data fields, such as `billingAccount
   *
   * Search for accountNumbers across all organizations matching resident address and provided accountNumber. May return more than one ServiceConsumer in case of some organizations at resident address had same accountNumber. To be successfully created accountNumber and unitName should at least have billingAccount with same data or Meter with same data
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "NOT_FOUND",
   *   "message": "Cannot find Resident for current user"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "NOT_FOUND",
   *   "message": "Cannot find Organization for current user"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "NOT_FOUND",
   *   "message": "Cannot find Organization for current resident"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "NOT_FOUND",
   *   "message": "Can't find billingAccount and any meters with this accountNumber, unitName and address",
   *   "messageForUser": "No receipts or meters related to the entered account number were found. Please contact the management company."
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "NOT_FOUND",
   *   "message": "Can't find billingAccount and any meters with this accountNumber, unitName and organization combination",
   *   "messageForUser": "No receipts or meters related to the entered account number were found. Please contact the management company."
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "WRONG_FORMAT",
   *   "message": "Argument \"accountNumber\" is null or empty"
   * }`
   */
    registerResidentServiceConsumers?: Maybe<Array<ServiceConsumer>>
    /**
   * Creates service consumer with default data, and automatically populates the optional data fields, such as `billingAccount
   *
   * To be successfully created accountNumber and unitName should at least have billingAccount with same data or Meter with same data
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "NOT_FOUND",
   *   "message": "Cannot find Resident for current user"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "NOT_FOUND",
   *   "message": "Cannot find Organization for current user"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "NOT_FOUND",
   *   "message": "Cannot find Organization for current resident"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "NOT_FOUND",
   *   "message": "Can't find billingAccount and any meters with this accountNumber, unitName and address",
   *   "messageForUser": "No receipts or meters related to the entered account number were found. Please contact the management company."
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "NOT_FOUND",
   *   "message": "Can't find billingAccount and any meters with this accountNumber, unitName and organization combination",
   *   "messageForUser": "No receipts or meters related to the entered account number were found. Please contact the management company."
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "WRONG_FORMAT",
   *   "message": "Argument \"accountNumber\" is null or empty"
   * }`
   */
    registerServiceConsumer?: Maybe<ServiceConsumer>
    /**
   * Replaces old role "A" with new role "B" for all employees with role "A"
   *
   * Replaces old role "A" with new role "B" for all employees with role "A". By default, old role is retained. If you pass the “withDeletionOldRole” flag, then old role will be deleted.
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "DV_VERSION_MISMATCH",
   *   "message": "Wrong value for data version number"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "WRONG_FORMAT",
   *   "message": "Invalid format of \"sender\" field value. Please, check the example for details",
   *   "correctExample": "{ \"dv\": 1, \"fingerprint\": \"uniq-device-or-container-id\" }"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "ORGANIZATION_NOT_FOUND",
   *   "message": "Organization not found",
   *   "messageForUser": "Organization not found"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "OLD_ROLE_NOT_FOUND",
   *   "message": "Old role not found in specified organization",
   *   "messageForUser": "Old role not found in your organization"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "NEW_ROLE_NOT_FOUND",
   *   "message": "New role not found in specified organization",
   *   "messageForUser": "New role not found in your organization"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "DEFAULT_ROLE_CANNOT_BE_DELETED",
   *   "message": "The default role cannot be deleted",
   *   "messageForUser": "The default role cannot be deleted"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "ROLES_MUST_BE_DIFFERENT",
   *   "message": "The old role and new role must be different",
   *   "messageForUser": "The new and old roles cannot be the same"
   * }`
   *
   * `{
   *   "code": "TOO_MANY_REQUESTS",
   *   "type": "ROLES_ARE_BEING_PROCESSED",
   *   "message": "These roles are already being processed. Please try again a little later",
   *   "messageForUser": "These roles are already being processed. Please try again a little later"
   * }`
   */
    replaceOrganizationEmployeeRole?: Maybe<ReplaceOrganizationEmployeeRoleOutput>
    /**
   * Resend the confirm phone SMS message for existing token
   *
   *
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "CAPTCHA_CHECK_FAILED",
   *   "message": "Failed to check CAPTCHA",
   *   "messageForUser": "The operation could not be completed. Please update the application"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "UNABLE_TO_FIND_CONFIRM_PHONE_ACTION",
   *   "message": "Confirm phone action was expired or it could not be found. Try to initiate phone confirmation again",
   *   "messageForUser": "Unable to verify your phone number. Try again or ask our support"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "TOO_MANY_REQUESTS",
   *   "message": "You have to wait 5000 seconds to be able to send request again",
   *   "messageForUser": "You have to wait 5000 sec. to be able to send request again"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "SMS_FOR_PHONE_DAY_LIMIT_REACHED",
   *   "message": "Too many sms requests for this phone number. Try again tomorrow"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "SMS_FOR_IP_DAY_LIMIT_REACHED",
   *   "message": "Too many sms requests from this ip address. Try again tomorrow"
   * }`
   */
    resendConfirmPhoneActionSms?: Maybe<ResendConfirmPhoneActionSmsOutput>
    resendMessage?: Maybe<ResendMessageOutput>
    resetOrganization?: Maybe<ResetOrganizationOutput>
    /**
   * Used for cleaning existing user to avoid utilizing every time new phone and email
   *
   *
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "DV_VERSION_MISMATCH",
   *   "message": "Unsupported value for dv"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "USER_NOT_FOUND",
   *   "message": "Could not find User by provided id"
   * }`
   *
   * `{
   *   "code": "FORBIDDEN",
   *   "type": "CANNOT_RESET_ADMIN_USER",
   *   "message": "You cannot reset admin user"
   * }`
   */
    resetUser?: Maybe<ResetUserOutput>
    sendB2CAppPushMessage?: Maybe<SendB2CAppPushMessageOutput>
    /**
   * Sends message of specified type to specified contact
   *
   * Each message type has specific set of required fields:
   *
   * `{
   * 	"TICKET_CREATED": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"ticketId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"ticketNumber": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"ticketStatus": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"ticketAddress": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"ticketUnit": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"ticketCreatedAt": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"ticketDetails": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"ticketClassifier": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"organizationId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"organizationName": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			}
   * 		},
   * 		"telegramMeta": {
   * 			"required": false
   * 		}
   * 	},
   * 	"TICKET_COMMENT_CREATED": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"ticketId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"ticketNumber": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"ticketStatus": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"ticketAddress": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"ticketUnit": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"ticketDetails": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"ticketClassifier": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"commentId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"commentContent": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"commentCreatedAt": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"commentType": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"commentTypeMessage": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"organizationId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"organizationName": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			}
   * 		},
   * 		"telegramMeta": {
   * 			"required": false
   * 		}
   * 	},
   * 	"INVITE_NEW_EMPLOYEE": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"inviteCode": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"userName": {
   * 			"defaultValue": "USERNAME",
   * 			"required": false
   * 		},
   * 		"userEmail": {
   * 			"defaultValue": "",
   * 			"required": false
   * 		},
   * 		"userPhone": {
   * 			"defaultValue": "",
   * 			"required": false
   * 		},
   * 		"organizationName": {
   * 			"defaultValue": "ORGANIZATION",
   * 			"required": false
   * 		}
   * 	},
   * 	"SHARE_TICKET": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"ticketNumber": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"date": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"id": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"details": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		}
   * 	},
   * 	"BANK_ACCOUNT_CREATION_REQUEST": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"bankAccountClient": {
   * 			"defaultValue": {},
   * 			"required": true
   * 		},
   * 		"tin": {
   * 			"required": true
   * 		},
   * 		"name": {
   * 			"required": true
   * 		},
   * 		"propertyAddress": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		}
   * 	},
   * 	"DIRTY_INVITE_NEW_EMPLOYEE_SMS": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"organizationName": {
   * 			"defaultValue": "ORGANIZATION",
   * 			"required": false
   * 		},
   * 		"serverUrl": {
   * 			"defaultValue": "",
   * 			"required": false
   * 		}
   * 	},
   * 	"DIRTY_INVITE_NEW_EMPLOYEE_EMAIL": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"organizationName": {
   * 			"defaultValue": "ORGANIZATION",
   * 			"required": false
   * 		},
   * 		"serverUrl": {
   * 			"defaultValue": "",
   * 			"required": false
   * 		}
   * 	},
   * 	"REGISTER_NEW_USER": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"userPhone": {
   * 			"defaultValue": "",
   * 			"required": false
   * 		},
   * 		"userPassword": {
   * 			"defaultValue": "",
   * 			"required": false
   * 		}
   * 	},
   * 	"SMS_VERIFY": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"smsCode": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"appId": {
   * 			"required": false
   * 		}
   * 	},
   * 	"DEVELOPER_IMPORTANT_NOTE_TYPE": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"type": {
   * 			"defaultValue": "UNKNOWN",
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"defaultValue": null,
   * 			"required": true
   * 		}
   * 	},
   * 	"CUSTOMER_IMPORTANT_NOTE_TYPE": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"type": {
   * 			"defaultValue": "UNKNOWN",
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"defaultValue": null,
   * 			"required": true
   * 		}
   * 	},
   * 	"MESSAGE_FORWARDED_TO_SUPPORT": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"text": {
   * 			"defaultValue": null,
   * 			"required": true
   * 		},
   * 		"residentsExtraInfo": {
   * 			"defaultValue": null,
   * 			"required": true
   * 		},
   * 		"os": {
   * 			"defaultValue": null,
   * 			"required": true
   * 		},
   * 		"appVersion": {
   * 			"defaultValue": null,
   * 			"required": true
   * 		},
   * 		"attachments": {
   * 			"defaultValue": [],
   * 			"isRequired": false
   * 		}
   * 	},
   * 	"TICKET_ASSIGNEE_CONNECTED": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"ticketId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"ticketNumber": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"organizationId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"TICKET_EXECUTOR_CONNECTED": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"ticketId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"ticketNumber": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"organizationId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"TRACK_TICKET_IN_DOMA_APP": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"organization": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"TICKET_STATUS_OPENED": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"ticketId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"ticketNumber": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"TICKET_STATUS_IN_PROGRESS": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"ticketId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"ticketNumber": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"TICKET_STATUS_COMPLETED": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"ticketId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"ticketNumber": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"TICKET_STATUS_RETURNED": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"ticketId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"ticketNumber": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"TICKET_STATUS_DECLINED": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"ticketId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"ticketNumber": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"TICKET_COMMENT_ADDED": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"ticketId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"ticketNumber": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"commentId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"organizationId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"METER_VERIFICATION_DATE_REMINDER": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"reminderDate": {
   * 				"required": true
   * 			},
   * 			"meterId": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"RESIDENT_ADD_BILLING_ACCOUNT": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"residentIds": {
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"BILLING_RECEIPT_AVAILABLE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"receiptId": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"accountId": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"period": {
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"BILLING_RECEIPT_AVAILABLE_NO_ACCOUNT": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"residentIds": {
   * 				"required": true
   * 			},
   * 			"propertyId": {
   * 				"required": true
   * 			},
   * 			"period": {
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"BILLING_RECEIPT_CATEGORY_AVAILABLE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"propertyId": {
   * 				"required": true
   * 			},
   * 			"period": {
   * 				"required": true
   * 			},
   * 			"categoryId": {
   * 				"required": true
   * 			}
   * 		},
   * 		"categoryName": {
   * 			"required": true
   * 		}
   * 	},
   * 	"BILLING_RECEIPT_ADDED": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"residentId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"billingReceiptId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"billingAccountId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"billingPropertyId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"period": {
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"BILLING_RECEIPT_FILE_ADDED": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"organization": {
   * 				"required": true
   * 			},
   * 			"year": {
   * 				"required": true
   * 			},
   * 			"month": {
   * 				"required": true
   * 			}
   * 		},
   * 		"attachments": {
   * 			"defaultValue": [],
   * 			"isRequired": false
   * 		}
   * 	},
   * 	"BILLING_RECEIPT_ADDED_WITH_DEBT": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"residentId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"billingReceiptId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"billingAccountId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"billingPropertyId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"period": {
   * 				"required": true
   * 			},
   * 			"category": {
   * 				"required": true
   * 			},
   * 			"toPay": {
   * 				"required": true
   * 			},
   * 			"currencyCode": {
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"BILLING_RECEIPT_ADDED_WITH_NO_DEBT": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"residentId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"billingReceiptId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"billingAccountId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"billingPropertyId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"period": {
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"METER_SUBMIT_READINGS_REMINDER": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"meterId": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"METER_SUBMIT_READINGS_REMINDER_START_PERIOD": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"monthName": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"meterId": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"METER_SUBMIT_READINGS_REMINDER_END_PERIOD": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"monthName": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"meterId": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"METER_VERIFICATION_DATE_EXPIRED": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"meterId": {
   * 				"required": true
   * 			},
   * 			"resource": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"RESIDENT_UPGRADE_APP": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"userType": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"STAFF_UPGRADE_APP": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"userType": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"CUSTOM_CONTENT_MESSAGE_PUSH": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"title": {
   * 			"required": false
   * 		},
   * 		"body": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"userId": {
   * 				"required": false
   * 			},
   * 			"remoteClient": {
   * 				"required": false
   * 			},
   * 			"url": {
   * 				"defaultValue": "",
   * 				"required": false
   * 			},
   * 			"messageBatchId": {
   * 				"required": false
   * 			}
   * 		}
   * 	},
   * 	"MOBILE_APP_UPDATE_AVAILABLE_MESSAGE_PUSH": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"title": {
   * 			"required": false
   * 		},
   * 		"body": {
   * 			"required": false
   * 		}
   * 	},
   * 	"CUSTOM_CONTENT_MESSAGE_EMAIL": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"title": {
   * 			"required": false
   * 		},
   * 		"subject": {
   * 			"required": false
   * 		},
   * 		"body": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"email": {
   * 				"required": false
   * 			},
   * 			"messageBatchId": {
   * 				"required": false
   * 			}
   * 		}
   * 	},
   * 	"CUSTOM_CONTENT_MESSAGE_SMS": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"title": {
   * 			"required": false
   * 		},
   * 		"body": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"phone": {
   * 				"required": false
   * 			},
   * 			"messageBatchId": {
   * 				"required": false
   * 			}
   * 		}
   * 	},
   * 	"VOIP_INCOMING_CALL_MESSAGE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"title": {
   * 			"required": false
   * 		},
   * 		"body": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"B2CAppId": {
   * 				"required": true
   * 			},
   * 			"B2CAppContext": {
   * 				"required": false
   * 			},
   * 			"B2CAppName": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"callId": {
   * 				"required": false
   * 			},
   * 			"voipType": {
   * 				"required": false
   * 			},
   * 			"voipAddress": {
   * 				"required": false
   * 			},
   * 			"voipLogin": {
   * 				"required": false
   * 			},
   * 			"voipPassword": {
   * 				"required": false
   * 			},
   * 			"voipDtfmCommand": {
   * 				"required": false
   * 			}
   * 		}
   * 	},
   * 	"B2C_APP_MESSAGE_PUSH": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"title": {
   * 			"required": false
   * 		},
   * 		"body": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"B2CAppId": {
   * 				"required": true
   * 			},
   * 			"B2CAppContext": {
   * 				"required": false
   * 			},
   * 			"B2CAppName": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"RECURRENT_PAYMENT_PROCEEDING_SUCCESS_RESULT_MESSAGE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"recurrentPaymentContextId": {
   * 				"required": true
   * 			},
   * 			"recurrentPaymentId": {
   * 				"required": true
   * 			},
   * 			"serviceConsumerId": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"RECURRENT_PAYMENT_PROCEEDING_UNKNOWN_ERROR_MESSAGE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"recurrentPaymentContextId": {
   * 				"required": true
   * 			},
   * 			"recurrentPaymentId": {
   * 				"required": true
   * 			},
   * 			"serviceConsumerId": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"errorCode": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"required": true
   * 			},
   * 			"lastTry": {
   * 				"required": false
   * 			}
   * 		}
   * 	},
   * 	"RECURRENT_PAYMENT_PROCEEDING_ACQUIRING_PAYMENT_PROCEED_ERROR_MESSAGE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"recurrentPaymentContextId": {
   * 				"required": true
   * 			},
   * 			"recurrentPaymentId": {
   * 				"required": true
   * 			},
   * 			"serviceConsumerId": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"errorCode": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"required": true
   * 			},
   * 			"lastTry": {
   * 				"required": false
   * 			}
   * 		}
   * 	},
   * 	"RECURRENT_PAYMENT_PROCEEDING_SERVICE_CONSUMER_NOT_FOUND_ERROR_MESSAGE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"recurrentPaymentContextId": {
   * 				"required": true
   * 			},
   * 			"recurrentPaymentId": {
   * 				"required": true
   * 			},
   * 			"serviceConsumerId": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"errorCode": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"required": true
   * 			},
   * 			"lastTry": {
   * 				"required": false
   * 			}
   * 		}
   * 	},
   * 	"RECURRENT_PAYMENT_PROCEEDING_LIMIT_EXCEEDED_ERROR_MESSAGE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"recurrentPaymentContextId": {
   * 				"required": true
   * 			},
   * 			"recurrentPaymentId": {
   * 				"required": true
   * 			},
   * 			"serviceConsumerId": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"errorCode": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"required": true
   * 			},
   * 			"lastTry": {
   * 				"required": false
   * 			}
   * 		}
   * 	},
   * 	"RECURRENT_PAYMENT_PROCEEDING_CONTEXT_NOT_FOUND_ERROR_MESSAGE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"recurrentPaymentContextId": {
   * 				"required": true
   * 			},
   * 			"recurrentPaymentId": {
   * 				"required": true
   * 			},
   * 			"serviceConsumerId": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"errorCode": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"required": true
   * 			},
   * 			"lastTry": {
   * 				"required": false
   * 			}
   * 		}
   * 	},
   * 	"RECURRENT_PAYMENT_PROCEEDING_CONTEXT_DISABLED_ERROR_MESSAGE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"recurrentPaymentContextId": {
   * 				"required": true
   * 			},
   * 			"recurrentPaymentId": {
   * 				"required": true
   * 			},
   * 			"serviceConsumerId": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"errorCode": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"required": true
   * 			},
   * 			"lastTry": {
   * 				"required": false
   * 			}
   * 		}
   * 	},
   * 	"RECURRENT_PAYMENT_PROCEEDING_CARD_TOKEN_NOT_VALID_ERROR_MESSAGE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"recurrentPaymentContextId": {
   * 				"required": true
   * 			},
   * 			"recurrentPaymentId": {
   * 				"required": true
   * 			},
   * 			"serviceConsumerId": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"errorCode": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"required": true
   * 			},
   * 			"lastTry": {
   * 				"required": false
   * 			}
   * 		}
   * 	},
   * 	"RECURRENT_PAYMENT_PROCEEDING_CAN_NOT_REGISTER_MULTI_PAYMENT_ERROR_MESSAGE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"recurrentPaymentContextId": {
   * 				"required": true
   * 			},
   * 			"recurrentPaymentId": {
   * 				"required": true
   * 			},
   * 			"serviceConsumerId": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"errorCode": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"required": true
   * 			},
   * 			"lastTry": {
   * 				"required": false
   * 			}
   * 		}
   * 	},
   * 	"RECURRENT_PAYMENT_PROCEEDING_NO_RECEIPTS_TO_PROCEED_ERROR_MESSAGE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"recurrentPaymentContextId": {
   * 				"required": true
   * 			},
   * 			"serviceConsumerId": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"RECURRENT_PAYMENT_TOMORROW_PAYMENT_MESSAGE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"recurrentPaymentContextId": {
   * 				"required": true
   * 			},
   * 			"serviceConsumerId": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"RECURRENT_PAYMENT_TOMORROW_PAYMENT_NO_RECEIPTS_MESSAGE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"recurrentPaymentContextId": {
   * 				"required": true
   * 			},
   * 			"serviceConsumerId": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"RECURRENT_PAYMENT_TOMORROW_PAYMENT_LIMIT_EXCEED_MESSAGE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"recurrentPaymentContextId": {
   * 				"required": true
   * 			},
   * 			"serviceConsumerId": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"required": true
   * 			},
   * 			"toPayAmount": {
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"NEWS_ITEM_COMMON_MESSAGE_TYPE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"title": {
   * 			"required": true
   * 		},
   * 		"body": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"newsItemId": {
   * 				"required": true
   * 			},
   * 			"organizationId": {
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"NEWS_ITEM_EMERGENCY_MESSAGE_TYPE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"title": {
   * 			"required": true
   * 		},
   * 		"body": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"newsItemId": {
   * 				"required": true
   * 			},
   * 			"organizationId": {
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"DEV_PORTAL_MESSAGE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"body": {
   * 			"required": true
   * 		},
   * 		"subject": {
   * 			"required": false
   * 		}
   * 	},
   * 	"SEND_BILLING_RECEIPTS_ON_PAYDAY_REMINDER_MESSAGE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"monthName": {
   * 				"required": true
   * 			},
   * 			"serviceConsumerId": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"MARKETPLACE_INVOICE_PUBLISHED_MESSAGE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"invoiceId": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"MARKETPLACE_INVOICE_WITH_TICKET_PUBLISHED_MESSAGE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"invoiceId": {
   * 				"required": true
   * 			},
   * 			"ticketId": {
   * 				"required": true
   * 			},
   * 			"ticketNumber": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"MARKETPLACE_INVOICE_CASH_PUBLISHED_MESSAGE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"invoiceId": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"MARKETPLACE_INVOICE_CASH_WITH_TICKET_PUBLISHED_MESSAGE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"invoiceId": {
   * 				"required": true
   * 			},
   * 			"ticketId": {
   * 				"required": true
   * 			},
   * 			"ticketNumber": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"SERVICE_USER_CREATED": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"serverUrl": {
   * 				"required": true
   * 			},
   * 			"email": {
   * 				"required": true
   * 			},
   * 			"password": {
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"SEND_DAILY_STATISTICS": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"attachingData": {
   * 			"required": false
   * 		},
   * 		"data": {
   * 			"date": {
   * 				"required": true
   * 			},
   * 			"tickets": {
   * 				"required": true
   * 			},
   * 			"incidents": {
   * 				"required": true
   * 			}
   * 		}
   * 	}
   * }`
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "REQUIRED",
   *   "message": "You can not use emailFrom without to.email"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "REQUIRED",
   *   "message": "You should provide either \"user\", \"email\", \"phone\" or \"remoteClient\" attribute"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "UNKNOWN_ATTRIBUTE",
   *   "message": "Unknown attribute \"<attr-name>\" provided to \"meta\" variable"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "REQUIRED",
   *   "message": "Missing value for required \"meta.<name>\" attribute"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "WRONG_VALUE",
   *   "message": "Unknown value \"<type-name>\" provided for message type"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "DV_VERSION_MISMATCH",
   *   "message": "Wrong value for data version number"
   * }`
   */
    sendMessage?: Maybe<SendMessageOutput>
    /**
   * Sends notification of requested type to all residents of provided scopes
   *
   *
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "NOT_FOUND",
   *   "message": "Scopes could not be empty",
   *   "messageForUser": "Scopes could not be empty"
   * }`
   */
    sendMessageToResidentScopes?: Maybe<SendMessageToResidentScopesServiceOutput>
    /**
   * If you have any problem you can use this to notify our support team
   *
   *
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "WRONG_FORMAT",
   *   "message": "Wrong format of specified email",
   *   "messageForUser": "Wrong format of specified email"
   * }`
   */
    sendMessageToSupport?: Maybe<SendMessageToSupportOutput>
    sendNewBillingReceiptFilesNotifications?: Maybe<SendNewBillingReceiptFilesNotificationsOutput>
    /**
   * Sends notification of BILLING_RECEIPT_CATEGORY_AVAILABLE_TYPE type to all residents of provided scopes. Requires service user with access rights to billing domain. All properties in scopes should be connected to provided context.
   *
   *
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "NOT_FOUND",
   *   "message": "Please provide proper period not before start of previous and not later than start of current month, valid format is YYYY-MM-DD",
   *   "messageForUser": "Please provide proper period not before start of previous and not later than start of current month, valid format is YYYY-MM-DD"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "NOT_FOUND",
   *   "message": "Please provide existing non-deleted billing integration organization context id",
   *   "messageForUser": "Please provide existing non-deleted billing integration organization context id"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "NOT_FOUND",
   *   "message": "Please provide existing non-deleted billing category id",
   *   "messageForUser": "Please provide existing non-deleted billing category id"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "NOT_FOUND",
   *   "message": "Scopes could not be empty",
   *   "messageForUser": "Scopes could not be empty"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "NOT_FOUND",
   *   "message": "All provided billing properties in scopes should be non-deleted & connected to organization of the billing context",
   *   "messageForUser": "All provided billing properties in scopes should be non-deleted & connected to organization of the billing context"
   * }`
   */
    sendNewReceiptMessagesToResidentScopes?: Maybe<SendNewReceiptMessagesToResidentScopesOutput>
    setMessageStatus?: Maybe<SetMessageStatusOutput>
    shareTicket?: Maybe<ShareTicketOutput>
    /**
   * Authenticates as an another user to be able to see the system, as it does
   *
   * You cannot authenticate for another admin or support or whatever kind of a non-client user
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "USER_NOT_FOUND",
   *   "message": "Could not find a user with a specified id",
   *   "messageForUser": "User not found"
   * }`
   *
   * `{
   *   "code": "FORBIDDEN",
   *   "type": "DENIED_FOR_ADMIN",
   *   "message": "You cannot authenticate for an another admin user",
   *   "messageForUser": "You cannot authenticate for an another admin user"
   * }`
   *
   * `{
   *   "code": "FORBIDDEN",
   *   "type": "DENIED_FOR_SUPPORT",
   *   "message": "You cannot authenticate for an another support user",
   *   "messageForUser": "You cannot authenticate for an another support user"
   * }`
   */
    signinAsUser?: Maybe<SigninAsUserOutput>
    /**
   * Authenticates resident user for mobile apps
   *
   *
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "TOKEN_NOT_FOUND",
   *   "message": "Unable to find a non-expired confirm phone action, that corresponds to provided token",
   *   "messageForUser": "Something went wrong during authentication :( Try again or ask our support"
   * }`
   *
   * `{
   *   "code": "INTERNAL_ERROR",
   *   "type": "UNABLE_TO_CREATE_USER",
   *   "message": "Something went wrong while trying to create a User record",
   *   "messageForUser": "Something went wrong during registration :( Try again or ask our support"
   * }`
   */
    signinResidentUser?: Maybe<SigninResidentUserOutput>
    /**
   * Send confirmation phone SMS message and return confirmation token. You can use the token for completeConfirmPhoneAction mutation. And then use the token in other mutations to prove that the phone number is verified
   *
   *
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "CAPTCHA_CHECK_FAILED",
   *   "message": "Failed to check CAPTCHA",
   *   "messageForUser": "The operation could not be completed. Please update the application"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "WRONG_PHONE_FORMAT",
   *   "message": "Wrong format of provided phone number",
   *   "messageForUser": "Wrong phone number format",
   *   "correctExample": "+79991234567"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "TOO_MANY_REQUESTS",
   *   "message": "You have to wait 5000 seconds to be able to send request again",
   *   "messageForUser": "You have to wait 5000 sec. to be able to send request again"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "SMS_FOR_PHONE_DAY_LIMIT_REACHED",
   *   "message": "Too many sms requests for this phone number. Try again tomorrow"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "SMS_FOR_IP_DAY_LIMIT_REACHED",
   *   "message": "Too many sms requests from this ip address. Try again tomorrow"
   * }`
   */
    startConfirmPhoneAction?: Maybe<StartConfirmPhoneActionOutput>
    syncRemoteClient?: Maybe<RemoteClient>
    syncTourSteps?: Maybe<SyncTourStepsOutput>
    ticketMultipleUpdate: Ticket
    unauthenticateUser?: Maybe<UnauthenticateUserOutput>
    /**  Update a single AcquiringIntegration item by ID.  */
    updateAcquiringIntegration?: Maybe<AcquiringIntegration>
    /**  Update a single AcquiringIntegrationAccessRight item by ID.  */
    updateAcquiringIntegrationAccessRight?: Maybe<AcquiringIntegrationAccessRight>
    /**  Update a single AcquiringIntegrationAccessRightHistoryRecord item by ID.  */
    updateAcquiringIntegrationAccessRightHistoryRecord?: Maybe<AcquiringIntegrationAccessRightHistoryRecord>
    /**  Update multiple AcquiringIntegrationAccessRightHistoryRecord items by ID.  */
    updateAcquiringIntegrationAccessRightHistoryRecords?: Maybe<Array<Maybe<AcquiringIntegrationAccessRightHistoryRecord>>>
    /**  Update multiple AcquiringIntegrationAccessRight items by ID.  */
    updateAcquiringIntegrationAccessRights?: Maybe<Array<Maybe<AcquiringIntegrationAccessRight>>>
    /**  Update a single AcquiringIntegrationContext item by ID.  */
    updateAcquiringIntegrationContext?: Maybe<AcquiringIntegrationContext>
    /**  Update a single AcquiringIntegrationContextHistoryRecord item by ID.  */
    updateAcquiringIntegrationContextHistoryRecord?: Maybe<AcquiringIntegrationContextHistoryRecord>
    /**  Update multiple AcquiringIntegrationContextHistoryRecord items by ID.  */
    updateAcquiringIntegrationContextHistoryRecords?: Maybe<Array<Maybe<AcquiringIntegrationContextHistoryRecord>>>
    /**  Update multiple AcquiringIntegrationContext items by ID.  */
    updateAcquiringIntegrationContexts?: Maybe<Array<Maybe<AcquiringIntegrationContext>>>
    /**  Update a single AcquiringIntegrationHistoryRecord item by ID.  */
    updateAcquiringIntegrationHistoryRecord?: Maybe<AcquiringIntegrationHistoryRecord>
    /**  Update multiple AcquiringIntegrationHistoryRecord items by ID.  */
    updateAcquiringIntegrationHistoryRecords?: Maybe<Array<Maybe<AcquiringIntegrationHistoryRecord>>>
    /**  Update multiple AcquiringIntegration items by ID.  */
    updateAcquiringIntegrations?: Maybe<Array<Maybe<AcquiringIntegration>>>
    updateAuthenticatedUser?: Maybe<User>
    /**  Update a single B2BApp item by ID.  */
    updateB2BApp?: Maybe<B2BApp>
    /**  Update a single B2BAppAccessRight item by ID.  */
    updateB2BAppAccessRight?: Maybe<B2BAppAccessRight>
    /**  Update a single B2BAppAccessRightHistoryRecord item by ID.  */
    updateB2BAppAccessRightHistoryRecord?: Maybe<B2BAppAccessRightHistoryRecord>
    /**  Update multiple B2BAppAccessRightHistoryRecord items by ID.  */
    updateB2BAppAccessRightHistoryRecords?: Maybe<Array<Maybe<B2BAppAccessRightHistoryRecord>>>
    /**  Update a single B2BAppAccessRightSet item by ID.  */
    updateB2BAppAccessRightSet?: Maybe<B2BAppAccessRightSet>
    /**  Update a single B2BAppAccessRightSetHistoryRecord item by ID.  */
    updateB2BAppAccessRightSetHistoryRecord?: Maybe<B2BAppAccessRightSetHistoryRecord>
    /**  Update multiple B2BAppAccessRightSetHistoryRecord items by ID.  */
    updateB2BAppAccessRightSetHistoryRecords?: Maybe<Array<Maybe<B2BAppAccessRightSetHistoryRecord>>>
    /**  Update multiple B2BAppAccessRightSet items by ID.  */
    updateB2BAppAccessRightSets?: Maybe<Array<Maybe<B2BAppAccessRightSet>>>
    /**  Update multiple B2BAppAccessRight items by ID.  */
    updateB2BAppAccessRights?: Maybe<Array<Maybe<B2BAppAccessRight>>>
    /**  Update a single B2BAppContext item by ID.  */
    updateB2BAppContext?: Maybe<B2BAppContext>
    /**  Update a single B2BAppContextHistoryRecord item by ID.  */
    updateB2BAppContextHistoryRecord?: Maybe<B2BAppContextHistoryRecord>
    /**  Update multiple B2BAppContextHistoryRecord items by ID.  */
    updateB2BAppContextHistoryRecords?: Maybe<Array<Maybe<B2BAppContextHistoryRecord>>>
    /**  Update multiple B2BAppContext items by ID.  */
    updateB2BAppContexts?: Maybe<Array<Maybe<B2BAppContext>>>
    /**  Update a single B2BAppHistoryRecord item by ID.  */
    updateB2BAppHistoryRecord?: Maybe<B2BAppHistoryRecord>
    /**  Update multiple B2BAppHistoryRecord items by ID.  */
    updateB2BAppHistoryRecords?: Maybe<Array<Maybe<B2BAppHistoryRecord>>>
    /**  Update a single B2BAppNewsSharingConfig item by ID.  */
    updateB2BAppNewsSharingConfig?: Maybe<B2BAppNewsSharingConfig>
    /**  Update a single B2BAppNewsSharingConfigHistoryRecord item by ID.  */
    updateB2BAppNewsSharingConfigHistoryRecord?: Maybe<B2BAppNewsSharingConfigHistoryRecord>
    /**  Update multiple B2BAppNewsSharingConfigHistoryRecord items by ID.  */
    updateB2BAppNewsSharingConfigHistoryRecords?: Maybe<Array<Maybe<B2BAppNewsSharingConfigHistoryRecord>>>
    /**  Update multiple B2BAppNewsSharingConfig items by ID.  */
    updateB2BAppNewsSharingConfigs?: Maybe<Array<Maybe<B2BAppNewsSharingConfig>>>
    /**  Update a single B2BAppPermission item by ID.  */
    updateB2BAppPermission?: Maybe<B2BAppPermission>
    /**  Update a single B2BAppPermissionHistoryRecord item by ID.  */
    updateB2BAppPermissionHistoryRecord?: Maybe<B2BAppPermissionHistoryRecord>
    /**  Update multiple B2BAppPermissionHistoryRecord items by ID.  */
    updateB2BAppPermissionHistoryRecords?: Maybe<Array<Maybe<B2BAppPermissionHistoryRecord>>>
    /**  Update multiple B2BAppPermission items by ID.  */
    updateB2BAppPermissions?: Maybe<Array<Maybe<B2BAppPermission>>>
    /**  Update a single B2BAppPromoBlock item by ID.  */
    updateB2BAppPromoBlock?: Maybe<B2BAppPromoBlock>
    /**  Update a single B2BAppPromoBlockHistoryRecord item by ID.  */
    updateB2BAppPromoBlockHistoryRecord?: Maybe<B2BAppPromoBlockHistoryRecord>
    /**  Update multiple B2BAppPromoBlockHistoryRecord items by ID.  */
    updateB2BAppPromoBlockHistoryRecords?: Maybe<Array<Maybe<B2BAppPromoBlockHistoryRecord>>>
    /**  Update multiple B2BAppPromoBlock items by ID.  */
    updateB2BAppPromoBlocks?: Maybe<Array<Maybe<B2BAppPromoBlock>>>
    /**  Update a single B2BAppRole item by ID.  */
    updateB2BAppRole?: Maybe<B2BAppRole>
    /**  Update a single B2BAppRoleHistoryRecord item by ID.  */
    updateB2BAppRoleHistoryRecord?: Maybe<B2BAppRoleHistoryRecord>
    /**  Update multiple B2BAppRoleHistoryRecord items by ID.  */
    updateB2BAppRoleHistoryRecords?: Maybe<Array<Maybe<B2BAppRoleHistoryRecord>>>
    /**  Update multiple B2BAppRole items by ID.  */
    updateB2BAppRoles?: Maybe<Array<Maybe<B2BAppRole>>>
    /**  Update multiple B2BApp items by ID.  */
    updateB2BApps?: Maybe<Array<Maybe<B2BApp>>>
    /**  Update a single B2CApp item by ID.  */
    updateB2CApp?: Maybe<B2CApp>
    /**  Update a single B2CAppAccessRight item by ID.  */
    updateB2CAppAccessRight?: Maybe<B2CAppAccessRight>
    /**  Update a single B2CAppAccessRightHistoryRecord item by ID.  */
    updateB2CAppAccessRightHistoryRecord?: Maybe<B2CAppAccessRightHistoryRecord>
    /**  Update multiple B2CAppAccessRightHistoryRecord items by ID.  */
    updateB2CAppAccessRightHistoryRecords?: Maybe<Array<Maybe<B2CAppAccessRightHistoryRecord>>>
    /**  Update multiple B2CAppAccessRight items by ID.  */
    updateB2CAppAccessRights?: Maybe<Array<Maybe<B2CAppAccessRight>>>
    /**  Update a single B2CAppBuild item by ID.  */
    updateB2CAppBuild?: Maybe<B2CAppBuild>
    /**  Update a single B2CAppBuildHistoryRecord item by ID.  */
    updateB2CAppBuildHistoryRecord?: Maybe<B2CAppBuildHistoryRecord>
    /**  Update multiple B2CAppBuildHistoryRecord items by ID.  */
    updateB2CAppBuildHistoryRecords?: Maybe<Array<Maybe<B2CAppBuildHistoryRecord>>>
    /**  Update multiple B2CAppBuild items by ID.  */
    updateB2CAppBuilds?: Maybe<Array<Maybe<B2CAppBuild>>>
    /**  Update a single B2CAppHistoryRecord item by ID.  */
    updateB2CAppHistoryRecord?: Maybe<B2CAppHistoryRecord>
    /**  Update multiple B2CAppHistoryRecord items by ID.  */
    updateB2CAppHistoryRecords?: Maybe<Array<Maybe<B2CAppHistoryRecord>>>
    /**  Update a single B2CAppMessageSetting item by ID.  */
    updateB2CAppMessageSetting?: Maybe<B2CAppMessageSetting>
    /**  Update a single B2CAppMessageSettingHistoryRecord item by ID.  */
    updateB2CAppMessageSettingHistoryRecord?: Maybe<B2CAppMessageSettingHistoryRecord>
    /**  Update multiple B2CAppMessageSettingHistoryRecord items by ID.  */
    updateB2CAppMessageSettingHistoryRecords?: Maybe<Array<Maybe<B2CAppMessageSettingHistoryRecord>>>
    /**  Update multiple B2CAppMessageSetting items by ID.  */
    updateB2CAppMessageSettings?: Maybe<Array<Maybe<B2CAppMessageSetting>>>
    /**  Update multiple B2CAppProperty items by ID.  */
    updateB2CAppProperties?: Maybe<Array<Maybe<B2CAppProperty>>>
    /**  Update a single B2CAppProperty item by ID.  */
    updateB2CAppProperty?: Maybe<B2CAppProperty>
    /**  Update a single B2CAppPropertyHistoryRecord item by ID.  */
    updateB2CAppPropertyHistoryRecord?: Maybe<B2CAppPropertyHistoryRecord>
    /**  Update multiple B2CAppPropertyHistoryRecord items by ID.  */
    updateB2CAppPropertyHistoryRecords?: Maybe<Array<Maybe<B2CAppPropertyHistoryRecord>>>
    /**  Update multiple B2CApp items by ID.  */
    updateB2CApps?: Maybe<Array<Maybe<B2CApp>>>
    /**  Update a single BankAccount item by ID.  */
    updateBankAccount?: Maybe<BankAccount>
    /**  Update a single BankAccountHistoryRecord item by ID.  */
    updateBankAccountHistoryRecord?: Maybe<BankAccountHistoryRecord>
    /**  Update multiple BankAccountHistoryRecord items by ID.  */
    updateBankAccountHistoryRecords?: Maybe<Array<Maybe<BankAccountHistoryRecord>>>
    /**  Update a single BankAccountReport item by ID.  */
    updateBankAccountReport?: Maybe<BankAccountReport>
    /**  Update a single BankAccountReportHistoryRecord item by ID.  */
    updateBankAccountReportHistoryRecord?: Maybe<BankAccountReportHistoryRecord>
    /**  Update multiple BankAccountReportHistoryRecord items by ID.  */
    updateBankAccountReportHistoryRecords?: Maybe<Array<Maybe<BankAccountReportHistoryRecord>>>
    /**  Update a single BankAccountReportTask item by ID.  */
    updateBankAccountReportTask?: Maybe<BankAccountReportTask>
    /**  Update a single BankAccountReportTaskHistoryRecord item by ID.  */
    updateBankAccountReportTaskHistoryRecord?: Maybe<BankAccountReportTaskHistoryRecord>
    /**  Update multiple BankAccountReportTaskHistoryRecord items by ID.  */
    updateBankAccountReportTaskHistoryRecords?: Maybe<Array<Maybe<BankAccountReportTaskHistoryRecord>>>
    /**  Update multiple BankAccountReportTask items by ID.  */
    updateBankAccountReportTasks?: Maybe<Array<Maybe<BankAccountReportTask>>>
    /**  Update multiple BankAccountReport items by ID.  */
    updateBankAccountReports?: Maybe<Array<Maybe<BankAccountReport>>>
    /**  Update multiple BankAccount items by ID.  */
    updateBankAccounts?: Maybe<Array<Maybe<BankAccount>>>
    /**  Update multiple BankCategory items by ID.  */
    updateBankCategories?: Maybe<Array<Maybe<BankCategory>>>
    /**  Update a single BankCategory item by ID.  */
    updateBankCategory?: Maybe<BankCategory>
    /**  Update a single BankCategoryHistoryRecord item by ID.  */
    updateBankCategoryHistoryRecord?: Maybe<BankCategoryHistoryRecord>
    /**  Update multiple BankCategoryHistoryRecord items by ID.  */
    updateBankCategoryHistoryRecords?: Maybe<Array<Maybe<BankCategoryHistoryRecord>>>
    /**  Update a single BankContractorAccount item by ID.  */
    updateBankContractorAccount?: Maybe<BankContractorAccount>
    /**  Update a single BankContractorAccountHistoryRecord item by ID.  */
    updateBankContractorAccountHistoryRecord?: Maybe<BankContractorAccountHistoryRecord>
    /**  Update multiple BankContractorAccountHistoryRecord items by ID.  */
    updateBankContractorAccountHistoryRecords?: Maybe<Array<Maybe<BankContractorAccountHistoryRecord>>>
    /**  Update multiple BankContractorAccount items by ID.  */
    updateBankContractorAccounts?: Maybe<Array<Maybe<BankContractorAccount>>>
    /**  Update a single BankCostItem item by ID.  */
    updateBankCostItem?: Maybe<BankCostItem>
    /**  Update a single BankCostItemHistoryRecord item by ID.  */
    updateBankCostItemHistoryRecord?: Maybe<BankCostItemHistoryRecord>
    /**  Update multiple BankCostItemHistoryRecord items by ID.  */
    updateBankCostItemHistoryRecords?: Maybe<Array<Maybe<BankCostItemHistoryRecord>>>
    /**  Update multiple BankCostItem items by ID.  */
    updateBankCostItems?: Maybe<Array<Maybe<BankCostItem>>>
    /**  Update a single BankIntegration item by ID.  */
    updateBankIntegration?: Maybe<BankIntegration>
    /**  Update a single BankIntegrationAccessRight item by ID.  */
    updateBankIntegrationAccessRight?: Maybe<BankIntegrationAccessRight>
    /**  Update a single BankIntegrationAccessRightHistoryRecord item by ID.  */
    updateBankIntegrationAccessRightHistoryRecord?: Maybe<BankIntegrationAccessRightHistoryRecord>
    /**  Update multiple BankIntegrationAccessRightHistoryRecord items by ID.  */
    updateBankIntegrationAccessRightHistoryRecords?: Maybe<Array<Maybe<BankIntegrationAccessRightHistoryRecord>>>
    /**  Update multiple BankIntegrationAccessRight items by ID.  */
    updateBankIntegrationAccessRights?: Maybe<Array<Maybe<BankIntegrationAccessRight>>>
    /**  Update a single BankIntegrationAccountContext item by ID.  */
    updateBankIntegrationAccountContext?: Maybe<BankIntegrationAccountContext>
    /**  Update a single BankIntegrationAccountContextHistoryRecord item by ID.  */
    updateBankIntegrationAccountContextHistoryRecord?: Maybe<BankIntegrationAccountContextHistoryRecord>
    /**  Update multiple BankIntegrationAccountContextHistoryRecord items by ID.  */
    updateBankIntegrationAccountContextHistoryRecords?: Maybe<Array<Maybe<BankIntegrationAccountContextHistoryRecord>>>
    /**  Update multiple BankIntegrationAccountContext items by ID.  */
    updateBankIntegrationAccountContexts?: Maybe<Array<Maybe<BankIntegrationAccountContext>>>
    /**  Update a single BankIntegrationHistoryRecord item by ID.  */
    updateBankIntegrationHistoryRecord?: Maybe<BankIntegrationHistoryRecord>
    /**  Update multiple BankIntegrationHistoryRecord items by ID.  */
    updateBankIntegrationHistoryRecords?: Maybe<Array<Maybe<BankIntegrationHistoryRecord>>>
    /**  Update a single BankIntegrationOrganizationContext item by ID.  */
    updateBankIntegrationOrganizationContext?: Maybe<BankIntegrationOrganizationContext>
    /**  Update a single BankIntegrationOrganizationContextHistoryRecord item by ID.  */
    updateBankIntegrationOrganizationContextHistoryRecord?: Maybe<BankIntegrationOrganizationContextHistoryRecord>
    /**  Update multiple BankIntegrationOrganizationContextHistoryRecord items by ID.  */
    updateBankIntegrationOrganizationContextHistoryRecords?: Maybe<Array<Maybe<BankIntegrationOrganizationContextHistoryRecord>>>
    /**  Update multiple BankIntegrationOrganizationContext items by ID.  */
    updateBankIntegrationOrganizationContexts?: Maybe<Array<Maybe<BankIntegrationOrganizationContext>>>
    /**  Update multiple BankIntegration items by ID.  */
    updateBankIntegrations?: Maybe<Array<Maybe<BankIntegration>>>
    /**  Update a single BankSyncTask item by ID.  */
    updateBankSyncTask?: Maybe<BankSyncTask>
    /**  Update a single BankSyncTaskHistoryRecord item by ID.  */
    updateBankSyncTaskHistoryRecord?: Maybe<BankSyncTaskHistoryRecord>
    /**  Update multiple BankSyncTaskHistoryRecord items by ID.  */
    updateBankSyncTaskHistoryRecords?: Maybe<Array<Maybe<BankSyncTaskHistoryRecord>>>
    /**  Update multiple BankSyncTask items by ID.  */
    updateBankSyncTasks?: Maybe<Array<Maybe<BankSyncTask>>>
    /**  Update a single BankTransaction item by ID.  */
    updateBankTransaction?: Maybe<BankTransaction>
    /**  Update a single BankTransactionHistoryRecord item by ID.  */
    updateBankTransactionHistoryRecord?: Maybe<BankTransactionHistoryRecord>
    /**  Update multiple BankTransactionHistoryRecord items by ID.  */
    updateBankTransactionHistoryRecords?: Maybe<Array<Maybe<BankTransactionHistoryRecord>>>
    /**  Update multiple BankTransaction items by ID.  */
    updateBankTransactions?: Maybe<Array<Maybe<BankTransaction>>>
    /**  Update a single BillingAccount item by ID.  */
    updateBillingAccount?: Maybe<BillingAccount>
    /**  Update a single BillingAccountHistoryRecord item by ID.  */
    updateBillingAccountHistoryRecord?: Maybe<BillingAccountHistoryRecord>
    /**  Update multiple BillingAccountHistoryRecord items by ID.  */
    updateBillingAccountHistoryRecords?: Maybe<Array<Maybe<BillingAccountHistoryRecord>>>
    /**  Update multiple BillingAccount items by ID.  */
    updateBillingAccounts?: Maybe<Array<Maybe<BillingAccount>>>
    /**  Update multiple BillingCategory items by ID.  */
    updateBillingCategories?: Maybe<Array<Maybe<BillingCategory>>>
    /**  Update a single BillingCategory item by ID.  */
    updateBillingCategory?: Maybe<BillingCategory>
    /**  Update a single BillingCategoryHistoryRecord item by ID.  */
    updateBillingCategoryHistoryRecord?: Maybe<BillingCategoryHistoryRecord>
    /**  Update multiple BillingCategoryHistoryRecord items by ID.  */
    updateBillingCategoryHistoryRecords?: Maybe<Array<Maybe<BillingCategoryHistoryRecord>>>
    /**  Update a single BillingIntegration item by ID.  */
    updateBillingIntegration?: Maybe<BillingIntegration>
    /**  Update a single BillingIntegrationAccessRight item by ID.  */
    updateBillingIntegrationAccessRight?: Maybe<BillingIntegrationAccessRight>
    /**  Update a single BillingIntegrationAccessRightHistoryRecord item by ID.  */
    updateBillingIntegrationAccessRightHistoryRecord?: Maybe<BillingIntegrationAccessRightHistoryRecord>
    /**  Update multiple BillingIntegrationAccessRightHistoryRecord items by ID.  */
    updateBillingIntegrationAccessRightHistoryRecords?: Maybe<Array<Maybe<BillingIntegrationAccessRightHistoryRecord>>>
    /**  Update multiple BillingIntegrationAccessRight items by ID.  */
    updateBillingIntegrationAccessRights?: Maybe<Array<Maybe<BillingIntegrationAccessRight>>>
    /**  Update a single BillingIntegrationHistoryRecord item by ID.  */
    updateBillingIntegrationHistoryRecord?: Maybe<BillingIntegrationHistoryRecord>
    /**  Update multiple BillingIntegrationHistoryRecord items by ID.  */
    updateBillingIntegrationHistoryRecords?: Maybe<Array<Maybe<BillingIntegrationHistoryRecord>>>
    /**  Update a single BillingIntegrationOrganizationContext item by ID.  */
    updateBillingIntegrationOrganizationContext?: Maybe<BillingIntegrationOrganizationContext>
    /**  Update a single BillingIntegrationOrganizationContextHistoryRecord item by ID.  */
    updateBillingIntegrationOrganizationContextHistoryRecord?: Maybe<BillingIntegrationOrganizationContextHistoryRecord>
    /**  Update multiple BillingIntegrationOrganizationContextHistoryRecord items by ID.  */
    updateBillingIntegrationOrganizationContextHistoryRecords?: Maybe<Array<Maybe<BillingIntegrationOrganizationContextHistoryRecord>>>
    /**  Update multiple BillingIntegrationOrganizationContext items by ID.  */
    updateBillingIntegrationOrganizationContexts?: Maybe<Array<Maybe<BillingIntegrationOrganizationContext>>>
    /**  Update a single BillingIntegrationProblem item by ID.  */
    updateBillingIntegrationProblem?: Maybe<BillingIntegrationProblem>
    /**  Update multiple BillingIntegrationProblem items by ID.  */
    updateBillingIntegrationProblems?: Maybe<Array<Maybe<BillingIntegrationProblem>>>
    /**  Update multiple BillingIntegration items by ID.  */
    updateBillingIntegrations?: Maybe<Array<Maybe<BillingIntegration>>>
    /**  Update multiple BillingProperty items by ID.  */
    updateBillingProperties?: Maybe<Array<Maybe<BillingProperty>>>
    /**  Update a single BillingProperty item by ID.  */
    updateBillingProperty?: Maybe<BillingProperty>
    /**  Update a single BillingPropertyHistoryRecord item by ID.  */
    updateBillingPropertyHistoryRecord?: Maybe<BillingPropertyHistoryRecord>
    /**  Update multiple BillingPropertyHistoryRecord items by ID.  */
    updateBillingPropertyHistoryRecords?: Maybe<Array<Maybe<BillingPropertyHistoryRecord>>>
    /**  Update a single BillingReceipt item by ID.  */
    updateBillingReceipt?: Maybe<BillingReceipt>
    /**  Update a single BillingReceiptFile item by ID.  */
    updateBillingReceiptFile?: Maybe<BillingReceiptFile>
    /**  Update a single BillingReceiptFileHistoryRecord item by ID.  */
    updateBillingReceiptFileHistoryRecord?: Maybe<BillingReceiptFileHistoryRecord>
    /**  Update multiple BillingReceiptFileHistoryRecord items by ID.  */
    updateBillingReceiptFileHistoryRecords?: Maybe<Array<Maybe<BillingReceiptFileHistoryRecord>>>
    /**  Update multiple BillingReceiptFile items by ID.  */
    updateBillingReceiptFiles?: Maybe<Array<Maybe<BillingReceiptFile>>>
    /**  Update a single BillingReceiptHistoryRecord item by ID.  */
    updateBillingReceiptHistoryRecord?: Maybe<BillingReceiptHistoryRecord>
    /**  Update multiple BillingReceiptHistoryRecord items by ID.  */
    updateBillingReceiptHistoryRecords?: Maybe<Array<Maybe<BillingReceiptHistoryRecord>>>
    /**  Update multiple BillingReceipt items by ID.  */
    updateBillingReceipts?: Maybe<Array<Maybe<BillingReceipt>>>
    /**  Update a single BillingRecipient item by ID.  */
    updateBillingRecipient?: Maybe<BillingRecipient>
    /**  Update a single BillingRecipientHistoryRecord item by ID.  */
    updateBillingRecipientHistoryRecord?: Maybe<BillingRecipientHistoryRecord>
    /**  Update multiple BillingRecipientHistoryRecord items by ID.  */
    updateBillingRecipientHistoryRecords?: Maybe<Array<Maybe<BillingRecipientHistoryRecord>>>
    /**  Update multiple BillingRecipient items by ID.  */
    updateBillingRecipients?: Maybe<Array<Maybe<BillingRecipient>>>
    /**  Update a single CallRecord item by ID.  */
    updateCallRecord?: Maybe<CallRecord>
    /**  Update a single CallRecordFragment item by ID.  */
    updateCallRecordFragment?: Maybe<CallRecordFragment>
    /**  Update a single CallRecordFragmentHistoryRecord item by ID.  */
    updateCallRecordFragmentHistoryRecord?: Maybe<CallRecordFragmentHistoryRecord>
    /**  Update multiple CallRecordFragmentHistoryRecord items by ID.  */
    updateCallRecordFragmentHistoryRecords?: Maybe<Array<Maybe<CallRecordFragmentHistoryRecord>>>
    /**  Update multiple CallRecordFragment items by ID.  */
    updateCallRecordFragments?: Maybe<Array<Maybe<CallRecordFragment>>>
    /**  Update a single CallRecordHistoryRecord item by ID.  */
    updateCallRecordHistoryRecord?: Maybe<CallRecordHistoryRecord>
    /**  Update multiple CallRecordHistoryRecord items by ID.  */
    updateCallRecordHistoryRecords?: Maybe<Array<Maybe<CallRecordHistoryRecord>>>
    /**  Update multiple CallRecord items by ID.  */
    updateCallRecords?: Maybe<Array<Maybe<CallRecord>>>
    /**  Update a single ConfirmPhoneAction item by ID.  */
    updateConfirmPhoneAction?: Maybe<ConfirmPhoneAction>
    /**  Update a single ConfirmPhoneActionHistoryRecord item by ID.  */
    updateConfirmPhoneActionHistoryRecord?: Maybe<ConfirmPhoneActionHistoryRecord>
    /**  Update multiple ConfirmPhoneActionHistoryRecord items by ID.  */
    updateConfirmPhoneActionHistoryRecords?: Maybe<Array<Maybe<ConfirmPhoneActionHistoryRecord>>>
    /**  Update multiple ConfirmPhoneAction items by ID.  */
    updateConfirmPhoneActions?: Maybe<Array<Maybe<ConfirmPhoneAction>>>
    /**  Update a single Contact item by ID.  */
    updateContact?: Maybe<Contact>
    /**  Update a single ContactExportTask item by ID.  */
    updateContactExportTask?: Maybe<ContactExportTask>
    /**  Update a single ContactExportTaskHistoryRecord item by ID.  */
    updateContactExportTaskHistoryRecord?: Maybe<ContactExportTaskHistoryRecord>
    /**  Update multiple ContactExportTaskHistoryRecord items by ID.  */
    updateContactExportTaskHistoryRecords?: Maybe<Array<Maybe<ContactExportTaskHistoryRecord>>>
    /**  Update multiple ContactExportTask items by ID.  */
    updateContactExportTasks?: Maybe<Array<Maybe<ContactExportTask>>>
    /**  Update a single ContactHistoryRecord item by ID.  */
    updateContactHistoryRecord?: Maybe<ContactHistoryRecord>
    /**  Update multiple ContactHistoryRecord items by ID.  */
    updateContactHistoryRecords?: Maybe<Array<Maybe<ContactHistoryRecord>>>
    /**  Update a single ContactRole item by ID.  */
    updateContactRole?: Maybe<ContactRole>
    /**  Update a single ContactRoleHistoryRecord item by ID.  */
    updateContactRoleHistoryRecord?: Maybe<ContactRoleHistoryRecord>
    /**  Update multiple ContactRoleHistoryRecord items by ID.  */
    updateContactRoleHistoryRecords?: Maybe<Array<Maybe<ContactRoleHistoryRecord>>>
    /**  Update multiple ContactRole items by ID.  */
    updateContactRoles?: Maybe<Array<Maybe<ContactRole>>>
    /**  Update multiple Contact items by ID.  */
    updateContacts?: Maybe<Array<Maybe<Contact>>>
    /**  Update a single Document item by ID.  */
    updateDocument?: Maybe<Document>
    /**  Update multiple DocumentCategory items by ID.  */
    updateDocumentCategories?: Maybe<Array<Maybe<DocumentCategory>>>
    /**  Update a single DocumentCategory item by ID.  */
    updateDocumentCategory?: Maybe<DocumentCategory>
    /**  Update a single DocumentCategoryHistoryRecord item by ID.  */
    updateDocumentCategoryHistoryRecord?: Maybe<DocumentCategoryHistoryRecord>
    /**  Update multiple DocumentCategoryHistoryRecord items by ID.  */
    updateDocumentCategoryHistoryRecords?: Maybe<Array<Maybe<DocumentCategoryHistoryRecord>>>
    /**  Update a single DocumentHistoryRecord item by ID.  */
    updateDocumentHistoryRecord?: Maybe<DocumentHistoryRecord>
    /**  Update multiple DocumentHistoryRecord items by ID.  */
    updateDocumentHistoryRecords?: Maybe<Array<Maybe<DocumentHistoryRecord>>>
    /**  Update multiple Document items by ID.  */
    updateDocuments?: Maybe<Array<Maybe<Document>>>
    /**  Update a single ExternalTokenAccessRight item by ID.  */
    updateExternalTokenAccessRight?: Maybe<ExternalTokenAccessRight>
    /**  Update a single ExternalTokenAccessRightHistoryRecord item by ID.  */
    updateExternalTokenAccessRightHistoryRecord?: Maybe<ExternalTokenAccessRightHistoryRecord>
    /**  Update multiple ExternalTokenAccessRightHistoryRecord items by ID.  */
    updateExternalTokenAccessRightHistoryRecords?: Maybe<Array<Maybe<ExternalTokenAccessRightHistoryRecord>>>
    /**  Update multiple ExternalTokenAccessRight items by ID.  */
    updateExternalTokenAccessRights?: Maybe<Array<Maybe<ExternalTokenAccessRight>>>
    /**  Update a single Incident item by ID.  */
    updateIncident?: Maybe<Incident>
    /**  Update a single IncidentChange item by ID.  */
    updateIncidentChange?: Maybe<IncidentChange>
    /**  Update multiple IncidentChange items by ID.  */
    updateIncidentChanges?: Maybe<Array<Maybe<IncidentChange>>>
    /**  Update a single IncidentClassifier item by ID.  */
    updateIncidentClassifier?: Maybe<IncidentClassifier>
    /**  Update a single IncidentClassifierHistoryRecord item by ID.  */
    updateIncidentClassifierHistoryRecord?: Maybe<IncidentClassifierHistoryRecord>
    /**  Update multiple IncidentClassifierHistoryRecord items by ID.  */
    updateIncidentClassifierHistoryRecords?: Maybe<Array<Maybe<IncidentClassifierHistoryRecord>>>
    /**  Update a single IncidentClassifierIncident item by ID.  */
    updateIncidentClassifierIncident?: Maybe<IncidentClassifierIncident>
    /**  Update a single IncidentClassifierIncidentHistoryRecord item by ID.  */
    updateIncidentClassifierIncidentHistoryRecord?: Maybe<IncidentClassifierIncidentHistoryRecord>
    /**  Update multiple IncidentClassifierIncidentHistoryRecord items by ID.  */
    updateIncidentClassifierIncidentHistoryRecords?: Maybe<Array<Maybe<IncidentClassifierIncidentHistoryRecord>>>
    /**  Update multiple IncidentClassifierIncident items by ID.  */
    updateIncidentClassifierIncidents?: Maybe<Array<Maybe<IncidentClassifierIncident>>>
    /**  Update multiple IncidentClassifier items by ID.  */
    updateIncidentClassifiers?: Maybe<Array<Maybe<IncidentClassifier>>>
    /**  Update a single IncidentExportTask item by ID.  */
    updateIncidentExportTask?: Maybe<IncidentExportTask>
    /**  Update a single IncidentExportTaskHistoryRecord item by ID.  */
    updateIncidentExportTaskHistoryRecord?: Maybe<IncidentExportTaskHistoryRecord>
    /**  Update multiple IncidentExportTaskHistoryRecord items by ID.  */
    updateIncidentExportTaskHistoryRecords?: Maybe<Array<Maybe<IncidentExportTaskHistoryRecord>>>
    /**  Update multiple IncidentExportTask items by ID.  */
    updateIncidentExportTasks?: Maybe<Array<Maybe<IncidentExportTask>>>
    /**  Update a single IncidentHistoryRecord item by ID.  */
    updateIncidentHistoryRecord?: Maybe<IncidentHistoryRecord>
    /**  Update multiple IncidentHistoryRecord items by ID.  */
    updateIncidentHistoryRecords?: Maybe<Array<Maybe<IncidentHistoryRecord>>>
    /**  Update multiple IncidentProperty items by ID.  */
    updateIncidentProperties?: Maybe<Array<Maybe<IncidentProperty>>>
    /**  Update a single IncidentProperty item by ID.  */
    updateIncidentProperty?: Maybe<IncidentProperty>
    /**  Update a single IncidentPropertyHistoryRecord item by ID.  */
    updateIncidentPropertyHistoryRecord?: Maybe<IncidentPropertyHistoryRecord>
    /**  Update multiple IncidentPropertyHistoryRecord items by ID.  */
    updateIncidentPropertyHistoryRecords?: Maybe<Array<Maybe<IncidentPropertyHistoryRecord>>>
    /**  Update multiple Incident items by ID.  */
    updateIncidents?: Maybe<Array<Maybe<Incident>>>
    /**  Update a single Invoice item by ID.  */
    updateInvoice?: Maybe<Invoice>
    /**  Update a single InvoiceHistoryRecord item by ID.  */
    updateInvoiceHistoryRecord?: Maybe<InvoiceHistoryRecord>
    /**  Update multiple InvoiceHistoryRecord items by ID.  */
    updateInvoiceHistoryRecords?: Maybe<Array<Maybe<InvoiceHistoryRecord>>>
    /**  Update multiple Invoice items by ID.  */
    updateInvoices?: Maybe<Array<Maybe<Invoice>>>
    /**  Update multiple MarketCategory items by ID.  */
    updateMarketCategories?: Maybe<Array<Maybe<MarketCategory>>>
    /**  Update a single MarketCategory item by ID.  */
    updateMarketCategory?: Maybe<MarketCategory>
    /**  Update a single MarketCategoryHistoryRecord item by ID.  */
    updateMarketCategoryHistoryRecord?: Maybe<MarketCategoryHistoryRecord>
    /**  Update multiple MarketCategoryHistoryRecord items by ID.  */
    updateMarketCategoryHistoryRecords?: Maybe<Array<Maybe<MarketCategoryHistoryRecord>>>
    /**  Update a single MarketItem item by ID.  */
    updateMarketItem?: Maybe<MarketItem>
    /**  Update a single MarketItemFile item by ID.  */
    updateMarketItemFile?: Maybe<MarketItemFile>
    /**  Update a single MarketItemFileHistoryRecord item by ID.  */
    updateMarketItemFileHistoryRecord?: Maybe<MarketItemFileHistoryRecord>
    /**  Update multiple MarketItemFileHistoryRecord items by ID.  */
    updateMarketItemFileHistoryRecords?: Maybe<Array<Maybe<MarketItemFileHistoryRecord>>>
    /**  Update multiple MarketItemFile items by ID.  */
    updateMarketItemFiles?: Maybe<Array<Maybe<MarketItemFile>>>
    /**  Update a single MarketItemHistoryRecord item by ID.  */
    updateMarketItemHistoryRecord?: Maybe<MarketItemHistoryRecord>
    /**  Update multiple MarketItemHistoryRecord items by ID.  */
    updateMarketItemHistoryRecords?: Maybe<Array<Maybe<MarketItemHistoryRecord>>>
    /**  Update a single MarketItemPrice item by ID.  */
    updateMarketItemPrice?: Maybe<MarketItemPrice>
    /**  Update a single MarketItemPriceHistoryRecord item by ID.  */
    updateMarketItemPriceHistoryRecord?: Maybe<MarketItemPriceHistoryRecord>
    /**  Update multiple MarketItemPriceHistoryRecord items by ID.  */
    updateMarketItemPriceHistoryRecords?: Maybe<Array<Maybe<MarketItemPriceHistoryRecord>>>
    /**  Update multiple MarketItemPrice items by ID.  */
    updateMarketItemPrices?: Maybe<Array<Maybe<MarketItemPrice>>>
    /**  Update multiple MarketItem items by ID.  */
    updateMarketItems?: Maybe<Array<Maybe<MarketItem>>>
    /**  Update a single MarketPriceScope item by ID.  */
    updateMarketPriceScope?: Maybe<MarketPriceScope>
    /**  Update a single MarketPriceScopeHistoryRecord item by ID.  */
    updateMarketPriceScopeHistoryRecord?: Maybe<MarketPriceScopeHistoryRecord>
    /**  Update multiple MarketPriceScopeHistoryRecord items by ID.  */
    updateMarketPriceScopeHistoryRecords?: Maybe<Array<Maybe<MarketPriceScopeHistoryRecord>>>
    /**  Update multiple MarketPriceScope items by ID.  */
    updateMarketPriceScopes?: Maybe<Array<Maybe<MarketPriceScope>>>
    /**  Update a single MarketSetting item by ID.  */
    updateMarketSetting?: Maybe<MarketSetting>
    /**  Update a single MarketSettingHistoryRecord item by ID.  */
    updateMarketSettingHistoryRecord?: Maybe<MarketSettingHistoryRecord>
    /**  Update multiple MarketSettingHistoryRecord items by ID.  */
    updateMarketSettingHistoryRecords?: Maybe<Array<Maybe<MarketSettingHistoryRecord>>>
    /**  Update multiple MarketSetting items by ID.  */
    updateMarketSettings?: Maybe<Array<Maybe<MarketSetting>>>
    /**  Update a single Message item by ID.  */
    updateMessage?: Maybe<Message>
    /**  Update a single MessageAppBlackList item by ID.  */
    updateMessageAppBlackList?: Maybe<MessageAppBlackList>
    /**  Update a single MessageAppBlackListHistoryRecord item by ID.  */
    updateMessageAppBlackListHistoryRecord?: Maybe<MessageAppBlackListHistoryRecord>
    /**  Update multiple MessageAppBlackListHistoryRecord items by ID.  */
    updateMessageAppBlackListHistoryRecords?: Maybe<Array<Maybe<MessageAppBlackListHistoryRecord>>>
    /**  Update multiple MessageAppBlackList items by ID.  */
    updateMessageAppBlackLists?: Maybe<Array<Maybe<MessageAppBlackList>>>
    /**  Update a single MessageBatch item by ID.  */
    updateMessageBatch?: Maybe<MessageBatch>
    /**  Update a single MessageBatchHistoryRecord item by ID.  */
    updateMessageBatchHistoryRecord?: Maybe<MessageBatchHistoryRecord>
    /**  Update multiple MessageBatchHistoryRecord items by ID.  */
    updateMessageBatchHistoryRecords?: Maybe<Array<Maybe<MessageBatchHistoryRecord>>>
    /**  Update multiple MessageBatch items by ID.  */
    updateMessageBatches?: Maybe<Array<Maybe<MessageBatch>>>
    /**  Update a single MessageHistoryRecord item by ID.  */
    updateMessageHistoryRecord?: Maybe<MessageHistoryRecord>
    /**  Update multiple MessageHistoryRecord items by ID.  */
    updateMessageHistoryRecords?: Maybe<Array<Maybe<MessageHistoryRecord>>>
    /**  Update a single MessageOrganizationBlackList item by ID.  */
    updateMessageOrganizationBlackList?: Maybe<MessageOrganizationBlackList>
    /**  Update a single MessageOrganizationBlackListHistoryRecord item by ID.  */
    updateMessageOrganizationBlackListHistoryRecord?: Maybe<MessageOrganizationBlackListHistoryRecord>
    /**  Update multiple MessageOrganizationBlackListHistoryRecord items by ID.  */
    updateMessageOrganizationBlackListHistoryRecords?: Maybe<Array<Maybe<MessageOrganizationBlackListHistoryRecord>>>
    /**  Update multiple MessageOrganizationBlackList items by ID.  */
    updateMessageOrganizationBlackLists?: Maybe<Array<Maybe<MessageOrganizationBlackList>>>
    /**  Update a single MessageUserBlackList item by ID.  */
    updateMessageUserBlackList?: Maybe<MessageUserBlackList>
    /**  Update a single MessageUserBlackListHistoryRecord item by ID.  */
    updateMessageUserBlackListHistoryRecord?: Maybe<MessageUserBlackListHistoryRecord>
    /**  Update multiple MessageUserBlackListHistoryRecord items by ID.  */
    updateMessageUserBlackListHistoryRecords?: Maybe<Array<Maybe<MessageUserBlackListHistoryRecord>>>
    /**  Update multiple MessageUserBlackList items by ID.  */
    updateMessageUserBlackLists?: Maybe<Array<Maybe<MessageUserBlackList>>>
    /**  Update multiple Message items by ID.  */
    updateMessages?: Maybe<Array<Maybe<Message>>>
    /**  Update a single Meter item by ID.  */
    updateMeter?: Maybe<Meter>
    /**  Update a single MeterHistoryRecord item by ID.  */
    updateMeterHistoryRecord?: Maybe<MeterHistoryRecord>
    /**  Update multiple MeterHistoryRecord items by ID.  */
    updateMeterHistoryRecords?: Maybe<Array<Maybe<MeterHistoryRecord>>>
    /**  Update a single MeterReading item by ID.  */
    updateMeterReading?: Maybe<MeterReading>
    /**  Update a single MeterReadingExportTask item by ID.  */
    updateMeterReadingExportTask?: Maybe<MeterReadingExportTask>
    /**  Update a single MeterReadingExportTaskHistoryRecord item by ID.  */
    updateMeterReadingExportTaskHistoryRecord?: Maybe<MeterReadingExportTaskHistoryRecord>
    /**  Update multiple MeterReadingExportTaskHistoryRecord items by ID.  */
    updateMeterReadingExportTaskHistoryRecords?: Maybe<Array<Maybe<MeterReadingExportTaskHistoryRecord>>>
    /**  Update multiple MeterReadingExportTask items by ID.  */
    updateMeterReadingExportTasks?: Maybe<Array<Maybe<MeterReadingExportTask>>>
    /**  Update a single MeterReadingFilterTemplate item by ID.  */
    updateMeterReadingFilterTemplate?: Maybe<MeterReadingFilterTemplate>
    /**  Update a single MeterReadingFilterTemplateHistoryRecord item by ID.  */
    updateMeterReadingFilterTemplateHistoryRecord?: Maybe<MeterReadingFilterTemplateHistoryRecord>
    /**  Update multiple MeterReadingFilterTemplateHistoryRecord items by ID.  */
    updateMeterReadingFilterTemplateHistoryRecords?: Maybe<Array<Maybe<MeterReadingFilterTemplateHistoryRecord>>>
    /**  Update multiple MeterReadingFilterTemplate items by ID.  */
    updateMeterReadingFilterTemplates?: Maybe<Array<Maybe<MeterReadingFilterTemplate>>>
    /**  Update a single MeterReadingHistoryRecord item by ID.  */
    updateMeterReadingHistoryRecord?: Maybe<MeterReadingHistoryRecord>
    /**  Update multiple MeterReadingHistoryRecord items by ID.  */
    updateMeterReadingHistoryRecords?: Maybe<Array<Maybe<MeterReadingHistoryRecord>>>
    /**  Update a single MeterReadingSource item by ID.  */
    updateMeterReadingSource?: Maybe<MeterReadingSource>
    /**  Update a single MeterReadingSourceHistoryRecord item by ID.  */
    updateMeterReadingSourceHistoryRecord?: Maybe<MeterReadingSourceHistoryRecord>
    /**  Update multiple MeterReadingSourceHistoryRecord items by ID.  */
    updateMeterReadingSourceHistoryRecords?: Maybe<Array<Maybe<MeterReadingSourceHistoryRecord>>>
    /**  Update multiple MeterReadingSource items by ID.  */
    updateMeterReadingSources?: Maybe<Array<Maybe<MeterReadingSource>>>
    /**  Update multiple MeterReading items by ID.  */
    updateMeterReadings?: Maybe<Array<Maybe<MeterReading>>>
    /**  Update a single MeterReadingsImportTask item by ID.  */
    updateMeterReadingsImportTask?: Maybe<MeterReadingsImportTask>
    /**  Update a single MeterReadingsImportTaskHistoryRecord item by ID.  */
    updateMeterReadingsImportTaskHistoryRecord?: Maybe<MeterReadingsImportTaskHistoryRecord>
    /**  Update multiple MeterReadingsImportTaskHistoryRecord items by ID.  */
    updateMeterReadingsImportTaskHistoryRecords?: Maybe<Array<Maybe<MeterReadingsImportTaskHistoryRecord>>>
    /**  Update multiple MeterReadingsImportTask items by ID.  */
    updateMeterReadingsImportTasks?: Maybe<Array<Maybe<MeterReadingsImportTask>>>
    /**  Update a single MeterReportingPeriod item by ID.  */
    updateMeterReportingPeriod?: Maybe<MeterReportingPeriod>
    /**  Update a single MeterReportingPeriodHistoryRecord item by ID.  */
    updateMeterReportingPeriodHistoryRecord?: Maybe<MeterReportingPeriodHistoryRecord>
    /**  Update multiple MeterReportingPeriodHistoryRecord items by ID.  */
    updateMeterReportingPeriodHistoryRecords?: Maybe<Array<Maybe<MeterReportingPeriodHistoryRecord>>>
    /**  Update multiple MeterReportingPeriod items by ID.  */
    updateMeterReportingPeriods?: Maybe<Array<Maybe<MeterReportingPeriod>>>
    /**  Update a single MeterResource item by ID.  */
    updateMeterResource?: Maybe<MeterResource>
    /**  Update a single MeterResourceHistoryRecord item by ID.  */
    updateMeterResourceHistoryRecord?: Maybe<MeterResourceHistoryRecord>
    /**  Update multiple MeterResourceHistoryRecord items by ID.  */
    updateMeterResourceHistoryRecords?: Maybe<Array<Maybe<MeterResourceHistoryRecord>>>
    /**  Update a single MeterResourceOwner item by ID.  */
    updateMeterResourceOwner?: Maybe<MeterResourceOwner>
    /**  Update a single MeterResourceOwnerHistoryRecord item by ID.  */
    updateMeterResourceOwnerHistoryRecord?: Maybe<MeterResourceOwnerHistoryRecord>
    /**  Update multiple MeterResourceOwnerHistoryRecord items by ID.  */
    updateMeterResourceOwnerHistoryRecords?: Maybe<Array<Maybe<MeterResourceOwnerHistoryRecord>>>
    /**  Update multiple MeterResourceOwner items by ID.  */
    updateMeterResourceOwners?: Maybe<Array<Maybe<MeterResourceOwner>>>
    /**  Update multiple MeterResource items by ID.  */
    updateMeterResources?: Maybe<Array<Maybe<MeterResource>>>
    /**  Update multiple Meter items by ID.  */
    updateMeters?: Maybe<Array<Maybe<Meter>>>
    /**  Update a single MobileFeatureConfig item by ID.  */
    updateMobileFeatureConfig?: Maybe<MobileFeatureConfig>
    /**  Update a single MobileFeatureConfigHistoryRecord item by ID.  */
    updateMobileFeatureConfigHistoryRecord?: Maybe<MobileFeatureConfigHistoryRecord>
    /**  Update multiple MobileFeatureConfigHistoryRecord items by ID.  */
    updateMobileFeatureConfigHistoryRecords?: Maybe<Array<Maybe<MobileFeatureConfigHistoryRecord>>>
    /**  Update multiple MobileFeatureConfig items by ID.  */
    updateMobileFeatureConfigs?: Maybe<Array<Maybe<MobileFeatureConfig>>>
    /**  Update a single MultiPayment item by ID.  */
    updateMultiPayment?: Maybe<MultiPayment>
    /**  Update a single MultiPaymentHistoryRecord item by ID.  */
    updateMultiPaymentHistoryRecord?: Maybe<MultiPaymentHistoryRecord>
    /**  Update multiple MultiPaymentHistoryRecord items by ID.  */
    updateMultiPaymentHistoryRecords?: Maybe<Array<Maybe<MultiPaymentHistoryRecord>>>
    /**  Update multiple MultiPayment items by ID.  */
    updateMultiPayments?: Maybe<Array<Maybe<MultiPayment>>>
    /**  Update a single NewsItem item by ID.  */
    updateNewsItem?: Maybe<NewsItem>
    /**  Update a single NewsItemHistoryRecord item by ID.  */
    updateNewsItemHistoryRecord?: Maybe<NewsItemHistoryRecord>
    /**  Update multiple NewsItemHistoryRecord items by ID.  */
    updateNewsItemHistoryRecords?: Maybe<Array<Maybe<NewsItemHistoryRecord>>>
    /**  Update a single NewsItemRecipientsExportTask item by ID.  */
    updateNewsItemRecipientsExportTask?: Maybe<NewsItemRecipientsExportTask>
    /**  Update a single NewsItemRecipientsExportTaskHistoryRecord item by ID.  */
    updateNewsItemRecipientsExportTaskHistoryRecord?: Maybe<NewsItemRecipientsExportTaskHistoryRecord>
    /**  Update multiple NewsItemRecipientsExportTaskHistoryRecord items by ID.  */
    updateNewsItemRecipientsExportTaskHistoryRecords?: Maybe<Array<Maybe<NewsItemRecipientsExportTaskHistoryRecord>>>
    /**  Update multiple NewsItemRecipientsExportTask items by ID.  */
    updateNewsItemRecipientsExportTasks?: Maybe<Array<Maybe<NewsItemRecipientsExportTask>>>
    /**  Update a single NewsItemScope item by ID.  */
    updateNewsItemScope?: Maybe<NewsItemScope>
    /**  Update a single NewsItemScopeHistoryRecord item by ID.  */
    updateNewsItemScopeHistoryRecord?: Maybe<NewsItemScopeHistoryRecord>
    /**  Update multiple NewsItemScopeHistoryRecord items by ID.  */
    updateNewsItemScopeHistoryRecords?: Maybe<Array<Maybe<NewsItemScopeHistoryRecord>>>
    /**  Update multiple NewsItemScope items by ID.  */
    updateNewsItemScopes?: Maybe<Array<Maybe<NewsItemScope>>>
    /**  Update a single NewsItemSharing item by ID.  */
    updateNewsItemSharing?: Maybe<NewsItemSharing>
    /**  Update a single NewsItemSharingHistoryRecord item by ID.  */
    updateNewsItemSharingHistoryRecord?: Maybe<NewsItemSharingHistoryRecord>
    /**  Update multiple NewsItemSharingHistoryRecord items by ID.  */
    updateNewsItemSharingHistoryRecords?: Maybe<Array<Maybe<NewsItemSharingHistoryRecord>>>
    /**  Update multiple NewsItemSharing items by ID.  */
    updateNewsItemSharings?: Maybe<Array<Maybe<NewsItemSharing>>>
    /**  Update a single NewsItemTemplate item by ID.  */
    updateNewsItemTemplate?: Maybe<NewsItemTemplate>
    /**  Update a single NewsItemTemplateHistoryRecord item by ID.  */
    updateNewsItemTemplateHistoryRecord?: Maybe<NewsItemTemplateHistoryRecord>
    /**  Update multiple NewsItemTemplateHistoryRecord items by ID.  */
    updateNewsItemTemplateHistoryRecords?: Maybe<Array<Maybe<NewsItemTemplateHistoryRecord>>>
    /**  Update multiple NewsItemTemplate items by ID.  */
    updateNewsItemTemplates?: Maybe<Array<Maybe<NewsItemTemplate>>>
    /**  Update a single NewsItemUserRead item by ID.  */
    updateNewsItemUserRead?: Maybe<NewsItemUserRead>
    /**  Update a single NewsItemUserReadHistoryRecord item by ID.  */
    updateNewsItemUserReadHistoryRecord?: Maybe<NewsItemUserReadHistoryRecord>
    /**  Update multiple NewsItemUserReadHistoryRecord items by ID.  */
    updateNewsItemUserReadHistoryRecords?: Maybe<Array<Maybe<NewsItemUserReadHistoryRecord>>>
    /**  Update multiple NewsItemUserRead items by ID.  */
    updateNewsItemUserReads?: Maybe<Array<Maybe<NewsItemUserRead>>>
    /**  Update multiple NewsItem items by ID.  */
    updateNewsItems?: Maybe<Array<Maybe<NewsItem>>>
    /**  Update a single NotificationAnonymousSetting item by ID.  */
    updateNotificationAnonymousSetting?: Maybe<NotificationAnonymousSetting>
    /**  Update a single NotificationAnonymousSettingHistoryRecord item by ID.  */
    updateNotificationAnonymousSettingHistoryRecord?: Maybe<NotificationAnonymousSettingHistoryRecord>
    /**  Update multiple NotificationAnonymousSettingHistoryRecord items by ID.  */
    updateNotificationAnonymousSettingHistoryRecords?: Maybe<Array<Maybe<NotificationAnonymousSettingHistoryRecord>>>
    /**  Update multiple NotificationAnonymousSetting items by ID.  */
    updateNotificationAnonymousSettings?: Maybe<Array<Maybe<NotificationAnonymousSetting>>>
    /**  Update a single NotificationUserSetting item by ID.  */
    updateNotificationUserSetting?: Maybe<NotificationUserSetting>
    /**  Update a single NotificationUserSettingHistoryRecord item by ID.  */
    updateNotificationUserSettingHistoryRecord?: Maybe<NotificationUserSettingHistoryRecord>
    /**  Update multiple NotificationUserSettingHistoryRecord items by ID.  */
    updateNotificationUserSettingHistoryRecords?: Maybe<Array<Maybe<NotificationUserSettingHistoryRecord>>>
    /**  Update multiple NotificationUserSetting items by ID.  */
    updateNotificationUserSettings?: Maybe<Array<Maybe<NotificationUserSetting>>>
    /**  Update a single OidcClient item by ID.  */
    updateOidcClient?: Maybe<OidcClient>
    /**  Update a single OidcClientHistoryRecord item by ID.  */
    updateOidcClientHistoryRecord?: Maybe<OidcClientHistoryRecord>
    /**  Update multiple OidcClientHistoryRecord items by ID.  */
    updateOidcClientHistoryRecords?: Maybe<Array<Maybe<OidcClientHistoryRecord>>>
    /**  Update multiple OidcClient items by ID.  */
    updateOidcClients?: Maybe<Array<Maybe<OidcClient>>>
    /**  Update a single Organization item by ID.  */
    updateOrganization?: Maybe<Organization>
    /**  Update a single OrganizationEmployee item by ID.  */
    updateOrganizationEmployee?: Maybe<OrganizationEmployee>
    /**  Update a single OrganizationEmployeeHistoryRecord item by ID.  */
    updateOrganizationEmployeeHistoryRecord?: Maybe<OrganizationEmployeeHistoryRecord>
    /**  Update multiple OrganizationEmployeeHistoryRecord items by ID.  */
    updateOrganizationEmployeeHistoryRecords?: Maybe<Array<Maybe<OrganizationEmployeeHistoryRecord>>>
    /**  Update a single OrganizationEmployeeRole item by ID.  */
    updateOrganizationEmployeeRole?: Maybe<OrganizationEmployeeRole>
    /**  Update a single OrganizationEmployeeRoleHistoryRecord item by ID.  */
    updateOrganizationEmployeeRoleHistoryRecord?: Maybe<OrganizationEmployeeRoleHistoryRecord>
    /**  Update multiple OrganizationEmployeeRoleHistoryRecord items by ID.  */
    updateOrganizationEmployeeRoleHistoryRecords?: Maybe<Array<Maybe<OrganizationEmployeeRoleHistoryRecord>>>
    /**  Update multiple OrganizationEmployeeRole items by ID.  */
    updateOrganizationEmployeeRoles?: Maybe<Array<Maybe<OrganizationEmployeeRole>>>
    /**  Update a single OrganizationEmployeeSpecialization item by ID.  */
    updateOrganizationEmployeeSpecialization?: Maybe<OrganizationEmployeeSpecialization>
    /**  Update a single OrganizationEmployeeSpecializationHistoryRecord item by ID.  */
    updateOrganizationEmployeeSpecializationHistoryRecord?: Maybe<OrganizationEmployeeSpecializationHistoryRecord>
    /**  Update multiple OrganizationEmployeeSpecializationHistoryRecord items by ID.  */
    updateOrganizationEmployeeSpecializationHistoryRecords?: Maybe<Array<Maybe<OrganizationEmployeeSpecializationHistoryRecord>>>
    /**  Update multiple OrganizationEmployeeSpecialization items by ID.  */
    updateOrganizationEmployeeSpecializations?: Maybe<Array<Maybe<OrganizationEmployeeSpecialization>>>
    /**  Update multiple OrganizationEmployee items by ID.  */
    updateOrganizationEmployees?: Maybe<Array<Maybe<OrganizationEmployee>>>
    /**  Update a single OrganizationHistoryRecord item by ID.  */
    updateOrganizationHistoryRecord?: Maybe<OrganizationHistoryRecord>
    /**  Update multiple OrganizationHistoryRecord items by ID.  */
    updateOrganizationHistoryRecords?: Maybe<Array<Maybe<OrganizationHistoryRecord>>>
    /**  Update a single OrganizationLink item by ID.  */
    updateOrganizationLink?: Maybe<OrganizationLink>
    /**  Update a single OrganizationLinkHistoryRecord item by ID.  */
    updateOrganizationLinkHistoryRecord?: Maybe<OrganizationLinkHistoryRecord>
    /**  Update multiple OrganizationLinkHistoryRecord items by ID.  */
    updateOrganizationLinkHistoryRecords?: Maybe<Array<Maybe<OrganizationLinkHistoryRecord>>>
    /**  Update multiple OrganizationLink items by ID.  */
    updateOrganizationLinks?: Maybe<Array<Maybe<OrganizationLink>>>
    /**  Update multiple Organization items by ID.  */
    updateOrganizations?: Maybe<Array<Maybe<Organization>>>
    /**  Update a single Payment item by ID.  */
    updatePayment?: Maybe<Payment>
    /**  Update a single PaymentHistoryRecord item by ID.  */
    updatePaymentHistoryRecord?: Maybe<PaymentHistoryRecord>
    /**  Update multiple PaymentHistoryRecord items by ID.  */
    updatePaymentHistoryRecords?: Maybe<Array<Maybe<PaymentHistoryRecord>>>
    /**  Update multiple Payment items by ID.  */
    updatePayments?: Maybe<Array<Maybe<Payment>>>
    /**  Update a single PaymentsFilterTemplate item by ID.  */
    updatePaymentsFilterTemplate?: Maybe<PaymentsFilterTemplate>
    /**  Update a single PaymentsFilterTemplateHistoryRecord item by ID.  */
    updatePaymentsFilterTemplateHistoryRecord?: Maybe<PaymentsFilterTemplateHistoryRecord>
    /**  Update multiple PaymentsFilterTemplateHistoryRecord items by ID.  */
    updatePaymentsFilterTemplateHistoryRecords?: Maybe<Array<Maybe<PaymentsFilterTemplateHistoryRecord>>>
    /**  Update multiple PaymentsFilterTemplate items by ID.  */
    updatePaymentsFilterTemplates?: Maybe<Array<Maybe<PaymentsFilterTemplate>>>
    /**  Update multiple Property items by ID.  */
    updateProperties?: Maybe<Array<Maybe<Property>>>
    /**  Update a single Property item by ID.  */
    updateProperty?: Maybe<Property>
    /**  Update a single PropertyHistoryRecord item by ID.  */
    updatePropertyHistoryRecord?: Maybe<PropertyHistoryRecord>
    /**  Update multiple PropertyHistoryRecord items by ID.  */
    updatePropertyHistoryRecords?: Maybe<Array<Maybe<PropertyHistoryRecord>>>
    /**  Update a single PropertyMeter item by ID.  */
    updatePropertyMeter?: Maybe<PropertyMeter>
    /**  Update a single PropertyMeterHistoryRecord item by ID.  */
    updatePropertyMeterHistoryRecord?: Maybe<PropertyMeterHistoryRecord>
    /**  Update multiple PropertyMeterHistoryRecord items by ID.  */
    updatePropertyMeterHistoryRecords?: Maybe<Array<Maybe<PropertyMeterHistoryRecord>>>
    /**  Update a single PropertyMeterReading item by ID.  */
    updatePropertyMeterReading?: Maybe<PropertyMeterReading>
    /**  Update a single PropertyMeterReadingHistoryRecord item by ID.  */
    updatePropertyMeterReadingHistoryRecord?: Maybe<PropertyMeterReadingHistoryRecord>
    /**  Update multiple PropertyMeterReadingHistoryRecord items by ID.  */
    updatePropertyMeterReadingHistoryRecords?: Maybe<Array<Maybe<PropertyMeterReadingHistoryRecord>>>
    /**  Update multiple PropertyMeterReading items by ID.  */
    updatePropertyMeterReadings?: Maybe<Array<Maybe<PropertyMeterReading>>>
    /**  Update multiple PropertyMeter items by ID.  */
    updatePropertyMeters?: Maybe<Array<Maybe<PropertyMeter>>>
    /**  Update a single PropertyScope item by ID.  */
    updatePropertyScope?: Maybe<PropertyScope>
    /**  Update a single PropertyScopeHistoryRecord item by ID.  */
    updatePropertyScopeHistoryRecord?: Maybe<PropertyScopeHistoryRecord>
    /**  Update multiple PropertyScopeHistoryRecord items by ID.  */
    updatePropertyScopeHistoryRecords?: Maybe<Array<Maybe<PropertyScopeHistoryRecord>>>
    /**  Update a single PropertyScopeOrganizationEmployee item by ID.  */
    updatePropertyScopeOrganizationEmployee?: Maybe<PropertyScopeOrganizationEmployee>
    /**  Update a single PropertyScopeOrganizationEmployeeHistoryRecord item by ID.  */
    updatePropertyScopeOrganizationEmployeeHistoryRecord?: Maybe<PropertyScopeOrganizationEmployeeHistoryRecord>
    /**  Update multiple PropertyScopeOrganizationEmployeeHistoryRecord items by ID.  */
    updatePropertyScopeOrganizationEmployeeHistoryRecords?: Maybe<Array<Maybe<PropertyScopeOrganizationEmployeeHistoryRecord>>>
    /**  Update multiple PropertyScopeOrganizationEmployee items by ID.  */
    updatePropertyScopeOrganizationEmployees?: Maybe<Array<Maybe<PropertyScopeOrganizationEmployee>>>
    /**  Update multiple PropertyScopeProperty items by ID.  */
    updatePropertyScopeProperties?: Maybe<Array<Maybe<PropertyScopeProperty>>>
    /**  Update a single PropertyScopeProperty item by ID.  */
    updatePropertyScopeProperty?: Maybe<PropertyScopeProperty>
    /**  Update a single PropertyScopePropertyHistoryRecord item by ID.  */
    updatePropertyScopePropertyHistoryRecord?: Maybe<PropertyScopePropertyHistoryRecord>
    /**  Update multiple PropertyScopePropertyHistoryRecord items by ID.  */
    updatePropertyScopePropertyHistoryRecords?: Maybe<Array<Maybe<PropertyScopePropertyHistoryRecord>>>
    /**  Update multiple PropertyScope items by ID.  */
    updatePropertyScopes?: Maybe<Array<Maybe<PropertyScope>>>
    /**  Update a single RecurrentPayment item by ID.  */
    updateRecurrentPayment?: Maybe<RecurrentPayment>
    /**  Update a single RecurrentPaymentContext item by ID.  */
    updateRecurrentPaymentContext?: Maybe<RecurrentPaymentContext>
    /**  Update a single RecurrentPaymentContextHistoryRecord item by ID.  */
    updateRecurrentPaymentContextHistoryRecord?: Maybe<RecurrentPaymentContextHistoryRecord>
    /**  Update multiple RecurrentPaymentContextHistoryRecord items by ID.  */
    updateRecurrentPaymentContextHistoryRecords?: Maybe<Array<Maybe<RecurrentPaymentContextHistoryRecord>>>
    /**  Update multiple RecurrentPaymentContext items by ID.  */
    updateRecurrentPaymentContexts?: Maybe<Array<Maybe<RecurrentPaymentContext>>>
    /**  Update a single RecurrentPaymentHistoryRecord item by ID.  */
    updateRecurrentPaymentHistoryRecord?: Maybe<RecurrentPaymentHistoryRecord>
    /**  Update multiple RecurrentPaymentHistoryRecord items by ID.  */
    updateRecurrentPaymentHistoryRecords?: Maybe<Array<Maybe<RecurrentPaymentHistoryRecord>>>
    /**  Update multiple RecurrentPayment items by ID.  */
    updateRecurrentPayments?: Maybe<Array<Maybe<RecurrentPayment>>>
    /**  Update a single RemoteClient item by ID.  */
    updateRemoteClient?: Maybe<RemoteClient>
    /**  Update a single RemoteClientHistoryRecord item by ID.  */
    updateRemoteClientHistoryRecord?: Maybe<RemoteClientHistoryRecord>
    /**  Update multiple RemoteClientHistoryRecord items by ID.  */
    updateRemoteClientHistoryRecords?: Maybe<Array<Maybe<RemoteClientHistoryRecord>>>
    /**  Update multiple RemoteClient items by ID.  */
    updateRemoteClients?: Maybe<Array<Maybe<RemoteClient>>>
    /**  Update a single Resident item by ID.  */
    updateResident?: Maybe<Resident>
    /**  Update a single ResidentHistoryRecord item by ID.  */
    updateResidentHistoryRecord?: Maybe<ResidentHistoryRecord>
    /**  Update multiple ResidentHistoryRecord items by ID.  */
    updateResidentHistoryRecords?: Maybe<Array<Maybe<ResidentHistoryRecord>>>
    /**  Update multiple Resident items by ID.  */
    updateResidents?: Maybe<Array<Maybe<Resident>>>
    /**  Update a single ServiceConsumer item by ID.  */
    updateServiceConsumer?: Maybe<ServiceConsumer>
    /**  Update a single ServiceConsumerHistoryRecord item by ID.  */
    updateServiceConsumerHistoryRecord?: Maybe<ServiceConsumerHistoryRecord>
    /**  Update multiple ServiceConsumerHistoryRecord items by ID.  */
    updateServiceConsumerHistoryRecords?: Maybe<Array<Maybe<ServiceConsumerHistoryRecord>>>
    /**  Update multiple ServiceConsumer items by ID.  */
    updateServiceConsumers?: Maybe<Array<Maybe<ServiceConsumer>>>
    /**  Update a single ServiceSubscription item by ID.  */
    updateServiceSubscription?: Maybe<ServiceSubscription>
    /**  Update a single ServiceSubscriptionHistoryRecord item by ID.  */
    updateServiceSubscriptionHistoryRecord?: Maybe<ServiceSubscriptionHistoryRecord>
    /**  Update multiple ServiceSubscriptionHistoryRecord items by ID.  */
    updateServiceSubscriptionHistoryRecords?: Maybe<Array<Maybe<ServiceSubscriptionHistoryRecord>>>
    /**  Update multiple ServiceSubscription items by ID.  */
    updateServiceSubscriptions?: Maybe<Array<Maybe<ServiceSubscription>>>
    /**  Update a single TelegramUserChat item by ID.  */
    updateTelegramUserChat?: Maybe<TelegramUserChat>
    /**  Update a single TelegramUserChatHistoryRecord item by ID.  */
    updateTelegramUserChatHistoryRecord?: Maybe<TelegramUserChatHistoryRecord>
    /**  Update multiple TelegramUserChatHistoryRecord items by ID.  */
    updateTelegramUserChatHistoryRecords?: Maybe<Array<Maybe<TelegramUserChatHistoryRecord>>>
    /**  Update multiple TelegramUserChat items by ID.  */
    updateTelegramUserChats?: Maybe<Array<Maybe<TelegramUserChat>>>
    /**  Update a single Ticket item by ID.  */
    updateTicket?: Maybe<Ticket>
    /**  Update a single TicketAutoAssignment item by ID.  */
    updateTicketAutoAssignment?: Maybe<TicketAutoAssignment>
    /**  Update a single TicketAutoAssignmentHistoryRecord item by ID.  */
    updateTicketAutoAssignmentHistoryRecord?: Maybe<TicketAutoAssignmentHistoryRecord>
    /**  Update multiple TicketAutoAssignmentHistoryRecord items by ID.  */
    updateTicketAutoAssignmentHistoryRecords?: Maybe<Array<Maybe<TicketAutoAssignmentHistoryRecord>>>
    /**  Update multiple TicketAutoAssignment items by ID.  */
    updateTicketAutoAssignments?: Maybe<Array<Maybe<TicketAutoAssignment>>>
    /**  Update a single TicketCategoryClassifier item by ID.  */
    updateTicketCategoryClassifier?: Maybe<TicketCategoryClassifier>
    /**  Update a single TicketCategoryClassifierHistoryRecord item by ID.  */
    updateTicketCategoryClassifierHistoryRecord?: Maybe<TicketCategoryClassifierHistoryRecord>
    /**  Update multiple TicketCategoryClassifierHistoryRecord items by ID.  */
    updateTicketCategoryClassifierHistoryRecords?: Maybe<Array<Maybe<TicketCategoryClassifierHistoryRecord>>>
    /**  Update multiple TicketCategoryClassifier items by ID.  */
    updateTicketCategoryClassifiers?: Maybe<Array<Maybe<TicketCategoryClassifier>>>
    /**  Update a single TicketChange item by ID.  */
    updateTicketChange?: Maybe<TicketChange>
    /**  Update multiple TicketChange items by ID.  */
    updateTicketChanges?: Maybe<Array<Maybe<TicketChange>>>
    /**  Update a single TicketClassifier item by ID.  */
    updateTicketClassifier?: Maybe<TicketClassifier>
    /**  Update a single TicketClassifierHistoryRecord item by ID.  */
    updateTicketClassifierHistoryRecord?: Maybe<TicketClassifierHistoryRecord>
    /**  Update multiple TicketClassifierHistoryRecord items by ID.  */
    updateTicketClassifierHistoryRecords?: Maybe<Array<Maybe<TicketClassifierHistoryRecord>>>
    /**  Update multiple TicketClassifier items by ID.  */
    updateTicketClassifiers?: Maybe<Array<Maybe<TicketClassifier>>>
    /**  Update a single TicketComment item by ID.  */
    updateTicketComment?: Maybe<TicketComment>
    /**  Update a single TicketCommentFile item by ID.  */
    updateTicketCommentFile?: Maybe<TicketCommentFile>
    /**  Update a single TicketCommentFileHistoryRecord item by ID.  */
    updateTicketCommentFileHistoryRecord?: Maybe<TicketCommentFileHistoryRecord>
    /**  Update multiple TicketCommentFileHistoryRecord items by ID.  */
    updateTicketCommentFileHistoryRecords?: Maybe<Array<Maybe<TicketCommentFileHistoryRecord>>>
    /**  Update multiple TicketCommentFile items by ID.  */
    updateTicketCommentFiles?: Maybe<Array<Maybe<TicketCommentFile>>>
    /**  Update a single TicketCommentHistoryRecord item by ID.  */
    updateTicketCommentHistoryRecord?: Maybe<TicketCommentHistoryRecord>
    /**  Update multiple TicketCommentHistoryRecord items by ID.  */
    updateTicketCommentHistoryRecords?: Maybe<Array<Maybe<TicketCommentHistoryRecord>>>
    /**  Update multiple TicketComment items by ID.  */
    updateTicketComments?: Maybe<Array<Maybe<TicketComment>>>
    /**  Update a single TicketDocumentGenerationTask item by ID.  */
    updateTicketDocumentGenerationTask?: Maybe<TicketDocumentGenerationTask>
    /**  Update a single TicketDocumentGenerationTaskHistoryRecord item by ID.  */
    updateTicketDocumentGenerationTaskHistoryRecord?: Maybe<TicketDocumentGenerationTaskHistoryRecord>
    /**  Update multiple TicketDocumentGenerationTaskHistoryRecord items by ID.  */
    updateTicketDocumentGenerationTaskHistoryRecords?: Maybe<Array<Maybe<TicketDocumentGenerationTaskHistoryRecord>>>
    /**  Update multiple TicketDocumentGenerationTask items by ID.  */
    updateTicketDocumentGenerationTasks?: Maybe<Array<Maybe<TicketDocumentGenerationTask>>>
    /**  Update a single TicketExportTask item by ID.  */
    updateTicketExportTask?: Maybe<TicketExportTask>
    /**  Update a single TicketExportTaskHistoryRecord item by ID.  */
    updateTicketExportTaskHistoryRecord?: Maybe<TicketExportTaskHistoryRecord>
    /**  Update multiple TicketExportTaskHistoryRecord items by ID.  */
    updateTicketExportTaskHistoryRecords?: Maybe<Array<Maybe<TicketExportTaskHistoryRecord>>>
    /**  Update multiple TicketExportTask items by ID.  */
    updateTicketExportTasks?: Maybe<Array<Maybe<TicketExportTask>>>
    /**  Update a single TicketFile item by ID.  */
    updateTicketFile?: Maybe<TicketFile>
    /**  Update a single TicketFileHistoryRecord item by ID.  */
    updateTicketFileHistoryRecord?: Maybe<TicketFileHistoryRecord>
    /**  Update multiple TicketFileHistoryRecord items by ID.  */
    updateTicketFileHistoryRecords?: Maybe<Array<Maybe<TicketFileHistoryRecord>>>
    /**  Update multiple TicketFile items by ID.  */
    updateTicketFiles?: Maybe<Array<Maybe<TicketFile>>>
    /**  Update a single TicketFilterTemplate item by ID.  */
    updateTicketFilterTemplate?: Maybe<TicketFilterTemplate>
    /**  Update a single TicketFilterTemplateHistoryRecord item by ID.  */
    updateTicketFilterTemplateHistoryRecord?: Maybe<TicketFilterTemplateHistoryRecord>
    /**  Update multiple TicketFilterTemplateHistoryRecord items by ID.  */
    updateTicketFilterTemplateHistoryRecords?: Maybe<Array<Maybe<TicketFilterTemplateHistoryRecord>>>
    /**  Update multiple TicketFilterTemplate items by ID.  */
    updateTicketFilterTemplates?: Maybe<Array<Maybe<TicketFilterTemplate>>>
    /**  Update a single TicketHistoryRecord item by ID.  */
    updateTicketHistoryRecord?: Maybe<TicketHistoryRecord>
    /**  Update multiple TicketHistoryRecord items by ID.  */
    updateTicketHistoryRecords?: Maybe<Array<Maybe<TicketHistoryRecord>>>
    /**  Update a single TicketOrganizationSetting item by ID.  */
    updateTicketOrganizationSetting?: Maybe<TicketOrganizationSetting>
    /**  Update a single TicketOrganizationSettingHistoryRecord item by ID.  */
    updateTicketOrganizationSettingHistoryRecord?: Maybe<TicketOrganizationSettingHistoryRecord>
    /**  Update multiple TicketOrganizationSettingHistoryRecord items by ID.  */
    updateTicketOrganizationSettingHistoryRecords?: Maybe<Array<Maybe<TicketOrganizationSettingHistoryRecord>>>
    /**  Update multiple TicketOrganizationSetting items by ID.  */
    updateTicketOrganizationSettings?: Maybe<Array<Maybe<TicketOrganizationSetting>>>
    /**  Update a single TicketPlaceClassifier item by ID.  */
    updateTicketPlaceClassifier?: Maybe<TicketPlaceClassifier>
    /**  Update a single TicketPlaceClassifierHistoryRecord item by ID.  */
    updateTicketPlaceClassifierHistoryRecord?: Maybe<TicketPlaceClassifierHistoryRecord>
    /**  Update multiple TicketPlaceClassifierHistoryRecord items by ID.  */
    updateTicketPlaceClassifierHistoryRecords?: Maybe<Array<Maybe<TicketPlaceClassifierHistoryRecord>>>
    /**  Update multiple TicketPlaceClassifier items by ID.  */
    updateTicketPlaceClassifiers?: Maybe<Array<Maybe<TicketPlaceClassifier>>>
    /**  Update a single TicketProblemClassifier item by ID.  */
    updateTicketProblemClassifier?: Maybe<TicketProblemClassifier>
    /**  Update a single TicketProblemClassifierHistoryRecord item by ID.  */
    updateTicketProblemClassifierHistoryRecord?: Maybe<TicketProblemClassifierHistoryRecord>
    /**  Update multiple TicketProblemClassifierHistoryRecord items by ID.  */
    updateTicketProblemClassifierHistoryRecords?: Maybe<Array<Maybe<TicketProblemClassifierHistoryRecord>>>
    /**  Update multiple TicketProblemClassifier items by ID.  */
    updateTicketProblemClassifiers?: Maybe<Array<Maybe<TicketProblemClassifier>>>
    /**  Update a single TicketPropertyHint item by ID.  */
    updateTicketPropertyHint?: Maybe<TicketPropertyHint>
    /**  Update a single TicketPropertyHintHistoryRecord item by ID.  */
    updateTicketPropertyHintHistoryRecord?: Maybe<TicketPropertyHintHistoryRecord>
    /**  Update multiple TicketPropertyHintHistoryRecord items by ID.  */
    updateTicketPropertyHintHistoryRecords?: Maybe<Array<Maybe<TicketPropertyHintHistoryRecord>>>
    /**  Update multiple TicketPropertyHintProperty items by ID.  */
    updateTicketPropertyHintProperties?: Maybe<Array<Maybe<TicketPropertyHintProperty>>>
    /**  Update a single TicketPropertyHintProperty item by ID.  */
    updateTicketPropertyHintProperty?: Maybe<TicketPropertyHintProperty>
    /**  Update a single TicketPropertyHintPropertyHistoryRecord item by ID.  */
    updateTicketPropertyHintPropertyHistoryRecord?: Maybe<TicketPropertyHintPropertyHistoryRecord>
    /**  Update multiple TicketPropertyHintPropertyHistoryRecord items by ID.  */
    updateTicketPropertyHintPropertyHistoryRecords?: Maybe<Array<Maybe<TicketPropertyHintPropertyHistoryRecord>>>
    /**  Update multiple TicketPropertyHint items by ID.  */
    updateTicketPropertyHints?: Maybe<Array<Maybe<TicketPropertyHint>>>
    /**  Update a single TicketSource item by ID.  */
    updateTicketSource?: Maybe<TicketSource>
    /**  Update a single TicketSourceHistoryRecord item by ID.  */
    updateTicketSourceHistoryRecord?: Maybe<TicketSourceHistoryRecord>
    /**  Update multiple TicketSourceHistoryRecord items by ID.  */
    updateTicketSourceHistoryRecords?: Maybe<Array<Maybe<TicketSourceHistoryRecord>>>
    /**  Update multiple TicketSource items by ID.  */
    updateTicketSources?: Maybe<Array<Maybe<TicketSource>>>
    /**  Update a single TicketStatus item by ID.  */
    updateTicketStatus?: Maybe<TicketStatus>
    /**  Update a single TicketStatusHistoryRecord item by ID.  */
    updateTicketStatusHistoryRecord?: Maybe<TicketStatusHistoryRecord>
    /**  Update multiple TicketStatusHistoryRecord items by ID.  */
    updateTicketStatusHistoryRecords?: Maybe<Array<Maybe<TicketStatusHistoryRecord>>>
    /**  Update multiple TicketStatus items by ID.  */
    updateTicketStatuses?: Maybe<Array<Maybe<TicketStatus>>>
    /**  Update multiple Ticket items by ID.  */
    updateTickets?: Maybe<Array<Maybe<Ticket>>>
    /**  Update a single TourStep item by ID.  */
    updateTourStep?: Maybe<TourStep>
    /**  Update a single TourStepHistoryRecord item by ID.  */
    updateTourStepHistoryRecord?: Maybe<TourStepHistoryRecord>
    /**  Update multiple TourStepHistoryRecord items by ID.  */
    updateTourStepHistoryRecords?: Maybe<Array<Maybe<TourStepHistoryRecord>>>
    /**  Update multiple TourStep items by ID.  */
    updateTourSteps?: Maybe<Array<Maybe<TourStep>>>
    /**  Update a single User item by ID.  */
    updateUser?: Maybe<User>
    /**  Update multiple UserExternalIdentity items by ID.  */
    updateUserExternalIdentities?: Maybe<Array<Maybe<UserExternalIdentity>>>
    /**  Update a single UserExternalIdentity item by ID.  */
    updateUserExternalIdentity?: Maybe<UserExternalIdentity>
    /**  Update a single UserExternalIdentityHistoryRecord item by ID.  */
    updateUserExternalIdentityHistoryRecord?: Maybe<UserExternalIdentityHistoryRecord>
    /**  Update multiple UserExternalIdentityHistoryRecord items by ID.  */
    updateUserExternalIdentityHistoryRecords?: Maybe<Array<Maybe<UserExternalIdentityHistoryRecord>>>
    /**  Update a single UserFavoriteTicket item by ID.  */
    updateUserFavoriteTicket?: Maybe<UserFavoriteTicket>
    /**  Update a single UserFavoriteTicketHistoryRecord item by ID.  */
    updateUserFavoriteTicketHistoryRecord?: Maybe<UserFavoriteTicketHistoryRecord>
    /**  Update multiple UserFavoriteTicketHistoryRecord items by ID.  */
    updateUserFavoriteTicketHistoryRecords?: Maybe<Array<Maybe<UserFavoriteTicketHistoryRecord>>>
    /**  Update multiple UserFavoriteTicket items by ID.  */
    updateUserFavoriteTickets?: Maybe<Array<Maybe<UserFavoriteTicket>>>
    /**  Update a single UserHelpRequest item by ID.  */
    updateUserHelpRequest?: Maybe<UserHelpRequest>
    /**  Update a single UserHelpRequestFile item by ID.  */
    updateUserHelpRequestFile?: Maybe<UserHelpRequestFile>
    /**  Update a single UserHelpRequestFileHistoryRecord item by ID.  */
    updateUserHelpRequestFileHistoryRecord?: Maybe<UserHelpRequestFileHistoryRecord>
    /**  Update multiple UserHelpRequestFileHistoryRecord items by ID.  */
    updateUserHelpRequestFileHistoryRecords?: Maybe<Array<Maybe<UserHelpRequestFileHistoryRecord>>>
    /**  Update multiple UserHelpRequestFile items by ID.  */
    updateUserHelpRequestFiles?: Maybe<Array<Maybe<UserHelpRequestFile>>>
    /**  Update a single UserHelpRequestHistoryRecord item by ID.  */
    updateUserHelpRequestHistoryRecord?: Maybe<UserHelpRequestHistoryRecord>
    /**  Update multiple UserHelpRequestHistoryRecord items by ID.  */
    updateUserHelpRequestHistoryRecords?: Maybe<Array<Maybe<UserHelpRequestHistoryRecord>>>
    /**  Update multiple UserHelpRequest items by ID.  */
    updateUserHelpRequests?: Maybe<Array<Maybe<UserHelpRequest>>>
    /**  Update a single UserHistoryRecord item by ID.  */
    updateUserHistoryRecord?: Maybe<UserHistoryRecord>
    /**  Update multiple UserHistoryRecord items by ID.  */
    updateUserHistoryRecords?: Maybe<Array<Maybe<UserHistoryRecord>>>
    /**  Update a single UserRightsSet item by ID.  */
    updateUserRightsSet?: Maybe<UserRightsSet>
    /**  Update a single UserRightsSetHistoryRecord item by ID.  */
    updateUserRightsSetHistoryRecord?: Maybe<UserRightsSetHistoryRecord>
    /**  Update multiple UserRightsSetHistoryRecord items by ID.  */
    updateUserRightsSetHistoryRecords?: Maybe<Array<Maybe<UserRightsSetHistoryRecord>>>
    /**  Update multiple UserRightsSet items by ID.  */
    updateUserRightsSets?: Maybe<Array<Maybe<UserRightsSet>>>
    /**  Update a single UserTicketCommentReadTime item by ID.  */
    updateUserTicketCommentReadTime?: Maybe<UserTicketCommentReadTime>
    /**  Update a single UserTicketCommentReadTimeHistoryRecord item by ID.  */
    updateUserTicketCommentReadTimeHistoryRecord?: Maybe<UserTicketCommentReadTimeHistoryRecord>
    /**  Update multiple UserTicketCommentReadTimeHistoryRecord items by ID.  */
    updateUserTicketCommentReadTimeHistoryRecords?: Maybe<Array<Maybe<UserTicketCommentReadTimeHistoryRecord>>>
    /**  Update multiple UserTicketCommentReadTime items by ID.  */
    updateUserTicketCommentReadTimes?: Maybe<Array<Maybe<UserTicketCommentReadTime>>>
    /**  Update multiple User items by ID.  */
    updateUsers?: Maybe<Array<Maybe<User>>>
    /**  Update a single Webhook item by ID.  */
    updateWebhook?: Maybe<Webhook>
    /**  Update a single WebhookHistoryRecord item by ID.  */
    updateWebhookHistoryRecord?: Maybe<WebhookHistoryRecord>
    /**  Update multiple WebhookHistoryRecord items by ID.  */
    updateWebhookHistoryRecords?: Maybe<Array<Maybe<WebhookHistoryRecord>>>
    /**  Update a single WebhookSubscription item by ID.  */
    updateWebhookSubscription?: Maybe<WebhookSubscription>
    /**  Update a single WebhookSubscriptionHistoryRecord item by ID.  */
    updateWebhookSubscriptionHistoryRecord?: Maybe<WebhookSubscriptionHistoryRecord>
    /**  Update multiple WebhookSubscriptionHistoryRecord items by ID.  */
    updateWebhookSubscriptionHistoryRecords?: Maybe<Array<Maybe<WebhookSubscriptionHistoryRecord>>>
    /**  Update multiple WebhookSubscription items by ID.  */
    updateWebhookSubscriptions?: Maybe<Array<Maybe<WebhookSubscription>>>
    /**  Update multiple Webhook items by ID.  */
    updateWebhooks?: Maybe<Array<Maybe<Webhook>>>
    validateQRCode?: Maybe<ValidateQrCodeOutput>
}


export type Mutation_InternalDeleteMeterAndMeterReadingsArgs = {
    data: _InternalDeleteMeterAndMeterReadingsInput
}


export type Mutation_InternalDeleteMeterReadingsArgs = {
    data: _InternalDeleteMeterReadingsInput
}


export type Mutation_InternalScheduleTaskByNameArgs = {
    data: _InternalScheduleTaskByNameInput
}


export type Mutation_InternalSendHashedResidentPhonesArgs = {
    data: _InternalSendHashedResidentPhonesInput
}


export type Mutation_InternalSendNotificationNewMobileAppVersionArgs = {
    data: _InternalSendNotificationNewMobileAppVersionInput
}


export type Mutation_InternalSyncContactsWithResidentsForOrganizationArgs = {
    data: _InternalSyncContactsWithResidentsForOrganizationInput
}


export type MutationAcceptOrRejectOrganizationInviteByCodeArgs = {
    data: AcceptOrRejectOrganizationInviteInput
    inviteCode: Scalars['String']['input']
}


export type MutationAcceptOrRejectOrganizationInviteByIdArgs = {
    data: AcceptOrRejectOrganizationInviteInput
    id: Scalars['ID']['input']
}


export type MutationAuthenticateUserWithPasswordArgs = {
    email?: InputMaybe<Scalars['String']['input']>
    password?: InputMaybe<Scalars['String']['input']>
}


export type MutationAuthenticateUserWithPhoneAndPasswordArgs = {
    data: AuthenticateUserWithPhoneAndPasswordInput
}


export type MutationChangePasswordWithTokenArgs = {
    data: ChangePasswordWithTokenInput
}


export type MutationChangePhoneNumberResidentUserArgs = {
    data: ChangePhoneNumberResidentUserInput
}


export type MutationCompleteConfirmPhoneActionArgs = {
    data: CompleteConfirmPhoneActionInput
}


export type MutationCreateAcquiringIntegrationArgs = {
    data?: InputMaybe<AcquiringIntegrationCreateInput>
}


export type MutationCreateAcquiringIntegrationAccessRightArgs = {
    data?: InputMaybe<AcquiringIntegrationAccessRightCreateInput>
}


export type MutationCreateAcquiringIntegrationAccessRightHistoryRecordArgs = {
    data?: InputMaybe<AcquiringIntegrationAccessRightHistoryRecordCreateInput>
}


export type MutationCreateAcquiringIntegrationAccessRightHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<AcquiringIntegrationAccessRightHistoryRecordsCreateInput>>>
}


export type MutationCreateAcquiringIntegrationAccessRightsArgs = {
    data?: InputMaybe<Array<InputMaybe<AcquiringIntegrationAccessRightsCreateInput>>>
}


export type MutationCreateAcquiringIntegrationContextArgs = {
    data?: InputMaybe<AcquiringIntegrationContextCreateInput>
}


export type MutationCreateAcquiringIntegrationContextHistoryRecordArgs = {
    data?: InputMaybe<AcquiringIntegrationContextHistoryRecordCreateInput>
}


export type MutationCreateAcquiringIntegrationContextHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<AcquiringIntegrationContextHistoryRecordsCreateInput>>>
}


export type MutationCreateAcquiringIntegrationContextsArgs = {
    data?: InputMaybe<Array<InputMaybe<AcquiringIntegrationContextsCreateInput>>>
}


export type MutationCreateAcquiringIntegrationHistoryRecordArgs = {
    data?: InputMaybe<AcquiringIntegrationHistoryRecordCreateInput>
}


export type MutationCreateAcquiringIntegrationHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<AcquiringIntegrationHistoryRecordsCreateInput>>>
}


export type MutationCreateAcquiringIntegrationsArgs = {
    data?: InputMaybe<Array<InputMaybe<AcquiringIntegrationsCreateInput>>>
}


export type MutationCreateB2BAppArgs = {
    data?: InputMaybe<B2BAppCreateInput>
}


export type MutationCreateB2BAppAccessRightArgs = {
    data?: InputMaybe<B2BAppAccessRightCreateInput>
}


export type MutationCreateB2BAppAccessRightHistoryRecordArgs = {
    data?: InputMaybe<B2BAppAccessRightHistoryRecordCreateInput>
}


export type MutationCreateB2BAppAccessRightHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<B2BAppAccessRightHistoryRecordsCreateInput>>>
}


export type MutationCreateB2BAppAccessRightSetArgs = {
    data?: InputMaybe<B2BAppAccessRightSetCreateInput>
}


export type MutationCreateB2BAppAccessRightSetHistoryRecordArgs = {
    data?: InputMaybe<B2BAppAccessRightSetHistoryRecordCreateInput>
}


export type MutationCreateB2BAppAccessRightSetHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<B2BAppAccessRightSetHistoryRecordsCreateInput>>>
}


export type MutationCreateB2BAppAccessRightSetsArgs = {
    data?: InputMaybe<Array<InputMaybe<B2BAppAccessRightSetsCreateInput>>>
}


export type MutationCreateB2BAppAccessRightsArgs = {
    data?: InputMaybe<Array<InputMaybe<B2BAppAccessRightsCreateInput>>>
}


export type MutationCreateB2BAppContextArgs = {
    data?: InputMaybe<B2BAppContextCreateInput>
}


export type MutationCreateB2BAppContextHistoryRecordArgs = {
    data?: InputMaybe<B2BAppContextHistoryRecordCreateInput>
}


export type MutationCreateB2BAppContextHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<B2BAppContextHistoryRecordsCreateInput>>>
}


export type MutationCreateB2BAppContextsArgs = {
    data?: InputMaybe<Array<InputMaybe<B2BAppContextsCreateInput>>>
}


export type MutationCreateB2BAppHistoryRecordArgs = {
    data?: InputMaybe<B2BAppHistoryRecordCreateInput>
}


export type MutationCreateB2BAppHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<B2BAppHistoryRecordsCreateInput>>>
}


export type MutationCreateB2BAppNewsSharingConfigArgs = {
    data?: InputMaybe<B2BAppNewsSharingConfigCreateInput>
}


export type MutationCreateB2BAppNewsSharingConfigHistoryRecordArgs = {
    data?: InputMaybe<B2BAppNewsSharingConfigHistoryRecordCreateInput>
}


export type MutationCreateB2BAppNewsSharingConfigHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<B2BAppNewsSharingConfigHistoryRecordsCreateInput>>>
}


export type MutationCreateB2BAppNewsSharingConfigsArgs = {
    data?: InputMaybe<Array<InputMaybe<B2BAppNewsSharingConfigsCreateInput>>>
}


export type MutationCreateB2BAppPermissionArgs = {
    data?: InputMaybe<B2BAppPermissionCreateInput>
}


export type MutationCreateB2BAppPermissionHistoryRecordArgs = {
    data?: InputMaybe<B2BAppPermissionHistoryRecordCreateInput>
}


export type MutationCreateB2BAppPermissionHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<B2BAppPermissionHistoryRecordsCreateInput>>>
}


export type MutationCreateB2BAppPermissionsArgs = {
    data?: InputMaybe<Array<InputMaybe<B2BAppPermissionsCreateInput>>>
}


export type MutationCreateB2BAppPromoBlockArgs = {
    data?: InputMaybe<B2BAppPromoBlockCreateInput>
}


export type MutationCreateB2BAppPromoBlockHistoryRecordArgs = {
    data?: InputMaybe<B2BAppPromoBlockHistoryRecordCreateInput>
}


export type MutationCreateB2BAppPromoBlockHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<B2BAppPromoBlockHistoryRecordsCreateInput>>>
}


export type MutationCreateB2BAppPromoBlocksArgs = {
    data?: InputMaybe<Array<InputMaybe<B2BAppPromoBlocksCreateInput>>>
}


export type MutationCreateB2BAppRoleArgs = {
    data?: InputMaybe<B2BAppRoleCreateInput>
}


export type MutationCreateB2BAppRoleHistoryRecordArgs = {
    data?: InputMaybe<B2BAppRoleHistoryRecordCreateInput>
}


export type MutationCreateB2BAppRoleHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<B2BAppRoleHistoryRecordsCreateInput>>>
}


export type MutationCreateB2BAppRolesArgs = {
    data?: InputMaybe<Array<InputMaybe<B2BAppRolesCreateInput>>>
}


export type MutationCreateB2BAppsArgs = {
    data?: InputMaybe<Array<InputMaybe<B2BAppsCreateInput>>>
}


export type MutationCreateB2CAppArgs = {
    data?: InputMaybe<B2CAppCreateInput>
}


export type MutationCreateB2CAppAccessRightArgs = {
    data?: InputMaybe<B2CAppAccessRightCreateInput>
}


export type MutationCreateB2CAppAccessRightHistoryRecordArgs = {
    data?: InputMaybe<B2CAppAccessRightHistoryRecordCreateInput>
}


export type MutationCreateB2CAppAccessRightHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<B2CAppAccessRightHistoryRecordsCreateInput>>>
}


export type MutationCreateB2CAppAccessRightsArgs = {
    data?: InputMaybe<Array<InputMaybe<B2CAppAccessRightsCreateInput>>>
}


export type MutationCreateB2CAppBuildArgs = {
    data?: InputMaybe<B2CAppBuildCreateInput>
}


export type MutationCreateB2CAppBuildHistoryRecordArgs = {
    data?: InputMaybe<B2CAppBuildHistoryRecordCreateInput>
}


export type MutationCreateB2CAppBuildHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<B2CAppBuildHistoryRecordsCreateInput>>>
}


export type MutationCreateB2CAppBuildsArgs = {
    data?: InputMaybe<Array<InputMaybe<B2CAppBuildsCreateInput>>>
}


export type MutationCreateB2CAppHistoryRecordArgs = {
    data?: InputMaybe<B2CAppHistoryRecordCreateInput>
}


export type MutationCreateB2CAppHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<B2CAppHistoryRecordsCreateInput>>>
}


export type MutationCreateB2CAppMessageSettingArgs = {
    data?: InputMaybe<B2CAppMessageSettingCreateInput>
}


export type MutationCreateB2CAppMessageSettingHistoryRecordArgs = {
    data?: InputMaybe<B2CAppMessageSettingHistoryRecordCreateInput>
}


export type MutationCreateB2CAppMessageSettingHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<B2CAppMessageSettingHistoryRecordsCreateInput>>>
}


export type MutationCreateB2CAppMessageSettingsArgs = {
    data?: InputMaybe<Array<InputMaybe<B2CAppMessageSettingsCreateInput>>>
}


export type MutationCreateB2CAppPropertiesArgs = {
    data?: InputMaybe<Array<InputMaybe<B2CAppPropertiesCreateInput>>>
}


export type MutationCreateB2CAppPropertyArgs = {
    data?: InputMaybe<B2CAppPropertyCreateInput>
}


export type MutationCreateB2CAppPropertyHistoryRecordArgs = {
    data?: InputMaybe<B2CAppPropertyHistoryRecordCreateInput>
}


export type MutationCreateB2CAppPropertyHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<B2CAppPropertyHistoryRecordsCreateInput>>>
}


export type MutationCreateB2CAppsArgs = {
    data?: InputMaybe<Array<InputMaybe<B2CAppsCreateInput>>>
}


export type MutationCreateBankAccountArgs = {
    data?: InputMaybe<BankAccountCreateInput>
}


export type MutationCreateBankAccountHistoryRecordArgs = {
    data?: InputMaybe<BankAccountHistoryRecordCreateInput>
}


export type MutationCreateBankAccountHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<BankAccountHistoryRecordsCreateInput>>>
}


export type MutationCreateBankAccountReportArgs = {
    data?: InputMaybe<BankAccountReportCreateInput>
}


export type MutationCreateBankAccountReportHistoryRecordArgs = {
    data?: InputMaybe<BankAccountReportHistoryRecordCreateInput>
}


export type MutationCreateBankAccountReportHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<BankAccountReportHistoryRecordsCreateInput>>>
}


export type MutationCreateBankAccountReportTaskArgs = {
    data?: InputMaybe<BankAccountReportTaskCreateInput>
}


export type MutationCreateBankAccountReportTaskHistoryRecordArgs = {
    data?: InputMaybe<BankAccountReportTaskHistoryRecordCreateInput>
}


export type MutationCreateBankAccountReportTaskHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<BankAccountReportTaskHistoryRecordsCreateInput>>>
}


export type MutationCreateBankAccountReportTasksArgs = {
    data?: InputMaybe<Array<InputMaybe<BankAccountReportTasksCreateInput>>>
}


export type MutationCreateBankAccountReportsArgs = {
    data?: InputMaybe<Array<InputMaybe<BankAccountReportsCreateInput>>>
}


export type MutationCreateBankAccountRequestArgs = {
    data: CreateBankAccountRequestInput
}


export type MutationCreateBankAccountsArgs = {
    data?: InputMaybe<Array<InputMaybe<BankAccountsCreateInput>>>
}


export type MutationCreateBankCategoriesArgs = {
    data?: InputMaybe<Array<InputMaybe<BankCategoriesCreateInput>>>
}


export type MutationCreateBankCategoryArgs = {
    data?: InputMaybe<BankCategoryCreateInput>
}


export type MutationCreateBankCategoryHistoryRecordArgs = {
    data?: InputMaybe<BankCategoryHistoryRecordCreateInput>
}


export type MutationCreateBankCategoryHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<BankCategoryHistoryRecordsCreateInput>>>
}


export type MutationCreateBankContractorAccountArgs = {
    data?: InputMaybe<BankContractorAccountCreateInput>
}


export type MutationCreateBankContractorAccountHistoryRecordArgs = {
    data?: InputMaybe<BankContractorAccountHistoryRecordCreateInput>
}


export type MutationCreateBankContractorAccountHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<BankContractorAccountHistoryRecordsCreateInput>>>
}


export type MutationCreateBankContractorAccountsArgs = {
    data?: InputMaybe<Array<InputMaybe<BankContractorAccountsCreateInput>>>
}


export type MutationCreateBankCostItemArgs = {
    data?: InputMaybe<BankCostItemCreateInput>
}


export type MutationCreateBankCostItemHistoryRecordArgs = {
    data?: InputMaybe<BankCostItemHistoryRecordCreateInput>
}


export type MutationCreateBankCostItemHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<BankCostItemHistoryRecordsCreateInput>>>
}


export type MutationCreateBankCostItemsArgs = {
    data?: InputMaybe<Array<InputMaybe<BankCostItemsCreateInput>>>
}


export type MutationCreateBankIntegrationArgs = {
    data?: InputMaybe<BankIntegrationCreateInput>
}


export type MutationCreateBankIntegrationAccessRightArgs = {
    data?: InputMaybe<BankIntegrationAccessRightCreateInput>
}


export type MutationCreateBankIntegrationAccessRightHistoryRecordArgs = {
    data?: InputMaybe<BankIntegrationAccessRightHistoryRecordCreateInput>
}


export type MutationCreateBankIntegrationAccessRightHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<BankIntegrationAccessRightHistoryRecordsCreateInput>>>
}


export type MutationCreateBankIntegrationAccessRightsArgs = {
    data?: InputMaybe<Array<InputMaybe<BankIntegrationAccessRightsCreateInput>>>
}


export type MutationCreateBankIntegrationAccountContextArgs = {
    data?: InputMaybe<BankIntegrationAccountContextCreateInput>
}


export type MutationCreateBankIntegrationAccountContextHistoryRecordArgs = {
    data?: InputMaybe<BankIntegrationAccountContextHistoryRecordCreateInput>
}


export type MutationCreateBankIntegrationAccountContextHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<BankIntegrationAccountContextHistoryRecordsCreateInput>>>
}


export type MutationCreateBankIntegrationAccountContextsArgs = {
    data?: InputMaybe<Array<InputMaybe<BankIntegrationAccountContextsCreateInput>>>
}


export type MutationCreateBankIntegrationHistoryRecordArgs = {
    data?: InputMaybe<BankIntegrationHistoryRecordCreateInput>
}


export type MutationCreateBankIntegrationHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<BankIntegrationHistoryRecordsCreateInput>>>
}


export type MutationCreateBankIntegrationOrganizationContextArgs = {
    data?: InputMaybe<BankIntegrationOrganizationContextCreateInput>
}


export type MutationCreateBankIntegrationOrganizationContextHistoryRecordArgs = {
    data?: InputMaybe<BankIntegrationOrganizationContextHistoryRecordCreateInput>
}


export type MutationCreateBankIntegrationOrganizationContextHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<BankIntegrationOrganizationContextHistoryRecordsCreateInput>>>
}


export type MutationCreateBankIntegrationOrganizationContextsArgs = {
    data?: InputMaybe<Array<InputMaybe<BankIntegrationOrganizationContextsCreateInput>>>
}


export type MutationCreateBankIntegrationsArgs = {
    data?: InputMaybe<Array<InputMaybe<BankIntegrationsCreateInput>>>
}


export type MutationCreateBankSyncTaskArgs = {
    data?: InputMaybe<BankSyncTaskCreateInput>
}


export type MutationCreateBankSyncTaskHistoryRecordArgs = {
    data?: InputMaybe<BankSyncTaskHistoryRecordCreateInput>
}


export type MutationCreateBankSyncTaskHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<BankSyncTaskHistoryRecordsCreateInput>>>
}


export type MutationCreateBankSyncTasksArgs = {
    data?: InputMaybe<Array<InputMaybe<BankSyncTasksCreateInput>>>
}


export type MutationCreateBankTransactionArgs = {
    data?: InputMaybe<BankTransactionCreateInput>
}


export type MutationCreateBankTransactionHistoryRecordArgs = {
    data?: InputMaybe<BankTransactionHistoryRecordCreateInput>
}


export type MutationCreateBankTransactionHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<BankTransactionHistoryRecordsCreateInput>>>
}


export type MutationCreateBankTransactionsArgs = {
    data?: InputMaybe<Array<InputMaybe<BankTransactionsCreateInput>>>
}


export type MutationCreateBillingAccountArgs = {
    data?: InputMaybe<BillingAccountCreateInput>
}


export type MutationCreateBillingAccountHistoryRecordArgs = {
    data?: InputMaybe<BillingAccountHistoryRecordCreateInput>
}


export type MutationCreateBillingAccountHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<BillingAccountHistoryRecordsCreateInput>>>
}


export type MutationCreateBillingAccountsArgs = {
    data?: InputMaybe<Array<InputMaybe<BillingAccountsCreateInput>>>
}


export type MutationCreateBillingCategoriesArgs = {
    data?: InputMaybe<Array<InputMaybe<BillingCategoriesCreateInput>>>
}


export type MutationCreateBillingCategoryArgs = {
    data?: InputMaybe<BillingCategoryCreateInput>
}


export type MutationCreateBillingCategoryHistoryRecordArgs = {
    data?: InputMaybe<BillingCategoryHistoryRecordCreateInput>
}


export type MutationCreateBillingCategoryHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<BillingCategoryHistoryRecordsCreateInput>>>
}


export type MutationCreateBillingIntegrationArgs = {
    data?: InputMaybe<BillingIntegrationCreateInput>
}


export type MutationCreateBillingIntegrationAccessRightArgs = {
    data?: InputMaybe<BillingIntegrationAccessRightCreateInput>
}


export type MutationCreateBillingIntegrationAccessRightHistoryRecordArgs = {
    data?: InputMaybe<BillingIntegrationAccessRightHistoryRecordCreateInput>
}


export type MutationCreateBillingIntegrationAccessRightHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<BillingIntegrationAccessRightHistoryRecordsCreateInput>>>
}


export type MutationCreateBillingIntegrationAccessRightsArgs = {
    data?: InputMaybe<Array<InputMaybe<BillingIntegrationAccessRightsCreateInput>>>
}


export type MutationCreateBillingIntegrationHistoryRecordArgs = {
    data?: InputMaybe<BillingIntegrationHistoryRecordCreateInput>
}


export type MutationCreateBillingIntegrationHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<BillingIntegrationHistoryRecordsCreateInput>>>
}


export type MutationCreateBillingIntegrationOrganizationContextArgs = {
    data?: InputMaybe<BillingIntegrationOrganizationContextCreateInput>
}


export type MutationCreateBillingIntegrationOrganizationContextHistoryRecordArgs = {
    data?: InputMaybe<BillingIntegrationOrganizationContextHistoryRecordCreateInput>
}


export type MutationCreateBillingIntegrationOrganizationContextHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<BillingIntegrationOrganizationContextHistoryRecordsCreateInput>>>
}


export type MutationCreateBillingIntegrationOrganizationContextsArgs = {
    data?: InputMaybe<Array<InputMaybe<BillingIntegrationOrganizationContextsCreateInput>>>
}


export type MutationCreateBillingIntegrationProblemArgs = {
    data?: InputMaybe<BillingIntegrationProblemCreateInput>
}


export type MutationCreateBillingIntegrationProblemsArgs = {
    data?: InputMaybe<Array<InputMaybe<BillingIntegrationProblemsCreateInput>>>
}


export type MutationCreateBillingIntegrationsArgs = {
    data?: InputMaybe<Array<InputMaybe<BillingIntegrationsCreateInput>>>
}


export type MutationCreateBillingPropertiesArgs = {
    data?: InputMaybe<Array<InputMaybe<BillingPropertiesCreateInput>>>
}


export type MutationCreateBillingPropertyArgs = {
    data?: InputMaybe<BillingPropertyCreateInput>
}


export type MutationCreateBillingPropertyHistoryRecordArgs = {
    data?: InputMaybe<BillingPropertyHistoryRecordCreateInput>
}


export type MutationCreateBillingPropertyHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<BillingPropertyHistoryRecordsCreateInput>>>
}


export type MutationCreateBillingReceiptArgs = {
    data?: InputMaybe<BillingReceiptCreateInput>
}


export type MutationCreateBillingReceiptFileArgs = {
    data?: InputMaybe<BillingReceiptFileCreateInput>
}


export type MutationCreateBillingReceiptFileHistoryRecordArgs = {
    data?: InputMaybe<BillingReceiptFileHistoryRecordCreateInput>
}


export type MutationCreateBillingReceiptFileHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<BillingReceiptFileHistoryRecordsCreateInput>>>
}


export type MutationCreateBillingReceiptFilesArgs = {
    data?: InputMaybe<Array<InputMaybe<BillingReceiptFilesCreateInput>>>
}


export type MutationCreateBillingReceiptHistoryRecordArgs = {
    data?: InputMaybe<BillingReceiptHistoryRecordCreateInput>
}


export type MutationCreateBillingReceiptHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<BillingReceiptHistoryRecordsCreateInput>>>
}


export type MutationCreateBillingReceiptsArgs = {
    data?: InputMaybe<Array<InputMaybe<BillingReceiptsCreateInput>>>
}


export type MutationCreateBillingRecipientArgs = {
    data?: InputMaybe<BillingRecipientCreateInput>
}


export type MutationCreateBillingRecipientHistoryRecordArgs = {
    data?: InputMaybe<BillingRecipientHistoryRecordCreateInput>
}


export type MutationCreateBillingRecipientHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<BillingRecipientHistoryRecordsCreateInput>>>
}


export type MutationCreateBillingRecipientsArgs = {
    data?: InputMaybe<Array<InputMaybe<BillingRecipientsCreateInput>>>
}


export type MutationCreateCallRecordArgs = {
    data?: InputMaybe<CallRecordCreateInput>
}


export type MutationCreateCallRecordFragmentArgs = {
    data?: InputMaybe<CallRecordFragmentCreateInput>
}


export type MutationCreateCallRecordFragmentHistoryRecordArgs = {
    data?: InputMaybe<CallRecordFragmentHistoryRecordCreateInput>
}


export type MutationCreateCallRecordFragmentHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<CallRecordFragmentHistoryRecordsCreateInput>>>
}


export type MutationCreateCallRecordFragmentsArgs = {
    data?: InputMaybe<Array<InputMaybe<CallRecordFragmentsCreateInput>>>
}


export type MutationCreateCallRecordHistoryRecordArgs = {
    data?: InputMaybe<CallRecordHistoryRecordCreateInput>
}


export type MutationCreateCallRecordHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<CallRecordHistoryRecordsCreateInput>>>
}


export type MutationCreateCallRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<CallRecordsCreateInput>>>
}


export type MutationCreateConfirmPhoneActionArgs = {
    data?: InputMaybe<ConfirmPhoneActionCreateInput>
}


export type MutationCreateConfirmPhoneActionHistoryRecordArgs = {
    data?: InputMaybe<ConfirmPhoneActionHistoryRecordCreateInput>
}


export type MutationCreateConfirmPhoneActionHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<ConfirmPhoneActionHistoryRecordsCreateInput>>>
}


export type MutationCreateConfirmPhoneActionsArgs = {
    data?: InputMaybe<Array<InputMaybe<ConfirmPhoneActionsCreateInput>>>
}


export type MutationCreateContactArgs = {
    data?: InputMaybe<ContactCreateInput>
}


export type MutationCreateContactExportTaskArgs = {
    data?: InputMaybe<ContactExportTaskCreateInput>
}


export type MutationCreateContactExportTaskHistoryRecordArgs = {
    data?: InputMaybe<ContactExportTaskHistoryRecordCreateInput>
}


export type MutationCreateContactExportTaskHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<ContactExportTaskHistoryRecordsCreateInput>>>
}


export type MutationCreateContactExportTasksArgs = {
    data?: InputMaybe<Array<InputMaybe<ContactExportTasksCreateInput>>>
}


export type MutationCreateContactHistoryRecordArgs = {
    data?: InputMaybe<ContactHistoryRecordCreateInput>
}


export type MutationCreateContactHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<ContactHistoryRecordsCreateInput>>>
}


export type MutationCreateContactRoleArgs = {
    data?: InputMaybe<ContactRoleCreateInput>
}


export type MutationCreateContactRoleHistoryRecordArgs = {
    data?: InputMaybe<ContactRoleHistoryRecordCreateInput>
}


export type MutationCreateContactRoleHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<ContactRoleHistoryRecordsCreateInput>>>
}


export type MutationCreateContactRolesArgs = {
    data?: InputMaybe<Array<InputMaybe<ContactRolesCreateInput>>>
}


export type MutationCreateContactsArgs = {
    data?: InputMaybe<Array<InputMaybe<ContactsCreateInput>>>
}


export type MutationCreateDocumentArgs = {
    data?: InputMaybe<DocumentCreateInput>
}


export type MutationCreateDocumentCategoriesArgs = {
    data?: InputMaybe<Array<InputMaybe<DocumentCategoriesCreateInput>>>
}


export type MutationCreateDocumentCategoryArgs = {
    data?: InputMaybe<DocumentCategoryCreateInput>
}


export type MutationCreateDocumentCategoryHistoryRecordArgs = {
    data?: InputMaybe<DocumentCategoryHistoryRecordCreateInput>
}


export type MutationCreateDocumentCategoryHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<DocumentCategoryHistoryRecordsCreateInput>>>
}


export type MutationCreateDocumentHistoryRecordArgs = {
    data?: InputMaybe<DocumentHistoryRecordCreateInput>
}


export type MutationCreateDocumentHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<DocumentHistoryRecordsCreateInput>>>
}


export type MutationCreateDocumentsArgs = {
    data?: InputMaybe<Array<InputMaybe<DocumentsCreateInput>>>
}


export type MutationCreateExternalTokenAccessRightArgs = {
    data?: InputMaybe<ExternalTokenAccessRightCreateInput>
}


export type MutationCreateExternalTokenAccessRightHistoryRecordArgs = {
    data?: InputMaybe<ExternalTokenAccessRightHistoryRecordCreateInput>
}


export type MutationCreateExternalTokenAccessRightHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<ExternalTokenAccessRightHistoryRecordsCreateInput>>>
}


export type MutationCreateExternalTokenAccessRightsArgs = {
    data?: InputMaybe<Array<InputMaybe<ExternalTokenAccessRightsCreateInput>>>
}


export type MutationCreateIncidentArgs = {
    data?: InputMaybe<IncidentCreateInput>
}


export type MutationCreateIncidentChangeArgs = {
    data?: InputMaybe<IncidentChangeCreateInput>
}


export type MutationCreateIncidentChangesArgs = {
    data?: InputMaybe<Array<InputMaybe<IncidentChangesCreateInput>>>
}


export type MutationCreateIncidentClassifierArgs = {
    data?: InputMaybe<IncidentClassifierCreateInput>
}


export type MutationCreateIncidentClassifierHistoryRecordArgs = {
    data?: InputMaybe<IncidentClassifierHistoryRecordCreateInput>
}


export type MutationCreateIncidentClassifierHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<IncidentClassifierHistoryRecordsCreateInput>>>
}


export type MutationCreateIncidentClassifierIncidentArgs = {
    data?: InputMaybe<IncidentClassifierIncidentCreateInput>
}


export type MutationCreateIncidentClassifierIncidentHistoryRecordArgs = {
    data?: InputMaybe<IncidentClassifierIncidentHistoryRecordCreateInput>
}


export type MutationCreateIncidentClassifierIncidentHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<IncidentClassifierIncidentHistoryRecordsCreateInput>>>
}


export type MutationCreateIncidentClassifierIncidentsArgs = {
    data?: InputMaybe<Array<InputMaybe<IncidentClassifierIncidentsCreateInput>>>
}


export type MutationCreateIncidentClassifiersArgs = {
    data?: InputMaybe<Array<InputMaybe<IncidentClassifiersCreateInput>>>
}


export type MutationCreateIncidentExportTaskArgs = {
    data?: InputMaybe<IncidentExportTaskCreateInput>
}


export type MutationCreateIncidentExportTaskHistoryRecordArgs = {
    data?: InputMaybe<IncidentExportTaskHistoryRecordCreateInput>
}


export type MutationCreateIncidentExportTaskHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<IncidentExportTaskHistoryRecordsCreateInput>>>
}


export type MutationCreateIncidentExportTasksArgs = {
    data?: InputMaybe<Array<InputMaybe<IncidentExportTasksCreateInput>>>
}


export type MutationCreateIncidentHistoryRecordArgs = {
    data?: InputMaybe<IncidentHistoryRecordCreateInput>
}


export type MutationCreateIncidentHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<IncidentHistoryRecordsCreateInput>>>
}


export type MutationCreateIncidentPropertiesArgs = {
    data?: InputMaybe<Array<InputMaybe<IncidentPropertiesCreateInput>>>
}


export type MutationCreateIncidentPropertyArgs = {
    data?: InputMaybe<IncidentPropertyCreateInput>
}


export type MutationCreateIncidentPropertyHistoryRecordArgs = {
    data?: InputMaybe<IncidentPropertyHistoryRecordCreateInput>
}


export type MutationCreateIncidentPropertyHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<IncidentPropertyHistoryRecordsCreateInput>>>
}


export type MutationCreateIncidentsArgs = {
    data?: InputMaybe<Array<InputMaybe<IncidentsCreateInput>>>
}


export type MutationCreateInvoiceArgs = {
    data?: InputMaybe<InvoiceCreateInput>
}


export type MutationCreateInvoiceHistoryRecordArgs = {
    data?: InputMaybe<InvoiceHistoryRecordCreateInput>
}


export type MutationCreateInvoiceHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<InvoiceHistoryRecordsCreateInput>>>
}


export type MutationCreateInvoicesArgs = {
    data?: InputMaybe<Array<InputMaybe<InvoicesCreateInput>>>
}


export type MutationCreateMarketCategoriesArgs = {
    data?: InputMaybe<Array<InputMaybe<MarketCategoriesCreateInput>>>
}


export type MutationCreateMarketCategoryArgs = {
    data?: InputMaybe<MarketCategoryCreateInput>
}


export type MutationCreateMarketCategoryHistoryRecordArgs = {
    data?: InputMaybe<MarketCategoryHistoryRecordCreateInput>
}


export type MutationCreateMarketCategoryHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<MarketCategoryHistoryRecordsCreateInput>>>
}


export type MutationCreateMarketItemArgs = {
    data?: InputMaybe<MarketItemCreateInput>
}


export type MutationCreateMarketItemFileArgs = {
    data?: InputMaybe<MarketItemFileCreateInput>
}


export type MutationCreateMarketItemFileHistoryRecordArgs = {
    data?: InputMaybe<MarketItemFileHistoryRecordCreateInput>
}


export type MutationCreateMarketItemFileHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<MarketItemFileHistoryRecordsCreateInput>>>
}


export type MutationCreateMarketItemFilesArgs = {
    data?: InputMaybe<Array<InputMaybe<MarketItemFilesCreateInput>>>
}


export type MutationCreateMarketItemHistoryRecordArgs = {
    data?: InputMaybe<MarketItemHistoryRecordCreateInput>
}


export type MutationCreateMarketItemHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<MarketItemHistoryRecordsCreateInput>>>
}


export type MutationCreateMarketItemPriceArgs = {
    data?: InputMaybe<MarketItemPriceCreateInput>
}


export type MutationCreateMarketItemPriceHistoryRecordArgs = {
    data?: InputMaybe<MarketItemPriceHistoryRecordCreateInput>
}


export type MutationCreateMarketItemPriceHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<MarketItemPriceHistoryRecordsCreateInput>>>
}


export type MutationCreateMarketItemPricesArgs = {
    data?: InputMaybe<Array<InputMaybe<MarketItemPricesCreateInput>>>
}


export type MutationCreateMarketItemsArgs = {
    data?: InputMaybe<Array<InputMaybe<MarketItemsCreateInput>>>
}


export type MutationCreateMarketPriceScopeArgs = {
    data?: InputMaybe<MarketPriceScopeCreateInput>
}


export type MutationCreateMarketPriceScopeHistoryRecordArgs = {
    data?: InputMaybe<MarketPriceScopeHistoryRecordCreateInput>
}


export type MutationCreateMarketPriceScopeHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<MarketPriceScopeHistoryRecordsCreateInput>>>
}


export type MutationCreateMarketPriceScopesArgs = {
    data?: InputMaybe<Array<InputMaybe<MarketPriceScopesCreateInput>>>
}


export type MutationCreateMarketSettingArgs = {
    data?: InputMaybe<MarketSettingCreateInput>
}


export type MutationCreateMarketSettingHistoryRecordArgs = {
    data?: InputMaybe<MarketSettingHistoryRecordCreateInput>
}


export type MutationCreateMarketSettingHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<MarketSettingHistoryRecordsCreateInput>>>
}


export type MutationCreateMarketSettingsArgs = {
    data?: InputMaybe<Array<InputMaybe<MarketSettingsCreateInput>>>
}


export type MutationCreateMessageArgs = {
    data?: InputMaybe<MessageCreateInput>
}


export type MutationCreateMessageAppBlackListArgs = {
    data?: InputMaybe<MessageAppBlackListCreateInput>
}


export type MutationCreateMessageAppBlackListHistoryRecordArgs = {
    data?: InputMaybe<MessageAppBlackListHistoryRecordCreateInput>
}


export type MutationCreateMessageAppBlackListHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<MessageAppBlackListHistoryRecordsCreateInput>>>
}


export type MutationCreateMessageAppBlackListsArgs = {
    data?: InputMaybe<Array<InputMaybe<MessageAppBlackListsCreateInput>>>
}


export type MutationCreateMessageBatchArgs = {
    data?: InputMaybe<MessageBatchCreateInput>
}


export type MutationCreateMessageBatchHistoryRecordArgs = {
    data?: InputMaybe<MessageBatchHistoryRecordCreateInput>
}


export type MutationCreateMessageBatchHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<MessageBatchHistoryRecordsCreateInput>>>
}


export type MutationCreateMessageBatchesArgs = {
    data?: InputMaybe<Array<InputMaybe<MessageBatchesCreateInput>>>
}


export type MutationCreateMessageHistoryRecordArgs = {
    data?: InputMaybe<MessageHistoryRecordCreateInput>
}


export type MutationCreateMessageHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<MessageHistoryRecordsCreateInput>>>
}


export type MutationCreateMessageOrganizationBlackListArgs = {
    data?: InputMaybe<MessageOrganizationBlackListCreateInput>
}


export type MutationCreateMessageOrganizationBlackListHistoryRecordArgs = {
    data?: InputMaybe<MessageOrganizationBlackListHistoryRecordCreateInput>
}


export type MutationCreateMessageOrganizationBlackListHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<MessageOrganizationBlackListHistoryRecordsCreateInput>>>
}


export type MutationCreateMessageOrganizationBlackListsArgs = {
    data?: InputMaybe<Array<InputMaybe<MessageOrganizationBlackListsCreateInput>>>
}


export type MutationCreateMessageUserBlackListArgs = {
    data?: InputMaybe<MessageUserBlackListCreateInput>
}


export type MutationCreateMessageUserBlackListHistoryRecordArgs = {
    data?: InputMaybe<MessageUserBlackListHistoryRecordCreateInput>
}


export type MutationCreateMessageUserBlackListHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<MessageUserBlackListHistoryRecordsCreateInput>>>
}


export type MutationCreateMessageUserBlackListsArgs = {
    data?: InputMaybe<Array<InputMaybe<MessageUserBlackListsCreateInput>>>
}


export type MutationCreateMessagesArgs = {
    data?: InputMaybe<Array<InputMaybe<MessagesCreateInput>>>
}


export type MutationCreateMeterArgs = {
    data?: InputMaybe<MeterCreateInput>
}


export type MutationCreateMeterHistoryRecordArgs = {
    data?: InputMaybe<MeterHistoryRecordCreateInput>
}


export type MutationCreateMeterHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<MeterHistoryRecordsCreateInput>>>
}


export type MutationCreateMeterReadingArgs = {
    data?: InputMaybe<MeterReadingCreateInput>
}


export type MutationCreateMeterReadingExportTaskArgs = {
    data?: InputMaybe<MeterReadingExportTaskCreateInput>
}


export type MutationCreateMeterReadingExportTaskHistoryRecordArgs = {
    data?: InputMaybe<MeterReadingExportTaskHistoryRecordCreateInput>
}


export type MutationCreateMeterReadingExportTaskHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<MeterReadingExportTaskHistoryRecordsCreateInput>>>
}


export type MutationCreateMeterReadingExportTasksArgs = {
    data?: InputMaybe<Array<InputMaybe<MeterReadingExportTasksCreateInput>>>
}


export type MutationCreateMeterReadingFilterTemplateArgs = {
    data?: InputMaybe<MeterReadingFilterTemplateCreateInput>
}


export type MutationCreateMeterReadingFilterTemplateHistoryRecordArgs = {
    data?: InputMaybe<MeterReadingFilterTemplateHistoryRecordCreateInput>
}


export type MutationCreateMeterReadingFilterTemplateHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<MeterReadingFilterTemplateHistoryRecordsCreateInput>>>
}


export type MutationCreateMeterReadingFilterTemplatesArgs = {
    data?: InputMaybe<Array<InputMaybe<MeterReadingFilterTemplatesCreateInput>>>
}


export type MutationCreateMeterReadingHistoryRecordArgs = {
    data?: InputMaybe<MeterReadingHistoryRecordCreateInput>
}


export type MutationCreateMeterReadingHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<MeterReadingHistoryRecordsCreateInput>>>
}


export type MutationCreateMeterReadingSourceArgs = {
    data?: InputMaybe<MeterReadingSourceCreateInput>
}


export type MutationCreateMeterReadingSourceHistoryRecordArgs = {
    data?: InputMaybe<MeterReadingSourceHistoryRecordCreateInput>
}


export type MutationCreateMeterReadingSourceHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<MeterReadingSourceHistoryRecordsCreateInput>>>
}


export type MutationCreateMeterReadingSourcesArgs = {
    data?: InputMaybe<Array<InputMaybe<MeterReadingSourcesCreateInput>>>
}


export type MutationCreateMeterReadingsArgs = {
    data?: InputMaybe<Array<InputMaybe<MeterReadingsCreateInput>>>
}


export type MutationCreateMeterReadingsImportTaskArgs = {
    data?: InputMaybe<MeterReadingsImportTaskCreateInput>
}


export type MutationCreateMeterReadingsImportTaskHistoryRecordArgs = {
    data?: InputMaybe<MeterReadingsImportTaskHistoryRecordCreateInput>
}


export type MutationCreateMeterReadingsImportTaskHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<MeterReadingsImportTaskHistoryRecordsCreateInput>>>
}


export type MutationCreateMeterReadingsImportTasksArgs = {
    data?: InputMaybe<Array<InputMaybe<MeterReadingsImportTasksCreateInput>>>
}


export type MutationCreateMeterReportingPeriodArgs = {
    data?: InputMaybe<MeterReportingPeriodCreateInput>
}


export type MutationCreateMeterReportingPeriodHistoryRecordArgs = {
    data?: InputMaybe<MeterReportingPeriodHistoryRecordCreateInput>
}


export type MutationCreateMeterReportingPeriodHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<MeterReportingPeriodHistoryRecordsCreateInput>>>
}


export type MutationCreateMeterReportingPeriodsArgs = {
    data?: InputMaybe<Array<InputMaybe<MeterReportingPeriodsCreateInput>>>
}


export type MutationCreateMeterResourceArgs = {
    data?: InputMaybe<MeterResourceCreateInput>
}


export type MutationCreateMeterResourceHistoryRecordArgs = {
    data?: InputMaybe<MeterResourceHistoryRecordCreateInput>
}


export type MutationCreateMeterResourceHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<MeterResourceHistoryRecordsCreateInput>>>
}


export type MutationCreateMeterResourceOwnerArgs = {
    data?: InputMaybe<MeterResourceOwnerCreateInput>
}


export type MutationCreateMeterResourceOwnerHistoryRecordArgs = {
    data?: InputMaybe<MeterResourceOwnerHistoryRecordCreateInput>
}


export type MutationCreateMeterResourceOwnerHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<MeterResourceOwnerHistoryRecordsCreateInput>>>
}


export type MutationCreateMeterResourceOwnersArgs = {
    data?: InputMaybe<Array<InputMaybe<MeterResourceOwnersCreateInput>>>
}


export type MutationCreateMeterResourcesArgs = {
    data?: InputMaybe<Array<InputMaybe<MeterResourcesCreateInput>>>
}


export type MutationCreateMetersArgs = {
    data?: InputMaybe<Array<InputMaybe<MetersCreateInput>>>
}


export type MutationCreateMobileFeatureConfigArgs = {
    data?: InputMaybe<MobileFeatureConfigCreateInput>
}


export type MutationCreateMobileFeatureConfigHistoryRecordArgs = {
    data?: InputMaybe<MobileFeatureConfigHistoryRecordCreateInput>
}


export type MutationCreateMobileFeatureConfigHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<MobileFeatureConfigHistoryRecordsCreateInput>>>
}


export type MutationCreateMobileFeatureConfigsArgs = {
    data?: InputMaybe<Array<InputMaybe<MobileFeatureConfigsCreateInput>>>
}


export type MutationCreateMultiPaymentArgs = {
    data?: InputMaybe<MultiPaymentCreateInput>
}


export type MutationCreateMultiPaymentHistoryRecordArgs = {
    data?: InputMaybe<MultiPaymentHistoryRecordCreateInput>
}


export type MutationCreateMultiPaymentHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<MultiPaymentHistoryRecordsCreateInput>>>
}


export type MutationCreateMultiPaymentsArgs = {
    data?: InputMaybe<Array<InputMaybe<MultiPaymentsCreateInput>>>
}


export type MutationCreateNewsItemArgs = {
    data?: InputMaybe<NewsItemCreateInput>
}


export type MutationCreateNewsItemHistoryRecordArgs = {
    data?: InputMaybe<NewsItemHistoryRecordCreateInput>
}


export type MutationCreateNewsItemHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<NewsItemHistoryRecordsCreateInput>>>
}


export type MutationCreateNewsItemRecipientsExportTaskArgs = {
    data?: InputMaybe<NewsItemRecipientsExportTaskCreateInput>
}


export type MutationCreateNewsItemRecipientsExportTaskHistoryRecordArgs = {
    data?: InputMaybe<NewsItemRecipientsExportTaskHistoryRecordCreateInput>
}


export type MutationCreateNewsItemRecipientsExportTaskHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<NewsItemRecipientsExportTaskHistoryRecordsCreateInput>>>
}


export type MutationCreateNewsItemRecipientsExportTasksArgs = {
    data?: InputMaybe<Array<InputMaybe<NewsItemRecipientsExportTasksCreateInput>>>
}


export type MutationCreateNewsItemScopeArgs = {
    data?: InputMaybe<NewsItemScopeCreateInput>
}


export type MutationCreateNewsItemScopeHistoryRecordArgs = {
    data?: InputMaybe<NewsItemScopeHistoryRecordCreateInput>
}


export type MutationCreateNewsItemScopeHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<NewsItemScopeHistoryRecordsCreateInput>>>
}


export type MutationCreateNewsItemScopesArgs = {
    data?: InputMaybe<Array<InputMaybe<NewsItemScopesCreateInput>>>
}


export type MutationCreateNewsItemSharingArgs = {
    data?: InputMaybe<NewsItemSharingCreateInput>
}


export type MutationCreateNewsItemSharingHistoryRecordArgs = {
    data?: InputMaybe<NewsItemSharingHistoryRecordCreateInput>
}


export type MutationCreateNewsItemSharingHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<NewsItemSharingHistoryRecordsCreateInput>>>
}


export type MutationCreateNewsItemSharingsArgs = {
    data?: InputMaybe<Array<InputMaybe<NewsItemSharingsCreateInput>>>
}


export type MutationCreateNewsItemTemplateArgs = {
    data?: InputMaybe<NewsItemTemplateCreateInput>
}


export type MutationCreateNewsItemTemplateHistoryRecordArgs = {
    data?: InputMaybe<NewsItemTemplateHistoryRecordCreateInput>
}


export type MutationCreateNewsItemTemplateHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<NewsItemTemplateHistoryRecordsCreateInput>>>
}


export type MutationCreateNewsItemTemplatesArgs = {
    data?: InputMaybe<Array<InputMaybe<NewsItemTemplatesCreateInput>>>
}


export type MutationCreateNewsItemUserReadArgs = {
    data?: InputMaybe<NewsItemUserReadCreateInput>
}


export type MutationCreateNewsItemUserReadHistoryRecordArgs = {
    data?: InputMaybe<NewsItemUserReadHistoryRecordCreateInput>
}


export type MutationCreateNewsItemUserReadHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<NewsItemUserReadHistoryRecordsCreateInput>>>
}


export type MutationCreateNewsItemUserReadsArgs = {
    data?: InputMaybe<Array<InputMaybe<NewsItemUserReadsCreateInput>>>
}


export type MutationCreateNewsItemsArgs = {
    data?: InputMaybe<Array<InputMaybe<NewsItemsCreateInput>>>
}


export type MutationCreateNotificationAnonymousSettingArgs = {
    data?: InputMaybe<NotificationAnonymousSettingCreateInput>
}


export type MutationCreateNotificationAnonymousSettingHistoryRecordArgs = {
    data?: InputMaybe<NotificationAnonymousSettingHistoryRecordCreateInput>
}


export type MutationCreateNotificationAnonymousSettingHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<NotificationAnonymousSettingHistoryRecordsCreateInput>>>
}


export type MutationCreateNotificationAnonymousSettingsArgs = {
    data?: InputMaybe<Array<InputMaybe<NotificationAnonymousSettingsCreateInput>>>
}


export type MutationCreateNotificationUserSettingArgs = {
    data?: InputMaybe<NotificationUserSettingCreateInput>
}


export type MutationCreateNotificationUserSettingHistoryRecordArgs = {
    data?: InputMaybe<NotificationUserSettingHistoryRecordCreateInput>
}


export type MutationCreateNotificationUserSettingHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<NotificationUserSettingHistoryRecordsCreateInput>>>
}


export type MutationCreateNotificationUserSettingsArgs = {
    data?: InputMaybe<Array<InputMaybe<NotificationUserSettingsCreateInput>>>
}


export type MutationCreateOidcClientArgs = {
    data?: InputMaybe<OidcClientCreateInput>
}


export type MutationCreateOidcClientHistoryRecordArgs = {
    data?: InputMaybe<OidcClientHistoryRecordCreateInput>
}


export type MutationCreateOidcClientHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<OidcClientHistoryRecordsCreateInput>>>
}


export type MutationCreateOidcClientsArgs = {
    data?: InputMaybe<Array<InputMaybe<OidcClientsCreateInput>>>
}


export type MutationCreateOrganizationArgs = {
    data?: InputMaybe<OrganizationCreateInput>
}


export type MutationCreateOrganizationEmployeeArgs = {
    data?: InputMaybe<OrganizationEmployeeCreateInput>
}


export type MutationCreateOrganizationEmployeeHistoryRecordArgs = {
    data?: InputMaybe<OrganizationEmployeeHistoryRecordCreateInput>
}


export type MutationCreateOrganizationEmployeeHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<OrganizationEmployeeHistoryRecordsCreateInput>>>
}


export type MutationCreateOrganizationEmployeeRoleArgs = {
    data?: InputMaybe<OrganizationEmployeeRoleCreateInput>
}


export type MutationCreateOrganizationEmployeeRoleHistoryRecordArgs = {
    data?: InputMaybe<OrganizationEmployeeRoleHistoryRecordCreateInput>
}


export type MutationCreateOrganizationEmployeeRoleHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<OrganizationEmployeeRoleHistoryRecordsCreateInput>>>
}


export type MutationCreateOrganizationEmployeeRolesArgs = {
    data?: InputMaybe<Array<InputMaybe<OrganizationEmployeeRolesCreateInput>>>
}


export type MutationCreateOrganizationEmployeeSpecializationArgs = {
    data?: InputMaybe<OrganizationEmployeeSpecializationCreateInput>
}


export type MutationCreateOrganizationEmployeeSpecializationHistoryRecordArgs = {
    data?: InputMaybe<OrganizationEmployeeSpecializationHistoryRecordCreateInput>
}


export type MutationCreateOrganizationEmployeeSpecializationHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<OrganizationEmployeeSpecializationHistoryRecordsCreateInput>>>
}


export type MutationCreateOrganizationEmployeeSpecializationsArgs = {
    data?: InputMaybe<Array<InputMaybe<OrganizationEmployeeSpecializationsCreateInput>>>
}


export type MutationCreateOrganizationEmployeesArgs = {
    data?: InputMaybe<Array<InputMaybe<OrganizationEmployeesCreateInput>>>
}


export type MutationCreateOrganizationHistoryRecordArgs = {
    data?: InputMaybe<OrganizationHistoryRecordCreateInput>
}


export type MutationCreateOrganizationHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<OrganizationHistoryRecordsCreateInput>>>
}


export type MutationCreateOrganizationLinkArgs = {
    data?: InputMaybe<OrganizationLinkCreateInput>
}


export type MutationCreateOrganizationLinkHistoryRecordArgs = {
    data?: InputMaybe<OrganizationLinkHistoryRecordCreateInput>
}


export type MutationCreateOrganizationLinkHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<OrganizationLinkHistoryRecordsCreateInput>>>
}


export type MutationCreateOrganizationLinksArgs = {
    data?: InputMaybe<Array<InputMaybe<OrganizationLinksCreateInput>>>
}


export type MutationCreateOrganizationsArgs = {
    data?: InputMaybe<Array<InputMaybe<OrganizationsCreateInput>>>
}


export type MutationCreatePaymentArgs = {
    data?: InputMaybe<PaymentCreateInput>
}


export type MutationCreatePaymentByLinkArgs = {
    data: CreatePaymentByLinkInput
}


export type MutationCreatePaymentHistoryRecordArgs = {
    data?: InputMaybe<PaymentHistoryRecordCreateInput>
}


export type MutationCreatePaymentHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<PaymentHistoryRecordsCreateInput>>>
}


export type MutationCreatePaymentsArgs = {
    data?: InputMaybe<Array<InputMaybe<PaymentsCreateInput>>>
}


export type MutationCreatePaymentsFilterTemplateArgs = {
    data?: InputMaybe<PaymentsFilterTemplateCreateInput>
}


export type MutationCreatePaymentsFilterTemplateHistoryRecordArgs = {
    data?: InputMaybe<PaymentsFilterTemplateHistoryRecordCreateInput>
}


export type MutationCreatePaymentsFilterTemplateHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<PaymentsFilterTemplateHistoryRecordsCreateInput>>>
}


export type MutationCreatePaymentsFilterTemplatesArgs = {
    data?: InputMaybe<Array<InputMaybe<PaymentsFilterTemplatesCreateInput>>>
}


export type MutationCreatePropertiesArgs = {
    data?: InputMaybe<Array<InputMaybe<PropertiesCreateInput>>>
}


export type MutationCreatePropertyArgs = {
    data?: InputMaybe<PropertyCreateInput>
}


export type MutationCreatePropertyHistoryRecordArgs = {
    data?: InputMaybe<PropertyHistoryRecordCreateInput>
}


export type MutationCreatePropertyHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<PropertyHistoryRecordsCreateInput>>>
}


export type MutationCreatePropertyMeterArgs = {
    data?: InputMaybe<PropertyMeterCreateInput>
}


export type MutationCreatePropertyMeterHistoryRecordArgs = {
    data?: InputMaybe<PropertyMeterHistoryRecordCreateInput>
}


export type MutationCreatePropertyMeterHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<PropertyMeterHistoryRecordsCreateInput>>>
}


export type MutationCreatePropertyMeterReadingArgs = {
    data?: InputMaybe<PropertyMeterReadingCreateInput>
}


export type MutationCreatePropertyMeterReadingHistoryRecordArgs = {
    data?: InputMaybe<PropertyMeterReadingHistoryRecordCreateInput>
}


export type MutationCreatePropertyMeterReadingHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<PropertyMeterReadingHistoryRecordsCreateInput>>>
}


export type MutationCreatePropertyMeterReadingsArgs = {
    data?: InputMaybe<Array<InputMaybe<PropertyMeterReadingsCreateInput>>>
}


export type MutationCreatePropertyMetersArgs = {
    data?: InputMaybe<Array<InputMaybe<PropertyMetersCreateInput>>>
}


export type MutationCreatePropertyScopeArgs = {
    data?: InputMaybe<PropertyScopeCreateInput>
}


export type MutationCreatePropertyScopeHistoryRecordArgs = {
    data?: InputMaybe<PropertyScopeHistoryRecordCreateInput>
}


export type MutationCreatePropertyScopeHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<PropertyScopeHistoryRecordsCreateInput>>>
}


export type MutationCreatePropertyScopeOrganizationEmployeeArgs = {
    data?: InputMaybe<PropertyScopeOrganizationEmployeeCreateInput>
}


export type MutationCreatePropertyScopeOrganizationEmployeeHistoryRecordArgs = {
    data?: InputMaybe<PropertyScopeOrganizationEmployeeHistoryRecordCreateInput>
}


export type MutationCreatePropertyScopeOrganizationEmployeeHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<PropertyScopeOrganizationEmployeeHistoryRecordsCreateInput>>>
}


export type MutationCreatePropertyScopeOrganizationEmployeesArgs = {
    data?: InputMaybe<Array<InputMaybe<PropertyScopeOrganizationEmployeesCreateInput>>>
}


export type MutationCreatePropertyScopePropertiesArgs = {
    data?: InputMaybe<Array<InputMaybe<PropertyScopePropertiesCreateInput>>>
}


export type MutationCreatePropertyScopePropertyArgs = {
    data?: InputMaybe<PropertyScopePropertyCreateInput>
}


export type MutationCreatePropertyScopePropertyHistoryRecordArgs = {
    data?: InputMaybe<PropertyScopePropertyHistoryRecordCreateInput>
}


export type MutationCreatePropertyScopePropertyHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<PropertyScopePropertyHistoryRecordsCreateInput>>>
}


export type MutationCreatePropertyScopesArgs = {
    data?: InputMaybe<Array<InputMaybe<PropertyScopesCreateInput>>>
}


export type MutationCreateRecurrentPaymentArgs = {
    data?: InputMaybe<RecurrentPaymentCreateInput>
}


export type MutationCreateRecurrentPaymentContextArgs = {
    data?: InputMaybe<RecurrentPaymentContextCreateInput>
}


export type MutationCreateRecurrentPaymentContextHistoryRecordArgs = {
    data?: InputMaybe<RecurrentPaymentContextHistoryRecordCreateInput>
}


export type MutationCreateRecurrentPaymentContextHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<RecurrentPaymentContextHistoryRecordsCreateInput>>>
}


export type MutationCreateRecurrentPaymentContextsArgs = {
    data?: InputMaybe<Array<InputMaybe<RecurrentPaymentContextsCreateInput>>>
}


export type MutationCreateRecurrentPaymentHistoryRecordArgs = {
    data?: InputMaybe<RecurrentPaymentHistoryRecordCreateInput>
}


export type MutationCreateRecurrentPaymentHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<RecurrentPaymentHistoryRecordsCreateInput>>>
}


export type MutationCreateRecurrentPaymentsArgs = {
    data?: InputMaybe<Array<InputMaybe<RecurrentPaymentsCreateInput>>>
}


export type MutationCreateRemoteClientArgs = {
    data?: InputMaybe<RemoteClientCreateInput>
}


export type MutationCreateRemoteClientHistoryRecordArgs = {
    data?: InputMaybe<RemoteClientHistoryRecordCreateInput>
}


export type MutationCreateRemoteClientHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<RemoteClientHistoryRecordsCreateInput>>>
}


export type MutationCreateRemoteClientsArgs = {
    data?: InputMaybe<Array<InputMaybe<RemoteClientsCreateInput>>>
}


export type MutationCreateResidentArgs = {
    data?: InputMaybe<ResidentCreateInput>
}


export type MutationCreateResidentHistoryRecordArgs = {
    data?: InputMaybe<ResidentHistoryRecordCreateInput>
}


export type MutationCreateResidentHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<ResidentHistoryRecordsCreateInput>>>
}


export type MutationCreateResidentsArgs = {
    data?: InputMaybe<Array<InputMaybe<ResidentsCreateInput>>>
}


export type MutationCreateServiceConsumerArgs = {
    data?: InputMaybe<ServiceConsumerCreateInput>
}


export type MutationCreateServiceConsumerHistoryRecordArgs = {
    data?: InputMaybe<ServiceConsumerHistoryRecordCreateInput>
}


export type MutationCreateServiceConsumerHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<ServiceConsumerHistoryRecordsCreateInput>>>
}


export type MutationCreateServiceConsumersArgs = {
    data?: InputMaybe<Array<InputMaybe<ServiceConsumersCreateInput>>>
}


export type MutationCreateServiceSubscriptionArgs = {
    data?: InputMaybe<ServiceSubscriptionCreateInput>
}


export type MutationCreateServiceSubscriptionHistoryRecordArgs = {
    data?: InputMaybe<ServiceSubscriptionHistoryRecordCreateInput>
}


export type MutationCreateServiceSubscriptionHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<ServiceSubscriptionHistoryRecordsCreateInput>>>
}


export type MutationCreateServiceSubscriptionsArgs = {
    data?: InputMaybe<Array<InputMaybe<ServiceSubscriptionsCreateInput>>>
}


export type MutationCreateTelegramUserChatArgs = {
    data?: InputMaybe<TelegramUserChatCreateInput>
}


export type MutationCreateTelegramUserChatHistoryRecordArgs = {
    data?: InputMaybe<TelegramUserChatHistoryRecordCreateInput>
}


export type MutationCreateTelegramUserChatHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<TelegramUserChatHistoryRecordsCreateInput>>>
}


export type MutationCreateTelegramUserChatsArgs = {
    data?: InputMaybe<Array<InputMaybe<TelegramUserChatsCreateInput>>>
}


export type MutationCreateTicketArgs = {
    data?: InputMaybe<TicketCreateInput>
}


export type MutationCreateTicketAutoAssignmentArgs = {
    data?: InputMaybe<TicketAutoAssignmentCreateInput>
}


export type MutationCreateTicketAutoAssignmentHistoryRecordArgs = {
    data?: InputMaybe<TicketAutoAssignmentHistoryRecordCreateInput>
}


export type MutationCreateTicketAutoAssignmentHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketAutoAssignmentHistoryRecordsCreateInput>>>
}


export type MutationCreateTicketAutoAssignmentsArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketAutoAssignmentsCreateInput>>>
}


export type MutationCreateTicketCategoryClassifierArgs = {
    data?: InputMaybe<TicketCategoryClassifierCreateInput>
}


export type MutationCreateTicketCategoryClassifierHistoryRecordArgs = {
    data?: InputMaybe<TicketCategoryClassifierHistoryRecordCreateInput>
}


export type MutationCreateTicketCategoryClassifierHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketCategoryClassifierHistoryRecordsCreateInput>>>
}


export type MutationCreateTicketCategoryClassifiersArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketCategoryClassifiersCreateInput>>>
}


export type MutationCreateTicketChangeArgs = {
    data?: InputMaybe<TicketChangeCreateInput>
}


export type MutationCreateTicketChangesArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketChangesCreateInput>>>
}


export type MutationCreateTicketClassifierArgs = {
    data?: InputMaybe<TicketClassifierCreateInput>
}


export type MutationCreateTicketClassifierHistoryRecordArgs = {
    data?: InputMaybe<TicketClassifierHistoryRecordCreateInput>
}


export type MutationCreateTicketClassifierHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketClassifierHistoryRecordsCreateInput>>>
}


export type MutationCreateTicketClassifiersArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketClassifiersCreateInput>>>
}


export type MutationCreateTicketCommentArgs = {
    data?: InputMaybe<TicketCommentCreateInput>
}


export type MutationCreateTicketCommentFileArgs = {
    data?: InputMaybe<TicketCommentFileCreateInput>
}


export type MutationCreateTicketCommentFileHistoryRecordArgs = {
    data?: InputMaybe<TicketCommentFileHistoryRecordCreateInput>
}


export type MutationCreateTicketCommentFileHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketCommentFileHistoryRecordsCreateInput>>>
}


export type MutationCreateTicketCommentFilesArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketCommentFilesCreateInput>>>
}


export type MutationCreateTicketCommentHistoryRecordArgs = {
    data?: InputMaybe<TicketCommentHistoryRecordCreateInput>
}


export type MutationCreateTicketCommentHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketCommentHistoryRecordsCreateInput>>>
}


export type MutationCreateTicketCommentsArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketCommentsCreateInput>>>
}


export type MutationCreateTicketDocumentGenerationTaskArgs = {
    data?: InputMaybe<TicketDocumentGenerationTaskCreateInput>
}


export type MutationCreateTicketDocumentGenerationTaskHistoryRecordArgs = {
    data?: InputMaybe<TicketDocumentGenerationTaskHistoryRecordCreateInput>
}


export type MutationCreateTicketDocumentGenerationTaskHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketDocumentGenerationTaskHistoryRecordsCreateInput>>>
}


export type MutationCreateTicketDocumentGenerationTasksArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketDocumentGenerationTasksCreateInput>>>
}


export type MutationCreateTicketExportTaskArgs = {
    data?: InputMaybe<TicketExportTaskCreateInput>
}


export type MutationCreateTicketExportTaskHistoryRecordArgs = {
    data?: InputMaybe<TicketExportTaskHistoryRecordCreateInput>
}


export type MutationCreateTicketExportTaskHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketExportTaskHistoryRecordsCreateInput>>>
}


export type MutationCreateTicketExportTasksArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketExportTasksCreateInput>>>
}


export type MutationCreateTicketFileArgs = {
    data?: InputMaybe<TicketFileCreateInput>
}


export type MutationCreateTicketFileHistoryRecordArgs = {
    data?: InputMaybe<TicketFileHistoryRecordCreateInput>
}


export type MutationCreateTicketFileHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketFileHistoryRecordsCreateInput>>>
}


export type MutationCreateTicketFilesArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketFilesCreateInput>>>
}


export type MutationCreateTicketFilterTemplateArgs = {
    data?: InputMaybe<TicketFilterTemplateCreateInput>
}


export type MutationCreateTicketFilterTemplateHistoryRecordArgs = {
    data?: InputMaybe<TicketFilterTemplateHistoryRecordCreateInput>
}


export type MutationCreateTicketFilterTemplateHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketFilterTemplateHistoryRecordsCreateInput>>>
}


export type MutationCreateTicketFilterTemplatesArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketFilterTemplatesCreateInput>>>
}


export type MutationCreateTicketHistoryRecordArgs = {
    data?: InputMaybe<TicketHistoryRecordCreateInput>
}


export type MutationCreateTicketHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketHistoryRecordsCreateInput>>>
}


export type MutationCreateTicketOrganizationSettingArgs = {
    data?: InputMaybe<TicketOrganizationSettingCreateInput>
}


export type MutationCreateTicketOrganizationSettingHistoryRecordArgs = {
    data?: InputMaybe<TicketOrganizationSettingHistoryRecordCreateInput>
}


export type MutationCreateTicketOrganizationSettingHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketOrganizationSettingHistoryRecordsCreateInput>>>
}


export type MutationCreateTicketOrganizationSettingsArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketOrganizationSettingsCreateInput>>>
}


export type MutationCreateTicketPlaceClassifierArgs = {
    data?: InputMaybe<TicketPlaceClassifierCreateInput>
}


export type MutationCreateTicketPlaceClassifierHistoryRecordArgs = {
    data?: InputMaybe<TicketPlaceClassifierHistoryRecordCreateInput>
}


export type MutationCreateTicketPlaceClassifierHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketPlaceClassifierHistoryRecordsCreateInput>>>
}


export type MutationCreateTicketPlaceClassifiersArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketPlaceClassifiersCreateInput>>>
}


export type MutationCreateTicketProblemClassifierArgs = {
    data?: InputMaybe<TicketProblemClassifierCreateInput>
}


export type MutationCreateTicketProblemClassifierHistoryRecordArgs = {
    data?: InputMaybe<TicketProblemClassifierHistoryRecordCreateInput>
}


export type MutationCreateTicketProblemClassifierHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketProblemClassifierHistoryRecordsCreateInput>>>
}


export type MutationCreateTicketProblemClassifiersArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketProblemClassifiersCreateInput>>>
}


export type MutationCreateTicketPropertyHintArgs = {
    data?: InputMaybe<TicketPropertyHintCreateInput>
}


export type MutationCreateTicketPropertyHintHistoryRecordArgs = {
    data?: InputMaybe<TicketPropertyHintHistoryRecordCreateInput>
}


export type MutationCreateTicketPropertyHintHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketPropertyHintHistoryRecordsCreateInput>>>
}


export type MutationCreateTicketPropertyHintPropertiesArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketPropertyHintPropertiesCreateInput>>>
}


export type MutationCreateTicketPropertyHintPropertyArgs = {
    data?: InputMaybe<TicketPropertyHintPropertyCreateInput>
}


export type MutationCreateTicketPropertyHintPropertyHistoryRecordArgs = {
    data?: InputMaybe<TicketPropertyHintPropertyHistoryRecordCreateInput>
}


export type MutationCreateTicketPropertyHintPropertyHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketPropertyHintPropertyHistoryRecordsCreateInput>>>
}


export type MutationCreateTicketPropertyHintsArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketPropertyHintsCreateInput>>>
}


export type MutationCreateTicketSourceArgs = {
    data?: InputMaybe<TicketSourceCreateInput>
}


export type MutationCreateTicketSourceHistoryRecordArgs = {
    data?: InputMaybe<TicketSourceHistoryRecordCreateInput>
}


export type MutationCreateTicketSourceHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketSourceHistoryRecordsCreateInput>>>
}


export type MutationCreateTicketSourcesArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketSourcesCreateInput>>>
}


export type MutationCreateTicketStatusArgs = {
    data?: InputMaybe<TicketStatusCreateInput>
}


export type MutationCreateTicketStatusHistoryRecordArgs = {
    data?: InputMaybe<TicketStatusHistoryRecordCreateInput>
}


export type MutationCreateTicketStatusHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketStatusHistoryRecordsCreateInput>>>
}


export type MutationCreateTicketStatusesArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketStatusesCreateInput>>>
}


export type MutationCreateTicketsArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketsCreateInput>>>
}


export type MutationCreateTourStepArgs = {
    data?: InputMaybe<TourStepCreateInput>
}


export type MutationCreateTourStepHistoryRecordArgs = {
    data?: InputMaybe<TourStepHistoryRecordCreateInput>
}


export type MutationCreateTourStepHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<TourStepHistoryRecordsCreateInput>>>
}


export type MutationCreateTourStepsArgs = {
    data?: InputMaybe<Array<InputMaybe<TourStepsCreateInput>>>
}


export type MutationCreateUserArgs = {
    data?: InputMaybe<UserCreateInput>
}


export type MutationCreateUserExternalIdentitiesArgs = {
    data?: InputMaybe<Array<InputMaybe<UserExternalIdentitiesCreateInput>>>
}


export type MutationCreateUserExternalIdentityArgs = {
    data?: InputMaybe<UserExternalIdentityCreateInput>
}


export type MutationCreateUserExternalIdentityHistoryRecordArgs = {
    data?: InputMaybe<UserExternalIdentityHistoryRecordCreateInput>
}


export type MutationCreateUserExternalIdentityHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<UserExternalIdentityHistoryRecordsCreateInput>>>
}


export type MutationCreateUserFavoriteTicketArgs = {
    data?: InputMaybe<UserFavoriteTicketCreateInput>
}


export type MutationCreateUserFavoriteTicketHistoryRecordArgs = {
    data?: InputMaybe<UserFavoriteTicketHistoryRecordCreateInput>
}


export type MutationCreateUserFavoriteTicketHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<UserFavoriteTicketHistoryRecordsCreateInput>>>
}


export type MutationCreateUserFavoriteTicketsArgs = {
    data?: InputMaybe<Array<InputMaybe<UserFavoriteTicketsCreateInput>>>
}


export type MutationCreateUserHelpRequestArgs = {
    data?: InputMaybe<UserHelpRequestCreateInput>
}


export type MutationCreateUserHelpRequestFileArgs = {
    data?: InputMaybe<UserHelpRequestFileCreateInput>
}


export type MutationCreateUserHelpRequestFileHistoryRecordArgs = {
    data?: InputMaybe<UserHelpRequestFileHistoryRecordCreateInput>
}


export type MutationCreateUserHelpRequestFileHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<UserHelpRequestFileHistoryRecordsCreateInput>>>
}


export type MutationCreateUserHelpRequestFilesArgs = {
    data?: InputMaybe<Array<InputMaybe<UserHelpRequestFilesCreateInput>>>
}


export type MutationCreateUserHelpRequestHistoryRecordArgs = {
    data?: InputMaybe<UserHelpRequestHistoryRecordCreateInput>
}


export type MutationCreateUserHelpRequestHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<UserHelpRequestHistoryRecordsCreateInput>>>
}


export type MutationCreateUserHelpRequestsArgs = {
    data?: InputMaybe<Array<InputMaybe<UserHelpRequestsCreateInput>>>
}


export type MutationCreateUserHistoryRecordArgs = {
    data?: InputMaybe<UserHistoryRecordCreateInput>
}


export type MutationCreateUserHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<UserHistoryRecordsCreateInput>>>
}


export type MutationCreateUserRightsSetArgs = {
    data?: InputMaybe<UserRightsSetCreateInput>
}


export type MutationCreateUserRightsSetHistoryRecordArgs = {
    data?: InputMaybe<UserRightsSetHistoryRecordCreateInput>
}


export type MutationCreateUserRightsSetHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<UserRightsSetHistoryRecordsCreateInput>>>
}


export type MutationCreateUserRightsSetsArgs = {
    data?: InputMaybe<Array<InputMaybe<UserRightsSetsCreateInput>>>
}


export type MutationCreateUserTicketCommentReadTimeArgs = {
    data?: InputMaybe<UserTicketCommentReadTimeCreateInput>
}


export type MutationCreateUserTicketCommentReadTimeHistoryRecordArgs = {
    data?: InputMaybe<UserTicketCommentReadTimeHistoryRecordCreateInput>
}


export type MutationCreateUserTicketCommentReadTimeHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<UserTicketCommentReadTimeHistoryRecordsCreateInput>>>
}


export type MutationCreateUserTicketCommentReadTimesArgs = {
    data?: InputMaybe<Array<InputMaybe<UserTicketCommentReadTimesCreateInput>>>
}


export type MutationCreateUsersArgs = {
    data?: InputMaybe<Array<InputMaybe<UsersCreateInput>>>
}


export type MutationCreateWebhookArgs = {
    data?: InputMaybe<WebhookCreateInput>
}


export type MutationCreateWebhookHistoryRecordArgs = {
    data?: InputMaybe<WebhookHistoryRecordCreateInput>
}


export type MutationCreateWebhookHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<WebhookHistoryRecordsCreateInput>>>
}


export type MutationCreateWebhookSubscriptionArgs = {
    data?: InputMaybe<WebhookSubscriptionCreateInput>
}


export type MutationCreateWebhookSubscriptionHistoryRecordArgs = {
    data?: InputMaybe<WebhookSubscriptionHistoryRecordCreateInput>
}


export type MutationCreateWebhookSubscriptionHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<WebhookSubscriptionHistoryRecordsCreateInput>>>
}


export type MutationCreateWebhookSubscriptionsArgs = {
    data?: InputMaybe<Array<InputMaybe<WebhookSubscriptionsCreateInput>>>
}


export type MutationCreateWebhooksArgs = {
    data?: InputMaybe<Array<InputMaybe<WebhooksCreateInput>>>
}


export type MutationDeleteAcquiringIntegrationArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteAcquiringIntegrationAccessRightArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteAcquiringIntegrationAccessRightHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteAcquiringIntegrationAccessRightHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteAcquiringIntegrationAccessRightsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteAcquiringIntegrationContextArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteAcquiringIntegrationContextHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteAcquiringIntegrationContextHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteAcquiringIntegrationContextsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteAcquiringIntegrationHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteAcquiringIntegrationHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteAcquiringIntegrationsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteB2BAppArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteB2BAppAccessRightArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteB2BAppAccessRightHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteB2BAppAccessRightHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteB2BAppAccessRightSetArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteB2BAppAccessRightSetHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteB2BAppAccessRightSetHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteB2BAppAccessRightSetsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteB2BAppAccessRightsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteB2BAppContextArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteB2BAppContextHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteB2BAppContextHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteB2BAppContextsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteB2BAppHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteB2BAppHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteB2BAppNewsSharingConfigArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteB2BAppNewsSharingConfigHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteB2BAppNewsSharingConfigHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteB2BAppNewsSharingConfigsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteB2BAppPermissionArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteB2BAppPermissionHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteB2BAppPermissionHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteB2BAppPermissionsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteB2BAppPromoBlockArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteB2BAppPromoBlockHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteB2BAppPromoBlockHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteB2BAppPromoBlocksArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteB2BAppRoleArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteB2BAppRoleHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteB2BAppRoleHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteB2BAppRolesArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteB2BAppsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteB2CAppArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteB2CAppAccessRightArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteB2CAppAccessRightHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteB2CAppAccessRightHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteB2CAppAccessRightsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteB2CAppBuildArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteB2CAppBuildHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteB2CAppBuildHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteB2CAppBuildsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteB2CAppHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteB2CAppHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteB2CAppMessageSettingArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteB2CAppMessageSettingHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteB2CAppMessageSettingHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteB2CAppMessageSettingsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteB2CAppPropertiesArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteB2CAppPropertyArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteB2CAppPropertyHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteB2CAppPropertyHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteB2CAppsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteBankAccountArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteBankAccountHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteBankAccountHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteBankAccountReportArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteBankAccountReportHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteBankAccountReportHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteBankAccountReportTaskArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteBankAccountReportTaskHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteBankAccountReportTaskHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteBankAccountReportTasksArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteBankAccountReportsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteBankAccountsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteBankCategoriesArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteBankCategoryArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteBankCategoryHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteBankCategoryHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteBankContractorAccountArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteBankContractorAccountHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteBankContractorAccountHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteBankContractorAccountsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteBankCostItemArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteBankCostItemHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteBankCostItemHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteBankCostItemsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteBankIntegrationArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteBankIntegrationAccessRightArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteBankIntegrationAccessRightHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteBankIntegrationAccessRightHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteBankIntegrationAccessRightsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteBankIntegrationAccountContextArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteBankIntegrationAccountContextHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteBankIntegrationAccountContextHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteBankIntegrationAccountContextsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteBankIntegrationHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteBankIntegrationHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteBankIntegrationOrganizationContextArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteBankIntegrationOrganizationContextHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteBankIntegrationOrganizationContextHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteBankIntegrationOrganizationContextsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteBankIntegrationsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteBankSyncTaskArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteBankSyncTaskHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteBankSyncTaskHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteBankSyncTasksArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteBankTransactionArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteBankTransactionHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteBankTransactionHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteBankTransactionsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteBillingAccountArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteBillingAccountHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteBillingAccountHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteBillingAccountsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteBillingCategoriesArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteBillingCategoryArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteBillingCategoryHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteBillingCategoryHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteBillingIntegrationArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteBillingIntegrationAccessRightArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteBillingIntegrationAccessRightHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteBillingIntegrationAccessRightHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteBillingIntegrationAccessRightsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteBillingIntegrationHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteBillingIntegrationHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteBillingIntegrationOrganizationContextArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteBillingIntegrationOrganizationContextHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteBillingIntegrationOrganizationContextHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteBillingIntegrationOrganizationContextsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteBillingIntegrationProblemArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteBillingIntegrationProblemsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteBillingIntegrationsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteBillingPropertiesArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteBillingPropertyArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteBillingPropertyHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteBillingPropertyHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteBillingReceiptArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteBillingReceiptFileArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteBillingReceiptFileHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteBillingReceiptFileHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteBillingReceiptFilesArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteBillingReceiptHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteBillingReceiptHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteBillingReceiptsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteBillingRecipientArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteBillingRecipientHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteBillingRecipientHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteBillingRecipientsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteCallRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteCallRecordFragmentArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteCallRecordFragmentHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteCallRecordFragmentHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteCallRecordFragmentsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteCallRecordHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteCallRecordHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteCallRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteConfirmPhoneActionArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteConfirmPhoneActionHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteConfirmPhoneActionHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteConfirmPhoneActionsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteContactArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteContactExportTaskArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteContactExportTaskHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteContactExportTaskHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteContactExportTasksArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteContactHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteContactHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteContactRoleArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteContactRoleHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteContactRoleHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteContactRolesArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteContactsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteDocumentArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteDocumentCategoriesArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteDocumentCategoryArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteDocumentCategoryHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteDocumentCategoryHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteDocumentHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteDocumentHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteDocumentsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteExternalTokenAccessRightArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteExternalTokenAccessRightHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteExternalTokenAccessRightHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteExternalTokenAccessRightsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteIncidentArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteIncidentChangeArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteIncidentChangesArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteIncidentClassifierArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteIncidentClassifierHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteIncidentClassifierHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteIncidentClassifierIncidentArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteIncidentClassifierIncidentHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteIncidentClassifierIncidentHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteIncidentClassifierIncidentsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteIncidentClassifiersArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteIncidentExportTaskArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteIncidentExportTaskHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteIncidentExportTaskHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteIncidentExportTasksArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteIncidentHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteIncidentHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteIncidentPropertiesArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteIncidentPropertyArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteIncidentPropertyHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteIncidentPropertyHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteIncidentsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteInvoiceArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteInvoiceHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteInvoiceHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteInvoicesArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteMarketCategoriesArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteMarketCategoryArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteMarketCategoryHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteMarketCategoryHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteMarketItemArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteMarketItemFileArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteMarketItemFileHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteMarketItemFileHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteMarketItemFilesArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteMarketItemHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteMarketItemHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteMarketItemPriceArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteMarketItemPriceHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteMarketItemPriceHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteMarketItemPricesArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteMarketItemsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteMarketPriceScopeArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteMarketPriceScopeHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteMarketPriceScopeHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteMarketPriceScopesArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteMarketSettingArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteMarketSettingHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteMarketSettingHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteMarketSettingsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteMessageArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteMessageAppBlackListArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteMessageAppBlackListHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteMessageAppBlackListHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteMessageAppBlackListsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteMessageBatchArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteMessageBatchHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteMessageBatchHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteMessageBatchesArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteMessageHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteMessageHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteMessageOrganizationBlackListArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteMessageOrganizationBlackListHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteMessageOrganizationBlackListHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteMessageOrganizationBlackListsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteMessageUserBlackListArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteMessageUserBlackListHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteMessageUserBlackListHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteMessageUserBlackListsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteMessagesArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteMeterArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteMeterHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteMeterHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteMeterReadingArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteMeterReadingExportTaskArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteMeterReadingExportTaskHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteMeterReadingExportTaskHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteMeterReadingExportTasksArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteMeterReadingFilterTemplateArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteMeterReadingFilterTemplateHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteMeterReadingFilterTemplateHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteMeterReadingFilterTemplatesArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteMeterReadingHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteMeterReadingHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteMeterReadingSourceArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteMeterReadingSourceHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteMeterReadingSourceHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteMeterReadingSourcesArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteMeterReadingsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteMeterReadingsImportTaskArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteMeterReadingsImportTaskHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteMeterReadingsImportTaskHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteMeterReadingsImportTasksArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteMeterReportingPeriodArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteMeterReportingPeriodHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteMeterReportingPeriodHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteMeterReportingPeriodsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteMeterResourceArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteMeterResourceHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteMeterResourceHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteMeterResourceOwnerArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteMeterResourceOwnerHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteMeterResourceOwnerHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteMeterResourceOwnersArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteMeterResourcesArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteMetersArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteMobileFeatureConfigArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteMobileFeatureConfigHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteMobileFeatureConfigHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteMobileFeatureConfigsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteMultiPaymentArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteMultiPaymentHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteMultiPaymentHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteMultiPaymentsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteNewsItemArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteNewsItemHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteNewsItemHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteNewsItemRecipientsExportTaskArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteNewsItemRecipientsExportTaskHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteNewsItemRecipientsExportTaskHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteNewsItemRecipientsExportTasksArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteNewsItemScopeArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteNewsItemScopeHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteNewsItemScopeHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteNewsItemScopesArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteNewsItemSharingArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteNewsItemSharingHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteNewsItemSharingHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteNewsItemSharingsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteNewsItemTemplateArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteNewsItemTemplateHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteNewsItemTemplateHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteNewsItemTemplatesArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteNewsItemUserReadArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteNewsItemUserReadHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteNewsItemUserReadHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteNewsItemUserReadsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteNewsItemsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteNotificationAnonymousSettingArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteNotificationAnonymousSettingHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteNotificationAnonymousSettingHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteNotificationAnonymousSettingsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteNotificationUserSettingArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteNotificationUserSettingHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteNotificationUserSettingHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteNotificationUserSettingsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteOidcClientArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteOidcClientHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteOidcClientHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteOidcClientsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteOrganizationArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteOrganizationEmployeeArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteOrganizationEmployeeHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteOrganizationEmployeeHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteOrganizationEmployeeRoleArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteOrganizationEmployeeRoleHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteOrganizationEmployeeRoleHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteOrganizationEmployeeRolesArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteOrganizationEmployeeSpecializationArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteOrganizationEmployeeSpecializationHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteOrganizationEmployeeSpecializationHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteOrganizationEmployeeSpecializationsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteOrganizationEmployeesArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteOrganizationHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteOrganizationHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteOrganizationLinkArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteOrganizationLinkHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteOrganizationLinkHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteOrganizationLinksArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteOrganizationsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeletePaymentArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeletePaymentHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeletePaymentHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeletePaymentsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeletePaymentsFilterTemplateArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeletePaymentsFilterTemplateHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeletePaymentsFilterTemplateHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeletePaymentsFilterTemplatesArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeletePropertiesArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeletePropertyArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeletePropertyHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeletePropertyHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeletePropertyMeterArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeletePropertyMeterHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeletePropertyMeterHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeletePropertyMeterReadingArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeletePropertyMeterReadingHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeletePropertyMeterReadingHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeletePropertyMeterReadingsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeletePropertyMetersArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeletePropertyScopeArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeletePropertyScopeHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeletePropertyScopeHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeletePropertyScopeOrganizationEmployeeArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeletePropertyScopeOrganizationEmployeeHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeletePropertyScopeOrganizationEmployeeHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeletePropertyScopeOrganizationEmployeesArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeletePropertyScopePropertiesArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeletePropertyScopePropertyArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeletePropertyScopePropertyHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeletePropertyScopePropertyHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeletePropertyScopesArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteRecurrentPaymentArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteRecurrentPaymentContextArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteRecurrentPaymentContextHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteRecurrentPaymentContextHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteRecurrentPaymentContextsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteRecurrentPaymentHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteRecurrentPaymentHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteRecurrentPaymentsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteRemoteClientArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteRemoteClientHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteRemoteClientHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteRemoteClientsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteResidentArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteResidentHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteResidentHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteResidentsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteServiceConsumerArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteServiceConsumerHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteServiceConsumerHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteServiceConsumersArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteServiceSubscriptionArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteServiceSubscriptionHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteServiceSubscriptionHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteServiceSubscriptionsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteTelegramUserChatArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteTelegramUserChatHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteTelegramUserChatHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteTelegramUserChatsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteTicketArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteTicketAutoAssignmentArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteTicketAutoAssignmentHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteTicketAutoAssignmentHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteTicketAutoAssignmentsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteTicketCategoryClassifierArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteTicketCategoryClassifierHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteTicketCategoryClassifierHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteTicketCategoryClassifiersArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteTicketChangeArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteTicketChangesArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteTicketClassifierArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteTicketClassifierHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteTicketClassifierHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteTicketClassifiersArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteTicketCommentArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteTicketCommentFileArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteTicketCommentFileHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteTicketCommentFileHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteTicketCommentFilesArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteTicketCommentHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteTicketCommentHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteTicketCommentsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteTicketDocumentGenerationTaskArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteTicketDocumentGenerationTaskHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteTicketDocumentGenerationTaskHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteTicketDocumentGenerationTasksArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteTicketExportTaskArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteTicketExportTaskHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteTicketExportTaskHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteTicketExportTasksArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteTicketFileArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteTicketFileHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteTicketFileHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteTicketFilesArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteTicketFilterTemplateArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteTicketFilterTemplateHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteTicketFilterTemplateHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteTicketFilterTemplatesArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteTicketHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteTicketHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteTicketOrganizationSettingArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteTicketOrganizationSettingHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteTicketOrganizationSettingHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteTicketOrganizationSettingsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteTicketPlaceClassifierArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteTicketPlaceClassifierHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteTicketPlaceClassifierHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteTicketPlaceClassifiersArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteTicketProblemClassifierArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteTicketProblemClassifierHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteTicketProblemClassifierHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteTicketProblemClassifiersArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteTicketPropertyHintArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteTicketPropertyHintHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteTicketPropertyHintHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteTicketPropertyHintPropertiesArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteTicketPropertyHintPropertyArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteTicketPropertyHintPropertyHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteTicketPropertyHintPropertyHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteTicketPropertyHintsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteTicketSourceArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteTicketSourceHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteTicketSourceHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteTicketSourcesArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteTicketStatusArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteTicketStatusHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteTicketStatusHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteTicketStatusesArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteTicketsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteTourStepArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteTourStepHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteTourStepHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteTourStepsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteUserArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteUserExternalIdentitiesArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteUserExternalIdentityArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteUserExternalIdentityHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteUserExternalIdentityHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteUserFavoriteTicketArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteUserFavoriteTicketHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteUserFavoriteTicketHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteUserFavoriteTicketsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteUserHelpRequestArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteUserHelpRequestFileArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteUserHelpRequestFileHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteUserHelpRequestFileHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteUserHelpRequestFilesArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteUserHelpRequestHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteUserHelpRequestHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteUserHelpRequestsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteUserHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteUserHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteUserRightsSetArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteUserRightsSetHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteUserRightsSetHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteUserRightsSetsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteUserTicketCommentReadTimeArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteUserTicketCommentReadTimeHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteUserTicketCommentReadTimeHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteUserTicketCommentReadTimesArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteUsersArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteWebhookArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteWebhookHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteWebhookHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteWebhookSubscriptionArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteWebhookSubscriptionHistoryRecordArgs = {
    id: Scalars['ID']['input']
}


export type MutationDeleteWebhookSubscriptionHistoryRecordsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteWebhookSubscriptionsArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDeleteWebhooksArgs = {
    ids?: InputMaybe<Array<Scalars['ID']['input']>>
}


export type MutationDisconnectUserFromRemoteClientArgs = {
    data: DisconnectUserFromRemoteClientInput
}


export type MutationDiscoverServiceConsumersArgs = {
    data: DiscoverServiceConsumersInput
}


export type MutationInviteNewOrganizationEmployeeArgs = {
    data: InviteNewOrganizationEmployeeInput
}


export type MutationReInviteOrganizationEmployeeArgs = {
    data: ReInviteOrganizationEmployeeInput
}


export type MutationRegisterBillingReceiptFileArgs = {
    data: RegisterBillingReceiptFileInput
}


export type MutationRegisterBillingReceiptsArgs = {
    data: RegisterBillingReceiptsInput
}


export type MutationRegisterMetersReadingsArgs = {
    data: RegisterMetersReadingsInput
}


export type MutationRegisterMultiPaymentArgs = {
    data: RegisterMultiPaymentInput
}


export type MutationRegisterMultiPaymentForInvoicesArgs = {
    data: RegisterMultiPaymentForInvoicesInput
}


export type MutationRegisterMultiPaymentForOneReceiptArgs = {
    data: RegisterMultiPaymentForOneReceiptInput
}


export type MutationRegisterMultiPaymentForVirtualReceiptArgs = {
    data: RegisterMultiPaymentForVirtualReceiptInput
}


export type MutationRegisterNewOrganizationArgs = {
    data: RegisterNewOrganizationInput
}


export type MutationRegisterNewServiceUserArgs = {
    data: RegisterNewServiceUserInput
}


export type MutationRegisterNewUserArgs = {
    data: RegisterNewUserInput
}


export type MutationRegisterResidentArgs = {
    data: RegisterResidentInput
}


export type MutationRegisterResidentInvoiceArgs = {
    data: RegisterResidentInvoiceInput
}


export type MutationRegisterResidentServiceConsumersArgs = {
    data: RegisterResidentServiceConsumersInput
}


export type MutationRegisterServiceConsumerArgs = {
    data: RegisterServiceConsumerInput
}


export type MutationReplaceOrganizationEmployeeRoleArgs = {
    data: ReplaceOrganizationEmployeeRoleInput
}


export type MutationResendConfirmPhoneActionSmsArgs = {
    data: ResendConfirmPhoneActionSmsInput
}


export type MutationResendMessageArgs = {
    data: ResendMessageInput
}


export type MutationResetOrganizationArgs = {
    data: ResetOrganizationInput
}


export type MutationResetUserArgs = {
    data: ResetUserInput
}


export type MutationSendB2CAppPushMessageArgs = {
    data: SendB2CAppPushMessageInput
}


export type MutationSendMessageArgs = {
    data: SendMessageInput
}


export type MutationSendMessageToResidentScopesArgs = {
    data: SendMessageToResidentScopesServiceInput
}


export type MutationSendMessageToSupportArgs = {
    data: SendMessageToSupportInput
}


export type MutationSendNewBillingReceiptFilesNotificationsArgs = {
    data: SendNewBillingReceiptFilesNotificationsInput
}


export type MutationSendNewReceiptMessagesToResidentScopesArgs = {
    data: SendNewReceiptMessagesToResidentScopesInput
}


export type MutationSetMessageStatusArgs = {
    data: SetMessageStatusInput
}


export type MutationShareTicketArgs = {
    data: ShareTicketInput
}


export type MutationSigninAsUserArgs = {
    data: SigninAsUserInput
}


export type MutationSigninResidentUserArgs = {
    data: SigninResidentUserInput
}


export type MutationStartConfirmPhoneActionArgs = {
    data: StartConfirmPhoneActionInput
}


export type MutationSyncRemoteClientArgs = {
    data: SyncRemoteClientInput
}


export type MutationSyncTourStepsArgs = {
    data: SyncTourStepsInput
}


export type MutationTicketMultipleUpdateArgs = {
    data: TicketMultipleUpdateInput
}


export type MutationUpdateAcquiringIntegrationArgs = {
    data?: InputMaybe<AcquiringIntegrationUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateAcquiringIntegrationAccessRightArgs = {
    data?: InputMaybe<AcquiringIntegrationAccessRightUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateAcquiringIntegrationAccessRightHistoryRecordArgs = {
    data?: InputMaybe<AcquiringIntegrationAccessRightHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateAcquiringIntegrationAccessRightHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<AcquiringIntegrationAccessRightHistoryRecordsUpdateInput>>>
}


export type MutationUpdateAcquiringIntegrationAccessRightsArgs = {
    data?: InputMaybe<Array<InputMaybe<AcquiringIntegrationAccessRightsUpdateInput>>>
}


export type MutationUpdateAcquiringIntegrationContextArgs = {
    data?: InputMaybe<AcquiringIntegrationContextUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateAcquiringIntegrationContextHistoryRecordArgs = {
    data?: InputMaybe<AcquiringIntegrationContextHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateAcquiringIntegrationContextHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<AcquiringIntegrationContextHistoryRecordsUpdateInput>>>
}


export type MutationUpdateAcquiringIntegrationContextsArgs = {
    data?: InputMaybe<Array<InputMaybe<AcquiringIntegrationContextsUpdateInput>>>
}


export type MutationUpdateAcquiringIntegrationHistoryRecordArgs = {
    data?: InputMaybe<AcquiringIntegrationHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateAcquiringIntegrationHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<AcquiringIntegrationHistoryRecordsUpdateInput>>>
}


export type MutationUpdateAcquiringIntegrationsArgs = {
    data?: InputMaybe<Array<InputMaybe<AcquiringIntegrationsUpdateInput>>>
}


export type MutationUpdateAuthenticatedUserArgs = {
    data?: InputMaybe<UserUpdateInput>
}


export type MutationUpdateB2BAppArgs = {
    data?: InputMaybe<B2BAppUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateB2BAppAccessRightArgs = {
    data?: InputMaybe<B2BAppAccessRightUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateB2BAppAccessRightHistoryRecordArgs = {
    data?: InputMaybe<B2BAppAccessRightHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateB2BAppAccessRightHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<B2BAppAccessRightHistoryRecordsUpdateInput>>>
}


export type MutationUpdateB2BAppAccessRightSetArgs = {
    data?: InputMaybe<B2BAppAccessRightSetUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateB2BAppAccessRightSetHistoryRecordArgs = {
    data?: InputMaybe<B2BAppAccessRightSetHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateB2BAppAccessRightSetHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<B2BAppAccessRightSetHistoryRecordsUpdateInput>>>
}


export type MutationUpdateB2BAppAccessRightSetsArgs = {
    data?: InputMaybe<Array<InputMaybe<B2BAppAccessRightSetsUpdateInput>>>
}


export type MutationUpdateB2BAppAccessRightsArgs = {
    data?: InputMaybe<Array<InputMaybe<B2BAppAccessRightsUpdateInput>>>
}


export type MutationUpdateB2BAppContextArgs = {
    data?: InputMaybe<B2BAppContextUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateB2BAppContextHistoryRecordArgs = {
    data?: InputMaybe<B2BAppContextHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateB2BAppContextHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<B2BAppContextHistoryRecordsUpdateInput>>>
}


export type MutationUpdateB2BAppContextsArgs = {
    data?: InputMaybe<Array<InputMaybe<B2BAppContextsUpdateInput>>>
}


export type MutationUpdateB2BAppHistoryRecordArgs = {
    data?: InputMaybe<B2BAppHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateB2BAppHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<B2BAppHistoryRecordsUpdateInput>>>
}


export type MutationUpdateB2BAppNewsSharingConfigArgs = {
    data?: InputMaybe<B2BAppNewsSharingConfigUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateB2BAppNewsSharingConfigHistoryRecordArgs = {
    data?: InputMaybe<B2BAppNewsSharingConfigHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateB2BAppNewsSharingConfigHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<B2BAppNewsSharingConfigHistoryRecordsUpdateInput>>>
}


export type MutationUpdateB2BAppNewsSharingConfigsArgs = {
    data?: InputMaybe<Array<InputMaybe<B2BAppNewsSharingConfigsUpdateInput>>>
}


export type MutationUpdateB2BAppPermissionArgs = {
    data?: InputMaybe<B2BAppPermissionUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateB2BAppPermissionHistoryRecordArgs = {
    data?: InputMaybe<B2BAppPermissionHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateB2BAppPermissionHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<B2BAppPermissionHistoryRecordsUpdateInput>>>
}


export type MutationUpdateB2BAppPermissionsArgs = {
    data?: InputMaybe<Array<InputMaybe<B2BAppPermissionsUpdateInput>>>
}


export type MutationUpdateB2BAppPromoBlockArgs = {
    data?: InputMaybe<B2BAppPromoBlockUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateB2BAppPromoBlockHistoryRecordArgs = {
    data?: InputMaybe<B2BAppPromoBlockHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateB2BAppPromoBlockHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<B2BAppPromoBlockHistoryRecordsUpdateInput>>>
}


export type MutationUpdateB2BAppPromoBlocksArgs = {
    data?: InputMaybe<Array<InputMaybe<B2BAppPromoBlocksUpdateInput>>>
}


export type MutationUpdateB2BAppRoleArgs = {
    data?: InputMaybe<B2BAppRoleUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateB2BAppRoleHistoryRecordArgs = {
    data?: InputMaybe<B2BAppRoleHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateB2BAppRoleHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<B2BAppRoleHistoryRecordsUpdateInput>>>
}


export type MutationUpdateB2BAppRolesArgs = {
    data?: InputMaybe<Array<InputMaybe<B2BAppRolesUpdateInput>>>
}


export type MutationUpdateB2BAppsArgs = {
    data?: InputMaybe<Array<InputMaybe<B2BAppsUpdateInput>>>
}


export type MutationUpdateB2CAppArgs = {
    data?: InputMaybe<B2CAppUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateB2CAppAccessRightArgs = {
    data?: InputMaybe<B2CAppAccessRightUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateB2CAppAccessRightHistoryRecordArgs = {
    data?: InputMaybe<B2CAppAccessRightHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateB2CAppAccessRightHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<B2CAppAccessRightHistoryRecordsUpdateInput>>>
}


export type MutationUpdateB2CAppAccessRightsArgs = {
    data?: InputMaybe<Array<InputMaybe<B2CAppAccessRightsUpdateInput>>>
}


export type MutationUpdateB2CAppBuildArgs = {
    data?: InputMaybe<B2CAppBuildUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateB2CAppBuildHistoryRecordArgs = {
    data?: InputMaybe<B2CAppBuildHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateB2CAppBuildHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<B2CAppBuildHistoryRecordsUpdateInput>>>
}


export type MutationUpdateB2CAppBuildsArgs = {
    data?: InputMaybe<Array<InputMaybe<B2CAppBuildsUpdateInput>>>
}


export type MutationUpdateB2CAppHistoryRecordArgs = {
    data?: InputMaybe<B2CAppHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateB2CAppHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<B2CAppHistoryRecordsUpdateInput>>>
}


export type MutationUpdateB2CAppMessageSettingArgs = {
    data?: InputMaybe<B2CAppMessageSettingUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateB2CAppMessageSettingHistoryRecordArgs = {
    data?: InputMaybe<B2CAppMessageSettingHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateB2CAppMessageSettingHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<B2CAppMessageSettingHistoryRecordsUpdateInput>>>
}


export type MutationUpdateB2CAppMessageSettingsArgs = {
    data?: InputMaybe<Array<InputMaybe<B2CAppMessageSettingsUpdateInput>>>
}


export type MutationUpdateB2CAppPropertiesArgs = {
    data?: InputMaybe<Array<InputMaybe<B2CAppPropertiesUpdateInput>>>
}


export type MutationUpdateB2CAppPropertyArgs = {
    data?: InputMaybe<B2CAppPropertyUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateB2CAppPropertyHistoryRecordArgs = {
    data?: InputMaybe<B2CAppPropertyHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateB2CAppPropertyHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<B2CAppPropertyHistoryRecordsUpdateInput>>>
}


export type MutationUpdateB2CAppsArgs = {
    data?: InputMaybe<Array<InputMaybe<B2CAppsUpdateInput>>>
}


export type MutationUpdateBankAccountArgs = {
    data?: InputMaybe<BankAccountUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateBankAccountHistoryRecordArgs = {
    data?: InputMaybe<BankAccountHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateBankAccountHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<BankAccountHistoryRecordsUpdateInput>>>
}


export type MutationUpdateBankAccountReportArgs = {
    data?: InputMaybe<BankAccountReportUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateBankAccountReportHistoryRecordArgs = {
    data?: InputMaybe<BankAccountReportHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateBankAccountReportHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<BankAccountReportHistoryRecordsUpdateInput>>>
}


export type MutationUpdateBankAccountReportTaskArgs = {
    data?: InputMaybe<BankAccountReportTaskUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateBankAccountReportTaskHistoryRecordArgs = {
    data?: InputMaybe<BankAccountReportTaskHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateBankAccountReportTaskHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<BankAccountReportTaskHistoryRecordsUpdateInput>>>
}


export type MutationUpdateBankAccountReportTasksArgs = {
    data?: InputMaybe<Array<InputMaybe<BankAccountReportTasksUpdateInput>>>
}


export type MutationUpdateBankAccountReportsArgs = {
    data?: InputMaybe<Array<InputMaybe<BankAccountReportsUpdateInput>>>
}


export type MutationUpdateBankAccountsArgs = {
    data?: InputMaybe<Array<InputMaybe<BankAccountsUpdateInput>>>
}


export type MutationUpdateBankCategoriesArgs = {
    data?: InputMaybe<Array<InputMaybe<BankCategoriesUpdateInput>>>
}


export type MutationUpdateBankCategoryArgs = {
    data?: InputMaybe<BankCategoryUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateBankCategoryHistoryRecordArgs = {
    data?: InputMaybe<BankCategoryHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateBankCategoryHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<BankCategoryHistoryRecordsUpdateInput>>>
}


export type MutationUpdateBankContractorAccountArgs = {
    data?: InputMaybe<BankContractorAccountUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateBankContractorAccountHistoryRecordArgs = {
    data?: InputMaybe<BankContractorAccountHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateBankContractorAccountHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<BankContractorAccountHistoryRecordsUpdateInput>>>
}


export type MutationUpdateBankContractorAccountsArgs = {
    data?: InputMaybe<Array<InputMaybe<BankContractorAccountsUpdateInput>>>
}


export type MutationUpdateBankCostItemArgs = {
    data?: InputMaybe<BankCostItemUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateBankCostItemHistoryRecordArgs = {
    data?: InputMaybe<BankCostItemHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateBankCostItemHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<BankCostItemHistoryRecordsUpdateInput>>>
}


export type MutationUpdateBankCostItemsArgs = {
    data?: InputMaybe<Array<InputMaybe<BankCostItemsUpdateInput>>>
}


export type MutationUpdateBankIntegrationArgs = {
    data?: InputMaybe<BankIntegrationUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateBankIntegrationAccessRightArgs = {
    data?: InputMaybe<BankIntegrationAccessRightUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateBankIntegrationAccessRightHistoryRecordArgs = {
    data?: InputMaybe<BankIntegrationAccessRightHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateBankIntegrationAccessRightHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<BankIntegrationAccessRightHistoryRecordsUpdateInput>>>
}


export type MutationUpdateBankIntegrationAccessRightsArgs = {
    data?: InputMaybe<Array<InputMaybe<BankIntegrationAccessRightsUpdateInput>>>
}


export type MutationUpdateBankIntegrationAccountContextArgs = {
    data?: InputMaybe<BankIntegrationAccountContextUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateBankIntegrationAccountContextHistoryRecordArgs = {
    data?: InputMaybe<BankIntegrationAccountContextHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateBankIntegrationAccountContextHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<BankIntegrationAccountContextHistoryRecordsUpdateInput>>>
}


export type MutationUpdateBankIntegrationAccountContextsArgs = {
    data?: InputMaybe<Array<InputMaybe<BankIntegrationAccountContextsUpdateInput>>>
}


export type MutationUpdateBankIntegrationHistoryRecordArgs = {
    data?: InputMaybe<BankIntegrationHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateBankIntegrationHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<BankIntegrationHistoryRecordsUpdateInput>>>
}


export type MutationUpdateBankIntegrationOrganizationContextArgs = {
    data?: InputMaybe<BankIntegrationOrganizationContextUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateBankIntegrationOrganizationContextHistoryRecordArgs = {
    data?: InputMaybe<BankIntegrationOrganizationContextHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateBankIntegrationOrganizationContextHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<BankIntegrationOrganizationContextHistoryRecordsUpdateInput>>>
}


export type MutationUpdateBankIntegrationOrganizationContextsArgs = {
    data?: InputMaybe<Array<InputMaybe<BankIntegrationOrganizationContextsUpdateInput>>>
}


export type MutationUpdateBankIntegrationsArgs = {
    data?: InputMaybe<Array<InputMaybe<BankIntegrationsUpdateInput>>>
}


export type MutationUpdateBankSyncTaskArgs = {
    data?: InputMaybe<BankSyncTaskUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateBankSyncTaskHistoryRecordArgs = {
    data?: InputMaybe<BankSyncTaskHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateBankSyncTaskHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<BankSyncTaskHistoryRecordsUpdateInput>>>
}


export type MutationUpdateBankSyncTasksArgs = {
    data?: InputMaybe<Array<InputMaybe<BankSyncTasksUpdateInput>>>
}


export type MutationUpdateBankTransactionArgs = {
    data?: InputMaybe<BankTransactionUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateBankTransactionHistoryRecordArgs = {
    data?: InputMaybe<BankTransactionHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateBankTransactionHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<BankTransactionHistoryRecordsUpdateInput>>>
}


export type MutationUpdateBankTransactionsArgs = {
    data?: InputMaybe<Array<InputMaybe<BankTransactionsUpdateInput>>>
}


export type MutationUpdateBillingAccountArgs = {
    data?: InputMaybe<BillingAccountUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateBillingAccountHistoryRecordArgs = {
    data?: InputMaybe<BillingAccountHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateBillingAccountHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<BillingAccountHistoryRecordsUpdateInput>>>
}


export type MutationUpdateBillingAccountsArgs = {
    data?: InputMaybe<Array<InputMaybe<BillingAccountsUpdateInput>>>
}


export type MutationUpdateBillingCategoriesArgs = {
    data?: InputMaybe<Array<InputMaybe<BillingCategoriesUpdateInput>>>
}


export type MutationUpdateBillingCategoryArgs = {
    data?: InputMaybe<BillingCategoryUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateBillingCategoryHistoryRecordArgs = {
    data?: InputMaybe<BillingCategoryHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateBillingCategoryHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<BillingCategoryHistoryRecordsUpdateInput>>>
}


export type MutationUpdateBillingIntegrationArgs = {
    data?: InputMaybe<BillingIntegrationUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateBillingIntegrationAccessRightArgs = {
    data?: InputMaybe<BillingIntegrationAccessRightUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateBillingIntegrationAccessRightHistoryRecordArgs = {
    data?: InputMaybe<BillingIntegrationAccessRightHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateBillingIntegrationAccessRightHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<BillingIntegrationAccessRightHistoryRecordsUpdateInput>>>
}


export type MutationUpdateBillingIntegrationAccessRightsArgs = {
    data?: InputMaybe<Array<InputMaybe<BillingIntegrationAccessRightsUpdateInput>>>
}


export type MutationUpdateBillingIntegrationHistoryRecordArgs = {
    data?: InputMaybe<BillingIntegrationHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateBillingIntegrationHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<BillingIntegrationHistoryRecordsUpdateInput>>>
}


export type MutationUpdateBillingIntegrationOrganizationContextArgs = {
    data?: InputMaybe<BillingIntegrationOrganizationContextUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateBillingIntegrationOrganizationContextHistoryRecordArgs = {
    data?: InputMaybe<BillingIntegrationOrganizationContextHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateBillingIntegrationOrganizationContextHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<BillingIntegrationOrganizationContextHistoryRecordsUpdateInput>>>
}


export type MutationUpdateBillingIntegrationOrganizationContextsArgs = {
    data?: InputMaybe<Array<InputMaybe<BillingIntegrationOrganizationContextsUpdateInput>>>
}


export type MutationUpdateBillingIntegrationProblemArgs = {
    data?: InputMaybe<BillingIntegrationProblemUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateBillingIntegrationProblemsArgs = {
    data?: InputMaybe<Array<InputMaybe<BillingIntegrationProblemsUpdateInput>>>
}


export type MutationUpdateBillingIntegrationsArgs = {
    data?: InputMaybe<Array<InputMaybe<BillingIntegrationsUpdateInput>>>
}


export type MutationUpdateBillingPropertiesArgs = {
    data?: InputMaybe<Array<InputMaybe<BillingPropertiesUpdateInput>>>
}


export type MutationUpdateBillingPropertyArgs = {
    data?: InputMaybe<BillingPropertyUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateBillingPropertyHistoryRecordArgs = {
    data?: InputMaybe<BillingPropertyHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateBillingPropertyHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<BillingPropertyHistoryRecordsUpdateInput>>>
}


export type MutationUpdateBillingReceiptArgs = {
    data?: InputMaybe<BillingReceiptUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateBillingReceiptFileArgs = {
    data?: InputMaybe<BillingReceiptFileUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateBillingReceiptFileHistoryRecordArgs = {
    data?: InputMaybe<BillingReceiptFileHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateBillingReceiptFileHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<BillingReceiptFileHistoryRecordsUpdateInput>>>
}


export type MutationUpdateBillingReceiptFilesArgs = {
    data?: InputMaybe<Array<InputMaybe<BillingReceiptFilesUpdateInput>>>
}


export type MutationUpdateBillingReceiptHistoryRecordArgs = {
    data?: InputMaybe<BillingReceiptHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateBillingReceiptHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<BillingReceiptHistoryRecordsUpdateInput>>>
}


export type MutationUpdateBillingReceiptsArgs = {
    data?: InputMaybe<Array<InputMaybe<BillingReceiptsUpdateInput>>>
}


export type MutationUpdateBillingRecipientArgs = {
    data?: InputMaybe<BillingRecipientUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateBillingRecipientHistoryRecordArgs = {
    data?: InputMaybe<BillingRecipientHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateBillingRecipientHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<BillingRecipientHistoryRecordsUpdateInput>>>
}


export type MutationUpdateBillingRecipientsArgs = {
    data?: InputMaybe<Array<InputMaybe<BillingRecipientsUpdateInput>>>
}


export type MutationUpdateCallRecordArgs = {
    data?: InputMaybe<CallRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateCallRecordFragmentArgs = {
    data?: InputMaybe<CallRecordFragmentUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateCallRecordFragmentHistoryRecordArgs = {
    data?: InputMaybe<CallRecordFragmentHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateCallRecordFragmentHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<CallRecordFragmentHistoryRecordsUpdateInput>>>
}


export type MutationUpdateCallRecordFragmentsArgs = {
    data?: InputMaybe<Array<InputMaybe<CallRecordFragmentsUpdateInput>>>
}


export type MutationUpdateCallRecordHistoryRecordArgs = {
    data?: InputMaybe<CallRecordHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateCallRecordHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<CallRecordHistoryRecordsUpdateInput>>>
}


export type MutationUpdateCallRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<CallRecordsUpdateInput>>>
}


export type MutationUpdateConfirmPhoneActionArgs = {
    data?: InputMaybe<ConfirmPhoneActionUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateConfirmPhoneActionHistoryRecordArgs = {
    data?: InputMaybe<ConfirmPhoneActionHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateConfirmPhoneActionHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<ConfirmPhoneActionHistoryRecordsUpdateInput>>>
}


export type MutationUpdateConfirmPhoneActionsArgs = {
    data?: InputMaybe<Array<InputMaybe<ConfirmPhoneActionsUpdateInput>>>
}


export type MutationUpdateContactArgs = {
    data?: InputMaybe<ContactUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateContactExportTaskArgs = {
    data?: InputMaybe<ContactExportTaskUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateContactExportTaskHistoryRecordArgs = {
    data?: InputMaybe<ContactExportTaskHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateContactExportTaskHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<ContactExportTaskHistoryRecordsUpdateInput>>>
}


export type MutationUpdateContactExportTasksArgs = {
    data?: InputMaybe<Array<InputMaybe<ContactExportTasksUpdateInput>>>
}


export type MutationUpdateContactHistoryRecordArgs = {
    data?: InputMaybe<ContactHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateContactHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<ContactHistoryRecordsUpdateInput>>>
}


export type MutationUpdateContactRoleArgs = {
    data?: InputMaybe<ContactRoleUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateContactRoleHistoryRecordArgs = {
    data?: InputMaybe<ContactRoleHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateContactRoleHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<ContactRoleHistoryRecordsUpdateInput>>>
}


export type MutationUpdateContactRolesArgs = {
    data?: InputMaybe<Array<InputMaybe<ContactRolesUpdateInput>>>
}


export type MutationUpdateContactsArgs = {
    data?: InputMaybe<Array<InputMaybe<ContactsUpdateInput>>>
}


export type MutationUpdateDocumentArgs = {
    data?: InputMaybe<DocumentUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateDocumentCategoriesArgs = {
    data?: InputMaybe<Array<InputMaybe<DocumentCategoriesUpdateInput>>>
}


export type MutationUpdateDocumentCategoryArgs = {
    data?: InputMaybe<DocumentCategoryUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateDocumentCategoryHistoryRecordArgs = {
    data?: InputMaybe<DocumentCategoryHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateDocumentCategoryHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<DocumentCategoryHistoryRecordsUpdateInput>>>
}


export type MutationUpdateDocumentHistoryRecordArgs = {
    data?: InputMaybe<DocumentHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateDocumentHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<DocumentHistoryRecordsUpdateInput>>>
}


export type MutationUpdateDocumentsArgs = {
    data?: InputMaybe<Array<InputMaybe<DocumentsUpdateInput>>>
}


export type MutationUpdateExternalTokenAccessRightArgs = {
    data?: InputMaybe<ExternalTokenAccessRightUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateExternalTokenAccessRightHistoryRecordArgs = {
    data?: InputMaybe<ExternalTokenAccessRightHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateExternalTokenAccessRightHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<ExternalTokenAccessRightHistoryRecordsUpdateInput>>>
}


export type MutationUpdateExternalTokenAccessRightsArgs = {
    data?: InputMaybe<Array<InputMaybe<ExternalTokenAccessRightsUpdateInput>>>
}


export type MutationUpdateIncidentArgs = {
    data?: InputMaybe<IncidentUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateIncidentChangeArgs = {
    data?: InputMaybe<IncidentChangeUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateIncidentChangesArgs = {
    data?: InputMaybe<Array<InputMaybe<IncidentChangesUpdateInput>>>
}


export type MutationUpdateIncidentClassifierArgs = {
    data?: InputMaybe<IncidentClassifierUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateIncidentClassifierHistoryRecordArgs = {
    data?: InputMaybe<IncidentClassifierHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateIncidentClassifierHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<IncidentClassifierHistoryRecordsUpdateInput>>>
}


export type MutationUpdateIncidentClassifierIncidentArgs = {
    data?: InputMaybe<IncidentClassifierIncidentUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateIncidentClassifierIncidentHistoryRecordArgs = {
    data?: InputMaybe<IncidentClassifierIncidentHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateIncidentClassifierIncidentHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<IncidentClassifierIncidentHistoryRecordsUpdateInput>>>
}


export type MutationUpdateIncidentClassifierIncidentsArgs = {
    data?: InputMaybe<Array<InputMaybe<IncidentClassifierIncidentsUpdateInput>>>
}


export type MutationUpdateIncidentClassifiersArgs = {
    data?: InputMaybe<Array<InputMaybe<IncidentClassifiersUpdateInput>>>
}


export type MutationUpdateIncidentExportTaskArgs = {
    data?: InputMaybe<IncidentExportTaskUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateIncidentExportTaskHistoryRecordArgs = {
    data?: InputMaybe<IncidentExportTaskHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateIncidentExportTaskHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<IncidentExportTaskHistoryRecordsUpdateInput>>>
}


export type MutationUpdateIncidentExportTasksArgs = {
    data?: InputMaybe<Array<InputMaybe<IncidentExportTasksUpdateInput>>>
}


export type MutationUpdateIncidentHistoryRecordArgs = {
    data?: InputMaybe<IncidentHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateIncidentHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<IncidentHistoryRecordsUpdateInput>>>
}


export type MutationUpdateIncidentPropertiesArgs = {
    data?: InputMaybe<Array<InputMaybe<IncidentPropertiesUpdateInput>>>
}


export type MutationUpdateIncidentPropertyArgs = {
    data?: InputMaybe<IncidentPropertyUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateIncidentPropertyHistoryRecordArgs = {
    data?: InputMaybe<IncidentPropertyHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateIncidentPropertyHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<IncidentPropertyHistoryRecordsUpdateInput>>>
}


export type MutationUpdateIncidentsArgs = {
    data?: InputMaybe<Array<InputMaybe<IncidentsUpdateInput>>>
}


export type MutationUpdateInvoiceArgs = {
    data?: InputMaybe<InvoiceUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateInvoiceHistoryRecordArgs = {
    data?: InputMaybe<InvoiceHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateInvoiceHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<InvoiceHistoryRecordsUpdateInput>>>
}


export type MutationUpdateInvoicesArgs = {
    data?: InputMaybe<Array<InputMaybe<InvoicesUpdateInput>>>
}


export type MutationUpdateMarketCategoriesArgs = {
    data?: InputMaybe<Array<InputMaybe<MarketCategoriesUpdateInput>>>
}


export type MutationUpdateMarketCategoryArgs = {
    data?: InputMaybe<MarketCategoryUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateMarketCategoryHistoryRecordArgs = {
    data?: InputMaybe<MarketCategoryHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateMarketCategoryHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<MarketCategoryHistoryRecordsUpdateInput>>>
}


export type MutationUpdateMarketItemArgs = {
    data?: InputMaybe<MarketItemUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateMarketItemFileArgs = {
    data?: InputMaybe<MarketItemFileUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateMarketItemFileHistoryRecordArgs = {
    data?: InputMaybe<MarketItemFileHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateMarketItemFileHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<MarketItemFileHistoryRecordsUpdateInput>>>
}


export type MutationUpdateMarketItemFilesArgs = {
    data?: InputMaybe<Array<InputMaybe<MarketItemFilesUpdateInput>>>
}


export type MutationUpdateMarketItemHistoryRecordArgs = {
    data?: InputMaybe<MarketItemHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateMarketItemHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<MarketItemHistoryRecordsUpdateInput>>>
}


export type MutationUpdateMarketItemPriceArgs = {
    data?: InputMaybe<MarketItemPriceUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateMarketItemPriceHistoryRecordArgs = {
    data?: InputMaybe<MarketItemPriceHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateMarketItemPriceHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<MarketItemPriceHistoryRecordsUpdateInput>>>
}


export type MutationUpdateMarketItemPricesArgs = {
    data?: InputMaybe<Array<InputMaybe<MarketItemPricesUpdateInput>>>
}


export type MutationUpdateMarketItemsArgs = {
    data?: InputMaybe<Array<InputMaybe<MarketItemsUpdateInput>>>
}


export type MutationUpdateMarketPriceScopeArgs = {
    data?: InputMaybe<MarketPriceScopeUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateMarketPriceScopeHistoryRecordArgs = {
    data?: InputMaybe<MarketPriceScopeHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateMarketPriceScopeHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<MarketPriceScopeHistoryRecordsUpdateInput>>>
}


export type MutationUpdateMarketPriceScopesArgs = {
    data?: InputMaybe<Array<InputMaybe<MarketPriceScopesUpdateInput>>>
}


export type MutationUpdateMarketSettingArgs = {
    data?: InputMaybe<MarketSettingUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateMarketSettingHistoryRecordArgs = {
    data?: InputMaybe<MarketSettingHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateMarketSettingHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<MarketSettingHistoryRecordsUpdateInput>>>
}


export type MutationUpdateMarketSettingsArgs = {
    data?: InputMaybe<Array<InputMaybe<MarketSettingsUpdateInput>>>
}


export type MutationUpdateMessageArgs = {
    data?: InputMaybe<MessageUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateMessageAppBlackListArgs = {
    data?: InputMaybe<MessageAppBlackListUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateMessageAppBlackListHistoryRecordArgs = {
    data?: InputMaybe<MessageAppBlackListHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateMessageAppBlackListHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<MessageAppBlackListHistoryRecordsUpdateInput>>>
}


export type MutationUpdateMessageAppBlackListsArgs = {
    data?: InputMaybe<Array<InputMaybe<MessageAppBlackListsUpdateInput>>>
}


export type MutationUpdateMessageBatchArgs = {
    data?: InputMaybe<MessageBatchUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateMessageBatchHistoryRecordArgs = {
    data?: InputMaybe<MessageBatchHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateMessageBatchHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<MessageBatchHistoryRecordsUpdateInput>>>
}


export type MutationUpdateMessageBatchesArgs = {
    data?: InputMaybe<Array<InputMaybe<MessageBatchesUpdateInput>>>
}


export type MutationUpdateMessageHistoryRecordArgs = {
    data?: InputMaybe<MessageHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateMessageHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<MessageHistoryRecordsUpdateInput>>>
}


export type MutationUpdateMessageOrganizationBlackListArgs = {
    data?: InputMaybe<MessageOrganizationBlackListUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateMessageOrganizationBlackListHistoryRecordArgs = {
    data?: InputMaybe<MessageOrganizationBlackListHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateMessageOrganizationBlackListHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<MessageOrganizationBlackListHistoryRecordsUpdateInput>>>
}


export type MutationUpdateMessageOrganizationBlackListsArgs = {
    data?: InputMaybe<Array<InputMaybe<MessageOrganizationBlackListsUpdateInput>>>
}


export type MutationUpdateMessageUserBlackListArgs = {
    data?: InputMaybe<MessageUserBlackListUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateMessageUserBlackListHistoryRecordArgs = {
    data?: InputMaybe<MessageUserBlackListHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateMessageUserBlackListHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<MessageUserBlackListHistoryRecordsUpdateInput>>>
}


export type MutationUpdateMessageUserBlackListsArgs = {
    data?: InputMaybe<Array<InputMaybe<MessageUserBlackListsUpdateInput>>>
}


export type MutationUpdateMessagesArgs = {
    data?: InputMaybe<Array<InputMaybe<MessagesUpdateInput>>>
}


export type MutationUpdateMeterArgs = {
    data?: InputMaybe<MeterUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateMeterHistoryRecordArgs = {
    data?: InputMaybe<MeterHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateMeterHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<MeterHistoryRecordsUpdateInput>>>
}


export type MutationUpdateMeterReadingArgs = {
    data?: InputMaybe<MeterReadingUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateMeterReadingExportTaskArgs = {
    data?: InputMaybe<MeterReadingExportTaskUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateMeterReadingExportTaskHistoryRecordArgs = {
    data?: InputMaybe<MeterReadingExportTaskHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateMeterReadingExportTaskHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<MeterReadingExportTaskHistoryRecordsUpdateInput>>>
}


export type MutationUpdateMeterReadingExportTasksArgs = {
    data?: InputMaybe<Array<InputMaybe<MeterReadingExportTasksUpdateInput>>>
}


export type MutationUpdateMeterReadingFilterTemplateArgs = {
    data?: InputMaybe<MeterReadingFilterTemplateUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateMeterReadingFilterTemplateHistoryRecordArgs = {
    data?: InputMaybe<MeterReadingFilterTemplateHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateMeterReadingFilterTemplateHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<MeterReadingFilterTemplateHistoryRecordsUpdateInput>>>
}


export type MutationUpdateMeterReadingFilterTemplatesArgs = {
    data?: InputMaybe<Array<InputMaybe<MeterReadingFilterTemplatesUpdateInput>>>
}


export type MutationUpdateMeterReadingHistoryRecordArgs = {
    data?: InputMaybe<MeterReadingHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateMeterReadingHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<MeterReadingHistoryRecordsUpdateInput>>>
}


export type MutationUpdateMeterReadingSourceArgs = {
    data?: InputMaybe<MeterReadingSourceUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateMeterReadingSourceHistoryRecordArgs = {
    data?: InputMaybe<MeterReadingSourceHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateMeterReadingSourceHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<MeterReadingSourceHistoryRecordsUpdateInput>>>
}


export type MutationUpdateMeterReadingSourcesArgs = {
    data?: InputMaybe<Array<InputMaybe<MeterReadingSourcesUpdateInput>>>
}


export type MutationUpdateMeterReadingsArgs = {
    data?: InputMaybe<Array<InputMaybe<MeterReadingsUpdateInput>>>
}


export type MutationUpdateMeterReadingsImportTaskArgs = {
    data?: InputMaybe<MeterReadingsImportTaskUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateMeterReadingsImportTaskHistoryRecordArgs = {
    data?: InputMaybe<MeterReadingsImportTaskHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateMeterReadingsImportTaskHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<MeterReadingsImportTaskHistoryRecordsUpdateInput>>>
}


export type MutationUpdateMeterReadingsImportTasksArgs = {
    data?: InputMaybe<Array<InputMaybe<MeterReadingsImportTasksUpdateInput>>>
}


export type MutationUpdateMeterReportingPeriodArgs = {
    data?: InputMaybe<MeterReportingPeriodUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateMeterReportingPeriodHistoryRecordArgs = {
    data?: InputMaybe<MeterReportingPeriodHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateMeterReportingPeriodHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<MeterReportingPeriodHistoryRecordsUpdateInput>>>
}


export type MutationUpdateMeterReportingPeriodsArgs = {
    data?: InputMaybe<Array<InputMaybe<MeterReportingPeriodsUpdateInput>>>
}


export type MutationUpdateMeterResourceArgs = {
    data?: InputMaybe<MeterResourceUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateMeterResourceHistoryRecordArgs = {
    data?: InputMaybe<MeterResourceHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateMeterResourceHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<MeterResourceHistoryRecordsUpdateInput>>>
}


export type MutationUpdateMeterResourceOwnerArgs = {
    data?: InputMaybe<MeterResourceOwnerUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateMeterResourceOwnerHistoryRecordArgs = {
    data?: InputMaybe<MeterResourceOwnerHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateMeterResourceOwnerHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<MeterResourceOwnerHistoryRecordsUpdateInput>>>
}


export type MutationUpdateMeterResourceOwnersArgs = {
    data?: InputMaybe<Array<InputMaybe<MeterResourceOwnersUpdateInput>>>
}


export type MutationUpdateMeterResourcesArgs = {
    data?: InputMaybe<Array<InputMaybe<MeterResourcesUpdateInput>>>
}


export type MutationUpdateMetersArgs = {
    data?: InputMaybe<Array<InputMaybe<MetersUpdateInput>>>
}


export type MutationUpdateMobileFeatureConfigArgs = {
    data?: InputMaybe<MobileFeatureConfigUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateMobileFeatureConfigHistoryRecordArgs = {
    data?: InputMaybe<MobileFeatureConfigHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateMobileFeatureConfigHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<MobileFeatureConfigHistoryRecordsUpdateInput>>>
}


export type MutationUpdateMobileFeatureConfigsArgs = {
    data?: InputMaybe<Array<InputMaybe<MobileFeatureConfigsUpdateInput>>>
}


export type MutationUpdateMultiPaymentArgs = {
    data?: InputMaybe<MultiPaymentUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateMultiPaymentHistoryRecordArgs = {
    data?: InputMaybe<MultiPaymentHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateMultiPaymentHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<MultiPaymentHistoryRecordsUpdateInput>>>
}


export type MutationUpdateMultiPaymentsArgs = {
    data?: InputMaybe<Array<InputMaybe<MultiPaymentsUpdateInput>>>
}


export type MutationUpdateNewsItemArgs = {
    data?: InputMaybe<NewsItemUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateNewsItemHistoryRecordArgs = {
    data?: InputMaybe<NewsItemHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateNewsItemHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<NewsItemHistoryRecordsUpdateInput>>>
}


export type MutationUpdateNewsItemRecipientsExportTaskArgs = {
    data?: InputMaybe<NewsItemRecipientsExportTaskUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateNewsItemRecipientsExportTaskHistoryRecordArgs = {
    data?: InputMaybe<NewsItemRecipientsExportTaskHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateNewsItemRecipientsExportTaskHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<NewsItemRecipientsExportTaskHistoryRecordsUpdateInput>>>
}


export type MutationUpdateNewsItemRecipientsExportTasksArgs = {
    data?: InputMaybe<Array<InputMaybe<NewsItemRecipientsExportTasksUpdateInput>>>
}


export type MutationUpdateNewsItemScopeArgs = {
    data?: InputMaybe<NewsItemScopeUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateNewsItemScopeHistoryRecordArgs = {
    data?: InputMaybe<NewsItemScopeHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateNewsItemScopeHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<NewsItemScopeHistoryRecordsUpdateInput>>>
}


export type MutationUpdateNewsItemScopesArgs = {
    data?: InputMaybe<Array<InputMaybe<NewsItemScopesUpdateInput>>>
}


export type MutationUpdateNewsItemSharingArgs = {
    data?: InputMaybe<NewsItemSharingUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateNewsItemSharingHistoryRecordArgs = {
    data?: InputMaybe<NewsItemSharingHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateNewsItemSharingHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<NewsItemSharingHistoryRecordsUpdateInput>>>
}


export type MutationUpdateNewsItemSharingsArgs = {
    data?: InputMaybe<Array<InputMaybe<NewsItemSharingsUpdateInput>>>
}


export type MutationUpdateNewsItemTemplateArgs = {
    data?: InputMaybe<NewsItemTemplateUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateNewsItemTemplateHistoryRecordArgs = {
    data?: InputMaybe<NewsItemTemplateHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateNewsItemTemplateHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<NewsItemTemplateHistoryRecordsUpdateInput>>>
}


export type MutationUpdateNewsItemTemplatesArgs = {
    data?: InputMaybe<Array<InputMaybe<NewsItemTemplatesUpdateInput>>>
}


export type MutationUpdateNewsItemUserReadArgs = {
    data?: InputMaybe<NewsItemUserReadUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateNewsItemUserReadHistoryRecordArgs = {
    data?: InputMaybe<NewsItemUserReadHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateNewsItemUserReadHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<NewsItemUserReadHistoryRecordsUpdateInput>>>
}


export type MutationUpdateNewsItemUserReadsArgs = {
    data?: InputMaybe<Array<InputMaybe<NewsItemUserReadsUpdateInput>>>
}


export type MutationUpdateNewsItemsArgs = {
    data?: InputMaybe<Array<InputMaybe<NewsItemsUpdateInput>>>
}


export type MutationUpdateNotificationAnonymousSettingArgs = {
    data?: InputMaybe<NotificationAnonymousSettingUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateNotificationAnonymousSettingHistoryRecordArgs = {
    data?: InputMaybe<NotificationAnonymousSettingHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateNotificationAnonymousSettingHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<NotificationAnonymousSettingHistoryRecordsUpdateInput>>>
}


export type MutationUpdateNotificationAnonymousSettingsArgs = {
    data?: InputMaybe<Array<InputMaybe<NotificationAnonymousSettingsUpdateInput>>>
}


export type MutationUpdateNotificationUserSettingArgs = {
    data?: InputMaybe<NotificationUserSettingUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateNotificationUserSettingHistoryRecordArgs = {
    data?: InputMaybe<NotificationUserSettingHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateNotificationUserSettingHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<NotificationUserSettingHistoryRecordsUpdateInput>>>
}


export type MutationUpdateNotificationUserSettingsArgs = {
    data?: InputMaybe<Array<InputMaybe<NotificationUserSettingsUpdateInput>>>
}


export type MutationUpdateOidcClientArgs = {
    data?: InputMaybe<OidcClientUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateOidcClientHistoryRecordArgs = {
    data?: InputMaybe<OidcClientHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateOidcClientHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<OidcClientHistoryRecordsUpdateInput>>>
}


export type MutationUpdateOidcClientsArgs = {
    data?: InputMaybe<Array<InputMaybe<OidcClientsUpdateInput>>>
}


export type MutationUpdateOrganizationArgs = {
    data?: InputMaybe<OrganizationUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateOrganizationEmployeeArgs = {
    data?: InputMaybe<OrganizationEmployeeUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateOrganizationEmployeeHistoryRecordArgs = {
    data?: InputMaybe<OrganizationEmployeeHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateOrganizationEmployeeHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<OrganizationEmployeeHistoryRecordsUpdateInput>>>
}


export type MutationUpdateOrganizationEmployeeRoleArgs = {
    data?: InputMaybe<OrganizationEmployeeRoleUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateOrganizationEmployeeRoleHistoryRecordArgs = {
    data?: InputMaybe<OrganizationEmployeeRoleHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateOrganizationEmployeeRoleHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<OrganizationEmployeeRoleHistoryRecordsUpdateInput>>>
}


export type MutationUpdateOrganizationEmployeeRolesArgs = {
    data?: InputMaybe<Array<InputMaybe<OrganizationEmployeeRolesUpdateInput>>>
}


export type MutationUpdateOrganizationEmployeeSpecializationArgs = {
    data?: InputMaybe<OrganizationEmployeeSpecializationUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateOrganizationEmployeeSpecializationHistoryRecordArgs = {
    data?: InputMaybe<OrganizationEmployeeSpecializationHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateOrganizationEmployeeSpecializationHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<OrganizationEmployeeSpecializationHistoryRecordsUpdateInput>>>
}


export type MutationUpdateOrganizationEmployeeSpecializationsArgs = {
    data?: InputMaybe<Array<InputMaybe<OrganizationEmployeeSpecializationsUpdateInput>>>
}


export type MutationUpdateOrganizationEmployeesArgs = {
    data?: InputMaybe<Array<InputMaybe<OrganizationEmployeesUpdateInput>>>
}


export type MutationUpdateOrganizationHistoryRecordArgs = {
    data?: InputMaybe<OrganizationHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateOrganizationHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<OrganizationHistoryRecordsUpdateInput>>>
}


export type MutationUpdateOrganizationLinkArgs = {
    data?: InputMaybe<OrganizationLinkUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateOrganizationLinkHistoryRecordArgs = {
    data?: InputMaybe<OrganizationLinkHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateOrganizationLinkHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<OrganizationLinkHistoryRecordsUpdateInput>>>
}


export type MutationUpdateOrganizationLinksArgs = {
    data?: InputMaybe<Array<InputMaybe<OrganizationLinksUpdateInput>>>
}


export type MutationUpdateOrganizationsArgs = {
    data?: InputMaybe<Array<InputMaybe<OrganizationsUpdateInput>>>
}


export type MutationUpdatePaymentArgs = {
    data?: InputMaybe<PaymentUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdatePaymentHistoryRecordArgs = {
    data?: InputMaybe<PaymentHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdatePaymentHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<PaymentHistoryRecordsUpdateInput>>>
}


export type MutationUpdatePaymentsArgs = {
    data?: InputMaybe<Array<InputMaybe<PaymentsUpdateInput>>>
}


export type MutationUpdatePaymentsFilterTemplateArgs = {
    data?: InputMaybe<PaymentsFilterTemplateUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdatePaymentsFilterTemplateHistoryRecordArgs = {
    data?: InputMaybe<PaymentsFilterTemplateHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdatePaymentsFilterTemplateHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<PaymentsFilterTemplateHistoryRecordsUpdateInput>>>
}


export type MutationUpdatePaymentsFilterTemplatesArgs = {
    data?: InputMaybe<Array<InputMaybe<PaymentsFilterTemplatesUpdateInput>>>
}


export type MutationUpdatePropertiesArgs = {
    data?: InputMaybe<Array<InputMaybe<PropertiesUpdateInput>>>
}


export type MutationUpdatePropertyArgs = {
    data?: InputMaybe<PropertyUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdatePropertyHistoryRecordArgs = {
    data?: InputMaybe<PropertyHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdatePropertyHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<PropertyHistoryRecordsUpdateInput>>>
}


export type MutationUpdatePropertyMeterArgs = {
    data?: InputMaybe<PropertyMeterUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdatePropertyMeterHistoryRecordArgs = {
    data?: InputMaybe<PropertyMeterHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdatePropertyMeterHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<PropertyMeterHistoryRecordsUpdateInput>>>
}


export type MutationUpdatePropertyMeterReadingArgs = {
    data?: InputMaybe<PropertyMeterReadingUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdatePropertyMeterReadingHistoryRecordArgs = {
    data?: InputMaybe<PropertyMeterReadingHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdatePropertyMeterReadingHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<PropertyMeterReadingHistoryRecordsUpdateInput>>>
}


export type MutationUpdatePropertyMeterReadingsArgs = {
    data?: InputMaybe<Array<InputMaybe<PropertyMeterReadingsUpdateInput>>>
}


export type MutationUpdatePropertyMetersArgs = {
    data?: InputMaybe<Array<InputMaybe<PropertyMetersUpdateInput>>>
}


export type MutationUpdatePropertyScopeArgs = {
    data?: InputMaybe<PropertyScopeUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdatePropertyScopeHistoryRecordArgs = {
    data?: InputMaybe<PropertyScopeHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdatePropertyScopeHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<PropertyScopeHistoryRecordsUpdateInput>>>
}


export type MutationUpdatePropertyScopeOrganizationEmployeeArgs = {
    data?: InputMaybe<PropertyScopeOrganizationEmployeeUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdatePropertyScopeOrganizationEmployeeHistoryRecordArgs = {
    data?: InputMaybe<PropertyScopeOrganizationEmployeeHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdatePropertyScopeOrganizationEmployeeHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<PropertyScopeOrganizationEmployeeHistoryRecordsUpdateInput>>>
}


export type MutationUpdatePropertyScopeOrganizationEmployeesArgs = {
    data?: InputMaybe<Array<InputMaybe<PropertyScopeOrganizationEmployeesUpdateInput>>>
}


export type MutationUpdatePropertyScopePropertiesArgs = {
    data?: InputMaybe<Array<InputMaybe<PropertyScopePropertiesUpdateInput>>>
}


export type MutationUpdatePropertyScopePropertyArgs = {
    data?: InputMaybe<PropertyScopePropertyUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdatePropertyScopePropertyHistoryRecordArgs = {
    data?: InputMaybe<PropertyScopePropertyHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdatePropertyScopePropertyHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<PropertyScopePropertyHistoryRecordsUpdateInput>>>
}


export type MutationUpdatePropertyScopesArgs = {
    data?: InputMaybe<Array<InputMaybe<PropertyScopesUpdateInput>>>
}


export type MutationUpdateRecurrentPaymentArgs = {
    data?: InputMaybe<RecurrentPaymentUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateRecurrentPaymentContextArgs = {
    data?: InputMaybe<RecurrentPaymentContextUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateRecurrentPaymentContextHistoryRecordArgs = {
    data?: InputMaybe<RecurrentPaymentContextHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateRecurrentPaymentContextHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<RecurrentPaymentContextHistoryRecordsUpdateInput>>>
}


export type MutationUpdateRecurrentPaymentContextsArgs = {
    data?: InputMaybe<Array<InputMaybe<RecurrentPaymentContextsUpdateInput>>>
}


export type MutationUpdateRecurrentPaymentHistoryRecordArgs = {
    data?: InputMaybe<RecurrentPaymentHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateRecurrentPaymentHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<RecurrentPaymentHistoryRecordsUpdateInput>>>
}


export type MutationUpdateRecurrentPaymentsArgs = {
    data?: InputMaybe<Array<InputMaybe<RecurrentPaymentsUpdateInput>>>
}


export type MutationUpdateRemoteClientArgs = {
    data?: InputMaybe<RemoteClientUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateRemoteClientHistoryRecordArgs = {
    data?: InputMaybe<RemoteClientHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateRemoteClientHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<RemoteClientHistoryRecordsUpdateInput>>>
}


export type MutationUpdateRemoteClientsArgs = {
    data?: InputMaybe<Array<InputMaybe<RemoteClientsUpdateInput>>>
}


export type MutationUpdateResidentArgs = {
    data?: InputMaybe<ResidentUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateResidentHistoryRecordArgs = {
    data?: InputMaybe<ResidentHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateResidentHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<ResidentHistoryRecordsUpdateInput>>>
}


export type MutationUpdateResidentsArgs = {
    data?: InputMaybe<Array<InputMaybe<ResidentsUpdateInput>>>
}


export type MutationUpdateServiceConsumerArgs = {
    data?: InputMaybe<ServiceConsumerUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateServiceConsumerHistoryRecordArgs = {
    data?: InputMaybe<ServiceConsumerHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateServiceConsumerHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<ServiceConsumerHistoryRecordsUpdateInput>>>
}


export type MutationUpdateServiceConsumersArgs = {
    data?: InputMaybe<Array<InputMaybe<ServiceConsumersUpdateInput>>>
}


export type MutationUpdateServiceSubscriptionArgs = {
    data?: InputMaybe<ServiceSubscriptionUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateServiceSubscriptionHistoryRecordArgs = {
    data?: InputMaybe<ServiceSubscriptionHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateServiceSubscriptionHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<ServiceSubscriptionHistoryRecordsUpdateInput>>>
}


export type MutationUpdateServiceSubscriptionsArgs = {
    data?: InputMaybe<Array<InputMaybe<ServiceSubscriptionsUpdateInput>>>
}


export type MutationUpdateTelegramUserChatArgs = {
    data?: InputMaybe<TelegramUserChatUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateTelegramUserChatHistoryRecordArgs = {
    data?: InputMaybe<TelegramUserChatHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateTelegramUserChatHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<TelegramUserChatHistoryRecordsUpdateInput>>>
}


export type MutationUpdateTelegramUserChatsArgs = {
    data?: InputMaybe<Array<InputMaybe<TelegramUserChatsUpdateInput>>>
}


export type MutationUpdateTicketArgs = {
    data?: InputMaybe<TicketUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateTicketAutoAssignmentArgs = {
    data?: InputMaybe<TicketAutoAssignmentUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateTicketAutoAssignmentHistoryRecordArgs = {
    data?: InputMaybe<TicketAutoAssignmentHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateTicketAutoAssignmentHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketAutoAssignmentHistoryRecordsUpdateInput>>>
}


export type MutationUpdateTicketAutoAssignmentsArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketAutoAssignmentsUpdateInput>>>
}


export type MutationUpdateTicketCategoryClassifierArgs = {
    data?: InputMaybe<TicketCategoryClassifierUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateTicketCategoryClassifierHistoryRecordArgs = {
    data?: InputMaybe<TicketCategoryClassifierHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateTicketCategoryClassifierHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketCategoryClassifierHistoryRecordsUpdateInput>>>
}


export type MutationUpdateTicketCategoryClassifiersArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketCategoryClassifiersUpdateInput>>>
}


export type MutationUpdateTicketChangeArgs = {
    data?: InputMaybe<TicketChangeUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateTicketChangesArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketChangesUpdateInput>>>
}


export type MutationUpdateTicketClassifierArgs = {
    data?: InputMaybe<TicketClassifierUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateTicketClassifierHistoryRecordArgs = {
    data?: InputMaybe<TicketClassifierHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateTicketClassifierHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketClassifierHistoryRecordsUpdateInput>>>
}


export type MutationUpdateTicketClassifiersArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketClassifiersUpdateInput>>>
}


export type MutationUpdateTicketCommentArgs = {
    data?: InputMaybe<TicketCommentUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateTicketCommentFileArgs = {
    data?: InputMaybe<TicketCommentFileUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateTicketCommentFileHistoryRecordArgs = {
    data?: InputMaybe<TicketCommentFileHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateTicketCommentFileHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketCommentFileHistoryRecordsUpdateInput>>>
}


export type MutationUpdateTicketCommentFilesArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketCommentFilesUpdateInput>>>
}


export type MutationUpdateTicketCommentHistoryRecordArgs = {
    data?: InputMaybe<TicketCommentHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateTicketCommentHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketCommentHistoryRecordsUpdateInput>>>
}


export type MutationUpdateTicketCommentsArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketCommentsUpdateInput>>>
}


export type MutationUpdateTicketDocumentGenerationTaskArgs = {
    data?: InputMaybe<TicketDocumentGenerationTaskUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateTicketDocumentGenerationTaskHistoryRecordArgs = {
    data?: InputMaybe<TicketDocumentGenerationTaskHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateTicketDocumentGenerationTaskHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketDocumentGenerationTaskHistoryRecordsUpdateInput>>>
}


export type MutationUpdateTicketDocumentGenerationTasksArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketDocumentGenerationTasksUpdateInput>>>
}


export type MutationUpdateTicketExportTaskArgs = {
    data?: InputMaybe<TicketExportTaskUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateTicketExportTaskHistoryRecordArgs = {
    data?: InputMaybe<TicketExportTaskHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateTicketExportTaskHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketExportTaskHistoryRecordsUpdateInput>>>
}


export type MutationUpdateTicketExportTasksArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketExportTasksUpdateInput>>>
}


export type MutationUpdateTicketFileArgs = {
    data?: InputMaybe<TicketFileUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateTicketFileHistoryRecordArgs = {
    data?: InputMaybe<TicketFileHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateTicketFileHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketFileHistoryRecordsUpdateInput>>>
}


export type MutationUpdateTicketFilesArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketFilesUpdateInput>>>
}


export type MutationUpdateTicketFilterTemplateArgs = {
    data?: InputMaybe<TicketFilterTemplateUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateTicketFilterTemplateHistoryRecordArgs = {
    data?: InputMaybe<TicketFilterTemplateHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateTicketFilterTemplateHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketFilterTemplateHistoryRecordsUpdateInput>>>
}


export type MutationUpdateTicketFilterTemplatesArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketFilterTemplatesUpdateInput>>>
}


export type MutationUpdateTicketHistoryRecordArgs = {
    data?: InputMaybe<TicketHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateTicketHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketHistoryRecordsUpdateInput>>>
}


export type MutationUpdateTicketOrganizationSettingArgs = {
    data?: InputMaybe<TicketOrganizationSettingUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateTicketOrganizationSettingHistoryRecordArgs = {
    data?: InputMaybe<TicketOrganizationSettingHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateTicketOrganizationSettingHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketOrganizationSettingHistoryRecordsUpdateInput>>>
}


export type MutationUpdateTicketOrganizationSettingsArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketOrganizationSettingsUpdateInput>>>
}


export type MutationUpdateTicketPlaceClassifierArgs = {
    data?: InputMaybe<TicketPlaceClassifierUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateTicketPlaceClassifierHistoryRecordArgs = {
    data?: InputMaybe<TicketPlaceClassifierHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateTicketPlaceClassifierHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketPlaceClassifierHistoryRecordsUpdateInput>>>
}


export type MutationUpdateTicketPlaceClassifiersArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketPlaceClassifiersUpdateInput>>>
}


export type MutationUpdateTicketProblemClassifierArgs = {
    data?: InputMaybe<TicketProblemClassifierUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateTicketProblemClassifierHistoryRecordArgs = {
    data?: InputMaybe<TicketProblemClassifierHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateTicketProblemClassifierHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketProblemClassifierHistoryRecordsUpdateInput>>>
}


export type MutationUpdateTicketProblemClassifiersArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketProblemClassifiersUpdateInput>>>
}


export type MutationUpdateTicketPropertyHintArgs = {
    data?: InputMaybe<TicketPropertyHintUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateTicketPropertyHintHistoryRecordArgs = {
    data?: InputMaybe<TicketPropertyHintHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateTicketPropertyHintHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketPropertyHintHistoryRecordsUpdateInput>>>
}


export type MutationUpdateTicketPropertyHintPropertiesArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketPropertyHintPropertiesUpdateInput>>>
}


export type MutationUpdateTicketPropertyHintPropertyArgs = {
    data?: InputMaybe<TicketPropertyHintPropertyUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateTicketPropertyHintPropertyHistoryRecordArgs = {
    data?: InputMaybe<TicketPropertyHintPropertyHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateTicketPropertyHintPropertyHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketPropertyHintPropertyHistoryRecordsUpdateInput>>>
}


export type MutationUpdateTicketPropertyHintsArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketPropertyHintsUpdateInput>>>
}


export type MutationUpdateTicketSourceArgs = {
    data?: InputMaybe<TicketSourceUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateTicketSourceHistoryRecordArgs = {
    data?: InputMaybe<TicketSourceHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateTicketSourceHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketSourceHistoryRecordsUpdateInput>>>
}


export type MutationUpdateTicketSourcesArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketSourcesUpdateInput>>>
}


export type MutationUpdateTicketStatusArgs = {
    data?: InputMaybe<TicketStatusUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateTicketStatusHistoryRecordArgs = {
    data?: InputMaybe<TicketStatusHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateTicketStatusHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketStatusHistoryRecordsUpdateInput>>>
}


export type MutationUpdateTicketStatusesArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketStatusesUpdateInput>>>
}


export type MutationUpdateTicketsArgs = {
    data?: InputMaybe<Array<InputMaybe<TicketsUpdateInput>>>
}


export type MutationUpdateTourStepArgs = {
    data?: InputMaybe<TourStepUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateTourStepHistoryRecordArgs = {
    data?: InputMaybe<TourStepHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateTourStepHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<TourStepHistoryRecordsUpdateInput>>>
}


export type MutationUpdateTourStepsArgs = {
    data?: InputMaybe<Array<InputMaybe<TourStepsUpdateInput>>>
}


export type MutationUpdateUserArgs = {
    data?: InputMaybe<UserUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateUserExternalIdentitiesArgs = {
    data?: InputMaybe<Array<InputMaybe<UserExternalIdentitiesUpdateInput>>>
}


export type MutationUpdateUserExternalIdentityArgs = {
    data?: InputMaybe<UserExternalIdentityUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateUserExternalIdentityHistoryRecordArgs = {
    data?: InputMaybe<UserExternalIdentityHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateUserExternalIdentityHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<UserExternalIdentityHistoryRecordsUpdateInput>>>
}


export type MutationUpdateUserFavoriteTicketArgs = {
    data?: InputMaybe<UserFavoriteTicketUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateUserFavoriteTicketHistoryRecordArgs = {
    data?: InputMaybe<UserFavoriteTicketHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateUserFavoriteTicketHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<UserFavoriteTicketHistoryRecordsUpdateInput>>>
}


export type MutationUpdateUserFavoriteTicketsArgs = {
    data?: InputMaybe<Array<InputMaybe<UserFavoriteTicketsUpdateInput>>>
}


export type MutationUpdateUserHelpRequestArgs = {
    data?: InputMaybe<UserHelpRequestUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateUserHelpRequestFileArgs = {
    data?: InputMaybe<UserHelpRequestFileUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateUserHelpRequestFileHistoryRecordArgs = {
    data?: InputMaybe<UserHelpRequestFileHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateUserHelpRequestFileHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<UserHelpRequestFileHistoryRecordsUpdateInput>>>
}


export type MutationUpdateUserHelpRequestFilesArgs = {
    data?: InputMaybe<Array<InputMaybe<UserHelpRequestFilesUpdateInput>>>
}


export type MutationUpdateUserHelpRequestHistoryRecordArgs = {
    data?: InputMaybe<UserHelpRequestHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateUserHelpRequestHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<UserHelpRequestHistoryRecordsUpdateInput>>>
}


export type MutationUpdateUserHelpRequestsArgs = {
    data?: InputMaybe<Array<InputMaybe<UserHelpRequestsUpdateInput>>>
}


export type MutationUpdateUserHistoryRecordArgs = {
    data?: InputMaybe<UserHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateUserHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<UserHistoryRecordsUpdateInput>>>
}


export type MutationUpdateUserRightsSetArgs = {
    data?: InputMaybe<UserRightsSetUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateUserRightsSetHistoryRecordArgs = {
    data?: InputMaybe<UserRightsSetHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateUserRightsSetHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<UserRightsSetHistoryRecordsUpdateInput>>>
}


export type MutationUpdateUserRightsSetsArgs = {
    data?: InputMaybe<Array<InputMaybe<UserRightsSetsUpdateInput>>>
}


export type MutationUpdateUserTicketCommentReadTimeArgs = {
    data?: InputMaybe<UserTicketCommentReadTimeUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateUserTicketCommentReadTimeHistoryRecordArgs = {
    data?: InputMaybe<UserTicketCommentReadTimeHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateUserTicketCommentReadTimeHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<UserTicketCommentReadTimeHistoryRecordsUpdateInput>>>
}


export type MutationUpdateUserTicketCommentReadTimesArgs = {
    data?: InputMaybe<Array<InputMaybe<UserTicketCommentReadTimesUpdateInput>>>
}


export type MutationUpdateUsersArgs = {
    data?: InputMaybe<Array<InputMaybe<UsersUpdateInput>>>
}


export type MutationUpdateWebhookArgs = {
    data?: InputMaybe<WebhookUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateWebhookHistoryRecordArgs = {
    data?: InputMaybe<WebhookHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateWebhookHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<WebhookHistoryRecordsUpdateInput>>>
}


export type MutationUpdateWebhookSubscriptionArgs = {
    data?: InputMaybe<WebhookSubscriptionUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateWebhookSubscriptionHistoryRecordArgs = {
    data?: InputMaybe<WebhookSubscriptionHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}


export type MutationUpdateWebhookSubscriptionHistoryRecordsArgs = {
    data?: InputMaybe<Array<InputMaybe<WebhookSubscriptionHistoryRecordsUpdateInput>>>
}


export type MutationUpdateWebhookSubscriptionsArgs = {
    data?: InputMaybe<Array<InputMaybe<WebhookSubscriptionsUpdateInput>>>
}


export type MutationUpdateWebhooksArgs = {
    data?: InputMaybe<Array<InputMaybe<WebhooksUpdateInput>>>
}


export type MutationValidateQrCodeArgs = {
    data: ValidateQrCodeInput
}

/**  The news item created by the organization to show on resident's mobile devices  */
export type NewsItem = {
    __typename?: 'NewsItem'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the NewsItem List config, or
   *  2. As an alias to the field set on 'labelField' in the NewsItem List config, or
   *  3. As an alias to a 'name' field on the NewsItem List (if one exists), or
   *  4. As an alias to the 'id' field on the NewsItem List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    _scopesMeta?: Maybe<_QueryMeta>
    /**  The news item main body  */
    body?: Maybe<Scalars['String']['output']>
    /**
   *  Returns the number of scopes that are specified for sending the news, and also the first two of them.
   * Used to reduce requests for get of scopes in the UI
   */
    compactScopes?: Maybe<ShortScopesField>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  Shows if the news item is ready to be shown and send to residents  */
    isPublished?: Maybe<Scalars['Boolean']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  The news item number  */
    number?: Maybe<Scalars['Int']['output']>
    /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
    organization?: Maybe<Organization>
    /**  The date when the news item was published. It is an auto-Calculated field.  */
    publishedAt?: Maybe<Scalars['String']['output']>
    scopes: Array<NewsItemScope>
    /**
   *  Start time for sending notifications.
   * If the value is null, but the “isPublished” flag is true, then the "sendAt" value will be automatically set to "publishedAt" + 15 sec
   */
    sendAt?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  The date when newsItem was sent to residents. This is an internal field used to detect was the message has already been sent or not.  */
    sentAt?: Maybe<Scalars['String']['output']>
    /**  The news item title  */
    title?: Maybe<Scalars['String']['output']>
    /**  The news item type. The `common` type generates push messages no more than 1 per hour per single user. Also, the resident may disable push messages for this type. The `emergency` type will always be accompanied by a push message and shown at the top of the news list. Also, this type always has a news item validity date.  */
    type?: Maybe<NewsItemTypeType>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
    /**  Date before which the news item makes sense  */
    validBefore?: Maybe<Scalars['String']['output']>
}


/**  The news item created by the organization to show on resident's mobile devices  */
export type NewsItem_ScopesMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortNewsItemScopesBy>>
    where?: InputMaybe<NewsItemScopeWhereInput>
}


/**  The news item created by the organization to show on resident's mobile devices  */
export type NewsItemScopesArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortNewsItemScopesBy>>
    where?: InputMaybe<NewsItemScopeWhereInput>
}

export type NewsItemCreateInput = {
    body?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    isPublished?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    number?: InputMaybe<Scalars['Int']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    publishedAt?: InputMaybe<Scalars['String']['input']>
    scopes?: InputMaybe<NewsItemScopeRelateToManyInput>
    sendAt?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sentAt?: InputMaybe<Scalars['String']['input']>
    title?: InputMaybe<Scalars['String']['input']>
    type?: InputMaybe<NewsItemTypeType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
    validBefore?: InputMaybe<Scalars['String']['input']>
}

/**  A keystone list  */
export type NewsItemHistoryRecord = {
    __typename?: 'NewsItemHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the NewsItemHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the NewsItemHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the NewsItemHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the NewsItemHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    body?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<NewsItemHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    isPublished?: Maybe<Scalars['Boolean']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    number?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    publishedAt?: Maybe<Scalars['String']['output']>
    sendAt?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    sentAt?: Maybe<Scalars['String']['output']>
    title?: Maybe<Scalars['String']['output']>
    type?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
    validBefore?: Maybe<Scalars['String']['output']>
}

export type NewsItemHistoryRecordCreateInput = {
    body?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<NewsItemHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    isPublished?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    number?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    publishedAt?: InputMaybe<Scalars['String']['input']>
    sendAt?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sentAt?: InputMaybe<Scalars['String']['input']>
    title?: InputMaybe<Scalars['String']['input']>
    type?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    validBefore?: InputMaybe<Scalars['String']['input']>
}

export enum NewsItemHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type NewsItemHistoryRecordUpdateInput = {
    body?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<NewsItemHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    isPublished?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    number?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    publishedAt?: InputMaybe<Scalars['String']['input']>
    sendAt?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sentAt?: InputMaybe<Scalars['String']['input']>
    title?: InputMaybe<Scalars['String']['input']>
    type?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    validBefore?: InputMaybe<Scalars['String']['input']>
}

export type NewsItemHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<NewsItemHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<NewsItemHistoryRecordWhereInput>>>
    body?: InputMaybe<Scalars['String']['input']>
    body_contains?: InputMaybe<Scalars['String']['input']>
    body_contains_i?: InputMaybe<Scalars['String']['input']>
    body_ends_with?: InputMaybe<Scalars['String']['input']>
    body_ends_with_i?: InputMaybe<Scalars['String']['input']>
    body_i?: InputMaybe<Scalars['String']['input']>
    body_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    body_not?: InputMaybe<Scalars['String']['input']>
    body_not_contains?: InputMaybe<Scalars['String']['input']>
    body_not_contains_i?: InputMaybe<Scalars['String']['input']>
    body_not_ends_with?: InputMaybe<Scalars['String']['input']>
    body_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    body_not_i?: InputMaybe<Scalars['String']['input']>
    body_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    body_not_starts_with?: InputMaybe<Scalars['String']['input']>
    body_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    body_starts_with?: InputMaybe<Scalars['String']['input']>
    body_starts_with_i?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<NewsItemHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<NewsItemHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<NewsItemHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<NewsItemHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    isPublished?: InputMaybe<Scalars['Boolean']['input']>
    isPublished_not?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    number?: InputMaybe<Scalars['JSON']['input']>
    number_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    number_not?: InputMaybe<Scalars['JSON']['input']>
    number_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    publishedAt?: InputMaybe<Scalars['String']['input']>
    publishedAt_gt?: InputMaybe<Scalars['String']['input']>
    publishedAt_gte?: InputMaybe<Scalars['String']['input']>
    publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    publishedAt_lt?: InputMaybe<Scalars['String']['input']>
    publishedAt_lte?: InputMaybe<Scalars['String']['input']>
    publishedAt_not?: InputMaybe<Scalars['String']['input']>
    publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sendAt?: InputMaybe<Scalars['String']['input']>
    sendAt_gt?: InputMaybe<Scalars['String']['input']>
    sendAt_gte?: InputMaybe<Scalars['String']['input']>
    sendAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sendAt_lt?: InputMaybe<Scalars['String']['input']>
    sendAt_lte?: InputMaybe<Scalars['String']['input']>
    sendAt_not?: InputMaybe<Scalars['String']['input']>
    sendAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sentAt?: InputMaybe<Scalars['String']['input']>
    sentAt_gt?: InputMaybe<Scalars['String']['input']>
    sentAt_gte?: InputMaybe<Scalars['String']['input']>
    sentAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sentAt_lt?: InputMaybe<Scalars['String']['input']>
    sentAt_lte?: InputMaybe<Scalars['String']['input']>
    sentAt_not?: InputMaybe<Scalars['String']['input']>
    sentAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    title?: InputMaybe<Scalars['String']['input']>
    title_contains?: InputMaybe<Scalars['String']['input']>
    title_contains_i?: InputMaybe<Scalars['String']['input']>
    title_ends_with?: InputMaybe<Scalars['String']['input']>
    title_ends_with_i?: InputMaybe<Scalars['String']['input']>
    title_i?: InputMaybe<Scalars['String']['input']>
    title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    title_not?: InputMaybe<Scalars['String']['input']>
    title_not_contains?: InputMaybe<Scalars['String']['input']>
    title_not_contains_i?: InputMaybe<Scalars['String']['input']>
    title_not_ends_with?: InputMaybe<Scalars['String']['input']>
    title_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    title_not_i?: InputMaybe<Scalars['String']['input']>
    title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    title_not_starts_with?: InputMaybe<Scalars['String']['input']>
    title_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    title_starts_with?: InputMaybe<Scalars['String']['input']>
    title_starts_with_i?: InputMaybe<Scalars['String']['input']>
    type?: InputMaybe<Scalars['String']['input']>
    type_contains?: InputMaybe<Scalars['String']['input']>
    type_contains_i?: InputMaybe<Scalars['String']['input']>
    type_ends_with?: InputMaybe<Scalars['String']['input']>
    type_ends_with_i?: InputMaybe<Scalars['String']['input']>
    type_i?: InputMaybe<Scalars['String']['input']>
    type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    type_not?: InputMaybe<Scalars['String']['input']>
    type_not_contains?: InputMaybe<Scalars['String']['input']>
    type_not_contains_i?: InputMaybe<Scalars['String']['input']>
    type_not_ends_with?: InputMaybe<Scalars['String']['input']>
    type_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    type_not_i?: InputMaybe<Scalars['String']['input']>
    type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    type_not_starts_with?: InputMaybe<Scalars['String']['input']>
    type_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    type_starts_with?: InputMaybe<Scalars['String']['input']>
    type_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    validBefore?: InputMaybe<Scalars['String']['input']>
    validBefore_gt?: InputMaybe<Scalars['String']['input']>
    validBefore_gte?: InputMaybe<Scalars['String']['input']>
    validBefore_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    validBefore_lt?: InputMaybe<Scalars['String']['input']>
    validBefore_lte?: InputMaybe<Scalars['String']['input']>
    validBefore_not?: InputMaybe<Scalars['String']['input']>
    validBefore_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type NewsItemHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type NewsItemHistoryRecordsCreateInput = {
    data?: InputMaybe<NewsItemHistoryRecordCreateInput>
}

export type NewsItemHistoryRecordsUpdateInput = {
    data?: InputMaybe<NewsItemHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

/**  Stores data about the exporting  */
export type NewsItemRecipientsExportTask = {
    __typename?: 'NewsItemRecipientsExportTask'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the NewsItemRecipientsExportTask List config, or
   *  2. As an alias to the field set on 'labelField' in the NewsItemRecipientsExportTask List config, or
   *  3. As an alias to a 'name' field on the NewsItemRecipientsExportTask List (if one exists), or
   *  4. As an alias to the 'id' field on the NewsItemRecipientsExportTask List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  Meta information about file, saved outside of database somewhere. Shape of meta information JSON object is specific to file adapter, used by saving a file.  */
    file?: Maybe<File>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['String']['output']>
    /**  The organization from which the recipients will be exported from  */
    organization?: Maybe<Organization>
    /**  The array of recipients' scopes  */
    scopes?: Maybe<Scalars['JSON']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Status of export job  */
    status?: Maybe<NewsItemRecipientsExportTaskStatusType>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    /**  The user who has started the exporting  */
    user?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type NewsItemRecipientsExportTaskCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['Upload']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    scopes?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<SenderFieldInput>
    status?: InputMaybe<NewsItemRecipientsExportTaskStatusType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type NewsItemRecipientsExportTaskHistoryRecord = {
    __typename?: 'NewsItemRecipientsExportTaskHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the NewsItemRecipientsExportTaskHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the NewsItemRecipientsExportTaskHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the NewsItemRecipientsExportTaskHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the NewsItemRecipientsExportTaskHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    file?: Maybe<Scalars['JSON']['output']>
    history_action?: Maybe<NewsItemRecipientsExportTaskHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    scopes?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    status?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    user?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type NewsItemRecipientsExportTaskHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['JSON']['input']>
    history_action?: InputMaybe<NewsItemRecipientsExportTaskHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    scopes?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum NewsItemRecipientsExportTaskHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type NewsItemRecipientsExportTaskHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['JSON']['input']>
    history_action?: InputMaybe<NewsItemRecipientsExportTaskHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    scopes?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type NewsItemRecipientsExportTaskHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<NewsItemRecipientsExportTaskHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<NewsItemRecipientsExportTaskHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    file?: InputMaybe<Scalars['JSON']['input']>
    file_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    file_not?: InputMaybe<Scalars['JSON']['input']>
    file_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    history_action?: InputMaybe<NewsItemRecipientsExportTaskHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<NewsItemRecipientsExportTaskHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<NewsItemRecipientsExportTaskHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<NewsItemRecipientsExportTaskHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    scopes?: InputMaybe<Scalars['JSON']['input']>
    scopes_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    scopes_not?: InputMaybe<Scalars['JSON']['input']>
    scopes_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    status?: InputMaybe<Scalars['String']['input']>
    status_contains?: InputMaybe<Scalars['String']['input']>
    status_contains_i?: InputMaybe<Scalars['String']['input']>
    status_ends_with?: InputMaybe<Scalars['String']['input']>
    status_ends_with_i?: InputMaybe<Scalars['String']['input']>
    status_i?: InputMaybe<Scalars['String']['input']>
    status_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status_not?: InputMaybe<Scalars['String']['input']>
    status_not_contains?: InputMaybe<Scalars['String']['input']>
    status_not_contains_i?: InputMaybe<Scalars['String']['input']>
    status_not_ends_with?: InputMaybe<Scalars['String']['input']>
    status_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    status_not_i?: InputMaybe<Scalars['String']['input']>
    status_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status_not_starts_with?: InputMaybe<Scalars['String']['input']>
    status_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    status_starts_with?: InputMaybe<Scalars['String']['input']>
    status_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user?: InputMaybe<Scalars['String']['input']>
    user_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user_not?: InputMaybe<Scalars['String']['input']>
    user_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type NewsItemRecipientsExportTaskHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type NewsItemRecipientsExportTaskHistoryRecordsCreateInput = {
    data?: InputMaybe<NewsItemRecipientsExportTaskHistoryRecordCreateInput>
}

export type NewsItemRecipientsExportTaskHistoryRecordsUpdateInput = {
    data?: InputMaybe<NewsItemRecipientsExportTaskHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export enum NewsItemRecipientsExportTaskStatusType {
    Cancelled = 'cancelled',
    Completed = 'completed',
    Error = 'error',
    Processing = 'processing',
}

export type NewsItemRecipientsExportTaskUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['Upload']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    scopes?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<SenderFieldInput>
    status?: InputMaybe<NewsItemRecipientsExportTaskStatusType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type NewsItemRecipientsExportTaskWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<NewsItemRecipientsExportTaskWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<NewsItemRecipientsExportTaskWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    file?: InputMaybe<Scalars['String']['input']>
    file_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    file_not?: InputMaybe<Scalars['String']['input']>
    file_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    scopes?: InputMaybe<Scalars['JSON']['input']>
    scopes_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    scopes_not?: InputMaybe<Scalars['JSON']['input']>
    scopes_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    status?: InputMaybe<NewsItemRecipientsExportTaskStatusType>
    status_in?: InputMaybe<Array<InputMaybe<NewsItemRecipientsExportTaskStatusType>>>
    status_not?: InputMaybe<NewsItemRecipientsExportTaskStatusType>
    status_not_in?: InputMaybe<Array<InputMaybe<NewsItemRecipientsExportTaskStatusType>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    user?: InputMaybe<UserWhereInput>
    user_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type NewsItemRecipientsExportTaskWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type NewsItemRecipientsExportTasksCreateInput = {
    data?: InputMaybe<NewsItemRecipientsExportTaskCreateInput>
}

export type NewsItemRecipientsExportTasksUpdateInput = {
    data?: InputMaybe<NewsItemRecipientsExportTaskUpdateInput>
    id: Scalars['ID']['input']
}

export type NewsItemRelateToOneInput = {
    connect?: InputMaybe<NewsItemWhereUniqueInput>
    create?: InputMaybe<NewsItemCreateInput>
    disconnect?: InputMaybe<NewsItemWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

/**  Which residents can see the particular news item  */
export type NewsItemScope = {
    __typename?: 'NewsItemScope'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the NewsItemScope List config, or
   *  2. As an alias to the field set on 'labelField' in the NewsItemScope List config, or
   *  3. As an alias to a 'name' field on the NewsItemScope List (if one exists), or
   *  4. As an alias to the 'id' field on the NewsItemScope List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['String']['output']>
    /**  The news item to control access for  */
    newsItem?: Maybe<NewsItem>
    /**  Filter on Resident by property, who can read news  */
    property?: Maybe<Property>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  The scope type. This is an auto-calculated field. Used to find news items by scopes filled with some set of attributes.  */
    type?: Maybe<NewsItemScopeTypeType>
    /**  Filter on Resident by unit name, who can read news. Because in case when a Property will have several units of different types, NewsItem can go to wrong recipients. In practice, when an organization needs to address specific residents it is usually assumed, that they are from units of some specific type, for example, a flat. Without this restriction, a NewsItem, targeted only to unitName 1 can potentially be received by residents of flat 1, parking 1 etc.  */
    unitName?: Maybe<Scalars['String']['output']>
    /**  Filter on Resident by unit type, who can read news  */
    unitType?: Maybe<NewsItemScopeUnitTypeType>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type NewsItemScopeCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newsItem?: InputMaybe<NewsItemRelateToOneInput>
    property?: InputMaybe<PropertyRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    type?: InputMaybe<NewsItemScopeTypeType>
    unitName?: InputMaybe<Scalars['String']['input']>
    unitType?: InputMaybe<NewsItemScopeUnitTypeType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type NewsItemScopeHistoryRecord = {
    __typename?: 'NewsItemScopeHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the NewsItemScopeHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the NewsItemScopeHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the NewsItemScopeHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the NewsItemScopeHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<NewsItemScopeHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['JSON']['output']>
    newsItem?: Maybe<Scalars['String']['output']>
    property?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    type?: Maybe<Scalars['String']['output']>
    unitName?: Maybe<Scalars['String']['output']>
    unitType?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type NewsItemScopeHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<NewsItemScopeHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newsItem?: InputMaybe<Scalars['String']['input']>
    property?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    type?: InputMaybe<Scalars['String']['input']>
    unitName?: InputMaybe<Scalars['String']['input']>
    unitType?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum NewsItemScopeHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type NewsItemScopeHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<NewsItemScopeHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newsItem?: InputMaybe<Scalars['String']['input']>
    property?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    type?: InputMaybe<Scalars['String']['input']>
    unitName?: InputMaybe<Scalars['String']['input']>
    unitType?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type NewsItemScopeHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<NewsItemScopeHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<NewsItemScopeHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<NewsItemScopeHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<NewsItemScopeHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<NewsItemScopeHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<NewsItemScopeHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newsItem?: InputMaybe<Scalars['String']['input']>
    newsItem_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newsItem_not?: InputMaybe<Scalars['String']['input']>
    newsItem_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    property?: InputMaybe<Scalars['String']['input']>
    property_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    property_not?: InputMaybe<Scalars['String']['input']>
    property_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    type?: InputMaybe<Scalars['String']['input']>
    type_contains?: InputMaybe<Scalars['String']['input']>
    type_contains_i?: InputMaybe<Scalars['String']['input']>
    type_ends_with?: InputMaybe<Scalars['String']['input']>
    type_ends_with_i?: InputMaybe<Scalars['String']['input']>
    type_i?: InputMaybe<Scalars['String']['input']>
    type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    type_not?: InputMaybe<Scalars['String']['input']>
    type_not_contains?: InputMaybe<Scalars['String']['input']>
    type_not_contains_i?: InputMaybe<Scalars['String']['input']>
    type_not_ends_with?: InputMaybe<Scalars['String']['input']>
    type_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    type_not_i?: InputMaybe<Scalars['String']['input']>
    type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    type_not_starts_with?: InputMaybe<Scalars['String']['input']>
    type_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    type_starts_with?: InputMaybe<Scalars['String']['input']>
    type_starts_with_i?: InputMaybe<Scalars['String']['input']>
    unitName?: InputMaybe<Scalars['String']['input']>
    unitName_contains?: InputMaybe<Scalars['String']['input']>
    unitName_contains_i?: InputMaybe<Scalars['String']['input']>
    unitName_ends_with?: InputMaybe<Scalars['String']['input']>
    unitName_ends_with_i?: InputMaybe<Scalars['String']['input']>
    unitName_i?: InputMaybe<Scalars['String']['input']>
    unitName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitName_not?: InputMaybe<Scalars['String']['input']>
    unitName_not_contains?: InputMaybe<Scalars['String']['input']>
    unitName_not_contains_i?: InputMaybe<Scalars['String']['input']>
    unitName_not_ends_with?: InputMaybe<Scalars['String']['input']>
    unitName_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    unitName_not_i?: InputMaybe<Scalars['String']['input']>
    unitName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitName_not_starts_with?: InputMaybe<Scalars['String']['input']>
    unitName_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    unitName_starts_with?: InputMaybe<Scalars['String']['input']>
    unitName_starts_with_i?: InputMaybe<Scalars['String']['input']>
    unitType?: InputMaybe<Scalars['String']['input']>
    unitType_contains?: InputMaybe<Scalars['String']['input']>
    unitType_contains_i?: InputMaybe<Scalars['String']['input']>
    unitType_ends_with?: InputMaybe<Scalars['String']['input']>
    unitType_ends_with_i?: InputMaybe<Scalars['String']['input']>
    unitType_i?: InputMaybe<Scalars['String']['input']>
    unitType_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitType_not?: InputMaybe<Scalars['String']['input']>
    unitType_not_contains?: InputMaybe<Scalars['String']['input']>
    unitType_not_contains_i?: InputMaybe<Scalars['String']['input']>
    unitType_not_ends_with?: InputMaybe<Scalars['String']['input']>
    unitType_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    unitType_not_i?: InputMaybe<Scalars['String']['input']>
    unitType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitType_not_starts_with?: InputMaybe<Scalars['String']['input']>
    unitType_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    unitType_starts_with?: InputMaybe<Scalars['String']['input']>
    unitType_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type NewsItemScopeHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type NewsItemScopeHistoryRecordsCreateInput = {
    data?: InputMaybe<NewsItemScopeHistoryRecordCreateInput>
}

export type NewsItemScopeHistoryRecordsUpdateInput = {
    data?: InputMaybe<NewsItemScopeHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type NewsItemScopeRelateToManyInput = {
    connect?: InputMaybe<Array<InputMaybe<NewsItemScopeWhereUniqueInput>>>
    create?: InputMaybe<Array<InputMaybe<NewsItemScopeCreateInput>>>
    disconnect?: InputMaybe<Array<InputMaybe<NewsItemScopeWhereUniqueInput>>>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export enum NewsItemScopeTypeType {
    Organization = 'organization',
    Property = 'property',
    PropertyUnitType = 'property_unitType',
    PropertyUnitTypeUnitName = 'property_unitType_unitName',
    Unknown = 'unknown',
}

export enum NewsItemScopeUnitTypeType {
    Apartment = 'apartment',
    Commercial = 'commercial',
    Flat = 'flat',
    Parking = 'parking',
    Warehouse = 'warehouse',
}

export type NewsItemScopeUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newsItem?: InputMaybe<NewsItemRelateToOneInput>
    property?: InputMaybe<PropertyRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    type?: InputMaybe<NewsItemScopeTypeType>
    unitName?: InputMaybe<Scalars['String']['input']>
    unitType?: InputMaybe<NewsItemScopeUnitTypeType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type NewsItemScopeWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<NewsItemScopeWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<NewsItemScopeWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newsItem?: InputMaybe<NewsItemWhereInput>
    newsItem_is_null?: InputMaybe<Scalars['Boolean']['input']>
    property?: InputMaybe<PropertyWhereInput>
    property_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    type?: InputMaybe<NewsItemScopeTypeType>
    type_in?: InputMaybe<Array<InputMaybe<NewsItemScopeTypeType>>>
    type_not?: InputMaybe<NewsItemScopeTypeType>
    type_not_in?: InputMaybe<Array<InputMaybe<NewsItemScopeTypeType>>>
    unitName?: InputMaybe<Scalars['String']['input']>
    unitName_contains?: InputMaybe<Scalars['String']['input']>
    unitName_contains_i?: InputMaybe<Scalars['String']['input']>
    unitName_ends_with?: InputMaybe<Scalars['String']['input']>
    unitName_ends_with_i?: InputMaybe<Scalars['String']['input']>
    unitName_i?: InputMaybe<Scalars['String']['input']>
    unitName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitName_not?: InputMaybe<Scalars['String']['input']>
    unitName_not_contains?: InputMaybe<Scalars['String']['input']>
    unitName_not_contains_i?: InputMaybe<Scalars['String']['input']>
    unitName_not_ends_with?: InputMaybe<Scalars['String']['input']>
    unitName_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    unitName_not_i?: InputMaybe<Scalars['String']['input']>
    unitName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitName_not_starts_with?: InputMaybe<Scalars['String']['input']>
    unitName_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    unitName_starts_with?: InputMaybe<Scalars['String']['input']>
    unitName_starts_with_i?: InputMaybe<Scalars['String']['input']>
    unitType?: InputMaybe<NewsItemScopeUnitTypeType>
    unitType_in?: InputMaybe<Array<InputMaybe<NewsItemScopeUnitTypeType>>>
    unitType_not?: InputMaybe<NewsItemScopeUnitTypeType>
    unitType_not_in?: InputMaybe<Array<InputMaybe<NewsItemScopeUnitTypeType>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type NewsItemScopeWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type NewsItemScopesCreateInput = {
    data?: InputMaybe<NewsItemScopeCreateInput>
}

export type NewsItemScopesUpdateInput = {
    data?: InputMaybe<NewsItemScopeUpdateInput>
    id: Scalars['ID']['input']
}

/**  Existence of this models means that certain NewsItem should published in certain B2BApp that implements NewsSharing API.  */
export type NewsItemSharing = {
    __typename?: 'NewsItemSharing'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the NewsItemSharing List config, or
   *  2. As an alias to the field set on 'labelField' in the NewsItemSharing List config, or
   *  3. As an alias to a 'name' field on the NewsItemSharing List (if one exists), or
   *  4. As an alias to the 'id' field on the NewsItemSharing List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  Connection to the miniapp that is responsible for publishing this news item  */
    b2bAppContext?: Maybe<B2BAppContext>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  The outcome from the most recent invocation of the lastPostRequest  */
    lastPostRequest?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Connection to the published news item  */
    newsItem?: Maybe<NewsItem>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Sending parameters specific to a particular mini-app  */
    sharingParams?: Maybe<Scalars['JSON']['output']>
    /**  Publication status of the news: updated automatically  */
    status?: Maybe<NewsItemSharingStatusType>
    /**  Explanations regarding the publication status. Might be shown to user  */
    statusMessage?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type NewsItemSharingCreateInput = {
    b2bAppContext?: InputMaybe<B2BAppContextRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    lastPostRequest?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newsItem?: InputMaybe<NewsItemRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    sharingParams?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<NewsItemSharingStatusType>
    statusMessage?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type NewsItemSharingHistoryRecord = {
    __typename?: 'NewsItemSharingHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the NewsItemSharingHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the NewsItemSharingHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the NewsItemSharingHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the NewsItemSharingHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    b2bAppContext?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<NewsItemSharingHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    lastPostRequest?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    newsItem?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    sharingParams?: Maybe<Scalars['JSON']['output']>
    status?: Maybe<Scalars['String']['output']>
    statusMessage?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type NewsItemSharingHistoryRecordCreateInput = {
    b2bAppContext?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<NewsItemSharingHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    lastPostRequest?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newsItem?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sharingParams?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    statusMessage?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum NewsItemSharingHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type NewsItemSharingHistoryRecordUpdateInput = {
    b2bAppContext?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<NewsItemSharingHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    lastPostRequest?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newsItem?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sharingParams?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    statusMessage?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type NewsItemSharingHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<NewsItemSharingHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<NewsItemSharingHistoryRecordWhereInput>>>
    b2bAppContext?: InputMaybe<Scalars['String']['input']>
    b2bAppContext_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    b2bAppContext_not?: InputMaybe<Scalars['String']['input']>
    b2bAppContext_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<NewsItemSharingHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<NewsItemSharingHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<NewsItemSharingHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<NewsItemSharingHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    lastPostRequest?: InputMaybe<Scalars['JSON']['input']>
    lastPostRequest_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    lastPostRequest_not?: InputMaybe<Scalars['JSON']['input']>
    lastPostRequest_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newsItem?: InputMaybe<Scalars['String']['input']>
    newsItem_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newsItem_not?: InputMaybe<Scalars['String']['input']>
    newsItem_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sharingParams?: InputMaybe<Scalars['JSON']['input']>
    sharingParams_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sharingParams_not?: InputMaybe<Scalars['JSON']['input']>
    sharingParams_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    status?: InputMaybe<Scalars['String']['input']>
    statusMessage?: InputMaybe<Scalars['String']['input']>
    statusMessage_contains?: InputMaybe<Scalars['String']['input']>
    statusMessage_contains_i?: InputMaybe<Scalars['String']['input']>
    statusMessage_ends_with?: InputMaybe<Scalars['String']['input']>
    statusMessage_ends_with_i?: InputMaybe<Scalars['String']['input']>
    statusMessage_i?: InputMaybe<Scalars['String']['input']>
    statusMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    statusMessage_not?: InputMaybe<Scalars['String']['input']>
    statusMessage_not_contains?: InputMaybe<Scalars['String']['input']>
    statusMessage_not_contains_i?: InputMaybe<Scalars['String']['input']>
    statusMessage_not_ends_with?: InputMaybe<Scalars['String']['input']>
    statusMessage_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    statusMessage_not_i?: InputMaybe<Scalars['String']['input']>
    statusMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    statusMessage_not_starts_with?: InputMaybe<Scalars['String']['input']>
    statusMessage_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    statusMessage_starts_with?: InputMaybe<Scalars['String']['input']>
    statusMessage_starts_with_i?: InputMaybe<Scalars['String']['input']>
    status_contains?: InputMaybe<Scalars['String']['input']>
    status_contains_i?: InputMaybe<Scalars['String']['input']>
    status_ends_with?: InputMaybe<Scalars['String']['input']>
    status_ends_with_i?: InputMaybe<Scalars['String']['input']>
    status_i?: InputMaybe<Scalars['String']['input']>
    status_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status_not?: InputMaybe<Scalars['String']['input']>
    status_not_contains?: InputMaybe<Scalars['String']['input']>
    status_not_contains_i?: InputMaybe<Scalars['String']['input']>
    status_not_ends_with?: InputMaybe<Scalars['String']['input']>
    status_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    status_not_i?: InputMaybe<Scalars['String']['input']>
    status_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status_not_starts_with?: InputMaybe<Scalars['String']['input']>
    status_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    status_starts_with?: InputMaybe<Scalars['String']['input']>
    status_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type NewsItemSharingHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type NewsItemSharingHistoryRecordsCreateInput = {
    data?: InputMaybe<NewsItemSharingHistoryRecordCreateInput>
}

export type NewsItemSharingHistoryRecordsUpdateInput = {
    data?: InputMaybe<NewsItemSharingHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export enum NewsItemSharingStatusType {
    Error = 'error',
    Processing = 'processing',
    Published = 'published',
    Scheduled = 'scheduled',
}

export type NewsItemSharingUpdateInput = {
    b2bAppContext?: InputMaybe<B2BAppContextRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    lastPostRequest?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newsItem?: InputMaybe<NewsItemRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    sharingParams?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<NewsItemSharingStatusType>
    statusMessage?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type NewsItemSharingWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<NewsItemSharingWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<NewsItemSharingWhereInput>>>
    b2bAppContext?: InputMaybe<B2BAppContextWhereInput>
    b2bAppContext_is_null?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    lastPostRequest?: InputMaybe<Scalars['JSON']['input']>
    lastPostRequest_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    lastPostRequest_not?: InputMaybe<Scalars['JSON']['input']>
    lastPostRequest_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newsItem?: InputMaybe<NewsItemWhereInput>
    newsItem_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sharingParams?: InputMaybe<Scalars['JSON']['input']>
    sharingParams_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sharingParams_not?: InputMaybe<Scalars['JSON']['input']>
    sharingParams_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    status?: InputMaybe<NewsItemSharingStatusType>
    statusMessage?: InputMaybe<Scalars['String']['input']>
    statusMessage_contains?: InputMaybe<Scalars['String']['input']>
    statusMessage_contains_i?: InputMaybe<Scalars['String']['input']>
    statusMessage_ends_with?: InputMaybe<Scalars['String']['input']>
    statusMessage_ends_with_i?: InputMaybe<Scalars['String']['input']>
    statusMessage_i?: InputMaybe<Scalars['String']['input']>
    statusMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    statusMessage_not?: InputMaybe<Scalars['String']['input']>
    statusMessage_not_contains?: InputMaybe<Scalars['String']['input']>
    statusMessage_not_contains_i?: InputMaybe<Scalars['String']['input']>
    statusMessage_not_ends_with?: InputMaybe<Scalars['String']['input']>
    statusMessage_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    statusMessage_not_i?: InputMaybe<Scalars['String']['input']>
    statusMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    statusMessage_not_starts_with?: InputMaybe<Scalars['String']['input']>
    statusMessage_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    statusMessage_starts_with?: InputMaybe<Scalars['String']['input']>
    statusMessage_starts_with_i?: InputMaybe<Scalars['String']['input']>
    status_in?: InputMaybe<Array<InputMaybe<NewsItemSharingStatusType>>>
    status_not?: InputMaybe<NewsItemSharingStatusType>
    status_not_in?: InputMaybe<Array<InputMaybe<NewsItemSharingStatusType>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type NewsItemSharingWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type NewsItemSharingsCreateInput = {
    data?: InputMaybe<NewsItemSharingCreateInput>
}

export type NewsItemSharingsUpdateInput = {
    data?: InputMaybe<NewsItemSharingUpdateInput>
    id: Scalars['ID']['input']
}

/**  The news item template for quick composing a news item  */
export type NewsItemTemplate = {
    __typename?: 'NewsItemTemplate'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the NewsItemTemplate List config, or
   *  2. As an alias to the field set on 'labelField' in the NewsItemTemplate List config, or
   *  3. As an alias to a 'name' field on the NewsItemTemplate List (if one exists), or
   *  4. As an alias to the 'id' field on the NewsItemTemplate List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  A future news item's body  */
    body?: Maybe<Scalars['String']['output']>
    /**  Category of template of the news item. Example `Water` or any other select value. News item does not necessarily have a category  */
    category?: Maybe<NewsItemTemplateCategoryType>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  Name of template the news item. Example `Heating outage due to repairs` or any other text value  */
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Organization who creates the template. A common template if there is no organization  */
    organization?: Maybe<Organization>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  The title of a future news item  */
    title?: Maybe<Scalars['String']['output']>
    /**  The news item template type.  */
    type?: Maybe<NewsItemTemplateTypeType>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export enum NewsItemTemplateCategoryType {
    Cleaning = 'CLEANING',
    Electricity = 'ELECTRICITY',
    ElectricityAndElevators = 'ELECTRICITY_AND_ELEVATORS',
    Elevators = 'ELEVATORS',
    Gas = 'GAS',
    Heating = 'HEATING',
    Intercom = 'INTERCOM',
    Meters = 'METERS',
    Other = 'OTHER',
    Snow = 'SNOW',
    Telephone = 'TELEPHONE',
    Utilities = 'UTILITIES',
    Water = 'WATER',
}

export type NewsItemTemplateCreateInput = {
    body?: InputMaybe<Scalars['String']['input']>
    category?: InputMaybe<NewsItemTemplateCategoryType>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    title?: InputMaybe<Scalars['String']['input']>
    type?: InputMaybe<NewsItemTemplateTypeType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type NewsItemTemplateHistoryRecord = {
    __typename?: 'NewsItemTemplateHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the NewsItemTemplateHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the NewsItemTemplateHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the NewsItemTemplateHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the NewsItemTemplateHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    body?: Maybe<Scalars['String']['output']>
    category?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<NewsItemTemplateHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    title?: Maybe<Scalars['String']['output']>
    type?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type NewsItemTemplateHistoryRecordCreateInput = {
    body?: InputMaybe<Scalars['String']['input']>
    category?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<NewsItemTemplateHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    title?: InputMaybe<Scalars['String']['input']>
    type?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum NewsItemTemplateHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type NewsItemTemplateHistoryRecordUpdateInput = {
    body?: InputMaybe<Scalars['String']['input']>
    category?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<NewsItemTemplateHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    title?: InputMaybe<Scalars['String']['input']>
    type?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type NewsItemTemplateHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<NewsItemTemplateHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<NewsItemTemplateHistoryRecordWhereInput>>>
    body?: InputMaybe<Scalars['String']['input']>
    body_contains?: InputMaybe<Scalars['String']['input']>
    body_contains_i?: InputMaybe<Scalars['String']['input']>
    body_ends_with?: InputMaybe<Scalars['String']['input']>
    body_ends_with_i?: InputMaybe<Scalars['String']['input']>
    body_i?: InputMaybe<Scalars['String']['input']>
    body_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    body_not?: InputMaybe<Scalars['String']['input']>
    body_not_contains?: InputMaybe<Scalars['String']['input']>
    body_not_contains_i?: InputMaybe<Scalars['String']['input']>
    body_not_ends_with?: InputMaybe<Scalars['String']['input']>
    body_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    body_not_i?: InputMaybe<Scalars['String']['input']>
    body_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    body_not_starts_with?: InputMaybe<Scalars['String']['input']>
    body_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    body_starts_with?: InputMaybe<Scalars['String']['input']>
    body_starts_with_i?: InputMaybe<Scalars['String']['input']>
    category?: InputMaybe<Scalars['String']['input']>
    category_contains?: InputMaybe<Scalars['String']['input']>
    category_contains_i?: InputMaybe<Scalars['String']['input']>
    category_ends_with?: InputMaybe<Scalars['String']['input']>
    category_ends_with_i?: InputMaybe<Scalars['String']['input']>
    category_i?: InputMaybe<Scalars['String']['input']>
    category_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    category_not?: InputMaybe<Scalars['String']['input']>
    category_not_contains?: InputMaybe<Scalars['String']['input']>
    category_not_contains_i?: InputMaybe<Scalars['String']['input']>
    category_not_ends_with?: InputMaybe<Scalars['String']['input']>
    category_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    category_not_i?: InputMaybe<Scalars['String']['input']>
    category_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    category_not_starts_with?: InputMaybe<Scalars['String']['input']>
    category_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    category_starts_with?: InputMaybe<Scalars['String']['input']>
    category_starts_with_i?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<NewsItemTemplateHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<NewsItemTemplateHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<NewsItemTemplateHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<NewsItemTemplateHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    title?: InputMaybe<Scalars['String']['input']>
    title_contains?: InputMaybe<Scalars['String']['input']>
    title_contains_i?: InputMaybe<Scalars['String']['input']>
    title_ends_with?: InputMaybe<Scalars['String']['input']>
    title_ends_with_i?: InputMaybe<Scalars['String']['input']>
    title_i?: InputMaybe<Scalars['String']['input']>
    title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    title_not?: InputMaybe<Scalars['String']['input']>
    title_not_contains?: InputMaybe<Scalars['String']['input']>
    title_not_contains_i?: InputMaybe<Scalars['String']['input']>
    title_not_ends_with?: InputMaybe<Scalars['String']['input']>
    title_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    title_not_i?: InputMaybe<Scalars['String']['input']>
    title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    title_not_starts_with?: InputMaybe<Scalars['String']['input']>
    title_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    title_starts_with?: InputMaybe<Scalars['String']['input']>
    title_starts_with_i?: InputMaybe<Scalars['String']['input']>
    type?: InputMaybe<Scalars['String']['input']>
    type_contains?: InputMaybe<Scalars['String']['input']>
    type_contains_i?: InputMaybe<Scalars['String']['input']>
    type_ends_with?: InputMaybe<Scalars['String']['input']>
    type_ends_with_i?: InputMaybe<Scalars['String']['input']>
    type_i?: InputMaybe<Scalars['String']['input']>
    type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    type_not?: InputMaybe<Scalars['String']['input']>
    type_not_contains?: InputMaybe<Scalars['String']['input']>
    type_not_contains_i?: InputMaybe<Scalars['String']['input']>
    type_not_ends_with?: InputMaybe<Scalars['String']['input']>
    type_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    type_not_i?: InputMaybe<Scalars['String']['input']>
    type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    type_not_starts_with?: InputMaybe<Scalars['String']['input']>
    type_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    type_starts_with?: InputMaybe<Scalars['String']['input']>
    type_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type NewsItemTemplateHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type NewsItemTemplateHistoryRecordsCreateInput = {
    data?: InputMaybe<NewsItemTemplateHistoryRecordCreateInput>
}

export type NewsItemTemplateHistoryRecordsUpdateInput = {
    data?: InputMaybe<NewsItemTemplateHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export enum NewsItemTemplateTypeType {
    Common = 'common',
    Emergency = 'emergency',
}

export type NewsItemTemplateUpdateInput = {
    body?: InputMaybe<Scalars['String']['input']>
    category?: InputMaybe<NewsItemTemplateCategoryType>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    title?: InputMaybe<Scalars['String']['input']>
    type?: InputMaybe<NewsItemTemplateTypeType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type NewsItemTemplateWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<NewsItemTemplateWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<NewsItemTemplateWhereInput>>>
    body?: InputMaybe<Scalars['String']['input']>
    body_contains?: InputMaybe<Scalars['String']['input']>
    body_contains_i?: InputMaybe<Scalars['String']['input']>
    body_ends_with?: InputMaybe<Scalars['String']['input']>
    body_ends_with_i?: InputMaybe<Scalars['String']['input']>
    body_i?: InputMaybe<Scalars['String']['input']>
    body_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    body_not?: InputMaybe<Scalars['String']['input']>
    body_not_contains?: InputMaybe<Scalars['String']['input']>
    body_not_contains_i?: InputMaybe<Scalars['String']['input']>
    body_not_ends_with?: InputMaybe<Scalars['String']['input']>
    body_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    body_not_i?: InputMaybe<Scalars['String']['input']>
    body_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    body_not_starts_with?: InputMaybe<Scalars['String']['input']>
    body_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    body_starts_with?: InputMaybe<Scalars['String']['input']>
    body_starts_with_i?: InputMaybe<Scalars['String']['input']>
    category?: InputMaybe<NewsItemTemplateCategoryType>
    category_in?: InputMaybe<Array<InputMaybe<NewsItemTemplateCategoryType>>>
    category_not?: InputMaybe<NewsItemTemplateCategoryType>
    category_not_in?: InputMaybe<Array<InputMaybe<NewsItemTemplateCategoryType>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    title?: InputMaybe<Scalars['String']['input']>
    title_contains?: InputMaybe<Scalars['String']['input']>
    title_contains_i?: InputMaybe<Scalars['String']['input']>
    title_ends_with?: InputMaybe<Scalars['String']['input']>
    title_ends_with_i?: InputMaybe<Scalars['String']['input']>
    title_i?: InputMaybe<Scalars['String']['input']>
    title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    title_not?: InputMaybe<Scalars['String']['input']>
    title_not_contains?: InputMaybe<Scalars['String']['input']>
    title_not_contains_i?: InputMaybe<Scalars['String']['input']>
    title_not_ends_with?: InputMaybe<Scalars['String']['input']>
    title_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    title_not_i?: InputMaybe<Scalars['String']['input']>
    title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    title_not_starts_with?: InputMaybe<Scalars['String']['input']>
    title_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    title_starts_with?: InputMaybe<Scalars['String']['input']>
    title_starts_with_i?: InputMaybe<Scalars['String']['input']>
    type?: InputMaybe<NewsItemTemplateTypeType>
    type_in?: InputMaybe<Array<InputMaybe<NewsItemTemplateTypeType>>>
    type_not?: InputMaybe<NewsItemTemplateTypeType>
    type_not_in?: InputMaybe<Array<InputMaybe<NewsItemTemplateTypeType>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type NewsItemTemplateWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type NewsItemTemplatesCreateInput = {
    data?: InputMaybe<NewsItemTemplateCreateInput>
}

export type NewsItemTemplatesUpdateInput = {
    data?: InputMaybe<NewsItemTemplateUpdateInput>
    id: Scalars['ID']['input']
}

export enum NewsItemTypeType {
    Common = 'common',
    Emergency = 'emergency',
}

export type NewsItemUpdateInput = {
    body?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    isPublished?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    number?: InputMaybe<Scalars['Int']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    publishedAt?: InputMaybe<Scalars['String']['input']>
    scopes?: InputMaybe<NewsItemScopeRelateToManyInput>
    sendAt?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sentAt?: InputMaybe<Scalars['String']['input']>
    title?: InputMaybe<Scalars['String']['input']>
    type?: InputMaybe<NewsItemTypeType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
    validBefore?: InputMaybe<Scalars['String']['input']>
}

/**  The fact the user has read the particular news item  */
export type NewsItemUserRead = {
    __typename?: 'NewsItemUserRead'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the NewsItemUserRead List config, or
   *  2. As an alias to the field set on 'labelField' in the NewsItemUserRead List config, or
   *  3. As an alias to a 'name' field on the NewsItemUserRead List (if one exists), or
   *  4. As an alias to the 'id' field on the NewsItemUserRead List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['String']['output']>
    /**  The news item the user has read  */
    newsItem?: Maybe<NewsItem>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    /**  The user who has read the particular news item  */
    user?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type NewsItemUserReadCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newsItem?: InputMaybe<NewsItemRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type NewsItemUserReadHistoryRecord = {
    __typename?: 'NewsItemUserReadHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the NewsItemUserReadHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the NewsItemUserReadHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the NewsItemUserReadHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the NewsItemUserReadHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<NewsItemUserReadHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['JSON']['output']>
    newsItem?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    user?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type NewsItemUserReadHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<NewsItemUserReadHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newsItem?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum NewsItemUserReadHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type NewsItemUserReadHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<NewsItemUserReadHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newsItem?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type NewsItemUserReadHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<NewsItemUserReadHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<NewsItemUserReadHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<NewsItemUserReadHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<NewsItemUserReadHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<NewsItemUserReadHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<NewsItemUserReadHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newsItem?: InputMaybe<Scalars['String']['input']>
    newsItem_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newsItem_not?: InputMaybe<Scalars['String']['input']>
    newsItem_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user?: InputMaybe<Scalars['String']['input']>
    user_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user_not?: InputMaybe<Scalars['String']['input']>
    user_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type NewsItemUserReadHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type NewsItemUserReadHistoryRecordsCreateInput = {
    data?: InputMaybe<NewsItemUserReadHistoryRecordCreateInput>
}

export type NewsItemUserReadHistoryRecordsUpdateInput = {
    data?: InputMaybe<NewsItemUserReadHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type NewsItemUserReadUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newsItem?: InputMaybe<NewsItemRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type NewsItemUserReadWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<NewsItemUserReadWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<NewsItemUserReadWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newsItem?: InputMaybe<NewsItemWhereInput>
    newsItem_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    user?: InputMaybe<UserWhereInput>
    user_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type NewsItemUserReadWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type NewsItemUserReadsCreateInput = {
    data?: InputMaybe<NewsItemUserReadCreateInput>
}

export type NewsItemUserReadsUpdateInput = {
    data?: InputMaybe<NewsItemUserReadUpdateInput>
    id: Scalars['ID']['input']
}

export type NewsItemWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<NewsItemWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<NewsItemWhereInput>>>
    body?: InputMaybe<Scalars['String']['input']>
    body_contains?: InputMaybe<Scalars['String']['input']>
    body_contains_i?: InputMaybe<Scalars['String']['input']>
    body_ends_with?: InputMaybe<Scalars['String']['input']>
    body_ends_with_i?: InputMaybe<Scalars['String']['input']>
    body_i?: InputMaybe<Scalars['String']['input']>
    body_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    body_not?: InputMaybe<Scalars['String']['input']>
    body_not_contains?: InputMaybe<Scalars['String']['input']>
    body_not_contains_i?: InputMaybe<Scalars['String']['input']>
    body_not_ends_with?: InputMaybe<Scalars['String']['input']>
    body_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    body_not_i?: InputMaybe<Scalars['String']['input']>
    body_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    body_not_starts_with?: InputMaybe<Scalars['String']['input']>
    body_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    body_starts_with?: InputMaybe<Scalars['String']['input']>
    body_starts_with_i?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    isPublished?: InputMaybe<Scalars['Boolean']['input']>
    isPublished_not?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    number?: InputMaybe<Scalars['Int']['input']>
    number_gt?: InputMaybe<Scalars['Int']['input']>
    number_gte?: InputMaybe<Scalars['Int']['input']>
    number_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    number_lt?: InputMaybe<Scalars['Int']['input']>
    number_lte?: InputMaybe<Scalars['Int']['input']>
    number_not?: InputMaybe<Scalars['Int']['input']>
    number_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    publishedAt?: InputMaybe<Scalars['String']['input']>
    publishedAt_gt?: InputMaybe<Scalars['String']['input']>
    publishedAt_gte?: InputMaybe<Scalars['String']['input']>
    publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    publishedAt_lt?: InputMaybe<Scalars['String']['input']>
    publishedAt_lte?: InputMaybe<Scalars['String']['input']>
    publishedAt_not?: InputMaybe<Scalars['String']['input']>
    publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    /**  condition must be true for all nodes  */
    scopes_every?: InputMaybe<NewsItemScopeWhereInput>
    /**  condition must be false for all nodes  */
    scopes_none?: InputMaybe<NewsItemScopeWhereInput>
    /**  condition must be true for at least 1 node  */
    scopes_some?: InputMaybe<NewsItemScopeWhereInput>
    sendAt?: InputMaybe<Scalars['String']['input']>
    sendAt_gt?: InputMaybe<Scalars['String']['input']>
    sendAt_gte?: InputMaybe<Scalars['String']['input']>
    sendAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sendAt_lt?: InputMaybe<Scalars['String']['input']>
    sendAt_lte?: InputMaybe<Scalars['String']['input']>
    sendAt_not?: InputMaybe<Scalars['String']['input']>
    sendAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sentAt?: InputMaybe<Scalars['String']['input']>
    sentAt_gt?: InputMaybe<Scalars['String']['input']>
    sentAt_gte?: InputMaybe<Scalars['String']['input']>
    sentAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sentAt_lt?: InputMaybe<Scalars['String']['input']>
    sentAt_lte?: InputMaybe<Scalars['String']['input']>
    sentAt_not?: InputMaybe<Scalars['String']['input']>
    sentAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    title?: InputMaybe<Scalars['String']['input']>
    title_contains?: InputMaybe<Scalars['String']['input']>
    title_contains_i?: InputMaybe<Scalars['String']['input']>
    title_ends_with?: InputMaybe<Scalars['String']['input']>
    title_ends_with_i?: InputMaybe<Scalars['String']['input']>
    title_i?: InputMaybe<Scalars['String']['input']>
    title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    title_not?: InputMaybe<Scalars['String']['input']>
    title_not_contains?: InputMaybe<Scalars['String']['input']>
    title_not_contains_i?: InputMaybe<Scalars['String']['input']>
    title_not_ends_with?: InputMaybe<Scalars['String']['input']>
    title_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    title_not_i?: InputMaybe<Scalars['String']['input']>
    title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    title_not_starts_with?: InputMaybe<Scalars['String']['input']>
    title_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    title_starts_with?: InputMaybe<Scalars['String']['input']>
    title_starts_with_i?: InputMaybe<Scalars['String']['input']>
    type?: InputMaybe<NewsItemTypeType>
    type_in?: InputMaybe<Array<InputMaybe<NewsItemTypeType>>>
    type_not?: InputMaybe<NewsItemTypeType>
    type_not_in?: InputMaybe<Array<InputMaybe<NewsItemTypeType>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    validBefore?: InputMaybe<Scalars['String']['input']>
    validBefore_gt?: InputMaybe<Scalars['String']['input']>
    validBefore_gte?: InputMaybe<Scalars['String']['input']>
    validBefore_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    validBefore_lt?: InputMaybe<Scalars['String']['input']>
    validBefore_lte?: InputMaybe<Scalars['String']['input']>
    validBefore_not?: InputMaybe<Scalars['String']['input']>
    validBefore_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type NewsItemWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type NewsItemsCreateInput = {
    data?: InputMaybe<NewsItemCreateInput>
}

export type NewsItemsUpdateInput = {
    data?: InputMaybe<NewsItemUpdateInput>
    id: Scalars['ID']['input']
}

/**  Anonymous contact notifications settings  */
export type NotificationAnonymousSetting = {
    __typename?: 'NotificationAnonymousSetting'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the NotificationAnonymousSetting List config, or
   *  2. As an alias to the field set on 'labelField' in the NotificationAnonymousSetting List config, or
   *  3. As an alias to a 'name' field on the NotificationAnonymousSetting List (if one exists), or
   *  4. As an alias to the 'id' field on the NotificationAnonymousSetting List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  The settings will applied for  */
    email?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    /**  Is notification enabled  */
    isEnabled?: Maybe<Scalars['Boolean']['output']>
    /**  Affected message transport. Possible values are: email, sms  */
    messageTransport?: Maybe<NotificationAnonymousSettingMessageTransportType>
    /**  Affected message type  */
    messageType?: Maybe<MessageType>
    newId?: Maybe<Scalars['String']['output']>
    /**  Phone. In international E.164 format without spaces  */
    phone?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type NotificationAnonymousSettingCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    email?: InputMaybe<Scalars['String']['input']>
    isEnabled?: InputMaybe<Scalars['Boolean']['input']>
    messageTransport?: InputMaybe<NotificationAnonymousSettingMessageTransportType>
    messageType?: InputMaybe<MessageType>
    newId?: InputMaybe<Scalars['String']['input']>
    phone?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type NotificationAnonymousSettingHistoryRecord = {
    __typename?: 'NotificationAnonymousSettingHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the NotificationAnonymousSettingHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the NotificationAnonymousSettingHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the NotificationAnonymousSettingHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the NotificationAnonymousSettingHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    email?: Maybe<Scalars['String']['output']>
    history_action?: Maybe<NotificationAnonymousSettingHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    isEnabled?: Maybe<Scalars['Boolean']['output']>
    messageTransport?: Maybe<Scalars['String']['output']>
    messageType?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    phone?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type NotificationAnonymousSettingHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    email?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<NotificationAnonymousSettingHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    isEnabled?: InputMaybe<Scalars['Boolean']['input']>
    messageTransport?: InputMaybe<Scalars['String']['input']>
    messageType?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    phone?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum NotificationAnonymousSettingHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type NotificationAnonymousSettingHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    email?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<NotificationAnonymousSettingHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    isEnabled?: InputMaybe<Scalars['Boolean']['input']>
    messageTransport?: InputMaybe<Scalars['String']['input']>
    messageType?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    phone?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type NotificationAnonymousSettingHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<NotificationAnonymousSettingHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<NotificationAnonymousSettingHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    email?: InputMaybe<Scalars['String']['input']>
    email_contains?: InputMaybe<Scalars['String']['input']>
    email_contains_i?: InputMaybe<Scalars['String']['input']>
    email_ends_with?: InputMaybe<Scalars['String']['input']>
    email_ends_with_i?: InputMaybe<Scalars['String']['input']>
    email_i?: InputMaybe<Scalars['String']['input']>
    email_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    email_not?: InputMaybe<Scalars['String']['input']>
    email_not_contains?: InputMaybe<Scalars['String']['input']>
    email_not_contains_i?: InputMaybe<Scalars['String']['input']>
    email_not_ends_with?: InputMaybe<Scalars['String']['input']>
    email_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    email_not_i?: InputMaybe<Scalars['String']['input']>
    email_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    email_not_starts_with?: InputMaybe<Scalars['String']['input']>
    email_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    email_starts_with?: InputMaybe<Scalars['String']['input']>
    email_starts_with_i?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<NotificationAnonymousSettingHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<NotificationAnonymousSettingHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<NotificationAnonymousSettingHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<NotificationAnonymousSettingHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    isEnabled?: InputMaybe<Scalars['Boolean']['input']>
    isEnabled_not?: InputMaybe<Scalars['Boolean']['input']>
    messageTransport?: InputMaybe<Scalars['String']['input']>
    messageTransport_contains?: InputMaybe<Scalars['String']['input']>
    messageTransport_contains_i?: InputMaybe<Scalars['String']['input']>
    messageTransport_ends_with?: InputMaybe<Scalars['String']['input']>
    messageTransport_ends_with_i?: InputMaybe<Scalars['String']['input']>
    messageTransport_i?: InputMaybe<Scalars['String']['input']>
    messageTransport_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    messageTransport_not?: InputMaybe<Scalars['String']['input']>
    messageTransport_not_contains?: InputMaybe<Scalars['String']['input']>
    messageTransport_not_contains_i?: InputMaybe<Scalars['String']['input']>
    messageTransport_not_ends_with?: InputMaybe<Scalars['String']['input']>
    messageTransport_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    messageTransport_not_i?: InputMaybe<Scalars['String']['input']>
    messageTransport_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    messageTransport_not_starts_with?: InputMaybe<Scalars['String']['input']>
    messageTransport_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    messageTransport_starts_with?: InputMaybe<Scalars['String']['input']>
    messageTransport_starts_with_i?: InputMaybe<Scalars['String']['input']>
    messageType?: InputMaybe<Scalars['String']['input']>
    messageType_contains?: InputMaybe<Scalars['String']['input']>
    messageType_contains_i?: InputMaybe<Scalars['String']['input']>
    messageType_ends_with?: InputMaybe<Scalars['String']['input']>
    messageType_ends_with_i?: InputMaybe<Scalars['String']['input']>
    messageType_i?: InputMaybe<Scalars['String']['input']>
    messageType_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    messageType_not?: InputMaybe<Scalars['String']['input']>
    messageType_not_contains?: InputMaybe<Scalars['String']['input']>
    messageType_not_contains_i?: InputMaybe<Scalars['String']['input']>
    messageType_not_ends_with?: InputMaybe<Scalars['String']['input']>
    messageType_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    messageType_not_i?: InputMaybe<Scalars['String']['input']>
    messageType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    messageType_not_starts_with?: InputMaybe<Scalars['String']['input']>
    messageType_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    messageType_starts_with?: InputMaybe<Scalars['String']['input']>
    messageType_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    phone?: InputMaybe<Scalars['String']['input']>
    phone_contains?: InputMaybe<Scalars['String']['input']>
    phone_contains_i?: InputMaybe<Scalars['String']['input']>
    phone_ends_with?: InputMaybe<Scalars['String']['input']>
    phone_ends_with_i?: InputMaybe<Scalars['String']['input']>
    phone_i?: InputMaybe<Scalars['String']['input']>
    phone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phone_not?: InputMaybe<Scalars['String']['input']>
    phone_not_contains?: InputMaybe<Scalars['String']['input']>
    phone_not_contains_i?: InputMaybe<Scalars['String']['input']>
    phone_not_ends_with?: InputMaybe<Scalars['String']['input']>
    phone_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    phone_not_i?: InputMaybe<Scalars['String']['input']>
    phone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phone_not_starts_with?: InputMaybe<Scalars['String']['input']>
    phone_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    phone_starts_with?: InputMaybe<Scalars['String']['input']>
    phone_starts_with_i?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type NotificationAnonymousSettingHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type NotificationAnonymousSettingHistoryRecordsCreateInput = {
    data?: InputMaybe<NotificationAnonymousSettingHistoryRecordCreateInput>
}

export type NotificationAnonymousSettingHistoryRecordsUpdateInput = {
    data?: InputMaybe<NotificationAnonymousSettingHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export enum NotificationAnonymousSettingMessageTransportType {
    Email = 'email',
    Sms = 'sms',
}

export type NotificationAnonymousSettingUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    email?: InputMaybe<Scalars['String']['input']>
    isEnabled?: InputMaybe<Scalars['Boolean']['input']>
    messageTransport?: InputMaybe<NotificationAnonymousSettingMessageTransportType>
    messageType?: InputMaybe<MessageType>
    newId?: InputMaybe<Scalars['String']['input']>
    phone?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type NotificationAnonymousSettingWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<NotificationAnonymousSettingWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<NotificationAnonymousSettingWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    email?: InputMaybe<Scalars['String']['input']>
    email_contains?: InputMaybe<Scalars['String']['input']>
    email_contains_i?: InputMaybe<Scalars['String']['input']>
    email_ends_with?: InputMaybe<Scalars['String']['input']>
    email_ends_with_i?: InputMaybe<Scalars['String']['input']>
    email_i?: InputMaybe<Scalars['String']['input']>
    email_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    email_not?: InputMaybe<Scalars['String']['input']>
    email_not_contains?: InputMaybe<Scalars['String']['input']>
    email_not_contains_i?: InputMaybe<Scalars['String']['input']>
    email_not_ends_with?: InputMaybe<Scalars['String']['input']>
    email_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    email_not_i?: InputMaybe<Scalars['String']['input']>
    email_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    email_not_starts_with?: InputMaybe<Scalars['String']['input']>
    email_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    email_starts_with?: InputMaybe<Scalars['String']['input']>
    email_starts_with_i?: InputMaybe<Scalars['String']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    isEnabled?: InputMaybe<Scalars['Boolean']['input']>
    isEnabled_not?: InputMaybe<Scalars['Boolean']['input']>
    messageTransport?: InputMaybe<NotificationAnonymousSettingMessageTransportType>
    messageTransport_in?: InputMaybe<Array<InputMaybe<NotificationAnonymousSettingMessageTransportType>>>
    messageTransport_not?: InputMaybe<NotificationAnonymousSettingMessageTransportType>
    messageTransport_not_in?: InputMaybe<Array<InputMaybe<NotificationAnonymousSettingMessageTransportType>>>
    messageType?: InputMaybe<MessageType>
    messageType_in?: InputMaybe<Array<InputMaybe<MessageType>>>
    messageType_not?: InputMaybe<MessageType>
    messageType_not_in?: InputMaybe<Array<InputMaybe<MessageType>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phone?: InputMaybe<Scalars['String']['input']>
    phone_contains?: InputMaybe<Scalars['String']['input']>
    phone_contains_i?: InputMaybe<Scalars['String']['input']>
    phone_ends_with?: InputMaybe<Scalars['String']['input']>
    phone_ends_with_i?: InputMaybe<Scalars['String']['input']>
    phone_i?: InputMaybe<Scalars['String']['input']>
    phone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phone_not?: InputMaybe<Scalars['String']['input']>
    phone_not_contains?: InputMaybe<Scalars['String']['input']>
    phone_not_contains_i?: InputMaybe<Scalars['String']['input']>
    phone_not_ends_with?: InputMaybe<Scalars['String']['input']>
    phone_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    phone_not_i?: InputMaybe<Scalars['String']['input']>
    phone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phone_not_starts_with?: InputMaybe<Scalars['String']['input']>
    phone_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    phone_starts_with?: InputMaybe<Scalars['String']['input']>
    phone_starts_with_i?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type NotificationAnonymousSettingWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type NotificationAnonymousSettingsCreateInput = {
    data?: InputMaybe<NotificationAnonymousSettingCreateInput>
}

export type NotificationAnonymousSettingsUpdateInput = {
    data?: InputMaybe<NotificationAnonymousSettingUpdateInput>
    id: Scalars['ID']['input']
}

/**  User controlled notifications settings  */
export type NotificationUserSetting = {
    __typename?: 'NotificationUserSetting'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the NotificationUserSetting List config, or
   *  2. As an alias to the field set on 'labelField' in the NotificationUserSetting List config, or
   *  3. As an alias to a 'name' field on the NotificationUserSetting List (if one exists), or
   *  4. As an alias to the 'id' field on the NotificationUserSetting List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  Is notification enabled  */
    isEnabled?: Maybe<Scalars['Boolean']['output']>
    /**  Affected message transport. Possible values are: sms,email,telegram,push  */
    messageTransport?: Maybe<NotificationUserSettingMessageTransportType>
    /**  Affected message type  */
    messageType?: Maybe<MessageType>
    newId?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    /**  The user the settings will apply for  */
    user?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type NotificationUserSettingCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    isEnabled?: InputMaybe<Scalars['Boolean']['input']>
    messageTransport?: InputMaybe<NotificationUserSettingMessageTransportType>
    messageType?: InputMaybe<MessageType>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type NotificationUserSettingHistoryRecord = {
    __typename?: 'NotificationUserSettingHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the NotificationUserSettingHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the NotificationUserSettingHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the NotificationUserSettingHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the NotificationUserSettingHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<NotificationUserSettingHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    isEnabled?: Maybe<Scalars['Boolean']['output']>
    messageTransport?: Maybe<Scalars['String']['output']>
    messageType?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    user?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type NotificationUserSettingHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<NotificationUserSettingHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    isEnabled?: InputMaybe<Scalars['Boolean']['input']>
    messageTransport?: InputMaybe<Scalars['String']['input']>
    messageType?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum NotificationUserSettingHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type NotificationUserSettingHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<NotificationUserSettingHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    isEnabled?: InputMaybe<Scalars['Boolean']['input']>
    messageTransport?: InputMaybe<Scalars['String']['input']>
    messageType?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type NotificationUserSettingHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<NotificationUserSettingHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<NotificationUserSettingHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<NotificationUserSettingHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<NotificationUserSettingHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<NotificationUserSettingHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<NotificationUserSettingHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    isEnabled?: InputMaybe<Scalars['Boolean']['input']>
    isEnabled_not?: InputMaybe<Scalars['Boolean']['input']>
    messageTransport?: InputMaybe<Scalars['String']['input']>
    messageTransport_contains?: InputMaybe<Scalars['String']['input']>
    messageTransport_contains_i?: InputMaybe<Scalars['String']['input']>
    messageTransport_ends_with?: InputMaybe<Scalars['String']['input']>
    messageTransport_ends_with_i?: InputMaybe<Scalars['String']['input']>
    messageTransport_i?: InputMaybe<Scalars['String']['input']>
    messageTransport_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    messageTransport_not?: InputMaybe<Scalars['String']['input']>
    messageTransport_not_contains?: InputMaybe<Scalars['String']['input']>
    messageTransport_not_contains_i?: InputMaybe<Scalars['String']['input']>
    messageTransport_not_ends_with?: InputMaybe<Scalars['String']['input']>
    messageTransport_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    messageTransport_not_i?: InputMaybe<Scalars['String']['input']>
    messageTransport_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    messageTransport_not_starts_with?: InputMaybe<Scalars['String']['input']>
    messageTransport_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    messageTransport_starts_with?: InputMaybe<Scalars['String']['input']>
    messageTransport_starts_with_i?: InputMaybe<Scalars['String']['input']>
    messageType?: InputMaybe<Scalars['String']['input']>
    messageType_contains?: InputMaybe<Scalars['String']['input']>
    messageType_contains_i?: InputMaybe<Scalars['String']['input']>
    messageType_ends_with?: InputMaybe<Scalars['String']['input']>
    messageType_ends_with_i?: InputMaybe<Scalars['String']['input']>
    messageType_i?: InputMaybe<Scalars['String']['input']>
    messageType_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    messageType_not?: InputMaybe<Scalars['String']['input']>
    messageType_not_contains?: InputMaybe<Scalars['String']['input']>
    messageType_not_contains_i?: InputMaybe<Scalars['String']['input']>
    messageType_not_ends_with?: InputMaybe<Scalars['String']['input']>
    messageType_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    messageType_not_i?: InputMaybe<Scalars['String']['input']>
    messageType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    messageType_not_starts_with?: InputMaybe<Scalars['String']['input']>
    messageType_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    messageType_starts_with?: InputMaybe<Scalars['String']['input']>
    messageType_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user?: InputMaybe<Scalars['String']['input']>
    user_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user_not?: InputMaybe<Scalars['String']['input']>
    user_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type NotificationUserSettingHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type NotificationUserSettingHistoryRecordsCreateInput = {
    data?: InputMaybe<NotificationUserSettingHistoryRecordCreateInput>
}

export type NotificationUserSettingHistoryRecordsUpdateInput = {
    data?: InputMaybe<NotificationUserSettingHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export enum NotificationUserSettingMessageTransportType {
    Email = 'email',
    Push = 'push',
    Sms = 'sms',
    Telegram = 'telegram',
}

export type NotificationUserSettingUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    isEnabled?: InputMaybe<Scalars['Boolean']['input']>
    messageTransport?: InputMaybe<NotificationUserSettingMessageTransportType>
    messageType?: InputMaybe<MessageType>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type NotificationUserSettingWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<NotificationUserSettingWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<NotificationUserSettingWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    isEnabled?: InputMaybe<Scalars['Boolean']['input']>
    isEnabled_not?: InputMaybe<Scalars['Boolean']['input']>
    messageTransport?: InputMaybe<NotificationUserSettingMessageTransportType>
    messageTransport_in?: InputMaybe<Array<InputMaybe<NotificationUserSettingMessageTransportType>>>
    messageTransport_not?: InputMaybe<NotificationUserSettingMessageTransportType>
    messageTransport_not_in?: InputMaybe<Array<InputMaybe<NotificationUserSettingMessageTransportType>>>
    messageType?: InputMaybe<MessageType>
    messageType_in?: InputMaybe<Array<InputMaybe<MessageType>>>
    messageType_not?: InputMaybe<MessageType>
    messageType_not_in?: InputMaybe<Array<InputMaybe<MessageType>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    user?: InputMaybe<UserWhereInput>
    user_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type NotificationUserSettingWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type NotificationUserSettingsCreateInput = {
    data?: InputMaybe<NotificationUserSettingCreateInput>
}

export type NotificationUserSettingsUpdateInput = {
    data?: InputMaybe<NotificationUserSettingUpdateInput>
    id: Scalars['ID']['input']
}

/**  The OIDC clients list  */
export type OidcClient = {
    __typename?: 'OidcClient'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the OidcClient List config, or
   *  2. As an alias to the field set on 'labelField' in the OidcClient List config, or
   *  3. As an alias to a 'name' field on the OidcClient List (if one exists), or
   *  4. As an alias to the 'id' field on the OidcClient List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  A switch that allows this OIDC client to authorize users with privileges, such as admins, support, or users with a special rights set. Temporary solution to allow OIDCs to be used to access the admin panel of mini-applications from condo ecosystem. Tokens with scopes should solve the logic separation problem and remove this checkbox  */
    canAuthorizeSuperUsers?: Maybe<Scalars['Boolean']['output']>
    /**  The clientId  */
    clientId?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  The timestamp of the client expiration  */
    expiresAt?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    /**  ID of the object in the system from which it was imported  */
    importId?: Maybe<Scalars['String']['output']>
    /**  Name of the system from which object was imported  */
    importRemoteSystem?: Maybe<Scalars['String']['output']>
    /**  A switch that allows you to disable some OIDC clients. If an OIDC client is disabled, it cannot be used for OIDC authorization. Used mainly by developers portal to create OIDC client before publishing an application, and enable OIDC after publishing.  */
    isEnabled?: Maybe<Scalars['Boolean']['output']>
    /**  The additional client data  */
    meta?: Maybe<Scalars['JSON']['output']>
    /**  The human readable name for client  */
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  The payload of the client (clientId, clientSecret, callbackUrl, ...)  */
    payload?: Maybe<Scalars['JSON']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type OidcClientCreateInput = {
    canAuthorizeSuperUsers?: InputMaybe<Scalars['Boolean']['input']>
    clientId?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    expiresAt?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem?: InputMaybe<Scalars['String']['input']>
    isEnabled?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    payload?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type OidcClientHistoryRecord = {
    __typename?: 'OidcClientHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the OidcClientHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the OidcClientHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the OidcClientHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the OidcClientHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    canAuthorizeSuperUsers?: Maybe<Scalars['Boolean']['output']>
    clientId?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    expiresAt?: Maybe<Scalars['String']['output']>
    history_action?: Maybe<OidcClientHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    isEnabled?: Maybe<Scalars['Boolean']['output']>
    meta?: Maybe<Scalars['JSON']['output']>
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    payload?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type OidcClientHistoryRecordCreateInput = {
    canAuthorizeSuperUsers?: InputMaybe<Scalars['Boolean']['input']>
    clientId?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    expiresAt?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<OidcClientHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    isEnabled?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    payload?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum OidcClientHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type OidcClientHistoryRecordUpdateInput = {
    canAuthorizeSuperUsers?: InputMaybe<Scalars['Boolean']['input']>
    clientId?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    expiresAt?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<OidcClientHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    isEnabled?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    payload?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type OidcClientHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<OidcClientHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<OidcClientHistoryRecordWhereInput>>>
    canAuthorizeSuperUsers?: InputMaybe<Scalars['Boolean']['input']>
    canAuthorizeSuperUsers_not?: InputMaybe<Scalars['Boolean']['input']>
    clientId?: InputMaybe<Scalars['String']['input']>
    clientId_contains?: InputMaybe<Scalars['String']['input']>
    clientId_contains_i?: InputMaybe<Scalars['String']['input']>
    clientId_ends_with?: InputMaybe<Scalars['String']['input']>
    clientId_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientId_i?: InputMaybe<Scalars['String']['input']>
    clientId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientId_not?: InputMaybe<Scalars['String']['input']>
    clientId_not_contains?: InputMaybe<Scalars['String']['input']>
    clientId_not_contains_i?: InputMaybe<Scalars['String']['input']>
    clientId_not_ends_with?: InputMaybe<Scalars['String']['input']>
    clientId_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientId_not_i?: InputMaybe<Scalars['String']['input']>
    clientId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientId_not_starts_with?: InputMaybe<Scalars['String']['input']>
    clientId_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    clientId_starts_with?: InputMaybe<Scalars['String']['input']>
    clientId_starts_with_i?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    expiresAt?: InputMaybe<Scalars['String']['input']>
    expiresAt_gt?: InputMaybe<Scalars['String']['input']>
    expiresAt_gte?: InputMaybe<Scalars['String']['input']>
    expiresAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    expiresAt_lt?: InputMaybe<Scalars['String']['input']>
    expiresAt_lte?: InputMaybe<Scalars['String']['input']>
    expiresAt_not?: InputMaybe<Scalars['String']['input']>
    expiresAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_action?: InputMaybe<OidcClientHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<OidcClientHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<OidcClientHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<OidcClientHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    isEnabled?: InputMaybe<Scalars['Boolean']['input']>
    isEnabled_not?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    payload?: InputMaybe<Scalars['JSON']['input']>
    payload_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    payload_not?: InputMaybe<Scalars['JSON']['input']>
    payload_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type OidcClientHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type OidcClientHistoryRecordsCreateInput = {
    data?: InputMaybe<OidcClientHistoryRecordCreateInput>
}

export type OidcClientHistoryRecordsUpdateInput = {
    data?: InputMaybe<OidcClientHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type OidcClientUpdateInput = {
    canAuthorizeSuperUsers?: InputMaybe<Scalars['Boolean']['input']>
    clientId?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    expiresAt?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem?: InputMaybe<Scalars['String']['input']>
    isEnabled?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    payload?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type OidcClientWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<OidcClientWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<OidcClientWhereInput>>>
    canAuthorizeSuperUsers?: InputMaybe<Scalars['Boolean']['input']>
    canAuthorizeSuperUsers_not?: InputMaybe<Scalars['Boolean']['input']>
    clientId?: InputMaybe<Scalars['String']['input']>
    clientId_contains?: InputMaybe<Scalars['String']['input']>
    clientId_contains_i?: InputMaybe<Scalars['String']['input']>
    clientId_ends_with?: InputMaybe<Scalars['String']['input']>
    clientId_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientId_i?: InputMaybe<Scalars['String']['input']>
    clientId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientId_not?: InputMaybe<Scalars['String']['input']>
    clientId_not_contains?: InputMaybe<Scalars['String']['input']>
    clientId_not_contains_i?: InputMaybe<Scalars['String']['input']>
    clientId_not_ends_with?: InputMaybe<Scalars['String']['input']>
    clientId_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientId_not_i?: InputMaybe<Scalars['String']['input']>
    clientId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientId_not_starts_with?: InputMaybe<Scalars['String']['input']>
    clientId_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    clientId_starts_with?: InputMaybe<Scalars['String']['input']>
    clientId_starts_with_i?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    expiresAt?: InputMaybe<Scalars['String']['input']>
    expiresAt_gt?: InputMaybe<Scalars['String']['input']>
    expiresAt_gte?: InputMaybe<Scalars['String']['input']>
    expiresAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    expiresAt_lt?: InputMaybe<Scalars['String']['input']>
    expiresAt_lte?: InputMaybe<Scalars['String']['input']>
    expiresAt_not?: InputMaybe<Scalars['String']['input']>
    expiresAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    importId?: InputMaybe<Scalars['String']['input']>
    importId_contains?: InputMaybe<Scalars['String']['input']>
    importId_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_i?: InputMaybe<Scalars['String']['input']>
    importId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not?: InputMaybe<Scalars['String']['input']>
    importId_not_contains?: InputMaybe<Scalars['String']['input']>
    importId_not_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_not_i?: InputMaybe<Scalars['String']['input']>
    importId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importId_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_contains?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_contains_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_ends_with?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importRemoteSystem_not?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_contains?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_contains_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_ends_with?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importRemoteSystem_not_starts_with?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_starts_with?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_starts_with_i?: InputMaybe<Scalars['String']['input']>
    isEnabled?: InputMaybe<Scalars['Boolean']['input']>
    isEnabled_not?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    payload?: InputMaybe<Scalars['JSON']['input']>
    payload_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    payload_not?: InputMaybe<Scalars['JSON']['input']>
    payload_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type OidcClientWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type OidcClientsCreateInput = {
    data?: InputMaybe<OidcClientCreateInput>
}

export type OidcClientsUpdateInput = {
    data?: InputMaybe<OidcClientUpdateInput>
    id: Scalars['ID']['input']
}

/**  B2B customer of the service, a legal entity or an association of legal entities (holding/group)  */
export type Organization = {
    __typename?: 'Organization'
    _employeesMeta?: Maybe<_QueryMeta>
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the Organization List config, or
   *  2. As an alias to the field set on 'labelField' in the Organization List config, or
   *  3. As an alias to a 'name' field on the Organization List (if one exists), or
   *  4. As an alias to the 'id' field on the Organization List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    _relatedOrganizationsMeta?: Maybe<_QueryMeta>
    /**  Customer-friendly avatar  */
    avatar?: Maybe<File>
    /**  Country level specific  */
    country?: Maybe<OrganizationCountryType>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    /**  Default employee role status transitions map which will be used as fallback for status transition validationif user dont have OrganizationEmployeeRole  */
    defaultEmployeeRoleStatusTransitions?: Maybe<Scalars['JSON']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Customer-friendly description. Friendly text for employee and resident users  */
    description?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    employees: Array<OrganizationEmployee>
    /**  List of the organization's features connected by a particular integration  */
    features: Array<OrganizationFeature>
    id: Scalars['ID']['output']
    /**  External system organization id. Used for integrations  */
    importId?: Maybe<Scalars['String']['output']>
    /**  External provider for organization  */
    importRemoteSystem?: Maybe<Scalars['String']['output']>
    /**
   *  @deprecated
   * Indicates that organization is real.
   * This is a service field, you should not implement logic using it.
   */
    isApproved?: Maybe<Scalars['Boolean']['output']>
    /**  Organization metadata. Depends on country level specificExample of data key: `kpp`  */
    meta?: Maybe<Scalars['JSON']['output']>
    /**  Customer-friendly name  */
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Normalized organization phone in E.164 format without spaces  */
    phone?: Maybe<Scalars['String']['output']>
    /**
   *  Numeric identifier assigned to a specific line in software for calling.
   * Used when outgoing call before the number to be called.
   * For example phoneNumberPrefix = 01, then the result phone number to be called = 01{phone number}.
   */
    phoneNumberPrefix?: Maybe<Scalars['String']['output']>
    relatedOrganizations: Array<OrganizationLink>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Graph of possible transitions for statuses. If there is no transition in this graph, it is impossible to change status if the user in the role has the right to do so.  */
    statusTransitions?: Maybe<Scalars['JSON']['output']>
    /**  Taxpayer identification number. Every country has its own identification. Examples: INN for Russia, IIN for Kazakhstan and so on  */
    tin?: Maybe<Scalars['String']['output']>
    /**  Type of organization. Organizations with different types see slightly different interfaces. In addition, some of the logic depends on this field: 1. Residents can be connected to only "MANAGING_COMPANY" organization2. OrganizationLink cannot be created if parent organization is not "HOLDING"  */
    type?: Maybe<OrganizationTypeType>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}


/**  B2B customer of the service, a legal entity or an association of legal entities (holding/group)  */
export type Organization_EmployeesMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortOrganizationEmployeesBy>>
    where?: InputMaybe<OrganizationEmployeeWhereInput>
}


/**  B2B customer of the service, a legal entity or an association of legal entities (holding/group)  */
export type Organization_RelatedOrganizationsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortOrganizationLinksBy>>
    where?: InputMaybe<OrganizationLinkWhereInput>
}


/**  B2B customer of the service, a legal entity or an association of legal entities (holding/group)  */
export type OrganizationEmployeesArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortOrganizationEmployeesBy>>
    where?: InputMaybe<OrganizationEmployeeWhereInput>
}


/**  B2B customer of the service, a legal entity or an association of legal entities (holding/group)  */
export type OrganizationRelatedOrganizationsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortOrganizationLinksBy>>
    where?: InputMaybe<OrganizationLinkWhereInput>
}

export enum OrganizationCountryType {
    En = 'en',
    Ru = 'ru',
}

export type OrganizationCreateInput = {
    avatar?: InputMaybe<Scalars['Upload']['input']>
    country?: InputMaybe<OrganizationCountryType>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    description?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    employees?: InputMaybe<OrganizationEmployeeRelateToManyInput>
    features?: InputMaybe<Array<OrganizationFeature>>
    importId?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem?: InputMaybe<Scalars['String']['input']>
    isApproved?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    phone?: InputMaybe<Scalars['String']['input']>
    phoneNumberPrefix?: InputMaybe<Scalars['String']['input']>
    relatedOrganizations?: InputMaybe<OrganizationLinkRelateToManyInput>
    sender?: InputMaybe<SenderFieldInput>
    tin?: InputMaybe<Scalars['String']['input']>
    type?: InputMaybe<OrganizationTypeType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  B2B customer employees. For invite employee should use inviteNewOrganizationEmployee and reInviteOrganizationEmployee  */
export type OrganizationEmployee = {
    __typename?: 'OrganizationEmployee'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the OrganizationEmployee List config, or
   *  2. As an alias to the field set on 'labelField' in the OrganizationEmployee List config, or
   *  3. As an alias to a 'name' field on the OrganizationEmployee List (if one exists), or
   *  4. As an alias to the 'id' field on the OrganizationEmployee List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    email?: Maybe<Scalars['String']['output']>
    /**  True if employee has all specializations  */
    hasAllSpecializations?: Maybe<Scalars['Boolean']['output']>
    id: Scalars['ID']['output']
    /**  Secret invite code (used for accept invite verification)  */
    inviteCode?: Maybe<Scalars['ID']['output']>
    isAccepted?: Maybe<Scalars['Boolean']['output']>
    /**  Employee is blocked status, used in permissions functions, isBlocked has Free-form description of the employee's position over all permissions  */
    isBlocked?: Maybe<Scalars['Boolean']['output']>
    isRejected?: Maybe<Scalars['Boolean']['output']>
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
    organization?: Maybe<Organization>
    phone?: Maybe<Scalars['String']['output']>
    /**  Free-form description of the employee's position  */
    position?: Maybe<Scalars['String']['output']>
    role?: Maybe<OrganizationEmployeeRole>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    /**  If user exists => invite is matched by email/phone (user can reject or accept it)  */
    user?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type OrganizationEmployeeCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    email?: InputMaybe<Scalars['String']['input']>
    hasAllSpecializations?: InputMaybe<Scalars['Boolean']['input']>
    inviteCode?: InputMaybe<Scalars['ID']['input']>
    isAccepted?: InputMaybe<Scalars['Boolean']['input']>
    isBlocked?: InputMaybe<Scalars['Boolean']['input']>
    isRejected?: InputMaybe<Scalars['Boolean']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    phone?: InputMaybe<Scalars['String']['input']>
    position?: InputMaybe<Scalars['String']['input']>
    role?: InputMaybe<OrganizationEmployeeRoleRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type OrganizationEmployeeHistoryRecord = {
    __typename?: 'OrganizationEmployeeHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the OrganizationEmployeeHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the OrganizationEmployeeHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the OrganizationEmployeeHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the OrganizationEmployeeHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    email?: Maybe<Scalars['String']['output']>
    hasAllSpecializations?: Maybe<Scalars['Boolean']['output']>
    history_action?: Maybe<OrganizationEmployeeHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    inviteCode?: Maybe<Scalars['ID']['output']>
    isAccepted?: Maybe<Scalars['Boolean']['output']>
    isBlocked?: Maybe<Scalars['Boolean']['output']>
    isRejected?: Maybe<Scalars['Boolean']['output']>
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    phone?: Maybe<Scalars['String']['output']>
    position?: Maybe<Scalars['String']['output']>
    role?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    user?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type OrganizationEmployeeHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    email?: InputMaybe<Scalars['String']['input']>
    hasAllSpecializations?: InputMaybe<Scalars['Boolean']['input']>
    history_action?: InputMaybe<OrganizationEmployeeHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    inviteCode?: InputMaybe<Scalars['ID']['input']>
    isAccepted?: InputMaybe<Scalars['Boolean']['input']>
    isBlocked?: InputMaybe<Scalars['Boolean']['input']>
    isRejected?: InputMaybe<Scalars['Boolean']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    phone?: InputMaybe<Scalars['String']['input']>
    position?: InputMaybe<Scalars['String']['input']>
    role?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum OrganizationEmployeeHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type OrganizationEmployeeHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    email?: InputMaybe<Scalars['String']['input']>
    hasAllSpecializations?: InputMaybe<Scalars['Boolean']['input']>
    history_action?: InputMaybe<OrganizationEmployeeHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    inviteCode?: InputMaybe<Scalars['ID']['input']>
    isAccepted?: InputMaybe<Scalars['Boolean']['input']>
    isBlocked?: InputMaybe<Scalars['Boolean']['input']>
    isRejected?: InputMaybe<Scalars['Boolean']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    phone?: InputMaybe<Scalars['String']['input']>
    position?: InputMaybe<Scalars['String']['input']>
    role?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type OrganizationEmployeeHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<OrganizationEmployeeHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<OrganizationEmployeeHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    email?: InputMaybe<Scalars['String']['input']>
    email_contains?: InputMaybe<Scalars['String']['input']>
    email_contains_i?: InputMaybe<Scalars['String']['input']>
    email_ends_with?: InputMaybe<Scalars['String']['input']>
    email_ends_with_i?: InputMaybe<Scalars['String']['input']>
    email_i?: InputMaybe<Scalars['String']['input']>
    email_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    email_not?: InputMaybe<Scalars['String']['input']>
    email_not_contains?: InputMaybe<Scalars['String']['input']>
    email_not_contains_i?: InputMaybe<Scalars['String']['input']>
    email_not_ends_with?: InputMaybe<Scalars['String']['input']>
    email_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    email_not_i?: InputMaybe<Scalars['String']['input']>
    email_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    email_not_starts_with?: InputMaybe<Scalars['String']['input']>
    email_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    email_starts_with?: InputMaybe<Scalars['String']['input']>
    email_starts_with_i?: InputMaybe<Scalars['String']['input']>
    hasAllSpecializations?: InputMaybe<Scalars['Boolean']['input']>
    hasAllSpecializations_not?: InputMaybe<Scalars['Boolean']['input']>
    history_action?: InputMaybe<OrganizationEmployeeHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<OrganizationEmployeeHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<OrganizationEmployeeHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<OrganizationEmployeeHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    inviteCode?: InputMaybe<Scalars['ID']['input']>
    inviteCode_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    inviteCode_not?: InputMaybe<Scalars['ID']['input']>
    inviteCode_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    isAccepted?: InputMaybe<Scalars['Boolean']['input']>
    isAccepted_not?: InputMaybe<Scalars['Boolean']['input']>
    isBlocked?: InputMaybe<Scalars['Boolean']['input']>
    isBlocked_not?: InputMaybe<Scalars['Boolean']['input']>
    isRejected?: InputMaybe<Scalars['Boolean']['input']>
    isRejected_not?: InputMaybe<Scalars['Boolean']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phone?: InputMaybe<Scalars['String']['input']>
    phone_contains?: InputMaybe<Scalars['String']['input']>
    phone_contains_i?: InputMaybe<Scalars['String']['input']>
    phone_ends_with?: InputMaybe<Scalars['String']['input']>
    phone_ends_with_i?: InputMaybe<Scalars['String']['input']>
    phone_i?: InputMaybe<Scalars['String']['input']>
    phone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phone_not?: InputMaybe<Scalars['String']['input']>
    phone_not_contains?: InputMaybe<Scalars['String']['input']>
    phone_not_contains_i?: InputMaybe<Scalars['String']['input']>
    phone_not_ends_with?: InputMaybe<Scalars['String']['input']>
    phone_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    phone_not_i?: InputMaybe<Scalars['String']['input']>
    phone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phone_not_starts_with?: InputMaybe<Scalars['String']['input']>
    phone_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    phone_starts_with?: InputMaybe<Scalars['String']['input']>
    phone_starts_with_i?: InputMaybe<Scalars['String']['input']>
    position?: InputMaybe<Scalars['String']['input']>
    position_contains?: InputMaybe<Scalars['String']['input']>
    position_contains_i?: InputMaybe<Scalars['String']['input']>
    position_ends_with?: InputMaybe<Scalars['String']['input']>
    position_ends_with_i?: InputMaybe<Scalars['String']['input']>
    position_i?: InputMaybe<Scalars['String']['input']>
    position_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    position_not?: InputMaybe<Scalars['String']['input']>
    position_not_contains?: InputMaybe<Scalars['String']['input']>
    position_not_contains_i?: InputMaybe<Scalars['String']['input']>
    position_not_ends_with?: InputMaybe<Scalars['String']['input']>
    position_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    position_not_i?: InputMaybe<Scalars['String']['input']>
    position_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    position_not_starts_with?: InputMaybe<Scalars['String']['input']>
    position_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    position_starts_with?: InputMaybe<Scalars['String']['input']>
    position_starts_with_i?: InputMaybe<Scalars['String']['input']>
    role?: InputMaybe<Scalars['String']['input']>
    role_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    role_not?: InputMaybe<Scalars['String']['input']>
    role_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user?: InputMaybe<Scalars['String']['input']>
    user_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user_not?: InputMaybe<Scalars['String']['input']>
    user_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type OrganizationEmployeeHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type OrganizationEmployeeHistoryRecordsCreateInput = {
    data?: InputMaybe<OrganizationEmployeeHistoryRecordCreateInput>
}

export type OrganizationEmployeeHistoryRecordsUpdateInput = {
    data?: InputMaybe<OrganizationEmployeeHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type OrganizationEmployeeRelateToManyInput = {
    connect?: InputMaybe<Array<InputMaybe<OrganizationEmployeeWhereUniqueInput>>>
    create?: InputMaybe<Array<InputMaybe<OrganizationEmployeeCreateInput>>>
    disconnect?: InputMaybe<Array<InputMaybe<OrganizationEmployeeWhereUniqueInput>>>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type OrganizationEmployeeRelateToOneInput = {
    connect?: InputMaybe<OrganizationEmployeeWhereUniqueInput>
    create?: InputMaybe<OrganizationEmployeeCreateInput>
    disconnect?: InputMaybe<OrganizationEmployeeWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

/**  Employee role name and access permissions  */
export type OrganizationEmployeeRole = {
    __typename?: 'OrganizationEmployeeRole'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the OrganizationEmployeeRole List config, or
   *  2. As an alias to the field set on 'labelField' in the OrganizationEmployeeRole List config, or
   *  3. As an alias to a 'name' field on the OrganizationEmployeeRole List (if one exists), or
   *  4. As an alias to the 'id' field on the OrganizationEmployeeRole List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  Allows employees with this role to be assigned to tickets as executor  */
    canBeAssignedAsExecutor?: Maybe<Scalars['Boolean']['output']>
    /**  Allows employees with this role to be assigned to tickets as responsible  */
    canBeAssignedAsResponsible?: Maybe<Scalars['Boolean']['output']>
    canDownloadCallRecords?: Maybe<Scalars['Boolean']['output']>
    canImportBillingReceipts?: Maybe<Scalars['Boolean']['output']>
    canInviteNewOrganizationEmployees?: Maybe<Scalars['Boolean']['output']>
    canManageB2BApps?: Maybe<Scalars['Boolean']['output']>
    canManageBankAccountReportTasks?: Maybe<Scalars['Boolean']['output']>
    canManageBankAccountReports?: Maybe<Scalars['Boolean']['output']>
    canManageBankAccounts?: Maybe<Scalars['Boolean']['output']>
    canManageBankContractorAccounts?: Maybe<Scalars['Boolean']['output']>
    canManageBankIntegrationAccountContexts?: Maybe<Scalars['Boolean']['output']>
    canManageBankIntegrationOrganizationContexts?: Maybe<Scalars['Boolean']['output']>
    canManageBankTransactions?: Maybe<Scalars['Boolean']['output']>
    canManageCallRecords?: Maybe<Scalars['Boolean']['output']>
    canManageContactRoles?: Maybe<Scalars['Boolean']['output']>
    canManageContacts?: Maybe<Scalars['Boolean']['output']>
    canManageDocuments?: Maybe<Scalars['Boolean']['output']>
    canManageEmployees?: Maybe<Scalars['Boolean']['output']>
    canManageIncidents?: Maybe<Scalars['Boolean']['output']>
    canManageIntegrations?: Maybe<Scalars['Boolean']['output']>
    canManageInvoices?: Maybe<Scalars['Boolean']['output']>
    canManageMarketItemPrices?: Maybe<Scalars['Boolean']['output']>
    canManageMarketItems?: Maybe<Scalars['Boolean']['output']>
    canManageMarketPriceScopes?: Maybe<Scalars['Boolean']['output']>
    canManageMarketSetting?: Maybe<Scalars['Boolean']['output']>
    canManageMarketplace?: Maybe<Scalars['Boolean']['output']>
    canManageMeterReadings?: Maybe<Scalars['Boolean']['output']>
    canManageMeters?: Maybe<Scalars['Boolean']['output']>
    canManageMobileFeatureConfigs?: Maybe<Scalars['Boolean']['output']>
    canManageNewsItemTemplates?: Maybe<Scalars['Boolean']['output']>
    canManageNewsItems?: Maybe<Scalars['Boolean']['output']>
    canManageOrganization?: Maybe<Scalars['Boolean']['output']>
    canManageProperties?: Maybe<Scalars['Boolean']['output']>
    canManagePropertyScopes?: Maybe<Scalars['Boolean']['output']>
    canManageRoles?: Maybe<Scalars['Boolean']['output']>
    canManageTicketAutoAssignments?: Maybe<Scalars['Boolean']['output']>
    canManageTicketComments?: Maybe<Scalars['Boolean']['output']>
    canManageTicketPropertyHints?: Maybe<Scalars['Boolean']['output']>
    canManageTickets?: Maybe<Scalars['Boolean']['output']>
    canManageTour?: Maybe<Scalars['Boolean']['output']>
    canReadAnalytics?: Maybe<Scalars['Boolean']['output']>
    canReadBillingReceipts?: Maybe<Scalars['Boolean']['output']>
    canReadCallRecords?: Maybe<Scalars['Boolean']['output']>
    canReadContacts?: Maybe<Scalars['Boolean']['output']>
    canReadDocuments?: Maybe<Scalars['Boolean']['output']>
    canReadEmployees?: Maybe<Scalars['Boolean']['output']>
    canReadExternalReports?: Maybe<Scalars['Boolean']['output']>
    canReadIncidents?: Maybe<Scalars['Boolean']['output']>
    canReadInvoices?: Maybe<Scalars['Boolean']['output']>
    canReadMarketItemPrices?: Maybe<Scalars['Boolean']['output']>
    canReadMarketItems?: Maybe<Scalars['Boolean']['output']>
    canReadMarketPriceScopes?: Maybe<Scalars['Boolean']['output']>
    canReadMarketSetting?: Maybe<Scalars['Boolean']['output']>
    canReadMarketplace?: Maybe<Scalars['Boolean']['output']>
    canReadMeters?: Maybe<Scalars['Boolean']['output']>
    canReadNewsItems?: Maybe<Scalars['Boolean']['output']>
    canReadPayments?: Maybe<Scalars['Boolean']['output']>
    canReadPaymentsWithInvoices?: Maybe<Scalars['Boolean']['output']>
    canReadProperties?: Maybe<Scalars['Boolean']['output']>
    canReadServices?: Maybe<Scalars['Boolean']['output']>
    canReadSettings?: Maybe<Scalars['Boolean']['output']>
    canReadTickets?: Maybe<Scalars['Boolean']['output']>
    canReadTour?: Maybe<Scalars['Boolean']['output']>
    canShareTickets?: Maybe<Scalars['Boolean']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Role description. Cannot be changed for default roles  */
    description?: Maybe<Scalars['String']['output']>
    descriptionNonLocalized?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**
   *  (Read-only) Indicates whether the role was added by default when the organization was created.
   * Such roles cannot be deleted and their name, description and “ticketVisibilityType” cannot be changed.
   */
    isDefault?: Maybe<Scalars['Boolean']['output']>
    /**  (Read-only) Indicates whether the role can be edited  */
    isEditable?: Maybe<Scalars['Boolean']['output']>
    /**  Role name. Cannot be changed for default roles  */
    name?: Maybe<Scalars['String']['output']>
    nameNonLocalized?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
    organization?: Maybe<Organization>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Employee status transitions map  */
    statusTransitions?: Maybe<Scalars['JSON']['output']>
    /**
   *  Which tickets the employee sees:
   * 1) organization - sees all tickets in the organization.
   * 2) property - Sees tickets in PropertyScope that have this employee
   * 3) propertyAndSpecialization - Sees tickets by employee specialization + PropertyScope
   * 4) assigned - sees only those tickets in which he is the executor or responsible
   */
    ticketVisibilityType?: Maybe<OrganizationEmployeeRoleTicketVisibilityTypeType>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type OrganizationEmployeeRoleCreateInput = {
    canBeAssignedAsExecutor?: InputMaybe<Scalars['Boolean']['input']>
    canBeAssignedAsResponsible?: InputMaybe<Scalars['Boolean']['input']>
    canDownloadCallRecords?: InputMaybe<Scalars['Boolean']['input']>
    canImportBillingReceipts?: InputMaybe<Scalars['Boolean']['input']>
    canInviteNewOrganizationEmployees?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BApps?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankAccountReportTasks?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankAccountReports?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankAccounts?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankContractorAccounts?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankIntegrationAccountContexts?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankIntegrationOrganizationContexts?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankTransactions?: InputMaybe<Scalars['Boolean']['input']>
    canManageCallRecords?: InputMaybe<Scalars['Boolean']['input']>
    canManageContactRoles?: InputMaybe<Scalars['Boolean']['input']>
    canManageContacts?: InputMaybe<Scalars['Boolean']['input']>
    canManageDocuments?: InputMaybe<Scalars['Boolean']['input']>
    canManageEmployees?: InputMaybe<Scalars['Boolean']['input']>
    canManageIncidents?: InputMaybe<Scalars['Boolean']['input']>
    canManageIntegrations?: InputMaybe<Scalars['Boolean']['input']>
    canManageInvoices?: InputMaybe<Scalars['Boolean']['input']>
    canManageMarketItemPrices?: InputMaybe<Scalars['Boolean']['input']>
    canManageMarketItems?: InputMaybe<Scalars['Boolean']['input']>
    canManageMarketPriceScopes?: InputMaybe<Scalars['Boolean']['input']>
    canManageMarketSetting?: InputMaybe<Scalars['Boolean']['input']>
    canManageMarketplace?: InputMaybe<Scalars['Boolean']['input']>
    canManageMeterReadings?: InputMaybe<Scalars['Boolean']['input']>
    canManageMeters?: InputMaybe<Scalars['Boolean']['input']>
    canManageMobileFeatureConfigs?: InputMaybe<Scalars['Boolean']['input']>
    canManageNewsItemTemplates?: InputMaybe<Scalars['Boolean']['input']>
    canManageNewsItems?: InputMaybe<Scalars['Boolean']['input']>
    canManageOrganization?: InputMaybe<Scalars['Boolean']['input']>
    canManageProperties?: InputMaybe<Scalars['Boolean']['input']>
    canManagePropertyScopes?: InputMaybe<Scalars['Boolean']['input']>
    canManageRoles?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketAutoAssignments?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketComments?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketPropertyHints?: InputMaybe<Scalars['Boolean']['input']>
    canManageTickets?: InputMaybe<Scalars['Boolean']['input']>
    canManageTour?: InputMaybe<Scalars['Boolean']['input']>
    canReadAnalytics?: InputMaybe<Scalars['Boolean']['input']>
    canReadBillingReceipts?: InputMaybe<Scalars['Boolean']['input']>
    canReadCallRecords?: InputMaybe<Scalars['Boolean']['input']>
    canReadContacts?: InputMaybe<Scalars['Boolean']['input']>
    canReadDocuments?: InputMaybe<Scalars['Boolean']['input']>
    canReadEmployees?: InputMaybe<Scalars['Boolean']['input']>
    canReadExternalReports?: InputMaybe<Scalars['Boolean']['input']>
    canReadIncidents?: InputMaybe<Scalars['Boolean']['input']>
    canReadInvoices?: InputMaybe<Scalars['Boolean']['input']>
    canReadMarketItemPrices?: InputMaybe<Scalars['Boolean']['input']>
    canReadMarketItems?: InputMaybe<Scalars['Boolean']['input']>
    canReadMarketPriceScopes?: InputMaybe<Scalars['Boolean']['input']>
    canReadMarketSetting?: InputMaybe<Scalars['Boolean']['input']>
    canReadMarketplace?: InputMaybe<Scalars['Boolean']['input']>
    canReadMeters?: InputMaybe<Scalars['Boolean']['input']>
    canReadNewsItems?: InputMaybe<Scalars['Boolean']['input']>
    canReadPayments?: InputMaybe<Scalars['Boolean']['input']>
    canReadPaymentsWithInvoices?: InputMaybe<Scalars['Boolean']['input']>
    canReadProperties?: InputMaybe<Scalars['Boolean']['input']>
    canReadServices?: InputMaybe<Scalars['Boolean']['input']>
    canReadSettings?: InputMaybe<Scalars['Boolean']['input']>
    canReadTickets?: InputMaybe<Scalars['Boolean']['input']>
    canReadTour?: InputMaybe<Scalars['Boolean']['input']>
    canShareTickets?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    description?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    isDefault?: InputMaybe<Scalars['Boolean']['input']>
    isEditable?: InputMaybe<Scalars['Boolean']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    ticketVisibilityType?: InputMaybe<OrganizationEmployeeRoleTicketVisibilityTypeType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type OrganizationEmployeeRoleHistoryRecord = {
    __typename?: 'OrganizationEmployeeRoleHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the OrganizationEmployeeRoleHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the OrganizationEmployeeRoleHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the OrganizationEmployeeRoleHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the OrganizationEmployeeRoleHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    canBeAssignedAsExecutor?: Maybe<Scalars['Boolean']['output']>
    canBeAssignedAsResponsible?: Maybe<Scalars['Boolean']['output']>
    canDownloadCallRecords?: Maybe<Scalars['Boolean']['output']>
    canImportBillingReceipts?: Maybe<Scalars['Boolean']['output']>
    canInviteNewOrganizationEmployees?: Maybe<Scalars['Boolean']['output']>
    canManageB2BApps?: Maybe<Scalars['Boolean']['output']>
    canManageBankAccountReportTasks?: Maybe<Scalars['Boolean']['output']>
    canManageBankAccountReports?: Maybe<Scalars['Boolean']['output']>
    canManageBankAccounts?: Maybe<Scalars['Boolean']['output']>
    canManageBankContractorAccounts?: Maybe<Scalars['Boolean']['output']>
    canManageBankIntegrationAccountContexts?: Maybe<Scalars['Boolean']['output']>
    canManageBankIntegrationOrganizationContexts?: Maybe<Scalars['Boolean']['output']>
    canManageBankTransactions?: Maybe<Scalars['Boolean']['output']>
    canManageCallRecords?: Maybe<Scalars['Boolean']['output']>
    canManageContactRoles?: Maybe<Scalars['Boolean']['output']>
    canManageContacts?: Maybe<Scalars['Boolean']['output']>
    canManageDocuments?: Maybe<Scalars['Boolean']['output']>
    canManageEmployees?: Maybe<Scalars['Boolean']['output']>
    canManageIncidents?: Maybe<Scalars['Boolean']['output']>
    canManageIntegrations?: Maybe<Scalars['Boolean']['output']>
    canManageInvoices?: Maybe<Scalars['Boolean']['output']>
    canManageMarketItemPrices?: Maybe<Scalars['Boolean']['output']>
    canManageMarketItems?: Maybe<Scalars['Boolean']['output']>
    canManageMarketPriceScopes?: Maybe<Scalars['Boolean']['output']>
    canManageMarketSetting?: Maybe<Scalars['Boolean']['output']>
    canManageMarketplace?: Maybe<Scalars['Boolean']['output']>
    canManageMeterReadings?: Maybe<Scalars['Boolean']['output']>
    canManageMeters?: Maybe<Scalars['Boolean']['output']>
    canManageMobileFeatureConfigs?: Maybe<Scalars['Boolean']['output']>
    canManageNewsItemTemplates?: Maybe<Scalars['Boolean']['output']>
    canManageNewsItems?: Maybe<Scalars['Boolean']['output']>
    canManageOrganization?: Maybe<Scalars['Boolean']['output']>
    canManageProperties?: Maybe<Scalars['Boolean']['output']>
    canManagePropertyScopes?: Maybe<Scalars['Boolean']['output']>
    canManageRoles?: Maybe<Scalars['Boolean']['output']>
    canManageTicketAutoAssignments?: Maybe<Scalars['Boolean']['output']>
    canManageTicketComments?: Maybe<Scalars['Boolean']['output']>
    canManageTicketPropertyHints?: Maybe<Scalars['Boolean']['output']>
    canManageTickets?: Maybe<Scalars['Boolean']['output']>
    canManageTour?: Maybe<Scalars['Boolean']['output']>
    canReadAnalytics?: Maybe<Scalars['Boolean']['output']>
    canReadBillingReceipts?: Maybe<Scalars['Boolean']['output']>
    canReadCallRecords?: Maybe<Scalars['Boolean']['output']>
    canReadContacts?: Maybe<Scalars['Boolean']['output']>
    canReadDocuments?: Maybe<Scalars['Boolean']['output']>
    canReadEmployees?: Maybe<Scalars['Boolean']['output']>
    canReadExternalReports?: Maybe<Scalars['Boolean']['output']>
    canReadIncidents?: Maybe<Scalars['Boolean']['output']>
    canReadInvoices?: Maybe<Scalars['Boolean']['output']>
    canReadMarketItemPrices?: Maybe<Scalars['Boolean']['output']>
    canReadMarketItems?: Maybe<Scalars['Boolean']['output']>
    canReadMarketPriceScopes?: Maybe<Scalars['Boolean']['output']>
    canReadMarketSetting?: Maybe<Scalars['Boolean']['output']>
    canReadMarketplace?: Maybe<Scalars['Boolean']['output']>
    canReadMeters?: Maybe<Scalars['Boolean']['output']>
    canReadNewsItems?: Maybe<Scalars['Boolean']['output']>
    canReadPayments?: Maybe<Scalars['Boolean']['output']>
    canReadPaymentsWithInvoices?: Maybe<Scalars['Boolean']['output']>
    canReadProperties?: Maybe<Scalars['Boolean']['output']>
    canReadServices?: Maybe<Scalars['Boolean']['output']>
    canReadSettings?: Maybe<Scalars['Boolean']['output']>
    canReadTickets?: Maybe<Scalars['Boolean']['output']>
    canReadTour?: Maybe<Scalars['Boolean']['output']>
    canShareTickets?: Maybe<Scalars['Boolean']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    description?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<OrganizationEmployeeRoleHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    isDefault?: Maybe<Scalars['Boolean']['output']>
    isEditable?: Maybe<Scalars['Boolean']['output']>
    name?: Maybe<Scalars['String']['output']>
    organization?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    ticketVisibilityType?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type OrganizationEmployeeRoleHistoryRecordCreateInput = {
    canBeAssignedAsExecutor?: InputMaybe<Scalars['Boolean']['input']>
    canBeAssignedAsResponsible?: InputMaybe<Scalars['Boolean']['input']>
    canDownloadCallRecords?: InputMaybe<Scalars['Boolean']['input']>
    canImportBillingReceipts?: InputMaybe<Scalars['Boolean']['input']>
    canInviteNewOrganizationEmployees?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BApps?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankAccountReportTasks?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankAccountReports?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankAccounts?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankContractorAccounts?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankIntegrationAccountContexts?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankIntegrationOrganizationContexts?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankTransactions?: InputMaybe<Scalars['Boolean']['input']>
    canManageCallRecords?: InputMaybe<Scalars['Boolean']['input']>
    canManageContactRoles?: InputMaybe<Scalars['Boolean']['input']>
    canManageContacts?: InputMaybe<Scalars['Boolean']['input']>
    canManageDocuments?: InputMaybe<Scalars['Boolean']['input']>
    canManageEmployees?: InputMaybe<Scalars['Boolean']['input']>
    canManageIncidents?: InputMaybe<Scalars['Boolean']['input']>
    canManageIntegrations?: InputMaybe<Scalars['Boolean']['input']>
    canManageInvoices?: InputMaybe<Scalars['Boolean']['input']>
    canManageMarketItemPrices?: InputMaybe<Scalars['Boolean']['input']>
    canManageMarketItems?: InputMaybe<Scalars['Boolean']['input']>
    canManageMarketPriceScopes?: InputMaybe<Scalars['Boolean']['input']>
    canManageMarketSetting?: InputMaybe<Scalars['Boolean']['input']>
    canManageMarketplace?: InputMaybe<Scalars['Boolean']['input']>
    canManageMeterReadings?: InputMaybe<Scalars['Boolean']['input']>
    canManageMeters?: InputMaybe<Scalars['Boolean']['input']>
    canManageMobileFeatureConfigs?: InputMaybe<Scalars['Boolean']['input']>
    canManageNewsItemTemplates?: InputMaybe<Scalars['Boolean']['input']>
    canManageNewsItems?: InputMaybe<Scalars['Boolean']['input']>
    canManageOrganization?: InputMaybe<Scalars['Boolean']['input']>
    canManageProperties?: InputMaybe<Scalars['Boolean']['input']>
    canManagePropertyScopes?: InputMaybe<Scalars['Boolean']['input']>
    canManageRoles?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketAutoAssignments?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketComments?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketPropertyHints?: InputMaybe<Scalars['Boolean']['input']>
    canManageTickets?: InputMaybe<Scalars['Boolean']['input']>
    canManageTour?: InputMaybe<Scalars['Boolean']['input']>
    canReadAnalytics?: InputMaybe<Scalars['Boolean']['input']>
    canReadBillingReceipts?: InputMaybe<Scalars['Boolean']['input']>
    canReadCallRecords?: InputMaybe<Scalars['Boolean']['input']>
    canReadContacts?: InputMaybe<Scalars['Boolean']['input']>
    canReadDocuments?: InputMaybe<Scalars['Boolean']['input']>
    canReadEmployees?: InputMaybe<Scalars['Boolean']['input']>
    canReadExternalReports?: InputMaybe<Scalars['Boolean']['input']>
    canReadIncidents?: InputMaybe<Scalars['Boolean']['input']>
    canReadInvoices?: InputMaybe<Scalars['Boolean']['input']>
    canReadMarketItemPrices?: InputMaybe<Scalars['Boolean']['input']>
    canReadMarketItems?: InputMaybe<Scalars['Boolean']['input']>
    canReadMarketPriceScopes?: InputMaybe<Scalars['Boolean']['input']>
    canReadMarketSetting?: InputMaybe<Scalars['Boolean']['input']>
    canReadMarketplace?: InputMaybe<Scalars['Boolean']['input']>
    canReadMeters?: InputMaybe<Scalars['Boolean']['input']>
    canReadNewsItems?: InputMaybe<Scalars['Boolean']['input']>
    canReadPayments?: InputMaybe<Scalars['Boolean']['input']>
    canReadPaymentsWithInvoices?: InputMaybe<Scalars['Boolean']['input']>
    canReadProperties?: InputMaybe<Scalars['Boolean']['input']>
    canReadServices?: InputMaybe<Scalars['Boolean']['input']>
    canReadSettings?: InputMaybe<Scalars['Boolean']['input']>
    canReadTickets?: InputMaybe<Scalars['Boolean']['input']>
    canReadTour?: InputMaybe<Scalars['Boolean']['input']>
    canShareTickets?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    description?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<OrganizationEmployeeRoleHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    isDefault?: InputMaybe<Scalars['Boolean']['input']>
    isEditable?: InputMaybe<Scalars['Boolean']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    ticketVisibilityType?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum OrganizationEmployeeRoleHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type OrganizationEmployeeRoleHistoryRecordUpdateInput = {
    canBeAssignedAsExecutor?: InputMaybe<Scalars['Boolean']['input']>
    canBeAssignedAsResponsible?: InputMaybe<Scalars['Boolean']['input']>
    canDownloadCallRecords?: InputMaybe<Scalars['Boolean']['input']>
    canImportBillingReceipts?: InputMaybe<Scalars['Boolean']['input']>
    canInviteNewOrganizationEmployees?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BApps?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankAccountReportTasks?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankAccountReports?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankAccounts?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankContractorAccounts?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankIntegrationAccountContexts?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankIntegrationOrganizationContexts?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankTransactions?: InputMaybe<Scalars['Boolean']['input']>
    canManageCallRecords?: InputMaybe<Scalars['Boolean']['input']>
    canManageContactRoles?: InputMaybe<Scalars['Boolean']['input']>
    canManageContacts?: InputMaybe<Scalars['Boolean']['input']>
    canManageDocuments?: InputMaybe<Scalars['Boolean']['input']>
    canManageEmployees?: InputMaybe<Scalars['Boolean']['input']>
    canManageIncidents?: InputMaybe<Scalars['Boolean']['input']>
    canManageIntegrations?: InputMaybe<Scalars['Boolean']['input']>
    canManageInvoices?: InputMaybe<Scalars['Boolean']['input']>
    canManageMarketItemPrices?: InputMaybe<Scalars['Boolean']['input']>
    canManageMarketItems?: InputMaybe<Scalars['Boolean']['input']>
    canManageMarketPriceScopes?: InputMaybe<Scalars['Boolean']['input']>
    canManageMarketSetting?: InputMaybe<Scalars['Boolean']['input']>
    canManageMarketplace?: InputMaybe<Scalars['Boolean']['input']>
    canManageMeterReadings?: InputMaybe<Scalars['Boolean']['input']>
    canManageMeters?: InputMaybe<Scalars['Boolean']['input']>
    canManageMobileFeatureConfigs?: InputMaybe<Scalars['Boolean']['input']>
    canManageNewsItemTemplates?: InputMaybe<Scalars['Boolean']['input']>
    canManageNewsItems?: InputMaybe<Scalars['Boolean']['input']>
    canManageOrganization?: InputMaybe<Scalars['Boolean']['input']>
    canManageProperties?: InputMaybe<Scalars['Boolean']['input']>
    canManagePropertyScopes?: InputMaybe<Scalars['Boolean']['input']>
    canManageRoles?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketAutoAssignments?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketComments?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketPropertyHints?: InputMaybe<Scalars['Boolean']['input']>
    canManageTickets?: InputMaybe<Scalars['Boolean']['input']>
    canManageTour?: InputMaybe<Scalars['Boolean']['input']>
    canReadAnalytics?: InputMaybe<Scalars['Boolean']['input']>
    canReadBillingReceipts?: InputMaybe<Scalars['Boolean']['input']>
    canReadCallRecords?: InputMaybe<Scalars['Boolean']['input']>
    canReadContacts?: InputMaybe<Scalars['Boolean']['input']>
    canReadDocuments?: InputMaybe<Scalars['Boolean']['input']>
    canReadEmployees?: InputMaybe<Scalars['Boolean']['input']>
    canReadExternalReports?: InputMaybe<Scalars['Boolean']['input']>
    canReadIncidents?: InputMaybe<Scalars['Boolean']['input']>
    canReadInvoices?: InputMaybe<Scalars['Boolean']['input']>
    canReadMarketItemPrices?: InputMaybe<Scalars['Boolean']['input']>
    canReadMarketItems?: InputMaybe<Scalars['Boolean']['input']>
    canReadMarketPriceScopes?: InputMaybe<Scalars['Boolean']['input']>
    canReadMarketSetting?: InputMaybe<Scalars['Boolean']['input']>
    canReadMarketplace?: InputMaybe<Scalars['Boolean']['input']>
    canReadMeters?: InputMaybe<Scalars['Boolean']['input']>
    canReadNewsItems?: InputMaybe<Scalars['Boolean']['input']>
    canReadPayments?: InputMaybe<Scalars['Boolean']['input']>
    canReadPaymentsWithInvoices?: InputMaybe<Scalars['Boolean']['input']>
    canReadProperties?: InputMaybe<Scalars['Boolean']['input']>
    canReadServices?: InputMaybe<Scalars['Boolean']['input']>
    canReadSettings?: InputMaybe<Scalars['Boolean']['input']>
    canReadTickets?: InputMaybe<Scalars['Boolean']['input']>
    canReadTour?: InputMaybe<Scalars['Boolean']['input']>
    canShareTickets?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    description?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<OrganizationEmployeeRoleHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    isDefault?: InputMaybe<Scalars['Boolean']['input']>
    isEditable?: InputMaybe<Scalars['Boolean']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    ticketVisibilityType?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type OrganizationEmployeeRoleHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<OrganizationEmployeeRoleHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<OrganizationEmployeeRoleHistoryRecordWhereInput>>>
    canBeAssignedAsExecutor?: InputMaybe<Scalars['Boolean']['input']>
    canBeAssignedAsExecutor_not?: InputMaybe<Scalars['Boolean']['input']>
    canBeAssignedAsResponsible?: InputMaybe<Scalars['Boolean']['input']>
    canBeAssignedAsResponsible_not?: InputMaybe<Scalars['Boolean']['input']>
    canDownloadCallRecords?: InputMaybe<Scalars['Boolean']['input']>
    canDownloadCallRecords_not?: InputMaybe<Scalars['Boolean']['input']>
    canImportBillingReceipts?: InputMaybe<Scalars['Boolean']['input']>
    canImportBillingReceipts_not?: InputMaybe<Scalars['Boolean']['input']>
    canInviteNewOrganizationEmployees?: InputMaybe<Scalars['Boolean']['input']>
    canInviteNewOrganizationEmployees_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BApps?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BApps_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankAccountReportTasks?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankAccountReportTasks_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankAccountReports?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankAccountReports_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankAccounts?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankAccounts_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankContractorAccounts?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankContractorAccounts_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankIntegrationAccountContexts?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankIntegrationAccountContexts_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankIntegrationOrganizationContexts?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankIntegrationOrganizationContexts_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankTransactions?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankTransactions_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageCallRecords?: InputMaybe<Scalars['Boolean']['input']>
    canManageCallRecords_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageContactRoles?: InputMaybe<Scalars['Boolean']['input']>
    canManageContactRoles_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageContacts?: InputMaybe<Scalars['Boolean']['input']>
    canManageContacts_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageDocuments?: InputMaybe<Scalars['Boolean']['input']>
    canManageDocuments_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageEmployees?: InputMaybe<Scalars['Boolean']['input']>
    canManageEmployees_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageIncidents?: InputMaybe<Scalars['Boolean']['input']>
    canManageIncidents_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageIntegrations?: InputMaybe<Scalars['Boolean']['input']>
    canManageIntegrations_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageInvoices?: InputMaybe<Scalars['Boolean']['input']>
    canManageInvoices_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageMarketItemPrices?: InputMaybe<Scalars['Boolean']['input']>
    canManageMarketItemPrices_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageMarketItems?: InputMaybe<Scalars['Boolean']['input']>
    canManageMarketItems_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageMarketPriceScopes?: InputMaybe<Scalars['Boolean']['input']>
    canManageMarketPriceScopes_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageMarketSetting?: InputMaybe<Scalars['Boolean']['input']>
    canManageMarketSetting_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageMarketplace?: InputMaybe<Scalars['Boolean']['input']>
    canManageMarketplace_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageMeterReadings?: InputMaybe<Scalars['Boolean']['input']>
    canManageMeterReadings_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageMeters?: InputMaybe<Scalars['Boolean']['input']>
    canManageMeters_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageMobileFeatureConfigs?: InputMaybe<Scalars['Boolean']['input']>
    canManageMobileFeatureConfigs_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageNewsItemTemplates?: InputMaybe<Scalars['Boolean']['input']>
    canManageNewsItemTemplates_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageNewsItems?: InputMaybe<Scalars['Boolean']['input']>
    canManageNewsItems_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageOrganization?: InputMaybe<Scalars['Boolean']['input']>
    canManageOrganization_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageProperties?: InputMaybe<Scalars['Boolean']['input']>
    canManageProperties_not?: InputMaybe<Scalars['Boolean']['input']>
    canManagePropertyScopes?: InputMaybe<Scalars['Boolean']['input']>
    canManagePropertyScopes_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageRoles?: InputMaybe<Scalars['Boolean']['input']>
    canManageRoles_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketAutoAssignments?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketAutoAssignments_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketComments?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketComments_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketPropertyHints?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketPropertyHints_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageTickets?: InputMaybe<Scalars['Boolean']['input']>
    canManageTickets_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageTour?: InputMaybe<Scalars['Boolean']['input']>
    canManageTour_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadAnalytics?: InputMaybe<Scalars['Boolean']['input']>
    canReadAnalytics_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadBillingReceipts?: InputMaybe<Scalars['Boolean']['input']>
    canReadBillingReceipts_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadCallRecords?: InputMaybe<Scalars['Boolean']['input']>
    canReadCallRecords_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadContacts?: InputMaybe<Scalars['Boolean']['input']>
    canReadContacts_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadDocuments?: InputMaybe<Scalars['Boolean']['input']>
    canReadDocuments_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadEmployees?: InputMaybe<Scalars['Boolean']['input']>
    canReadEmployees_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadExternalReports?: InputMaybe<Scalars['Boolean']['input']>
    canReadExternalReports_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadIncidents?: InputMaybe<Scalars['Boolean']['input']>
    canReadIncidents_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadInvoices?: InputMaybe<Scalars['Boolean']['input']>
    canReadInvoices_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadMarketItemPrices?: InputMaybe<Scalars['Boolean']['input']>
    canReadMarketItemPrices_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadMarketItems?: InputMaybe<Scalars['Boolean']['input']>
    canReadMarketItems_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadMarketPriceScopes?: InputMaybe<Scalars['Boolean']['input']>
    canReadMarketPriceScopes_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadMarketSetting?: InputMaybe<Scalars['Boolean']['input']>
    canReadMarketSetting_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadMarketplace?: InputMaybe<Scalars['Boolean']['input']>
    canReadMarketplace_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadMeters?: InputMaybe<Scalars['Boolean']['input']>
    canReadMeters_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadNewsItems?: InputMaybe<Scalars['Boolean']['input']>
    canReadNewsItems_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadPayments?: InputMaybe<Scalars['Boolean']['input']>
    canReadPaymentsWithInvoices?: InputMaybe<Scalars['Boolean']['input']>
    canReadPaymentsWithInvoices_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadPayments_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadProperties?: InputMaybe<Scalars['Boolean']['input']>
    canReadProperties_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadServices?: InputMaybe<Scalars['Boolean']['input']>
    canReadServices_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadSettings?: InputMaybe<Scalars['Boolean']['input']>
    canReadSettings_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadTickets?: InputMaybe<Scalars['Boolean']['input']>
    canReadTickets_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadTour?: InputMaybe<Scalars['Boolean']['input']>
    canReadTour_not?: InputMaybe<Scalars['Boolean']['input']>
    canShareTickets?: InputMaybe<Scalars['Boolean']['input']>
    canShareTickets_not?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    description?: InputMaybe<Scalars['String']['input']>
    description_contains?: InputMaybe<Scalars['String']['input']>
    description_contains_i?: InputMaybe<Scalars['String']['input']>
    description_ends_with?: InputMaybe<Scalars['String']['input']>
    description_ends_with_i?: InputMaybe<Scalars['String']['input']>
    description_i?: InputMaybe<Scalars['String']['input']>
    description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    description_not?: InputMaybe<Scalars['String']['input']>
    description_not_contains?: InputMaybe<Scalars['String']['input']>
    description_not_contains_i?: InputMaybe<Scalars['String']['input']>
    description_not_ends_with?: InputMaybe<Scalars['String']['input']>
    description_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    description_not_i?: InputMaybe<Scalars['String']['input']>
    description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    description_not_starts_with?: InputMaybe<Scalars['String']['input']>
    description_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    description_starts_with?: InputMaybe<Scalars['String']['input']>
    description_starts_with_i?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<OrganizationEmployeeRoleHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<OrganizationEmployeeRoleHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<OrganizationEmployeeRoleHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<OrganizationEmployeeRoleHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    isDefault?: InputMaybe<Scalars['Boolean']['input']>
    isDefault_not?: InputMaybe<Scalars['Boolean']['input']>
    isEditable?: InputMaybe<Scalars['Boolean']['input']>
    isEditable_not?: InputMaybe<Scalars['Boolean']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    ticketVisibilityType?: InputMaybe<Scalars['String']['input']>
    ticketVisibilityType_contains?: InputMaybe<Scalars['String']['input']>
    ticketVisibilityType_contains_i?: InputMaybe<Scalars['String']['input']>
    ticketVisibilityType_ends_with?: InputMaybe<Scalars['String']['input']>
    ticketVisibilityType_ends_with_i?: InputMaybe<Scalars['String']['input']>
    ticketVisibilityType_i?: InputMaybe<Scalars['String']['input']>
    ticketVisibilityType_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    ticketVisibilityType_not?: InputMaybe<Scalars['String']['input']>
    ticketVisibilityType_not_contains?: InputMaybe<Scalars['String']['input']>
    ticketVisibilityType_not_contains_i?: InputMaybe<Scalars['String']['input']>
    ticketVisibilityType_not_ends_with?: InputMaybe<Scalars['String']['input']>
    ticketVisibilityType_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    ticketVisibilityType_not_i?: InputMaybe<Scalars['String']['input']>
    ticketVisibilityType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    ticketVisibilityType_not_starts_with?: InputMaybe<Scalars['String']['input']>
    ticketVisibilityType_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    ticketVisibilityType_starts_with?: InputMaybe<Scalars['String']['input']>
    ticketVisibilityType_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type OrganizationEmployeeRoleHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type OrganizationEmployeeRoleHistoryRecordsCreateInput = {
    data?: InputMaybe<OrganizationEmployeeRoleHistoryRecordCreateInput>
}

export type OrganizationEmployeeRoleHistoryRecordsUpdateInput = {
    data?: InputMaybe<OrganizationEmployeeRoleHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type OrganizationEmployeeRoleRelateToOneInput = {
    connect?: InputMaybe<OrganizationEmployeeRoleWhereUniqueInput>
    create?: InputMaybe<OrganizationEmployeeRoleCreateInput>
    disconnect?: InputMaybe<OrganizationEmployeeRoleWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export enum OrganizationEmployeeRoleTicketVisibilityTypeType {
    Assigned = 'assigned',
    Organization = 'organization',
    Property = 'property',
    PropertyAndSpecialization = 'propertyAndSpecialization',
}

export type OrganizationEmployeeRoleUpdateInput = {
    canBeAssignedAsExecutor?: InputMaybe<Scalars['Boolean']['input']>
    canBeAssignedAsResponsible?: InputMaybe<Scalars['Boolean']['input']>
    canDownloadCallRecords?: InputMaybe<Scalars['Boolean']['input']>
    canImportBillingReceipts?: InputMaybe<Scalars['Boolean']['input']>
    canInviteNewOrganizationEmployees?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BApps?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankAccountReportTasks?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankAccountReports?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankAccounts?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankContractorAccounts?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankIntegrationAccountContexts?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankIntegrationOrganizationContexts?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankTransactions?: InputMaybe<Scalars['Boolean']['input']>
    canManageCallRecords?: InputMaybe<Scalars['Boolean']['input']>
    canManageContactRoles?: InputMaybe<Scalars['Boolean']['input']>
    canManageContacts?: InputMaybe<Scalars['Boolean']['input']>
    canManageDocuments?: InputMaybe<Scalars['Boolean']['input']>
    canManageEmployees?: InputMaybe<Scalars['Boolean']['input']>
    canManageIncidents?: InputMaybe<Scalars['Boolean']['input']>
    canManageIntegrations?: InputMaybe<Scalars['Boolean']['input']>
    canManageInvoices?: InputMaybe<Scalars['Boolean']['input']>
    canManageMarketItemPrices?: InputMaybe<Scalars['Boolean']['input']>
    canManageMarketItems?: InputMaybe<Scalars['Boolean']['input']>
    canManageMarketPriceScopes?: InputMaybe<Scalars['Boolean']['input']>
    canManageMarketSetting?: InputMaybe<Scalars['Boolean']['input']>
    canManageMarketplace?: InputMaybe<Scalars['Boolean']['input']>
    canManageMeterReadings?: InputMaybe<Scalars['Boolean']['input']>
    canManageMeters?: InputMaybe<Scalars['Boolean']['input']>
    canManageMobileFeatureConfigs?: InputMaybe<Scalars['Boolean']['input']>
    canManageNewsItemTemplates?: InputMaybe<Scalars['Boolean']['input']>
    canManageNewsItems?: InputMaybe<Scalars['Boolean']['input']>
    canManageOrganization?: InputMaybe<Scalars['Boolean']['input']>
    canManageProperties?: InputMaybe<Scalars['Boolean']['input']>
    canManagePropertyScopes?: InputMaybe<Scalars['Boolean']['input']>
    canManageRoles?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketAutoAssignments?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketComments?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketPropertyHints?: InputMaybe<Scalars['Boolean']['input']>
    canManageTickets?: InputMaybe<Scalars['Boolean']['input']>
    canManageTour?: InputMaybe<Scalars['Boolean']['input']>
    canReadAnalytics?: InputMaybe<Scalars['Boolean']['input']>
    canReadBillingReceipts?: InputMaybe<Scalars['Boolean']['input']>
    canReadCallRecords?: InputMaybe<Scalars['Boolean']['input']>
    canReadContacts?: InputMaybe<Scalars['Boolean']['input']>
    canReadDocuments?: InputMaybe<Scalars['Boolean']['input']>
    canReadEmployees?: InputMaybe<Scalars['Boolean']['input']>
    canReadExternalReports?: InputMaybe<Scalars['Boolean']['input']>
    canReadIncidents?: InputMaybe<Scalars['Boolean']['input']>
    canReadInvoices?: InputMaybe<Scalars['Boolean']['input']>
    canReadMarketItemPrices?: InputMaybe<Scalars['Boolean']['input']>
    canReadMarketItems?: InputMaybe<Scalars['Boolean']['input']>
    canReadMarketPriceScopes?: InputMaybe<Scalars['Boolean']['input']>
    canReadMarketSetting?: InputMaybe<Scalars['Boolean']['input']>
    canReadMarketplace?: InputMaybe<Scalars['Boolean']['input']>
    canReadMeters?: InputMaybe<Scalars['Boolean']['input']>
    canReadNewsItems?: InputMaybe<Scalars['Boolean']['input']>
    canReadPayments?: InputMaybe<Scalars['Boolean']['input']>
    canReadPaymentsWithInvoices?: InputMaybe<Scalars['Boolean']['input']>
    canReadProperties?: InputMaybe<Scalars['Boolean']['input']>
    canReadServices?: InputMaybe<Scalars['Boolean']['input']>
    canReadSettings?: InputMaybe<Scalars['Boolean']['input']>
    canReadTickets?: InputMaybe<Scalars['Boolean']['input']>
    canReadTour?: InputMaybe<Scalars['Boolean']['input']>
    canShareTickets?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    description?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    isDefault?: InputMaybe<Scalars['Boolean']['input']>
    isEditable?: InputMaybe<Scalars['Boolean']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    ticketVisibilityType?: InputMaybe<OrganizationEmployeeRoleTicketVisibilityTypeType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type OrganizationEmployeeRoleWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<OrganizationEmployeeRoleWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<OrganizationEmployeeRoleWhereInput>>>
    canBeAssignedAsExecutor?: InputMaybe<Scalars['Boolean']['input']>
    canBeAssignedAsExecutor_not?: InputMaybe<Scalars['Boolean']['input']>
    canBeAssignedAsResponsible?: InputMaybe<Scalars['Boolean']['input']>
    canBeAssignedAsResponsible_not?: InputMaybe<Scalars['Boolean']['input']>
    canDownloadCallRecords?: InputMaybe<Scalars['Boolean']['input']>
    canDownloadCallRecords_not?: InputMaybe<Scalars['Boolean']['input']>
    canImportBillingReceipts?: InputMaybe<Scalars['Boolean']['input']>
    canImportBillingReceipts_not?: InputMaybe<Scalars['Boolean']['input']>
    canInviteNewOrganizationEmployees?: InputMaybe<Scalars['Boolean']['input']>
    canInviteNewOrganizationEmployees_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BApps?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BApps_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankAccountReportTasks?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankAccountReportTasks_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankAccountReports?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankAccountReports_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankAccounts?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankAccounts_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankContractorAccounts?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankContractorAccounts_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankIntegrationAccountContexts?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankIntegrationAccountContexts_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankIntegrationOrganizationContexts?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankIntegrationOrganizationContexts_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankTransactions?: InputMaybe<Scalars['Boolean']['input']>
    canManageBankTransactions_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageCallRecords?: InputMaybe<Scalars['Boolean']['input']>
    canManageCallRecords_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageContactRoles?: InputMaybe<Scalars['Boolean']['input']>
    canManageContactRoles_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageContacts?: InputMaybe<Scalars['Boolean']['input']>
    canManageContacts_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageDocuments?: InputMaybe<Scalars['Boolean']['input']>
    canManageDocuments_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageEmployees?: InputMaybe<Scalars['Boolean']['input']>
    canManageEmployees_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageIncidents?: InputMaybe<Scalars['Boolean']['input']>
    canManageIncidents_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageIntegrations?: InputMaybe<Scalars['Boolean']['input']>
    canManageIntegrations_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageInvoices?: InputMaybe<Scalars['Boolean']['input']>
    canManageInvoices_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageMarketItemPrices?: InputMaybe<Scalars['Boolean']['input']>
    canManageMarketItemPrices_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageMarketItems?: InputMaybe<Scalars['Boolean']['input']>
    canManageMarketItems_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageMarketPriceScopes?: InputMaybe<Scalars['Boolean']['input']>
    canManageMarketPriceScopes_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageMarketSetting?: InputMaybe<Scalars['Boolean']['input']>
    canManageMarketSetting_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageMarketplace?: InputMaybe<Scalars['Boolean']['input']>
    canManageMarketplace_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageMeterReadings?: InputMaybe<Scalars['Boolean']['input']>
    canManageMeterReadings_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageMeters?: InputMaybe<Scalars['Boolean']['input']>
    canManageMeters_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageMobileFeatureConfigs?: InputMaybe<Scalars['Boolean']['input']>
    canManageMobileFeatureConfigs_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageNewsItemTemplates?: InputMaybe<Scalars['Boolean']['input']>
    canManageNewsItemTemplates_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageNewsItems?: InputMaybe<Scalars['Boolean']['input']>
    canManageNewsItems_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageOrganization?: InputMaybe<Scalars['Boolean']['input']>
    canManageOrganization_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageProperties?: InputMaybe<Scalars['Boolean']['input']>
    canManageProperties_not?: InputMaybe<Scalars['Boolean']['input']>
    canManagePropertyScopes?: InputMaybe<Scalars['Boolean']['input']>
    canManagePropertyScopes_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageRoles?: InputMaybe<Scalars['Boolean']['input']>
    canManageRoles_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketAutoAssignments?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketAutoAssignments_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketComments?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketComments_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketPropertyHints?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketPropertyHints_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageTickets?: InputMaybe<Scalars['Boolean']['input']>
    canManageTickets_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageTour?: InputMaybe<Scalars['Boolean']['input']>
    canManageTour_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadAnalytics?: InputMaybe<Scalars['Boolean']['input']>
    canReadAnalytics_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadBillingReceipts?: InputMaybe<Scalars['Boolean']['input']>
    canReadBillingReceipts_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadCallRecords?: InputMaybe<Scalars['Boolean']['input']>
    canReadCallRecords_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadContacts?: InputMaybe<Scalars['Boolean']['input']>
    canReadContacts_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadDocuments?: InputMaybe<Scalars['Boolean']['input']>
    canReadDocuments_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadEmployees?: InputMaybe<Scalars['Boolean']['input']>
    canReadEmployees_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadExternalReports?: InputMaybe<Scalars['Boolean']['input']>
    canReadExternalReports_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadIncidents?: InputMaybe<Scalars['Boolean']['input']>
    canReadIncidents_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadInvoices?: InputMaybe<Scalars['Boolean']['input']>
    canReadInvoices_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadMarketItemPrices?: InputMaybe<Scalars['Boolean']['input']>
    canReadMarketItemPrices_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadMarketItems?: InputMaybe<Scalars['Boolean']['input']>
    canReadMarketItems_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadMarketPriceScopes?: InputMaybe<Scalars['Boolean']['input']>
    canReadMarketPriceScopes_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadMarketSetting?: InputMaybe<Scalars['Boolean']['input']>
    canReadMarketSetting_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadMarketplace?: InputMaybe<Scalars['Boolean']['input']>
    canReadMarketplace_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadMeters?: InputMaybe<Scalars['Boolean']['input']>
    canReadMeters_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadNewsItems?: InputMaybe<Scalars['Boolean']['input']>
    canReadNewsItems_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadPayments?: InputMaybe<Scalars['Boolean']['input']>
    canReadPaymentsWithInvoices?: InputMaybe<Scalars['Boolean']['input']>
    canReadPaymentsWithInvoices_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadPayments_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadProperties?: InputMaybe<Scalars['Boolean']['input']>
    canReadProperties_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadServices?: InputMaybe<Scalars['Boolean']['input']>
    canReadServices_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadSettings?: InputMaybe<Scalars['Boolean']['input']>
    canReadSettings_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadTickets?: InputMaybe<Scalars['Boolean']['input']>
    canReadTickets_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadTour?: InputMaybe<Scalars['Boolean']['input']>
    canReadTour_not?: InputMaybe<Scalars['Boolean']['input']>
    canShareTickets?: InputMaybe<Scalars['Boolean']['input']>
    canShareTickets_not?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    description?: InputMaybe<Scalars['String']['input']>
    description_contains?: InputMaybe<Scalars['String']['input']>
    description_contains_i?: InputMaybe<Scalars['String']['input']>
    description_ends_with?: InputMaybe<Scalars['String']['input']>
    description_ends_with_i?: InputMaybe<Scalars['String']['input']>
    description_i?: InputMaybe<Scalars['String']['input']>
    description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    description_not?: InputMaybe<Scalars['String']['input']>
    description_not_contains?: InputMaybe<Scalars['String']['input']>
    description_not_contains_i?: InputMaybe<Scalars['String']['input']>
    description_not_ends_with?: InputMaybe<Scalars['String']['input']>
    description_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    description_not_i?: InputMaybe<Scalars['String']['input']>
    description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    description_not_starts_with?: InputMaybe<Scalars['String']['input']>
    description_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    description_starts_with?: InputMaybe<Scalars['String']['input']>
    description_starts_with_i?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    isDefault?: InputMaybe<Scalars['Boolean']['input']>
    isDefault_not?: InputMaybe<Scalars['Boolean']['input']>
    isEditable?: InputMaybe<Scalars['Boolean']['input']>
    isEditable_not?: InputMaybe<Scalars['Boolean']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    ticketVisibilityType?: InputMaybe<OrganizationEmployeeRoleTicketVisibilityTypeType>
    ticketVisibilityType_in?: InputMaybe<Array<InputMaybe<OrganizationEmployeeRoleTicketVisibilityTypeType>>>
    ticketVisibilityType_not?: InputMaybe<OrganizationEmployeeRoleTicketVisibilityTypeType>
    ticketVisibilityType_not_in?: InputMaybe<Array<InputMaybe<OrganizationEmployeeRoleTicketVisibilityTypeType>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type OrganizationEmployeeRoleWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type OrganizationEmployeeRolesCreateInput = {
    data?: InputMaybe<OrganizationEmployeeRoleCreateInput>
}

export type OrganizationEmployeeRolesUpdateInput = {
    data?: InputMaybe<OrganizationEmployeeRoleUpdateInput>
    id: Scalars['ID']['input']
}

/**  A work category, that employee can perform. Used to limit the visibility of tickets for employees with "propertyAndSpecialization" ticketVisibilityType  */
export type OrganizationEmployeeSpecialization = {
    __typename?: 'OrganizationEmployeeSpecialization'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the OrganizationEmployeeSpecialization List config, or
   *  2. As an alias to the field set on 'labelField' in the OrganizationEmployeeSpecialization List config, or
   *  3. As an alias to a 'name' field on the OrganizationEmployeeSpecialization List (if one exists), or
   *  4. As an alias to the 'id' field on the OrganizationEmployeeSpecialization List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    employee?: Maybe<OrganizationEmployee>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Specific work category, that this employee can perform  */
    specialization?: Maybe<TicketCategoryClassifier>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type OrganizationEmployeeSpecializationCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    employee?: InputMaybe<OrganizationEmployeeRelateToOneInput>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    specialization?: InputMaybe<TicketCategoryClassifierRelateToOneInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type OrganizationEmployeeSpecializationHistoryRecord = {
    __typename?: 'OrganizationEmployeeSpecializationHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the OrganizationEmployeeSpecializationHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the OrganizationEmployeeSpecializationHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the OrganizationEmployeeSpecializationHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the OrganizationEmployeeSpecializationHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    employee?: Maybe<Scalars['String']['output']>
    history_action?: Maybe<OrganizationEmployeeSpecializationHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    specialization?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type OrganizationEmployeeSpecializationHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    employee?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<OrganizationEmployeeSpecializationHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    specialization?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum OrganizationEmployeeSpecializationHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type OrganizationEmployeeSpecializationHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    employee?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<OrganizationEmployeeSpecializationHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    specialization?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type OrganizationEmployeeSpecializationHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<OrganizationEmployeeSpecializationHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<OrganizationEmployeeSpecializationHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    employee?: InputMaybe<Scalars['String']['input']>
    employee_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    employee_not?: InputMaybe<Scalars['String']['input']>
    employee_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_action?: InputMaybe<OrganizationEmployeeSpecializationHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<OrganizationEmployeeSpecializationHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<OrganizationEmployeeSpecializationHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<OrganizationEmployeeSpecializationHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    specialization?: InputMaybe<Scalars['String']['input']>
    specialization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    specialization_not?: InputMaybe<Scalars['String']['input']>
    specialization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type OrganizationEmployeeSpecializationHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type OrganizationEmployeeSpecializationHistoryRecordsCreateInput = {
    data?: InputMaybe<OrganizationEmployeeSpecializationHistoryRecordCreateInput>
}

export type OrganizationEmployeeSpecializationHistoryRecordsUpdateInput = {
    data?: InputMaybe<OrganizationEmployeeSpecializationHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type OrganizationEmployeeSpecializationUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    employee?: InputMaybe<OrganizationEmployeeRelateToOneInput>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    specialization?: InputMaybe<TicketCategoryClassifierRelateToOneInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type OrganizationEmployeeSpecializationWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<OrganizationEmployeeSpecializationWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<OrganizationEmployeeSpecializationWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    employee?: InputMaybe<OrganizationEmployeeWhereInput>
    employee_is_null?: InputMaybe<Scalars['Boolean']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    specialization?: InputMaybe<TicketCategoryClassifierWhereInput>
    specialization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type OrganizationEmployeeSpecializationWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type OrganizationEmployeeSpecializationsCreateInput = {
    data?: InputMaybe<OrganizationEmployeeSpecializationCreateInput>
}

export type OrganizationEmployeeSpecializationsUpdateInput = {
    data?: InputMaybe<OrganizationEmployeeSpecializationUpdateInput>
    id: Scalars['ID']['input']
}

export type OrganizationEmployeeUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    email?: InputMaybe<Scalars['String']['input']>
    hasAllSpecializations?: InputMaybe<Scalars['Boolean']['input']>
    inviteCode?: InputMaybe<Scalars['ID']['input']>
    isAccepted?: InputMaybe<Scalars['Boolean']['input']>
    isBlocked?: InputMaybe<Scalars['Boolean']['input']>
    isRejected?: InputMaybe<Scalars['Boolean']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    phone?: InputMaybe<Scalars['String']['input']>
    position?: InputMaybe<Scalars['String']['input']>
    role?: InputMaybe<OrganizationEmployeeRoleRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type OrganizationEmployeeWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<OrganizationEmployeeWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<OrganizationEmployeeWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    email?: InputMaybe<Scalars['String']['input']>
    email_contains?: InputMaybe<Scalars['String']['input']>
    email_contains_i?: InputMaybe<Scalars['String']['input']>
    email_ends_with?: InputMaybe<Scalars['String']['input']>
    email_ends_with_i?: InputMaybe<Scalars['String']['input']>
    email_i?: InputMaybe<Scalars['String']['input']>
    email_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    email_not?: InputMaybe<Scalars['String']['input']>
    email_not_contains?: InputMaybe<Scalars['String']['input']>
    email_not_contains_i?: InputMaybe<Scalars['String']['input']>
    email_not_ends_with?: InputMaybe<Scalars['String']['input']>
    email_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    email_not_i?: InputMaybe<Scalars['String']['input']>
    email_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    email_not_starts_with?: InputMaybe<Scalars['String']['input']>
    email_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    email_starts_with?: InputMaybe<Scalars['String']['input']>
    email_starts_with_i?: InputMaybe<Scalars['String']['input']>
    hasAllSpecializations?: InputMaybe<Scalars['Boolean']['input']>
    hasAllSpecializations_not?: InputMaybe<Scalars['Boolean']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    inviteCode?: InputMaybe<Scalars['ID']['input']>
    inviteCode_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    inviteCode_not?: InputMaybe<Scalars['ID']['input']>
    inviteCode_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    isAccepted?: InputMaybe<Scalars['Boolean']['input']>
    isAccepted_not?: InputMaybe<Scalars['Boolean']['input']>
    isBlocked?: InputMaybe<Scalars['Boolean']['input']>
    isBlocked_not?: InputMaybe<Scalars['Boolean']['input']>
    isRejected?: InputMaybe<Scalars['Boolean']['input']>
    isRejected_not?: InputMaybe<Scalars['Boolean']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    phone?: InputMaybe<Scalars['String']['input']>
    phone_contains?: InputMaybe<Scalars['String']['input']>
    phone_contains_i?: InputMaybe<Scalars['String']['input']>
    phone_ends_with?: InputMaybe<Scalars['String']['input']>
    phone_ends_with_i?: InputMaybe<Scalars['String']['input']>
    phone_i?: InputMaybe<Scalars['String']['input']>
    phone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phone_not?: InputMaybe<Scalars['String']['input']>
    phone_not_contains?: InputMaybe<Scalars['String']['input']>
    phone_not_contains_i?: InputMaybe<Scalars['String']['input']>
    phone_not_ends_with?: InputMaybe<Scalars['String']['input']>
    phone_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    phone_not_i?: InputMaybe<Scalars['String']['input']>
    phone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phone_not_starts_with?: InputMaybe<Scalars['String']['input']>
    phone_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    phone_starts_with?: InputMaybe<Scalars['String']['input']>
    phone_starts_with_i?: InputMaybe<Scalars['String']['input']>
    position?: InputMaybe<Scalars['String']['input']>
    position_contains?: InputMaybe<Scalars['String']['input']>
    position_contains_i?: InputMaybe<Scalars['String']['input']>
    position_ends_with?: InputMaybe<Scalars['String']['input']>
    position_ends_with_i?: InputMaybe<Scalars['String']['input']>
    position_i?: InputMaybe<Scalars['String']['input']>
    position_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    position_not?: InputMaybe<Scalars['String']['input']>
    position_not_contains?: InputMaybe<Scalars['String']['input']>
    position_not_contains_i?: InputMaybe<Scalars['String']['input']>
    position_not_ends_with?: InputMaybe<Scalars['String']['input']>
    position_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    position_not_i?: InputMaybe<Scalars['String']['input']>
    position_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    position_not_starts_with?: InputMaybe<Scalars['String']['input']>
    position_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    position_starts_with?: InputMaybe<Scalars['String']['input']>
    position_starts_with_i?: InputMaybe<Scalars['String']['input']>
    role?: InputMaybe<OrganizationEmployeeRoleWhereInput>
    role_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    user?: InputMaybe<UserWhereInput>
    user_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type OrganizationEmployeeWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type OrganizationEmployeesCreateInput = {
    data?: InputMaybe<OrganizationEmployeeCreateInput>
}

export type OrganizationEmployeesUpdateInput = {
    data?: InputMaybe<OrganizationEmployeeUpdateInput>
    id: Scalars['ID']['input']
}

export enum OrganizationFeature {
    Spp = 'SPP',
}

export type OrganizationFeatures = {
    __typename?: 'OrganizationFeatures'
    hasBillingData: Scalars['Boolean']['output']
    hasMeters: Scalars['Boolean']['output']
}

/**  A keystone list  */
export type OrganizationHistoryRecord = {
    __typename?: 'OrganizationHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the OrganizationHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the OrganizationHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the OrganizationHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the OrganizationHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    avatar?: Maybe<Scalars['JSON']['output']>
    country?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    description?: Maybe<Scalars['JSON']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    features?: Maybe<Scalars['JSON']['output']>
    history_action?: Maybe<OrganizationHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    importId?: Maybe<Scalars['String']['output']>
    importRemoteSystem?: Maybe<Scalars['String']['output']>
    isApproved?: Maybe<Scalars['Boolean']['output']>
    meta?: Maybe<Scalars['JSON']['output']>
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    phone?: Maybe<Scalars['String']['output']>
    phoneNumberPrefix?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    tin?: Maybe<Scalars['String']['output']>
    type?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type OrganizationHistoryRecordCreateInput = {
    avatar?: InputMaybe<Scalars['JSON']['input']>
    country?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    description?: InputMaybe<Scalars['JSON']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    features?: InputMaybe<Scalars['JSON']['input']>
    history_action?: InputMaybe<OrganizationHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem?: InputMaybe<Scalars['String']['input']>
    isApproved?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    phone?: InputMaybe<Scalars['String']['input']>
    phoneNumberPrefix?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    tin?: InputMaybe<Scalars['String']['input']>
    type?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum OrganizationHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type OrganizationHistoryRecordUpdateInput = {
    avatar?: InputMaybe<Scalars['JSON']['input']>
    country?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    description?: InputMaybe<Scalars['JSON']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    features?: InputMaybe<Scalars['JSON']['input']>
    history_action?: InputMaybe<OrganizationHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem?: InputMaybe<Scalars['String']['input']>
    isApproved?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    phone?: InputMaybe<Scalars['String']['input']>
    phoneNumberPrefix?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    tin?: InputMaybe<Scalars['String']['input']>
    type?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type OrganizationHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<OrganizationHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<OrganizationHistoryRecordWhereInput>>>
    avatar?: InputMaybe<Scalars['JSON']['input']>
    avatar_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    avatar_not?: InputMaybe<Scalars['JSON']['input']>
    avatar_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    country?: InputMaybe<Scalars['String']['input']>
    country_contains?: InputMaybe<Scalars['String']['input']>
    country_contains_i?: InputMaybe<Scalars['String']['input']>
    country_ends_with?: InputMaybe<Scalars['String']['input']>
    country_ends_with_i?: InputMaybe<Scalars['String']['input']>
    country_i?: InputMaybe<Scalars['String']['input']>
    country_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    country_not?: InputMaybe<Scalars['String']['input']>
    country_not_contains?: InputMaybe<Scalars['String']['input']>
    country_not_contains_i?: InputMaybe<Scalars['String']['input']>
    country_not_ends_with?: InputMaybe<Scalars['String']['input']>
    country_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    country_not_i?: InputMaybe<Scalars['String']['input']>
    country_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    country_not_starts_with?: InputMaybe<Scalars['String']['input']>
    country_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    country_starts_with?: InputMaybe<Scalars['String']['input']>
    country_starts_with_i?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    description?: InputMaybe<Scalars['JSON']['input']>
    description_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    description_not?: InputMaybe<Scalars['JSON']['input']>
    description_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    features?: InputMaybe<Scalars['JSON']['input']>
    features_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    features_not?: InputMaybe<Scalars['JSON']['input']>
    features_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    history_action?: InputMaybe<OrganizationHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<OrganizationHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<OrganizationHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<OrganizationHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    importId?: InputMaybe<Scalars['String']['input']>
    importId_contains?: InputMaybe<Scalars['String']['input']>
    importId_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_i?: InputMaybe<Scalars['String']['input']>
    importId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not?: InputMaybe<Scalars['String']['input']>
    importId_not_contains?: InputMaybe<Scalars['String']['input']>
    importId_not_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_not_i?: InputMaybe<Scalars['String']['input']>
    importId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importId_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_contains?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_contains_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_ends_with?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importRemoteSystem_not?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_contains?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_contains_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_ends_with?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importRemoteSystem_not_starts_with?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_starts_with?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_starts_with_i?: InputMaybe<Scalars['String']['input']>
    isApproved?: InputMaybe<Scalars['Boolean']['input']>
    isApproved_not?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    phone?: InputMaybe<Scalars['String']['input']>
    phoneNumberPrefix?: InputMaybe<Scalars['String']['input']>
    phoneNumberPrefix_contains?: InputMaybe<Scalars['String']['input']>
    phoneNumberPrefix_contains_i?: InputMaybe<Scalars['String']['input']>
    phoneNumberPrefix_ends_with?: InputMaybe<Scalars['String']['input']>
    phoneNumberPrefix_ends_with_i?: InputMaybe<Scalars['String']['input']>
    phoneNumberPrefix_i?: InputMaybe<Scalars['String']['input']>
    phoneNumberPrefix_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phoneNumberPrefix_not?: InputMaybe<Scalars['String']['input']>
    phoneNumberPrefix_not_contains?: InputMaybe<Scalars['String']['input']>
    phoneNumberPrefix_not_contains_i?: InputMaybe<Scalars['String']['input']>
    phoneNumberPrefix_not_ends_with?: InputMaybe<Scalars['String']['input']>
    phoneNumberPrefix_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    phoneNumberPrefix_not_i?: InputMaybe<Scalars['String']['input']>
    phoneNumberPrefix_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phoneNumberPrefix_not_starts_with?: InputMaybe<Scalars['String']['input']>
    phoneNumberPrefix_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    phoneNumberPrefix_starts_with?: InputMaybe<Scalars['String']['input']>
    phoneNumberPrefix_starts_with_i?: InputMaybe<Scalars['String']['input']>
    phone_contains?: InputMaybe<Scalars['String']['input']>
    phone_contains_i?: InputMaybe<Scalars['String']['input']>
    phone_ends_with?: InputMaybe<Scalars['String']['input']>
    phone_ends_with_i?: InputMaybe<Scalars['String']['input']>
    phone_i?: InputMaybe<Scalars['String']['input']>
    phone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phone_not?: InputMaybe<Scalars['String']['input']>
    phone_not_contains?: InputMaybe<Scalars['String']['input']>
    phone_not_contains_i?: InputMaybe<Scalars['String']['input']>
    phone_not_ends_with?: InputMaybe<Scalars['String']['input']>
    phone_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    phone_not_i?: InputMaybe<Scalars['String']['input']>
    phone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phone_not_starts_with?: InputMaybe<Scalars['String']['input']>
    phone_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    phone_starts_with?: InputMaybe<Scalars['String']['input']>
    phone_starts_with_i?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    tin?: InputMaybe<Scalars['String']['input']>
    tin_contains?: InputMaybe<Scalars['String']['input']>
    tin_contains_i?: InputMaybe<Scalars['String']['input']>
    tin_ends_with?: InputMaybe<Scalars['String']['input']>
    tin_ends_with_i?: InputMaybe<Scalars['String']['input']>
    tin_i?: InputMaybe<Scalars['String']['input']>
    tin_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    tin_not?: InputMaybe<Scalars['String']['input']>
    tin_not_contains?: InputMaybe<Scalars['String']['input']>
    tin_not_contains_i?: InputMaybe<Scalars['String']['input']>
    tin_not_ends_with?: InputMaybe<Scalars['String']['input']>
    tin_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    tin_not_i?: InputMaybe<Scalars['String']['input']>
    tin_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    tin_not_starts_with?: InputMaybe<Scalars['String']['input']>
    tin_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    tin_starts_with?: InputMaybe<Scalars['String']['input']>
    tin_starts_with_i?: InputMaybe<Scalars['String']['input']>
    type?: InputMaybe<Scalars['String']['input']>
    type_contains?: InputMaybe<Scalars['String']['input']>
    type_contains_i?: InputMaybe<Scalars['String']['input']>
    type_ends_with?: InputMaybe<Scalars['String']['input']>
    type_ends_with_i?: InputMaybe<Scalars['String']['input']>
    type_i?: InputMaybe<Scalars['String']['input']>
    type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    type_not?: InputMaybe<Scalars['String']['input']>
    type_not_contains?: InputMaybe<Scalars['String']['input']>
    type_not_contains_i?: InputMaybe<Scalars['String']['input']>
    type_not_ends_with?: InputMaybe<Scalars['String']['input']>
    type_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    type_not_i?: InputMaybe<Scalars['String']['input']>
    type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    type_not_starts_with?: InputMaybe<Scalars['String']['input']>
    type_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    type_starts_with?: InputMaybe<Scalars['String']['input']>
    type_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type OrganizationHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type OrganizationHistoryRecordsCreateInput = {
    data?: InputMaybe<OrganizationHistoryRecordCreateInput>
}

export type OrganizationHistoryRecordsUpdateInput = {
    data?: InputMaybe<OrganizationHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

/**  Relationship between Organization that allows an employee of a "parent" (or "holding", you name it) Organization to interact as an employee of another "child" Organizations. If the relationship is created via OrganizationLink between "parent" and "child" Organization, a user does not need to be employee in all "child" Organization to have the abilities, required to perform some actions there. It is enough to be an employee in just one "parent" Organization. "Child" and "parent" means logical connection, not a structural from real world. Example use case of OrganizationLink is to be able to read Ticket from many organization when current user is an employee of Organization that act as a supervisor, like call center, that servers many "client" Organizations. Similar use cases are implemented for abilities to read MeterReading, ExternalReport, Contact, Division, Property, Ticket, TicketComment and other entities.  */
export type OrganizationLink = {
    __typename?: 'OrganizationLink'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the OrganizationLink List config, or
   *  2. As an alias to the field set on 'labelField' in the OrganizationLink List config, or
   *  3. As an alias to a 'name' field on the OrganizationLink List (if one exists), or
   *  4. As an alias to the 'id' field on the OrganizationLink List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  "Parent" Organization that gains its employee an access to all its "child" Organizations  */
    from?: Maybe<Organization>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  "Child" Organization that is getting accessible by employee of "parent" Organization, also, some entities of "Child" Organization are becoming accessible for CRUD operations.  */
    to?: Maybe<Organization>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type OrganizationLinkCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    from?: InputMaybe<OrganizationRelateToOneInput>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    to?: InputMaybe<OrganizationRelateToOneInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type OrganizationLinkHistoryRecord = {
    __typename?: 'OrganizationLinkHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the OrganizationLinkHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the OrganizationLinkHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the OrganizationLinkHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the OrganizationLinkHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    from?: Maybe<Scalars['String']['output']>
    history_action?: Maybe<OrganizationLinkHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    to?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type OrganizationLinkHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    from?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<OrganizationLinkHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    to?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum OrganizationLinkHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type OrganizationLinkHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    from?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<OrganizationLinkHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    to?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type OrganizationLinkHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<OrganizationLinkHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<OrganizationLinkHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    from?: InputMaybe<Scalars['String']['input']>
    from_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    from_not?: InputMaybe<Scalars['String']['input']>
    from_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_action?: InputMaybe<OrganizationLinkHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<OrganizationLinkHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<OrganizationLinkHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<OrganizationLinkHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    to?: InputMaybe<Scalars['String']['input']>
    to_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    to_not?: InputMaybe<Scalars['String']['input']>
    to_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type OrganizationLinkHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type OrganizationLinkHistoryRecordsCreateInput = {
    data?: InputMaybe<OrganizationLinkHistoryRecordCreateInput>
}

export type OrganizationLinkHistoryRecordsUpdateInput = {
    data?: InputMaybe<OrganizationLinkHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type OrganizationLinkRelateToManyInput = {
    connect?: InputMaybe<Array<InputMaybe<OrganizationLinkWhereUniqueInput>>>
    create?: InputMaybe<Array<InputMaybe<OrganizationLinkCreateInput>>>
    disconnect?: InputMaybe<Array<InputMaybe<OrganizationLinkWhereUniqueInput>>>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type OrganizationLinkUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    from?: InputMaybe<OrganizationRelateToOneInput>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    to?: InputMaybe<OrganizationRelateToOneInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type OrganizationLinkWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<OrganizationLinkWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<OrganizationLinkWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    from?: InputMaybe<OrganizationWhereInput>
    from_is_null?: InputMaybe<Scalars['Boolean']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    to?: InputMaybe<OrganizationWhereInput>
    to_is_null?: InputMaybe<Scalars['Boolean']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type OrganizationLinkWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type OrganizationLinksCreateInput = {
    data?: InputMaybe<OrganizationLinkCreateInput>
}

export type OrganizationLinksUpdateInput = {
    data?: InputMaybe<OrganizationLinkUpdateInput>
    id: Scalars['ID']['input']
}

export type OrganizationRelateToOneInput = {
    connect?: InputMaybe<OrganizationWhereUniqueInput>
    create?: InputMaybe<OrganizationCreateInput>
    disconnect?: InputMaybe<OrganizationWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export enum OrganizationType {
    Holding = 'HOLDING',
    ManagingCompany = 'MANAGING_COMPANY',
    ServiceProvider = 'SERVICE_PROVIDER',
}

export enum OrganizationTypeType {
    Holding = 'HOLDING',
    ManagingCompany = 'MANAGING_COMPANY',
    ServiceProvider = 'SERVICE_PROVIDER',
}

export type OrganizationUpdateInput = {
    avatar?: InputMaybe<Scalars['Upload']['input']>
    country?: InputMaybe<OrganizationCountryType>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    description?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    employees?: InputMaybe<OrganizationEmployeeRelateToManyInput>
    features?: InputMaybe<Array<OrganizationFeature>>
    importId?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem?: InputMaybe<Scalars['String']['input']>
    isApproved?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    phone?: InputMaybe<Scalars['String']['input']>
    phoneNumberPrefix?: InputMaybe<Scalars['String']['input']>
    relatedOrganizations?: InputMaybe<OrganizationLinkRelateToManyInput>
    sender?: InputMaybe<SenderFieldInput>
    tin?: InputMaybe<Scalars['String']['input']>
    type?: InputMaybe<OrganizationTypeType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type OrganizationWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<OrganizationWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<OrganizationWhereInput>>>
    avatar?: InputMaybe<Scalars['String']['input']>
    avatar_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    avatar_not?: InputMaybe<Scalars['String']['input']>
    avatar_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    country?: InputMaybe<OrganizationCountryType>
    country_in?: InputMaybe<Array<InputMaybe<OrganizationCountryType>>>
    country_not?: InputMaybe<OrganizationCountryType>
    country_not_in?: InputMaybe<Array<InputMaybe<OrganizationCountryType>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    description?: InputMaybe<Scalars['String']['input']>
    description_contains?: InputMaybe<Scalars['String']['input']>
    description_contains_i?: InputMaybe<Scalars['String']['input']>
    description_ends_with?: InputMaybe<Scalars['String']['input']>
    description_ends_with_i?: InputMaybe<Scalars['String']['input']>
    description_i?: InputMaybe<Scalars['String']['input']>
    description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    description_not?: InputMaybe<Scalars['String']['input']>
    description_not_contains?: InputMaybe<Scalars['String']['input']>
    description_not_contains_i?: InputMaybe<Scalars['String']['input']>
    description_not_ends_with?: InputMaybe<Scalars['String']['input']>
    description_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    description_not_i?: InputMaybe<Scalars['String']['input']>
    description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    description_not_starts_with?: InputMaybe<Scalars['String']['input']>
    description_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    description_starts_with?: InputMaybe<Scalars['String']['input']>
    description_starts_with_i?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    /**  condition must be true for all nodes  */
    employees_every?: InputMaybe<OrganizationEmployeeWhereInput>
    /**  condition must be false for all nodes  */
    employees_none?: InputMaybe<OrganizationEmployeeWhereInput>
    /**  condition must be true for at least 1 node  */
    employees_some?: InputMaybe<OrganizationEmployeeWhereInput>
    features?: InputMaybe<Array<OrganizationFeature>>
    features_in?: InputMaybe<Array<InputMaybe<Array<OrganizationFeature>>>>
    features_not?: InputMaybe<Array<OrganizationFeature>>
    features_not_in?: InputMaybe<Array<InputMaybe<Array<OrganizationFeature>>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    importId?: InputMaybe<Scalars['String']['input']>
    importId_contains?: InputMaybe<Scalars['String']['input']>
    importId_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_i?: InputMaybe<Scalars['String']['input']>
    importId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not?: InputMaybe<Scalars['String']['input']>
    importId_not_contains?: InputMaybe<Scalars['String']['input']>
    importId_not_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_not_i?: InputMaybe<Scalars['String']['input']>
    importId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importId_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_contains?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_contains_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_ends_with?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importRemoteSystem_not?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_contains?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_contains_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_ends_with?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importRemoteSystem_not_starts_with?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_starts_with?: InputMaybe<Scalars['String']['input']>
    importRemoteSystem_starts_with_i?: InputMaybe<Scalars['String']['input']>
    isApproved?: InputMaybe<Scalars['Boolean']['input']>
    isApproved_not?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phone?: InputMaybe<Scalars['String']['input']>
    phoneNumberPrefix?: InputMaybe<Scalars['String']['input']>
    phoneNumberPrefix_contains?: InputMaybe<Scalars['String']['input']>
    phoneNumberPrefix_contains_i?: InputMaybe<Scalars['String']['input']>
    phoneNumberPrefix_ends_with?: InputMaybe<Scalars['String']['input']>
    phoneNumberPrefix_ends_with_i?: InputMaybe<Scalars['String']['input']>
    phoneNumberPrefix_i?: InputMaybe<Scalars['String']['input']>
    phoneNumberPrefix_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phoneNumberPrefix_not?: InputMaybe<Scalars['String']['input']>
    phoneNumberPrefix_not_contains?: InputMaybe<Scalars['String']['input']>
    phoneNumberPrefix_not_contains_i?: InputMaybe<Scalars['String']['input']>
    phoneNumberPrefix_not_ends_with?: InputMaybe<Scalars['String']['input']>
    phoneNumberPrefix_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    phoneNumberPrefix_not_i?: InputMaybe<Scalars['String']['input']>
    phoneNumberPrefix_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phoneNumberPrefix_not_starts_with?: InputMaybe<Scalars['String']['input']>
    phoneNumberPrefix_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    phoneNumberPrefix_starts_with?: InputMaybe<Scalars['String']['input']>
    phoneNumberPrefix_starts_with_i?: InputMaybe<Scalars['String']['input']>
    phone_contains?: InputMaybe<Scalars['String']['input']>
    phone_contains_i?: InputMaybe<Scalars['String']['input']>
    phone_ends_with?: InputMaybe<Scalars['String']['input']>
    phone_ends_with_i?: InputMaybe<Scalars['String']['input']>
    phone_i?: InputMaybe<Scalars['String']['input']>
    phone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phone_not?: InputMaybe<Scalars['String']['input']>
    phone_not_contains?: InputMaybe<Scalars['String']['input']>
    phone_not_contains_i?: InputMaybe<Scalars['String']['input']>
    phone_not_ends_with?: InputMaybe<Scalars['String']['input']>
    phone_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    phone_not_i?: InputMaybe<Scalars['String']['input']>
    phone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phone_not_starts_with?: InputMaybe<Scalars['String']['input']>
    phone_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    phone_starts_with?: InputMaybe<Scalars['String']['input']>
    phone_starts_with_i?: InputMaybe<Scalars['String']['input']>
    /**  condition must be true for all nodes  */
    relatedOrganizations_every?: InputMaybe<OrganizationLinkWhereInput>
    /**  condition must be false for all nodes  */
    relatedOrganizations_none?: InputMaybe<OrganizationLinkWhereInput>
    /**  condition must be true for at least 1 node  */
    relatedOrganizations_some?: InputMaybe<OrganizationLinkWhereInput>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    tin?: InputMaybe<Scalars['String']['input']>
    tin_contains?: InputMaybe<Scalars['String']['input']>
    tin_contains_i?: InputMaybe<Scalars['String']['input']>
    tin_ends_with?: InputMaybe<Scalars['String']['input']>
    tin_ends_with_i?: InputMaybe<Scalars['String']['input']>
    tin_i?: InputMaybe<Scalars['String']['input']>
    tin_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    tin_not?: InputMaybe<Scalars['String']['input']>
    tin_not_contains?: InputMaybe<Scalars['String']['input']>
    tin_not_contains_i?: InputMaybe<Scalars['String']['input']>
    tin_not_ends_with?: InputMaybe<Scalars['String']['input']>
    tin_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    tin_not_i?: InputMaybe<Scalars['String']['input']>
    tin_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    tin_not_starts_with?: InputMaybe<Scalars['String']['input']>
    tin_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    tin_starts_with?: InputMaybe<Scalars['String']['input']>
    tin_starts_with_i?: InputMaybe<Scalars['String']['input']>
    type?: InputMaybe<OrganizationTypeType>
    type_in?: InputMaybe<Array<InputMaybe<OrganizationTypeType>>>
    type_not?: InputMaybe<OrganizationTypeType>
    type_not_in?: InputMaybe<Array<InputMaybe<OrganizationTypeType>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type OrganizationWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type OrganizationsCreateInput = {
    data?: InputMaybe<OrganizationCreateInput>
}

export type OrganizationsUpdateInput = {
    data?: InputMaybe<OrganizationUpdateInput>
    id: Scalars['ID']['input']
}

export enum OverviewDashboardEntities {
    Incident = 'incident',
    Payment = 'payment',
    Property = 'property',
    Receipt = 'receipt',
    Resident = 'resident',
    TicketByCategory = 'ticketByCategory',
    TicketByDay = 'ticketByDay',
    TicketByExecutor = 'ticketByExecutor',
    TicketByProperty = 'ticketByProperty',
    TicketQualityControlValue = 'ticketQualityControlValue',
}

export type OverviewData = {
    __typename?: 'OverviewData'
    incident?: Maybe<IncidentOverviewResult>
    payment?: Maybe<PaymentOverviewResult>
    property?: Maybe<PropertyOverviewResult>
    receipt?: Maybe<ReceiptOverviewResult>
    resident?: Maybe<ResidentOverviewResult>
    ticketByCategory?: Maybe<TicketOverviewResult>
    ticketByDay?: Maybe<TicketOverviewResult>
    ticketByExecutor?: Maybe<TicketOverviewResult>
    ticketByProperty?: Maybe<TicketOverviewResult>
    ticketQualityControlValue?: Maybe<TicketOverviewResult>
}

/**  Information about completed transaction from user to a specific organization  */
export type Payment = {
    __typename?: 'Payment'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the Payment List config, or
   *  2. As an alias to the field set on 'labelField' in the Payment List config, or
   *  3. As an alias to a 'name' field on the Payment List (if one exists), or
   *  4. As an alias to the 'id' field on the Payment List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  Payer's account number  */
    accountNumber?: Maybe<Scalars['String']['output']>
    /**  Time at which money was advanced to recipient's account  */
    advancedAt?: Maybe<Scalars['String']['output']>
    /**  Amount of money from MultiPayment.amountWithOutExplicitFee to pay for billing receipt  */
    amount?: Maybe<Scalars['String']['output']>
    /**  Acquiring context, which used to link organization and acquiring integration and provide storage for organization-acquiring-specific settings / state  */
    context?: Maybe<AcquiringIntegrationContext>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    /**  Code of currency in ISO-4217 format  */
    currencyCode?: Maybe<PaymentCurrencyCodeType>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Time at which the payment was made by client  */
    depositedDate?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  Amount of money which payer pays on top of initial "amount", which counts as fee for every service which is not housing and communal services  */
    explicitFee?: Maybe<Scalars['String']['output']>
    /**  Amount of money which payer pays on top of initial "amount", which counts as internal service charge for all payments from housing and communal services category  */
    explicitServiceCharge?: Maybe<Scalars['String']['output']>
    /**  Frozen invoice, used to resolving conflicts  */
    frozenInvoice?: Maybe<Scalars['JSON']['output']>
    /**  Frozen billing receipt, used to resolving conflicts  */
    frozenReceipt?: Maybe<Scalars['JSON']['output']>
    id: Scalars['ID']['output']
    /**  Amount of money which recipient pays from initial amount for transaction  */
    implicitFee?: Maybe<Scalars['String']['output']>
    /**  Id of object in external service which represents current item. Mostly used for internal needs of integration services for matching our objects with theirs  */
    importId?: Maybe<Scalars['String']['output']>
    /**  Link to the invoice that user paid for.  */
    invoice?: Maybe<Invoice>
    /**  Link to a payment related MultiPayment. Required field to update, but initially created unlinked  */
    multiPayment?: Maybe<MultiPayment>
    newId?: Maybe<Scalars['String']['output']>
    /**  Payment order. A directive to a bank from a bank account holder instructing the bank to make a payment or series of payments to a third party  */
    order?: Maybe<Scalars['String']['output']>
    /**  Direct link to organization, since acquiring context cannot be defined for some payments  */
    organization?: Maybe<Organization>
    /**  Period date: Generated on template <year>-<month>-01  */
    period?: Maybe<Scalars['String']['output']>
    /**  Purpose of payment. Mostly used as title such as "Payment by agreement №123"  */
    purpose?: Maybe<Scalars['String']['output']>
    /**  Non-normalized address that was imported from the organization  */
    rawAddress?: Maybe<Scalars['String']['output']>
    /**  Link to a billing receipt that the user paid for. Can be null in cases of getting payments out of our system  */
    receipt?: Maybe<BillingReceipt>
    /**  Recipient. Should contain all meta information to identify the organization and bank account  */
    recipient?: Maybe<RecipientField>
    /**  @deprecated --use "recipient" Bank account number of recipient organization, used for matching payments with receipts in case of multiple receipts per account + address  */
    recipientBankAccount?: Maybe<Scalars['String']['output']>
    /**  @deprecated --use "recipient" Bic of recipient organization, used for matching payments with receipts in case of multiple receipts per account + address  */
    recipientBic?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  The amount of money charged by our service for the provision of service after subtracting from it the shares of all participants in the process. Can be part of explicit fee, implicit fee or explicit service charge  */
    serviceFee?: Maybe<Scalars['String']['output']>
    /**  Status of payment. Can be: "CREATED", "PROCESSING", "DONE", "ERROR", "WITHDRAWN"  */
    status?: Maybe<PaymentStatusType>
    /**  Time at which money was transfered to recipient's account  */
    transferDate?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type PaymentCategory = {
    __typename?: 'PaymentCategory'
    acquiringName: Scalars['String']['output']
    billingName: Scalars['String']['output']
    categoryName: Scalars['String']['output']
    id: Scalars['String']['output']
}

export type PaymentCreateInput = {
    accountNumber?: InputMaybe<Scalars['String']['input']>
    advancedAt?: InputMaybe<Scalars['String']['input']>
    amount?: InputMaybe<Scalars['String']['input']>
    context?: InputMaybe<AcquiringIntegrationContextRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    currencyCode?: InputMaybe<PaymentCurrencyCodeType>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    depositedDate?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    explicitFee?: InputMaybe<Scalars['String']['input']>
    explicitServiceCharge?: InputMaybe<Scalars['String']['input']>
    frozenInvoice?: InputMaybe<Scalars['JSON']['input']>
    frozenReceipt?: InputMaybe<Scalars['JSON']['input']>
    implicitFee?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    invoice?: InputMaybe<InvoiceRelateToOneInput>
    multiPayment?: InputMaybe<MultiPaymentRelateToOneInput>
    newId?: InputMaybe<Scalars['String']['input']>
    order?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    period?: InputMaybe<Scalars['String']['input']>
    purpose?: InputMaybe<Scalars['String']['input']>
    rawAddress?: InputMaybe<Scalars['String']['input']>
    receipt?: InputMaybe<BillingReceiptRelateToOneInput>
    recipient?: InputMaybe<RecipientFieldInput>
    recipientBankAccount?: InputMaybe<Scalars['String']['input']>
    recipientBic?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    serviceFee?: InputMaybe<Scalars['String']['input']>
    status?: InputMaybe<PaymentStatusType>
    transferDate?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum PaymentCurrencyCodeType {
    Aed = 'AED',
    Afn = 'AFN',
    All = 'ALL',
    Amd = 'AMD',
    Ang = 'ANG',
    Aoa = 'AOA',
    Ars = 'ARS',
    Aud = 'AUD',
    Awg = 'AWG',
    Azn = 'AZN',
    Bam = 'BAM',
    Bbd = 'BBD',
    Bdt = 'BDT',
    Bgn = 'BGN',
    Bhd = 'BHD',
    Bif = 'BIF',
    Bmd = 'BMD',
    Bnd = 'BND',
    Bob = 'BOB',
    Bov = 'BOV',
    Brl = 'BRL',
    Bsd = 'BSD',
    Btn = 'BTN',
    Bwp = 'BWP',
    Byn = 'BYN',
    Bzd = 'BZD',
    Cad = 'CAD',
    Cdf = 'CDF',
    Che = 'CHE',
    Chf = 'CHF',
    Chw = 'CHW',
    Clf = 'CLF',
    Clp = 'CLP',
    Cny = 'CNY',
    Cop = 'COP',
    Cou = 'COU',
    Crc = 'CRC',
    Cuc = 'CUC',
    Cup = 'CUP',
    Cve = 'CVE',
    Czk = 'CZK',
    Djf = 'DJF',
    Dkk = 'DKK',
    Dop = 'DOP',
    Dzd = 'DZD',
    Egp = 'EGP',
    Ern = 'ERN',
    Etb = 'ETB',
    Eur = 'EUR',
    Fjd = 'FJD',
    Fkp = 'FKP',
    Gbp = 'GBP',
    Gel = 'GEL',
    Ghs = 'GHS',
    Gip = 'GIP',
    Gmd = 'GMD',
    Gnf = 'GNF',
    Gtq = 'GTQ',
    Gyd = 'GYD',
    Hkd = 'HKD',
    Hnl = 'HNL',
    Hrk = 'HRK',
    Htg = 'HTG',
    Huf = 'HUF',
    Idr = 'IDR',
    Ils = 'ILS',
    Inr = 'INR',
    Iqd = 'IQD',
    Irr = 'IRR',
    Isk = 'ISK',
    Jmd = 'JMD',
    Jod = 'JOD',
    Jpy = 'JPY',
    Kes = 'KES',
    Kgs = 'KGS',
    Khr = 'KHR',
    Kmf = 'KMF',
    Kpw = 'KPW',
    Krw = 'KRW',
    Kwd = 'KWD',
    Kyd = 'KYD',
    Kzt = 'KZT',
    Lak = 'LAK',
    Lbp = 'LBP',
    Lkr = 'LKR',
    Lrd = 'LRD',
    Lsl = 'LSL',
    Lyd = 'LYD',
    Mad = 'MAD',
    Mdl = 'MDL',
    Mga = 'MGA',
    Mkd = 'MKD',
    Mmk = 'MMK',
    Mnt = 'MNT',
    Mop = 'MOP',
    Mru = 'MRU',
    Mur = 'MUR',
    Mvr = 'MVR',
    Mwk = 'MWK',
    Mxn = 'MXN',
    Mxv = 'MXV',
    Myr = 'MYR',
    Mzn = 'MZN',
    Nad = 'NAD',
    Ngn = 'NGN',
    Nio = 'NIO',
    Nok = 'NOK',
    Npr = 'NPR',
    Nzd = 'NZD',
    Omr = 'OMR',
    Pab = 'PAB',
    Pen = 'PEN',
    Pgk = 'PGK',
    Php = 'PHP',
    Pkr = 'PKR',
    Pln = 'PLN',
    Pyg = 'PYG',
    Qar = 'QAR',
    Ron = 'RON',
    Rsd = 'RSD',
    Rub = 'RUB',
    Rwf = 'RWF',
    Sar = 'SAR',
    Sbd = 'SBD',
    Scr = 'SCR',
    Sdg = 'SDG',
    Sek = 'SEK',
    Sgd = 'SGD',
    Shp = 'SHP',
    Sll = 'SLL',
    Sos = 'SOS',
    Srd = 'SRD',
    Ssp = 'SSP',
    Stn = 'STN',
    Svc = 'SVC',
    Syp = 'SYP',
    Szl = 'SZL',
    Thb = 'THB',
    Tjs = 'TJS',
    Tmt = 'TMT',
    Tnd = 'TND',
    Top = 'TOP',
    Try = 'TRY',
    Ttd = 'TTD',
    Twd = 'TWD',
    Tzs = 'TZS',
    Uah = 'UAH',
    Ugx = 'UGX',
    Usd = 'USD',
    Usn = 'USN',
    Uyi = 'UYI',
    Uyu = 'UYU',
    Uyw = 'UYW',
    Uzs = 'UZS',
    Ves = 'VES',
    Vnd = 'VND',
    Vuv = 'VUV',
    Wst = 'WST',
    Xaf = 'XAF',
    Xag = 'XAG',
    Xau = 'XAU',
    Xba = 'XBA',
    Xbb = 'XBB',
    Xbc = 'XBC',
    Xbd = 'XBD',
    Xcd = 'XCD',
    Xdr = 'XDR',
    Xof = 'XOF',
    Xpd = 'XPD',
    Xpf = 'XPF',
    Xpt = 'XPT',
    Xsu = 'XSU',
    Xts = 'XTS',
    Xua = 'XUA',
    Yer = 'YER',
    Zar = 'ZAR',
    Zmw = 'ZMW',
    Zwl = 'ZWL',
}

export type PaymentGroupedCounter = {
    __typename?: 'PaymentGroupedCounter'
    count: Scalars['String']['output']
    createdBy: Scalars['ID']['output']
    dayGroup: Scalars['String']['output']
    sum: Scalars['String']['output']
}

/**  A keystone list  */
export type PaymentHistoryRecord = {
    __typename?: 'PaymentHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the PaymentHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the PaymentHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the PaymentHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the PaymentHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    accountNumber?: Maybe<Scalars['String']['output']>
    advancedAt?: Maybe<Scalars['String']['output']>
    amount?: Maybe<Scalars['String']['output']>
    context?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    currencyCode?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    depositedDate?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    explicitFee?: Maybe<Scalars['String']['output']>
    explicitServiceCharge?: Maybe<Scalars['String']['output']>
    frozenInvoice?: Maybe<Scalars['JSON']['output']>
    frozenReceipt?: Maybe<Scalars['JSON']['output']>
    history_action?: Maybe<PaymentHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    implicitFee?: Maybe<Scalars['String']['output']>
    importId?: Maybe<Scalars['String']['output']>
    invoice?: Maybe<Scalars['String']['output']>
    multiPayment?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    order?: Maybe<Scalars['String']['output']>
    organization?: Maybe<Scalars['String']['output']>
    period?: Maybe<Scalars['String']['output']>
    purpose?: Maybe<Scalars['String']['output']>
    rawAddress?: Maybe<Scalars['String']['output']>
    receipt?: Maybe<Scalars['String']['output']>
    recipient?: Maybe<Scalars['JSON']['output']>
    recipientBankAccount?: Maybe<Scalars['String']['output']>
    recipientBic?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    serviceFee?: Maybe<Scalars['String']['output']>
    status?: Maybe<Scalars['String']['output']>
    transferDate?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type PaymentHistoryRecordCreateInput = {
    accountNumber?: InputMaybe<Scalars['String']['input']>
    advancedAt?: InputMaybe<Scalars['String']['input']>
    amount?: InputMaybe<Scalars['String']['input']>
    context?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    currencyCode?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    depositedDate?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    explicitFee?: InputMaybe<Scalars['String']['input']>
    explicitServiceCharge?: InputMaybe<Scalars['String']['input']>
    frozenInvoice?: InputMaybe<Scalars['JSON']['input']>
    frozenReceipt?: InputMaybe<Scalars['JSON']['input']>
    history_action?: InputMaybe<PaymentHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    implicitFee?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    invoice?: InputMaybe<Scalars['String']['input']>
    multiPayment?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    order?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    period?: InputMaybe<Scalars['String']['input']>
    purpose?: InputMaybe<Scalars['String']['input']>
    rawAddress?: InputMaybe<Scalars['String']['input']>
    receipt?: InputMaybe<Scalars['String']['input']>
    recipient?: InputMaybe<Scalars['JSON']['input']>
    recipientBankAccount?: InputMaybe<Scalars['String']['input']>
    recipientBic?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    serviceFee?: InputMaybe<Scalars['String']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    transferDate?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum PaymentHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type PaymentHistoryRecordUpdateInput = {
    accountNumber?: InputMaybe<Scalars['String']['input']>
    advancedAt?: InputMaybe<Scalars['String']['input']>
    amount?: InputMaybe<Scalars['String']['input']>
    context?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    currencyCode?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    depositedDate?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    explicitFee?: InputMaybe<Scalars['String']['input']>
    explicitServiceCharge?: InputMaybe<Scalars['String']['input']>
    frozenInvoice?: InputMaybe<Scalars['JSON']['input']>
    frozenReceipt?: InputMaybe<Scalars['JSON']['input']>
    history_action?: InputMaybe<PaymentHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    implicitFee?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    invoice?: InputMaybe<Scalars['String']['input']>
    multiPayment?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    order?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    period?: InputMaybe<Scalars['String']['input']>
    purpose?: InputMaybe<Scalars['String']['input']>
    rawAddress?: InputMaybe<Scalars['String']['input']>
    receipt?: InputMaybe<Scalars['String']['input']>
    recipient?: InputMaybe<Scalars['JSON']['input']>
    recipientBankAccount?: InputMaybe<Scalars['String']['input']>
    recipientBic?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    serviceFee?: InputMaybe<Scalars['String']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    transferDate?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type PaymentHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<PaymentHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<PaymentHistoryRecordWhereInput>>>
    accountNumber?: InputMaybe<Scalars['String']['input']>
    accountNumber_contains?: InputMaybe<Scalars['String']['input']>
    accountNumber_contains_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_ends_with?: InputMaybe<Scalars['String']['input']>
    accountNumber_ends_with_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    accountNumber_not?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_contains?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_contains_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_ends_with?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    accountNumber_not_starts_with?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_starts_with?: InputMaybe<Scalars['String']['input']>
    accountNumber_starts_with_i?: InputMaybe<Scalars['String']['input']>
    advancedAt?: InputMaybe<Scalars['String']['input']>
    advancedAt_gt?: InputMaybe<Scalars['String']['input']>
    advancedAt_gte?: InputMaybe<Scalars['String']['input']>
    advancedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    advancedAt_lt?: InputMaybe<Scalars['String']['input']>
    advancedAt_lte?: InputMaybe<Scalars['String']['input']>
    advancedAt_not?: InputMaybe<Scalars['String']['input']>
    advancedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    amount?: InputMaybe<Scalars['String']['input']>
    amount_gt?: InputMaybe<Scalars['String']['input']>
    amount_gte?: InputMaybe<Scalars['String']['input']>
    amount_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    amount_lt?: InputMaybe<Scalars['String']['input']>
    amount_lte?: InputMaybe<Scalars['String']['input']>
    amount_not?: InputMaybe<Scalars['String']['input']>
    amount_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    context?: InputMaybe<Scalars['String']['input']>
    context_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    context_not?: InputMaybe<Scalars['String']['input']>
    context_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    currencyCode?: InputMaybe<Scalars['String']['input']>
    currencyCode_contains?: InputMaybe<Scalars['String']['input']>
    currencyCode_contains_i?: InputMaybe<Scalars['String']['input']>
    currencyCode_ends_with?: InputMaybe<Scalars['String']['input']>
    currencyCode_ends_with_i?: InputMaybe<Scalars['String']['input']>
    currencyCode_i?: InputMaybe<Scalars['String']['input']>
    currencyCode_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    currencyCode_not?: InputMaybe<Scalars['String']['input']>
    currencyCode_not_contains?: InputMaybe<Scalars['String']['input']>
    currencyCode_not_contains_i?: InputMaybe<Scalars['String']['input']>
    currencyCode_not_ends_with?: InputMaybe<Scalars['String']['input']>
    currencyCode_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    currencyCode_not_i?: InputMaybe<Scalars['String']['input']>
    currencyCode_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    currencyCode_not_starts_with?: InputMaybe<Scalars['String']['input']>
    currencyCode_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    currencyCode_starts_with?: InputMaybe<Scalars['String']['input']>
    currencyCode_starts_with_i?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    depositedDate?: InputMaybe<Scalars['String']['input']>
    depositedDate_gt?: InputMaybe<Scalars['String']['input']>
    depositedDate_gte?: InputMaybe<Scalars['String']['input']>
    depositedDate_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    depositedDate_lt?: InputMaybe<Scalars['String']['input']>
    depositedDate_lte?: InputMaybe<Scalars['String']['input']>
    depositedDate_not?: InputMaybe<Scalars['String']['input']>
    depositedDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    explicitFee?: InputMaybe<Scalars['String']['input']>
    explicitFee_gt?: InputMaybe<Scalars['String']['input']>
    explicitFee_gte?: InputMaybe<Scalars['String']['input']>
    explicitFee_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    explicitFee_lt?: InputMaybe<Scalars['String']['input']>
    explicitFee_lte?: InputMaybe<Scalars['String']['input']>
    explicitFee_not?: InputMaybe<Scalars['String']['input']>
    explicitFee_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    explicitServiceCharge?: InputMaybe<Scalars['String']['input']>
    explicitServiceCharge_gt?: InputMaybe<Scalars['String']['input']>
    explicitServiceCharge_gte?: InputMaybe<Scalars['String']['input']>
    explicitServiceCharge_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    explicitServiceCharge_lt?: InputMaybe<Scalars['String']['input']>
    explicitServiceCharge_lte?: InputMaybe<Scalars['String']['input']>
    explicitServiceCharge_not?: InputMaybe<Scalars['String']['input']>
    explicitServiceCharge_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    frozenInvoice?: InputMaybe<Scalars['JSON']['input']>
    frozenInvoice_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    frozenInvoice_not?: InputMaybe<Scalars['JSON']['input']>
    frozenInvoice_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    frozenReceipt?: InputMaybe<Scalars['JSON']['input']>
    frozenReceipt_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    frozenReceipt_not?: InputMaybe<Scalars['JSON']['input']>
    frozenReceipt_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    history_action?: InputMaybe<PaymentHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<PaymentHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<PaymentHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<PaymentHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    implicitFee?: InputMaybe<Scalars['String']['input']>
    implicitFee_gt?: InputMaybe<Scalars['String']['input']>
    implicitFee_gte?: InputMaybe<Scalars['String']['input']>
    implicitFee_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    implicitFee_lt?: InputMaybe<Scalars['String']['input']>
    implicitFee_lte?: InputMaybe<Scalars['String']['input']>
    implicitFee_not?: InputMaybe<Scalars['String']['input']>
    implicitFee_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId?: InputMaybe<Scalars['String']['input']>
    importId_contains?: InputMaybe<Scalars['String']['input']>
    importId_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_i?: InputMaybe<Scalars['String']['input']>
    importId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not?: InputMaybe<Scalars['String']['input']>
    importId_not_contains?: InputMaybe<Scalars['String']['input']>
    importId_not_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_not_i?: InputMaybe<Scalars['String']['input']>
    importId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importId_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_starts_with_i?: InputMaybe<Scalars['String']['input']>
    invoice?: InputMaybe<Scalars['String']['input']>
    invoice_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    invoice_not?: InputMaybe<Scalars['String']['input']>
    invoice_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    multiPayment?: InputMaybe<Scalars['String']['input']>
    multiPayment_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    multiPayment_not?: InputMaybe<Scalars['String']['input']>
    multiPayment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    order?: InputMaybe<Scalars['String']['input']>
    order_contains?: InputMaybe<Scalars['String']['input']>
    order_contains_i?: InputMaybe<Scalars['String']['input']>
    order_ends_with?: InputMaybe<Scalars['String']['input']>
    order_ends_with_i?: InputMaybe<Scalars['String']['input']>
    order_i?: InputMaybe<Scalars['String']['input']>
    order_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    order_not?: InputMaybe<Scalars['String']['input']>
    order_not_contains?: InputMaybe<Scalars['String']['input']>
    order_not_contains_i?: InputMaybe<Scalars['String']['input']>
    order_not_ends_with?: InputMaybe<Scalars['String']['input']>
    order_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    order_not_i?: InputMaybe<Scalars['String']['input']>
    order_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    order_not_starts_with?: InputMaybe<Scalars['String']['input']>
    order_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    order_starts_with?: InputMaybe<Scalars['String']['input']>
    order_starts_with_i?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    period?: InputMaybe<Scalars['String']['input']>
    period_gt?: InputMaybe<Scalars['String']['input']>
    period_gte?: InputMaybe<Scalars['String']['input']>
    period_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    period_lt?: InputMaybe<Scalars['String']['input']>
    period_lte?: InputMaybe<Scalars['String']['input']>
    period_not?: InputMaybe<Scalars['String']['input']>
    period_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    purpose?: InputMaybe<Scalars['String']['input']>
    purpose_contains?: InputMaybe<Scalars['String']['input']>
    purpose_contains_i?: InputMaybe<Scalars['String']['input']>
    purpose_ends_with?: InputMaybe<Scalars['String']['input']>
    purpose_ends_with_i?: InputMaybe<Scalars['String']['input']>
    purpose_i?: InputMaybe<Scalars['String']['input']>
    purpose_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    purpose_not?: InputMaybe<Scalars['String']['input']>
    purpose_not_contains?: InputMaybe<Scalars['String']['input']>
    purpose_not_contains_i?: InputMaybe<Scalars['String']['input']>
    purpose_not_ends_with?: InputMaybe<Scalars['String']['input']>
    purpose_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    purpose_not_i?: InputMaybe<Scalars['String']['input']>
    purpose_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    purpose_not_starts_with?: InputMaybe<Scalars['String']['input']>
    purpose_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    purpose_starts_with?: InputMaybe<Scalars['String']['input']>
    purpose_starts_with_i?: InputMaybe<Scalars['String']['input']>
    rawAddress?: InputMaybe<Scalars['String']['input']>
    rawAddress_contains?: InputMaybe<Scalars['String']['input']>
    rawAddress_contains_i?: InputMaybe<Scalars['String']['input']>
    rawAddress_ends_with?: InputMaybe<Scalars['String']['input']>
    rawAddress_ends_with_i?: InputMaybe<Scalars['String']['input']>
    rawAddress_i?: InputMaybe<Scalars['String']['input']>
    rawAddress_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    rawAddress_not?: InputMaybe<Scalars['String']['input']>
    rawAddress_not_contains?: InputMaybe<Scalars['String']['input']>
    rawAddress_not_contains_i?: InputMaybe<Scalars['String']['input']>
    rawAddress_not_ends_with?: InputMaybe<Scalars['String']['input']>
    rawAddress_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    rawAddress_not_i?: InputMaybe<Scalars['String']['input']>
    rawAddress_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    rawAddress_not_starts_with?: InputMaybe<Scalars['String']['input']>
    rawAddress_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    rawAddress_starts_with?: InputMaybe<Scalars['String']['input']>
    rawAddress_starts_with_i?: InputMaybe<Scalars['String']['input']>
    receipt?: InputMaybe<Scalars['String']['input']>
    receipt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    receipt_not?: InputMaybe<Scalars['String']['input']>
    receipt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    recipient?: InputMaybe<Scalars['JSON']['input']>
    recipientBankAccount?: InputMaybe<Scalars['String']['input']>
    recipientBankAccount_contains?: InputMaybe<Scalars['String']['input']>
    recipientBankAccount_contains_i?: InputMaybe<Scalars['String']['input']>
    recipientBankAccount_ends_with?: InputMaybe<Scalars['String']['input']>
    recipientBankAccount_ends_with_i?: InputMaybe<Scalars['String']['input']>
    recipientBankAccount_i?: InputMaybe<Scalars['String']['input']>
    recipientBankAccount_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    recipientBankAccount_not?: InputMaybe<Scalars['String']['input']>
    recipientBankAccount_not_contains?: InputMaybe<Scalars['String']['input']>
    recipientBankAccount_not_contains_i?: InputMaybe<Scalars['String']['input']>
    recipientBankAccount_not_ends_with?: InputMaybe<Scalars['String']['input']>
    recipientBankAccount_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    recipientBankAccount_not_i?: InputMaybe<Scalars['String']['input']>
    recipientBankAccount_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    recipientBankAccount_not_starts_with?: InputMaybe<Scalars['String']['input']>
    recipientBankAccount_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    recipientBankAccount_starts_with?: InputMaybe<Scalars['String']['input']>
    recipientBankAccount_starts_with_i?: InputMaybe<Scalars['String']['input']>
    recipientBic?: InputMaybe<Scalars['String']['input']>
    recipientBic_contains?: InputMaybe<Scalars['String']['input']>
    recipientBic_contains_i?: InputMaybe<Scalars['String']['input']>
    recipientBic_ends_with?: InputMaybe<Scalars['String']['input']>
    recipientBic_ends_with_i?: InputMaybe<Scalars['String']['input']>
    recipientBic_i?: InputMaybe<Scalars['String']['input']>
    recipientBic_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    recipientBic_not?: InputMaybe<Scalars['String']['input']>
    recipientBic_not_contains?: InputMaybe<Scalars['String']['input']>
    recipientBic_not_contains_i?: InputMaybe<Scalars['String']['input']>
    recipientBic_not_ends_with?: InputMaybe<Scalars['String']['input']>
    recipientBic_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    recipientBic_not_i?: InputMaybe<Scalars['String']['input']>
    recipientBic_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    recipientBic_not_starts_with?: InputMaybe<Scalars['String']['input']>
    recipientBic_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    recipientBic_starts_with?: InputMaybe<Scalars['String']['input']>
    recipientBic_starts_with_i?: InputMaybe<Scalars['String']['input']>
    recipient_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    recipient_not?: InputMaybe<Scalars['JSON']['input']>
    recipient_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    serviceFee?: InputMaybe<Scalars['String']['input']>
    serviceFee_gt?: InputMaybe<Scalars['String']['input']>
    serviceFee_gte?: InputMaybe<Scalars['String']['input']>
    serviceFee_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    serviceFee_lt?: InputMaybe<Scalars['String']['input']>
    serviceFee_lte?: InputMaybe<Scalars['String']['input']>
    serviceFee_not?: InputMaybe<Scalars['String']['input']>
    serviceFee_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status?: InputMaybe<Scalars['String']['input']>
    status_contains?: InputMaybe<Scalars['String']['input']>
    status_contains_i?: InputMaybe<Scalars['String']['input']>
    status_ends_with?: InputMaybe<Scalars['String']['input']>
    status_ends_with_i?: InputMaybe<Scalars['String']['input']>
    status_i?: InputMaybe<Scalars['String']['input']>
    status_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status_not?: InputMaybe<Scalars['String']['input']>
    status_not_contains?: InputMaybe<Scalars['String']['input']>
    status_not_contains_i?: InputMaybe<Scalars['String']['input']>
    status_not_ends_with?: InputMaybe<Scalars['String']['input']>
    status_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    status_not_i?: InputMaybe<Scalars['String']['input']>
    status_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status_not_starts_with?: InputMaybe<Scalars['String']['input']>
    status_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    status_starts_with?: InputMaybe<Scalars['String']['input']>
    status_starts_with_i?: InputMaybe<Scalars['String']['input']>
    transferDate?: InputMaybe<Scalars['String']['input']>
    transferDate_gt?: InputMaybe<Scalars['String']['input']>
    transferDate_gte?: InputMaybe<Scalars['String']['input']>
    transferDate_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    transferDate_lt?: InputMaybe<Scalars['String']['input']>
    transferDate_lte?: InputMaybe<Scalars['String']['input']>
    transferDate_not?: InputMaybe<Scalars['String']['input']>
    transferDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type PaymentHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type PaymentHistoryRecordsCreateInput = {
    data?: InputMaybe<PaymentHistoryRecordCreateInput>
}

export type PaymentHistoryRecordsUpdateInput = {
    data?: InputMaybe<PaymentHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type PaymentOverviewResult = {
    __typename?: 'PaymentOverviewResult'
    payments?: Maybe<Array<PaymentGroupedCounter>>
    sum: Scalars['String']['output']
}

export type PaymentRelateToManyInput = {
    connect?: InputMaybe<Array<InputMaybe<PaymentWhereUniqueInput>>>
    create?: InputMaybe<Array<InputMaybe<PaymentCreateInput>>>
    disconnect?: InputMaybe<Array<InputMaybe<PaymentWhereUniqueInput>>>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export enum PaymentStatusType {
    Created = 'CREATED',
    Done = 'DONE',
    Error = 'ERROR',
    Processing = 'PROCESSING',
    Withdrawn = 'WITHDRAWN',
}

export type PaymentUpdateInput = {
    accountNumber?: InputMaybe<Scalars['String']['input']>
    advancedAt?: InputMaybe<Scalars['String']['input']>
    amount?: InputMaybe<Scalars['String']['input']>
    context?: InputMaybe<AcquiringIntegrationContextRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    currencyCode?: InputMaybe<PaymentCurrencyCodeType>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    depositedDate?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    explicitFee?: InputMaybe<Scalars['String']['input']>
    explicitServiceCharge?: InputMaybe<Scalars['String']['input']>
    frozenInvoice?: InputMaybe<Scalars['JSON']['input']>
    frozenReceipt?: InputMaybe<Scalars['JSON']['input']>
    implicitFee?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    invoice?: InputMaybe<InvoiceRelateToOneInput>
    multiPayment?: InputMaybe<MultiPaymentRelateToOneInput>
    newId?: InputMaybe<Scalars['String']['input']>
    order?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    period?: InputMaybe<Scalars['String']['input']>
    purpose?: InputMaybe<Scalars['String']['input']>
    rawAddress?: InputMaybe<Scalars['String']['input']>
    receipt?: InputMaybe<BillingReceiptRelateToOneInput>
    recipient?: InputMaybe<RecipientFieldInput>
    recipientBankAccount?: InputMaybe<Scalars['String']['input']>
    recipientBic?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    serviceFee?: InputMaybe<Scalars['String']['input']>
    status?: InputMaybe<PaymentStatusType>
    transferDate?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type PaymentWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<PaymentWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<PaymentWhereInput>>>
    accountNumber?: InputMaybe<Scalars['String']['input']>
    accountNumber_contains?: InputMaybe<Scalars['String']['input']>
    accountNumber_contains_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_ends_with?: InputMaybe<Scalars['String']['input']>
    accountNumber_ends_with_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    accountNumber_not?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_contains?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_contains_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_ends_with?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    accountNumber_not_starts_with?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_starts_with?: InputMaybe<Scalars['String']['input']>
    accountNumber_starts_with_i?: InputMaybe<Scalars['String']['input']>
    advancedAt?: InputMaybe<Scalars['String']['input']>
    advancedAt_gt?: InputMaybe<Scalars['String']['input']>
    advancedAt_gte?: InputMaybe<Scalars['String']['input']>
    advancedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    advancedAt_lt?: InputMaybe<Scalars['String']['input']>
    advancedAt_lte?: InputMaybe<Scalars['String']['input']>
    advancedAt_not?: InputMaybe<Scalars['String']['input']>
    advancedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    amount?: InputMaybe<Scalars['String']['input']>
    amount_gt?: InputMaybe<Scalars['String']['input']>
    amount_gte?: InputMaybe<Scalars['String']['input']>
    amount_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    amount_lt?: InputMaybe<Scalars['String']['input']>
    amount_lte?: InputMaybe<Scalars['String']['input']>
    amount_not?: InputMaybe<Scalars['String']['input']>
    amount_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    context?: InputMaybe<AcquiringIntegrationContextWhereInput>
    context_is_null?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    currencyCode?: InputMaybe<PaymentCurrencyCodeType>
    currencyCode_in?: InputMaybe<Array<InputMaybe<PaymentCurrencyCodeType>>>
    currencyCode_not?: InputMaybe<PaymentCurrencyCodeType>
    currencyCode_not_in?: InputMaybe<Array<InputMaybe<PaymentCurrencyCodeType>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    depositedDate?: InputMaybe<Scalars['String']['input']>
    depositedDate_gt?: InputMaybe<Scalars['String']['input']>
    depositedDate_gte?: InputMaybe<Scalars['String']['input']>
    depositedDate_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    depositedDate_lt?: InputMaybe<Scalars['String']['input']>
    depositedDate_lte?: InputMaybe<Scalars['String']['input']>
    depositedDate_not?: InputMaybe<Scalars['String']['input']>
    depositedDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    explicitFee?: InputMaybe<Scalars['String']['input']>
    explicitFee_gt?: InputMaybe<Scalars['String']['input']>
    explicitFee_gte?: InputMaybe<Scalars['String']['input']>
    explicitFee_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    explicitFee_lt?: InputMaybe<Scalars['String']['input']>
    explicitFee_lte?: InputMaybe<Scalars['String']['input']>
    explicitFee_not?: InputMaybe<Scalars['String']['input']>
    explicitFee_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    explicitServiceCharge?: InputMaybe<Scalars['String']['input']>
    explicitServiceCharge_gt?: InputMaybe<Scalars['String']['input']>
    explicitServiceCharge_gte?: InputMaybe<Scalars['String']['input']>
    explicitServiceCharge_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    explicitServiceCharge_lt?: InputMaybe<Scalars['String']['input']>
    explicitServiceCharge_lte?: InputMaybe<Scalars['String']['input']>
    explicitServiceCharge_not?: InputMaybe<Scalars['String']['input']>
    explicitServiceCharge_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    frozenInvoice?: InputMaybe<Scalars['JSON']['input']>
    frozenInvoice_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    frozenInvoice_not?: InputMaybe<Scalars['JSON']['input']>
    frozenInvoice_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    frozenReceipt?: InputMaybe<Scalars['JSON']['input']>
    frozenReceipt_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    frozenReceipt_not?: InputMaybe<Scalars['JSON']['input']>
    frozenReceipt_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    implicitFee?: InputMaybe<Scalars['String']['input']>
    implicitFee_gt?: InputMaybe<Scalars['String']['input']>
    implicitFee_gte?: InputMaybe<Scalars['String']['input']>
    implicitFee_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    implicitFee_lt?: InputMaybe<Scalars['String']['input']>
    implicitFee_lte?: InputMaybe<Scalars['String']['input']>
    implicitFee_not?: InputMaybe<Scalars['String']['input']>
    implicitFee_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId?: InputMaybe<Scalars['String']['input']>
    importId_contains?: InputMaybe<Scalars['String']['input']>
    importId_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_i?: InputMaybe<Scalars['String']['input']>
    importId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not?: InputMaybe<Scalars['String']['input']>
    importId_not_contains?: InputMaybe<Scalars['String']['input']>
    importId_not_contains_i?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with?: InputMaybe<Scalars['String']['input']>
    importId_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    importId_not_i?: InputMaybe<Scalars['String']['input']>
    importId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    importId_not_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    importId_starts_with?: InputMaybe<Scalars['String']['input']>
    importId_starts_with_i?: InputMaybe<Scalars['String']['input']>
    invoice?: InputMaybe<InvoiceWhereInput>
    invoice_is_null?: InputMaybe<Scalars['Boolean']['input']>
    multiPayment?: InputMaybe<MultiPaymentWhereInput>
    multiPayment_is_null?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    order?: InputMaybe<Scalars['String']['input']>
    order_contains?: InputMaybe<Scalars['String']['input']>
    order_contains_i?: InputMaybe<Scalars['String']['input']>
    order_ends_with?: InputMaybe<Scalars['String']['input']>
    order_ends_with_i?: InputMaybe<Scalars['String']['input']>
    order_i?: InputMaybe<Scalars['String']['input']>
    order_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    order_not?: InputMaybe<Scalars['String']['input']>
    order_not_contains?: InputMaybe<Scalars['String']['input']>
    order_not_contains_i?: InputMaybe<Scalars['String']['input']>
    order_not_ends_with?: InputMaybe<Scalars['String']['input']>
    order_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    order_not_i?: InputMaybe<Scalars['String']['input']>
    order_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    order_not_starts_with?: InputMaybe<Scalars['String']['input']>
    order_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    order_starts_with?: InputMaybe<Scalars['String']['input']>
    order_starts_with_i?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    period?: InputMaybe<Scalars['String']['input']>
    period_gt?: InputMaybe<Scalars['String']['input']>
    period_gte?: InputMaybe<Scalars['String']['input']>
    period_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    period_lt?: InputMaybe<Scalars['String']['input']>
    period_lte?: InputMaybe<Scalars['String']['input']>
    period_not?: InputMaybe<Scalars['String']['input']>
    period_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    purpose?: InputMaybe<Scalars['String']['input']>
    purpose_contains?: InputMaybe<Scalars['String']['input']>
    purpose_contains_i?: InputMaybe<Scalars['String']['input']>
    purpose_ends_with?: InputMaybe<Scalars['String']['input']>
    purpose_ends_with_i?: InputMaybe<Scalars['String']['input']>
    purpose_i?: InputMaybe<Scalars['String']['input']>
    purpose_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    purpose_not?: InputMaybe<Scalars['String']['input']>
    purpose_not_contains?: InputMaybe<Scalars['String']['input']>
    purpose_not_contains_i?: InputMaybe<Scalars['String']['input']>
    purpose_not_ends_with?: InputMaybe<Scalars['String']['input']>
    purpose_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    purpose_not_i?: InputMaybe<Scalars['String']['input']>
    purpose_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    purpose_not_starts_with?: InputMaybe<Scalars['String']['input']>
    purpose_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    purpose_starts_with?: InputMaybe<Scalars['String']['input']>
    purpose_starts_with_i?: InputMaybe<Scalars['String']['input']>
    rawAddress?: InputMaybe<Scalars['String']['input']>
    rawAddress_contains?: InputMaybe<Scalars['String']['input']>
    rawAddress_contains_i?: InputMaybe<Scalars['String']['input']>
    rawAddress_ends_with?: InputMaybe<Scalars['String']['input']>
    rawAddress_ends_with_i?: InputMaybe<Scalars['String']['input']>
    rawAddress_i?: InputMaybe<Scalars['String']['input']>
    rawAddress_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    rawAddress_not?: InputMaybe<Scalars['String']['input']>
    rawAddress_not_contains?: InputMaybe<Scalars['String']['input']>
    rawAddress_not_contains_i?: InputMaybe<Scalars['String']['input']>
    rawAddress_not_ends_with?: InputMaybe<Scalars['String']['input']>
    rawAddress_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    rawAddress_not_i?: InputMaybe<Scalars['String']['input']>
    rawAddress_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    rawAddress_not_starts_with?: InputMaybe<Scalars['String']['input']>
    rawAddress_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    rawAddress_starts_with?: InputMaybe<Scalars['String']['input']>
    rawAddress_starts_with_i?: InputMaybe<Scalars['String']['input']>
    receipt?: InputMaybe<BillingReceiptWhereInput>
    receipt_is_null?: InputMaybe<Scalars['Boolean']['input']>
    recipient?: InputMaybe<RecipientFieldInput>
    recipientBankAccount?: InputMaybe<Scalars['String']['input']>
    recipientBankAccount_contains?: InputMaybe<Scalars['String']['input']>
    recipientBankAccount_contains_i?: InputMaybe<Scalars['String']['input']>
    recipientBankAccount_ends_with?: InputMaybe<Scalars['String']['input']>
    recipientBankAccount_ends_with_i?: InputMaybe<Scalars['String']['input']>
    recipientBankAccount_i?: InputMaybe<Scalars['String']['input']>
    recipientBankAccount_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    recipientBankAccount_not?: InputMaybe<Scalars['String']['input']>
    recipientBankAccount_not_contains?: InputMaybe<Scalars['String']['input']>
    recipientBankAccount_not_contains_i?: InputMaybe<Scalars['String']['input']>
    recipientBankAccount_not_ends_with?: InputMaybe<Scalars['String']['input']>
    recipientBankAccount_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    recipientBankAccount_not_i?: InputMaybe<Scalars['String']['input']>
    recipientBankAccount_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    recipientBankAccount_not_starts_with?: InputMaybe<Scalars['String']['input']>
    recipientBankAccount_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    recipientBankAccount_starts_with?: InputMaybe<Scalars['String']['input']>
    recipientBankAccount_starts_with_i?: InputMaybe<Scalars['String']['input']>
    recipientBic?: InputMaybe<Scalars['String']['input']>
    recipientBic_contains?: InputMaybe<Scalars['String']['input']>
    recipientBic_contains_i?: InputMaybe<Scalars['String']['input']>
    recipientBic_ends_with?: InputMaybe<Scalars['String']['input']>
    recipientBic_ends_with_i?: InputMaybe<Scalars['String']['input']>
    recipientBic_i?: InputMaybe<Scalars['String']['input']>
    recipientBic_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    recipientBic_not?: InputMaybe<Scalars['String']['input']>
    recipientBic_not_contains?: InputMaybe<Scalars['String']['input']>
    recipientBic_not_contains_i?: InputMaybe<Scalars['String']['input']>
    recipientBic_not_ends_with?: InputMaybe<Scalars['String']['input']>
    recipientBic_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    recipientBic_not_i?: InputMaybe<Scalars['String']['input']>
    recipientBic_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    recipientBic_not_starts_with?: InputMaybe<Scalars['String']['input']>
    recipientBic_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    recipientBic_starts_with?: InputMaybe<Scalars['String']['input']>
    recipientBic_starts_with_i?: InputMaybe<Scalars['String']['input']>
    recipient_in?: InputMaybe<Array<InputMaybe<RecipientFieldInput>>>
    recipient_not?: InputMaybe<RecipientFieldInput>
    recipient_not_in?: InputMaybe<Array<InputMaybe<RecipientFieldInput>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    serviceFee?: InputMaybe<Scalars['String']['input']>
    serviceFee_gt?: InputMaybe<Scalars['String']['input']>
    serviceFee_gte?: InputMaybe<Scalars['String']['input']>
    serviceFee_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    serviceFee_lt?: InputMaybe<Scalars['String']['input']>
    serviceFee_lte?: InputMaybe<Scalars['String']['input']>
    serviceFee_not?: InputMaybe<Scalars['String']['input']>
    serviceFee_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status?: InputMaybe<PaymentStatusType>
    status_in?: InputMaybe<Array<InputMaybe<PaymentStatusType>>>
    status_not?: InputMaybe<PaymentStatusType>
    status_not_in?: InputMaybe<Array<InputMaybe<PaymentStatusType>>>
    transferDate?: InputMaybe<Scalars['String']['input']>
    transferDate_gt?: InputMaybe<Scalars['String']['input']>
    transferDate_gte?: InputMaybe<Scalars['String']['input']>
    transferDate_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    transferDate_lt?: InputMaybe<Scalars['String']['input']>
    transferDate_lte?: InputMaybe<Scalars['String']['input']>
    transferDate_not?: InputMaybe<Scalars['String']['input']>
    transferDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type PaymentWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type PaymentsCreateInput = {
    data?: InputMaybe<PaymentCreateInput>
}

export type PaymentsFilter = {
    __typename?: 'PaymentsFilter'
    accountNumber?: Maybe<Scalars['String']['output']>
    address?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    advancedAt?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    depositedDate?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    order?: Maybe<Scalars['String']['output']>
    status?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    transferDate?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    type?: Maybe<Array<Maybe<Scalars['String']['output']>>>
}

export type PaymentsFilterInput = {
    accountNumber?: InputMaybe<Scalars['String']['input']>
    address?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    advancedAt?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    depositedDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    order?: InputMaybe<Scalars['String']['input']>
    status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    transferDate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/**  The payments filter preset  */
export type PaymentsFilterTemplate = {
    __typename?: 'PaymentsFilterTemplate'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the PaymentsFilterTemplate List config, or
   *  2. As an alias to the field set on 'labelField' in the PaymentsFilterTemplate List config, or
   *  3. As an alias to a 'name' field on the PaymentsFilterTemplate List (if one exists), or
   *  4. As an alias to the 'id' field on the PaymentsFilterTemplate List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  Preset owner  */
    employee?: Maybe<OrganizationEmployee>
    /**  Filter that match the given template  */
    fields?: Maybe<PaymentsFilter>
    id: Scalars['ID']['output']
    /**  Preset name  */
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type PaymentsFilterTemplateCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    employee?: InputMaybe<OrganizationEmployeeRelateToOneInput>
    fields?: InputMaybe<PaymentsFilterInput>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type PaymentsFilterTemplateHistoryRecord = {
    __typename?: 'PaymentsFilterTemplateHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the PaymentsFilterTemplateHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the PaymentsFilterTemplateHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the PaymentsFilterTemplateHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the PaymentsFilterTemplateHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    employee?: Maybe<Scalars['String']['output']>
    fields?: Maybe<Scalars['JSON']['output']>
    history_action?: Maybe<PaymentsFilterTemplateHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type PaymentsFilterTemplateHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    employee?: InputMaybe<Scalars['String']['input']>
    fields?: InputMaybe<Scalars['JSON']['input']>
    history_action?: InputMaybe<PaymentsFilterTemplateHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum PaymentsFilterTemplateHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type PaymentsFilterTemplateHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    employee?: InputMaybe<Scalars['String']['input']>
    fields?: InputMaybe<Scalars['JSON']['input']>
    history_action?: InputMaybe<PaymentsFilterTemplateHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type PaymentsFilterTemplateHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<PaymentsFilterTemplateHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<PaymentsFilterTemplateHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    employee?: InputMaybe<Scalars['String']['input']>
    employee_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    employee_not?: InputMaybe<Scalars['String']['input']>
    employee_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    fields?: InputMaybe<Scalars['JSON']['input']>
    fields_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    fields_not?: InputMaybe<Scalars['JSON']['input']>
    fields_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    history_action?: InputMaybe<PaymentsFilterTemplateHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<PaymentsFilterTemplateHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<PaymentsFilterTemplateHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<PaymentsFilterTemplateHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type PaymentsFilterTemplateHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type PaymentsFilterTemplateHistoryRecordsCreateInput = {
    data?: InputMaybe<PaymentsFilterTemplateHistoryRecordCreateInput>
}

export type PaymentsFilterTemplateHistoryRecordsUpdateInput = {
    data?: InputMaybe<PaymentsFilterTemplateHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type PaymentsFilterTemplateUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    employee?: InputMaybe<OrganizationEmployeeRelateToOneInput>
    fields?: InputMaybe<PaymentsFilterInput>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type PaymentsFilterTemplateWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<PaymentsFilterTemplateWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<PaymentsFilterTemplateWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    employee?: InputMaybe<OrganizationEmployeeWhereInput>
    employee_is_null?: InputMaybe<Scalars['Boolean']['input']>
    fields?: InputMaybe<PaymentsFilterInput>
    fields_in?: InputMaybe<Array<InputMaybe<PaymentsFilterInput>>>
    fields_not?: InputMaybe<PaymentsFilterInput>
    fields_not_in?: InputMaybe<Array<InputMaybe<PaymentsFilterInput>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type PaymentsFilterTemplateWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type PaymentsFilterTemplatesCreateInput = {
    data?: InputMaybe<PaymentsFilterTemplateCreateInput>
}

export type PaymentsFilterTemplatesUpdateInput = {
    data?: InputMaybe<PaymentsFilterTemplateUpdateInput>
    id: Scalars['ID']['input']
}

export type PaymentsSumOutput = {
    __typename?: 'PaymentsSumOutput'
    sum: Scalars['String']['output']
}

export type PaymentsUpdateInput = {
    data?: InputMaybe<PaymentUpdateInput>
    id: Scalars['ID']['input']
}

export type PredictTicketClassificationInput = {
    details: Scalars['String']['input']
}

export type PredictTransactionClassificationInput = {
    isOutcome: Scalars['Boolean']['input']
    purpose: Scalars['String']['input']
}

export type PredictTransactionClassificationOutput = {
    __typename?: 'PredictTransactionClassificationOutput'
    category: Scalars['ID']['output']
    id: Scalars['ID']['output']
    isOutcome: Scalars['Boolean']['output']
    name: Scalars['String']['output']
}

export type PropertiesCreateInput = {
    data?: InputMaybe<PropertyCreateInput>
}

export type PropertiesUpdateInput = {
    data?: InputMaybe<PropertyUpdateInput>
    id: Scalars['ID']['input']
}

/**  Common property. The property is divided into separate `unit` parts, each of which can be owned by an independent owner. Community farm, residential buildings, or a cottage settlement  */
export type Property = {
    __typename?: 'Property'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the Property List config, or
   *  2. As an alias to the field set on 'labelField' in the Property List config, or
   *  3. As an alias to a 'name' field on the Property List (if one exists), or
   *  4. As an alias to the 'id' field on the Property List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  Normalized address  */
    address?: Maybe<Scalars['String']['output']>
    /**  The unique key of the address  */
    addressKey?: Maybe<Scalars['String']['output']>
    /**  Property address components  */
    addressMeta?: Maybe<AddressMetaField>
    /**  The origins of the address (some strings which may looks like real address or some id)  */
    addressSources?: Maybe<Scalars['JSON']['output']>
    /**  Property area in square meters  */
    area?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  Whether or not this organization can manage this property. Usually set by support. Defaults to False. Field is dropped to false if address is updated  */
    isApproved?: Maybe<Scalars['Boolean']['output']>
    /**  Property map/schema  */
    map?: Maybe<BuildingMap>
    /**  Client understandable Property name. A well-known property name for the client  */
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
    organization?: Maybe<Organization>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Counter for closed tickets  */
    ticketsClosed?: Maybe<Scalars['String']['output']>
    /**  Counter for deferred tickets  */
    ticketsDeferred?: Maybe<Scalars['String']['output']>
    /**  Counter for not closed tickets  */
    ticketsInWork?: Maybe<Scalars['String']['output']>
    /**  Common property type  */
    type?: Maybe<PropertyTypeType>
    /**  A number of non-residential units. Number of parking places for unit.unitType = parking, apartment, commercial & warehouse  */
    uninhabitedUnitsCount?: Maybe<Scalars['Int']['output']>
    /**  A number of parts in the property. The number of flats for property.type = house. The number of garden houses for property.type = village.  */
    unitsCount?: Maybe<Scalars['Int']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
    /**  Year of the property was built  */
    yearOfConstruction?: Maybe<Scalars['String']['output']>
}

export type PropertyCreateInput = {
    address?: InputMaybe<Scalars['String']['input']>
    addressKey?: InputMaybe<Scalars['String']['input']>
    addressMeta?: InputMaybe<Scalars['JSON']['input']>
    addressSources?: InputMaybe<Scalars['JSON']['input']>
    area?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    isApproved?: InputMaybe<Scalars['Boolean']['input']>
    map?: InputMaybe<Scalars['JSON']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    type?: InputMaybe<PropertyTypeType>
    uninhabitedUnitsCount?: InputMaybe<Scalars['Int']['input']>
    unitsCount?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
    yearOfConstruction?: InputMaybe<Scalars['String']['input']>
}

/**  A keystone list  */
export type PropertyHistoryRecord = {
    __typename?: 'PropertyHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the PropertyHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the PropertyHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the PropertyHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the PropertyHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    address?: Maybe<Scalars['String']['output']>
    addressKey?: Maybe<Scalars['String']['output']>
    addressMeta?: Maybe<Scalars['JSON']['output']>
    addressSources?: Maybe<Scalars['JSON']['output']>
    area?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<PropertyHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    isApproved?: Maybe<Scalars['Boolean']['output']>
    map?: Maybe<Scalars['JSON']['output']>
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    type?: Maybe<Scalars['String']['output']>
    uninhabitedUnitsCount?: Maybe<Scalars['Int']['output']>
    unitsCount?: Maybe<Scalars['Int']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
    yearOfConstruction?: Maybe<Scalars['String']['output']>
}

export type PropertyHistoryRecordCreateInput = {
    address?: InputMaybe<Scalars['String']['input']>
    addressKey?: InputMaybe<Scalars['String']['input']>
    addressMeta?: InputMaybe<Scalars['JSON']['input']>
    addressSources?: InputMaybe<Scalars['JSON']['input']>
    area?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<PropertyHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    isApproved?: InputMaybe<Scalars['Boolean']['input']>
    map?: InputMaybe<Scalars['JSON']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    type?: InputMaybe<Scalars['String']['input']>
    uninhabitedUnitsCount?: InputMaybe<Scalars['Int']['input']>
    unitsCount?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    yearOfConstruction?: InputMaybe<Scalars['String']['input']>
}

export enum PropertyHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type PropertyHistoryRecordUpdateInput = {
    address?: InputMaybe<Scalars['String']['input']>
    addressKey?: InputMaybe<Scalars['String']['input']>
    addressMeta?: InputMaybe<Scalars['JSON']['input']>
    addressSources?: InputMaybe<Scalars['JSON']['input']>
    area?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<PropertyHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    isApproved?: InputMaybe<Scalars['Boolean']['input']>
    map?: InputMaybe<Scalars['JSON']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    type?: InputMaybe<Scalars['String']['input']>
    uninhabitedUnitsCount?: InputMaybe<Scalars['Int']['input']>
    unitsCount?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    yearOfConstruction?: InputMaybe<Scalars['String']['input']>
}

export type PropertyHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<PropertyHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<PropertyHistoryRecordWhereInput>>>
    address?: InputMaybe<Scalars['String']['input']>
    addressKey?: InputMaybe<Scalars['String']['input']>
    addressKey_contains?: InputMaybe<Scalars['String']['input']>
    addressKey_contains_i?: InputMaybe<Scalars['String']['input']>
    addressKey_ends_with?: InputMaybe<Scalars['String']['input']>
    addressKey_ends_with_i?: InputMaybe<Scalars['String']['input']>
    addressKey_i?: InputMaybe<Scalars['String']['input']>
    addressKey_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    addressKey_not?: InputMaybe<Scalars['String']['input']>
    addressKey_not_contains?: InputMaybe<Scalars['String']['input']>
    addressKey_not_contains_i?: InputMaybe<Scalars['String']['input']>
    addressKey_not_ends_with?: InputMaybe<Scalars['String']['input']>
    addressKey_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    addressKey_not_i?: InputMaybe<Scalars['String']['input']>
    addressKey_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    addressKey_not_starts_with?: InputMaybe<Scalars['String']['input']>
    addressKey_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    addressKey_starts_with?: InputMaybe<Scalars['String']['input']>
    addressKey_starts_with_i?: InputMaybe<Scalars['String']['input']>
    addressMeta?: InputMaybe<Scalars['JSON']['input']>
    addressMeta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    addressMeta_not?: InputMaybe<Scalars['JSON']['input']>
    addressMeta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    addressSources?: InputMaybe<Scalars['JSON']['input']>
    addressSources_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    addressSources_not?: InputMaybe<Scalars['JSON']['input']>
    addressSources_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    address_contains?: InputMaybe<Scalars['String']['input']>
    address_contains_i?: InputMaybe<Scalars['String']['input']>
    address_ends_with?: InputMaybe<Scalars['String']['input']>
    address_ends_with_i?: InputMaybe<Scalars['String']['input']>
    address_i?: InputMaybe<Scalars['String']['input']>
    address_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    address_not?: InputMaybe<Scalars['String']['input']>
    address_not_contains?: InputMaybe<Scalars['String']['input']>
    address_not_contains_i?: InputMaybe<Scalars['String']['input']>
    address_not_ends_with?: InputMaybe<Scalars['String']['input']>
    address_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    address_not_i?: InputMaybe<Scalars['String']['input']>
    address_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    address_not_starts_with?: InputMaybe<Scalars['String']['input']>
    address_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    address_starts_with?: InputMaybe<Scalars['String']['input']>
    address_starts_with_i?: InputMaybe<Scalars['String']['input']>
    area?: InputMaybe<Scalars['String']['input']>
    area_gt?: InputMaybe<Scalars['String']['input']>
    area_gte?: InputMaybe<Scalars['String']['input']>
    area_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    area_lt?: InputMaybe<Scalars['String']['input']>
    area_lte?: InputMaybe<Scalars['String']['input']>
    area_not?: InputMaybe<Scalars['String']['input']>
    area_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<PropertyHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<PropertyHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<PropertyHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<PropertyHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    isApproved?: InputMaybe<Scalars['Boolean']['input']>
    isApproved_not?: InputMaybe<Scalars['Boolean']['input']>
    map?: InputMaybe<Scalars['JSON']['input']>
    map_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    map_not?: InputMaybe<Scalars['JSON']['input']>
    map_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    type?: InputMaybe<Scalars['String']['input']>
    type_contains?: InputMaybe<Scalars['String']['input']>
    type_contains_i?: InputMaybe<Scalars['String']['input']>
    type_ends_with?: InputMaybe<Scalars['String']['input']>
    type_ends_with_i?: InputMaybe<Scalars['String']['input']>
    type_i?: InputMaybe<Scalars['String']['input']>
    type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    type_not?: InputMaybe<Scalars['String']['input']>
    type_not_contains?: InputMaybe<Scalars['String']['input']>
    type_not_contains_i?: InputMaybe<Scalars['String']['input']>
    type_not_ends_with?: InputMaybe<Scalars['String']['input']>
    type_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    type_not_i?: InputMaybe<Scalars['String']['input']>
    type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    type_not_starts_with?: InputMaybe<Scalars['String']['input']>
    type_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    type_starts_with?: InputMaybe<Scalars['String']['input']>
    type_starts_with_i?: InputMaybe<Scalars['String']['input']>
    uninhabitedUnitsCount?: InputMaybe<Scalars['Int']['input']>
    uninhabitedUnitsCount_gt?: InputMaybe<Scalars['Int']['input']>
    uninhabitedUnitsCount_gte?: InputMaybe<Scalars['Int']['input']>
    uninhabitedUnitsCount_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    uninhabitedUnitsCount_lt?: InputMaybe<Scalars['Int']['input']>
    uninhabitedUnitsCount_lte?: InputMaybe<Scalars['Int']['input']>
    uninhabitedUnitsCount_not?: InputMaybe<Scalars['Int']['input']>
    uninhabitedUnitsCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    unitsCount?: InputMaybe<Scalars['Int']['input']>
    unitsCount_gt?: InputMaybe<Scalars['Int']['input']>
    unitsCount_gte?: InputMaybe<Scalars['Int']['input']>
    unitsCount_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    unitsCount_lt?: InputMaybe<Scalars['Int']['input']>
    unitsCount_lte?: InputMaybe<Scalars['Int']['input']>
    unitsCount_not?: InputMaybe<Scalars['Int']['input']>
    unitsCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    yearOfConstruction?: InputMaybe<Scalars['String']['input']>
    yearOfConstruction_gt?: InputMaybe<Scalars['String']['input']>
    yearOfConstruction_gte?: InputMaybe<Scalars['String']['input']>
    yearOfConstruction_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    yearOfConstruction_lt?: InputMaybe<Scalars['String']['input']>
    yearOfConstruction_lte?: InputMaybe<Scalars['String']['input']>
    yearOfConstruction_not?: InputMaybe<Scalars['String']['input']>
    yearOfConstruction_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type PropertyHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type PropertyHistoryRecordsCreateInput = {
    data?: InputMaybe<PropertyHistoryRecordCreateInput>
}

export type PropertyHistoryRecordsUpdateInput = {
    data?: InputMaybe<PropertyHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type PropertyMap = BuildingMap | VillageMap

/**  Resource meter installed on the entire apartment building  */
export type PropertyMeter = {
    __typename?: 'PropertyMeter'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the PropertyMeter List config, or
   *  2. As an alias to the field set on 'labelField' in the PropertyMeter List config, or
   *  3. As an alias to a 'name' field on the PropertyMeter List (if one exists), or
   *  4. As an alias to the 'id' field on the PropertyMeter List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  The date when the meter was disabled and no longer in use  */
    archiveDate?: Maybe<Scalars['String']['output']>
    /**  Ref to B2BApp, which is used as a master system for this meter. Specified organization must connect this app.  */
    b2bApp?: Maybe<B2BApp>
    /**  Date when the meter was commissioned.Commissioning - documentation of the meter as a billing meter  */
    commissioningDate?: Maybe<Scalars['String']['output']>
    /**  The date when the employee came and took readings from the meter  */
    controlReadingsDate?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  Date when the meter was installed  */
    installationDate?: Maybe<Scalars['String']['output']>
    /**  Determines, if Meter is automatic or not. False by default. If set to True - prevents user with type "resident" from creating MeterReading.So MeterReadings only be acquired through external integration or adjusted by organization employee  */
    isAutomatic?: Maybe<Scalars['Boolean']['output']>
    /**  Meter metadata. Can be used to store additional settings from external sources, such as billing integrations or mini apps  */
    meta?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  The date of the next meter verification.For example, for a cold water meter - usually 6 years after the verification date  */
    nextVerificationDate?: Maybe<Scalars['String']['output']>
    /**  Number of resource meter, such as "А03 9908"  */
    number?: Maybe<Scalars['String']['output']>
    numberOfTariffs?: Maybe<Scalars['Int']['output']>
    /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
    organization?: Maybe<Organization>
    /**  Link to property which contains this meter  */
    property?: Maybe<Property>
    /**  Meter resource, such as hot water or electricity  */
    resource?: Maybe<MeterResource>
    /**  The date when meter was sealed.Sealing is the installation of a unique single-use device (directly a seal and a sealing rope)on the metering device, which is designed to control unauthorized access to the equipment.  */
    sealingDate?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
    /**  The date when the employee came and checked how accurately the meter counts the resource  */
    verificationDate?: Maybe<Scalars['String']['output']>
}

export type PropertyMeterCreateInput = {
    archiveDate?: InputMaybe<Scalars['String']['input']>
    b2bApp?: InputMaybe<B2BAppRelateToOneInput>
    commissioningDate?: InputMaybe<Scalars['String']['input']>
    controlReadingsDate?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    installationDate?: InputMaybe<Scalars['String']['input']>
    isAutomatic?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    nextVerificationDate?: InputMaybe<Scalars['String']['input']>
    number?: InputMaybe<Scalars['String']['input']>
    numberOfTariffs?: InputMaybe<Scalars['Int']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    property?: InputMaybe<PropertyRelateToOneInput>
    resource?: InputMaybe<MeterResourceRelateToOneInput>
    sealingDate?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
    verificationDate?: InputMaybe<Scalars['String']['input']>
}

/**  A keystone list  */
export type PropertyMeterHistoryRecord = {
    __typename?: 'PropertyMeterHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the PropertyMeterHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the PropertyMeterHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the PropertyMeterHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the PropertyMeterHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    archiveDate?: Maybe<Scalars['String']['output']>
    b2bApp?: Maybe<Scalars['String']['output']>
    commissioningDate?: Maybe<Scalars['String']['output']>
    controlReadingsDate?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<PropertyMeterHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    installationDate?: Maybe<Scalars['String']['output']>
    isAutomatic?: Maybe<Scalars['Boolean']['output']>
    meta?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    nextVerificationDate?: Maybe<Scalars['String']['output']>
    number?: Maybe<Scalars['String']['output']>
    numberOfTariffs?: Maybe<Scalars['Int']['output']>
    organization?: Maybe<Scalars['String']['output']>
    property?: Maybe<Scalars['String']['output']>
    resource?: Maybe<Scalars['String']['output']>
    sealingDate?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
    verificationDate?: Maybe<Scalars['String']['output']>
}

export type PropertyMeterHistoryRecordCreateInput = {
    archiveDate?: InputMaybe<Scalars['String']['input']>
    b2bApp?: InputMaybe<Scalars['String']['input']>
    commissioningDate?: InputMaybe<Scalars['String']['input']>
    controlReadingsDate?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<PropertyMeterHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    installationDate?: InputMaybe<Scalars['String']['input']>
    isAutomatic?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    nextVerificationDate?: InputMaybe<Scalars['String']['input']>
    number?: InputMaybe<Scalars['String']['input']>
    numberOfTariffs?: InputMaybe<Scalars['Int']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    property?: InputMaybe<Scalars['String']['input']>
    resource?: InputMaybe<Scalars['String']['input']>
    sealingDate?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    verificationDate?: InputMaybe<Scalars['String']['input']>
}

export enum PropertyMeterHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type PropertyMeterHistoryRecordUpdateInput = {
    archiveDate?: InputMaybe<Scalars['String']['input']>
    b2bApp?: InputMaybe<Scalars['String']['input']>
    commissioningDate?: InputMaybe<Scalars['String']['input']>
    controlReadingsDate?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<PropertyMeterHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    installationDate?: InputMaybe<Scalars['String']['input']>
    isAutomatic?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    nextVerificationDate?: InputMaybe<Scalars['String']['input']>
    number?: InputMaybe<Scalars['String']['input']>
    numberOfTariffs?: InputMaybe<Scalars['Int']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    property?: InputMaybe<Scalars['String']['input']>
    resource?: InputMaybe<Scalars['String']['input']>
    sealingDate?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    verificationDate?: InputMaybe<Scalars['String']['input']>
}

export type PropertyMeterHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<PropertyMeterHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<PropertyMeterHistoryRecordWhereInput>>>
    archiveDate?: InputMaybe<Scalars['String']['input']>
    archiveDate_gt?: InputMaybe<Scalars['String']['input']>
    archiveDate_gte?: InputMaybe<Scalars['String']['input']>
    archiveDate_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    archiveDate_lt?: InputMaybe<Scalars['String']['input']>
    archiveDate_lte?: InputMaybe<Scalars['String']['input']>
    archiveDate_not?: InputMaybe<Scalars['String']['input']>
    archiveDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    b2bApp?: InputMaybe<Scalars['String']['input']>
    b2bApp_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    b2bApp_not?: InputMaybe<Scalars['String']['input']>
    b2bApp_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    commissioningDate?: InputMaybe<Scalars['String']['input']>
    commissioningDate_gt?: InputMaybe<Scalars['String']['input']>
    commissioningDate_gte?: InputMaybe<Scalars['String']['input']>
    commissioningDate_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    commissioningDate_lt?: InputMaybe<Scalars['String']['input']>
    commissioningDate_lte?: InputMaybe<Scalars['String']['input']>
    commissioningDate_not?: InputMaybe<Scalars['String']['input']>
    commissioningDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    controlReadingsDate?: InputMaybe<Scalars['String']['input']>
    controlReadingsDate_gt?: InputMaybe<Scalars['String']['input']>
    controlReadingsDate_gte?: InputMaybe<Scalars['String']['input']>
    controlReadingsDate_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    controlReadingsDate_lt?: InputMaybe<Scalars['String']['input']>
    controlReadingsDate_lte?: InputMaybe<Scalars['String']['input']>
    controlReadingsDate_not?: InputMaybe<Scalars['String']['input']>
    controlReadingsDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<PropertyMeterHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<PropertyMeterHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<PropertyMeterHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<PropertyMeterHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    installationDate?: InputMaybe<Scalars['String']['input']>
    installationDate_gt?: InputMaybe<Scalars['String']['input']>
    installationDate_gte?: InputMaybe<Scalars['String']['input']>
    installationDate_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    installationDate_lt?: InputMaybe<Scalars['String']['input']>
    installationDate_lte?: InputMaybe<Scalars['String']['input']>
    installationDate_not?: InputMaybe<Scalars['String']['input']>
    installationDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    isAutomatic?: InputMaybe<Scalars['Boolean']['input']>
    isAutomatic_not?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    nextVerificationDate?: InputMaybe<Scalars['String']['input']>
    nextVerificationDate_gt?: InputMaybe<Scalars['String']['input']>
    nextVerificationDate_gte?: InputMaybe<Scalars['String']['input']>
    nextVerificationDate_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    nextVerificationDate_lt?: InputMaybe<Scalars['String']['input']>
    nextVerificationDate_lte?: InputMaybe<Scalars['String']['input']>
    nextVerificationDate_not?: InputMaybe<Scalars['String']['input']>
    nextVerificationDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    number?: InputMaybe<Scalars['String']['input']>
    numberOfTariffs?: InputMaybe<Scalars['Int']['input']>
    numberOfTariffs_gt?: InputMaybe<Scalars['Int']['input']>
    numberOfTariffs_gte?: InputMaybe<Scalars['Int']['input']>
    numberOfTariffs_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    numberOfTariffs_lt?: InputMaybe<Scalars['Int']['input']>
    numberOfTariffs_lte?: InputMaybe<Scalars['Int']['input']>
    numberOfTariffs_not?: InputMaybe<Scalars['Int']['input']>
    numberOfTariffs_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    number_contains?: InputMaybe<Scalars['String']['input']>
    number_contains_i?: InputMaybe<Scalars['String']['input']>
    number_ends_with?: InputMaybe<Scalars['String']['input']>
    number_ends_with_i?: InputMaybe<Scalars['String']['input']>
    number_i?: InputMaybe<Scalars['String']['input']>
    number_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    number_not?: InputMaybe<Scalars['String']['input']>
    number_not_contains?: InputMaybe<Scalars['String']['input']>
    number_not_contains_i?: InputMaybe<Scalars['String']['input']>
    number_not_ends_with?: InputMaybe<Scalars['String']['input']>
    number_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    number_not_i?: InputMaybe<Scalars['String']['input']>
    number_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    number_not_starts_with?: InputMaybe<Scalars['String']['input']>
    number_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    number_starts_with?: InputMaybe<Scalars['String']['input']>
    number_starts_with_i?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    property?: InputMaybe<Scalars['String']['input']>
    property_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    property_not?: InputMaybe<Scalars['String']['input']>
    property_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    resource?: InputMaybe<Scalars['String']['input']>
    resource_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    resource_not?: InputMaybe<Scalars['String']['input']>
    resource_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sealingDate?: InputMaybe<Scalars['String']['input']>
    sealingDate_gt?: InputMaybe<Scalars['String']['input']>
    sealingDate_gte?: InputMaybe<Scalars['String']['input']>
    sealingDate_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sealingDate_lt?: InputMaybe<Scalars['String']['input']>
    sealingDate_lte?: InputMaybe<Scalars['String']['input']>
    sealingDate_not?: InputMaybe<Scalars['String']['input']>
    sealingDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    verificationDate?: InputMaybe<Scalars['String']['input']>
    verificationDate_gt?: InputMaybe<Scalars['String']['input']>
    verificationDate_gte?: InputMaybe<Scalars['String']['input']>
    verificationDate_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    verificationDate_lt?: InputMaybe<Scalars['String']['input']>
    verificationDate_lte?: InputMaybe<Scalars['String']['input']>
    verificationDate_not?: InputMaybe<Scalars['String']['input']>
    verificationDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type PropertyMeterHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type PropertyMeterHistoryRecordsCreateInput = {
    data?: InputMaybe<PropertyMeterHistoryRecordCreateInput>
}

export type PropertyMeterHistoryRecordsUpdateInput = {
    data?: InputMaybe<PropertyMeterHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

/**  Meter reading taken from a client or billing  */
export type PropertyMeterReading = {
    __typename?: 'PropertyMeterReading'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the PropertyMeterReading List config, or
   *  2. As an alias to the field set on 'labelField' in the PropertyMeterReading List config, or
   *  3. As an alias to a 'name' field on the PropertyMeterReading List (if one exists), or
   *  4. As an alias to the 'id' field on the PropertyMeterReading List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    /**  Date when the readings were taken  */
    date?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  Meter from which readings were taken  */
    meter?: Maybe<PropertyMeter>
    newId?: Maybe<Scalars['String']['output']>
    /**  Ref to the organization. It is filled in on the server and is read-only  */
    organization?: Maybe<Organization>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Meter reading source channel/system. Examples: call, mobile_app, billing, ...  */
    source?: Maybe<MeterReadingSource>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
    /**  If the meter is single-tariff, then only this value will be filled in;If multi-tariff, then the value of the first tariff will be in this field  */
    value1?: Maybe<Scalars['String']['output']>
    /**  If the meter is multi-tariff, then the value of the second tariff is stored here  */
    value2?: Maybe<Scalars['String']['output']>
    /**  If the meter is multi-tariff, then the value of the second tariff is stored here  */
    value3?: Maybe<Scalars['String']['output']>
    /**  If the meter is multi-tariff, then the value of the second tariff is stored here  */
    value4?: Maybe<Scalars['String']['output']>
}

export type PropertyMeterReadingCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    date?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    meter?: InputMaybe<PropertyMeterRelateToOneInput>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    source?: InputMaybe<MeterReadingSourceRelateToOneInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
    value1?: InputMaybe<Scalars['String']['input']>
    value2?: InputMaybe<Scalars['String']['input']>
    value3?: InputMaybe<Scalars['String']['input']>
    value4?: InputMaybe<Scalars['String']['input']>
}

/**  A keystone list  */
export type PropertyMeterReadingHistoryRecord = {
    __typename?: 'PropertyMeterReadingHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the PropertyMeterReadingHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the PropertyMeterReadingHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the PropertyMeterReadingHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the PropertyMeterReadingHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    date?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<PropertyMeterReadingHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    meter?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    source?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
    value1?: Maybe<Scalars['String']['output']>
    value2?: Maybe<Scalars['String']['output']>
    value3?: Maybe<Scalars['String']['output']>
    value4?: Maybe<Scalars['String']['output']>
}

export type PropertyMeterReadingHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    date?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<PropertyMeterReadingHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    meter?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    source?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    value1?: InputMaybe<Scalars['String']['input']>
    value2?: InputMaybe<Scalars['String']['input']>
    value3?: InputMaybe<Scalars['String']['input']>
    value4?: InputMaybe<Scalars['String']['input']>
}

export enum PropertyMeterReadingHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type PropertyMeterReadingHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    date?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<PropertyMeterReadingHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    meter?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    source?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    value1?: InputMaybe<Scalars['String']['input']>
    value2?: InputMaybe<Scalars['String']['input']>
    value3?: InputMaybe<Scalars['String']['input']>
    value4?: InputMaybe<Scalars['String']['input']>
}

export type PropertyMeterReadingHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<PropertyMeterReadingHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<PropertyMeterReadingHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    date?: InputMaybe<Scalars['String']['input']>
    date_gt?: InputMaybe<Scalars['String']['input']>
    date_gte?: InputMaybe<Scalars['String']['input']>
    date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    date_lt?: InputMaybe<Scalars['String']['input']>
    date_lte?: InputMaybe<Scalars['String']['input']>
    date_not?: InputMaybe<Scalars['String']['input']>
    date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<PropertyMeterReadingHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<PropertyMeterReadingHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<PropertyMeterReadingHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<PropertyMeterReadingHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    meter?: InputMaybe<Scalars['String']['input']>
    meter_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    meter_not?: InputMaybe<Scalars['String']['input']>
    meter_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    source?: InputMaybe<Scalars['String']['input']>
    source_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    source_not?: InputMaybe<Scalars['String']['input']>
    source_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    value1?: InputMaybe<Scalars['String']['input']>
    value1_gt?: InputMaybe<Scalars['String']['input']>
    value1_gte?: InputMaybe<Scalars['String']['input']>
    value1_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    value1_lt?: InputMaybe<Scalars['String']['input']>
    value1_lte?: InputMaybe<Scalars['String']['input']>
    value1_not?: InputMaybe<Scalars['String']['input']>
    value1_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    value2?: InputMaybe<Scalars['String']['input']>
    value2_gt?: InputMaybe<Scalars['String']['input']>
    value2_gte?: InputMaybe<Scalars['String']['input']>
    value2_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    value2_lt?: InputMaybe<Scalars['String']['input']>
    value2_lte?: InputMaybe<Scalars['String']['input']>
    value2_not?: InputMaybe<Scalars['String']['input']>
    value2_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    value3?: InputMaybe<Scalars['String']['input']>
    value3_gt?: InputMaybe<Scalars['String']['input']>
    value3_gte?: InputMaybe<Scalars['String']['input']>
    value3_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    value3_lt?: InputMaybe<Scalars['String']['input']>
    value3_lte?: InputMaybe<Scalars['String']['input']>
    value3_not?: InputMaybe<Scalars['String']['input']>
    value3_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    value4?: InputMaybe<Scalars['String']['input']>
    value4_gt?: InputMaybe<Scalars['String']['input']>
    value4_gte?: InputMaybe<Scalars['String']['input']>
    value4_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    value4_lt?: InputMaybe<Scalars['String']['input']>
    value4_lte?: InputMaybe<Scalars['String']['input']>
    value4_not?: InputMaybe<Scalars['String']['input']>
    value4_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type PropertyMeterReadingHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type PropertyMeterReadingHistoryRecordsCreateInput = {
    data?: InputMaybe<PropertyMeterReadingHistoryRecordCreateInput>
}

export type PropertyMeterReadingHistoryRecordsUpdateInput = {
    data?: InputMaybe<PropertyMeterReadingHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type PropertyMeterReadingUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    date?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    meter?: InputMaybe<PropertyMeterRelateToOneInput>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    source?: InputMaybe<MeterReadingSourceRelateToOneInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
    value1?: InputMaybe<Scalars['String']['input']>
    value2?: InputMaybe<Scalars['String']['input']>
    value3?: InputMaybe<Scalars['String']['input']>
    value4?: InputMaybe<Scalars['String']['input']>
}

export type PropertyMeterReadingWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<PropertyMeterReadingWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<PropertyMeterReadingWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    date?: InputMaybe<Scalars['String']['input']>
    date_gt?: InputMaybe<Scalars['String']['input']>
    date_gte?: InputMaybe<Scalars['String']['input']>
    date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    date_lt?: InputMaybe<Scalars['String']['input']>
    date_lte?: InputMaybe<Scalars['String']['input']>
    date_not?: InputMaybe<Scalars['String']['input']>
    date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    meter?: InputMaybe<PropertyMeterWhereInput>
    meter_is_null?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    source?: InputMaybe<MeterReadingSourceWhereInput>
    source_is_null?: InputMaybe<Scalars['Boolean']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    value1?: InputMaybe<Scalars['String']['input']>
    value1_gt?: InputMaybe<Scalars['String']['input']>
    value1_gte?: InputMaybe<Scalars['String']['input']>
    value1_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    value1_lt?: InputMaybe<Scalars['String']['input']>
    value1_lte?: InputMaybe<Scalars['String']['input']>
    value1_not?: InputMaybe<Scalars['String']['input']>
    value1_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    value2?: InputMaybe<Scalars['String']['input']>
    value2_gt?: InputMaybe<Scalars['String']['input']>
    value2_gte?: InputMaybe<Scalars['String']['input']>
    value2_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    value2_lt?: InputMaybe<Scalars['String']['input']>
    value2_lte?: InputMaybe<Scalars['String']['input']>
    value2_not?: InputMaybe<Scalars['String']['input']>
    value2_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    value3?: InputMaybe<Scalars['String']['input']>
    value3_gt?: InputMaybe<Scalars['String']['input']>
    value3_gte?: InputMaybe<Scalars['String']['input']>
    value3_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    value3_lt?: InputMaybe<Scalars['String']['input']>
    value3_lte?: InputMaybe<Scalars['String']['input']>
    value3_not?: InputMaybe<Scalars['String']['input']>
    value3_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    value4?: InputMaybe<Scalars['String']['input']>
    value4_gt?: InputMaybe<Scalars['String']['input']>
    value4_gte?: InputMaybe<Scalars['String']['input']>
    value4_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    value4_lt?: InputMaybe<Scalars['String']['input']>
    value4_lte?: InputMaybe<Scalars['String']['input']>
    value4_not?: InputMaybe<Scalars['String']['input']>
    value4_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type PropertyMeterReadingWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type PropertyMeterReadingsCreateInput = {
    data?: InputMaybe<PropertyMeterReadingCreateInput>
}

export type PropertyMeterReadingsUpdateInput = {
    data?: InputMaybe<PropertyMeterReadingUpdateInput>
    id: Scalars['ID']['input']
}

export type PropertyMeterRelateToOneInput = {
    connect?: InputMaybe<PropertyMeterWhereUniqueInput>
    create?: InputMaybe<PropertyMeterCreateInput>
    disconnect?: InputMaybe<PropertyMeterWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type PropertyMeterUpdateInput = {
    archiveDate?: InputMaybe<Scalars['String']['input']>
    b2bApp?: InputMaybe<B2BAppRelateToOneInput>
    commissioningDate?: InputMaybe<Scalars['String']['input']>
    controlReadingsDate?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    installationDate?: InputMaybe<Scalars['String']['input']>
    isAutomatic?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    nextVerificationDate?: InputMaybe<Scalars['String']['input']>
    number?: InputMaybe<Scalars['String']['input']>
    numberOfTariffs?: InputMaybe<Scalars['Int']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    property?: InputMaybe<PropertyRelateToOneInput>
    resource?: InputMaybe<MeterResourceRelateToOneInput>
    sealingDate?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
    verificationDate?: InputMaybe<Scalars['String']['input']>
}

export type PropertyMeterWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<PropertyMeterWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<PropertyMeterWhereInput>>>
    archiveDate?: InputMaybe<Scalars['String']['input']>
    archiveDate_gt?: InputMaybe<Scalars['String']['input']>
    archiveDate_gte?: InputMaybe<Scalars['String']['input']>
    archiveDate_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    archiveDate_lt?: InputMaybe<Scalars['String']['input']>
    archiveDate_lte?: InputMaybe<Scalars['String']['input']>
    archiveDate_not?: InputMaybe<Scalars['String']['input']>
    archiveDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    b2bApp?: InputMaybe<B2BAppWhereInput>
    b2bApp_is_null?: InputMaybe<Scalars['Boolean']['input']>
    commissioningDate?: InputMaybe<Scalars['String']['input']>
    commissioningDate_gt?: InputMaybe<Scalars['String']['input']>
    commissioningDate_gte?: InputMaybe<Scalars['String']['input']>
    commissioningDate_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    commissioningDate_lt?: InputMaybe<Scalars['String']['input']>
    commissioningDate_lte?: InputMaybe<Scalars['String']['input']>
    commissioningDate_not?: InputMaybe<Scalars['String']['input']>
    commissioningDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    controlReadingsDate?: InputMaybe<Scalars['String']['input']>
    controlReadingsDate_gt?: InputMaybe<Scalars['String']['input']>
    controlReadingsDate_gte?: InputMaybe<Scalars['String']['input']>
    controlReadingsDate_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    controlReadingsDate_lt?: InputMaybe<Scalars['String']['input']>
    controlReadingsDate_lte?: InputMaybe<Scalars['String']['input']>
    controlReadingsDate_not?: InputMaybe<Scalars['String']['input']>
    controlReadingsDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    installationDate?: InputMaybe<Scalars['String']['input']>
    installationDate_gt?: InputMaybe<Scalars['String']['input']>
    installationDate_gte?: InputMaybe<Scalars['String']['input']>
    installationDate_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    installationDate_lt?: InputMaybe<Scalars['String']['input']>
    installationDate_lte?: InputMaybe<Scalars['String']['input']>
    installationDate_not?: InputMaybe<Scalars['String']['input']>
    installationDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    isAutomatic?: InputMaybe<Scalars['Boolean']['input']>
    isAutomatic_not?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    nextVerificationDate?: InputMaybe<Scalars['String']['input']>
    nextVerificationDate_gt?: InputMaybe<Scalars['String']['input']>
    nextVerificationDate_gte?: InputMaybe<Scalars['String']['input']>
    nextVerificationDate_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    nextVerificationDate_lt?: InputMaybe<Scalars['String']['input']>
    nextVerificationDate_lte?: InputMaybe<Scalars['String']['input']>
    nextVerificationDate_not?: InputMaybe<Scalars['String']['input']>
    nextVerificationDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    number?: InputMaybe<Scalars['String']['input']>
    numberOfTariffs?: InputMaybe<Scalars['Int']['input']>
    numberOfTariffs_gt?: InputMaybe<Scalars['Int']['input']>
    numberOfTariffs_gte?: InputMaybe<Scalars['Int']['input']>
    numberOfTariffs_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    numberOfTariffs_lt?: InputMaybe<Scalars['Int']['input']>
    numberOfTariffs_lte?: InputMaybe<Scalars['Int']['input']>
    numberOfTariffs_not?: InputMaybe<Scalars['Int']['input']>
    numberOfTariffs_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    number_contains?: InputMaybe<Scalars['String']['input']>
    number_contains_i?: InputMaybe<Scalars['String']['input']>
    number_ends_with?: InputMaybe<Scalars['String']['input']>
    number_ends_with_i?: InputMaybe<Scalars['String']['input']>
    number_i?: InputMaybe<Scalars['String']['input']>
    number_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    number_not?: InputMaybe<Scalars['String']['input']>
    number_not_contains?: InputMaybe<Scalars['String']['input']>
    number_not_contains_i?: InputMaybe<Scalars['String']['input']>
    number_not_ends_with?: InputMaybe<Scalars['String']['input']>
    number_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    number_not_i?: InputMaybe<Scalars['String']['input']>
    number_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    number_not_starts_with?: InputMaybe<Scalars['String']['input']>
    number_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    number_starts_with?: InputMaybe<Scalars['String']['input']>
    number_starts_with_i?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    property?: InputMaybe<PropertyWhereInput>
    property_is_null?: InputMaybe<Scalars['Boolean']['input']>
    resource?: InputMaybe<MeterResourceWhereInput>
    resource_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sealingDate?: InputMaybe<Scalars['String']['input']>
    sealingDate_gt?: InputMaybe<Scalars['String']['input']>
    sealingDate_gte?: InputMaybe<Scalars['String']['input']>
    sealingDate_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sealingDate_lt?: InputMaybe<Scalars['String']['input']>
    sealingDate_lte?: InputMaybe<Scalars['String']['input']>
    sealingDate_not?: InputMaybe<Scalars['String']['input']>
    sealingDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    verificationDate?: InputMaybe<Scalars['String']['input']>
    verificationDate_gt?: InputMaybe<Scalars['String']['input']>
    verificationDate_gte?: InputMaybe<Scalars['String']['input']>
    verificationDate_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    verificationDate_lt?: InputMaybe<Scalars['String']['input']>
    verificationDate_lte?: InputMaybe<Scalars['String']['input']>
    verificationDate_not?: InputMaybe<Scalars['String']['input']>
    verificationDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type PropertyMeterWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type PropertyMetersCreateInput = {
    data?: InputMaybe<PropertyMeterCreateInput>
}

export type PropertyMetersUpdateInput = {
    data?: InputMaybe<PropertyMeterUpdateInput>
    id: Scalars['ID']['input']
}

export type PropertyOverviewResult = {
    __typename?: 'PropertyOverviewResult'
    sum: Scalars['String']['output']
}

export type PropertyRelateToOneInput = {
    connect?: InputMaybe<PropertyWhereUniqueInput>
    create?: InputMaybe<PropertyCreateInput>
    disconnect?: InputMaybe<PropertyWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

/**  A set of properties that limits the visibility of the organization's objects to the specified employees  */
export type PropertyScope = {
    __typename?: 'PropertyScope'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the PropertyScope List config, or
   *  2. As an alias to the field set on 'labelField' in the PropertyScope List config, or
   *  3. As an alias to a 'name' field on the PropertyScope List (if one exists), or
   *  4. As an alias to the 'id' field on the PropertyScope List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  True if PropertyScope includes all employees in organization  */
    hasAllEmployees?: Maybe<Scalars['Boolean']['output']>
    /**  True if PropertyScope includes all properties in organization  */
    hasAllProperties?: Maybe<Scalars['Boolean']['output']>
    id: Scalars['ID']['output']
    /**  The name of the zone that limits the visibility of employees by properties  */
    name?: Maybe<Scalars['String']['output']>
    nameNonLocalized?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
    organization?: Maybe<Organization>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type PropertyScopeCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    hasAllEmployees?: InputMaybe<Scalars['Boolean']['input']>
    hasAllProperties?: InputMaybe<Scalars['Boolean']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type PropertyScopeHistoryRecord = {
    __typename?: 'PropertyScopeHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the PropertyScopeHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the PropertyScopeHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the PropertyScopeHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the PropertyScopeHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    hasAllEmployees?: Maybe<Scalars['Boolean']['output']>
    hasAllProperties?: Maybe<Scalars['Boolean']['output']>
    history_action?: Maybe<PropertyScopeHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type PropertyScopeHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    hasAllEmployees?: InputMaybe<Scalars['Boolean']['input']>
    hasAllProperties?: InputMaybe<Scalars['Boolean']['input']>
    history_action?: InputMaybe<PropertyScopeHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum PropertyScopeHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type PropertyScopeHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    hasAllEmployees?: InputMaybe<Scalars['Boolean']['input']>
    hasAllProperties?: InputMaybe<Scalars['Boolean']['input']>
    history_action?: InputMaybe<PropertyScopeHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type PropertyScopeHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<PropertyScopeHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<PropertyScopeHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    hasAllEmployees?: InputMaybe<Scalars['Boolean']['input']>
    hasAllEmployees_not?: InputMaybe<Scalars['Boolean']['input']>
    hasAllProperties?: InputMaybe<Scalars['Boolean']['input']>
    hasAllProperties_not?: InputMaybe<Scalars['Boolean']['input']>
    history_action?: InputMaybe<PropertyScopeHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<PropertyScopeHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<PropertyScopeHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<PropertyScopeHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type PropertyScopeHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type PropertyScopeHistoryRecordsCreateInput = {
    data?: InputMaybe<PropertyScopeHistoryRecordCreateInput>
}

export type PropertyScopeHistoryRecordsUpdateInput = {
    data?: InputMaybe<PropertyScopeHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

/**  Many-to-many relationship between PropertyScope and OrganizationEmployee  */
export type PropertyScopeOrganizationEmployee = {
    __typename?: 'PropertyScopeOrganizationEmployee'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the PropertyScopeOrganizationEmployee List config, or
   *  2. As an alias to the field set on 'labelField' in the PropertyScopeOrganizationEmployee List config, or
   *  3. As an alias to a 'name' field on the PropertyScopeOrganizationEmployee List (if one exists), or
   *  4. As an alias to the 'id' field on the PropertyScopeOrganizationEmployee List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  An employee who is in the property scope  */
    employee?: Maybe<OrganizationEmployee>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['String']['output']>
    /**  Property scope which has an employee  */
    propertyScope?: Maybe<PropertyScope>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type PropertyScopeOrganizationEmployeeCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    employee?: InputMaybe<OrganizationEmployeeRelateToOneInput>
    newId?: InputMaybe<Scalars['String']['input']>
    propertyScope?: InputMaybe<PropertyScopeRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type PropertyScopeOrganizationEmployeeHistoryRecord = {
    __typename?: 'PropertyScopeOrganizationEmployeeHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the PropertyScopeOrganizationEmployeeHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the PropertyScopeOrganizationEmployeeHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the PropertyScopeOrganizationEmployeeHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the PropertyScopeOrganizationEmployeeHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    employee?: Maybe<Scalars['String']['output']>
    history_action?: Maybe<PropertyScopeOrganizationEmployeeHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['JSON']['output']>
    propertyScope?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type PropertyScopeOrganizationEmployeeHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    employee?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<PropertyScopeOrganizationEmployeeHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    propertyScope?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum PropertyScopeOrganizationEmployeeHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type PropertyScopeOrganizationEmployeeHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    employee?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<PropertyScopeOrganizationEmployeeHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    propertyScope?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type PropertyScopeOrganizationEmployeeHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<PropertyScopeOrganizationEmployeeHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<PropertyScopeOrganizationEmployeeHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    employee?: InputMaybe<Scalars['String']['input']>
    employee_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    employee_not?: InputMaybe<Scalars['String']['input']>
    employee_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_action?: InputMaybe<PropertyScopeOrganizationEmployeeHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<PropertyScopeOrganizationEmployeeHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<PropertyScopeOrganizationEmployeeHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<PropertyScopeOrganizationEmployeeHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    propertyScope?: InputMaybe<Scalars['String']['input']>
    propertyScope_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    propertyScope_not?: InputMaybe<Scalars['String']['input']>
    propertyScope_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type PropertyScopeOrganizationEmployeeHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type PropertyScopeOrganizationEmployeeHistoryRecordsCreateInput = {
    data?: InputMaybe<PropertyScopeOrganizationEmployeeHistoryRecordCreateInput>
}

export type PropertyScopeOrganizationEmployeeHistoryRecordsUpdateInput = {
    data?: InputMaybe<PropertyScopeOrganizationEmployeeHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type PropertyScopeOrganizationEmployeeUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    employee?: InputMaybe<OrganizationEmployeeRelateToOneInput>
    newId?: InputMaybe<Scalars['String']['input']>
    propertyScope?: InputMaybe<PropertyScopeRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type PropertyScopeOrganizationEmployeeWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<PropertyScopeOrganizationEmployeeWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<PropertyScopeOrganizationEmployeeWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    employee?: InputMaybe<OrganizationEmployeeWhereInput>
    employee_is_null?: InputMaybe<Scalars['Boolean']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    propertyScope?: InputMaybe<PropertyScopeWhereInput>
    propertyScope_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type PropertyScopeOrganizationEmployeeWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type PropertyScopeOrganizationEmployeesCreateInput = {
    data?: InputMaybe<PropertyScopeOrganizationEmployeeCreateInput>
}

export type PropertyScopeOrganizationEmployeesUpdateInput = {
    data?: InputMaybe<PropertyScopeOrganizationEmployeeUpdateInput>
    id: Scalars['ID']['input']
}

export type PropertyScopePropertiesCreateInput = {
    data?: InputMaybe<PropertyScopePropertyCreateInput>
}

export type PropertyScopePropertiesUpdateInput = {
    data?: InputMaybe<PropertyScopePropertyUpdateInput>
    id: Scalars['ID']['input']
}

/**  Many-to-many relationship between PropertyScope and Property  */
export type PropertyScopeProperty = {
    __typename?: 'PropertyScopeProperty'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the PropertyScopeProperty List config, or
   *  2. As an alias to the field set on 'labelField' in the PropertyScopeProperty List config, or
   *  3. As an alias to a 'name' field on the PropertyScopeProperty List (if one exists), or
   *  4. As an alias to the 'id' field on the PropertyScopeProperty List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['String']['output']>
    /**  A property which is in the property scope  */
    property?: Maybe<Property>
    /**  Property scope which has a property  */
    propertyScope?: Maybe<PropertyScope>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type PropertyScopePropertyCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    property?: InputMaybe<PropertyRelateToOneInput>
    propertyScope?: InputMaybe<PropertyScopeRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type PropertyScopePropertyHistoryRecord = {
    __typename?: 'PropertyScopePropertyHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the PropertyScopePropertyHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the PropertyScopePropertyHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the PropertyScopePropertyHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the PropertyScopePropertyHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<PropertyScopePropertyHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['JSON']['output']>
    property?: Maybe<Scalars['String']['output']>
    propertyScope?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type PropertyScopePropertyHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<PropertyScopePropertyHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    property?: InputMaybe<Scalars['String']['input']>
    propertyScope?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum PropertyScopePropertyHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type PropertyScopePropertyHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<PropertyScopePropertyHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    property?: InputMaybe<Scalars['String']['input']>
    propertyScope?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type PropertyScopePropertyHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<PropertyScopePropertyHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<PropertyScopePropertyHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<PropertyScopePropertyHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<PropertyScopePropertyHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<PropertyScopePropertyHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<PropertyScopePropertyHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    property?: InputMaybe<Scalars['String']['input']>
    propertyScope?: InputMaybe<Scalars['String']['input']>
    propertyScope_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    propertyScope_not?: InputMaybe<Scalars['String']['input']>
    propertyScope_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    property_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    property_not?: InputMaybe<Scalars['String']['input']>
    property_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type PropertyScopePropertyHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type PropertyScopePropertyHistoryRecordsCreateInput = {
    data?: InputMaybe<PropertyScopePropertyHistoryRecordCreateInput>
}

export type PropertyScopePropertyHistoryRecordsUpdateInput = {
    data?: InputMaybe<PropertyScopePropertyHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type PropertyScopePropertyUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    property?: InputMaybe<PropertyRelateToOneInput>
    propertyScope?: InputMaybe<PropertyScopeRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type PropertyScopePropertyWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<PropertyScopePropertyWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<PropertyScopePropertyWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    property?: InputMaybe<PropertyWhereInput>
    propertyScope?: InputMaybe<PropertyScopeWhereInput>
    propertyScope_is_null?: InputMaybe<Scalars['Boolean']['input']>
    property_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type PropertyScopePropertyWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type PropertyScopeRelateToOneInput = {
    connect?: InputMaybe<PropertyScopeWhereUniqueInput>
    create?: InputMaybe<PropertyScopeCreateInput>
    disconnect?: InputMaybe<PropertyScopeWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type PropertyScopeUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    hasAllEmployees?: InputMaybe<Scalars['Boolean']['input']>
    hasAllProperties?: InputMaybe<Scalars['Boolean']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type PropertyScopeWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<PropertyScopeWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<PropertyScopeWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    hasAllEmployees?: InputMaybe<Scalars['Boolean']['input']>
    hasAllEmployees_not?: InputMaybe<Scalars['Boolean']['input']>
    hasAllProperties?: InputMaybe<Scalars['Boolean']['input']>
    hasAllProperties_not?: InputMaybe<Scalars['Boolean']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type PropertyScopeWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type PropertyScopesCreateInput = {
    data?: InputMaybe<PropertyScopeCreateInput>
}

export type PropertyScopesInput = {
    accountNumbers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    billingProperty: BillingPropertyWhereUniqueInput
    skipAccountNumbers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    skipUnits?: InputMaybe<Array<InputMaybe<PropertyUnitInput>>>
    units?: InputMaybe<Array<InputMaybe<PropertyUnitInput>>>
}

export type PropertyScopesUpdateInput = {
    data?: InputMaybe<PropertyScopeUpdateInput>
    id: Scalars['ID']['input']
}

export enum PropertyTypeType {
    Building = 'building',
    Village = 'village',
}

export type PropertyUnitInput = {
    unitName: Scalars['String']['input']
    unitType: Scalars['String']['input']
}

export type PropertyUpdateInput = {
    address?: InputMaybe<Scalars['String']['input']>
    addressKey?: InputMaybe<Scalars['String']['input']>
    addressMeta?: InputMaybe<Scalars['JSON']['input']>
    addressSources?: InputMaybe<Scalars['JSON']['input']>
    area?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    isApproved?: InputMaybe<Scalars['Boolean']['input']>
    map?: InputMaybe<Scalars['JSON']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    type?: InputMaybe<PropertyTypeType>
    uninhabitedUnitsCount?: InputMaybe<Scalars['Int']['input']>
    unitsCount?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
    yearOfConstruction?: InputMaybe<Scalars['String']['input']>
}

export type PropertyWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<PropertyWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<PropertyWhereInput>>>
    address?: InputMaybe<Scalars['String']['input']>
    addressKey?: InputMaybe<Scalars['String']['input']>
    addressKey_contains?: InputMaybe<Scalars['String']['input']>
    addressKey_contains_i?: InputMaybe<Scalars['String']['input']>
    addressKey_ends_with?: InputMaybe<Scalars['String']['input']>
    addressKey_ends_with_i?: InputMaybe<Scalars['String']['input']>
    addressKey_i?: InputMaybe<Scalars['String']['input']>
    addressKey_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    addressKey_not?: InputMaybe<Scalars['String']['input']>
    addressKey_not_contains?: InputMaybe<Scalars['String']['input']>
    addressKey_not_contains_i?: InputMaybe<Scalars['String']['input']>
    addressKey_not_ends_with?: InputMaybe<Scalars['String']['input']>
    addressKey_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    addressKey_not_i?: InputMaybe<Scalars['String']['input']>
    addressKey_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    addressKey_not_starts_with?: InputMaybe<Scalars['String']['input']>
    addressKey_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    addressKey_starts_with?: InputMaybe<Scalars['String']['input']>
    addressKey_starts_with_i?: InputMaybe<Scalars['String']['input']>
    addressMeta?: InputMaybe<Scalars['JSON']['input']>
    addressMeta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    addressMeta_not?: InputMaybe<Scalars['JSON']['input']>
    addressMeta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    addressSources?: InputMaybe<Scalars['JSON']['input']>
    addressSources_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    addressSources_not?: InputMaybe<Scalars['JSON']['input']>
    addressSources_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    address_contains?: InputMaybe<Scalars['String']['input']>
    address_contains_i?: InputMaybe<Scalars['String']['input']>
    address_ends_with?: InputMaybe<Scalars['String']['input']>
    address_ends_with_i?: InputMaybe<Scalars['String']['input']>
    address_i?: InputMaybe<Scalars['String']['input']>
    address_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    address_not?: InputMaybe<Scalars['String']['input']>
    address_not_contains?: InputMaybe<Scalars['String']['input']>
    address_not_contains_i?: InputMaybe<Scalars['String']['input']>
    address_not_ends_with?: InputMaybe<Scalars['String']['input']>
    address_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    address_not_i?: InputMaybe<Scalars['String']['input']>
    address_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    address_not_starts_with?: InputMaybe<Scalars['String']['input']>
    address_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    address_starts_with?: InputMaybe<Scalars['String']['input']>
    address_starts_with_i?: InputMaybe<Scalars['String']['input']>
    area?: InputMaybe<Scalars['String']['input']>
    area_gt?: InputMaybe<Scalars['String']['input']>
    area_gte?: InputMaybe<Scalars['String']['input']>
    area_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    area_lt?: InputMaybe<Scalars['String']['input']>
    area_lte?: InputMaybe<Scalars['String']['input']>
    area_not?: InputMaybe<Scalars['String']['input']>
    area_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    isApproved?: InputMaybe<Scalars['Boolean']['input']>
    isApproved_not?: InputMaybe<Scalars['Boolean']['input']>
    map?: InputMaybe<Scalars['JSON']['input']>
    map_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    map_not?: InputMaybe<Scalars['JSON']['input']>
    map_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    type?: InputMaybe<PropertyTypeType>
    type_in?: InputMaybe<Array<InputMaybe<PropertyTypeType>>>
    type_not?: InputMaybe<PropertyTypeType>
    type_not_in?: InputMaybe<Array<InputMaybe<PropertyTypeType>>>
    uninhabitedUnitsCount?: InputMaybe<Scalars['Int']['input']>
    uninhabitedUnitsCount_gt?: InputMaybe<Scalars['Int']['input']>
    uninhabitedUnitsCount_gte?: InputMaybe<Scalars['Int']['input']>
    uninhabitedUnitsCount_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    uninhabitedUnitsCount_lt?: InputMaybe<Scalars['Int']['input']>
    uninhabitedUnitsCount_lte?: InputMaybe<Scalars['Int']['input']>
    uninhabitedUnitsCount_not?: InputMaybe<Scalars['Int']['input']>
    uninhabitedUnitsCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    unitsCount?: InputMaybe<Scalars['Int']['input']>
    unitsCount_gt?: InputMaybe<Scalars['Int']['input']>
    unitsCount_gte?: InputMaybe<Scalars['Int']['input']>
    unitsCount_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    unitsCount_lt?: InputMaybe<Scalars['Int']['input']>
    unitsCount_lte?: InputMaybe<Scalars['Int']['input']>
    unitsCount_not?: InputMaybe<Scalars['Int']['input']>
    unitsCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    yearOfConstruction?: InputMaybe<Scalars['String']['input']>
    yearOfConstruction_gt?: InputMaybe<Scalars['String']['input']>
    yearOfConstruction_gte?: InputMaybe<Scalars['String']['input']>
    yearOfConstruction_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    yearOfConstruction_lt?: InputMaybe<Scalars['String']['input']>
    yearOfConstruction_lte?: InputMaybe<Scalars['String']['input']>
    yearOfConstruction_not?: InputMaybe<Scalars['String']['input']>
    yearOfConstruction_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type PropertyWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export enum PushTransportType {
    Apple = 'apple',
    Firebase = 'firebase',
    Huawei = 'huawei',
    Redstore = 'redstore',
}

export enum PushType {
    Default = 'default',
    SilentData = 'silent_data',
}

export enum QualityControlAdditionalOptionsType {
    HighQuality = 'highQuality',
    LowQuality = 'lowQuality',
    Quickly = 'quickly',
    Slowly = 'slowly',
}

export type Query = {
    __typename?: 'Query'
    /**  Search for the AcquiringIntegration item with the matching ID.  */
    AcquiringIntegration?: Maybe<AcquiringIntegration>
    /**  Search for the AcquiringIntegrationAccessRight item with the matching ID.  */
    AcquiringIntegrationAccessRight?: Maybe<AcquiringIntegrationAccessRight>
    /**  Search for the AcquiringIntegrationAccessRightHistoryRecord item with the matching ID.  */
    AcquiringIntegrationAccessRightHistoryRecord?: Maybe<AcquiringIntegrationAccessRightHistoryRecord>
    /**  Search for the AcquiringIntegrationContext item with the matching ID.  */
    AcquiringIntegrationContext?: Maybe<AcquiringIntegrationContext>
    /**  Search for the AcquiringIntegrationContextHistoryRecord item with the matching ID.  */
    AcquiringIntegrationContextHistoryRecord?: Maybe<AcquiringIntegrationContextHistoryRecord>
    /**  Search for the AcquiringIntegrationHistoryRecord item with the matching ID.  */
    AcquiringIntegrationHistoryRecord?: Maybe<AcquiringIntegrationHistoryRecord>
    /**  Search for the B2BApp item with the matching ID.  */
    B2BApp?: Maybe<B2BApp>
    /**  Search for the B2BAppAccessRight item with the matching ID.  */
    B2BAppAccessRight?: Maybe<B2BAppAccessRight>
    /**  Search for the B2BAppAccessRightHistoryRecord item with the matching ID.  */
    B2BAppAccessRightHistoryRecord?: Maybe<B2BAppAccessRightHistoryRecord>
    /**  Search for the B2BAppAccessRightSet item with the matching ID.  */
    B2BAppAccessRightSet?: Maybe<B2BAppAccessRightSet>
    /**  Search for the B2BAppAccessRightSetHistoryRecord item with the matching ID.  */
    B2BAppAccessRightSetHistoryRecord?: Maybe<B2BAppAccessRightSetHistoryRecord>
    /**  Search for the B2BAppContext item with the matching ID.  */
    B2BAppContext?: Maybe<B2BAppContext>
    /**  Search for the B2BAppContextHistoryRecord item with the matching ID.  */
    B2BAppContextHistoryRecord?: Maybe<B2BAppContextHistoryRecord>
    /**  Search for the B2BAppHistoryRecord item with the matching ID.  */
    B2BAppHistoryRecord?: Maybe<B2BAppHistoryRecord>
    /**  Search for the B2BAppNewsSharingConfig item with the matching ID.  */
    B2BAppNewsSharingConfig?: Maybe<B2BAppNewsSharingConfig>
    /**  Search for the B2BAppNewsSharingConfigHistoryRecord item with the matching ID.  */
    B2BAppNewsSharingConfigHistoryRecord?: Maybe<B2BAppNewsSharingConfigHistoryRecord>
    /**  Search for the B2BAppPermission item with the matching ID.  */
    B2BAppPermission?: Maybe<B2BAppPermission>
    /**  Search for the B2BAppPermissionHistoryRecord item with the matching ID.  */
    B2BAppPermissionHistoryRecord?: Maybe<B2BAppPermissionHistoryRecord>
    /**  Search for the B2BAppPromoBlock item with the matching ID.  */
    B2BAppPromoBlock?: Maybe<B2BAppPromoBlock>
    /**  Search for the B2BAppPromoBlockHistoryRecord item with the matching ID.  */
    B2BAppPromoBlockHistoryRecord?: Maybe<B2BAppPromoBlockHistoryRecord>
    /**  Search for the B2BAppRole item with the matching ID.  */
    B2BAppRole?: Maybe<B2BAppRole>
    /**  Search for the B2BAppRoleHistoryRecord item with the matching ID.  */
    B2BAppRoleHistoryRecord?: Maybe<B2BAppRoleHistoryRecord>
    /**  Search for the B2CApp item with the matching ID.  */
    B2CApp?: Maybe<B2CApp>
    /**  Search for the B2CAppAccessRight item with the matching ID.  */
    B2CAppAccessRight?: Maybe<B2CAppAccessRight>
    /**  Search for the B2CAppAccessRightHistoryRecord item with the matching ID.  */
    B2CAppAccessRightHistoryRecord?: Maybe<B2CAppAccessRightHistoryRecord>
    /**  Search for the B2CAppBuild item with the matching ID.  */
    B2CAppBuild?: Maybe<B2CAppBuild>
    /**  Search for the B2CAppBuildHistoryRecord item with the matching ID.  */
    B2CAppBuildHistoryRecord?: Maybe<B2CAppBuildHistoryRecord>
    /**  Search for the B2CAppHistoryRecord item with the matching ID.  */
    B2CAppHistoryRecord?: Maybe<B2CAppHistoryRecord>
    /**  Search for the B2CAppMessageSetting item with the matching ID.  */
    B2CAppMessageSetting?: Maybe<B2CAppMessageSetting>
    /**  Search for the B2CAppMessageSettingHistoryRecord item with the matching ID.  */
    B2CAppMessageSettingHistoryRecord?: Maybe<B2CAppMessageSettingHistoryRecord>
    /**  Search for the B2CAppProperty item with the matching ID.  */
    B2CAppProperty?: Maybe<B2CAppProperty>
    /**  Search for the B2CAppPropertyHistoryRecord item with the matching ID.  */
    B2CAppPropertyHistoryRecord?: Maybe<B2CAppPropertyHistoryRecord>
    /**  Search for the BankAccount item with the matching ID.  */
    BankAccount?: Maybe<BankAccount>
    /**  Search for the BankAccountHistoryRecord item with the matching ID.  */
    BankAccountHistoryRecord?: Maybe<BankAccountHistoryRecord>
    /**  Search for the BankAccountReport item with the matching ID.  */
    BankAccountReport?: Maybe<BankAccountReport>
    /**  Search for the BankAccountReportHistoryRecord item with the matching ID.  */
    BankAccountReportHistoryRecord?: Maybe<BankAccountReportHistoryRecord>
    /**  Search for the BankAccountReportTask item with the matching ID.  */
    BankAccountReportTask?: Maybe<BankAccountReportTask>
    /**  Search for the BankAccountReportTaskHistoryRecord item with the matching ID.  */
    BankAccountReportTaskHistoryRecord?: Maybe<BankAccountReportTaskHistoryRecord>
    /**  Search for the BankCategory item with the matching ID.  */
    BankCategory?: Maybe<BankCategory>
    /**  Search for the BankCategoryHistoryRecord item with the matching ID.  */
    BankCategoryHistoryRecord?: Maybe<BankCategoryHistoryRecord>
    /**  Search for the BankContractorAccount item with the matching ID.  */
    BankContractorAccount?: Maybe<BankContractorAccount>
    /**  Search for the BankContractorAccountHistoryRecord item with the matching ID.  */
    BankContractorAccountHistoryRecord?: Maybe<BankContractorAccountHistoryRecord>
    /**  Search for the BankCostItem item with the matching ID.  */
    BankCostItem?: Maybe<BankCostItem>
    /**  Search for the BankCostItemHistoryRecord item with the matching ID.  */
    BankCostItemHistoryRecord?: Maybe<BankCostItemHistoryRecord>
    /**  Search for the BankIntegration item with the matching ID.  */
    BankIntegration?: Maybe<BankIntegration>
    /**  Search for the BankIntegrationAccessRight item with the matching ID.  */
    BankIntegrationAccessRight?: Maybe<BankIntegrationAccessRight>
    /**  Search for the BankIntegrationAccessRightHistoryRecord item with the matching ID.  */
    BankIntegrationAccessRightHistoryRecord?: Maybe<BankIntegrationAccessRightHistoryRecord>
    /**  Search for the BankIntegrationAccountContext item with the matching ID.  */
    BankIntegrationAccountContext?: Maybe<BankIntegrationAccountContext>
    /**  Search for the BankIntegrationAccountContextHistoryRecord item with the matching ID.  */
    BankIntegrationAccountContextHistoryRecord?: Maybe<BankIntegrationAccountContextHistoryRecord>
    /**  Search for the BankIntegrationHistoryRecord item with the matching ID.  */
    BankIntegrationHistoryRecord?: Maybe<BankIntegrationHistoryRecord>
    /**  Search for the BankIntegrationOrganizationContext item with the matching ID.  */
    BankIntegrationOrganizationContext?: Maybe<BankIntegrationOrganizationContext>
    /**  Search for the BankIntegrationOrganizationContextHistoryRecord item with the matching ID.  */
    BankIntegrationOrganizationContextHistoryRecord?: Maybe<BankIntegrationOrganizationContextHistoryRecord>
    /**  Search for the BankSyncTask item with the matching ID.  */
    BankSyncTask?: Maybe<BankSyncTask>
    /**  Search for the BankSyncTaskHistoryRecord item with the matching ID.  */
    BankSyncTaskHistoryRecord?: Maybe<BankSyncTaskHistoryRecord>
    /**  Search for the BankTransaction item with the matching ID.  */
    BankTransaction?: Maybe<BankTransaction>
    /**  Search for the BankTransactionHistoryRecord item with the matching ID.  */
    BankTransactionHistoryRecord?: Maybe<BankTransactionHistoryRecord>
    /**  Search for the BillingAccount item with the matching ID.  */
    BillingAccount?: Maybe<BillingAccount>
    /**  Search for the BillingAccountHistoryRecord item with the matching ID.  */
    BillingAccountHistoryRecord?: Maybe<BillingAccountHistoryRecord>
    /**  Search for the BillingCategory item with the matching ID.  */
    BillingCategory?: Maybe<BillingCategory>
    /**  Search for the BillingCategoryHistoryRecord item with the matching ID.  */
    BillingCategoryHistoryRecord?: Maybe<BillingCategoryHistoryRecord>
    /**  Search for the BillingIntegration item with the matching ID.  */
    BillingIntegration?: Maybe<BillingIntegration>
    /**  Search for the BillingIntegrationAccessRight item with the matching ID.  */
    BillingIntegrationAccessRight?: Maybe<BillingIntegrationAccessRight>
    /**  Search for the BillingIntegrationAccessRightHistoryRecord item with the matching ID.  */
    BillingIntegrationAccessRightHistoryRecord?: Maybe<BillingIntegrationAccessRightHistoryRecord>
    /**  Search for the BillingIntegrationHistoryRecord item with the matching ID.  */
    BillingIntegrationHistoryRecord?: Maybe<BillingIntegrationHistoryRecord>
    /**  Search for the BillingIntegrationOrganizationContext item with the matching ID.  */
    BillingIntegrationOrganizationContext?: Maybe<BillingIntegrationOrganizationContext>
    /**  Search for the BillingIntegrationOrganizationContextHistoryRecord item with the matching ID.  */
    BillingIntegrationOrganizationContextHistoryRecord?: Maybe<BillingIntegrationOrganizationContextHistoryRecord>
    /**  Search for the BillingIntegrationProblem item with the matching ID.  */
    BillingIntegrationProblem?: Maybe<BillingIntegrationProblem>
    /**  Search for the BillingProperty item with the matching ID.  */
    BillingProperty?: Maybe<BillingProperty>
    /**  Search for the BillingPropertyHistoryRecord item with the matching ID.  */
    BillingPropertyHistoryRecord?: Maybe<BillingPropertyHistoryRecord>
    /**  Search for the BillingReceipt item with the matching ID.  */
    BillingReceipt?: Maybe<BillingReceipt>
    /**  Search for the BillingReceiptFile item with the matching ID.  */
    BillingReceiptFile?: Maybe<BillingReceiptFile>
    /**  Search for the BillingReceiptFileHistoryRecord item with the matching ID.  */
    BillingReceiptFileHistoryRecord?: Maybe<BillingReceiptFileHistoryRecord>
    /**  Search for the BillingReceiptHistoryRecord item with the matching ID.  */
    BillingReceiptHistoryRecord?: Maybe<BillingReceiptHistoryRecord>
    /**  Search for the BillingRecipient item with the matching ID.  */
    BillingRecipient?: Maybe<BillingRecipient>
    /**  Search for the BillingRecipientHistoryRecord item with the matching ID.  */
    BillingRecipientHistoryRecord?: Maybe<BillingRecipientHistoryRecord>
    /**  Search for the CallRecord item with the matching ID.  */
    CallRecord?: Maybe<CallRecord>
    /**  Search for the CallRecordFragment item with the matching ID.  */
    CallRecordFragment?: Maybe<CallRecordFragment>
    /**  Search for the CallRecordFragmentHistoryRecord item with the matching ID.  */
    CallRecordFragmentHistoryRecord?: Maybe<CallRecordFragmentHistoryRecord>
    /**  Search for the CallRecordHistoryRecord item with the matching ID.  */
    CallRecordHistoryRecord?: Maybe<CallRecordHistoryRecord>
    /**  Search for the ConfirmPhoneAction item with the matching ID.  */
    ConfirmPhoneAction?: Maybe<ConfirmPhoneAction>
    /**  Search for the ConfirmPhoneActionHistoryRecord item with the matching ID.  */
    ConfirmPhoneActionHistoryRecord?: Maybe<ConfirmPhoneActionHistoryRecord>
    /**  Search for the Contact item with the matching ID.  */
    Contact?: Maybe<Contact>
    /**  Search for the ContactExportTask item with the matching ID.  */
    ContactExportTask?: Maybe<ContactExportTask>
    /**  Search for the ContactExportTaskHistoryRecord item with the matching ID.  */
    ContactExportTaskHistoryRecord?: Maybe<ContactExportTaskHistoryRecord>
    /**  Search for the ContactHistoryRecord item with the matching ID.  */
    ContactHistoryRecord?: Maybe<ContactHistoryRecord>
    /**  Search for the ContactRole item with the matching ID.  */
    ContactRole?: Maybe<ContactRole>
    /**  Search for the ContactRoleHistoryRecord item with the matching ID.  */
    ContactRoleHistoryRecord?: Maybe<ContactRoleHistoryRecord>
    /**  Search for the Document item with the matching ID.  */
    Document?: Maybe<Document>
    /**  Search for the DocumentCategory item with the matching ID.  */
    DocumentCategory?: Maybe<DocumentCategory>
    /**  Search for the DocumentCategoryHistoryRecord item with the matching ID.  */
    DocumentCategoryHistoryRecord?: Maybe<DocumentCategoryHistoryRecord>
    /**  Search for the DocumentHistoryRecord item with the matching ID.  */
    DocumentHistoryRecord?: Maybe<DocumentHistoryRecord>
    /**  Search for the ExternalTokenAccessRight item with the matching ID.  */
    ExternalTokenAccessRight?: Maybe<ExternalTokenAccessRight>
    /**  Search for the ExternalTokenAccessRightHistoryRecord item with the matching ID.  */
    ExternalTokenAccessRightHistoryRecord?: Maybe<ExternalTokenAccessRightHistoryRecord>
    /**  Search for the Incident item with the matching ID.  */
    Incident?: Maybe<Incident>
    /**  Search for the IncidentChange item with the matching ID.  */
    IncidentChange?: Maybe<IncidentChange>
    /**  Search for the IncidentClassifier item with the matching ID.  */
    IncidentClassifier?: Maybe<IncidentClassifier>
    /**  Search for the IncidentClassifierHistoryRecord item with the matching ID.  */
    IncidentClassifierHistoryRecord?: Maybe<IncidentClassifierHistoryRecord>
    /**  Search for the IncidentClassifierIncident item with the matching ID.  */
    IncidentClassifierIncident?: Maybe<IncidentClassifierIncident>
    /**  Search for the IncidentClassifierIncidentHistoryRecord item with the matching ID.  */
    IncidentClassifierIncidentHistoryRecord?: Maybe<IncidentClassifierIncidentHistoryRecord>
    /**  Search for the IncidentExportTask item with the matching ID.  */
    IncidentExportTask?: Maybe<IncidentExportTask>
    /**  Search for the IncidentExportTaskHistoryRecord item with the matching ID.  */
    IncidentExportTaskHistoryRecord?: Maybe<IncidentExportTaskHistoryRecord>
    /**  Search for the IncidentHistoryRecord item with the matching ID.  */
    IncidentHistoryRecord?: Maybe<IncidentHistoryRecord>
    /**  Search for the IncidentProperty item with the matching ID.  */
    IncidentProperty?: Maybe<IncidentProperty>
    /**  Search for the IncidentPropertyHistoryRecord item with the matching ID.  */
    IncidentPropertyHistoryRecord?: Maybe<IncidentPropertyHistoryRecord>
    /**  Search for the Invoice item with the matching ID.  */
    Invoice?: Maybe<Invoice>
    /**  Search for the InvoiceHistoryRecord item with the matching ID.  */
    InvoiceHistoryRecord?: Maybe<InvoiceHistoryRecord>
    /**  Search for the MarketCategory item with the matching ID.  */
    MarketCategory?: Maybe<MarketCategory>
    /**  Search for the MarketCategoryHistoryRecord item with the matching ID.  */
    MarketCategoryHistoryRecord?: Maybe<MarketCategoryHistoryRecord>
    /**  Search for the MarketItem item with the matching ID.  */
    MarketItem?: Maybe<MarketItem>
    /**  Search for the MarketItemFile item with the matching ID.  */
    MarketItemFile?: Maybe<MarketItemFile>
    /**  Search for the MarketItemFileHistoryRecord item with the matching ID.  */
    MarketItemFileHistoryRecord?: Maybe<MarketItemFileHistoryRecord>
    /**  Search for the MarketItemHistoryRecord item with the matching ID.  */
    MarketItemHistoryRecord?: Maybe<MarketItemHistoryRecord>
    /**  Search for the MarketItemPrice item with the matching ID.  */
    MarketItemPrice?: Maybe<MarketItemPrice>
    /**  Search for the MarketItemPriceHistoryRecord item with the matching ID.  */
    MarketItemPriceHistoryRecord?: Maybe<MarketItemPriceHistoryRecord>
    /**  Search for the MarketPriceScope item with the matching ID.  */
    MarketPriceScope?: Maybe<MarketPriceScope>
    /**  Search for the MarketPriceScopeHistoryRecord item with the matching ID.  */
    MarketPriceScopeHistoryRecord?: Maybe<MarketPriceScopeHistoryRecord>
    /**  Search for the MarketSetting item with the matching ID.  */
    MarketSetting?: Maybe<MarketSetting>
    /**  Search for the MarketSettingHistoryRecord item with the matching ID.  */
    MarketSettingHistoryRecord?: Maybe<MarketSettingHistoryRecord>
    /**  Search for the Message item with the matching ID.  */
    Message?: Maybe<Message>
    /**  Search for the MessageAppBlackList item with the matching ID.  */
    MessageAppBlackList?: Maybe<MessageAppBlackList>
    /**  Search for the MessageAppBlackListHistoryRecord item with the matching ID.  */
    MessageAppBlackListHistoryRecord?: Maybe<MessageAppBlackListHistoryRecord>
    /**  Search for the MessageBatch item with the matching ID.  */
    MessageBatch?: Maybe<MessageBatch>
    /**  Search for the MessageBatchHistoryRecord item with the matching ID.  */
    MessageBatchHistoryRecord?: Maybe<MessageBatchHistoryRecord>
    /**  Search for the MessageHistoryRecord item with the matching ID.  */
    MessageHistoryRecord?: Maybe<MessageHistoryRecord>
    /**  Search for the MessageOrganizationBlackList item with the matching ID.  */
    MessageOrganizationBlackList?: Maybe<MessageOrganizationBlackList>
    /**  Search for the MessageOrganizationBlackListHistoryRecord item with the matching ID.  */
    MessageOrganizationBlackListHistoryRecord?: Maybe<MessageOrganizationBlackListHistoryRecord>
    /**  Search for the MessageUserBlackList item with the matching ID.  */
    MessageUserBlackList?: Maybe<MessageUserBlackList>
    /**  Search for the MessageUserBlackListHistoryRecord item with the matching ID.  */
    MessageUserBlackListHistoryRecord?: Maybe<MessageUserBlackListHistoryRecord>
    /**  Search for the Meter item with the matching ID.  */
    Meter?: Maybe<Meter>
    /**  Search for the MeterHistoryRecord item with the matching ID.  */
    MeterHistoryRecord?: Maybe<MeterHistoryRecord>
    /**  Search for the MeterReading item with the matching ID.  */
    MeterReading?: Maybe<MeterReading>
    /**  Search for the MeterReadingExportTask item with the matching ID.  */
    MeterReadingExportTask?: Maybe<MeterReadingExportTask>
    /**  Search for the MeterReadingExportTaskHistoryRecord item with the matching ID.  */
    MeterReadingExportTaskHistoryRecord?: Maybe<MeterReadingExportTaskHistoryRecord>
    /**  Search for the MeterReadingFilterTemplate item with the matching ID.  */
    MeterReadingFilterTemplate?: Maybe<MeterReadingFilterTemplate>
    /**  Search for the MeterReadingFilterTemplateHistoryRecord item with the matching ID.  */
    MeterReadingFilterTemplateHistoryRecord?: Maybe<MeterReadingFilterTemplateHistoryRecord>
    /**  Search for the MeterReadingHistoryRecord item with the matching ID.  */
    MeterReadingHistoryRecord?: Maybe<MeterReadingHistoryRecord>
    /**  Search for the MeterReadingSource item with the matching ID.  */
    MeterReadingSource?: Maybe<MeterReadingSource>
    /**  Search for the MeterReadingSourceHistoryRecord item with the matching ID.  */
    MeterReadingSourceHistoryRecord?: Maybe<MeterReadingSourceHistoryRecord>
    /**  Search for the MeterReadingsImportTask item with the matching ID.  */
    MeterReadingsImportTask?: Maybe<MeterReadingsImportTask>
    /**  Search for the MeterReadingsImportTaskHistoryRecord item with the matching ID.  */
    MeterReadingsImportTaskHistoryRecord?: Maybe<MeterReadingsImportTaskHistoryRecord>
    /**  Search for the MeterReportingPeriod item with the matching ID.  */
    MeterReportingPeriod?: Maybe<MeterReportingPeriod>
    /**  Search for the MeterReportingPeriodHistoryRecord item with the matching ID.  */
    MeterReportingPeriodHistoryRecord?: Maybe<MeterReportingPeriodHistoryRecord>
    /**  Search for the MeterResource item with the matching ID.  */
    MeterResource?: Maybe<MeterResource>
    /**  Search for the MeterResourceHistoryRecord item with the matching ID.  */
    MeterResourceHistoryRecord?: Maybe<MeterResourceHistoryRecord>
    /**  Search for the MeterResourceOwner item with the matching ID.  */
    MeterResourceOwner?: Maybe<MeterResourceOwner>
    /**  Search for the MeterResourceOwnerHistoryRecord item with the matching ID.  */
    MeterResourceOwnerHistoryRecord?: Maybe<MeterResourceOwnerHistoryRecord>
    /**  Search for the MobileFeatureConfig item with the matching ID.  */
    MobileFeatureConfig?: Maybe<MobileFeatureConfig>
    /**  Search for the MobileFeatureConfigHistoryRecord item with the matching ID.  */
    MobileFeatureConfigHistoryRecord?: Maybe<MobileFeatureConfigHistoryRecord>
    /**  Search for the MultiPayment item with the matching ID.  */
    MultiPayment?: Maybe<MultiPayment>
    /**  Search for the MultiPaymentHistoryRecord item with the matching ID.  */
    MultiPaymentHistoryRecord?: Maybe<MultiPaymentHistoryRecord>
    /**  Search for the NewsItem item with the matching ID.  */
    NewsItem?: Maybe<NewsItem>
    /**  Search for the NewsItemHistoryRecord item with the matching ID.  */
    NewsItemHistoryRecord?: Maybe<NewsItemHistoryRecord>
    /**  Search for the NewsItemRecipientsExportTask item with the matching ID.  */
    NewsItemRecipientsExportTask?: Maybe<NewsItemRecipientsExportTask>
    /**  Search for the NewsItemRecipientsExportTaskHistoryRecord item with the matching ID.  */
    NewsItemRecipientsExportTaskHistoryRecord?: Maybe<NewsItemRecipientsExportTaskHistoryRecord>
    /**  Search for the NewsItemScope item with the matching ID.  */
    NewsItemScope?: Maybe<NewsItemScope>
    /**  Search for the NewsItemScopeHistoryRecord item with the matching ID.  */
    NewsItemScopeHistoryRecord?: Maybe<NewsItemScopeHistoryRecord>
    /**  Search for the NewsItemSharing item with the matching ID.  */
    NewsItemSharing?: Maybe<NewsItemSharing>
    /**  Search for the NewsItemSharingHistoryRecord item with the matching ID.  */
    NewsItemSharingHistoryRecord?: Maybe<NewsItemSharingHistoryRecord>
    /**  Search for the NewsItemTemplate item with the matching ID.  */
    NewsItemTemplate?: Maybe<NewsItemTemplate>
    /**  Search for the NewsItemTemplateHistoryRecord item with the matching ID.  */
    NewsItemTemplateHistoryRecord?: Maybe<NewsItemTemplateHistoryRecord>
    /**  Search for the NewsItemUserRead item with the matching ID.  */
    NewsItemUserRead?: Maybe<NewsItemUserRead>
    /**  Search for the NewsItemUserReadHistoryRecord item with the matching ID.  */
    NewsItemUserReadHistoryRecord?: Maybe<NewsItemUserReadHistoryRecord>
    /**  Search for the NotificationAnonymousSetting item with the matching ID.  */
    NotificationAnonymousSetting?: Maybe<NotificationAnonymousSetting>
    /**  Search for the NotificationAnonymousSettingHistoryRecord item with the matching ID.  */
    NotificationAnonymousSettingHistoryRecord?: Maybe<NotificationAnonymousSettingHistoryRecord>
    /**  Search for the NotificationUserSetting item with the matching ID.  */
    NotificationUserSetting?: Maybe<NotificationUserSetting>
    /**  Search for the NotificationUserSettingHistoryRecord item with the matching ID.  */
    NotificationUserSettingHistoryRecord?: Maybe<NotificationUserSettingHistoryRecord>
    /**  Search for the OidcClient item with the matching ID.  */
    OidcClient?: Maybe<OidcClient>
    /**  Search for the OidcClientHistoryRecord item with the matching ID.  */
    OidcClientHistoryRecord?: Maybe<OidcClientHistoryRecord>
    /**  Search for the Organization item with the matching ID.  */
    Organization?: Maybe<Organization>
    /**  Search for the OrganizationEmployee item with the matching ID.  */
    OrganizationEmployee?: Maybe<OrganizationEmployee>
    /**  Search for the OrganizationEmployeeHistoryRecord item with the matching ID.  */
    OrganizationEmployeeHistoryRecord?: Maybe<OrganizationEmployeeHistoryRecord>
    /**  Search for the OrganizationEmployeeRole item with the matching ID.  */
    OrganizationEmployeeRole?: Maybe<OrganizationEmployeeRole>
    /**  Search for the OrganizationEmployeeRoleHistoryRecord item with the matching ID.  */
    OrganizationEmployeeRoleHistoryRecord?: Maybe<OrganizationEmployeeRoleHistoryRecord>
    /**  Search for the OrganizationEmployeeSpecialization item with the matching ID.  */
    OrganizationEmployeeSpecialization?: Maybe<OrganizationEmployeeSpecialization>
    /**  Search for the OrganizationEmployeeSpecializationHistoryRecord item with the matching ID.  */
    OrganizationEmployeeSpecializationHistoryRecord?: Maybe<OrganizationEmployeeSpecializationHistoryRecord>
    /**  Search for the OrganizationHistoryRecord item with the matching ID.  */
    OrganizationHistoryRecord?: Maybe<OrganizationHistoryRecord>
    /**  Search for the OrganizationLink item with the matching ID.  */
    OrganizationLink?: Maybe<OrganizationLink>
    /**  Search for the OrganizationLinkHistoryRecord item with the matching ID.  */
    OrganizationLinkHistoryRecord?: Maybe<OrganizationLinkHistoryRecord>
    /**  Search for the Payment item with the matching ID.  */
    Payment?: Maybe<Payment>
    /**  Search for the PaymentHistoryRecord item with the matching ID.  */
    PaymentHistoryRecord?: Maybe<PaymentHistoryRecord>
    /**  Search for the PaymentsFilterTemplate item with the matching ID.  */
    PaymentsFilterTemplate?: Maybe<PaymentsFilterTemplate>
    /**  Search for the PaymentsFilterTemplateHistoryRecord item with the matching ID.  */
    PaymentsFilterTemplateHistoryRecord?: Maybe<PaymentsFilterTemplateHistoryRecord>
    /**  Search for the Property item with the matching ID.  */
    Property?: Maybe<Property>
    /**  Search for the PropertyHistoryRecord item with the matching ID.  */
    PropertyHistoryRecord?: Maybe<PropertyHistoryRecord>
    /**  Search for the PropertyMeter item with the matching ID.  */
    PropertyMeter?: Maybe<PropertyMeter>
    /**  Search for the PropertyMeterHistoryRecord item with the matching ID.  */
    PropertyMeterHistoryRecord?: Maybe<PropertyMeterHistoryRecord>
    /**  Search for the PropertyMeterReading item with the matching ID.  */
    PropertyMeterReading?: Maybe<PropertyMeterReading>
    /**  Search for the PropertyMeterReadingHistoryRecord item with the matching ID.  */
    PropertyMeterReadingHistoryRecord?: Maybe<PropertyMeterReadingHistoryRecord>
    /**  Search for the PropertyScope item with the matching ID.  */
    PropertyScope?: Maybe<PropertyScope>
    /**  Search for the PropertyScopeHistoryRecord item with the matching ID.  */
    PropertyScopeHistoryRecord?: Maybe<PropertyScopeHistoryRecord>
    /**  Search for the PropertyScopeOrganizationEmployee item with the matching ID.  */
    PropertyScopeOrganizationEmployee?: Maybe<PropertyScopeOrganizationEmployee>
    /**  Search for the PropertyScopeOrganizationEmployeeHistoryRecord item with the matching ID.  */
    PropertyScopeOrganizationEmployeeHistoryRecord?: Maybe<PropertyScopeOrganizationEmployeeHistoryRecord>
    /**  Search for the PropertyScopeProperty item with the matching ID.  */
    PropertyScopeProperty?: Maybe<PropertyScopeProperty>
    /**  Search for the PropertyScopePropertyHistoryRecord item with the matching ID.  */
    PropertyScopePropertyHistoryRecord?: Maybe<PropertyScopePropertyHistoryRecord>
    /**  Search for the RecurrentPayment item with the matching ID.  */
    RecurrentPayment?: Maybe<RecurrentPayment>
    /**  Search for the RecurrentPaymentContext item with the matching ID.  */
    RecurrentPaymentContext?: Maybe<RecurrentPaymentContext>
    /**  Search for the RecurrentPaymentContextHistoryRecord item with the matching ID.  */
    RecurrentPaymentContextHistoryRecord?: Maybe<RecurrentPaymentContextHistoryRecord>
    /**  Search for the RecurrentPaymentHistoryRecord item with the matching ID.  */
    RecurrentPaymentHistoryRecord?: Maybe<RecurrentPaymentHistoryRecord>
    /**  Search for the RemoteClient item with the matching ID.  */
    RemoteClient?: Maybe<RemoteClient>
    /**  Search for the RemoteClientHistoryRecord item with the matching ID.  */
    RemoteClientHistoryRecord?: Maybe<RemoteClientHistoryRecord>
    /**  Search for the Resident item with the matching ID.  */
    Resident?: Maybe<Resident>
    /**  Search for the ResidentHistoryRecord item with the matching ID.  */
    ResidentHistoryRecord?: Maybe<ResidentHistoryRecord>
    /**  Search for the ServiceConsumer item with the matching ID.  */
    ServiceConsumer?: Maybe<ServiceConsumer>
    /**  Search for the ServiceConsumerHistoryRecord item with the matching ID.  */
    ServiceConsumerHistoryRecord?: Maybe<ServiceConsumerHistoryRecord>
    /**  Search for the ServiceSubscription item with the matching ID.  */
    ServiceSubscription?: Maybe<ServiceSubscription>
    /**  Search for the ServiceSubscriptionHistoryRecord item with the matching ID.  */
    ServiceSubscriptionHistoryRecord?: Maybe<ServiceSubscriptionHistoryRecord>
    /**  Search for the TelegramUserChat item with the matching ID.  */
    TelegramUserChat?: Maybe<TelegramUserChat>
    /**  Search for the TelegramUserChatHistoryRecord item with the matching ID.  */
    TelegramUserChatHistoryRecord?: Maybe<TelegramUserChatHistoryRecord>
    /**  Search for the Ticket item with the matching ID.  */
    Ticket?: Maybe<Ticket>
    /**  Search for the TicketAutoAssignment item with the matching ID.  */
    TicketAutoAssignment?: Maybe<TicketAutoAssignment>
    /**  Search for the TicketAutoAssignmentHistoryRecord item with the matching ID.  */
    TicketAutoAssignmentHistoryRecord?: Maybe<TicketAutoAssignmentHistoryRecord>
    /**  Search for the TicketCategoryClassifier item with the matching ID.  */
    TicketCategoryClassifier?: Maybe<TicketCategoryClassifier>
    /**  Search for the TicketCategoryClassifierHistoryRecord item with the matching ID.  */
    TicketCategoryClassifierHistoryRecord?: Maybe<TicketCategoryClassifierHistoryRecord>
    /**  Search for the TicketChange item with the matching ID.  */
    TicketChange?: Maybe<TicketChange>
    /**  Search for the TicketClassifier item with the matching ID.  */
    TicketClassifier?: Maybe<TicketClassifier>
    /**  Search for the TicketClassifierHistoryRecord item with the matching ID.  */
    TicketClassifierHistoryRecord?: Maybe<TicketClassifierHistoryRecord>
    /**  Search for the TicketComment item with the matching ID.  */
    TicketComment?: Maybe<TicketComment>
    /**  Search for the TicketCommentFile item with the matching ID.  */
    TicketCommentFile?: Maybe<TicketCommentFile>
    /**  Search for the TicketCommentFileHistoryRecord item with the matching ID.  */
    TicketCommentFileHistoryRecord?: Maybe<TicketCommentFileHistoryRecord>
    /**  Search for the TicketCommentHistoryRecord item with the matching ID.  */
    TicketCommentHistoryRecord?: Maybe<TicketCommentHistoryRecord>
    /**  Search for the TicketDocumentGenerationTask item with the matching ID.  */
    TicketDocumentGenerationTask?: Maybe<TicketDocumentGenerationTask>
    /**  Search for the TicketDocumentGenerationTaskHistoryRecord item with the matching ID.  */
    TicketDocumentGenerationTaskHistoryRecord?: Maybe<TicketDocumentGenerationTaskHistoryRecord>
    /**  Search for the TicketExportTask item with the matching ID.  */
    TicketExportTask?: Maybe<TicketExportTask>
    /**  Search for the TicketExportTaskHistoryRecord item with the matching ID.  */
    TicketExportTaskHistoryRecord?: Maybe<TicketExportTaskHistoryRecord>
    /**  Search for the TicketFile item with the matching ID.  */
    TicketFile?: Maybe<TicketFile>
    /**  Search for the TicketFileHistoryRecord item with the matching ID.  */
    TicketFileHistoryRecord?: Maybe<TicketFileHistoryRecord>
    /**  Search for the TicketFilterTemplate item with the matching ID.  */
    TicketFilterTemplate?: Maybe<TicketFilterTemplate>
    /**  Search for the TicketFilterTemplateHistoryRecord item with the matching ID.  */
    TicketFilterTemplateHistoryRecord?: Maybe<TicketFilterTemplateHistoryRecord>
    /**  Search for the TicketHistoryRecord item with the matching ID.  */
    TicketHistoryRecord?: Maybe<TicketHistoryRecord>
    /**  Search for the TicketOrganizationSetting item with the matching ID.  */
    TicketOrganizationSetting?: Maybe<TicketOrganizationSetting>
    /**  Search for the TicketOrganizationSettingHistoryRecord item with the matching ID.  */
    TicketOrganizationSettingHistoryRecord?: Maybe<TicketOrganizationSettingHistoryRecord>
    /**  Search for the TicketPlaceClassifier item with the matching ID.  */
    TicketPlaceClassifier?: Maybe<TicketPlaceClassifier>
    /**  Search for the TicketPlaceClassifierHistoryRecord item with the matching ID.  */
    TicketPlaceClassifierHistoryRecord?: Maybe<TicketPlaceClassifierHistoryRecord>
    /**  Search for the TicketProblemClassifier item with the matching ID.  */
    TicketProblemClassifier?: Maybe<TicketProblemClassifier>
    /**  Search for the TicketProblemClassifierHistoryRecord item with the matching ID.  */
    TicketProblemClassifierHistoryRecord?: Maybe<TicketProblemClassifierHistoryRecord>
    /**  Search for the TicketPropertyHint item with the matching ID.  */
    TicketPropertyHint?: Maybe<TicketPropertyHint>
    /**  Search for the TicketPropertyHintHistoryRecord item with the matching ID.  */
    TicketPropertyHintHistoryRecord?: Maybe<TicketPropertyHintHistoryRecord>
    /**  Search for the TicketPropertyHintProperty item with the matching ID.  */
    TicketPropertyHintProperty?: Maybe<TicketPropertyHintProperty>
    /**  Search for the TicketPropertyHintPropertyHistoryRecord item with the matching ID.  */
    TicketPropertyHintPropertyHistoryRecord?: Maybe<TicketPropertyHintPropertyHistoryRecord>
    /**  Search for the TicketSource item with the matching ID.  */
    TicketSource?: Maybe<TicketSource>
    /**  Search for the TicketSourceHistoryRecord item with the matching ID.  */
    TicketSourceHistoryRecord?: Maybe<TicketSourceHistoryRecord>
    /**  Search for the TicketStatus item with the matching ID.  */
    TicketStatus?: Maybe<TicketStatus>
    /**  Search for the TicketStatusHistoryRecord item with the matching ID.  */
    TicketStatusHistoryRecord?: Maybe<TicketStatusHistoryRecord>
    /**  Search for the TourStep item with the matching ID.  */
    TourStep?: Maybe<TourStep>
    /**  Search for the TourStepHistoryRecord item with the matching ID.  */
    TourStepHistoryRecord?: Maybe<TourStepHistoryRecord>
    /**  Search for the User item with the matching ID.  */
    User?: Maybe<User>
    /**  Search for the UserExternalIdentity item with the matching ID.  */
    UserExternalIdentity?: Maybe<UserExternalIdentity>
    /**  Search for the UserExternalIdentityHistoryRecord item with the matching ID.  */
    UserExternalIdentityHistoryRecord?: Maybe<UserExternalIdentityHistoryRecord>
    /**  Search for the UserFavoriteTicket item with the matching ID.  */
    UserFavoriteTicket?: Maybe<UserFavoriteTicket>
    /**  Search for the UserFavoriteTicketHistoryRecord item with the matching ID.  */
    UserFavoriteTicketHistoryRecord?: Maybe<UserFavoriteTicketHistoryRecord>
    /**  Search for the UserHelpRequest item with the matching ID.  */
    UserHelpRequest?: Maybe<UserHelpRequest>
    /**  Search for the UserHelpRequestFile item with the matching ID.  */
    UserHelpRequestFile?: Maybe<UserHelpRequestFile>
    /**  Search for the UserHelpRequestFileHistoryRecord item with the matching ID.  */
    UserHelpRequestFileHistoryRecord?: Maybe<UserHelpRequestFileHistoryRecord>
    /**  Search for the UserHelpRequestHistoryRecord item with the matching ID.  */
    UserHelpRequestHistoryRecord?: Maybe<UserHelpRequestHistoryRecord>
    /**  Search for the UserHistoryRecord item with the matching ID.  */
    UserHistoryRecord?: Maybe<UserHistoryRecord>
    /**  Search for the UserRightsSet item with the matching ID.  */
    UserRightsSet?: Maybe<UserRightsSet>
    /**  Search for the UserRightsSetHistoryRecord item with the matching ID.  */
    UserRightsSetHistoryRecord?: Maybe<UserRightsSetHistoryRecord>
    /**  Search for the UserTicketCommentReadTime item with the matching ID.  */
    UserTicketCommentReadTime?: Maybe<UserTicketCommentReadTime>
    /**  Search for the UserTicketCommentReadTimeHistoryRecord item with the matching ID.  */
    UserTicketCommentReadTimeHistoryRecord?: Maybe<UserTicketCommentReadTimeHistoryRecord>
    /**  Search for the Webhook item with the matching ID.  */
    Webhook?: Maybe<Webhook>
    /**  Search for the WebhookHistoryRecord item with the matching ID.  */
    WebhookHistoryRecord?: Maybe<WebhookHistoryRecord>
    /**  Search for the WebhookSubscription item with the matching ID.  */
    WebhookSubscription?: Maybe<WebhookSubscription>
    /**  Search for the WebhookSubscriptionHistoryRecord item with the matching ID.  */
    WebhookSubscriptionHistoryRecord?: Maybe<WebhookSubscriptionHistoryRecord>
    /**  Retrieve the meta-data for the AcquiringIntegrationAccessRightHistoryRecord list.  */
    _AcquiringIntegrationAccessRightHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the AcquiringIntegrationAccessRight list.  */
    _AcquiringIntegrationAccessRightsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the AcquiringIntegrationContextHistoryRecord list.  */
    _AcquiringIntegrationContextHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the AcquiringIntegrationContext list.  */
    _AcquiringIntegrationContextsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the AcquiringIntegrationHistoryRecord list.  */
    _AcquiringIntegrationHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the AcquiringIntegration list.  */
    _AcquiringIntegrationsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the B2BAppAccessRightHistoryRecord list.  */
    _B2BAppAccessRightHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the B2BAppAccessRightSetHistoryRecord list.  */
    _B2BAppAccessRightSetHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the B2BAppAccessRightSet list.  */
    _B2BAppAccessRightSetsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the B2BAppAccessRight list.  */
    _B2BAppAccessRightsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the B2BAppContextHistoryRecord list.  */
    _B2BAppContextHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the B2BAppContext list.  */
    _B2BAppContextsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the B2BAppHistoryRecord list.  */
    _B2BAppHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the B2BAppNewsSharingConfigHistoryRecord list.  */
    _B2BAppNewsSharingConfigHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the B2BAppNewsSharingConfig list.  */
    _B2BAppNewsSharingConfigsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the B2BAppPermissionHistoryRecord list.  */
    _B2BAppPermissionHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the B2BAppPermission list.  */
    _B2BAppPermissionsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the B2BAppPromoBlockHistoryRecord list.  */
    _B2BAppPromoBlockHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the B2BAppPromoBlock list.  */
    _B2BAppPromoBlocksMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the B2BAppRoleHistoryRecord list.  */
    _B2BAppRoleHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the B2BAppRole list.  */
    _B2BAppRolesMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the B2BApp list.  */
    _B2BAppsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the B2CAppAccessRightHistoryRecord list.  */
    _B2CAppAccessRightHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the B2CAppAccessRight list.  */
    _B2CAppAccessRightsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the B2CAppBuildHistoryRecord list.  */
    _B2CAppBuildHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the B2CAppBuild list.  */
    _B2CAppBuildsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the B2CAppHistoryRecord list.  */
    _B2CAppHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the B2CAppMessageSettingHistoryRecord list.  */
    _B2CAppMessageSettingHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the B2CAppMessageSetting list.  */
    _B2CAppMessageSettingsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the B2CAppProperty list.  */
    _B2CAppPropertiesMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the B2CAppPropertyHistoryRecord list.  */
    _B2CAppPropertyHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the B2CApp list.  */
    _B2CAppsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the BankAccountHistoryRecord list.  */
    _BankAccountHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the BankAccountReportHistoryRecord list.  */
    _BankAccountReportHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the BankAccountReportTaskHistoryRecord list.  */
    _BankAccountReportTaskHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the BankAccountReportTask list.  */
    _BankAccountReportTasksMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the BankAccountReport list.  */
    _BankAccountReportsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the BankAccount list.  */
    _BankAccountsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the BankCategory list.  */
    _BankCategoriesMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the BankCategoryHistoryRecord list.  */
    _BankCategoryHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the BankContractorAccountHistoryRecord list.  */
    _BankContractorAccountHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the BankContractorAccount list.  */
    _BankContractorAccountsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the BankCostItemHistoryRecord list.  */
    _BankCostItemHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the BankCostItem list.  */
    _BankCostItemsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the BankIntegrationAccessRightHistoryRecord list.  */
    _BankIntegrationAccessRightHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the BankIntegrationAccessRight list.  */
    _BankIntegrationAccessRightsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the BankIntegrationAccountContextHistoryRecord list.  */
    _BankIntegrationAccountContextHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the BankIntegrationAccountContext list.  */
    _BankIntegrationAccountContextsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the BankIntegrationHistoryRecord list.  */
    _BankIntegrationHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the BankIntegrationOrganizationContextHistoryRecord list.  */
    _BankIntegrationOrganizationContextHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the BankIntegrationOrganizationContext list.  */
    _BankIntegrationOrganizationContextsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the BankIntegration list.  */
    _BankIntegrationsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the BankSyncTaskHistoryRecord list.  */
    _BankSyncTaskHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the BankSyncTask list.  */
    _BankSyncTasksMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the BankTransactionHistoryRecord list.  */
    _BankTransactionHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the BankTransaction list.  */
    _BankTransactionsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the BillingAccountHistoryRecord list.  */
    _BillingAccountHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the BillingAccount list.  */
    _BillingAccountsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the BillingCategory list.  */
    _BillingCategoriesMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the BillingCategoryHistoryRecord list.  */
    _BillingCategoryHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the BillingIntegrationAccessRightHistoryRecord list.  */
    _BillingIntegrationAccessRightHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the BillingIntegrationAccessRight list.  */
    _BillingIntegrationAccessRightsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the BillingIntegrationHistoryRecord list.  */
    _BillingIntegrationHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the BillingIntegrationOrganizationContextHistoryRecord list.  */
    _BillingIntegrationOrganizationContextHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the BillingIntegrationOrganizationContext list.  */
    _BillingIntegrationOrganizationContextsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the BillingIntegrationProblem list.  */
    _BillingIntegrationProblemsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the BillingIntegration list.  */
    _BillingIntegrationsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the BillingProperty list.  */
    _BillingPropertiesMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the BillingPropertyHistoryRecord list.  */
    _BillingPropertyHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the BillingReceiptFileHistoryRecord list.  */
    _BillingReceiptFileHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the BillingReceiptFile list.  */
    _BillingReceiptFilesMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the BillingReceiptHistoryRecord list.  */
    _BillingReceiptHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the BillingReceipt list.  */
    _BillingReceiptsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the BillingRecipientHistoryRecord list.  */
    _BillingRecipientHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the BillingRecipient list.  */
    _BillingRecipientsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the CallRecordFragmentHistoryRecord list.  */
    _CallRecordFragmentHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the CallRecordFragment list.  */
    _CallRecordFragmentsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the CallRecordHistoryRecord list.  */
    _CallRecordHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the CallRecord list.  */
    _CallRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the ConfirmPhoneActionHistoryRecord list.  */
    _ConfirmPhoneActionHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the ConfirmPhoneAction list.  */
    _ConfirmPhoneActionsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the ContactExportTaskHistoryRecord list.  */
    _ContactExportTaskHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the ContactExportTask list.  */
    _ContactExportTasksMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the ContactHistoryRecord list.  */
    _ContactHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the ContactRoleHistoryRecord list.  */
    _ContactRoleHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the ContactRole list.  */
    _ContactRolesMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the Contact list.  */
    _ContactsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the DocumentCategory list.  */
    _DocumentCategoriesMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the DocumentCategoryHistoryRecord list.  */
    _DocumentCategoryHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the DocumentHistoryRecord list.  */
    _DocumentHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the Document list.  */
    _DocumentsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the ExternalTokenAccessRightHistoryRecord list.  */
    _ExternalTokenAccessRightHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the ExternalTokenAccessRight list.  */
    _ExternalTokenAccessRightsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the IncidentChange list.  */
    _IncidentChangesMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the IncidentClassifierHistoryRecord list.  */
    _IncidentClassifierHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the IncidentClassifierIncidentHistoryRecord list.  */
    _IncidentClassifierIncidentHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the IncidentClassifierIncident list.  */
    _IncidentClassifierIncidentsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the IncidentClassifier list.  */
    _IncidentClassifiersMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the IncidentExportTaskHistoryRecord list.  */
    _IncidentExportTaskHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the IncidentExportTask list.  */
    _IncidentExportTasksMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the IncidentHistoryRecord list.  */
    _IncidentHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the IncidentProperty list.  */
    _IncidentPropertiesMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the IncidentPropertyHistoryRecord list.  */
    _IncidentPropertyHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the Incident list.  */
    _IncidentsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the InvoiceHistoryRecord list.  */
    _InvoiceHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the Invoice list.  */
    _InvoicesMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the MarketCategory list.  */
    _MarketCategoriesMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the MarketCategoryHistoryRecord list.  */
    _MarketCategoryHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the MarketItemFileHistoryRecord list.  */
    _MarketItemFileHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the MarketItemFile list.  */
    _MarketItemFilesMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the MarketItemHistoryRecord list.  */
    _MarketItemHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the MarketItemPriceHistoryRecord list.  */
    _MarketItemPriceHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the MarketItemPrice list.  */
    _MarketItemPricesMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the MarketItem list.  */
    _MarketItemsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the MarketPriceScopeHistoryRecord list.  */
    _MarketPriceScopeHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the MarketPriceScope list.  */
    _MarketPriceScopesMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the MarketSettingHistoryRecord list.  */
    _MarketSettingHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the MarketSetting list.  */
    _MarketSettingsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the MessageAppBlackListHistoryRecord list.  */
    _MessageAppBlackListHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the MessageAppBlackList list.  */
    _MessageAppBlackListsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the MessageBatchHistoryRecord list.  */
    _MessageBatchHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the MessageBatch list.  */
    _MessageBatchesMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the MessageHistoryRecord list.  */
    _MessageHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the MessageOrganizationBlackListHistoryRecord list.  */
    _MessageOrganizationBlackListHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the MessageOrganizationBlackList list.  */
    _MessageOrganizationBlackListsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the MessageUserBlackListHistoryRecord list.  */
    _MessageUserBlackListHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the MessageUserBlackList list.  */
    _MessageUserBlackListsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the Message list.  */
    _MessagesMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the MeterHistoryRecord list.  */
    _MeterHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the MeterReadingExportTaskHistoryRecord list.  */
    _MeterReadingExportTaskHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the MeterReadingExportTask list.  */
    _MeterReadingExportTasksMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the MeterReadingFilterTemplateHistoryRecord list.  */
    _MeterReadingFilterTemplateHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the MeterReadingFilterTemplate list.  */
    _MeterReadingFilterTemplatesMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the MeterReadingHistoryRecord list.  */
    _MeterReadingHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the MeterReadingSourceHistoryRecord list.  */
    _MeterReadingSourceHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the MeterReadingSource list.  */
    _MeterReadingSourcesMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the MeterReadingsImportTaskHistoryRecord list.  */
    _MeterReadingsImportTaskHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the MeterReadingsImportTask list.  */
    _MeterReadingsImportTasksMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the MeterReading list.  */
    _MeterReadingsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the MeterReportingPeriodHistoryRecord list.  */
    _MeterReportingPeriodHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the MeterReportingPeriod list.  */
    _MeterReportingPeriodsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the MeterResourceHistoryRecord list.  */
    _MeterResourceHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the MeterResourceOwnerHistoryRecord list.  */
    _MeterResourceOwnerHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the MeterResourceOwner list.  */
    _MeterResourceOwnersMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the MeterResource list.  */
    _MeterResourcesMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the Meter list.  */
    _MetersMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the MobileFeatureConfigHistoryRecord list.  */
    _MobileFeatureConfigHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the MobileFeatureConfig list.  */
    _MobileFeatureConfigsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the MultiPaymentHistoryRecord list.  */
    _MultiPaymentHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the MultiPayment list.  */
    _MultiPaymentsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the NewsItemHistoryRecord list.  */
    _NewsItemHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the NewsItemRecipientsExportTaskHistoryRecord list.  */
    _NewsItemRecipientsExportTaskHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the NewsItemRecipientsExportTask list.  */
    _NewsItemRecipientsExportTasksMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the NewsItemScopeHistoryRecord list.  */
    _NewsItemScopeHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the NewsItemScope list.  */
    _NewsItemScopesMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the NewsItemSharingHistoryRecord list.  */
    _NewsItemSharingHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the NewsItemSharing list.  */
    _NewsItemSharingsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the NewsItemTemplateHistoryRecord list.  */
    _NewsItemTemplateHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the NewsItemTemplate list.  */
    _NewsItemTemplatesMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the NewsItemUserReadHistoryRecord list.  */
    _NewsItemUserReadHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the NewsItemUserRead list.  */
    _NewsItemUserReadsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the NewsItem list.  */
    _NewsItemsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the NotificationAnonymousSettingHistoryRecord list.  */
    _NotificationAnonymousSettingHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the NotificationAnonymousSetting list.  */
    _NotificationAnonymousSettingsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the NotificationUserSettingHistoryRecord list.  */
    _NotificationUserSettingHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the NotificationUserSetting list.  */
    _NotificationUserSettingsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the OidcClientHistoryRecord list.  */
    _OidcClientHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the OidcClient list.  */
    _OidcClientsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the OrganizationEmployeeHistoryRecord list.  */
    _OrganizationEmployeeHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the OrganizationEmployeeRoleHistoryRecord list.  */
    _OrganizationEmployeeRoleHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the OrganizationEmployeeRole list.  */
    _OrganizationEmployeeRolesMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the OrganizationEmployeeSpecializationHistoryRecord list.  */
    _OrganizationEmployeeSpecializationHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the OrganizationEmployeeSpecialization list.  */
    _OrganizationEmployeeSpecializationsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the OrganizationEmployee list.  */
    _OrganizationEmployeesMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the OrganizationHistoryRecord list.  */
    _OrganizationHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the OrganizationLinkHistoryRecord list.  */
    _OrganizationLinkHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the OrganizationLink list.  */
    _OrganizationLinksMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the Organization list.  */
    _OrganizationsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the PaymentHistoryRecord list.  */
    _PaymentHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the PaymentsFilterTemplateHistoryRecord list.  */
    _PaymentsFilterTemplateHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the PaymentsFilterTemplate list.  */
    _PaymentsFilterTemplatesMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the Payment list.  */
    _PaymentsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the Property list.  */
    _PropertiesMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the PropertyHistoryRecord list.  */
    _PropertyHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the PropertyMeterHistoryRecord list.  */
    _PropertyMeterHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the PropertyMeterReadingHistoryRecord list.  */
    _PropertyMeterReadingHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the PropertyMeterReading list.  */
    _PropertyMeterReadingsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the PropertyMeter list.  */
    _PropertyMetersMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the PropertyScopeHistoryRecord list.  */
    _PropertyScopeHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the PropertyScopeOrganizationEmployeeHistoryRecord list.  */
    _PropertyScopeOrganizationEmployeeHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the PropertyScopeOrganizationEmployee list.  */
    _PropertyScopeOrganizationEmployeesMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the PropertyScopeProperty list.  */
    _PropertyScopePropertiesMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the PropertyScopePropertyHistoryRecord list.  */
    _PropertyScopePropertyHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the PropertyScope list.  */
    _PropertyScopesMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the RecurrentPaymentContextHistoryRecord list.  */
    _RecurrentPaymentContextHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the RecurrentPaymentContext list.  */
    _RecurrentPaymentContextsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the RecurrentPaymentHistoryRecord list.  */
    _RecurrentPaymentHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the RecurrentPayment list.  */
    _RecurrentPaymentsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the RemoteClientHistoryRecord list.  */
    _RemoteClientHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the RemoteClient list.  */
    _RemoteClientsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the ResidentHistoryRecord list.  */
    _ResidentHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the Resident list.  */
    _ResidentsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the ServiceConsumerHistoryRecord list.  */
    _ServiceConsumerHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the ServiceConsumer list.  */
    _ServiceConsumersMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the ServiceSubscriptionHistoryRecord list.  */
    _ServiceSubscriptionHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the ServiceSubscription list.  */
    _ServiceSubscriptionsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the TelegramUserChatHistoryRecord list.  */
    _TelegramUserChatHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the TelegramUserChat list.  */
    _TelegramUserChatsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the TicketAutoAssignmentHistoryRecord list.  */
    _TicketAutoAssignmentHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the TicketAutoAssignment list.  */
    _TicketAutoAssignmentsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the TicketCategoryClassifierHistoryRecord list.  */
    _TicketCategoryClassifierHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the TicketCategoryClassifier list.  */
    _TicketCategoryClassifiersMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the TicketChange list.  */
    _TicketChangesMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the TicketClassifierHistoryRecord list.  */
    _TicketClassifierHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the TicketClassifier list.  */
    _TicketClassifiersMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the TicketCommentFileHistoryRecord list.  */
    _TicketCommentFileHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the TicketCommentFile list.  */
    _TicketCommentFilesMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the TicketCommentHistoryRecord list.  */
    _TicketCommentHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the TicketComment list.  */
    _TicketCommentsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the TicketDocumentGenerationTaskHistoryRecord list.  */
    _TicketDocumentGenerationTaskHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the TicketDocumentGenerationTask list.  */
    _TicketDocumentGenerationTasksMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the TicketExportTaskHistoryRecord list.  */
    _TicketExportTaskHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the TicketExportTask list.  */
    _TicketExportTasksMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the TicketFileHistoryRecord list.  */
    _TicketFileHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the TicketFile list.  */
    _TicketFilesMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the TicketFilterTemplateHistoryRecord list.  */
    _TicketFilterTemplateHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the TicketFilterTemplate list.  */
    _TicketFilterTemplatesMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the TicketHistoryRecord list.  */
    _TicketHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the TicketOrganizationSettingHistoryRecord list.  */
    _TicketOrganizationSettingHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the TicketOrganizationSetting list.  */
    _TicketOrganizationSettingsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the TicketPlaceClassifierHistoryRecord list.  */
    _TicketPlaceClassifierHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the TicketPlaceClassifier list.  */
    _TicketPlaceClassifiersMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the TicketProblemClassifierHistoryRecord list.  */
    _TicketProblemClassifierHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the TicketProblemClassifier list.  */
    _TicketProblemClassifiersMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the TicketPropertyHintHistoryRecord list.  */
    _TicketPropertyHintHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the TicketPropertyHintProperty list.  */
    _TicketPropertyHintPropertiesMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the TicketPropertyHintPropertyHistoryRecord list.  */
    _TicketPropertyHintPropertyHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the TicketPropertyHint list.  */
    _TicketPropertyHintsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the TicketSourceHistoryRecord list.  */
    _TicketSourceHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the TicketSource list.  */
    _TicketSourcesMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the TicketStatusHistoryRecord list.  */
    _TicketStatusHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the TicketStatus list.  */
    _TicketStatusesMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the Ticket list.  */
    _TicketsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the TourStepHistoryRecord list.  */
    _TourStepHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the TourStep list.  */
    _TourStepsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the UserExternalIdentity list.  */
    _UserExternalIdentitiesMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the UserExternalIdentityHistoryRecord list.  */
    _UserExternalIdentityHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the UserFavoriteTicketHistoryRecord list.  */
    _UserFavoriteTicketHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the UserFavoriteTicket list.  */
    _UserFavoriteTicketsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the UserHelpRequestFileHistoryRecord list.  */
    _UserHelpRequestFileHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the UserHelpRequestFile list.  */
    _UserHelpRequestFilesMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the UserHelpRequestHistoryRecord list.  */
    _UserHelpRequestHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the UserHelpRequest list.  */
    _UserHelpRequestsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the UserHistoryRecord list.  */
    _UserHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the UserRightsSetHistoryRecord list.  */
    _UserRightsSetHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the UserRightsSet list.  */
    _UserRightsSetsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the UserTicketCommentReadTimeHistoryRecord list.  */
    _UserTicketCommentReadTimeHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the UserTicketCommentReadTime list.  */
    _UserTicketCommentReadTimesMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the User list.  */
    _UsersMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the WebhookHistoryRecord list.  */
    _WebhookHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the WebhookSubscriptionHistoryRecord list.  */
    _WebhookSubscriptionHistoryRecordsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the WebhookSubscription list.  */
    _WebhookSubscriptionsMeta?: Maybe<_ListMeta>
    /**  Retrieve the meta-data for the Webhook list.  */
    _WebhooksMeta?: Maybe<_ListMeta>
    /**  Perform a meta-query on all AcquiringIntegrationAccessRightHistoryRecord items which match the where clause.  */
    _allAcquiringIntegrationAccessRightHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all AcquiringIntegrationAccessRight items which match the where clause.  */
    _allAcquiringIntegrationAccessRightsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all AcquiringIntegrationContextHistoryRecord items which match the where clause.  */
    _allAcquiringIntegrationContextHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all AcquiringIntegrationContext items which match the where clause.  */
    _allAcquiringIntegrationContextsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all AcquiringIntegrationHistoryRecord items which match the where clause.  */
    _allAcquiringIntegrationHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all AcquiringIntegration items which match the where clause.  */
    _allAcquiringIntegrationsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all B2BAppAccessRightHistoryRecord items which match the where clause.  */
    _allB2BAppAccessRightHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all B2BAppAccessRightSetHistoryRecord items which match the where clause.  */
    _allB2BAppAccessRightSetHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all B2BAppAccessRightSet items which match the where clause.  */
    _allB2BAppAccessRightSetsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all B2BAppAccessRight items which match the where clause.  */
    _allB2BAppAccessRightsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all B2BAppContextHistoryRecord items which match the where clause.  */
    _allB2BAppContextHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all B2BAppContext items which match the where clause.  */
    _allB2BAppContextsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all B2BAppHistoryRecord items which match the where clause.  */
    _allB2BAppHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all B2BAppNewsSharingConfigHistoryRecord items which match the where clause.  */
    _allB2BAppNewsSharingConfigHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all B2BAppNewsSharingConfig items which match the where clause.  */
    _allB2BAppNewsSharingConfigsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all B2BAppPermissionHistoryRecord items which match the where clause.  */
    _allB2BAppPermissionHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all B2BAppPermission items which match the where clause.  */
    _allB2BAppPermissionsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all B2BAppPromoBlockHistoryRecord items which match the where clause.  */
    _allB2BAppPromoBlockHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all B2BAppPromoBlock items which match the where clause.  */
    _allB2BAppPromoBlocksMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all B2BAppRoleHistoryRecord items which match the where clause.  */
    _allB2BAppRoleHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all B2BAppRole items which match the where clause.  */
    _allB2BAppRolesMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all B2BApp items which match the where clause.  */
    _allB2BAppsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all B2CAppAccessRightHistoryRecord items which match the where clause.  */
    _allB2CAppAccessRightHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all B2CAppAccessRight items which match the where clause.  */
    _allB2CAppAccessRightsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all B2CAppBuildHistoryRecord items which match the where clause.  */
    _allB2CAppBuildHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all B2CAppBuild items which match the where clause.  */
    _allB2CAppBuildsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all B2CAppHistoryRecord items which match the where clause.  */
    _allB2CAppHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all B2CAppMessageSettingHistoryRecord items which match the where clause.  */
    _allB2CAppMessageSettingHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all B2CAppMessageSetting items which match the where clause.  */
    _allB2CAppMessageSettingsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all B2CAppProperty items which match the where clause.  */
    _allB2CAppPropertiesMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all B2CAppPropertyHistoryRecord items which match the where clause.  */
    _allB2CAppPropertyHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all B2CApp items which match the where clause.  */
    _allB2CAppsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all BankAccountHistoryRecord items which match the where clause.  */
    _allBankAccountHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all BankAccountReportHistoryRecord items which match the where clause.  */
    _allBankAccountReportHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all BankAccountReportTaskHistoryRecord items which match the where clause.  */
    _allBankAccountReportTaskHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all BankAccountReportTask items which match the where clause.  */
    _allBankAccountReportTasksMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all BankAccountReport items which match the where clause.  */
    _allBankAccountReportsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all BankAccount items which match the where clause.  */
    _allBankAccountsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all BankCategory items which match the where clause.  */
    _allBankCategoriesMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all BankCategoryHistoryRecord items which match the where clause.  */
    _allBankCategoryHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all BankContractorAccountHistoryRecord items which match the where clause.  */
    _allBankContractorAccountHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all BankContractorAccount items which match the where clause.  */
    _allBankContractorAccountsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all BankCostItemHistoryRecord items which match the where clause.  */
    _allBankCostItemHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all BankCostItem items which match the where clause.  */
    _allBankCostItemsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all BankIntegrationAccessRightHistoryRecord items which match the where clause.  */
    _allBankIntegrationAccessRightHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all BankIntegrationAccessRight items which match the where clause.  */
    _allBankIntegrationAccessRightsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all BankIntegrationAccountContextHistoryRecord items which match the where clause.  */
    _allBankIntegrationAccountContextHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all BankIntegrationAccountContext items which match the where clause.  */
    _allBankIntegrationAccountContextsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all BankIntegrationHistoryRecord items which match the where clause.  */
    _allBankIntegrationHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all BankIntegrationOrganizationContextHistoryRecord items which match the where clause.  */
    _allBankIntegrationOrganizationContextHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all BankIntegrationOrganizationContext items which match the where clause.  */
    _allBankIntegrationOrganizationContextsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all BankIntegration items which match the where clause.  */
    _allBankIntegrationsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all BankSyncTaskHistoryRecord items which match the where clause.  */
    _allBankSyncTaskHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all BankSyncTask items which match the where clause.  */
    _allBankSyncTasksMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all BankTransactionHistoryRecord items which match the where clause.  */
    _allBankTransactionHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all BankTransaction items which match the where clause.  */
    _allBankTransactionsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all BillingAccountHistoryRecord items which match the where clause.  */
    _allBillingAccountHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all BillingAccount items which match the where clause.  */
    _allBillingAccountsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all BillingCategory items which match the where clause.  */
    _allBillingCategoriesMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all BillingCategoryHistoryRecord items which match the where clause.  */
    _allBillingCategoryHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all BillingIntegrationAccessRightHistoryRecord items which match the where clause.  */
    _allBillingIntegrationAccessRightHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all BillingIntegrationAccessRight items which match the where clause.  */
    _allBillingIntegrationAccessRightsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all BillingIntegrationHistoryRecord items which match the where clause.  */
    _allBillingIntegrationHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all BillingIntegrationOrganizationContextHistoryRecord items which match the where clause.  */
    _allBillingIntegrationOrganizationContextHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all BillingIntegrationOrganizationContext items which match the where clause.  */
    _allBillingIntegrationOrganizationContextsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all BillingIntegrationProblem items which match the where clause.  */
    _allBillingIntegrationProblemsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all BillingIntegration items which match the where clause.  */
    _allBillingIntegrationsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all BillingProperty items which match the where clause.  */
    _allBillingPropertiesMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all BillingPropertyHistoryRecord items which match the where clause.  */
    _allBillingPropertyHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all BillingReceiptFileHistoryRecord items which match the where clause.  */
    _allBillingReceiptFileHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all BillingReceiptFile items which match the where clause.  */
    _allBillingReceiptFilesMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all BillingReceiptHistoryRecord items which match the where clause.  */
    _allBillingReceiptHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all BillingReceipt items which match the where clause.  */
    _allBillingReceiptsMeta?: Maybe<_QueryMeta>
    /**
   * Sum of organizations billing receipts
   *
   * Calculate sum of organizations billing receipts by organizationId or tin and period
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "WRONG_VALUE",
   *   "message": "You must specify one of two values: tin or organization"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "WRONG_VALUE",
   *   "message": "Bad period format, must be YYYY-MM-01. Example: 2022-02-01"
   * }`
   */
    _allBillingReceiptsSum?: Maybe<BillingReceiptsSumOutput>
    /**  Perform a meta-query on all BillingRecipientHistoryRecord items which match the where clause.  */
    _allBillingRecipientHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all BillingRecipient items which match the where clause.  */
    _allBillingRecipientsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all CallRecordFragmentHistoryRecord items which match the where clause.  */
    _allCallRecordFragmentHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all CallRecordFragment items which match the where clause.  */
    _allCallRecordFragmentsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all CallRecordHistoryRecord items which match the where clause.  */
    _allCallRecordHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all CallRecord items which match the where clause.  */
    _allCallRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all ConfirmPhoneActionHistoryRecord items which match the where clause.  */
    _allConfirmPhoneActionHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all ConfirmPhoneAction items which match the where clause.  */
    _allConfirmPhoneActionsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all ContactExportTaskHistoryRecord items which match the where clause.  */
    _allContactExportTaskHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all ContactExportTask items which match the where clause.  */
    _allContactExportTasksMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all ContactHistoryRecord items which match the where clause.  */
    _allContactHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all ContactRoleHistoryRecord items which match the where clause.  */
    _allContactRoleHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all ContactRole items which match the where clause.  */
    _allContactRolesMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all Contact items which match the where clause.  */
    _allContactsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all DocumentCategory items which match the where clause.  */
    _allDocumentCategoriesMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all DocumentCategoryHistoryRecord items which match the where clause.  */
    _allDocumentCategoryHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all DocumentHistoryRecord items which match the where clause.  */
    _allDocumentHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all Document items which match the where clause.  */
    _allDocumentsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all ExternalTokenAccessRightHistoryRecord items which match the where clause.  */
    _allExternalTokenAccessRightHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all ExternalTokenAccessRight items which match the where clause.  */
    _allExternalTokenAccessRightsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all IncidentChange items which match the where clause.  */
    _allIncidentChangesMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all IncidentClassifierHistoryRecord items which match the where clause.  */
    _allIncidentClassifierHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all IncidentClassifierIncidentHistoryRecord items which match the where clause.  */
    _allIncidentClassifierIncidentHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all IncidentClassifierIncident items which match the where clause.  */
    _allIncidentClassifierIncidentsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all IncidentClassifier items which match the where clause.  */
    _allIncidentClassifiersMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all IncidentExportTaskHistoryRecord items which match the where clause.  */
    _allIncidentExportTaskHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all IncidentExportTask items which match the where clause.  */
    _allIncidentExportTasksMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all IncidentHistoryRecord items which match the where clause.  */
    _allIncidentHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all IncidentProperty items which match the where clause.  */
    _allIncidentPropertiesMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all IncidentPropertyHistoryRecord items which match the where clause.  */
    _allIncidentPropertyHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all Incident items which match the where clause.  */
    _allIncidentsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all InvoiceHistoryRecord items which match the where clause.  */
    _allInvoiceHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all Invoice items which match the where clause.  */
    _allInvoicesMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all MarketCategory items which match the where clause.  */
    _allMarketCategoriesMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all MarketCategoryHistoryRecord items which match the where clause.  */
    _allMarketCategoryHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all MarketItemFileHistoryRecord items which match the where clause.  */
    _allMarketItemFileHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all MarketItemFile items which match the where clause.  */
    _allMarketItemFilesMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all MarketItemHistoryRecord items which match the where clause.  */
    _allMarketItemHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all MarketItemPriceHistoryRecord items which match the where clause.  */
    _allMarketItemPriceHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all MarketItemPrice items which match the where clause.  */
    _allMarketItemPricesMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all MarketItem items which match the where clause.  */
    _allMarketItemsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all MarketPriceScopeHistoryRecord items which match the where clause.  */
    _allMarketPriceScopeHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all MarketPriceScope items which match the where clause.  */
    _allMarketPriceScopesMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all MarketSettingHistoryRecord items which match the where clause.  */
    _allMarketSettingHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all MarketSetting items which match the where clause.  */
    _allMarketSettingsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all MessageAppBlackListHistoryRecord items which match the where clause.  */
    _allMessageAppBlackListHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all MessageAppBlackList items which match the where clause.  */
    _allMessageAppBlackListsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all MessageBatchHistoryRecord items which match the where clause.  */
    _allMessageBatchHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all MessageBatch items which match the where clause.  */
    _allMessageBatchesMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all MessageHistoryRecord items which match the where clause.  */
    _allMessageHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all MessageOrganizationBlackListHistoryRecord items which match the where clause.  */
    _allMessageOrganizationBlackListHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all MessageOrganizationBlackList items which match the where clause.  */
    _allMessageOrganizationBlackListsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all MessageUserBlackListHistoryRecord items which match the where clause.  */
    _allMessageUserBlackListHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all MessageUserBlackList items which match the where clause.  */
    _allMessageUserBlackListsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all Message items which match the where clause.  */
    _allMessagesMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all MeterHistoryRecord items which match the where clause.  */
    _allMeterHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all MeterReadingExportTaskHistoryRecord items which match the where clause.  */
    _allMeterReadingExportTaskHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all MeterReadingExportTask items which match the where clause.  */
    _allMeterReadingExportTasksMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all MeterReadingFilterTemplateHistoryRecord items which match the where clause.  */
    _allMeterReadingFilterTemplateHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all MeterReadingFilterTemplate items which match the where clause.  */
    _allMeterReadingFilterTemplatesMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all MeterReadingHistoryRecord items which match the where clause.  */
    _allMeterReadingHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all MeterReadingSourceHistoryRecord items which match the where clause.  */
    _allMeterReadingSourceHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all MeterReadingSource items which match the where clause.  */
    _allMeterReadingSourcesMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all MeterReadingsImportTaskHistoryRecord items which match the where clause.  */
    _allMeterReadingsImportTaskHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all MeterReadingsImportTask items which match the where clause.  */
    _allMeterReadingsImportTasksMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all MeterReading items which match the where clause.  */
    _allMeterReadingsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all MeterReportingPeriodHistoryRecord items which match the where clause.  */
    _allMeterReportingPeriodHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all MeterReportingPeriod items which match the where clause.  */
    _allMeterReportingPeriodsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all MeterResourceHistoryRecord items which match the where clause.  */
    _allMeterResourceHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all MeterResourceOwnerHistoryRecord items which match the where clause.  */
    _allMeterResourceOwnerHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all MeterResourceOwner items which match the where clause.  */
    _allMeterResourceOwnersMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all MeterResource items which match the where clause.  */
    _allMeterResourcesMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all Meter items which match the where clause.  */
    _allMetersMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all MobileFeatureConfigHistoryRecord items which match the where clause.  */
    _allMobileFeatureConfigHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all MobileFeatureConfig items which match the where clause.  */
    _allMobileFeatureConfigsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all MultiPaymentHistoryRecord items which match the where clause.  */
    _allMultiPaymentHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all MultiPayment items which match the where clause.  */
    _allMultiPaymentsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all NewsItemHistoryRecord items which match the where clause.  */
    _allNewsItemHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all NewsItemRecipientsExportTaskHistoryRecord items which match the where clause.  */
    _allNewsItemRecipientsExportTaskHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all NewsItemRecipientsExportTask items which match the where clause.  */
    _allNewsItemRecipientsExportTasksMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all NewsItemScopeHistoryRecord items which match the where clause.  */
    _allNewsItemScopeHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all NewsItemScope items which match the where clause.  */
    _allNewsItemScopesMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all NewsItemSharingHistoryRecord items which match the where clause.  */
    _allNewsItemSharingHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all NewsItemSharing items which match the where clause.  */
    _allNewsItemSharingsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all NewsItemTemplateHistoryRecord items which match the where clause.  */
    _allNewsItemTemplateHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all NewsItemTemplate items which match the where clause.  */
    _allNewsItemTemplatesMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all NewsItemUserReadHistoryRecord items which match the where clause.  */
    _allNewsItemUserReadHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all NewsItemUserRead items which match the where clause.  */
    _allNewsItemUserReadsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all NewsItem items which match the where clause.  */
    _allNewsItemsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all NotificationAnonymousSettingHistoryRecord items which match the where clause.  */
    _allNotificationAnonymousSettingHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all NotificationAnonymousSetting items which match the where clause.  */
    _allNotificationAnonymousSettingsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all NotificationUserSettingHistoryRecord items which match the where clause.  */
    _allNotificationUserSettingHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all NotificationUserSetting items which match the where clause.  */
    _allNotificationUserSettingsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all OidcClientHistoryRecord items which match the where clause.  */
    _allOidcClientHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all OidcClient items which match the where clause.  */
    _allOidcClientsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all OrganizationEmployeeHistoryRecord items which match the where clause.  */
    _allOrganizationEmployeeHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all OrganizationEmployeeRoleHistoryRecord items which match the where clause.  */
    _allOrganizationEmployeeRoleHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all OrganizationEmployeeRole items which match the where clause.  */
    _allOrganizationEmployeeRolesMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all OrganizationEmployeeSpecializationHistoryRecord items which match the where clause.  */
    _allOrganizationEmployeeSpecializationHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all OrganizationEmployeeSpecialization items which match the where clause.  */
    _allOrganizationEmployeeSpecializationsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all OrganizationEmployee items which match the where clause.  */
    _allOrganizationEmployeesMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all OrganizationHistoryRecord items which match the where clause.  */
    _allOrganizationHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all OrganizationLinkHistoryRecord items which match the where clause.  */
    _allOrganizationLinkHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all OrganizationLink items which match the where clause.  */
    _allOrganizationLinksMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all Organization items which match the where clause.  */
    _allOrganizationsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all PaymentHistoryRecord items which match the where clause.  */
    _allPaymentHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all PaymentsFilterTemplateHistoryRecord items which match the where clause.  */
    _allPaymentsFilterTemplateHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all PaymentsFilterTemplate items which match the where clause.  */
    _allPaymentsFilterTemplatesMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all Payment items which match the where clause.  */
    _allPaymentsMeta?: Maybe<_QueryMeta>
    _allPaymentsSum?: Maybe<PaymentsSumOutput>
    /**  Perform a meta-query on all Property items which match the where clause.  */
    _allPropertiesMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all PropertyHistoryRecord items which match the where clause.  */
    _allPropertyHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all PropertyMeterHistoryRecord items which match the where clause.  */
    _allPropertyMeterHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all PropertyMeterReadingHistoryRecord items which match the where clause.  */
    _allPropertyMeterReadingHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all PropertyMeterReading items which match the where clause.  */
    _allPropertyMeterReadingsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all PropertyMeter items which match the where clause.  */
    _allPropertyMetersMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all PropertyScopeHistoryRecord items which match the where clause.  */
    _allPropertyScopeHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all PropertyScopeOrganizationEmployeeHistoryRecord items which match the where clause.  */
    _allPropertyScopeOrganizationEmployeeHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all PropertyScopeOrganizationEmployee items which match the where clause.  */
    _allPropertyScopeOrganizationEmployeesMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all PropertyScopeProperty items which match the where clause.  */
    _allPropertyScopePropertiesMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all PropertyScopePropertyHistoryRecord items which match the where clause.  */
    _allPropertyScopePropertyHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all PropertyScope items which match the where clause.  */
    _allPropertyScopesMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all RecurrentPaymentContextHistoryRecord items which match the where clause.  */
    _allRecurrentPaymentContextHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all RecurrentPaymentContext items which match the where clause.  */
    _allRecurrentPaymentContextsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all RecurrentPaymentHistoryRecord items which match the where clause.  */
    _allRecurrentPaymentHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all RecurrentPayment items which match the where clause.  */
    _allRecurrentPaymentsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all RemoteClientHistoryRecord items which match the where clause.  */
    _allRemoteClientHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all RemoteClient items which match the where clause.  */
    _allRemoteClientsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all ResidentHistoryRecord items which match the where clause.  */
    _allResidentHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all Resident items which match the where clause.  */
    _allResidentsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all ServiceConsumerHistoryRecord items which match the where clause.  */
    _allServiceConsumerHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all ServiceConsumer items which match the where clause.  */
    _allServiceConsumersMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all ServiceSubscriptionHistoryRecord items which match the where clause.  */
    _allServiceSubscriptionHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all ServiceSubscription items which match the where clause.  */
    _allServiceSubscriptionsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all TelegramUserChatHistoryRecord items which match the where clause.  */
    _allTelegramUserChatHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all TelegramUserChat items which match the where clause.  */
    _allTelegramUserChatsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all TicketAutoAssignmentHistoryRecord items which match the where clause.  */
    _allTicketAutoAssignmentHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all TicketAutoAssignment items which match the where clause.  */
    _allTicketAutoAssignmentsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all TicketCategoryClassifierHistoryRecord items which match the where clause.  */
    _allTicketCategoryClassifierHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all TicketCategoryClassifier items which match the where clause.  */
    _allTicketCategoryClassifiersMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all TicketChange items which match the where clause.  */
    _allTicketChangesMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all TicketClassifierHistoryRecord items which match the where clause.  */
    _allTicketClassifierHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all TicketClassifier items which match the where clause.  */
    _allTicketClassifiersMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all TicketCommentFileHistoryRecord items which match the where clause.  */
    _allTicketCommentFileHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all TicketCommentFile items which match the where clause.  */
    _allTicketCommentFilesMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all TicketCommentHistoryRecord items which match the where clause.  */
    _allTicketCommentHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all TicketComment items which match the where clause.  */
    _allTicketCommentsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all TicketDocumentGenerationTaskHistoryRecord items which match the where clause.  */
    _allTicketDocumentGenerationTaskHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all TicketDocumentGenerationTask items which match the where clause.  */
    _allTicketDocumentGenerationTasksMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all TicketExportTaskHistoryRecord items which match the where clause.  */
    _allTicketExportTaskHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all TicketExportTask items which match the where clause.  */
    _allTicketExportTasksMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all TicketFileHistoryRecord items which match the where clause.  */
    _allTicketFileHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all TicketFile items which match the where clause.  */
    _allTicketFilesMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all TicketFilterTemplateHistoryRecord items which match the where clause.  */
    _allTicketFilterTemplateHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all TicketFilterTemplate items which match the where clause.  */
    _allTicketFilterTemplatesMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all TicketHistoryRecord items which match the where clause.  */
    _allTicketHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all TicketOrganizationSettingHistoryRecord items which match the where clause.  */
    _allTicketOrganizationSettingHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all TicketOrganizationSetting items which match the where clause.  */
    _allTicketOrganizationSettingsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all TicketPlaceClassifierHistoryRecord items which match the where clause.  */
    _allTicketPlaceClassifierHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all TicketPlaceClassifier items which match the where clause.  */
    _allTicketPlaceClassifiersMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all TicketProblemClassifierHistoryRecord items which match the where clause.  */
    _allTicketProblemClassifierHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all TicketProblemClassifier items which match the where clause.  */
    _allTicketProblemClassifiersMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all TicketPropertyHintHistoryRecord items which match the where clause.  */
    _allTicketPropertyHintHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all TicketPropertyHintProperty items which match the where clause.  */
    _allTicketPropertyHintPropertiesMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all TicketPropertyHintPropertyHistoryRecord items which match the where clause.  */
    _allTicketPropertyHintPropertyHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all TicketPropertyHint items which match the where clause.  */
    _allTicketPropertyHintsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all TicketSourceHistoryRecord items which match the where clause.  */
    _allTicketSourceHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all TicketSource items which match the where clause.  */
    _allTicketSourcesMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all TicketStatusHistoryRecord items which match the where clause.  */
    _allTicketStatusHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all TicketStatus items which match the where clause.  */
    _allTicketStatusesMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all Ticket items which match the where clause.  */
    _allTicketsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all TourStepHistoryRecord items which match the where clause.  */
    _allTourStepHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all TourStep items which match the where clause.  */
    _allTourStepsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all UserExternalIdentity items which match the where clause.  */
    _allUserExternalIdentitiesMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all UserExternalIdentityHistoryRecord items which match the where clause.  */
    _allUserExternalIdentityHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all UserFavoriteTicketHistoryRecord items which match the where clause.  */
    _allUserFavoriteTicketHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all UserFavoriteTicket items which match the where clause.  */
    _allUserFavoriteTicketsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all UserHelpRequestFileHistoryRecord items which match the where clause.  */
    _allUserHelpRequestFileHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all UserHelpRequestFile items which match the where clause.  */
    _allUserHelpRequestFilesMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all UserHelpRequestHistoryRecord items which match the where clause.  */
    _allUserHelpRequestHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all UserHelpRequest items which match the where clause.  */
    _allUserHelpRequestsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all UserHistoryRecord items which match the where clause.  */
    _allUserHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all UserRightsSetHistoryRecord items which match the where clause.  */
    _allUserRightsSetHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all UserRightsSet items which match the where clause.  */
    _allUserRightsSetsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all UserTicketCommentReadTimeHistoryRecord items which match the where clause.  */
    _allUserTicketCommentReadTimeHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all UserTicketCommentReadTime items which match the where clause.  */
    _allUserTicketCommentReadTimesMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all User items which match the where clause.  */
    _allUsersMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all WebhookHistoryRecord items which match the where clause.  */
    _allWebhookHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all WebhookSubscriptionHistoryRecord items which match the where clause.  */
    _allWebhookSubscriptionHistoryRecordsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all WebhookSubscription items which match the where clause.  */
    _allWebhookSubscriptionsMeta?: Maybe<_QueryMeta>
    /**  Perform a meta-query on all Webhook items which match the where clause.  */
    _allWebhooksMeta?: Maybe<_QueryMeta>
    /**  Retrieve the meta-data for all lists.  */
    _ksListsMeta?: Maybe<Array<Maybe<_ListMeta>>>
    /**  Search for all AcquiringIntegrationAccessRightHistoryRecord items which match the where clause.  */
    allAcquiringIntegrationAccessRightHistoryRecords?: Maybe<Array<Maybe<AcquiringIntegrationAccessRightHistoryRecord>>>
    /**  Search for all AcquiringIntegrationAccessRight items which match the where clause.  */
    allAcquiringIntegrationAccessRights?: Maybe<Array<Maybe<AcquiringIntegrationAccessRight>>>
    /**  Search for all AcquiringIntegrationContextHistoryRecord items which match the where clause.  */
    allAcquiringIntegrationContextHistoryRecords?: Maybe<Array<Maybe<AcquiringIntegrationContextHistoryRecord>>>
    /**  Search for all AcquiringIntegrationContext items which match the where clause.  */
    allAcquiringIntegrationContexts?: Maybe<Array<Maybe<AcquiringIntegrationContext>>>
    /**  Search for all AcquiringIntegrationHistoryRecord items which match the where clause.  */
    allAcquiringIntegrationHistoryRecords?: Maybe<Array<Maybe<AcquiringIntegrationHistoryRecord>>>
    /**  Search for all AcquiringIntegration items which match the where clause.  */
    allAcquiringIntegrations?: Maybe<Array<Maybe<AcquiringIntegration>>>
    /**  Search for all B2BAppAccessRightHistoryRecord items which match the where clause.  */
    allB2BAppAccessRightHistoryRecords?: Maybe<Array<Maybe<B2BAppAccessRightHistoryRecord>>>
    /**  Search for all B2BAppAccessRightSetHistoryRecord items which match the where clause.  */
    allB2BAppAccessRightSetHistoryRecords?: Maybe<Array<Maybe<B2BAppAccessRightSetHistoryRecord>>>
    /**  Search for all B2BAppAccessRightSet items which match the where clause.  */
    allB2BAppAccessRightSets?: Maybe<Array<Maybe<B2BAppAccessRightSet>>>
    /**  Search for all B2BAppAccessRight items which match the where clause.  */
    allB2BAppAccessRights?: Maybe<Array<Maybe<B2BAppAccessRight>>>
    /**  Search for all B2BAppContextHistoryRecord items which match the where clause.  */
    allB2BAppContextHistoryRecords?: Maybe<Array<Maybe<B2BAppContextHistoryRecord>>>
    /**  Search for all B2BAppContext items which match the where clause.  */
    allB2BAppContexts?: Maybe<Array<Maybe<B2BAppContext>>>
    /**  Search for all B2BAppHistoryRecord items which match the where clause.  */
    allB2BAppHistoryRecords?: Maybe<Array<Maybe<B2BAppHistoryRecord>>>
    /**  Search for all B2BAppNewsSharingConfigHistoryRecord items which match the where clause.  */
    allB2BAppNewsSharingConfigHistoryRecords?: Maybe<Array<Maybe<B2BAppNewsSharingConfigHistoryRecord>>>
    /**  Search for all B2BAppNewsSharingConfig items which match the where clause.  */
    allB2BAppNewsSharingConfigs?: Maybe<Array<Maybe<B2BAppNewsSharingConfig>>>
    /**  Search for all B2BAppPermissionHistoryRecord items which match the where clause.  */
    allB2BAppPermissionHistoryRecords?: Maybe<Array<Maybe<B2BAppPermissionHistoryRecord>>>
    /**  Search for all B2BAppPermission items which match the where clause.  */
    allB2BAppPermissions?: Maybe<Array<Maybe<B2BAppPermission>>>
    /**  Search for all B2BAppPromoBlockHistoryRecord items which match the where clause.  */
    allB2BAppPromoBlockHistoryRecords?: Maybe<Array<Maybe<B2BAppPromoBlockHistoryRecord>>>
    /**  Search for all B2BAppPromoBlock items which match the where clause.  */
    allB2BAppPromoBlocks?: Maybe<Array<Maybe<B2BAppPromoBlock>>>
    /**  Search for all B2BAppRoleHistoryRecord items which match the where clause.  */
    allB2BAppRoleHistoryRecords?: Maybe<Array<Maybe<B2BAppRoleHistoryRecord>>>
    /**  Search for all B2BAppRole items which match the where clause.  */
    allB2BAppRoles?: Maybe<Array<Maybe<B2BAppRole>>>
    /**  Search for all B2BApp items which match the where clause.  */
    allB2BApps?: Maybe<Array<Maybe<B2BApp>>>
    /**  Search for all B2CAppAccessRightHistoryRecord items which match the where clause.  */
    allB2CAppAccessRightHistoryRecords?: Maybe<Array<Maybe<B2CAppAccessRightHistoryRecord>>>
    /**  Search for all B2CAppAccessRight items which match the where clause.  */
    allB2CAppAccessRights?: Maybe<Array<Maybe<B2CAppAccessRight>>>
    /**  Search for all B2CAppBuildHistoryRecord items which match the where clause.  */
    allB2CAppBuildHistoryRecords?: Maybe<Array<Maybe<B2CAppBuildHistoryRecord>>>
    /**  Search for all B2CAppBuild items which match the where clause.  */
    allB2CAppBuilds?: Maybe<Array<Maybe<B2CAppBuild>>>
    /**  Search for all B2CAppHistoryRecord items which match the where clause.  */
    allB2CAppHistoryRecords?: Maybe<Array<Maybe<B2CAppHistoryRecord>>>
    /**  Search for all B2CAppMessageSettingHistoryRecord items which match the where clause.  */
    allB2CAppMessageSettingHistoryRecords?: Maybe<Array<Maybe<B2CAppMessageSettingHistoryRecord>>>
    /**  Search for all B2CAppMessageSetting items which match the where clause.  */
    allB2CAppMessageSettings?: Maybe<Array<Maybe<B2CAppMessageSetting>>>
    /**  Search for all B2CAppProperty items which match the where clause.  */
    allB2CAppProperties?: Maybe<Array<Maybe<B2CAppProperty>>>
    /**  Search for all B2CAppPropertyHistoryRecord items which match the where clause.  */
    allB2CAppPropertyHistoryRecords?: Maybe<Array<Maybe<B2CAppPropertyHistoryRecord>>>
    /**  Search for all B2CApp items which match the where clause.  */
    allB2CApps?: Maybe<Array<Maybe<B2CApp>>>
    /**  Search for all BankAccountHistoryRecord items which match the where clause.  */
    allBankAccountHistoryRecords?: Maybe<Array<Maybe<BankAccountHistoryRecord>>>
    /**  Search for all BankAccountReportHistoryRecord items which match the where clause.  */
    allBankAccountReportHistoryRecords?: Maybe<Array<Maybe<BankAccountReportHistoryRecord>>>
    /**  Search for all BankAccountReportTaskHistoryRecord items which match the where clause.  */
    allBankAccountReportTaskHistoryRecords?: Maybe<Array<Maybe<BankAccountReportTaskHistoryRecord>>>
    /**  Search for all BankAccountReportTask items which match the where clause.  */
    allBankAccountReportTasks?: Maybe<Array<Maybe<BankAccountReportTask>>>
    /**  Search for all BankAccountReport items which match the where clause.  */
    allBankAccountReports?: Maybe<Array<Maybe<BankAccountReport>>>
    /**  Search for all BankAccount items which match the where clause.  */
    allBankAccounts?: Maybe<Array<Maybe<BankAccount>>>
    /**  Search for all BankCategory items which match the where clause.  */
    allBankCategories?: Maybe<Array<Maybe<BankCategory>>>
    /**  Search for all BankCategoryHistoryRecord items which match the where clause.  */
    allBankCategoryHistoryRecords?: Maybe<Array<Maybe<BankCategoryHistoryRecord>>>
    /**  Search for all BankContractorAccountHistoryRecord items which match the where clause.  */
    allBankContractorAccountHistoryRecords?: Maybe<Array<Maybe<BankContractorAccountHistoryRecord>>>
    /**  Search for all BankContractorAccount items which match the where clause.  */
    allBankContractorAccounts?: Maybe<Array<Maybe<BankContractorAccount>>>
    /**  Search for all BankCostItemHistoryRecord items which match the where clause.  */
    allBankCostItemHistoryRecords?: Maybe<Array<Maybe<BankCostItemHistoryRecord>>>
    /**  Search for all BankCostItem items which match the where clause.  */
    allBankCostItems?: Maybe<Array<Maybe<BankCostItem>>>
    /**  Search for all BankIntegrationAccessRightHistoryRecord items which match the where clause.  */
    allBankIntegrationAccessRightHistoryRecords?: Maybe<Array<Maybe<BankIntegrationAccessRightHistoryRecord>>>
    /**  Search for all BankIntegrationAccessRight items which match the where clause.  */
    allBankIntegrationAccessRights?: Maybe<Array<Maybe<BankIntegrationAccessRight>>>
    /**  Search for all BankIntegrationAccountContextHistoryRecord items which match the where clause.  */
    allBankIntegrationAccountContextHistoryRecords?: Maybe<Array<Maybe<BankIntegrationAccountContextHistoryRecord>>>
    /**  Search for all BankIntegrationAccountContext items which match the where clause.  */
    allBankIntegrationAccountContexts?: Maybe<Array<Maybe<BankIntegrationAccountContext>>>
    /**  Search for all BankIntegrationHistoryRecord items which match the where clause.  */
    allBankIntegrationHistoryRecords?: Maybe<Array<Maybe<BankIntegrationHistoryRecord>>>
    /**  Search for all BankIntegrationOrganizationContextHistoryRecord items which match the where clause.  */
    allBankIntegrationOrganizationContextHistoryRecords?: Maybe<Array<Maybe<BankIntegrationOrganizationContextHistoryRecord>>>
    /**  Search for all BankIntegrationOrganizationContext items which match the where clause.  */
    allBankIntegrationOrganizationContexts?: Maybe<Array<Maybe<BankIntegrationOrganizationContext>>>
    /**  Search for all BankIntegration items which match the where clause.  */
    allBankIntegrations?: Maybe<Array<Maybe<BankIntegration>>>
    /**  Search for all BankSyncTaskHistoryRecord items which match the where clause.  */
    allBankSyncTaskHistoryRecords?: Maybe<Array<Maybe<BankSyncTaskHistoryRecord>>>
    /**  Search for all BankSyncTask items which match the where clause.  */
    allBankSyncTasks?: Maybe<Array<Maybe<BankSyncTask>>>
    /**  Search for all BankTransactionHistoryRecord items which match the where clause.  */
    allBankTransactionHistoryRecords?: Maybe<Array<Maybe<BankTransactionHistoryRecord>>>
    /**  Search for all BankTransaction items which match the where clause.  */
    allBankTransactions?: Maybe<Array<Maybe<BankTransaction>>>
    /**  Search for all BillingAccountHistoryRecord items which match the where clause.  */
    allBillingAccountHistoryRecords?: Maybe<Array<Maybe<BillingAccountHistoryRecord>>>
    /**  Search for all BillingAccount items which match the where clause.  */
    allBillingAccounts?: Maybe<Array<Maybe<BillingAccount>>>
    /**  Search for all BillingCategory items which match the where clause.  */
    allBillingCategories?: Maybe<Array<Maybe<BillingCategory>>>
    /**  Search for all BillingCategoryHistoryRecord items which match the where clause.  */
    allBillingCategoryHistoryRecords?: Maybe<Array<Maybe<BillingCategoryHistoryRecord>>>
    /**  Search for all BillingIntegrationAccessRightHistoryRecord items which match the where clause.  */
    allBillingIntegrationAccessRightHistoryRecords?: Maybe<Array<Maybe<BillingIntegrationAccessRightHistoryRecord>>>
    /**  Search for all BillingIntegrationAccessRight items which match the where clause.  */
    allBillingIntegrationAccessRights?: Maybe<Array<Maybe<BillingIntegrationAccessRight>>>
    /**  Search for all BillingIntegrationHistoryRecord items which match the where clause.  */
    allBillingIntegrationHistoryRecords?: Maybe<Array<Maybe<BillingIntegrationHistoryRecord>>>
    /**  Search for all BillingIntegrationOrganizationContextHistoryRecord items which match the where clause.  */
    allBillingIntegrationOrganizationContextHistoryRecords?: Maybe<Array<Maybe<BillingIntegrationOrganizationContextHistoryRecord>>>
    /**  Search for all BillingIntegrationOrganizationContext items which match the where clause.  */
    allBillingIntegrationOrganizationContexts?: Maybe<Array<Maybe<BillingIntegrationOrganizationContext>>>
    /**  Search for all BillingIntegrationProblem items which match the where clause.  */
    allBillingIntegrationProblems?: Maybe<Array<Maybe<BillingIntegrationProblem>>>
    /**  Search for all BillingIntegration items which match the where clause.  */
    allBillingIntegrations?: Maybe<Array<Maybe<BillingIntegration>>>
    /**  Search for all BillingProperty items which match the where clause.  */
    allBillingProperties?: Maybe<Array<Maybe<BillingProperty>>>
    /**  Search for all BillingPropertyHistoryRecord items which match the where clause.  */
    allBillingPropertyHistoryRecords?: Maybe<Array<Maybe<BillingPropertyHistoryRecord>>>
    /**  Search for all BillingReceiptFileHistoryRecord items which match the where clause.  */
    allBillingReceiptFileHistoryRecords?: Maybe<Array<Maybe<BillingReceiptFileHistoryRecord>>>
    /**  Search for all BillingReceiptFile items which match the where clause.  */
    allBillingReceiptFiles?: Maybe<Array<Maybe<BillingReceiptFile>>>
    /**  Search for all BillingReceiptHistoryRecord items which match the where clause.  */
    allBillingReceiptHistoryRecords?: Maybe<Array<Maybe<BillingReceiptHistoryRecord>>>
    /**  Search for all BillingReceipt items which match the where clause.  */
    allBillingReceipts?: Maybe<Array<Maybe<BillingReceipt>>>
    /**  Search for all BillingRecipientHistoryRecord items which match the where clause.  */
    allBillingRecipientHistoryRecords?: Maybe<Array<Maybe<BillingRecipientHistoryRecord>>>
    /**  Search for all BillingRecipient items which match the where clause.  */
    allBillingRecipients?: Maybe<Array<Maybe<BillingRecipient>>>
    /**  Search for all CallRecordFragmentHistoryRecord items which match the where clause.  */
    allCallRecordFragmentHistoryRecords?: Maybe<Array<Maybe<CallRecordFragmentHistoryRecord>>>
    /**  Search for all CallRecordFragment items which match the where clause.  */
    allCallRecordFragments?: Maybe<Array<Maybe<CallRecordFragment>>>
    /**  Search for all CallRecordHistoryRecord items which match the where clause.  */
    allCallRecordHistoryRecords?: Maybe<Array<Maybe<CallRecordHistoryRecord>>>
    /**  Search for all CallRecord items which match the where clause.  */
    allCallRecords?: Maybe<Array<Maybe<CallRecord>>>
    /**  Search for all ConfirmPhoneActionHistoryRecord items which match the where clause.  */
    allConfirmPhoneActionHistoryRecords?: Maybe<Array<Maybe<ConfirmPhoneActionHistoryRecord>>>
    /**  Search for all ConfirmPhoneAction items which match the where clause.  */
    allConfirmPhoneActions?: Maybe<Array<Maybe<ConfirmPhoneAction>>>
    /**  Search for all ContactExportTaskHistoryRecord items which match the where clause.  */
    allContactExportTaskHistoryRecords?: Maybe<Array<Maybe<ContactExportTaskHistoryRecord>>>
    /**  Search for all ContactExportTask items which match the where clause.  */
    allContactExportTasks?: Maybe<Array<Maybe<ContactExportTask>>>
    /**  Search for all ContactHistoryRecord items which match the where clause.  */
    allContactHistoryRecords?: Maybe<Array<Maybe<ContactHistoryRecord>>>
    /**  Search for all ContactRoleHistoryRecord items which match the where clause.  */
    allContactRoleHistoryRecords?: Maybe<Array<Maybe<ContactRoleHistoryRecord>>>
    /**  Search for all ContactRole items which match the where clause.  */
    allContactRoles?: Maybe<Array<Maybe<ContactRole>>>
    /**  Search for all Contact items which match the where clause.  */
    allContacts?: Maybe<Array<Maybe<Contact>>>
    /**  Search for all DocumentCategory items which match the where clause.  */
    allDocumentCategories?: Maybe<Array<Maybe<DocumentCategory>>>
    /**  Search for all DocumentCategoryHistoryRecord items which match the where clause.  */
    allDocumentCategoryHistoryRecords?: Maybe<Array<Maybe<DocumentCategoryHistoryRecord>>>
    /**  Search for all DocumentHistoryRecord items which match the where clause.  */
    allDocumentHistoryRecords?: Maybe<Array<Maybe<DocumentHistoryRecord>>>
    /**  Search for all Document items which match the where clause.  */
    allDocuments?: Maybe<Array<Maybe<Document>>>
    /**  Search for all ExternalTokenAccessRightHistoryRecord items which match the where clause.  */
    allExternalTokenAccessRightHistoryRecords?: Maybe<Array<Maybe<ExternalTokenAccessRightHistoryRecord>>>
    /**  Search for all ExternalTokenAccessRight items which match the where clause.  */
    allExternalTokenAccessRights?: Maybe<Array<Maybe<ExternalTokenAccessRight>>>
    /**  Search for all IncidentChange items which match the where clause.  */
    allIncidentChanges?: Maybe<Array<Maybe<IncidentChange>>>
    /**  Search for all IncidentClassifierHistoryRecord items which match the where clause.  */
    allIncidentClassifierHistoryRecords?: Maybe<Array<Maybe<IncidentClassifierHistoryRecord>>>
    /**  Search for all IncidentClassifierIncidentHistoryRecord items which match the where clause.  */
    allIncidentClassifierIncidentHistoryRecords?: Maybe<Array<Maybe<IncidentClassifierIncidentHistoryRecord>>>
    /**  Search for all IncidentClassifierIncident items which match the where clause.  */
    allIncidentClassifierIncidents?: Maybe<Array<Maybe<IncidentClassifierIncident>>>
    /**  Search for all IncidentClassifier items which match the where clause.  */
    allIncidentClassifiers?: Maybe<Array<Maybe<IncidentClassifier>>>
    /**  Search for all IncidentExportTaskHistoryRecord items which match the where clause.  */
    allIncidentExportTaskHistoryRecords?: Maybe<Array<Maybe<IncidentExportTaskHistoryRecord>>>
    /**  Search for all IncidentExportTask items which match the where clause.  */
    allIncidentExportTasks?: Maybe<Array<Maybe<IncidentExportTask>>>
    /**  Search for all IncidentHistoryRecord items which match the where clause.  */
    allIncidentHistoryRecords?: Maybe<Array<Maybe<IncidentHistoryRecord>>>
    /**  Search for all IncidentProperty items which match the where clause.  */
    allIncidentProperties?: Maybe<Array<Maybe<IncidentProperty>>>
    /**  Search for all IncidentPropertyHistoryRecord items which match the where clause.  */
    allIncidentPropertyHistoryRecords?: Maybe<Array<Maybe<IncidentPropertyHistoryRecord>>>
    /**  Search for all Incident items which match the where clause.  */
    allIncidents?: Maybe<Array<Maybe<Incident>>>
    /**  Search for all InvoiceHistoryRecord items which match the where clause.  */
    allInvoiceHistoryRecords?: Maybe<Array<Maybe<InvoiceHistoryRecord>>>
    /**  Search for all Invoice items which match the where clause.  */
    allInvoices?: Maybe<Array<Maybe<Invoice>>>
    /**  Search for all MarketCategory items which match the where clause.  */
    allMarketCategories?: Maybe<Array<Maybe<MarketCategory>>>
    /**  Search for all MarketCategoryHistoryRecord items which match the where clause.  */
    allMarketCategoryHistoryRecords?: Maybe<Array<Maybe<MarketCategoryHistoryRecord>>>
    /**  Search for all MarketItemFileHistoryRecord items which match the where clause.  */
    allMarketItemFileHistoryRecords?: Maybe<Array<Maybe<MarketItemFileHistoryRecord>>>
    /**  Search for all MarketItemFile items which match the where clause.  */
    allMarketItemFiles?: Maybe<Array<Maybe<MarketItemFile>>>
    /**  Search for all MarketItemHistoryRecord items which match the where clause.  */
    allMarketItemHistoryRecords?: Maybe<Array<Maybe<MarketItemHistoryRecord>>>
    /**  Search for all MarketItemPriceHistoryRecord items which match the where clause.  */
    allMarketItemPriceHistoryRecords?: Maybe<Array<Maybe<MarketItemPriceHistoryRecord>>>
    /**  Search for all MarketItemPrice items which match the where clause.  */
    allMarketItemPrices?: Maybe<Array<Maybe<MarketItemPrice>>>
    /**  Search for all MarketItem items which match the where clause.  */
    allMarketItems?: Maybe<Array<Maybe<MarketItem>>>
    /**  Search for all MarketPriceScopeHistoryRecord items which match the where clause.  */
    allMarketPriceScopeHistoryRecords?: Maybe<Array<Maybe<MarketPriceScopeHistoryRecord>>>
    /**  Search for all MarketPriceScope items which match the where clause.  */
    allMarketPriceScopes?: Maybe<Array<Maybe<MarketPriceScope>>>
    /**  Search for all MarketSettingHistoryRecord items which match the where clause.  */
    allMarketSettingHistoryRecords?: Maybe<Array<Maybe<MarketSettingHistoryRecord>>>
    /**  Search for all MarketSetting items which match the where clause.  */
    allMarketSettings?: Maybe<Array<Maybe<MarketSetting>>>
    /**  Search for all MessageAppBlackListHistoryRecord items which match the where clause.  */
    allMessageAppBlackListHistoryRecords?: Maybe<Array<Maybe<MessageAppBlackListHistoryRecord>>>
    /**  Search for all MessageAppBlackList items which match the where clause.  */
    allMessageAppBlackLists?: Maybe<Array<Maybe<MessageAppBlackList>>>
    /**  Search for all MessageBatchHistoryRecord items which match the where clause.  */
    allMessageBatchHistoryRecords?: Maybe<Array<Maybe<MessageBatchHistoryRecord>>>
    /**  Search for all MessageBatch items which match the where clause.  */
    allMessageBatches?: Maybe<Array<Maybe<MessageBatch>>>
    /**  Search for all MessageHistoryRecord items which match the where clause.  */
    allMessageHistoryRecords?: Maybe<Array<Maybe<MessageHistoryRecord>>>
    /**  Search for all MessageOrganizationBlackListHistoryRecord items which match the where clause.  */
    allMessageOrganizationBlackListHistoryRecords?: Maybe<Array<Maybe<MessageOrganizationBlackListHistoryRecord>>>
    /**  Search for all MessageOrganizationBlackList items which match the where clause.  */
    allMessageOrganizationBlackLists?: Maybe<Array<Maybe<MessageOrganizationBlackList>>>
    /**  Search for all MessageUserBlackListHistoryRecord items which match the where clause.  */
    allMessageUserBlackListHistoryRecords?: Maybe<Array<Maybe<MessageUserBlackListHistoryRecord>>>
    /**  Search for all MessageUserBlackList items which match the where clause.  */
    allMessageUserBlackLists?: Maybe<Array<Maybe<MessageUserBlackList>>>
    /**  Search for all Message items which match the where clause.  */
    allMessages?: Maybe<Array<Maybe<Message>>>
    /**  Search for all MeterHistoryRecord items which match the where clause.  */
    allMeterHistoryRecords?: Maybe<Array<Maybe<MeterHistoryRecord>>>
    /**  Search for all MeterReadingExportTaskHistoryRecord items which match the where clause.  */
    allMeterReadingExportTaskHistoryRecords?: Maybe<Array<Maybe<MeterReadingExportTaskHistoryRecord>>>
    /**  Search for all MeterReadingExportTask items which match the where clause.  */
    allMeterReadingExportTasks?: Maybe<Array<Maybe<MeterReadingExportTask>>>
    /**  Search for all MeterReadingFilterTemplateHistoryRecord items which match the where clause.  */
    allMeterReadingFilterTemplateHistoryRecords?: Maybe<Array<Maybe<MeterReadingFilterTemplateHistoryRecord>>>
    /**  Search for all MeterReadingFilterTemplate items which match the where clause.  */
    allMeterReadingFilterTemplates?: Maybe<Array<Maybe<MeterReadingFilterTemplate>>>
    /**  Search for all MeterReadingHistoryRecord items which match the where clause.  */
    allMeterReadingHistoryRecords?: Maybe<Array<Maybe<MeterReadingHistoryRecord>>>
    /**  Search for all MeterReadingSourceHistoryRecord items which match the where clause.  */
    allMeterReadingSourceHistoryRecords?: Maybe<Array<Maybe<MeterReadingSourceHistoryRecord>>>
    /**  Search for all MeterReadingSource items which match the where clause.  */
    allMeterReadingSources?: Maybe<Array<Maybe<MeterReadingSource>>>
    /**  Search for all MeterReading items which match the where clause.  */
    allMeterReadings?: Maybe<Array<Maybe<MeterReading>>>
    /**  Search for all MeterReadingsImportTaskHistoryRecord items which match the where clause.  */
    allMeterReadingsImportTaskHistoryRecords?: Maybe<Array<Maybe<MeterReadingsImportTaskHistoryRecord>>>
    /**  Search for all MeterReadingsImportTask items which match the where clause.  */
    allMeterReadingsImportTasks?: Maybe<Array<Maybe<MeterReadingsImportTask>>>
    /**  Search for all MeterReportingPeriodHistoryRecord items which match the where clause.  */
    allMeterReportingPeriodHistoryRecords?: Maybe<Array<Maybe<MeterReportingPeriodHistoryRecord>>>
    /**  Search for all MeterReportingPeriod items which match the where clause.  */
    allMeterReportingPeriods?: Maybe<Array<Maybe<MeterReportingPeriod>>>
    /**  Search for all MeterResourceHistoryRecord items which match the where clause.  */
    allMeterResourceHistoryRecords?: Maybe<Array<Maybe<MeterResourceHistoryRecord>>>
    /**  Search for all MeterResourceOwnerHistoryRecord items which match the where clause.  */
    allMeterResourceOwnerHistoryRecords?: Maybe<Array<Maybe<MeterResourceOwnerHistoryRecord>>>
    /**  Search for all MeterResourceOwner items which match the where clause.  */
    allMeterResourceOwners?: Maybe<Array<Maybe<MeterResourceOwner>>>
    /**  Search for all MeterResource items which match the where clause.  */
    allMeterResources?: Maybe<Array<Maybe<MeterResource>>>
    /**  Search for all Meter items which match the where clause.  */
    allMeters?: Maybe<Array<Maybe<Meter>>>
    allMiniApps?: Maybe<Array<MiniAppOutput>>
    /**  Search for all MobileFeatureConfigHistoryRecord items which match the where clause.  */
    allMobileFeatureConfigHistoryRecords?: Maybe<Array<Maybe<MobileFeatureConfigHistoryRecord>>>
    /**  Search for all MobileFeatureConfig items which match the where clause.  */
    allMobileFeatureConfigs?: Maybe<Array<Maybe<MobileFeatureConfig>>>
    /**  Search for all MultiPaymentHistoryRecord items which match the where clause.  */
    allMultiPaymentHistoryRecords?: Maybe<Array<Maybe<MultiPaymentHistoryRecord>>>
    /**  Search for all MultiPayment items which match the where clause.  */
    allMultiPayments?: Maybe<Array<Maybe<MultiPayment>>>
    /**  Search for all NewsItemHistoryRecord items which match the where clause.  */
    allNewsItemHistoryRecords?: Maybe<Array<Maybe<NewsItemHistoryRecord>>>
    /**  Search for all NewsItemRecipientsExportTaskHistoryRecord items which match the where clause.  */
    allNewsItemRecipientsExportTaskHistoryRecords?: Maybe<Array<Maybe<NewsItemRecipientsExportTaskHistoryRecord>>>
    /**  Search for all NewsItemRecipientsExportTask items which match the where clause.  */
    allNewsItemRecipientsExportTasks?: Maybe<Array<Maybe<NewsItemRecipientsExportTask>>>
    /**  Search for all NewsItemScopeHistoryRecord items which match the where clause.  */
    allNewsItemScopeHistoryRecords?: Maybe<Array<Maybe<NewsItemScopeHistoryRecord>>>
    /**  Search for all NewsItemScope items which match the where clause.  */
    allNewsItemScopes?: Maybe<Array<Maybe<NewsItemScope>>>
    /**  Search for all NewsItemSharingHistoryRecord items which match the where clause.  */
    allNewsItemSharingHistoryRecords?: Maybe<Array<Maybe<NewsItemSharingHistoryRecord>>>
    /**  Search for all NewsItemSharing items which match the where clause.  */
    allNewsItemSharings?: Maybe<Array<Maybe<NewsItemSharing>>>
    /**  Search for all NewsItemTemplateHistoryRecord items which match the where clause.  */
    allNewsItemTemplateHistoryRecords?: Maybe<Array<Maybe<NewsItemTemplateHistoryRecord>>>
    /**  Search for all NewsItemTemplate items which match the where clause.  */
    allNewsItemTemplates?: Maybe<Array<Maybe<NewsItemTemplate>>>
    /**  Search for all NewsItemUserReadHistoryRecord items which match the where clause.  */
    allNewsItemUserReadHistoryRecords?: Maybe<Array<Maybe<NewsItemUserReadHistoryRecord>>>
    /**  Search for all NewsItemUserRead items which match the where clause.  */
    allNewsItemUserReads?: Maybe<Array<Maybe<NewsItemUserRead>>>
    /**  Search for all NewsItem items which match the where clause.  */
    allNewsItems?: Maybe<Array<Maybe<NewsItem>>>
    /**  Search for all NotificationAnonymousSettingHistoryRecord items which match the where clause.  */
    allNotificationAnonymousSettingHistoryRecords?: Maybe<Array<Maybe<NotificationAnonymousSettingHistoryRecord>>>
    /**  Search for all NotificationAnonymousSetting items which match the where clause.  */
    allNotificationAnonymousSettings?: Maybe<Array<Maybe<NotificationAnonymousSetting>>>
    /**  Search for all NotificationUserSettingHistoryRecord items which match the where clause.  */
    allNotificationUserSettingHistoryRecords?: Maybe<Array<Maybe<NotificationUserSettingHistoryRecord>>>
    /**  Search for all NotificationUserSetting items which match the where clause.  */
    allNotificationUserSettings?: Maybe<Array<Maybe<NotificationUserSetting>>>
    /**  Search for all OidcClientHistoryRecord items which match the where clause.  */
    allOidcClientHistoryRecords?: Maybe<Array<Maybe<OidcClientHistoryRecord>>>
    /**  Search for all OidcClient items which match the where clause.  */
    allOidcClients?: Maybe<Array<Maybe<OidcClient>>>
    /**  Search for all OrganizationEmployeeHistoryRecord items which match the where clause.  */
    allOrganizationEmployeeHistoryRecords?: Maybe<Array<Maybe<OrganizationEmployeeHistoryRecord>>>
    /**  Search for all OrganizationEmployeeRoleHistoryRecord items which match the where clause.  */
    allOrganizationEmployeeRoleHistoryRecords?: Maybe<Array<Maybe<OrganizationEmployeeRoleHistoryRecord>>>
    /**  Search for all OrganizationEmployeeRole items which match the where clause.  */
    allOrganizationEmployeeRoles?: Maybe<Array<Maybe<OrganizationEmployeeRole>>>
    /**  Search for all OrganizationEmployeeSpecializationHistoryRecord items which match the where clause.  */
    allOrganizationEmployeeSpecializationHistoryRecords?: Maybe<Array<Maybe<OrganizationEmployeeSpecializationHistoryRecord>>>
    /**  Search for all OrganizationEmployeeSpecialization items which match the where clause.  */
    allOrganizationEmployeeSpecializations?: Maybe<Array<Maybe<OrganizationEmployeeSpecialization>>>
    /**  Search for all OrganizationEmployee items which match the where clause.  */
    allOrganizationEmployees?: Maybe<Array<Maybe<OrganizationEmployee>>>
    /**  Search for all OrganizationHistoryRecord items which match the where clause.  */
    allOrganizationHistoryRecords?: Maybe<Array<Maybe<OrganizationHistoryRecord>>>
    /**  Search for all OrganizationLinkHistoryRecord items which match the where clause.  */
    allOrganizationLinkHistoryRecords?: Maybe<Array<Maybe<OrganizationLinkHistoryRecord>>>
    /**  Search for all OrganizationLink items which match the where clause.  */
    allOrganizationLinks?: Maybe<Array<Maybe<OrganizationLink>>>
    /**  Search for all Organization items which match the where clause.  */
    allOrganizations?: Maybe<Array<Maybe<Organization>>>
    /**  Search for all PaymentHistoryRecord items which match the where clause.  */
    allPaymentHistoryRecords?: Maybe<Array<Maybe<PaymentHistoryRecord>>>
    /**  Search for all Payment items which match the where clause.  */
    allPayments?: Maybe<Array<Maybe<Payment>>>
    /**  Search for all PaymentsFilterTemplateHistoryRecord items which match the where clause.  */
    allPaymentsFilterTemplateHistoryRecords?: Maybe<Array<Maybe<PaymentsFilterTemplateHistoryRecord>>>
    /**  Search for all PaymentsFilterTemplate items which match the where clause.  */
    allPaymentsFilterTemplates?: Maybe<Array<Maybe<PaymentsFilterTemplate>>>
    /**  Search for all Property items which match the where clause.  */
    allProperties?: Maybe<Array<Maybe<Property>>>
    /**  Search for all PropertyHistoryRecord items which match the where clause.  */
    allPropertyHistoryRecords?: Maybe<Array<Maybe<PropertyHistoryRecord>>>
    /**  Search for all PropertyMeterHistoryRecord items which match the where clause.  */
    allPropertyMeterHistoryRecords?: Maybe<Array<Maybe<PropertyMeterHistoryRecord>>>
    /**  Search for all PropertyMeterReadingHistoryRecord items which match the where clause.  */
    allPropertyMeterReadingHistoryRecords?: Maybe<Array<Maybe<PropertyMeterReadingHistoryRecord>>>
    /**  Search for all PropertyMeterReading items which match the where clause.  */
    allPropertyMeterReadings?: Maybe<Array<Maybe<PropertyMeterReading>>>
    /**  Search for all PropertyMeter items which match the where clause.  */
    allPropertyMeters?: Maybe<Array<Maybe<PropertyMeter>>>
    /**  Search for all PropertyScopeHistoryRecord items which match the where clause.  */
    allPropertyScopeHistoryRecords?: Maybe<Array<Maybe<PropertyScopeHistoryRecord>>>
    /**  Search for all PropertyScopeOrganizationEmployeeHistoryRecord items which match the where clause.  */
    allPropertyScopeOrganizationEmployeeHistoryRecords?: Maybe<Array<Maybe<PropertyScopeOrganizationEmployeeHistoryRecord>>>
    /**  Search for all PropertyScopeOrganizationEmployee items which match the where clause.  */
    allPropertyScopeOrganizationEmployees?: Maybe<Array<Maybe<PropertyScopeOrganizationEmployee>>>
    /**  Search for all PropertyScopeProperty items which match the where clause.  */
    allPropertyScopeProperties?: Maybe<Array<Maybe<PropertyScopeProperty>>>
    /**  Search for all PropertyScopePropertyHistoryRecord items which match the where clause.  */
    allPropertyScopePropertyHistoryRecords?: Maybe<Array<Maybe<PropertyScopePropertyHistoryRecord>>>
    /**  Search for all PropertyScope items which match the where clause.  */
    allPropertyScopes?: Maybe<Array<Maybe<PropertyScope>>>
    /**  Search for all RecurrentPaymentContextHistoryRecord items which match the where clause.  */
    allRecurrentPaymentContextHistoryRecords?: Maybe<Array<Maybe<RecurrentPaymentContextHistoryRecord>>>
    /**  Search for all RecurrentPaymentContext items which match the where clause.  */
    allRecurrentPaymentContexts?: Maybe<Array<Maybe<RecurrentPaymentContext>>>
    /**  Search for all RecurrentPaymentHistoryRecord items which match the where clause.  */
    allRecurrentPaymentHistoryRecords?: Maybe<Array<Maybe<RecurrentPaymentHistoryRecord>>>
    /**  Search for all RecurrentPayment items which match the where clause.  */
    allRecurrentPayments?: Maybe<Array<Maybe<RecurrentPayment>>>
    /**  Search for all RemoteClientHistoryRecord items which match the where clause.  */
    allRemoteClientHistoryRecords?: Maybe<Array<Maybe<RemoteClientHistoryRecord>>>
    /**  Search for all RemoteClient items which match the where clause.  */
    allRemoteClients?: Maybe<Array<Maybe<RemoteClient>>>
    allResidentBillingReceipts?: Maybe<Array<Maybe<ResidentBillingReceiptOutput>>>
    allResidentBillingVirtualReceipts?: Maybe<Array<Maybe<ResidentBillingReceiptOutput>>>
    /**  Search for all ResidentHistoryRecord items which match the where clause.  */
    allResidentHistoryRecords?: Maybe<Array<Maybe<ResidentHistoryRecord>>>
    /**  Search for all Resident items which match the where clause.  */
    allResidents?: Maybe<Array<Maybe<Resident>>>
    /**  Search for all ServiceConsumerHistoryRecord items which match the where clause.  */
    allServiceConsumerHistoryRecords?: Maybe<Array<Maybe<ServiceConsumerHistoryRecord>>>
    /**  Search for all ServiceConsumer items which match the where clause.  */
    allServiceConsumers?: Maybe<Array<Maybe<ServiceConsumer>>>
    /**  Search for all ServiceSubscriptionHistoryRecord items which match the where clause.  */
    allServiceSubscriptionHistoryRecords?: Maybe<Array<Maybe<ServiceSubscriptionHistoryRecord>>>
    /**  Search for all ServiceSubscription items which match the where clause.  */
    allServiceSubscriptions?: Maybe<Array<Maybe<ServiceSubscription>>>
    /**  Search for all TelegramUserChatHistoryRecord items which match the where clause.  */
    allTelegramUserChatHistoryRecords?: Maybe<Array<Maybe<TelegramUserChatHistoryRecord>>>
    /**  Search for all TelegramUserChat items which match the where clause.  */
    allTelegramUserChats?: Maybe<Array<Maybe<TelegramUserChat>>>
    /**  Search for all TicketAutoAssignmentHistoryRecord items which match the where clause.  */
    allTicketAutoAssignmentHistoryRecords?: Maybe<Array<Maybe<TicketAutoAssignmentHistoryRecord>>>
    /**  Search for all TicketAutoAssignment items which match the where clause.  */
    allTicketAutoAssignments?: Maybe<Array<Maybe<TicketAutoAssignment>>>
    /**  Search for all TicketCategoryClassifierHistoryRecord items which match the where clause.  */
    allTicketCategoryClassifierHistoryRecords?: Maybe<Array<Maybe<TicketCategoryClassifierHistoryRecord>>>
    /**  Search for all TicketCategoryClassifier items which match the where clause.  */
    allTicketCategoryClassifiers?: Maybe<Array<Maybe<TicketCategoryClassifier>>>
    /**  Search for all TicketChange items which match the where clause.  */
    allTicketChanges?: Maybe<Array<Maybe<TicketChange>>>
    /**  Search for all TicketClassifierHistoryRecord items which match the where clause.  */
    allTicketClassifierHistoryRecords?: Maybe<Array<Maybe<TicketClassifierHistoryRecord>>>
    /**  Search for all TicketClassifier items which match the where clause.  */
    allTicketClassifiers?: Maybe<Array<Maybe<TicketClassifier>>>
    /**  Search for all TicketCommentFileHistoryRecord items which match the where clause.  */
    allTicketCommentFileHistoryRecords?: Maybe<Array<Maybe<TicketCommentFileHistoryRecord>>>
    /**  Search for all TicketCommentFile items which match the where clause.  */
    allTicketCommentFiles?: Maybe<Array<Maybe<TicketCommentFile>>>
    /**  Search for all TicketCommentHistoryRecord items which match the where clause.  */
    allTicketCommentHistoryRecords?: Maybe<Array<Maybe<TicketCommentHistoryRecord>>>
    /**  Search for all TicketComment items which match the where clause.  */
    allTicketComments?: Maybe<Array<Maybe<TicketComment>>>
    /**  Search for all TicketDocumentGenerationTaskHistoryRecord items which match the where clause.  */
    allTicketDocumentGenerationTaskHistoryRecords?: Maybe<Array<Maybe<TicketDocumentGenerationTaskHistoryRecord>>>
    /**  Search for all TicketDocumentGenerationTask items which match the where clause.  */
    allTicketDocumentGenerationTasks?: Maybe<Array<Maybe<TicketDocumentGenerationTask>>>
    /**  Search for all TicketExportTaskHistoryRecord items which match the where clause.  */
    allTicketExportTaskHistoryRecords?: Maybe<Array<Maybe<TicketExportTaskHistoryRecord>>>
    /**  Search for all TicketExportTask items which match the where clause.  */
    allTicketExportTasks?: Maybe<Array<Maybe<TicketExportTask>>>
    /**  Search for all TicketFileHistoryRecord items which match the where clause.  */
    allTicketFileHistoryRecords?: Maybe<Array<Maybe<TicketFileHistoryRecord>>>
    /**  Search for all TicketFile items which match the where clause.  */
    allTicketFiles?: Maybe<Array<Maybe<TicketFile>>>
    /**  Search for all TicketFilterTemplateHistoryRecord items which match the where clause.  */
    allTicketFilterTemplateHistoryRecords?: Maybe<Array<Maybe<TicketFilterTemplateHistoryRecord>>>
    /**  Search for all TicketFilterTemplate items which match the where clause.  */
    allTicketFilterTemplates?: Maybe<Array<Maybe<TicketFilterTemplate>>>
    /**  Search for all TicketHistoryRecord items which match the where clause.  */
    allTicketHistoryRecords?: Maybe<Array<Maybe<TicketHistoryRecord>>>
    /**  Search for all TicketOrganizationSettingHistoryRecord items which match the where clause.  */
    allTicketOrganizationSettingHistoryRecords?: Maybe<Array<Maybe<TicketOrganizationSettingHistoryRecord>>>
    /**  Search for all TicketOrganizationSetting items which match the where clause.  */
    allTicketOrganizationSettings?: Maybe<Array<Maybe<TicketOrganizationSetting>>>
    /**  Search for all TicketPlaceClassifierHistoryRecord items which match the where clause.  */
    allTicketPlaceClassifierHistoryRecords?: Maybe<Array<Maybe<TicketPlaceClassifierHistoryRecord>>>
    /**  Search for all TicketPlaceClassifier items which match the where clause.  */
    allTicketPlaceClassifiers?: Maybe<Array<Maybe<TicketPlaceClassifier>>>
    /**  Search for all TicketProblemClassifierHistoryRecord items which match the where clause.  */
    allTicketProblemClassifierHistoryRecords?: Maybe<Array<Maybe<TicketProblemClassifierHistoryRecord>>>
    /**  Search for all TicketProblemClassifier items which match the where clause.  */
    allTicketProblemClassifiers?: Maybe<Array<Maybe<TicketProblemClassifier>>>
    /**  Search for all TicketPropertyHintHistoryRecord items which match the where clause.  */
    allTicketPropertyHintHistoryRecords?: Maybe<Array<Maybe<TicketPropertyHintHistoryRecord>>>
    /**  Search for all TicketPropertyHintProperty items which match the where clause.  */
    allTicketPropertyHintProperties?: Maybe<Array<Maybe<TicketPropertyHintProperty>>>
    /**  Search for all TicketPropertyHintPropertyHistoryRecord items which match the where clause.  */
    allTicketPropertyHintPropertyHistoryRecords?: Maybe<Array<Maybe<TicketPropertyHintPropertyHistoryRecord>>>
    /**  Search for all TicketPropertyHint items which match the where clause.  */
    allTicketPropertyHints?: Maybe<Array<Maybe<TicketPropertyHint>>>
    /**  Search for all TicketSourceHistoryRecord items which match the where clause.  */
    allTicketSourceHistoryRecords?: Maybe<Array<Maybe<TicketSourceHistoryRecord>>>
    /**  Search for all TicketSource items which match the where clause.  */
    allTicketSources?: Maybe<Array<Maybe<TicketSource>>>
    /**  Search for all TicketStatusHistoryRecord items which match the where clause.  */
    allTicketStatusHistoryRecords?: Maybe<Array<Maybe<TicketStatusHistoryRecord>>>
    /**  Search for all TicketStatus items which match the where clause.  */
    allTicketStatuses?: Maybe<Array<Maybe<TicketStatus>>>
    /**  Search for all Ticket items which match the where clause.  */
    allTickets?: Maybe<Array<Maybe<Ticket>>>
    /**  Search for all TourStepHistoryRecord items which match the where clause.  */
    allTourStepHistoryRecords?: Maybe<Array<Maybe<TourStepHistoryRecord>>>
    /**  Search for all TourStep items which match the where clause.  */
    allTourSteps?: Maybe<Array<Maybe<TourStep>>>
    /**  Search for all UserExternalIdentity items which match the where clause.  */
    allUserExternalIdentities?: Maybe<Array<Maybe<UserExternalIdentity>>>
    /**  Search for all UserExternalIdentityHistoryRecord items which match the where clause.  */
    allUserExternalIdentityHistoryRecords?: Maybe<Array<Maybe<UserExternalIdentityHistoryRecord>>>
    /**  Search for all UserFavoriteTicketHistoryRecord items which match the where clause.  */
    allUserFavoriteTicketHistoryRecords?: Maybe<Array<Maybe<UserFavoriteTicketHistoryRecord>>>
    /**  Search for all UserFavoriteTicket items which match the where clause.  */
    allUserFavoriteTickets?: Maybe<Array<Maybe<UserFavoriteTicket>>>
    /**  Search for all UserHelpRequestFileHistoryRecord items which match the where clause.  */
    allUserHelpRequestFileHistoryRecords?: Maybe<Array<Maybe<UserHelpRequestFileHistoryRecord>>>
    /**  Search for all UserHelpRequestFile items which match the where clause.  */
    allUserHelpRequestFiles?: Maybe<Array<Maybe<UserHelpRequestFile>>>
    /**  Search for all UserHelpRequestHistoryRecord items which match the where clause.  */
    allUserHelpRequestHistoryRecords?: Maybe<Array<Maybe<UserHelpRequestHistoryRecord>>>
    /**  Search for all UserHelpRequest items which match the where clause.  */
    allUserHelpRequests?: Maybe<Array<Maybe<UserHelpRequest>>>
    /**  Search for all UserHistoryRecord items which match the where clause.  */
    allUserHistoryRecords?: Maybe<Array<Maybe<UserHistoryRecord>>>
    /**  Search for all UserRightsSetHistoryRecord items which match the where clause.  */
    allUserRightsSetHistoryRecords?: Maybe<Array<Maybe<UserRightsSetHistoryRecord>>>
    /**  Search for all UserRightsSet items which match the where clause.  */
    allUserRightsSets?: Maybe<Array<Maybe<UserRightsSet>>>
    /**  Search for all UserTicketCommentReadTimeHistoryRecord items which match the where clause.  */
    allUserTicketCommentReadTimeHistoryRecords?: Maybe<Array<Maybe<UserTicketCommentReadTimeHistoryRecord>>>
    /**  Search for all UserTicketCommentReadTime items which match the where clause.  */
    allUserTicketCommentReadTimes?: Maybe<Array<Maybe<UserTicketCommentReadTime>>>
    /**  Search for all User items which match the where clause.  */
    allUsers?: Maybe<Array<Maybe<User>>>
    /**  Search for all WebhookHistoryRecord items which match the where clause.  */
    allWebhookHistoryRecords?: Maybe<Array<Maybe<WebhookHistoryRecord>>>
    /**  Search for all WebhookSubscriptionHistoryRecord items which match the where clause.  */
    allWebhookSubscriptionHistoryRecords?: Maybe<Array<Maybe<WebhookSubscriptionHistoryRecord>>>
    /**  Search for all WebhookSubscription items which match the where clause.  */
    allWebhookSubscriptions?: Maybe<Array<Maybe<WebhookSubscription>>>
    /**  Search for all Webhook items which match the where clause.  */
    allWebhooks?: Maybe<Array<Maybe<Webhook>>>
    /** The version of the Keystone application serving this API. */
    appVersion?: Maybe<Scalars['String']['output']>
    authenticatedUser?: Maybe<User>
    calculateFeeForReceipt?: Maybe<CalculateFeeForReceiptOutput>
    /**
   * Using an action token with a verified phone number, checks whether a user with the specified type (resident, staff, service) is registered.
   * As a result, information about the completion of some important fields (name, email, phone, password) is also returned.
   *
   *
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "code": "NOT_FOUND",
   *   "type": "TOKEN_NOT_FOUND",
   *   "message": "Token not found"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "DV_VERSION_MISMATCH",
   *   "message": "Wrong value for data version number"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "WRONG_FORMAT",
   *   "message": "Invalid format of \"sender\" field value",
   *   "correctExample": "{ \"dv\": 1, \"fingerprint\": \"uniq-device-or-container-id\" }"
   * }`
   */
    checkUserExistence?: Maybe<CheckUserExistenceOutput>
    exportPaymentsToExcel?: Maybe<ExportPaymentsToExcelOutput>
    exportPropertiesToExcel?: Maybe<ExportPropertiesToExcelOutput>
    /**
   * Export of property meters readings. A link to the file is returned
   *
   *
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "DV_VERSION_MISMATCH",
   *   "message": "Wrong value for data version number"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "WRONG_FORMAT",
   *   "message": "Invalid format of \"sender\" field value",
   *   "correctExample": "{ \"dv\": 1, \"fingerprint\": \"uniq-device-or-container-id\" }"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "NOTHING_TO_EXPORT",
   *   "message": "Could not found property meter readings to export for specified organization",
   *   "messageForUser": "No meter readings to export for specified organization"
   * }`
   */
    exportPropertyMeterReadings?: Maybe<ExportPropertyMeterReadingsOutput>
    exportPropertyScopesToExcel?: Maybe<ExportPropertyScopeToExcelOutput>
    exportTicketAnalyticsToExcel?: Maybe<ExportTicketAnalyticsToExcelOutput>
    findOrganizationsByAddress?: Maybe<Array<Maybe<FindOrganizationByAddressOutput>>>
    generatePaymentLink?: Maybe<GeneratePaymentLinkOutput>
    /**
   * To get a token for a specific user, you need to call this query, specifying the required integration type and userId in the parameters. To pass the rights check, you need to request on behalf of the service user, and also have an entry in the ExternalTokenAccessRight table that regulates access to tokens of different integrations
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "code": "INTERNAL_ERROR",
   *   "type": "REFRESH_TOKEN_EXPIRED",
   *   "message": "SBBOL user refreshToken expired"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "INVALID_USER_ID",
   *   "message": "Received userId must be uuid"
   * }`
   *
   * `{
   *   "code": "INTERNAL_ERROR",
   *   "type": "ERROR_GETTING_ACCESS_TOKEN",
   *   "message": "Unresolved error in getAccessTokenForUser"
   * }`
   */
    getAccessTokenByUserId?: Maybe<GetAccessTokenByUserIdOutput>
    getNewsItemsRecipientsCounters?: Maybe<GetNewsItemsRecipientsCountersOutput>
    getNewsSharingRecipients?: Maybe<Array<Maybe<GetNewsSharingRecipientsOutput>>>
    getNewsSharingRecipientsCounters?: Maybe<GetNewsSharingRecipientsCountersOutput>
    getOverviewDashboard?: Maybe<GetOverviewDashboardOutput>
    /**
   * Returns phone number information from ConfirmPhoneAction, that matches provided search conditions
   *
   *
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "CAPTCHA_CHECK_FAILED",
   *   "message": "Failed to check CAPTCHA",
   *   "messageForUser": "The operation could not be completed. Please update the application"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "UNABLE_TO_FIND_CONFIRM_PHONE_ACTION",
   *   "message": "Confirm phone action was expired or it could not be found. Try to initiate phone confirmation again",
   *   "messageForUser": "Unable to verify your phone number. Try again or ask our support"
   * }`
   */
    getPhoneByConfirmPhoneActionToken?: Maybe<GetPhoneByConfirmPhoneActionTokenOutput>
    getResidentExistenceByPhoneAndAddress?: Maybe<GetResidentExistenceByPhoneAndAddressOutput>
    predictTicketClassification?: Maybe<TicketClassifier>
    /**
   * Returns id of BankCostItem corresponding to specified payment purpose string
   *
   * Matching is performed by empirical model, implemented in external microservice "condo-classifier-api"
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "code": "INTERNAL_ERROR",
   *   "type": "NOT_FOUND",
   *   "message": "Bank cost item not found"
   * }`
   *
   * `{
   *   "code": "INTERNAL_ERROR",
   *   "type": "NOT_FOUND",
   *   "message": "Bank cost item isOutcome field not equal isOutcome field from transaction"
   * }`
   *
   * `{
   *   "code": "INTERNAL_ERROR",
   *   "type": "NOT_FOUND",
   *   "message": "ML server response is not successful"
   * }`
   *
   * `{
   *   "code": "INTERNAL_ERROR",
   *   "type": "NOT_FOUND",
   *   "message": "ML_SPACE_TRANSACTION_CLASSIFIER env variable needs to have endpoint, authKey, workspace"
   * }`
   */
    predictTransactionClassification?: Maybe<PredictTransactionClassificationOutput>
    suggestServiceProvider?: Maybe<Array<Maybe<SuggestServiceProviderOutput>>>
    ticketAnalyticsReport?: Maybe<TicketAnalyticsReportOutput>
    ticketReportWidgetData?: Maybe<TicketReportWidgetOutput>
}


export type QueryAcquiringIntegrationArgs = {
    where: AcquiringIntegrationWhereUniqueInput
}


export type QueryAcquiringIntegrationAccessRightArgs = {
    where: AcquiringIntegrationAccessRightWhereUniqueInput
}


export type QueryAcquiringIntegrationAccessRightHistoryRecordArgs = {
    where: AcquiringIntegrationAccessRightHistoryRecordWhereUniqueInput
}


export type QueryAcquiringIntegrationContextArgs = {
    where: AcquiringIntegrationContextWhereUniqueInput
}


export type QueryAcquiringIntegrationContextHistoryRecordArgs = {
    where: AcquiringIntegrationContextHistoryRecordWhereUniqueInput
}


export type QueryAcquiringIntegrationHistoryRecordArgs = {
    where: AcquiringIntegrationHistoryRecordWhereUniqueInput
}


export type QueryB2BAppArgs = {
    where: B2BAppWhereUniqueInput
}


export type QueryB2BAppAccessRightArgs = {
    where: B2BAppAccessRightWhereUniqueInput
}


export type QueryB2BAppAccessRightHistoryRecordArgs = {
    where: B2BAppAccessRightHistoryRecordWhereUniqueInput
}


export type QueryB2BAppAccessRightSetArgs = {
    where: B2BAppAccessRightSetWhereUniqueInput
}


export type QueryB2BAppAccessRightSetHistoryRecordArgs = {
    where: B2BAppAccessRightSetHistoryRecordWhereUniqueInput
}


export type QueryB2BAppContextArgs = {
    where: B2BAppContextWhereUniqueInput
}


export type QueryB2BAppContextHistoryRecordArgs = {
    where: B2BAppContextHistoryRecordWhereUniqueInput
}


export type QueryB2BAppHistoryRecordArgs = {
    where: B2BAppHistoryRecordWhereUniqueInput
}


export type QueryB2BAppNewsSharingConfigArgs = {
    where: B2BAppNewsSharingConfigWhereUniqueInput
}


export type QueryB2BAppNewsSharingConfigHistoryRecordArgs = {
    where: B2BAppNewsSharingConfigHistoryRecordWhereUniqueInput
}


export type QueryB2BAppPermissionArgs = {
    where: B2BAppPermissionWhereUniqueInput
}


export type QueryB2BAppPermissionHistoryRecordArgs = {
    where: B2BAppPermissionHistoryRecordWhereUniqueInput
}


export type QueryB2BAppPromoBlockArgs = {
    where: B2BAppPromoBlockWhereUniqueInput
}


export type QueryB2BAppPromoBlockHistoryRecordArgs = {
    where: B2BAppPromoBlockHistoryRecordWhereUniqueInput
}


export type QueryB2BAppRoleArgs = {
    where: B2BAppRoleWhereUniqueInput
}


export type QueryB2BAppRoleHistoryRecordArgs = {
    where: B2BAppRoleHistoryRecordWhereUniqueInput
}


export type QueryB2CAppArgs = {
    where: B2CAppWhereUniqueInput
}


export type QueryB2CAppAccessRightArgs = {
    where: B2CAppAccessRightWhereUniqueInput
}


export type QueryB2CAppAccessRightHistoryRecordArgs = {
    where: B2CAppAccessRightHistoryRecordWhereUniqueInput
}


export type QueryB2CAppBuildArgs = {
    where: B2CAppBuildWhereUniqueInput
}


export type QueryB2CAppBuildHistoryRecordArgs = {
    where: B2CAppBuildHistoryRecordWhereUniqueInput
}


export type QueryB2CAppHistoryRecordArgs = {
    where: B2CAppHistoryRecordWhereUniqueInput
}


export type QueryB2CAppMessageSettingArgs = {
    where: B2CAppMessageSettingWhereUniqueInput
}


export type QueryB2CAppMessageSettingHistoryRecordArgs = {
    where: B2CAppMessageSettingHistoryRecordWhereUniqueInput
}


export type QueryB2CAppPropertyArgs = {
    where: B2CAppPropertyWhereUniqueInput
}


export type QueryB2CAppPropertyHistoryRecordArgs = {
    where: B2CAppPropertyHistoryRecordWhereUniqueInput
}


export type QueryBankAccountArgs = {
    where: BankAccountWhereUniqueInput
}


export type QueryBankAccountHistoryRecordArgs = {
    where: BankAccountHistoryRecordWhereUniqueInput
}


export type QueryBankAccountReportArgs = {
    where: BankAccountReportWhereUniqueInput
}


export type QueryBankAccountReportHistoryRecordArgs = {
    where: BankAccountReportHistoryRecordWhereUniqueInput
}


export type QueryBankAccountReportTaskArgs = {
    where: BankAccountReportTaskWhereUniqueInput
}


export type QueryBankAccountReportTaskHistoryRecordArgs = {
    where: BankAccountReportTaskHistoryRecordWhereUniqueInput
}


export type QueryBankCategoryArgs = {
    where: BankCategoryWhereUniqueInput
}


export type QueryBankCategoryHistoryRecordArgs = {
    where: BankCategoryHistoryRecordWhereUniqueInput
}


export type QueryBankContractorAccountArgs = {
    where: BankContractorAccountWhereUniqueInput
}


export type QueryBankContractorAccountHistoryRecordArgs = {
    where: BankContractorAccountHistoryRecordWhereUniqueInput
}


export type QueryBankCostItemArgs = {
    where: BankCostItemWhereUniqueInput
}


export type QueryBankCostItemHistoryRecordArgs = {
    where: BankCostItemHistoryRecordWhereUniqueInput
}


export type QueryBankIntegrationArgs = {
    where: BankIntegrationWhereUniqueInput
}


export type QueryBankIntegrationAccessRightArgs = {
    where: BankIntegrationAccessRightWhereUniqueInput
}


export type QueryBankIntegrationAccessRightHistoryRecordArgs = {
    where: BankIntegrationAccessRightHistoryRecordWhereUniqueInput
}


export type QueryBankIntegrationAccountContextArgs = {
    where: BankIntegrationAccountContextWhereUniqueInput
}


export type QueryBankIntegrationAccountContextHistoryRecordArgs = {
    where: BankIntegrationAccountContextHistoryRecordWhereUniqueInput
}


export type QueryBankIntegrationHistoryRecordArgs = {
    where: BankIntegrationHistoryRecordWhereUniqueInput
}


export type QueryBankIntegrationOrganizationContextArgs = {
    where: BankIntegrationOrganizationContextWhereUniqueInput
}


export type QueryBankIntegrationOrganizationContextHistoryRecordArgs = {
    where: BankIntegrationOrganizationContextHistoryRecordWhereUniqueInput
}


export type QueryBankSyncTaskArgs = {
    where: BankSyncTaskWhereUniqueInput
}


export type QueryBankSyncTaskHistoryRecordArgs = {
    where: BankSyncTaskHistoryRecordWhereUniqueInput
}


export type QueryBankTransactionArgs = {
    where: BankTransactionWhereUniqueInput
}


export type QueryBankTransactionHistoryRecordArgs = {
    where: BankTransactionHistoryRecordWhereUniqueInput
}


export type QueryBillingAccountArgs = {
    where: BillingAccountWhereUniqueInput
}


export type QueryBillingAccountHistoryRecordArgs = {
    where: BillingAccountHistoryRecordWhereUniqueInput
}


export type QueryBillingCategoryArgs = {
    where: BillingCategoryWhereUniqueInput
}


export type QueryBillingCategoryHistoryRecordArgs = {
    where: BillingCategoryHistoryRecordWhereUniqueInput
}


export type QueryBillingIntegrationArgs = {
    where: BillingIntegrationWhereUniqueInput
}


export type QueryBillingIntegrationAccessRightArgs = {
    where: BillingIntegrationAccessRightWhereUniqueInput
}


export type QueryBillingIntegrationAccessRightHistoryRecordArgs = {
    where: BillingIntegrationAccessRightHistoryRecordWhereUniqueInput
}


export type QueryBillingIntegrationHistoryRecordArgs = {
    where: BillingIntegrationHistoryRecordWhereUniqueInput
}


export type QueryBillingIntegrationOrganizationContextArgs = {
    where: BillingIntegrationOrganizationContextWhereUniqueInput
}


export type QueryBillingIntegrationOrganizationContextHistoryRecordArgs = {
    where: BillingIntegrationOrganizationContextHistoryRecordWhereUniqueInput
}


export type QueryBillingIntegrationProblemArgs = {
    where: BillingIntegrationProblemWhereUniqueInput
}


export type QueryBillingPropertyArgs = {
    where: BillingPropertyWhereUniqueInput
}


export type QueryBillingPropertyHistoryRecordArgs = {
    where: BillingPropertyHistoryRecordWhereUniqueInput
}


export type QueryBillingReceiptArgs = {
    where: BillingReceiptWhereUniqueInput
}


export type QueryBillingReceiptFileArgs = {
    where: BillingReceiptFileWhereUniqueInput
}


export type QueryBillingReceiptFileHistoryRecordArgs = {
    where: BillingReceiptFileHistoryRecordWhereUniqueInput
}


export type QueryBillingReceiptHistoryRecordArgs = {
    where: BillingReceiptHistoryRecordWhereUniqueInput
}


export type QueryBillingRecipientArgs = {
    where: BillingRecipientWhereUniqueInput
}


export type QueryBillingRecipientHistoryRecordArgs = {
    where: BillingRecipientHistoryRecordWhereUniqueInput
}


export type QueryCallRecordArgs = {
    where: CallRecordWhereUniqueInput
}


export type QueryCallRecordFragmentArgs = {
    where: CallRecordFragmentWhereUniqueInput
}


export type QueryCallRecordFragmentHistoryRecordArgs = {
    where: CallRecordFragmentHistoryRecordWhereUniqueInput
}


export type QueryCallRecordHistoryRecordArgs = {
    where: CallRecordHistoryRecordWhereUniqueInput
}


export type QueryConfirmPhoneActionArgs = {
    where: ConfirmPhoneActionWhereUniqueInput
}


export type QueryConfirmPhoneActionHistoryRecordArgs = {
    where: ConfirmPhoneActionHistoryRecordWhereUniqueInput
}


export type QueryContactArgs = {
    where: ContactWhereUniqueInput
}


export type QueryContactExportTaskArgs = {
    where: ContactExportTaskWhereUniqueInput
}


export type QueryContactExportTaskHistoryRecordArgs = {
    where: ContactExportTaskHistoryRecordWhereUniqueInput
}


export type QueryContactHistoryRecordArgs = {
    where: ContactHistoryRecordWhereUniqueInput
}


export type QueryContactRoleArgs = {
    where: ContactRoleWhereUniqueInput
}


export type QueryContactRoleHistoryRecordArgs = {
    where: ContactRoleHistoryRecordWhereUniqueInput
}


export type QueryDocumentArgs = {
    where: DocumentWhereUniqueInput
}


export type QueryDocumentCategoryArgs = {
    where: DocumentCategoryWhereUniqueInput
}


export type QueryDocumentCategoryHistoryRecordArgs = {
    where: DocumentCategoryHistoryRecordWhereUniqueInput
}


export type QueryDocumentHistoryRecordArgs = {
    where: DocumentHistoryRecordWhereUniqueInput
}


export type QueryExternalTokenAccessRightArgs = {
    where: ExternalTokenAccessRightWhereUniqueInput
}


export type QueryExternalTokenAccessRightHistoryRecordArgs = {
    where: ExternalTokenAccessRightHistoryRecordWhereUniqueInput
}


export type QueryIncidentArgs = {
    where: IncidentWhereUniqueInput
}


export type QueryIncidentChangeArgs = {
    where: IncidentChangeWhereUniqueInput
}


export type QueryIncidentClassifierArgs = {
    where: IncidentClassifierWhereUniqueInput
}


export type QueryIncidentClassifierHistoryRecordArgs = {
    where: IncidentClassifierHistoryRecordWhereUniqueInput
}


export type QueryIncidentClassifierIncidentArgs = {
    where: IncidentClassifierIncidentWhereUniqueInput
}


export type QueryIncidentClassifierIncidentHistoryRecordArgs = {
    where: IncidentClassifierIncidentHistoryRecordWhereUniqueInput
}


export type QueryIncidentExportTaskArgs = {
    where: IncidentExportTaskWhereUniqueInput
}


export type QueryIncidentExportTaskHistoryRecordArgs = {
    where: IncidentExportTaskHistoryRecordWhereUniqueInput
}


export type QueryIncidentHistoryRecordArgs = {
    where: IncidentHistoryRecordWhereUniqueInput
}


export type QueryIncidentPropertyArgs = {
    where: IncidentPropertyWhereUniqueInput
}


export type QueryIncidentPropertyHistoryRecordArgs = {
    where: IncidentPropertyHistoryRecordWhereUniqueInput
}


export type QueryInvoiceArgs = {
    where: InvoiceWhereUniqueInput
}


export type QueryInvoiceHistoryRecordArgs = {
    where: InvoiceHistoryRecordWhereUniqueInput
}


export type QueryMarketCategoryArgs = {
    where: MarketCategoryWhereUniqueInput
}


export type QueryMarketCategoryHistoryRecordArgs = {
    where: MarketCategoryHistoryRecordWhereUniqueInput
}


export type QueryMarketItemArgs = {
    where: MarketItemWhereUniqueInput
}


export type QueryMarketItemFileArgs = {
    where: MarketItemFileWhereUniqueInput
}


export type QueryMarketItemFileHistoryRecordArgs = {
    where: MarketItemFileHistoryRecordWhereUniqueInput
}


export type QueryMarketItemHistoryRecordArgs = {
    where: MarketItemHistoryRecordWhereUniqueInput
}


export type QueryMarketItemPriceArgs = {
    where: MarketItemPriceWhereUniqueInput
}


export type QueryMarketItemPriceHistoryRecordArgs = {
    where: MarketItemPriceHistoryRecordWhereUniqueInput
}


export type QueryMarketPriceScopeArgs = {
    where: MarketPriceScopeWhereUniqueInput
}


export type QueryMarketPriceScopeHistoryRecordArgs = {
    where: MarketPriceScopeHistoryRecordWhereUniqueInput
}


export type QueryMarketSettingArgs = {
    where: MarketSettingWhereUniqueInput
}


export type QueryMarketSettingHistoryRecordArgs = {
    where: MarketSettingHistoryRecordWhereUniqueInput
}


export type QueryMessageArgs = {
    where: MessageWhereUniqueInput
}


export type QueryMessageAppBlackListArgs = {
    where: MessageAppBlackListWhereUniqueInput
}


export type QueryMessageAppBlackListHistoryRecordArgs = {
    where: MessageAppBlackListHistoryRecordWhereUniqueInput
}


export type QueryMessageBatchArgs = {
    where: MessageBatchWhereUniqueInput
}


export type QueryMessageBatchHistoryRecordArgs = {
    where: MessageBatchHistoryRecordWhereUniqueInput
}


export type QueryMessageHistoryRecordArgs = {
    where: MessageHistoryRecordWhereUniqueInput
}


export type QueryMessageOrganizationBlackListArgs = {
    where: MessageOrganizationBlackListWhereUniqueInput
}


export type QueryMessageOrganizationBlackListHistoryRecordArgs = {
    where: MessageOrganizationBlackListHistoryRecordWhereUniqueInput
}


export type QueryMessageUserBlackListArgs = {
    where: MessageUserBlackListWhereUniqueInput
}


export type QueryMessageUserBlackListHistoryRecordArgs = {
    where: MessageUserBlackListHistoryRecordWhereUniqueInput
}


export type QueryMeterArgs = {
    where: MeterWhereUniqueInput
}


export type QueryMeterHistoryRecordArgs = {
    where: MeterHistoryRecordWhereUniqueInput
}


export type QueryMeterReadingArgs = {
    where: MeterReadingWhereUniqueInput
}


export type QueryMeterReadingExportTaskArgs = {
    where: MeterReadingExportTaskWhereUniqueInput
}


export type QueryMeterReadingExportTaskHistoryRecordArgs = {
    where: MeterReadingExportTaskHistoryRecordWhereUniqueInput
}


export type QueryMeterReadingFilterTemplateArgs = {
    where: MeterReadingFilterTemplateWhereUniqueInput
}


export type QueryMeterReadingFilterTemplateHistoryRecordArgs = {
    where: MeterReadingFilterTemplateHistoryRecordWhereUniqueInput
}


export type QueryMeterReadingHistoryRecordArgs = {
    where: MeterReadingHistoryRecordWhereUniqueInput
}


export type QueryMeterReadingSourceArgs = {
    where: MeterReadingSourceWhereUniqueInput
}


export type QueryMeterReadingSourceHistoryRecordArgs = {
    where: MeterReadingSourceHistoryRecordWhereUniqueInput
}


export type QueryMeterReadingsImportTaskArgs = {
    where: MeterReadingsImportTaskWhereUniqueInput
}


export type QueryMeterReadingsImportTaskHistoryRecordArgs = {
    where: MeterReadingsImportTaskHistoryRecordWhereUniqueInput
}


export type QueryMeterReportingPeriodArgs = {
    where: MeterReportingPeriodWhereUniqueInput
}


export type QueryMeterReportingPeriodHistoryRecordArgs = {
    where: MeterReportingPeriodHistoryRecordWhereUniqueInput
}


export type QueryMeterResourceArgs = {
    where: MeterResourceWhereUniqueInput
}


export type QueryMeterResourceHistoryRecordArgs = {
    where: MeterResourceHistoryRecordWhereUniqueInput
}


export type QueryMeterResourceOwnerArgs = {
    where: MeterResourceOwnerWhereUniqueInput
}


export type QueryMeterResourceOwnerHistoryRecordArgs = {
    where: MeterResourceOwnerHistoryRecordWhereUniqueInput
}


export type QueryMobileFeatureConfigArgs = {
    where: MobileFeatureConfigWhereUniqueInput
}


export type QueryMobileFeatureConfigHistoryRecordArgs = {
    where: MobileFeatureConfigHistoryRecordWhereUniqueInput
}


export type QueryMultiPaymentArgs = {
    where: MultiPaymentWhereUniqueInput
}


export type QueryMultiPaymentHistoryRecordArgs = {
    where: MultiPaymentHistoryRecordWhereUniqueInput
}


export type QueryNewsItemArgs = {
    where: NewsItemWhereUniqueInput
}


export type QueryNewsItemHistoryRecordArgs = {
    where: NewsItemHistoryRecordWhereUniqueInput
}


export type QueryNewsItemRecipientsExportTaskArgs = {
    where: NewsItemRecipientsExportTaskWhereUniqueInput
}


export type QueryNewsItemRecipientsExportTaskHistoryRecordArgs = {
    where: NewsItemRecipientsExportTaskHistoryRecordWhereUniqueInput
}


export type QueryNewsItemScopeArgs = {
    where: NewsItemScopeWhereUniqueInput
}


export type QueryNewsItemScopeHistoryRecordArgs = {
    where: NewsItemScopeHistoryRecordWhereUniqueInput
}


export type QueryNewsItemSharingArgs = {
    where: NewsItemSharingWhereUniqueInput
}


export type QueryNewsItemSharingHistoryRecordArgs = {
    where: NewsItemSharingHistoryRecordWhereUniqueInput
}


export type QueryNewsItemTemplateArgs = {
    where: NewsItemTemplateWhereUniqueInput
}


export type QueryNewsItemTemplateHistoryRecordArgs = {
    where: NewsItemTemplateHistoryRecordWhereUniqueInput
}


export type QueryNewsItemUserReadArgs = {
    where: NewsItemUserReadWhereUniqueInput
}


export type QueryNewsItemUserReadHistoryRecordArgs = {
    where: NewsItemUserReadHistoryRecordWhereUniqueInput
}


export type QueryNotificationAnonymousSettingArgs = {
    where: NotificationAnonymousSettingWhereUniqueInput
}


export type QueryNotificationAnonymousSettingHistoryRecordArgs = {
    where: NotificationAnonymousSettingHistoryRecordWhereUniqueInput
}


export type QueryNotificationUserSettingArgs = {
    where: NotificationUserSettingWhereUniqueInput
}


export type QueryNotificationUserSettingHistoryRecordArgs = {
    where: NotificationUserSettingHistoryRecordWhereUniqueInput
}


export type QueryOidcClientArgs = {
    where: OidcClientWhereUniqueInput
}


export type QueryOidcClientHistoryRecordArgs = {
    where: OidcClientHistoryRecordWhereUniqueInput
}


export type QueryOrganizationArgs = {
    where: OrganizationWhereUniqueInput
}


export type QueryOrganizationEmployeeArgs = {
    where: OrganizationEmployeeWhereUniqueInput
}


export type QueryOrganizationEmployeeHistoryRecordArgs = {
    where: OrganizationEmployeeHistoryRecordWhereUniqueInput
}


export type QueryOrganizationEmployeeRoleArgs = {
    where: OrganizationEmployeeRoleWhereUniqueInput
}


export type QueryOrganizationEmployeeRoleHistoryRecordArgs = {
    where: OrganizationEmployeeRoleHistoryRecordWhereUniqueInput
}


export type QueryOrganizationEmployeeSpecializationArgs = {
    where: OrganizationEmployeeSpecializationWhereUniqueInput
}


export type QueryOrganizationEmployeeSpecializationHistoryRecordArgs = {
    where: OrganizationEmployeeSpecializationHistoryRecordWhereUniqueInput
}


export type QueryOrganizationHistoryRecordArgs = {
    where: OrganizationHistoryRecordWhereUniqueInput
}


export type QueryOrganizationLinkArgs = {
    where: OrganizationLinkWhereUniqueInput
}


export type QueryOrganizationLinkHistoryRecordArgs = {
    where: OrganizationLinkHistoryRecordWhereUniqueInput
}


export type QueryPaymentArgs = {
    where: PaymentWhereUniqueInput
}


export type QueryPaymentHistoryRecordArgs = {
    where: PaymentHistoryRecordWhereUniqueInput
}


export type QueryPaymentsFilterTemplateArgs = {
    where: PaymentsFilterTemplateWhereUniqueInput
}


export type QueryPaymentsFilterTemplateHistoryRecordArgs = {
    where: PaymentsFilterTemplateHistoryRecordWhereUniqueInput
}


export type QueryPropertyArgs = {
    where: PropertyWhereUniqueInput
}


export type QueryPropertyHistoryRecordArgs = {
    where: PropertyHistoryRecordWhereUniqueInput
}


export type QueryPropertyMeterArgs = {
    where: PropertyMeterWhereUniqueInput
}


export type QueryPropertyMeterHistoryRecordArgs = {
    where: PropertyMeterHistoryRecordWhereUniqueInput
}


export type QueryPropertyMeterReadingArgs = {
    where: PropertyMeterReadingWhereUniqueInput
}


export type QueryPropertyMeterReadingHistoryRecordArgs = {
    where: PropertyMeterReadingHistoryRecordWhereUniqueInput
}


export type QueryPropertyScopeArgs = {
    where: PropertyScopeWhereUniqueInput
}


export type QueryPropertyScopeHistoryRecordArgs = {
    where: PropertyScopeHistoryRecordWhereUniqueInput
}


export type QueryPropertyScopeOrganizationEmployeeArgs = {
    where: PropertyScopeOrganizationEmployeeWhereUniqueInput
}


export type QueryPropertyScopeOrganizationEmployeeHistoryRecordArgs = {
    where: PropertyScopeOrganizationEmployeeHistoryRecordWhereUniqueInput
}


export type QueryPropertyScopePropertyArgs = {
    where: PropertyScopePropertyWhereUniqueInput
}


export type QueryPropertyScopePropertyHistoryRecordArgs = {
    where: PropertyScopePropertyHistoryRecordWhereUniqueInput
}


export type QueryRecurrentPaymentArgs = {
    where: RecurrentPaymentWhereUniqueInput
}


export type QueryRecurrentPaymentContextArgs = {
    where: RecurrentPaymentContextWhereUniqueInput
}


export type QueryRecurrentPaymentContextHistoryRecordArgs = {
    where: RecurrentPaymentContextHistoryRecordWhereUniqueInput
}


export type QueryRecurrentPaymentHistoryRecordArgs = {
    where: RecurrentPaymentHistoryRecordWhereUniqueInput
}


export type QueryRemoteClientArgs = {
    where: RemoteClientWhereUniqueInput
}


export type QueryRemoteClientHistoryRecordArgs = {
    where: RemoteClientHistoryRecordWhereUniqueInput
}


export type QueryResidentArgs = {
    where: ResidentWhereUniqueInput
}


export type QueryResidentHistoryRecordArgs = {
    where: ResidentHistoryRecordWhereUniqueInput
}


export type QueryServiceConsumerArgs = {
    where: ServiceConsumerWhereUniqueInput
}


export type QueryServiceConsumerHistoryRecordArgs = {
    where: ServiceConsumerHistoryRecordWhereUniqueInput
}


export type QueryServiceSubscriptionArgs = {
    where: ServiceSubscriptionWhereUniqueInput
}


export type QueryServiceSubscriptionHistoryRecordArgs = {
    where: ServiceSubscriptionHistoryRecordWhereUniqueInput
}


export type QueryTelegramUserChatArgs = {
    where: TelegramUserChatWhereUniqueInput
}


export type QueryTelegramUserChatHistoryRecordArgs = {
    where: TelegramUserChatHistoryRecordWhereUniqueInput
}


export type QueryTicketArgs = {
    where: TicketWhereUniqueInput
}


export type QueryTicketAutoAssignmentArgs = {
    where: TicketAutoAssignmentWhereUniqueInput
}


export type QueryTicketAutoAssignmentHistoryRecordArgs = {
    where: TicketAutoAssignmentHistoryRecordWhereUniqueInput
}


export type QueryTicketCategoryClassifierArgs = {
    where: TicketCategoryClassifierWhereUniqueInput
}


export type QueryTicketCategoryClassifierHistoryRecordArgs = {
    where: TicketCategoryClassifierHistoryRecordWhereUniqueInput
}


export type QueryTicketChangeArgs = {
    where: TicketChangeWhereUniqueInput
}


export type QueryTicketClassifierArgs = {
    where: TicketClassifierWhereUniqueInput
}


export type QueryTicketClassifierHistoryRecordArgs = {
    where: TicketClassifierHistoryRecordWhereUniqueInput
}


export type QueryTicketCommentArgs = {
    where: TicketCommentWhereUniqueInput
}


export type QueryTicketCommentFileArgs = {
    where: TicketCommentFileWhereUniqueInput
}


export type QueryTicketCommentFileHistoryRecordArgs = {
    where: TicketCommentFileHistoryRecordWhereUniqueInput
}


export type QueryTicketCommentHistoryRecordArgs = {
    where: TicketCommentHistoryRecordWhereUniqueInput
}


export type QueryTicketDocumentGenerationTaskArgs = {
    where: TicketDocumentGenerationTaskWhereUniqueInput
}


export type QueryTicketDocumentGenerationTaskHistoryRecordArgs = {
    where: TicketDocumentGenerationTaskHistoryRecordWhereUniqueInput
}


export type QueryTicketExportTaskArgs = {
    where: TicketExportTaskWhereUniqueInput
}


export type QueryTicketExportTaskHistoryRecordArgs = {
    where: TicketExportTaskHistoryRecordWhereUniqueInput
}


export type QueryTicketFileArgs = {
    where: TicketFileWhereUniqueInput
}


export type QueryTicketFileHistoryRecordArgs = {
    where: TicketFileHistoryRecordWhereUniqueInput
}


export type QueryTicketFilterTemplateArgs = {
    where: TicketFilterTemplateWhereUniqueInput
}


export type QueryTicketFilterTemplateHistoryRecordArgs = {
    where: TicketFilterTemplateHistoryRecordWhereUniqueInput
}


export type QueryTicketHistoryRecordArgs = {
    where: TicketHistoryRecordWhereUniqueInput
}


export type QueryTicketOrganizationSettingArgs = {
    where: TicketOrganizationSettingWhereUniqueInput
}


export type QueryTicketOrganizationSettingHistoryRecordArgs = {
    where: TicketOrganizationSettingHistoryRecordWhereUniqueInput
}


export type QueryTicketPlaceClassifierArgs = {
    where: TicketPlaceClassifierWhereUniqueInput
}


export type QueryTicketPlaceClassifierHistoryRecordArgs = {
    where: TicketPlaceClassifierHistoryRecordWhereUniqueInput
}


export type QueryTicketProblemClassifierArgs = {
    where: TicketProblemClassifierWhereUniqueInput
}


export type QueryTicketProblemClassifierHistoryRecordArgs = {
    where: TicketProblemClassifierHistoryRecordWhereUniqueInput
}


export type QueryTicketPropertyHintArgs = {
    where: TicketPropertyHintWhereUniqueInput
}


export type QueryTicketPropertyHintHistoryRecordArgs = {
    where: TicketPropertyHintHistoryRecordWhereUniqueInput
}


export type QueryTicketPropertyHintPropertyArgs = {
    where: TicketPropertyHintPropertyWhereUniqueInput
}


export type QueryTicketPropertyHintPropertyHistoryRecordArgs = {
    where: TicketPropertyHintPropertyHistoryRecordWhereUniqueInput
}


export type QueryTicketSourceArgs = {
    where: TicketSourceWhereUniqueInput
}


export type QueryTicketSourceHistoryRecordArgs = {
    where: TicketSourceHistoryRecordWhereUniqueInput
}


export type QueryTicketStatusArgs = {
    where: TicketStatusWhereUniqueInput
}


export type QueryTicketStatusHistoryRecordArgs = {
    where: TicketStatusHistoryRecordWhereUniqueInput
}


export type QueryTourStepArgs = {
    where: TourStepWhereUniqueInput
}


export type QueryTourStepHistoryRecordArgs = {
    where: TourStepHistoryRecordWhereUniqueInput
}


export type QueryUserArgs = {
    where: UserWhereUniqueInput
}


export type QueryUserExternalIdentityArgs = {
    where: UserExternalIdentityWhereUniqueInput
}


export type QueryUserExternalIdentityHistoryRecordArgs = {
    where: UserExternalIdentityHistoryRecordWhereUniqueInput
}


export type QueryUserFavoriteTicketArgs = {
    where: UserFavoriteTicketWhereUniqueInput
}


export type QueryUserFavoriteTicketHistoryRecordArgs = {
    where: UserFavoriteTicketHistoryRecordWhereUniqueInput
}


export type QueryUserHelpRequestArgs = {
    where: UserHelpRequestWhereUniqueInput
}


export type QueryUserHelpRequestFileArgs = {
    where: UserHelpRequestFileWhereUniqueInput
}


export type QueryUserHelpRequestFileHistoryRecordArgs = {
    where: UserHelpRequestFileHistoryRecordWhereUniqueInput
}


export type QueryUserHelpRequestHistoryRecordArgs = {
    where: UserHelpRequestHistoryRecordWhereUniqueInput
}


export type QueryUserHistoryRecordArgs = {
    where: UserHistoryRecordWhereUniqueInput
}


export type QueryUserRightsSetArgs = {
    where: UserRightsSetWhereUniqueInput
}


export type QueryUserRightsSetHistoryRecordArgs = {
    where: UserRightsSetHistoryRecordWhereUniqueInput
}


export type QueryUserTicketCommentReadTimeArgs = {
    where: UserTicketCommentReadTimeWhereUniqueInput
}


export type QueryUserTicketCommentReadTimeHistoryRecordArgs = {
    where: UserTicketCommentReadTimeHistoryRecordWhereUniqueInput
}


export type QueryWebhookArgs = {
    where: WebhookWhereUniqueInput
}


export type QueryWebhookHistoryRecordArgs = {
    where: WebhookHistoryRecordWhereUniqueInput
}


export type QueryWebhookSubscriptionArgs = {
    where: WebhookSubscriptionWhereUniqueInput
}


export type QueryWebhookSubscriptionHistoryRecordArgs = {
    where: WebhookSubscriptionHistoryRecordWhereUniqueInput
}


export type Query_AllAcquiringIntegrationAccessRightHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortAcquiringIntegrationAccessRightHistoryRecordsBy>>
    where?: InputMaybe<AcquiringIntegrationAccessRightHistoryRecordWhereInput>
}


export type Query_AllAcquiringIntegrationAccessRightsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortAcquiringIntegrationAccessRightsBy>>
    where?: InputMaybe<AcquiringIntegrationAccessRightWhereInput>
}


export type Query_AllAcquiringIntegrationContextHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortAcquiringIntegrationContextHistoryRecordsBy>>
    where?: InputMaybe<AcquiringIntegrationContextHistoryRecordWhereInput>
}


export type Query_AllAcquiringIntegrationContextsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortAcquiringIntegrationContextsBy>>
    where?: InputMaybe<AcquiringIntegrationContextWhereInput>
}


export type Query_AllAcquiringIntegrationHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortAcquiringIntegrationHistoryRecordsBy>>
    where?: InputMaybe<AcquiringIntegrationHistoryRecordWhereInput>
}


export type Query_AllAcquiringIntegrationsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortAcquiringIntegrationsBy>>
    where?: InputMaybe<AcquiringIntegrationWhereInput>
}


export type Query_AllB2BAppAccessRightHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2BAppAccessRightHistoryRecordsBy>>
    where?: InputMaybe<B2BAppAccessRightHistoryRecordWhereInput>
}


export type Query_AllB2BAppAccessRightSetHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2BAppAccessRightSetHistoryRecordsBy>>
    where?: InputMaybe<B2BAppAccessRightSetHistoryRecordWhereInput>
}


export type Query_AllB2BAppAccessRightSetsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2BAppAccessRightSetsBy>>
    where?: InputMaybe<B2BAppAccessRightSetWhereInput>
}


export type Query_AllB2BAppAccessRightsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2BAppAccessRightsBy>>
    where?: InputMaybe<B2BAppAccessRightWhereInput>
}


export type Query_AllB2BAppContextHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2BAppContextHistoryRecordsBy>>
    where?: InputMaybe<B2BAppContextHistoryRecordWhereInput>
}


export type Query_AllB2BAppContextsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2BAppContextsBy>>
    where?: InputMaybe<B2BAppContextWhereInput>
}


export type Query_AllB2BAppHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2BAppHistoryRecordsBy>>
    where?: InputMaybe<B2BAppHistoryRecordWhereInput>
}


export type Query_AllB2BAppNewsSharingConfigHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2BAppNewsSharingConfigHistoryRecordsBy>>
    where?: InputMaybe<B2BAppNewsSharingConfigHistoryRecordWhereInput>
}


export type Query_AllB2BAppNewsSharingConfigsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2BAppNewsSharingConfigsBy>>
    where?: InputMaybe<B2BAppNewsSharingConfigWhereInput>
}


export type Query_AllB2BAppPermissionHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2BAppPermissionHistoryRecordsBy>>
    where?: InputMaybe<B2BAppPermissionHistoryRecordWhereInput>
}


export type Query_AllB2BAppPermissionsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2BAppPermissionsBy>>
    where?: InputMaybe<B2BAppPermissionWhereInput>
}


export type Query_AllB2BAppPromoBlockHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2BAppPromoBlockHistoryRecordsBy>>
    where?: InputMaybe<B2BAppPromoBlockHistoryRecordWhereInput>
}


export type Query_AllB2BAppPromoBlocksMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2BAppPromoBlocksBy>>
    where?: InputMaybe<B2BAppPromoBlockWhereInput>
}


export type Query_AllB2BAppRoleHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2BAppRoleHistoryRecordsBy>>
    where?: InputMaybe<B2BAppRoleHistoryRecordWhereInput>
}


export type Query_AllB2BAppRolesMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2BAppRolesBy>>
    where?: InputMaybe<B2BAppRoleWhereInput>
}


export type Query_AllB2BAppsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2BAppsBy>>
    where?: InputMaybe<B2BAppWhereInput>
}


export type Query_AllB2CAppAccessRightHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2CAppAccessRightHistoryRecordsBy>>
    where?: InputMaybe<B2CAppAccessRightHistoryRecordWhereInput>
}


export type Query_AllB2CAppAccessRightsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2CAppAccessRightsBy>>
    where?: InputMaybe<B2CAppAccessRightWhereInput>
}


export type Query_AllB2CAppBuildHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2CAppBuildHistoryRecordsBy>>
    where?: InputMaybe<B2CAppBuildHistoryRecordWhereInput>
}


export type Query_AllB2CAppBuildsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2CAppBuildsBy>>
    where?: InputMaybe<B2CAppBuildWhereInput>
}


export type Query_AllB2CAppHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2CAppHistoryRecordsBy>>
    where?: InputMaybe<B2CAppHistoryRecordWhereInput>
}


export type Query_AllB2CAppMessageSettingHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2CAppMessageSettingHistoryRecordsBy>>
    where?: InputMaybe<B2CAppMessageSettingHistoryRecordWhereInput>
}


export type Query_AllB2CAppMessageSettingsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2CAppMessageSettingsBy>>
    where?: InputMaybe<B2CAppMessageSettingWhereInput>
}


export type Query_AllB2CAppPropertiesMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2CAppPropertiesBy>>
    where?: InputMaybe<B2CAppPropertyWhereInput>
}


export type Query_AllB2CAppPropertyHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2CAppPropertyHistoryRecordsBy>>
    where?: InputMaybe<B2CAppPropertyHistoryRecordWhereInput>
}


export type Query_AllB2CAppsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2CAppsBy>>
    where?: InputMaybe<B2CAppWhereInput>
}


export type Query_AllBankAccountHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankAccountHistoryRecordsBy>>
    where?: InputMaybe<BankAccountHistoryRecordWhereInput>
}


export type Query_AllBankAccountReportHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankAccountReportHistoryRecordsBy>>
    where?: InputMaybe<BankAccountReportHistoryRecordWhereInput>
}


export type Query_AllBankAccountReportTaskHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankAccountReportTaskHistoryRecordsBy>>
    where?: InputMaybe<BankAccountReportTaskHistoryRecordWhereInput>
}


export type Query_AllBankAccountReportTasksMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankAccountReportTasksBy>>
    where?: InputMaybe<BankAccountReportTaskWhereInput>
}


export type Query_AllBankAccountReportsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankAccountReportsBy>>
    where?: InputMaybe<BankAccountReportWhereInput>
}


export type Query_AllBankAccountsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankAccountsBy>>
    where?: InputMaybe<BankAccountWhereInput>
}


export type Query_AllBankCategoriesMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankCategoriesBy>>
    where?: InputMaybe<BankCategoryWhereInput>
}


export type Query_AllBankCategoryHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankCategoryHistoryRecordsBy>>
    where?: InputMaybe<BankCategoryHistoryRecordWhereInput>
}


export type Query_AllBankContractorAccountHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankContractorAccountHistoryRecordsBy>>
    where?: InputMaybe<BankContractorAccountHistoryRecordWhereInput>
}


export type Query_AllBankContractorAccountsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankContractorAccountsBy>>
    where?: InputMaybe<BankContractorAccountWhereInput>
}


export type Query_AllBankCostItemHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankCostItemHistoryRecordsBy>>
    where?: InputMaybe<BankCostItemHistoryRecordWhereInput>
}


export type Query_AllBankCostItemsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankCostItemsBy>>
    where?: InputMaybe<BankCostItemWhereInput>
}


export type Query_AllBankIntegrationAccessRightHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankIntegrationAccessRightHistoryRecordsBy>>
    where?: InputMaybe<BankIntegrationAccessRightHistoryRecordWhereInput>
}


export type Query_AllBankIntegrationAccessRightsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankIntegrationAccessRightsBy>>
    where?: InputMaybe<BankIntegrationAccessRightWhereInput>
}


export type Query_AllBankIntegrationAccountContextHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankIntegrationAccountContextHistoryRecordsBy>>
    where?: InputMaybe<BankIntegrationAccountContextHistoryRecordWhereInput>
}


export type Query_AllBankIntegrationAccountContextsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankIntegrationAccountContextsBy>>
    where?: InputMaybe<BankIntegrationAccountContextWhereInput>
}


export type Query_AllBankIntegrationHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankIntegrationHistoryRecordsBy>>
    where?: InputMaybe<BankIntegrationHistoryRecordWhereInput>
}


export type Query_AllBankIntegrationOrganizationContextHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankIntegrationOrganizationContextHistoryRecordsBy>>
    where?: InputMaybe<BankIntegrationOrganizationContextHistoryRecordWhereInput>
}


export type Query_AllBankIntegrationOrganizationContextsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankIntegrationOrganizationContextsBy>>
    where?: InputMaybe<BankIntegrationOrganizationContextWhereInput>
}


export type Query_AllBankIntegrationsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankIntegrationsBy>>
    where?: InputMaybe<BankIntegrationWhereInput>
}


export type Query_AllBankSyncTaskHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankSyncTaskHistoryRecordsBy>>
    where?: InputMaybe<BankSyncTaskHistoryRecordWhereInput>
}


export type Query_AllBankSyncTasksMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankSyncTasksBy>>
    where?: InputMaybe<BankSyncTaskWhereInput>
}


export type Query_AllBankTransactionHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankTransactionHistoryRecordsBy>>
    where?: InputMaybe<BankTransactionHistoryRecordWhereInput>
}


export type Query_AllBankTransactionsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankTransactionsBy>>
    where?: InputMaybe<BankTransactionWhereInput>
}


export type Query_AllBillingAccountHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBillingAccountHistoryRecordsBy>>
    where?: InputMaybe<BillingAccountHistoryRecordWhereInput>
}


export type Query_AllBillingAccountsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBillingAccountsBy>>
    where?: InputMaybe<BillingAccountWhereInput>
}


export type Query_AllBillingCategoriesMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBillingCategoriesBy>>
    where?: InputMaybe<BillingCategoryWhereInput>
}


export type Query_AllBillingCategoryHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBillingCategoryHistoryRecordsBy>>
    where?: InputMaybe<BillingCategoryHistoryRecordWhereInput>
}


export type Query_AllBillingIntegrationAccessRightHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBillingIntegrationAccessRightHistoryRecordsBy>>
    where?: InputMaybe<BillingIntegrationAccessRightHistoryRecordWhereInput>
}


export type Query_AllBillingIntegrationAccessRightsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBillingIntegrationAccessRightsBy>>
    where?: InputMaybe<BillingIntegrationAccessRightWhereInput>
}


export type Query_AllBillingIntegrationHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBillingIntegrationHistoryRecordsBy>>
    where?: InputMaybe<BillingIntegrationHistoryRecordWhereInput>
}


export type Query_AllBillingIntegrationOrganizationContextHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBillingIntegrationOrganizationContextHistoryRecordsBy>>
    where?: InputMaybe<BillingIntegrationOrganizationContextHistoryRecordWhereInput>
}


export type Query_AllBillingIntegrationOrganizationContextsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBillingIntegrationOrganizationContextsBy>>
    where?: InputMaybe<BillingIntegrationOrganizationContextWhereInput>
}


export type Query_AllBillingIntegrationProblemsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBillingIntegrationProblemsBy>>
    where?: InputMaybe<BillingIntegrationProblemWhereInput>
}


export type Query_AllBillingIntegrationsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBillingIntegrationsBy>>
    where?: InputMaybe<BillingIntegrationWhereInput>
}


export type Query_AllBillingPropertiesMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBillingPropertiesBy>>
    where?: InputMaybe<BillingPropertyWhereInput>
}


export type Query_AllBillingPropertyHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBillingPropertyHistoryRecordsBy>>
    where?: InputMaybe<BillingPropertyHistoryRecordWhereInput>
}


export type Query_AllBillingReceiptFileHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBillingReceiptFileHistoryRecordsBy>>
    where?: InputMaybe<BillingReceiptFileHistoryRecordWhereInput>
}


export type Query_AllBillingReceiptFilesMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBillingReceiptFilesBy>>
    where?: InputMaybe<BillingReceiptFileWhereInput>
}


export type Query_AllBillingReceiptHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBillingReceiptHistoryRecordsBy>>
    where?: InputMaybe<BillingReceiptHistoryRecordWhereInput>
}


export type Query_AllBillingReceiptsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBillingReceiptsBy>>
    where?: InputMaybe<BillingReceiptWhereInput>
}


export type Query_AllBillingReceiptsSumArgs = {
    data: BillingReceiptsSumInput
}


export type Query_AllBillingRecipientHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBillingRecipientHistoryRecordsBy>>
    where?: InputMaybe<BillingRecipientHistoryRecordWhereInput>
}


export type Query_AllBillingRecipientsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBillingRecipientsBy>>
    where?: InputMaybe<BillingRecipientWhereInput>
}


export type Query_AllCallRecordFragmentHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortCallRecordFragmentHistoryRecordsBy>>
    where?: InputMaybe<CallRecordFragmentHistoryRecordWhereInput>
}


export type Query_AllCallRecordFragmentsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortCallRecordFragmentsBy>>
    where?: InputMaybe<CallRecordFragmentWhereInput>
}


export type Query_AllCallRecordHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortCallRecordHistoryRecordsBy>>
    where?: InputMaybe<CallRecordHistoryRecordWhereInput>
}


export type Query_AllCallRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortCallRecordsBy>>
    where?: InputMaybe<CallRecordWhereInput>
}


export type Query_AllConfirmPhoneActionHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortConfirmPhoneActionHistoryRecordsBy>>
    where?: InputMaybe<ConfirmPhoneActionHistoryRecordWhereInput>
}


export type Query_AllConfirmPhoneActionsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortConfirmPhoneActionsBy>>
    where?: InputMaybe<ConfirmPhoneActionWhereInput>
}


export type Query_AllContactExportTaskHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortContactExportTaskHistoryRecordsBy>>
    where?: InputMaybe<ContactExportTaskHistoryRecordWhereInput>
}


export type Query_AllContactExportTasksMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortContactExportTasksBy>>
    where?: InputMaybe<ContactExportTaskWhereInput>
}


export type Query_AllContactHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortContactHistoryRecordsBy>>
    where?: InputMaybe<ContactHistoryRecordWhereInput>
}


export type Query_AllContactRoleHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortContactRoleHistoryRecordsBy>>
    where?: InputMaybe<ContactRoleHistoryRecordWhereInput>
}


export type Query_AllContactRolesMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortContactRolesBy>>
    where?: InputMaybe<ContactRoleWhereInput>
}


export type Query_AllContactsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortContactsBy>>
    where?: InputMaybe<ContactWhereInput>
}


export type Query_AllDocumentCategoriesMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortDocumentCategoriesBy>>
    where?: InputMaybe<DocumentCategoryWhereInput>
}


export type Query_AllDocumentCategoryHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortDocumentCategoryHistoryRecordsBy>>
    where?: InputMaybe<DocumentCategoryHistoryRecordWhereInput>
}


export type Query_AllDocumentHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortDocumentHistoryRecordsBy>>
    where?: InputMaybe<DocumentHistoryRecordWhereInput>
}


export type Query_AllDocumentsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortDocumentsBy>>
    where?: InputMaybe<DocumentWhereInput>
}


export type Query_AllExternalTokenAccessRightHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortExternalTokenAccessRightHistoryRecordsBy>>
    where?: InputMaybe<ExternalTokenAccessRightHistoryRecordWhereInput>
}


export type Query_AllExternalTokenAccessRightsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortExternalTokenAccessRightsBy>>
    where?: InputMaybe<ExternalTokenAccessRightWhereInput>
}


export type Query_AllIncidentChangesMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortIncidentChangesBy>>
    where?: InputMaybe<IncidentChangeWhereInput>
}


export type Query_AllIncidentClassifierHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortIncidentClassifierHistoryRecordsBy>>
    where?: InputMaybe<IncidentClassifierHistoryRecordWhereInput>
}


export type Query_AllIncidentClassifierIncidentHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortIncidentClassifierIncidentHistoryRecordsBy>>
    where?: InputMaybe<IncidentClassifierIncidentHistoryRecordWhereInput>
}


export type Query_AllIncidentClassifierIncidentsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortIncidentClassifierIncidentsBy>>
    where?: InputMaybe<IncidentClassifierIncidentWhereInput>
}


export type Query_AllIncidentClassifiersMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortIncidentClassifiersBy>>
    where?: InputMaybe<IncidentClassifierWhereInput>
}


export type Query_AllIncidentExportTaskHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortIncidentExportTaskHistoryRecordsBy>>
    where?: InputMaybe<IncidentExportTaskHistoryRecordWhereInput>
}


export type Query_AllIncidentExportTasksMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortIncidentExportTasksBy>>
    where?: InputMaybe<IncidentExportTaskWhereInput>
}


export type Query_AllIncidentHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortIncidentHistoryRecordsBy>>
    where?: InputMaybe<IncidentHistoryRecordWhereInput>
}


export type Query_AllIncidentPropertiesMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortIncidentPropertiesBy>>
    where?: InputMaybe<IncidentPropertyWhereInput>
}


export type Query_AllIncidentPropertyHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortIncidentPropertyHistoryRecordsBy>>
    where?: InputMaybe<IncidentPropertyHistoryRecordWhereInput>
}


export type Query_AllIncidentsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortIncidentsBy>>
    where?: InputMaybe<IncidentWhereInput>
}


export type Query_AllInvoiceHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortInvoiceHistoryRecordsBy>>
    where?: InputMaybe<InvoiceHistoryRecordWhereInput>
}


export type Query_AllInvoicesMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortInvoicesBy>>
    where?: InputMaybe<InvoiceWhereInput>
}


export type Query_AllMarketCategoriesMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMarketCategoriesBy>>
    where?: InputMaybe<MarketCategoryWhereInput>
}


export type Query_AllMarketCategoryHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMarketCategoryHistoryRecordsBy>>
    where?: InputMaybe<MarketCategoryHistoryRecordWhereInput>
}


export type Query_AllMarketItemFileHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMarketItemFileHistoryRecordsBy>>
    where?: InputMaybe<MarketItemFileHistoryRecordWhereInput>
}


export type Query_AllMarketItemFilesMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMarketItemFilesBy>>
    where?: InputMaybe<MarketItemFileWhereInput>
}


export type Query_AllMarketItemHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMarketItemHistoryRecordsBy>>
    where?: InputMaybe<MarketItemHistoryRecordWhereInput>
}


export type Query_AllMarketItemPriceHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMarketItemPriceHistoryRecordsBy>>
    where?: InputMaybe<MarketItemPriceHistoryRecordWhereInput>
}


export type Query_AllMarketItemPricesMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMarketItemPricesBy>>
    where?: InputMaybe<MarketItemPriceWhereInput>
}


export type Query_AllMarketItemsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMarketItemsBy>>
    where?: InputMaybe<MarketItemWhereInput>
}


export type Query_AllMarketPriceScopeHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMarketPriceScopeHistoryRecordsBy>>
    where?: InputMaybe<MarketPriceScopeHistoryRecordWhereInput>
}


export type Query_AllMarketPriceScopesMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMarketPriceScopesBy>>
    where?: InputMaybe<MarketPriceScopeWhereInput>
}


export type Query_AllMarketSettingHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMarketSettingHistoryRecordsBy>>
    where?: InputMaybe<MarketSettingHistoryRecordWhereInput>
}


export type Query_AllMarketSettingsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMarketSettingsBy>>
    where?: InputMaybe<MarketSettingWhereInput>
}


export type Query_AllMessageAppBlackListHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMessageAppBlackListHistoryRecordsBy>>
    where?: InputMaybe<MessageAppBlackListHistoryRecordWhereInput>
}


export type Query_AllMessageAppBlackListsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMessageAppBlackListsBy>>
    where?: InputMaybe<MessageAppBlackListWhereInput>
}


export type Query_AllMessageBatchHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMessageBatchHistoryRecordsBy>>
    where?: InputMaybe<MessageBatchHistoryRecordWhereInput>
}


export type Query_AllMessageBatchesMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMessageBatchesBy>>
    where?: InputMaybe<MessageBatchWhereInput>
}


export type Query_AllMessageHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMessageHistoryRecordsBy>>
    where?: InputMaybe<MessageHistoryRecordWhereInput>
}


export type Query_AllMessageOrganizationBlackListHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMessageOrganizationBlackListHistoryRecordsBy>>
    where?: InputMaybe<MessageOrganizationBlackListHistoryRecordWhereInput>
}


export type Query_AllMessageOrganizationBlackListsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMessageOrganizationBlackListsBy>>
    where?: InputMaybe<MessageOrganizationBlackListWhereInput>
}


export type Query_AllMessageUserBlackListHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMessageUserBlackListHistoryRecordsBy>>
    where?: InputMaybe<MessageUserBlackListHistoryRecordWhereInput>
}


export type Query_AllMessageUserBlackListsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMessageUserBlackListsBy>>
    where?: InputMaybe<MessageUserBlackListWhereInput>
}


export type Query_AllMessagesMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMessagesBy>>
    where?: InputMaybe<MessageWhereInput>
}


export type Query_AllMeterHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMeterHistoryRecordsBy>>
    where?: InputMaybe<MeterHistoryRecordWhereInput>
}


export type Query_AllMeterReadingExportTaskHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMeterReadingExportTaskHistoryRecordsBy>>
    where?: InputMaybe<MeterReadingExportTaskHistoryRecordWhereInput>
}


export type Query_AllMeterReadingExportTasksMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMeterReadingExportTasksBy>>
    where?: InputMaybe<MeterReadingExportTaskWhereInput>
}


export type Query_AllMeterReadingFilterTemplateHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMeterReadingFilterTemplateHistoryRecordsBy>>
    where?: InputMaybe<MeterReadingFilterTemplateHistoryRecordWhereInput>
}


export type Query_AllMeterReadingFilterTemplatesMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMeterReadingFilterTemplatesBy>>
    where?: InputMaybe<MeterReadingFilterTemplateWhereInput>
}


export type Query_AllMeterReadingHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMeterReadingHistoryRecordsBy>>
    where?: InputMaybe<MeterReadingHistoryRecordWhereInput>
}


export type Query_AllMeterReadingSourceHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMeterReadingSourceHistoryRecordsBy>>
    where?: InputMaybe<MeterReadingSourceHistoryRecordWhereInput>
}


export type Query_AllMeterReadingSourcesMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMeterReadingSourcesBy>>
    where?: InputMaybe<MeterReadingSourceWhereInput>
}


export type Query_AllMeterReadingsImportTaskHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMeterReadingsImportTaskHistoryRecordsBy>>
    where?: InputMaybe<MeterReadingsImportTaskHistoryRecordWhereInput>
}


export type Query_AllMeterReadingsImportTasksMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMeterReadingsImportTasksBy>>
    where?: InputMaybe<MeterReadingsImportTaskWhereInput>
}


export type Query_AllMeterReadingsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMeterReadingsBy>>
    where?: InputMaybe<MeterReadingWhereInput>
}


export type Query_AllMeterReportingPeriodHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMeterReportingPeriodHistoryRecordsBy>>
    where?: InputMaybe<MeterReportingPeriodHistoryRecordWhereInput>
}


export type Query_AllMeterReportingPeriodsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMeterReportingPeriodsBy>>
    where?: InputMaybe<MeterReportingPeriodWhereInput>
}


export type Query_AllMeterResourceHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMeterResourceHistoryRecordsBy>>
    where?: InputMaybe<MeterResourceHistoryRecordWhereInput>
}


export type Query_AllMeterResourceOwnerHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMeterResourceOwnerHistoryRecordsBy>>
    where?: InputMaybe<MeterResourceOwnerHistoryRecordWhereInput>
}


export type Query_AllMeterResourceOwnersMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMeterResourceOwnersBy>>
    where?: InputMaybe<MeterResourceOwnerWhereInput>
}


export type Query_AllMeterResourcesMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMeterResourcesBy>>
    where?: InputMaybe<MeterResourceWhereInput>
}


export type Query_AllMetersMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMetersBy>>
    where?: InputMaybe<MeterWhereInput>
}


export type Query_AllMobileFeatureConfigHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMobileFeatureConfigHistoryRecordsBy>>
    where?: InputMaybe<MobileFeatureConfigHistoryRecordWhereInput>
}


export type Query_AllMobileFeatureConfigsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMobileFeatureConfigsBy>>
    where?: InputMaybe<MobileFeatureConfigWhereInput>
}


export type Query_AllMultiPaymentHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMultiPaymentHistoryRecordsBy>>
    where?: InputMaybe<MultiPaymentHistoryRecordWhereInput>
}


export type Query_AllMultiPaymentsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMultiPaymentsBy>>
    where?: InputMaybe<MultiPaymentWhereInput>
}


export type Query_AllNewsItemHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortNewsItemHistoryRecordsBy>>
    where?: InputMaybe<NewsItemHistoryRecordWhereInput>
}


export type Query_AllNewsItemRecipientsExportTaskHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortNewsItemRecipientsExportTaskHistoryRecordsBy>>
    where?: InputMaybe<NewsItemRecipientsExportTaskHistoryRecordWhereInput>
}


export type Query_AllNewsItemRecipientsExportTasksMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortNewsItemRecipientsExportTasksBy>>
    where?: InputMaybe<NewsItemRecipientsExportTaskWhereInput>
}


export type Query_AllNewsItemScopeHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortNewsItemScopeHistoryRecordsBy>>
    where?: InputMaybe<NewsItemScopeHistoryRecordWhereInput>
}


export type Query_AllNewsItemScopesMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortNewsItemScopesBy>>
    where?: InputMaybe<NewsItemScopeWhereInput>
}


export type Query_AllNewsItemSharingHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortNewsItemSharingHistoryRecordsBy>>
    where?: InputMaybe<NewsItemSharingHistoryRecordWhereInput>
}


export type Query_AllNewsItemSharingsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortNewsItemSharingsBy>>
    where?: InputMaybe<NewsItemSharingWhereInput>
}


export type Query_AllNewsItemTemplateHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortNewsItemTemplateHistoryRecordsBy>>
    where?: InputMaybe<NewsItemTemplateHistoryRecordWhereInput>
}


export type Query_AllNewsItemTemplatesMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortNewsItemTemplatesBy>>
    where?: InputMaybe<NewsItemTemplateWhereInput>
}


export type Query_AllNewsItemUserReadHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortNewsItemUserReadHistoryRecordsBy>>
    where?: InputMaybe<NewsItemUserReadHistoryRecordWhereInput>
}


export type Query_AllNewsItemUserReadsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortNewsItemUserReadsBy>>
    where?: InputMaybe<NewsItemUserReadWhereInput>
}


export type Query_AllNewsItemsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortNewsItemsBy>>
    where?: InputMaybe<NewsItemWhereInput>
}


export type Query_AllNotificationAnonymousSettingHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortNotificationAnonymousSettingHistoryRecordsBy>>
    where?: InputMaybe<NotificationAnonymousSettingHistoryRecordWhereInput>
}


export type Query_AllNotificationAnonymousSettingsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortNotificationAnonymousSettingsBy>>
    where?: InputMaybe<NotificationAnonymousSettingWhereInput>
}


export type Query_AllNotificationUserSettingHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortNotificationUserSettingHistoryRecordsBy>>
    where?: InputMaybe<NotificationUserSettingHistoryRecordWhereInput>
}


export type Query_AllNotificationUserSettingsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortNotificationUserSettingsBy>>
    where?: InputMaybe<NotificationUserSettingWhereInput>
}


export type Query_AllOidcClientHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortOidcClientHistoryRecordsBy>>
    where?: InputMaybe<OidcClientHistoryRecordWhereInput>
}


export type Query_AllOidcClientsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortOidcClientsBy>>
    where?: InputMaybe<OidcClientWhereInput>
}


export type Query_AllOrganizationEmployeeHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortOrganizationEmployeeHistoryRecordsBy>>
    where?: InputMaybe<OrganizationEmployeeHistoryRecordWhereInput>
}


export type Query_AllOrganizationEmployeeRoleHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortOrganizationEmployeeRoleHistoryRecordsBy>>
    where?: InputMaybe<OrganizationEmployeeRoleHistoryRecordWhereInput>
}


export type Query_AllOrganizationEmployeeRolesMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortOrganizationEmployeeRolesBy>>
    where?: InputMaybe<OrganizationEmployeeRoleWhereInput>
}


export type Query_AllOrganizationEmployeeSpecializationHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortOrganizationEmployeeSpecializationHistoryRecordsBy>>
    where?: InputMaybe<OrganizationEmployeeSpecializationHistoryRecordWhereInput>
}


export type Query_AllOrganizationEmployeeSpecializationsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortOrganizationEmployeeSpecializationsBy>>
    where?: InputMaybe<OrganizationEmployeeSpecializationWhereInput>
}


export type Query_AllOrganizationEmployeesMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortOrganizationEmployeesBy>>
    where?: InputMaybe<OrganizationEmployeeWhereInput>
}


export type Query_AllOrganizationHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortOrganizationHistoryRecordsBy>>
    where?: InputMaybe<OrganizationHistoryRecordWhereInput>
}


export type Query_AllOrganizationLinkHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortOrganizationLinkHistoryRecordsBy>>
    where?: InputMaybe<OrganizationLinkHistoryRecordWhereInput>
}


export type Query_AllOrganizationLinksMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortOrganizationLinksBy>>
    where?: InputMaybe<OrganizationLinkWhereInput>
}


export type Query_AllOrganizationsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortOrganizationsBy>>
    where?: InputMaybe<OrganizationWhereInput>
}


export type Query_AllPaymentHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortPaymentHistoryRecordsBy>>
    where?: InputMaybe<PaymentHistoryRecordWhereInput>
}


export type Query_AllPaymentsFilterTemplateHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortPaymentsFilterTemplateHistoryRecordsBy>>
    where?: InputMaybe<PaymentsFilterTemplateHistoryRecordWhereInput>
}


export type Query_AllPaymentsFilterTemplatesMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortPaymentsFilterTemplatesBy>>
    where?: InputMaybe<PaymentsFilterTemplateWhereInput>
}


export type Query_AllPaymentsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortPaymentsBy>>
    where?: InputMaybe<PaymentWhereInput>
}


export type Query_AllPaymentsSumArgs = {
    where: PaymentWhereInput
}


export type Query_AllPropertiesMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortPropertiesBy>>
    where?: InputMaybe<PropertyWhereInput>
}


export type Query_AllPropertyHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortPropertyHistoryRecordsBy>>
    where?: InputMaybe<PropertyHistoryRecordWhereInput>
}


export type Query_AllPropertyMeterHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortPropertyMeterHistoryRecordsBy>>
    where?: InputMaybe<PropertyMeterHistoryRecordWhereInput>
}


export type Query_AllPropertyMeterReadingHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortPropertyMeterReadingHistoryRecordsBy>>
    where?: InputMaybe<PropertyMeterReadingHistoryRecordWhereInput>
}


export type Query_AllPropertyMeterReadingsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortPropertyMeterReadingsBy>>
    where?: InputMaybe<PropertyMeterReadingWhereInput>
}


export type Query_AllPropertyMetersMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortPropertyMetersBy>>
    where?: InputMaybe<PropertyMeterWhereInput>
}


export type Query_AllPropertyScopeHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortPropertyScopeHistoryRecordsBy>>
    where?: InputMaybe<PropertyScopeHistoryRecordWhereInput>
}


export type Query_AllPropertyScopeOrganizationEmployeeHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortPropertyScopeOrganizationEmployeeHistoryRecordsBy>>
    where?: InputMaybe<PropertyScopeOrganizationEmployeeHistoryRecordWhereInput>
}


export type Query_AllPropertyScopeOrganizationEmployeesMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortPropertyScopeOrganizationEmployeesBy>>
    where?: InputMaybe<PropertyScopeOrganizationEmployeeWhereInput>
}


export type Query_AllPropertyScopePropertiesMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortPropertyScopePropertiesBy>>
    where?: InputMaybe<PropertyScopePropertyWhereInput>
}


export type Query_AllPropertyScopePropertyHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortPropertyScopePropertyHistoryRecordsBy>>
    where?: InputMaybe<PropertyScopePropertyHistoryRecordWhereInput>
}


export type Query_AllPropertyScopesMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortPropertyScopesBy>>
    where?: InputMaybe<PropertyScopeWhereInput>
}


export type Query_AllRecurrentPaymentContextHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortRecurrentPaymentContextHistoryRecordsBy>>
    where?: InputMaybe<RecurrentPaymentContextHistoryRecordWhereInput>
}


export type Query_AllRecurrentPaymentContextsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortRecurrentPaymentContextsBy>>
    where?: InputMaybe<RecurrentPaymentContextWhereInput>
}


export type Query_AllRecurrentPaymentHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortRecurrentPaymentHistoryRecordsBy>>
    where?: InputMaybe<RecurrentPaymentHistoryRecordWhereInput>
}


export type Query_AllRecurrentPaymentsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortRecurrentPaymentsBy>>
    where?: InputMaybe<RecurrentPaymentWhereInput>
}


export type Query_AllRemoteClientHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortRemoteClientHistoryRecordsBy>>
    where?: InputMaybe<RemoteClientHistoryRecordWhereInput>
}


export type Query_AllRemoteClientsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortRemoteClientsBy>>
    where?: InputMaybe<RemoteClientWhereInput>
}


export type Query_AllResidentHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortResidentHistoryRecordsBy>>
    where?: InputMaybe<ResidentHistoryRecordWhereInput>
}


export type Query_AllResidentsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortResidentsBy>>
    where?: InputMaybe<ResidentWhereInput>
}


export type Query_AllServiceConsumerHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortServiceConsumerHistoryRecordsBy>>
    where?: InputMaybe<ServiceConsumerHistoryRecordWhereInput>
}


export type Query_AllServiceConsumersMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortServiceConsumersBy>>
    where?: InputMaybe<ServiceConsumerWhereInput>
}


export type Query_AllServiceSubscriptionHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortServiceSubscriptionHistoryRecordsBy>>
    where?: InputMaybe<ServiceSubscriptionHistoryRecordWhereInput>
}


export type Query_AllServiceSubscriptionsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortServiceSubscriptionsBy>>
    where?: InputMaybe<ServiceSubscriptionWhereInput>
}


export type Query_AllTelegramUserChatHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTelegramUserChatHistoryRecordsBy>>
    where?: InputMaybe<TelegramUserChatHistoryRecordWhereInput>
}


export type Query_AllTelegramUserChatsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTelegramUserChatsBy>>
    where?: InputMaybe<TelegramUserChatWhereInput>
}


export type Query_AllTicketAutoAssignmentHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketAutoAssignmentHistoryRecordsBy>>
    where?: InputMaybe<TicketAutoAssignmentHistoryRecordWhereInput>
}


export type Query_AllTicketAutoAssignmentsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketAutoAssignmentsBy>>
    where?: InputMaybe<TicketAutoAssignmentWhereInput>
}


export type Query_AllTicketCategoryClassifierHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketCategoryClassifierHistoryRecordsBy>>
    where?: InputMaybe<TicketCategoryClassifierHistoryRecordWhereInput>
}


export type Query_AllTicketCategoryClassifiersMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketCategoryClassifiersBy>>
    where?: InputMaybe<TicketCategoryClassifierWhereInput>
}


export type Query_AllTicketChangesMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketChangesBy>>
    where?: InputMaybe<TicketChangeWhereInput>
}


export type Query_AllTicketClassifierHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketClassifierHistoryRecordsBy>>
    where?: InputMaybe<TicketClassifierHistoryRecordWhereInput>
}


export type Query_AllTicketClassifiersMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketClassifiersBy>>
    where?: InputMaybe<TicketClassifierWhereInput>
}


export type Query_AllTicketCommentFileHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketCommentFileHistoryRecordsBy>>
    where?: InputMaybe<TicketCommentFileHistoryRecordWhereInput>
}


export type Query_AllTicketCommentFilesMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketCommentFilesBy>>
    where?: InputMaybe<TicketCommentFileWhereInput>
}


export type Query_AllTicketCommentHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketCommentHistoryRecordsBy>>
    where?: InputMaybe<TicketCommentHistoryRecordWhereInput>
}


export type Query_AllTicketCommentsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketCommentsBy>>
    where?: InputMaybe<TicketCommentWhereInput>
}


export type Query_AllTicketDocumentGenerationTaskHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketDocumentGenerationTaskHistoryRecordsBy>>
    where?: InputMaybe<TicketDocumentGenerationTaskHistoryRecordWhereInput>
}


export type Query_AllTicketDocumentGenerationTasksMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketDocumentGenerationTasksBy>>
    where?: InputMaybe<TicketDocumentGenerationTaskWhereInput>
}


export type Query_AllTicketExportTaskHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketExportTaskHistoryRecordsBy>>
    where?: InputMaybe<TicketExportTaskHistoryRecordWhereInput>
}


export type Query_AllTicketExportTasksMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketExportTasksBy>>
    where?: InputMaybe<TicketExportTaskWhereInput>
}


export type Query_AllTicketFileHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketFileHistoryRecordsBy>>
    where?: InputMaybe<TicketFileHistoryRecordWhereInput>
}


export type Query_AllTicketFilesMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketFilesBy>>
    where?: InputMaybe<TicketFileWhereInput>
}


export type Query_AllTicketFilterTemplateHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketFilterTemplateHistoryRecordsBy>>
    where?: InputMaybe<TicketFilterTemplateHistoryRecordWhereInput>
}


export type Query_AllTicketFilterTemplatesMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketFilterTemplatesBy>>
    where?: InputMaybe<TicketFilterTemplateWhereInput>
}


export type Query_AllTicketHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketHistoryRecordsBy>>
    where?: InputMaybe<TicketHistoryRecordWhereInput>
}


export type Query_AllTicketOrganizationSettingHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketOrganizationSettingHistoryRecordsBy>>
    where?: InputMaybe<TicketOrganizationSettingHistoryRecordWhereInput>
}


export type Query_AllTicketOrganizationSettingsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketOrganizationSettingsBy>>
    where?: InputMaybe<TicketOrganizationSettingWhereInput>
}


export type Query_AllTicketPlaceClassifierHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketPlaceClassifierHistoryRecordsBy>>
    where?: InputMaybe<TicketPlaceClassifierHistoryRecordWhereInput>
}


export type Query_AllTicketPlaceClassifiersMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketPlaceClassifiersBy>>
    where?: InputMaybe<TicketPlaceClassifierWhereInput>
}


export type Query_AllTicketProblemClassifierHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketProblemClassifierHistoryRecordsBy>>
    where?: InputMaybe<TicketProblemClassifierHistoryRecordWhereInput>
}


export type Query_AllTicketProblemClassifiersMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketProblemClassifiersBy>>
    where?: InputMaybe<TicketProblemClassifierWhereInput>
}


export type Query_AllTicketPropertyHintHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketPropertyHintHistoryRecordsBy>>
    where?: InputMaybe<TicketPropertyHintHistoryRecordWhereInput>
}


export type Query_AllTicketPropertyHintPropertiesMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketPropertyHintPropertiesBy>>
    where?: InputMaybe<TicketPropertyHintPropertyWhereInput>
}


export type Query_AllTicketPropertyHintPropertyHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketPropertyHintPropertyHistoryRecordsBy>>
    where?: InputMaybe<TicketPropertyHintPropertyHistoryRecordWhereInput>
}


export type Query_AllTicketPropertyHintsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketPropertyHintsBy>>
    where?: InputMaybe<TicketPropertyHintWhereInput>
}


export type Query_AllTicketSourceHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketSourceHistoryRecordsBy>>
    where?: InputMaybe<TicketSourceHistoryRecordWhereInput>
}


export type Query_AllTicketSourcesMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketSourcesBy>>
    where?: InputMaybe<TicketSourceWhereInput>
}


export type Query_AllTicketStatusHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketStatusHistoryRecordsBy>>
    where?: InputMaybe<TicketStatusHistoryRecordWhereInput>
}


export type Query_AllTicketStatusesMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketStatusesBy>>
    where?: InputMaybe<TicketStatusWhereInput>
}


export type Query_AllTicketsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketsBy>>
    where?: InputMaybe<TicketWhereInput>
}


export type Query_AllTourStepHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTourStepHistoryRecordsBy>>
    where?: InputMaybe<TourStepHistoryRecordWhereInput>
}


export type Query_AllTourStepsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTourStepsBy>>
    where?: InputMaybe<TourStepWhereInput>
}


export type Query_AllUserExternalIdentitiesMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortUserExternalIdentitiesBy>>
    where?: InputMaybe<UserExternalIdentityWhereInput>
}


export type Query_AllUserExternalIdentityHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortUserExternalIdentityHistoryRecordsBy>>
    where?: InputMaybe<UserExternalIdentityHistoryRecordWhereInput>
}


export type Query_AllUserFavoriteTicketHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortUserFavoriteTicketHistoryRecordsBy>>
    where?: InputMaybe<UserFavoriteTicketHistoryRecordWhereInput>
}


export type Query_AllUserFavoriteTicketsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortUserFavoriteTicketsBy>>
    where?: InputMaybe<UserFavoriteTicketWhereInput>
}


export type Query_AllUserHelpRequestFileHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortUserHelpRequestFileHistoryRecordsBy>>
    where?: InputMaybe<UserHelpRequestFileHistoryRecordWhereInput>
}


export type Query_AllUserHelpRequestFilesMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortUserHelpRequestFilesBy>>
    where?: InputMaybe<UserHelpRequestFileWhereInput>
}


export type Query_AllUserHelpRequestHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortUserHelpRequestHistoryRecordsBy>>
    where?: InputMaybe<UserHelpRequestHistoryRecordWhereInput>
}


export type Query_AllUserHelpRequestsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortUserHelpRequestsBy>>
    where?: InputMaybe<UserHelpRequestWhereInput>
}


export type Query_AllUserHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortUserHistoryRecordsBy>>
    where?: InputMaybe<UserHistoryRecordWhereInput>
}


export type Query_AllUserRightsSetHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortUserRightsSetHistoryRecordsBy>>
    where?: InputMaybe<UserRightsSetHistoryRecordWhereInput>
}


export type Query_AllUserRightsSetsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortUserRightsSetsBy>>
    where?: InputMaybe<UserRightsSetWhereInput>
}


export type Query_AllUserTicketCommentReadTimeHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortUserTicketCommentReadTimeHistoryRecordsBy>>
    where?: InputMaybe<UserTicketCommentReadTimeHistoryRecordWhereInput>
}


export type Query_AllUserTicketCommentReadTimesMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortUserTicketCommentReadTimesBy>>
    where?: InputMaybe<UserTicketCommentReadTimeWhereInput>
}


export type Query_AllUsersMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortUsersBy>>
    where?: InputMaybe<UserWhereInput>
}


export type Query_AllWebhookHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortWebhookHistoryRecordsBy>>
    where?: InputMaybe<WebhookHistoryRecordWhereInput>
}


export type Query_AllWebhookSubscriptionHistoryRecordsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortWebhookSubscriptionHistoryRecordsBy>>
    where?: InputMaybe<WebhookSubscriptionHistoryRecordWhereInput>
}


export type Query_AllWebhookSubscriptionsMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortWebhookSubscriptionsBy>>
    where?: InputMaybe<WebhookSubscriptionWhereInput>
}


export type Query_AllWebhooksMetaArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortWebhooksBy>>
    where?: InputMaybe<WebhookWhereInput>
}


export type Query_KsListsMetaArgs = {
    where?: InputMaybe<_KsListsMetaInput>
}


export type QueryAllAcquiringIntegrationAccessRightHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortAcquiringIntegrationAccessRightHistoryRecordsBy>>
    where?: InputMaybe<AcquiringIntegrationAccessRightHistoryRecordWhereInput>
}


export type QueryAllAcquiringIntegrationAccessRightsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortAcquiringIntegrationAccessRightsBy>>
    where?: InputMaybe<AcquiringIntegrationAccessRightWhereInput>
}


export type QueryAllAcquiringIntegrationContextHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortAcquiringIntegrationContextHistoryRecordsBy>>
    where?: InputMaybe<AcquiringIntegrationContextHistoryRecordWhereInput>
}


export type QueryAllAcquiringIntegrationContextsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortAcquiringIntegrationContextsBy>>
    where?: InputMaybe<AcquiringIntegrationContextWhereInput>
}


export type QueryAllAcquiringIntegrationHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortAcquiringIntegrationHistoryRecordsBy>>
    where?: InputMaybe<AcquiringIntegrationHistoryRecordWhereInput>
}


export type QueryAllAcquiringIntegrationsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortAcquiringIntegrationsBy>>
    where?: InputMaybe<AcquiringIntegrationWhereInput>
}


export type QueryAllB2BAppAccessRightHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2BAppAccessRightHistoryRecordsBy>>
    where?: InputMaybe<B2BAppAccessRightHistoryRecordWhereInput>
}


export type QueryAllB2BAppAccessRightSetHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2BAppAccessRightSetHistoryRecordsBy>>
    where?: InputMaybe<B2BAppAccessRightSetHistoryRecordWhereInput>
}


export type QueryAllB2BAppAccessRightSetsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2BAppAccessRightSetsBy>>
    where?: InputMaybe<B2BAppAccessRightSetWhereInput>
}


export type QueryAllB2BAppAccessRightsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2BAppAccessRightsBy>>
    where?: InputMaybe<B2BAppAccessRightWhereInput>
}


export type QueryAllB2BAppContextHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2BAppContextHistoryRecordsBy>>
    where?: InputMaybe<B2BAppContextHistoryRecordWhereInput>
}


export type QueryAllB2BAppContextsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2BAppContextsBy>>
    where?: InputMaybe<B2BAppContextWhereInput>
}


export type QueryAllB2BAppHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2BAppHistoryRecordsBy>>
    where?: InputMaybe<B2BAppHistoryRecordWhereInput>
}


export type QueryAllB2BAppNewsSharingConfigHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2BAppNewsSharingConfigHistoryRecordsBy>>
    where?: InputMaybe<B2BAppNewsSharingConfigHistoryRecordWhereInput>
}


export type QueryAllB2BAppNewsSharingConfigsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2BAppNewsSharingConfigsBy>>
    where?: InputMaybe<B2BAppNewsSharingConfigWhereInput>
}


export type QueryAllB2BAppPermissionHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2BAppPermissionHistoryRecordsBy>>
    where?: InputMaybe<B2BAppPermissionHistoryRecordWhereInput>
}


export type QueryAllB2BAppPermissionsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2BAppPermissionsBy>>
    where?: InputMaybe<B2BAppPermissionWhereInput>
}


export type QueryAllB2BAppPromoBlockHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2BAppPromoBlockHistoryRecordsBy>>
    where?: InputMaybe<B2BAppPromoBlockHistoryRecordWhereInput>
}


export type QueryAllB2BAppPromoBlocksArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2BAppPromoBlocksBy>>
    where?: InputMaybe<B2BAppPromoBlockWhereInput>
}


export type QueryAllB2BAppRoleHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2BAppRoleHistoryRecordsBy>>
    where?: InputMaybe<B2BAppRoleHistoryRecordWhereInput>
}


export type QueryAllB2BAppRolesArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2BAppRolesBy>>
    where?: InputMaybe<B2BAppRoleWhereInput>
}


export type QueryAllB2BAppsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2BAppsBy>>
    where?: InputMaybe<B2BAppWhereInput>
}


export type QueryAllB2CAppAccessRightHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2CAppAccessRightHistoryRecordsBy>>
    where?: InputMaybe<B2CAppAccessRightHistoryRecordWhereInput>
}


export type QueryAllB2CAppAccessRightsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2CAppAccessRightsBy>>
    where?: InputMaybe<B2CAppAccessRightWhereInput>
}


export type QueryAllB2CAppBuildHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2CAppBuildHistoryRecordsBy>>
    where?: InputMaybe<B2CAppBuildHistoryRecordWhereInput>
}


export type QueryAllB2CAppBuildsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2CAppBuildsBy>>
    where?: InputMaybe<B2CAppBuildWhereInput>
}


export type QueryAllB2CAppHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2CAppHistoryRecordsBy>>
    where?: InputMaybe<B2CAppHistoryRecordWhereInput>
}


export type QueryAllB2CAppMessageSettingHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2CAppMessageSettingHistoryRecordsBy>>
    where?: InputMaybe<B2CAppMessageSettingHistoryRecordWhereInput>
}


export type QueryAllB2CAppMessageSettingsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2CAppMessageSettingsBy>>
    where?: InputMaybe<B2CAppMessageSettingWhereInput>
}


export type QueryAllB2CAppPropertiesArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2CAppPropertiesBy>>
    where?: InputMaybe<B2CAppPropertyWhereInput>
}


export type QueryAllB2CAppPropertyHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2CAppPropertyHistoryRecordsBy>>
    where?: InputMaybe<B2CAppPropertyHistoryRecordWhereInput>
}


export type QueryAllB2CAppsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortB2CAppsBy>>
    where?: InputMaybe<B2CAppWhereInput>
}


export type QueryAllBankAccountHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankAccountHistoryRecordsBy>>
    where?: InputMaybe<BankAccountHistoryRecordWhereInput>
}


export type QueryAllBankAccountReportHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankAccountReportHistoryRecordsBy>>
    where?: InputMaybe<BankAccountReportHistoryRecordWhereInput>
}


export type QueryAllBankAccountReportTaskHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankAccountReportTaskHistoryRecordsBy>>
    where?: InputMaybe<BankAccountReportTaskHistoryRecordWhereInput>
}


export type QueryAllBankAccountReportTasksArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankAccountReportTasksBy>>
    where?: InputMaybe<BankAccountReportTaskWhereInput>
}


export type QueryAllBankAccountReportsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankAccountReportsBy>>
    where?: InputMaybe<BankAccountReportWhereInput>
}


export type QueryAllBankAccountsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankAccountsBy>>
    where?: InputMaybe<BankAccountWhereInput>
}


export type QueryAllBankCategoriesArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankCategoriesBy>>
    where?: InputMaybe<BankCategoryWhereInput>
}


export type QueryAllBankCategoryHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankCategoryHistoryRecordsBy>>
    where?: InputMaybe<BankCategoryHistoryRecordWhereInput>
}


export type QueryAllBankContractorAccountHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankContractorAccountHistoryRecordsBy>>
    where?: InputMaybe<BankContractorAccountHistoryRecordWhereInput>
}


export type QueryAllBankContractorAccountsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankContractorAccountsBy>>
    where?: InputMaybe<BankContractorAccountWhereInput>
}


export type QueryAllBankCostItemHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankCostItemHistoryRecordsBy>>
    where?: InputMaybe<BankCostItemHistoryRecordWhereInput>
}


export type QueryAllBankCostItemsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankCostItemsBy>>
    where?: InputMaybe<BankCostItemWhereInput>
}


export type QueryAllBankIntegrationAccessRightHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankIntegrationAccessRightHistoryRecordsBy>>
    where?: InputMaybe<BankIntegrationAccessRightHistoryRecordWhereInput>
}


export type QueryAllBankIntegrationAccessRightsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankIntegrationAccessRightsBy>>
    where?: InputMaybe<BankIntegrationAccessRightWhereInput>
}


export type QueryAllBankIntegrationAccountContextHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankIntegrationAccountContextHistoryRecordsBy>>
    where?: InputMaybe<BankIntegrationAccountContextHistoryRecordWhereInput>
}


export type QueryAllBankIntegrationAccountContextsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankIntegrationAccountContextsBy>>
    where?: InputMaybe<BankIntegrationAccountContextWhereInput>
}


export type QueryAllBankIntegrationHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankIntegrationHistoryRecordsBy>>
    where?: InputMaybe<BankIntegrationHistoryRecordWhereInput>
}


export type QueryAllBankIntegrationOrganizationContextHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankIntegrationOrganizationContextHistoryRecordsBy>>
    where?: InputMaybe<BankIntegrationOrganizationContextHistoryRecordWhereInput>
}


export type QueryAllBankIntegrationOrganizationContextsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankIntegrationOrganizationContextsBy>>
    where?: InputMaybe<BankIntegrationOrganizationContextWhereInput>
}


export type QueryAllBankIntegrationsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankIntegrationsBy>>
    where?: InputMaybe<BankIntegrationWhereInput>
}


export type QueryAllBankSyncTaskHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankSyncTaskHistoryRecordsBy>>
    where?: InputMaybe<BankSyncTaskHistoryRecordWhereInput>
}


export type QueryAllBankSyncTasksArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankSyncTasksBy>>
    where?: InputMaybe<BankSyncTaskWhereInput>
}


export type QueryAllBankTransactionHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankTransactionHistoryRecordsBy>>
    where?: InputMaybe<BankTransactionHistoryRecordWhereInput>
}


export type QueryAllBankTransactionsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBankTransactionsBy>>
    where?: InputMaybe<BankTransactionWhereInput>
}


export type QueryAllBillingAccountHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBillingAccountHistoryRecordsBy>>
    where?: InputMaybe<BillingAccountHistoryRecordWhereInput>
}


export type QueryAllBillingAccountsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBillingAccountsBy>>
    where?: InputMaybe<BillingAccountWhereInput>
}


export type QueryAllBillingCategoriesArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBillingCategoriesBy>>
    where?: InputMaybe<BillingCategoryWhereInput>
}


export type QueryAllBillingCategoryHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBillingCategoryHistoryRecordsBy>>
    where?: InputMaybe<BillingCategoryHistoryRecordWhereInput>
}


export type QueryAllBillingIntegrationAccessRightHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBillingIntegrationAccessRightHistoryRecordsBy>>
    where?: InputMaybe<BillingIntegrationAccessRightHistoryRecordWhereInput>
}


export type QueryAllBillingIntegrationAccessRightsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBillingIntegrationAccessRightsBy>>
    where?: InputMaybe<BillingIntegrationAccessRightWhereInput>
}


export type QueryAllBillingIntegrationHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBillingIntegrationHistoryRecordsBy>>
    where?: InputMaybe<BillingIntegrationHistoryRecordWhereInput>
}


export type QueryAllBillingIntegrationOrganizationContextHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBillingIntegrationOrganizationContextHistoryRecordsBy>>
    where?: InputMaybe<BillingIntegrationOrganizationContextHistoryRecordWhereInput>
}


export type QueryAllBillingIntegrationOrganizationContextsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBillingIntegrationOrganizationContextsBy>>
    where?: InputMaybe<BillingIntegrationOrganizationContextWhereInput>
}


export type QueryAllBillingIntegrationProblemsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBillingIntegrationProblemsBy>>
    where?: InputMaybe<BillingIntegrationProblemWhereInput>
}


export type QueryAllBillingIntegrationsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBillingIntegrationsBy>>
    where?: InputMaybe<BillingIntegrationWhereInput>
}


export type QueryAllBillingPropertiesArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBillingPropertiesBy>>
    where?: InputMaybe<BillingPropertyWhereInput>
}


export type QueryAllBillingPropertyHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBillingPropertyHistoryRecordsBy>>
    where?: InputMaybe<BillingPropertyHistoryRecordWhereInput>
}


export type QueryAllBillingReceiptFileHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBillingReceiptFileHistoryRecordsBy>>
    where?: InputMaybe<BillingReceiptFileHistoryRecordWhereInput>
}


export type QueryAllBillingReceiptFilesArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBillingReceiptFilesBy>>
    where?: InputMaybe<BillingReceiptFileWhereInput>
}


export type QueryAllBillingReceiptHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBillingReceiptHistoryRecordsBy>>
    where?: InputMaybe<BillingReceiptHistoryRecordWhereInput>
}


export type QueryAllBillingReceiptsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBillingReceiptsBy>>
    where?: InputMaybe<BillingReceiptWhereInput>
}


export type QueryAllBillingRecipientHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBillingRecipientHistoryRecordsBy>>
    where?: InputMaybe<BillingRecipientHistoryRecordWhereInput>
}


export type QueryAllBillingRecipientsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortBillingRecipientsBy>>
    where?: InputMaybe<BillingRecipientWhereInput>
}


export type QueryAllCallRecordFragmentHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortCallRecordFragmentHistoryRecordsBy>>
    where?: InputMaybe<CallRecordFragmentHistoryRecordWhereInput>
}


export type QueryAllCallRecordFragmentsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortCallRecordFragmentsBy>>
    where?: InputMaybe<CallRecordFragmentWhereInput>
}


export type QueryAllCallRecordHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortCallRecordHistoryRecordsBy>>
    where?: InputMaybe<CallRecordHistoryRecordWhereInput>
}


export type QueryAllCallRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortCallRecordsBy>>
    where?: InputMaybe<CallRecordWhereInput>
}


export type QueryAllConfirmPhoneActionHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortConfirmPhoneActionHistoryRecordsBy>>
    where?: InputMaybe<ConfirmPhoneActionHistoryRecordWhereInput>
}


export type QueryAllConfirmPhoneActionsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortConfirmPhoneActionsBy>>
    where?: InputMaybe<ConfirmPhoneActionWhereInput>
}


export type QueryAllContactExportTaskHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortContactExportTaskHistoryRecordsBy>>
    where?: InputMaybe<ContactExportTaskHistoryRecordWhereInput>
}


export type QueryAllContactExportTasksArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortContactExportTasksBy>>
    where?: InputMaybe<ContactExportTaskWhereInput>
}


export type QueryAllContactHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortContactHistoryRecordsBy>>
    where?: InputMaybe<ContactHistoryRecordWhereInput>
}


export type QueryAllContactRoleHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortContactRoleHistoryRecordsBy>>
    where?: InputMaybe<ContactRoleHistoryRecordWhereInput>
}


export type QueryAllContactRolesArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortContactRolesBy>>
    where?: InputMaybe<ContactRoleWhereInput>
}


export type QueryAllContactsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortContactsBy>>
    where?: InputMaybe<ContactWhereInput>
}


export type QueryAllDocumentCategoriesArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortDocumentCategoriesBy>>
    where?: InputMaybe<DocumentCategoryWhereInput>
}


export type QueryAllDocumentCategoryHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortDocumentCategoryHistoryRecordsBy>>
    where?: InputMaybe<DocumentCategoryHistoryRecordWhereInput>
}


export type QueryAllDocumentHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortDocumentHistoryRecordsBy>>
    where?: InputMaybe<DocumentHistoryRecordWhereInput>
}


export type QueryAllDocumentsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortDocumentsBy>>
    where?: InputMaybe<DocumentWhereInput>
}


export type QueryAllExternalTokenAccessRightHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortExternalTokenAccessRightHistoryRecordsBy>>
    where?: InputMaybe<ExternalTokenAccessRightHistoryRecordWhereInput>
}


export type QueryAllExternalTokenAccessRightsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortExternalTokenAccessRightsBy>>
    where?: InputMaybe<ExternalTokenAccessRightWhereInput>
}


export type QueryAllIncidentChangesArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortIncidentChangesBy>>
    where?: InputMaybe<IncidentChangeWhereInput>
}


export type QueryAllIncidentClassifierHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortIncidentClassifierHistoryRecordsBy>>
    where?: InputMaybe<IncidentClassifierHistoryRecordWhereInput>
}


export type QueryAllIncidentClassifierIncidentHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortIncidentClassifierIncidentHistoryRecordsBy>>
    where?: InputMaybe<IncidentClassifierIncidentHistoryRecordWhereInput>
}


export type QueryAllIncidentClassifierIncidentsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortIncidentClassifierIncidentsBy>>
    where?: InputMaybe<IncidentClassifierIncidentWhereInput>
}


export type QueryAllIncidentClassifiersArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortIncidentClassifiersBy>>
    where?: InputMaybe<IncidentClassifierWhereInput>
}


export type QueryAllIncidentExportTaskHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortIncidentExportTaskHistoryRecordsBy>>
    where?: InputMaybe<IncidentExportTaskHistoryRecordWhereInput>
}


export type QueryAllIncidentExportTasksArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortIncidentExportTasksBy>>
    where?: InputMaybe<IncidentExportTaskWhereInput>
}


export type QueryAllIncidentHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortIncidentHistoryRecordsBy>>
    where?: InputMaybe<IncidentHistoryRecordWhereInput>
}


export type QueryAllIncidentPropertiesArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortIncidentPropertiesBy>>
    where?: InputMaybe<IncidentPropertyWhereInput>
}


export type QueryAllIncidentPropertyHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortIncidentPropertyHistoryRecordsBy>>
    where?: InputMaybe<IncidentPropertyHistoryRecordWhereInput>
}


export type QueryAllIncidentsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortIncidentsBy>>
    where?: InputMaybe<IncidentWhereInput>
}


export type QueryAllInvoiceHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortInvoiceHistoryRecordsBy>>
    where?: InputMaybe<InvoiceHistoryRecordWhereInput>
}


export type QueryAllInvoicesArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortInvoicesBy>>
    where?: InputMaybe<InvoiceWhereInput>
}


export type QueryAllMarketCategoriesArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMarketCategoriesBy>>
    where?: InputMaybe<MarketCategoryWhereInput>
}


export type QueryAllMarketCategoryHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMarketCategoryHistoryRecordsBy>>
    where?: InputMaybe<MarketCategoryHistoryRecordWhereInput>
}


export type QueryAllMarketItemFileHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMarketItemFileHistoryRecordsBy>>
    where?: InputMaybe<MarketItemFileHistoryRecordWhereInput>
}


export type QueryAllMarketItemFilesArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMarketItemFilesBy>>
    where?: InputMaybe<MarketItemFileWhereInput>
}


export type QueryAllMarketItemHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMarketItemHistoryRecordsBy>>
    where?: InputMaybe<MarketItemHistoryRecordWhereInput>
}


export type QueryAllMarketItemPriceHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMarketItemPriceHistoryRecordsBy>>
    where?: InputMaybe<MarketItemPriceHistoryRecordWhereInput>
}


export type QueryAllMarketItemPricesArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMarketItemPricesBy>>
    where?: InputMaybe<MarketItemPriceWhereInput>
}


export type QueryAllMarketItemsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMarketItemsBy>>
    where?: InputMaybe<MarketItemWhereInput>
}


export type QueryAllMarketPriceScopeHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMarketPriceScopeHistoryRecordsBy>>
    where?: InputMaybe<MarketPriceScopeHistoryRecordWhereInput>
}


export type QueryAllMarketPriceScopesArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMarketPriceScopesBy>>
    where?: InputMaybe<MarketPriceScopeWhereInput>
}


export type QueryAllMarketSettingHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMarketSettingHistoryRecordsBy>>
    where?: InputMaybe<MarketSettingHistoryRecordWhereInput>
}


export type QueryAllMarketSettingsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMarketSettingsBy>>
    where?: InputMaybe<MarketSettingWhereInput>
}


export type QueryAllMessageAppBlackListHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMessageAppBlackListHistoryRecordsBy>>
    where?: InputMaybe<MessageAppBlackListHistoryRecordWhereInput>
}


export type QueryAllMessageAppBlackListsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMessageAppBlackListsBy>>
    where?: InputMaybe<MessageAppBlackListWhereInput>
}


export type QueryAllMessageBatchHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMessageBatchHistoryRecordsBy>>
    where?: InputMaybe<MessageBatchHistoryRecordWhereInput>
}


export type QueryAllMessageBatchesArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMessageBatchesBy>>
    where?: InputMaybe<MessageBatchWhereInput>
}


export type QueryAllMessageHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMessageHistoryRecordsBy>>
    where?: InputMaybe<MessageHistoryRecordWhereInput>
}


export type QueryAllMessageOrganizationBlackListHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMessageOrganizationBlackListHistoryRecordsBy>>
    where?: InputMaybe<MessageOrganizationBlackListHistoryRecordWhereInput>
}


export type QueryAllMessageOrganizationBlackListsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMessageOrganizationBlackListsBy>>
    where?: InputMaybe<MessageOrganizationBlackListWhereInput>
}


export type QueryAllMessageUserBlackListHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMessageUserBlackListHistoryRecordsBy>>
    where?: InputMaybe<MessageUserBlackListHistoryRecordWhereInput>
}


export type QueryAllMessageUserBlackListsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMessageUserBlackListsBy>>
    where?: InputMaybe<MessageUserBlackListWhereInput>
}


export type QueryAllMessagesArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMessagesBy>>
    where?: InputMaybe<MessageWhereInput>
}


export type QueryAllMeterHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMeterHistoryRecordsBy>>
    where?: InputMaybe<MeterHistoryRecordWhereInput>
}


export type QueryAllMeterReadingExportTaskHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMeterReadingExportTaskHistoryRecordsBy>>
    where?: InputMaybe<MeterReadingExportTaskHistoryRecordWhereInput>
}


export type QueryAllMeterReadingExportTasksArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMeterReadingExportTasksBy>>
    where?: InputMaybe<MeterReadingExportTaskWhereInput>
}


export type QueryAllMeterReadingFilterTemplateHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMeterReadingFilterTemplateHistoryRecordsBy>>
    where?: InputMaybe<MeterReadingFilterTemplateHistoryRecordWhereInput>
}


export type QueryAllMeterReadingFilterTemplatesArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMeterReadingFilterTemplatesBy>>
    where?: InputMaybe<MeterReadingFilterTemplateWhereInput>
}


export type QueryAllMeterReadingHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMeterReadingHistoryRecordsBy>>
    where?: InputMaybe<MeterReadingHistoryRecordWhereInput>
}


export type QueryAllMeterReadingSourceHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMeterReadingSourceHistoryRecordsBy>>
    where?: InputMaybe<MeterReadingSourceHistoryRecordWhereInput>
}


export type QueryAllMeterReadingSourcesArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMeterReadingSourcesBy>>
    where?: InputMaybe<MeterReadingSourceWhereInput>
}


export type QueryAllMeterReadingsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMeterReadingsBy>>
    where?: InputMaybe<MeterReadingWhereInput>
}


export type QueryAllMeterReadingsImportTaskHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMeterReadingsImportTaskHistoryRecordsBy>>
    where?: InputMaybe<MeterReadingsImportTaskHistoryRecordWhereInput>
}


export type QueryAllMeterReadingsImportTasksArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMeterReadingsImportTasksBy>>
    where?: InputMaybe<MeterReadingsImportTaskWhereInput>
}


export type QueryAllMeterReportingPeriodHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMeterReportingPeriodHistoryRecordsBy>>
    where?: InputMaybe<MeterReportingPeriodHistoryRecordWhereInput>
}


export type QueryAllMeterReportingPeriodsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMeterReportingPeriodsBy>>
    where?: InputMaybe<MeterReportingPeriodWhereInput>
}


export type QueryAllMeterResourceHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMeterResourceHistoryRecordsBy>>
    where?: InputMaybe<MeterResourceHistoryRecordWhereInput>
}


export type QueryAllMeterResourceOwnerHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMeterResourceOwnerHistoryRecordsBy>>
    where?: InputMaybe<MeterResourceOwnerHistoryRecordWhereInput>
}


export type QueryAllMeterResourceOwnersArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMeterResourceOwnersBy>>
    where?: InputMaybe<MeterResourceOwnerWhereInput>
}


export type QueryAllMeterResourcesArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMeterResourcesBy>>
    where?: InputMaybe<MeterResourceWhereInput>
}


export type QueryAllMetersArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMetersBy>>
    where?: InputMaybe<MeterWhereInput>
}


export type QueryAllMiniAppsArgs = {
    data: AllMiniAppsInput
}


export type QueryAllMobileFeatureConfigHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMobileFeatureConfigHistoryRecordsBy>>
    where?: InputMaybe<MobileFeatureConfigHistoryRecordWhereInput>
}


export type QueryAllMobileFeatureConfigsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMobileFeatureConfigsBy>>
    where?: InputMaybe<MobileFeatureConfigWhereInput>
}


export type QueryAllMultiPaymentHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMultiPaymentHistoryRecordsBy>>
    where?: InputMaybe<MultiPaymentHistoryRecordWhereInput>
}


export type QueryAllMultiPaymentsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortMultiPaymentsBy>>
    where?: InputMaybe<MultiPaymentWhereInput>
}


export type QueryAllNewsItemHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortNewsItemHistoryRecordsBy>>
    where?: InputMaybe<NewsItemHistoryRecordWhereInput>
}


export type QueryAllNewsItemRecipientsExportTaskHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortNewsItemRecipientsExportTaskHistoryRecordsBy>>
    where?: InputMaybe<NewsItemRecipientsExportTaskHistoryRecordWhereInput>
}


export type QueryAllNewsItemRecipientsExportTasksArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortNewsItemRecipientsExportTasksBy>>
    where?: InputMaybe<NewsItemRecipientsExportTaskWhereInput>
}


export type QueryAllNewsItemScopeHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortNewsItemScopeHistoryRecordsBy>>
    where?: InputMaybe<NewsItemScopeHistoryRecordWhereInput>
}


export type QueryAllNewsItemScopesArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortNewsItemScopesBy>>
    where?: InputMaybe<NewsItemScopeWhereInput>
}


export type QueryAllNewsItemSharingHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortNewsItemSharingHistoryRecordsBy>>
    where?: InputMaybe<NewsItemSharingHistoryRecordWhereInput>
}


export type QueryAllNewsItemSharingsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortNewsItemSharingsBy>>
    where?: InputMaybe<NewsItemSharingWhereInput>
}


export type QueryAllNewsItemTemplateHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortNewsItemTemplateHistoryRecordsBy>>
    where?: InputMaybe<NewsItemTemplateHistoryRecordWhereInput>
}


export type QueryAllNewsItemTemplatesArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortNewsItemTemplatesBy>>
    where?: InputMaybe<NewsItemTemplateWhereInput>
}


export type QueryAllNewsItemUserReadHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortNewsItemUserReadHistoryRecordsBy>>
    where?: InputMaybe<NewsItemUserReadHistoryRecordWhereInput>
}


export type QueryAllNewsItemUserReadsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortNewsItemUserReadsBy>>
    where?: InputMaybe<NewsItemUserReadWhereInput>
}


export type QueryAllNewsItemsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortNewsItemsBy>>
    where?: InputMaybe<NewsItemWhereInput>
}


export type QueryAllNotificationAnonymousSettingHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortNotificationAnonymousSettingHistoryRecordsBy>>
    where?: InputMaybe<NotificationAnonymousSettingHistoryRecordWhereInput>
}


export type QueryAllNotificationAnonymousSettingsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortNotificationAnonymousSettingsBy>>
    where?: InputMaybe<NotificationAnonymousSettingWhereInput>
}


export type QueryAllNotificationUserSettingHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortNotificationUserSettingHistoryRecordsBy>>
    where?: InputMaybe<NotificationUserSettingHistoryRecordWhereInput>
}


export type QueryAllNotificationUserSettingsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortNotificationUserSettingsBy>>
    where?: InputMaybe<NotificationUserSettingWhereInput>
}


export type QueryAllOidcClientHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortOidcClientHistoryRecordsBy>>
    where?: InputMaybe<OidcClientHistoryRecordWhereInput>
}


export type QueryAllOidcClientsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortOidcClientsBy>>
    where?: InputMaybe<OidcClientWhereInput>
}


export type QueryAllOrganizationEmployeeHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortOrganizationEmployeeHistoryRecordsBy>>
    where?: InputMaybe<OrganizationEmployeeHistoryRecordWhereInput>
}


export type QueryAllOrganizationEmployeeRoleHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortOrganizationEmployeeRoleHistoryRecordsBy>>
    where?: InputMaybe<OrganizationEmployeeRoleHistoryRecordWhereInput>
}


export type QueryAllOrganizationEmployeeRolesArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortOrganizationEmployeeRolesBy>>
    where?: InputMaybe<OrganizationEmployeeRoleWhereInput>
}


export type QueryAllOrganizationEmployeeSpecializationHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortOrganizationEmployeeSpecializationHistoryRecordsBy>>
    where?: InputMaybe<OrganizationEmployeeSpecializationHistoryRecordWhereInput>
}


export type QueryAllOrganizationEmployeeSpecializationsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortOrganizationEmployeeSpecializationsBy>>
    where?: InputMaybe<OrganizationEmployeeSpecializationWhereInput>
}


export type QueryAllOrganizationEmployeesArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortOrganizationEmployeesBy>>
    where?: InputMaybe<OrganizationEmployeeWhereInput>
}


export type QueryAllOrganizationHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortOrganizationHistoryRecordsBy>>
    where?: InputMaybe<OrganizationHistoryRecordWhereInput>
}


export type QueryAllOrganizationLinkHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortOrganizationLinkHistoryRecordsBy>>
    where?: InputMaybe<OrganizationLinkHistoryRecordWhereInput>
}


export type QueryAllOrganizationLinksArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortOrganizationLinksBy>>
    where?: InputMaybe<OrganizationLinkWhereInput>
}


export type QueryAllOrganizationsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortOrganizationsBy>>
    where?: InputMaybe<OrganizationWhereInput>
}


export type QueryAllPaymentHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortPaymentHistoryRecordsBy>>
    where?: InputMaybe<PaymentHistoryRecordWhereInput>
}


export type QueryAllPaymentsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortPaymentsBy>>
    where?: InputMaybe<PaymentWhereInput>
}


export type QueryAllPaymentsFilterTemplateHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortPaymentsFilterTemplateHistoryRecordsBy>>
    where?: InputMaybe<PaymentsFilterTemplateHistoryRecordWhereInput>
}


export type QueryAllPaymentsFilterTemplatesArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortPaymentsFilterTemplatesBy>>
    where?: InputMaybe<PaymentsFilterTemplateWhereInput>
}


export type QueryAllPropertiesArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortPropertiesBy>>
    where?: InputMaybe<PropertyWhereInput>
}


export type QueryAllPropertyHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortPropertyHistoryRecordsBy>>
    where?: InputMaybe<PropertyHistoryRecordWhereInput>
}


export type QueryAllPropertyMeterHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortPropertyMeterHistoryRecordsBy>>
    where?: InputMaybe<PropertyMeterHistoryRecordWhereInput>
}


export type QueryAllPropertyMeterReadingHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortPropertyMeterReadingHistoryRecordsBy>>
    where?: InputMaybe<PropertyMeterReadingHistoryRecordWhereInput>
}


export type QueryAllPropertyMeterReadingsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortPropertyMeterReadingsBy>>
    where?: InputMaybe<PropertyMeterReadingWhereInput>
}


export type QueryAllPropertyMetersArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortPropertyMetersBy>>
    where?: InputMaybe<PropertyMeterWhereInput>
}


export type QueryAllPropertyScopeHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortPropertyScopeHistoryRecordsBy>>
    where?: InputMaybe<PropertyScopeHistoryRecordWhereInput>
}


export type QueryAllPropertyScopeOrganizationEmployeeHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortPropertyScopeOrganizationEmployeeHistoryRecordsBy>>
    where?: InputMaybe<PropertyScopeOrganizationEmployeeHistoryRecordWhereInput>
}


export type QueryAllPropertyScopeOrganizationEmployeesArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortPropertyScopeOrganizationEmployeesBy>>
    where?: InputMaybe<PropertyScopeOrganizationEmployeeWhereInput>
}


export type QueryAllPropertyScopePropertiesArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortPropertyScopePropertiesBy>>
    where?: InputMaybe<PropertyScopePropertyWhereInput>
}


export type QueryAllPropertyScopePropertyHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortPropertyScopePropertyHistoryRecordsBy>>
    where?: InputMaybe<PropertyScopePropertyHistoryRecordWhereInput>
}


export type QueryAllPropertyScopesArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortPropertyScopesBy>>
    where?: InputMaybe<PropertyScopeWhereInput>
}


export type QueryAllRecurrentPaymentContextHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortRecurrentPaymentContextHistoryRecordsBy>>
    where?: InputMaybe<RecurrentPaymentContextHistoryRecordWhereInput>
}


export type QueryAllRecurrentPaymentContextsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortRecurrentPaymentContextsBy>>
    where?: InputMaybe<RecurrentPaymentContextWhereInput>
}


export type QueryAllRecurrentPaymentHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortRecurrentPaymentHistoryRecordsBy>>
    where?: InputMaybe<RecurrentPaymentHistoryRecordWhereInput>
}


export type QueryAllRecurrentPaymentsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortRecurrentPaymentsBy>>
    where?: InputMaybe<RecurrentPaymentWhereInput>
}


export type QueryAllRemoteClientHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortRemoteClientHistoryRecordsBy>>
    where?: InputMaybe<RemoteClientHistoryRecordWhereInput>
}


export type QueryAllRemoteClientsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortRemoteClientsBy>>
    where?: InputMaybe<RemoteClientWhereInput>
}


export type QueryAllResidentBillingReceiptsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortResidentBillingReceiptsBy>>
    where?: InputMaybe<ResidentBillingReceiptWhereInput>
}


export type QueryAllResidentBillingVirtualReceiptsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortResidentBillingVirtualReceiptsBy>>
    where?: InputMaybe<ResidentBillingVirtualReceiptWhereInput>
}


export type QueryAllResidentHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortResidentHistoryRecordsBy>>
    where?: InputMaybe<ResidentHistoryRecordWhereInput>
}


export type QueryAllResidentsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortResidentsBy>>
    where?: InputMaybe<ResidentWhereInput>
}


export type QueryAllServiceConsumerHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortServiceConsumerHistoryRecordsBy>>
    where?: InputMaybe<ServiceConsumerHistoryRecordWhereInput>
}


export type QueryAllServiceConsumersArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortServiceConsumersBy>>
    where?: InputMaybe<ServiceConsumerWhereInput>
}


export type QueryAllServiceSubscriptionHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortServiceSubscriptionHistoryRecordsBy>>
    where?: InputMaybe<ServiceSubscriptionHistoryRecordWhereInput>
}


export type QueryAllServiceSubscriptionsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortServiceSubscriptionsBy>>
    where?: InputMaybe<ServiceSubscriptionWhereInput>
}


export type QueryAllTelegramUserChatHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTelegramUserChatHistoryRecordsBy>>
    where?: InputMaybe<TelegramUserChatHistoryRecordWhereInput>
}


export type QueryAllTelegramUserChatsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTelegramUserChatsBy>>
    where?: InputMaybe<TelegramUserChatWhereInput>
}


export type QueryAllTicketAutoAssignmentHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketAutoAssignmentHistoryRecordsBy>>
    where?: InputMaybe<TicketAutoAssignmentHistoryRecordWhereInput>
}


export type QueryAllTicketAutoAssignmentsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketAutoAssignmentsBy>>
    where?: InputMaybe<TicketAutoAssignmentWhereInput>
}


export type QueryAllTicketCategoryClassifierHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketCategoryClassifierHistoryRecordsBy>>
    where?: InputMaybe<TicketCategoryClassifierHistoryRecordWhereInput>
}


export type QueryAllTicketCategoryClassifiersArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketCategoryClassifiersBy>>
    where?: InputMaybe<TicketCategoryClassifierWhereInput>
}


export type QueryAllTicketChangesArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketChangesBy>>
    where?: InputMaybe<TicketChangeWhereInput>
}


export type QueryAllTicketClassifierHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketClassifierHistoryRecordsBy>>
    where?: InputMaybe<TicketClassifierHistoryRecordWhereInput>
}


export type QueryAllTicketClassifiersArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketClassifiersBy>>
    where?: InputMaybe<TicketClassifierWhereInput>
}


export type QueryAllTicketCommentFileHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketCommentFileHistoryRecordsBy>>
    where?: InputMaybe<TicketCommentFileHistoryRecordWhereInput>
}


export type QueryAllTicketCommentFilesArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketCommentFilesBy>>
    where?: InputMaybe<TicketCommentFileWhereInput>
}


export type QueryAllTicketCommentHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketCommentHistoryRecordsBy>>
    where?: InputMaybe<TicketCommentHistoryRecordWhereInput>
}


export type QueryAllTicketCommentsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketCommentsBy>>
    where?: InputMaybe<TicketCommentWhereInput>
}


export type QueryAllTicketDocumentGenerationTaskHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketDocumentGenerationTaskHistoryRecordsBy>>
    where?: InputMaybe<TicketDocumentGenerationTaskHistoryRecordWhereInput>
}


export type QueryAllTicketDocumentGenerationTasksArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketDocumentGenerationTasksBy>>
    where?: InputMaybe<TicketDocumentGenerationTaskWhereInput>
}


export type QueryAllTicketExportTaskHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketExportTaskHistoryRecordsBy>>
    where?: InputMaybe<TicketExportTaskHistoryRecordWhereInput>
}


export type QueryAllTicketExportTasksArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketExportTasksBy>>
    where?: InputMaybe<TicketExportTaskWhereInput>
}


export type QueryAllTicketFileHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketFileHistoryRecordsBy>>
    where?: InputMaybe<TicketFileHistoryRecordWhereInput>
}


export type QueryAllTicketFilesArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketFilesBy>>
    where?: InputMaybe<TicketFileWhereInput>
}


export type QueryAllTicketFilterTemplateHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketFilterTemplateHistoryRecordsBy>>
    where?: InputMaybe<TicketFilterTemplateHistoryRecordWhereInput>
}


export type QueryAllTicketFilterTemplatesArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketFilterTemplatesBy>>
    where?: InputMaybe<TicketFilterTemplateWhereInput>
}


export type QueryAllTicketHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketHistoryRecordsBy>>
    where?: InputMaybe<TicketHistoryRecordWhereInput>
}


export type QueryAllTicketOrganizationSettingHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketOrganizationSettingHistoryRecordsBy>>
    where?: InputMaybe<TicketOrganizationSettingHistoryRecordWhereInput>
}


export type QueryAllTicketOrganizationSettingsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketOrganizationSettingsBy>>
    where?: InputMaybe<TicketOrganizationSettingWhereInput>
}


export type QueryAllTicketPlaceClassifierHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketPlaceClassifierHistoryRecordsBy>>
    where?: InputMaybe<TicketPlaceClassifierHistoryRecordWhereInput>
}


export type QueryAllTicketPlaceClassifiersArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketPlaceClassifiersBy>>
    where?: InputMaybe<TicketPlaceClassifierWhereInput>
}


export type QueryAllTicketProblemClassifierHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketProblemClassifierHistoryRecordsBy>>
    where?: InputMaybe<TicketProblemClassifierHistoryRecordWhereInput>
}


export type QueryAllTicketProblemClassifiersArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketProblemClassifiersBy>>
    where?: InputMaybe<TicketProblemClassifierWhereInput>
}


export type QueryAllTicketPropertyHintHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketPropertyHintHistoryRecordsBy>>
    where?: InputMaybe<TicketPropertyHintHistoryRecordWhereInput>
}


export type QueryAllTicketPropertyHintPropertiesArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketPropertyHintPropertiesBy>>
    where?: InputMaybe<TicketPropertyHintPropertyWhereInput>
}


export type QueryAllTicketPropertyHintPropertyHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketPropertyHintPropertyHistoryRecordsBy>>
    where?: InputMaybe<TicketPropertyHintPropertyHistoryRecordWhereInput>
}


export type QueryAllTicketPropertyHintsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketPropertyHintsBy>>
    where?: InputMaybe<TicketPropertyHintWhereInput>
}


export type QueryAllTicketSourceHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketSourceHistoryRecordsBy>>
    where?: InputMaybe<TicketSourceHistoryRecordWhereInput>
}


export type QueryAllTicketSourcesArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketSourcesBy>>
    where?: InputMaybe<TicketSourceWhereInput>
}


export type QueryAllTicketStatusHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketStatusHistoryRecordsBy>>
    where?: InputMaybe<TicketStatusHistoryRecordWhereInput>
}


export type QueryAllTicketStatusesArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketStatusesBy>>
    where?: InputMaybe<TicketStatusWhereInput>
}


export type QueryAllTicketsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTicketsBy>>
    where?: InputMaybe<TicketWhereInput>
}


export type QueryAllTourStepHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTourStepHistoryRecordsBy>>
    where?: InputMaybe<TourStepHistoryRecordWhereInput>
}


export type QueryAllTourStepsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortTourStepsBy>>
    where?: InputMaybe<TourStepWhereInput>
}


export type QueryAllUserExternalIdentitiesArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortUserExternalIdentitiesBy>>
    where?: InputMaybe<UserExternalIdentityWhereInput>
}


export type QueryAllUserExternalIdentityHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortUserExternalIdentityHistoryRecordsBy>>
    where?: InputMaybe<UserExternalIdentityHistoryRecordWhereInput>
}


export type QueryAllUserFavoriteTicketHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortUserFavoriteTicketHistoryRecordsBy>>
    where?: InputMaybe<UserFavoriteTicketHistoryRecordWhereInput>
}


export type QueryAllUserFavoriteTicketsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortUserFavoriteTicketsBy>>
    where?: InputMaybe<UserFavoriteTicketWhereInput>
}


export type QueryAllUserHelpRequestFileHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortUserHelpRequestFileHistoryRecordsBy>>
    where?: InputMaybe<UserHelpRequestFileHistoryRecordWhereInput>
}


export type QueryAllUserHelpRequestFilesArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortUserHelpRequestFilesBy>>
    where?: InputMaybe<UserHelpRequestFileWhereInput>
}


export type QueryAllUserHelpRequestHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortUserHelpRequestHistoryRecordsBy>>
    where?: InputMaybe<UserHelpRequestHistoryRecordWhereInput>
}


export type QueryAllUserHelpRequestsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortUserHelpRequestsBy>>
    where?: InputMaybe<UserHelpRequestWhereInput>
}


export type QueryAllUserHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortUserHistoryRecordsBy>>
    where?: InputMaybe<UserHistoryRecordWhereInput>
}


export type QueryAllUserRightsSetHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortUserRightsSetHistoryRecordsBy>>
    where?: InputMaybe<UserRightsSetHistoryRecordWhereInput>
}


export type QueryAllUserRightsSetsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortUserRightsSetsBy>>
    where?: InputMaybe<UserRightsSetWhereInput>
}


export type QueryAllUserTicketCommentReadTimeHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortUserTicketCommentReadTimeHistoryRecordsBy>>
    where?: InputMaybe<UserTicketCommentReadTimeHistoryRecordWhereInput>
}


export type QueryAllUserTicketCommentReadTimesArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortUserTicketCommentReadTimesBy>>
    where?: InputMaybe<UserTicketCommentReadTimeWhereInput>
}


export type QueryAllUsersArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortUsersBy>>
    where?: InputMaybe<UserWhereInput>
}


export type QueryAllWebhookHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortWebhookHistoryRecordsBy>>
    where?: InputMaybe<WebhookHistoryRecordWhereInput>
}


export type QueryAllWebhookSubscriptionHistoryRecordsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortWebhookSubscriptionHistoryRecordsBy>>
    where?: InputMaybe<WebhookSubscriptionHistoryRecordWhereInput>
}


export type QueryAllWebhookSubscriptionsArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortWebhookSubscriptionsBy>>
    where?: InputMaybe<WebhookSubscriptionWhereInput>
}


export type QueryAllWebhooksArgs = {
    first?: InputMaybe<Scalars['Int']['input']>
    orderBy?: InputMaybe<Scalars['String']['input']>
    search?: InputMaybe<Scalars['String']['input']>
    skip?: InputMaybe<Scalars['Int']['input']>
    sortBy?: InputMaybe<Array<SortWebhooksBy>>
    where?: InputMaybe<WebhookWhereInput>
}


export type QueryCalculateFeeForReceiptArgs = {
    data: CalculateFeeForReceiptInput
}


export type QueryCheckUserExistenceArgs = {
    data: CheckUserExistenceInput
}


export type QueryExportPaymentsToExcelArgs = {
    data: ExportPaymentsToExcelInput
}


export type QueryExportPropertiesToExcelArgs = {
    data: ExportPropertiesToExcelInput
}


export type QueryExportPropertyMeterReadingsArgs = {
    data: ExportPropertyMeterReadingsInput
}


export type QueryExportPropertyScopesToExcelArgs = {
    data: ExportPropertyScopeToExcelInput
}


export type QueryExportTicketAnalyticsToExcelArgs = {
    data?: InputMaybe<ExportTicketAnalyticsToExcelInput>
}


export type QueryFindOrganizationsByAddressArgs = {
    data: FindOrganizationsByAddressInput
}


export type QueryGeneratePaymentLinkArgs = {
    data: GeneratePaymentLinkInput
}


export type QueryGetAccessTokenByUserIdArgs = {
    data: GetAccessTokenByUserIdInput
}


export type QueryGetNewsItemsRecipientsCountersArgs = {
    data: GetNewsItemsRecipientsCountersInput
}


export type QueryGetNewsSharingRecipientsArgs = {
    data: GetNewsSharingRecipientsInput
}


export type QueryGetNewsSharingRecipientsCountersArgs = {
    data: GetNewsSharingRecipientsCountersInput
}


export type QueryGetOverviewDashboardArgs = {
    data: GetOverviewDashboardInput
}


export type QueryGetPhoneByConfirmPhoneActionTokenArgs = {
    data: GetPhoneByConfirmPhoneActionTokenInput
}


export type QueryGetResidentExistenceByPhoneAndAddressArgs = {
    data: GetResidentExistenceByPhoneAndAddressInput
}


export type QueryPredictTicketClassificationArgs = {
    data: PredictTicketClassificationInput
}


export type QueryPredictTransactionClassificationArgs = {
    data: PredictTransactionClassificationInput
}


export type QuerySuggestServiceProviderArgs = {
    data: SuggestServiceProviderInput
}


export type QueryTicketAnalyticsReportArgs = {
    data?: InputMaybe<TicketAnalyticsReportInput>
}


export type QueryTicketReportWidgetDataArgs = {
    data: TicketReportWidgetInput
}

export type ReInviteOrganizationEmployeeInput = {
    dv: Scalars['Int']['input']
    email?: InputMaybe<Scalars['String']['input']>
    organization: OrganizationWhereUniqueInput
    phone: Scalars['String']['input']
    sender: SenderFieldInput
}

export type ReceiptGroupedCounter = {
    __typename?: 'ReceiptGroupedCounter'
    count: Scalars['String']['output']
    dayGroup: Scalars['String']['output']
    sum: Scalars['String']['output']
}

export type ReceiptOverviewResult = {
    __typename?: 'ReceiptOverviewResult'
    receipts?: Maybe<Array<ReceiptGroupedCounter>>
    sum: Scalars['String']['output']
}

export type RecipientField = {
    __typename?: 'RecipientField'
    bankAccount: Scalars['String']['output']
    bankName?: Maybe<Scalars['String']['output']>
    bic: Scalars['String']['output']
    classificationCode?: Maybe<Scalars['String']['output']>
    iec?: Maybe<Scalars['String']['output']>
    name?: Maybe<Scalars['String']['output']>
    offsettingAccount?: Maybe<Scalars['String']['output']>
    territoryCode?: Maybe<Scalars['String']['output']>
    tin: Scalars['String']['output']
}

export type RecipientFieldInput = {
    bankAccount: Scalars['String']['input']
    bankName?: InputMaybe<Scalars['String']['input']>
    bic: Scalars['String']['input']
    classificationCode?: InputMaybe<Scalars['String']['input']>
    iec?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    offsettingAccount?: InputMaybe<Scalars['String']['input']>
    territoryCode?: InputMaybe<Scalars['String']['input']>
    tin: Scalars['String']['input']
}

/**  Hold a snapshot of monthly payment data. Used by background recurrent payment process.  */
export type RecurrentPayment = {
    __typename?: 'RecurrentPayment'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the RecurrentPayment List config, or
   *  2. As an alias to the field set on 'labelField' in the RecurrentPayment List config, or
   *  3. As an alias to a 'name' field on the RecurrentPayment List (if one exists), or
   *  4. As an alias to the 'id' field on the RecurrentPayment List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  Contains information about the distribution of a certain type of fee  */
    billingReceipts?: Maybe<Array<BillingReceiptsField>>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['String']['output']>
    /**  Payment proceeding should start after date.  */
    payAfter?: Maybe<Scalars['String']['output']>
    /**  Link to RecurrentPaymentContext  */
    recurrentPaymentContext?: Maybe<RecurrentPaymentContext>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Hold current state of payment proceeding.  */
    state?: Maybe<Scalars['JSON']['output']>
    /**  Payment proceeding status  */
    status?: Maybe<RecurrentPaymentStatusType>
    /**  Count of proceeding tries.  */
    tryCount?: Maybe<Scalars['Int']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

/**  Recurrent payments context configuration. Holding settings, limits and etc, configured by resident for background process that proceeding resident`s receipts on monthly basis.  */
export type RecurrentPaymentContext = {
    __typename?: 'RecurrentPaymentContext'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the RecurrentPaymentContext List config, or
   *  2. As an alias to the field set on 'labelField' in the RecurrentPaymentContext List config, or
   *  3. As an alias to a 'name' field on the RecurrentPaymentContext List (if one exists), or
   *  4. As an alias to the 'id' field on the RecurrentPaymentContext List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  Pay for billing receipts right after they was created. Only one trigger should be configured: autoPayReceipts or paymentDay.  */
    autoPayReceipts?: Maybe<Scalars['Boolean']['output']>
    /**  Link to BillingCategory  */
    billingCategory?: Maybe<BillingCategory>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  Enable or Disable recurrent payment proceeding.  */
    enabled?: Maybe<Scalars['Boolean']['output']>
    id: Scalars['ID']['output']
    /**  Maximal `toPay` amount of multi payment that can be proceeded. Otherwise payment should be interrupted.  */
    limit?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  The day of month when resident`s receipts going to be proceeded. Only one trigger should be configured: autoPayReceipts or paymentDay.  */
    paymentDay?: Maybe<Scalars['Int']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Link to ServiceConsumer  */
    serviceConsumer?: Maybe<ServiceConsumer>
    /**  Settings. Should contain recurrent payment context configuration.  */
    settings?: Maybe<SettingsField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type RecurrentPaymentContextCreateInput = {
    autoPayReceipts?: InputMaybe<Scalars['Boolean']['input']>
    billingCategory?: InputMaybe<BillingCategoryRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    enabled?: InputMaybe<Scalars['Boolean']['input']>
    limit?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    paymentDay?: InputMaybe<Scalars['Int']['input']>
    sender?: InputMaybe<SenderFieldInput>
    serviceConsumer?: InputMaybe<ServiceConsumerRelateToOneInput>
    settings?: InputMaybe<SettingsFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type RecurrentPaymentContextHistoryRecord = {
    __typename?: 'RecurrentPaymentContextHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the RecurrentPaymentContextHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the RecurrentPaymentContextHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the RecurrentPaymentContextHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the RecurrentPaymentContextHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    autoPayReceipts?: Maybe<Scalars['Boolean']['output']>
    billingCategory?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    enabled?: Maybe<Scalars['Boolean']['output']>
    history_action?: Maybe<RecurrentPaymentContextHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    limit?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    paymentDay?: Maybe<Scalars['Int']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    serviceConsumer?: Maybe<Scalars['String']['output']>
    settings?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type RecurrentPaymentContextHistoryRecordCreateInput = {
    autoPayReceipts?: InputMaybe<Scalars['Boolean']['input']>
    billingCategory?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    enabled?: InputMaybe<Scalars['Boolean']['input']>
    history_action?: InputMaybe<RecurrentPaymentContextHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    limit?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    paymentDay?: InputMaybe<Scalars['Int']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    serviceConsumer?: InputMaybe<Scalars['String']['input']>
    settings?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum RecurrentPaymentContextHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type RecurrentPaymentContextHistoryRecordUpdateInput = {
    autoPayReceipts?: InputMaybe<Scalars['Boolean']['input']>
    billingCategory?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    enabled?: InputMaybe<Scalars['Boolean']['input']>
    history_action?: InputMaybe<RecurrentPaymentContextHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    limit?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    paymentDay?: InputMaybe<Scalars['Int']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    serviceConsumer?: InputMaybe<Scalars['String']['input']>
    settings?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type RecurrentPaymentContextHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<RecurrentPaymentContextHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<RecurrentPaymentContextHistoryRecordWhereInput>>>
    autoPayReceipts?: InputMaybe<Scalars['Boolean']['input']>
    autoPayReceipts_not?: InputMaybe<Scalars['Boolean']['input']>
    billingCategory?: InputMaybe<Scalars['String']['input']>
    billingCategory_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    billingCategory_not?: InputMaybe<Scalars['String']['input']>
    billingCategory_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    enabled?: InputMaybe<Scalars['Boolean']['input']>
    enabled_not?: InputMaybe<Scalars['Boolean']['input']>
    history_action?: InputMaybe<RecurrentPaymentContextHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<RecurrentPaymentContextHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<RecurrentPaymentContextHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<RecurrentPaymentContextHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    limit?: InputMaybe<Scalars['String']['input']>
    limit_gt?: InputMaybe<Scalars['String']['input']>
    limit_gte?: InputMaybe<Scalars['String']['input']>
    limit_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    limit_lt?: InputMaybe<Scalars['String']['input']>
    limit_lte?: InputMaybe<Scalars['String']['input']>
    limit_not?: InputMaybe<Scalars['String']['input']>
    limit_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    paymentDay?: InputMaybe<Scalars['Int']['input']>
    paymentDay_gt?: InputMaybe<Scalars['Int']['input']>
    paymentDay_gte?: InputMaybe<Scalars['Int']['input']>
    paymentDay_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    paymentDay_lt?: InputMaybe<Scalars['Int']['input']>
    paymentDay_lte?: InputMaybe<Scalars['Int']['input']>
    paymentDay_not?: InputMaybe<Scalars['Int']['input']>
    paymentDay_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    serviceConsumer?: InputMaybe<Scalars['String']['input']>
    serviceConsumer_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    serviceConsumer_not?: InputMaybe<Scalars['String']['input']>
    serviceConsumer_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    settings?: InputMaybe<Scalars['JSON']['input']>
    settings_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    settings_not?: InputMaybe<Scalars['JSON']['input']>
    settings_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type RecurrentPaymentContextHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type RecurrentPaymentContextHistoryRecordsCreateInput = {
    data?: InputMaybe<RecurrentPaymentContextHistoryRecordCreateInput>
}

export type RecurrentPaymentContextHistoryRecordsUpdateInput = {
    data?: InputMaybe<RecurrentPaymentContextHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type RecurrentPaymentContextRelateToOneInput = {
    connect?: InputMaybe<RecurrentPaymentContextWhereUniqueInput>
    create?: InputMaybe<RecurrentPaymentContextCreateInput>
    disconnect?: InputMaybe<RecurrentPaymentContextWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type RecurrentPaymentContextUpdateInput = {
    autoPayReceipts?: InputMaybe<Scalars['Boolean']['input']>
    billingCategory?: InputMaybe<BillingCategoryRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    enabled?: InputMaybe<Scalars['Boolean']['input']>
    limit?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    paymentDay?: InputMaybe<Scalars['Int']['input']>
    sender?: InputMaybe<SenderFieldInput>
    serviceConsumer?: InputMaybe<ServiceConsumerRelateToOneInput>
    settings?: InputMaybe<SettingsFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type RecurrentPaymentContextWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<RecurrentPaymentContextWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<RecurrentPaymentContextWhereInput>>>
    autoPayReceipts?: InputMaybe<Scalars['Boolean']['input']>
    autoPayReceipts_not?: InputMaybe<Scalars['Boolean']['input']>
    billingCategory?: InputMaybe<BillingCategoryWhereInput>
    billingCategory_is_null?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    enabled?: InputMaybe<Scalars['Boolean']['input']>
    enabled_not?: InputMaybe<Scalars['Boolean']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    limit?: InputMaybe<Scalars['String']['input']>
    limit_gt?: InputMaybe<Scalars['String']['input']>
    limit_gte?: InputMaybe<Scalars['String']['input']>
    limit_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    limit_lt?: InputMaybe<Scalars['String']['input']>
    limit_lte?: InputMaybe<Scalars['String']['input']>
    limit_not?: InputMaybe<Scalars['String']['input']>
    limit_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    paymentDay?: InputMaybe<Scalars['Int']['input']>
    paymentDay_gt?: InputMaybe<Scalars['Int']['input']>
    paymentDay_gte?: InputMaybe<Scalars['Int']['input']>
    paymentDay_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    paymentDay_lt?: InputMaybe<Scalars['Int']['input']>
    paymentDay_lte?: InputMaybe<Scalars['Int']['input']>
    paymentDay_not?: InputMaybe<Scalars['Int']['input']>
    paymentDay_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    serviceConsumer?: InputMaybe<ServiceConsumerWhereInput>
    serviceConsumer_is_null?: InputMaybe<Scalars['Boolean']['input']>
    settings?: InputMaybe<SettingsFieldInput>
    settings_in?: InputMaybe<Array<InputMaybe<SettingsFieldInput>>>
    settings_not?: InputMaybe<SettingsFieldInput>
    settings_not_in?: InputMaybe<Array<InputMaybe<SettingsFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type RecurrentPaymentContextWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type RecurrentPaymentContextsCreateInput = {
    data?: InputMaybe<RecurrentPaymentContextCreateInput>
}

export type RecurrentPaymentContextsUpdateInput = {
    data?: InputMaybe<RecurrentPaymentContextUpdateInput>
    id: Scalars['ID']['input']
}

export type RecurrentPaymentCreateInput = {
    billingReceipts?: InputMaybe<Array<BillingReceiptsFieldInput>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    payAfter?: InputMaybe<Scalars['String']['input']>
    recurrentPaymentContext?: InputMaybe<RecurrentPaymentContextRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    state?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<RecurrentPaymentStatusType>
    tryCount?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type RecurrentPaymentHistoryRecord = {
    __typename?: 'RecurrentPaymentHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the RecurrentPaymentHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the RecurrentPaymentHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the RecurrentPaymentHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the RecurrentPaymentHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    billingReceipts?: Maybe<Scalars['JSON']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<RecurrentPaymentHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['JSON']['output']>
    payAfter?: Maybe<Scalars['String']['output']>
    recurrentPaymentContext?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    state?: Maybe<Scalars['JSON']['output']>
    status?: Maybe<Scalars['String']['output']>
    tryCount?: Maybe<Scalars['Int']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type RecurrentPaymentHistoryRecordCreateInput = {
    billingReceipts?: InputMaybe<Scalars['JSON']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<RecurrentPaymentHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    payAfter?: InputMaybe<Scalars['String']['input']>
    recurrentPaymentContext?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    state?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    tryCount?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum RecurrentPaymentHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type RecurrentPaymentHistoryRecordUpdateInput = {
    billingReceipts?: InputMaybe<Scalars['JSON']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<RecurrentPaymentHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    payAfter?: InputMaybe<Scalars['String']['input']>
    recurrentPaymentContext?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    state?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    tryCount?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type RecurrentPaymentHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<RecurrentPaymentHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<RecurrentPaymentHistoryRecordWhereInput>>>
    billingReceipts?: InputMaybe<Scalars['JSON']['input']>
    billingReceipts_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    billingReceipts_not?: InputMaybe<Scalars['JSON']['input']>
    billingReceipts_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<RecurrentPaymentHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<RecurrentPaymentHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<RecurrentPaymentHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<RecurrentPaymentHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    payAfter?: InputMaybe<Scalars['String']['input']>
    payAfter_gt?: InputMaybe<Scalars['String']['input']>
    payAfter_gte?: InputMaybe<Scalars['String']['input']>
    payAfter_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    payAfter_lt?: InputMaybe<Scalars['String']['input']>
    payAfter_lte?: InputMaybe<Scalars['String']['input']>
    payAfter_not?: InputMaybe<Scalars['String']['input']>
    payAfter_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    recurrentPaymentContext?: InputMaybe<Scalars['String']['input']>
    recurrentPaymentContext_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    recurrentPaymentContext_not?: InputMaybe<Scalars['String']['input']>
    recurrentPaymentContext_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    state?: InputMaybe<Scalars['JSON']['input']>
    state_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    state_not?: InputMaybe<Scalars['JSON']['input']>
    state_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    status?: InputMaybe<Scalars['String']['input']>
    status_contains?: InputMaybe<Scalars['String']['input']>
    status_contains_i?: InputMaybe<Scalars['String']['input']>
    status_ends_with?: InputMaybe<Scalars['String']['input']>
    status_ends_with_i?: InputMaybe<Scalars['String']['input']>
    status_i?: InputMaybe<Scalars['String']['input']>
    status_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status_not?: InputMaybe<Scalars['String']['input']>
    status_not_contains?: InputMaybe<Scalars['String']['input']>
    status_not_contains_i?: InputMaybe<Scalars['String']['input']>
    status_not_ends_with?: InputMaybe<Scalars['String']['input']>
    status_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    status_not_i?: InputMaybe<Scalars['String']['input']>
    status_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status_not_starts_with?: InputMaybe<Scalars['String']['input']>
    status_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    status_starts_with?: InputMaybe<Scalars['String']['input']>
    status_starts_with_i?: InputMaybe<Scalars['String']['input']>
    tryCount?: InputMaybe<Scalars['Int']['input']>
    tryCount_gt?: InputMaybe<Scalars['Int']['input']>
    tryCount_gte?: InputMaybe<Scalars['Int']['input']>
    tryCount_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    tryCount_lt?: InputMaybe<Scalars['Int']['input']>
    tryCount_lte?: InputMaybe<Scalars['Int']['input']>
    tryCount_not?: InputMaybe<Scalars['Int']['input']>
    tryCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type RecurrentPaymentHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type RecurrentPaymentHistoryRecordsCreateInput = {
    data?: InputMaybe<RecurrentPaymentHistoryRecordCreateInput>
}

export type RecurrentPaymentHistoryRecordsUpdateInput = {
    data?: InputMaybe<RecurrentPaymentHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export enum RecurrentPaymentStatusType {
    Cancel = 'CANCEL',
    Created = 'CREATED',
    Done = 'DONE',
    Error = 'ERROR',
    ErrorNeedRetry = 'ERROR_NEED_RETRY',
    Processing = 'PROCESSING',
}

export type RecurrentPaymentUpdateInput = {
    billingReceipts?: InputMaybe<Array<BillingReceiptsFieldInput>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    payAfter?: InputMaybe<Scalars['String']['input']>
    recurrentPaymentContext?: InputMaybe<RecurrentPaymentContextRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    state?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<RecurrentPaymentStatusType>
    tryCount?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type RecurrentPaymentWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<RecurrentPaymentWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<RecurrentPaymentWhereInput>>>
    billingReceipts?: InputMaybe<Array<BillingReceiptsFieldInput>>
    billingReceipts_in?: InputMaybe<Array<InputMaybe<Array<BillingReceiptsFieldInput>>>>
    billingReceipts_not?: InputMaybe<Array<BillingReceiptsFieldInput>>
    billingReceipts_not_in?: InputMaybe<Array<InputMaybe<Array<BillingReceiptsFieldInput>>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    payAfter?: InputMaybe<Scalars['String']['input']>
    payAfter_gt?: InputMaybe<Scalars['String']['input']>
    payAfter_gte?: InputMaybe<Scalars['String']['input']>
    payAfter_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    payAfter_lt?: InputMaybe<Scalars['String']['input']>
    payAfter_lte?: InputMaybe<Scalars['String']['input']>
    payAfter_not?: InputMaybe<Scalars['String']['input']>
    payAfter_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    recurrentPaymentContext?: InputMaybe<RecurrentPaymentContextWhereInput>
    recurrentPaymentContext_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    state?: InputMaybe<Scalars['JSON']['input']>
    state_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    state_not?: InputMaybe<Scalars['JSON']['input']>
    state_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    status?: InputMaybe<RecurrentPaymentStatusType>
    status_in?: InputMaybe<Array<InputMaybe<RecurrentPaymentStatusType>>>
    status_not?: InputMaybe<RecurrentPaymentStatusType>
    status_not_in?: InputMaybe<Array<InputMaybe<RecurrentPaymentStatusType>>>
    tryCount?: InputMaybe<Scalars['Int']['input']>
    tryCount_gt?: InputMaybe<Scalars['Int']['input']>
    tryCount_gte?: InputMaybe<Scalars['Int']['input']>
    tryCount_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    tryCount_lt?: InputMaybe<Scalars['Int']['input']>
    tryCount_lte?: InputMaybe<Scalars['Int']['input']>
    tryCount_not?: InputMaybe<Scalars['Int']['input']>
    tryCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type RecurrentPaymentWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type RecurrentPaymentsCreateInput = {
    data?: InputMaybe<RecurrentPaymentCreateInput>
}

export type RecurrentPaymentsUpdateInput = {
    data?: InputMaybe<RecurrentPaymentUpdateInput>
    id: Scalars['ID']['input']
}

export type RegisterBillingReceiptAccountMetaInput = {
    fullName?: InputMaybe<Scalars['String']['input']>
    globalId?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    isClosed?: InputMaybe<Scalars['Boolean']['input']>
    ownerType?: InputMaybe<BillingAccountOwnerTypeType>
}

export type RegisterBillingReceiptAddressMetaInput = {
    globalId?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    unitName?: InputMaybe<Scalars['String']['input']>
    unitType?: InputMaybe<Scalars['String']['input']>
}

export type RegisterBillingReceiptFileInput = {
    base64EncodedPDF: Scalars['String']['input']
    context: BillingIntegrationOrganizationContextWhereUniqueInput
    dv: Scalars['Int']['input']
    receipt: BillingReceiptWhereInput
    sender: SenderFieldInput
}

export type RegisterBillingReceiptFileOutput = {
    __typename?: 'RegisterBillingReceiptFileOutput'
    id: Scalars['ID']['output']
    status: RegisterBillingReceiptFileResultStatus
}

export enum RegisterBillingReceiptFileResultStatus {
    Created = 'CREATED',
    Skipped = 'SKIPPED',
    Updated = 'UPDATED',
}

export type RegisterBillingReceiptInput = {
    accountMeta?: InputMaybe<RegisterBillingReceiptAccountMetaInput>
    accountNumber: Scalars['String']['input']
    address: Scalars['String']['input']
    addressMeta?: InputMaybe<RegisterBillingReceiptAddressMetaInput>
    bankAccount: Scalars['String']['input']
    category?: InputMaybe<BillingCategoryWhereUniqueInput>
    fullName?: InputMaybe<Scalars['String']['input']>
    importId?: InputMaybe<Scalars['String']['input']>
    month: Scalars['Int']['input']
    normalizedAddress?: InputMaybe<Scalars['String']['input']>
    raw?: InputMaybe<Scalars['JSON']['input']>
    routingNumber: Scalars['String']['input']
    routingNumberMeta?: InputMaybe<Scalars['JSON']['input']>
    services?: InputMaybe<Array<InputMaybe<BillingReceiptServiceFieldInput>>>
    tin: Scalars['String']['input']
    tinMeta?: InputMaybe<Scalars['JSON']['input']>
    toPay: Scalars['String']['input']
    toPayDetails?: InputMaybe<BillingReceiptServiceToPayDetailsFieldInput>
    unitName?: InputMaybe<Scalars['String']['input']>
    unitType?: InputMaybe<Scalars['String']['input']>
    year: Scalars['Int']['input']
}

export type RegisterBillingReceiptsInput = {
    context?: InputMaybe<BillingIntegrationOrganizationContextWhereUniqueInput>
    dv: Scalars['Int']['input']
    receipts: Array<RegisterBillingReceiptInput>
    sender: SenderFieldInput
}

export type RegisterMetersReadingsInput = {
    dv: Scalars['Int']['input']
    organization: OrganizationWhereUniqueInput
    readings: Array<RegisterMetersReadingsReadingInput>
    sender: SenderFieldInput
}

export type RegisterMetersReadingsMeterMetaInput = {
    archiveDate?: InputMaybe<Scalars['String']['input']>
    commissioningDate?: InputMaybe<Scalars['String']['input']>
    controlReadingsDate?: InputMaybe<Scalars['String']['input']>
    installationDate?: InputMaybe<Scalars['String']['input']>
    isAutomatic?: InputMaybe<Scalars['Boolean']['input']>
    nextVerificationDate?: InputMaybe<Scalars['String']['input']>
    numberOfTariffs?: InputMaybe<Scalars['Int']['input']>
    place?: InputMaybe<Scalars['String']['input']>
    sealingDate?: InputMaybe<Scalars['String']['input']>
    verificationDate?: InputMaybe<Scalars['String']['input']>
}

export type RegisterMetersReadingsMeterOutput = {
    __typename?: 'RegisterMetersReadingsMeterOutput'
    accountNumber: Scalars['String']['output']
    id: Scalars['ID']['output']
    number: Scalars['String']['output']
    property: RegisterMetersReadingsMeterPropertyOutput
    unitName: Scalars['String']['output']
    unitType: Scalars['String']['output']
}

export type RegisterMetersReadingsMeterPropertyOutput = {
    __typename?: 'RegisterMetersReadingsMeterPropertyOutput'
    address: Scalars['String']['output']
    addressKey: Scalars['String']['output']
    id: Scalars['ID']['output']
}

export type RegisterMetersReadingsOutput = {
    __typename?: 'RegisterMetersReadingsOutput'
    id: Scalars['ID']['output']
    meter: RegisterMetersReadingsMeterOutput
}

export type RegisterMetersReadingsReadingAddressInfoInput = {
    globalId?: InputMaybe<Scalars['String']['input']>
    unitName?: InputMaybe<Scalars['String']['input']>
    unitType?: InputMaybe<Scalars['String']['input']>
}

export type RegisterMetersReadingsReadingInput = {
    accountNumber: Scalars['String']['input']
    address: Scalars['String']['input']
    addressInfo: RegisterMetersReadingsReadingAddressInfoInput
    billingStatus?: InputMaybe<MeterReadingBillingStatusType>
    billingStatusText?: InputMaybe<Scalars['String']['input']>
    date: Scalars['String']['input']
    meterMeta?: InputMaybe<RegisterMetersReadingsMeterMetaInput>
    meterNumber: Scalars['String']['input']
    meterResource: MeterResourceWhereUniqueInput
    readingSource?: InputMaybe<MeterReadingSourceWhereUniqueInput>
    value1: Scalars['String']['input']
    value2?: InputMaybe<Scalars['String']['input']>
    value3?: InputMaybe<Scalars['String']['input']>
    value4?: InputMaybe<Scalars['String']['input']>
}

export type RegisterMultiPaymentForInvoicesInput = {
    dv: Scalars['Int']['input']
    invoices: Array<InvoiceWhereUniqueInput>
    sender: Scalars['JSON']['input']
}

export type RegisterMultiPaymentForInvoicesOutput = {
    __typename?: 'RegisterMultiPaymentForInvoicesOutput'
    anonymousPaymentUrl: Scalars['String']['output']
    directPaymentUrl: Scalars['String']['output']
    dv: Scalars['Int']['output']
    feeCalculationUrl: Scalars['String']['output']
    multiPaymentId: Scalars['String']['output']
    webViewUrl: Scalars['String']['output']
}

export type RegisterMultiPaymentForOneReceiptInput = {
    acquiringIntegrationContext: AcquiringIntegrationContextWhereUniqueInput
    dv: Scalars['Int']['input']
    receipt: BillingReceiptWhereUniqueInput
    sender: SenderFieldInput
}

export type RegisterMultiPaymentForOneReceiptOutput = {
    __typename?: 'RegisterMultiPaymentForOneReceiptOutput'
    anonymousPaymentUrl: Scalars['String']['output']
    directPaymentUrl: Scalars['String']['output']
    dv: Scalars['Int']['output']
    feeCalculationUrl: Scalars['String']['output']
    multiPaymentId: Scalars['String']['output']
    webViewUrl: Scalars['String']['output']
}

export type RegisterMultiPaymentForVirtualReceiptInput = {
    acquiringIntegrationContext: AcquiringIntegrationContextWhereUniqueInput
    dv: Scalars['Int']['input']
    receipt: RegisterMultiPaymentVirtualReceiptInput
    sender: SenderFieldInput
}

export type RegisterMultiPaymentForVirtualReceiptOutput = {
    __typename?: 'RegisterMultiPaymentForVirtualReceiptOutput'
    anonymousPaymentUrl: Scalars['String']['output']
    directPaymentUrl: Scalars['String']['output']
    dv: Scalars['Int']['output']
    feeCalculationUrl: Scalars['String']['output']
    multiPaymentId: Scalars['String']['output']
    webViewUrl: Scalars['String']['output']
}

export type RegisterMultiPaymentInput = {
    dv: Scalars['Int']['input']
    groupedReceipts?: InputMaybe<Array<RegisterMultiPaymentServiceConsumerInput>>
    invoices?: InputMaybe<Array<InvoiceWhereUniqueInput>>
    recurrentPaymentContext?: InputMaybe<RecurrentPaymentContextWhereUniqueInput>
    sender: SenderFieldInput
}

export type RegisterMultiPaymentOutput = {
    __typename?: 'RegisterMultiPaymentOutput'
    directPaymentUrl: Scalars['String']['output']
    dv: Scalars['Int']['output']
    feeCalculationUrl: Scalars['String']['output']
    getCardTokensUrl: Scalars['String']['output']
    multiPaymentId: Scalars['String']['output']
    webViewUrl: Scalars['String']['output']
}

export type RegisterMultiPaymentReceiptAmountInput = {
    amount: Scalars['String']['input']
    receipt: BillingReceiptWhereUniqueInput
}

export type RegisterMultiPaymentServiceConsumerInput = {
    amountDistribution?: InputMaybe<Array<RegisterMultiPaymentReceiptAmountInput>>
    receipts: Array<BillingReceiptWhereUniqueInput>
    serviceConsumer: ServiceConsumerWhereUniqueInput
}

export type RegisterMultiPaymentVirtualReceiptInput = {
    amount: Scalars['String']['input']
    currencyCode: Scalars['String']['input']
    period: Scalars['String']['input']
    recipient: RegisterMultiPaymentVirtualReceiptRecipientInput
}

export type RegisterMultiPaymentVirtualReceiptRecipientInput = {
    accountNumber: Scalars['String']['input']
    bankAccount: Scalars['String']['input']
    routingNumber: Scalars['String']['input']
}

export type RegisterNewOrganizationInput = {
    avatar?: InputMaybe<Scalars['Upload']['input']>
    country: Scalars['String']['input']
    description?: InputMaybe<Scalars['String']['input']>
    dv: Scalars['Int']['input']
    meta: Scalars['JSON']['input']
    name: Scalars['String']['input']
    sender: SenderFieldInput
    tin: Scalars['String']['input']
    type?: InputMaybe<OrganizationType>
}

export type RegisterNewServiceUserInput = {
    dv: Scalars['Int']['input']
    email: Scalars['String']['input']
    meta?: InputMaybe<Scalars['JSON']['input']>
    name: Scalars['String']['input']
    sender: SenderFieldInput
}

export type RegisterNewServiceUserOutput = {
    __typename?: 'RegisterNewServiceUserOutput'
    email: Scalars['String']['output']
    id: Scalars['ID']['output']
    password: Scalars['String']['output']
}

export type RegisterNewUserInput = {
    confirmPhoneActionToken: Scalars['String']['input']
    dv: Scalars['Int']['input']
    email?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    name: Scalars['String']['input']
    password: Scalars['String']['input']
    phone?: InputMaybe<Scalars['String']['input']>
    sender: SenderFieldInput
}

export type RegisterResidentInput = {
    address: Scalars['String']['input']
    addressMeta?: InputMaybe<AddressMetaFieldInput>
    dv: Scalars['Int']['input']
    sender: SenderFieldInput
    unitName: Scalars['String']['input']
    unitType?: InputMaybe<BuildingUnitSubType>
}

export type RegisterResidentInvoiceInput = {
    dv: Scalars['Int']['input']
    invoiceRows: Array<InvoiceRowsInput>
    paymentType: InvoicePaymentType
    resident: ResidentWhereUniqueInput
    sender: SenderFieldInput
}

export type RegisterResidentServiceConsumersInput = {
    accountNumber: Scalars['String']['input']
    dv: Scalars['Int']['input']
    paymentCategory?: InputMaybe<Scalars['String']['input']>
    resident: ResidentWhereUniqueInput
    sender: SenderFieldInput
}

export type RegisterServiceConsumerInput = {
    accountNumber: Scalars['String']['input']
    dv: Scalars['Int']['input']
    extra?: InputMaybe<RegisterServiceConsumerInputExtra>
    organizationId: Scalars['ID']['input']
    residentId: Scalars['ID']['input']
    sender: SenderFieldInput
}

export type RegisterServiceConsumerInputExtra = {
    paymentCategory?: InputMaybe<Scalars['String']['input']>
}

/**  Used to describe device in order to be able to send push notifications via corresponding transport, depending on pushTransport value. RemoteClient could be mobile or web based. RemoteClient could be registered (created by user, admin or anonymous) with or without token, and updated later on by admin (or a user within SyncRemoteClientService) by adding/changing token value and connecting device to user (whose authorization was passed within request). All such interactions should be done via SyncRemoteClientService.  */
export type RemoteClient = {
    __typename?: 'RemoteClient'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the RemoteClient List config, or
   *  2. As an alias to the field set on 'labelField' in the RemoteClient List config, or
   *  3. As an alias to a 'name' field on the RemoteClient List (if one exists), or
   *  4. As an alias to the 'id' field on the RemoteClient List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  Application ID, which is used to identify app. Same device can have multiple apps installed for same user. It is also required for applying correct credentials for Firebase/Huawei/etc. API requests.  */
    appId?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Mobile/web device ID, which is used to identify a device. One user can have many devices, and one device can be used by many users once upon a time.  */
    deviceId?: Maybe<Scalars['String']['output']>
    /**  Represents the platform type of the client application like android/ios/web.  */
    devicePlatform?: Maybe<RemoteClientDevicePlatformType>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  RemoteClient metadata. OS type, OS version, etc.  */
    meta?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Owner user of a device and a push token. User, which is logged in on the device. Push token can be created by anonymous user and connected to authorized user later on.  */
    owner?: Maybe<User>
    /**  Used by transport services (FireBase, Apple, Huawei, etc.) to transfer push notifications to devices.  */
    pushToken?: Maybe<Scalars['String']['output']>
    /**  Used by transport services (FireBase, Apple, Huawei, etc.) to transfer VoIP push notifications to devices.  */
    pushTokenVoIP?: Maybe<Scalars['String']['output']>
    /**  Transport service, that delivers push notifications to client device. Type of device requires specific transport service, e.g. Huawei devices can not receive notifications through FireBase.  */
    pushTransport?: Maybe<RemoteClientPushTransportType>
    /**  Transport service, that delivers VoIP push notifications to client device. Type of device requires specific transport service, e.g. Huawei devices can not receive notifications through FireBase.  */
    pushTransportVoIP?: Maybe<RemoteClientPushTransportVoIpType>
    /**  Represents the type of push to be sent to the remote client like default/silent-data/etc. Remote client can control structure of data sent via push to the device using this field.Some remote clients are able to show own notifications instead system ones. To do so they have to receive push, containing no notification part, which is sent if this field is equal to PUSH_TYPE_SILENT_DATA.  */
    pushType?: Maybe<RemoteClientPushTypeType>
    /**  Represents the type of VoIP push to be sent to the remote client like default/silent-data/etc. Remote client can control structure of data sent via push to the device using this field.Some remote clients are able to show own notifications instead system ones. To do so they have to receive push, containing no notification part, which is sent if this field is equal to PUSH_TYPE_SILENT_DATA.  */
    pushTypeVoIP?: Maybe<RemoteClientPushTypeVoIpType>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type RemoteClientCreateInput = {
    appId?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deviceId?: InputMaybe<Scalars['String']['input']>
    devicePlatform?: InputMaybe<RemoteClientDevicePlatformType>
    dv?: InputMaybe<Scalars['Int']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    owner?: InputMaybe<UserRelateToOneInput>
    pushToken?: InputMaybe<Scalars['String']['input']>
    pushTokenVoIP?: InputMaybe<Scalars['String']['input']>
    pushTransport?: InputMaybe<RemoteClientPushTransportType>
    pushTransportVoIP?: InputMaybe<RemoteClientPushTransportVoIpType>
    pushType?: InputMaybe<RemoteClientPushTypeType>
    pushTypeVoIP?: InputMaybe<RemoteClientPushTypeVoIpType>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum RemoteClientDevicePlatformType {
    Android = 'android',
    Ios = 'ios',
    Web = 'web',
}

/**  A keystone list  */
export type RemoteClientHistoryRecord = {
    __typename?: 'RemoteClientHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the RemoteClientHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the RemoteClientHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the RemoteClientHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the RemoteClientHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    appId?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    deviceId?: Maybe<Scalars['String']['output']>
    devicePlatform?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<RemoteClientHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    meta?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    owner?: Maybe<Scalars['String']['output']>
    pushToken?: Maybe<Scalars['String']['output']>
    pushTokenVoIP?: Maybe<Scalars['String']['output']>
    pushTransport?: Maybe<Scalars['String']['output']>
    pushTransportVoIP?: Maybe<Scalars['String']['output']>
    pushType?: Maybe<Scalars['String']['output']>
    pushTypeVoIP?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type RemoteClientHistoryRecordCreateInput = {
    appId?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deviceId?: InputMaybe<Scalars['String']['input']>
    devicePlatform?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<RemoteClientHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    owner?: InputMaybe<Scalars['String']['input']>
    pushToken?: InputMaybe<Scalars['String']['input']>
    pushTokenVoIP?: InputMaybe<Scalars['String']['input']>
    pushTransport?: InputMaybe<Scalars['String']['input']>
    pushTransportVoIP?: InputMaybe<Scalars['String']['input']>
    pushType?: InputMaybe<Scalars['String']['input']>
    pushTypeVoIP?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum RemoteClientHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type RemoteClientHistoryRecordUpdateInput = {
    appId?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deviceId?: InputMaybe<Scalars['String']['input']>
    devicePlatform?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<RemoteClientHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    owner?: InputMaybe<Scalars['String']['input']>
    pushToken?: InputMaybe<Scalars['String']['input']>
    pushTokenVoIP?: InputMaybe<Scalars['String']['input']>
    pushTransport?: InputMaybe<Scalars['String']['input']>
    pushTransportVoIP?: InputMaybe<Scalars['String']['input']>
    pushType?: InputMaybe<Scalars['String']['input']>
    pushTypeVoIP?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type RemoteClientHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<RemoteClientHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<RemoteClientHistoryRecordWhereInput>>>
    appId?: InputMaybe<Scalars['String']['input']>
    appId_contains?: InputMaybe<Scalars['String']['input']>
    appId_contains_i?: InputMaybe<Scalars['String']['input']>
    appId_ends_with?: InputMaybe<Scalars['String']['input']>
    appId_ends_with_i?: InputMaybe<Scalars['String']['input']>
    appId_i?: InputMaybe<Scalars['String']['input']>
    appId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    appId_not?: InputMaybe<Scalars['String']['input']>
    appId_not_contains?: InputMaybe<Scalars['String']['input']>
    appId_not_contains_i?: InputMaybe<Scalars['String']['input']>
    appId_not_ends_with?: InputMaybe<Scalars['String']['input']>
    appId_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    appId_not_i?: InputMaybe<Scalars['String']['input']>
    appId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    appId_not_starts_with?: InputMaybe<Scalars['String']['input']>
    appId_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    appId_starts_with?: InputMaybe<Scalars['String']['input']>
    appId_starts_with_i?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deviceId?: InputMaybe<Scalars['String']['input']>
    deviceId_contains?: InputMaybe<Scalars['String']['input']>
    deviceId_contains_i?: InputMaybe<Scalars['String']['input']>
    deviceId_ends_with?: InputMaybe<Scalars['String']['input']>
    deviceId_ends_with_i?: InputMaybe<Scalars['String']['input']>
    deviceId_i?: InputMaybe<Scalars['String']['input']>
    deviceId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deviceId_not?: InputMaybe<Scalars['String']['input']>
    deviceId_not_contains?: InputMaybe<Scalars['String']['input']>
    deviceId_not_contains_i?: InputMaybe<Scalars['String']['input']>
    deviceId_not_ends_with?: InputMaybe<Scalars['String']['input']>
    deviceId_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    deviceId_not_i?: InputMaybe<Scalars['String']['input']>
    deviceId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deviceId_not_starts_with?: InputMaybe<Scalars['String']['input']>
    deviceId_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    deviceId_starts_with?: InputMaybe<Scalars['String']['input']>
    deviceId_starts_with_i?: InputMaybe<Scalars['String']['input']>
    devicePlatform?: InputMaybe<Scalars['String']['input']>
    devicePlatform_contains?: InputMaybe<Scalars['String']['input']>
    devicePlatform_contains_i?: InputMaybe<Scalars['String']['input']>
    devicePlatform_ends_with?: InputMaybe<Scalars['String']['input']>
    devicePlatform_ends_with_i?: InputMaybe<Scalars['String']['input']>
    devicePlatform_i?: InputMaybe<Scalars['String']['input']>
    devicePlatform_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    devicePlatform_not?: InputMaybe<Scalars['String']['input']>
    devicePlatform_not_contains?: InputMaybe<Scalars['String']['input']>
    devicePlatform_not_contains_i?: InputMaybe<Scalars['String']['input']>
    devicePlatform_not_ends_with?: InputMaybe<Scalars['String']['input']>
    devicePlatform_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    devicePlatform_not_i?: InputMaybe<Scalars['String']['input']>
    devicePlatform_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    devicePlatform_not_starts_with?: InputMaybe<Scalars['String']['input']>
    devicePlatform_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    devicePlatform_starts_with?: InputMaybe<Scalars['String']['input']>
    devicePlatform_starts_with_i?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<RemoteClientHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<RemoteClientHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<RemoteClientHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<RemoteClientHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    owner?: InputMaybe<Scalars['String']['input']>
    owner_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    owner_not?: InputMaybe<Scalars['String']['input']>
    owner_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    pushToken?: InputMaybe<Scalars['String']['input']>
    pushTokenVoIP?: InputMaybe<Scalars['String']['input']>
    pushTokenVoIP_contains?: InputMaybe<Scalars['String']['input']>
    pushTokenVoIP_contains_i?: InputMaybe<Scalars['String']['input']>
    pushTokenVoIP_ends_with?: InputMaybe<Scalars['String']['input']>
    pushTokenVoIP_ends_with_i?: InputMaybe<Scalars['String']['input']>
    pushTokenVoIP_i?: InputMaybe<Scalars['String']['input']>
    pushTokenVoIP_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    pushTokenVoIP_not?: InputMaybe<Scalars['String']['input']>
    pushTokenVoIP_not_contains?: InputMaybe<Scalars['String']['input']>
    pushTokenVoIP_not_contains_i?: InputMaybe<Scalars['String']['input']>
    pushTokenVoIP_not_ends_with?: InputMaybe<Scalars['String']['input']>
    pushTokenVoIP_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    pushTokenVoIP_not_i?: InputMaybe<Scalars['String']['input']>
    pushTokenVoIP_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    pushTokenVoIP_not_starts_with?: InputMaybe<Scalars['String']['input']>
    pushTokenVoIP_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    pushTokenVoIP_starts_with?: InputMaybe<Scalars['String']['input']>
    pushTokenVoIP_starts_with_i?: InputMaybe<Scalars['String']['input']>
    pushToken_contains?: InputMaybe<Scalars['String']['input']>
    pushToken_contains_i?: InputMaybe<Scalars['String']['input']>
    pushToken_ends_with?: InputMaybe<Scalars['String']['input']>
    pushToken_ends_with_i?: InputMaybe<Scalars['String']['input']>
    pushToken_i?: InputMaybe<Scalars['String']['input']>
    pushToken_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    pushToken_not?: InputMaybe<Scalars['String']['input']>
    pushToken_not_contains?: InputMaybe<Scalars['String']['input']>
    pushToken_not_contains_i?: InputMaybe<Scalars['String']['input']>
    pushToken_not_ends_with?: InputMaybe<Scalars['String']['input']>
    pushToken_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    pushToken_not_i?: InputMaybe<Scalars['String']['input']>
    pushToken_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    pushToken_not_starts_with?: InputMaybe<Scalars['String']['input']>
    pushToken_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    pushToken_starts_with?: InputMaybe<Scalars['String']['input']>
    pushToken_starts_with_i?: InputMaybe<Scalars['String']['input']>
    pushTransport?: InputMaybe<Scalars['String']['input']>
    pushTransportVoIP?: InputMaybe<Scalars['String']['input']>
    pushTransportVoIP_contains?: InputMaybe<Scalars['String']['input']>
    pushTransportVoIP_contains_i?: InputMaybe<Scalars['String']['input']>
    pushTransportVoIP_ends_with?: InputMaybe<Scalars['String']['input']>
    pushTransportVoIP_ends_with_i?: InputMaybe<Scalars['String']['input']>
    pushTransportVoIP_i?: InputMaybe<Scalars['String']['input']>
    pushTransportVoIP_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    pushTransportVoIP_not?: InputMaybe<Scalars['String']['input']>
    pushTransportVoIP_not_contains?: InputMaybe<Scalars['String']['input']>
    pushTransportVoIP_not_contains_i?: InputMaybe<Scalars['String']['input']>
    pushTransportVoIP_not_ends_with?: InputMaybe<Scalars['String']['input']>
    pushTransportVoIP_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    pushTransportVoIP_not_i?: InputMaybe<Scalars['String']['input']>
    pushTransportVoIP_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    pushTransportVoIP_not_starts_with?: InputMaybe<Scalars['String']['input']>
    pushTransportVoIP_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    pushTransportVoIP_starts_with?: InputMaybe<Scalars['String']['input']>
    pushTransportVoIP_starts_with_i?: InputMaybe<Scalars['String']['input']>
    pushTransport_contains?: InputMaybe<Scalars['String']['input']>
    pushTransport_contains_i?: InputMaybe<Scalars['String']['input']>
    pushTransport_ends_with?: InputMaybe<Scalars['String']['input']>
    pushTransport_ends_with_i?: InputMaybe<Scalars['String']['input']>
    pushTransport_i?: InputMaybe<Scalars['String']['input']>
    pushTransport_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    pushTransport_not?: InputMaybe<Scalars['String']['input']>
    pushTransport_not_contains?: InputMaybe<Scalars['String']['input']>
    pushTransport_not_contains_i?: InputMaybe<Scalars['String']['input']>
    pushTransport_not_ends_with?: InputMaybe<Scalars['String']['input']>
    pushTransport_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    pushTransport_not_i?: InputMaybe<Scalars['String']['input']>
    pushTransport_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    pushTransport_not_starts_with?: InputMaybe<Scalars['String']['input']>
    pushTransport_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    pushTransport_starts_with?: InputMaybe<Scalars['String']['input']>
    pushTransport_starts_with_i?: InputMaybe<Scalars['String']['input']>
    pushType?: InputMaybe<Scalars['String']['input']>
    pushTypeVoIP?: InputMaybe<Scalars['String']['input']>
    pushTypeVoIP_contains?: InputMaybe<Scalars['String']['input']>
    pushTypeVoIP_contains_i?: InputMaybe<Scalars['String']['input']>
    pushTypeVoIP_ends_with?: InputMaybe<Scalars['String']['input']>
    pushTypeVoIP_ends_with_i?: InputMaybe<Scalars['String']['input']>
    pushTypeVoIP_i?: InputMaybe<Scalars['String']['input']>
    pushTypeVoIP_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    pushTypeVoIP_not?: InputMaybe<Scalars['String']['input']>
    pushTypeVoIP_not_contains?: InputMaybe<Scalars['String']['input']>
    pushTypeVoIP_not_contains_i?: InputMaybe<Scalars['String']['input']>
    pushTypeVoIP_not_ends_with?: InputMaybe<Scalars['String']['input']>
    pushTypeVoIP_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    pushTypeVoIP_not_i?: InputMaybe<Scalars['String']['input']>
    pushTypeVoIP_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    pushTypeVoIP_not_starts_with?: InputMaybe<Scalars['String']['input']>
    pushTypeVoIP_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    pushTypeVoIP_starts_with?: InputMaybe<Scalars['String']['input']>
    pushTypeVoIP_starts_with_i?: InputMaybe<Scalars['String']['input']>
    pushType_contains?: InputMaybe<Scalars['String']['input']>
    pushType_contains_i?: InputMaybe<Scalars['String']['input']>
    pushType_ends_with?: InputMaybe<Scalars['String']['input']>
    pushType_ends_with_i?: InputMaybe<Scalars['String']['input']>
    pushType_i?: InputMaybe<Scalars['String']['input']>
    pushType_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    pushType_not?: InputMaybe<Scalars['String']['input']>
    pushType_not_contains?: InputMaybe<Scalars['String']['input']>
    pushType_not_contains_i?: InputMaybe<Scalars['String']['input']>
    pushType_not_ends_with?: InputMaybe<Scalars['String']['input']>
    pushType_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    pushType_not_i?: InputMaybe<Scalars['String']['input']>
    pushType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    pushType_not_starts_with?: InputMaybe<Scalars['String']['input']>
    pushType_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    pushType_starts_with?: InputMaybe<Scalars['String']['input']>
    pushType_starts_with_i?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type RemoteClientHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type RemoteClientHistoryRecordsCreateInput = {
    data?: InputMaybe<RemoteClientHistoryRecordCreateInput>
}

export type RemoteClientHistoryRecordsUpdateInput = {
    data?: InputMaybe<RemoteClientHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export enum RemoteClientPushTransportType {
    Apple = 'apple',
    Firebase = 'firebase',
    Huawei = 'huawei',
    Redstore = 'redstore',
}

export enum RemoteClientPushTransportVoIpType {
    Apple = 'apple',
    Firebase = 'firebase',
    Huawei = 'huawei',
    Redstore = 'redstore',
}

export enum RemoteClientPushTypeType {
    Default = 'default',
    SilentData = 'silent_data',
}

export enum RemoteClientPushTypeVoIpType {
    Default = 'default',
    SilentData = 'silent_data',
}

export type RemoteClientRelateToOneInput = {
    connect?: InputMaybe<RemoteClientWhereUniqueInput>
    create?: InputMaybe<RemoteClientCreateInput>
    disconnect?: InputMaybe<RemoteClientWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type RemoteClientUpdateInput = {
    appId?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deviceId?: InputMaybe<Scalars['String']['input']>
    devicePlatform?: InputMaybe<RemoteClientDevicePlatformType>
    dv?: InputMaybe<Scalars['Int']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    owner?: InputMaybe<UserRelateToOneInput>
    pushToken?: InputMaybe<Scalars['String']['input']>
    pushTokenVoIP?: InputMaybe<Scalars['String']['input']>
    pushTransport?: InputMaybe<RemoteClientPushTransportType>
    pushTransportVoIP?: InputMaybe<RemoteClientPushTransportVoIpType>
    pushType?: InputMaybe<RemoteClientPushTypeType>
    pushTypeVoIP?: InputMaybe<RemoteClientPushTypeVoIpType>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type RemoteClientWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<RemoteClientWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<RemoteClientWhereInput>>>
    appId?: InputMaybe<Scalars['String']['input']>
    appId_contains?: InputMaybe<Scalars['String']['input']>
    appId_contains_i?: InputMaybe<Scalars['String']['input']>
    appId_ends_with?: InputMaybe<Scalars['String']['input']>
    appId_ends_with_i?: InputMaybe<Scalars['String']['input']>
    appId_i?: InputMaybe<Scalars['String']['input']>
    appId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    appId_not?: InputMaybe<Scalars['String']['input']>
    appId_not_contains?: InputMaybe<Scalars['String']['input']>
    appId_not_contains_i?: InputMaybe<Scalars['String']['input']>
    appId_not_ends_with?: InputMaybe<Scalars['String']['input']>
    appId_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    appId_not_i?: InputMaybe<Scalars['String']['input']>
    appId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    appId_not_starts_with?: InputMaybe<Scalars['String']['input']>
    appId_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    appId_starts_with?: InputMaybe<Scalars['String']['input']>
    appId_starts_with_i?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deviceId?: InputMaybe<Scalars['String']['input']>
    deviceId_contains?: InputMaybe<Scalars['String']['input']>
    deviceId_contains_i?: InputMaybe<Scalars['String']['input']>
    deviceId_ends_with?: InputMaybe<Scalars['String']['input']>
    deviceId_ends_with_i?: InputMaybe<Scalars['String']['input']>
    deviceId_i?: InputMaybe<Scalars['String']['input']>
    deviceId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deviceId_not?: InputMaybe<Scalars['String']['input']>
    deviceId_not_contains?: InputMaybe<Scalars['String']['input']>
    deviceId_not_contains_i?: InputMaybe<Scalars['String']['input']>
    deviceId_not_ends_with?: InputMaybe<Scalars['String']['input']>
    deviceId_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    deviceId_not_i?: InputMaybe<Scalars['String']['input']>
    deviceId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deviceId_not_starts_with?: InputMaybe<Scalars['String']['input']>
    deviceId_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    deviceId_starts_with?: InputMaybe<Scalars['String']['input']>
    deviceId_starts_with_i?: InputMaybe<Scalars['String']['input']>
    devicePlatform?: InputMaybe<RemoteClientDevicePlatformType>
    devicePlatform_in?: InputMaybe<Array<InputMaybe<RemoteClientDevicePlatformType>>>
    devicePlatform_not?: InputMaybe<RemoteClientDevicePlatformType>
    devicePlatform_not_in?: InputMaybe<Array<InputMaybe<RemoteClientDevicePlatformType>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    owner?: InputMaybe<UserWhereInput>
    owner_is_null?: InputMaybe<Scalars['Boolean']['input']>
    pushToken?: InputMaybe<Scalars['String']['input']>
    pushTokenVoIP?: InputMaybe<Scalars['String']['input']>
    pushTokenVoIP_contains?: InputMaybe<Scalars['String']['input']>
    pushTokenVoIP_contains_i?: InputMaybe<Scalars['String']['input']>
    pushTokenVoIP_ends_with?: InputMaybe<Scalars['String']['input']>
    pushTokenVoIP_ends_with_i?: InputMaybe<Scalars['String']['input']>
    pushTokenVoIP_i?: InputMaybe<Scalars['String']['input']>
    pushTokenVoIP_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    pushTokenVoIP_not?: InputMaybe<Scalars['String']['input']>
    pushTokenVoIP_not_contains?: InputMaybe<Scalars['String']['input']>
    pushTokenVoIP_not_contains_i?: InputMaybe<Scalars['String']['input']>
    pushTokenVoIP_not_ends_with?: InputMaybe<Scalars['String']['input']>
    pushTokenVoIP_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    pushTokenVoIP_not_i?: InputMaybe<Scalars['String']['input']>
    pushTokenVoIP_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    pushTokenVoIP_not_starts_with?: InputMaybe<Scalars['String']['input']>
    pushTokenVoIP_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    pushTokenVoIP_starts_with?: InputMaybe<Scalars['String']['input']>
    pushTokenVoIP_starts_with_i?: InputMaybe<Scalars['String']['input']>
    pushToken_contains?: InputMaybe<Scalars['String']['input']>
    pushToken_contains_i?: InputMaybe<Scalars['String']['input']>
    pushToken_ends_with?: InputMaybe<Scalars['String']['input']>
    pushToken_ends_with_i?: InputMaybe<Scalars['String']['input']>
    pushToken_i?: InputMaybe<Scalars['String']['input']>
    pushToken_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    pushToken_not?: InputMaybe<Scalars['String']['input']>
    pushToken_not_contains?: InputMaybe<Scalars['String']['input']>
    pushToken_not_contains_i?: InputMaybe<Scalars['String']['input']>
    pushToken_not_ends_with?: InputMaybe<Scalars['String']['input']>
    pushToken_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    pushToken_not_i?: InputMaybe<Scalars['String']['input']>
    pushToken_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    pushToken_not_starts_with?: InputMaybe<Scalars['String']['input']>
    pushToken_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    pushToken_starts_with?: InputMaybe<Scalars['String']['input']>
    pushToken_starts_with_i?: InputMaybe<Scalars['String']['input']>
    pushTransport?: InputMaybe<RemoteClientPushTransportType>
    pushTransportVoIP?: InputMaybe<RemoteClientPushTransportVoIpType>
    pushTransportVoIP_in?: InputMaybe<Array<InputMaybe<RemoteClientPushTransportVoIpType>>>
    pushTransportVoIP_not?: InputMaybe<RemoteClientPushTransportVoIpType>
    pushTransportVoIP_not_in?: InputMaybe<Array<InputMaybe<RemoteClientPushTransportVoIpType>>>
    pushTransport_in?: InputMaybe<Array<InputMaybe<RemoteClientPushTransportType>>>
    pushTransport_not?: InputMaybe<RemoteClientPushTransportType>
    pushTransport_not_in?: InputMaybe<Array<InputMaybe<RemoteClientPushTransportType>>>
    pushType?: InputMaybe<RemoteClientPushTypeType>
    pushTypeVoIP?: InputMaybe<RemoteClientPushTypeVoIpType>
    pushTypeVoIP_in?: InputMaybe<Array<InputMaybe<RemoteClientPushTypeVoIpType>>>
    pushTypeVoIP_not?: InputMaybe<RemoteClientPushTypeVoIpType>
    pushTypeVoIP_not_in?: InputMaybe<Array<InputMaybe<RemoteClientPushTypeVoIpType>>>
    pushType_in?: InputMaybe<Array<InputMaybe<RemoteClientPushTypeType>>>
    pushType_not?: InputMaybe<RemoteClientPushTypeType>
    pushType_not_in?: InputMaybe<Array<InputMaybe<RemoteClientPushTypeType>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type RemoteClientWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type RemoteClientsCreateInput = {
    data?: InputMaybe<RemoteClientCreateInput>
}

export type RemoteClientsUpdateInput = {
    data?: InputMaybe<RemoteClientUpdateInput>
    id: Scalars['ID']['input']
}

export type ReplaceOrganizationEmployeeRoleInput = {
    dv: Scalars['Int']['input']
    newRole: OrganizationEmployeeRoleWhereUniqueInput
    oldRole: OrganizationEmployeeRoleWhereUniqueInput
    organization: OrganizationWhereUniqueInput
    sender: SenderFieldInput
    withDeletionOldRole: Scalars['Boolean']['input']
}

export type ReplaceOrganizationEmployeeRoleOutput = {
    __typename?: 'ReplaceOrganizationEmployeeRoleOutput'
    status: Scalars['String']['output']
}

export type ResendConfirmPhoneActionSmsInput = {
    captcha: Scalars['String']['input']
    dv: Scalars['Int']['input']
    sender: SenderFieldInput
    token: Scalars['String']['input']
}

export type ResendConfirmPhoneActionSmsOutput = {
    __typename?: 'ResendConfirmPhoneActionSmsOutput'
    status: Scalars['String']['output']
}

export type ResendMessageInput = {
    dv: Scalars['Int']['input']
    message: MessageWhereUniqueInput
    sender: SenderFieldInput
}

export type ResendMessageOutput = {
    __typename?: 'ResendMessageOutput'
    id: Scalars['String']['output']
    status: Scalars['String']['output']
}

export type ResetOrganizationInput = {
    dv: Scalars['Int']['input']
    organizationId: Scalars['ID']['input']
    sender: Scalars['JSON']['input']
}

export type ResetOrganizationOutput = {
    __typename?: 'ResetOrganizationOutput'
    status: Scalars['String']['output']
}

export type ResetUserInput = {
    dv: Scalars['Int']['input']
    saveName?: InputMaybe<Scalars['Boolean']['input']>
    sender: SenderFieldInput
    user: UserWhereUniqueInput
}

export type ResetUserOutput = {
    __typename?: 'ResetUserOutput'
    status: Scalars['String']['output']
}

/**  Person, that resides in a specified property and unit  */
export type Resident = {
    __typename?: 'Resident'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the Resident List config, or
   *  2. As an alias to the field set on 'labelField' in the Resident List config, or
   *  3. As an alias to a 'name' field on the Resident List (if one exists), or
   *  4. As an alias to the 'id' field on the Resident List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  Normalized address  */
    address?: Maybe<Scalars['String']['output']>
    /**  The unique key of the address  */
    addressKey?: Maybe<Scalars['String']['output']>
    /**  Property address components  */
    addressMeta?: Maybe<AddressMetaField>
    /**  The origins of the address (some strings which may looks like real address or some id)  */
    addressSources?: Maybe<Scalars['JSON']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    isVerifiedByManagingCompany?: Maybe<Scalars['Boolean']['output']>
    managingCompanyContactRole?: Maybe<ResidentContactRole>
    newId?: Maybe<Scalars['String']['output']>
    /**  Ref to the organization. It is filled in on the server and is read-only  */
    organization?: Maybe<Organization>
    /**  Contains features that are enabled for user organization  */
    organizationFeatures?: Maybe<OrganizationFeatures>
    /**  Contains billing and acquiring integration names that are enabled for this resident per category  */
    paymentCategories?: Maybe<Array<Maybe<PaymentCategory>>>
    /**  Property, in which this person resides. Can be missing, when a resident has been registered, but there is no Property in our system yet  */
    property?: Maybe<Property>
    /**  Organization data, that is returned for current resident in mobile client  */
    residentOrganization?: Maybe<ResidentOrganization>
    /**  Property data, that is returned for current resident in mobile client  */
    residentProperty?: Maybe<ResidentProperty>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Unit of the property, in which this person resides  */
    unitName?: Maybe<Scalars['String']['output']>
    /**  Type of unit, such as parking lot or flat. Default value: "flat"  */
    unitType?: Maybe<ResidentUnitTypeType>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    /**  Mobile user account  */
    user?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type ResidentAcquiringIntegrationContext = {
    __typename?: 'ResidentAcquiringIntegrationContext'
    id: Scalars['ID']['output']
    integration?: Maybe<AcquiringIntegration>
}

export type ResidentBillingAccount = {
    __typename?: 'ResidentBillingAccount'
    id: Scalars['ID']['output']
}

export type ResidentBillingReceiptFile = {
    __typename?: 'ResidentBillingReceiptFile'
    controlSum?: Maybe<Scalars['String']['output']>
    file?: Maybe<File>
}

export type ResidentBillingReceiptOutput = {
    __typename?: 'ResidentBillingReceiptOutput'
    category: BillingCategory
    currencyCode: Scalars['String']['output']
    dv: Scalars['String']['output']
    explicitFee: Scalars['String']['output']
    file?: Maybe<ResidentBillingReceiptFile>
    id: Scalars['ID']['output']
    isPayable: Scalars['Boolean']['output']
    paid: Scalars['String']['output']
    period: Scalars['String']['output']
    printableNumber?: Maybe<Scalars['String']['output']>
    recipient: BillingReceiptsRecipientField
    serviceConsumer: ServiceConsumer
    services?: Maybe<Array<BillingReceiptServiceField>>
    toPay: Scalars['String']['output']
    toPayDetails?: Maybe<BillingReceiptToPayDetailsField>
    updatedAt?: Maybe<Scalars['String']['output']>
}

export type ResidentBillingReceiptWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<ResidentBillingReceiptWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<ResidentBillingReceiptWhereInput>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    period?: InputMaybe<Scalars['String']['input']>
    period_contains?: InputMaybe<Scalars['String']['input']>
    period_contains_i?: InputMaybe<Scalars['String']['input']>
    period_ends_with?: InputMaybe<Scalars['String']['input']>
    period_ends_with_i?: InputMaybe<Scalars['String']['input']>
    period_i?: InputMaybe<Scalars['String']['input']>
    period_not?: InputMaybe<Scalars['String']['input']>
    period_not_contains?: InputMaybe<Scalars['String']['input']>
    period_not_contains_i?: InputMaybe<Scalars['String']['input']>
    period_not_ends_with?: InputMaybe<Scalars['String']['input']>
    period_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    period_not_i?: InputMaybe<Scalars['String']['input']>
    period_not_starts_with?: InputMaybe<Scalars['String']['input']>
    period_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    period_starts_with?: InputMaybe<Scalars['String']['input']>
    period_starts_with_i?: InputMaybe<Scalars['String']['input']>
    printableNumber?: InputMaybe<Scalars['String']['input']>
    printableNumber_contains?: InputMaybe<Scalars['String']['input']>
    printableNumber_contains_i?: InputMaybe<Scalars['String']['input']>
    printableNumber_ends_with?: InputMaybe<Scalars['String']['input']>
    printableNumber_ends_with_i?: InputMaybe<Scalars['String']['input']>
    printableNumber_i?: InputMaybe<Scalars['String']['input']>
    printableNumber_not?: InputMaybe<Scalars['String']['input']>
    printableNumber_not_contains?: InputMaybe<Scalars['String']['input']>
    printableNumber_not_contains_i?: InputMaybe<Scalars['String']['input']>
    printableNumber_not_ends_with?: InputMaybe<Scalars['String']['input']>
    printableNumber_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    printableNumber_not_i?: InputMaybe<Scalars['String']['input']>
    printableNumber_not_starts_with?: InputMaybe<Scalars['String']['input']>
    printableNumber_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    printableNumber_starts_with?: InputMaybe<Scalars['String']['input']>
    printableNumber_starts_with_i?: InputMaybe<Scalars['String']['input']>
    serviceConsumer?: InputMaybe<ServiceConsumerWhereInput>
    serviceConsumer_is_null?: InputMaybe<Scalars['Boolean']['input']>
    toPay?: InputMaybe<Scalars['String']['input']>
    toPay_contains?: InputMaybe<Scalars['String']['input']>
    toPay_contains_i?: InputMaybe<Scalars['String']['input']>
    toPay_ends_with?: InputMaybe<Scalars['String']['input']>
    toPay_ends_with_i?: InputMaybe<Scalars['String']['input']>
    toPay_i?: InputMaybe<Scalars['String']['input']>
    toPay_not?: InputMaybe<Scalars['String']['input']>
    toPay_not_contains?: InputMaybe<Scalars['String']['input']>
    toPay_not_contains_i?: InputMaybe<Scalars['String']['input']>
    toPay_not_ends_with?: InputMaybe<Scalars['String']['input']>
    toPay_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    toPay_not_i?: InputMaybe<Scalars['String']['input']>
    toPay_not_starts_with?: InputMaybe<Scalars['String']['input']>
    toPay_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    toPay_starts_with?: InputMaybe<Scalars['String']['input']>
    toPay_starts_with_i?: InputMaybe<Scalars['String']['input']>
}

export type ResidentBillingVirtualReceiptWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<ResidentBillingVirtualReceiptWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<ResidentBillingVirtualReceiptWhereInput>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    period?: InputMaybe<Scalars['String']['input']>
    period_contains?: InputMaybe<Scalars['String']['input']>
    period_contains_i?: InputMaybe<Scalars['String']['input']>
    period_ends_with?: InputMaybe<Scalars['String']['input']>
    period_ends_with_i?: InputMaybe<Scalars['String']['input']>
    period_i?: InputMaybe<Scalars['String']['input']>
    period_not?: InputMaybe<Scalars['String']['input']>
    period_not_contains?: InputMaybe<Scalars['String']['input']>
    period_not_contains_i?: InputMaybe<Scalars['String']['input']>
    period_not_ends_with?: InputMaybe<Scalars['String']['input']>
    period_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    period_not_i?: InputMaybe<Scalars['String']['input']>
    period_not_starts_with?: InputMaybe<Scalars['String']['input']>
    period_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    period_starts_with?: InputMaybe<Scalars['String']['input']>
    period_starts_with_i?: InputMaybe<Scalars['String']['input']>
    printableNumber?: InputMaybe<Scalars['String']['input']>
    printableNumber_contains?: InputMaybe<Scalars['String']['input']>
    printableNumber_contains_i?: InputMaybe<Scalars['String']['input']>
    printableNumber_ends_with?: InputMaybe<Scalars['String']['input']>
    printableNumber_ends_with_i?: InputMaybe<Scalars['String']['input']>
    printableNumber_i?: InputMaybe<Scalars['String']['input']>
    printableNumber_not?: InputMaybe<Scalars['String']['input']>
    printableNumber_not_contains?: InputMaybe<Scalars['String']['input']>
    printableNumber_not_contains_i?: InputMaybe<Scalars['String']['input']>
    printableNumber_not_ends_with?: InputMaybe<Scalars['String']['input']>
    printableNumber_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    printableNumber_not_i?: InputMaybe<Scalars['String']['input']>
    printableNumber_not_starts_with?: InputMaybe<Scalars['String']['input']>
    printableNumber_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    printableNumber_starts_with?: InputMaybe<Scalars['String']['input']>
    printableNumber_starts_with_i?: InputMaybe<Scalars['String']['input']>
    serviceConsumer?: InputMaybe<ServiceConsumerWhereInput>
    serviceConsumer_is_null?: InputMaybe<Scalars['Boolean']['input']>
    toPay?: InputMaybe<Scalars['String']['input']>
    toPay_contains?: InputMaybe<Scalars['String']['input']>
    toPay_contains_i?: InputMaybe<Scalars['String']['input']>
    toPay_ends_with?: InputMaybe<Scalars['String']['input']>
    toPay_ends_with_i?: InputMaybe<Scalars['String']['input']>
    toPay_i?: InputMaybe<Scalars['String']['input']>
    toPay_not?: InputMaybe<Scalars['String']['input']>
    toPay_not_contains?: InputMaybe<Scalars['String']['input']>
    toPay_not_contains_i?: InputMaybe<Scalars['String']['input']>
    toPay_not_ends_with?: InputMaybe<Scalars['String']['input']>
    toPay_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    toPay_not_i?: InputMaybe<Scalars['String']['input']>
    toPay_not_starts_with?: InputMaybe<Scalars['String']['input']>
    toPay_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    toPay_starts_with?: InputMaybe<Scalars['String']['input']>
    toPay_starts_with_i?: InputMaybe<Scalars['String']['input']>
}

export type ResidentContactRole = {
    __typename?: 'ResidentContactRole'
    id: Scalars['ID']['output']
    name: Scalars['String']['output']
    organization?: Maybe<Scalars['ID']['output']>
}

export type ResidentCreateInput = {
    address?: InputMaybe<Scalars['String']['input']>
    addressKey?: InputMaybe<Scalars['String']['input']>
    addressMeta?: InputMaybe<Scalars['JSON']['input']>
    addressSources?: InputMaybe<Scalars['JSON']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    property?: InputMaybe<PropertyRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    unitName?: InputMaybe<Scalars['String']['input']>
    unitType?: InputMaybe<ResidentUnitTypeType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type ResidentGroupedCounter = {
    __typename?: 'ResidentGroupedCounter'
    address: Scalars['String']['output']
    count: Scalars['String']['output']
}

/**  A keystone list  */
export type ResidentHistoryRecord = {
    __typename?: 'ResidentHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the ResidentHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the ResidentHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the ResidentHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the ResidentHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    address?: Maybe<Scalars['String']['output']>
    addressKey?: Maybe<Scalars['String']['output']>
    addressMeta?: Maybe<Scalars['JSON']['output']>
    addressSources?: Maybe<Scalars['JSON']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<ResidentHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    property?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    unitName?: Maybe<Scalars['String']['output']>
    unitType?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    user?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type ResidentHistoryRecordCreateInput = {
    address?: InputMaybe<Scalars['String']['input']>
    addressKey?: InputMaybe<Scalars['String']['input']>
    addressMeta?: InputMaybe<Scalars['JSON']['input']>
    addressSources?: InputMaybe<Scalars['JSON']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<ResidentHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    property?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    unitName?: InputMaybe<Scalars['String']['input']>
    unitType?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum ResidentHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type ResidentHistoryRecordUpdateInput = {
    address?: InputMaybe<Scalars['String']['input']>
    addressKey?: InputMaybe<Scalars['String']['input']>
    addressMeta?: InputMaybe<Scalars['JSON']['input']>
    addressSources?: InputMaybe<Scalars['JSON']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<ResidentHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    property?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    unitName?: InputMaybe<Scalars['String']['input']>
    unitType?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type ResidentHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<ResidentHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<ResidentHistoryRecordWhereInput>>>
    address?: InputMaybe<Scalars['String']['input']>
    addressKey?: InputMaybe<Scalars['String']['input']>
    addressKey_contains?: InputMaybe<Scalars['String']['input']>
    addressKey_contains_i?: InputMaybe<Scalars['String']['input']>
    addressKey_ends_with?: InputMaybe<Scalars['String']['input']>
    addressKey_ends_with_i?: InputMaybe<Scalars['String']['input']>
    addressKey_i?: InputMaybe<Scalars['String']['input']>
    addressKey_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    addressKey_not?: InputMaybe<Scalars['String']['input']>
    addressKey_not_contains?: InputMaybe<Scalars['String']['input']>
    addressKey_not_contains_i?: InputMaybe<Scalars['String']['input']>
    addressKey_not_ends_with?: InputMaybe<Scalars['String']['input']>
    addressKey_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    addressKey_not_i?: InputMaybe<Scalars['String']['input']>
    addressKey_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    addressKey_not_starts_with?: InputMaybe<Scalars['String']['input']>
    addressKey_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    addressKey_starts_with?: InputMaybe<Scalars['String']['input']>
    addressKey_starts_with_i?: InputMaybe<Scalars['String']['input']>
    addressMeta?: InputMaybe<Scalars['JSON']['input']>
    addressMeta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    addressMeta_not?: InputMaybe<Scalars['JSON']['input']>
    addressMeta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    addressSources?: InputMaybe<Scalars['JSON']['input']>
    addressSources_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    addressSources_not?: InputMaybe<Scalars['JSON']['input']>
    addressSources_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    address_contains?: InputMaybe<Scalars['String']['input']>
    address_contains_i?: InputMaybe<Scalars['String']['input']>
    address_ends_with?: InputMaybe<Scalars['String']['input']>
    address_ends_with_i?: InputMaybe<Scalars['String']['input']>
    address_i?: InputMaybe<Scalars['String']['input']>
    address_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    address_not?: InputMaybe<Scalars['String']['input']>
    address_not_contains?: InputMaybe<Scalars['String']['input']>
    address_not_contains_i?: InputMaybe<Scalars['String']['input']>
    address_not_ends_with?: InputMaybe<Scalars['String']['input']>
    address_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    address_not_i?: InputMaybe<Scalars['String']['input']>
    address_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    address_not_starts_with?: InputMaybe<Scalars['String']['input']>
    address_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    address_starts_with?: InputMaybe<Scalars['String']['input']>
    address_starts_with_i?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<ResidentHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<ResidentHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<ResidentHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<ResidentHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    property?: InputMaybe<Scalars['String']['input']>
    property_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    property_not?: InputMaybe<Scalars['String']['input']>
    property_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    unitName?: InputMaybe<Scalars['String']['input']>
    unitName_contains?: InputMaybe<Scalars['String']['input']>
    unitName_contains_i?: InputMaybe<Scalars['String']['input']>
    unitName_ends_with?: InputMaybe<Scalars['String']['input']>
    unitName_ends_with_i?: InputMaybe<Scalars['String']['input']>
    unitName_i?: InputMaybe<Scalars['String']['input']>
    unitName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitName_not?: InputMaybe<Scalars['String']['input']>
    unitName_not_contains?: InputMaybe<Scalars['String']['input']>
    unitName_not_contains_i?: InputMaybe<Scalars['String']['input']>
    unitName_not_ends_with?: InputMaybe<Scalars['String']['input']>
    unitName_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    unitName_not_i?: InputMaybe<Scalars['String']['input']>
    unitName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitName_not_starts_with?: InputMaybe<Scalars['String']['input']>
    unitName_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    unitName_starts_with?: InputMaybe<Scalars['String']['input']>
    unitName_starts_with_i?: InputMaybe<Scalars['String']['input']>
    unitType?: InputMaybe<Scalars['String']['input']>
    unitType_contains?: InputMaybe<Scalars['String']['input']>
    unitType_contains_i?: InputMaybe<Scalars['String']['input']>
    unitType_ends_with?: InputMaybe<Scalars['String']['input']>
    unitType_ends_with_i?: InputMaybe<Scalars['String']['input']>
    unitType_i?: InputMaybe<Scalars['String']['input']>
    unitType_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitType_not?: InputMaybe<Scalars['String']['input']>
    unitType_not_contains?: InputMaybe<Scalars['String']['input']>
    unitType_not_contains_i?: InputMaybe<Scalars['String']['input']>
    unitType_not_ends_with?: InputMaybe<Scalars['String']['input']>
    unitType_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    unitType_not_i?: InputMaybe<Scalars['String']['input']>
    unitType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitType_not_starts_with?: InputMaybe<Scalars['String']['input']>
    unitType_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    unitType_starts_with?: InputMaybe<Scalars['String']['input']>
    unitType_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user?: InputMaybe<Scalars['String']['input']>
    user_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user_not?: InputMaybe<Scalars['String']['input']>
    user_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type ResidentHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type ResidentHistoryRecordsCreateInput = {
    data?: InputMaybe<ResidentHistoryRecordCreateInput>
}

export type ResidentHistoryRecordsUpdateInput = {
    data?: InputMaybe<ResidentHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type ResidentOverviewResult = {
    __typename?: 'ResidentOverviewResult'
    residents?: Maybe<Array<ResidentGroupedCounter>>
}

export type ResidentProperty = {
    __typename?: 'ResidentProperty'
    address: Scalars['String']['output']
    addressKey?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    name?: Maybe<Scalars['String']['output']>
}

export type ResidentRelateToOneInput = {
    connect?: InputMaybe<ResidentWhereUniqueInput>
    create?: InputMaybe<ResidentCreateInput>
    disconnect?: InputMaybe<ResidentWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type ResidentScopesInput = {
    billingAccountNumbers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    property: PropertyWhereUniqueInput
    skipBillingAccountNumbers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    skipUnits?: InputMaybe<Array<InputMaybe<PropertyUnitInput>>>
    units?: InputMaybe<Array<InputMaybe<PropertyUnitInput>>>
}

export enum ResidentUnitTypeType {
    Apartment = 'apartment',
    Commercial = 'commercial',
    Flat = 'flat',
    Parking = 'parking',
    Warehouse = 'warehouse',
}

export type ResidentUpdateInput = {
    address?: InputMaybe<Scalars['String']['input']>
    addressKey?: InputMaybe<Scalars['String']['input']>
    addressMeta?: InputMaybe<Scalars['JSON']['input']>
    addressSources?: InputMaybe<Scalars['JSON']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    property?: InputMaybe<PropertyRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    unitName?: InputMaybe<Scalars['String']['input']>
    unitType?: InputMaybe<ResidentUnitTypeType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type ResidentWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<ResidentWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<ResidentWhereInput>>>
    address?: InputMaybe<Scalars['String']['input']>
    addressKey?: InputMaybe<Scalars['String']['input']>
    addressKey_contains?: InputMaybe<Scalars['String']['input']>
    addressKey_contains_i?: InputMaybe<Scalars['String']['input']>
    addressKey_ends_with?: InputMaybe<Scalars['String']['input']>
    addressKey_ends_with_i?: InputMaybe<Scalars['String']['input']>
    addressKey_i?: InputMaybe<Scalars['String']['input']>
    addressKey_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    addressKey_not?: InputMaybe<Scalars['String']['input']>
    addressKey_not_contains?: InputMaybe<Scalars['String']['input']>
    addressKey_not_contains_i?: InputMaybe<Scalars['String']['input']>
    addressKey_not_ends_with?: InputMaybe<Scalars['String']['input']>
    addressKey_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    addressKey_not_i?: InputMaybe<Scalars['String']['input']>
    addressKey_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    addressKey_not_starts_with?: InputMaybe<Scalars['String']['input']>
    addressKey_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    addressKey_starts_with?: InputMaybe<Scalars['String']['input']>
    addressKey_starts_with_i?: InputMaybe<Scalars['String']['input']>
    addressMeta?: InputMaybe<Scalars['JSON']['input']>
    addressMeta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    addressMeta_not?: InputMaybe<Scalars['JSON']['input']>
    addressMeta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    addressSources?: InputMaybe<Scalars['JSON']['input']>
    addressSources_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    addressSources_not?: InputMaybe<Scalars['JSON']['input']>
    addressSources_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    address_contains?: InputMaybe<Scalars['String']['input']>
    address_contains_i?: InputMaybe<Scalars['String']['input']>
    address_ends_with?: InputMaybe<Scalars['String']['input']>
    address_ends_with_i?: InputMaybe<Scalars['String']['input']>
    address_i?: InputMaybe<Scalars['String']['input']>
    address_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    address_not?: InputMaybe<Scalars['String']['input']>
    address_not_contains?: InputMaybe<Scalars['String']['input']>
    address_not_contains_i?: InputMaybe<Scalars['String']['input']>
    address_not_ends_with?: InputMaybe<Scalars['String']['input']>
    address_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    address_not_i?: InputMaybe<Scalars['String']['input']>
    address_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    address_not_starts_with?: InputMaybe<Scalars['String']['input']>
    address_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    address_starts_with?: InputMaybe<Scalars['String']['input']>
    address_starts_with_i?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    property?: InputMaybe<PropertyWhereInput>
    property_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    unitName?: InputMaybe<Scalars['String']['input']>
    unitName_contains?: InputMaybe<Scalars['String']['input']>
    unitName_contains_i?: InputMaybe<Scalars['String']['input']>
    unitName_ends_with?: InputMaybe<Scalars['String']['input']>
    unitName_ends_with_i?: InputMaybe<Scalars['String']['input']>
    unitName_i?: InputMaybe<Scalars['String']['input']>
    unitName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitName_not?: InputMaybe<Scalars['String']['input']>
    unitName_not_contains?: InputMaybe<Scalars['String']['input']>
    unitName_not_contains_i?: InputMaybe<Scalars['String']['input']>
    unitName_not_ends_with?: InputMaybe<Scalars['String']['input']>
    unitName_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    unitName_not_i?: InputMaybe<Scalars['String']['input']>
    unitName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitName_not_starts_with?: InputMaybe<Scalars['String']['input']>
    unitName_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    unitName_starts_with?: InputMaybe<Scalars['String']['input']>
    unitName_starts_with_i?: InputMaybe<Scalars['String']['input']>
    unitType?: InputMaybe<ResidentUnitTypeType>
    unitType_in?: InputMaybe<Array<InputMaybe<ResidentUnitTypeType>>>
    unitType_not?: InputMaybe<ResidentUnitTypeType>
    unitType_not_in?: InputMaybe<Array<InputMaybe<ResidentUnitTypeType>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    user?: InputMaybe<UserWhereInput>
    user_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type ResidentWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type ResidentsCreateInput = {
    data?: InputMaybe<ResidentCreateInput>
}

export type ResidentsUpdateInput = {
    data?: InputMaybe<ResidentUpdateInput>
    id: Scalars['ID']['input']
}

export type SendB2CAppPushMessageData = {
    B2CAppContext?: InputMaybe<Scalars['String']['input']>
    body: Scalars['String']['input']
    callId?: InputMaybe<Scalars['String']['input']>
    codec?: InputMaybe<Scalars['String']['input']>
    stun?: InputMaybe<Scalars['String']['input']>
    title?: InputMaybe<Scalars['String']['input']>
    voipAddress?: InputMaybe<Scalars['String']['input']>
    voipDtfmCommand?: InputMaybe<Scalars['String']['input']>
    voipLogin?: InputMaybe<Scalars['String']['input']>
    voipPassword?: InputMaybe<Scalars['String']['input']>
    voipType?: InputMaybe<Scalars['String']['input']>
}

export type SendB2CAppPushMessageInput = {
    app: B2CAppWhereUniqueInput
    data: SendB2CAppPushMessageData
    dv: Scalars['Int']['input']
    resident: ResidentWhereUniqueInput
    sender: SenderFieldInput
    type: SendB2CAppPushMessageType
    user: UserWhereUniqueInput
}

export type SendB2CAppPushMessageOutput = {
    __typename?: 'SendB2CAppPushMessageOutput'
    id: Scalars['String']['output']
    status: Scalars['String']['output']
}

export enum SendB2CAppPushMessageType {
    B2CAppMessagePush = 'B2C_APP_MESSAGE_PUSH',
    VoipIncomingCallMessage = 'VOIP_INCOMING_CALL_MESSAGE',
}

export type SendMessageInput = {
    dv: Scalars['Int']['input']
    emailFrom?: InputMaybe<Scalars['String']['input']>
    lang: SendMessageLang
    meta: Scalars['JSON']['input']
    organization?: InputMaybe<OrganizationWhereUniqueInput>
    sender: SenderFieldInput
    to: SendMessageToInput
    type: MessageType
    uniqKey?: InputMaybe<Scalars['String']['input']>
}

export enum SendMessageLang {
    En = 'en',
    Ru = 'ru',
}

export type SendMessageOutput = {
    __typename?: 'SendMessageOutput'
    id: Scalars['String']['output']
    isDuplicateMessage?: Maybe<Scalars['Boolean']['output']>
    status: Scalars['String']['output']
}

export type SendMessageToInput = {
    email?: InputMaybe<Scalars['String']['input']>
    phone?: InputMaybe<Scalars['String']['input']>
    remoteClient?: InputMaybe<RemoteClientWhereInput>
    user?: InputMaybe<UserWhereUniqueInput>
}

export type SendMessageToResidentScopesServiceInput = {
    dv: Scalars['Int']['input']
    lang?: InputMaybe<SendMessageLang>
    meta?: InputMaybe<Scalars['JSON']['input']>
    scopes: Array<ResidentScopesInput>
    sender: Scalars['JSON']['input']
    type: MessageType
    uniqKeyTemplate?: InputMaybe<Scalars['String']['input']>
}

export type SendMessageToResidentScopesServiceOutput = {
    __typename?: 'SendMessageToResidentScopesServiceOutput'
    status: Scalars['String']['output']
}

export type SendMessageToSupportInput = {
    appVersion: Scalars['String']['input']
    attachments?: InputMaybe<Array<InputMaybe<Scalars['Upload']['input']>>>
    dv: Scalars['Int']['input']
    emailFrom?: InputMaybe<Scalars['String']['input']>
    lang: SendMessageToSupportLang
    meta: Scalars['JSON']['input']
    os: Scalars['String']['input']
    sender: SenderFieldInput
    text: Scalars['String']['input']
}

export enum SendMessageToSupportLang {
    En = 'en',
    Ru = 'ru',
}

export type SendMessageToSupportOutput = {
    __typename?: 'SendMessageToSupportOutput'
    id: Scalars['String']['output']
    status: Scalars['String']['output']
}

export type SendNewBillingReceiptFilesNotificationsInput = {
    createdAfter: Scalars['String']['input']
    dv: Scalars['Int']['input']
    organization: OrganizationWhereUniqueInput
    period: Scalars['String']['input']
    sender: SenderFieldInput
}

export type SendNewBillingReceiptFilesNotificationsOutput = {
    __typename?: 'SendNewBillingReceiptFilesNotificationsOutput'
    taskStarted: Scalars['Boolean']['output']
}

export type SendNewReceiptMessagesToResidentScopesInput = {
    category: BillingCategoryWhereUniqueInput
    context: BillingIntegrationOrganizationContextWhereUniqueInput
    dv: Scalars['Int']['input']
    lang?: InputMaybe<SendMessageLang>
    meta?: InputMaybe<Scalars['JSON']['input']>
    period: Scalars['String']['input']
    scopes: Array<InputMaybe<PropertyScopesInput>>
    sender: Scalars['JSON']['input']
}

export type SendNewReceiptMessagesToResidentScopesOutput = {
    __typename?: 'SendNewReceiptMessagesToResidentScopesOutput'
    status: Scalars['String']['output']
}

export type SenderField = {
    __typename?: 'SenderField'
    dv: Scalars['Int']['output']
    fingerprint: Scalars['String']['output']
}

export type SenderFieldInput = {
    dv: Scalars['Int']['input']
    fingerprint: Scalars['String']['input']
}

/**  Service Consumer object. Existence of this object means that the resident is willing to pay for certain services  */
export type ServiceConsumer = {
    __typename?: 'ServiceConsumer'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the ServiceConsumer List config, or
   *  2. As an alias to the field set on 'labelField' in the ServiceConsumer List config, or
   *  3. As an alias to a 'name' field on the ServiceConsumer List (if one exists), or
   *  4. As an alias to the 'id' field on the ServiceConsumer List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  Account number taken from resident. This is what resident think his account number is  */
    accountNumber?: Maybe<Scalars['String']['output']>
    /**  Acquiring integration context, that this serviceConsumer is connected to  */
    acquiringIntegrationContext?: Maybe<AcquiringIntegrationContext>
    /**  [DEPRECATED] Billing integration context, that this serviceConsumer is connected to  */
    billingIntegrationContext?: Maybe<BillingIntegrationOrganizationContext>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  Determines if the resident created this service consumer providing the account number, or it was created automatically based on the house address and unit name with unit type  */
    isDiscovered?: Maybe<Scalars['Boolean']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
    organization?: Maybe<Organization>
    /**  A payment category for this resident  */
    paymentCategory?: Maybe<Scalars['String']['output']>
    /**  Resident object  */
    resident?: Maybe<Resident>
    /**  AcquiringIntegration, that is returned for current serviceConsumer in mobile client  */
    residentAcquiringIntegrationContext?: Maybe<ResidentAcquiringIntegrationContext>
    /**  [DEPRECATED] BillingAccount id, that is returned for current serviceConsumer in mobile client  */
    residentBillingAccount?: Maybe<ResidentBillingAccount>
    /**  Organization data, that is returned for current resident in mobile client  */
    residentOrganization?: Maybe<ResidentOrganization>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type ServiceConsumerCreateInput = {
    accountNumber?: InputMaybe<Scalars['String']['input']>
    acquiringIntegrationContext?: InputMaybe<AcquiringIntegrationContextRelateToOneInput>
    billingIntegrationContext?: InputMaybe<BillingIntegrationOrganizationContextRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    isDiscovered?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    paymentCategory?: InputMaybe<Scalars['String']['input']>
    resident?: InputMaybe<ResidentRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type ServiceConsumerHistoryRecord = {
    __typename?: 'ServiceConsumerHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the ServiceConsumerHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the ServiceConsumerHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the ServiceConsumerHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the ServiceConsumerHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    accountNumber?: Maybe<Scalars['String']['output']>
    acquiringIntegrationContext?: Maybe<Scalars['String']['output']>
    billingIntegrationContext?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<ServiceConsumerHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    isDiscovered?: Maybe<Scalars['Boolean']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    paymentCategory?: Maybe<Scalars['String']['output']>
    resident?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type ServiceConsumerHistoryRecordCreateInput = {
    accountNumber?: InputMaybe<Scalars['String']['input']>
    acquiringIntegrationContext?: InputMaybe<Scalars['String']['input']>
    billingIntegrationContext?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<ServiceConsumerHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    isDiscovered?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    paymentCategory?: InputMaybe<Scalars['String']['input']>
    resident?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum ServiceConsumerHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type ServiceConsumerHistoryRecordUpdateInput = {
    accountNumber?: InputMaybe<Scalars['String']['input']>
    acquiringIntegrationContext?: InputMaybe<Scalars['String']['input']>
    billingIntegrationContext?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<ServiceConsumerHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    isDiscovered?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    paymentCategory?: InputMaybe<Scalars['String']['input']>
    resident?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type ServiceConsumerHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<ServiceConsumerHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<ServiceConsumerHistoryRecordWhereInput>>>
    accountNumber?: InputMaybe<Scalars['String']['input']>
    accountNumber_contains?: InputMaybe<Scalars['String']['input']>
    accountNumber_contains_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_ends_with?: InputMaybe<Scalars['String']['input']>
    accountNumber_ends_with_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    accountNumber_not?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_contains?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_contains_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_ends_with?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    accountNumber_not_starts_with?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_starts_with?: InputMaybe<Scalars['String']['input']>
    accountNumber_starts_with_i?: InputMaybe<Scalars['String']['input']>
    acquiringIntegrationContext?: InputMaybe<Scalars['String']['input']>
    acquiringIntegrationContext_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    acquiringIntegrationContext_not?: InputMaybe<Scalars['String']['input']>
    acquiringIntegrationContext_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    billingIntegrationContext?: InputMaybe<Scalars['String']['input']>
    billingIntegrationContext_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    billingIntegrationContext_not?: InputMaybe<Scalars['String']['input']>
    billingIntegrationContext_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<ServiceConsumerHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<ServiceConsumerHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<ServiceConsumerHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<ServiceConsumerHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    isDiscovered?: InputMaybe<Scalars['Boolean']['input']>
    isDiscovered_not?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    paymentCategory?: InputMaybe<Scalars['String']['input']>
    paymentCategory_contains?: InputMaybe<Scalars['String']['input']>
    paymentCategory_contains_i?: InputMaybe<Scalars['String']['input']>
    paymentCategory_ends_with?: InputMaybe<Scalars['String']['input']>
    paymentCategory_ends_with_i?: InputMaybe<Scalars['String']['input']>
    paymentCategory_i?: InputMaybe<Scalars['String']['input']>
    paymentCategory_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    paymentCategory_not?: InputMaybe<Scalars['String']['input']>
    paymentCategory_not_contains?: InputMaybe<Scalars['String']['input']>
    paymentCategory_not_contains_i?: InputMaybe<Scalars['String']['input']>
    paymentCategory_not_ends_with?: InputMaybe<Scalars['String']['input']>
    paymentCategory_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    paymentCategory_not_i?: InputMaybe<Scalars['String']['input']>
    paymentCategory_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    paymentCategory_not_starts_with?: InputMaybe<Scalars['String']['input']>
    paymentCategory_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    paymentCategory_starts_with?: InputMaybe<Scalars['String']['input']>
    paymentCategory_starts_with_i?: InputMaybe<Scalars['String']['input']>
    resident?: InputMaybe<Scalars['String']['input']>
    resident_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    resident_not?: InputMaybe<Scalars['String']['input']>
    resident_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type ServiceConsumerHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type ServiceConsumerHistoryRecordsCreateInput = {
    data?: InputMaybe<ServiceConsumerHistoryRecordCreateInput>
}

export type ServiceConsumerHistoryRecordsUpdateInput = {
    data?: InputMaybe<ServiceConsumerHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type ServiceConsumerRelateToOneInput = {
    connect?: InputMaybe<ServiceConsumerWhereUniqueInput>
    create?: InputMaybe<ServiceConsumerCreateInput>
    disconnect?: InputMaybe<ServiceConsumerWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type ServiceConsumerUpdateInput = {
    accountNumber?: InputMaybe<Scalars['String']['input']>
    acquiringIntegrationContext?: InputMaybe<AcquiringIntegrationContextRelateToOneInput>
    billingIntegrationContext?: InputMaybe<BillingIntegrationOrganizationContextRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    isDiscovered?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    paymentCategory?: InputMaybe<Scalars['String']['input']>
    resident?: InputMaybe<ResidentRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type ServiceConsumerWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<ServiceConsumerWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<ServiceConsumerWhereInput>>>
    accountNumber?: InputMaybe<Scalars['String']['input']>
    accountNumber_contains?: InputMaybe<Scalars['String']['input']>
    accountNumber_contains_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_ends_with?: InputMaybe<Scalars['String']['input']>
    accountNumber_ends_with_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    accountNumber_not?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_contains?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_contains_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_ends_with?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    accountNumber_not_starts_with?: InputMaybe<Scalars['String']['input']>
    accountNumber_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    accountNumber_starts_with?: InputMaybe<Scalars['String']['input']>
    accountNumber_starts_with_i?: InputMaybe<Scalars['String']['input']>
    acquiringIntegrationContext?: InputMaybe<AcquiringIntegrationContextWhereInput>
    acquiringIntegrationContext_is_null?: InputMaybe<Scalars['Boolean']['input']>
    billingIntegrationContext?: InputMaybe<BillingIntegrationOrganizationContextWhereInput>
    billingIntegrationContext_is_null?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    isDiscovered?: InputMaybe<Scalars['Boolean']['input']>
    isDiscovered_not?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    paymentCategory?: InputMaybe<Scalars['String']['input']>
    paymentCategory_contains?: InputMaybe<Scalars['String']['input']>
    paymentCategory_contains_i?: InputMaybe<Scalars['String']['input']>
    paymentCategory_ends_with?: InputMaybe<Scalars['String']['input']>
    paymentCategory_ends_with_i?: InputMaybe<Scalars['String']['input']>
    paymentCategory_i?: InputMaybe<Scalars['String']['input']>
    paymentCategory_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    paymentCategory_not?: InputMaybe<Scalars['String']['input']>
    paymentCategory_not_contains?: InputMaybe<Scalars['String']['input']>
    paymentCategory_not_contains_i?: InputMaybe<Scalars['String']['input']>
    paymentCategory_not_ends_with?: InputMaybe<Scalars['String']['input']>
    paymentCategory_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    paymentCategory_not_i?: InputMaybe<Scalars['String']['input']>
    paymentCategory_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    paymentCategory_not_starts_with?: InputMaybe<Scalars['String']['input']>
    paymentCategory_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    paymentCategory_starts_with?: InputMaybe<Scalars['String']['input']>
    paymentCategory_starts_with_i?: InputMaybe<Scalars['String']['input']>
    resident?: InputMaybe<ResidentWhereInput>
    resident_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type ServiceConsumerWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type ServiceConsumersCreateInput = {
    data?: InputMaybe<ServiceConsumerCreateInput>
}

export type ServiceConsumersUpdateInput = {
    data?: InputMaybe<ServiceConsumerUpdateInput>
    id: Scalars['ID']['input']
}

/**  Availability time period of service features for client organization. Can be trial or payed.  */
export type ServiceSubscription = {
    __typename?: 'ServiceSubscription'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the ServiceSubscription List config, or
   *  2. As an alias to the field set on 'labelField' in the ServiceSubscription List config, or
   *  3. As an alias to a 'name' field on the ServiceSubscription List (if one exists), or
   *  4. As an alias to the 'id' field on the ServiceSubscription List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    /**  Currency of values for all price fields  */
    currency?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  When subscription should be ended  */
    finishAt?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    /**  Trial mode of subscription  */
    isTrial?: Maybe<Scalars['Boolean']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
    organization?: Maybe<Organization>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  When subscription was started  */
    startAt?: Maybe<Scalars['String']['output']>
    /**  Total price of this subscription, calculated as unitCost * unitsPayed  */
    totalPrice?: Maybe<Scalars['String']['output']>
    /**  System, from where subscription was created (our or external)  */
    type?: Maybe<ServiceSubscriptionTypeType>
    /**  How much one unit cost in Rubles for this subscription  */
    unitPrice?: Maybe<Scalars['String']['output']>
    /**  How much units are payed for this subscription  */
    unitsCount?: Maybe<Scalars['Int']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type ServiceSubscriptionCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    currency?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    finishAt?: InputMaybe<Scalars['String']['input']>
    isTrial?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    startAt?: InputMaybe<Scalars['String']['input']>
    totalPrice?: InputMaybe<Scalars['String']['input']>
    type?: InputMaybe<ServiceSubscriptionTypeType>
    unitPrice?: InputMaybe<Scalars['String']['input']>
    unitsCount?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type ServiceSubscriptionHistoryRecord = {
    __typename?: 'ServiceSubscriptionHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the ServiceSubscriptionHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the ServiceSubscriptionHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the ServiceSubscriptionHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the ServiceSubscriptionHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    currency?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    finishAt?: Maybe<Scalars['String']['output']>
    history_action?: Maybe<ServiceSubscriptionHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    isTrial?: Maybe<Scalars['Boolean']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    startAt?: Maybe<Scalars['String']['output']>
    totalPrice?: Maybe<Scalars['String']['output']>
    type?: Maybe<Scalars['String']['output']>
    unitPrice?: Maybe<Scalars['String']['output']>
    unitsCount?: Maybe<Scalars['Int']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type ServiceSubscriptionHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    currency?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    finishAt?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<ServiceSubscriptionHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    isTrial?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    startAt?: InputMaybe<Scalars['String']['input']>
    totalPrice?: InputMaybe<Scalars['String']['input']>
    type?: InputMaybe<Scalars['String']['input']>
    unitPrice?: InputMaybe<Scalars['String']['input']>
    unitsCount?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum ServiceSubscriptionHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type ServiceSubscriptionHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    currency?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    finishAt?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<ServiceSubscriptionHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    isTrial?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    startAt?: InputMaybe<Scalars['String']['input']>
    totalPrice?: InputMaybe<Scalars['String']['input']>
    type?: InputMaybe<Scalars['String']['input']>
    unitPrice?: InputMaybe<Scalars['String']['input']>
    unitsCount?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type ServiceSubscriptionHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<ServiceSubscriptionHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<ServiceSubscriptionHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    currency?: InputMaybe<Scalars['String']['input']>
    currency_contains?: InputMaybe<Scalars['String']['input']>
    currency_contains_i?: InputMaybe<Scalars['String']['input']>
    currency_ends_with?: InputMaybe<Scalars['String']['input']>
    currency_ends_with_i?: InputMaybe<Scalars['String']['input']>
    currency_i?: InputMaybe<Scalars['String']['input']>
    currency_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    currency_not?: InputMaybe<Scalars['String']['input']>
    currency_not_contains?: InputMaybe<Scalars['String']['input']>
    currency_not_contains_i?: InputMaybe<Scalars['String']['input']>
    currency_not_ends_with?: InputMaybe<Scalars['String']['input']>
    currency_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    currency_not_i?: InputMaybe<Scalars['String']['input']>
    currency_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    currency_not_starts_with?: InputMaybe<Scalars['String']['input']>
    currency_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    currency_starts_with?: InputMaybe<Scalars['String']['input']>
    currency_starts_with_i?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    finishAt?: InputMaybe<Scalars['String']['input']>
    finishAt_gt?: InputMaybe<Scalars['String']['input']>
    finishAt_gte?: InputMaybe<Scalars['String']['input']>
    finishAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    finishAt_lt?: InputMaybe<Scalars['String']['input']>
    finishAt_lte?: InputMaybe<Scalars['String']['input']>
    finishAt_not?: InputMaybe<Scalars['String']['input']>
    finishAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_action?: InputMaybe<ServiceSubscriptionHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<ServiceSubscriptionHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<ServiceSubscriptionHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<ServiceSubscriptionHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    isTrial?: InputMaybe<Scalars['Boolean']['input']>
    isTrial_not?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    startAt?: InputMaybe<Scalars['String']['input']>
    startAt_gt?: InputMaybe<Scalars['String']['input']>
    startAt_gte?: InputMaybe<Scalars['String']['input']>
    startAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    startAt_lt?: InputMaybe<Scalars['String']['input']>
    startAt_lte?: InputMaybe<Scalars['String']['input']>
    startAt_not?: InputMaybe<Scalars['String']['input']>
    startAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    totalPrice?: InputMaybe<Scalars['String']['input']>
    totalPrice_gt?: InputMaybe<Scalars['String']['input']>
    totalPrice_gte?: InputMaybe<Scalars['String']['input']>
    totalPrice_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    totalPrice_lt?: InputMaybe<Scalars['String']['input']>
    totalPrice_lte?: InputMaybe<Scalars['String']['input']>
    totalPrice_not?: InputMaybe<Scalars['String']['input']>
    totalPrice_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    type?: InputMaybe<Scalars['String']['input']>
    type_contains?: InputMaybe<Scalars['String']['input']>
    type_contains_i?: InputMaybe<Scalars['String']['input']>
    type_ends_with?: InputMaybe<Scalars['String']['input']>
    type_ends_with_i?: InputMaybe<Scalars['String']['input']>
    type_i?: InputMaybe<Scalars['String']['input']>
    type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    type_not?: InputMaybe<Scalars['String']['input']>
    type_not_contains?: InputMaybe<Scalars['String']['input']>
    type_not_contains_i?: InputMaybe<Scalars['String']['input']>
    type_not_ends_with?: InputMaybe<Scalars['String']['input']>
    type_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    type_not_i?: InputMaybe<Scalars['String']['input']>
    type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    type_not_starts_with?: InputMaybe<Scalars['String']['input']>
    type_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    type_starts_with?: InputMaybe<Scalars['String']['input']>
    type_starts_with_i?: InputMaybe<Scalars['String']['input']>
    unitPrice?: InputMaybe<Scalars['String']['input']>
    unitPrice_gt?: InputMaybe<Scalars['String']['input']>
    unitPrice_gte?: InputMaybe<Scalars['String']['input']>
    unitPrice_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitPrice_lt?: InputMaybe<Scalars['String']['input']>
    unitPrice_lte?: InputMaybe<Scalars['String']['input']>
    unitPrice_not?: InputMaybe<Scalars['String']['input']>
    unitPrice_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitsCount?: InputMaybe<Scalars['Int']['input']>
    unitsCount_gt?: InputMaybe<Scalars['Int']['input']>
    unitsCount_gte?: InputMaybe<Scalars['Int']['input']>
    unitsCount_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    unitsCount_lt?: InputMaybe<Scalars['Int']['input']>
    unitsCount_lte?: InputMaybe<Scalars['Int']['input']>
    unitsCount_not?: InputMaybe<Scalars['Int']['input']>
    unitsCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type ServiceSubscriptionHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type ServiceSubscriptionHistoryRecordsCreateInput = {
    data?: InputMaybe<ServiceSubscriptionHistoryRecordCreateInput>
}

export type ServiceSubscriptionHistoryRecordsUpdateInput = {
    data?: InputMaybe<ServiceSubscriptionHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export enum ServiceSubscriptionTypeType {
    Default = 'default',
    Sbbol = 'sbbol',
}

export type ServiceSubscriptionUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    currency?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    finishAt?: InputMaybe<Scalars['String']['input']>
    isTrial?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    startAt?: InputMaybe<Scalars['String']['input']>
    totalPrice?: InputMaybe<Scalars['String']['input']>
    type?: InputMaybe<ServiceSubscriptionTypeType>
    unitPrice?: InputMaybe<Scalars['String']['input']>
    unitsCount?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type ServiceSubscriptionWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<ServiceSubscriptionWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<ServiceSubscriptionWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    currency?: InputMaybe<Scalars['String']['input']>
    currency_contains?: InputMaybe<Scalars['String']['input']>
    currency_contains_i?: InputMaybe<Scalars['String']['input']>
    currency_ends_with?: InputMaybe<Scalars['String']['input']>
    currency_ends_with_i?: InputMaybe<Scalars['String']['input']>
    currency_i?: InputMaybe<Scalars['String']['input']>
    currency_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    currency_not?: InputMaybe<Scalars['String']['input']>
    currency_not_contains?: InputMaybe<Scalars['String']['input']>
    currency_not_contains_i?: InputMaybe<Scalars['String']['input']>
    currency_not_ends_with?: InputMaybe<Scalars['String']['input']>
    currency_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    currency_not_i?: InputMaybe<Scalars['String']['input']>
    currency_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    currency_not_starts_with?: InputMaybe<Scalars['String']['input']>
    currency_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    currency_starts_with?: InputMaybe<Scalars['String']['input']>
    currency_starts_with_i?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    finishAt?: InputMaybe<Scalars['String']['input']>
    finishAt_gt?: InputMaybe<Scalars['String']['input']>
    finishAt_gte?: InputMaybe<Scalars['String']['input']>
    finishAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    finishAt_lt?: InputMaybe<Scalars['String']['input']>
    finishAt_lte?: InputMaybe<Scalars['String']['input']>
    finishAt_not?: InputMaybe<Scalars['String']['input']>
    finishAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    isTrial?: InputMaybe<Scalars['Boolean']['input']>
    isTrial_not?: InputMaybe<Scalars['Boolean']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    startAt?: InputMaybe<Scalars['String']['input']>
    startAt_gt?: InputMaybe<Scalars['String']['input']>
    startAt_gte?: InputMaybe<Scalars['String']['input']>
    startAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    startAt_lt?: InputMaybe<Scalars['String']['input']>
    startAt_lte?: InputMaybe<Scalars['String']['input']>
    startAt_not?: InputMaybe<Scalars['String']['input']>
    startAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    totalPrice?: InputMaybe<Scalars['String']['input']>
    totalPrice_gt?: InputMaybe<Scalars['String']['input']>
    totalPrice_gte?: InputMaybe<Scalars['String']['input']>
    totalPrice_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    totalPrice_lt?: InputMaybe<Scalars['String']['input']>
    totalPrice_lte?: InputMaybe<Scalars['String']['input']>
    totalPrice_not?: InputMaybe<Scalars['String']['input']>
    totalPrice_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    type?: InputMaybe<ServiceSubscriptionTypeType>
    type_in?: InputMaybe<Array<InputMaybe<ServiceSubscriptionTypeType>>>
    type_not?: InputMaybe<ServiceSubscriptionTypeType>
    type_not_in?: InputMaybe<Array<InputMaybe<ServiceSubscriptionTypeType>>>
    unitPrice?: InputMaybe<Scalars['String']['input']>
    unitPrice_gt?: InputMaybe<Scalars['String']['input']>
    unitPrice_gte?: InputMaybe<Scalars['String']['input']>
    unitPrice_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitPrice_lt?: InputMaybe<Scalars['String']['input']>
    unitPrice_lte?: InputMaybe<Scalars['String']['input']>
    unitPrice_not?: InputMaybe<Scalars['String']['input']>
    unitPrice_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitsCount?: InputMaybe<Scalars['Int']['input']>
    unitsCount_gt?: InputMaybe<Scalars['Int']['input']>
    unitsCount_gte?: InputMaybe<Scalars['Int']['input']>
    unitsCount_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    unitsCount_lt?: InputMaybe<Scalars['Int']['input']>
    unitsCount_lte?: InputMaybe<Scalars['Int']['input']>
    unitsCount_not?: InputMaybe<Scalars['Int']['input']>
    unitsCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type ServiceSubscriptionWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type ServiceSubscriptionsCreateInput = {
    data?: InputMaybe<ServiceSubscriptionCreateInput>
}

export type ServiceSubscriptionsUpdateInput = {
    data?: InputMaybe<ServiceSubscriptionUpdateInput>
    id: Scalars['ID']['input']
}

export type SetMessageStatusInput = {
    deliveredAt?: InputMaybe<Scalars['String']['input']>
    dv: Scalars['Int']['input']
    message: MessageWhereUniqueInput
    readAt?: InputMaybe<Scalars['String']['input']>
    sender: SenderFieldInput
}

export type SetMessageStatusOutput = {
    __typename?: 'SetMessageStatusOutput'
    id: Scalars['ID']['output']
    status: Scalars['String']['output']
}

export type SettingsField = {
    __typename?: 'SettingsField'
    cardId: Scalars['String']['output']
}

export type SettingsFieldInput = {
    cardId: Scalars['String']['input']
}

export type ShareTicketInput = {
    employees: Array<Scalars['ID']['input']>
    sender: SenderFieldInput
    ticketId: Scalars['ID']['input']
}

export type ShareTicketOutput = {
    __typename?: 'ShareTicketOutput'
    status: Scalars['String']['output']
}

export type ShortScopesField = {
    __typename?: 'ShortScopesField'
    count: Scalars['Int']['output']
    firstOnes: Array<Maybe<NewsItemScope>>
}

export type SigninAsUserInput = {
    dv: Scalars['Int']['input']
    id: Scalars['ID']['input']
    sender: SenderFieldInput
}

export type SigninAsUserOutput = {
    __typename?: 'SigninAsUserOutput'
    token: Scalars['String']['output']
    user?: Maybe<User>
}

export type SigninResidentUserInput = {
    dv: Scalars['Int']['input']
    sender: SenderFieldInput
    token: Scalars['String']['input']
}

export type SigninResidentUserOutput = {
    __typename?: 'SigninResidentUserOutput'
    token: Scalars['String']['output']
    user?: Maybe<User>
}

export enum SortAcquiringIntegrationAccessRightHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortAcquiringIntegrationAccessRightsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IntegrationAsc = 'integration_ASC',
    IntegrationDesc = 'integration_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    UserAsc = 'user_ASC',
    UserDesc = 'user_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortAcquiringIntegrationContextHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    EmailAsc = 'email_ASC',
    EmailDesc = 'email_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    InvoiceEmailsAsc = 'invoiceEmails_ASC',
    InvoiceEmailsDesc = 'invoiceEmails_DESC',
    InvoiceReasonAsc = 'invoiceReason_ASC',
    InvoiceReasonDesc = 'invoiceReason_DESC',
    InvoiceSalesTaxPercentAsc = 'invoiceSalesTaxPercent_ASC',
    InvoiceSalesTaxPercentDesc = 'invoiceSalesTaxPercent_DESC',
    InvoiceStatusAsc = 'invoiceStatus_ASC',
    InvoiceStatusDesc = 'invoiceStatus_DESC',
    InvoiceTaxRegimeAsc = 'invoiceTaxRegime_ASC',
    InvoiceTaxRegimeDesc = 'invoiceTaxRegime_DESC',
    InvoiceVatPercentAsc = 'invoiceVatPercent_ASC',
    InvoiceVatPercentDesc = 'invoiceVatPercent_DESC',
    ReasonAsc = 'reason_ASC',
    ReasonDesc = 'reason_DESC',
    StatusAsc = 'status_ASC',
    StatusDesc = 'status_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortAcquiringIntegrationContextsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    EmailAsc = 'email_ASC',
    EmailDesc = 'email_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IntegrationAsc = 'integration_ASC',
    IntegrationDesc = 'integration_DESC',
    InvoiceEmailsAsc = 'invoiceEmails_ASC',
    InvoiceEmailsDesc = 'invoiceEmails_DESC',
    InvoiceReasonAsc = 'invoiceReason_ASC',
    InvoiceReasonDesc = 'invoiceReason_DESC',
    InvoiceSalesTaxPercentAsc = 'invoiceSalesTaxPercent_ASC',
    InvoiceSalesTaxPercentDesc = 'invoiceSalesTaxPercent_DESC',
    InvoiceStatusAsc = 'invoiceStatus_ASC',
    InvoiceStatusDesc = 'invoiceStatus_DESC',
    InvoiceTaxRegimeAsc = 'invoiceTaxRegime_ASC',
    InvoiceTaxRegimeDesc = 'invoiceTaxRegime_DESC',
    InvoiceVatPercentAsc = 'invoiceVatPercent_ASC',
    InvoiceVatPercentDesc = 'invoiceVatPercent_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    ReasonAsc = 'reason_ASC',
    ReasonDesc = 'reason_DESC',
    StatusAsc = 'status_ASC',
    StatusDesc = 'status_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortAcquiringIntegrationHistoryRecordsBy {
    CanGroupReceiptsAsc = 'canGroupReceipts_ASC',
    CanGroupReceiptsDesc = 'canGroupReceipts_DESC',
    ContextDefaultStatusAsc = 'contextDefaultStatus_ASC',
    ContextDefaultStatusDesc = 'contextDefaultStatus_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    HostUrlAsc = 'hostUrl_ASC',
    HostUrlDesc = 'hostUrl_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IsHiddenAsc = 'isHidden_ASC',
    IsHiddenDesc = 'isHidden_DESC',
    MaximumPaymentAmountAsc = 'maximumPaymentAmount_ASC',
    MaximumPaymentAmountDesc = 'maximumPaymentAmount_DESC',
    MinimumPaymentAmountAsc = 'minimumPaymentAmount_ASC',
    MinimumPaymentAmountDesc = 'minimumPaymentAmount_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    SetupUrlAsc = 'setupUrl_ASC',
    SetupUrlDesc = 'setupUrl_DESC',
    SupportedBillingIntegrationsGroupAsc = 'supportedBillingIntegrationsGroup_ASC',
    SupportedBillingIntegrationsGroupDesc = 'supportedBillingIntegrationsGroup_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
    VatPercentOptionsAsc = 'vatPercentOptions_ASC',
    VatPercentOptionsDesc = 'vatPercentOptions_DESC',
}

export enum SortAcquiringIntegrationsBy {
    AccessRightsAsc = 'accessRights_ASC',
    AccessRightsDesc = 'accessRights_DESC',
    CanGroupReceiptsAsc = 'canGroupReceipts_ASC',
    CanGroupReceiptsDesc = 'canGroupReceipts_DESC',
    ContextDefaultStatusAsc = 'contextDefaultStatus_ASC',
    ContextDefaultStatusDesc = 'contextDefaultStatus_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HostUrlAsc = 'hostUrl_ASC',
    HostUrlDesc = 'hostUrl_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IsHiddenAsc = 'isHidden_ASC',
    IsHiddenDesc = 'isHidden_DESC',
    MaximumPaymentAmountAsc = 'maximumPaymentAmount_ASC',
    MaximumPaymentAmountDesc = 'maximumPaymentAmount_DESC',
    MinimumPaymentAmountAsc = 'minimumPaymentAmount_ASC',
    MinimumPaymentAmountDesc = 'minimumPaymentAmount_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    SetupUrlAsc = 'setupUrl_ASC',
    SetupUrlDesc = 'setupUrl_DESC',
    SupportedBillingIntegrationsGroupAsc = 'supportedBillingIntegrationsGroup_ASC',
    SupportedBillingIntegrationsGroupDesc = 'supportedBillingIntegrationsGroup_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
    VatPercentOptionsAsc = 'vatPercentOptions_ASC',
    VatPercentOptionsDesc = 'vatPercentOptions_DESC',
}

export enum SortAllMiniAppsBy {
    ConnectedAtAsc = 'connectedAt_ASC',
    DisplayPriorityDesc = 'displayPriority_DESC',
}

export enum SortB2BAppAccessRightHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortB2BAppAccessRightSetHistoryRecordsBy {
    CanExecuteRegisterMetersReadingsAsc = 'canExecuteRegisterMetersReadings_ASC',
    CanExecuteRegisterMetersReadingsDesc = 'canExecuteRegisterMetersReadings_DESC',
    CanManageContactsAsc = 'canManageContacts_ASC',
    CanManageContactsDesc = 'canManageContacts_DESC',
    CanManageMeterReadingsAsc = 'canManageMeterReadings_ASC',
    CanManageMeterReadingsDesc = 'canManageMeterReadings_DESC',
    CanManageMetersAsc = 'canManageMeters_ASC',
    CanManageMetersDesc = 'canManageMeters_DESC',
    CanManageOrganizationsAsc = 'canManageOrganizations_ASC',
    CanManageOrganizationsDesc = 'canManageOrganizations_DESC',
    CanManagePropertiesAsc = 'canManageProperties_ASC',
    CanManagePropertiesDesc = 'canManageProperties_DESC',
    CanManageTicketCommentFilesAsc = 'canManageTicketCommentFiles_ASC',
    CanManageTicketCommentFilesDesc = 'canManageTicketCommentFiles_DESC',
    CanManageTicketCommentsAsc = 'canManageTicketComments_ASC',
    CanManageTicketCommentsDesc = 'canManageTicketComments_DESC',
    CanManageTicketFilesAsc = 'canManageTicketFiles_ASC',
    CanManageTicketFilesDesc = 'canManageTicketFiles_DESC',
    CanManageTicketsAsc = 'canManageTickets_ASC',
    CanManageTicketsDesc = 'canManageTickets_DESC',
    CanReadContactsAsc = 'canReadContacts_ASC',
    CanReadContactsDesc = 'canReadContacts_DESC',
    CanReadMeterReadingsAsc = 'canReadMeterReadings_ASC',
    CanReadMeterReadingsDesc = 'canReadMeterReadings_DESC',
    CanReadMetersAsc = 'canReadMeters_ASC',
    CanReadMetersDesc = 'canReadMeters_DESC',
    CanReadOrganizationsAsc = 'canReadOrganizations_ASC',
    CanReadOrganizationsDesc = 'canReadOrganizations_DESC',
    CanReadPropertiesAsc = 'canReadProperties_ASC',
    CanReadPropertiesDesc = 'canReadProperties_DESC',
    CanReadTicketCommentFilesAsc = 'canReadTicketCommentFiles_ASC',
    CanReadTicketCommentFilesDesc = 'canReadTicketCommentFiles_DESC',
    CanReadTicketCommentsAsc = 'canReadTicketComments_ASC',
    CanReadTicketCommentsDesc = 'canReadTicketComments_DESC',
    CanReadTicketFilesAsc = 'canReadTicketFiles_ASC',
    CanReadTicketFilesDesc = 'canReadTicketFiles_DESC',
    CanReadTicketsAsc = 'canReadTickets_ASC',
    CanReadTicketsDesc = 'canReadTickets_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortB2BAppAccessRightSetsBy {
    AppAsc = 'app_ASC',
    AppDesc = 'app_DESC',
    CanExecuteRegisterMetersReadingsAsc = 'canExecuteRegisterMetersReadings_ASC',
    CanExecuteRegisterMetersReadingsDesc = 'canExecuteRegisterMetersReadings_DESC',
    CanManageContactsAsc = 'canManageContacts_ASC',
    CanManageContactsDesc = 'canManageContacts_DESC',
    CanManageMeterReadingsAsc = 'canManageMeterReadings_ASC',
    CanManageMeterReadingsDesc = 'canManageMeterReadings_DESC',
    CanManageMetersAsc = 'canManageMeters_ASC',
    CanManageMetersDesc = 'canManageMeters_DESC',
    CanManageOrganizationsAsc = 'canManageOrganizations_ASC',
    CanManageOrganizationsDesc = 'canManageOrganizations_DESC',
    CanManagePropertiesAsc = 'canManageProperties_ASC',
    CanManagePropertiesDesc = 'canManageProperties_DESC',
    CanManageTicketCommentFilesAsc = 'canManageTicketCommentFiles_ASC',
    CanManageTicketCommentFilesDesc = 'canManageTicketCommentFiles_DESC',
    CanManageTicketCommentsAsc = 'canManageTicketComments_ASC',
    CanManageTicketCommentsDesc = 'canManageTicketComments_DESC',
    CanManageTicketFilesAsc = 'canManageTicketFiles_ASC',
    CanManageTicketFilesDesc = 'canManageTicketFiles_DESC',
    CanManageTicketsAsc = 'canManageTickets_ASC',
    CanManageTicketsDesc = 'canManageTickets_DESC',
    CanReadContactsAsc = 'canReadContacts_ASC',
    CanReadContactsDesc = 'canReadContacts_DESC',
    CanReadMeterReadingsAsc = 'canReadMeterReadings_ASC',
    CanReadMeterReadingsDesc = 'canReadMeterReadings_DESC',
    CanReadMetersAsc = 'canReadMeters_ASC',
    CanReadMetersDesc = 'canReadMeters_DESC',
    CanReadOrganizationsAsc = 'canReadOrganizations_ASC',
    CanReadOrganizationsDesc = 'canReadOrganizations_DESC',
    CanReadPropertiesAsc = 'canReadProperties_ASC',
    CanReadPropertiesDesc = 'canReadProperties_DESC',
    CanReadTicketCommentFilesAsc = 'canReadTicketCommentFiles_ASC',
    CanReadTicketCommentFilesDesc = 'canReadTicketCommentFiles_DESC',
    CanReadTicketCommentsAsc = 'canReadTicketComments_ASC',
    CanReadTicketCommentsDesc = 'canReadTicketComments_DESC',
    CanReadTicketFilesAsc = 'canReadTicketFiles_ASC',
    CanReadTicketFilesDesc = 'canReadTicketFiles_DESC',
    CanReadTicketsAsc = 'canReadTickets_ASC',
    CanReadTicketsDesc = 'canReadTickets_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortB2BAppAccessRightsBy {
    AccessRightSetAsc = 'accessRightSet_ASC',
    AccessRightSetDesc = 'accessRightSet_DESC',
    AppAsc = 'app_ASC',
    AppDesc = 'app_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    UserAsc = 'user_ASC',
    UserDesc = 'user_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortB2BAppContextHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    StatusAsc = 'status_ASC',
    StatusDesc = 'status_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortB2BAppContextsBy {
    AppAsc = 'app_ASC',
    AppDesc = 'app_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    StatusAsc = 'status_ASC',
    StatusDesc = 'status_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortB2BAppHistoryRecordsBy {
    AppUrlAsc = 'appUrl_ASC',
    AppUrlDesc = 'appUrl_DESC',
    CategoryAsc = 'category_ASC',
    CategoryDesc = 'category_DESC',
    ContextDefaultStatusAsc = 'contextDefaultStatus_ASC',
    ContextDefaultStatusDesc = 'contextDefaultStatus_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DeveloperAsc = 'developer_ASC',
    DeveloperDesc = 'developer_DESC',
    DisplayPriorityAsc = 'displayPriority_ASC',
    DisplayPriorityDesc = 'displayPriority_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HasDynamicTitleAsc = 'hasDynamicTitle_ASC',
    HasDynamicTitleDesc = 'hasDynamicTitle_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IconAsc = 'icon_ASC',
    IconDesc = 'icon_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IsGlobalAsc = 'isGlobal_ASC',
    IsGlobalDesc = 'isGlobal_DESC',
    IsHiddenAsc = 'isHidden_ASC',
    IsHiddenDesc = 'isHidden_DESC',
    IsPublicAsc = 'isPublic_ASC',
    IsPublicDesc = 'isPublic_DESC',
    LabelAsc = 'label_ASC',
    LabelDesc = 'label_DESC',
    MenuCategoryAsc = 'menuCategory_ASC',
    MenuCategoryDesc = 'menuCategory_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    PartnerUrlAsc = 'partnerUrl_ASC',
    PartnerUrlDesc = 'partnerUrl_DESC',
    PriceAsc = 'price_ASC',
    PriceDesc = 'price_DESC',
    ShortDescriptionAsc = 'shortDescription_ASC',
    ShortDescriptionDesc = 'shortDescription_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortB2BAppNewsSharingConfigHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CustomFormUrlAsc = 'customFormUrl_ASC',
    CustomFormUrlDesc = 'customFormUrl_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    GetRecipientsCountersUrlAsc = 'getRecipientsCountersUrl_ASC',
    GetRecipientsCountersUrlDesc = 'getRecipientsCountersUrl_DESC',
    GetRecipientsUrlAsc = 'getRecipientsUrl_ASC',
    GetRecipientsUrlDesc = 'getRecipientsUrl_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    PreviewUrlAsc = 'previewUrl_ASC',
    PreviewUrlDesc = 'previewUrl_DESC',
    PublishUrlAsc = 'publishUrl_ASC',
    PublishUrlDesc = 'publishUrl_DESC',
    PushNotificationSettingsAsc = 'pushNotificationSettings_ASC',
    PushNotificationSettingsDesc = 'pushNotificationSettings_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortB2BAppNewsSharingConfigsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    CustomFormUrlAsc = 'customFormUrl_ASC',
    CustomFormUrlDesc = 'customFormUrl_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    GetRecipientsCountersUrlAsc = 'getRecipientsCountersUrl_ASC',
    GetRecipientsCountersUrlDesc = 'getRecipientsCountersUrl_DESC',
    GetRecipientsUrlAsc = 'getRecipientsUrl_ASC',
    GetRecipientsUrlDesc = 'getRecipientsUrl_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    PreviewUrlAsc = 'previewUrl_ASC',
    PreviewUrlDesc = 'previewUrl_DESC',
    PublishUrlAsc = 'publishUrl_ASC',
    PublishUrlDesc = 'publishUrl_DESC',
    PushNotificationSettingsAsc = 'pushNotificationSettings_ASC',
    PushNotificationSettingsDesc = 'pushNotificationSettings_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortB2BAppPermissionHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    KeyAsc = 'key_ASC',
    KeyDesc = 'key_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortB2BAppPermissionsBy {
    AppAsc = 'app_ASC',
    AppDesc = 'app_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    KeyAsc = 'key_ASC',
    KeyDesc = 'key_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortB2BAppPromoBlockHistoryRecordsBy {
    BackgroundColorAsc = 'backgroundColor_ASC',
    BackgroundColorDesc = 'backgroundColor_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    ExternalAsc = 'external_ASC',
    ExternalDesc = 'external_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    PriorityAsc = 'priority_ASC',
    PriorityDesc = 'priority_DESC',
    SubtitleAsc = 'subtitle_ASC',
    SubtitleDesc = 'subtitle_DESC',
    TargetUrlAsc = 'targetUrl_ASC',
    TargetUrlDesc = 'targetUrl_DESC',
    TextVariantAsc = 'textVariant_ASC',
    TextVariantDesc = 'textVariant_DESC',
    TitleAsc = 'title_ASC',
    TitleDesc = 'title_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortB2BAppPromoBlocksBy {
    BackgroundColorAsc = 'backgroundColor_ASC',
    BackgroundColorDesc = 'backgroundColor_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    ExternalAsc = 'external_ASC',
    ExternalDesc = 'external_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    PriorityAsc = 'priority_ASC',
    PriorityDesc = 'priority_DESC',
    SubtitleAsc = 'subtitle_ASC',
    SubtitleDesc = 'subtitle_DESC',
    TargetUrlAsc = 'targetUrl_ASC',
    TargetUrlDesc = 'targetUrl_DESC',
    TextVariantAsc = 'textVariant_ASC',
    TextVariantDesc = 'textVariant_DESC',
    TitleAsc = 'title_ASC',
    TitleDesc = 'title_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortB2BAppRoleHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortB2BAppRolesBy {
    AppAsc = 'app_ASC',
    AppDesc = 'app_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    RoleAsc = 'role_ASC',
    RoleDesc = 'role_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortB2BAppsBy {
    AccessRightsAsc = 'accessRights_ASC',
    AccessRightsDesc = 'accessRights_DESC',
    AppUrlAsc = 'appUrl_ASC',
    AppUrlDesc = 'appUrl_DESC',
    CategoryAsc = 'category_ASC',
    CategoryDesc = 'category_DESC',
    ContextDefaultStatusAsc = 'contextDefaultStatus_ASC',
    ContextDefaultStatusDesc = 'contextDefaultStatus_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DetailedDescriptionAsc = 'detailedDescription_ASC',
    DetailedDescriptionDesc = 'detailedDescription_DESC',
    DeveloperAsc = 'developer_ASC',
    DeveloperDesc = 'developer_DESC',
    DisplayPriorityAsc = 'displayPriority_ASC',
    DisplayPriorityDesc = 'displayPriority_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HasDynamicTitleAsc = 'hasDynamicTitle_ASC',
    HasDynamicTitleDesc = 'hasDynamicTitle_DESC',
    IconAsc = 'icon_ASC',
    IconDesc = 'icon_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IsGlobalAsc = 'isGlobal_ASC',
    IsGlobalDesc = 'isGlobal_DESC',
    IsHiddenAsc = 'isHidden_ASC',
    IsHiddenDesc = 'isHidden_DESC',
    IsPublicAsc = 'isPublic_ASC',
    IsPublicDesc = 'isPublic_DESC',
    LabelAsc = 'label_ASC',
    LabelDesc = 'label_DESC',
    MenuCategoryAsc = 'menuCategory_ASC',
    MenuCategoryDesc = 'menuCategory_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    NewsSharingConfigAsc = 'newsSharingConfig_ASC',
    NewsSharingConfigDesc = 'newsSharingConfig_DESC',
    PartnerUrlAsc = 'partnerUrl_ASC',
    PartnerUrlDesc = 'partnerUrl_DESC',
    PriceAsc = 'price_ASC',
    PriceDesc = 'price_DESC',
    ShortDescriptionAsc = 'shortDescription_ASC',
    ShortDescriptionDesc = 'shortDescription_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortB2CAppAccessRightHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    ImportIdAsc = 'importId_ASC',
    ImportIdDesc = 'importId_DESC',
    ImportRemoteSystemAsc = 'importRemoteSystem_ASC',
    ImportRemoteSystemDesc = 'importRemoteSystem_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortB2CAppAccessRightsBy {
    AppAsc = 'app_ASC',
    AppDesc = 'app_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    ImportIdAsc = 'importId_ASC',
    ImportIdDesc = 'importId_DESC',
    ImportRemoteSystemAsc = 'importRemoteSystem_ASC',
    ImportRemoteSystemDesc = 'importRemoteSystem_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    UserAsc = 'user_ASC',
    UserDesc = 'user_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortB2CAppBuildHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    ImportIdAsc = 'importId_ASC',
    ImportIdDesc = 'importId_DESC',
    ImportRemoteSystemAsc = 'importRemoteSystem_ASC',
    ImportRemoteSystemDesc = 'importRemoteSystem_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
    VersionAsc = 'version_ASC',
    VersionDesc = 'version_DESC',
}

export enum SortB2CAppBuildsBy {
    AppAsc = 'app_ASC',
    AppDesc = 'app_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    ImportIdAsc = 'importId_ASC',
    ImportIdDesc = 'importId_DESC',
    ImportRemoteSystemAsc = 'importRemoteSystem_ASC',
    ImportRemoteSystemDesc = 'importRemoteSystem_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
    VersionAsc = 'version_ASC',
    VersionDesc = 'version_DESC',
}

export enum SortB2CAppHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DeveloperAsc = 'developer_ASC',
    DeveloperDesc = 'developer_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    ImportIdAsc = 'importId_ASC',
    ImportIdDesc = 'importId_DESC',
    ImportRemoteSystemAsc = 'importRemoteSystem_ASC',
    ImportRemoteSystemDesc = 'importRemoteSystem_DESC',
    IsHiddenAsc = 'isHidden_ASC',
    IsHiddenDesc = 'isHidden_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    ShortDescriptionAsc = 'shortDescription_ASC',
    ShortDescriptionDesc = 'shortDescription_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortB2CAppMessageSettingHistoryRecordsBy {
    BlockReasonAsc = 'blockReason_ASC',
    BlockReasonDesc = 'blockReason_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IsBlacklistedAsc = 'isBlacklisted_ASC',
    IsBlacklistedDesc = 'isBlacklisted_DESC',
    NotificationWindowSizeAsc = 'notificationWindowSize_ASC',
    NotificationWindowSizeDesc = 'notificationWindowSize_DESC',
    NumberOfNotificationInWindowAsc = 'numberOfNotificationInWindow_ASC',
    NumberOfNotificationInWindowDesc = 'numberOfNotificationInWindow_DESC',
    TypeAsc = 'type_ASC',
    TypeDesc = 'type_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortB2CAppMessageSettingsBy {
    AppAsc = 'app_ASC',
    AppDesc = 'app_DESC',
    BlockReasonAsc = 'blockReason_ASC',
    BlockReasonDesc = 'blockReason_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IsBlacklistedAsc = 'isBlacklisted_ASC',
    IsBlacklistedDesc = 'isBlacklisted_DESC',
    NotificationWindowSizeAsc = 'notificationWindowSize_ASC',
    NotificationWindowSizeDesc = 'notificationWindowSize_DESC',
    NumberOfNotificationInWindowAsc = 'numberOfNotificationInWindow_ASC',
    NumberOfNotificationInWindowDesc = 'numberOfNotificationInWindow_DESC',
    TypeAsc = 'type_ASC',
    TypeDesc = 'type_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortB2CAppPropertiesBy {
    AddressKeyAsc = 'addressKey_ASC',
    AddressKeyDesc = 'addressKey_DESC',
    AddressAsc = 'address_ASC',
    AddressDesc = 'address_DESC',
    AppAsc = 'app_ASC',
    AppDesc = 'app_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortB2CAppPropertyHistoryRecordsBy {
    AddressKeyAsc = 'addressKey_ASC',
    AddressKeyDesc = 'addressKey_DESC',
    AddressAsc = 'address_ASC',
    AddressDesc = 'address_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortB2CAppsBy {
    AccessRightsAsc = 'accessRights_ASC',
    AccessRightsDesc = 'accessRights_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    CurrentBuildAsc = 'currentBuild_ASC',
    CurrentBuildDesc = 'currentBuild_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DeveloperAsc = 'developer_ASC',
    DeveloperDesc = 'developer_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    ImportIdAsc = 'importId_ASC',
    ImportIdDesc = 'importId_DESC',
    ImportRemoteSystemAsc = 'importRemoteSystem_ASC',
    ImportRemoteSystemDesc = 'importRemoteSystem_DESC',
    IsHiddenAsc = 'isHidden_ASC',
    IsHiddenDesc = 'isHidden_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    ShortDescriptionAsc = 'shortDescription_ASC',
    ShortDescriptionDesc = 'shortDescription_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortBankAccountHistoryRecordsBy {
    ApprovedAtAsc = 'approvedAt_ASC',
    ApprovedAtDesc = 'approvedAt_DESC',
    BankNameAsc = 'bankName_ASC',
    BankNameDesc = 'bankName_DESC',
    ClassificationCodeAsc = 'classificationCode_ASC',
    ClassificationCodeDesc = 'classificationCode_DESC',
    CountryAsc = 'country_ASC',
    CountryDesc = 'country_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CurrencyCodeAsc = 'currencyCode_ASC',
    CurrencyCodeDesc = 'currencyCode_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    ImportIdAsc = 'importId_ASC',
    ImportIdDesc = 'importId_DESC',
    IsApprovedAsc = 'isApproved_ASC',
    IsApprovedDesc = 'isApproved_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    NumberAsc = 'number_ASC',
    NumberDesc = 'number_DESC',
    RoutingNumberAsc = 'routingNumber_ASC',
    RoutingNumberDesc = 'routingNumber_DESC',
    TerritoryCodeAsc = 'territoryCode_ASC',
    TerritoryCodeDesc = 'territoryCode_DESC',
    TinAsc = 'tin_ASC',
    TinDesc = 'tin_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortBankAccountReportHistoryRecordsBy {
    AmountAtAsc = 'amountAt_ASC',
    AmountAtDesc = 'amountAt_DESC',
    AmountAsc = 'amount_ASC',
    AmountDesc = 'amount_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IsLatestAsc = 'isLatest_ASC',
    IsLatestDesc = 'isLatest_DESC',
    PeriodAsc = 'period_ASC',
    PeriodDesc = 'period_DESC',
    PublishedAtAsc = 'publishedAt_ASC',
    PublishedAtDesc = 'publishedAt_DESC',
    TemplateAsc = 'template_ASC',
    TemplateDesc = 'template_DESC',
    TotalIncomeAsc = 'totalIncome_ASC',
    TotalIncomeDesc = 'totalIncome_DESC',
    TotalOutcomeAsc = 'totalOutcome_ASC',
    TotalOutcomeDesc = 'totalOutcome_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
    VersionAsc = 'version_ASC',
    VersionDesc = 'version_DESC',
}

export enum SortBankAccountReportTaskHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    ProgressAsc = 'progress_ASC',
    ProgressDesc = 'progress_DESC',
    StatusAsc = 'status_ASC',
    StatusDesc = 'status_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortBankAccountReportTasksBy {
    AccountAsc = 'account_ASC',
    AccountDesc = 'account_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    ProgressAsc = 'progress_ASC',
    ProgressDesc = 'progress_DESC',
    StatusAsc = 'status_ASC',
    StatusDesc = 'status_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    UserAsc = 'user_ASC',
    UserDesc = 'user_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortBankAccountReportsBy {
    AccountAsc = 'account_ASC',
    AccountDesc = 'account_DESC',
    AmountAtAsc = 'amountAt_ASC',
    AmountAtDesc = 'amountAt_DESC',
    AmountAsc = 'amount_ASC',
    AmountDesc = 'amount_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IsLatestAsc = 'isLatest_ASC',
    IsLatestDesc = 'isLatest_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    PeriodAsc = 'period_ASC',
    PeriodDesc = 'period_DESC',
    PublishedAtAsc = 'publishedAt_ASC',
    PublishedAtDesc = 'publishedAt_DESC',
    TemplateAsc = 'template_ASC',
    TemplateDesc = 'template_DESC',
    TotalIncomeAsc = 'totalIncome_ASC',
    TotalIncomeDesc = 'totalIncome_DESC',
    TotalOutcomeAsc = 'totalOutcome_ASC',
    TotalOutcomeDesc = 'totalOutcome_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
    VersionAsc = 'version_ASC',
    VersionDesc = 'version_DESC',
}

export enum SortBankAccountsBy {
    ApprovedAtAsc = 'approvedAt_ASC',
    ApprovedAtDesc = 'approvedAt_DESC',
    ApprovedByAsc = 'approvedBy_ASC',
    ApprovedByDesc = 'approvedBy_DESC',
    BankNameAsc = 'bankName_ASC',
    BankNameDesc = 'bankName_DESC',
    ClassificationCodeAsc = 'classificationCode_ASC',
    ClassificationCodeDesc = 'classificationCode_DESC',
    CountryAsc = 'country_ASC',
    CountryDesc = 'country_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    CurrencyCodeAsc = 'currencyCode_ASC',
    CurrencyCodeDesc = 'currencyCode_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    ImportIdAsc = 'importId_ASC',
    ImportIdDesc = 'importId_DESC',
    IntegrationContextAsc = 'integrationContext_ASC',
    IntegrationContextDesc = 'integrationContext_DESC',
    IsApprovedAsc = 'isApproved_ASC',
    IsApprovedDesc = 'isApproved_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    NumberAsc = 'number_ASC',
    NumberDesc = 'number_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    PropertyAsc = 'property_ASC',
    PropertyDesc = 'property_DESC',
    RoutingNumberAsc = 'routingNumber_ASC',
    RoutingNumberDesc = 'routingNumber_DESC',
    TerritoryCodeAsc = 'territoryCode_ASC',
    TerritoryCodeDesc = 'territoryCode_DESC',
    TinAsc = 'tin_ASC',
    TinDesc = 'tin_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortBankCategoriesBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortBankCategoryHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortBankContractorAccountHistoryRecordsBy {
    BankNameAsc = 'bankName_ASC',
    BankNameDesc = 'bankName_DESC',
    CountryAsc = 'country_ASC',
    CountryDesc = 'country_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CurrencyCodeAsc = 'currencyCode_ASC',
    CurrencyCodeDesc = 'currencyCode_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    ImportIdAsc = 'importId_ASC',
    ImportIdDesc = 'importId_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    NumberAsc = 'number_ASC',
    NumberDesc = 'number_DESC',
    RoutingNumberAsc = 'routingNumber_ASC',
    RoutingNumberDesc = 'routingNumber_DESC',
    TerritoryCodeAsc = 'territoryCode_ASC',
    TerritoryCodeDesc = 'territoryCode_DESC',
    TinAsc = 'tin_ASC',
    TinDesc = 'tin_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortBankContractorAccountsBy {
    BankNameAsc = 'bankName_ASC',
    BankNameDesc = 'bankName_DESC',
    CostItemAsc = 'costItem_ASC',
    CostItemDesc = 'costItem_DESC',
    CountryAsc = 'country_ASC',
    CountryDesc = 'country_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    CurrencyCodeAsc = 'currencyCode_ASC',
    CurrencyCodeDesc = 'currencyCode_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    ImportIdAsc = 'importId_ASC',
    ImportIdDesc = 'importId_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    NumberAsc = 'number_ASC',
    NumberDesc = 'number_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    RoutingNumberAsc = 'routingNumber_ASC',
    RoutingNumberDesc = 'routingNumber_DESC',
    TerritoryCodeAsc = 'territoryCode_ASC',
    TerritoryCodeDesc = 'territoryCode_DESC',
    TinAsc = 'tin_ASC',
    TinDesc = 'tin_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortBankCostItemHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IsOutcomeAsc = 'isOutcome_ASC',
    IsOutcomeDesc = 'isOutcome_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortBankCostItemsBy {
    CategoryAsc = 'category_ASC',
    CategoryDesc = 'category_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IsOutcomeAsc = 'isOutcome_ASC',
    IsOutcomeDesc = 'isOutcome_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortBankIntegrationAccessRightHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortBankIntegrationAccessRightsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IntegrationAsc = 'integration_ASC',
    IntegrationDesc = 'integration_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    UserAsc = 'user_ASC',
    UserDesc = 'user_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortBankIntegrationAccountContextHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    EnabledAsc = 'enabled_ASC',
    EnabledDesc = 'enabled_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortBankIntegrationAccountContextsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    EnabledAsc = 'enabled_ASC',
    EnabledDesc = 'enabled_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IntegrationAsc = 'integration_ASC',
    IntegrationDesc = 'integration_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortBankIntegrationHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortBankIntegrationOrganizationContextHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    EnabledAsc = 'enabled_ASC',
    EnabledDesc = 'enabled_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortBankIntegrationOrganizationContextsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    EnabledAsc = 'enabled_ASC',
    EnabledDesc = 'enabled_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IntegrationAsc = 'integration_ASC',
    IntegrationDesc = 'integration_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortBankIntegrationsBy {
    AccessRightsAsc = 'accessRights_ASC',
    AccessRightsDesc = 'accessRights_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortBankSyncTaskHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    ProcessedCountAsc = 'processedCount_ASC',
    ProcessedCountDesc = 'processedCount_DESC',
    StatusAsc = 'status_ASC',
    StatusDesc = 'status_DESC',
    TotalCountAsc = 'totalCount_ASC',
    TotalCountDesc = 'totalCount_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortBankSyncTasksBy {
    AccountAsc = 'account_ASC',
    AccountDesc = 'account_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IntegrationContextAsc = 'integrationContext_ASC',
    IntegrationContextDesc = 'integrationContext_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    ProcessedCountAsc = 'processedCount_ASC',
    ProcessedCountDesc = 'processedCount_DESC',
    PropertyAsc = 'property_ASC',
    PropertyDesc = 'property_DESC',
    StatusAsc = 'status_ASC',
    StatusDesc = 'status_DESC',
    TotalCountAsc = 'totalCount_ASC',
    TotalCountDesc = 'totalCount_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    UserAsc = 'user_ASC',
    UserDesc = 'user_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortBankTransactionHistoryRecordsBy {
    AmountAsc = 'amount_ASC',
    AmountDesc = 'amount_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CurrencyCodeAsc = 'currencyCode_ASC',
    CurrencyCodeDesc = 'currencyCode_DESC',
    DateAsc = 'date_ASC',
    DateDesc = 'date_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    ImportIdAsc = 'importId_ASC',
    ImportIdDesc = 'importId_DESC',
    ImportRemoteSystemAsc = 'importRemoteSystem_ASC',
    ImportRemoteSystemDesc = 'importRemoteSystem_DESC',
    IsOutcomeAsc = 'isOutcome_ASC',
    IsOutcomeDesc = 'isOutcome_DESC',
    NumberAsc = 'number_ASC',
    NumberDesc = 'number_DESC',
    PurposeAsc = 'purpose_ASC',
    PurposeDesc = 'purpose_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortBankTransactionsBy {
    AccountAsc = 'account_ASC',
    AccountDesc = 'account_DESC',
    AmountAsc = 'amount_ASC',
    AmountDesc = 'amount_DESC',
    ContractorAccountAsc = 'contractorAccount_ASC',
    ContractorAccountDesc = 'contractorAccount_DESC',
    CostItemAsc = 'costItem_ASC',
    CostItemDesc = 'costItem_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    CurrencyCodeAsc = 'currencyCode_ASC',
    CurrencyCodeDesc = 'currencyCode_DESC',
    DateAsc = 'date_ASC',
    DateDesc = 'date_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    ImportIdAsc = 'importId_ASC',
    ImportIdDesc = 'importId_DESC',
    ImportRemoteSystemAsc = 'importRemoteSystem_ASC',
    ImportRemoteSystemDesc = 'importRemoteSystem_DESC',
    IntegrationContextAsc = 'integrationContext_ASC',
    IntegrationContextDesc = 'integrationContext_DESC',
    IsOutcomeAsc = 'isOutcome_ASC',
    IsOutcomeDesc = 'isOutcome_DESC',
    NumberAsc = 'number_ASC',
    NumberDesc = 'number_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    PurposeAsc = 'purpose_ASC',
    PurposeDesc = 'purpose_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortBillingAccountHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    FullNameAsc = 'fullName_ASC',
    FullNameDesc = 'fullName_DESC',
    GlobalIdAsc = 'globalId_ASC',
    GlobalIdDesc = 'globalId_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    ImportIdAsc = 'importId_ASC',
    ImportIdDesc = 'importId_DESC',
    IsClosedAsc = 'isClosed_ASC',
    IsClosedDesc = 'isClosed_DESC',
    NumberAsc = 'number_ASC',
    NumberDesc = 'number_DESC',
    OwnerTypeAsc = 'ownerType_ASC',
    OwnerTypeDesc = 'ownerType_DESC',
    UnitNameAsc = 'unitName_ASC',
    UnitNameDesc = 'unitName_DESC',
    UnitTypeAsc = 'unitType_ASC',
    UnitTypeDesc = 'unitType_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortBillingAccountsBy {
    ContextAsc = 'context_ASC',
    ContextDesc = 'context_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    FullNameAsc = 'fullName_ASC',
    FullNameDesc = 'fullName_DESC',
    GlobalIdAsc = 'globalId_ASC',
    GlobalIdDesc = 'globalId_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    ImportIdAsc = 'importId_ASC',
    ImportIdDesc = 'importId_DESC',
    IsClosedAsc = 'isClosed_ASC',
    IsClosedDesc = 'isClosed_DESC',
    NumberAsc = 'number_ASC',
    NumberDesc = 'number_DESC',
    OwnerTypeAsc = 'ownerType_ASC',
    OwnerTypeDesc = 'ownerType_DESC',
    PropertyAsc = 'property_ASC',
    PropertyDesc = 'property_DESC',
    UnitNameAsc = 'unitName_ASC',
    UnitNameDesc = 'unitName_DESC',
    UnitTypeAsc = 'unitType_ASC',
    UnitTypeDesc = 'unitType_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortBillingCategoriesBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortBillingCategoryHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortBillingIntegrationAccessRightHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
}

export enum SortBillingIntegrationAccessRightsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IntegrationAsc = 'integration_ASC',
    IntegrationDesc = 'integration_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    UserAsc = 'user_ASC',
    UserDesc = 'user_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortBillingIntegrationHistoryRecordsBy {
    AppUrlAsc = 'appUrl_ASC',
    AppUrlDesc = 'appUrl_DESC',
    BannerColorAsc = 'bannerColor_ASC',
    BannerColorDesc = 'bannerColor_DESC',
    BannerTextColorAsc = 'bannerTextColor_ASC',
    BannerTextColorDesc = 'bannerTextColor_DESC',
    BillingPageTitleAsc = 'billingPageTitle_ASC',
    BillingPageTitleDesc = 'billingPageTitle_DESC',
    CheckAccountNumberUrlAsc = 'checkAccountNumberUrl_ASC',
    CheckAccountNumberUrlDesc = 'checkAccountNumberUrl_DESC',
    CheckAddressUrlAsc = 'checkAddressUrl_ASC',
    CheckAddressUrlDesc = 'checkAddressUrl_DESC',
    ConnectedMessageAsc = 'connectedMessage_ASC',
    ConnectedMessageDesc = 'connectedMessage_DESC',
    ContextDefaultStatusAsc = 'contextDefaultStatus_ASC',
    ContextDefaultStatusDesc = 'contextDefaultStatus_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CurrencyCodeAsc = 'currencyCode_ASC',
    CurrencyCodeDesc = 'currencyCode_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    ExtendsBillingPageAsc = 'extendsBillingPage_ASC',
    ExtendsBillingPageDesc = 'extendsBillingPage_DESC',
    GroupAsc = 'group_ASC',
    GroupDesc = 'group_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    InstructionExtraLinkAsc = 'instructionExtraLink_ASC',
    InstructionExtraLinkDesc = 'instructionExtraLink_DESC',
    IsHiddenAsc = 'isHidden_ASC',
    IsHiddenDesc = 'isHidden_DESC',
    IsTrustedBankAccountSourceAsc = 'isTrustedBankAccountSource_ASC',
    IsTrustedBankAccountSourceDesc = 'isTrustedBankAccountSource_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    ReceiptsLoadingTimeAsc = 'receiptsLoadingTime_ASC',
    ReceiptsLoadingTimeDesc = 'receiptsLoadingTime_DESC',
    SetupUrlAsc = 'setupUrl_ASC',
    SetupUrlDesc = 'setupUrl_DESC',
    ShortDescriptionAsc = 'shortDescription_ASC',
    ShortDescriptionDesc = 'shortDescription_DESC',
    SkipNoAccountNotificationsAsc = 'skipNoAccountNotifications_ASC',
    SkipNoAccountNotificationsDesc = 'skipNoAccountNotifications_DESC',
    TargetDescriptionAsc = 'targetDescription_ASC',
    TargetDescriptionDesc = 'targetDescription_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UploadMessageAsc = 'uploadMessage_ASC',
    UploadMessageDesc = 'uploadMessage_DESC',
    UploadUrlAsc = 'uploadUrl_ASC',
    UploadUrlDesc = 'uploadUrl_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortBillingIntegrationOrganizationContextHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    StatusAsc = 'status_ASC',
    StatusDesc = 'status_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortBillingIntegrationOrganizationContextsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    CurrentProblemAsc = 'currentProblem_ASC',
    CurrentProblemDesc = 'currentProblem_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IntegrationAsc = 'integration_ASC',
    IntegrationDesc = 'integration_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    StatusAsc = 'status_ASC',
    StatusDesc = 'status_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortBillingIntegrationProblemsBy {
    ContextAsc = 'context_ASC',
    ContextDesc = 'context_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    MessageAsc = 'message_ASC',
    MessageDesc = 'message_DESC',
    TitleAsc = 'title_ASC',
    TitleDesc = 'title_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortBillingIntegrationsBy {
    AccessRightsAsc = 'accessRights_ASC',
    AccessRightsDesc = 'accessRights_DESC',
    AppUrlAsc = 'appUrl_ASC',
    AppUrlDesc = 'appUrl_DESC',
    BannerColorAsc = 'bannerColor_ASC',
    BannerColorDesc = 'bannerColor_DESC',
    BannerTextColorAsc = 'bannerTextColor_ASC',
    BannerTextColorDesc = 'bannerTextColor_DESC',
    BillingPageTitleAsc = 'billingPageTitle_ASC',
    BillingPageTitleDesc = 'billingPageTitle_DESC',
    CheckAccountNumberUrlAsc = 'checkAccountNumberUrl_ASC',
    CheckAccountNumberUrlDesc = 'checkAccountNumberUrl_DESC',
    CheckAddressUrlAsc = 'checkAddressUrl_ASC',
    CheckAddressUrlDesc = 'checkAddressUrl_DESC',
    ConnectedMessageAsc = 'connectedMessage_ASC',
    ConnectedMessageDesc = 'connectedMessage_DESC',
    ContextDefaultStatusAsc = 'contextDefaultStatus_ASC',
    ContextDefaultStatusDesc = 'contextDefaultStatus_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    CurrencyCodeAsc = 'currencyCode_ASC',
    CurrencyCodeDesc = 'currencyCode_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DetailedDescriptionAsc = 'detailedDescription_ASC',
    DetailedDescriptionDesc = 'detailedDescription_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    ExtendsBillingPageAsc = 'extendsBillingPage_ASC',
    ExtendsBillingPageDesc = 'extendsBillingPage_DESC',
    GroupAsc = 'group_ASC',
    GroupDesc = 'group_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    InstructionExtraLinkAsc = 'instructionExtraLink_ASC',
    InstructionExtraLinkDesc = 'instructionExtraLink_DESC',
    InstructionAsc = 'instruction_ASC',
    InstructionDesc = 'instruction_DESC',
    IsHiddenAsc = 'isHidden_ASC',
    IsHiddenDesc = 'isHidden_DESC',
    IsTrustedBankAccountSourceAsc = 'isTrustedBankAccountSource_ASC',
    IsTrustedBankAccountSourceDesc = 'isTrustedBankAccountSource_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    ReceiptsLoadingTimeAsc = 'receiptsLoadingTime_ASC',
    ReceiptsLoadingTimeDesc = 'receiptsLoadingTime_DESC',
    SetupUrlAsc = 'setupUrl_ASC',
    SetupUrlDesc = 'setupUrl_DESC',
    ShortDescriptionAsc = 'shortDescription_ASC',
    ShortDescriptionDesc = 'shortDescription_DESC',
    SkipNoAccountNotificationsAsc = 'skipNoAccountNotifications_ASC',
    SkipNoAccountNotificationsDesc = 'skipNoAccountNotifications_DESC',
    TargetDescriptionAsc = 'targetDescription_ASC',
    TargetDescriptionDesc = 'targetDescription_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    UploadMessageAsc = 'uploadMessage_ASC',
    UploadMessageDesc = 'uploadMessage_DESC',
    UploadUrlAsc = 'uploadUrl_ASC',
    UploadUrlDesc = 'uploadUrl_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortBillingPropertiesBy {
    AddressKeyAsc = 'addressKey_ASC',
    AddressKeyDesc = 'addressKey_DESC',
    AddressAsc = 'address_ASC',
    AddressDesc = 'address_DESC',
    ContextAsc = 'context_ASC',
    ContextDesc = 'context_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    GlobalIdAsc = 'globalId_ASC',
    GlobalIdDesc = 'globalId_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    ImportIdAsc = 'importId_ASC',
    ImportIdDesc = 'importId_DESC',
    NormalizedAddressAsc = 'normalizedAddress_ASC',
    NormalizedAddressDesc = 'normalizedAddress_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortBillingPropertyHistoryRecordsBy {
    AddressKeyAsc = 'addressKey_ASC',
    AddressKeyDesc = 'addressKey_DESC',
    AddressAsc = 'address_ASC',
    AddressDesc = 'address_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    GlobalIdAsc = 'globalId_ASC',
    GlobalIdDesc = 'globalId_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    ImportIdAsc = 'importId_ASC',
    ImportIdDesc = 'importId_DESC',
    NormalizedAddressAsc = 'normalizedAddress_ASC',
    NormalizedAddressDesc = 'normalizedAddress_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortBillingReceiptFileHistoryRecordsBy {
    ControlSumAsc = 'controlSum_ASC',
    ControlSumDesc = 'controlSum_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    ImportIdAsc = 'importId_ASC',
    ImportIdDesc = 'importId_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortBillingReceiptFilesBy {
    ContextAsc = 'context_ASC',
    ContextDesc = 'context_DESC',
    ControlSumAsc = 'controlSum_ASC',
    ControlSumDesc = 'controlSum_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    ImportIdAsc = 'importId_ASC',
    ImportIdDesc = 'importId_DESC',
    ReceiptAsc = 'receipt_ASC',
    ReceiptDesc = 'receipt_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortBillingReceiptHistoryRecordsBy {
    BalanceAsc = 'balance_ASC',
    BalanceDesc = 'balance_DESC',
    ChargeAsc = 'charge_ASC',
    ChargeDesc = 'charge_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    FormulaAsc = 'formula_ASC',
    FormulaDesc = 'formula_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    ImportIdAsc = 'importId_ASC',
    ImportIdDesc = 'importId_DESC',
    PaidAsc = 'paid_ASC',
    PaidDesc = 'paid_DESC',
    PenaltyAsc = 'penalty_ASC',
    PenaltyDesc = 'penalty_DESC',
    PeriodAsc = 'period_ASC',
    PeriodDesc = 'period_DESC',
    PrintableNumberAsc = 'printableNumber_ASC',
    PrintableNumberDesc = 'printableNumber_DESC',
    PrivilegeAsc = 'privilege_ASC',
    PrivilegeDesc = 'privilege_DESC',
    RecalculationAsc = 'recalculation_ASC',
    RecalculationDesc = 'recalculation_DESC',
    ToPayAsc = 'toPay_ASC',
    ToPayDesc = 'toPay_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortBillingReceiptsBy {
    AccountAsc = 'account_ASC',
    AccountDesc = 'account_DESC',
    BalanceAsc = 'balance_ASC',
    BalanceDesc = 'balance_DESC',
    CategoryAsc = 'category_ASC',
    CategoryDesc = 'category_DESC',
    ChargeAsc = 'charge_ASC',
    ChargeDesc = 'charge_DESC',
    ContextAsc = 'context_ASC',
    ContextDesc = 'context_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    FileAsc = 'file_ASC',
    FileDesc = 'file_DESC',
    FormulaAsc = 'formula_ASC',
    FormulaDesc = 'formula_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    ImportIdAsc = 'importId_ASC',
    ImportIdDesc = 'importId_DESC',
    PaidAsc = 'paid_ASC',
    PaidDesc = 'paid_DESC',
    PenaltyAsc = 'penalty_ASC',
    PenaltyDesc = 'penalty_DESC',
    PeriodAsc = 'period_ASC',
    PeriodDesc = 'period_DESC',
    PrintableNumberAsc = 'printableNumber_ASC',
    PrintableNumberDesc = 'printableNumber_DESC',
    PrivilegeAsc = 'privilege_ASC',
    PrivilegeDesc = 'privilege_DESC',
    PropertyAsc = 'property_ASC',
    PropertyDesc = 'property_DESC',
    RecalculationAsc = 'recalculation_ASC',
    RecalculationDesc = 'recalculation_DESC',
    ReceiverAsc = 'receiver_ASC',
    ReceiverDesc = 'receiver_DESC',
    ToPayAsc = 'toPay_ASC',
    ToPayDesc = 'toPay_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortBillingRecipientHistoryRecordsBy {
    BankAccountAsc = 'bankAccount_ASC',
    BankAccountDesc = 'bankAccount_DESC',
    BankNameAsc = 'bankName_ASC',
    BankNameDesc = 'bankName_DESC',
    BicAsc = 'bic_ASC',
    BicDesc = 'bic_DESC',
    ClassificationCodeAsc = 'classificationCode_ASC',
    ClassificationCodeDesc = 'classificationCode_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IecAsc = 'iec_ASC',
    IecDesc = 'iec_DESC',
    ImportIdAsc = 'importId_ASC',
    ImportIdDesc = 'importId_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    OffsettingAccountAsc = 'offsettingAccount_ASC',
    OffsettingAccountDesc = 'offsettingAccount_DESC',
    PurposeAsc = 'purpose_ASC',
    PurposeDesc = 'purpose_DESC',
    TerritoryCodeAsc = 'territoryCode_ASC',
    TerritoryCodeDesc = 'territoryCode_DESC',
    TinAsc = 'tin_ASC',
    TinDesc = 'tin_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortBillingRecipientsBy {
    BankAccountAsc = 'bankAccount_ASC',
    BankAccountDesc = 'bankAccount_DESC',
    BankNameAsc = 'bankName_ASC',
    BankNameDesc = 'bankName_DESC',
    BicAsc = 'bic_ASC',
    BicDesc = 'bic_DESC',
    ClassificationCodeAsc = 'classificationCode_ASC',
    ClassificationCodeDesc = 'classificationCode_DESC',
    ContextAsc = 'context_ASC',
    ContextDesc = 'context_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IecAsc = 'iec_ASC',
    IecDesc = 'iec_DESC',
    ImportIdAsc = 'importId_ASC',
    ImportIdDesc = 'importId_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    OffsettingAccountAsc = 'offsettingAccount_ASC',
    OffsettingAccountDesc = 'offsettingAccount_DESC',
    PurposeAsc = 'purpose_ASC',
    PurposeDesc = 'purpose_DESC',
    TerritoryCodeAsc = 'territoryCode_ASC',
    TerritoryCodeDesc = 'territoryCode_DESC',
    TinAsc = 'tin_ASC',
    TinDesc = 'tin_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortCallRecordFragmentHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    StartedAtAsc = 'startedAt_ASC',
    StartedAtDesc = 'startedAt_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortCallRecordFragmentsBy {
    CallRecordAsc = 'callRecord_ASC',
    CallRecordDesc = 'callRecord_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    StartedAtAsc = 'startedAt_ASC',
    StartedAtDesc = 'startedAt_DESC',
    TicketAsc = 'ticket_ASC',
    TicketDesc = 'ticket_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortCallRecordHistoryRecordsBy {
    CallerPhoneAsc = 'callerPhone_ASC',
    CallerPhoneDesc = 'callerPhone_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DestCallerPhoneAsc = 'destCallerPhone_ASC',
    DestCallerPhoneDesc = 'destCallerPhone_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    ImportIdAsc = 'importId_ASC',
    ImportIdDesc = 'importId_DESC',
    IsIncomingCallAsc = 'isIncomingCall_ASC',
    IsIncomingCallDesc = 'isIncomingCall_DESC',
    StartedAtAsc = 'startedAt_ASC',
    StartedAtDesc = 'startedAt_DESC',
    TalkTimeAsc = 'talkTime_ASC',
    TalkTimeDesc = 'talkTime_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortCallRecordsBy {
    CallerPhoneAsc = 'callerPhone_ASC',
    CallerPhoneDesc = 'callerPhone_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DestCallerPhoneAsc = 'destCallerPhone_ASC',
    DestCallerPhoneDesc = 'destCallerPhone_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    ImportIdAsc = 'importId_ASC',
    ImportIdDesc = 'importId_DESC',
    IsIncomingCallAsc = 'isIncomingCall_ASC',
    IsIncomingCallDesc = 'isIncomingCall_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    StartedAtAsc = 'startedAt_ASC',
    StartedAtDesc = 'startedAt_DESC',
    TalkTimeAsc = 'talkTime_ASC',
    TalkTimeDesc = 'talkTime_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortConfirmPhoneActionHistoryRecordsBy {
    CompletedAtAsc = 'completedAt_ASC',
    CompletedAtDesc = 'completedAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    ExpiresAtAsc = 'expiresAt_ASC',
    ExpiresAtDesc = 'expiresAt_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IsPhoneVerifiedAsc = 'isPhoneVerified_ASC',
    IsPhoneVerifiedDesc = 'isPhoneVerified_DESC',
    PhoneAsc = 'phone_ASC',
    PhoneDesc = 'phone_DESC',
    RequestedAtAsc = 'requestedAt_ASC',
    RequestedAtDesc = 'requestedAt_DESC',
    RetriesAsc = 'retries_ASC',
    RetriesDesc = 'retries_DESC',
    SmsCodeExpiresAtAsc = 'smsCodeExpiresAt_ASC',
    SmsCodeExpiresAtDesc = 'smsCodeExpiresAt_DESC',
    SmsCodeRequestedAtAsc = 'smsCodeRequestedAt_ASC',
    SmsCodeRequestedAtDesc = 'smsCodeRequestedAt_DESC',
    SmsCodeAsc = 'smsCode_ASC',
    SmsCodeDesc = 'smsCode_DESC',
    TokenAsc = 'token_ASC',
    TokenDesc = 'token_DESC',
}

export enum SortConfirmPhoneActionsBy {
    CompletedAtAsc = 'completedAt_ASC',
    CompletedAtDesc = 'completedAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    ExpiresAtAsc = 'expiresAt_ASC',
    ExpiresAtDesc = 'expiresAt_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IsPhoneVerifiedAsc = 'isPhoneVerified_ASC',
    IsPhoneVerifiedDesc = 'isPhoneVerified_DESC',
    PhoneAsc = 'phone_ASC',
    PhoneDesc = 'phone_DESC',
    RequestedAtAsc = 'requestedAt_ASC',
    RequestedAtDesc = 'requestedAt_DESC',
    RetriesAsc = 'retries_ASC',
    RetriesDesc = 'retries_DESC',
    SmsCodeExpiresAtAsc = 'smsCodeExpiresAt_ASC',
    SmsCodeExpiresAtDesc = 'smsCodeExpiresAt_DESC',
    SmsCodeRequestedAtAsc = 'smsCodeRequestedAt_ASC',
    SmsCodeRequestedAtDesc = 'smsCodeRequestedAt_DESC',
    SmsCodeAsc = 'smsCode_ASC',
    SmsCodeDesc = 'smsCode_DESC',
    TokenAsc = 'token_ASC',
    TokenDesc = 'token_DESC',
}

export enum SortContactExportTaskHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    ExportedRecordsCountAsc = 'exportedRecordsCount_ASC',
    ExportedRecordsCountDesc = 'exportedRecordsCount_DESC',
    FormatAsc = 'format_ASC',
    FormatDesc = 'format_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    LocaleAsc = 'locale_ASC',
    LocaleDesc = 'locale_DESC',
    StatusAsc = 'status_ASC',
    StatusDesc = 'status_DESC',
    TimeZoneAsc = 'timeZone_ASC',
    TimeZoneDesc = 'timeZone_DESC',
    TotalRecordsCountAsc = 'totalRecordsCount_ASC',
    TotalRecordsCountDesc = 'totalRecordsCount_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortContactExportTasksBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    ExportedRecordsCountAsc = 'exportedRecordsCount_ASC',
    ExportedRecordsCountDesc = 'exportedRecordsCount_DESC',
    FormatAsc = 'format_ASC',
    FormatDesc = 'format_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    LocaleAsc = 'locale_ASC',
    LocaleDesc = 'locale_DESC',
    StatusAsc = 'status_ASC',
    StatusDesc = 'status_DESC',
    TimeZoneAsc = 'timeZone_ASC',
    TimeZoneDesc = 'timeZone_DESC',
    TotalRecordsCountAsc = 'totalRecordsCount_ASC',
    TotalRecordsCountDesc = 'totalRecordsCount_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    UserAsc = 'user_ASC',
    UserDesc = 'user_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortContactHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    EmailAsc = 'email_ASC',
    EmailDesc = 'email_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IsVerifiedAsc = 'isVerified_ASC',
    IsVerifiedDesc = 'isVerified_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    PhoneAsc = 'phone_ASC',
    PhoneDesc = 'phone_DESC',
    QuotaAsc = 'quota_ASC',
    QuotaDesc = 'quota_DESC',
    UnitNameAsc = 'unitName_ASC',
    UnitNameDesc = 'unitName_DESC',
    UnitTypeAsc = 'unitType_ASC',
    UnitTypeDesc = 'unitType_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortContactRoleHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortContactRolesBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortContactsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    EmailAsc = 'email_ASC',
    EmailDesc = 'email_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IsVerifiedAsc = 'isVerified_ASC',
    IsVerifiedDesc = 'isVerified_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    PhoneAsc = 'phone_ASC',
    PhoneDesc = 'phone_DESC',
    PropertyAsc = 'property_ASC',
    PropertyDesc = 'property_DESC',
    QuotaAsc = 'quota_ASC',
    QuotaDesc = 'quota_DESC',
    RoleAsc = 'role_ASC',
    RoleDesc = 'role_DESC',
    UnitNameAsc = 'unitName_ASC',
    UnitNameDesc = 'unitName_DESC',
    UnitTypeAsc = 'unitType_ASC',
    UnitTypeDesc = 'unitType_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortDocumentCategoriesBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortDocumentCategoryHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortDocumentHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortDocumentsBy {
    CategoryAsc = 'category_ASC',
    CategoryDesc = 'category_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    PropertyAsc = 'property_ASC',
    PropertyDesc = 'property_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortExternalTokenAccessRightHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    TypeAsc = 'type_ASC',
    TypeDesc = 'type_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortExternalTokenAccessRightsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    TypeAsc = 'type_ASC',
    TypeDesc = 'type_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    UserAsc = 'user_ASC',
    UserDesc = 'user_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortIncidentChangesBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DetailsFromAsc = 'detailsFrom_ASC',
    DetailsFromDesc = 'detailsFrom_DESC',
    DetailsToAsc = 'detailsTo_ASC',
    DetailsToDesc = 'detailsTo_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IncidentAsc = 'incident_ASC',
    IncidentDesc = 'incident_DESC',
    OrganizationDisplayNameFromAsc = 'organizationDisplayNameFrom_ASC',
    OrganizationDisplayNameFromDesc = 'organizationDisplayNameFrom_DESC',
    OrganizationDisplayNameToAsc = 'organizationDisplayNameTo_ASC',
    OrganizationDisplayNameToDesc = 'organizationDisplayNameTo_DESC',
    OrganizationIdFromAsc = 'organizationIdFrom_ASC',
    OrganizationIdFromDesc = 'organizationIdFrom_DESC',
    OrganizationIdToAsc = 'organizationIdTo_ASC',
    OrganizationIdToDesc = 'organizationIdTo_DESC',
    StatusFromAsc = 'statusFrom_ASC',
    StatusFromDesc = 'statusFrom_DESC',
    StatusToAsc = 'statusTo_ASC',
    StatusToDesc = 'statusTo_DESC',
    TextForResidentFromAsc = 'textForResidentFrom_ASC',
    TextForResidentFromDesc = 'textForResidentFrom_DESC',
    TextForResidentToAsc = 'textForResidentTo_ASC',
    TextForResidentToDesc = 'textForResidentTo_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
    WorkFinishFromAsc = 'workFinishFrom_ASC',
    WorkFinishFromDesc = 'workFinishFrom_DESC',
    WorkFinishToAsc = 'workFinishTo_ASC',
    WorkFinishToDesc = 'workFinishTo_DESC',
    WorkStartFromAsc = 'workStartFrom_ASC',
    WorkStartFromDesc = 'workStartFrom_DESC',
    WorkStartToAsc = 'workStartTo_ASC',
    WorkStartToDesc = 'workStartTo_DESC',
    WorkTypeFromAsc = 'workTypeFrom_ASC',
    WorkTypeFromDesc = 'workTypeFrom_DESC',
    WorkTypeToAsc = 'workTypeTo_ASC',
    WorkTypeToDesc = 'workTypeTo_DESC',
}

export enum SortIncidentClassifierHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortIncidentClassifierIncidentHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortIncidentClassifierIncidentsBy {
    ClassifierAsc = 'classifier_ASC',
    ClassifierDesc = 'classifier_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IncidentAsc = 'incident_ASC',
    IncidentDesc = 'incident_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortIncidentClassifiersBy {
    CategoryAsc = 'category_ASC',
    CategoryDesc = 'category_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    ProblemAsc = 'problem_ASC',
    ProblemDesc = 'problem_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortIncidentExportTaskHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    ExportedRecordsCountAsc = 'exportedRecordsCount_ASC',
    ExportedRecordsCountDesc = 'exportedRecordsCount_DESC',
    FormatAsc = 'format_ASC',
    FormatDesc = 'format_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    LocaleAsc = 'locale_ASC',
    LocaleDesc = 'locale_DESC',
    StatusAsc = 'status_ASC',
    StatusDesc = 'status_DESC',
    TimeZoneAsc = 'timeZone_ASC',
    TimeZoneDesc = 'timeZone_DESC',
    TotalRecordsCountAsc = 'totalRecordsCount_ASC',
    TotalRecordsCountDesc = 'totalRecordsCount_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortIncidentExportTasksBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    ExportedRecordsCountAsc = 'exportedRecordsCount_ASC',
    ExportedRecordsCountDesc = 'exportedRecordsCount_DESC',
    FormatAsc = 'format_ASC',
    FormatDesc = 'format_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    LocaleAsc = 'locale_ASC',
    LocaleDesc = 'locale_DESC',
    StatusAsc = 'status_ASC',
    StatusDesc = 'status_DESC',
    TimeZoneAsc = 'timeZone_ASC',
    TimeZoneDesc = 'timeZone_DESC',
    TotalRecordsCountAsc = 'totalRecordsCount_ASC',
    TotalRecordsCountDesc = 'totalRecordsCount_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    UserAsc = 'user_ASC',
    UserDesc = 'user_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortIncidentHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DetailsAsc = 'details_ASC',
    DetailsDesc = 'details_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HasAllPropertiesAsc = 'hasAllProperties_ASC',
    HasAllPropertiesDesc = 'hasAllProperties_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    StatusAsc = 'status_ASC',
    StatusDesc = 'status_DESC',
    TextForResidentAsc = 'textForResident_ASC',
    TextForResidentDesc = 'textForResident_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
    WorkFinishAsc = 'workFinish_ASC',
    WorkFinishDesc = 'workFinish_DESC',
    WorkStartAsc = 'workStart_ASC',
    WorkStartDesc = 'workStart_DESC',
    WorkTypeAsc = 'workType_ASC',
    WorkTypeDesc = 'workType_DESC',
}

export enum SortIncidentPropertiesBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IncidentAsc = 'incident_ASC',
    IncidentDesc = 'incident_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    PropertyAddressAsc = 'propertyAddress_ASC',
    PropertyAddressDesc = 'propertyAddress_DESC',
    PropertyAsc = 'property_ASC',
    PropertyDesc = 'property_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortIncidentPropertyHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    PropertyAddressAsc = 'propertyAddress_ASC',
    PropertyAddressDesc = 'propertyAddress_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortIncidentsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DetailsAsc = 'details_ASC',
    DetailsDesc = 'details_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HasAllPropertiesAsc = 'hasAllProperties_ASC',
    HasAllPropertiesDesc = 'hasAllProperties_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NumberAsc = 'number_ASC',
    NumberDesc = 'number_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    StatusAsc = 'status_ASC',
    StatusDesc = 'status_DESC',
    TextForResidentAsc = 'textForResident_ASC',
    TextForResidentDesc = 'textForResident_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
    WorkFinishAsc = 'workFinish_ASC',
    WorkFinishDesc = 'workFinish_DESC',
    WorkStartAsc = 'workStart_ASC',
    WorkStartDesc = 'workStart_DESC',
    WorkTypeAsc = 'workType_ASC',
    WorkTypeDesc = 'workType_DESC',
}

export enum SortInvoiceHistoryRecordsBy {
    AccountNumberAsc = 'accountNumber_ASC',
    AccountNumberDesc = 'accountNumber_DESC',
    CanceledAtAsc = 'canceledAt_ASC',
    CanceledAtDesc = 'canceledAt_DESC',
    ClientNameAsc = 'clientName_ASC',
    ClientNameDesc = 'clientName_DESC',
    ClientPhoneAsc = 'clientPhone_ASC',
    ClientPhoneDesc = 'clientPhone_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    PaidAtAsc = 'paidAt_ASC',
    PaidAtDesc = 'paidAt_DESC',
    PaymentTypeAsc = 'paymentType_ASC',
    PaymentTypeDesc = 'paymentType_DESC',
    PublishedAtAsc = 'publishedAt_ASC',
    PublishedAtDesc = 'publishedAt_DESC',
    StatusAsc = 'status_ASC',
    StatusDesc = 'status_DESC',
    ToPayAsc = 'toPay_ASC',
    ToPayDesc = 'toPay_DESC',
    UnitNameAsc = 'unitName_ASC',
    UnitNameDesc = 'unitName_DESC',
    UnitTypeAsc = 'unitType_ASC',
    UnitTypeDesc = 'unitType_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortInvoicesBy {
    AccountNumberAsc = 'accountNumber_ASC',
    AccountNumberDesc = 'accountNumber_DESC',
    CanceledAtAsc = 'canceledAt_ASC',
    CanceledAtDesc = 'canceledAt_DESC',
    ClientNameAsc = 'clientName_ASC',
    ClientNameDesc = 'clientName_DESC',
    ClientPhoneAsc = 'clientPhone_ASC',
    ClientPhoneDesc = 'clientPhone_DESC',
    ClientAsc = 'client_ASC',
    ClientDesc = 'client_DESC',
    ContactAsc = 'contact_ASC',
    ContactDesc = 'contact_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NumberAsc = 'number_ASC',
    NumberDesc = 'number_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    PaidAtAsc = 'paidAt_ASC',
    PaidAtDesc = 'paidAt_DESC',
    PaymentTypeAsc = 'paymentType_ASC',
    PaymentTypeDesc = 'paymentType_DESC',
    PropertyAsc = 'property_ASC',
    PropertyDesc = 'property_DESC',
    PublishedAtAsc = 'publishedAt_ASC',
    PublishedAtDesc = 'publishedAt_DESC',
    StatusAsc = 'status_ASC',
    StatusDesc = 'status_DESC',
    TicketAsc = 'ticket_ASC',
    TicketDesc = 'ticket_DESC',
    ToPayAsc = 'toPay_ASC',
    ToPayDesc = 'toPay_DESC',
    UnitNameAsc = 'unitName_ASC',
    UnitNameDesc = 'unitName_DESC',
    UnitTypeAsc = 'unitType_ASC',
    UnitTypeDesc = 'unitType_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortMarketCategoriesBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    OrderAsc = 'order_ASC',
    OrderDesc = 'order_DESC',
    ParentCategoryAsc = 'parentCategory_ASC',
    ParentCategoryDesc = 'parentCategory_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortMarketCategoryHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    OrderAsc = 'order_ASC',
    OrderDesc = 'order_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortMarketItemFileHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortMarketItemFilesBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    MarketItemAsc = 'marketItem_ASC',
    MarketItemDesc = 'marketItem_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortMarketItemHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DescriptionAsc = 'description_ASC',
    DescriptionDesc = 'description_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    SkuAsc = 'sku_ASC',
    SkuDesc = 'sku_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortMarketItemPriceHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortMarketItemPricesBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    MarketItemAsc = 'marketItem_ASC',
    MarketItemDesc = 'marketItem_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortMarketItemsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DescriptionAsc = 'description_ASC',
    DescriptionDesc = 'description_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    MarketCategoryAsc = 'marketCategory_ASC',
    MarketCategoryDesc = 'marketCategory_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    SkuAsc = 'sku_ASC',
    SkuDesc = 'sku_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortMarketPriceScopeHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    TypeAsc = 'type_ASC',
    TypeDesc = 'type_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortMarketPriceScopesBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    MarketItemPriceAsc = 'marketItemPrice_ASC',
    MarketItemPriceDesc = 'marketItemPrice_DESC',
    PropertyAsc = 'property_ASC',
    PropertyDesc = 'property_DESC',
    TypeAsc = 'type_ASC',
    TypeDesc = 'type_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortMarketSettingHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortMarketSettingsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortMessageAppBlackListHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DescriptionAsc = 'description_ASC',
    DescriptionDesc = 'description_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    TypeAsc = 'type_ASC',
    TypeDesc = 'type_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortMessageAppBlackListsBy {
    AppAsc = 'app_ASC',
    AppDesc = 'app_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DescriptionAsc = 'description_ASC',
    DescriptionDesc = 'description_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    TypeAsc = 'type_ASC',
    TypeDesc = 'type_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortMessageBatchHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeepLinkAsc = 'deepLink_ASC',
    DeepLinkDesc = 'deepLink_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    MessageTypeAsc = 'messageType_ASC',
    MessageTypeDesc = 'messageType_DESC',
    MessageAsc = 'message_ASC',
    MessageDesc = 'message_DESC',
    StatusAsc = 'status_ASC',
    StatusDesc = 'status_DESC',
    TitleAsc = 'title_ASC',
    TitleDesc = 'title_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortMessageBatchesBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeepLinkAsc = 'deepLink_ASC',
    DeepLinkDesc = 'deepLink_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    MessageTypeAsc = 'messageType_ASC',
    MessageTypeDesc = 'messageType_DESC',
    MessageAsc = 'message_ASC',
    MessageDesc = 'message_DESC',
    StatusAsc = 'status_ASC',
    StatusDesc = 'status_DESC',
    TitleAsc = 'title_ASC',
    TitleDesc = 'title_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortMessageHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DeliveredAtAsc = 'deliveredAt_ASC',
    DeliveredAtDesc = 'deliveredAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    EmailFromAsc = 'emailFrom_ASC',
    EmailFromDesc = 'emailFrom_DESC',
    EmailAsc = 'email_ASC',
    EmailDesc = 'email_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    LangAsc = 'lang_ASC',
    LangDesc = 'lang_DESC',
    PhoneAsc = 'phone_ASC',
    PhoneDesc = 'phone_DESC',
    ReadAtAsc = 'readAt_ASC',
    ReadAtDesc = 'readAt_DESC',
    SentAtAsc = 'sentAt_ASC',
    SentAtDesc = 'sentAt_DESC',
    StatusAsc = 'status_ASC',
    StatusDesc = 'status_DESC',
    TypeAsc = 'type_ASC',
    TypeDesc = 'type_DESC',
    UniqKeyAsc = 'uniqKey_ASC',
    UniqKeyDesc = 'uniqKey_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortMessageOrganizationBlackListHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DescriptionAsc = 'description_ASC',
    DescriptionDesc = 'description_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    TypeAsc = 'type_ASC',
    TypeDesc = 'type_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortMessageOrganizationBlackListsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DescriptionAsc = 'description_ASC',
    DescriptionDesc = 'description_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    TypeAsc = 'type_ASC',
    TypeDesc = 'type_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortMessageUserBlackListHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DescriptionAsc = 'description_ASC',
    DescriptionDesc = 'description_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    EmailAsc = 'email_ASC',
    EmailDesc = 'email_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    PhoneAsc = 'phone_ASC',
    PhoneDesc = 'phone_DESC',
    TypeAsc = 'type_ASC',
    TypeDesc = 'type_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortMessageUserBlackListsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DescriptionAsc = 'description_ASC',
    DescriptionDesc = 'description_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    EmailAsc = 'email_ASC',
    EmailDesc = 'email_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    PhoneAsc = 'phone_ASC',
    PhoneDesc = 'phone_DESC',
    TypeAsc = 'type_ASC',
    TypeDesc = 'type_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    UserAsc = 'user_ASC',
    UserDesc = 'user_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortMessagesBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DeliveredAtAsc = 'deliveredAt_ASC',
    DeliveredAtDesc = 'deliveredAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    EmailFromAsc = 'emailFrom_ASC',
    EmailFromDesc = 'emailFrom_DESC',
    EmailAsc = 'email_ASC',
    EmailDesc = 'email_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    LangAsc = 'lang_ASC',
    LangDesc = 'lang_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    PhoneAsc = 'phone_ASC',
    PhoneDesc = 'phone_DESC',
    ReadAtAsc = 'readAt_ASC',
    ReadAtDesc = 'readAt_DESC',
    RemoteClientAsc = 'remoteClient_ASC',
    RemoteClientDesc = 'remoteClient_DESC',
    SentAtAsc = 'sentAt_ASC',
    SentAtDesc = 'sentAt_DESC',
    StatusAsc = 'status_ASC',
    StatusDesc = 'status_DESC',
    TypeAsc = 'type_ASC',
    TypeDesc = 'type_DESC',
    UniqKeyAsc = 'uniqKey_ASC',
    UniqKeyDesc = 'uniqKey_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    UserAsc = 'user_ASC',
    UserDesc = 'user_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortMeterHistoryRecordsBy {
    AccountNumberAsc = 'accountNumber_ASC',
    AccountNumberDesc = 'accountNumber_DESC',
    ArchiveDateAsc = 'archiveDate_ASC',
    ArchiveDateDesc = 'archiveDate_DESC',
    CommissioningDateAsc = 'commissioningDate_ASC',
    CommissioningDateDesc = 'commissioningDate_DESC',
    ControlReadingsDateAsc = 'controlReadingsDate_ASC',
    ControlReadingsDateDesc = 'controlReadingsDate_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    InstallationDateAsc = 'installationDate_ASC',
    InstallationDateDesc = 'installationDate_DESC',
    IsAutomaticAsc = 'isAutomatic_ASC',
    IsAutomaticDesc = 'isAutomatic_DESC',
    NextVerificationDateAsc = 'nextVerificationDate_ASC',
    NextVerificationDateDesc = 'nextVerificationDate_DESC',
    NumberOfTariffsAsc = 'numberOfTariffs_ASC',
    NumberOfTariffsDesc = 'numberOfTariffs_DESC',
    NumberAsc = 'number_ASC',
    NumberDesc = 'number_DESC',
    PlaceAsc = 'place_ASC',
    PlaceDesc = 'place_DESC',
    SealingDateAsc = 'sealingDate_ASC',
    SealingDateDesc = 'sealingDate_DESC',
    UnitNameAsc = 'unitName_ASC',
    UnitNameDesc = 'unitName_DESC',
    UnitTypeAsc = 'unitType_ASC',
    UnitTypeDesc = 'unitType_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
    VerificationDateAsc = 'verificationDate_ASC',
    VerificationDateDesc = 'verificationDate_DESC',
}

export enum SortMeterReadingExportTaskHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    ExportedRecordsCountAsc = 'exportedRecordsCount_ASC',
    ExportedRecordsCountDesc = 'exportedRecordsCount_DESC',
    FormatAsc = 'format_ASC',
    FormatDesc = 'format_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    LocaleAsc = 'locale_ASC',
    LocaleDesc = 'locale_DESC',
    StatusAsc = 'status_ASC',
    StatusDesc = 'status_DESC',
    TimeZoneAsc = 'timeZone_ASC',
    TimeZoneDesc = 'timeZone_DESC',
    TotalRecordsCountAsc = 'totalRecordsCount_ASC',
    TotalRecordsCountDesc = 'totalRecordsCount_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortMeterReadingExportTasksBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    ExportedRecordsCountAsc = 'exportedRecordsCount_ASC',
    ExportedRecordsCountDesc = 'exportedRecordsCount_DESC',
    FormatAsc = 'format_ASC',
    FormatDesc = 'format_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    LocaleAsc = 'locale_ASC',
    LocaleDesc = 'locale_DESC',
    StatusAsc = 'status_ASC',
    StatusDesc = 'status_DESC',
    TimeZoneAsc = 'timeZone_ASC',
    TimeZoneDesc = 'timeZone_DESC',
    TotalRecordsCountAsc = 'totalRecordsCount_ASC',
    TotalRecordsCountDesc = 'totalRecordsCount_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    UserAsc = 'user_ASC',
    UserDesc = 'user_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortMeterReadingFilterTemplateHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortMeterReadingFilterTemplatesBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    EmployeeAsc = 'employee_ASC',
    EmployeeDesc = 'employee_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortMeterReadingHistoryRecordsBy {
    AccountNumberAsc = 'accountNumber_ASC',
    AccountNumberDesc = 'accountNumber_DESC',
    BillingStatusTextAsc = 'billingStatusText_ASC',
    BillingStatusTextDesc = 'billingStatusText_DESC',
    BillingStatusAsc = 'billingStatus_ASC',
    BillingStatusDesc = 'billingStatus_DESC',
    ClientEmailAsc = 'clientEmail_ASC',
    ClientEmailDesc = 'clientEmail_DESC',
    ClientNameAsc = 'clientName_ASC',
    ClientNameDesc = 'clientName_DESC',
    ClientPhoneAsc = 'clientPhone_ASC',
    ClientPhoneDesc = 'clientPhone_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DateAsc = 'date_ASC',
    DateDesc = 'date_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
    Value1Asc = 'value1_ASC',
    Value1Desc = 'value1_DESC',
    Value2Asc = 'value2_ASC',
    Value2Desc = 'value2_DESC',
    Value3Asc = 'value3_ASC',
    Value3Desc = 'value3_DESC',
    Value4Asc = 'value4_ASC',
    Value4Desc = 'value4_DESC',
}

export enum SortMeterReadingSourceHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    TypeAsc = 'type_ASC',
    TypeDesc = 'type_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortMeterReadingSourcesBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    TypeAsc = 'type_ASC',
    TypeDesc = 'type_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortMeterReadingsBy {
    AccountNumberAsc = 'accountNumber_ASC',
    AccountNumberDesc = 'accountNumber_DESC',
    BillingStatusTextAsc = 'billingStatusText_ASC',
    BillingStatusTextDesc = 'billingStatusText_DESC',
    BillingStatusAsc = 'billingStatus_ASC',
    BillingStatusDesc = 'billingStatus_DESC',
    ClientEmailAsc = 'clientEmail_ASC',
    ClientEmailDesc = 'clientEmail_DESC',
    ClientNameAsc = 'clientName_ASC',
    ClientNameDesc = 'clientName_DESC',
    ClientPhoneAsc = 'clientPhone_ASC',
    ClientPhoneDesc = 'clientPhone_DESC',
    ClientAsc = 'client_ASC',
    ClientDesc = 'client_DESC',
    ContactAsc = 'contact_ASC',
    ContactDesc = 'contact_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DateAsc = 'date_ASC',
    DateDesc = 'date_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    MeterAsc = 'meter_ASC',
    MeterDesc = 'meter_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    SourceAsc = 'source_ASC',
    SourceDesc = 'source_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
    Value1Asc = 'value1_ASC',
    Value1Desc = 'value1_DESC',
    Value2Asc = 'value2_ASC',
    Value2Desc = 'value2_DESC',
    Value3Asc = 'value3_ASC',
    Value3Desc = 'value3_DESC',
    Value4Asc = 'value4_ASC',
    Value4Desc = 'value4_DESC',
}

export enum SortMeterReadingsImportTaskHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    ErrorMessageAsc = 'errorMessage_ASC',
    ErrorMessageDesc = 'errorMessage_DESC',
    FormatAsc = 'format_ASC',
    FormatDesc = 'format_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    ImportedRecordsCountAsc = 'importedRecordsCount_ASC',
    ImportedRecordsCountDesc = 'importedRecordsCount_DESC',
    LocaleAsc = 'locale_ASC',
    LocaleDesc = 'locale_DESC',
    ProcessedRecordsCountAsc = 'processedRecordsCount_ASC',
    ProcessedRecordsCountDesc = 'processedRecordsCount_DESC',
    StatusAsc = 'status_ASC',
    StatusDesc = 'status_DESC',
    TotalRecordsCountAsc = 'totalRecordsCount_ASC',
    TotalRecordsCountDesc = 'totalRecordsCount_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortMeterReadingsImportTasksBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    ErrorMessageAsc = 'errorMessage_ASC',
    ErrorMessageDesc = 'errorMessage_DESC',
    FormatAsc = 'format_ASC',
    FormatDesc = 'format_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    ImportedRecordsCountAsc = 'importedRecordsCount_ASC',
    ImportedRecordsCountDesc = 'importedRecordsCount_DESC',
    LocaleAsc = 'locale_ASC',
    LocaleDesc = 'locale_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    ProcessedRecordsCountAsc = 'processedRecordsCount_ASC',
    ProcessedRecordsCountDesc = 'processedRecordsCount_DESC',
    StatusAsc = 'status_ASC',
    StatusDesc = 'status_DESC',
    TotalRecordsCountAsc = 'totalRecordsCount_ASC',
    TotalRecordsCountDesc = 'totalRecordsCount_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    UserAsc = 'user_ASC',
    UserDesc = 'user_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortMeterReportingPeriodHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NotifyEndDayAsc = 'notifyEndDay_ASC',
    NotifyEndDayDesc = 'notifyEndDay_DESC',
    NotifyStartDayAsc = 'notifyStartDay_ASC',
    NotifyStartDayDesc = 'notifyStartDay_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortMeterReportingPeriodsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NotifyEndDayAsc = 'notifyEndDay_ASC',
    NotifyEndDayDesc = 'notifyEndDay_DESC',
    NotifyStartDayAsc = 'notifyStartDay_ASC',
    NotifyStartDayDesc = 'notifyStartDay_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    PropertyAsc = 'property_ASC',
    PropertyDesc = 'property_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortMeterResourceHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    MeasureAsc = 'measure_ASC',
    MeasureDesc = 'measure_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortMeterResourceOwnerHistoryRecordsBy {
    AddressKeyAsc = 'addressKey_ASC',
    AddressKeyDesc = 'addressKey_DESC',
    AddressAsc = 'address_ASC',
    AddressDesc = 'address_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortMeterResourceOwnersBy {
    AddressKeyAsc = 'addressKey_ASC',
    AddressKeyDesc = 'addressKey_DESC',
    AddressAsc = 'address_ASC',
    AddressDesc = 'address_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    ResourceAsc = 'resource_ASC',
    ResourceDesc = 'resource_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortMeterResourcesBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    MeasureAsc = 'measure_ASC',
    MeasureDesc = 'measure_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortMetersBy {
    AccountNumberAsc = 'accountNumber_ASC',
    AccountNumberDesc = 'accountNumber_DESC',
    ArchiveDateAsc = 'archiveDate_ASC',
    ArchiveDateDesc = 'archiveDate_DESC',
    B2bAppAsc = 'b2bApp_ASC',
    B2bAppDesc = 'b2bApp_DESC',
    B2cAppAsc = 'b2cApp_ASC',
    B2cAppDesc = 'b2cApp_DESC',
    CommissioningDateAsc = 'commissioningDate_ASC',
    CommissioningDateDesc = 'commissioningDate_DESC',
    ControlReadingsDateAsc = 'controlReadingsDate_ASC',
    ControlReadingsDateDesc = 'controlReadingsDate_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    InstallationDateAsc = 'installationDate_ASC',
    InstallationDateDesc = 'installationDate_DESC',
    IsAutomaticAsc = 'isAutomatic_ASC',
    IsAutomaticDesc = 'isAutomatic_DESC',
    NextVerificationDateAsc = 'nextVerificationDate_ASC',
    NextVerificationDateDesc = 'nextVerificationDate_DESC',
    NumberOfTariffsAsc = 'numberOfTariffs_ASC',
    NumberOfTariffsDesc = 'numberOfTariffs_DESC',
    NumberAsc = 'number_ASC',
    NumberDesc = 'number_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    PlaceAsc = 'place_ASC',
    PlaceDesc = 'place_DESC',
    PropertyAsc = 'property_ASC',
    PropertyDesc = 'property_DESC',
    ResourceAsc = 'resource_ASC',
    ResourceDesc = 'resource_DESC',
    SealingDateAsc = 'sealingDate_ASC',
    SealingDateDesc = 'sealingDate_DESC',
    UnitNameAsc = 'unitName_ASC',
    UnitNameDesc = 'unitName_DESC',
    UnitTypeAsc = 'unitType_ASC',
    UnitTypeDesc = 'unitType_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
    VerificationDateAsc = 'verificationDate_ASC',
    VerificationDateDesc = 'verificationDate_DESC',
}

export enum SortMobileFeatureConfigHistoryRecordsBy {
    CommonPhoneAsc = 'commonPhone_ASC',
    CommonPhoneDesc = 'commonPhone_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    OnlyGreaterThanPreviousMeterReadingIsEnabledAsc = 'onlyGreaterThanPreviousMeterReadingIsEnabled_ASC',
    OnlyGreaterThanPreviousMeterReadingIsEnabledDesc = 'onlyGreaterThanPreviousMeterReadingIsEnabled_DESC',
    TicketSubmittingIsDisabledAsc = 'ticketSubmittingIsDisabled_ASC',
    TicketSubmittingIsDisabledDesc = 'ticketSubmittingIsDisabled_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortMobileFeatureConfigsBy {
    CommonPhoneAsc = 'commonPhone_ASC',
    CommonPhoneDesc = 'commonPhone_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    OnlyGreaterThanPreviousMeterReadingIsEnabledAsc = 'onlyGreaterThanPreviousMeterReadingIsEnabled_ASC',
    OnlyGreaterThanPreviousMeterReadingIsEnabledDesc = 'onlyGreaterThanPreviousMeterReadingIsEnabled_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    TicketSubmittingIsDisabledAsc = 'ticketSubmittingIsDisabled_ASC',
    TicketSubmittingIsDisabledDesc = 'ticketSubmittingIsDisabled_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortMultiPaymentHistoryRecordsBy {
    AmountWithoutExplicitFeeAsc = 'amountWithoutExplicitFee_ASC',
    AmountWithoutExplicitFeeDesc = 'amountWithoutExplicitFee_DESC',
    CardNumberAsc = 'cardNumber_ASC',
    CardNumberDesc = 'cardNumber_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CurrencyCodeAsc = 'currencyCode_ASC',
    CurrencyCodeDesc = 'currencyCode_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    ExplicitFeeAsc = 'explicitFee_ASC',
    ExplicitFeeDesc = 'explicitFee_DESC',
    ExplicitServiceChargeAsc = 'explicitServiceCharge_ASC',
    ExplicitServiceChargeDesc = 'explicitServiceCharge_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    ImplicitFeeAsc = 'implicitFee_ASC',
    ImplicitFeeDesc = 'implicitFee_DESC',
    ImportIdAsc = 'importId_ASC',
    ImportIdDesc = 'importId_DESC',
    PayerEmailAsc = 'payerEmail_ASC',
    PayerEmailDesc = 'payerEmail_DESC',
    PaymentWayAsc = 'paymentWay_ASC',
    PaymentWayDesc = 'paymentWay_DESC',
    ServiceCategoryAsc = 'serviceCategory_ASC',
    ServiceCategoryDesc = 'serviceCategory_DESC',
    ServiceFeeAsc = 'serviceFee_ASC',
    ServiceFeeDesc = 'serviceFee_DESC',
    StatusAsc = 'status_ASC',
    StatusDesc = 'status_DESC',
    TransactionIdAsc = 'transactionId_ASC',
    TransactionIdDesc = 'transactionId_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
    WithdrawnAtAsc = 'withdrawnAt_ASC',
    WithdrawnAtDesc = 'withdrawnAt_DESC',
}

export enum SortMultiPaymentsBy {
    AmountWithoutExplicitFeeAsc = 'amountWithoutExplicitFee_ASC',
    AmountWithoutExplicitFeeDesc = 'amountWithoutExplicitFee_DESC',
    CardNumberAsc = 'cardNumber_ASC',
    CardNumberDesc = 'cardNumber_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    CurrencyCodeAsc = 'currencyCode_ASC',
    CurrencyCodeDesc = 'currencyCode_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    ExplicitFeeAsc = 'explicitFee_ASC',
    ExplicitFeeDesc = 'explicitFee_DESC',
    ExplicitServiceChargeAsc = 'explicitServiceCharge_ASC',
    ExplicitServiceChargeDesc = 'explicitServiceCharge_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    ImplicitFeeAsc = 'implicitFee_ASC',
    ImplicitFeeDesc = 'implicitFee_DESC',
    ImportIdAsc = 'importId_ASC',
    ImportIdDesc = 'importId_DESC',
    IntegrationAsc = 'integration_ASC',
    IntegrationDesc = 'integration_DESC',
    PayerEmailAsc = 'payerEmail_ASC',
    PayerEmailDesc = 'payerEmail_DESC',
    PaymentWayAsc = 'paymentWay_ASC',
    PaymentWayDesc = 'paymentWay_DESC',
    PaymentsAsc = 'payments_ASC',
    PaymentsDesc = 'payments_DESC',
    RecurrentPaymentContextAsc = 'recurrentPaymentContext_ASC',
    RecurrentPaymentContextDesc = 'recurrentPaymentContext_DESC',
    ServiceCategoryAsc = 'serviceCategory_ASC',
    ServiceCategoryDesc = 'serviceCategory_DESC',
    ServiceFeeAsc = 'serviceFee_ASC',
    ServiceFeeDesc = 'serviceFee_DESC',
    StatusAsc = 'status_ASC',
    StatusDesc = 'status_DESC',
    TransactionIdAsc = 'transactionId_ASC',
    TransactionIdDesc = 'transactionId_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    UserAsc = 'user_ASC',
    UserDesc = 'user_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
    WithdrawnAtAsc = 'withdrawnAt_ASC',
    WithdrawnAtDesc = 'withdrawnAt_DESC',
}

export enum SortNewsItemHistoryRecordsBy {
    BodyAsc = 'body_ASC',
    BodyDesc = 'body_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IsPublishedAsc = 'isPublished_ASC',
    IsPublishedDesc = 'isPublished_DESC',
    PublishedAtAsc = 'publishedAt_ASC',
    PublishedAtDesc = 'publishedAt_DESC',
    SendAtAsc = 'sendAt_ASC',
    SendAtDesc = 'sendAt_DESC',
    SentAtAsc = 'sentAt_ASC',
    SentAtDesc = 'sentAt_DESC',
    TitleAsc = 'title_ASC',
    TitleDesc = 'title_DESC',
    TypeAsc = 'type_ASC',
    TypeDesc = 'type_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
    ValidBeforeAsc = 'validBefore_ASC',
    ValidBeforeDesc = 'validBefore_DESC',
}

export enum SortNewsItemRecipientsExportTaskHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    StatusAsc = 'status_ASC',
    StatusDesc = 'status_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortNewsItemRecipientsExportTasksBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    StatusAsc = 'status_ASC',
    StatusDesc = 'status_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    UserAsc = 'user_ASC',
    UserDesc = 'user_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortNewsItemScopeHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    TypeAsc = 'type_ASC',
    TypeDesc = 'type_DESC',
    UnitNameAsc = 'unitName_ASC',
    UnitNameDesc = 'unitName_DESC',
    UnitTypeAsc = 'unitType_ASC',
    UnitTypeDesc = 'unitType_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortNewsItemScopesBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NewsItemAsc = 'newsItem_ASC',
    NewsItemDesc = 'newsItem_DESC',
    PropertyAsc = 'property_ASC',
    PropertyDesc = 'property_DESC',
    TypeAsc = 'type_ASC',
    TypeDesc = 'type_DESC',
    UnitNameAsc = 'unitName_ASC',
    UnitNameDesc = 'unitName_DESC',
    UnitTypeAsc = 'unitType_ASC',
    UnitTypeDesc = 'unitType_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortNewsItemSharingHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    StatusMessageAsc = 'statusMessage_ASC',
    StatusMessageDesc = 'statusMessage_DESC',
    StatusAsc = 'status_ASC',
    StatusDesc = 'status_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortNewsItemSharingsBy {
    B2bAppContextAsc = 'b2bAppContext_ASC',
    B2bAppContextDesc = 'b2bAppContext_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NewsItemAsc = 'newsItem_ASC',
    NewsItemDesc = 'newsItem_DESC',
    StatusMessageAsc = 'statusMessage_ASC',
    StatusMessageDesc = 'statusMessage_DESC',
    StatusAsc = 'status_ASC',
    StatusDesc = 'status_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortNewsItemTemplateHistoryRecordsBy {
    BodyAsc = 'body_ASC',
    BodyDesc = 'body_DESC',
    CategoryAsc = 'category_ASC',
    CategoryDesc = 'category_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    TitleAsc = 'title_ASC',
    TitleDesc = 'title_DESC',
    TypeAsc = 'type_ASC',
    TypeDesc = 'type_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortNewsItemTemplatesBy {
    BodyAsc = 'body_ASC',
    BodyDesc = 'body_DESC',
    CategoryAsc = 'category_ASC',
    CategoryDesc = 'category_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    TitleAsc = 'title_ASC',
    TitleDesc = 'title_DESC',
    TypeAsc = 'type_ASC',
    TypeDesc = 'type_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortNewsItemUserReadHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortNewsItemUserReadsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NewsItemAsc = 'newsItem_ASC',
    NewsItemDesc = 'newsItem_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    UserAsc = 'user_ASC',
    UserDesc = 'user_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortNewsItemsBy {
    BodyAsc = 'body_ASC',
    BodyDesc = 'body_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IsPublishedAsc = 'isPublished_ASC',
    IsPublishedDesc = 'isPublished_DESC',
    NumberAsc = 'number_ASC',
    NumberDesc = 'number_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    PublishedAtAsc = 'publishedAt_ASC',
    PublishedAtDesc = 'publishedAt_DESC',
    ScopesAsc = 'scopes_ASC',
    ScopesDesc = 'scopes_DESC',
    SendAtAsc = 'sendAt_ASC',
    SendAtDesc = 'sendAt_DESC',
    SentAtAsc = 'sentAt_ASC',
    SentAtDesc = 'sentAt_DESC',
    TitleAsc = 'title_ASC',
    TitleDesc = 'title_DESC',
    TypeAsc = 'type_ASC',
    TypeDesc = 'type_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
    ValidBeforeAsc = 'validBefore_ASC',
    ValidBeforeDesc = 'validBefore_DESC',
}

export enum SortNotificationAnonymousSettingHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    EmailAsc = 'email_ASC',
    EmailDesc = 'email_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IsEnabledAsc = 'isEnabled_ASC',
    IsEnabledDesc = 'isEnabled_DESC',
    MessageTransportAsc = 'messageTransport_ASC',
    MessageTransportDesc = 'messageTransport_DESC',
    MessageTypeAsc = 'messageType_ASC',
    MessageTypeDesc = 'messageType_DESC',
    PhoneAsc = 'phone_ASC',
    PhoneDesc = 'phone_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortNotificationAnonymousSettingsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    EmailAsc = 'email_ASC',
    EmailDesc = 'email_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IsEnabledAsc = 'isEnabled_ASC',
    IsEnabledDesc = 'isEnabled_DESC',
    MessageTransportAsc = 'messageTransport_ASC',
    MessageTransportDesc = 'messageTransport_DESC',
    MessageTypeAsc = 'messageType_ASC',
    MessageTypeDesc = 'messageType_DESC',
    PhoneAsc = 'phone_ASC',
    PhoneDesc = 'phone_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortNotificationUserSettingHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IsEnabledAsc = 'isEnabled_ASC',
    IsEnabledDesc = 'isEnabled_DESC',
    MessageTransportAsc = 'messageTransport_ASC',
    MessageTransportDesc = 'messageTransport_DESC',
    MessageTypeAsc = 'messageType_ASC',
    MessageTypeDesc = 'messageType_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortNotificationUserSettingsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IsEnabledAsc = 'isEnabled_ASC',
    IsEnabledDesc = 'isEnabled_DESC',
    MessageTransportAsc = 'messageTransport_ASC',
    MessageTransportDesc = 'messageTransport_DESC',
    MessageTypeAsc = 'messageType_ASC',
    MessageTypeDesc = 'messageType_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    UserAsc = 'user_ASC',
    UserDesc = 'user_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortOidcClientHistoryRecordsBy {
    CanAuthorizeSuperUsersAsc = 'canAuthorizeSuperUsers_ASC',
    CanAuthorizeSuperUsersDesc = 'canAuthorizeSuperUsers_DESC',
    ClientIdAsc = 'clientId_ASC',
    ClientIdDesc = 'clientId_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    ExpiresAtAsc = 'expiresAt_ASC',
    ExpiresAtDesc = 'expiresAt_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IsEnabledAsc = 'isEnabled_ASC',
    IsEnabledDesc = 'isEnabled_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortOidcClientsBy {
    CanAuthorizeSuperUsersAsc = 'canAuthorizeSuperUsers_ASC',
    CanAuthorizeSuperUsersDesc = 'canAuthorizeSuperUsers_DESC',
    ClientIdAsc = 'clientId_ASC',
    ClientIdDesc = 'clientId_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    ExpiresAtAsc = 'expiresAt_ASC',
    ExpiresAtDesc = 'expiresAt_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    ImportIdAsc = 'importId_ASC',
    ImportIdDesc = 'importId_DESC',
    ImportRemoteSystemAsc = 'importRemoteSystem_ASC',
    ImportRemoteSystemDesc = 'importRemoteSystem_DESC',
    IsEnabledAsc = 'isEnabled_ASC',
    IsEnabledDesc = 'isEnabled_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortOrganizationEmployeeHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    EmailAsc = 'email_ASC',
    EmailDesc = 'email_DESC',
    HasAllSpecializationsAsc = 'hasAllSpecializations_ASC',
    HasAllSpecializationsDesc = 'hasAllSpecializations_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    InviteCodeAsc = 'inviteCode_ASC',
    InviteCodeDesc = 'inviteCode_DESC',
    IsAcceptedAsc = 'isAccepted_ASC',
    IsAcceptedDesc = 'isAccepted_DESC',
    IsBlockedAsc = 'isBlocked_ASC',
    IsBlockedDesc = 'isBlocked_DESC',
    IsRejectedAsc = 'isRejected_ASC',
    IsRejectedDesc = 'isRejected_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    PhoneAsc = 'phone_ASC',
    PhoneDesc = 'phone_DESC',
    PositionAsc = 'position_ASC',
    PositionDesc = 'position_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortOrganizationEmployeeRoleHistoryRecordsBy {
    CanBeAssignedAsExecutorAsc = 'canBeAssignedAsExecutor_ASC',
    CanBeAssignedAsExecutorDesc = 'canBeAssignedAsExecutor_DESC',
    CanBeAssignedAsResponsibleAsc = 'canBeAssignedAsResponsible_ASC',
    CanBeAssignedAsResponsibleDesc = 'canBeAssignedAsResponsible_DESC',
    CanDownloadCallRecordsAsc = 'canDownloadCallRecords_ASC',
    CanDownloadCallRecordsDesc = 'canDownloadCallRecords_DESC',
    CanImportBillingReceiptsAsc = 'canImportBillingReceipts_ASC',
    CanImportBillingReceiptsDesc = 'canImportBillingReceipts_DESC',
    CanInviteNewOrganizationEmployeesAsc = 'canInviteNewOrganizationEmployees_ASC',
    CanInviteNewOrganizationEmployeesDesc = 'canInviteNewOrganizationEmployees_DESC',
    CanManageB2BAppsAsc = 'canManageB2BApps_ASC',
    CanManageB2BAppsDesc = 'canManageB2BApps_DESC',
    CanManageBankAccountReportTasksAsc = 'canManageBankAccountReportTasks_ASC',
    CanManageBankAccountReportTasksDesc = 'canManageBankAccountReportTasks_DESC',
    CanManageBankAccountReportsAsc = 'canManageBankAccountReports_ASC',
    CanManageBankAccountReportsDesc = 'canManageBankAccountReports_DESC',
    CanManageBankAccountsAsc = 'canManageBankAccounts_ASC',
    CanManageBankAccountsDesc = 'canManageBankAccounts_DESC',
    CanManageBankContractorAccountsAsc = 'canManageBankContractorAccounts_ASC',
    CanManageBankContractorAccountsDesc = 'canManageBankContractorAccounts_DESC',
    CanManageBankIntegrationAccountContextsAsc = 'canManageBankIntegrationAccountContexts_ASC',
    CanManageBankIntegrationAccountContextsDesc = 'canManageBankIntegrationAccountContexts_DESC',
    CanManageBankIntegrationOrganizationContextsAsc = 'canManageBankIntegrationOrganizationContexts_ASC',
    CanManageBankIntegrationOrganizationContextsDesc = 'canManageBankIntegrationOrganizationContexts_DESC',
    CanManageBankTransactionsAsc = 'canManageBankTransactions_ASC',
    CanManageBankTransactionsDesc = 'canManageBankTransactions_DESC',
    CanManageCallRecordsAsc = 'canManageCallRecords_ASC',
    CanManageCallRecordsDesc = 'canManageCallRecords_DESC',
    CanManageContactRolesAsc = 'canManageContactRoles_ASC',
    CanManageContactRolesDesc = 'canManageContactRoles_DESC',
    CanManageContactsAsc = 'canManageContacts_ASC',
    CanManageContactsDesc = 'canManageContacts_DESC',
    CanManageDocumentsAsc = 'canManageDocuments_ASC',
    CanManageDocumentsDesc = 'canManageDocuments_DESC',
    CanManageEmployeesAsc = 'canManageEmployees_ASC',
    CanManageEmployeesDesc = 'canManageEmployees_DESC',
    CanManageIncidentsAsc = 'canManageIncidents_ASC',
    CanManageIncidentsDesc = 'canManageIncidents_DESC',
    CanManageIntegrationsAsc = 'canManageIntegrations_ASC',
    CanManageIntegrationsDesc = 'canManageIntegrations_DESC',
    CanManageInvoicesAsc = 'canManageInvoices_ASC',
    CanManageInvoicesDesc = 'canManageInvoices_DESC',
    CanManageMarketItemPricesAsc = 'canManageMarketItemPrices_ASC',
    CanManageMarketItemPricesDesc = 'canManageMarketItemPrices_DESC',
    CanManageMarketItemsAsc = 'canManageMarketItems_ASC',
    CanManageMarketItemsDesc = 'canManageMarketItems_DESC',
    CanManageMarketPriceScopesAsc = 'canManageMarketPriceScopes_ASC',
    CanManageMarketPriceScopesDesc = 'canManageMarketPriceScopes_DESC',
    CanManageMarketSettingAsc = 'canManageMarketSetting_ASC',
    CanManageMarketSettingDesc = 'canManageMarketSetting_DESC',
    CanManageMarketplaceAsc = 'canManageMarketplace_ASC',
    CanManageMarketplaceDesc = 'canManageMarketplace_DESC',
    CanManageMeterReadingsAsc = 'canManageMeterReadings_ASC',
    CanManageMeterReadingsDesc = 'canManageMeterReadings_DESC',
    CanManageMetersAsc = 'canManageMeters_ASC',
    CanManageMetersDesc = 'canManageMeters_DESC',
    CanManageMobileFeatureConfigsAsc = 'canManageMobileFeatureConfigs_ASC',
    CanManageMobileFeatureConfigsDesc = 'canManageMobileFeatureConfigs_DESC',
    CanManageNewsItemTemplatesAsc = 'canManageNewsItemTemplates_ASC',
    CanManageNewsItemTemplatesDesc = 'canManageNewsItemTemplates_DESC',
    CanManageNewsItemsAsc = 'canManageNewsItems_ASC',
    CanManageNewsItemsDesc = 'canManageNewsItems_DESC',
    CanManageOrganizationAsc = 'canManageOrganization_ASC',
    CanManageOrganizationDesc = 'canManageOrganization_DESC',
    CanManagePropertiesAsc = 'canManageProperties_ASC',
    CanManagePropertiesDesc = 'canManageProperties_DESC',
    CanManagePropertyScopesAsc = 'canManagePropertyScopes_ASC',
    CanManagePropertyScopesDesc = 'canManagePropertyScopes_DESC',
    CanManageRolesAsc = 'canManageRoles_ASC',
    CanManageRolesDesc = 'canManageRoles_DESC',
    CanManageTicketAutoAssignmentsAsc = 'canManageTicketAutoAssignments_ASC',
    CanManageTicketAutoAssignmentsDesc = 'canManageTicketAutoAssignments_DESC',
    CanManageTicketCommentsAsc = 'canManageTicketComments_ASC',
    CanManageTicketCommentsDesc = 'canManageTicketComments_DESC',
    CanManageTicketPropertyHintsAsc = 'canManageTicketPropertyHints_ASC',
    CanManageTicketPropertyHintsDesc = 'canManageTicketPropertyHints_DESC',
    CanManageTicketsAsc = 'canManageTickets_ASC',
    CanManageTicketsDesc = 'canManageTickets_DESC',
    CanManageTourAsc = 'canManageTour_ASC',
    CanManageTourDesc = 'canManageTour_DESC',
    CanReadAnalyticsAsc = 'canReadAnalytics_ASC',
    CanReadAnalyticsDesc = 'canReadAnalytics_DESC',
    CanReadBillingReceiptsAsc = 'canReadBillingReceipts_ASC',
    CanReadBillingReceiptsDesc = 'canReadBillingReceipts_DESC',
    CanReadCallRecordsAsc = 'canReadCallRecords_ASC',
    CanReadCallRecordsDesc = 'canReadCallRecords_DESC',
    CanReadContactsAsc = 'canReadContacts_ASC',
    CanReadContactsDesc = 'canReadContacts_DESC',
    CanReadDocumentsAsc = 'canReadDocuments_ASC',
    CanReadDocumentsDesc = 'canReadDocuments_DESC',
    CanReadEmployeesAsc = 'canReadEmployees_ASC',
    CanReadEmployeesDesc = 'canReadEmployees_DESC',
    CanReadExternalReportsAsc = 'canReadExternalReports_ASC',
    CanReadExternalReportsDesc = 'canReadExternalReports_DESC',
    CanReadIncidentsAsc = 'canReadIncidents_ASC',
    CanReadIncidentsDesc = 'canReadIncidents_DESC',
    CanReadInvoicesAsc = 'canReadInvoices_ASC',
    CanReadInvoicesDesc = 'canReadInvoices_DESC',
    CanReadMarketItemPricesAsc = 'canReadMarketItemPrices_ASC',
    CanReadMarketItemPricesDesc = 'canReadMarketItemPrices_DESC',
    CanReadMarketItemsAsc = 'canReadMarketItems_ASC',
    CanReadMarketItemsDesc = 'canReadMarketItems_DESC',
    CanReadMarketPriceScopesAsc = 'canReadMarketPriceScopes_ASC',
    CanReadMarketPriceScopesDesc = 'canReadMarketPriceScopes_DESC',
    CanReadMarketSettingAsc = 'canReadMarketSetting_ASC',
    CanReadMarketSettingDesc = 'canReadMarketSetting_DESC',
    CanReadMarketplaceAsc = 'canReadMarketplace_ASC',
    CanReadMarketplaceDesc = 'canReadMarketplace_DESC',
    CanReadMetersAsc = 'canReadMeters_ASC',
    CanReadMetersDesc = 'canReadMeters_DESC',
    CanReadNewsItemsAsc = 'canReadNewsItems_ASC',
    CanReadNewsItemsDesc = 'canReadNewsItems_DESC',
    CanReadPaymentsWithInvoicesAsc = 'canReadPaymentsWithInvoices_ASC',
    CanReadPaymentsWithInvoicesDesc = 'canReadPaymentsWithInvoices_DESC',
    CanReadPaymentsAsc = 'canReadPayments_ASC',
    CanReadPaymentsDesc = 'canReadPayments_DESC',
    CanReadPropertiesAsc = 'canReadProperties_ASC',
    CanReadPropertiesDesc = 'canReadProperties_DESC',
    CanReadServicesAsc = 'canReadServices_ASC',
    CanReadServicesDesc = 'canReadServices_DESC',
    CanReadSettingsAsc = 'canReadSettings_ASC',
    CanReadSettingsDesc = 'canReadSettings_DESC',
    CanReadTicketsAsc = 'canReadTickets_ASC',
    CanReadTicketsDesc = 'canReadTickets_DESC',
    CanReadTourAsc = 'canReadTour_ASC',
    CanReadTourDesc = 'canReadTour_DESC',
    CanShareTicketsAsc = 'canShareTickets_ASC',
    CanShareTicketsDesc = 'canShareTickets_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DescriptionAsc = 'description_ASC',
    DescriptionDesc = 'description_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IsDefaultAsc = 'isDefault_ASC',
    IsDefaultDesc = 'isDefault_DESC',
    IsEditableAsc = 'isEditable_ASC',
    IsEditableDesc = 'isEditable_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    TicketVisibilityTypeAsc = 'ticketVisibilityType_ASC',
    TicketVisibilityTypeDesc = 'ticketVisibilityType_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortOrganizationEmployeeRolesBy {
    CanBeAssignedAsExecutorAsc = 'canBeAssignedAsExecutor_ASC',
    CanBeAssignedAsExecutorDesc = 'canBeAssignedAsExecutor_DESC',
    CanBeAssignedAsResponsibleAsc = 'canBeAssignedAsResponsible_ASC',
    CanBeAssignedAsResponsibleDesc = 'canBeAssignedAsResponsible_DESC',
    CanDownloadCallRecordsAsc = 'canDownloadCallRecords_ASC',
    CanDownloadCallRecordsDesc = 'canDownloadCallRecords_DESC',
    CanImportBillingReceiptsAsc = 'canImportBillingReceipts_ASC',
    CanImportBillingReceiptsDesc = 'canImportBillingReceipts_DESC',
    CanInviteNewOrganizationEmployeesAsc = 'canInviteNewOrganizationEmployees_ASC',
    CanInviteNewOrganizationEmployeesDesc = 'canInviteNewOrganizationEmployees_DESC',
    CanManageB2BAppsAsc = 'canManageB2BApps_ASC',
    CanManageB2BAppsDesc = 'canManageB2BApps_DESC',
    CanManageBankAccountReportTasksAsc = 'canManageBankAccountReportTasks_ASC',
    CanManageBankAccountReportTasksDesc = 'canManageBankAccountReportTasks_DESC',
    CanManageBankAccountReportsAsc = 'canManageBankAccountReports_ASC',
    CanManageBankAccountReportsDesc = 'canManageBankAccountReports_DESC',
    CanManageBankAccountsAsc = 'canManageBankAccounts_ASC',
    CanManageBankAccountsDesc = 'canManageBankAccounts_DESC',
    CanManageBankContractorAccountsAsc = 'canManageBankContractorAccounts_ASC',
    CanManageBankContractorAccountsDesc = 'canManageBankContractorAccounts_DESC',
    CanManageBankIntegrationAccountContextsAsc = 'canManageBankIntegrationAccountContexts_ASC',
    CanManageBankIntegrationAccountContextsDesc = 'canManageBankIntegrationAccountContexts_DESC',
    CanManageBankIntegrationOrganizationContextsAsc = 'canManageBankIntegrationOrganizationContexts_ASC',
    CanManageBankIntegrationOrganizationContextsDesc = 'canManageBankIntegrationOrganizationContexts_DESC',
    CanManageBankTransactionsAsc = 'canManageBankTransactions_ASC',
    CanManageBankTransactionsDesc = 'canManageBankTransactions_DESC',
    CanManageCallRecordsAsc = 'canManageCallRecords_ASC',
    CanManageCallRecordsDesc = 'canManageCallRecords_DESC',
    CanManageContactRolesAsc = 'canManageContactRoles_ASC',
    CanManageContactRolesDesc = 'canManageContactRoles_DESC',
    CanManageContactsAsc = 'canManageContacts_ASC',
    CanManageContactsDesc = 'canManageContacts_DESC',
    CanManageDocumentsAsc = 'canManageDocuments_ASC',
    CanManageDocumentsDesc = 'canManageDocuments_DESC',
    CanManageEmployeesAsc = 'canManageEmployees_ASC',
    CanManageEmployeesDesc = 'canManageEmployees_DESC',
    CanManageIncidentsAsc = 'canManageIncidents_ASC',
    CanManageIncidentsDesc = 'canManageIncidents_DESC',
    CanManageIntegrationsAsc = 'canManageIntegrations_ASC',
    CanManageIntegrationsDesc = 'canManageIntegrations_DESC',
    CanManageInvoicesAsc = 'canManageInvoices_ASC',
    CanManageInvoicesDesc = 'canManageInvoices_DESC',
    CanManageMarketItemPricesAsc = 'canManageMarketItemPrices_ASC',
    CanManageMarketItemPricesDesc = 'canManageMarketItemPrices_DESC',
    CanManageMarketItemsAsc = 'canManageMarketItems_ASC',
    CanManageMarketItemsDesc = 'canManageMarketItems_DESC',
    CanManageMarketPriceScopesAsc = 'canManageMarketPriceScopes_ASC',
    CanManageMarketPriceScopesDesc = 'canManageMarketPriceScopes_DESC',
    CanManageMarketSettingAsc = 'canManageMarketSetting_ASC',
    CanManageMarketSettingDesc = 'canManageMarketSetting_DESC',
    CanManageMarketplaceAsc = 'canManageMarketplace_ASC',
    CanManageMarketplaceDesc = 'canManageMarketplace_DESC',
    CanManageMeterReadingsAsc = 'canManageMeterReadings_ASC',
    CanManageMeterReadingsDesc = 'canManageMeterReadings_DESC',
    CanManageMetersAsc = 'canManageMeters_ASC',
    CanManageMetersDesc = 'canManageMeters_DESC',
    CanManageMobileFeatureConfigsAsc = 'canManageMobileFeatureConfigs_ASC',
    CanManageMobileFeatureConfigsDesc = 'canManageMobileFeatureConfigs_DESC',
    CanManageNewsItemTemplatesAsc = 'canManageNewsItemTemplates_ASC',
    CanManageNewsItemTemplatesDesc = 'canManageNewsItemTemplates_DESC',
    CanManageNewsItemsAsc = 'canManageNewsItems_ASC',
    CanManageNewsItemsDesc = 'canManageNewsItems_DESC',
    CanManageOrganizationAsc = 'canManageOrganization_ASC',
    CanManageOrganizationDesc = 'canManageOrganization_DESC',
    CanManagePropertiesAsc = 'canManageProperties_ASC',
    CanManagePropertiesDesc = 'canManageProperties_DESC',
    CanManagePropertyScopesAsc = 'canManagePropertyScopes_ASC',
    CanManagePropertyScopesDesc = 'canManagePropertyScopes_DESC',
    CanManageRolesAsc = 'canManageRoles_ASC',
    CanManageRolesDesc = 'canManageRoles_DESC',
    CanManageTicketAutoAssignmentsAsc = 'canManageTicketAutoAssignments_ASC',
    CanManageTicketAutoAssignmentsDesc = 'canManageTicketAutoAssignments_DESC',
    CanManageTicketCommentsAsc = 'canManageTicketComments_ASC',
    CanManageTicketCommentsDesc = 'canManageTicketComments_DESC',
    CanManageTicketPropertyHintsAsc = 'canManageTicketPropertyHints_ASC',
    CanManageTicketPropertyHintsDesc = 'canManageTicketPropertyHints_DESC',
    CanManageTicketsAsc = 'canManageTickets_ASC',
    CanManageTicketsDesc = 'canManageTickets_DESC',
    CanManageTourAsc = 'canManageTour_ASC',
    CanManageTourDesc = 'canManageTour_DESC',
    CanReadAnalyticsAsc = 'canReadAnalytics_ASC',
    CanReadAnalyticsDesc = 'canReadAnalytics_DESC',
    CanReadBillingReceiptsAsc = 'canReadBillingReceipts_ASC',
    CanReadBillingReceiptsDesc = 'canReadBillingReceipts_DESC',
    CanReadCallRecordsAsc = 'canReadCallRecords_ASC',
    CanReadCallRecordsDesc = 'canReadCallRecords_DESC',
    CanReadContactsAsc = 'canReadContacts_ASC',
    CanReadContactsDesc = 'canReadContacts_DESC',
    CanReadDocumentsAsc = 'canReadDocuments_ASC',
    CanReadDocumentsDesc = 'canReadDocuments_DESC',
    CanReadEmployeesAsc = 'canReadEmployees_ASC',
    CanReadEmployeesDesc = 'canReadEmployees_DESC',
    CanReadExternalReportsAsc = 'canReadExternalReports_ASC',
    CanReadExternalReportsDesc = 'canReadExternalReports_DESC',
    CanReadIncidentsAsc = 'canReadIncidents_ASC',
    CanReadIncidentsDesc = 'canReadIncidents_DESC',
    CanReadInvoicesAsc = 'canReadInvoices_ASC',
    CanReadInvoicesDesc = 'canReadInvoices_DESC',
    CanReadMarketItemPricesAsc = 'canReadMarketItemPrices_ASC',
    CanReadMarketItemPricesDesc = 'canReadMarketItemPrices_DESC',
    CanReadMarketItemsAsc = 'canReadMarketItems_ASC',
    CanReadMarketItemsDesc = 'canReadMarketItems_DESC',
    CanReadMarketPriceScopesAsc = 'canReadMarketPriceScopes_ASC',
    CanReadMarketPriceScopesDesc = 'canReadMarketPriceScopes_DESC',
    CanReadMarketSettingAsc = 'canReadMarketSetting_ASC',
    CanReadMarketSettingDesc = 'canReadMarketSetting_DESC',
    CanReadMarketplaceAsc = 'canReadMarketplace_ASC',
    CanReadMarketplaceDesc = 'canReadMarketplace_DESC',
    CanReadMetersAsc = 'canReadMeters_ASC',
    CanReadMetersDesc = 'canReadMeters_DESC',
    CanReadNewsItemsAsc = 'canReadNewsItems_ASC',
    CanReadNewsItemsDesc = 'canReadNewsItems_DESC',
    CanReadPaymentsWithInvoicesAsc = 'canReadPaymentsWithInvoices_ASC',
    CanReadPaymentsWithInvoicesDesc = 'canReadPaymentsWithInvoices_DESC',
    CanReadPaymentsAsc = 'canReadPayments_ASC',
    CanReadPaymentsDesc = 'canReadPayments_DESC',
    CanReadPropertiesAsc = 'canReadProperties_ASC',
    CanReadPropertiesDesc = 'canReadProperties_DESC',
    CanReadServicesAsc = 'canReadServices_ASC',
    CanReadServicesDesc = 'canReadServices_DESC',
    CanReadSettingsAsc = 'canReadSettings_ASC',
    CanReadSettingsDesc = 'canReadSettings_DESC',
    CanReadTicketsAsc = 'canReadTickets_ASC',
    CanReadTicketsDesc = 'canReadTickets_DESC',
    CanReadTourAsc = 'canReadTour_ASC',
    CanReadTourDesc = 'canReadTour_DESC',
    CanShareTicketsAsc = 'canShareTickets_ASC',
    CanShareTicketsDesc = 'canShareTickets_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DescriptionAsc = 'description_ASC',
    DescriptionDesc = 'description_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IsDefaultAsc = 'isDefault_ASC',
    IsDefaultDesc = 'isDefault_DESC',
    IsEditableAsc = 'isEditable_ASC',
    IsEditableDesc = 'isEditable_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    TicketVisibilityTypeAsc = 'ticketVisibilityType_ASC',
    TicketVisibilityTypeDesc = 'ticketVisibilityType_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortOrganizationEmployeeSpecializationHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortOrganizationEmployeeSpecializationsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    EmployeeAsc = 'employee_ASC',
    EmployeeDesc = 'employee_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    SpecializationAsc = 'specialization_ASC',
    SpecializationDesc = 'specialization_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortOrganizationEmployeesBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    EmailAsc = 'email_ASC',
    EmailDesc = 'email_DESC',
    HasAllSpecializationsAsc = 'hasAllSpecializations_ASC',
    HasAllSpecializationsDesc = 'hasAllSpecializations_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    InviteCodeAsc = 'inviteCode_ASC',
    InviteCodeDesc = 'inviteCode_DESC',
    IsAcceptedAsc = 'isAccepted_ASC',
    IsAcceptedDesc = 'isAccepted_DESC',
    IsBlockedAsc = 'isBlocked_ASC',
    IsBlockedDesc = 'isBlocked_DESC',
    IsRejectedAsc = 'isRejected_ASC',
    IsRejectedDesc = 'isRejected_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    PhoneAsc = 'phone_ASC',
    PhoneDesc = 'phone_DESC',
    PositionAsc = 'position_ASC',
    PositionDesc = 'position_DESC',
    RoleAsc = 'role_ASC',
    RoleDesc = 'role_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    UserAsc = 'user_ASC',
    UserDesc = 'user_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortOrganizationHistoryRecordsBy {
    CountryAsc = 'country_ASC',
    CountryDesc = 'country_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    ImportIdAsc = 'importId_ASC',
    ImportIdDesc = 'importId_DESC',
    ImportRemoteSystemAsc = 'importRemoteSystem_ASC',
    ImportRemoteSystemDesc = 'importRemoteSystem_DESC',
    IsApprovedAsc = 'isApproved_ASC',
    IsApprovedDesc = 'isApproved_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    PhoneNumberPrefixAsc = 'phoneNumberPrefix_ASC',
    PhoneNumberPrefixDesc = 'phoneNumberPrefix_DESC',
    PhoneAsc = 'phone_ASC',
    PhoneDesc = 'phone_DESC',
    TinAsc = 'tin_ASC',
    TinDesc = 'tin_DESC',
    TypeAsc = 'type_ASC',
    TypeDesc = 'type_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortOrganizationLinkHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortOrganizationLinksBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    FromAsc = 'from_ASC',
    FromDesc = 'from_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    ToAsc = 'to_ASC',
    ToDesc = 'to_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortOrganizationsBy {
    CountryAsc = 'country_ASC',
    CountryDesc = 'country_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DescriptionAsc = 'description_ASC',
    DescriptionDesc = 'description_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    EmployeesAsc = 'employees_ASC',
    EmployeesDesc = 'employees_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    ImportIdAsc = 'importId_ASC',
    ImportIdDesc = 'importId_DESC',
    ImportRemoteSystemAsc = 'importRemoteSystem_ASC',
    ImportRemoteSystemDesc = 'importRemoteSystem_DESC',
    IsApprovedAsc = 'isApproved_ASC',
    IsApprovedDesc = 'isApproved_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    PhoneNumberPrefixAsc = 'phoneNumberPrefix_ASC',
    PhoneNumberPrefixDesc = 'phoneNumberPrefix_DESC',
    PhoneAsc = 'phone_ASC',
    PhoneDesc = 'phone_DESC',
    RelatedOrganizationsAsc = 'relatedOrganizations_ASC',
    RelatedOrganizationsDesc = 'relatedOrganizations_DESC',
    TinAsc = 'tin_ASC',
    TinDesc = 'tin_DESC',
    TypeAsc = 'type_ASC',
    TypeDesc = 'type_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortPaymentHistoryRecordsBy {
    AccountNumberAsc = 'accountNumber_ASC',
    AccountNumberDesc = 'accountNumber_DESC',
    AdvancedAtAsc = 'advancedAt_ASC',
    AdvancedAtDesc = 'advancedAt_DESC',
    AmountAsc = 'amount_ASC',
    AmountDesc = 'amount_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CurrencyCodeAsc = 'currencyCode_ASC',
    CurrencyCodeDesc = 'currencyCode_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DepositedDateAsc = 'depositedDate_ASC',
    DepositedDateDesc = 'depositedDate_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    ExplicitFeeAsc = 'explicitFee_ASC',
    ExplicitFeeDesc = 'explicitFee_DESC',
    ExplicitServiceChargeAsc = 'explicitServiceCharge_ASC',
    ExplicitServiceChargeDesc = 'explicitServiceCharge_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    ImplicitFeeAsc = 'implicitFee_ASC',
    ImplicitFeeDesc = 'implicitFee_DESC',
    ImportIdAsc = 'importId_ASC',
    ImportIdDesc = 'importId_DESC',
    OrderAsc = 'order_ASC',
    OrderDesc = 'order_DESC',
    PeriodAsc = 'period_ASC',
    PeriodDesc = 'period_DESC',
    PurposeAsc = 'purpose_ASC',
    PurposeDesc = 'purpose_DESC',
    RawAddressAsc = 'rawAddress_ASC',
    RawAddressDesc = 'rawAddress_DESC',
    RecipientBankAccountAsc = 'recipientBankAccount_ASC',
    RecipientBankAccountDesc = 'recipientBankAccount_DESC',
    RecipientBicAsc = 'recipientBic_ASC',
    RecipientBicDesc = 'recipientBic_DESC',
    ServiceFeeAsc = 'serviceFee_ASC',
    ServiceFeeDesc = 'serviceFee_DESC',
    StatusAsc = 'status_ASC',
    StatusDesc = 'status_DESC',
    TransferDateAsc = 'transferDate_ASC',
    TransferDateDesc = 'transferDate_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortPaymentsBy {
    AccountNumberAsc = 'accountNumber_ASC',
    AccountNumberDesc = 'accountNumber_DESC',
    AdvancedAtAsc = 'advancedAt_ASC',
    AdvancedAtDesc = 'advancedAt_DESC',
    AmountAsc = 'amount_ASC',
    AmountDesc = 'amount_DESC',
    ContextAsc = 'context_ASC',
    ContextDesc = 'context_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    CurrencyCodeAsc = 'currencyCode_ASC',
    CurrencyCodeDesc = 'currencyCode_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DepositedDateAsc = 'depositedDate_ASC',
    DepositedDateDesc = 'depositedDate_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    ExplicitFeeAsc = 'explicitFee_ASC',
    ExplicitFeeDesc = 'explicitFee_DESC',
    ExplicitServiceChargeAsc = 'explicitServiceCharge_ASC',
    ExplicitServiceChargeDesc = 'explicitServiceCharge_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    ImplicitFeeAsc = 'implicitFee_ASC',
    ImplicitFeeDesc = 'implicitFee_DESC',
    ImportIdAsc = 'importId_ASC',
    ImportIdDesc = 'importId_DESC',
    InvoiceAsc = 'invoice_ASC',
    InvoiceDesc = 'invoice_DESC',
    MultiPaymentAsc = 'multiPayment_ASC',
    MultiPaymentDesc = 'multiPayment_DESC',
    OrderAsc = 'order_ASC',
    OrderDesc = 'order_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    PeriodAsc = 'period_ASC',
    PeriodDesc = 'period_DESC',
    PurposeAsc = 'purpose_ASC',
    PurposeDesc = 'purpose_DESC',
    RawAddressAsc = 'rawAddress_ASC',
    RawAddressDesc = 'rawAddress_DESC',
    ReceiptAsc = 'receipt_ASC',
    ReceiptDesc = 'receipt_DESC',
    RecipientBankAccountAsc = 'recipientBankAccount_ASC',
    RecipientBankAccountDesc = 'recipientBankAccount_DESC',
    RecipientBicAsc = 'recipientBic_ASC',
    RecipientBicDesc = 'recipientBic_DESC',
    ServiceFeeAsc = 'serviceFee_ASC',
    ServiceFeeDesc = 'serviceFee_DESC',
    StatusAsc = 'status_ASC',
    StatusDesc = 'status_DESC',
    TransferDateAsc = 'transferDate_ASC',
    TransferDateDesc = 'transferDate_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortPaymentsFilterTemplateHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortPaymentsFilterTemplatesBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    EmployeeAsc = 'employee_ASC',
    EmployeeDesc = 'employee_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortPropertiesBy {
    AddressKeyAsc = 'addressKey_ASC',
    AddressKeyDesc = 'addressKey_DESC',
    AddressAsc = 'address_ASC',
    AddressDesc = 'address_DESC',
    AreaAsc = 'area_ASC',
    AreaDesc = 'area_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IsApprovedAsc = 'isApproved_ASC',
    IsApprovedDesc = 'isApproved_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    TypeAsc = 'type_ASC',
    TypeDesc = 'type_DESC',
    UninhabitedUnitsCountAsc = 'uninhabitedUnitsCount_ASC',
    UninhabitedUnitsCountDesc = 'uninhabitedUnitsCount_DESC',
    UnitsCountAsc = 'unitsCount_ASC',
    UnitsCountDesc = 'unitsCount_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
    YearOfConstructionAsc = 'yearOfConstruction_ASC',
    YearOfConstructionDesc = 'yearOfConstruction_DESC',
}

export enum SortPropertyHistoryRecordsBy {
    AddressKeyAsc = 'addressKey_ASC',
    AddressKeyDesc = 'addressKey_DESC',
    AddressAsc = 'address_ASC',
    AddressDesc = 'address_DESC',
    AreaAsc = 'area_ASC',
    AreaDesc = 'area_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IsApprovedAsc = 'isApproved_ASC',
    IsApprovedDesc = 'isApproved_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    TypeAsc = 'type_ASC',
    TypeDesc = 'type_DESC',
    UninhabitedUnitsCountAsc = 'uninhabitedUnitsCount_ASC',
    UninhabitedUnitsCountDesc = 'uninhabitedUnitsCount_DESC',
    UnitsCountAsc = 'unitsCount_ASC',
    UnitsCountDesc = 'unitsCount_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
    YearOfConstructionAsc = 'yearOfConstruction_ASC',
    YearOfConstructionDesc = 'yearOfConstruction_DESC',
}

export enum SortPropertyMeterHistoryRecordsBy {
    ArchiveDateAsc = 'archiveDate_ASC',
    ArchiveDateDesc = 'archiveDate_DESC',
    CommissioningDateAsc = 'commissioningDate_ASC',
    CommissioningDateDesc = 'commissioningDate_DESC',
    ControlReadingsDateAsc = 'controlReadingsDate_ASC',
    ControlReadingsDateDesc = 'controlReadingsDate_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    InstallationDateAsc = 'installationDate_ASC',
    InstallationDateDesc = 'installationDate_DESC',
    IsAutomaticAsc = 'isAutomatic_ASC',
    IsAutomaticDesc = 'isAutomatic_DESC',
    NextVerificationDateAsc = 'nextVerificationDate_ASC',
    NextVerificationDateDesc = 'nextVerificationDate_DESC',
    NumberOfTariffsAsc = 'numberOfTariffs_ASC',
    NumberOfTariffsDesc = 'numberOfTariffs_DESC',
    NumberAsc = 'number_ASC',
    NumberDesc = 'number_DESC',
    SealingDateAsc = 'sealingDate_ASC',
    SealingDateDesc = 'sealingDate_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
    VerificationDateAsc = 'verificationDate_ASC',
    VerificationDateDesc = 'verificationDate_DESC',
}

export enum SortPropertyMeterReadingHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DateAsc = 'date_ASC',
    DateDesc = 'date_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
    Value1Asc = 'value1_ASC',
    Value1Desc = 'value1_DESC',
    Value2Asc = 'value2_ASC',
    Value2Desc = 'value2_DESC',
    Value3Asc = 'value3_ASC',
    Value3Desc = 'value3_DESC',
    Value4Asc = 'value4_ASC',
    Value4Desc = 'value4_DESC',
}

export enum SortPropertyMeterReadingsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DateAsc = 'date_ASC',
    DateDesc = 'date_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    MeterAsc = 'meter_ASC',
    MeterDesc = 'meter_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    SourceAsc = 'source_ASC',
    SourceDesc = 'source_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
    Value1Asc = 'value1_ASC',
    Value1Desc = 'value1_DESC',
    Value2Asc = 'value2_ASC',
    Value2Desc = 'value2_DESC',
    Value3Asc = 'value3_ASC',
    Value3Desc = 'value3_DESC',
    Value4Asc = 'value4_ASC',
    Value4Desc = 'value4_DESC',
}

export enum SortPropertyMetersBy {
    ArchiveDateAsc = 'archiveDate_ASC',
    ArchiveDateDesc = 'archiveDate_DESC',
    B2bAppAsc = 'b2bApp_ASC',
    B2bAppDesc = 'b2bApp_DESC',
    CommissioningDateAsc = 'commissioningDate_ASC',
    CommissioningDateDesc = 'commissioningDate_DESC',
    ControlReadingsDateAsc = 'controlReadingsDate_ASC',
    ControlReadingsDateDesc = 'controlReadingsDate_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    InstallationDateAsc = 'installationDate_ASC',
    InstallationDateDesc = 'installationDate_DESC',
    IsAutomaticAsc = 'isAutomatic_ASC',
    IsAutomaticDesc = 'isAutomatic_DESC',
    NextVerificationDateAsc = 'nextVerificationDate_ASC',
    NextVerificationDateDesc = 'nextVerificationDate_DESC',
    NumberOfTariffsAsc = 'numberOfTariffs_ASC',
    NumberOfTariffsDesc = 'numberOfTariffs_DESC',
    NumberAsc = 'number_ASC',
    NumberDesc = 'number_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    PropertyAsc = 'property_ASC',
    PropertyDesc = 'property_DESC',
    ResourceAsc = 'resource_ASC',
    ResourceDesc = 'resource_DESC',
    SealingDateAsc = 'sealingDate_ASC',
    SealingDateDesc = 'sealingDate_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
    VerificationDateAsc = 'verificationDate_ASC',
    VerificationDateDesc = 'verificationDate_DESC',
}

export enum SortPropertyScopeHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HasAllEmployeesAsc = 'hasAllEmployees_ASC',
    HasAllEmployeesDesc = 'hasAllEmployees_DESC',
    HasAllPropertiesAsc = 'hasAllProperties_ASC',
    HasAllPropertiesDesc = 'hasAllProperties_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortPropertyScopeOrganizationEmployeeHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortPropertyScopeOrganizationEmployeesBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    EmployeeAsc = 'employee_ASC',
    EmployeeDesc = 'employee_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    PropertyScopeAsc = 'propertyScope_ASC',
    PropertyScopeDesc = 'propertyScope_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortPropertyScopePropertiesBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    PropertyScopeAsc = 'propertyScope_ASC',
    PropertyScopeDesc = 'propertyScope_DESC',
    PropertyAsc = 'property_ASC',
    PropertyDesc = 'property_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortPropertyScopePropertyHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortPropertyScopesBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HasAllEmployeesAsc = 'hasAllEmployees_ASC',
    HasAllEmployeesDesc = 'hasAllEmployees_DESC',
    HasAllPropertiesAsc = 'hasAllProperties_ASC',
    HasAllPropertiesDesc = 'hasAllProperties_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortRecurrentPaymentContextHistoryRecordsBy {
    AutoPayReceiptsAsc = 'autoPayReceipts_ASC',
    AutoPayReceiptsDesc = 'autoPayReceipts_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    EnabledAsc = 'enabled_ASC',
    EnabledDesc = 'enabled_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    LimitAsc = 'limit_ASC',
    LimitDesc = 'limit_DESC',
    PaymentDayAsc = 'paymentDay_ASC',
    PaymentDayDesc = 'paymentDay_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortRecurrentPaymentContextsBy {
    AutoPayReceiptsAsc = 'autoPayReceipts_ASC',
    AutoPayReceiptsDesc = 'autoPayReceipts_DESC',
    BillingCategoryAsc = 'billingCategory_ASC',
    BillingCategoryDesc = 'billingCategory_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    EnabledAsc = 'enabled_ASC',
    EnabledDesc = 'enabled_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    LimitAsc = 'limit_ASC',
    LimitDesc = 'limit_DESC',
    PaymentDayAsc = 'paymentDay_ASC',
    PaymentDayDesc = 'paymentDay_DESC',
    ServiceConsumerAsc = 'serviceConsumer_ASC',
    ServiceConsumerDesc = 'serviceConsumer_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortRecurrentPaymentHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    PayAfterAsc = 'payAfter_ASC',
    PayAfterDesc = 'payAfter_DESC',
    StatusAsc = 'status_ASC',
    StatusDesc = 'status_DESC',
    TryCountAsc = 'tryCount_ASC',
    TryCountDesc = 'tryCount_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortRecurrentPaymentsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    PayAfterAsc = 'payAfter_ASC',
    PayAfterDesc = 'payAfter_DESC',
    RecurrentPaymentContextAsc = 'recurrentPaymentContext_ASC',
    RecurrentPaymentContextDesc = 'recurrentPaymentContext_DESC',
    StatusAsc = 'status_ASC',
    StatusDesc = 'status_DESC',
    TryCountAsc = 'tryCount_ASC',
    TryCountDesc = 'tryCount_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortRemoteClientHistoryRecordsBy {
    AppIdAsc = 'appId_ASC',
    AppIdDesc = 'appId_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DeviceIdAsc = 'deviceId_ASC',
    DeviceIdDesc = 'deviceId_DESC',
    DevicePlatformAsc = 'devicePlatform_ASC',
    DevicePlatformDesc = 'devicePlatform_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    PushTokenVoIpAsc = 'pushTokenVoIP_ASC',
    PushTokenVoIpDesc = 'pushTokenVoIP_DESC',
    PushTokenAsc = 'pushToken_ASC',
    PushTokenDesc = 'pushToken_DESC',
    PushTransportVoIpAsc = 'pushTransportVoIP_ASC',
    PushTransportVoIpDesc = 'pushTransportVoIP_DESC',
    PushTransportAsc = 'pushTransport_ASC',
    PushTransportDesc = 'pushTransport_DESC',
    PushTypeVoIpAsc = 'pushTypeVoIP_ASC',
    PushTypeVoIpDesc = 'pushTypeVoIP_DESC',
    PushTypeAsc = 'pushType_ASC',
    PushTypeDesc = 'pushType_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortRemoteClientsBy {
    AppIdAsc = 'appId_ASC',
    AppIdDesc = 'appId_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DeviceIdAsc = 'deviceId_ASC',
    DeviceIdDesc = 'deviceId_DESC',
    DevicePlatformAsc = 'devicePlatform_ASC',
    DevicePlatformDesc = 'devicePlatform_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    OwnerAsc = 'owner_ASC',
    OwnerDesc = 'owner_DESC',
    PushTokenVoIpAsc = 'pushTokenVoIP_ASC',
    PushTokenVoIpDesc = 'pushTokenVoIP_DESC',
    PushTokenAsc = 'pushToken_ASC',
    PushTokenDesc = 'pushToken_DESC',
    PushTransportVoIpAsc = 'pushTransportVoIP_ASC',
    PushTransportVoIpDesc = 'pushTransportVoIP_DESC',
    PushTransportAsc = 'pushTransport_ASC',
    PushTransportDesc = 'pushTransport_DESC',
    PushTypeVoIpAsc = 'pushTypeVoIP_ASC',
    PushTypeVoIpDesc = 'pushTypeVoIP_DESC',
    PushTypeAsc = 'pushType_ASC',
    PushTypeDesc = 'pushType_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortResidentBillingReceiptsBy {
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    PeriodAsc = 'period_ASC',
    PeriodDesc = 'period_DESC',
    PrintableNumberAsc = 'printableNumber_ASC',
    PrintableNumberDesc = 'printableNumber_DESC',
    ServiceConsumerAsc = 'serviceConsumer_ASC',
    ServiceConsumerDesc = 'serviceConsumer_DESC',
    ToPayAsc = 'toPay_ASC',
    ToPayDesc = 'toPay_DESC',
}

export enum SortResidentBillingVirtualReceiptsBy {
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    PeriodAsc = 'period_ASC',
    PeriodDesc = 'period_DESC',
    PrintableNumberAsc = 'printableNumber_ASC',
    PrintableNumberDesc = 'printableNumber_DESC',
    ServiceConsumerAsc = 'serviceConsumer_ASC',
    ServiceConsumerDesc = 'serviceConsumer_DESC',
    ToPayAsc = 'toPay_ASC',
    ToPayDesc = 'toPay_DESC',
}

export enum SortResidentHistoryRecordsBy {
    AddressKeyAsc = 'addressKey_ASC',
    AddressKeyDesc = 'addressKey_DESC',
    AddressAsc = 'address_ASC',
    AddressDesc = 'address_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    UnitNameAsc = 'unitName_ASC',
    UnitNameDesc = 'unitName_DESC',
    UnitTypeAsc = 'unitType_ASC',
    UnitTypeDesc = 'unitType_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortResidentsBy {
    AddressKeyAsc = 'addressKey_ASC',
    AddressKeyDesc = 'addressKey_DESC',
    AddressAsc = 'address_ASC',
    AddressDesc = 'address_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    PropertyAsc = 'property_ASC',
    PropertyDesc = 'property_DESC',
    UnitNameAsc = 'unitName_ASC',
    UnitNameDesc = 'unitName_DESC',
    UnitTypeAsc = 'unitType_ASC',
    UnitTypeDesc = 'unitType_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    UserAsc = 'user_ASC',
    UserDesc = 'user_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortServiceConsumerHistoryRecordsBy {
    AccountNumberAsc = 'accountNumber_ASC',
    AccountNumberDesc = 'accountNumber_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IsDiscoveredAsc = 'isDiscovered_ASC',
    IsDiscoveredDesc = 'isDiscovered_DESC',
    PaymentCategoryAsc = 'paymentCategory_ASC',
    PaymentCategoryDesc = 'paymentCategory_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortServiceConsumersBy {
    AccountNumberAsc = 'accountNumber_ASC',
    AccountNumberDesc = 'accountNumber_DESC',
    AcquiringIntegrationContextAsc = 'acquiringIntegrationContext_ASC',
    AcquiringIntegrationContextDesc = 'acquiringIntegrationContext_DESC',
    BillingIntegrationContextAsc = 'billingIntegrationContext_ASC',
    BillingIntegrationContextDesc = 'billingIntegrationContext_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IsDiscoveredAsc = 'isDiscovered_ASC',
    IsDiscoveredDesc = 'isDiscovered_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    PaymentCategoryAsc = 'paymentCategory_ASC',
    PaymentCategoryDesc = 'paymentCategory_DESC',
    ResidentAsc = 'resident_ASC',
    ResidentDesc = 'resident_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortServiceSubscriptionHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CurrencyAsc = 'currency_ASC',
    CurrencyDesc = 'currency_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    FinishAtAsc = 'finishAt_ASC',
    FinishAtDesc = 'finishAt_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IsTrialAsc = 'isTrial_ASC',
    IsTrialDesc = 'isTrial_DESC',
    StartAtAsc = 'startAt_ASC',
    StartAtDesc = 'startAt_DESC',
    TotalPriceAsc = 'totalPrice_ASC',
    TotalPriceDesc = 'totalPrice_DESC',
    TypeAsc = 'type_ASC',
    TypeDesc = 'type_DESC',
    UnitPriceAsc = 'unitPrice_ASC',
    UnitPriceDesc = 'unitPrice_DESC',
    UnitsCountAsc = 'unitsCount_ASC',
    UnitsCountDesc = 'unitsCount_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortServiceSubscriptionsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    CurrencyAsc = 'currency_ASC',
    CurrencyDesc = 'currency_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    FinishAtAsc = 'finishAt_ASC',
    FinishAtDesc = 'finishAt_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IsTrialAsc = 'isTrial_ASC',
    IsTrialDesc = 'isTrial_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    StartAtAsc = 'startAt_ASC',
    StartAtDesc = 'startAt_DESC',
    TotalPriceAsc = 'totalPrice_ASC',
    TotalPriceDesc = 'totalPrice_DESC',
    TypeAsc = 'type_ASC',
    TypeDesc = 'type_DESC',
    UnitPriceAsc = 'unitPrice_ASC',
    UnitPriceDesc = 'unitPrice_DESC',
    UnitsCountAsc = 'unitsCount_ASC',
    UnitsCountDesc = 'unitsCount_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortTelegramUserChatHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    TelegramChatIdAsc = 'telegramChatId_ASC',
    TelegramChatIdDesc = 'telegramChatId_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortTelegramUserChatsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    TelegramChatIdAsc = 'telegramChatId_ASC',
    TelegramChatIdDesc = 'telegramChatId_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    UserAsc = 'user_ASC',
    UserDesc = 'user_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortTicketAutoAssignmentHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortTicketAutoAssignmentsBy {
    AssigneeAsc = 'assignee_ASC',
    AssigneeDesc = 'assignee_DESC',
    ClassifierAsc = 'classifier_ASC',
    ClassifierDesc = 'classifier_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    ExecutorAsc = 'executor_ASC',
    ExecutorDesc = 'executor_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortTicketCategoryClassifierHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortTicketCategoryClassifiersBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortTicketChangesBy {
    ActualCreationDateAsc = 'actualCreationDate_ASC',
    ActualCreationDateDesc = 'actualCreationDate_DESC',
    AssigneeDisplayNameFromAsc = 'assigneeDisplayNameFrom_ASC',
    AssigneeDisplayNameFromDesc = 'assigneeDisplayNameFrom_DESC',
    AssigneeDisplayNameToAsc = 'assigneeDisplayNameTo_ASC',
    AssigneeDisplayNameToDesc = 'assigneeDisplayNameTo_DESC',
    AssigneeIdFromAsc = 'assigneeIdFrom_ASC',
    AssigneeIdFromDesc = 'assigneeIdFrom_DESC',
    AssigneeIdToAsc = 'assigneeIdTo_ASC',
    AssigneeIdToDesc = 'assigneeIdTo_DESC',
    CanReadByResidentFromAsc = 'canReadByResidentFrom_ASC',
    CanReadByResidentFromDesc = 'canReadByResidentFrom_DESC',
    CanReadByResidentToAsc = 'canReadByResidentTo_ASC',
    CanReadByResidentToDesc = 'canReadByResidentTo_DESC',
    ClassifierDisplayNameFromAsc = 'classifierDisplayNameFrom_ASC',
    ClassifierDisplayNameFromDesc = 'classifierDisplayNameFrom_DESC',
    ClassifierDisplayNameToAsc = 'classifierDisplayNameTo_ASC',
    ClassifierDisplayNameToDesc = 'classifierDisplayNameTo_DESC',
    ClassifierIdFromAsc = 'classifierIdFrom_ASC',
    ClassifierIdFromDesc = 'classifierIdFrom_DESC',
    ClassifierIdToAsc = 'classifierIdTo_ASC',
    ClassifierIdToDesc = 'classifierIdTo_DESC',
    ClientDisplayNameFromAsc = 'clientDisplayNameFrom_ASC',
    ClientDisplayNameFromDesc = 'clientDisplayNameFrom_DESC',
    ClientDisplayNameToAsc = 'clientDisplayNameTo_ASC',
    ClientDisplayNameToDesc = 'clientDisplayNameTo_DESC',
    ClientEmailFromAsc = 'clientEmailFrom_ASC',
    ClientEmailFromDesc = 'clientEmailFrom_DESC',
    ClientEmailToAsc = 'clientEmailTo_ASC',
    ClientEmailToDesc = 'clientEmailTo_DESC',
    ClientIdFromAsc = 'clientIdFrom_ASC',
    ClientIdFromDesc = 'clientIdFrom_DESC',
    ClientIdToAsc = 'clientIdTo_ASC',
    ClientIdToDesc = 'clientIdTo_DESC',
    ClientNameFromAsc = 'clientNameFrom_ASC',
    ClientNameFromDesc = 'clientNameFrom_DESC',
    ClientNameToAsc = 'clientNameTo_ASC',
    ClientNameToDesc = 'clientNameTo_DESC',
    ClientPhoneFromAsc = 'clientPhoneFrom_ASC',
    ClientPhoneFromDesc = 'clientPhoneFrom_DESC',
    ClientPhoneToAsc = 'clientPhoneTo_ASC',
    ClientPhoneToDesc = 'clientPhoneTo_DESC',
    ContactDisplayNameFromAsc = 'contactDisplayNameFrom_ASC',
    ContactDisplayNameFromDesc = 'contactDisplayNameFrom_DESC',
    ContactDisplayNameToAsc = 'contactDisplayNameTo_ASC',
    ContactDisplayNameToDesc = 'contactDisplayNameTo_DESC',
    ContactIdFromAsc = 'contactIdFrom_ASC',
    ContactIdFromDesc = 'contactIdFrom_DESC',
    ContactIdToAsc = 'contactIdTo_ASC',
    ContactIdToDesc = 'contactIdTo_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeadlineFromAsc = 'deadlineFrom_ASC',
    DeadlineFromDesc = 'deadlineFrom_DESC',
    DeadlineToAsc = 'deadlineTo_ASC',
    DeadlineToDesc = 'deadlineTo_DESC',
    DeferredUntilFromAsc = 'deferredUntilFrom_ASC',
    DeferredUntilFromDesc = 'deferredUntilFrom_DESC',
    DeferredUntilToAsc = 'deferredUntilTo_ASC',
    DeferredUntilToDesc = 'deferredUntilTo_DESC',
    DetailsFromAsc = 'detailsFrom_ASC',
    DetailsFromDesc = 'detailsFrom_DESC',
    DetailsToAsc = 'detailsTo_ASC',
    DetailsToDesc = 'detailsTo_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    ExecutorDisplayNameFromAsc = 'executorDisplayNameFrom_ASC',
    ExecutorDisplayNameFromDesc = 'executorDisplayNameFrom_DESC',
    ExecutorDisplayNameToAsc = 'executorDisplayNameTo_ASC',
    ExecutorDisplayNameToDesc = 'executorDisplayNameTo_DESC',
    ExecutorIdFromAsc = 'executorIdFrom_ASC',
    ExecutorIdFromDesc = 'executorIdFrom_DESC',
    ExecutorIdToAsc = 'executorIdTo_ASC',
    ExecutorIdToDesc = 'executorIdTo_DESC',
    FeedbackCommentFromAsc = 'feedbackCommentFrom_ASC',
    FeedbackCommentFromDesc = 'feedbackCommentFrom_DESC',
    FeedbackCommentToAsc = 'feedbackCommentTo_ASC',
    FeedbackCommentToDesc = 'feedbackCommentTo_DESC',
    FeedbackValueFromAsc = 'feedbackValueFrom_ASC',
    FeedbackValueFromDesc = 'feedbackValueFrom_DESC',
    FeedbackValueToAsc = 'feedbackValueTo_ASC',
    FeedbackValueToDesc = 'feedbackValueTo_DESC',
    FloorNameFromAsc = 'floorNameFrom_ASC',
    FloorNameFromDesc = 'floorNameFrom_DESC',
    FloorNameToAsc = 'floorNameTo_ASC',
    FloorNameToDesc = 'floorNameTo_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IsEmergencyFromAsc = 'isEmergencyFrom_ASC',
    IsEmergencyFromDesc = 'isEmergencyFrom_DESC',
    IsEmergencyToAsc = 'isEmergencyTo_ASC',
    IsEmergencyToDesc = 'isEmergencyTo_DESC',
    IsPaidFromAsc = 'isPaidFrom_ASC',
    IsPaidFromDesc = 'isPaidFrom_DESC',
    IsPaidToAsc = 'isPaidTo_ASC',
    IsPaidToDesc = 'isPaidTo_DESC',
    IsPayableFromAsc = 'isPayableFrom_ASC',
    IsPayableFromDesc = 'isPayableFrom_DESC',
    IsPayableToAsc = 'isPayableTo_ASC',
    IsPayableToDesc = 'isPayableTo_DESC',
    IsResidentTicketFromAsc = 'isResidentTicketFrom_ASC',
    IsResidentTicketFromDesc = 'isResidentTicketFrom_DESC',
    IsResidentTicketToAsc = 'isResidentTicketTo_ASC',
    IsResidentTicketToDesc = 'isResidentTicketTo_DESC',
    IsWarrantyFromAsc = 'isWarrantyFrom_ASC',
    IsWarrantyFromDesc = 'isWarrantyFrom_DESC',
    IsWarrantyToAsc = 'isWarrantyTo_ASC',
    IsWarrantyToDesc = 'isWarrantyTo_DESC',
    OrganizationDisplayNameFromAsc = 'organizationDisplayNameFrom_ASC',
    OrganizationDisplayNameFromDesc = 'organizationDisplayNameFrom_DESC',
    OrganizationDisplayNameToAsc = 'organizationDisplayNameTo_ASC',
    OrganizationDisplayNameToDesc = 'organizationDisplayNameTo_DESC',
    OrganizationIdFromAsc = 'organizationIdFrom_ASC',
    OrganizationIdFromDesc = 'organizationIdFrom_DESC',
    OrganizationIdToAsc = 'organizationIdTo_ASC',
    OrganizationIdToDesc = 'organizationIdTo_DESC',
    PropertyAddressFromAsc = 'propertyAddressFrom_ASC',
    PropertyAddressFromDesc = 'propertyAddressFrom_DESC',
    PropertyAddressToAsc = 'propertyAddressTo_ASC',
    PropertyAddressToDesc = 'propertyAddressTo_DESC',
    PropertyDisplayNameFromAsc = 'propertyDisplayNameFrom_ASC',
    PropertyDisplayNameFromDesc = 'propertyDisplayNameFrom_DESC',
    PropertyDisplayNameToAsc = 'propertyDisplayNameTo_ASC',
    PropertyDisplayNameToDesc = 'propertyDisplayNameTo_DESC',
    PropertyIdFromAsc = 'propertyIdFrom_ASC',
    PropertyIdFromDesc = 'propertyIdFrom_DESC',
    PropertyIdToAsc = 'propertyIdTo_ASC',
    PropertyIdToDesc = 'propertyIdTo_DESC',
    QualityControlCommentFromAsc = 'qualityControlCommentFrom_ASC',
    QualityControlCommentFromDesc = 'qualityControlCommentFrom_DESC',
    QualityControlCommentToAsc = 'qualityControlCommentTo_ASC',
    QualityControlCommentToDesc = 'qualityControlCommentTo_DESC',
    QualityControlValueFromAsc = 'qualityControlValueFrom_ASC',
    QualityControlValueFromDesc = 'qualityControlValueFrom_DESC',
    QualityControlValueToAsc = 'qualityControlValueTo_ASC',
    QualityControlValueToDesc = 'qualityControlValueTo_DESC',
    RelatedDisplayNameFromAsc = 'relatedDisplayNameFrom_ASC',
    RelatedDisplayNameFromDesc = 'relatedDisplayNameFrom_DESC',
    RelatedDisplayNameToAsc = 'relatedDisplayNameTo_ASC',
    RelatedDisplayNameToDesc = 'relatedDisplayNameTo_DESC',
    RelatedIdFromAsc = 'relatedIdFrom_ASC',
    RelatedIdFromDesc = 'relatedIdFrom_DESC',
    RelatedIdToAsc = 'relatedIdTo_ASC',
    RelatedIdToDesc = 'relatedIdTo_DESC',
    ReviewCommentFromAsc = 'reviewCommentFrom_ASC',
    ReviewCommentFromDesc = 'reviewCommentFrom_DESC',
    ReviewCommentToAsc = 'reviewCommentTo_ASC',
    ReviewCommentToDesc = 'reviewCommentTo_DESC',
    ReviewValueFromAsc = 'reviewValueFrom_ASC',
    ReviewValueFromDesc = 'reviewValueFrom_DESC',
    ReviewValueToAsc = 'reviewValueTo_ASC',
    ReviewValueToDesc = 'reviewValueTo_DESC',
    SectionNameFromAsc = 'sectionNameFrom_ASC',
    SectionNameFromDesc = 'sectionNameFrom_DESC',
    SectionNameToAsc = 'sectionNameTo_ASC',
    SectionNameToDesc = 'sectionNameTo_DESC',
    SectionTypeFromAsc = 'sectionTypeFrom_ASC',
    SectionTypeFromDesc = 'sectionTypeFrom_DESC',
    SectionTypeToAsc = 'sectionTypeTo_ASC',
    SectionTypeToDesc = 'sectionTypeTo_DESC',
    SourceDisplayNameFromAsc = 'sourceDisplayNameFrom_ASC',
    SourceDisplayNameFromDesc = 'sourceDisplayNameFrom_DESC',
    SourceDisplayNameToAsc = 'sourceDisplayNameTo_ASC',
    SourceDisplayNameToDesc = 'sourceDisplayNameTo_DESC',
    SourceIdFromAsc = 'sourceIdFrom_ASC',
    SourceIdFromDesc = 'sourceIdFrom_DESC',
    SourceIdToAsc = 'sourceIdTo_ASC',
    SourceIdToDesc = 'sourceIdTo_DESC',
    StatusDisplayNameFromAsc = 'statusDisplayNameFrom_ASC',
    StatusDisplayNameFromDesc = 'statusDisplayNameFrom_DESC',
    StatusDisplayNameToAsc = 'statusDisplayNameTo_ASC',
    StatusDisplayNameToDesc = 'statusDisplayNameTo_DESC',
    StatusIdFromAsc = 'statusIdFrom_ASC',
    StatusIdFromDesc = 'statusIdFrom_DESC',
    StatusIdToAsc = 'statusIdTo_ASC',
    StatusIdToDesc = 'statusIdTo_DESC',
    StatusReasonFromAsc = 'statusReasonFrom_ASC',
    StatusReasonFromDesc = 'statusReasonFrom_DESC',
    StatusReasonToAsc = 'statusReasonTo_ASC',
    StatusReasonToDesc = 'statusReasonTo_DESC',
    StatusReopenedCounterFromAsc = 'statusReopenedCounterFrom_ASC',
    StatusReopenedCounterFromDesc = 'statusReopenedCounterFrom_DESC',
    StatusReopenedCounterToAsc = 'statusReopenedCounterTo_ASC',
    StatusReopenedCounterToDesc = 'statusReopenedCounterTo_DESC',
    TicketAsc = 'ticket_ASC',
    TicketDesc = 'ticket_DESC',
    UnitNameFromAsc = 'unitNameFrom_ASC',
    UnitNameFromDesc = 'unitNameFrom_DESC',
    UnitNameToAsc = 'unitNameTo_ASC',
    UnitNameToDesc = 'unitNameTo_DESC',
    UnitTypeFromAsc = 'unitTypeFrom_ASC',
    UnitTypeFromDesc = 'unitTypeFrom_DESC',
    UnitTypeToAsc = 'unitTypeTo_ASC',
    UnitTypeToDesc = 'unitTypeTo_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortTicketClassifierHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortTicketClassifiersBy {
    CategoryAsc = 'category_ASC',
    CategoryDesc = 'category_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    PlaceAsc = 'place_ASC',
    PlaceDesc = 'place_DESC',
    ProblemAsc = 'problem_ASC',
    ProblemDesc = 'problem_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortTicketCommentFileHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortTicketCommentFilesBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    TicketCommentAsc = 'ticketComment_ASC',
    TicketCommentDesc = 'ticketComment_DESC',
    TicketAsc = 'ticket_ASC',
    TicketDesc = 'ticket_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortTicketCommentHistoryRecordsBy {
    ContentAsc = 'content_ASC',
    ContentDesc = 'content_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    TypeAsc = 'type_ASC',
    TypeDesc = 'type_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortTicketCommentsBy {
    ContentAsc = 'content_ASC',
    ContentDesc = 'content_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    TicketAsc = 'ticket_ASC',
    TicketDesc = 'ticket_DESC',
    TypeAsc = 'type_ASC',
    TypeDesc = 'type_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    UserAsc = 'user_ASC',
    UserDesc = 'user_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortTicketDocumentGenerationTaskHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DocumentTypeAsc = 'documentType_ASC',
    DocumentTypeDesc = 'documentType_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    FormatAsc = 'format_ASC',
    FormatDesc = 'format_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    ProgressAsc = 'progress_ASC',
    ProgressDesc = 'progress_DESC',
    StatusAsc = 'status_ASC',
    StatusDesc = 'status_DESC',
    TimeZoneAsc = 'timeZone_ASC',
    TimeZoneDesc = 'timeZone_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortTicketDocumentGenerationTasksBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DocumentTypeAsc = 'documentType_ASC',
    DocumentTypeDesc = 'documentType_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    FormatAsc = 'format_ASC',
    FormatDesc = 'format_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    ProgressAsc = 'progress_ASC',
    ProgressDesc = 'progress_DESC',
    StatusAsc = 'status_ASC',
    StatusDesc = 'status_DESC',
    TicketAsc = 'ticket_ASC',
    TicketDesc = 'ticket_DESC',
    TimeZoneAsc = 'timeZone_ASC',
    TimeZoneDesc = 'timeZone_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    UserAsc = 'user_ASC',
    UserDesc = 'user_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortTicketExportTaskHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    ExportedRecordsCountAsc = 'exportedRecordsCount_ASC',
    ExportedRecordsCountDesc = 'exportedRecordsCount_DESC',
    FormatAsc = 'format_ASC',
    FormatDesc = 'format_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    LocaleAsc = 'locale_ASC',
    LocaleDesc = 'locale_DESC',
    StatusAsc = 'status_ASC',
    StatusDesc = 'status_DESC',
    TimeZoneAsc = 'timeZone_ASC',
    TimeZoneDesc = 'timeZone_DESC',
    TotalRecordsCountAsc = 'totalRecordsCount_ASC',
    TotalRecordsCountDesc = 'totalRecordsCount_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortTicketExportTasksBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    ExportedRecordsCountAsc = 'exportedRecordsCount_ASC',
    ExportedRecordsCountDesc = 'exportedRecordsCount_DESC',
    FormatAsc = 'format_ASC',
    FormatDesc = 'format_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    LocaleAsc = 'locale_ASC',
    LocaleDesc = 'locale_DESC',
    StatusAsc = 'status_ASC',
    StatusDesc = 'status_DESC',
    TimeZoneAsc = 'timeZone_ASC',
    TimeZoneDesc = 'timeZone_DESC',
    TotalRecordsCountAsc = 'totalRecordsCount_ASC',
    TotalRecordsCountDesc = 'totalRecordsCount_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    UserAsc = 'user_ASC',
    UserDesc = 'user_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortTicketFileHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortTicketFilesBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    TicketAsc = 'ticket_ASC',
    TicketDesc = 'ticket_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortTicketFilterTemplateHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortTicketFilterTemplatesBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    EmployeeAsc = 'employee_ASC',
    EmployeeDesc = 'employee_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortTicketHistoryRecordsBy {
    CanReadByResidentAsc = 'canReadByResident_ASC',
    CanReadByResidentDesc = 'canReadByResident_DESC',
    ClientEmailAsc = 'clientEmail_ASC',
    ClientEmailDesc = 'clientEmail_DESC',
    ClientNameAsc = 'clientName_ASC',
    ClientNameDesc = 'clientName_DESC',
    ClientPhoneAsc = 'clientPhone_ASC',
    ClientPhoneDesc = 'clientPhone_DESC',
    CompletedAtAsc = 'completedAt_ASC',
    CompletedAtDesc = 'completedAt_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeadlineAsc = 'deadline_ASC',
    DeadlineDesc = 'deadline_DESC',
    DeferredUntilAsc = 'deferredUntil_ASC',
    DeferredUntilDesc = 'deferredUntil_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DetailsAsc = 'details_ASC',
    DetailsDesc = 'details_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    FeedbackCommentAsc = 'feedbackComment_ASC',
    FeedbackCommentDesc = 'feedbackComment_DESC',
    FeedbackUpdatedAtAsc = 'feedbackUpdatedAt_ASC',
    FeedbackUpdatedAtDesc = 'feedbackUpdatedAt_DESC',
    FeedbackValueAsc = 'feedbackValue_ASC',
    FeedbackValueDesc = 'feedbackValue_DESC',
    FloorNameAsc = 'floorName_ASC',
    FloorNameDesc = 'floorName_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IsAutoClassifiedAsc = 'isAutoClassified_ASC',
    IsAutoClassifiedDesc = 'isAutoClassified_DESC',
    IsCompletedAfterDeadlineAsc = 'isCompletedAfterDeadline_ASC',
    IsCompletedAfterDeadlineDesc = 'isCompletedAfterDeadline_DESC',
    IsEmergencyAsc = 'isEmergency_ASC',
    IsEmergencyDesc = 'isEmergency_DESC',
    IsPaidAsc = 'isPaid_ASC',
    IsPaidDesc = 'isPaid_DESC',
    IsPayableAsc = 'isPayable_ASC',
    IsPayableDesc = 'isPayable_DESC',
    IsResidentTicketAsc = 'isResidentTicket_ASC',
    IsResidentTicketDesc = 'isResidentTicket_DESC',
    IsWarrantyAsc = 'isWarranty_ASC',
    IsWarrantyDesc = 'isWarranty_DESC',
    LastCommentAtAsc = 'lastCommentAt_ASC',
    LastCommentAtDesc = 'lastCommentAt_DESC',
    LastCommentWithResidentTypeAtAsc = 'lastCommentWithResidentTypeAt_ASC',
    LastCommentWithResidentTypeAtDesc = 'lastCommentWithResidentTypeAt_DESC',
    LastResidentCommentAtAsc = 'lastResidentCommentAt_ASC',
    LastResidentCommentAtDesc = 'lastResidentCommentAt_DESC',
    OrderAsc = 'order_ASC',
    OrderDesc = 'order_DESC',
    PropertyAddressAsc = 'propertyAddress_ASC',
    PropertyAddressDesc = 'propertyAddress_DESC',
    QualityControlCommentAsc = 'qualityControlComment_ASC',
    QualityControlCommentDesc = 'qualityControlComment_DESC',
    QualityControlUpdatedAtAsc = 'qualityControlUpdatedAt_ASC',
    QualityControlUpdatedAtDesc = 'qualityControlUpdatedAt_DESC',
    QualityControlValueAsc = 'qualityControlValue_ASC',
    QualityControlValueDesc = 'qualityControlValue_DESC',
    ReviewCommentAsc = 'reviewComment_ASC',
    ReviewCommentDesc = 'reviewComment_DESC',
    ReviewValueAsc = 'reviewValue_ASC',
    ReviewValueDesc = 'reviewValue_DESC',
    SectionNameAsc = 'sectionName_ASC',
    SectionNameDesc = 'sectionName_DESC',
    SectionTypeAsc = 'sectionType_ASC',
    SectionTypeDesc = 'sectionType_DESC',
    StatusReasonAsc = 'statusReason_ASC',
    StatusReasonDesc = 'statusReason_DESC',
    StatusReopenedCounterAsc = 'statusReopenedCounter_ASC',
    StatusReopenedCounterDesc = 'statusReopenedCounter_DESC',
    StatusUpdatedAtAsc = 'statusUpdatedAt_ASC',
    StatusUpdatedAtDesc = 'statusUpdatedAt_DESC',
    UnitNameAsc = 'unitName_ASC',
    UnitNameDesc = 'unitName_DESC',
    UnitTypeAsc = 'unitType_ASC',
    UnitTypeDesc = 'unitType_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortTicketOrganizationSettingHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortTicketOrganizationSettingsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DefaultDeadlineDurationAsc = 'defaultDeadlineDuration_ASC',
    DefaultDeadlineDurationDesc = 'defaultDeadlineDuration_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    EmergencyDeadlineDurationAsc = 'emergencyDeadlineDuration_ASC',
    EmergencyDeadlineDurationDesc = 'emergencyDeadlineDuration_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    PaidDeadlineDurationAsc = 'paidDeadlineDuration_ASC',
    PaidDeadlineDurationDesc = 'paidDeadlineDuration_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
    WarrantyDeadlineDurationAsc = 'warrantyDeadlineDuration_ASC',
    WarrantyDeadlineDurationDesc = 'warrantyDeadlineDuration_DESC',
}

export enum SortTicketPlaceClassifierHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortTicketPlaceClassifiersBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortTicketProblemClassifierHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortTicketProblemClassifiersBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortTicketPropertyHintHistoryRecordsBy {
    ContentAsc = 'content_ASC',
    ContentDesc = 'content_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortTicketPropertyHintPropertiesBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    PropertyAsc = 'property_ASC',
    PropertyDesc = 'property_DESC',
    TicketPropertyHintAsc = 'ticketPropertyHint_ASC',
    TicketPropertyHintDesc = 'ticketPropertyHint_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortTicketPropertyHintPropertyHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortTicketPropertyHintsBy {
    ContentAsc = 'content_ASC',
    ContentDesc = 'content_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortTicketSourceHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    TypeAsc = 'type_ASC',
    TypeDesc = 'type_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortTicketSourcesBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    TypeAsc = 'type_ASC',
    TypeDesc = 'type_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortTicketStatusHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    TypeAsc = 'type_ASC',
    TypeDesc = 'type_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortTicketStatusesBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    TypeAsc = 'type_ASC',
    TypeDesc = 'type_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortTicketsBy {
    AssigneeAsc = 'assignee_ASC',
    AssigneeDesc = 'assignee_DESC',
    CanReadByResidentAsc = 'canReadByResident_ASC',
    CanReadByResidentDesc = 'canReadByResident_DESC',
    CategoryClassifierAsc = 'categoryClassifier_ASC',
    CategoryClassifierDesc = 'categoryClassifier_DESC',
    ClassifierAsc = 'classifier_ASC',
    ClassifierDesc = 'classifier_DESC',
    ClientEmailAsc = 'clientEmail_ASC',
    ClientEmailDesc = 'clientEmail_DESC',
    ClientNameAsc = 'clientName_ASC',
    ClientNameDesc = 'clientName_DESC',
    ClientPhoneAsc = 'clientPhone_ASC',
    ClientPhoneDesc = 'clientPhone_DESC',
    ClientAsc = 'client_ASC',
    ClientDesc = 'client_DESC',
    CompletedAtAsc = 'completedAt_ASC',
    CompletedAtDesc = 'completedAt_DESC',
    ContactAsc = 'contact_ASC',
    ContactDesc = 'contact_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeadlineAsc = 'deadline_ASC',
    DeadlineDesc = 'deadline_DESC',
    DeferredUntilAsc = 'deferredUntil_ASC',
    DeferredUntilDesc = 'deferredUntil_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DetailsAsc = 'details_ASC',
    DetailsDesc = 'details_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    ExecutorAsc = 'executor_ASC',
    ExecutorDesc = 'executor_DESC',
    FeedbackCommentAsc = 'feedbackComment_ASC',
    FeedbackCommentDesc = 'feedbackComment_DESC',
    FeedbackUpdatedAtAsc = 'feedbackUpdatedAt_ASC',
    FeedbackUpdatedAtDesc = 'feedbackUpdatedAt_DESC',
    FeedbackValueAsc = 'feedbackValue_ASC',
    FeedbackValueDesc = 'feedbackValue_DESC',
    FloorNameAsc = 'floorName_ASC',
    FloorNameDesc = 'floorName_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IsAutoClassifiedAsc = 'isAutoClassified_ASC',
    IsAutoClassifiedDesc = 'isAutoClassified_DESC',
    IsCompletedAfterDeadlineAsc = 'isCompletedAfterDeadline_ASC',
    IsCompletedAfterDeadlineDesc = 'isCompletedAfterDeadline_DESC',
    IsEmergencyAsc = 'isEmergency_ASC',
    IsEmergencyDesc = 'isEmergency_DESC',
    IsPaidAsc = 'isPaid_ASC',
    IsPaidDesc = 'isPaid_DESC',
    IsPayableAsc = 'isPayable_ASC',
    IsPayableDesc = 'isPayable_DESC',
    IsResidentTicketAsc = 'isResidentTicket_ASC',
    IsResidentTicketDesc = 'isResidentTicket_DESC',
    IsWarrantyAsc = 'isWarranty_ASC',
    IsWarrantyDesc = 'isWarranty_DESC',
    LastCommentAtAsc = 'lastCommentAt_ASC',
    LastCommentAtDesc = 'lastCommentAt_DESC',
    LastCommentWithResidentTypeAtAsc = 'lastCommentWithResidentTypeAt_ASC',
    LastCommentWithResidentTypeAtDesc = 'lastCommentWithResidentTypeAt_DESC',
    LastResidentCommentAtAsc = 'lastResidentCommentAt_ASC',
    LastResidentCommentAtDesc = 'lastResidentCommentAt_DESC',
    NumberAsc = 'number_ASC',
    NumberDesc = 'number_DESC',
    OrderAsc = 'order_ASC',
    OrderDesc = 'order_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    PlaceClassifierAsc = 'placeClassifier_ASC',
    PlaceClassifierDesc = 'placeClassifier_DESC',
    ProblemClassifierAsc = 'problemClassifier_ASC',
    ProblemClassifierDesc = 'problemClassifier_DESC',
    PropertyAddressAsc = 'propertyAddress_ASC',
    PropertyAddressDesc = 'propertyAddress_DESC',
    PropertyAsc = 'property_ASC',
    PropertyDesc = 'property_DESC',
    QualityControlCommentAsc = 'qualityControlComment_ASC',
    QualityControlCommentDesc = 'qualityControlComment_DESC',
    QualityControlUpdatedAtAsc = 'qualityControlUpdatedAt_ASC',
    QualityControlUpdatedAtDesc = 'qualityControlUpdatedAt_DESC',
    QualityControlUpdatedByAsc = 'qualityControlUpdatedBy_ASC',
    QualityControlUpdatedByDesc = 'qualityControlUpdatedBy_DESC',
    QualityControlValueAsc = 'qualityControlValue_ASC',
    QualityControlValueDesc = 'qualityControlValue_DESC',
    RelatedAsc = 'related_ASC',
    RelatedDesc = 'related_DESC',
    ReviewCommentAsc = 'reviewComment_ASC',
    ReviewCommentDesc = 'reviewComment_DESC',
    ReviewValueAsc = 'reviewValue_ASC',
    ReviewValueDesc = 'reviewValue_DESC',
    SectionNameAsc = 'sectionName_ASC',
    SectionNameDesc = 'sectionName_DESC',
    SectionTypeAsc = 'sectionType_ASC',
    SectionTypeDesc = 'sectionType_DESC',
    SourceAsc = 'source_ASC',
    SourceDesc = 'source_DESC',
    StatusReasonAsc = 'statusReason_ASC',
    StatusReasonDesc = 'statusReason_DESC',
    StatusReopenedCounterAsc = 'statusReopenedCounter_ASC',
    StatusReopenedCounterDesc = 'statusReopenedCounter_DESC',
    StatusUpdatedAtAsc = 'statusUpdatedAt_ASC',
    StatusUpdatedAtDesc = 'statusUpdatedAt_DESC',
    StatusAsc = 'status_ASC',
    StatusDesc = 'status_DESC',
    UnitNameAsc = 'unitName_ASC',
    UnitNameDesc = 'unitName_DESC',
    UnitTypeAsc = 'unitType_ASC',
    UnitTypeDesc = 'unitType_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortTourStepHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    OrderAsc = 'order_ASC',
    OrderDesc = 'order_DESC',
    StatusAsc = 'status_ASC',
    StatusDesc = 'status_DESC',
    TypeAsc = 'type_ASC',
    TypeDesc = 'type_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortTourStepsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    OrderAsc = 'order_ASC',
    OrderDesc = 'order_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    StatusAsc = 'status_ASC',
    StatusDesc = 'status_DESC',
    TypeAsc = 'type_ASC',
    TypeDesc = 'type_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortUserExternalIdentitiesBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IdentityIdAsc = 'identityId_ASC',
    IdentityIdDesc = 'identityId_DESC',
    IdentityTypeAsc = 'identityType_ASC',
    IdentityTypeDesc = 'identityType_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    UserAsc = 'user_ASC',
    UserDesc = 'user_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortUserExternalIdentityHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IdentityIdAsc = 'identityId_ASC',
    IdentityIdDesc = 'identityId_DESC',
    IdentityTypeAsc = 'identityType_ASC',
    IdentityTypeDesc = 'identityType_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortUserFavoriteTicketHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortUserFavoriteTicketsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    TicketAsc = 'ticket_ASC',
    TicketDesc = 'ticket_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    UserAsc = 'user_ASC',
    UserDesc = 'user_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortUserHelpRequestFileHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortUserHelpRequestFilesBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    UserHelpRequestAsc = 'userHelpRequest_ASC',
    UserHelpRequestDesc = 'userHelpRequest_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortUserHelpRequestHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IsReadyToSendAsc = 'isReadyToSend_ASC',
    IsReadyToSendDesc = 'isReadyToSend_DESC',
    PhoneAsc = 'phone_ASC',
    PhoneDesc = 'phone_DESC',
    TypeAsc = 'type_ASC',
    TypeDesc = 'type_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortUserHelpRequestsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IsReadyToSendAsc = 'isReadyToSend_ASC',
    IsReadyToSendDesc = 'isReadyToSend_DESC',
    OrganizationAsc = 'organization_ASC',
    OrganizationDesc = 'organization_DESC',
    PhoneAsc = 'phone_ASC',
    PhoneDesc = 'phone_DESC',
    TypeAsc = 'type_ASC',
    TypeDesc = 'type_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortUserHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    EmailAsc = 'email_ASC',
    EmailDesc = 'email_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IsAdminAsc = 'isAdmin_ASC',
    IsAdminDesc = 'isAdmin_DESC',
    IsEmailVerifiedAsc = 'isEmailVerified_ASC',
    IsEmailVerifiedDesc = 'isEmailVerified_DESC',
    IsPhoneVerifiedAsc = 'isPhoneVerified_ASC',
    IsPhoneVerifiedDesc = 'isPhoneVerified_DESC',
    IsSupportAsc = 'isSupport_ASC',
    IsSupportDesc = 'isSupport_DESC',
    LocaleAsc = 'locale_ASC',
    LocaleDesc = 'locale_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    PasswordAsc = 'password_ASC',
    PasswordDesc = 'password_DESC',
    PhoneAsc = 'phone_ASC',
    PhoneDesc = 'phone_DESC',
    ShowGlobalHintsAsc = 'showGlobalHints_ASC',
    ShowGlobalHintsDesc = 'showGlobalHints_DESC',
    TypeAsc = 'type_ASC',
    TypeDesc = 'type_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortUserRightsSetHistoryRecordsBy {
    CanExecuteRegisterNewServiceUserAsc = 'canExecuteRegisterNewServiceUser_ASC',
    CanExecuteRegisterNewServiceUserDesc = 'canExecuteRegisterNewServiceUser_DESC',
    CanExecuteSendMessageAsc = 'canExecuteSendMessage_ASC',
    CanExecuteSendMessageDesc = 'canExecuteSendMessage_DESC',
    CanExecuteAllBillingReceiptsSumAsc = 'canExecute_allBillingReceiptsSum_ASC',
    CanExecuteAllBillingReceiptsSumDesc = 'canExecute_allBillingReceiptsSum_DESC',
    CanExecuteAllPaymentsSumAsc = 'canExecute_allPaymentsSum_ASC',
    CanExecuteAllPaymentsSumDesc = 'canExecute_allPaymentsSum_DESC',
    CanExecuteInternalSendHashedResidentPhonesAsc = 'canExecute_internalSendHashedResidentPhones_ASC',
    CanExecuteInternalSendHashedResidentPhonesDesc = 'canExecute_internalSendHashedResidentPhones_DESC',
    CanManageB2BAppAccessRightSetsAsc = 'canManageB2BAppAccessRightSets_ASC',
    CanManageB2BAppAccessRightSetsDesc = 'canManageB2BAppAccessRightSets_DESC',
    CanManageB2BAppAccessRightsAsc = 'canManageB2BAppAccessRights_ASC',
    CanManageB2BAppAccessRightsDesc = 'canManageB2BAppAccessRights_DESC',
    CanManageB2BAppContextsAsc = 'canManageB2BAppContexts_ASC',
    CanManageB2BAppContextsDesc = 'canManageB2BAppContexts_DESC',
    CanManageB2BAppNewsSharingConfigsAsc = 'canManageB2BAppNewsSharingConfigs_ASC',
    CanManageB2BAppNewsSharingConfigsDesc = 'canManageB2BAppNewsSharingConfigs_DESC',
    CanManageB2BAppPermissionsAsc = 'canManageB2BAppPermissions_ASC',
    CanManageB2BAppPermissionsDesc = 'canManageB2BAppPermissions_DESC',
    CanManageB2BAppPromoBlocksAsc = 'canManageB2BAppPromoBlocks_ASC',
    CanManageB2BAppPromoBlocksDesc = 'canManageB2BAppPromoBlocks_DESC',
    CanManageB2BAppsAsc = 'canManageB2BApps_ASC',
    CanManageB2BAppsDesc = 'canManageB2BApps_DESC',
    CanManageB2CAppAccessRightsAsc = 'canManageB2CAppAccessRights_ASC',
    CanManageB2CAppAccessRightsDesc = 'canManageB2CAppAccessRights_DESC',
    CanManageB2CAppBuildsAsc = 'canManageB2CAppBuilds_ASC',
    CanManageB2CAppBuildsDesc = 'canManageB2CAppBuilds_DESC',
    CanManageB2CAppPropertiesAsc = 'canManageB2CAppProperties_ASC',
    CanManageB2CAppPropertiesDesc = 'canManageB2CAppProperties_DESC',
    CanManageB2CAppsAsc = 'canManageB2CApps_ASC',
    CanManageB2CAppsDesc = 'canManageB2CApps_DESC',
    CanManageMessageBatchesAsc = 'canManageMessageBatches_ASC',
    CanManageMessageBatchesDesc = 'canManageMessageBatches_DESC',
    CanManageOidcClientsAsc = 'canManageOidcClients_ASC',
    CanManageOidcClientsDesc = 'canManageOidcClients_DESC',
    CanManageOrganizationIsApprovedFieldAsc = 'canManageOrganizationIsApprovedField_ASC',
    CanManageOrganizationIsApprovedFieldDesc = 'canManageOrganizationIsApprovedField_DESC',
    CanManageOrganizationsAsc = 'canManageOrganizations_ASC',
    CanManageOrganizationsDesc = 'canManageOrganizations_DESC',
    CanManageTicketAutoAssignmentsAsc = 'canManageTicketAutoAssignments_ASC',
    CanManageTicketAutoAssignmentsDesc = 'canManageTicketAutoAssignments_DESC',
    CanManageTicketsAsc = 'canManageTickets_ASC',
    CanManageTicketsDesc = 'canManageTickets_DESC',
    CanManageUserRightsSetsAsc = 'canManageUserRightsSets_ASC',
    CanManageUserRightsSetsDesc = 'canManageUserRightsSets_DESC',
    CanReadB2BAppAccessRightSetsAsc = 'canReadB2BAppAccessRightSets_ASC',
    CanReadB2BAppAccessRightSetsDesc = 'canReadB2BAppAccessRightSets_DESC',
    CanReadB2BAppAccessRightsAsc = 'canReadB2BAppAccessRights_ASC',
    CanReadB2BAppAccessRightsDesc = 'canReadB2BAppAccessRights_DESC',
    CanReadB2BAppContextsAsc = 'canReadB2BAppContexts_ASC',
    CanReadB2BAppContextsDesc = 'canReadB2BAppContexts_DESC',
    CanReadB2BAppNewsSharingConfigsAsc = 'canReadB2BAppNewsSharingConfigs_ASC',
    CanReadB2BAppNewsSharingConfigsDesc = 'canReadB2BAppNewsSharingConfigs_DESC',
    CanReadB2BAppPermissionsAsc = 'canReadB2BAppPermissions_ASC',
    CanReadB2BAppPermissionsDesc = 'canReadB2BAppPermissions_DESC',
    CanReadB2BAppPromoBlocksAsc = 'canReadB2BAppPromoBlocks_ASC',
    CanReadB2BAppPromoBlocksDesc = 'canReadB2BAppPromoBlocks_DESC',
    CanReadB2BAppsAsc = 'canReadB2BApps_ASC',
    CanReadB2BAppsDesc = 'canReadB2BApps_DESC',
    CanReadB2CAppAccessRightsAsc = 'canReadB2CAppAccessRights_ASC',
    CanReadB2CAppAccessRightsDesc = 'canReadB2CAppAccessRights_DESC',
    CanReadB2CAppBuildsAsc = 'canReadB2CAppBuilds_ASC',
    CanReadB2CAppBuildsDesc = 'canReadB2CAppBuilds_DESC',
    CanReadB2CAppPropertiesAsc = 'canReadB2CAppProperties_ASC',
    CanReadB2CAppPropertiesDesc = 'canReadB2CAppProperties_DESC',
    CanReadB2CAppsAsc = 'canReadB2CApps_ASC',
    CanReadB2CAppsDesc = 'canReadB2CApps_DESC',
    CanReadBillingOrganizationIntegrationContextsAsc = 'canReadBillingOrganizationIntegrationContexts_ASC',
    CanReadBillingOrganizationIntegrationContextsDesc = 'canReadBillingOrganizationIntegrationContexts_DESC',
    CanReadBillingReceiptsAsc = 'canReadBillingReceipts_ASC',
    CanReadBillingReceiptsDesc = 'canReadBillingReceipts_DESC',
    CanReadMessageBatchesAsc = 'canReadMessageBatches_ASC',
    CanReadMessageBatchesDesc = 'canReadMessageBatches_DESC',
    CanReadMessagesAsc = 'canReadMessages_ASC',
    CanReadMessagesDesc = 'canReadMessages_DESC',
    CanReadOidcClientsAsc = 'canReadOidcClients_ASC',
    CanReadOidcClientsDesc = 'canReadOidcClients_DESC',
    CanReadOrganizationsAsc = 'canReadOrganizations_ASC',
    CanReadOrganizationsDesc = 'canReadOrganizations_DESC',
    CanReadPaymentsAsc = 'canReadPayments_ASC',
    CanReadPaymentsDesc = 'canReadPayments_DESC',
    CanReadTicketAutoAssignmentsAsc = 'canReadTicketAutoAssignments_ASC',
    CanReadTicketAutoAssignmentsDesc = 'canReadTicketAutoAssignments_DESC',
    CanReadTicketsAsc = 'canReadTickets_ASC',
    CanReadTicketsDesc = 'canReadTickets_DESC',
    CanReadUserEmailFieldAsc = 'canReadUserEmailField_ASC',
    CanReadUserEmailFieldDesc = 'canReadUserEmailField_DESC',
    CanReadUserRightsSetsAsc = 'canReadUserRightsSets_ASC',
    CanReadUserRightsSetsDesc = 'canReadUserRightsSets_DESC',
    CanReadUsersAsc = 'canReadUsers_ASC',
    CanReadUsersDesc = 'canReadUsers_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortUserRightsSetsBy {
    CanExecuteRegisterNewServiceUserAsc = 'canExecuteRegisterNewServiceUser_ASC',
    CanExecuteRegisterNewServiceUserDesc = 'canExecuteRegisterNewServiceUser_DESC',
    CanExecuteSendMessageAsc = 'canExecuteSendMessage_ASC',
    CanExecuteSendMessageDesc = 'canExecuteSendMessage_DESC',
    CanExecuteAllBillingReceiptsSumAsc = 'canExecute_allBillingReceiptsSum_ASC',
    CanExecuteAllBillingReceiptsSumDesc = 'canExecute_allBillingReceiptsSum_DESC',
    CanExecuteAllPaymentsSumAsc = 'canExecute_allPaymentsSum_ASC',
    CanExecuteAllPaymentsSumDesc = 'canExecute_allPaymentsSum_DESC',
    CanExecuteInternalSendHashedResidentPhonesAsc = 'canExecute_internalSendHashedResidentPhones_ASC',
    CanExecuteInternalSendHashedResidentPhonesDesc = 'canExecute_internalSendHashedResidentPhones_DESC',
    CanManageB2BAppAccessRightSetsAsc = 'canManageB2BAppAccessRightSets_ASC',
    CanManageB2BAppAccessRightSetsDesc = 'canManageB2BAppAccessRightSets_DESC',
    CanManageB2BAppAccessRightsAsc = 'canManageB2BAppAccessRights_ASC',
    CanManageB2BAppAccessRightsDesc = 'canManageB2BAppAccessRights_DESC',
    CanManageB2BAppContextsAsc = 'canManageB2BAppContexts_ASC',
    CanManageB2BAppContextsDesc = 'canManageB2BAppContexts_DESC',
    CanManageB2BAppNewsSharingConfigsAsc = 'canManageB2BAppNewsSharingConfigs_ASC',
    CanManageB2BAppNewsSharingConfigsDesc = 'canManageB2BAppNewsSharingConfigs_DESC',
    CanManageB2BAppPermissionsAsc = 'canManageB2BAppPermissions_ASC',
    CanManageB2BAppPermissionsDesc = 'canManageB2BAppPermissions_DESC',
    CanManageB2BAppPromoBlocksAsc = 'canManageB2BAppPromoBlocks_ASC',
    CanManageB2BAppPromoBlocksDesc = 'canManageB2BAppPromoBlocks_DESC',
    CanManageB2BAppsAsc = 'canManageB2BApps_ASC',
    CanManageB2BAppsDesc = 'canManageB2BApps_DESC',
    CanManageB2CAppAccessRightsAsc = 'canManageB2CAppAccessRights_ASC',
    CanManageB2CAppAccessRightsDesc = 'canManageB2CAppAccessRights_DESC',
    CanManageB2CAppBuildsAsc = 'canManageB2CAppBuilds_ASC',
    CanManageB2CAppBuildsDesc = 'canManageB2CAppBuilds_DESC',
    CanManageB2CAppPropertiesAsc = 'canManageB2CAppProperties_ASC',
    CanManageB2CAppPropertiesDesc = 'canManageB2CAppProperties_DESC',
    CanManageB2CAppsAsc = 'canManageB2CApps_ASC',
    CanManageB2CAppsDesc = 'canManageB2CApps_DESC',
    CanManageMessageBatchesAsc = 'canManageMessageBatches_ASC',
    CanManageMessageBatchesDesc = 'canManageMessageBatches_DESC',
    CanManageOidcClientsAsc = 'canManageOidcClients_ASC',
    CanManageOidcClientsDesc = 'canManageOidcClients_DESC',
    CanManageOrganizationIsApprovedFieldAsc = 'canManageOrganizationIsApprovedField_ASC',
    CanManageOrganizationIsApprovedFieldDesc = 'canManageOrganizationIsApprovedField_DESC',
    CanManageOrganizationsAsc = 'canManageOrganizations_ASC',
    CanManageOrganizationsDesc = 'canManageOrganizations_DESC',
    CanManageTicketAutoAssignmentsAsc = 'canManageTicketAutoAssignments_ASC',
    CanManageTicketAutoAssignmentsDesc = 'canManageTicketAutoAssignments_DESC',
    CanManageTicketsAsc = 'canManageTickets_ASC',
    CanManageTicketsDesc = 'canManageTickets_DESC',
    CanManageUserRightsSetsAsc = 'canManageUserRightsSets_ASC',
    CanManageUserRightsSetsDesc = 'canManageUserRightsSets_DESC',
    CanReadB2BAppAccessRightSetsAsc = 'canReadB2BAppAccessRightSets_ASC',
    CanReadB2BAppAccessRightSetsDesc = 'canReadB2BAppAccessRightSets_DESC',
    CanReadB2BAppAccessRightsAsc = 'canReadB2BAppAccessRights_ASC',
    CanReadB2BAppAccessRightsDesc = 'canReadB2BAppAccessRights_DESC',
    CanReadB2BAppContextsAsc = 'canReadB2BAppContexts_ASC',
    CanReadB2BAppContextsDesc = 'canReadB2BAppContexts_DESC',
    CanReadB2BAppNewsSharingConfigsAsc = 'canReadB2BAppNewsSharingConfigs_ASC',
    CanReadB2BAppNewsSharingConfigsDesc = 'canReadB2BAppNewsSharingConfigs_DESC',
    CanReadB2BAppPermissionsAsc = 'canReadB2BAppPermissions_ASC',
    CanReadB2BAppPermissionsDesc = 'canReadB2BAppPermissions_DESC',
    CanReadB2BAppPromoBlocksAsc = 'canReadB2BAppPromoBlocks_ASC',
    CanReadB2BAppPromoBlocksDesc = 'canReadB2BAppPromoBlocks_DESC',
    CanReadB2BAppsAsc = 'canReadB2BApps_ASC',
    CanReadB2BAppsDesc = 'canReadB2BApps_DESC',
    CanReadB2CAppAccessRightsAsc = 'canReadB2CAppAccessRights_ASC',
    CanReadB2CAppAccessRightsDesc = 'canReadB2CAppAccessRights_DESC',
    CanReadB2CAppBuildsAsc = 'canReadB2CAppBuilds_ASC',
    CanReadB2CAppBuildsDesc = 'canReadB2CAppBuilds_DESC',
    CanReadB2CAppPropertiesAsc = 'canReadB2CAppProperties_ASC',
    CanReadB2CAppPropertiesDesc = 'canReadB2CAppProperties_DESC',
    CanReadB2CAppsAsc = 'canReadB2CApps_ASC',
    CanReadB2CAppsDesc = 'canReadB2CApps_DESC',
    CanReadBillingOrganizationIntegrationContextsAsc = 'canReadBillingOrganizationIntegrationContexts_ASC',
    CanReadBillingOrganizationIntegrationContextsDesc = 'canReadBillingOrganizationIntegrationContexts_DESC',
    CanReadBillingReceiptsAsc = 'canReadBillingReceipts_ASC',
    CanReadBillingReceiptsDesc = 'canReadBillingReceipts_DESC',
    CanReadMessageBatchesAsc = 'canReadMessageBatches_ASC',
    CanReadMessageBatchesDesc = 'canReadMessageBatches_DESC',
    CanReadMessagesAsc = 'canReadMessages_ASC',
    CanReadMessagesDesc = 'canReadMessages_DESC',
    CanReadOidcClientsAsc = 'canReadOidcClients_ASC',
    CanReadOidcClientsDesc = 'canReadOidcClients_DESC',
    CanReadOrganizationsAsc = 'canReadOrganizations_ASC',
    CanReadOrganizationsDesc = 'canReadOrganizations_DESC',
    CanReadPaymentsAsc = 'canReadPayments_ASC',
    CanReadPaymentsDesc = 'canReadPayments_DESC',
    CanReadTicketAutoAssignmentsAsc = 'canReadTicketAutoAssignments_ASC',
    CanReadTicketAutoAssignmentsDesc = 'canReadTicketAutoAssignments_DESC',
    CanReadTicketsAsc = 'canReadTickets_ASC',
    CanReadTicketsDesc = 'canReadTickets_DESC',
    CanReadUserEmailFieldAsc = 'canReadUserEmailField_ASC',
    CanReadUserEmailFieldDesc = 'canReadUserEmailField_DESC',
    CanReadUserRightsSetsAsc = 'canReadUserRightsSets_ASC',
    CanReadUserRightsSetsDesc = 'canReadUserRightsSets_DESC',
    CanReadUsersAsc = 'canReadUsers_ASC',
    CanReadUsersDesc = 'canReadUsers_DESC',
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortUserTicketCommentReadTimeHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    ReadCommentAtAsc = 'readCommentAt_ASC',
    ReadCommentAtDesc = 'readCommentAt_DESC',
    ReadResidentCommentAtAsc = 'readResidentCommentAt_ASC',
    ReadResidentCommentAtDesc = 'readResidentCommentAt_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortUserTicketCommentReadTimesBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    ReadCommentAtAsc = 'readCommentAt_ASC',
    ReadCommentAtDesc = 'readCommentAt_DESC',
    ReadResidentCommentAtAsc = 'readResidentCommentAt_ASC',
    ReadResidentCommentAtDesc = 'readResidentCommentAt_DESC',
    TicketAsc = 'ticket_ASC',
    TicketDesc = 'ticket_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    UserAsc = 'user_ASC',
    UserDesc = 'user_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortUsersBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    EmailAsc = 'email_ASC',
    EmailDesc = 'email_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    IsAdminAsc = 'isAdmin_ASC',
    IsAdminDesc = 'isAdmin_DESC',
    IsEmailVerifiedAsc = 'isEmailVerified_ASC',
    IsEmailVerifiedDesc = 'isEmailVerified_DESC',
    IsPhoneVerifiedAsc = 'isPhoneVerified_ASC',
    IsPhoneVerifiedDesc = 'isPhoneVerified_DESC',
    IsSupportAsc = 'isSupport_ASC',
    IsSupportDesc = 'isSupport_DESC',
    LocaleAsc = 'locale_ASC',
    LocaleDesc = 'locale_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    PhoneAsc = 'phone_ASC',
    PhoneDesc = 'phone_DESC',
    RightsSetAsc = 'rightsSet_ASC',
    RightsSetDesc = 'rightsSet_DESC',
    ShowGlobalHintsAsc = 'showGlobalHints_ASC',
    ShowGlobalHintsDesc = 'showGlobalHints_DESC',
    TypeAsc = 'type_ASC',
    TypeDesc = 'type_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortWebhookHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DescriptionAsc = 'description_ASC',
    DescriptionDesc = 'description_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UrlAsc = 'url_ASC',
    UrlDesc = 'url_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortWebhookSubscriptionHistoryRecordsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    FailuresCountAsc = 'failuresCount_ASC',
    FailuresCountDesc = 'failuresCount_DESC',
    FieldsAsc = 'fields_ASC',
    FieldsDesc = 'fields_DESC',
    HistoryActionAsc = 'history_action_ASC',
    HistoryActionDesc = 'history_action_DESC',
    HistoryDateAsc = 'history_date_ASC',
    HistoryDateDesc = 'history_date_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    MaxPackSizeAsc = 'maxPackSize_ASC',
    MaxPackSizeDesc = 'maxPackSize_DESC',
    ModelAsc = 'model_ASC',
    ModelDesc = 'model_DESC',
    SyncedAmountAsc = 'syncedAmount_ASC',
    SyncedAmountDesc = 'syncedAmount_DESC',
    SyncedAtAsc = 'syncedAt_ASC',
    SyncedAtDesc = 'syncedAt_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UrlAsc = 'url_ASC',
    UrlDesc = 'url_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export enum SortWebhookSubscriptionsBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    FailuresCountAsc = 'failuresCount_ASC',
    FailuresCountDesc = 'failuresCount_DESC',
    FieldsAsc = 'fields_ASC',
    FieldsDesc = 'fields_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    MaxPackSizeAsc = 'maxPackSize_ASC',
    MaxPackSizeDesc = 'maxPackSize_DESC',
    ModelAsc = 'model_ASC',
    ModelDesc = 'model_DESC',
    SyncedAmountAsc = 'syncedAmount_ASC',
    SyncedAmountDesc = 'syncedAmount_DESC',
    SyncedAtAsc = 'syncedAt_ASC',
    SyncedAtDesc = 'syncedAt_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    UrlAsc = 'url_ASC',
    UrlDesc = 'url_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
    WebhookAsc = 'webhook_ASC',
    WebhookDesc = 'webhook_DESC',
}

export enum SortWebhooksBy {
    CreatedAtAsc = 'createdAt_ASC',
    CreatedAtDesc = 'createdAt_DESC',
    CreatedByAsc = 'createdBy_ASC',
    CreatedByDesc = 'createdBy_DESC',
    DeletedAtAsc = 'deletedAt_ASC',
    DeletedAtDesc = 'deletedAt_DESC',
    DescriptionAsc = 'description_ASC',
    DescriptionDesc = 'description_DESC',
    DvAsc = 'dv_ASC',
    DvDesc = 'dv_DESC',
    IdAsc = 'id_ASC',
    IdDesc = 'id_DESC',
    NameAsc = 'name_ASC',
    NameDesc = 'name_DESC',
    UpdatedAtAsc = 'updatedAt_ASC',
    UpdatedAtDesc = 'updatedAt_DESC',
    UpdatedByAsc = 'updatedBy_ASC',
    UpdatedByDesc = 'updatedBy_DESC',
    UrlAsc = 'url_ASC',
    UrlDesc = 'url_DESC',
    UserAsc = 'user_ASC',
    UserDesc = 'user_DESC',
    VAsc = 'v_ASC',
    VDesc = 'v_DESC',
}

export type StartConfirmPhoneActionInput = {
    captcha: Scalars['String']['input']
    dv: Scalars['Int']['input']
    phone: Scalars['String']['input']
    sender: SenderFieldInput
}

export type StartConfirmPhoneActionOutput = {
    __typename?: 'StartConfirmPhoneActionOutput'
    token: Scalars['String']['output']
}

export enum Status {
    Error = 'error',
    Success = 'success',
}

export type SuggestServiceProviderInput = {
    search: Scalars['String']['input']
}

export type SuggestServiceProviderOutput = {
    __typename?: 'SuggestServiceProviderOutput'
    name: Scalars['String']['output']
    tin: Scalars['String']['output']
}

export type SyncRemoteClientInput = {
    appId: Scalars['String']['input']
    deviceId: Scalars['String']['input']
    devicePlatform?: InputMaybe<DevicePlatformType>
    dv: Scalars['Int']['input']
    meta?: InputMaybe<Scalars['JSON']['input']>
    pushToken?: InputMaybe<Scalars['String']['input']>
    pushTokenVoIP?: InputMaybe<Scalars['String']['input']>
    pushTransport?: InputMaybe<PushTransportType>
    pushTransportVoIP?: InputMaybe<PushTransportType>
    pushType?: InputMaybe<PushType>
    pushTypeVoIP?: InputMaybe<PushType>
    sender: SenderFieldInput
}

export type SyncTourStepsInput = {
    dv: Scalars['Int']['input']
    organization: OrganizationWhereUniqueInput
    sender: SenderFieldInput
}

export type SyncTourStepsOutput = {
    __typename?: 'SyncTourStepsOutput'
    ok: Scalars['String']['output']
}

/**  Link between condo user and user's telegram chat with bot  */
export type TelegramUserChat = {
    __typename?: 'TelegramUserChat'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TelegramUserChat List config, or
   *  2. As an alias to the field set on 'labelField' in the TelegramUserChat List config, or
   *  3. As an alias to a 'name' field on the TelegramUserChat List (if one exists), or
   *  4. As an alias to the 'id' field on the TelegramUserChat List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Id of user's telegram chat with bot  */
    telegramChatId?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    /**  Condo user  */
    user?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type TelegramUserChatCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    telegramChatId?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type TelegramUserChatHistoryRecord = {
    __typename?: 'TelegramUserChatHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TelegramUserChatHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the TelegramUserChatHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the TelegramUserChatHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the TelegramUserChatHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<TelegramUserChatHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    telegramChatId?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    user?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type TelegramUserChatHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<TelegramUserChatHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    telegramChatId?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum TelegramUserChatHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type TelegramUserChatHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<TelegramUserChatHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    telegramChatId?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type TelegramUserChatHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<TelegramUserChatHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<TelegramUserChatHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<TelegramUserChatHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<TelegramUserChatHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<TelegramUserChatHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<TelegramUserChatHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    telegramChatId?: InputMaybe<Scalars['String']['input']>
    telegramChatId_contains?: InputMaybe<Scalars['String']['input']>
    telegramChatId_contains_i?: InputMaybe<Scalars['String']['input']>
    telegramChatId_ends_with?: InputMaybe<Scalars['String']['input']>
    telegramChatId_ends_with_i?: InputMaybe<Scalars['String']['input']>
    telegramChatId_i?: InputMaybe<Scalars['String']['input']>
    telegramChatId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    telegramChatId_not?: InputMaybe<Scalars['String']['input']>
    telegramChatId_not_contains?: InputMaybe<Scalars['String']['input']>
    telegramChatId_not_contains_i?: InputMaybe<Scalars['String']['input']>
    telegramChatId_not_ends_with?: InputMaybe<Scalars['String']['input']>
    telegramChatId_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    telegramChatId_not_i?: InputMaybe<Scalars['String']['input']>
    telegramChatId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    telegramChatId_not_starts_with?: InputMaybe<Scalars['String']['input']>
    telegramChatId_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    telegramChatId_starts_with?: InputMaybe<Scalars['String']['input']>
    telegramChatId_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user?: InputMaybe<Scalars['String']['input']>
    user_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user_not?: InputMaybe<Scalars['String']['input']>
    user_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type TelegramUserChatHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type TelegramUserChatHistoryRecordsCreateInput = {
    data?: InputMaybe<TelegramUserChatHistoryRecordCreateInput>
}

export type TelegramUserChatHistoryRecordsUpdateInput = {
    data?: InputMaybe<TelegramUserChatHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type TelegramUserChatUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    telegramChatId?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type TelegramUserChatWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<TelegramUserChatWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<TelegramUserChatWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    telegramChatId?: InputMaybe<Scalars['String']['input']>
    telegramChatId_contains?: InputMaybe<Scalars['String']['input']>
    telegramChatId_contains_i?: InputMaybe<Scalars['String']['input']>
    telegramChatId_ends_with?: InputMaybe<Scalars['String']['input']>
    telegramChatId_ends_with_i?: InputMaybe<Scalars['String']['input']>
    telegramChatId_i?: InputMaybe<Scalars['String']['input']>
    telegramChatId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    telegramChatId_not?: InputMaybe<Scalars['String']['input']>
    telegramChatId_not_contains?: InputMaybe<Scalars['String']['input']>
    telegramChatId_not_contains_i?: InputMaybe<Scalars['String']['input']>
    telegramChatId_not_ends_with?: InputMaybe<Scalars['String']['input']>
    telegramChatId_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    telegramChatId_not_i?: InputMaybe<Scalars['String']['input']>
    telegramChatId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    telegramChatId_not_starts_with?: InputMaybe<Scalars['String']['input']>
    telegramChatId_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    telegramChatId_starts_with?: InputMaybe<Scalars['String']['input']>
    telegramChatId_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    user?: InputMaybe<UserWhereInput>
    user_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type TelegramUserChatWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type TelegramUserChatsCreateInput = {
    data?: InputMaybe<TelegramUserChatCreateInput>
}

export type TelegramUserChatsUpdateInput = {
    data?: InputMaybe<TelegramUserChatUpdateInput>
    id: Scalars['ID']['input']
}

/**  Users request or contact with the user. It has fields `clientName`, `clientPhone`, `clientEmail`, which stores contact information at the moment of creating or updating. Values of these fields are independent from related entities, like Contact, Resident etc. If by some reason related entities will be deleted, unavailable or will change its contact information, these fields will stay unchanged.So, by creating a new ticket with connection to some contact entity (Contact, Resident), these fields will be populated by its contact information if other values are not explicitly provided.  */
export type Ticket = {
    __typename?: 'Ticket'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the Ticket List config, or
   *  2. As an alias to the field set on 'labelField' in the Ticket List config, or
   *  3. As an alias to a 'name' field on the Ticket List (if one exists), or
   *  4. As an alias to the 'id' field on the Ticket List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  Assignee/responsible employee/user who must ensure that the issue is fulfilled  */
    assignee?: Maybe<User>
    /**  Determines if a resident in the mobile app can see the ticket created in crm  */
    canReadByResident?: Maybe<Scalars['Boolean']['output']>
    /**  @deprecated  */
    categoryClassifier?: Maybe<TicketCategoryClassifier>
    /**  Valid combination of 3 classifiers  */
    classifier?: Maybe<TicketClassifier>
    /**  Inhabitant/customer/person who has a problem or want to improve/order something. Not null if we have a registered client. This field indicates, that the Ticket is visible to a Resident and it has access to it. This field will be set to User of corresponding Resident in following cases: 1) the Ticket was created by Resident from mobile app;2) the Ticket was created by OrganizationEmployee with phone number, that matches some Resident;3) a Resident was registered after this Ticket was created and the Resident have the same phone number as in this Ticket, so this Ticket will be automatically connected to it.  */
    client?: Maybe<User>
    /**  Inhabitant/customer/person who has a problem. Sometimes we get a problem from an unregistered client, in such cases we have a null inside the `client` and just have something here. Or sometimes clients want to change it  */
    clientEmail?: Maybe<Scalars['String']['output']>
    /**  Inhabitant/customer/person who has a problem. Sometimes we get a problem from an unregistered client, in such cases we have a null inside the `client` and just have something here. Or sometimes clients want to change it  */
    clientName?: Maybe<Scalars['String']['output']>
    /**  Inhabitant/customer/person who has a problem. Sometimes we get a problem from an unregistered client, in such cases we have a null inside the `client` and just have something here. Or sometimes clients want to change it  */
    clientPhone?: Maybe<Scalars['String']['output']>
    /**  When status of the ticket was changed to completed  */
    completedAt?: Maybe<Scalars['String']['output']>
    /**  Contact, that reported issue, described in this ticket  */
    contact?: Maybe<Contact>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    /**  Time after which the ticket must be completed  */
    deadline?: Maybe<Scalars['String']['output']>
    /**  Date until which the ticket is deferred  */
    deferredUntil?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Text description of the issue. Maybe written by a user or an operator  */
    details?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  Executor employee/user who perform the issue  */
    executor?: Maybe<User>
    /**  Feedback additional options that extend it.Duplicates are removed and empty arrays are converted to null.  */
    feedbackAdditionalOptions?: Maybe<Array<Maybe<FeedbackAdditionalOptionsType>>>
    /**  Staff's comment on ticket feedback  */
    feedbackComment?: Maybe<Scalars['String']['output']>
    /**  Feedback update time  */
    feedbackUpdatedAt?: Maybe<Scalars['String']['output']>
    /**  Feedback of the ticket by a resident on a 2-point scale (0 – ticket returned, 1 – bad review, 2 – good review)  */
    feedbackValue?: Maybe<TicketFeedbackValueType>
    /**  Floor of an apartment building (property). You need to take from Property.map  */
    floorName?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    /**  Indicates that ticket has been classified automatically without human confirmation  */
    isAutoClassified?: Maybe<Scalars['Boolean']['output']>
    /**  (Auto-set) Used to filter tickets that were completed (,closed or cancelled) after the deadline.   */
    isCompletedAfterDeadline?: Maybe<Scalars['Boolean']['output']>
    /**  Indicates the ticket is emergency  */
    isEmergency?: Maybe<Scalars['Boolean']['output']>
    /**
   *  @deprecated Please use "isPayable"
   * Indicates the ticket is payable
   */
    isPaid?: Maybe<Scalars['Boolean']['output']>
    /**  Indicates the ticket is payable  */
    isPayable?: Maybe<Scalars['Boolean']['output']>
    /**  Determines who the ticket was created for: for a resident or not for a resident  */
    isResidentTicket?: Maybe<Scalars['Boolean']['output']>
    /**  Indicates the ticket is warranty  */
    isWarranty?: Maybe<Scalars['Boolean']['output']>
    /**  Last comment time in ticket  */
    lastCommentAt?: Maybe<Scalars['String']['output']>
    /**  Time of the last comment with resident type (from staff or resident user) in ticket  */
    lastCommentWithResidentTypeAt?: Maybe<Scalars['String']['output']>
    /**  Time of the last comment with resident author in ticket  */
    lastResidentCommentAt?: Maybe<Scalars['String']['output']>
    /**  Extra analytics not related to remote system  */
    meta?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Autogenerated ticket human readable ID  */
    number?: Maybe<Scalars['Int']['output']>
    /**  Field required for specific sorting of model objects  */
    order?: Maybe<Scalars['Int']['output']>
    /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
    organization?: Maybe<Organization>
    /**  @deprecated  */
    placeClassifier?: Maybe<TicketPlaceClassifier>
    /**  @deprecated  */
    problemClassifier?: Maybe<TicketProblemClassifier>
    /**  Property related to the Ticket  */
    property?: Maybe<Property>
    /**  Address of property, which synced with property and displayed, if property is deleted  */
    propertyAddress?: Maybe<Scalars['String']['output']>
    /**  Address meta of property, which synced with property and used to form view of address, if property is deleted  */
    propertyAddressMeta?: Maybe<AddressMetaField>
    /**  Quality control additional options that extend it.Duplicates are removed and empty arrays are converted to null.  */
    qualityControlAdditionalOptions?: Maybe<Array<Maybe<QualityControlAdditionalOptionsType>>>
    /**  Staff's comment on ticket review  */
    qualityControlComment?: Maybe<Scalars['String']['output']>
    /**  Quality control updated at time  */
    qualityControlUpdatedAt?: Maybe<Scalars['String']['output']>
    /**  User who last updated quality control value/control/additional options  */
    qualityControlUpdatedBy?: Maybe<User>
    /**  Review of the ticket by a staff on a 2-point scale (bad or good)  */
    qualityControlValue?: Maybe<TicketQualityControlValueType>
    /**  Sometimes, it is important for us to show related issues. For example, to show related issues  */
    related?: Maybe<Ticket>
    /**  @deprecated - use "feedbackAdditionalOptions" and "feedbackComment". This field will be removed soon. Resident's comment on ticket review  */
    reviewComment?: Maybe<Scalars['String']['output']>
    /**  @deprecated - use "feedbackValue". This field will be removed soon. Review of the ticket by a resident on a 2-point scale. 0 – ticket returned, 1 – bad review, 2 – good review  */
    reviewValue?: Maybe<TicketReviewValueType>
    /**  Section name/number of an apartment building (property). You need to take from Property.map  */
    sectionName?: Maybe<Scalars['String']['output']>
    /**  Type of section, such as parking or section. Default value: "section"  */
    sectionType?: Maybe<TicketSectionTypeType>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Ticket source channel/system. Examples: call, email, visit, ...  */
    source?: Maybe<TicketSource>
    /**  In the case of remote system sync, you can store some extra analytics. Examples: email, name, phone, ...  */
    sourceMeta?: Maybe<Scalars['JSON']['output']>
    /**  Status is the step of the ticket processing workflow. Companies may have different ticket processing workflows  */
    status?: Maybe<TicketStatus>
    /**  Text reason for status changes. Sometimes you should describe the reason why you change the `status`  */
    statusReason?: Maybe<Scalars['String']['output']>
    /**  Counter showing the number of changes `status` to `new_or_reopened`  */
    statusReopenedCounter?: Maybe<Scalars['Int']['output']>
    /**  Status updated at time  */
    statusUpdatedAt?: Maybe<Scalars['String']['output']>
    /**  Flat number / door number of an apartment building (property). You need to take from Property.map  */
    unitName?: Maybe<Scalars['String']['output']>
    /**  Type of unit, such as parking lot or flat. Default value: "flat"  */
    unitType?: Maybe<TicketUnitTypeType>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export enum TicketAnalyticsGroupBy {
    Assignee = 'assignee',
    CategoryClassifier = 'categoryClassifier',
    Day = 'day',
    Executor = 'executor',
    Month = 'month',
    Property = 'property',
    QualityControlValue = 'qualityControlValue',
    Status = 'status',
    Week = 'week',
}

export type TicketAnalyticsNullReplaces = {
    assignee: Scalars['String']['input']
    categoryClassifier: Scalars['String']['input']
    executor: Scalars['String']['input']
}

export type TicketAnalyticsReportInput = {
    groupBy?: InputMaybe<Array<TicketAnalyticsGroupBy>>
    nullReplaces: TicketAnalyticsNullReplaces
    where: TicketWhereInput
}

export type TicketAnalyticsReportOutput = {
    __typename?: 'TicketAnalyticsReportOutput'
    groups?: Maybe<Array<TicketGroupedCounter>>
    ticketLabels?: Maybe<Array<Maybe<TicketLabel>>>
}

/**  This schema helps decides who should be assigned to ticket as executor and assignee  */
export type TicketAutoAssignment = {
    __typename?: 'TicketAutoAssignment'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketAutoAssignment List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketAutoAssignment List config, or
   *  3. As an alias to a 'name' field on the TicketAutoAssignment List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketAutoAssignment List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  An employee who will be appointed as assignee of ticket. If "null", then the field will remain empty and dispatcher will have to fill it in independently in UI  */
    assignee?: Maybe<OrganizationEmployee>
    /**  Ticket classifier  */
    classifier?: Maybe<TicketClassifier>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  An employee who will be appointed as executor of ticketIf "null", then the field will remain empty and dispatcher will have to fill it in independently in UI  */
    executor?: Maybe<OrganizationEmployee>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['String']['output']>
    /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
    organization?: Maybe<Organization>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type TicketAutoAssignmentCreateInput = {
    assignee?: InputMaybe<OrganizationEmployeeRelateToOneInput>
    classifier?: InputMaybe<TicketClassifierRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    executor?: InputMaybe<OrganizationEmployeeRelateToOneInput>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type TicketAutoAssignmentHistoryRecord = {
    __typename?: 'TicketAutoAssignmentHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketAutoAssignmentHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketAutoAssignmentHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the TicketAutoAssignmentHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketAutoAssignmentHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    assignee?: Maybe<Scalars['String']['output']>
    classifier?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    executor?: Maybe<Scalars['String']['output']>
    history_action?: Maybe<TicketAutoAssignmentHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type TicketAutoAssignmentHistoryRecordCreateInput = {
    assignee?: InputMaybe<Scalars['String']['input']>
    classifier?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    executor?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<TicketAutoAssignmentHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum TicketAutoAssignmentHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type TicketAutoAssignmentHistoryRecordUpdateInput = {
    assignee?: InputMaybe<Scalars['String']['input']>
    classifier?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    executor?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<TicketAutoAssignmentHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type TicketAutoAssignmentHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<TicketAutoAssignmentHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<TicketAutoAssignmentHistoryRecordWhereInput>>>
    assignee?: InputMaybe<Scalars['String']['input']>
    assignee_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    assignee_not?: InputMaybe<Scalars['String']['input']>
    assignee_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    classifier?: InputMaybe<Scalars['String']['input']>
    classifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    classifier_not?: InputMaybe<Scalars['String']['input']>
    classifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    executor?: InputMaybe<Scalars['String']['input']>
    executor_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    executor_not?: InputMaybe<Scalars['String']['input']>
    executor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_action?: InputMaybe<TicketAutoAssignmentHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<TicketAutoAssignmentHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<TicketAutoAssignmentHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<TicketAutoAssignmentHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type TicketAutoAssignmentHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type TicketAutoAssignmentHistoryRecordsCreateInput = {
    data?: InputMaybe<TicketAutoAssignmentHistoryRecordCreateInput>
}

export type TicketAutoAssignmentHistoryRecordsUpdateInput = {
    data?: InputMaybe<TicketAutoAssignmentHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type TicketAutoAssignmentUpdateInput = {
    assignee?: InputMaybe<OrganizationEmployeeRelateToOneInput>
    classifier?: InputMaybe<TicketClassifierRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    executor?: InputMaybe<OrganizationEmployeeRelateToOneInput>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type TicketAutoAssignmentWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<TicketAutoAssignmentWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<TicketAutoAssignmentWhereInput>>>
    assignee?: InputMaybe<OrganizationEmployeeWhereInput>
    assignee_is_null?: InputMaybe<Scalars['Boolean']['input']>
    classifier?: InputMaybe<TicketClassifierWhereInput>
    classifier_is_null?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    executor?: InputMaybe<OrganizationEmployeeWhereInput>
    executor_is_null?: InputMaybe<Scalars['Boolean']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type TicketAutoAssignmentWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type TicketAutoAssignmentsCreateInput = {
    data?: InputMaybe<TicketAutoAssignmentCreateInput>
}

export type TicketAutoAssignmentsUpdateInput = {
    data?: InputMaybe<TicketAutoAssignmentUpdateInput>
    id: Scalars['ID']['input']
}

/**  Describes what type of work needs to be done to fix incident  */
export type TicketCategoryClassifier = {
    __typename?: 'TicketCategoryClassifier'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketCategoryClassifier List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketCategoryClassifier List config, or
   *  3. As an alias to a 'name' field on the TicketCategoryClassifier List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketCategoryClassifier List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  text description  */
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Ref to the organization. If this ref is null the object is common for all organizations  */
    organization?: Maybe<Organization>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type TicketCategoryClassifierCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type TicketCategoryClassifierHistoryRecord = {
    __typename?: 'TicketCategoryClassifierHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketCategoryClassifierHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketCategoryClassifierHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the TicketCategoryClassifierHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketCategoryClassifierHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<TicketCategoryClassifierHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type TicketCategoryClassifierHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<TicketCategoryClassifierHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum TicketCategoryClassifierHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type TicketCategoryClassifierHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<TicketCategoryClassifierHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type TicketCategoryClassifierHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<TicketCategoryClassifierHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<TicketCategoryClassifierHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<TicketCategoryClassifierHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<TicketCategoryClassifierHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<TicketCategoryClassifierHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<TicketCategoryClassifierHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type TicketCategoryClassifierHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type TicketCategoryClassifierHistoryRecordsCreateInput = {
    data?: InputMaybe<TicketCategoryClassifierHistoryRecordCreateInput>
}

export type TicketCategoryClassifierHistoryRecordsUpdateInput = {
    data?: InputMaybe<TicketCategoryClassifierHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type TicketCategoryClassifierRelateToOneInput = {
    connect?: InputMaybe<TicketCategoryClassifierWhereUniqueInput>
    create?: InputMaybe<TicketCategoryClassifierCreateInput>
    disconnect?: InputMaybe<TicketCategoryClassifierWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type TicketCategoryClassifierUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type TicketCategoryClassifierWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<TicketCategoryClassifierWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<TicketCategoryClassifierWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type TicketCategoryClassifierWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type TicketCategoryClassifiersCreateInput = {
    data?: InputMaybe<TicketCategoryClassifierCreateInput>
}

export type TicketCategoryClassifiersUpdateInput = {
    data?: InputMaybe<TicketCategoryClassifierUpdateInput>
    id: Scalars['ID']['input']
}

/**  Incremental changes of Ticket  */
export type TicketChange = {
    __typename?: 'TicketChange'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketChange List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketChange List config, or
   *  3. As an alias to a 'name' field on the TicketChange List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketChange List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  Actual creation ticket change date, for case when ticket updated from offline  */
    actualCreationDate?: Maybe<Scalars['String']['output']>
    /**  Old display name of related entity. Assignee/responsible employee/user who must ensure that the issue is fulfilled  */
    assigneeDisplayNameFrom?: Maybe<Scalars['String']['output']>
    /**  New display name of related entity. Assignee/responsible employee/user who must ensure that the issue is fulfilled  */
    assigneeDisplayNameTo?: Maybe<Scalars['String']['output']>
    /**  Old id of related entity. Assignee/responsible employee/user who must ensure that the issue is fulfilled  */
    assigneeIdFrom?: Maybe<Scalars['ID']['output']>
    /**  New id of related entity. Assignee/responsible employee/user who must ensure that the issue is fulfilled  */
    assigneeIdTo?: Maybe<Scalars['ID']['output']>
    /**  Determines if a resident in the mobile app can see the ticket created in crm  */
    canReadByResidentFrom?: Maybe<Scalars['Boolean']['output']>
    /**  Determines if a resident in the mobile app can see the ticket created in crm  */
    canReadByResidentTo?: Maybe<Scalars['Boolean']['output']>
    /**  Type of user who changed the ticket, can be employee role from same organization or related, resident or deleted employee  */
    changedByRole?: Maybe<Scalars['String']['output']>
    /**  Old display name of related entity. Valid combination of 3 classifiers  */
    classifierDisplayNameFrom?: Maybe<Scalars['String']['output']>
    /**  New display name of related entity. Valid combination of 3 classifiers  */
    classifierDisplayNameTo?: Maybe<Scalars['String']['output']>
    /**  Old id of related entity. Valid combination of 3 classifiers  */
    classifierIdFrom?: Maybe<Scalars['ID']['output']>
    /**  New id of related entity. Valid combination of 3 classifiers  */
    classifierIdTo?: Maybe<Scalars['ID']['output']>
    /**  Old display name of related entity. Inhabitant/customer/person who has a problem or want to improve/order something. Not null if we have a registered client. This field indicates, that the Ticket is visible to a Resident and it has access to it. This field will be set to User of corresponding Resident in following cases: 1) the Ticket was created by Resident from mobile app;2) the Ticket was created by OrganizationEmployee with phone number, that matches some Resident;3) a Resident was registered after this Ticket was created and the Resident have the same phone number as in this Ticket, so this Ticket will be automatically connected to it.  */
    clientDisplayNameFrom?: Maybe<Scalars['String']['output']>
    /**  New display name of related entity. Inhabitant/customer/person who has a problem or want to improve/order something. Not null if we have a registered client. This field indicates, that the Ticket is visible to a Resident and it has access to it. This field will be set to User of corresponding Resident in following cases: 1) the Ticket was created by Resident from mobile app;2) the Ticket was created by OrganizationEmployee with phone number, that matches some Resident;3) a Resident was registered after this Ticket was created and the Resident have the same phone number as in this Ticket, so this Ticket will be automatically connected to it.  */
    clientDisplayNameTo?: Maybe<Scalars['String']['output']>
    /**  Inhabitant/customer/person who has a problem. Sometimes we get a problem from an unregistered client, in such cases we have a null inside the `client` and just have something here. Or sometimes clients want to change it  */
    clientEmailFrom?: Maybe<Scalars['String']['output']>
    /**  Inhabitant/customer/person who has a problem. Sometimes we get a problem from an unregistered client, in such cases we have a null inside the `client` and just have something here. Or sometimes clients want to change it  */
    clientEmailTo?: Maybe<Scalars['String']['output']>
    /**  Old id of related entity. Inhabitant/customer/person who has a problem or want to improve/order something. Not null if we have a registered client. This field indicates, that the Ticket is visible to a Resident and it has access to it. This field will be set to User of corresponding Resident in following cases: 1) the Ticket was created by Resident from mobile app;2) the Ticket was created by OrganizationEmployee with phone number, that matches some Resident;3) a Resident was registered after this Ticket was created and the Resident have the same phone number as in this Ticket, so this Ticket will be automatically connected to it.  */
    clientIdFrom?: Maybe<Scalars['ID']['output']>
    /**  New id of related entity. Inhabitant/customer/person who has a problem or want to improve/order something. Not null if we have a registered client. This field indicates, that the Ticket is visible to a Resident and it has access to it. This field will be set to User of corresponding Resident in following cases: 1) the Ticket was created by Resident from mobile app;2) the Ticket was created by OrganizationEmployee with phone number, that matches some Resident;3) a Resident was registered after this Ticket was created and the Resident have the same phone number as in this Ticket, so this Ticket will be automatically connected to it.  */
    clientIdTo?: Maybe<Scalars['ID']['output']>
    /**  Inhabitant/customer/person who has a problem. Sometimes we get a problem from an unregistered client, in such cases we have a null inside the `client` and just have something here. Or sometimes clients want to change it  */
    clientNameFrom?: Maybe<Scalars['String']['output']>
    /**  Inhabitant/customer/person who has a problem. Sometimes we get a problem from an unregistered client, in such cases we have a null inside the `client` and just have something here. Or sometimes clients want to change it  */
    clientNameTo?: Maybe<Scalars['String']['output']>
    /**  Inhabitant/customer/person who has a problem. Sometimes we get a problem from an unregistered client, in such cases we have a null inside the `client` and just have something here. Or sometimes clients want to change it  */
    clientPhoneFrom?: Maybe<Scalars['String']['output']>
    /**  Inhabitant/customer/person who has a problem. Sometimes we get a problem from an unregistered client, in such cases we have a null inside the `client` and just have something here. Or sometimes clients want to change it  */
    clientPhoneTo?: Maybe<Scalars['String']['output']>
    /**  Old display name of related entity. Contact, that reported issue, described in this ticket  */
    contactDisplayNameFrom?: Maybe<Scalars['String']['output']>
    /**  New display name of related entity. Contact, that reported issue, described in this ticket  */
    contactDisplayNameTo?: Maybe<Scalars['String']['output']>
    /**  Old id of related entity. Contact, that reported issue, described in this ticket  */
    contactIdFrom?: Maybe<Scalars['ID']['output']>
    /**  New id of related entity. Contact, that reported issue, described in this ticket  */
    contactIdTo?: Maybe<Scalars['ID']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    /**  Time after which the ticket must be completed  */
    deadlineFrom?: Maybe<Scalars['String']['output']>
    /**  Time after which the ticket must be completed  */
    deadlineTo?: Maybe<Scalars['String']['output']>
    /**  Date until which the ticket is deferred  */
    deferredUntilFrom?: Maybe<Scalars['String']['output']>
    /**  Date until which the ticket is deferred  */
    deferredUntilTo?: Maybe<Scalars['String']['output']>
    /**  Text description of the issue. Maybe written by a user or an operator  */
    detailsFrom?: Maybe<Scalars['String']['output']>
    /**  Text description of the issue. Maybe written by a user or an operator  */
    detailsTo?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  Old display name of related entity. Executor employee/user who perform the issue  */
    executorDisplayNameFrom?: Maybe<Scalars['String']['output']>
    /**  New display name of related entity. Executor employee/user who perform the issue  */
    executorDisplayNameTo?: Maybe<Scalars['String']['output']>
    /**  Old id of related entity. Executor employee/user who perform the issue  */
    executorIdFrom?: Maybe<Scalars['ID']['output']>
    /**  New id of related entity. Executor employee/user who perform the issue  */
    executorIdTo?: Maybe<Scalars['ID']['output']>
    /**  Feedback additional options that extend it.Duplicates are removed and empty arrays are converted to null.  */
    feedbackAdditionalOptionsFrom?: Maybe<Scalars['JSON']['output']>
    /**  Feedback additional options that extend it.Duplicates are removed and empty arrays are converted to null.  */
    feedbackAdditionalOptionsTo?: Maybe<Scalars['JSON']['output']>
    /**  Staff's comment on ticket feedback  */
    feedbackCommentFrom?: Maybe<Scalars['String']['output']>
    /**  Staff's comment on ticket feedback  */
    feedbackCommentTo?: Maybe<Scalars['String']['output']>
    /**  Feedback of the ticket by a resident on a 2-point scale (0 – ticket returned, 1 – bad review, 2 – good review)  */
    feedbackValueFrom?: Maybe<TicketChangeFeedbackValueFromType>
    /**  Feedback of the ticket by a resident on a 2-point scale (0 – ticket returned, 1 – bad review, 2 – good review)  */
    feedbackValueTo?: Maybe<TicketChangeFeedbackValueToType>
    /**  Floor of an apartment building (property). You need to take from Property.map  */
    floorNameFrom?: Maybe<Scalars['String']['output']>
    /**  Floor of an apartment building (property). You need to take from Property.map  */
    floorNameTo?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    /**  Indicates the ticket is emergency  */
    isEmergencyFrom?: Maybe<Scalars['Boolean']['output']>
    /**  Indicates the ticket is emergency  */
    isEmergencyTo?: Maybe<Scalars['Boolean']['output']>
    /**
   *  @deprecated Please use "isPayable"
   * Indicates the ticket is payable
   */
    isPaidFrom?: Maybe<Scalars['Boolean']['output']>
    /**
   *  @deprecated Please use "isPayable"
   * Indicates the ticket is payable
   */
    isPaidTo?: Maybe<Scalars['Boolean']['output']>
    /**  Indicates the ticket is payable  */
    isPayableFrom?: Maybe<Scalars['Boolean']['output']>
    /**  Indicates the ticket is payable  */
    isPayableTo?: Maybe<Scalars['Boolean']['output']>
    /**  Determines who the ticket was created for: for a resident or not for a resident  */
    isResidentTicketFrom?: Maybe<Scalars['Boolean']['output']>
    /**  Determines who the ticket was created for: for a resident or not for a resident  */
    isResidentTicketTo?: Maybe<Scalars['Boolean']['output']>
    /**  Indicates the ticket is warranty  */
    isWarrantyFrom?: Maybe<Scalars['Boolean']['output']>
    /**  Indicates the ticket is warranty  */
    isWarrantyTo?: Maybe<Scalars['Boolean']['output']>
    /**  Extra analytics not related to remote system  */
    metaFrom?: Maybe<Scalars['JSON']['output']>
    /**  Extra analytics not related to remote system  */
    metaTo?: Maybe<Scalars['JSON']['output']>
    /**  Old display name of related entity. Ref to the organization. The object will be deleted if the organization ceases to exist  */
    organizationDisplayNameFrom?: Maybe<Scalars['String']['output']>
    /**  New display name of related entity. Ref to the organization. The object will be deleted if the organization ceases to exist  */
    organizationDisplayNameTo?: Maybe<Scalars['String']['output']>
    /**  Old id of related entity. Ref to the organization. The object will be deleted if the organization ceases to exist  */
    organizationIdFrom?: Maybe<Scalars['ID']['output']>
    /**  New id of related entity. Ref to the organization. The object will be deleted if the organization ceases to exist  */
    organizationIdTo?: Maybe<Scalars['ID']['output']>
    /**  Address of property, which synced with property and displayed, if property is deleted  */
    propertyAddressFrom?: Maybe<Scalars['String']['output']>
    /**  Address meta of property, which synced with property and used to form view of address, if property is deleted  */
    propertyAddressMetaFrom?: Maybe<Scalars['JSON']['output']>
    /**  Address meta of property, which synced with property and used to form view of address, if property is deleted  */
    propertyAddressMetaTo?: Maybe<Scalars['JSON']['output']>
    /**  Address of property, which synced with property and displayed, if property is deleted  */
    propertyAddressTo?: Maybe<Scalars['String']['output']>
    /**  Old display name of related entity. Property related to the Ticket  */
    propertyDisplayNameFrom?: Maybe<Scalars['String']['output']>
    /**  New display name of related entity. Property related to the Ticket  */
    propertyDisplayNameTo?: Maybe<Scalars['String']['output']>
    /**  Old id of related entity. Property related to the Ticket  */
    propertyIdFrom?: Maybe<Scalars['ID']['output']>
    /**  New id of related entity. Property related to the Ticket  */
    propertyIdTo?: Maybe<Scalars['ID']['output']>
    /**  Quality control additional options that extend it.Duplicates are removed and empty arrays are converted to null.  */
    qualityControlAdditionalOptionsFrom?: Maybe<Scalars['JSON']['output']>
    /**  Quality control additional options that extend it.Duplicates are removed and empty arrays are converted to null.  */
    qualityControlAdditionalOptionsTo?: Maybe<Scalars['JSON']['output']>
    /**  Staff's comment on ticket review  */
    qualityControlCommentFrom?: Maybe<Scalars['String']['output']>
    /**  Staff's comment on ticket review  */
    qualityControlCommentTo?: Maybe<Scalars['String']['output']>
    /**  Review of the ticket by a staff on a 2-point scale (bad or good)  */
    qualityControlValueFrom?: Maybe<TicketChangeQualityControlValueFromType>
    /**  Review of the ticket by a staff on a 2-point scale (bad or good)  */
    qualityControlValueTo?: Maybe<TicketChangeQualityControlValueToType>
    /**  Old display name of related entity. Sometimes, it is important for us to show related issues. For example, to show related issues  */
    relatedDisplayNameFrom?: Maybe<Scalars['String']['output']>
    /**  New display name of related entity. Sometimes, it is important for us to show related issues. For example, to show related issues  */
    relatedDisplayNameTo?: Maybe<Scalars['String']['output']>
    /**  Old id of related entity. Sometimes, it is important for us to show related issues. For example, to show related issues  */
    relatedIdFrom?: Maybe<Scalars['ID']['output']>
    /**  New id of related entity. Sometimes, it is important for us to show related issues. For example, to show related issues  */
    relatedIdTo?: Maybe<Scalars['ID']['output']>
    /**  @deprecated - use "feedbackAdditionalOptions" and "feedbackComment". This field will be removed soon. Resident's comment on ticket review  */
    reviewCommentFrom?: Maybe<Scalars['String']['output']>
    /**  @deprecated - use "feedbackAdditionalOptions" and "feedbackComment". This field will be removed soon. Resident's comment on ticket review  */
    reviewCommentTo?: Maybe<Scalars['String']['output']>
    /**  @deprecated - use "feedbackValue". This field will be removed soon. Review of the ticket by a resident on a 2-point scale. 0 – ticket returned, 1 – bad review, 2 – good review  */
    reviewValueFrom?: Maybe<TicketChangeReviewValueFromType>
    /**  @deprecated - use "feedbackValue". This field will be removed soon. Review of the ticket by a resident on a 2-point scale. 0 – ticket returned, 1 – bad review, 2 – good review  */
    reviewValueTo?: Maybe<TicketChangeReviewValueToType>
    /**  Section name/number of an apartment building (property). You need to take from Property.map  */
    sectionNameFrom?: Maybe<Scalars['String']['output']>
    /**  Section name/number of an apartment building (property). You need to take from Property.map  */
    sectionNameTo?: Maybe<Scalars['String']['output']>
    /**  Type of section, such as parking or section. Default value: "section"  */
    sectionTypeFrom?: Maybe<TicketChangeSectionTypeFromType>
    /**  Type of section, such as parking or section. Default value: "section"  */
    sectionTypeTo?: Maybe<TicketChangeSectionTypeToType>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Old display name of related entity. Ticket source channel/system. Examples: call, email, visit, ...  */
    sourceDisplayNameFrom?: Maybe<Scalars['String']['output']>
    sourceDisplayNameFromNonLocalized?: Maybe<Scalars['String']['output']>
    /**  New display name of related entity. Ticket source channel/system. Examples: call, email, visit, ...  */
    sourceDisplayNameTo?: Maybe<Scalars['String']['output']>
    sourceDisplayNameToNonLocalized?: Maybe<Scalars['String']['output']>
    /**  Old id of related entity. Ticket source channel/system. Examples: call, email, visit, ...  */
    sourceIdFrom?: Maybe<Scalars['ID']['output']>
    /**  New id of related entity. Ticket source channel/system. Examples: call, email, visit, ...  */
    sourceIdTo?: Maybe<Scalars['ID']['output']>
    /**  In the case of remote system sync, you can store some extra analytics. Examples: email, name, phone, ...  */
    sourceMetaFrom?: Maybe<Scalars['JSON']['output']>
    /**  In the case of remote system sync, you can store some extra analytics. Examples: email, name, phone, ...  */
    sourceMetaTo?: Maybe<Scalars['JSON']['output']>
    /**  Old display name of related entity. Status is the step of the ticket processing workflow. Companies may have different ticket processing workflows  */
    statusDisplayNameFrom?: Maybe<Scalars['String']['output']>
    statusDisplayNameFromNonLocalized?: Maybe<Scalars['String']['output']>
    /**  New display name of related entity. Status is the step of the ticket processing workflow. Companies may have different ticket processing workflows  */
    statusDisplayNameTo?: Maybe<Scalars['String']['output']>
    statusDisplayNameToNonLocalized?: Maybe<Scalars['String']['output']>
    /**  Old id of related entity. Status is the step of the ticket processing workflow. Companies may have different ticket processing workflows  */
    statusIdFrom?: Maybe<Scalars['ID']['output']>
    /**  New id of related entity. Status is the step of the ticket processing workflow. Companies may have different ticket processing workflows  */
    statusIdTo?: Maybe<Scalars['ID']['output']>
    /**  Text reason for status changes. Sometimes you should describe the reason why you change the `status`  */
    statusReasonFrom?: Maybe<Scalars['String']['output']>
    /**  Text reason for status changes. Sometimes you should describe the reason why you change the `status`  */
    statusReasonTo?: Maybe<Scalars['String']['output']>
    /**  Counter showing the number of changes `status` to `new_or_reopened`  */
    statusReopenedCounterFrom?: Maybe<Scalars['Int']['output']>
    /**  Counter showing the number of changes `status` to `new_or_reopened`  */
    statusReopenedCounterTo?: Maybe<Scalars['Int']['output']>
    /**  Related ticket, whose change is logged in this entity  */
    ticket?: Maybe<Ticket>
    /**  Flat number / door number of an apartment building (property). You need to take from Property.map  */
    unitNameFrom?: Maybe<Scalars['String']['output']>
    /**  Flat number / door number of an apartment building (property). You need to take from Property.map  */
    unitNameTo?: Maybe<Scalars['String']['output']>
    /**  Type of unit, such as parking lot or flat. Default value: "flat"  */
    unitTypeFrom?: Maybe<TicketChangeUnitTypeFromType>
    /**  Type of unit, such as parking lot or flat. Default value: "flat"  */
    unitTypeTo?: Maybe<TicketChangeUnitTypeToType>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type TicketChangeCreateInput = {
    actualCreationDate?: InputMaybe<Scalars['String']['input']>
    assigneeDisplayNameFrom?: InputMaybe<Scalars['String']['input']>
    assigneeDisplayNameTo?: InputMaybe<Scalars['String']['input']>
    assigneeIdFrom?: InputMaybe<Scalars['ID']['input']>
    assigneeIdTo?: InputMaybe<Scalars['ID']['input']>
    canReadByResidentFrom?: InputMaybe<Scalars['Boolean']['input']>
    canReadByResidentTo?: InputMaybe<Scalars['Boolean']['input']>
    classifierDisplayNameFrom?: InputMaybe<Scalars['String']['input']>
    classifierDisplayNameTo?: InputMaybe<Scalars['String']['input']>
    classifierIdFrom?: InputMaybe<Scalars['ID']['input']>
    classifierIdTo?: InputMaybe<Scalars['ID']['input']>
    clientDisplayNameFrom?: InputMaybe<Scalars['String']['input']>
    clientDisplayNameTo?: InputMaybe<Scalars['String']['input']>
    clientEmailFrom?: InputMaybe<Scalars['String']['input']>
    clientEmailTo?: InputMaybe<Scalars['String']['input']>
    clientIdFrom?: InputMaybe<Scalars['ID']['input']>
    clientIdTo?: InputMaybe<Scalars['ID']['input']>
    clientNameFrom?: InputMaybe<Scalars['String']['input']>
    clientNameTo?: InputMaybe<Scalars['String']['input']>
    clientPhoneFrom?: InputMaybe<Scalars['String']['input']>
    clientPhoneTo?: InputMaybe<Scalars['String']['input']>
    contactDisplayNameFrom?: InputMaybe<Scalars['String']['input']>
    contactDisplayNameTo?: InputMaybe<Scalars['String']['input']>
    contactIdFrom?: InputMaybe<Scalars['ID']['input']>
    contactIdTo?: InputMaybe<Scalars['ID']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deadlineFrom?: InputMaybe<Scalars['String']['input']>
    deadlineTo?: InputMaybe<Scalars['String']['input']>
    deferredUntilFrom?: InputMaybe<Scalars['String']['input']>
    deferredUntilTo?: InputMaybe<Scalars['String']['input']>
    detailsFrom?: InputMaybe<Scalars['String']['input']>
    detailsTo?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    executorDisplayNameFrom?: InputMaybe<Scalars['String']['input']>
    executorDisplayNameTo?: InputMaybe<Scalars['String']['input']>
    executorIdFrom?: InputMaybe<Scalars['ID']['input']>
    executorIdTo?: InputMaybe<Scalars['ID']['input']>
    feedbackAdditionalOptionsFrom?: InputMaybe<Scalars['JSON']['input']>
    feedbackAdditionalOptionsTo?: InputMaybe<Scalars['JSON']['input']>
    feedbackCommentFrom?: InputMaybe<Scalars['String']['input']>
    feedbackCommentTo?: InputMaybe<Scalars['String']['input']>
    feedbackValueFrom?: InputMaybe<TicketChangeFeedbackValueFromType>
    feedbackValueTo?: InputMaybe<TicketChangeFeedbackValueToType>
    floorNameFrom?: InputMaybe<Scalars['String']['input']>
    floorNameTo?: InputMaybe<Scalars['String']['input']>
    isEmergencyFrom?: InputMaybe<Scalars['Boolean']['input']>
    isEmergencyTo?: InputMaybe<Scalars['Boolean']['input']>
    isPaidFrom?: InputMaybe<Scalars['Boolean']['input']>
    isPaidTo?: InputMaybe<Scalars['Boolean']['input']>
    isPayableFrom?: InputMaybe<Scalars['Boolean']['input']>
    isPayableTo?: InputMaybe<Scalars['Boolean']['input']>
    isResidentTicketFrom?: InputMaybe<Scalars['Boolean']['input']>
    isResidentTicketTo?: InputMaybe<Scalars['Boolean']['input']>
    isWarrantyFrom?: InputMaybe<Scalars['Boolean']['input']>
    isWarrantyTo?: InputMaybe<Scalars['Boolean']['input']>
    metaFrom?: InputMaybe<Scalars['JSON']['input']>
    metaTo?: InputMaybe<Scalars['JSON']['input']>
    organizationDisplayNameFrom?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameTo?: InputMaybe<Scalars['String']['input']>
    organizationIdFrom?: InputMaybe<Scalars['ID']['input']>
    organizationIdTo?: InputMaybe<Scalars['ID']['input']>
    propertyAddressFrom?: InputMaybe<Scalars['String']['input']>
    propertyAddressMetaFrom?: InputMaybe<Scalars['JSON']['input']>
    propertyAddressMetaTo?: InputMaybe<Scalars['JSON']['input']>
    propertyAddressTo?: InputMaybe<Scalars['String']['input']>
    propertyDisplayNameFrom?: InputMaybe<Scalars['String']['input']>
    propertyDisplayNameTo?: InputMaybe<Scalars['String']['input']>
    propertyIdFrom?: InputMaybe<Scalars['ID']['input']>
    propertyIdTo?: InputMaybe<Scalars['ID']['input']>
    qualityControlAdditionalOptionsFrom?: InputMaybe<Scalars['JSON']['input']>
    qualityControlAdditionalOptionsTo?: InputMaybe<Scalars['JSON']['input']>
    qualityControlCommentFrom?: InputMaybe<Scalars['String']['input']>
    qualityControlCommentTo?: InputMaybe<Scalars['String']['input']>
    qualityControlValueFrom?: InputMaybe<TicketChangeQualityControlValueFromType>
    qualityControlValueTo?: InputMaybe<TicketChangeQualityControlValueToType>
    relatedDisplayNameFrom?: InputMaybe<Scalars['String']['input']>
    relatedDisplayNameTo?: InputMaybe<Scalars['String']['input']>
    relatedIdFrom?: InputMaybe<Scalars['ID']['input']>
    relatedIdTo?: InputMaybe<Scalars['ID']['input']>
    reviewCommentFrom?: InputMaybe<Scalars['String']['input']>
    reviewCommentTo?: InputMaybe<Scalars['String']['input']>
    reviewValueFrom?: InputMaybe<TicketChangeReviewValueFromType>
    reviewValueTo?: InputMaybe<TicketChangeReviewValueToType>
    sectionNameFrom?: InputMaybe<Scalars['String']['input']>
    sectionNameTo?: InputMaybe<Scalars['String']['input']>
    sectionTypeFrom?: InputMaybe<TicketChangeSectionTypeFromType>
    sectionTypeTo?: InputMaybe<TicketChangeSectionTypeToType>
    sender?: InputMaybe<SenderFieldInput>
    sourceDisplayNameFrom?: InputMaybe<Scalars['String']['input']>
    sourceDisplayNameTo?: InputMaybe<Scalars['String']['input']>
    sourceIdFrom?: InputMaybe<Scalars['ID']['input']>
    sourceIdTo?: InputMaybe<Scalars['ID']['input']>
    sourceMetaFrom?: InputMaybe<Scalars['JSON']['input']>
    sourceMetaTo?: InputMaybe<Scalars['JSON']['input']>
    statusDisplayNameFrom?: InputMaybe<Scalars['String']['input']>
    statusDisplayNameTo?: InputMaybe<Scalars['String']['input']>
    statusIdFrom?: InputMaybe<Scalars['ID']['input']>
    statusIdTo?: InputMaybe<Scalars['ID']['input']>
    statusReasonFrom?: InputMaybe<Scalars['String']['input']>
    statusReasonTo?: InputMaybe<Scalars['String']['input']>
    statusReopenedCounterFrom?: InputMaybe<Scalars['Int']['input']>
    statusReopenedCounterTo?: InputMaybe<Scalars['Int']['input']>
    ticket?: InputMaybe<TicketRelateToOneInput>
    unitNameFrom?: InputMaybe<Scalars['String']['input']>
    unitNameTo?: InputMaybe<Scalars['String']['input']>
    unitTypeFrom?: InputMaybe<TicketChangeUnitTypeFromType>
    unitTypeTo?: InputMaybe<TicketChangeUnitTypeToType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum TicketChangeFeedbackValueFromType {
    Bad = 'bad',
    Good = 'good',
    Returned = 'returned',
}

export enum TicketChangeFeedbackValueToType {
    Bad = 'bad',
    Good = 'good',
    Returned = 'returned',
}

export enum TicketChangeQualityControlValueFromType {
    Bad = 'bad',
    Good = 'good',
}

export enum TicketChangeQualityControlValueToType {
    Bad = 'bad',
    Good = 'good',
}

export enum TicketChangeReviewValueFromType {
    Bad = 'bad',
    Good = 'good',
    Returned = 'returned',
}

export enum TicketChangeReviewValueToType {
    Bad = 'bad',
    Good = 'good',
    Returned = 'returned',
}

export enum TicketChangeSectionTypeFromType {
    Parking = 'parking',
    Section = 'section',
}

export enum TicketChangeSectionTypeToType {
    Parking = 'parking',
    Section = 'section',
}

export enum TicketChangeUnitTypeFromType {
    Apartment = 'apartment',
    Commercial = 'commercial',
    Flat = 'flat',
    Parking = 'parking',
    Warehouse = 'warehouse',
}

export enum TicketChangeUnitTypeToType {
    Apartment = 'apartment',
    Commercial = 'commercial',
    Flat = 'flat',
    Parking = 'parking',
    Warehouse = 'warehouse',
}

export type TicketChangeUpdateInput = {
    actualCreationDate?: InputMaybe<Scalars['String']['input']>
    assigneeDisplayNameFrom?: InputMaybe<Scalars['String']['input']>
    assigneeDisplayNameTo?: InputMaybe<Scalars['String']['input']>
    assigneeIdFrom?: InputMaybe<Scalars['ID']['input']>
    assigneeIdTo?: InputMaybe<Scalars['ID']['input']>
    canReadByResidentFrom?: InputMaybe<Scalars['Boolean']['input']>
    canReadByResidentTo?: InputMaybe<Scalars['Boolean']['input']>
    classifierDisplayNameFrom?: InputMaybe<Scalars['String']['input']>
    classifierDisplayNameTo?: InputMaybe<Scalars['String']['input']>
    classifierIdFrom?: InputMaybe<Scalars['ID']['input']>
    classifierIdTo?: InputMaybe<Scalars['ID']['input']>
    clientDisplayNameFrom?: InputMaybe<Scalars['String']['input']>
    clientDisplayNameTo?: InputMaybe<Scalars['String']['input']>
    clientEmailFrom?: InputMaybe<Scalars['String']['input']>
    clientEmailTo?: InputMaybe<Scalars['String']['input']>
    clientIdFrom?: InputMaybe<Scalars['ID']['input']>
    clientIdTo?: InputMaybe<Scalars['ID']['input']>
    clientNameFrom?: InputMaybe<Scalars['String']['input']>
    clientNameTo?: InputMaybe<Scalars['String']['input']>
    clientPhoneFrom?: InputMaybe<Scalars['String']['input']>
    clientPhoneTo?: InputMaybe<Scalars['String']['input']>
    contactDisplayNameFrom?: InputMaybe<Scalars['String']['input']>
    contactDisplayNameTo?: InputMaybe<Scalars['String']['input']>
    contactIdFrom?: InputMaybe<Scalars['ID']['input']>
    contactIdTo?: InputMaybe<Scalars['ID']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deadlineFrom?: InputMaybe<Scalars['String']['input']>
    deadlineTo?: InputMaybe<Scalars['String']['input']>
    deferredUntilFrom?: InputMaybe<Scalars['String']['input']>
    deferredUntilTo?: InputMaybe<Scalars['String']['input']>
    detailsFrom?: InputMaybe<Scalars['String']['input']>
    detailsTo?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    executorDisplayNameFrom?: InputMaybe<Scalars['String']['input']>
    executorDisplayNameTo?: InputMaybe<Scalars['String']['input']>
    executorIdFrom?: InputMaybe<Scalars['ID']['input']>
    executorIdTo?: InputMaybe<Scalars['ID']['input']>
    feedbackAdditionalOptionsFrom?: InputMaybe<Scalars['JSON']['input']>
    feedbackAdditionalOptionsTo?: InputMaybe<Scalars['JSON']['input']>
    feedbackCommentFrom?: InputMaybe<Scalars['String']['input']>
    feedbackCommentTo?: InputMaybe<Scalars['String']['input']>
    feedbackValueFrom?: InputMaybe<TicketChangeFeedbackValueFromType>
    feedbackValueTo?: InputMaybe<TicketChangeFeedbackValueToType>
    floorNameFrom?: InputMaybe<Scalars['String']['input']>
    floorNameTo?: InputMaybe<Scalars['String']['input']>
    isEmergencyFrom?: InputMaybe<Scalars['Boolean']['input']>
    isEmergencyTo?: InputMaybe<Scalars['Boolean']['input']>
    isPaidFrom?: InputMaybe<Scalars['Boolean']['input']>
    isPaidTo?: InputMaybe<Scalars['Boolean']['input']>
    isPayableFrom?: InputMaybe<Scalars['Boolean']['input']>
    isPayableTo?: InputMaybe<Scalars['Boolean']['input']>
    isResidentTicketFrom?: InputMaybe<Scalars['Boolean']['input']>
    isResidentTicketTo?: InputMaybe<Scalars['Boolean']['input']>
    isWarrantyFrom?: InputMaybe<Scalars['Boolean']['input']>
    isWarrantyTo?: InputMaybe<Scalars['Boolean']['input']>
    metaFrom?: InputMaybe<Scalars['JSON']['input']>
    metaTo?: InputMaybe<Scalars['JSON']['input']>
    organizationDisplayNameFrom?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameTo?: InputMaybe<Scalars['String']['input']>
    organizationIdFrom?: InputMaybe<Scalars['ID']['input']>
    organizationIdTo?: InputMaybe<Scalars['ID']['input']>
    propertyAddressFrom?: InputMaybe<Scalars['String']['input']>
    propertyAddressMetaFrom?: InputMaybe<Scalars['JSON']['input']>
    propertyAddressMetaTo?: InputMaybe<Scalars['JSON']['input']>
    propertyAddressTo?: InputMaybe<Scalars['String']['input']>
    propertyDisplayNameFrom?: InputMaybe<Scalars['String']['input']>
    propertyDisplayNameTo?: InputMaybe<Scalars['String']['input']>
    propertyIdFrom?: InputMaybe<Scalars['ID']['input']>
    propertyIdTo?: InputMaybe<Scalars['ID']['input']>
    qualityControlAdditionalOptionsFrom?: InputMaybe<Scalars['JSON']['input']>
    qualityControlAdditionalOptionsTo?: InputMaybe<Scalars['JSON']['input']>
    qualityControlCommentFrom?: InputMaybe<Scalars['String']['input']>
    qualityControlCommentTo?: InputMaybe<Scalars['String']['input']>
    qualityControlValueFrom?: InputMaybe<TicketChangeQualityControlValueFromType>
    qualityControlValueTo?: InputMaybe<TicketChangeQualityControlValueToType>
    relatedDisplayNameFrom?: InputMaybe<Scalars['String']['input']>
    relatedDisplayNameTo?: InputMaybe<Scalars['String']['input']>
    relatedIdFrom?: InputMaybe<Scalars['ID']['input']>
    relatedIdTo?: InputMaybe<Scalars['ID']['input']>
    reviewCommentFrom?: InputMaybe<Scalars['String']['input']>
    reviewCommentTo?: InputMaybe<Scalars['String']['input']>
    reviewValueFrom?: InputMaybe<TicketChangeReviewValueFromType>
    reviewValueTo?: InputMaybe<TicketChangeReviewValueToType>
    sectionNameFrom?: InputMaybe<Scalars['String']['input']>
    sectionNameTo?: InputMaybe<Scalars['String']['input']>
    sectionTypeFrom?: InputMaybe<TicketChangeSectionTypeFromType>
    sectionTypeTo?: InputMaybe<TicketChangeSectionTypeToType>
    sender?: InputMaybe<SenderFieldInput>
    sourceDisplayNameFrom?: InputMaybe<Scalars['String']['input']>
    sourceDisplayNameTo?: InputMaybe<Scalars['String']['input']>
    sourceIdFrom?: InputMaybe<Scalars['ID']['input']>
    sourceIdTo?: InputMaybe<Scalars['ID']['input']>
    sourceMetaFrom?: InputMaybe<Scalars['JSON']['input']>
    sourceMetaTo?: InputMaybe<Scalars['JSON']['input']>
    statusDisplayNameFrom?: InputMaybe<Scalars['String']['input']>
    statusDisplayNameTo?: InputMaybe<Scalars['String']['input']>
    statusIdFrom?: InputMaybe<Scalars['ID']['input']>
    statusIdTo?: InputMaybe<Scalars['ID']['input']>
    statusReasonFrom?: InputMaybe<Scalars['String']['input']>
    statusReasonTo?: InputMaybe<Scalars['String']['input']>
    statusReopenedCounterFrom?: InputMaybe<Scalars['Int']['input']>
    statusReopenedCounterTo?: InputMaybe<Scalars['Int']['input']>
    ticket?: InputMaybe<TicketRelateToOneInput>
    unitNameFrom?: InputMaybe<Scalars['String']['input']>
    unitNameTo?: InputMaybe<Scalars['String']['input']>
    unitTypeFrom?: InputMaybe<TicketChangeUnitTypeFromType>
    unitTypeTo?: InputMaybe<TicketChangeUnitTypeToType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type TicketChangeWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<TicketChangeWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<TicketChangeWhereInput>>>
    actualCreationDate?: InputMaybe<Scalars['String']['input']>
    actualCreationDate_gt?: InputMaybe<Scalars['String']['input']>
    actualCreationDate_gte?: InputMaybe<Scalars['String']['input']>
    actualCreationDate_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    actualCreationDate_lt?: InputMaybe<Scalars['String']['input']>
    actualCreationDate_lte?: InputMaybe<Scalars['String']['input']>
    actualCreationDate_not?: InputMaybe<Scalars['String']['input']>
    actualCreationDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    assigneeDisplayNameFrom?: InputMaybe<Scalars['String']['input']>
    assigneeDisplayNameFrom_contains?: InputMaybe<Scalars['String']['input']>
    assigneeDisplayNameFrom_contains_i?: InputMaybe<Scalars['String']['input']>
    assigneeDisplayNameFrom_ends_with?: InputMaybe<Scalars['String']['input']>
    assigneeDisplayNameFrom_ends_with_i?: InputMaybe<Scalars['String']['input']>
    assigneeDisplayNameFrom_i?: InputMaybe<Scalars['String']['input']>
    assigneeDisplayNameFrom_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    assigneeDisplayNameFrom_not?: InputMaybe<Scalars['String']['input']>
    assigneeDisplayNameFrom_not_contains?: InputMaybe<Scalars['String']['input']>
    assigneeDisplayNameFrom_not_contains_i?: InputMaybe<Scalars['String']['input']>
    assigneeDisplayNameFrom_not_ends_with?: InputMaybe<Scalars['String']['input']>
    assigneeDisplayNameFrom_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    assigneeDisplayNameFrom_not_i?: InputMaybe<Scalars['String']['input']>
    assigneeDisplayNameFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    assigneeDisplayNameFrom_not_starts_with?: InputMaybe<Scalars['String']['input']>
    assigneeDisplayNameFrom_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    assigneeDisplayNameFrom_starts_with?: InputMaybe<Scalars['String']['input']>
    assigneeDisplayNameFrom_starts_with_i?: InputMaybe<Scalars['String']['input']>
    assigneeDisplayNameTo?: InputMaybe<Scalars['String']['input']>
    assigneeDisplayNameTo_contains?: InputMaybe<Scalars['String']['input']>
    assigneeDisplayNameTo_contains_i?: InputMaybe<Scalars['String']['input']>
    assigneeDisplayNameTo_ends_with?: InputMaybe<Scalars['String']['input']>
    assigneeDisplayNameTo_ends_with_i?: InputMaybe<Scalars['String']['input']>
    assigneeDisplayNameTo_i?: InputMaybe<Scalars['String']['input']>
    assigneeDisplayNameTo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    assigneeDisplayNameTo_not?: InputMaybe<Scalars['String']['input']>
    assigneeDisplayNameTo_not_contains?: InputMaybe<Scalars['String']['input']>
    assigneeDisplayNameTo_not_contains_i?: InputMaybe<Scalars['String']['input']>
    assigneeDisplayNameTo_not_ends_with?: InputMaybe<Scalars['String']['input']>
    assigneeDisplayNameTo_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    assigneeDisplayNameTo_not_i?: InputMaybe<Scalars['String']['input']>
    assigneeDisplayNameTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    assigneeDisplayNameTo_not_starts_with?: InputMaybe<Scalars['String']['input']>
    assigneeDisplayNameTo_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    assigneeDisplayNameTo_starts_with?: InputMaybe<Scalars['String']['input']>
    assigneeDisplayNameTo_starts_with_i?: InputMaybe<Scalars['String']['input']>
    assigneeIdFrom?: InputMaybe<Scalars['ID']['input']>
    assigneeIdFrom_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    assigneeIdFrom_not?: InputMaybe<Scalars['ID']['input']>
    assigneeIdFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    assigneeIdTo?: InputMaybe<Scalars['ID']['input']>
    assigneeIdTo_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    assigneeIdTo_not?: InputMaybe<Scalars['ID']['input']>
    assigneeIdTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    canReadByResidentFrom?: InputMaybe<Scalars['Boolean']['input']>
    canReadByResidentFrom_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadByResidentTo?: InputMaybe<Scalars['Boolean']['input']>
    canReadByResidentTo_not?: InputMaybe<Scalars['Boolean']['input']>
    classifierDisplayNameFrom?: InputMaybe<Scalars['String']['input']>
    classifierDisplayNameFrom_contains?: InputMaybe<Scalars['String']['input']>
    classifierDisplayNameFrom_contains_i?: InputMaybe<Scalars['String']['input']>
    classifierDisplayNameFrom_ends_with?: InputMaybe<Scalars['String']['input']>
    classifierDisplayNameFrom_ends_with_i?: InputMaybe<Scalars['String']['input']>
    classifierDisplayNameFrom_i?: InputMaybe<Scalars['String']['input']>
    classifierDisplayNameFrom_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    classifierDisplayNameFrom_not?: InputMaybe<Scalars['String']['input']>
    classifierDisplayNameFrom_not_contains?: InputMaybe<Scalars['String']['input']>
    classifierDisplayNameFrom_not_contains_i?: InputMaybe<Scalars['String']['input']>
    classifierDisplayNameFrom_not_ends_with?: InputMaybe<Scalars['String']['input']>
    classifierDisplayNameFrom_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    classifierDisplayNameFrom_not_i?: InputMaybe<Scalars['String']['input']>
    classifierDisplayNameFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    classifierDisplayNameFrom_not_starts_with?: InputMaybe<Scalars['String']['input']>
    classifierDisplayNameFrom_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    classifierDisplayNameFrom_starts_with?: InputMaybe<Scalars['String']['input']>
    classifierDisplayNameFrom_starts_with_i?: InputMaybe<Scalars['String']['input']>
    classifierDisplayNameTo?: InputMaybe<Scalars['String']['input']>
    classifierDisplayNameTo_contains?: InputMaybe<Scalars['String']['input']>
    classifierDisplayNameTo_contains_i?: InputMaybe<Scalars['String']['input']>
    classifierDisplayNameTo_ends_with?: InputMaybe<Scalars['String']['input']>
    classifierDisplayNameTo_ends_with_i?: InputMaybe<Scalars['String']['input']>
    classifierDisplayNameTo_i?: InputMaybe<Scalars['String']['input']>
    classifierDisplayNameTo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    classifierDisplayNameTo_not?: InputMaybe<Scalars['String']['input']>
    classifierDisplayNameTo_not_contains?: InputMaybe<Scalars['String']['input']>
    classifierDisplayNameTo_not_contains_i?: InputMaybe<Scalars['String']['input']>
    classifierDisplayNameTo_not_ends_with?: InputMaybe<Scalars['String']['input']>
    classifierDisplayNameTo_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    classifierDisplayNameTo_not_i?: InputMaybe<Scalars['String']['input']>
    classifierDisplayNameTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    classifierDisplayNameTo_not_starts_with?: InputMaybe<Scalars['String']['input']>
    classifierDisplayNameTo_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    classifierDisplayNameTo_starts_with?: InputMaybe<Scalars['String']['input']>
    classifierDisplayNameTo_starts_with_i?: InputMaybe<Scalars['String']['input']>
    classifierIdFrom?: InputMaybe<Scalars['ID']['input']>
    classifierIdFrom_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    classifierIdFrom_not?: InputMaybe<Scalars['ID']['input']>
    classifierIdFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    classifierIdTo?: InputMaybe<Scalars['ID']['input']>
    classifierIdTo_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    classifierIdTo_not?: InputMaybe<Scalars['ID']['input']>
    classifierIdTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    clientDisplayNameFrom?: InputMaybe<Scalars['String']['input']>
    clientDisplayNameFrom_contains?: InputMaybe<Scalars['String']['input']>
    clientDisplayNameFrom_contains_i?: InputMaybe<Scalars['String']['input']>
    clientDisplayNameFrom_ends_with?: InputMaybe<Scalars['String']['input']>
    clientDisplayNameFrom_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientDisplayNameFrom_i?: InputMaybe<Scalars['String']['input']>
    clientDisplayNameFrom_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientDisplayNameFrom_not?: InputMaybe<Scalars['String']['input']>
    clientDisplayNameFrom_not_contains?: InputMaybe<Scalars['String']['input']>
    clientDisplayNameFrom_not_contains_i?: InputMaybe<Scalars['String']['input']>
    clientDisplayNameFrom_not_ends_with?: InputMaybe<Scalars['String']['input']>
    clientDisplayNameFrom_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientDisplayNameFrom_not_i?: InputMaybe<Scalars['String']['input']>
    clientDisplayNameFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientDisplayNameFrom_not_starts_with?: InputMaybe<Scalars['String']['input']>
    clientDisplayNameFrom_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    clientDisplayNameFrom_starts_with?: InputMaybe<Scalars['String']['input']>
    clientDisplayNameFrom_starts_with_i?: InputMaybe<Scalars['String']['input']>
    clientDisplayNameTo?: InputMaybe<Scalars['String']['input']>
    clientDisplayNameTo_contains?: InputMaybe<Scalars['String']['input']>
    clientDisplayNameTo_contains_i?: InputMaybe<Scalars['String']['input']>
    clientDisplayNameTo_ends_with?: InputMaybe<Scalars['String']['input']>
    clientDisplayNameTo_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientDisplayNameTo_i?: InputMaybe<Scalars['String']['input']>
    clientDisplayNameTo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientDisplayNameTo_not?: InputMaybe<Scalars['String']['input']>
    clientDisplayNameTo_not_contains?: InputMaybe<Scalars['String']['input']>
    clientDisplayNameTo_not_contains_i?: InputMaybe<Scalars['String']['input']>
    clientDisplayNameTo_not_ends_with?: InputMaybe<Scalars['String']['input']>
    clientDisplayNameTo_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientDisplayNameTo_not_i?: InputMaybe<Scalars['String']['input']>
    clientDisplayNameTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientDisplayNameTo_not_starts_with?: InputMaybe<Scalars['String']['input']>
    clientDisplayNameTo_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    clientDisplayNameTo_starts_with?: InputMaybe<Scalars['String']['input']>
    clientDisplayNameTo_starts_with_i?: InputMaybe<Scalars['String']['input']>
    clientEmailFrom?: InputMaybe<Scalars['String']['input']>
    clientEmailFrom_contains?: InputMaybe<Scalars['String']['input']>
    clientEmailFrom_contains_i?: InputMaybe<Scalars['String']['input']>
    clientEmailFrom_ends_with?: InputMaybe<Scalars['String']['input']>
    clientEmailFrom_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientEmailFrom_i?: InputMaybe<Scalars['String']['input']>
    clientEmailFrom_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientEmailFrom_not?: InputMaybe<Scalars['String']['input']>
    clientEmailFrom_not_contains?: InputMaybe<Scalars['String']['input']>
    clientEmailFrom_not_contains_i?: InputMaybe<Scalars['String']['input']>
    clientEmailFrom_not_ends_with?: InputMaybe<Scalars['String']['input']>
    clientEmailFrom_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientEmailFrom_not_i?: InputMaybe<Scalars['String']['input']>
    clientEmailFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientEmailFrom_not_starts_with?: InputMaybe<Scalars['String']['input']>
    clientEmailFrom_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    clientEmailFrom_starts_with?: InputMaybe<Scalars['String']['input']>
    clientEmailFrom_starts_with_i?: InputMaybe<Scalars['String']['input']>
    clientEmailTo?: InputMaybe<Scalars['String']['input']>
    clientEmailTo_contains?: InputMaybe<Scalars['String']['input']>
    clientEmailTo_contains_i?: InputMaybe<Scalars['String']['input']>
    clientEmailTo_ends_with?: InputMaybe<Scalars['String']['input']>
    clientEmailTo_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientEmailTo_i?: InputMaybe<Scalars['String']['input']>
    clientEmailTo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientEmailTo_not?: InputMaybe<Scalars['String']['input']>
    clientEmailTo_not_contains?: InputMaybe<Scalars['String']['input']>
    clientEmailTo_not_contains_i?: InputMaybe<Scalars['String']['input']>
    clientEmailTo_not_ends_with?: InputMaybe<Scalars['String']['input']>
    clientEmailTo_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientEmailTo_not_i?: InputMaybe<Scalars['String']['input']>
    clientEmailTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientEmailTo_not_starts_with?: InputMaybe<Scalars['String']['input']>
    clientEmailTo_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    clientEmailTo_starts_with?: InputMaybe<Scalars['String']['input']>
    clientEmailTo_starts_with_i?: InputMaybe<Scalars['String']['input']>
    clientIdFrom?: InputMaybe<Scalars['ID']['input']>
    clientIdFrom_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    clientIdFrom_not?: InputMaybe<Scalars['ID']['input']>
    clientIdFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    clientIdTo?: InputMaybe<Scalars['ID']['input']>
    clientIdTo_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    clientIdTo_not?: InputMaybe<Scalars['ID']['input']>
    clientIdTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    clientNameFrom?: InputMaybe<Scalars['String']['input']>
    clientNameFrom_contains?: InputMaybe<Scalars['String']['input']>
    clientNameFrom_contains_i?: InputMaybe<Scalars['String']['input']>
    clientNameFrom_ends_with?: InputMaybe<Scalars['String']['input']>
    clientNameFrom_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientNameFrom_i?: InputMaybe<Scalars['String']['input']>
    clientNameFrom_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientNameFrom_not?: InputMaybe<Scalars['String']['input']>
    clientNameFrom_not_contains?: InputMaybe<Scalars['String']['input']>
    clientNameFrom_not_contains_i?: InputMaybe<Scalars['String']['input']>
    clientNameFrom_not_ends_with?: InputMaybe<Scalars['String']['input']>
    clientNameFrom_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientNameFrom_not_i?: InputMaybe<Scalars['String']['input']>
    clientNameFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientNameFrom_not_starts_with?: InputMaybe<Scalars['String']['input']>
    clientNameFrom_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    clientNameFrom_starts_with?: InputMaybe<Scalars['String']['input']>
    clientNameFrom_starts_with_i?: InputMaybe<Scalars['String']['input']>
    clientNameTo?: InputMaybe<Scalars['String']['input']>
    clientNameTo_contains?: InputMaybe<Scalars['String']['input']>
    clientNameTo_contains_i?: InputMaybe<Scalars['String']['input']>
    clientNameTo_ends_with?: InputMaybe<Scalars['String']['input']>
    clientNameTo_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientNameTo_i?: InputMaybe<Scalars['String']['input']>
    clientNameTo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientNameTo_not?: InputMaybe<Scalars['String']['input']>
    clientNameTo_not_contains?: InputMaybe<Scalars['String']['input']>
    clientNameTo_not_contains_i?: InputMaybe<Scalars['String']['input']>
    clientNameTo_not_ends_with?: InputMaybe<Scalars['String']['input']>
    clientNameTo_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientNameTo_not_i?: InputMaybe<Scalars['String']['input']>
    clientNameTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientNameTo_not_starts_with?: InputMaybe<Scalars['String']['input']>
    clientNameTo_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    clientNameTo_starts_with?: InputMaybe<Scalars['String']['input']>
    clientNameTo_starts_with_i?: InputMaybe<Scalars['String']['input']>
    clientPhoneFrom?: InputMaybe<Scalars['String']['input']>
    clientPhoneFrom_contains?: InputMaybe<Scalars['String']['input']>
    clientPhoneFrom_contains_i?: InputMaybe<Scalars['String']['input']>
    clientPhoneFrom_ends_with?: InputMaybe<Scalars['String']['input']>
    clientPhoneFrom_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientPhoneFrom_i?: InputMaybe<Scalars['String']['input']>
    clientPhoneFrom_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientPhoneFrom_not?: InputMaybe<Scalars['String']['input']>
    clientPhoneFrom_not_contains?: InputMaybe<Scalars['String']['input']>
    clientPhoneFrom_not_contains_i?: InputMaybe<Scalars['String']['input']>
    clientPhoneFrom_not_ends_with?: InputMaybe<Scalars['String']['input']>
    clientPhoneFrom_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientPhoneFrom_not_i?: InputMaybe<Scalars['String']['input']>
    clientPhoneFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientPhoneFrom_not_starts_with?: InputMaybe<Scalars['String']['input']>
    clientPhoneFrom_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    clientPhoneFrom_starts_with?: InputMaybe<Scalars['String']['input']>
    clientPhoneFrom_starts_with_i?: InputMaybe<Scalars['String']['input']>
    clientPhoneTo?: InputMaybe<Scalars['String']['input']>
    clientPhoneTo_contains?: InputMaybe<Scalars['String']['input']>
    clientPhoneTo_contains_i?: InputMaybe<Scalars['String']['input']>
    clientPhoneTo_ends_with?: InputMaybe<Scalars['String']['input']>
    clientPhoneTo_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientPhoneTo_i?: InputMaybe<Scalars['String']['input']>
    clientPhoneTo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientPhoneTo_not?: InputMaybe<Scalars['String']['input']>
    clientPhoneTo_not_contains?: InputMaybe<Scalars['String']['input']>
    clientPhoneTo_not_contains_i?: InputMaybe<Scalars['String']['input']>
    clientPhoneTo_not_ends_with?: InputMaybe<Scalars['String']['input']>
    clientPhoneTo_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientPhoneTo_not_i?: InputMaybe<Scalars['String']['input']>
    clientPhoneTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientPhoneTo_not_starts_with?: InputMaybe<Scalars['String']['input']>
    clientPhoneTo_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    clientPhoneTo_starts_with?: InputMaybe<Scalars['String']['input']>
    clientPhoneTo_starts_with_i?: InputMaybe<Scalars['String']['input']>
    contactDisplayNameFrom?: InputMaybe<Scalars['String']['input']>
    contactDisplayNameFrom_contains?: InputMaybe<Scalars['String']['input']>
    contactDisplayNameFrom_contains_i?: InputMaybe<Scalars['String']['input']>
    contactDisplayNameFrom_ends_with?: InputMaybe<Scalars['String']['input']>
    contactDisplayNameFrom_ends_with_i?: InputMaybe<Scalars['String']['input']>
    contactDisplayNameFrom_i?: InputMaybe<Scalars['String']['input']>
    contactDisplayNameFrom_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    contactDisplayNameFrom_not?: InputMaybe<Scalars['String']['input']>
    contactDisplayNameFrom_not_contains?: InputMaybe<Scalars['String']['input']>
    contactDisplayNameFrom_not_contains_i?: InputMaybe<Scalars['String']['input']>
    contactDisplayNameFrom_not_ends_with?: InputMaybe<Scalars['String']['input']>
    contactDisplayNameFrom_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    contactDisplayNameFrom_not_i?: InputMaybe<Scalars['String']['input']>
    contactDisplayNameFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    contactDisplayNameFrom_not_starts_with?: InputMaybe<Scalars['String']['input']>
    contactDisplayNameFrom_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    contactDisplayNameFrom_starts_with?: InputMaybe<Scalars['String']['input']>
    contactDisplayNameFrom_starts_with_i?: InputMaybe<Scalars['String']['input']>
    contactDisplayNameTo?: InputMaybe<Scalars['String']['input']>
    contactDisplayNameTo_contains?: InputMaybe<Scalars['String']['input']>
    contactDisplayNameTo_contains_i?: InputMaybe<Scalars['String']['input']>
    contactDisplayNameTo_ends_with?: InputMaybe<Scalars['String']['input']>
    contactDisplayNameTo_ends_with_i?: InputMaybe<Scalars['String']['input']>
    contactDisplayNameTo_i?: InputMaybe<Scalars['String']['input']>
    contactDisplayNameTo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    contactDisplayNameTo_not?: InputMaybe<Scalars['String']['input']>
    contactDisplayNameTo_not_contains?: InputMaybe<Scalars['String']['input']>
    contactDisplayNameTo_not_contains_i?: InputMaybe<Scalars['String']['input']>
    contactDisplayNameTo_not_ends_with?: InputMaybe<Scalars['String']['input']>
    contactDisplayNameTo_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    contactDisplayNameTo_not_i?: InputMaybe<Scalars['String']['input']>
    contactDisplayNameTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    contactDisplayNameTo_not_starts_with?: InputMaybe<Scalars['String']['input']>
    contactDisplayNameTo_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    contactDisplayNameTo_starts_with?: InputMaybe<Scalars['String']['input']>
    contactDisplayNameTo_starts_with_i?: InputMaybe<Scalars['String']['input']>
    contactIdFrom?: InputMaybe<Scalars['ID']['input']>
    contactIdFrom_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    contactIdFrom_not?: InputMaybe<Scalars['ID']['input']>
    contactIdFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    contactIdTo?: InputMaybe<Scalars['ID']['input']>
    contactIdTo_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    contactIdTo_not?: InputMaybe<Scalars['ID']['input']>
    contactIdTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deadlineFrom?: InputMaybe<Scalars['String']['input']>
    deadlineFrom_gt?: InputMaybe<Scalars['String']['input']>
    deadlineFrom_gte?: InputMaybe<Scalars['String']['input']>
    deadlineFrom_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deadlineFrom_lt?: InputMaybe<Scalars['String']['input']>
    deadlineFrom_lte?: InputMaybe<Scalars['String']['input']>
    deadlineFrom_not?: InputMaybe<Scalars['String']['input']>
    deadlineFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deadlineTo?: InputMaybe<Scalars['String']['input']>
    deadlineTo_gt?: InputMaybe<Scalars['String']['input']>
    deadlineTo_gte?: InputMaybe<Scalars['String']['input']>
    deadlineTo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deadlineTo_lt?: InputMaybe<Scalars['String']['input']>
    deadlineTo_lte?: InputMaybe<Scalars['String']['input']>
    deadlineTo_not?: InputMaybe<Scalars['String']['input']>
    deadlineTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deferredUntilFrom?: InputMaybe<Scalars['String']['input']>
    deferredUntilFrom_gt?: InputMaybe<Scalars['String']['input']>
    deferredUntilFrom_gte?: InputMaybe<Scalars['String']['input']>
    deferredUntilFrom_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deferredUntilFrom_lt?: InputMaybe<Scalars['String']['input']>
    deferredUntilFrom_lte?: InputMaybe<Scalars['String']['input']>
    deferredUntilFrom_not?: InputMaybe<Scalars['String']['input']>
    deferredUntilFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deferredUntilTo?: InputMaybe<Scalars['String']['input']>
    deferredUntilTo_gt?: InputMaybe<Scalars['String']['input']>
    deferredUntilTo_gte?: InputMaybe<Scalars['String']['input']>
    deferredUntilTo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deferredUntilTo_lt?: InputMaybe<Scalars['String']['input']>
    deferredUntilTo_lte?: InputMaybe<Scalars['String']['input']>
    deferredUntilTo_not?: InputMaybe<Scalars['String']['input']>
    deferredUntilTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    detailsFrom?: InputMaybe<Scalars['String']['input']>
    detailsFrom_contains?: InputMaybe<Scalars['String']['input']>
    detailsFrom_contains_i?: InputMaybe<Scalars['String']['input']>
    detailsFrom_ends_with?: InputMaybe<Scalars['String']['input']>
    detailsFrom_ends_with_i?: InputMaybe<Scalars['String']['input']>
    detailsFrom_i?: InputMaybe<Scalars['String']['input']>
    detailsFrom_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    detailsFrom_not?: InputMaybe<Scalars['String']['input']>
    detailsFrom_not_contains?: InputMaybe<Scalars['String']['input']>
    detailsFrom_not_contains_i?: InputMaybe<Scalars['String']['input']>
    detailsFrom_not_ends_with?: InputMaybe<Scalars['String']['input']>
    detailsFrom_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    detailsFrom_not_i?: InputMaybe<Scalars['String']['input']>
    detailsFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    detailsFrom_not_starts_with?: InputMaybe<Scalars['String']['input']>
    detailsFrom_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    detailsFrom_starts_with?: InputMaybe<Scalars['String']['input']>
    detailsFrom_starts_with_i?: InputMaybe<Scalars['String']['input']>
    detailsTo?: InputMaybe<Scalars['String']['input']>
    detailsTo_contains?: InputMaybe<Scalars['String']['input']>
    detailsTo_contains_i?: InputMaybe<Scalars['String']['input']>
    detailsTo_ends_with?: InputMaybe<Scalars['String']['input']>
    detailsTo_ends_with_i?: InputMaybe<Scalars['String']['input']>
    detailsTo_i?: InputMaybe<Scalars['String']['input']>
    detailsTo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    detailsTo_not?: InputMaybe<Scalars['String']['input']>
    detailsTo_not_contains?: InputMaybe<Scalars['String']['input']>
    detailsTo_not_contains_i?: InputMaybe<Scalars['String']['input']>
    detailsTo_not_ends_with?: InputMaybe<Scalars['String']['input']>
    detailsTo_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    detailsTo_not_i?: InputMaybe<Scalars['String']['input']>
    detailsTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    detailsTo_not_starts_with?: InputMaybe<Scalars['String']['input']>
    detailsTo_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    detailsTo_starts_with?: InputMaybe<Scalars['String']['input']>
    detailsTo_starts_with_i?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    executorDisplayNameFrom?: InputMaybe<Scalars['String']['input']>
    executorDisplayNameFrom_contains?: InputMaybe<Scalars['String']['input']>
    executorDisplayNameFrom_contains_i?: InputMaybe<Scalars['String']['input']>
    executorDisplayNameFrom_ends_with?: InputMaybe<Scalars['String']['input']>
    executorDisplayNameFrom_ends_with_i?: InputMaybe<Scalars['String']['input']>
    executorDisplayNameFrom_i?: InputMaybe<Scalars['String']['input']>
    executorDisplayNameFrom_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    executorDisplayNameFrom_not?: InputMaybe<Scalars['String']['input']>
    executorDisplayNameFrom_not_contains?: InputMaybe<Scalars['String']['input']>
    executorDisplayNameFrom_not_contains_i?: InputMaybe<Scalars['String']['input']>
    executorDisplayNameFrom_not_ends_with?: InputMaybe<Scalars['String']['input']>
    executorDisplayNameFrom_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    executorDisplayNameFrom_not_i?: InputMaybe<Scalars['String']['input']>
    executorDisplayNameFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    executorDisplayNameFrom_not_starts_with?: InputMaybe<Scalars['String']['input']>
    executorDisplayNameFrom_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    executorDisplayNameFrom_starts_with?: InputMaybe<Scalars['String']['input']>
    executorDisplayNameFrom_starts_with_i?: InputMaybe<Scalars['String']['input']>
    executorDisplayNameTo?: InputMaybe<Scalars['String']['input']>
    executorDisplayNameTo_contains?: InputMaybe<Scalars['String']['input']>
    executorDisplayNameTo_contains_i?: InputMaybe<Scalars['String']['input']>
    executorDisplayNameTo_ends_with?: InputMaybe<Scalars['String']['input']>
    executorDisplayNameTo_ends_with_i?: InputMaybe<Scalars['String']['input']>
    executorDisplayNameTo_i?: InputMaybe<Scalars['String']['input']>
    executorDisplayNameTo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    executorDisplayNameTo_not?: InputMaybe<Scalars['String']['input']>
    executorDisplayNameTo_not_contains?: InputMaybe<Scalars['String']['input']>
    executorDisplayNameTo_not_contains_i?: InputMaybe<Scalars['String']['input']>
    executorDisplayNameTo_not_ends_with?: InputMaybe<Scalars['String']['input']>
    executorDisplayNameTo_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    executorDisplayNameTo_not_i?: InputMaybe<Scalars['String']['input']>
    executorDisplayNameTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    executorDisplayNameTo_not_starts_with?: InputMaybe<Scalars['String']['input']>
    executorDisplayNameTo_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    executorDisplayNameTo_starts_with?: InputMaybe<Scalars['String']['input']>
    executorDisplayNameTo_starts_with_i?: InputMaybe<Scalars['String']['input']>
    executorIdFrom?: InputMaybe<Scalars['ID']['input']>
    executorIdFrom_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    executorIdFrom_not?: InputMaybe<Scalars['ID']['input']>
    executorIdFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    executorIdTo?: InputMaybe<Scalars['ID']['input']>
    executorIdTo_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    executorIdTo_not?: InputMaybe<Scalars['ID']['input']>
    executorIdTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    feedbackAdditionalOptionsFrom?: InputMaybe<Scalars['JSON']['input']>
    feedbackAdditionalOptionsFrom_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    feedbackAdditionalOptionsFrom_not?: InputMaybe<Scalars['JSON']['input']>
    feedbackAdditionalOptionsFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    feedbackAdditionalOptionsTo?: InputMaybe<Scalars['JSON']['input']>
    feedbackAdditionalOptionsTo_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    feedbackAdditionalOptionsTo_not?: InputMaybe<Scalars['JSON']['input']>
    feedbackAdditionalOptionsTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    feedbackCommentFrom?: InputMaybe<Scalars['String']['input']>
    feedbackCommentFrom_contains?: InputMaybe<Scalars['String']['input']>
    feedbackCommentFrom_contains_i?: InputMaybe<Scalars['String']['input']>
    feedbackCommentFrom_ends_with?: InputMaybe<Scalars['String']['input']>
    feedbackCommentFrom_ends_with_i?: InputMaybe<Scalars['String']['input']>
    feedbackCommentFrom_i?: InputMaybe<Scalars['String']['input']>
    feedbackCommentFrom_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    feedbackCommentFrom_not?: InputMaybe<Scalars['String']['input']>
    feedbackCommentFrom_not_contains?: InputMaybe<Scalars['String']['input']>
    feedbackCommentFrom_not_contains_i?: InputMaybe<Scalars['String']['input']>
    feedbackCommentFrom_not_ends_with?: InputMaybe<Scalars['String']['input']>
    feedbackCommentFrom_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    feedbackCommentFrom_not_i?: InputMaybe<Scalars['String']['input']>
    feedbackCommentFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    feedbackCommentFrom_not_starts_with?: InputMaybe<Scalars['String']['input']>
    feedbackCommentFrom_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    feedbackCommentFrom_starts_with?: InputMaybe<Scalars['String']['input']>
    feedbackCommentFrom_starts_with_i?: InputMaybe<Scalars['String']['input']>
    feedbackCommentTo?: InputMaybe<Scalars['String']['input']>
    feedbackCommentTo_contains?: InputMaybe<Scalars['String']['input']>
    feedbackCommentTo_contains_i?: InputMaybe<Scalars['String']['input']>
    feedbackCommentTo_ends_with?: InputMaybe<Scalars['String']['input']>
    feedbackCommentTo_ends_with_i?: InputMaybe<Scalars['String']['input']>
    feedbackCommentTo_i?: InputMaybe<Scalars['String']['input']>
    feedbackCommentTo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    feedbackCommentTo_not?: InputMaybe<Scalars['String']['input']>
    feedbackCommentTo_not_contains?: InputMaybe<Scalars['String']['input']>
    feedbackCommentTo_not_contains_i?: InputMaybe<Scalars['String']['input']>
    feedbackCommentTo_not_ends_with?: InputMaybe<Scalars['String']['input']>
    feedbackCommentTo_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    feedbackCommentTo_not_i?: InputMaybe<Scalars['String']['input']>
    feedbackCommentTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    feedbackCommentTo_not_starts_with?: InputMaybe<Scalars['String']['input']>
    feedbackCommentTo_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    feedbackCommentTo_starts_with?: InputMaybe<Scalars['String']['input']>
    feedbackCommentTo_starts_with_i?: InputMaybe<Scalars['String']['input']>
    feedbackValueFrom?: InputMaybe<TicketChangeFeedbackValueFromType>
    feedbackValueFrom_in?: InputMaybe<Array<InputMaybe<TicketChangeFeedbackValueFromType>>>
    feedbackValueFrom_not?: InputMaybe<TicketChangeFeedbackValueFromType>
    feedbackValueFrom_not_in?: InputMaybe<Array<InputMaybe<TicketChangeFeedbackValueFromType>>>
    feedbackValueTo?: InputMaybe<TicketChangeFeedbackValueToType>
    feedbackValueTo_in?: InputMaybe<Array<InputMaybe<TicketChangeFeedbackValueToType>>>
    feedbackValueTo_not?: InputMaybe<TicketChangeFeedbackValueToType>
    feedbackValueTo_not_in?: InputMaybe<Array<InputMaybe<TicketChangeFeedbackValueToType>>>
    floorNameFrom?: InputMaybe<Scalars['String']['input']>
    floorNameFrom_contains?: InputMaybe<Scalars['String']['input']>
    floorNameFrom_contains_i?: InputMaybe<Scalars['String']['input']>
    floorNameFrom_ends_with?: InputMaybe<Scalars['String']['input']>
    floorNameFrom_ends_with_i?: InputMaybe<Scalars['String']['input']>
    floorNameFrom_i?: InputMaybe<Scalars['String']['input']>
    floorNameFrom_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    floorNameFrom_not?: InputMaybe<Scalars['String']['input']>
    floorNameFrom_not_contains?: InputMaybe<Scalars['String']['input']>
    floorNameFrom_not_contains_i?: InputMaybe<Scalars['String']['input']>
    floorNameFrom_not_ends_with?: InputMaybe<Scalars['String']['input']>
    floorNameFrom_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    floorNameFrom_not_i?: InputMaybe<Scalars['String']['input']>
    floorNameFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    floorNameFrom_not_starts_with?: InputMaybe<Scalars['String']['input']>
    floorNameFrom_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    floorNameFrom_starts_with?: InputMaybe<Scalars['String']['input']>
    floorNameFrom_starts_with_i?: InputMaybe<Scalars['String']['input']>
    floorNameTo?: InputMaybe<Scalars['String']['input']>
    floorNameTo_contains?: InputMaybe<Scalars['String']['input']>
    floorNameTo_contains_i?: InputMaybe<Scalars['String']['input']>
    floorNameTo_ends_with?: InputMaybe<Scalars['String']['input']>
    floorNameTo_ends_with_i?: InputMaybe<Scalars['String']['input']>
    floorNameTo_i?: InputMaybe<Scalars['String']['input']>
    floorNameTo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    floorNameTo_not?: InputMaybe<Scalars['String']['input']>
    floorNameTo_not_contains?: InputMaybe<Scalars['String']['input']>
    floorNameTo_not_contains_i?: InputMaybe<Scalars['String']['input']>
    floorNameTo_not_ends_with?: InputMaybe<Scalars['String']['input']>
    floorNameTo_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    floorNameTo_not_i?: InputMaybe<Scalars['String']['input']>
    floorNameTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    floorNameTo_not_starts_with?: InputMaybe<Scalars['String']['input']>
    floorNameTo_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    floorNameTo_starts_with?: InputMaybe<Scalars['String']['input']>
    floorNameTo_starts_with_i?: InputMaybe<Scalars['String']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    isEmergencyFrom?: InputMaybe<Scalars['Boolean']['input']>
    isEmergencyFrom_not?: InputMaybe<Scalars['Boolean']['input']>
    isEmergencyTo?: InputMaybe<Scalars['Boolean']['input']>
    isEmergencyTo_not?: InputMaybe<Scalars['Boolean']['input']>
    isPaidFrom?: InputMaybe<Scalars['Boolean']['input']>
    isPaidFrom_not?: InputMaybe<Scalars['Boolean']['input']>
    isPaidTo?: InputMaybe<Scalars['Boolean']['input']>
    isPaidTo_not?: InputMaybe<Scalars['Boolean']['input']>
    isPayableFrom?: InputMaybe<Scalars['Boolean']['input']>
    isPayableFrom_not?: InputMaybe<Scalars['Boolean']['input']>
    isPayableTo?: InputMaybe<Scalars['Boolean']['input']>
    isPayableTo_not?: InputMaybe<Scalars['Boolean']['input']>
    isResidentTicketFrom?: InputMaybe<Scalars['Boolean']['input']>
    isResidentTicketFrom_not?: InputMaybe<Scalars['Boolean']['input']>
    isResidentTicketTo?: InputMaybe<Scalars['Boolean']['input']>
    isResidentTicketTo_not?: InputMaybe<Scalars['Boolean']['input']>
    isWarrantyFrom?: InputMaybe<Scalars['Boolean']['input']>
    isWarrantyFrom_not?: InputMaybe<Scalars['Boolean']['input']>
    isWarrantyTo?: InputMaybe<Scalars['Boolean']['input']>
    isWarrantyTo_not?: InputMaybe<Scalars['Boolean']['input']>
    metaFrom?: InputMaybe<Scalars['JSON']['input']>
    metaFrom_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    metaFrom_not?: InputMaybe<Scalars['JSON']['input']>
    metaFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    metaTo?: InputMaybe<Scalars['JSON']['input']>
    metaTo_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    metaTo_not?: InputMaybe<Scalars['JSON']['input']>
    metaTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organizationDisplayNameFrom?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameFrom_contains?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameFrom_contains_i?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameFrom_ends_with?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameFrom_ends_with_i?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameFrom_i?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameFrom_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organizationDisplayNameFrom_not?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameFrom_not_contains?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameFrom_not_contains_i?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameFrom_not_ends_with?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameFrom_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameFrom_not_i?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organizationDisplayNameFrom_not_starts_with?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameFrom_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameFrom_starts_with?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameFrom_starts_with_i?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameTo?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameTo_contains?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameTo_contains_i?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameTo_ends_with?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameTo_ends_with_i?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameTo_i?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameTo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organizationDisplayNameTo_not?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameTo_not_contains?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameTo_not_contains_i?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameTo_not_ends_with?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameTo_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameTo_not_i?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organizationDisplayNameTo_not_starts_with?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameTo_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameTo_starts_with?: InputMaybe<Scalars['String']['input']>
    organizationDisplayNameTo_starts_with_i?: InputMaybe<Scalars['String']['input']>
    organizationIdFrom?: InputMaybe<Scalars['ID']['input']>
    organizationIdFrom_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    organizationIdFrom_not?: InputMaybe<Scalars['ID']['input']>
    organizationIdFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    organizationIdTo?: InputMaybe<Scalars['ID']['input']>
    organizationIdTo_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    organizationIdTo_not?: InputMaybe<Scalars['ID']['input']>
    organizationIdTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    propertyAddressFrom?: InputMaybe<Scalars['String']['input']>
    propertyAddressFrom_contains?: InputMaybe<Scalars['String']['input']>
    propertyAddressFrom_contains_i?: InputMaybe<Scalars['String']['input']>
    propertyAddressFrom_ends_with?: InputMaybe<Scalars['String']['input']>
    propertyAddressFrom_ends_with_i?: InputMaybe<Scalars['String']['input']>
    propertyAddressFrom_i?: InputMaybe<Scalars['String']['input']>
    propertyAddressFrom_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    propertyAddressFrom_not?: InputMaybe<Scalars['String']['input']>
    propertyAddressFrom_not_contains?: InputMaybe<Scalars['String']['input']>
    propertyAddressFrom_not_contains_i?: InputMaybe<Scalars['String']['input']>
    propertyAddressFrom_not_ends_with?: InputMaybe<Scalars['String']['input']>
    propertyAddressFrom_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    propertyAddressFrom_not_i?: InputMaybe<Scalars['String']['input']>
    propertyAddressFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    propertyAddressFrom_not_starts_with?: InputMaybe<Scalars['String']['input']>
    propertyAddressFrom_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    propertyAddressFrom_starts_with?: InputMaybe<Scalars['String']['input']>
    propertyAddressFrom_starts_with_i?: InputMaybe<Scalars['String']['input']>
    propertyAddressMetaFrom?: InputMaybe<Scalars['JSON']['input']>
    propertyAddressMetaFrom_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    propertyAddressMetaFrom_not?: InputMaybe<Scalars['JSON']['input']>
    propertyAddressMetaFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    propertyAddressMetaTo?: InputMaybe<Scalars['JSON']['input']>
    propertyAddressMetaTo_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    propertyAddressMetaTo_not?: InputMaybe<Scalars['JSON']['input']>
    propertyAddressMetaTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    propertyAddressTo?: InputMaybe<Scalars['String']['input']>
    propertyAddressTo_contains?: InputMaybe<Scalars['String']['input']>
    propertyAddressTo_contains_i?: InputMaybe<Scalars['String']['input']>
    propertyAddressTo_ends_with?: InputMaybe<Scalars['String']['input']>
    propertyAddressTo_ends_with_i?: InputMaybe<Scalars['String']['input']>
    propertyAddressTo_i?: InputMaybe<Scalars['String']['input']>
    propertyAddressTo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    propertyAddressTo_not?: InputMaybe<Scalars['String']['input']>
    propertyAddressTo_not_contains?: InputMaybe<Scalars['String']['input']>
    propertyAddressTo_not_contains_i?: InputMaybe<Scalars['String']['input']>
    propertyAddressTo_not_ends_with?: InputMaybe<Scalars['String']['input']>
    propertyAddressTo_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    propertyAddressTo_not_i?: InputMaybe<Scalars['String']['input']>
    propertyAddressTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    propertyAddressTo_not_starts_with?: InputMaybe<Scalars['String']['input']>
    propertyAddressTo_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    propertyAddressTo_starts_with?: InputMaybe<Scalars['String']['input']>
    propertyAddressTo_starts_with_i?: InputMaybe<Scalars['String']['input']>
    propertyDisplayNameFrom?: InputMaybe<Scalars['String']['input']>
    propertyDisplayNameFrom_contains?: InputMaybe<Scalars['String']['input']>
    propertyDisplayNameFrom_contains_i?: InputMaybe<Scalars['String']['input']>
    propertyDisplayNameFrom_ends_with?: InputMaybe<Scalars['String']['input']>
    propertyDisplayNameFrom_ends_with_i?: InputMaybe<Scalars['String']['input']>
    propertyDisplayNameFrom_i?: InputMaybe<Scalars['String']['input']>
    propertyDisplayNameFrom_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    propertyDisplayNameFrom_not?: InputMaybe<Scalars['String']['input']>
    propertyDisplayNameFrom_not_contains?: InputMaybe<Scalars['String']['input']>
    propertyDisplayNameFrom_not_contains_i?: InputMaybe<Scalars['String']['input']>
    propertyDisplayNameFrom_not_ends_with?: InputMaybe<Scalars['String']['input']>
    propertyDisplayNameFrom_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    propertyDisplayNameFrom_not_i?: InputMaybe<Scalars['String']['input']>
    propertyDisplayNameFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    propertyDisplayNameFrom_not_starts_with?: InputMaybe<Scalars['String']['input']>
    propertyDisplayNameFrom_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    propertyDisplayNameFrom_starts_with?: InputMaybe<Scalars['String']['input']>
    propertyDisplayNameFrom_starts_with_i?: InputMaybe<Scalars['String']['input']>
    propertyDisplayNameTo?: InputMaybe<Scalars['String']['input']>
    propertyDisplayNameTo_contains?: InputMaybe<Scalars['String']['input']>
    propertyDisplayNameTo_contains_i?: InputMaybe<Scalars['String']['input']>
    propertyDisplayNameTo_ends_with?: InputMaybe<Scalars['String']['input']>
    propertyDisplayNameTo_ends_with_i?: InputMaybe<Scalars['String']['input']>
    propertyDisplayNameTo_i?: InputMaybe<Scalars['String']['input']>
    propertyDisplayNameTo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    propertyDisplayNameTo_not?: InputMaybe<Scalars['String']['input']>
    propertyDisplayNameTo_not_contains?: InputMaybe<Scalars['String']['input']>
    propertyDisplayNameTo_not_contains_i?: InputMaybe<Scalars['String']['input']>
    propertyDisplayNameTo_not_ends_with?: InputMaybe<Scalars['String']['input']>
    propertyDisplayNameTo_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    propertyDisplayNameTo_not_i?: InputMaybe<Scalars['String']['input']>
    propertyDisplayNameTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    propertyDisplayNameTo_not_starts_with?: InputMaybe<Scalars['String']['input']>
    propertyDisplayNameTo_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    propertyDisplayNameTo_starts_with?: InputMaybe<Scalars['String']['input']>
    propertyDisplayNameTo_starts_with_i?: InputMaybe<Scalars['String']['input']>
    propertyIdFrom?: InputMaybe<Scalars['ID']['input']>
    propertyIdFrom_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    propertyIdFrom_not?: InputMaybe<Scalars['ID']['input']>
    propertyIdFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    propertyIdTo?: InputMaybe<Scalars['ID']['input']>
    propertyIdTo_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    propertyIdTo_not?: InputMaybe<Scalars['ID']['input']>
    propertyIdTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    qualityControlAdditionalOptionsFrom?: InputMaybe<Scalars['JSON']['input']>
    qualityControlAdditionalOptionsFrom_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    qualityControlAdditionalOptionsFrom_not?: InputMaybe<Scalars['JSON']['input']>
    qualityControlAdditionalOptionsFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    qualityControlAdditionalOptionsTo?: InputMaybe<Scalars['JSON']['input']>
    qualityControlAdditionalOptionsTo_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    qualityControlAdditionalOptionsTo_not?: InputMaybe<Scalars['JSON']['input']>
    qualityControlAdditionalOptionsTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    qualityControlCommentFrom?: InputMaybe<Scalars['String']['input']>
    qualityControlCommentFrom_contains?: InputMaybe<Scalars['String']['input']>
    qualityControlCommentFrom_contains_i?: InputMaybe<Scalars['String']['input']>
    qualityControlCommentFrom_ends_with?: InputMaybe<Scalars['String']['input']>
    qualityControlCommentFrom_ends_with_i?: InputMaybe<Scalars['String']['input']>
    qualityControlCommentFrom_i?: InputMaybe<Scalars['String']['input']>
    qualityControlCommentFrom_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    qualityControlCommentFrom_not?: InputMaybe<Scalars['String']['input']>
    qualityControlCommentFrom_not_contains?: InputMaybe<Scalars['String']['input']>
    qualityControlCommentFrom_not_contains_i?: InputMaybe<Scalars['String']['input']>
    qualityControlCommentFrom_not_ends_with?: InputMaybe<Scalars['String']['input']>
    qualityControlCommentFrom_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    qualityControlCommentFrom_not_i?: InputMaybe<Scalars['String']['input']>
    qualityControlCommentFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    qualityControlCommentFrom_not_starts_with?: InputMaybe<Scalars['String']['input']>
    qualityControlCommentFrom_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    qualityControlCommentFrom_starts_with?: InputMaybe<Scalars['String']['input']>
    qualityControlCommentFrom_starts_with_i?: InputMaybe<Scalars['String']['input']>
    qualityControlCommentTo?: InputMaybe<Scalars['String']['input']>
    qualityControlCommentTo_contains?: InputMaybe<Scalars['String']['input']>
    qualityControlCommentTo_contains_i?: InputMaybe<Scalars['String']['input']>
    qualityControlCommentTo_ends_with?: InputMaybe<Scalars['String']['input']>
    qualityControlCommentTo_ends_with_i?: InputMaybe<Scalars['String']['input']>
    qualityControlCommentTo_i?: InputMaybe<Scalars['String']['input']>
    qualityControlCommentTo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    qualityControlCommentTo_not?: InputMaybe<Scalars['String']['input']>
    qualityControlCommentTo_not_contains?: InputMaybe<Scalars['String']['input']>
    qualityControlCommentTo_not_contains_i?: InputMaybe<Scalars['String']['input']>
    qualityControlCommentTo_not_ends_with?: InputMaybe<Scalars['String']['input']>
    qualityControlCommentTo_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    qualityControlCommentTo_not_i?: InputMaybe<Scalars['String']['input']>
    qualityControlCommentTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    qualityControlCommentTo_not_starts_with?: InputMaybe<Scalars['String']['input']>
    qualityControlCommentTo_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    qualityControlCommentTo_starts_with?: InputMaybe<Scalars['String']['input']>
    qualityControlCommentTo_starts_with_i?: InputMaybe<Scalars['String']['input']>
    qualityControlValueFrom?: InputMaybe<TicketChangeQualityControlValueFromType>
    qualityControlValueFrom_in?: InputMaybe<Array<InputMaybe<TicketChangeQualityControlValueFromType>>>
    qualityControlValueFrom_not?: InputMaybe<TicketChangeQualityControlValueFromType>
    qualityControlValueFrom_not_in?: InputMaybe<Array<InputMaybe<TicketChangeQualityControlValueFromType>>>
    qualityControlValueTo?: InputMaybe<TicketChangeQualityControlValueToType>
    qualityControlValueTo_in?: InputMaybe<Array<InputMaybe<TicketChangeQualityControlValueToType>>>
    qualityControlValueTo_not?: InputMaybe<TicketChangeQualityControlValueToType>
    qualityControlValueTo_not_in?: InputMaybe<Array<InputMaybe<TicketChangeQualityControlValueToType>>>
    relatedDisplayNameFrom?: InputMaybe<Scalars['String']['input']>
    relatedDisplayNameFrom_contains?: InputMaybe<Scalars['String']['input']>
    relatedDisplayNameFrom_contains_i?: InputMaybe<Scalars['String']['input']>
    relatedDisplayNameFrom_ends_with?: InputMaybe<Scalars['String']['input']>
    relatedDisplayNameFrom_ends_with_i?: InputMaybe<Scalars['String']['input']>
    relatedDisplayNameFrom_i?: InputMaybe<Scalars['String']['input']>
    relatedDisplayNameFrom_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    relatedDisplayNameFrom_not?: InputMaybe<Scalars['String']['input']>
    relatedDisplayNameFrom_not_contains?: InputMaybe<Scalars['String']['input']>
    relatedDisplayNameFrom_not_contains_i?: InputMaybe<Scalars['String']['input']>
    relatedDisplayNameFrom_not_ends_with?: InputMaybe<Scalars['String']['input']>
    relatedDisplayNameFrom_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    relatedDisplayNameFrom_not_i?: InputMaybe<Scalars['String']['input']>
    relatedDisplayNameFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    relatedDisplayNameFrom_not_starts_with?: InputMaybe<Scalars['String']['input']>
    relatedDisplayNameFrom_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    relatedDisplayNameFrom_starts_with?: InputMaybe<Scalars['String']['input']>
    relatedDisplayNameFrom_starts_with_i?: InputMaybe<Scalars['String']['input']>
    relatedDisplayNameTo?: InputMaybe<Scalars['String']['input']>
    relatedDisplayNameTo_contains?: InputMaybe<Scalars['String']['input']>
    relatedDisplayNameTo_contains_i?: InputMaybe<Scalars['String']['input']>
    relatedDisplayNameTo_ends_with?: InputMaybe<Scalars['String']['input']>
    relatedDisplayNameTo_ends_with_i?: InputMaybe<Scalars['String']['input']>
    relatedDisplayNameTo_i?: InputMaybe<Scalars['String']['input']>
    relatedDisplayNameTo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    relatedDisplayNameTo_not?: InputMaybe<Scalars['String']['input']>
    relatedDisplayNameTo_not_contains?: InputMaybe<Scalars['String']['input']>
    relatedDisplayNameTo_not_contains_i?: InputMaybe<Scalars['String']['input']>
    relatedDisplayNameTo_not_ends_with?: InputMaybe<Scalars['String']['input']>
    relatedDisplayNameTo_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    relatedDisplayNameTo_not_i?: InputMaybe<Scalars['String']['input']>
    relatedDisplayNameTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    relatedDisplayNameTo_not_starts_with?: InputMaybe<Scalars['String']['input']>
    relatedDisplayNameTo_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    relatedDisplayNameTo_starts_with?: InputMaybe<Scalars['String']['input']>
    relatedDisplayNameTo_starts_with_i?: InputMaybe<Scalars['String']['input']>
    relatedIdFrom?: InputMaybe<Scalars['ID']['input']>
    relatedIdFrom_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    relatedIdFrom_not?: InputMaybe<Scalars['ID']['input']>
    relatedIdFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    relatedIdTo?: InputMaybe<Scalars['ID']['input']>
    relatedIdTo_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    relatedIdTo_not?: InputMaybe<Scalars['ID']['input']>
    relatedIdTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    reviewCommentFrom?: InputMaybe<Scalars['String']['input']>
    reviewCommentFrom_contains?: InputMaybe<Scalars['String']['input']>
    reviewCommentFrom_contains_i?: InputMaybe<Scalars['String']['input']>
    reviewCommentFrom_ends_with?: InputMaybe<Scalars['String']['input']>
    reviewCommentFrom_ends_with_i?: InputMaybe<Scalars['String']['input']>
    reviewCommentFrom_i?: InputMaybe<Scalars['String']['input']>
    reviewCommentFrom_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    reviewCommentFrom_not?: InputMaybe<Scalars['String']['input']>
    reviewCommentFrom_not_contains?: InputMaybe<Scalars['String']['input']>
    reviewCommentFrom_not_contains_i?: InputMaybe<Scalars['String']['input']>
    reviewCommentFrom_not_ends_with?: InputMaybe<Scalars['String']['input']>
    reviewCommentFrom_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    reviewCommentFrom_not_i?: InputMaybe<Scalars['String']['input']>
    reviewCommentFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    reviewCommentFrom_not_starts_with?: InputMaybe<Scalars['String']['input']>
    reviewCommentFrom_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    reviewCommentFrom_starts_with?: InputMaybe<Scalars['String']['input']>
    reviewCommentFrom_starts_with_i?: InputMaybe<Scalars['String']['input']>
    reviewCommentTo?: InputMaybe<Scalars['String']['input']>
    reviewCommentTo_contains?: InputMaybe<Scalars['String']['input']>
    reviewCommentTo_contains_i?: InputMaybe<Scalars['String']['input']>
    reviewCommentTo_ends_with?: InputMaybe<Scalars['String']['input']>
    reviewCommentTo_ends_with_i?: InputMaybe<Scalars['String']['input']>
    reviewCommentTo_i?: InputMaybe<Scalars['String']['input']>
    reviewCommentTo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    reviewCommentTo_not?: InputMaybe<Scalars['String']['input']>
    reviewCommentTo_not_contains?: InputMaybe<Scalars['String']['input']>
    reviewCommentTo_not_contains_i?: InputMaybe<Scalars['String']['input']>
    reviewCommentTo_not_ends_with?: InputMaybe<Scalars['String']['input']>
    reviewCommentTo_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    reviewCommentTo_not_i?: InputMaybe<Scalars['String']['input']>
    reviewCommentTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    reviewCommentTo_not_starts_with?: InputMaybe<Scalars['String']['input']>
    reviewCommentTo_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    reviewCommentTo_starts_with?: InputMaybe<Scalars['String']['input']>
    reviewCommentTo_starts_with_i?: InputMaybe<Scalars['String']['input']>
    reviewValueFrom?: InputMaybe<TicketChangeReviewValueFromType>
    reviewValueFrom_in?: InputMaybe<Array<InputMaybe<TicketChangeReviewValueFromType>>>
    reviewValueFrom_not?: InputMaybe<TicketChangeReviewValueFromType>
    reviewValueFrom_not_in?: InputMaybe<Array<InputMaybe<TicketChangeReviewValueFromType>>>
    reviewValueTo?: InputMaybe<TicketChangeReviewValueToType>
    reviewValueTo_in?: InputMaybe<Array<InputMaybe<TicketChangeReviewValueToType>>>
    reviewValueTo_not?: InputMaybe<TicketChangeReviewValueToType>
    reviewValueTo_not_in?: InputMaybe<Array<InputMaybe<TicketChangeReviewValueToType>>>
    sectionNameFrom?: InputMaybe<Scalars['String']['input']>
    sectionNameFrom_contains?: InputMaybe<Scalars['String']['input']>
    sectionNameFrom_contains_i?: InputMaybe<Scalars['String']['input']>
    sectionNameFrom_ends_with?: InputMaybe<Scalars['String']['input']>
    sectionNameFrom_ends_with_i?: InputMaybe<Scalars['String']['input']>
    sectionNameFrom_i?: InputMaybe<Scalars['String']['input']>
    sectionNameFrom_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sectionNameFrom_not?: InputMaybe<Scalars['String']['input']>
    sectionNameFrom_not_contains?: InputMaybe<Scalars['String']['input']>
    sectionNameFrom_not_contains_i?: InputMaybe<Scalars['String']['input']>
    sectionNameFrom_not_ends_with?: InputMaybe<Scalars['String']['input']>
    sectionNameFrom_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    sectionNameFrom_not_i?: InputMaybe<Scalars['String']['input']>
    sectionNameFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sectionNameFrom_not_starts_with?: InputMaybe<Scalars['String']['input']>
    sectionNameFrom_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    sectionNameFrom_starts_with?: InputMaybe<Scalars['String']['input']>
    sectionNameFrom_starts_with_i?: InputMaybe<Scalars['String']['input']>
    sectionNameTo?: InputMaybe<Scalars['String']['input']>
    sectionNameTo_contains?: InputMaybe<Scalars['String']['input']>
    sectionNameTo_contains_i?: InputMaybe<Scalars['String']['input']>
    sectionNameTo_ends_with?: InputMaybe<Scalars['String']['input']>
    sectionNameTo_ends_with_i?: InputMaybe<Scalars['String']['input']>
    sectionNameTo_i?: InputMaybe<Scalars['String']['input']>
    sectionNameTo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sectionNameTo_not?: InputMaybe<Scalars['String']['input']>
    sectionNameTo_not_contains?: InputMaybe<Scalars['String']['input']>
    sectionNameTo_not_contains_i?: InputMaybe<Scalars['String']['input']>
    sectionNameTo_not_ends_with?: InputMaybe<Scalars['String']['input']>
    sectionNameTo_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    sectionNameTo_not_i?: InputMaybe<Scalars['String']['input']>
    sectionNameTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sectionNameTo_not_starts_with?: InputMaybe<Scalars['String']['input']>
    sectionNameTo_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    sectionNameTo_starts_with?: InputMaybe<Scalars['String']['input']>
    sectionNameTo_starts_with_i?: InputMaybe<Scalars['String']['input']>
    sectionTypeFrom?: InputMaybe<TicketChangeSectionTypeFromType>
    sectionTypeFrom_in?: InputMaybe<Array<InputMaybe<TicketChangeSectionTypeFromType>>>
    sectionTypeFrom_not?: InputMaybe<TicketChangeSectionTypeFromType>
    sectionTypeFrom_not_in?: InputMaybe<Array<InputMaybe<TicketChangeSectionTypeFromType>>>
    sectionTypeTo?: InputMaybe<TicketChangeSectionTypeToType>
    sectionTypeTo_in?: InputMaybe<Array<InputMaybe<TicketChangeSectionTypeToType>>>
    sectionTypeTo_not?: InputMaybe<TicketChangeSectionTypeToType>
    sectionTypeTo_not_in?: InputMaybe<Array<InputMaybe<TicketChangeSectionTypeToType>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sourceDisplayNameFrom?: InputMaybe<Scalars['String']['input']>
    sourceDisplayNameFrom_contains?: InputMaybe<Scalars['String']['input']>
    sourceDisplayNameFrom_contains_i?: InputMaybe<Scalars['String']['input']>
    sourceDisplayNameFrom_ends_with?: InputMaybe<Scalars['String']['input']>
    sourceDisplayNameFrom_ends_with_i?: InputMaybe<Scalars['String']['input']>
    sourceDisplayNameFrom_i?: InputMaybe<Scalars['String']['input']>
    sourceDisplayNameFrom_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sourceDisplayNameFrom_not?: InputMaybe<Scalars['String']['input']>
    sourceDisplayNameFrom_not_contains?: InputMaybe<Scalars['String']['input']>
    sourceDisplayNameFrom_not_contains_i?: InputMaybe<Scalars['String']['input']>
    sourceDisplayNameFrom_not_ends_with?: InputMaybe<Scalars['String']['input']>
    sourceDisplayNameFrom_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    sourceDisplayNameFrom_not_i?: InputMaybe<Scalars['String']['input']>
    sourceDisplayNameFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sourceDisplayNameFrom_not_starts_with?: InputMaybe<Scalars['String']['input']>
    sourceDisplayNameFrom_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    sourceDisplayNameFrom_starts_with?: InputMaybe<Scalars['String']['input']>
    sourceDisplayNameFrom_starts_with_i?: InputMaybe<Scalars['String']['input']>
    sourceDisplayNameTo?: InputMaybe<Scalars['String']['input']>
    sourceDisplayNameTo_contains?: InputMaybe<Scalars['String']['input']>
    sourceDisplayNameTo_contains_i?: InputMaybe<Scalars['String']['input']>
    sourceDisplayNameTo_ends_with?: InputMaybe<Scalars['String']['input']>
    sourceDisplayNameTo_ends_with_i?: InputMaybe<Scalars['String']['input']>
    sourceDisplayNameTo_i?: InputMaybe<Scalars['String']['input']>
    sourceDisplayNameTo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sourceDisplayNameTo_not?: InputMaybe<Scalars['String']['input']>
    sourceDisplayNameTo_not_contains?: InputMaybe<Scalars['String']['input']>
    sourceDisplayNameTo_not_contains_i?: InputMaybe<Scalars['String']['input']>
    sourceDisplayNameTo_not_ends_with?: InputMaybe<Scalars['String']['input']>
    sourceDisplayNameTo_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    sourceDisplayNameTo_not_i?: InputMaybe<Scalars['String']['input']>
    sourceDisplayNameTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sourceDisplayNameTo_not_starts_with?: InputMaybe<Scalars['String']['input']>
    sourceDisplayNameTo_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    sourceDisplayNameTo_starts_with?: InputMaybe<Scalars['String']['input']>
    sourceDisplayNameTo_starts_with_i?: InputMaybe<Scalars['String']['input']>
    sourceIdFrom?: InputMaybe<Scalars['ID']['input']>
    sourceIdFrom_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    sourceIdFrom_not?: InputMaybe<Scalars['ID']['input']>
    sourceIdFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    sourceIdTo?: InputMaybe<Scalars['ID']['input']>
    sourceIdTo_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    sourceIdTo_not?: InputMaybe<Scalars['ID']['input']>
    sourceIdTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    sourceMetaFrom?: InputMaybe<Scalars['JSON']['input']>
    sourceMetaFrom_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sourceMetaFrom_not?: InputMaybe<Scalars['JSON']['input']>
    sourceMetaFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sourceMetaTo?: InputMaybe<Scalars['JSON']['input']>
    sourceMetaTo_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sourceMetaTo_not?: InputMaybe<Scalars['JSON']['input']>
    sourceMetaTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    statusDisplayNameFrom?: InputMaybe<Scalars['String']['input']>
    statusDisplayNameFrom_contains?: InputMaybe<Scalars['String']['input']>
    statusDisplayNameFrom_contains_i?: InputMaybe<Scalars['String']['input']>
    statusDisplayNameFrom_ends_with?: InputMaybe<Scalars['String']['input']>
    statusDisplayNameFrom_ends_with_i?: InputMaybe<Scalars['String']['input']>
    statusDisplayNameFrom_i?: InputMaybe<Scalars['String']['input']>
    statusDisplayNameFrom_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    statusDisplayNameFrom_not?: InputMaybe<Scalars['String']['input']>
    statusDisplayNameFrom_not_contains?: InputMaybe<Scalars['String']['input']>
    statusDisplayNameFrom_not_contains_i?: InputMaybe<Scalars['String']['input']>
    statusDisplayNameFrom_not_ends_with?: InputMaybe<Scalars['String']['input']>
    statusDisplayNameFrom_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    statusDisplayNameFrom_not_i?: InputMaybe<Scalars['String']['input']>
    statusDisplayNameFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    statusDisplayNameFrom_not_starts_with?: InputMaybe<Scalars['String']['input']>
    statusDisplayNameFrom_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    statusDisplayNameFrom_starts_with?: InputMaybe<Scalars['String']['input']>
    statusDisplayNameFrom_starts_with_i?: InputMaybe<Scalars['String']['input']>
    statusDisplayNameTo?: InputMaybe<Scalars['String']['input']>
    statusDisplayNameTo_contains?: InputMaybe<Scalars['String']['input']>
    statusDisplayNameTo_contains_i?: InputMaybe<Scalars['String']['input']>
    statusDisplayNameTo_ends_with?: InputMaybe<Scalars['String']['input']>
    statusDisplayNameTo_ends_with_i?: InputMaybe<Scalars['String']['input']>
    statusDisplayNameTo_i?: InputMaybe<Scalars['String']['input']>
    statusDisplayNameTo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    statusDisplayNameTo_not?: InputMaybe<Scalars['String']['input']>
    statusDisplayNameTo_not_contains?: InputMaybe<Scalars['String']['input']>
    statusDisplayNameTo_not_contains_i?: InputMaybe<Scalars['String']['input']>
    statusDisplayNameTo_not_ends_with?: InputMaybe<Scalars['String']['input']>
    statusDisplayNameTo_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    statusDisplayNameTo_not_i?: InputMaybe<Scalars['String']['input']>
    statusDisplayNameTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    statusDisplayNameTo_not_starts_with?: InputMaybe<Scalars['String']['input']>
    statusDisplayNameTo_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    statusDisplayNameTo_starts_with?: InputMaybe<Scalars['String']['input']>
    statusDisplayNameTo_starts_with_i?: InputMaybe<Scalars['String']['input']>
    statusIdFrom?: InputMaybe<Scalars['ID']['input']>
    statusIdFrom_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    statusIdFrom_not?: InputMaybe<Scalars['ID']['input']>
    statusIdFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    statusIdTo?: InputMaybe<Scalars['ID']['input']>
    statusIdTo_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    statusIdTo_not?: InputMaybe<Scalars['ID']['input']>
    statusIdTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    statusReasonFrom?: InputMaybe<Scalars['String']['input']>
    statusReasonFrom_contains?: InputMaybe<Scalars['String']['input']>
    statusReasonFrom_contains_i?: InputMaybe<Scalars['String']['input']>
    statusReasonFrom_ends_with?: InputMaybe<Scalars['String']['input']>
    statusReasonFrom_ends_with_i?: InputMaybe<Scalars['String']['input']>
    statusReasonFrom_i?: InputMaybe<Scalars['String']['input']>
    statusReasonFrom_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    statusReasonFrom_not?: InputMaybe<Scalars['String']['input']>
    statusReasonFrom_not_contains?: InputMaybe<Scalars['String']['input']>
    statusReasonFrom_not_contains_i?: InputMaybe<Scalars['String']['input']>
    statusReasonFrom_not_ends_with?: InputMaybe<Scalars['String']['input']>
    statusReasonFrom_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    statusReasonFrom_not_i?: InputMaybe<Scalars['String']['input']>
    statusReasonFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    statusReasonFrom_not_starts_with?: InputMaybe<Scalars['String']['input']>
    statusReasonFrom_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    statusReasonFrom_starts_with?: InputMaybe<Scalars['String']['input']>
    statusReasonFrom_starts_with_i?: InputMaybe<Scalars['String']['input']>
    statusReasonTo?: InputMaybe<Scalars['String']['input']>
    statusReasonTo_contains?: InputMaybe<Scalars['String']['input']>
    statusReasonTo_contains_i?: InputMaybe<Scalars['String']['input']>
    statusReasonTo_ends_with?: InputMaybe<Scalars['String']['input']>
    statusReasonTo_ends_with_i?: InputMaybe<Scalars['String']['input']>
    statusReasonTo_i?: InputMaybe<Scalars['String']['input']>
    statusReasonTo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    statusReasonTo_not?: InputMaybe<Scalars['String']['input']>
    statusReasonTo_not_contains?: InputMaybe<Scalars['String']['input']>
    statusReasonTo_not_contains_i?: InputMaybe<Scalars['String']['input']>
    statusReasonTo_not_ends_with?: InputMaybe<Scalars['String']['input']>
    statusReasonTo_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    statusReasonTo_not_i?: InputMaybe<Scalars['String']['input']>
    statusReasonTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    statusReasonTo_not_starts_with?: InputMaybe<Scalars['String']['input']>
    statusReasonTo_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    statusReasonTo_starts_with?: InputMaybe<Scalars['String']['input']>
    statusReasonTo_starts_with_i?: InputMaybe<Scalars['String']['input']>
    statusReopenedCounterFrom?: InputMaybe<Scalars['Int']['input']>
    statusReopenedCounterFrom_gt?: InputMaybe<Scalars['Int']['input']>
    statusReopenedCounterFrom_gte?: InputMaybe<Scalars['Int']['input']>
    statusReopenedCounterFrom_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    statusReopenedCounterFrom_lt?: InputMaybe<Scalars['Int']['input']>
    statusReopenedCounterFrom_lte?: InputMaybe<Scalars['Int']['input']>
    statusReopenedCounterFrom_not?: InputMaybe<Scalars['Int']['input']>
    statusReopenedCounterFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    statusReopenedCounterTo?: InputMaybe<Scalars['Int']['input']>
    statusReopenedCounterTo_gt?: InputMaybe<Scalars['Int']['input']>
    statusReopenedCounterTo_gte?: InputMaybe<Scalars['Int']['input']>
    statusReopenedCounterTo_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    statusReopenedCounterTo_lt?: InputMaybe<Scalars['Int']['input']>
    statusReopenedCounterTo_lte?: InputMaybe<Scalars['Int']['input']>
    statusReopenedCounterTo_not?: InputMaybe<Scalars['Int']['input']>
    statusReopenedCounterTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    ticket?: InputMaybe<TicketWhereInput>
    ticket_is_null?: InputMaybe<Scalars['Boolean']['input']>
    unitNameFrom?: InputMaybe<Scalars['String']['input']>
    unitNameFrom_contains?: InputMaybe<Scalars['String']['input']>
    unitNameFrom_contains_i?: InputMaybe<Scalars['String']['input']>
    unitNameFrom_ends_with?: InputMaybe<Scalars['String']['input']>
    unitNameFrom_ends_with_i?: InputMaybe<Scalars['String']['input']>
    unitNameFrom_i?: InputMaybe<Scalars['String']['input']>
    unitNameFrom_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitNameFrom_not?: InputMaybe<Scalars['String']['input']>
    unitNameFrom_not_contains?: InputMaybe<Scalars['String']['input']>
    unitNameFrom_not_contains_i?: InputMaybe<Scalars['String']['input']>
    unitNameFrom_not_ends_with?: InputMaybe<Scalars['String']['input']>
    unitNameFrom_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    unitNameFrom_not_i?: InputMaybe<Scalars['String']['input']>
    unitNameFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitNameFrom_not_starts_with?: InputMaybe<Scalars['String']['input']>
    unitNameFrom_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    unitNameFrom_starts_with?: InputMaybe<Scalars['String']['input']>
    unitNameFrom_starts_with_i?: InputMaybe<Scalars['String']['input']>
    unitNameTo?: InputMaybe<Scalars['String']['input']>
    unitNameTo_contains?: InputMaybe<Scalars['String']['input']>
    unitNameTo_contains_i?: InputMaybe<Scalars['String']['input']>
    unitNameTo_ends_with?: InputMaybe<Scalars['String']['input']>
    unitNameTo_ends_with_i?: InputMaybe<Scalars['String']['input']>
    unitNameTo_i?: InputMaybe<Scalars['String']['input']>
    unitNameTo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitNameTo_not?: InputMaybe<Scalars['String']['input']>
    unitNameTo_not_contains?: InputMaybe<Scalars['String']['input']>
    unitNameTo_not_contains_i?: InputMaybe<Scalars['String']['input']>
    unitNameTo_not_ends_with?: InputMaybe<Scalars['String']['input']>
    unitNameTo_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    unitNameTo_not_i?: InputMaybe<Scalars['String']['input']>
    unitNameTo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitNameTo_not_starts_with?: InputMaybe<Scalars['String']['input']>
    unitNameTo_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    unitNameTo_starts_with?: InputMaybe<Scalars['String']['input']>
    unitNameTo_starts_with_i?: InputMaybe<Scalars['String']['input']>
    unitTypeFrom?: InputMaybe<TicketChangeUnitTypeFromType>
    unitTypeFrom_in?: InputMaybe<Array<InputMaybe<TicketChangeUnitTypeFromType>>>
    unitTypeFrom_not?: InputMaybe<TicketChangeUnitTypeFromType>
    unitTypeFrom_not_in?: InputMaybe<Array<InputMaybe<TicketChangeUnitTypeFromType>>>
    unitTypeTo?: InputMaybe<TicketChangeUnitTypeToType>
    unitTypeTo_in?: InputMaybe<Array<InputMaybe<TicketChangeUnitTypeToType>>>
    unitTypeTo_not?: InputMaybe<TicketChangeUnitTypeToType>
    unitTypeTo_not_in?: InputMaybe<Array<InputMaybe<TicketChangeUnitTypeToType>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type TicketChangeWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type TicketChangesCreateInput = {
    data?: InputMaybe<TicketChangeCreateInput>
}

export type TicketChangesUpdateInput = {
    data?: InputMaybe<TicketChangeUpdateInput>
    id: Scalars['ID']['input']
}

/**  Rules for all possible valid combinations of classifiers  */
export type TicketClassifier = {
    __typename?: 'TicketClassifier'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketClassifier List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketClassifier List config, or
   *  3. As an alias to a 'name' field on the TicketClassifier List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketClassifier List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  Type of work to fix incident  */
    category?: Maybe<TicketCategoryClassifier>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['String']['output']>
    /**  Ref to the organization. If this ref is null the object is common for all organizations  */
    organization?: Maybe<Organization>
    /**  Location of incident  */
    place?: Maybe<TicketPlaceClassifier>
    /**  What needs to be done  */
    problem?: Maybe<TicketProblemClassifier>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type TicketClassifierCreateInput = {
    category?: InputMaybe<TicketCategoryClassifierRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    place?: InputMaybe<TicketPlaceClassifierRelateToOneInput>
    problem?: InputMaybe<TicketProblemClassifierRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type TicketClassifierHistoryRecord = {
    __typename?: 'TicketClassifierHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketClassifierHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketClassifierHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the TicketClassifierHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketClassifierHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    category?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<TicketClassifierHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    place?: Maybe<Scalars['String']['output']>
    problem?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type TicketClassifierHistoryRecordCreateInput = {
    category?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<TicketClassifierHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    place?: InputMaybe<Scalars['String']['input']>
    problem?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum TicketClassifierHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type TicketClassifierHistoryRecordUpdateInput = {
    category?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<TicketClassifierHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    place?: InputMaybe<Scalars['String']['input']>
    problem?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type TicketClassifierHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<TicketClassifierHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<TicketClassifierHistoryRecordWhereInput>>>
    category?: InputMaybe<Scalars['String']['input']>
    category_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    category_not?: InputMaybe<Scalars['String']['input']>
    category_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<TicketClassifierHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<TicketClassifierHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<TicketClassifierHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<TicketClassifierHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    place?: InputMaybe<Scalars['String']['input']>
    place_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    place_not?: InputMaybe<Scalars['String']['input']>
    place_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    problem?: InputMaybe<Scalars['String']['input']>
    problem_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    problem_not?: InputMaybe<Scalars['String']['input']>
    problem_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type TicketClassifierHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type TicketClassifierHistoryRecordsCreateInput = {
    data?: InputMaybe<TicketClassifierHistoryRecordCreateInput>
}

export type TicketClassifierHistoryRecordsUpdateInput = {
    data?: InputMaybe<TicketClassifierHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type TicketClassifierRelateToOneInput = {
    connect?: InputMaybe<TicketClassifierWhereUniqueInput>
    create?: InputMaybe<TicketClassifierCreateInput>
    disconnect?: InputMaybe<TicketClassifierWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type TicketClassifierUpdateInput = {
    category?: InputMaybe<TicketCategoryClassifierRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    place?: InputMaybe<TicketPlaceClassifierRelateToOneInput>
    problem?: InputMaybe<TicketProblemClassifierRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type TicketClassifierWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<TicketClassifierWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<TicketClassifierWhereInput>>>
    category?: InputMaybe<TicketCategoryClassifierWhereInput>
    category_is_null?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    place?: InputMaybe<TicketPlaceClassifierWhereInput>
    place_is_null?: InputMaybe<Scalars['Boolean']['input']>
    problem?: InputMaybe<TicketProblemClassifierWhereInput>
    problem_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type TicketClassifierWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type TicketClassifiersCreateInput = {
    data?: InputMaybe<TicketClassifierCreateInput>
}

export type TicketClassifiersUpdateInput = {
    data?: InputMaybe<TicketClassifierUpdateInput>
    id: Scalars['ID']['input']
}

/**  Textual comment for tickets  */
export type TicketComment = {
    __typename?: 'TicketComment'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketComment List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketComment List config, or
   *  3. As an alias to a 'name' field on the TicketComment List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketComment List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  Plain text content  */
    content?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Related ticket of the comment  */
    ticket?: Maybe<Ticket>
    /**  Comment type (internal for an organization or with a resident)  */
    type?: Maybe<TicketCommentTypeType>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    /**  User, who created the comment  */
    user?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type TicketCommentCreateInput = {
    content?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    ticket?: InputMaybe<TicketRelateToOneInput>
    type?: InputMaybe<TicketCommentTypeType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  File attached to the ticket comment  */
export type TicketCommentFile = {
    __typename?: 'TicketCommentFile'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketCommentFile List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketCommentFile List config, or
   *  3. As an alias to a 'name' field on the TicketCommentFile List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketCommentFile List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  File object with meta information and publicUrl  */
    file?: Maybe<File>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['String']['output']>
    /**  Ref to the organization. It is filled in on the server and is read-only  */
    organization?: Maybe<Organization>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Link to ticket  */
    ticket?: Maybe<Ticket>
    /**  Link to ticket comment  */
    ticketComment?: Maybe<TicketComment>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type TicketCommentFileCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['Upload']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    ticket?: InputMaybe<TicketRelateToOneInput>
    ticketComment?: InputMaybe<TicketCommentRelateToOneInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type TicketCommentFileHistoryRecord = {
    __typename?: 'TicketCommentFileHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketCommentFileHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketCommentFileHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the TicketCommentFileHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketCommentFileHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    file?: Maybe<Scalars['JSON']['output']>
    history_action?: Maybe<TicketCommentFileHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    ticket?: Maybe<Scalars['String']['output']>
    ticketComment?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type TicketCommentFileHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['JSON']['input']>
    history_action?: InputMaybe<TicketCommentFileHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    ticket?: InputMaybe<Scalars['String']['input']>
    ticketComment?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum TicketCommentFileHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type TicketCommentFileHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['JSON']['input']>
    history_action?: InputMaybe<TicketCommentFileHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    ticket?: InputMaybe<Scalars['String']['input']>
    ticketComment?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type TicketCommentFileHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<TicketCommentFileHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<TicketCommentFileHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    file?: InputMaybe<Scalars['JSON']['input']>
    file_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    file_not?: InputMaybe<Scalars['JSON']['input']>
    file_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    history_action?: InputMaybe<TicketCommentFileHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<TicketCommentFileHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<TicketCommentFileHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<TicketCommentFileHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    ticket?: InputMaybe<Scalars['String']['input']>
    ticketComment?: InputMaybe<Scalars['String']['input']>
    ticketComment_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    ticketComment_not?: InputMaybe<Scalars['String']['input']>
    ticketComment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    ticket_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    ticket_not?: InputMaybe<Scalars['String']['input']>
    ticket_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type TicketCommentFileHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type TicketCommentFileHistoryRecordsCreateInput = {
    data?: InputMaybe<TicketCommentFileHistoryRecordCreateInput>
}

export type TicketCommentFileHistoryRecordsUpdateInput = {
    data?: InputMaybe<TicketCommentFileHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type TicketCommentFileUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['Upload']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    ticket?: InputMaybe<TicketRelateToOneInput>
    ticketComment?: InputMaybe<TicketCommentRelateToOneInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type TicketCommentFileWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<TicketCommentFileWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<TicketCommentFileWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    file?: InputMaybe<Scalars['String']['input']>
    file_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    file_not?: InputMaybe<Scalars['String']['input']>
    file_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    ticket?: InputMaybe<TicketWhereInput>
    ticketComment?: InputMaybe<TicketCommentWhereInput>
    ticketComment_is_null?: InputMaybe<Scalars['Boolean']['input']>
    ticket_is_null?: InputMaybe<Scalars['Boolean']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type TicketCommentFileWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type TicketCommentFilesCreateInput = {
    data?: InputMaybe<TicketCommentFileCreateInput>
}

export type TicketCommentFilesUpdateInput = {
    data?: InputMaybe<TicketCommentFileUpdateInput>
    id: Scalars['ID']['input']
}

/**  A keystone list  */
export type TicketCommentHistoryRecord = {
    __typename?: 'TicketCommentHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketCommentHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketCommentHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the TicketCommentHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketCommentHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    content?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<TicketCommentHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    ticket?: Maybe<Scalars['String']['output']>
    type?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    user?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type TicketCommentHistoryRecordCreateInput = {
    content?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<TicketCommentHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    ticket?: InputMaybe<Scalars['String']['input']>
    type?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum TicketCommentHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type TicketCommentHistoryRecordUpdateInput = {
    content?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<TicketCommentHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    ticket?: InputMaybe<Scalars['String']['input']>
    type?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type TicketCommentHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<TicketCommentHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<TicketCommentHistoryRecordWhereInput>>>
    content?: InputMaybe<Scalars['String']['input']>
    content_contains?: InputMaybe<Scalars['String']['input']>
    content_contains_i?: InputMaybe<Scalars['String']['input']>
    content_ends_with?: InputMaybe<Scalars['String']['input']>
    content_ends_with_i?: InputMaybe<Scalars['String']['input']>
    content_i?: InputMaybe<Scalars['String']['input']>
    content_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    content_not?: InputMaybe<Scalars['String']['input']>
    content_not_contains?: InputMaybe<Scalars['String']['input']>
    content_not_contains_i?: InputMaybe<Scalars['String']['input']>
    content_not_ends_with?: InputMaybe<Scalars['String']['input']>
    content_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    content_not_i?: InputMaybe<Scalars['String']['input']>
    content_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    content_not_starts_with?: InputMaybe<Scalars['String']['input']>
    content_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    content_starts_with?: InputMaybe<Scalars['String']['input']>
    content_starts_with_i?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<TicketCommentHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<TicketCommentHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<TicketCommentHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<TicketCommentHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    ticket?: InputMaybe<Scalars['String']['input']>
    ticket_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    ticket_not?: InputMaybe<Scalars['String']['input']>
    ticket_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    type?: InputMaybe<Scalars['String']['input']>
    type_contains?: InputMaybe<Scalars['String']['input']>
    type_contains_i?: InputMaybe<Scalars['String']['input']>
    type_ends_with?: InputMaybe<Scalars['String']['input']>
    type_ends_with_i?: InputMaybe<Scalars['String']['input']>
    type_i?: InputMaybe<Scalars['String']['input']>
    type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    type_not?: InputMaybe<Scalars['String']['input']>
    type_not_contains?: InputMaybe<Scalars['String']['input']>
    type_not_contains_i?: InputMaybe<Scalars['String']['input']>
    type_not_ends_with?: InputMaybe<Scalars['String']['input']>
    type_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    type_not_i?: InputMaybe<Scalars['String']['input']>
    type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    type_not_starts_with?: InputMaybe<Scalars['String']['input']>
    type_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    type_starts_with?: InputMaybe<Scalars['String']['input']>
    type_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user?: InputMaybe<Scalars['String']['input']>
    user_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user_not?: InputMaybe<Scalars['String']['input']>
    user_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type TicketCommentHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type TicketCommentHistoryRecordsCreateInput = {
    data?: InputMaybe<TicketCommentHistoryRecordCreateInput>
}

export type TicketCommentHistoryRecordsUpdateInput = {
    data?: InputMaybe<TicketCommentHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type TicketCommentRelateToOneInput = {
    connect?: InputMaybe<TicketCommentWhereUniqueInput>
    create?: InputMaybe<TicketCommentCreateInput>
    disconnect?: InputMaybe<TicketCommentWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export enum TicketCommentTypeType {
    Organization = 'organization',
    Resident = 'resident',
}

export type TicketCommentUpdateInput = {
    content?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    ticket?: InputMaybe<TicketRelateToOneInput>
    type?: InputMaybe<TicketCommentTypeType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type TicketCommentWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<TicketCommentWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<TicketCommentWhereInput>>>
    content?: InputMaybe<Scalars['String']['input']>
    content_contains?: InputMaybe<Scalars['String']['input']>
    content_contains_i?: InputMaybe<Scalars['String']['input']>
    content_ends_with?: InputMaybe<Scalars['String']['input']>
    content_ends_with_i?: InputMaybe<Scalars['String']['input']>
    content_i?: InputMaybe<Scalars['String']['input']>
    content_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    content_not?: InputMaybe<Scalars['String']['input']>
    content_not_contains?: InputMaybe<Scalars['String']['input']>
    content_not_contains_i?: InputMaybe<Scalars['String']['input']>
    content_not_ends_with?: InputMaybe<Scalars['String']['input']>
    content_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    content_not_i?: InputMaybe<Scalars['String']['input']>
    content_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    content_not_starts_with?: InputMaybe<Scalars['String']['input']>
    content_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    content_starts_with?: InputMaybe<Scalars['String']['input']>
    content_starts_with_i?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    ticket?: InputMaybe<TicketWhereInput>
    ticket_is_null?: InputMaybe<Scalars['Boolean']['input']>
    type?: InputMaybe<TicketCommentTypeType>
    type_in?: InputMaybe<Array<InputMaybe<TicketCommentTypeType>>>
    type_not?: InputMaybe<TicketCommentTypeType>
    type_not_in?: InputMaybe<Array<InputMaybe<TicketCommentTypeType>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    user?: InputMaybe<UserWhereInput>
    user_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type TicketCommentWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type TicketCommentsCreateInput = {
    data?: InputMaybe<TicketCommentCreateInput>
}

export type TicketCommentsUpdateInput = {
    data?: InputMaybe<TicketCommentUpdateInput>
    id: Scalars['ID']['input']
}

export type TicketCreateInput = {
    assignee?: InputMaybe<UserRelateToOneInput>
    canReadByResident?: InputMaybe<Scalars['Boolean']['input']>
    categoryClassifier?: InputMaybe<TicketCategoryClassifierRelateToOneInput>
    classifier?: InputMaybe<TicketClassifierRelateToOneInput>
    client?: InputMaybe<UserRelateToOneInput>
    clientEmail?: InputMaybe<Scalars['String']['input']>
    clientName?: InputMaybe<Scalars['String']['input']>
    clientPhone?: InputMaybe<Scalars['String']['input']>
    completedAt?: InputMaybe<Scalars['String']['input']>
    contact?: InputMaybe<ContactRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deadline?: InputMaybe<Scalars['String']['input']>
    deferredUntil?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    details?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    executor?: InputMaybe<UserRelateToOneInput>
    feedbackAdditionalOptions?: InputMaybe<Array<InputMaybe<FeedbackAdditionalOptionsType>>>
    feedbackComment?: InputMaybe<Scalars['String']['input']>
    feedbackUpdatedAt?: InputMaybe<Scalars['String']['input']>
    feedbackValue?: InputMaybe<TicketFeedbackValueType>
    floorName?: InputMaybe<Scalars['String']['input']>
    isAutoClassified?: InputMaybe<Scalars['Boolean']['input']>
    isCompletedAfterDeadline?: InputMaybe<Scalars['Boolean']['input']>
    isEmergency?: InputMaybe<Scalars['Boolean']['input']>
    isPaid?: InputMaybe<Scalars['Boolean']['input']>
    isPayable?: InputMaybe<Scalars['Boolean']['input']>
    isResidentTicket?: InputMaybe<Scalars['Boolean']['input']>
    isWarranty?: InputMaybe<Scalars['Boolean']['input']>
    lastCommentAt?: InputMaybe<Scalars['String']['input']>
    lastCommentWithResidentTypeAt?: InputMaybe<Scalars['String']['input']>
    lastResidentCommentAt?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    number?: InputMaybe<Scalars['Int']['input']>
    order?: InputMaybe<Scalars['Int']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    placeClassifier?: InputMaybe<TicketPlaceClassifierRelateToOneInput>
    problemClassifier?: InputMaybe<TicketProblemClassifierRelateToOneInput>
    property?: InputMaybe<PropertyRelateToOneInput>
    propertyAddress?: InputMaybe<Scalars['String']['input']>
    propertyAddressMeta?: InputMaybe<Scalars['JSON']['input']>
    qualityControlAdditionalOptions?: InputMaybe<Array<InputMaybe<QualityControlAdditionalOptionsType>>>
    qualityControlComment?: InputMaybe<Scalars['String']['input']>
    qualityControlUpdatedAt?: InputMaybe<Scalars['String']['input']>
    qualityControlUpdatedBy?: InputMaybe<UserRelateToOneInput>
    qualityControlValue?: InputMaybe<TicketQualityControlValueType>
    related?: InputMaybe<TicketRelateToOneInput>
    reviewComment?: InputMaybe<Scalars['String']['input']>
    reviewValue?: InputMaybe<TicketReviewValueType>
    sectionName?: InputMaybe<Scalars['String']['input']>
    sectionType?: InputMaybe<TicketSectionTypeType>
    sender?: InputMaybe<SenderFieldInput>
    source?: InputMaybe<TicketSourceRelateToOneInput>
    sourceMeta?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<TicketStatusRelateToOneInput>
    statusReason?: InputMaybe<Scalars['String']['input']>
    statusReopenedCounter?: InputMaybe<Scalars['Int']['input']>
    statusUpdatedAt?: InputMaybe<Scalars['String']['input']>
    unitName?: InputMaybe<Scalars['String']['input']>
    unitType?: InputMaybe<TicketUnitTypeType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  Information about generation process of ticket document  */
export type TicketDocumentGenerationTask = {
    __typename?: 'TicketDocumentGenerationTask'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketDocumentGenerationTask List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketDocumentGenerationTask List config, or
   *  3. As an alias to a 'name' field on the TicketDocumentGenerationTask List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketDocumentGenerationTask List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Type of document  */
    documentType?: Maybe<TicketDocumentGenerationTaskDocumentTypeType>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  Meta information about file, saved outside of database somewhere. Shape of meta information JSON object is specific to file adapter, used by saving a file.  */
    file?: Maybe<File>
    /**  Output file format  */
    format?: Maybe<TicketDocumentGenerationTaskFormatType>
    id: Scalars['ID']['output']
    /**  Structured untyped metadata, can be used to store errors or anything else  */
    meta?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Progress of current generation operation  */
    progress?: Maybe<Scalars['Int']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Status of current generation operation  */
    status?: Maybe<TicketDocumentGenerationTaskStatusType>
    /**  The application for which the document is generated  */
    ticket?: Maybe<Ticket>
    /**  All datetime fields in export template will be converted to specified tz  */
    timeZone?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    /**  User that requested this generation operation. Will be used for read access checks to display all generating tasks somewhere and to display progress indicator of ongoing generating task for current user  */
    user?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type TicketDocumentGenerationTaskCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    documentType?: InputMaybe<TicketDocumentGenerationTaskDocumentTypeType>
    dv?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['Upload']['input']>
    format?: InputMaybe<TicketDocumentGenerationTaskFormatType>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    progress?: InputMaybe<Scalars['Int']['input']>
    sender?: InputMaybe<SenderFieldInput>
    status?: InputMaybe<TicketDocumentGenerationTaskStatusType>
    ticket?: InputMaybe<TicketRelateToOneInput>
    timeZone?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum TicketDocumentGenerationTaskDocumentTypeType {
    CompletionWorks = 'completionWorks',
    PaidWorks = 'paidWorks',
}

export enum TicketDocumentGenerationTaskFormatType {
    Docx = 'docx',
}

/**  A keystone list  */
export type TicketDocumentGenerationTaskHistoryRecord = {
    __typename?: 'TicketDocumentGenerationTaskHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketDocumentGenerationTaskHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketDocumentGenerationTaskHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the TicketDocumentGenerationTaskHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketDocumentGenerationTaskHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    documentType?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    file?: Maybe<Scalars['JSON']['output']>
    format?: Maybe<Scalars['String']['output']>
    history_action?: Maybe<TicketDocumentGenerationTaskHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    meta?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    progress?: Maybe<Scalars['Int']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    status?: Maybe<Scalars['String']['output']>
    ticket?: Maybe<Scalars['String']['output']>
    timeZone?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    user?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type TicketDocumentGenerationTaskHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    documentType?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['JSON']['input']>
    format?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<TicketDocumentGenerationTaskHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    progress?: InputMaybe<Scalars['Int']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    ticket?: InputMaybe<Scalars['String']['input']>
    timeZone?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum TicketDocumentGenerationTaskHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type TicketDocumentGenerationTaskHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    documentType?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['JSON']['input']>
    format?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<TicketDocumentGenerationTaskHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    progress?: InputMaybe<Scalars['Int']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    ticket?: InputMaybe<Scalars['String']['input']>
    timeZone?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type TicketDocumentGenerationTaskHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<TicketDocumentGenerationTaskHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<TicketDocumentGenerationTaskHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    documentType?: InputMaybe<Scalars['String']['input']>
    documentType_contains?: InputMaybe<Scalars['String']['input']>
    documentType_contains_i?: InputMaybe<Scalars['String']['input']>
    documentType_ends_with?: InputMaybe<Scalars['String']['input']>
    documentType_ends_with_i?: InputMaybe<Scalars['String']['input']>
    documentType_i?: InputMaybe<Scalars['String']['input']>
    documentType_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    documentType_not?: InputMaybe<Scalars['String']['input']>
    documentType_not_contains?: InputMaybe<Scalars['String']['input']>
    documentType_not_contains_i?: InputMaybe<Scalars['String']['input']>
    documentType_not_ends_with?: InputMaybe<Scalars['String']['input']>
    documentType_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    documentType_not_i?: InputMaybe<Scalars['String']['input']>
    documentType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    documentType_not_starts_with?: InputMaybe<Scalars['String']['input']>
    documentType_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    documentType_starts_with?: InputMaybe<Scalars['String']['input']>
    documentType_starts_with_i?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    file?: InputMaybe<Scalars['JSON']['input']>
    file_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    file_not?: InputMaybe<Scalars['JSON']['input']>
    file_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    format?: InputMaybe<Scalars['String']['input']>
    format_contains?: InputMaybe<Scalars['String']['input']>
    format_contains_i?: InputMaybe<Scalars['String']['input']>
    format_ends_with?: InputMaybe<Scalars['String']['input']>
    format_ends_with_i?: InputMaybe<Scalars['String']['input']>
    format_i?: InputMaybe<Scalars['String']['input']>
    format_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    format_not?: InputMaybe<Scalars['String']['input']>
    format_not_contains?: InputMaybe<Scalars['String']['input']>
    format_not_contains_i?: InputMaybe<Scalars['String']['input']>
    format_not_ends_with?: InputMaybe<Scalars['String']['input']>
    format_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    format_not_i?: InputMaybe<Scalars['String']['input']>
    format_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    format_not_starts_with?: InputMaybe<Scalars['String']['input']>
    format_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    format_starts_with?: InputMaybe<Scalars['String']['input']>
    format_starts_with_i?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<TicketDocumentGenerationTaskHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<TicketDocumentGenerationTaskHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<TicketDocumentGenerationTaskHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<TicketDocumentGenerationTaskHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    progress?: InputMaybe<Scalars['Int']['input']>
    progress_gt?: InputMaybe<Scalars['Int']['input']>
    progress_gte?: InputMaybe<Scalars['Int']['input']>
    progress_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    progress_lt?: InputMaybe<Scalars['Int']['input']>
    progress_lte?: InputMaybe<Scalars['Int']['input']>
    progress_not?: InputMaybe<Scalars['Int']['input']>
    progress_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    status?: InputMaybe<Scalars['String']['input']>
    status_contains?: InputMaybe<Scalars['String']['input']>
    status_contains_i?: InputMaybe<Scalars['String']['input']>
    status_ends_with?: InputMaybe<Scalars['String']['input']>
    status_ends_with_i?: InputMaybe<Scalars['String']['input']>
    status_i?: InputMaybe<Scalars['String']['input']>
    status_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status_not?: InputMaybe<Scalars['String']['input']>
    status_not_contains?: InputMaybe<Scalars['String']['input']>
    status_not_contains_i?: InputMaybe<Scalars['String']['input']>
    status_not_ends_with?: InputMaybe<Scalars['String']['input']>
    status_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    status_not_i?: InputMaybe<Scalars['String']['input']>
    status_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status_not_starts_with?: InputMaybe<Scalars['String']['input']>
    status_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    status_starts_with?: InputMaybe<Scalars['String']['input']>
    status_starts_with_i?: InputMaybe<Scalars['String']['input']>
    ticket?: InputMaybe<Scalars['String']['input']>
    ticket_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    ticket_not?: InputMaybe<Scalars['String']['input']>
    ticket_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    timeZone?: InputMaybe<Scalars['String']['input']>
    timeZone_contains?: InputMaybe<Scalars['String']['input']>
    timeZone_contains_i?: InputMaybe<Scalars['String']['input']>
    timeZone_ends_with?: InputMaybe<Scalars['String']['input']>
    timeZone_ends_with_i?: InputMaybe<Scalars['String']['input']>
    timeZone_i?: InputMaybe<Scalars['String']['input']>
    timeZone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    timeZone_not?: InputMaybe<Scalars['String']['input']>
    timeZone_not_contains?: InputMaybe<Scalars['String']['input']>
    timeZone_not_contains_i?: InputMaybe<Scalars['String']['input']>
    timeZone_not_ends_with?: InputMaybe<Scalars['String']['input']>
    timeZone_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    timeZone_not_i?: InputMaybe<Scalars['String']['input']>
    timeZone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    timeZone_not_starts_with?: InputMaybe<Scalars['String']['input']>
    timeZone_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    timeZone_starts_with?: InputMaybe<Scalars['String']['input']>
    timeZone_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user?: InputMaybe<Scalars['String']['input']>
    user_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user_not?: InputMaybe<Scalars['String']['input']>
    user_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type TicketDocumentGenerationTaskHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type TicketDocumentGenerationTaskHistoryRecordsCreateInput = {
    data?: InputMaybe<TicketDocumentGenerationTaskHistoryRecordCreateInput>
}

export type TicketDocumentGenerationTaskHistoryRecordsUpdateInput = {
    data?: InputMaybe<TicketDocumentGenerationTaskHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export enum TicketDocumentGenerationTaskStatusType {
    Cancelled = 'cancelled',
    Completed = 'completed',
    Error = 'error',
    Processing = 'processing',
}

export type TicketDocumentGenerationTaskUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    documentType?: InputMaybe<TicketDocumentGenerationTaskDocumentTypeType>
    dv?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['Upload']['input']>
    format?: InputMaybe<TicketDocumentGenerationTaskFormatType>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    progress?: InputMaybe<Scalars['Int']['input']>
    sender?: InputMaybe<SenderFieldInput>
    status?: InputMaybe<TicketDocumentGenerationTaskStatusType>
    ticket?: InputMaybe<TicketRelateToOneInput>
    timeZone?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type TicketDocumentGenerationTaskWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<TicketDocumentGenerationTaskWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<TicketDocumentGenerationTaskWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    documentType?: InputMaybe<TicketDocumentGenerationTaskDocumentTypeType>
    documentType_in?: InputMaybe<Array<InputMaybe<TicketDocumentGenerationTaskDocumentTypeType>>>
    documentType_not?: InputMaybe<TicketDocumentGenerationTaskDocumentTypeType>
    documentType_not_in?: InputMaybe<Array<InputMaybe<TicketDocumentGenerationTaskDocumentTypeType>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    file?: InputMaybe<Scalars['String']['input']>
    file_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    file_not?: InputMaybe<Scalars['String']['input']>
    file_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    format?: InputMaybe<TicketDocumentGenerationTaskFormatType>
    format_in?: InputMaybe<Array<InputMaybe<TicketDocumentGenerationTaskFormatType>>>
    format_not?: InputMaybe<TicketDocumentGenerationTaskFormatType>
    format_not_in?: InputMaybe<Array<InputMaybe<TicketDocumentGenerationTaskFormatType>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    progress?: InputMaybe<Scalars['Int']['input']>
    progress_gt?: InputMaybe<Scalars['Int']['input']>
    progress_gte?: InputMaybe<Scalars['Int']['input']>
    progress_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    progress_lt?: InputMaybe<Scalars['Int']['input']>
    progress_lte?: InputMaybe<Scalars['Int']['input']>
    progress_not?: InputMaybe<Scalars['Int']['input']>
    progress_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    status?: InputMaybe<TicketDocumentGenerationTaskStatusType>
    status_in?: InputMaybe<Array<InputMaybe<TicketDocumentGenerationTaskStatusType>>>
    status_not?: InputMaybe<TicketDocumentGenerationTaskStatusType>
    status_not_in?: InputMaybe<Array<InputMaybe<TicketDocumentGenerationTaskStatusType>>>
    ticket?: InputMaybe<TicketWhereInput>
    ticket_is_null?: InputMaybe<Scalars['Boolean']['input']>
    timeZone?: InputMaybe<Scalars['String']['input']>
    timeZone_contains?: InputMaybe<Scalars['String']['input']>
    timeZone_contains_i?: InputMaybe<Scalars['String']['input']>
    timeZone_ends_with?: InputMaybe<Scalars['String']['input']>
    timeZone_ends_with_i?: InputMaybe<Scalars['String']['input']>
    timeZone_i?: InputMaybe<Scalars['String']['input']>
    timeZone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    timeZone_not?: InputMaybe<Scalars['String']['input']>
    timeZone_not_contains?: InputMaybe<Scalars['String']['input']>
    timeZone_not_contains_i?: InputMaybe<Scalars['String']['input']>
    timeZone_not_ends_with?: InputMaybe<Scalars['String']['input']>
    timeZone_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    timeZone_not_i?: InputMaybe<Scalars['String']['input']>
    timeZone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    timeZone_not_starts_with?: InputMaybe<Scalars['String']['input']>
    timeZone_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    timeZone_starts_with?: InputMaybe<Scalars['String']['input']>
    timeZone_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    user?: InputMaybe<UserWhereInput>
    user_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type TicketDocumentGenerationTaskWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type TicketDocumentGenerationTasksCreateInput = {
    data?: InputMaybe<TicketDocumentGenerationTaskCreateInput>
}

export type TicketDocumentGenerationTasksUpdateInput = {
    data?: InputMaybe<TicketDocumentGenerationTaskUpdateInput>
    id: Scalars['ID']['input']
}

export type TicketExportOptions = {
    __typename?: 'TicketExportOptions'
    commentIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    haveAllComments?: Maybe<Scalars['Boolean']['output']>
    haveConsumedMaterials?: Maybe<Scalars['Boolean']['output']>
    haveListCompletedWorks?: Maybe<Scalars['Boolean']['output']>
    haveTotalCostWork?: Maybe<Scalars['Boolean']['output']>
}

export type TicketExportOptionsInput = {
    commentIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    haveAllComments?: InputMaybe<Scalars['Boolean']['input']>
    haveConsumedMaterials?: InputMaybe<Scalars['Boolean']['input']>
    haveListCompletedWorks?: InputMaybe<Scalars['Boolean']['input']>
    haveTotalCostWork?: InputMaybe<Scalars['Boolean']['input']>
}

/**  Stores requested export format, status of export job, link to exported file and information about progress of export job  */
export type TicketExportTask = {
    __typename?: 'TicketExportTask'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketExportTask List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketExportTask List config, or
   *  3. As an alias to a 'name' field on the TicketExportTask List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketExportTask List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  How many records at the moment are exported  */
    exportedRecordsCount?: Maybe<Scalars['Int']['output']>
    /**  Meta information about file, saved outside of database somewhere. Shape of meta information JSON object is specific to file adapter, used by saving a file.  */
    file?: Maybe<File>
    /**  Requested export file format  */
    format?: Maybe<TicketExportTaskFormatType>
    id: Scalars['ID']['output']
    /**  Requested export locale, in that the resulting file will be rendered  */
    locale?: Maybe<Scalars['String']['output']>
    /**  Stores information about query and ids of exported and failed records  */
    meta?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Options for exporting tickets into PDF format  */
    options?: Maybe<TicketExportOptions>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Sorting parameters for records to export  */
    sortBy?: Maybe<Scalars['JSON']['output']>
    /**  Status of export job  */
    status?: Maybe<TicketExportTaskStatusType>
    /**  To requested timeZone all datetime fields will be converted  */
    timeZone?: Maybe<Scalars['String']['output']>
    /**  Total records to export that will be determined at server side in export operation  */
    totalRecordsCount?: Maybe<Scalars['Int']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    /**  User that requested this exporting operation. Will be used for read access checks to display all exported tasks somewhere and to display progress indicator of ongoing exporting task for current user  */
    user?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
    /**  Filtering conditions for records to export  */
    where?: Maybe<Scalars['JSON']['output']>
}

export type TicketExportTaskCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['Upload']['input']>
    format?: InputMaybe<TicketExportTaskFormatType>
    locale?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    options?: InputMaybe<TicketExportOptionsInput>
    sender?: InputMaybe<SenderFieldInput>
    sortBy?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<TicketExportTaskStatusType>
    timeZone?: InputMaybe<Scalars['String']['input']>
    totalRecordsCount?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
    where?: InputMaybe<Scalars['JSON']['input']>
}

export enum TicketExportTaskFormatType {
    Excel = 'excel',
    Pdf = 'pdf',
}

/**  A keystone list  */
export type TicketExportTaskHistoryRecord = {
    __typename?: 'TicketExportTaskHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketExportTaskHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketExportTaskHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the TicketExportTaskHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketExportTaskHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    exportedRecordsCount?: Maybe<Scalars['Int']['output']>
    file?: Maybe<Scalars['JSON']['output']>
    format?: Maybe<Scalars['String']['output']>
    history_action?: Maybe<TicketExportTaskHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    locale?: Maybe<Scalars['String']['output']>
    meta?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    options?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    sortBy?: Maybe<Scalars['JSON']['output']>
    status?: Maybe<Scalars['String']['output']>
    timeZone?: Maybe<Scalars['String']['output']>
    totalRecordsCount?: Maybe<Scalars['Int']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    user?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
    where?: Maybe<Scalars['JSON']['output']>
}

export type TicketExportTaskHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['JSON']['input']>
    format?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<TicketExportTaskHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    locale?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    options?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sortBy?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    timeZone?: InputMaybe<Scalars['String']['input']>
    totalRecordsCount?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    where?: InputMaybe<Scalars['JSON']['input']>
}

export enum TicketExportTaskHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type TicketExportTaskHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['JSON']['input']>
    format?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<TicketExportTaskHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    locale?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    options?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sortBy?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    timeZone?: InputMaybe<Scalars['String']['input']>
    totalRecordsCount?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    where?: InputMaybe<Scalars['JSON']['input']>
}

export type TicketExportTaskHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<TicketExportTaskHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<TicketExportTaskHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    exportedRecordsCount?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_gt?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_gte?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    exportedRecordsCount_lt?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_lte?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_not?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    file?: InputMaybe<Scalars['JSON']['input']>
    file_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    file_not?: InputMaybe<Scalars['JSON']['input']>
    file_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    format?: InputMaybe<Scalars['String']['input']>
    format_contains?: InputMaybe<Scalars['String']['input']>
    format_contains_i?: InputMaybe<Scalars['String']['input']>
    format_ends_with?: InputMaybe<Scalars['String']['input']>
    format_ends_with_i?: InputMaybe<Scalars['String']['input']>
    format_i?: InputMaybe<Scalars['String']['input']>
    format_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    format_not?: InputMaybe<Scalars['String']['input']>
    format_not_contains?: InputMaybe<Scalars['String']['input']>
    format_not_contains_i?: InputMaybe<Scalars['String']['input']>
    format_not_ends_with?: InputMaybe<Scalars['String']['input']>
    format_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    format_not_i?: InputMaybe<Scalars['String']['input']>
    format_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    format_not_starts_with?: InputMaybe<Scalars['String']['input']>
    format_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    format_starts_with?: InputMaybe<Scalars['String']['input']>
    format_starts_with_i?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<TicketExportTaskHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<TicketExportTaskHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<TicketExportTaskHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<TicketExportTaskHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    locale?: InputMaybe<Scalars['String']['input']>
    locale_contains?: InputMaybe<Scalars['String']['input']>
    locale_contains_i?: InputMaybe<Scalars['String']['input']>
    locale_ends_with?: InputMaybe<Scalars['String']['input']>
    locale_ends_with_i?: InputMaybe<Scalars['String']['input']>
    locale_i?: InputMaybe<Scalars['String']['input']>
    locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    locale_not?: InputMaybe<Scalars['String']['input']>
    locale_not_contains?: InputMaybe<Scalars['String']['input']>
    locale_not_contains_i?: InputMaybe<Scalars['String']['input']>
    locale_not_ends_with?: InputMaybe<Scalars['String']['input']>
    locale_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    locale_not_i?: InputMaybe<Scalars['String']['input']>
    locale_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    locale_not_starts_with?: InputMaybe<Scalars['String']['input']>
    locale_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    locale_starts_with?: InputMaybe<Scalars['String']['input']>
    locale_starts_with_i?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    options?: InputMaybe<Scalars['JSON']['input']>
    options_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    options_not?: InputMaybe<Scalars['JSON']['input']>
    options_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sortBy?: InputMaybe<Scalars['JSON']['input']>
    sortBy_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sortBy_not?: InputMaybe<Scalars['JSON']['input']>
    sortBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    status?: InputMaybe<Scalars['String']['input']>
    status_contains?: InputMaybe<Scalars['String']['input']>
    status_contains_i?: InputMaybe<Scalars['String']['input']>
    status_ends_with?: InputMaybe<Scalars['String']['input']>
    status_ends_with_i?: InputMaybe<Scalars['String']['input']>
    status_i?: InputMaybe<Scalars['String']['input']>
    status_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status_not?: InputMaybe<Scalars['String']['input']>
    status_not_contains?: InputMaybe<Scalars['String']['input']>
    status_not_contains_i?: InputMaybe<Scalars['String']['input']>
    status_not_ends_with?: InputMaybe<Scalars['String']['input']>
    status_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    status_not_i?: InputMaybe<Scalars['String']['input']>
    status_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status_not_starts_with?: InputMaybe<Scalars['String']['input']>
    status_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    status_starts_with?: InputMaybe<Scalars['String']['input']>
    status_starts_with_i?: InputMaybe<Scalars['String']['input']>
    timeZone?: InputMaybe<Scalars['String']['input']>
    timeZone_contains?: InputMaybe<Scalars['String']['input']>
    timeZone_contains_i?: InputMaybe<Scalars['String']['input']>
    timeZone_ends_with?: InputMaybe<Scalars['String']['input']>
    timeZone_ends_with_i?: InputMaybe<Scalars['String']['input']>
    timeZone_i?: InputMaybe<Scalars['String']['input']>
    timeZone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    timeZone_not?: InputMaybe<Scalars['String']['input']>
    timeZone_not_contains?: InputMaybe<Scalars['String']['input']>
    timeZone_not_contains_i?: InputMaybe<Scalars['String']['input']>
    timeZone_not_ends_with?: InputMaybe<Scalars['String']['input']>
    timeZone_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    timeZone_not_i?: InputMaybe<Scalars['String']['input']>
    timeZone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    timeZone_not_starts_with?: InputMaybe<Scalars['String']['input']>
    timeZone_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    timeZone_starts_with?: InputMaybe<Scalars['String']['input']>
    timeZone_starts_with_i?: InputMaybe<Scalars['String']['input']>
    totalRecordsCount?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_gt?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_gte?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    totalRecordsCount_lt?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_lte?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_not?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user?: InputMaybe<Scalars['String']['input']>
    user_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user_not?: InputMaybe<Scalars['String']['input']>
    user_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    where?: InputMaybe<Scalars['JSON']['input']>
    where_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    where_not?: InputMaybe<Scalars['JSON']['input']>
    where_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
}

export type TicketExportTaskHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type TicketExportTaskHistoryRecordsCreateInput = {
    data?: InputMaybe<TicketExportTaskHistoryRecordCreateInput>
}

export type TicketExportTaskHistoryRecordsUpdateInput = {
    data?: InputMaybe<TicketExportTaskHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export enum TicketExportTaskStatusType {
    Cancelled = 'cancelled',
    Completed = 'completed',
    Error = 'error',
    Processing = 'processing',
}

export type TicketExportTaskUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['Upload']['input']>
    format?: InputMaybe<TicketExportTaskFormatType>
    locale?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    options?: InputMaybe<TicketExportOptionsInput>
    sender?: InputMaybe<SenderFieldInput>
    sortBy?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<TicketExportTaskStatusType>
    timeZone?: InputMaybe<Scalars['String']['input']>
    totalRecordsCount?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
    where?: InputMaybe<Scalars['JSON']['input']>
}

export type TicketExportTaskWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<TicketExportTaskWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<TicketExportTaskWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    exportedRecordsCount?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_gt?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_gte?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    exportedRecordsCount_lt?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_lte?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_not?: InputMaybe<Scalars['Int']['input']>
    exportedRecordsCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    file?: InputMaybe<Scalars['String']['input']>
    file_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    file_not?: InputMaybe<Scalars['String']['input']>
    file_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    format?: InputMaybe<TicketExportTaskFormatType>
    format_in?: InputMaybe<Array<InputMaybe<TicketExportTaskFormatType>>>
    format_not?: InputMaybe<TicketExportTaskFormatType>
    format_not_in?: InputMaybe<Array<InputMaybe<TicketExportTaskFormatType>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    locale?: InputMaybe<Scalars['String']['input']>
    locale_contains?: InputMaybe<Scalars['String']['input']>
    locale_contains_i?: InputMaybe<Scalars['String']['input']>
    locale_ends_with?: InputMaybe<Scalars['String']['input']>
    locale_ends_with_i?: InputMaybe<Scalars['String']['input']>
    locale_i?: InputMaybe<Scalars['String']['input']>
    locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    locale_not?: InputMaybe<Scalars['String']['input']>
    locale_not_contains?: InputMaybe<Scalars['String']['input']>
    locale_not_contains_i?: InputMaybe<Scalars['String']['input']>
    locale_not_ends_with?: InputMaybe<Scalars['String']['input']>
    locale_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    locale_not_i?: InputMaybe<Scalars['String']['input']>
    locale_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    locale_not_starts_with?: InputMaybe<Scalars['String']['input']>
    locale_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    locale_starts_with?: InputMaybe<Scalars['String']['input']>
    locale_starts_with_i?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    options?: InputMaybe<TicketExportOptionsInput>
    options_in?: InputMaybe<Array<InputMaybe<TicketExportOptionsInput>>>
    options_not?: InputMaybe<TicketExportOptionsInput>
    options_not_in?: InputMaybe<Array<InputMaybe<TicketExportOptionsInput>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sortBy?: InputMaybe<Scalars['JSON']['input']>
    sortBy_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sortBy_not?: InputMaybe<Scalars['JSON']['input']>
    sortBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    status?: InputMaybe<TicketExportTaskStatusType>
    status_in?: InputMaybe<Array<InputMaybe<TicketExportTaskStatusType>>>
    status_not?: InputMaybe<TicketExportTaskStatusType>
    status_not_in?: InputMaybe<Array<InputMaybe<TicketExportTaskStatusType>>>
    timeZone?: InputMaybe<Scalars['String']['input']>
    timeZone_contains?: InputMaybe<Scalars['String']['input']>
    timeZone_contains_i?: InputMaybe<Scalars['String']['input']>
    timeZone_ends_with?: InputMaybe<Scalars['String']['input']>
    timeZone_ends_with_i?: InputMaybe<Scalars['String']['input']>
    timeZone_i?: InputMaybe<Scalars['String']['input']>
    timeZone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    timeZone_not?: InputMaybe<Scalars['String']['input']>
    timeZone_not_contains?: InputMaybe<Scalars['String']['input']>
    timeZone_not_contains_i?: InputMaybe<Scalars['String']['input']>
    timeZone_not_ends_with?: InputMaybe<Scalars['String']['input']>
    timeZone_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    timeZone_not_i?: InputMaybe<Scalars['String']['input']>
    timeZone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    timeZone_not_starts_with?: InputMaybe<Scalars['String']['input']>
    timeZone_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    timeZone_starts_with?: InputMaybe<Scalars['String']['input']>
    timeZone_starts_with_i?: InputMaybe<Scalars['String']['input']>
    totalRecordsCount?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_gt?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_gte?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    totalRecordsCount_lt?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_lte?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_not?: InputMaybe<Scalars['Int']['input']>
    totalRecordsCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    user?: InputMaybe<UserWhereInput>
    user_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    where?: InputMaybe<Scalars['JSON']['input']>
    where_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    where_not?: InputMaybe<Scalars['JSON']['input']>
    where_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
}

export type TicketExportTaskWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type TicketExportTasksCreateInput = {
    data?: InputMaybe<TicketExportTaskCreateInput>
}

export type TicketExportTasksUpdateInput = {
    data?: InputMaybe<TicketExportTaskUpdateInput>
    id: Scalars['ID']['input']
}

export enum TicketFeedbackValueType {
    Bad = 'bad',
    Good = 'good',
    Returned = 'returned',
}

/**  File attached to the ticket  */
export type TicketFile = {
    __typename?: 'TicketFile'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketFile List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketFile List config, or
   *  3. As an alias to a 'name' field on the TicketFile List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketFile List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  File object with meta information and publicUrl  */
    file?: Maybe<File>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['String']['output']>
    /**  Ref to the organization. It is filled in on the server and is read-only  */
    organization?: Maybe<Organization>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Link to ticket  */
    ticket?: Maybe<Ticket>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type TicketFileCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['Upload']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    ticket?: InputMaybe<TicketRelateToOneInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type TicketFileHistoryRecord = {
    __typename?: 'TicketFileHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketFileHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketFileHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the TicketFileHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketFileHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    file?: Maybe<Scalars['JSON']['output']>
    history_action?: Maybe<TicketFileHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    ticket?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type TicketFileHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['JSON']['input']>
    history_action?: InputMaybe<TicketFileHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    ticket?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum TicketFileHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type TicketFileHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['JSON']['input']>
    history_action?: InputMaybe<TicketFileHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    ticket?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type TicketFileHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<TicketFileHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<TicketFileHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    file?: InputMaybe<Scalars['JSON']['input']>
    file_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    file_not?: InputMaybe<Scalars['JSON']['input']>
    file_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    history_action?: InputMaybe<TicketFileHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<TicketFileHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<TicketFileHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<TicketFileHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    ticket?: InputMaybe<Scalars['String']['input']>
    ticket_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    ticket_not?: InputMaybe<Scalars['String']['input']>
    ticket_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type TicketFileHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type TicketFileHistoryRecordsCreateInput = {
    data?: InputMaybe<TicketFileHistoryRecordCreateInput>
}

export type TicketFileHistoryRecordsUpdateInput = {
    data?: InputMaybe<TicketFileHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type TicketFileUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['Upload']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    ticket?: InputMaybe<TicketRelateToOneInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type TicketFileWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<TicketFileWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<TicketFileWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    file?: InputMaybe<Scalars['String']['input']>
    file_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    file_not?: InputMaybe<Scalars['String']['input']>
    file_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    ticket?: InputMaybe<TicketWhereInput>
    ticket_is_null?: InputMaybe<Scalars['Boolean']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type TicketFileWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type TicketFilesCreateInput = {
    data?: InputMaybe<TicketFileCreateInput>
}

export type TicketFilesUpdateInput = {
    data?: InputMaybe<TicketFileUpdateInput>
    id: Scalars['ID']['input']
}

export type TicketFilter = {
    __typename?: 'TicketFilter'
    address?: Maybe<Scalars['String']['output']>
    assignee?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    assigneeName?: Maybe<Scalars['String']['output']>
    attributes?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    categoryClassifier?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    clientName?: Maybe<Scalars['String']['output']>
    clientPhone?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    completedAt?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    contactIsNull?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    createdAt?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    createdBy?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    deadline?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    details?: Maybe<Scalars['String']['output']>
    executor?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    executorName?: Maybe<Scalars['String']['output']>
    feedbackValue?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    floorName?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    isCompletedAfterDeadline?: Maybe<Scalars['String']['output']>
    lastCommentAt?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    number?: Maybe<Scalars['Int']['output']>
    organization?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    placeClassifier?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    problemClassifier?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    property?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    propertyScope?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    qualityControlValue?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    reviewValue?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    sectionName?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    source?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    status?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    type?: Maybe<Scalars['String']['output']>
    unitName?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    unitType?: Maybe<Array<Maybe<Scalars['String']['output']>>>
}

/**  Employee specific ticket filter template  */
export type TicketFilterTemplate = {
    __typename?: 'TicketFilterTemplate'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketFilterTemplate List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketFilterTemplate List config, or
   *  3. As an alias to a 'name' field on the TicketFilterTemplate List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketFilterTemplate List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  Link to employee, who created this template  */
    employee?: Maybe<OrganizationEmployee>
    /**  Filter that match the given template  */
    fields?: Maybe<TicketFilter>
    id: Scalars['ID']['output']
    /**  Ticket filter template name  */
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type TicketFilterTemplateCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    employee?: InputMaybe<OrganizationEmployeeRelateToOneInput>
    fields?: InputMaybe<Scalars['JSON']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type TicketFilterTemplateHistoryRecord = {
    __typename?: 'TicketFilterTemplateHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketFilterTemplateHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketFilterTemplateHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the TicketFilterTemplateHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketFilterTemplateHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    employee?: Maybe<Scalars['String']['output']>
    fields?: Maybe<Scalars['JSON']['output']>
    history_action?: Maybe<TicketFilterTemplateHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type TicketFilterTemplateHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    employee?: InputMaybe<Scalars['String']['input']>
    fields?: InputMaybe<Scalars['JSON']['input']>
    history_action?: InputMaybe<TicketFilterTemplateHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum TicketFilterTemplateHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type TicketFilterTemplateHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    employee?: InputMaybe<Scalars['String']['input']>
    fields?: InputMaybe<Scalars['JSON']['input']>
    history_action?: InputMaybe<TicketFilterTemplateHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type TicketFilterTemplateHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<TicketFilterTemplateHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<TicketFilterTemplateHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    employee?: InputMaybe<Scalars['String']['input']>
    employee_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    employee_not?: InputMaybe<Scalars['String']['input']>
    employee_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    fields?: InputMaybe<Scalars['JSON']['input']>
    fields_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    fields_not?: InputMaybe<Scalars['JSON']['input']>
    fields_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    history_action?: InputMaybe<TicketFilterTemplateHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<TicketFilterTemplateHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<TicketFilterTemplateHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<TicketFilterTemplateHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type TicketFilterTemplateHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type TicketFilterTemplateHistoryRecordsCreateInput = {
    data?: InputMaybe<TicketFilterTemplateHistoryRecordCreateInput>
}

export type TicketFilterTemplateHistoryRecordsUpdateInput = {
    data?: InputMaybe<TicketFilterTemplateHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type TicketFilterTemplateUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    employee?: InputMaybe<OrganizationEmployeeRelateToOneInput>
    fields?: InputMaybe<Scalars['JSON']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type TicketFilterTemplateWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<TicketFilterTemplateWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<TicketFilterTemplateWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    employee?: InputMaybe<OrganizationEmployeeWhereInput>
    employee_is_null?: InputMaybe<Scalars['Boolean']['input']>
    fields?: InputMaybe<Scalars['JSON']['input']>
    fields_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    fields_not?: InputMaybe<Scalars['JSON']['input']>
    fields_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type TicketFilterTemplateWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type TicketFilterTemplatesCreateInput = {
    data?: InputMaybe<TicketFilterTemplateCreateInput>
}

export type TicketFilterTemplatesUpdateInput = {
    data?: InputMaybe<TicketFilterTemplateUpdateInput>
    id: Scalars['ID']['input']
}

export type TicketGroupedCounter = {
    __typename?: 'TicketGroupedCounter'
    assignee?: Maybe<Scalars['String']['output']>
    categoryClassifier?: Maybe<Scalars['String']['output']>
    count: Scalars['Int']['output']
    dayGroup: Scalars['String']['output']
    executor?: Maybe<Scalars['String']['output']>
    property?: Maybe<Scalars['String']['output']>
    qualityControlValue?: Maybe<Scalars['String']['output']>
    status?: Maybe<Scalars['String']['output']>
}

/**  A keystone list  */
export type TicketHistoryRecord = {
    __typename?: 'TicketHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the TicketHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    assignee?: Maybe<Scalars['String']['output']>
    canReadByResident?: Maybe<Scalars['Boolean']['output']>
    categoryClassifier?: Maybe<Scalars['String']['output']>
    classifier?: Maybe<Scalars['String']['output']>
    client?: Maybe<Scalars['String']['output']>
    clientEmail?: Maybe<Scalars['String']['output']>
    clientName?: Maybe<Scalars['String']['output']>
    clientPhone?: Maybe<Scalars['String']['output']>
    completedAt?: Maybe<Scalars['String']['output']>
    contact?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deadline?: Maybe<Scalars['String']['output']>
    deferredUntil?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    details?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    executor?: Maybe<Scalars['String']['output']>
    feedbackAdditionalOptions?: Maybe<Scalars['JSON']['output']>
    feedbackComment?: Maybe<Scalars['String']['output']>
    feedbackUpdatedAt?: Maybe<Scalars['String']['output']>
    feedbackValue?: Maybe<Scalars['String']['output']>
    floorName?: Maybe<Scalars['String']['output']>
    history_action?: Maybe<TicketHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    isAutoClassified?: Maybe<Scalars['Boolean']['output']>
    isCompletedAfterDeadline?: Maybe<Scalars['Boolean']['output']>
    isEmergency?: Maybe<Scalars['Boolean']['output']>
    isPaid?: Maybe<Scalars['Boolean']['output']>
    isPayable?: Maybe<Scalars['Boolean']['output']>
    isResidentTicket?: Maybe<Scalars['Boolean']['output']>
    isWarranty?: Maybe<Scalars['Boolean']['output']>
    lastCommentAt?: Maybe<Scalars['String']['output']>
    lastCommentWithResidentTypeAt?: Maybe<Scalars['String']['output']>
    lastResidentCommentAt?: Maybe<Scalars['String']['output']>
    meta?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    number?: Maybe<Scalars['JSON']['output']>
    order?: Maybe<Scalars['Int']['output']>
    organization?: Maybe<Scalars['String']['output']>
    placeClassifier?: Maybe<Scalars['String']['output']>
    problemClassifier?: Maybe<Scalars['String']['output']>
    property?: Maybe<Scalars['String']['output']>
    propertyAddress?: Maybe<Scalars['String']['output']>
    propertyAddressMeta?: Maybe<Scalars['JSON']['output']>
    qualityControlAdditionalOptions?: Maybe<Scalars['JSON']['output']>
    qualityControlComment?: Maybe<Scalars['String']['output']>
    qualityControlUpdatedAt?: Maybe<Scalars['String']['output']>
    qualityControlUpdatedBy?: Maybe<Scalars['String']['output']>
    qualityControlValue?: Maybe<Scalars['String']['output']>
    related?: Maybe<Scalars['String']['output']>
    reviewComment?: Maybe<Scalars['String']['output']>
    reviewValue?: Maybe<Scalars['String']['output']>
    sectionName?: Maybe<Scalars['String']['output']>
    sectionType?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    source?: Maybe<Scalars['String']['output']>
    sourceMeta?: Maybe<Scalars['JSON']['output']>
    status?: Maybe<Scalars['String']['output']>
    statusReason?: Maybe<Scalars['String']['output']>
    statusReopenedCounter?: Maybe<Scalars['Int']['output']>
    statusUpdatedAt?: Maybe<Scalars['String']['output']>
    unitName?: Maybe<Scalars['String']['output']>
    unitType?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type TicketHistoryRecordCreateInput = {
    assignee?: InputMaybe<Scalars['String']['input']>
    canReadByResident?: InputMaybe<Scalars['Boolean']['input']>
    categoryClassifier?: InputMaybe<Scalars['String']['input']>
    classifier?: InputMaybe<Scalars['String']['input']>
    client?: InputMaybe<Scalars['String']['input']>
    clientEmail?: InputMaybe<Scalars['String']['input']>
    clientName?: InputMaybe<Scalars['String']['input']>
    clientPhone?: InputMaybe<Scalars['String']['input']>
    completedAt?: InputMaybe<Scalars['String']['input']>
    contact?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deadline?: InputMaybe<Scalars['String']['input']>
    deferredUntil?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    details?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    executor?: InputMaybe<Scalars['String']['input']>
    feedbackAdditionalOptions?: InputMaybe<Scalars['JSON']['input']>
    feedbackComment?: InputMaybe<Scalars['String']['input']>
    feedbackUpdatedAt?: InputMaybe<Scalars['String']['input']>
    feedbackValue?: InputMaybe<Scalars['String']['input']>
    floorName?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<TicketHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    isAutoClassified?: InputMaybe<Scalars['Boolean']['input']>
    isCompletedAfterDeadline?: InputMaybe<Scalars['Boolean']['input']>
    isEmergency?: InputMaybe<Scalars['Boolean']['input']>
    isPaid?: InputMaybe<Scalars['Boolean']['input']>
    isPayable?: InputMaybe<Scalars['Boolean']['input']>
    isResidentTicket?: InputMaybe<Scalars['Boolean']['input']>
    isWarranty?: InputMaybe<Scalars['Boolean']['input']>
    lastCommentAt?: InputMaybe<Scalars['String']['input']>
    lastCommentWithResidentTypeAt?: InputMaybe<Scalars['String']['input']>
    lastResidentCommentAt?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    number?: InputMaybe<Scalars['JSON']['input']>
    order?: InputMaybe<Scalars['Int']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    placeClassifier?: InputMaybe<Scalars['String']['input']>
    problemClassifier?: InputMaybe<Scalars['String']['input']>
    property?: InputMaybe<Scalars['String']['input']>
    propertyAddress?: InputMaybe<Scalars['String']['input']>
    propertyAddressMeta?: InputMaybe<Scalars['JSON']['input']>
    qualityControlAdditionalOptions?: InputMaybe<Scalars['JSON']['input']>
    qualityControlComment?: InputMaybe<Scalars['String']['input']>
    qualityControlUpdatedAt?: InputMaybe<Scalars['String']['input']>
    qualityControlUpdatedBy?: InputMaybe<Scalars['String']['input']>
    qualityControlValue?: InputMaybe<Scalars['String']['input']>
    related?: InputMaybe<Scalars['String']['input']>
    reviewComment?: InputMaybe<Scalars['String']['input']>
    reviewValue?: InputMaybe<Scalars['String']['input']>
    sectionName?: InputMaybe<Scalars['String']['input']>
    sectionType?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    source?: InputMaybe<Scalars['String']['input']>
    sourceMeta?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    statusReason?: InputMaybe<Scalars['String']['input']>
    statusReopenedCounter?: InputMaybe<Scalars['Int']['input']>
    statusUpdatedAt?: InputMaybe<Scalars['String']['input']>
    unitName?: InputMaybe<Scalars['String']['input']>
    unitType?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum TicketHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type TicketHistoryRecordUpdateInput = {
    assignee?: InputMaybe<Scalars['String']['input']>
    canReadByResident?: InputMaybe<Scalars['Boolean']['input']>
    categoryClassifier?: InputMaybe<Scalars['String']['input']>
    classifier?: InputMaybe<Scalars['String']['input']>
    client?: InputMaybe<Scalars['String']['input']>
    clientEmail?: InputMaybe<Scalars['String']['input']>
    clientName?: InputMaybe<Scalars['String']['input']>
    clientPhone?: InputMaybe<Scalars['String']['input']>
    completedAt?: InputMaybe<Scalars['String']['input']>
    contact?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deadline?: InputMaybe<Scalars['String']['input']>
    deferredUntil?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    details?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    executor?: InputMaybe<Scalars['String']['input']>
    feedbackAdditionalOptions?: InputMaybe<Scalars['JSON']['input']>
    feedbackComment?: InputMaybe<Scalars['String']['input']>
    feedbackUpdatedAt?: InputMaybe<Scalars['String']['input']>
    feedbackValue?: InputMaybe<Scalars['String']['input']>
    floorName?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<TicketHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    isAutoClassified?: InputMaybe<Scalars['Boolean']['input']>
    isCompletedAfterDeadline?: InputMaybe<Scalars['Boolean']['input']>
    isEmergency?: InputMaybe<Scalars['Boolean']['input']>
    isPaid?: InputMaybe<Scalars['Boolean']['input']>
    isPayable?: InputMaybe<Scalars['Boolean']['input']>
    isResidentTicket?: InputMaybe<Scalars['Boolean']['input']>
    isWarranty?: InputMaybe<Scalars['Boolean']['input']>
    lastCommentAt?: InputMaybe<Scalars['String']['input']>
    lastCommentWithResidentTypeAt?: InputMaybe<Scalars['String']['input']>
    lastResidentCommentAt?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    number?: InputMaybe<Scalars['JSON']['input']>
    order?: InputMaybe<Scalars['Int']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    placeClassifier?: InputMaybe<Scalars['String']['input']>
    problemClassifier?: InputMaybe<Scalars['String']['input']>
    property?: InputMaybe<Scalars['String']['input']>
    propertyAddress?: InputMaybe<Scalars['String']['input']>
    propertyAddressMeta?: InputMaybe<Scalars['JSON']['input']>
    qualityControlAdditionalOptions?: InputMaybe<Scalars['JSON']['input']>
    qualityControlComment?: InputMaybe<Scalars['String']['input']>
    qualityControlUpdatedAt?: InputMaybe<Scalars['String']['input']>
    qualityControlUpdatedBy?: InputMaybe<Scalars['String']['input']>
    qualityControlValue?: InputMaybe<Scalars['String']['input']>
    related?: InputMaybe<Scalars['String']['input']>
    reviewComment?: InputMaybe<Scalars['String']['input']>
    reviewValue?: InputMaybe<Scalars['String']['input']>
    sectionName?: InputMaybe<Scalars['String']['input']>
    sectionType?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    source?: InputMaybe<Scalars['String']['input']>
    sourceMeta?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    statusReason?: InputMaybe<Scalars['String']['input']>
    statusReopenedCounter?: InputMaybe<Scalars['Int']['input']>
    statusUpdatedAt?: InputMaybe<Scalars['String']['input']>
    unitName?: InputMaybe<Scalars['String']['input']>
    unitType?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type TicketHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<TicketHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<TicketHistoryRecordWhereInput>>>
    assignee?: InputMaybe<Scalars['String']['input']>
    assignee_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    assignee_not?: InputMaybe<Scalars['String']['input']>
    assignee_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    canReadByResident?: InputMaybe<Scalars['Boolean']['input']>
    canReadByResident_not?: InputMaybe<Scalars['Boolean']['input']>
    categoryClassifier?: InputMaybe<Scalars['String']['input']>
    categoryClassifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    categoryClassifier_not?: InputMaybe<Scalars['String']['input']>
    categoryClassifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    classifier?: InputMaybe<Scalars['String']['input']>
    classifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    classifier_not?: InputMaybe<Scalars['String']['input']>
    classifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    client?: InputMaybe<Scalars['String']['input']>
    clientEmail?: InputMaybe<Scalars['String']['input']>
    clientEmail_contains?: InputMaybe<Scalars['String']['input']>
    clientEmail_contains_i?: InputMaybe<Scalars['String']['input']>
    clientEmail_ends_with?: InputMaybe<Scalars['String']['input']>
    clientEmail_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientEmail_i?: InputMaybe<Scalars['String']['input']>
    clientEmail_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientEmail_not?: InputMaybe<Scalars['String']['input']>
    clientEmail_not_contains?: InputMaybe<Scalars['String']['input']>
    clientEmail_not_contains_i?: InputMaybe<Scalars['String']['input']>
    clientEmail_not_ends_with?: InputMaybe<Scalars['String']['input']>
    clientEmail_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientEmail_not_i?: InputMaybe<Scalars['String']['input']>
    clientEmail_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientEmail_not_starts_with?: InputMaybe<Scalars['String']['input']>
    clientEmail_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    clientEmail_starts_with?: InputMaybe<Scalars['String']['input']>
    clientEmail_starts_with_i?: InputMaybe<Scalars['String']['input']>
    clientName?: InputMaybe<Scalars['String']['input']>
    clientName_contains?: InputMaybe<Scalars['String']['input']>
    clientName_contains_i?: InputMaybe<Scalars['String']['input']>
    clientName_ends_with?: InputMaybe<Scalars['String']['input']>
    clientName_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientName_i?: InputMaybe<Scalars['String']['input']>
    clientName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientName_not?: InputMaybe<Scalars['String']['input']>
    clientName_not_contains?: InputMaybe<Scalars['String']['input']>
    clientName_not_contains_i?: InputMaybe<Scalars['String']['input']>
    clientName_not_ends_with?: InputMaybe<Scalars['String']['input']>
    clientName_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientName_not_i?: InputMaybe<Scalars['String']['input']>
    clientName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientName_not_starts_with?: InputMaybe<Scalars['String']['input']>
    clientName_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    clientName_starts_with?: InputMaybe<Scalars['String']['input']>
    clientName_starts_with_i?: InputMaybe<Scalars['String']['input']>
    clientPhone?: InputMaybe<Scalars['String']['input']>
    clientPhone_contains?: InputMaybe<Scalars['String']['input']>
    clientPhone_contains_i?: InputMaybe<Scalars['String']['input']>
    clientPhone_ends_with?: InputMaybe<Scalars['String']['input']>
    clientPhone_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientPhone_i?: InputMaybe<Scalars['String']['input']>
    clientPhone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientPhone_not?: InputMaybe<Scalars['String']['input']>
    clientPhone_not_contains?: InputMaybe<Scalars['String']['input']>
    clientPhone_not_contains_i?: InputMaybe<Scalars['String']['input']>
    clientPhone_not_ends_with?: InputMaybe<Scalars['String']['input']>
    clientPhone_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientPhone_not_i?: InputMaybe<Scalars['String']['input']>
    clientPhone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientPhone_not_starts_with?: InputMaybe<Scalars['String']['input']>
    clientPhone_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    clientPhone_starts_with?: InputMaybe<Scalars['String']['input']>
    clientPhone_starts_with_i?: InputMaybe<Scalars['String']['input']>
    client_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    client_not?: InputMaybe<Scalars['String']['input']>
    client_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    completedAt?: InputMaybe<Scalars['String']['input']>
    completedAt_gt?: InputMaybe<Scalars['String']['input']>
    completedAt_gte?: InputMaybe<Scalars['String']['input']>
    completedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    completedAt_lt?: InputMaybe<Scalars['String']['input']>
    completedAt_lte?: InputMaybe<Scalars['String']['input']>
    completedAt_not?: InputMaybe<Scalars['String']['input']>
    completedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    contact?: InputMaybe<Scalars['String']['input']>
    contact_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    contact_not?: InputMaybe<Scalars['String']['input']>
    contact_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deadline?: InputMaybe<Scalars['String']['input']>
    deadline_gt?: InputMaybe<Scalars['String']['input']>
    deadline_gte?: InputMaybe<Scalars['String']['input']>
    deadline_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deadline_lt?: InputMaybe<Scalars['String']['input']>
    deadline_lte?: InputMaybe<Scalars['String']['input']>
    deadline_not?: InputMaybe<Scalars['String']['input']>
    deadline_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deferredUntil?: InputMaybe<Scalars['String']['input']>
    deferredUntil_gt?: InputMaybe<Scalars['String']['input']>
    deferredUntil_gte?: InputMaybe<Scalars['String']['input']>
    deferredUntil_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deferredUntil_lt?: InputMaybe<Scalars['String']['input']>
    deferredUntil_lte?: InputMaybe<Scalars['String']['input']>
    deferredUntil_not?: InputMaybe<Scalars['String']['input']>
    deferredUntil_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    details?: InputMaybe<Scalars['String']['input']>
    details_contains?: InputMaybe<Scalars['String']['input']>
    details_contains_i?: InputMaybe<Scalars['String']['input']>
    details_ends_with?: InputMaybe<Scalars['String']['input']>
    details_ends_with_i?: InputMaybe<Scalars['String']['input']>
    details_i?: InputMaybe<Scalars['String']['input']>
    details_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    details_not?: InputMaybe<Scalars['String']['input']>
    details_not_contains?: InputMaybe<Scalars['String']['input']>
    details_not_contains_i?: InputMaybe<Scalars['String']['input']>
    details_not_ends_with?: InputMaybe<Scalars['String']['input']>
    details_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    details_not_i?: InputMaybe<Scalars['String']['input']>
    details_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    details_not_starts_with?: InputMaybe<Scalars['String']['input']>
    details_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    details_starts_with?: InputMaybe<Scalars['String']['input']>
    details_starts_with_i?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    executor?: InputMaybe<Scalars['String']['input']>
    executor_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    executor_not?: InputMaybe<Scalars['String']['input']>
    executor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    feedbackAdditionalOptions?: InputMaybe<Scalars['JSON']['input']>
    feedbackAdditionalOptions_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    feedbackAdditionalOptions_not?: InputMaybe<Scalars['JSON']['input']>
    feedbackAdditionalOptions_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    feedbackComment?: InputMaybe<Scalars['String']['input']>
    feedbackComment_contains?: InputMaybe<Scalars['String']['input']>
    feedbackComment_contains_i?: InputMaybe<Scalars['String']['input']>
    feedbackComment_ends_with?: InputMaybe<Scalars['String']['input']>
    feedbackComment_ends_with_i?: InputMaybe<Scalars['String']['input']>
    feedbackComment_i?: InputMaybe<Scalars['String']['input']>
    feedbackComment_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    feedbackComment_not?: InputMaybe<Scalars['String']['input']>
    feedbackComment_not_contains?: InputMaybe<Scalars['String']['input']>
    feedbackComment_not_contains_i?: InputMaybe<Scalars['String']['input']>
    feedbackComment_not_ends_with?: InputMaybe<Scalars['String']['input']>
    feedbackComment_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    feedbackComment_not_i?: InputMaybe<Scalars['String']['input']>
    feedbackComment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    feedbackComment_not_starts_with?: InputMaybe<Scalars['String']['input']>
    feedbackComment_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    feedbackComment_starts_with?: InputMaybe<Scalars['String']['input']>
    feedbackComment_starts_with_i?: InputMaybe<Scalars['String']['input']>
    feedbackUpdatedAt?: InputMaybe<Scalars['String']['input']>
    feedbackUpdatedAt_gt?: InputMaybe<Scalars['String']['input']>
    feedbackUpdatedAt_gte?: InputMaybe<Scalars['String']['input']>
    feedbackUpdatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    feedbackUpdatedAt_lt?: InputMaybe<Scalars['String']['input']>
    feedbackUpdatedAt_lte?: InputMaybe<Scalars['String']['input']>
    feedbackUpdatedAt_not?: InputMaybe<Scalars['String']['input']>
    feedbackUpdatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    feedbackValue?: InputMaybe<Scalars['String']['input']>
    feedbackValue_contains?: InputMaybe<Scalars['String']['input']>
    feedbackValue_contains_i?: InputMaybe<Scalars['String']['input']>
    feedbackValue_ends_with?: InputMaybe<Scalars['String']['input']>
    feedbackValue_ends_with_i?: InputMaybe<Scalars['String']['input']>
    feedbackValue_i?: InputMaybe<Scalars['String']['input']>
    feedbackValue_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    feedbackValue_not?: InputMaybe<Scalars['String']['input']>
    feedbackValue_not_contains?: InputMaybe<Scalars['String']['input']>
    feedbackValue_not_contains_i?: InputMaybe<Scalars['String']['input']>
    feedbackValue_not_ends_with?: InputMaybe<Scalars['String']['input']>
    feedbackValue_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    feedbackValue_not_i?: InputMaybe<Scalars['String']['input']>
    feedbackValue_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    feedbackValue_not_starts_with?: InputMaybe<Scalars['String']['input']>
    feedbackValue_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    feedbackValue_starts_with?: InputMaybe<Scalars['String']['input']>
    feedbackValue_starts_with_i?: InputMaybe<Scalars['String']['input']>
    floorName?: InputMaybe<Scalars['String']['input']>
    floorName_contains?: InputMaybe<Scalars['String']['input']>
    floorName_contains_i?: InputMaybe<Scalars['String']['input']>
    floorName_ends_with?: InputMaybe<Scalars['String']['input']>
    floorName_ends_with_i?: InputMaybe<Scalars['String']['input']>
    floorName_i?: InputMaybe<Scalars['String']['input']>
    floorName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    floorName_not?: InputMaybe<Scalars['String']['input']>
    floorName_not_contains?: InputMaybe<Scalars['String']['input']>
    floorName_not_contains_i?: InputMaybe<Scalars['String']['input']>
    floorName_not_ends_with?: InputMaybe<Scalars['String']['input']>
    floorName_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    floorName_not_i?: InputMaybe<Scalars['String']['input']>
    floorName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    floorName_not_starts_with?: InputMaybe<Scalars['String']['input']>
    floorName_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    floorName_starts_with?: InputMaybe<Scalars['String']['input']>
    floorName_starts_with_i?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<TicketHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<TicketHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<TicketHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<TicketHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    isAutoClassified?: InputMaybe<Scalars['Boolean']['input']>
    isAutoClassified_not?: InputMaybe<Scalars['Boolean']['input']>
    isCompletedAfterDeadline?: InputMaybe<Scalars['Boolean']['input']>
    isCompletedAfterDeadline_not?: InputMaybe<Scalars['Boolean']['input']>
    isEmergency?: InputMaybe<Scalars['Boolean']['input']>
    isEmergency_not?: InputMaybe<Scalars['Boolean']['input']>
    isPaid?: InputMaybe<Scalars['Boolean']['input']>
    isPaid_not?: InputMaybe<Scalars['Boolean']['input']>
    isPayable?: InputMaybe<Scalars['Boolean']['input']>
    isPayable_not?: InputMaybe<Scalars['Boolean']['input']>
    isResidentTicket?: InputMaybe<Scalars['Boolean']['input']>
    isResidentTicket_not?: InputMaybe<Scalars['Boolean']['input']>
    isWarranty?: InputMaybe<Scalars['Boolean']['input']>
    isWarranty_not?: InputMaybe<Scalars['Boolean']['input']>
    lastCommentAt?: InputMaybe<Scalars['String']['input']>
    lastCommentAt_gt?: InputMaybe<Scalars['String']['input']>
    lastCommentAt_gte?: InputMaybe<Scalars['String']['input']>
    lastCommentAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    lastCommentAt_lt?: InputMaybe<Scalars['String']['input']>
    lastCommentAt_lte?: InputMaybe<Scalars['String']['input']>
    lastCommentAt_not?: InputMaybe<Scalars['String']['input']>
    lastCommentAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    lastCommentWithResidentTypeAt?: InputMaybe<Scalars['String']['input']>
    lastCommentWithResidentTypeAt_gt?: InputMaybe<Scalars['String']['input']>
    lastCommentWithResidentTypeAt_gte?: InputMaybe<Scalars['String']['input']>
    lastCommentWithResidentTypeAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    lastCommentWithResidentTypeAt_lt?: InputMaybe<Scalars['String']['input']>
    lastCommentWithResidentTypeAt_lte?: InputMaybe<Scalars['String']['input']>
    lastCommentWithResidentTypeAt_not?: InputMaybe<Scalars['String']['input']>
    lastCommentWithResidentTypeAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    lastResidentCommentAt?: InputMaybe<Scalars['String']['input']>
    lastResidentCommentAt_gt?: InputMaybe<Scalars['String']['input']>
    lastResidentCommentAt_gte?: InputMaybe<Scalars['String']['input']>
    lastResidentCommentAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    lastResidentCommentAt_lt?: InputMaybe<Scalars['String']['input']>
    lastResidentCommentAt_lte?: InputMaybe<Scalars['String']['input']>
    lastResidentCommentAt_not?: InputMaybe<Scalars['String']['input']>
    lastResidentCommentAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    number?: InputMaybe<Scalars['JSON']['input']>
    number_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    number_not?: InputMaybe<Scalars['JSON']['input']>
    number_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    order?: InputMaybe<Scalars['Int']['input']>
    order_gt?: InputMaybe<Scalars['Int']['input']>
    order_gte?: InputMaybe<Scalars['Int']['input']>
    order_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    order_lt?: InputMaybe<Scalars['Int']['input']>
    order_lte?: InputMaybe<Scalars['Int']['input']>
    order_not?: InputMaybe<Scalars['Int']['input']>
    order_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    placeClassifier?: InputMaybe<Scalars['String']['input']>
    placeClassifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    placeClassifier_not?: InputMaybe<Scalars['String']['input']>
    placeClassifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    problemClassifier?: InputMaybe<Scalars['String']['input']>
    problemClassifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    problemClassifier_not?: InputMaybe<Scalars['String']['input']>
    problemClassifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    property?: InputMaybe<Scalars['String']['input']>
    propertyAddress?: InputMaybe<Scalars['String']['input']>
    propertyAddressMeta?: InputMaybe<Scalars['JSON']['input']>
    propertyAddressMeta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    propertyAddressMeta_not?: InputMaybe<Scalars['JSON']['input']>
    propertyAddressMeta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    propertyAddress_contains?: InputMaybe<Scalars['String']['input']>
    propertyAddress_contains_i?: InputMaybe<Scalars['String']['input']>
    propertyAddress_ends_with?: InputMaybe<Scalars['String']['input']>
    propertyAddress_ends_with_i?: InputMaybe<Scalars['String']['input']>
    propertyAddress_i?: InputMaybe<Scalars['String']['input']>
    propertyAddress_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    propertyAddress_not?: InputMaybe<Scalars['String']['input']>
    propertyAddress_not_contains?: InputMaybe<Scalars['String']['input']>
    propertyAddress_not_contains_i?: InputMaybe<Scalars['String']['input']>
    propertyAddress_not_ends_with?: InputMaybe<Scalars['String']['input']>
    propertyAddress_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    propertyAddress_not_i?: InputMaybe<Scalars['String']['input']>
    propertyAddress_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    propertyAddress_not_starts_with?: InputMaybe<Scalars['String']['input']>
    propertyAddress_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    propertyAddress_starts_with?: InputMaybe<Scalars['String']['input']>
    propertyAddress_starts_with_i?: InputMaybe<Scalars['String']['input']>
    property_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    property_not?: InputMaybe<Scalars['String']['input']>
    property_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    qualityControlAdditionalOptions?: InputMaybe<Scalars['JSON']['input']>
    qualityControlAdditionalOptions_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    qualityControlAdditionalOptions_not?: InputMaybe<Scalars['JSON']['input']>
    qualityControlAdditionalOptions_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    qualityControlComment?: InputMaybe<Scalars['String']['input']>
    qualityControlComment_contains?: InputMaybe<Scalars['String']['input']>
    qualityControlComment_contains_i?: InputMaybe<Scalars['String']['input']>
    qualityControlComment_ends_with?: InputMaybe<Scalars['String']['input']>
    qualityControlComment_ends_with_i?: InputMaybe<Scalars['String']['input']>
    qualityControlComment_i?: InputMaybe<Scalars['String']['input']>
    qualityControlComment_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    qualityControlComment_not?: InputMaybe<Scalars['String']['input']>
    qualityControlComment_not_contains?: InputMaybe<Scalars['String']['input']>
    qualityControlComment_not_contains_i?: InputMaybe<Scalars['String']['input']>
    qualityControlComment_not_ends_with?: InputMaybe<Scalars['String']['input']>
    qualityControlComment_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    qualityControlComment_not_i?: InputMaybe<Scalars['String']['input']>
    qualityControlComment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    qualityControlComment_not_starts_with?: InputMaybe<Scalars['String']['input']>
    qualityControlComment_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    qualityControlComment_starts_with?: InputMaybe<Scalars['String']['input']>
    qualityControlComment_starts_with_i?: InputMaybe<Scalars['String']['input']>
    qualityControlUpdatedAt?: InputMaybe<Scalars['String']['input']>
    qualityControlUpdatedAt_gt?: InputMaybe<Scalars['String']['input']>
    qualityControlUpdatedAt_gte?: InputMaybe<Scalars['String']['input']>
    qualityControlUpdatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    qualityControlUpdatedAt_lt?: InputMaybe<Scalars['String']['input']>
    qualityControlUpdatedAt_lte?: InputMaybe<Scalars['String']['input']>
    qualityControlUpdatedAt_not?: InputMaybe<Scalars['String']['input']>
    qualityControlUpdatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    qualityControlUpdatedBy?: InputMaybe<Scalars['String']['input']>
    qualityControlUpdatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    qualityControlUpdatedBy_not?: InputMaybe<Scalars['String']['input']>
    qualityControlUpdatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    qualityControlValue?: InputMaybe<Scalars['String']['input']>
    qualityControlValue_contains?: InputMaybe<Scalars['String']['input']>
    qualityControlValue_contains_i?: InputMaybe<Scalars['String']['input']>
    qualityControlValue_ends_with?: InputMaybe<Scalars['String']['input']>
    qualityControlValue_ends_with_i?: InputMaybe<Scalars['String']['input']>
    qualityControlValue_i?: InputMaybe<Scalars['String']['input']>
    qualityControlValue_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    qualityControlValue_not?: InputMaybe<Scalars['String']['input']>
    qualityControlValue_not_contains?: InputMaybe<Scalars['String']['input']>
    qualityControlValue_not_contains_i?: InputMaybe<Scalars['String']['input']>
    qualityControlValue_not_ends_with?: InputMaybe<Scalars['String']['input']>
    qualityControlValue_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    qualityControlValue_not_i?: InputMaybe<Scalars['String']['input']>
    qualityControlValue_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    qualityControlValue_not_starts_with?: InputMaybe<Scalars['String']['input']>
    qualityControlValue_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    qualityControlValue_starts_with?: InputMaybe<Scalars['String']['input']>
    qualityControlValue_starts_with_i?: InputMaybe<Scalars['String']['input']>
    related?: InputMaybe<Scalars['String']['input']>
    related_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    related_not?: InputMaybe<Scalars['String']['input']>
    related_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    reviewComment?: InputMaybe<Scalars['String']['input']>
    reviewComment_contains?: InputMaybe<Scalars['String']['input']>
    reviewComment_contains_i?: InputMaybe<Scalars['String']['input']>
    reviewComment_ends_with?: InputMaybe<Scalars['String']['input']>
    reviewComment_ends_with_i?: InputMaybe<Scalars['String']['input']>
    reviewComment_i?: InputMaybe<Scalars['String']['input']>
    reviewComment_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    reviewComment_not?: InputMaybe<Scalars['String']['input']>
    reviewComment_not_contains?: InputMaybe<Scalars['String']['input']>
    reviewComment_not_contains_i?: InputMaybe<Scalars['String']['input']>
    reviewComment_not_ends_with?: InputMaybe<Scalars['String']['input']>
    reviewComment_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    reviewComment_not_i?: InputMaybe<Scalars['String']['input']>
    reviewComment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    reviewComment_not_starts_with?: InputMaybe<Scalars['String']['input']>
    reviewComment_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    reviewComment_starts_with?: InputMaybe<Scalars['String']['input']>
    reviewComment_starts_with_i?: InputMaybe<Scalars['String']['input']>
    reviewValue?: InputMaybe<Scalars['String']['input']>
    reviewValue_contains?: InputMaybe<Scalars['String']['input']>
    reviewValue_contains_i?: InputMaybe<Scalars['String']['input']>
    reviewValue_ends_with?: InputMaybe<Scalars['String']['input']>
    reviewValue_ends_with_i?: InputMaybe<Scalars['String']['input']>
    reviewValue_i?: InputMaybe<Scalars['String']['input']>
    reviewValue_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    reviewValue_not?: InputMaybe<Scalars['String']['input']>
    reviewValue_not_contains?: InputMaybe<Scalars['String']['input']>
    reviewValue_not_contains_i?: InputMaybe<Scalars['String']['input']>
    reviewValue_not_ends_with?: InputMaybe<Scalars['String']['input']>
    reviewValue_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    reviewValue_not_i?: InputMaybe<Scalars['String']['input']>
    reviewValue_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    reviewValue_not_starts_with?: InputMaybe<Scalars['String']['input']>
    reviewValue_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    reviewValue_starts_with?: InputMaybe<Scalars['String']['input']>
    reviewValue_starts_with_i?: InputMaybe<Scalars['String']['input']>
    sectionName?: InputMaybe<Scalars['String']['input']>
    sectionName_contains?: InputMaybe<Scalars['String']['input']>
    sectionName_contains_i?: InputMaybe<Scalars['String']['input']>
    sectionName_ends_with?: InputMaybe<Scalars['String']['input']>
    sectionName_ends_with_i?: InputMaybe<Scalars['String']['input']>
    sectionName_i?: InputMaybe<Scalars['String']['input']>
    sectionName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sectionName_not?: InputMaybe<Scalars['String']['input']>
    sectionName_not_contains?: InputMaybe<Scalars['String']['input']>
    sectionName_not_contains_i?: InputMaybe<Scalars['String']['input']>
    sectionName_not_ends_with?: InputMaybe<Scalars['String']['input']>
    sectionName_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    sectionName_not_i?: InputMaybe<Scalars['String']['input']>
    sectionName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sectionName_not_starts_with?: InputMaybe<Scalars['String']['input']>
    sectionName_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    sectionName_starts_with?: InputMaybe<Scalars['String']['input']>
    sectionName_starts_with_i?: InputMaybe<Scalars['String']['input']>
    sectionType?: InputMaybe<Scalars['String']['input']>
    sectionType_contains?: InputMaybe<Scalars['String']['input']>
    sectionType_contains_i?: InputMaybe<Scalars['String']['input']>
    sectionType_ends_with?: InputMaybe<Scalars['String']['input']>
    sectionType_ends_with_i?: InputMaybe<Scalars['String']['input']>
    sectionType_i?: InputMaybe<Scalars['String']['input']>
    sectionType_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sectionType_not?: InputMaybe<Scalars['String']['input']>
    sectionType_not_contains?: InputMaybe<Scalars['String']['input']>
    sectionType_not_contains_i?: InputMaybe<Scalars['String']['input']>
    sectionType_not_ends_with?: InputMaybe<Scalars['String']['input']>
    sectionType_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    sectionType_not_i?: InputMaybe<Scalars['String']['input']>
    sectionType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sectionType_not_starts_with?: InputMaybe<Scalars['String']['input']>
    sectionType_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    sectionType_starts_with?: InputMaybe<Scalars['String']['input']>
    sectionType_starts_with_i?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    source?: InputMaybe<Scalars['String']['input']>
    sourceMeta?: InputMaybe<Scalars['JSON']['input']>
    sourceMeta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sourceMeta_not?: InputMaybe<Scalars['JSON']['input']>
    sourceMeta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    source_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    source_not?: InputMaybe<Scalars['String']['input']>
    source_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status?: InputMaybe<Scalars['String']['input']>
    statusReason?: InputMaybe<Scalars['String']['input']>
    statusReason_contains?: InputMaybe<Scalars['String']['input']>
    statusReason_contains_i?: InputMaybe<Scalars['String']['input']>
    statusReason_ends_with?: InputMaybe<Scalars['String']['input']>
    statusReason_ends_with_i?: InputMaybe<Scalars['String']['input']>
    statusReason_i?: InputMaybe<Scalars['String']['input']>
    statusReason_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    statusReason_not?: InputMaybe<Scalars['String']['input']>
    statusReason_not_contains?: InputMaybe<Scalars['String']['input']>
    statusReason_not_contains_i?: InputMaybe<Scalars['String']['input']>
    statusReason_not_ends_with?: InputMaybe<Scalars['String']['input']>
    statusReason_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    statusReason_not_i?: InputMaybe<Scalars['String']['input']>
    statusReason_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    statusReason_not_starts_with?: InputMaybe<Scalars['String']['input']>
    statusReason_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    statusReason_starts_with?: InputMaybe<Scalars['String']['input']>
    statusReason_starts_with_i?: InputMaybe<Scalars['String']['input']>
    statusReopenedCounter?: InputMaybe<Scalars['Int']['input']>
    statusReopenedCounter_gt?: InputMaybe<Scalars['Int']['input']>
    statusReopenedCounter_gte?: InputMaybe<Scalars['Int']['input']>
    statusReopenedCounter_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    statusReopenedCounter_lt?: InputMaybe<Scalars['Int']['input']>
    statusReopenedCounter_lte?: InputMaybe<Scalars['Int']['input']>
    statusReopenedCounter_not?: InputMaybe<Scalars['Int']['input']>
    statusReopenedCounter_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    statusUpdatedAt?: InputMaybe<Scalars['String']['input']>
    statusUpdatedAt_gt?: InputMaybe<Scalars['String']['input']>
    statusUpdatedAt_gte?: InputMaybe<Scalars['String']['input']>
    statusUpdatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    statusUpdatedAt_lt?: InputMaybe<Scalars['String']['input']>
    statusUpdatedAt_lte?: InputMaybe<Scalars['String']['input']>
    statusUpdatedAt_not?: InputMaybe<Scalars['String']['input']>
    statusUpdatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status_not?: InputMaybe<Scalars['String']['input']>
    status_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitName?: InputMaybe<Scalars['String']['input']>
    unitName_contains?: InputMaybe<Scalars['String']['input']>
    unitName_contains_i?: InputMaybe<Scalars['String']['input']>
    unitName_ends_with?: InputMaybe<Scalars['String']['input']>
    unitName_ends_with_i?: InputMaybe<Scalars['String']['input']>
    unitName_i?: InputMaybe<Scalars['String']['input']>
    unitName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitName_not?: InputMaybe<Scalars['String']['input']>
    unitName_not_contains?: InputMaybe<Scalars['String']['input']>
    unitName_not_contains_i?: InputMaybe<Scalars['String']['input']>
    unitName_not_ends_with?: InputMaybe<Scalars['String']['input']>
    unitName_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    unitName_not_i?: InputMaybe<Scalars['String']['input']>
    unitName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitName_not_starts_with?: InputMaybe<Scalars['String']['input']>
    unitName_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    unitName_starts_with?: InputMaybe<Scalars['String']['input']>
    unitName_starts_with_i?: InputMaybe<Scalars['String']['input']>
    unitType?: InputMaybe<Scalars['String']['input']>
    unitType_contains?: InputMaybe<Scalars['String']['input']>
    unitType_contains_i?: InputMaybe<Scalars['String']['input']>
    unitType_ends_with?: InputMaybe<Scalars['String']['input']>
    unitType_ends_with_i?: InputMaybe<Scalars['String']['input']>
    unitType_i?: InputMaybe<Scalars['String']['input']>
    unitType_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitType_not?: InputMaybe<Scalars['String']['input']>
    unitType_not_contains?: InputMaybe<Scalars['String']['input']>
    unitType_not_contains_i?: InputMaybe<Scalars['String']['input']>
    unitType_not_ends_with?: InputMaybe<Scalars['String']['input']>
    unitType_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    unitType_not_i?: InputMaybe<Scalars['String']['input']>
    unitType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitType_not_starts_with?: InputMaybe<Scalars['String']['input']>
    unitType_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    unitType_starts_with?: InputMaybe<Scalars['String']['input']>
    unitType_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type TicketHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type TicketHistoryRecordsCreateInput = {
    data?: InputMaybe<TicketHistoryRecordCreateInput>
}

export type TicketHistoryRecordsUpdateInput = {
    data?: InputMaybe<TicketHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type TicketLabel = {
    __typename?: 'TicketLabel'
    color: Scalars['String']['output']
    label: Scalars['String']['output']
    value: Scalars['String']['output']
}

export type TicketMultipleUpdateInput = {
    data: Array<InputMaybe<TicketUpdateInput>>
    dv: Scalars['Int']['input']
    id: Scalars['ID']['input']
    sender: SenderFieldInput
}

/**  Ticket settings rules for each organization. (Setting the "deadline" fields for each ticket type)  */
export type TicketOrganizationSetting = {
    __typename?: 'TicketOrganizationSetting'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketOrganizationSetting List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketOrganizationSetting List config, or
   *  3. As an alias to a 'name' field on the TicketOrganizationSetting List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketOrganizationSetting List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    /**  Default deadline duration for default tickets (ISO 8601 format)  */
    defaultDeadlineDuration?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  Default deadline duration for emergency tickets (ISO 8601 format)  */
    emergencyDeadlineDuration?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['String']['output']>
    /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
    organization?: Maybe<Organization>
    /**  Default deadline duration for payable tickets (ISO 8601 format)  */
    paidDeadlineDuration?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
    /**  Default deadline duration for warranty tickets (ISO 8601 format)  */
    warrantyDeadlineDuration?: Maybe<Scalars['String']['output']>
}

export type TicketOrganizationSettingCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    defaultDeadlineDuration?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    emergencyDeadlineDuration?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    paidDeadlineDuration?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
    warrantyDeadlineDuration?: InputMaybe<Scalars['String']['input']>
}

/**  A keystone list  */
export type TicketOrganizationSettingHistoryRecord = {
    __typename?: 'TicketOrganizationSettingHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketOrganizationSettingHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketOrganizationSettingHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the TicketOrganizationSettingHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketOrganizationSettingHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    defaultDeadlineDuration?: Maybe<Scalars['JSON']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    emergencyDeadlineDuration?: Maybe<Scalars['JSON']['output']>
    history_action?: Maybe<TicketOrganizationSettingHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    paidDeadlineDuration?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
    warrantyDeadlineDuration?: Maybe<Scalars['JSON']['output']>
}

export type TicketOrganizationSettingHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    defaultDeadlineDuration?: InputMaybe<Scalars['JSON']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    emergencyDeadlineDuration?: InputMaybe<Scalars['JSON']['input']>
    history_action?: InputMaybe<TicketOrganizationSettingHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    paidDeadlineDuration?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    warrantyDeadlineDuration?: InputMaybe<Scalars['JSON']['input']>
}

export enum TicketOrganizationSettingHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type TicketOrganizationSettingHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    defaultDeadlineDuration?: InputMaybe<Scalars['JSON']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    emergencyDeadlineDuration?: InputMaybe<Scalars['JSON']['input']>
    history_action?: InputMaybe<TicketOrganizationSettingHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    paidDeadlineDuration?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    warrantyDeadlineDuration?: InputMaybe<Scalars['JSON']['input']>
}

export type TicketOrganizationSettingHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<TicketOrganizationSettingHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<TicketOrganizationSettingHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    defaultDeadlineDuration?: InputMaybe<Scalars['JSON']['input']>
    defaultDeadlineDuration_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    defaultDeadlineDuration_not?: InputMaybe<Scalars['JSON']['input']>
    defaultDeadlineDuration_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    emergencyDeadlineDuration?: InputMaybe<Scalars['JSON']['input']>
    emergencyDeadlineDuration_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    emergencyDeadlineDuration_not?: InputMaybe<Scalars['JSON']['input']>
    emergencyDeadlineDuration_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    history_action?: InputMaybe<TicketOrganizationSettingHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<TicketOrganizationSettingHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<TicketOrganizationSettingHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<TicketOrganizationSettingHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    paidDeadlineDuration?: InputMaybe<Scalars['JSON']['input']>
    paidDeadlineDuration_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    paidDeadlineDuration_not?: InputMaybe<Scalars['JSON']['input']>
    paidDeadlineDuration_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    warrantyDeadlineDuration?: InputMaybe<Scalars['JSON']['input']>
    warrantyDeadlineDuration_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    warrantyDeadlineDuration_not?: InputMaybe<Scalars['JSON']['input']>
    warrantyDeadlineDuration_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
}

export type TicketOrganizationSettingHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type TicketOrganizationSettingHistoryRecordsCreateInput = {
    data?: InputMaybe<TicketOrganizationSettingHistoryRecordCreateInput>
}

export type TicketOrganizationSettingHistoryRecordsUpdateInput = {
    data?: InputMaybe<TicketOrganizationSettingHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type TicketOrganizationSettingUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    defaultDeadlineDuration?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    emergencyDeadlineDuration?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    paidDeadlineDuration?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
    warrantyDeadlineDuration?: InputMaybe<Scalars['String']['input']>
}

export type TicketOrganizationSettingWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<TicketOrganizationSettingWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<TicketOrganizationSettingWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    defaultDeadlineDuration?: InputMaybe<Scalars['String']['input']>
    defaultDeadlineDuration_contains?: InputMaybe<Scalars['String']['input']>
    defaultDeadlineDuration_contains_i?: InputMaybe<Scalars['String']['input']>
    defaultDeadlineDuration_ends_with?: InputMaybe<Scalars['String']['input']>
    defaultDeadlineDuration_ends_with_i?: InputMaybe<Scalars['String']['input']>
    defaultDeadlineDuration_i?: InputMaybe<Scalars['String']['input']>
    defaultDeadlineDuration_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    defaultDeadlineDuration_not?: InputMaybe<Scalars['String']['input']>
    defaultDeadlineDuration_not_contains?: InputMaybe<Scalars['String']['input']>
    defaultDeadlineDuration_not_contains_i?: InputMaybe<Scalars['String']['input']>
    defaultDeadlineDuration_not_ends_with?: InputMaybe<Scalars['String']['input']>
    defaultDeadlineDuration_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    defaultDeadlineDuration_not_i?: InputMaybe<Scalars['String']['input']>
    defaultDeadlineDuration_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    defaultDeadlineDuration_not_starts_with?: InputMaybe<Scalars['String']['input']>
    defaultDeadlineDuration_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    defaultDeadlineDuration_starts_with?: InputMaybe<Scalars['String']['input']>
    defaultDeadlineDuration_starts_with_i?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    emergencyDeadlineDuration?: InputMaybe<Scalars['String']['input']>
    emergencyDeadlineDuration_contains?: InputMaybe<Scalars['String']['input']>
    emergencyDeadlineDuration_contains_i?: InputMaybe<Scalars['String']['input']>
    emergencyDeadlineDuration_ends_with?: InputMaybe<Scalars['String']['input']>
    emergencyDeadlineDuration_ends_with_i?: InputMaybe<Scalars['String']['input']>
    emergencyDeadlineDuration_i?: InputMaybe<Scalars['String']['input']>
    emergencyDeadlineDuration_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    emergencyDeadlineDuration_not?: InputMaybe<Scalars['String']['input']>
    emergencyDeadlineDuration_not_contains?: InputMaybe<Scalars['String']['input']>
    emergencyDeadlineDuration_not_contains_i?: InputMaybe<Scalars['String']['input']>
    emergencyDeadlineDuration_not_ends_with?: InputMaybe<Scalars['String']['input']>
    emergencyDeadlineDuration_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    emergencyDeadlineDuration_not_i?: InputMaybe<Scalars['String']['input']>
    emergencyDeadlineDuration_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    emergencyDeadlineDuration_not_starts_with?: InputMaybe<Scalars['String']['input']>
    emergencyDeadlineDuration_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    emergencyDeadlineDuration_starts_with?: InputMaybe<Scalars['String']['input']>
    emergencyDeadlineDuration_starts_with_i?: InputMaybe<Scalars['String']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    paidDeadlineDuration?: InputMaybe<Scalars['String']['input']>
    paidDeadlineDuration_contains?: InputMaybe<Scalars['String']['input']>
    paidDeadlineDuration_contains_i?: InputMaybe<Scalars['String']['input']>
    paidDeadlineDuration_ends_with?: InputMaybe<Scalars['String']['input']>
    paidDeadlineDuration_ends_with_i?: InputMaybe<Scalars['String']['input']>
    paidDeadlineDuration_i?: InputMaybe<Scalars['String']['input']>
    paidDeadlineDuration_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    paidDeadlineDuration_not?: InputMaybe<Scalars['String']['input']>
    paidDeadlineDuration_not_contains?: InputMaybe<Scalars['String']['input']>
    paidDeadlineDuration_not_contains_i?: InputMaybe<Scalars['String']['input']>
    paidDeadlineDuration_not_ends_with?: InputMaybe<Scalars['String']['input']>
    paidDeadlineDuration_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    paidDeadlineDuration_not_i?: InputMaybe<Scalars['String']['input']>
    paidDeadlineDuration_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    paidDeadlineDuration_not_starts_with?: InputMaybe<Scalars['String']['input']>
    paidDeadlineDuration_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    paidDeadlineDuration_starts_with?: InputMaybe<Scalars['String']['input']>
    paidDeadlineDuration_starts_with_i?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    warrantyDeadlineDuration?: InputMaybe<Scalars['String']['input']>
    warrantyDeadlineDuration_contains?: InputMaybe<Scalars['String']['input']>
    warrantyDeadlineDuration_contains_i?: InputMaybe<Scalars['String']['input']>
    warrantyDeadlineDuration_ends_with?: InputMaybe<Scalars['String']['input']>
    warrantyDeadlineDuration_ends_with_i?: InputMaybe<Scalars['String']['input']>
    warrantyDeadlineDuration_i?: InputMaybe<Scalars['String']['input']>
    warrantyDeadlineDuration_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    warrantyDeadlineDuration_not?: InputMaybe<Scalars['String']['input']>
    warrantyDeadlineDuration_not_contains?: InputMaybe<Scalars['String']['input']>
    warrantyDeadlineDuration_not_contains_i?: InputMaybe<Scalars['String']['input']>
    warrantyDeadlineDuration_not_ends_with?: InputMaybe<Scalars['String']['input']>
    warrantyDeadlineDuration_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    warrantyDeadlineDuration_not_i?: InputMaybe<Scalars['String']['input']>
    warrantyDeadlineDuration_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    warrantyDeadlineDuration_not_starts_with?: InputMaybe<Scalars['String']['input']>
    warrantyDeadlineDuration_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    warrantyDeadlineDuration_starts_with?: InputMaybe<Scalars['String']['input']>
    warrantyDeadlineDuration_starts_with_i?: InputMaybe<Scalars['String']['input']>
}

export type TicketOrganizationSettingWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type TicketOrganizationSettingsCreateInput = {
    data?: InputMaybe<TicketOrganizationSettingCreateInput>
}

export type TicketOrganizationSettingsUpdateInput = {
    data?: InputMaybe<TicketOrganizationSettingUpdateInput>
    id: Scalars['ID']['input']
}

export type TicketOverviewResult = {
    __typename?: 'TicketOverviewResult'
    tickets?: Maybe<Array<TicketGroupedCounter>>
    translations?: Maybe<Array<Maybe<TicketOverviewTranslations>>>
}

export type TicketOverviewTranslations = {
    __typename?: 'TicketOverviewTranslations'
    key: Scalars['String']['output']
    value: Scalars['String']['output']
}

/**  Describes where the incident occurred  */
export type TicketPlaceClassifier = {
    __typename?: 'TicketPlaceClassifier'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketPlaceClassifier List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketPlaceClassifier List config, or
   *  3. As an alias to a 'name' field on the TicketPlaceClassifier List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketPlaceClassifier List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  text content  */
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Ref to the organization. If this ref is null the object is common for all organizations  */
    organization?: Maybe<Organization>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type TicketPlaceClassifierCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type TicketPlaceClassifierHistoryRecord = {
    __typename?: 'TicketPlaceClassifierHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketPlaceClassifierHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketPlaceClassifierHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the TicketPlaceClassifierHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketPlaceClassifierHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<TicketPlaceClassifierHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type TicketPlaceClassifierHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<TicketPlaceClassifierHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum TicketPlaceClassifierHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type TicketPlaceClassifierHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<TicketPlaceClassifierHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type TicketPlaceClassifierHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<TicketPlaceClassifierHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<TicketPlaceClassifierHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<TicketPlaceClassifierHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<TicketPlaceClassifierHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<TicketPlaceClassifierHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<TicketPlaceClassifierHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type TicketPlaceClassifierHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type TicketPlaceClassifierHistoryRecordsCreateInput = {
    data?: InputMaybe<TicketPlaceClassifierHistoryRecordCreateInput>
}

export type TicketPlaceClassifierHistoryRecordsUpdateInput = {
    data?: InputMaybe<TicketPlaceClassifierHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type TicketPlaceClassifierRelateToOneInput = {
    connect?: InputMaybe<TicketPlaceClassifierWhereUniqueInput>
    create?: InputMaybe<TicketPlaceClassifierCreateInput>
    disconnect?: InputMaybe<TicketPlaceClassifierWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type TicketPlaceClassifierUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type TicketPlaceClassifierWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<TicketPlaceClassifierWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<TicketPlaceClassifierWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type TicketPlaceClassifierWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type TicketPlaceClassifiersCreateInput = {
    data?: InputMaybe<TicketPlaceClassifierCreateInput>
}

export type TicketPlaceClassifiersUpdateInput = {
    data?: InputMaybe<TicketPlaceClassifierUpdateInput>
    id: Scalars['ID']['input']
}

/**  Describes what work needs to be done to fix incident  */
export type TicketProblemClassifier = {
    __typename?: 'TicketProblemClassifier'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketProblemClassifier List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketProblemClassifier List config, or
   *  3. As an alias to a 'name' field on the TicketProblemClassifier List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketProblemClassifier List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  text content  */
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Ref to the organization. If this ref is null the object is common for all organizations  */
    organization?: Maybe<Organization>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type TicketProblemClassifierCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type TicketProblemClassifierHistoryRecord = {
    __typename?: 'TicketProblemClassifierHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketProblemClassifierHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketProblemClassifierHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the TicketProblemClassifierHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketProblemClassifierHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<TicketProblemClassifierHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type TicketProblemClassifierHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<TicketProblemClassifierHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum TicketProblemClassifierHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type TicketProblemClassifierHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<TicketProblemClassifierHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type TicketProblemClassifierHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<TicketProblemClassifierHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<TicketProblemClassifierHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<TicketProblemClassifierHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<TicketProblemClassifierHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<TicketProblemClassifierHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<TicketProblemClassifierHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type TicketProblemClassifierHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type TicketProblemClassifierHistoryRecordsCreateInput = {
    data?: InputMaybe<TicketProblemClassifierHistoryRecordCreateInput>
}

export type TicketProblemClassifierHistoryRecordsUpdateInput = {
    data?: InputMaybe<TicketProblemClassifierHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type TicketProblemClassifierRelateToOneInput = {
    connect?: InputMaybe<TicketProblemClassifierWhereUniqueInput>
    create?: InputMaybe<TicketProblemClassifierCreateInput>
    disconnect?: InputMaybe<TicketProblemClassifierWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type TicketProblemClassifierUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type TicketProblemClassifierWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<TicketProblemClassifierWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<TicketProblemClassifierWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type TicketProblemClassifierWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type TicketProblemClassifiersCreateInput = {
    data?: InputMaybe<TicketProblemClassifierCreateInput>
}

export type TicketProblemClassifiersUpdateInput = {
    data?: InputMaybe<TicketProblemClassifierUpdateInput>
    id: Scalars['ID']['input']
}

/**  Textual information in free format related to a property or group of properties, for example list of responsible for property, organization number for clients  */
export type TicketPropertyHint = {
    __typename?: 'TicketPropertyHint'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketPropertyHint List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketPropertyHint List config, or
   *  3. As an alias to a 'name' field on the TicketPropertyHint List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketPropertyHint List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  Textual content of help in HTML format  */
    content?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  Hint name  */
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
    organization?: Maybe<Organization>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type TicketPropertyHintCreateInput = {
    content?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type TicketPropertyHintHistoryRecord = {
    __typename?: 'TicketPropertyHintHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketPropertyHintHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketPropertyHintHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the TicketPropertyHintHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketPropertyHintHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    content?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<TicketPropertyHintHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type TicketPropertyHintHistoryRecordCreateInput = {
    content?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<TicketPropertyHintHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum TicketPropertyHintHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type TicketPropertyHintHistoryRecordUpdateInput = {
    content?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<TicketPropertyHintHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type TicketPropertyHintHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<TicketPropertyHintHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<TicketPropertyHintHistoryRecordWhereInput>>>
    content?: InputMaybe<Scalars['String']['input']>
    content_contains?: InputMaybe<Scalars['String']['input']>
    content_contains_i?: InputMaybe<Scalars['String']['input']>
    content_ends_with?: InputMaybe<Scalars['String']['input']>
    content_ends_with_i?: InputMaybe<Scalars['String']['input']>
    content_i?: InputMaybe<Scalars['String']['input']>
    content_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    content_not?: InputMaybe<Scalars['String']['input']>
    content_not_contains?: InputMaybe<Scalars['String']['input']>
    content_not_contains_i?: InputMaybe<Scalars['String']['input']>
    content_not_ends_with?: InputMaybe<Scalars['String']['input']>
    content_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    content_not_i?: InputMaybe<Scalars['String']['input']>
    content_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    content_not_starts_with?: InputMaybe<Scalars['String']['input']>
    content_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    content_starts_with?: InputMaybe<Scalars['String']['input']>
    content_starts_with_i?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<TicketPropertyHintHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<TicketPropertyHintHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<TicketPropertyHintHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<TicketPropertyHintHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type TicketPropertyHintHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type TicketPropertyHintHistoryRecordsCreateInput = {
    data?: InputMaybe<TicketPropertyHintHistoryRecordCreateInput>
}

export type TicketPropertyHintHistoryRecordsUpdateInput = {
    data?: InputMaybe<TicketPropertyHintHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type TicketPropertyHintPropertiesCreateInput = {
    data?: InputMaybe<TicketPropertyHintPropertyCreateInput>
}

export type TicketPropertyHintPropertiesUpdateInput = {
    data?: InputMaybe<TicketPropertyHintPropertyUpdateInput>
    id: Scalars['ID']['input']
}

/**  Join entity for many-to-many relations, that determines a Property to which the TicketPropertyHint belongs  */
export type TicketPropertyHintProperty = {
    __typename?: 'TicketPropertyHintProperty'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketPropertyHintProperty List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketPropertyHintProperty List config, or
   *  3. As an alias to a 'name' field on the TicketPropertyHintProperty List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketPropertyHintProperty List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['String']['output']>
    /**  Ref to the organization. It is filled in on the server and is read-only  */
    organization?: Maybe<Organization>
    /**  Property for which ticketPropertyHint exists  */
    property?: Maybe<Property>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  TicketPropertyHint which belongs to property  */
    ticketPropertyHint?: Maybe<TicketPropertyHint>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type TicketPropertyHintPropertyCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    property?: InputMaybe<PropertyRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    ticketPropertyHint?: InputMaybe<TicketPropertyHintRelateToOneInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type TicketPropertyHintPropertyHistoryRecord = {
    __typename?: 'TicketPropertyHintPropertyHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketPropertyHintPropertyHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketPropertyHintPropertyHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the TicketPropertyHintPropertyHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketPropertyHintPropertyHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<TicketPropertyHintPropertyHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    property?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    ticketPropertyHint?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type TicketPropertyHintPropertyHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<TicketPropertyHintPropertyHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    property?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    ticketPropertyHint?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum TicketPropertyHintPropertyHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type TicketPropertyHintPropertyHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<TicketPropertyHintPropertyHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    property?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    ticketPropertyHint?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type TicketPropertyHintPropertyHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<TicketPropertyHintPropertyHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<TicketPropertyHintPropertyHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<TicketPropertyHintPropertyHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<TicketPropertyHintPropertyHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<TicketPropertyHintPropertyHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<TicketPropertyHintPropertyHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    property?: InputMaybe<Scalars['String']['input']>
    property_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    property_not?: InputMaybe<Scalars['String']['input']>
    property_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    ticketPropertyHint?: InputMaybe<Scalars['String']['input']>
    ticketPropertyHint_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    ticketPropertyHint_not?: InputMaybe<Scalars['String']['input']>
    ticketPropertyHint_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type TicketPropertyHintPropertyHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type TicketPropertyHintPropertyHistoryRecordsCreateInput = {
    data?: InputMaybe<TicketPropertyHintPropertyHistoryRecordCreateInput>
}

export type TicketPropertyHintPropertyHistoryRecordsUpdateInput = {
    data?: InputMaybe<TicketPropertyHintPropertyHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type TicketPropertyHintPropertyUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    property?: InputMaybe<PropertyRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    ticketPropertyHint?: InputMaybe<TicketPropertyHintRelateToOneInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type TicketPropertyHintPropertyWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<TicketPropertyHintPropertyWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<TicketPropertyHintPropertyWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    property?: InputMaybe<PropertyWhereInput>
    property_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    ticketPropertyHint?: InputMaybe<TicketPropertyHintWhereInput>
    ticketPropertyHint_is_null?: InputMaybe<Scalars['Boolean']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type TicketPropertyHintPropertyWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type TicketPropertyHintRelateToOneInput = {
    connect?: InputMaybe<TicketPropertyHintWhereUniqueInput>
    create?: InputMaybe<TicketPropertyHintCreateInput>
    disconnect?: InputMaybe<TicketPropertyHintWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type TicketPropertyHintUpdateInput = {
    content?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type TicketPropertyHintWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<TicketPropertyHintWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<TicketPropertyHintWhereInput>>>
    content?: InputMaybe<Scalars['String']['input']>
    content_contains?: InputMaybe<Scalars['String']['input']>
    content_contains_i?: InputMaybe<Scalars['String']['input']>
    content_ends_with?: InputMaybe<Scalars['String']['input']>
    content_ends_with_i?: InputMaybe<Scalars['String']['input']>
    content_i?: InputMaybe<Scalars['String']['input']>
    content_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    content_not?: InputMaybe<Scalars['String']['input']>
    content_not_contains?: InputMaybe<Scalars['String']['input']>
    content_not_contains_i?: InputMaybe<Scalars['String']['input']>
    content_not_ends_with?: InputMaybe<Scalars['String']['input']>
    content_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    content_not_i?: InputMaybe<Scalars['String']['input']>
    content_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    content_not_starts_with?: InputMaybe<Scalars['String']['input']>
    content_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    content_starts_with?: InputMaybe<Scalars['String']['input']>
    content_starts_with_i?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type TicketPropertyHintWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type TicketPropertyHintsCreateInput = {
    data?: InputMaybe<TicketPropertyHintCreateInput>
}

export type TicketPropertyHintsUpdateInput = {
    data?: InputMaybe<TicketPropertyHintUpdateInput>
    id: Scalars['ID']['input']
}

export enum TicketQualityControlValueType {
    Bad = 'bad',
    Good = 'good',
}

export type TicketRelateToOneInput = {
    connect?: InputMaybe<TicketWhereUniqueInput>
    create?: InputMaybe<TicketCreateInput>
    disconnect?: InputMaybe<TicketWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type TicketReportData = {
    __typename?: 'TicketReportData'
    currentValue: Scalars['Int']['output']
    growth: Scalars['Float']['output']
    statusName: Scalars['String']['output']
    statusType: TicketStatusTypeType
}

export enum TicketReportPeriodType {
    CalendarWeek = 'calendarWeek',
    Month = 'month',
    Quarter = 'quarter',
    Year = 'year',
}

export type TicketReportWidgetInput = {
    offset?: InputMaybe<Scalars['Int']['input']>
    periodType: TicketReportPeriodType
    userOrganizationId: Scalars['String']['input']
}

export type TicketReportWidgetOutput = {
    __typename?: 'TicketReportWidgetOutput'
    data?: Maybe<Array<TicketReportData>>
}

export enum TicketReviewValueType {
    Bad = 'bad',
    Good = 'good',
    Returned = 'returned',
}

export enum TicketSectionTypeType {
    Parking = 'parking',
    Section = 'section',
}

/**  Ticket source. Income call, mobile app, external system, ...  */
export type TicketSource = {
    __typename?: 'TicketSource'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketSource List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketSource List config, or
   *  3. As an alias to a 'name' field on the TicketSource List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketSource List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  Localized Ticket source name  */
    name?: Maybe<Scalars['String']['output']>
    nameNonLocalized?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Ref to the organization. If this ref is null the object is common for all organizations  */
    organization?: Maybe<Organization>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    type?: Maybe<TicketSourceTypeType>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type TicketSourceCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    type?: InputMaybe<TicketSourceTypeType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type TicketSourceHistoryRecord = {
    __typename?: 'TicketSourceHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketSourceHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketSourceHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the TicketSourceHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketSourceHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<TicketSourceHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    type?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type TicketSourceHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<TicketSourceHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    type?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum TicketSourceHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type TicketSourceHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<TicketSourceHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    type?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type TicketSourceHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<TicketSourceHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<TicketSourceHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<TicketSourceHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<TicketSourceHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<TicketSourceHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<TicketSourceHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    type?: InputMaybe<Scalars['String']['input']>
    type_contains?: InputMaybe<Scalars['String']['input']>
    type_contains_i?: InputMaybe<Scalars['String']['input']>
    type_ends_with?: InputMaybe<Scalars['String']['input']>
    type_ends_with_i?: InputMaybe<Scalars['String']['input']>
    type_i?: InputMaybe<Scalars['String']['input']>
    type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    type_not?: InputMaybe<Scalars['String']['input']>
    type_not_contains?: InputMaybe<Scalars['String']['input']>
    type_not_contains_i?: InputMaybe<Scalars['String']['input']>
    type_not_ends_with?: InputMaybe<Scalars['String']['input']>
    type_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    type_not_i?: InputMaybe<Scalars['String']['input']>
    type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    type_not_starts_with?: InputMaybe<Scalars['String']['input']>
    type_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    type_starts_with?: InputMaybe<Scalars['String']['input']>
    type_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type TicketSourceHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type TicketSourceHistoryRecordsCreateInput = {
    data?: InputMaybe<TicketSourceHistoryRecordCreateInput>
}

export type TicketSourceHistoryRecordsUpdateInput = {
    data?: InputMaybe<TicketSourceHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type TicketSourceRelateToOneInput = {
    connect?: InputMaybe<TicketSourceWhereUniqueInput>
    create?: InputMaybe<TicketSourceCreateInput>
    disconnect?: InputMaybe<TicketSourceWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export enum TicketSourceTypeType {
    Call = 'call',
    CrmImport = 'crm_import',
    Email = 'email',
    Messenger = 'messenger',
    MobileApp = 'mobile_app',
    MobileAppResident = 'mobile_app_resident',
    MobileAppStaff = 'mobile_app_staff',
    OrganizationSite = 'organization_site',
    Other = 'other',
    RemoteSystem = 'remote_system',
    SocialNetwork = 'social_network',
    Visit = 'visit',
    WebApp = 'web_app',
}

export type TicketSourceUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    type?: InputMaybe<TicketSourceTypeType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type TicketSourceWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<TicketSourceWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<TicketSourceWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    type?: InputMaybe<TicketSourceTypeType>
    type_in?: InputMaybe<Array<InputMaybe<TicketSourceTypeType>>>
    type_not?: InputMaybe<TicketSourceTypeType>
    type_not_in?: InputMaybe<Array<InputMaybe<TicketSourceTypeType>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type TicketSourceWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type TicketSourcesCreateInput = {
    data?: InputMaybe<TicketSourceCreateInput>
}

export type TicketSourcesUpdateInput = {
    data?: InputMaybe<TicketSourceUpdateInput>
    id: Scalars['ID']['input']
}

/**  Ticket status. We have a organization specific statuses  */
export type TicketStatus = {
    __typename?: 'TicketStatus'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketStatus List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketStatus List config, or
   *  3. As an alias to a 'name' field on the TicketStatus List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketStatus List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  Status colors, includes primary (font color), secondary (background color), additional (border color), all colors presented in HEX  */
    colors?: Maybe<TicketStatusColorsField>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  Status name  */
    name?: Maybe<Scalars['String']['output']>
    nameNonLocalized?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Ref to the organization. If this ref is null the object is common for all organizations  */
    organization?: Maybe<Organization>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Ticket status. You should also increase `statusReopenedCounter` if you want to reopen ticket  */
    type?: Maybe<TicketStatusTypeType>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type TicketStatusColorsField = {
    __typename?: 'TicketStatusColorsField'
    additional?: Maybe<Scalars['String']['output']>
    primary?: Maybe<Scalars['String']['output']>
    secondary?: Maybe<Scalars['String']['output']>
}

export type TicketStatusCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    type?: InputMaybe<TicketStatusTypeType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type TicketStatusHistoryRecord = {
    __typename?: 'TicketStatusHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketStatusHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketStatusHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the TicketStatusHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketStatusHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<TicketStatusHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    type?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type TicketStatusHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<TicketStatusHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    type?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum TicketStatusHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type TicketStatusHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<TicketStatusHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    type?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type TicketStatusHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<TicketStatusHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<TicketStatusHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<TicketStatusHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<TicketStatusHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<TicketStatusHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<TicketStatusHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    type?: InputMaybe<Scalars['String']['input']>
    type_contains?: InputMaybe<Scalars['String']['input']>
    type_contains_i?: InputMaybe<Scalars['String']['input']>
    type_ends_with?: InputMaybe<Scalars['String']['input']>
    type_ends_with_i?: InputMaybe<Scalars['String']['input']>
    type_i?: InputMaybe<Scalars['String']['input']>
    type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    type_not?: InputMaybe<Scalars['String']['input']>
    type_not_contains?: InputMaybe<Scalars['String']['input']>
    type_not_contains_i?: InputMaybe<Scalars['String']['input']>
    type_not_ends_with?: InputMaybe<Scalars['String']['input']>
    type_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    type_not_i?: InputMaybe<Scalars['String']['input']>
    type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    type_not_starts_with?: InputMaybe<Scalars['String']['input']>
    type_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    type_starts_with?: InputMaybe<Scalars['String']['input']>
    type_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type TicketStatusHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type TicketStatusHistoryRecordsCreateInput = {
    data?: InputMaybe<TicketStatusHistoryRecordCreateInput>
}

export type TicketStatusHistoryRecordsUpdateInput = {
    data?: InputMaybe<TicketStatusHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type TicketStatusRelateToOneInput = {
    connect?: InputMaybe<TicketStatusWhereUniqueInput>
    create?: InputMaybe<TicketStatusCreateInput>
    disconnect?: InputMaybe<TicketStatusWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export enum TicketStatusTypeType {
    Canceled = 'canceled',
    Closed = 'closed',
    Completed = 'completed',
    Deferred = 'deferred',
    NewOrReopened = 'new_or_reopened',
    Processing = 'processing',
}

export type TicketStatusUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    type?: InputMaybe<TicketStatusTypeType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type TicketStatusWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<TicketStatusWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<TicketStatusWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    type?: InputMaybe<TicketStatusTypeType>
    type_in?: InputMaybe<Array<InputMaybe<TicketStatusTypeType>>>
    type_not?: InputMaybe<TicketStatusTypeType>
    type_not_in?: InputMaybe<Array<InputMaybe<TicketStatusTypeType>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type TicketStatusWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type TicketStatusesCreateInput = {
    data?: InputMaybe<TicketStatusCreateInput>
}

export type TicketStatusesUpdateInput = {
    data?: InputMaybe<TicketStatusUpdateInput>
    id: Scalars['ID']['input']
}

export enum TicketUnitTypeType {
    Apartment = 'apartment',
    Commercial = 'commercial',
    Flat = 'flat',
    Parking = 'parking',
    Warehouse = 'warehouse',
}

export type TicketUpdateInput = {
    assignee?: InputMaybe<UserRelateToOneInput>
    canReadByResident?: InputMaybe<Scalars['Boolean']['input']>
    categoryClassifier?: InputMaybe<TicketCategoryClassifierRelateToOneInput>
    classifier?: InputMaybe<TicketClassifierRelateToOneInput>
    client?: InputMaybe<UserRelateToOneInput>
    clientEmail?: InputMaybe<Scalars['String']['input']>
    clientName?: InputMaybe<Scalars['String']['input']>
    clientPhone?: InputMaybe<Scalars['String']['input']>
    completedAt?: InputMaybe<Scalars['String']['input']>
    contact?: InputMaybe<ContactRelateToOneInput>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deadline?: InputMaybe<Scalars['String']['input']>
    deferredUntil?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    details?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    executor?: InputMaybe<UserRelateToOneInput>
    feedbackAdditionalOptions?: InputMaybe<Array<InputMaybe<FeedbackAdditionalOptionsType>>>
    feedbackComment?: InputMaybe<Scalars['String']['input']>
    feedbackUpdatedAt?: InputMaybe<Scalars['String']['input']>
    feedbackValue?: InputMaybe<TicketFeedbackValueType>
    floorName?: InputMaybe<Scalars['String']['input']>
    isAutoClassified?: InputMaybe<Scalars['Boolean']['input']>
    isCompletedAfterDeadline?: InputMaybe<Scalars['Boolean']['input']>
    isEmergency?: InputMaybe<Scalars['Boolean']['input']>
    isPaid?: InputMaybe<Scalars['Boolean']['input']>
    isPayable?: InputMaybe<Scalars['Boolean']['input']>
    isResidentTicket?: InputMaybe<Scalars['Boolean']['input']>
    isWarranty?: InputMaybe<Scalars['Boolean']['input']>
    lastCommentAt?: InputMaybe<Scalars['String']['input']>
    lastCommentWithResidentTypeAt?: InputMaybe<Scalars['String']['input']>
    lastResidentCommentAt?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    number?: InputMaybe<Scalars['Int']['input']>
    order?: InputMaybe<Scalars['Int']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    placeClassifier?: InputMaybe<TicketPlaceClassifierRelateToOneInput>
    problemClassifier?: InputMaybe<TicketProblemClassifierRelateToOneInput>
    property?: InputMaybe<PropertyRelateToOneInput>
    propertyAddress?: InputMaybe<Scalars['String']['input']>
    propertyAddressMeta?: InputMaybe<Scalars['JSON']['input']>
    qualityControlAdditionalOptions?: InputMaybe<Array<InputMaybe<QualityControlAdditionalOptionsType>>>
    qualityControlComment?: InputMaybe<Scalars['String']['input']>
    qualityControlUpdatedAt?: InputMaybe<Scalars['String']['input']>
    qualityControlUpdatedBy?: InputMaybe<UserRelateToOneInput>
    qualityControlValue?: InputMaybe<TicketQualityControlValueType>
    related?: InputMaybe<TicketRelateToOneInput>
    reviewComment?: InputMaybe<Scalars['String']['input']>
    reviewValue?: InputMaybe<TicketReviewValueType>
    sectionName?: InputMaybe<Scalars['String']['input']>
    sectionType?: InputMaybe<TicketSectionTypeType>
    sender?: InputMaybe<SenderFieldInput>
    source?: InputMaybe<TicketSourceRelateToOneInput>
    sourceMeta?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<TicketStatusRelateToOneInput>
    statusReason?: InputMaybe<Scalars['String']['input']>
    statusReopenedCounter?: InputMaybe<Scalars['Int']['input']>
    statusUpdatedAt?: InputMaybe<Scalars['String']['input']>
    unitName?: InputMaybe<Scalars['String']['input']>
    unitType?: InputMaybe<TicketUnitTypeType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type TicketWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<TicketWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<TicketWhereInput>>>
    assignee?: InputMaybe<UserWhereInput>
    assignee_is_null?: InputMaybe<Scalars['Boolean']['input']>
    canReadByResident?: InputMaybe<Scalars['Boolean']['input']>
    canReadByResident_not?: InputMaybe<Scalars['Boolean']['input']>
    categoryClassifier?: InputMaybe<TicketCategoryClassifierWhereInput>
    categoryClassifier_is_null?: InputMaybe<Scalars['Boolean']['input']>
    classifier?: InputMaybe<TicketClassifierWhereInput>
    classifier_is_null?: InputMaybe<Scalars['Boolean']['input']>
    client?: InputMaybe<UserWhereInput>
    clientEmail?: InputMaybe<Scalars['String']['input']>
    clientEmail_contains?: InputMaybe<Scalars['String']['input']>
    clientEmail_contains_i?: InputMaybe<Scalars['String']['input']>
    clientEmail_ends_with?: InputMaybe<Scalars['String']['input']>
    clientEmail_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientEmail_i?: InputMaybe<Scalars['String']['input']>
    clientEmail_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientEmail_not?: InputMaybe<Scalars['String']['input']>
    clientEmail_not_contains?: InputMaybe<Scalars['String']['input']>
    clientEmail_not_contains_i?: InputMaybe<Scalars['String']['input']>
    clientEmail_not_ends_with?: InputMaybe<Scalars['String']['input']>
    clientEmail_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientEmail_not_i?: InputMaybe<Scalars['String']['input']>
    clientEmail_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientEmail_not_starts_with?: InputMaybe<Scalars['String']['input']>
    clientEmail_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    clientEmail_starts_with?: InputMaybe<Scalars['String']['input']>
    clientEmail_starts_with_i?: InputMaybe<Scalars['String']['input']>
    clientName?: InputMaybe<Scalars['String']['input']>
    clientName_contains?: InputMaybe<Scalars['String']['input']>
    clientName_contains_i?: InputMaybe<Scalars['String']['input']>
    clientName_ends_with?: InputMaybe<Scalars['String']['input']>
    clientName_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientName_i?: InputMaybe<Scalars['String']['input']>
    clientName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientName_not?: InputMaybe<Scalars['String']['input']>
    clientName_not_contains?: InputMaybe<Scalars['String']['input']>
    clientName_not_contains_i?: InputMaybe<Scalars['String']['input']>
    clientName_not_ends_with?: InputMaybe<Scalars['String']['input']>
    clientName_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientName_not_i?: InputMaybe<Scalars['String']['input']>
    clientName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientName_not_starts_with?: InputMaybe<Scalars['String']['input']>
    clientName_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    clientName_starts_with?: InputMaybe<Scalars['String']['input']>
    clientName_starts_with_i?: InputMaybe<Scalars['String']['input']>
    clientPhone?: InputMaybe<Scalars['String']['input']>
    clientPhone_contains?: InputMaybe<Scalars['String']['input']>
    clientPhone_contains_i?: InputMaybe<Scalars['String']['input']>
    clientPhone_ends_with?: InputMaybe<Scalars['String']['input']>
    clientPhone_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientPhone_i?: InputMaybe<Scalars['String']['input']>
    clientPhone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientPhone_not?: InputMaybe<Scalars['String']['input']>
    clientPhone_not_contains?: InputMaybe<Scalars['String']['input']>
    clientPhone_not_contains_i?: InputMaybe<Scalars['String']['input']>
    clientPhone_not_ends_with?: InputMaybe<Scalars['String']['input']>
    clientPhone_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    clientPhone_not_i?: InputMaybe<Scalars['String']['input']>
    clientPhone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    clientPhone_not_starts_with?: InputMaybe<Scalars['String']['input']>
    clientPhone_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    clientPhone_starts_with?: InputMaybe<Scalars['String']['input']>
    clientPhone_starts_with_i?: InputMaybe<Scalars['String']['input']>
    client_is_null?: InputMaybe<Scalars['Boolean']['input']>
    completedAt?: InputMaybe<Scalars['String']['input']>
    completedAt_gt?: InputMaybe<Scalars['String']['input']>
    completedAt_gte?: InputMaybe<Scalars['String']['input']>
    completedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    completedAt_lt?: InputMaybe<Scalars['String']['input']>
    completedAt_lte?: InputMaybe<Scalars['String']['input']>
    completedAt_not?: InputMaybe<Scalars['String']['input']>
    completedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    contact?: InputMaybe<ContactWhereInput>
    contact_is_null?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deadline?: InputMaybe<Scalars['String']['input']>
    deadline_gt?: InputMaybe<Scalars['String']['input']>
    deadline_gte?: InputMaybe<Scalars['String']['input']>
    deadline_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deadline_lt?: InputMaybe<Scalars['String']['input']>
    deadline_lte?: InputMaybe<Scalars['String']['input']>
    deadline_not?: InputMaybe<Scalars['String']['input']>
    deadline_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deferredUntil?: InputMaybe<Scalars['String']['input']>
    deferredUntil_gt?: InputMaybe<Scalars['String']['input']>
    deferredUntil_gte?: InputMaybe<Scalars['String']['input']>
    deferredUntil_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deferredUntil_lt?: InputMaybe<Scalars['String']['input']>
    deferredUntil_lte?: InputMaybe<Scalars['String']['input']>
    deferredUntil_not?: InputMaybe<Scalars['String']['input']>
    deferredUntil_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    details?: InputMaybe<Scalars['String']['input']>
    details_contains?: InputMaybe<Scalars['String']['input']>
    details_contains_i?: InputMaybe<Scalars['String']['input']>
    details_ends_with?: InputMaybe<Scalars['String']['input']>
    details_ends_with_i?: InputMaybe<Scalars['String']['input']>
    details_i?: InputMaybe<Scalars['String']['input']>
    details_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    details_not?: InputMaybe<Scalars['String']['input']>
    details_not_contains?: InputMaybe<Scalars['String']['input']>
    details_not_contains_i?: InputMaybe<Scalars['String']['input']>
    details_not_ends_with?: InputMaybe<Scalars['String']['input']>
    details_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    details_not_i?: InputMaybe<Scalars['String']['input']>
    details_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    details_not_starts_with?: InputMaybe<Scalars['String']['input']>
    details_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    details_starts_with?: InputMaybe<Scalars['String']['input']>
    details_starts_with_i?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    executor?: InputMaybe<UserWhereInput>
    executor_is_null?: InputMaybe<Scalars['Boolean']['input']>
    feedbackAdditionalOptions?: InputMaybe<Array<InputMaybe<FeedbackAdditionalOptionsType>>>
    feedbackAdditionalOptions_in?: InputMaybe<Array<InputMaybe<Array<InputMaybe<FeedbackAdditionalOptionsType>>>>>
    feedbackAdditionalOptions_not?: InputMaybe<Array<InputMaybe<FeedbackAdditionalOptionsType>>>
    feedbackAdditionalOptions_not_in?: InputMaybe<Array<InputMaybe<Array<InputMaybe<FeedbackAdditionalOptionsType>>>>>
    feedbackComment?: InputMaybe<Scalars['String']['input']>
    feedbackComment_contains?: InputMaybe<Scalars['String']['input']>
    feedbackComment_contains_i?: InputMaybe<Scalars['String']['input']>
    feedbackComment_ends_with?: InputMaybe<Scalars['String']['input']>
    feedbackComment_ends_with_i?: InputMaybe<Scalars['String']['input']>
    feedbackComment_i?: InputMaybe<Scalars['String']['input']>
    feedbackComment_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    feedbackComment_not?: InputMaybe<Scalars['String']['input']>
    feedbackComment_not_contains?: InputMaybe<Scalars['String']['input']>
    feedbackComment_not_contains_i?: InputMaybe<Scalars['String']['input']>
    feedbackComment_not_ends_with?: InputMaybe<Scalars['String']['input']>
    feedbackComment_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    feedbackComment_not_i?: InputMaybe<Scalars['String']['input']>
    feedbackComment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    feedbackComment_not_starts_with?: InputMaybe<Scalars['String']['input']>
    feedbackComment_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    feedbackComment_starts_with?: InputMaybe<Scalars['String']['input']>
    feedbackComment_starts_with_i?: InputMaybe<Scalars['String']['input']>
    feedbackUpdatedAt?: InputMaybe<Scalars['String']['input']>
    feedbackUpdatedAt_gt?: InputMaybe<Scalars['String']['input']>
    feedbackUpdatedAt_gte?: InputMaybe<Scalars['String']['input']>
    feedbackUpdatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    feedbackUpdatedAt_lt?: InputMaybe<Scalars['String']['input']>
    feedbackUpdatedAt_lte?: InputMaybe<Scalars['String']['input']>
    feedbackUpdatedAt_not?: InputMaybe<Scalars['String']['input']>
    feedbackUpdatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    feedbackValue?: InputMaybe<TicketFeedbackValueType>
    feedbackValue_in?: InputMaybe<Array<InputMaybe<TicketFeedbackValueType>>>
    feedbackValue_not?: InputMaybe<TicketFeedbackValueType>
    feedbackValue_not_in?: InputMaybe<Array<InputMaybe<TicketFeedbackValueType>>>
    floorName?: InputMaybe<Scalars['String']['input']>
    floorName_contains?: InputMaybe<Scalars['String']['input']>
    floorName_contains_i?: InputMaybe<Scalars['String']['input']>
    floorName_ends_with?: InputMaybe<Scalars['String']['input']>
    floorName_ends_with_i?: InputMaybe<Scalars['String']['input']>
    floorName_i?: InputMaybe<Scalars['String']['input']>
    floorName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    floorName_not?: InputMaybe<Scalars['String']['input']>
    floorName_not_contains?: InputMaybe<Scalars['String']['input']>
    floorName_not_contains_i?: InputMaybe<Scalars['String']['input']>
    floorName_not_ends_with?: InputMaybe<Scalars['String']['input']>
    floorName_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    floorName_not_i?: InputMaybe<Scalars['String']['input']>
    floorName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    floorName_not_starts_with?: InputMaybe<Scalars['String']['input']>
    floorName_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    floorName_starts_with?: InputMaybe<Scalars['String']['input']>
    floorName_starts_with_i?: InputMaybe<Scalars['String']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    isAutoClassified?: InputMaybe<Scalars['Boolean']['input']>
    isAutoClassified_not?: InputMaybe<Scalars['Boolean']['input']>
    isCompletedAfterDeadline?: InputMaybe<Scalars['Boolean']['input']>
    isCompletedAfterDeadline_not?: InputMaybe<Scalars['Boolean']['input']>
    isEmergency?: InputMaybe<Scalars['Boolean']['input']>
    isEmergency_not?: InputMaybe<Scalars['Boolean']['input']>
    isPaid?: InputMaybe<Scalars['Boolean']['input']>
    isPaid_not?: InputMaybe<Scalars['Boolean']['input']>
    isPayable?: InputMaybe<Scalars['Boolean']['input']>
    isPayable_not?: InputMaybe<Scalars['Boolean']['input']>
    isResidentTicket?: InputMaybe<Scalars['Boolean']['input']>
    isResidentTicket_not?: InputMaybe<Scalars['Boolean']['input']>
    isWarranty?: InputMaybe<Scalars['Boolean']['input']>
    isWarranty_not?: InputMaybe<Scalars['Boolean']['input']>
    lastCommentAt?: InputMaybe<Scalars['String']['input']>
    lastCommentAt_gt?: InputMaybe<Scalars['String']['input']>
    lastCommentAt_gte?: InputMaybe<Scalars['String']['input']>
    lastCommentAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    lastCommentAt_lt?: InputMaybe<Scalars['String']['input']>
    lastCommentAt_lte?: InputMaybe<Scalars['String']['input']>
    lastCommentAt_not?: InputMaybe<Scalars['String']['input']>
    lastCommentAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    lastCommentWithResidentTypeAt?: InputMaybe<Scalars['String']['input']>
    lastCommentWithResidentTypeAt_gt?: InputMaybe<Scalars['String']['input']>
    lastCommentWithResidentTypeAt_gte?: InputMaybe<Scalars['String']['input']>
    lastCommentWithResidentTypeAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    lastCommentWithResidentTypeAt_lt?: InputMaybe<Scalars['String']['input']>
    lastCommentWithResidentTypeAt_lte?: InputMaybe<Scalars['String']['input']>
    lastCommentWithResidentTypeAt_not?: InputMaybe<Scalars['String']['input']>
    lastCommentWithResidentTypeAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    lastResidentCommentAt?: InputMaybe<Scalars['String']['input']>
    lastResidentCommentAt_gt?: InputMaybe<Scalars['String']['input']>
    lastResidentCommentAt_gte?: InputMaybe<Scalars['String']['input']>
    lastResidentCommentAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    lastResidentCommentAt_lt?: InputMaybe<Scalars['String']['input']>
    lastResidentCommentAt_lte?: InputMaybe<Scalars['String']['input']>
    lastResidentCommentAt_not?: InputMaybe<Scalars['String']['input']>
    lastResidentCommentAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    number?: InputMaybe<Scalars['Int']['input']>
    number_gt?: InputMaybe<Scalars['Int']['input']>
    number_gte?: InputMaybe<Scalars['Int']['input']>
    number_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    number_lt?: InputMaybe<Scalars['Int']['input']>
    number_lte?: InputMaybe<Scalars['Int']['input']>
    number_not?: InputMaybe<Scalars['Int']['input']>
    number_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    order?: InputMaybe<Scalars['Int']['input']>
    order_gt?: InputMaybe<Scalars['Int']['input']>
    order_gte?: InputMaybe<Scalars['Int']['input']>
    order_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    order_lt?: InputMaybe<Scalars['Int']['input']>
    order_lte?: InputMaybe<Scalars['Int']['input']>
    order_not?: InputMaybe<Scalars['Int']['input']>
    order_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    placeClassifier?: InputMaybe<TicketPlaceClassifierWhereInput>
    placeClassifier_is_null?: InputMaybe<Scalars['Boolean']['input']>
    problemClassifier?: InputMaybe<TicketProblemClassifierWhereInput>
    problemClassifier_is_null?: InputMaybe<Scalars['Boolean']['input']>
    property?: InputMaybe<PropertyWhereInput>
    propertyAddress?: InputMaybe<Scalars['String']['input']>
    propertyAddressMeta?: InputMaybe<Scalars['JSON']['input']>
    propertyAddressMeta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    propertyAddressMeta_not?: InputMaybe<Scalars['JSON']['input']>
    propertyAddressMeta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    propertyAddress_contains?: InputMaybe<Scalars['String']['input']>
    propertyAddress_contains_i?: InputMaybe<Scalars['String']['input']>
    propertyAddress_ends_with?: InputMaybe<Scalars['String']['input']>
    propertyAddress_ends_with_i?: InputMaybe<Scalars['String']['input']>
    propertyAddress_i?: InputMaybe<Scalars['String']['input']>
    propertyAddress_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    propertyAddress_not?: InputMaybe<Scalars['String']['input']>
    propertyAddress_not_contains?: InputMaybe<Scalars['String']['input']>
    propertyAddress_not_contains_i?: InputMaybe<Scalars['String']['input']>
    propertyAddress_not_ends_with?: InputMaybe<Scalars['String']['input']>
    propertyAddress_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    propertyAddress_not_i?: InputMaybe<Scalars['String']['input']>
    propertyAddress_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    propertyAddress_not_starts_with?: InputMaybe<Scalars['String']['input']>
    propertyAddress_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    propertyAddress_starts_with?: InputMaybe<Scalars['String']['input']>
    propertyAddress_starts_with_i?: InputMaybe<Scalars['String']['input']>
    property_is_null?: InputMaybe<Scalars['Boolean']['input']>
    qualityControlAdditionalOptions?: InputMaybe<Array<InputMaybe<QualityControlAdditionalOptionsType>>>
    qualityControlAdditionalOptions_in?: InputMaybe<Array<InputMaybe<Array<InputMaybe<QualityControlAdditionalOptionsType>>>>>
    qualityControlAdditionalOptions_not?: InputMaybe<Array<InputMaybe<QualityControlAdditionalOptionsType>>>
    qualityControlAdditionalOptions_not_in?: InputMaybe<Array<InputMaybe<Array<InputMaybe<QualityControlAdditionalOptionsType>>>>>
    qualityControlComment?: InputMaybe<Scalars['String']['input']>
    qualityControlComment_contains?: InputMaybe<Scalars['String']['input']>
    qualityControlComment_contains_i?: InputMaybe<Scalars['String']['input']>
    qualityControlComment_ends_with?: InputMaybe<Scalars['String']['input']>
    qualityControlComment_ends_with_i?: InputMaybe<Scalars['String']['input']>
    qualityControlComment_i?: InputMaybe<Scalars['String']['input']>
    qualityControlComment_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    qualityControlComment_not?: InputMaybe<Scalars['String']['input']>
    qualityControlComment_not_contains?: InputMaybe<Scalars['String']['input']>
    qualityControlComment_not_contains_i?: InputMaybe<Scalars['String']['input']>
    qualityControlComment_not_ends_with?: InputMaybe<Scalars['String']['input']>
    qualityControlComment_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    qualityControlComment_not_i?: InputMaybe<Scalars['String']['input']>
    qualityControlComment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    qualityControlComment_not_starts_with?: InputMaybe<Scalars['String']['input']>
    qualityControlComment_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    qualityControlComment_starts_with?: InputMaybe<Scalars['String']['input']>
    qualityControlComment_starts_with_i?: InputMaybe<Scalars['String']['input']>
    qualityControlUpdatedAt?: InputMaybe<Scalars['String']['input']>
    qualityControlUpdatedAt_gt?: InputMaybe<Scalars['String']['input']>
    qualityControlUpdatedAt_gte?: InputMaybe<Scalars['String']['input']>
    qualityControlUpdatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    qualityControlUpdatedAt_lt?: InputMaybe<Scalars['String']['input']>
    qualityControlUpdatedAt_lte?: InputMaybe<Scalars['String']['input']>
    qualityControlUpdatedAt_not?: InputMaybe<Scalars['String']['input']>
    qualityControlUpdatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    qualityControlUpdatedBy?: InputMaybe<UserWhereInput>
    qualityControlUpdatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    qualityControlValue?: InputMaybe<TicketQualityControlValueType>
    qualityControlValue_in?: InputMaybe<Array<InputMaybe<TicketQualityControlValueType>>>
    qualityControlValue_not?: InputMaybe<TicketQualityControlValueType>
    qualityControlValue_not_in?: InputMaybe<Array<InputMaybe<TicketQualityControlValueType>>>
    related?: InputMaybe<TicketWhereInput>
    related_is_null?: InputMaybe<Scalars['Boolean']['input']>
    reviewComment?: InputMaybe<Scalars['String']['input']>
    reviewComment_contains?: InputMaybe<Scalars['String']['input']>
    reviewComment_contains_i?: InputMaybe<Scalars['String']['input']>
    reviewComment_ends_with?: InputMaybe<Scalars['String']['input']>
    reviewComment_ends_with_i?: InputMaybe<Scalars['String']['input']>
    reviewComment_i?: InputMaybe<Scalars['String']['input']>
    reviewComment_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    reviewComment_not?: InputMaybe<Scalars['String']['input']>
    reviewComment_not_contains?: InputMaybe<Scalars['String']['input']>
    reviewComment_not_contains_i?: InputMaybe<Scalars['String']['input']>
    reviewComment_not_ends_with?: InputMaybe<Scalars['String']['input']>
    reviewComment_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    reviewComment_not_i?: InputMaybe<Scalars['String']['input']>
    reviewComment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    reviewComment_not_starts_with?: InputMaybe<Scalars['String']['input']>
    reviewComment_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    reviewComment_starts_with?: InputMaybe<Scalars['String']['input']>
    reviewComment_starts_with_i?: InputMaybe<Scalars['String']['input']>
    reviewValue?: InputMaybe<TicketReviewValueType>
    reviewValue_in?: InputMaybe<Array<InputMaybe<TicketReviewValueType>>>
    reviewValue_not?: InputMaybe<TicketReviewValueType>
    reviewValue_not_in?: InputMaybe<Array<InputMaybe<TicketReviewValueType>>>
    sectionName?: InputMaybe<Scalars['String']['input']>
    sectionName_contains?: InputMaybe<Scalars['String']['input']>
    sectionName_contains_i?: InputMaybe<Scalars['String']['input']>
    sectionName_ends_with?: InputMaybe<Scalars['String']['input']>
    sectionName_ends_with_i?: InputMaybe<Scalars['String']['input']>
    sectionName_i?: InputMaybe<Scalars['String']['input']>
    sectionName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sectionName_not?: InputMaybe<Scalars['String']['input']>
    sectionName_not_contains?: InputMaybe<Scalars['String']['input']>
    sectionName_not_contains_i?: InputMaybe<Scalars['String']['input']>
    sectionName_not_ends_with?: InputMaybe<Scalars['String']['input']>
    sectionName_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    sectionName_not_i?: InputMaybe<Scalars['String']['input']>
    sectionName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sectionName_not_starts_with?: InputMaybe<Scalars['String']['input']>
    sectionName_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    sectionName_starts_with?: InputMaybe<Scalars['String']['input']>
    sectionName_starts_with_i?: InputMaybe<Scalars['String']['input']>
    sectionType?: InputMaybe<TicketSectionTypeType>
    sectionType_in?: InputMaybe<Array<InputMaybe<TicketSectionTypeType>>>
    sectionType_not?: InputMaybe<TicketSectionTypeType>
    sectionType_not_in?: InputMaybe<Array<InputMaybe<TicketSectionTypeType>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    source?: InputMaybe<TicketSourceWhereInput>
    sourceMeta?: InputMaybe<Scalars['JSON']['input']>
    sourceMeta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sourceMeta_not?: InputMaybe<Scalars['JSON']['input']>
    sourceMeta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    source_is_null?: InputMaybe<Scalars['Boolean']['input']>
    status?: InputMaybe<TicketStatusWhereInput>
    statusReason?: InputMaybe<Scalars['String']['input']>
    statusReason_contains?: InputMaybe<Scalars['String']['input']>
    statusReason_contains_i?: InputMaybe<Scalars['String']['input']>
    statusReason_ends_with?: InputMaybe<Scalars['String']['input']>
    statusReason_ends_with_i?: InputMaybe<Scalars['String']['input']>
    statusReason_i?: InputMaybe<Scalars['String']['input']>
    statusReason_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    statusReason_not?: InputMaybe<Scalars['String']['input']>
    statusReason_not_contains?: InputMaybe<Scalars['String']['input']>
    statusReason_not_contains_i?: InputMaybe<Scalars['String']['input']>
    statusReason_not_ends_with?: InputMaybe<Scalars['String']['input']>
    statusReason_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    statusReason_not_i?: InputMaybe<Scalars['String']['input']>
    statusReason_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    statusReason_not_starts_with?: InputMaybe<Scalars['String']['input']>
    statusReason_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    statusReason_starts_with?: InputMaybe<Scalars['String']['input']>
    statusReason_starts_with_i?: InputMaybe<Scalars['String']['input']>
    statusReopenedCounter?: InputMaybe<Scalars['Int']['input']>
    statusReopenedCounter_gt?: InputMaybe<Scalars['Int']['input']>
    statusReopenedCounter_gte?: InputMaybe<Scalars['Int']['input']>
    statusReopenedCounter_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    statusReopenedCounter_lt?: InputMaybe<Scalars['Int']['input']>
    statusReopenedCounter_lte?: InputMaybe<Scalars['Int']['input']>
    statusReopenedCounter_not?: InputMaybe<Scalars['Int']['input']>
    statusReopenedCounter_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    statusUpdatedAt?: InputMaybe<Scalars['String']['input']>
    statusUpdatedAt_gt?: InputMaybe<Scalars['String']['input']>
    statusUpdatedAt_gte?: InputMaybe<Scalars['String']['input']>
    statusUpdatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    statusUpdatedAt_lt?: InputMaybe<Scalars['String']['input']>
    statusUpdatedAt_lte?: InputMaybe<Scalars['String']['input']>
    statusUpdatedAt_not?: InputMaybe<Scalars['String']['input']>
    statusUpdatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status_is_null?: InputMaybe<Scalars['Boolean']['input']>
    unitName?: InputMaybe<Scalars['String']['input']>
    unitName_contains?: InputMaybe<Scalars['String']['input']>
    unitName_contains_i?: InputMaybe<Scalars['String']['input']>
    unitName_ends_with?: InputMaybe<Scalars['String']['input']>
    unitName_ends_with_i?: InputMaybe<Scalars['String']['input']>
    unitName_i?: InputMaybe<Scalars['String']['input']>
    unitName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitName_not?: InputMaybe<Scalars['String']['input']>
    unitName_not_contains?: InputMaybe<Scalars['String']['input']>
    unitName_not_contains_i?: InputMaybe<Scalars['String']['input']>
    unitName_not_ends_with?: InputMaybe<Scalars['String']['input']>
    unitName_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    unitName_not_i?: InputMaybe<Scalars['String']['input']>
    unitName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    unitName_not_starts_with?: InputMaybe<Scalars['String']['input']>
    unitName_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    unitName_starts_with?: InputMaybe<Scalars['String']['input']>
    unitName_starts_with_i?: InputMaybe<Scalars['String']['input']>
    unitType?: InputMaybe<TicketUnitTypeType>
    unitType_in?: InputMaybe<Array<InputMaybe<TicketUnitTypeType>>>
    unitType_not?: InputMaybe<TicketUnitTypeType>
    unitType_not_in?: InputMaybe<Array<InputMaybe<TicketUnitTypeType>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type TicketWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type TicketsCreateInput = {
    data?: InputMaybe<TicketCreateInput>
}

export type TicketsUpdateInput = {
    data?: InputMaybe<TicketUpdateInput>
    id: Scalars['ID']['input']
}

/**  One of the few steps in the tour for the organization  */
export type TourStep = {
    __typename?: 'TourStep'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TourStep List config, or
   *  2. As an alias to the field set on 'labelField' in the TourStep List config, or
   *  3. As an alias to a 'name' field on the TourStep List (if one exists), or
   *  4. As an alias to the 'id' field on the TourStep List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['String']['output']>
    /**  Used for sorting  */
    order?: Maybe<Scalars['Int']['output']>
    /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
    organization?: Maybe<Organization>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Step status, may be todo, waiting, completed and disabled  */
    status?: Maybe<TourStepStatusType>
    /**  Type of the tour step. It is need to understand what this step is responsible for  */
    type?: Maybe<TourStepTypeType>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type TourStepCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    order?: InputMaybe<Scalars['Int']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    status?: InputMaybe<TourStepStatusType>
    type?: InputMaybe<TourStepTypeType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type TourStepHistoryRecord = {
    __typename?: 'TourStepHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TourStepHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the TourStepHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the TourStepHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the TourStepHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<TourStepHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['JSON']['output']>
    order?: Maybe<Scalars['Int']['output']>
    organization?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    status?: Maybe<Scalars['String']['output']>
    type?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type TourStepHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<TourStepHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    order?: InputMaybe<Scalars['Int']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    type?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum TourStepHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type TourStepHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<TourStepHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    order?: InputMaybe<Scalars['Int']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    status?: InputMaybe<Scalars['String']['input']>
    type?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type TourStepHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<TourStepHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<TourStepHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<TourStepHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<TourStepHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<TourStepHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<TourStepHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    order?: InputMaybe<Scalars['Int']['input']>
    order_gt?: InputMaybe<Scalars['Int']['input']>
    order_gte?: InputMaybe<Scalars['Int']['input']>
    order_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    order_lt?: InputMaybe<Scalars['Int']['input']>
    order_lte?: InputMaybe<Scalars['Int']['input']>
    order_not?: InputMaybe<Scalars['Int']['input']>
    order_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    status?: InputMaybe<Scalars['String']['input']>
    status_contains?: InputMaybe<Scalars['String']['input']>
    status_contains_i?: InputMaybe<Scalars['String']['input']>
    status_ends_with?: InputMaybe<Scalars['String']['input']>
    status_ends_with_i?: InputMaybe<Scalars['String']['input']>
    status_i?: InputMaybe<Scalars['String']['input']>
    status_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status_not?: InputMaybe<Scalars['String']['input']>
    status_not_contains?: InputMaybe<Scalars['String']['input']>
    status_not_contains_i?: InputMaybe<Scalars['String']['input']>
    status_not_ends_with?: InputMaybe<Scalars['String']['input']>
    status_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    status_not_i?: InputMaybe<Scalars['String']['input']>
    status_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    status_not_starts_with?: InputMaybe<Scalars['String']['input']>
    status_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    status_starts_with?: InputMaybe<Scalars['String']['input']>
    status_starts_with_i?: InputMaybe<Scalars['String']['input']>
    type?: InputMaybe<Scalars['String']['input']>
    type_contains?: InputMaybe<Scalars['String']['input']>
    type_contains_i?: InputMaybe<Scalars['String']['input']>
    type_ends_with?: InputMaybe<Scalars['String']['input']>
    type_ends_with_i?: InputMaybe<Scalars['String']['input']>
    type_i?: InputMaybe<Scalars['String']['input']>
    type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    type_not?: InputMaybe<Scalars['String']['input']>
    type_not_contains?: InputMaybe<Scalars['String']['input']>
    type_not_contains_i?: InputMaybe<Scalars['String']['input']>
    type_not_ends_with?: InputMaybe<Scalars['String']['input']>
    type_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    type_not_i?: InputMaybe<Scalars['String']['input']>
    type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    type_not_starts_with?: InputMaybe<Scalars['String']['input']>
    type_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    type_starts_with?: InputMaybe<Scalars['String']['input']>
    type_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type TourStepHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type TourStepHistoryRecordsCreateInput = {
    data?: InputMaybe<TourStepHistoryRecordCreateInput>
}

export type TourStepHistoryRecordsUpdateInput = {
    data?: InputMaybe<TourStepHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export enum TourStepStatusType {
    Completed = 'completed',
    Disabled = 'disabled',
    Todo = 'todo',
    Waiting = 'waiting',
}

export enum TourStepTypeType {
    Billing = 'billing',
    CreateMeter = 'createMeter',
    CreateMeterReadings = 'createMeterReadings',
    CreateNews = 'createNews',
    CreateProperty = 'createProperty',
    CreatePropertyMap = 'createPropertyMap',
    CreateTicket = 'createTicket',
    Meter = 'meter',
    Resident = 'resident',
    Ticket = 'ticket',
    UploadReceipts = 'uploadReceipts',
    ViewResidentsAppGuide = 'viewResidentsAppGuide',
}

export type TourStepUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    order?: InputMaybe<Scalars['Int']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    status?: InputMaybe<TourStepStatusType>
    type?: InputMaybe<TourStepTypeType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type TourStepWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<TourStepWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<TourStepWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    order?: InputMaybe<Scalars['Int']['input']>
    order_gt?: InputMaybe<Scalars['Int']['input']>
    order_gte?: InputMaybe<Scalars['Int']['input']>
    order_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    order_lt?: InputMaybe<Scalars['Int']['input']>
    order_lte?: InputMaybe<Scalars['Int']['input']>
    order_not?: InputMaybe<Scalars['Int']['input']>
    order_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    status?: InputMaybe<TourStepStatusType>
    status_in?: InputMaybe<Array<InputMaybe<TourStepStatusType>>>
    status_not?: InputMaybe<TourStepStatusType>
    status_not_in?: InputMaybe<Array<InputMaybe<TourStepStatusType>>>
    type?: InputMaybe<TourStepTypeType>
    type_in?: InputMaybe<Array<InputMaybe<TourStepTypeType>>>
    type_not?: InputMaybe<TourStepTypeType>
    type_not_in?: InputMaybe<Array<InputMaybe<TourStepTypeType>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type TourStepWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type TourStepsCreateInput = {
    data?: InputMaybe<TourStepCreateInput>
}

export type TourStepsUpdateInput = {
    data?: InputMaybe<TourStepUpdateInput>
    id: Scalars['ID']['input']
}

/**  Individual / person / service account / impersonal company account. Used primarily for authorization purposes, optimized access control with checking of `type` field, tracking authority of performed CRUD operations. Think of `User` as a technical entity, not a business actor. Business actor entities are Resident, OrganizationEmployee etc., — they are participating in high-level business scenarios and have connected to `User`. Almost everyting, created in the system, ends up to `User` as a source of action.  */
export type User = {
    __typename?: 'User'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the User List config, or
   *  2. As an alias to the field set on 'labelField' in the User List config, or
   *  3. As an alias to a 'name' field on the User List (if one exists), or
   *  4. As an alias to the 'id' field on the User List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  User loaded avatar image  */
    avatar?: Maybe<File>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    /**  Override for business access rights for list or field of provided schema  */
    customAccess?: Maybe<CustomAccess>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  Email. Transformed to lower case  */
    email?: Maybe<Scalars['String']['output']>
    hasEmail?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    /**  Superuser access to service data  */
    isAdmin?: Maybe<Scalars['Boolean']['output']>
    /**  Email verification flag. User verify email by access to secret link  */
    isEmailVerified?: Maybe<Scalars['Boolean']['output']>
    /**  Phone verification flag. User verify phone by access to secret sms message  */
    isPhoneVerified?: Maybe<Scalars['Boolean']['output']>
    /**  Can access to "/admin/" panel. And do support tasks  */
    isSupport?: Maybe<Scalars['Boolean']['output']>
    /**  The user's locale  */
    locale?: Maybe<UserLocaleType>
    /**  User metadata. Example: `city`, `country`, ...  */
    meta?: Maybe<Scalars['JSON']['output']>
    /**  Name. If impersonal account should be a company name  */
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Password. Update only  */
    password_is_set?: Maybe<Scalars['Boolean']['output']>
    /**  Phone. In international E.164 format without spaces  */
    phone?: Maybe<Scalars['String']['output']>
    /**  A set of permissions that allow the user to directly read or manage certain schemas as well as run certain mutations.  */
    rightsSet?: Maybe<UserRightsSet>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Show global hints in CRM or not  */
    showGlobalHints?: Maybe<Scalars['Boolean']['output']>
    /**  Field that allows you to distinguish CRM users from mobile app users  */
    type?: Maybe<UserTypeType>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}


/**  Individual / person / service account / impersonal company account. Used primarily for authorization purposes, optimized access control with checking of `type` field, tracking authority of performed CRUD operations. Think of `User` as a technical entity, not a business actor. Business actor entities are Resident, OrganizationEmployee etc., — they are participating in high-level business scenarios and have connected to `User`. Almost everyting, created in the system, ends up to `User` as a source of action.  */
export type UserHasEmailArgs = {
    formatAs?: InputMaybe<Scalars['String']['input']>
}

export type UserCreateInput = {
    avatar?: InputMaybe<Scalars['Upload']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    customAccess?: InputMaybe<CustomAccessInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    email?: InputMaybe<Scalars['String']['input']>
    isAdmin?: InputMaybe<Scalars['Boolean']['input']>
    isEmailVerified?: InputMaybe<Scalars['Boolean']['input']>
    isPhoneVerified?: InputMaybe<Scalars['Boolean']['input']>
    isSupport?: InputMaybe<Scalars['Boolean']['input']>
    locale?: InputMaybe<UserLocaleType>
    meta?: InputMaybe<Scalars['JSON']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    password?: InputMaybe<Scalars['String']['input']>
    phone?: InputMaybe<Scalars['String']['input']>
    rightsSet?: InputMaybe<UserRightsSetRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    showGlobalHints?: InputMaybe<Scalars['Boolean']['input']>
    type?: InputMaybe<UserTypeType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type UserExternalIdentitiesCreateInput = {
    data?: InputMaybe<UserExternalIdentityCreateInput>
}

export type UserExternalIdentitiesUpdateInput = {
    data?: InputMaybe<UserExternalIdentityUpdateInput>
    id: Scalars['ID']['input']
}

/**  Individual user external identity link. Used primarily for authorization and linking to external identity provider purposes. Think of `User` as a technical entity, not a business actor.  */
export type UserExternalIdentity = {
    __typename?: 'UserExternalIdentity'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the UserExternalIdentity List config, or
   *  2. As an alias to the field set on 'labelField' in the UserExternalIdentity List config, or
   *  3. As an alias to a 'name' field on the UserExternalIdentity List (if one exists), or
   *  4. As an alias to the 'id' field on the UserExternalIdentity List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  External identity id. The value of this field should be populated from an external identity provider  */
    identityId?: Maybe<Scalars['String']['output']>
    /**  The type of external identity that was a source for this link  */
    identityType?: Maybe<UserExternalIdentityIdentityTypeType>
    /**  External identity provider user metadata  */
    meta?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    /**  Link to user  */
    user?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type UserExternalIdentityCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    identityId?: InputMaybe<Scalars['String']['input']>
    identityType?: InputMaybe<UserExternalIdentityIdentityTypeType>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type UserExternalIdentityHistoryRecord = {
    __typename?: 'UserExternalIdentityHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the UserExternalIdentityHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the UserExternalIdentityHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the UserExternalIdentityHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the UserExternalIdentityHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<UserExternalIdentityHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    identityId?: Maybe<Scalars['String']['output']>
    identityType?: Maybe<Scalars['String']['output']>
    meta?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    user?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type UserExternalIdentityHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<UserExternalIdentityHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    identityId?: InputMaybe<Scalars['String']['input']>
    identityType?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum UserExternalIdentityHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type UserExternalIdentityHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<UserExternalIdentityHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    identityId?: InputMaybe<Scalars['String']['input']>
    identityType?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type UserExternalIdentityHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<UserExternalIdentityHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<UserExternalIdentityHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<UserExternalIdentityHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<UserExternalIdentityHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<UserExternalIdentityHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<UserExternalIdentityHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    identityId?: InputMaybe<Scalars['String']['input']>
    identityId_contains?: InputMaybe<Scalars['String']['input']>
    identityId_contains_i?: InputMaybe<Scalars['String']['input']>
    identityId_ends_with?: InputMaybe<Scalars['String']['input']>
    identityId_ends_with_i?: InputMaybe<Scalars['String']['input']>
    identityId_i?: InputMaybe<Scalars['String']['input']>
    identityId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    identityId_not?: InputMaybe<Scalars['String']['input']>
    identityId_not_contains?: InputMaybe<Scalars['String']['input']>
    identityId_not_contains_i?: InputMaybe<Scalars['String']['input']>
    identityId_not_ends_with?: InputMaybe<Scalars['String']['input']>
    identityId_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    identityId_not_i?: InputMaybe<Scalars['String']['input']>
    identityId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    identityId_not_starts_with?: InputMaybe<Scalars['String']['input']>
    identityId_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    identityId_starts_with?: InputMaybe<Scalars['String']['input']>
    identityId_starts_with_i?: InputMaybe<Scalars['String']['input']>
    identityType?: InputMaybe<Scalars['String']['input']>
    identityType_contains?: InputMaybe<Scalars['String']['input']>
    identityType_contains_i?: InputMaybe<Scalars['String']['input']>
    identityType_ends_with?: InputMaybe<Scalars['String']['input']>
    identityType_ends_with_i?: InputMaybe<Scalars['String']['input']>
    identityType_i?: InputMaybe<Scalars['String']['input']>
    identityType_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    identityType_not?: InputMaybe<Scalars['String']['input']>
    identityType_not_contains?: InputMaybe<Scalars['String']['input']>
    identityType_not_contains_i?: InputMaybe<Scalars['String']['input']>
    identityType_not_ends_with?: InputMaybe<Scalars['String']['input']>
    identityType_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    identityType_not_i?: InputMaybe<Scalars['String']['input']>
    identityType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    identityType_not_starts_with?: InputMaybe<Scalars['String']['input']>
    identityType_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    identityType_starts_with?: InputMaybe<Scalars['String']['input']>
    identityType_starts_with_i?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user?: InputMaybe<Scalars['String']['input']>
    user_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user_not?: InputMaybe<Scalars['String']['input']>
    user_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type UserExternalIdentityHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type UserExternalIdentityHistoryRecordsCreateInput = {
    data?: InputMaybe<UserExternalIdentityHistoryRecordCreateInput>
}

export type UserExternalIdentityHistoryRecordsUpdateInput = {
    data?: InputMaybe<UserExternalIdentityHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export enum UserExternalIdentityIdentityTypeType {
    AppleId = 'apple_id',
    Sbbol = 'sbbol',
    SberId = 'sber_id',
}

export type UserExternalIdentityUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    identityId?: InputMaybe<Scalars['String']['input']>
    identityType?: InputMaybe<UserExternalIdentityIdentityTypeType>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type UserExternalIdentityWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<UserExternalIdentityWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<UserExternalIdentityWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    identityId?: InputMaybe<Scalars['String']['input']>
    identityId_contains?: InputMaybe<Scalars['String']['input']>
    identityId_contains_i?: InputMaybe<Scalars['String']['input']>
    identityId_ends_with?: InputMaybe<Scalars['String']['input']>
    identityId_ends_with_i?: InputMaybe<Scalars['String']['input']>
    identityId_i?: InputMaybe<Scalars['String']['input']>
    identityId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    identityId_not?: InputMaybe<Scalars['String']['input']>
    identityId_not_contains?: InputMaybe<Scalars['String']['input']>
    identityId_not_contains_i?: InputMaybe<Scalars['String']['input']>
    identityId_not_ends_with?: InputMaybe<Scalars['String']['input']>
    identityId_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    identityId_not_i?: InputMaybe<Scalars['String']['input']>
    identityId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    identityId_not_starts_with?: InputMaybe<Scalars['String']['input']>
    identityId_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    identityId_starts_with?: InputMaybe<Scalars['String']['input']>
    identityId_starts_with_i?: InputMaybe<Scalars['String']['input']>
    identityType?: InputMaybe<UserExternalIdentityIdentityTypeType>
    identityType_in?: InputMaybe<Array<InputMaybe<UserExternalIdentityIdentityTypeType>>>
    identityType_not?: InputMaybe<UserExternalIdentityIdentityTypeType>
    identityType_not_in?: InputMaybe<Array<InputMaybe<UserExternalIdentityIdentityTypeType>>>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    user?: InputMaybe<UserWhereInput>
    user_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type UserExternalIdentityWhereUniqueInput = {
    id: Scalars['ID']['input']
}

/**  Ticket that user has added to favorites  */
export type UserFavoriteTicket = {
    __typename?: 'UserFavoriteTicket'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the UserFavoriteTicket List config, or
   *  2. As an alias to the field set on 'labelField' in the UserFavoriteTicket List config, or
   *  3. As an alias to a 'name' field on the UserFavoriteTicket List (if one exists), or
   *  4. As an alias to the 'id' field on the UserFavoriteTicket List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['String']['output']>
    /**  Ref to the organization. It is filled in on the server and is read-only  */
    organization?: Maybe<Organization>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    ticket?: Maybe<Ticket>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    user?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type UserFavoriteTicketCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    ticket?: InputMaybe<TicketRelateToOneInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type UserFavoriteTicketHistoryRecord = {
    __typename?: 'UserFavoriteTicketHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the UserFavoriteTicketHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the UserFavoriteTicketHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the UserFavoriteTicketHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the UserFavoriteTicketHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<UserFavoriteTicketHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    ticket?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    user?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type UserFavoriteTicketHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<UserFavoriteTicketHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    ticket?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum UserFavoriteTicketHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type UserFavoriteTicketHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<UserFavoriteTicketHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    ticket?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type UserFavoriteTicketHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<UserFavoriteTicketHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<UserFavoriteTicketHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<UserFavoriteTicketHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<UserFavoriteTicketHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<UserFavoriteTicketHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<UserFavoriteTicketHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    ticket?: InputMaybe<Scalars['String']['input']>
    ticket_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    ticket_not?: InputMaybe<Scalars['String']['input']>
    ticket_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user?: InputMaybe<Scalars['String']['input']>
    user_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user_not?: InputMaybe<Scalars['String']['input']>
    user_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type UserFavoriteTicketHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type UserFavoriteTicketHistoryRecordsCreateInput = {
    data?: InputMaybe<UserFavoriteTicketHistoryRecordCreateInput>
}

export type UserFavoriteTicketHistoryRecordsUpdateInput = {
    data?: InputMaybe<UserFavoriteTicketHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type UserFavoriteTicketUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    ticket?: InputMaybe<TicketRelateToOneInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type UserFavoriteTicketWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<UserFavoriteTicketWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<UserFavoriteTicketWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    ticket?: InputMaybe<TicketWhereInput>
    ticket_is_null?: InputMaybe<Scalars['Boolean']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    user?: InputMaybe<UserWhereInput>
    user_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type UserFavoriteTicketWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type UserFavoriteTicketsCreateInput = {
    data?: InputMaybe<UserFavoriteTicketCreateInput>
}

export type UserFavoriteTicketsUpdateInput = {
    data?: InputMaybe<UserFavoriteTicketUpdateInput>
    id: Scalars['ID']['input']
}

/**  Request from the user to help him with some functionality  */
export type UserHelpRequest = {
    __typename?: 'UserHelpRequest'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the UserHelpRequest List config, or
   *  2. As an alias to the field set on 'labelField' in the UserHelpRequest List config, or
   *  3. As an alias to a 'name' field on the UserHelpRequest List (if one exists), or
   *  4. As an alias to the 'id' field on the UserHelpRequest List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  Shows if the request is ready to send. False value can be, for example, if files are not synced with help request yet  */
    isReadyToSend?: Maybe<Scalars['Boolean']['output']>
    /**  Additional info about request. May contain information about file urls, page where user made request or import type  */
    meta?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
    organization?: Maybe<Organization>
    /**  Specified phone in request for callback  */
    phone?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Type of request. It's can be, for example, request for callback or request to help with import  */
    type?: Maybe<UserHelpRequestTypeType>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type UserHelpRequestCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    isReadyToSend?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    phone?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    type?: InputMaybe<UserHelpRequestTypeType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  File related to user help request  */
export type UserHelpRequestFile = {
    __typename?: 'UserHelpRequestFile'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the UserHelpRequestFile List config, or
   *  2. As an alias to the field set on 'labelField' in the UserHelpRequestFile List config, or
   *  3. As an alias to a 'name' field on the UserHelpRequestFile List (if one exists), or
   *  4. As an alias to the 'id' field on the UserHelpRequestFile List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    file?: Maybe<File>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    userHelpRequest?: Maybe<UserHelpRequest>
    v?: Maybe<Scalars['Int']['output']>
}

export type UserHelpRequestFileCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['Upload']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    userHelpRequest?: InputMaybe<UserHelpRequestRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type UserHelpRequestFileHistoryRecord = {
    __typename?: 'UserHelpRequestFileHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the UserHelpRequestFileHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the UserHelpRequestFileHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the UserHelpRequestFileHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the UserHelpRequestFileHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    file?: Maybe<Scalars['JSON']['output']>
    history_action?: Maybe<UserHelpRequestFileHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    userHelpRequest?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type UserHelpRequestFileHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['JSON']['input']>
    history_action?: InputMaybe<UserHelpRequestFileHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    userHelpRequest?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum UserHelpRequestFileHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type UserHelpRequestFileHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['JSON']['input']>
    history_action?: InputMaybe<UserHelpRequestFileHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    userHelpRequest?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type UserHelpRequestFileHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<UserHelpRequestFileHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<UserHelpRequestFileHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    file?: InputMaybe<Scalars['JSON']['input']>
    file_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    file_not?: InputMaybe<Scalars['JSON']['input']>
    file_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    history_action?: InputMaybe<UserHelpRequestFileHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<UserHelpRequestFileHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<UserHelpRequestFileHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<UserHelpRequestFileHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    userHelpRequest?: InputMaybe<Scalars['String']['input']>
    userHelpRequest_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    userHelpRequest_not?: InputMaybe<Scalars['String']['input']>
    userHelpRequest_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type UserHelpRequestFileHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type UserHelpRequestFileHistoryRecordsCreateInput = {
    data?: InputMaybe<UserHelpRequestFileHistoryRecordCreateInput>
}

export type UserHelpRequestFileHistoryRecordsUpdateInput = {
    data?: InputMaybe<UserHelpRequestFileHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type UserHelpRequestFileUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    file?: InputMaybe<Scalars['Upload']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    userHelpRequest?: InputMaybe<UserHelpRequestRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type UserHelpRequestFileWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<UserHelpRequestFileWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<UserHelpRequestFileWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    file?: InputMaybe<Scalars['String']['input']>
    file_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    file_not?: InputMaybe<Scalars['String']['input']>
    file_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    userHelpRequest?: InputMaybe<UserHelpRequestWhereInput>
    userHelpRequest_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type UserHelpRequestFileWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type UserHelpRequestFilesCreateInput = {
    data?: InputMaybe<UserHelpRequestFileCreateInput>
}

export type UserHelpRequestFilesUpdateInput = {
    data?: InputMaybe<UserHelpRequestFileUpdateInput>
    id: Scalars['ID']['input']
}

/**  A keystone list  */
export type UserHelpRequestHistoryRecord = {
    __typename?: 'UserHelpRequestHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the UserHelpRequestHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the UserHelpRequestHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the UserHelpRequestHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the UserHelpRequestHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<UserHelpRequestHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    isReadyToSend?: Maybe<Scalars['Boolean']['output']>
    meta?: Maybe<Scalars['JSON']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    organization?: Maybe<Scalars['String']['output']>
    phone?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    type?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type UserHelpRequestHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<UserHelpRequestHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    isReadyToSend?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    phone?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    type?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum UserHelpRequestHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type UserHelpRequestHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<UserHelpRequestHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    isReadyToSend?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    organization?: InputMaybe<Scalars['String']['input']>
    phone?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    type?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type UserHelpRequestHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<UserHelpRequestHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<UserHelpRequestHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<UserHelpRequestHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<UserHelpRequestHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<UserHelpRequestHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<UserHelpRequestHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    isReadyToSend?: InputMaybe<Scalars['Boolean']['input']>
    isReadyToSend_not?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    organization?: InputMaybe<Scalars['String']['input']>
    organization_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization_not?: InputMaybe<Scalars['String']['input']>
    organization_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phone?: InputMaybe<Scalars['String']['input']>
    phone_contains?: InputMaybe<Scalars['String']['input']>
    phone_contains_i?: InputMaybe<Scalars['String']['input']>
    phone_ends_with?: InputMaybe<Scalars['String']['input']>
    phone_ends_with_i?: InputMaybe<Scalars['String']['input']>
    phone_i?: InputMaybe<Scalars['String']['input']>
    phone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phone_not?: InputMaybe<Scalars['String']['input']>
    phone_not_contains?: InputMaybe<Scalars['String']['input']>
    phone_not_contains_i?: InputMaybe<Scalars['String']['input']>
    phone_not_ends_with?: InputMaybe<Scalars['String']['input']>
    phone_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    phone_not_i?: InputMaybe<Scalars['String']['input']>
    phone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phone_not_starts_with?: InputMaybe<Scalars['String']['input']>
    phone_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    phone_starts_with?: InputMaybe<Scalars['String']['input']>
    phone_starts_with_i?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    type?: InputMaybe<Scalars['String']['input']>
    type_contains?: InputMaybe<Scalars['String']['input']>
    type_contains_i?: InputMaybe<Scalars['String']['input']>
    type_ends_with?: InputMaybe<Scalars['String']['input']>
    type_ends_with_i?: InputMaybe<Scalars['String']['input']>
    type_i?: InputMaybe<Scalars['String']['input']>
    type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    type_not?: InputMaybe<Scalars['String']['input']>
    type_not_contains?: InputMaybe<Scalars['String']['input']>
    type_not_contains_i?: InputMaybe<Scalars['String']['input']>
    type_not_ends_with?: InputMaybe<Scalars['String']['input']>
    type_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    type_not_i?: InputMaybe<Scalars['String']['input']>
    type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    type_not_starts_with?: InputMaybe<Scalars['String']['input']>
    type_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    type_starts_with?: InputMaybe<Scalars['String']['input']>
    type_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type UserHelpRequestHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type UserHelpRequestHistoryRecordsCreateInput = {
    data?: InputMaybe<UserHelpRequestHistoryRecordCreateInput>
}

export type UserHelpRequestHistoryRecordsUpdateInput = {
    data?: InputMaybe<UserHelpRequestHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type UserHelpRequestRelateToOneInput = {
    connect?: InputMaybe<UserHelpRequestWhereUniqueInput>
    create?: InputMaybe<UserHelpRequestCreateInput>
    disconnect?: InputMaybe<UserHelpRequestWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export enum UserHelpRequestTypeType {
    Callback = 'callback',
    ImportFile = 'importFile',
}

export type UserHelpRequestUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    isReadyToSend?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    organization?: InputMaybe<OrganizationRelateToOneInput>
    phone?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    type?: InputMaybe<UserHelpRequestTypeType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type UserHelpRequestWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<UserHelpRequestWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<UserHelpRequestWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    isReadyToSend?: InputMaybe<Scalars['Boolean']['input']>
    isReadyToSend_not?: InputMaybe<Scalars['Boolean']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    organization?: InputMaybe<OrganizationWhereInput>
    organization_is_null?: InputMaybe<Scalars['Boolean']['input']>
    phone?: InputMaybe<Scalars['String']['input']>
    phone_contains?: InputMaybe<Scalars['String']['input']>
    phone_contains_i?: InputMaybe<Scalars['String']['input']>
    phone_ends_with?: InputMaybe<Scalars['String']['input']>
    phone_ends_with_i?: InputMaybe<Scalars['String']['input']>
    phone_i?: InputMaybe<Scalars['String']['input']>
    phone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phone_not?: InputMaybe<Scalars['String']['input']>
    phone_not_contains?: InputMaybe<Scalars['String']['input']>
    phone_not_contains_i?: InputMaybe<Scalars['String']['input']>
    phone_not_ends_with?: InputMaybe<Scalars['String']['input']>
    phone_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    phone_not_i?: InputMaybe<Scalars['String']['input']>
    phone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phone_not_starts_with?: InputMaybe<Scalars['String']['input']>
    phone_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    phone_starts_with?: InputMaybe<Scalars['String']['input']>
    phone_starts_with_i?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    type?: InputMaybe<UserHelpRequestTypeType>
    type_in?: InputMaybe<Array<InputMaybe<UserHelpRequestTypeType>>>
    type_not?: InputMaybe<UserHelpRequestTypeType>
    type_not_in?: InputMaybe<Array<InputMaybe<UserHelpRequestTypeType>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type UserHelpRequestWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type UserHelpRequestsCreateInput = {
    data?: InputMaybe<UserHelpRequestCreateInput>
}

export type UserHelpRequestsUpdateInput = {
    data?: InputMaybe<UserHelpRequestUpdateInput>
    id: Scalars['ID']['input']
}

/**  A keystone list  */
export type UserHistoryRecord = {
    __typename?: 'UserHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the UserHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the UserHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the UserHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the UserHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    avatar?: Maybe<Scalars['JSON']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    customAccess?: Maybe<Scalars['JSON']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    email?: Maybe<Scalars['String']['output']>
    history_action?: Maybe<UserHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    isAdmin?: Maybe<Scalars['Boolean']['output']>
    isEmailVerified?: Maybe<Scalars['Boolean']['output']>
    isPhoneVerified?: Maybe<Scalars['Boolean']['output']>
    isSupport?: Maybe<Scalars['Boolean']['output']>
    locale?: Maybe<Scalars['String']['output']>
    meta?: Maybe<Scalars['JSON']['output']>
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    password?: Maybe<Scalars['String']['output']>
    phone?: Maybe<Scalars['String']['output']>
    rightsSet?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    showGlobalHints?: Maybe<Scalars['Boolean']['output']>
    type?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type UserHistoryRecordCreateInput = {
    avatar?: InputMaybe<Scalars['JSON']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    customAccess?: InputMaybe<Scalars['JSON']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    email?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<UserHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    isAdmin?: InputMaybe<Scalars['Boolean']['input']>
    isEmailVerified?: InputMaybe<Scalars['Boolean']['input']>
    isPhoneVerified?: InputMaybe<Scalars['Boolean']['input']>
    isSupport?: InputMaybe<Scalars['Boolean']['input']>
    locale?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    password?: InputMaybe<Scalars['String']['input']>
    phone?: InputMaybe<Scalars['String']['input']>
    rightsSet?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    showGlobalHints?: InputMaybe<Scalars['Boolean']['input']>
    type?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum UserHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type UserHistoryRecordUpdateInput = {
    avatar?: InputMaybe<Scalars['JSON']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    customAccess?: InputMaybe<Scalars['JSON']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    email?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<UserHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    isAdmin?: InputMaybe<Scalars['Boolean']['input']>
    isEmailVerified?: InputMaybe<Scalars['Boolean']['input']>
    isPhoneVerified?: InputMaybe<Scalars['Boolean']['input']>
    isSupport?: InputMaybe<Scalars['Boolean']['input']>
    locale?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    password?: InputMaybe<Scalars['String']['input']>
    phone?: InputMaybe<Scalars['String']['input']>
    rightsSet?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    showGlobalHints?: InputMaybe<Scalars['Boolean']['input']>
    type?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type UserHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<UserHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<UserHistoryRecordWhereInput>>>
    avatar?: InputMaybe<Scalars['JSON']['input']>
    avatar_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    avatar_not?: InputMaybe<Scalars['JSON']['input']>
    avatar_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    customAccess?: InputMaybe<Scalars['JSON']['input']>
    customAccess_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    customAccess_not?: InputMaybe<Scalars['JSON']['input']>
    customAccess_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    email?: InputMaybe<Scalars['String']['input']>
    email_contains?: InputMaybe<Scalars['String']['input']>
    email_contains_i?: InputMaybe<Scalars['String']['input']>
    email_ends_with?: InputMaybe<Scalars['String']['input']>
    email_ends_with_i?: InputMaybe<Scalars['String']['input']>
    email_i?: InputMaybe<Scalars['String']['input']>
    email_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    email_not?: InputMaybe<Scalars['String']['input']>
    email_not_contains?: InputMaybe<Scalars['String']['input']>
    email_not_contains_i?: InputMaybe<Scalars['String']['input']>
    email_not_ends_with?: InputMaybe<Scalars['String']['input']>
    email_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    email_not_i?: InputMaybe<Scalars['String']['input']>
    email_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    email_not_starts_with?: InputMaybe<Scalars['String']['input']>
    email_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    email_starts_with?: InputMaybe<Scalars['String']['input']>
    email_starts_with_i?: InputMaybe<Scalars['String']['input']>
    history_action?: InputMaybe<UserHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<UserHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<UserHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<UserHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    isAdmin?: InputMaybe<Scalars['Boolean']['input']>
    isAdmin_not?: InputMaybe<Scalars['Boolean']['input']>
    isEmailVerified?: InputMaybe<Scalars['Boolean']['input']>
    isEmailVerified_not?: InputMaybe<Scalars['Boolean']['input']>
    isPhoneVerified?: InputMaybe<Scalars['Boolean']['input']>
    isPhoneVerified_not?: InputMaybe<Scalars['Boolean']['input']>
    isSupport?: InputMaybe<Scalars['Boolean']['input']>
    isSupport_not?: InputMaybe<Scalars['Boolean']['input']>
    locale?: InputMaybe<Scalars['String']['input']>
    locale_contains?: InputMaybe<Scalars['String']['input']>
    locale_contains_i?: InputMaybe<Scalars['String']['input']>
    locale_ends_with?: InputMaybe<Scalars['String']['input']>
    locale_ends_with_i?: InputMaybe<Scalars['String']['input']>
    locale_i?: InputMaybe<Scalars['String']['input']>
    locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    locale_not?: InputMaybe<Scalars['String']['input']>
    locale_not_contains?: InputMaybe<Scalars['String']['input']>
    locale_not_contains_i?: InputMaybe<Scalars['String']['input']>
    locale_not_ends_with?: InputMaybe<Scalars['String']['input']>
    locale_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    locale_not_i?: InputMaybe<Scalars['String']['input']>
    locale_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    locale_not_starts_with?: InputMaybe<Scalars['String']['input']>
    locale_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    locale_starts_with?: InputMaybe<Scalars['String']['input']>
    locale_starts_with_i?: InputMaybe<Scalars['String']['input']>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    password?: InputMaybe<Scalars['String']['input']>
    password_contains?: InputMaybe<Scalars['String']['input']>
    password_contains_i?: InputMaybe<Scalars['String']['input']>
    password_ends_with?: InputMaybe<Scalars['String']['input']>
    password_ends_with_i?: InputMaybe<Scalars['String']['input']>
    password_i?: InputMaybe<Scalars['String']['input']>
    password_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    password_not?: InputMaybe<Scalars['String']['input']>
    password_not_contains?: InputMaybe<Scalars['String']['input']>
    password_not_contains_i?: InputMaybe<Scalars['String']['input']>
    password_not_ends_with?: InputMaybe<Scalars['String']['input']>
    password_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    password_not_i?: InputMaybe<Scalars['String']['input']>
    password_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    password_not_starts_with?: InputMaybe<Scalars['String']['input']>
    password_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    password_starts_with?: InputMaybe<Scalars['String']['input']>
    password_starts_with_i?: InputMaybe<Scalars['String']['input']>
    phone?: InputMaybe<Scalars['String']['input']>
    phone_contains?: InputMaybe<Scalars['String']['input']>
    phone_contains_i?: InputMaybe<Scalars['String']['input']>
    phone_ends_with?: InputMaybe<Scalars['String']['input']>
    phone_ends_with_i?: InputMaybe<Scalars['String']['input']>
    phone_i?: InputMaybe<Scalars['String']['input']>
    phone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phone_not?: InputMaybe<Scalars['String']['input']>
    phone_not_contains?: InputMaybe<Scalars['String']['input']>
    phone_not_contains_i?: InputMaybe<Scalars['String']['input']>
    phone_not_ends_with?: InputMaybe<Scalars['String']['input']>
    phone_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    phone_not_i?: InputMaybe<Scalars['String']['input']>
    phone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phone_not_starts_with?: InputMaybe<Scalars['String']['input']>
    phone_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    phone_starts_with?: InputMaybe<Scalars['String']['input']>
    phone_starts_with_i?: InputMaybe<Scalars['String']['input']>
    rightsSet?: InputMaybe<Scalars['String']['input']>
    rightsSet_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    rightsSet_not?: InputMaybe<Scalars['String']['input']>
    rightsSet_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    showGlobalHints?: InputMaybe<Scalars['Boolean']['input']>
    showGlobalHints_not?: InputMaybe<Scalars['Boolean']['input']>
    type?: InputMaybe<Scalars['String']['input']>
    type_contains?: InputMaybe<Scalars['String']['input']>
    type_contains_i?: InputMaybe<Scalars['String']['input']>
    type_ends_with?: InputMaybe<Scalars['String']['input']>
    type_ends_with_i?: InputMaybe<Scalars['String']['input']>
    type_i?: InputMaybe<Scalars['String']['input']>
    type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    type_not?: InputMaybe<Scalars['String']['input']>
    type_not_contains?: InputMaybe<Scalars['String']['input']>
    type_not_contains_i?: InputMaybe<Scalars['String']['input']>
    type_not_ends_with?: InputMaybe<Scalars['String']['input']>
    type_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    type_not_i?: InputMaybe<Scalars['String']['input']>
    type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    type_not_starts_with?: InputMaybe<Scalars['String']['input']>
    type_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    type_starts_with?: InputMaybe<Scalars['String']['input']>
    type_starts_with_i?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type UserHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type UserHistoryRecordsCreateInput = {
    data?: InputMaybe<UserHistoryRecordCreateInput>
}

export type UserHistoryRecordsUpdateInput = {
    data?: InputMaybe<UserHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export enum UserLocaleType {
    En = 'en',
    Ru = 'ru',
}

export type UserRelateToOneInput = {
    connect?: InputMaybe<UserWhereUniqueInput>
    create?: InputMaybe<UserCreateInput>
    disconnect?: InputMaybe<UserWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

/**  A set of rights that allows one user or a group of users to gain direct access to objects of a particular schema and to perform certain mutations  */
export type UserRightsSet = {
    __typename?: 'UserRightsSet'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the UserRightsSet List config, or
   *  2. As an alias to the field set on 'labelField' in the UserRightsSet List config, or
   *  3. As an alias to a 'name' field on the UserRightsSet List (if one exists), or
   *  4. As an alias to the 'id' field on the UserRightsSet List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    /**  Enables a user with the given UserRightsSet to execute "registerNewServiceUser" query/mutation  */
    canExecuteRegisterNewServiceUser?: Maybe<Scalars['Boolean']['output']>
    /**  Enables a user with the given UserRightsSet to execute "sendMessage" query/mutation  */
    canExecuteSendMessage?: Maybe<Scalars['Boolean']['output']>
    /**  Enables a user with the given UserRightsSet to execute "_allBillingReceiptsSum" query/mutation  */
    canExecute_allBillingReceiptsSum?: Maybe<Scalars['Boolean']['output']>
    /**  Enables a user with the given UserRightsSet to execute "_allPaymentsSum" query/mutation  */
    canExecute_allPaymentsSum?: Maybe<Scalars['Boolean']['output']>
    /**  Enables a user with the given UserRightsSet to execute "_internalSendHashedResidentPhones" query/mutation  */
    canExecute_internalSendHashedResidentPhones?: Maybe<Scalars['Boolean']['output']>
    /**  Enables a user with the given UserRightsSet to create, update or soft-delete entities of model "B2BAppAccessRightSet" similar to support users  */
    canManageB2BAppAccessRightSets?: Maybe<Scalars['Boolean']['output']>
    /**  Enables a user with the given UserRightsSet to create, update or soft-delete entities of model "B2BAppAccessRight" similar to support users  */
    canManageB2BAppAccessRights?: Maybe<Scalars['Boolean']['output']>
    /**  Enables a user with the given UserRightsSet to create, update or soft-delete entities of model "B2BAppContext" similar to support users  */
    canManageB2BAppContexts?: Maybe<Scalars['Boolean']['output']>
    /**  Enables a user with the given UserRightsSet to create, update or soft-delete entities of model "B2BAppNewsSharingConfig" similar to support users  */
    canManageB2BAppNewsSharingConfigs?: Maybe<Scalars['Boolean']['output']>
    /**  Enables a user with the given UserRightsSet to create, update or soft-delete entities of model "B2BAppPermission" similar to support users  */
    canManageB2BAppPermissions?: Maybe<Scalars['Boolean']['output']>
    /**  Enables a user with the given UserRightsSet to create, update or soft-delete entities of model "B2BAppPromoBlock" similar to support users  */
    canManageB2BAppPromoBlocks?: Maybe<Scalars['Boolean']['output']>
    /**  Enables a user with the given UserRightsSet to create, update or soft-delete entities of model "B2BApp" similar to support users  */
    canManageB2BApps?: Maybe<Scalars['Boolean']['output']>
    /**  Enables a user with the given UserRightsSet to create, update or soft-delete entities of model "B2CAppAccessRight" similar to support users  */
    canManageB2CAppAccessRights?: Maybe<Scalars['Boolean']['output']>
    /**  Enables a user with the given UserRightsSet to create, update or soft-delete entities of model "B2CAppBuild" similar to support users  */
    canManageB2CAppBuilds?: Maybe<Scalars['Boolean']['output']>
    /**  Enables a user with the given UserRightsSet to create, update or soft-delete entities of model "B2CAppProperty" similar to support users  */
    canManageB2CAppProperties?: Maybe<Scalars['Boolean']['output']>
    /**  Enables a user with the given UserRightsSet to create, update or soft-delete entities of model "B2CApp" similar to support users  */
    canManageB2CApps?: Maybe<Scalars['Boolean']['output']>
    /**  Enables a user with the given UserRightsSet to create, update or soft-delete entities of model "MessageBatch" similar to support users  */
    canManageMessageBatches?: Maybe<Scalars['Boolean']['output']>
    /**  Enables a user with the given UserRightsSet to create, update or soft-delete entities of model "OidcClient" similar to support users  */
    canManageOidcClients?: Maybe<Scalars['Boolean']['output']>
    /**  Enables a user with the given UserRightsSet to update "isApproved" field of model "Organization"  */
    canManageOrganizationIsApprovedField?: Maybe<Scalars['Boolean']['output']>
    /**  Enables a user with the given UserRightsSet to create, update or soft-delete entities of model "Organization" similar to support users  */
    canManageOrganizations?: Maybe<Scalars['Boolean']['output']>
    /**  Enables a user with the given UserRightsSet to create, update or soft-delete entities of model "TicketAutoAssignment" similar to support users  */
    canManageTicketAutoAssignments?: Maybe<Scalars['Boolean']['output']>
    /**  Enables a user with the given UserRightsSet to create, update or soft-delete entities of model "Ticket" similar to support users  */
    canManageTickets?: Maybe<Scalars['Boolean']['output']>
    /**  Enables a user with the given UserRightsSet to create, update or soft-delete entities of model "UserRightsSet" similar to support users  */
    canManageUserRightsSets?: Maybe<Scalars['Boolean']['output']>
    /**  Enables a user with the given UserRightsSet to view all entities of model "B2BAppAccessRightSet" as support / admin users do  */
    canReadB2BAppAccessRightSets?: Maybe<Scalars['Boolean']['output']>
    /**  Enables a user with the given UserRightsSet to view all entities of model "B2BAppAccessRight" as support / admin users do  */
    canReadB2BAppAccessRights?: Maybe<Scalars['Boolean']['output']>
    /**  Enables a user with the given UserRightsSet to view all entities of model "B2BAppContext" as support / admin users do  */
    canReadB2BAppContexts?: Maybe<Scalars['Boolean']['output']>
    /**  Enables a user with the given UserRightsSet to view all entities of model "B2BAppNewsSharingConfig" as support / admin users do  */
    canReadB2BAppNewsSharingConfigs?: Maybe<Scalars['Boolean']['output']>
    /**  Enables a user with the given UserRightsSet to view all entities of model "B2BAppPermission" as support / admin users do  */
    canReadB2BAppPermissions?: Maybe<Scalars['Boolean']['output']>
    /**  Enables a user with the given UserRightsSet to view all entities of model "B2BAppPromoBlock" as support / admin users do  */
    canReadB2BAppPromoBlocks?: Maybe<Scalars['Boolean']['output']>
    /**  Enables a user with the given UserRightsSet to view all entities of model "B2BApp" as support / admin users do  */
    canReadB2BApps?: Maybe<Scalars['Boolean']['output']>
    /**  Enables a user with the given UserRightsSet to view all entities of model "B2CAppAccessRight" as support / admin users do  */
    canReadB2CAppAccessRights?: Maybe<Scalars['Boolean']['output']>
    /**  Enables a user with the given UserRightsSet to view all entities of model "B2CAppBuild" as support / admin users do  */
    canReadB2CAppBuilds?: Maybe<Scalars['Boolean']['output']>
    /**  Enables a user with the given UserRightsSet to view all entities of model "B2CAppProperty" as support / admin users do  */
    canReadB2CAppProperties?: Maybe<Scalars['Boolean']['output']>
    /**  Enables a user with the given UserRightsSet to view all entities of model "B2CApp" as support / admin users do  */
    canReadB2CApps?: Maybe<Scalars['Boolean']['output']>
    /**  Enables a user with the given UserRightsSet to view all entities of model "BillingOrganizationIntegrationContext" as support / admin users do  */
    canReadBillingOrganizationIntegrationContexts?: Maybe<Scalars['Boolean']['output']>
    /**  Enables a user with the given UserRightsSet to view all entities of model "BillingReceipt" as support / admin users do  */
    canReadBillingReceipts?: Maybe<Scalars['Boolean']['output']>
    /**  Enables a user with the given UserRightsSet to view all entities of model "MessageBatch" as support / admin users do  */
    canReadMessageBatches?: Maybe<Scalars['Boolean']['output']>
    /**  Enables a user with the given UserRightsSet to view all entities of model "Message" as support / admin users do  */
    canReadMessages?: Maybe<Scalars['Boolean']['output']>
    /**  Enables a user with the given UserRightsSet to view all entities of model "OidcClient" as support / admin users do  */
    canReadOidcClients?: Maybe<Scalars['Boolean']['output']>
    /**  Enables a user with the given UserRightsSet to view all entities of model "Organization" as support / admin users do  */
    canReadOrganizations?: Maybe<Scalars['Boolean']['output']>
    /**  Enables a user with the given UserRightsSet to view all entities of model "Payment" as support / admin users do  */
    canReadPayments?: Maybe<Scalars['Boolean']['output']>
    /**  Enables a user with the given UserRightsSet to view all entities of model "TicketAutoAssignment" as support / admin users do  */
    canReadTicketAutoAssignments?: Maybe<Scalars['Boolean']['output']>
    /**  Enables a user with the given UserRightsSet to view all entities of model "Ticket" as support / admin users do  */
    canReadTickets?: Maybe<Scalars['Boolean']['output']>
    /**  Enables a user with the given UserRightsSet to read "email" field of model "User"  */
    canReadUserEmailField?: Maybe<Scalars['Boolean']['output']>
    /**  Enables a user with the given UserRightsSet to view all entities of model "UserRightsSet" as support / admin users do  */
    canReadUserRightsSets?: Maybe<Scalars['Boolean']['output']>
    /**  Enables a user with the given UserRightsSet to view all entities of model "User" as support / admin users do  */
    canReadUsers?: Maybe<Scalars['Boolean']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  A name that distinguishes this group of rights from others and describes the set of rights that users of this group receive. For example: "Full access to mini-applications", "Level 1 Support", "Read all organizations", etc  */
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type UserRightsSetCreateInput = {
    canExecuteRegisterNewServiceUser?: InputMaybe<Scalars['Boolean']['input']>
    canExecuteSendMessage?: InputMaybe<Scalars['Boolean']['input']>
    canExecute_allBillingReceiptsSum?: InputMaybe<Scalars['Boolean']['input']>
    canExecute_allPaymentsSum?: InputMaybe<Scalars['Boolean']['input']>
    canExecute_internalSendHashedResidentPhones?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppAccessRightSets?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppAccessRights?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppContexts?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppNewsSharingConfigs?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppPermissions?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppPromoBlocks?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BApps?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2CAppAccessRights?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2CAppBuilds?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2CAppProperties?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2CApps?: InputMaybe<Scalars['Boolean']['input']>
    canManageMessageBatches?: InputMaybe<Scalars['Boolean']['input']>
    canManageOidcClients?: InputMaybe<Scalars['Boolean']['input']>
    canManageOrganizationIsApprovedField?: InputMaybe<Scalars['Boolean']['input']>
    canManageOrganizations?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketAutoAssignments?: InputMaybe<Scalars['Boolean']['input']>
    canManageTickets?: InputMaybe<Scalars['Boolean']['input']>
    canManageUserRightsSets?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppAccessRightSets?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppAccessRights?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppContexts?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppNewsSharingConfigs?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppPermissions?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppPromoBlocks?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BApps?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2CAppAccessRights?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2CAppBuilds?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2CAppProperties?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2CApps?: InputMaybe<Scalars['Boolean']['input']>
    canReadBillingOrganizationIntegrationContexts?: InputMaybe<Scalars['Boolean']['input']>
    canReadBillingReceipts?: InputMaybe<Scalars['Boolean']['input']>
    canReadMessageBatches?: InputMaybe<Scalars['Boolean']['input']>
    canReadMessages?: InputMaybe<Scalars['Boolean']['input']>
    canReadOidcClients?: InputMaybe<Scalars['Boolean']['input']>
    canReadOrganizations?: InputMaybe<Scalars['Boolean']['input']>
    canReadPayments?: InputMaybe<Scalars['Boolean']['input']>
    canReadTicketAutoAssignments?: InputMaybe<Scalars['Boolean']['input']>
    canReadTickets?: InputMaybe<Scalars['Boolean']['input']>
    canReadUserEmailField?: InputMaybe<Scalars['Boolean']['input']>
    canReadUserRightsSets?: InputMaybe<Scalars['Boolean']['input']>
    canReadUsers?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type UserRightsSetHistoryRecord = {
    __typename?: 'UserRightsSetHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the UserRightsSetHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the UserRightsSetHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the UserRightsSetHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the UserRightsSetHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    canExecuteRegisterNewServiceUser?: Maybe<Scalars['Boolean']['output']>
    canExecuteSendMessage?: Maybe<Scalars['Boolean']['output']>
    canExecute_allBillingReceiptsSum?: Maybe<Scalars['Boolean']['output']>
    canExecute_allPaymentsSum?: Maybe<Scalars['Boolean']['output']>
    canExecute_internalSendHashedResidentPhones?: Maybe<Scalars['Boolean']['output']>
    canManageB2BAppAccessRightSets?: Maybe<Scalars['Boolean']['output']>
    canManageB2BAppAccessRights?: Maybe<Scalars['Boolean']['output']>
    canManageB2BAppContexts?: Maybe<Scalars['Boolean']['output']>
    canManageB2BAppNewsSharingConfigs?: Maybe<Scalars['Boolean']['output']>
    canManageB2BAppPermissions?: Maybe<Scalars['Boolean']['output']>
    canManageB2BAppPromoBlocks?: Maybe<Scalars['Boolean']['output']>
    canManageB2BApps?: Maybe<Scalars['Boolean']['output']>
    canManageB2CAppAccessRights?: Maybe<Scalars['Boolean']['output']>
    canManageB2CAppBuilds?: Maybe<Scalars['Boolean']['output']>
    canManageB2CAppProperties?: Maybe<Scalars['Boolean']['output']>
    canManageB2CApps?: Maybe<Scalars['Boolean']['output']>
    canManageMessageBatches?: Maybe<Scalars['Boolean']['output']>
    canManageOidcClients?: Maybe<Scalars['Boolean']['output']>
    canManageOrganizationIsApprovedField?: Maybe<Scalars['Boolean']['output']>
    canManageOrganizations?: Maybe<Scalars['Boolean']['output']>
    canManageTicketAutoAssignments?: Maybe<Scalars['Boolean']['output']>
    canManageTickets?: Maybe<Scalars['Boolean']['output']>
    canManageUserRightsSets?: Maybe<Scalars['Boolean']['output']>
    canReadB2BAppAccessRightSets?: Maybe<Scalars['Boolean']['output']>
    canReadB2BAppAccessRights?: Maybe<Scalars['Boolean']['output']>
    canReadB2BAppContexts?: Maybe<Scalars['Boolean']['output']>
    canReadB2BAppNewsSharingConfigs?: Maybe<Scalars['Boolean']['output']>
    canReadB2BAppPermissions?: Maybe<Scalars['Boolean']['output']>
    canReadB2BAppPromoBlocks?: Maybe<Scalars['Boolean']['output']>
    canReadB2BApps?: Maybe<Scalars['Boolean']['output']>
    canReadB2CAppAccessRights?: Maybe<Scalars['Boolean']['output']>
    canReadB2CAppBuilds?: Maybe<Scalars['Boolean']['output']>
    canReadB2CAppProperties?: Maybe<Scalars['Boolean']['output']>
    canReadB2CApps?: Maybe<Scalars['Boolean']['output']>
    canReadBillingOrganizationIntegrationContexts?: Maybe<Scalars['Boolean']['output']>
    canReadBillingReceipts?: Maybe<Scalars['Boolean']['output']>
    canReadMessageBatches?: Maybe<Scalars['Boolean']['output']>
    canReadMessages?: Maybe<Scalars['Boolean']['output']>
    canReadOidcClients?: Maybe<Scalars['Boolean']['output']>
    canReadOrganizations?: Maybe<Scalars['Boolean']['output']>
    canReadPayments?: Maybe<Scalars['Boolean']['output']>
    canReadTicketAutoAssignments?: Maybe<Scalars['Boolean']['output']>
    canReadTickets?: Maybe<Scalars['Boolean']['output']>
    canReadUserEmailField?: Maybe<Scalars['Boolean']['output']>
    canReadUserRightsSets?: Maybe<Scalars['Boolean']['output']>
    canReadUsers?: Maybe<Scalars['Boolean']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<UserRightsSetHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type UserRightsSetHistoryRecordCreateInput = {
    canExecuteRegisterNewServiceUser?: InputMaybe<Scalars['Boolean']['input']>
    canExecuteSendMessage?: InputMaybe<Scalars['Boolean']['input']>
    canExecute_allBillingReceiptsSum?: InputMaybe<Scalars['Boolean']['input']>
    canExecute_allPaymentsSum?: InputMaybe<Scalars['Boolean']['input']>
    canExecute_internalSendHashedResidentPhones?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppAccessRightSets?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppAccessRights?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppContexts?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppNewsSharingConfigs?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppPermissions?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppPromoBlocks?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BApps?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2CAppAccessRights?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2CAppBuilds?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2CAppProperties?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2CApps?: InputMaybe<Scalars['Boolean']['input']>
    canManageMessageBatches?: InputMaybe<Scalars['Boolean']['input']>
    canManageOidcClients?: InputMaybe<Scalars['Boolean']['input']>
    canManageOrganizationIsApprovedField?: InputMaybe<Scalars['Boolean']['input']>
    canManageOrganizations?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketAutoAssignments?: InputMaybe<Scalars['Boolean']['input']>
    canManageTickets?: InputMaybe<Scalars['Boolean']['input']>
    canManageUserRightsSets?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppAccessRightSets?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppAccessRights?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppContexts?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppNewsSharingConfigs?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppPermissions?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppPromoBlocks?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BApps?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2CAppAccessRights?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2CAppBuilds?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2CAppProperties?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2CApps?: InputMaybe<Scalars['Boolean']['input']>
    canReadBillingOrganizationIntegrationContexts?: InputMaybe<Scalars['Boolean']['input']>
    canReadBillingReceipts?: InputMaybe<Scalars['Boolean']['input']>
    canReadMessageBatches?: InputMaybe<Scalars['Boolean']['input']>
    canReadMessages?: InputMaybe<Scalars['Boolean']['input']>
    canReadOidcClients?: InputMaybe<Scalars['Boolean']['input']>
    canReadOrganizations?: InputMaybe<Scalars['Boolean']['input']>
    canReadPayments?: InputMaybe<Scalars['Boolean']['input']>
    canReadTicketAutoAssignments?: InputMaybe<Scalars['Boolean']['input']>
    canReadTickets?: InputMaybe<Scalars['Boolean']['input']>
    canReadUserEmailField?: InputMaybe<Scalars['Boolean']['input']>
    canReadUserRightsSets?: InputMaybe<Scalars['Boolean']['input']>
    canReadUsers?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<UserRightsSetHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum UserRightsSetHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type UserRightsSetHistoryRecordUpdateInput = {
    canExecuteRegisterNewServiceUser?: InputMaybe<Scalars['Boolean']['input']>
    canExecuteSendMessage?: InputMaybe<Scalars['Boolean']['input']>
    canExecute_allBillingReceiptsSum?: InputMaybe<Scalars['Boolean']['input']>
    canExecute_allPaymentsSum?: InputMaybe<Scalars['Boolean']['input']>
    canExecute_internalSendHashedResidentPhones?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppAccessRightSets?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppAccessRights?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppContexts?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppNewsSharingConfigs?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppPermissions?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppPromoBlocks?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BApps?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2CAppAccessRights?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2CAppBuilds?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2CAppProperties?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2CApps?: InputMaybe<Scalars['Boolean']['input']>
    canManageMessageBatches?: InputMaybe<Scalars['Boolean']['input']>
    canManageOidcClients?: InputMaybe<Scalars['Boolean']['input']>
    canManageOrganizationIsApprovedField?: InputMaybe<Scalars['Boolean']['input']>
    canManageOrganizations?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketAutoAssignments?: InputMaybe<Scalars['Boolean']['input']>
    canManageTickets?: InputMaybe<Scalars['Boolean']['input']>
    canManageUserRightsSets?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppAccessRightSets?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppAccessRights?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppContexts?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppNewsSharingConfigs?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppPermissions?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppPromoBlocks?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BApps?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2CAppAccessRights?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2CAppBuilds?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2CAppProperties?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2CApps?: InputMaybe<Scalars['Boolean']['input']>
    canReadBillingOrganizationIntegrationContexts?: InputMaybe<Scalars['Boolean']['input']>
    canReadBillingReceipts?: InputMaybe<Scalars['Boolean']['input']>
    canReadMessageBatches?: InputMaybe<Scalars['Boolean']['input']>
    canReadMessages?: InputMaybe<Scalars['Boolean']['input']>
    canReadOidcClients?: InputMaybe<Scalars['Boolean']['input']>
    canReadOrganizations?: InputMaybe<Scalars['Boolean']['input']>
    canReadPayments?: InputMaybe<Scalars['Boolean']['input']>
    canReadTicketAutoAssignments?: InputMaybe<Scalars['Boolean']['input']>
    canReadTickets?: InputMaybe<Scalars['Boolean']['input']>
    canReadUserEmailField?: InputMaybe<Scalars['Boolean']['input']>
    canReadUserRightsSets?: InputMaybe<Scalars['Boolean']['input']>
    canReadUsers?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<UserRightsSetHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type UserRightsSetHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<UserRightsSetHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<UserRightsSetHistoryRecordWhereInput>>>
    canExecuteRegisterNewServiceUser?: InputMaybe<Scalars['Boolean']['input']>
    canExecuteRegisterNewServiceUser_not?: InputMaybe<Scalars['Boolean']['input']>
    canExecuteSendMessage?: InputMaybe<Scalars['Boolean']['input']>
    canExecuteSendMessage_not?: InputMaybe<Scalars['Boolean']['input']>
    canExecute_allBillingReceiptsSum?: InputMaybe<Scalars['Boolean']['input']>
    canExecute_allBillingReceiptsSum_not?: InputMaybe<Scalars['Boolean']['input']>
    canExecute_allPaymentsSum?: InputMaybe<Scalars['Boolean']['input']>
    canExecute_allPaymentsSum_not?: InputMaybe<Scalars['Boolean']['input']>
    canExecute_internalSendHashedResidentPhones?: InputMaybe<Scalars['Boolean']['input']>
    canExecute_internalSendHashedResidentPhones_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppAccessRightSets?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppAccessRightSets_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppAccessRights?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppAccessRights_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppContexts?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppContexts_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppNewsSharingConfigs?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppNewsSharingConfigs_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppPermissions?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppPermissions_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppPromoBlocks?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppPromoBlocks_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BApps?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BApps_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2CAppAccessRights?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2CAppAccessRights_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2CAppBuilds?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2CAppBuilds_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2CAppProperties?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2CAppProperties_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2CApps?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2CApps_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageMessageBatches?: InputMaybe<Scalars['Boolean']['input']>
    canManageMessageBatches_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageOidcClients?: InputMaybe<Scalars['Boolean']['input']>
    canManageOidcClients_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageOrganizationIsApprovedField?: InputMaybe<Scalars['Boolean']['input']>
    canManageOrganizationIsApprovedField_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageOrganizations?: InputMaybe<Scalars['Boolean']['input']>
    canManageOrganizations_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketAutoAssignments?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketAutoAssignments_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageTickets?: InputMaybe<Scalars['Boolean']['input']>
    canManageTickets_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageUserRightsSets?: InputMaybe<Scalars['Boolean']['input']>
    canManageUserRightsSets_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppAccessRightSets?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppAccessRightSets_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppAccessRights?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppAccessRights_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppContexts?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppContexts_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppNewsSharingConfigs?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppNewsSharingConfigs_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppPermissions?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppPermissions_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppPromoBlocks?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppPromoBlocks_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BApps?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BApps_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2CAppAccessRights?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2CAppAccessRights_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2CAppBuilds?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2CAppBuilds_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2CAppProperties?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2CAppProperties_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2CApps?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2CApps_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadBillingOrganizationIntegrationContexts?: InputMaybe<Scalars['Boolean']['input']>
    canReadBillingOrganizationIntegrationContexts_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadBillingReceipts?: InputMaybe<Scalars['Boolean']['input']>
    canReadBillingReceipts_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadMessageBatches?: InputMaybe<Scalars['Boolean']['input']>
    canReadMessageBatches_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadMessages?: InputMaybe<Scalars['Boolean']['input']>
    canReadMessages_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadOidcClients?: InputMaybe<Scalars['Boolean']['input']>
    canReadOidcClients_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadOrganizations?: InputMaybe<Scalars['Boolean']['input']>
    canReadOrganizations_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadPayments?: InputMaybe<Scalars['Boolean']['input']>
    canReadPayments_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadTicketAutoAssignments?: InputMaybe<Scalars['Boolean']['input']>
    canReadTicketAutoAssignments_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadTickets?: InputMaybe<Scalars['Boolean']['input']>
    canReadTickets_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadUserEmailField?: InputMaybe<Scalars['Boolean']['input']>
    canReadUserEmailField_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadUserRightsSets?: InputMaybe<Scalars['Boolean']['input']>
    canReadUserRightsSets_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadUsers?: InputMaybe<Scalars['Boolean']['input']>
    canReadUsers_not?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<UserRightsSetHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<UserRightsSetHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<UserRightsSetHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<UserRightsSetHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type UserRightsSetHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type UserRightsSetHistoryRecordsCreateInput = {
    data?: InputMaybe<UserRightsSetHistoryRecordCreateInput>
}

export type UserRightsSetHistoryRecordsUpdateInput = {
    data?: InputMaybe<UserRightsSetHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type UserRightsSetRelateToOneInput = {
    connect?: InputMaybe<UserRightsSetWhereUniqueInput>
    create?: InputMaybe<UserRightsSetCreateInput>
    disconnect?: InputMaybe<UserRightsSetWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type UserRightsSetUpdateInput = {
    canExecuteRegisterNewServiceUser?: InputMaybe<Scalars['Boolean']['input']>
    canExecuteSendMessage?: InputMaybe<Scalars['Boolean']['input']>
    canExecute_allBillingReceiptsSum?: InputMaybe<Scalars['Boolean']['input']>
    canExecute_allPaymentsSum?: InputMaybe<Scalars['Boolean']['input']>
    canExecute_internalSendHashedResidentPhones?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppAccessRightSets?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppAccessRights?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppContexts?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppNewsSharingConfigs?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppPermissions?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppPromoBlocks?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BApps?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2CAppAccessRights?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2CAppBuilds?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2CAppProperties?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2CApps?: InputMaybe<Scalars['Boolean']['input']>
    canManageMessageBatches?: InputMaybe<Scalars['Boolean']['input']>
    canManageOidcClients?: InputMaybe<Scalars['Boolean']['input']>
    canManageOrganizationIsApprovedField?: InputMaybe<Scalars['Boolean']['input']>
    canManageOrganizations?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketAutoAssignments?: InputMaybe<Scalars['Boolean']['input']>
    canManageTickets?: InputMaybe<Scalars['Boolean']['input']>
    canManageUserRightsSets?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppAccessRightSets?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppAccessRights?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppContexts?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppNewsSharingConfigs?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppPermissions?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppPromoBlocks?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BApps?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2CAppAccessRights?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2CAppBuilds?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2CAppProperties?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2CApps?: InputMaybe<Scalars['Boolean']['input']>
    canReadBillingOrganizationIntegrationContexts?: InputMaybe<Scalars['Boolean']['input']>
    canReadBillingReceipts?: InputMaybe<Scalars['Boolean']['input']>
    canReadMessageBatches?: InputMaybe<Scalars['Boolean']['input']>
    canReadMessages?: InputMaybe<Scalars['Boolean']['input']>
    canReadOidcClients?: InputMaybe<Scalars['Boolean']['input']>
    canReadOrganizations?: InputMaybe<Scalars['Boolean']['input']>
    canReadPayments?: InputMaybe<Scalars['Boolean']['input']>
    canReadTicketAutoAssignments?: InputMaybe<Scalars['Boolean']['input']>
    canReadTickets?: InputMaybe<Scalars['Boolean']['input']>
    canReadUserEmailField?: InputMaybe<Scalars['Boolean']['input']>
    canReadUserRightsSets?: InputMaybe<Scalars['Boolean']['input']>
    canReadUsers?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type UserRightsSetWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<UserRightsSetWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<UserRightsSetWhereInput>>>
    canExecuteRegisterNewServiceUser?: InputMaybe<Scalars['Boolean']['input']>
    canExecuteRegisterNewServiceUser_not?: InputMaybe<Scalars['Boolean']['input']>
    canExecuteSendMessage?: InputMaybe<Scalars['Boolean']['input']>
    canExecuteSendMessage_not?: InputMaybe<Scalars['Boolean']['input']>
    canExecute_allBillingReceiptsSum?: InputMaybe<Scalars['Boolean']['input']>
    canExecute_allBillingReceiptsSum_not?: InputMaybe<Scalars['Boolean']['input']>
    canExecute_allPaymentsSum?: InputMaybe<Scalars['Boolean']['input']>
    canExecute_allPaymentsSum_not?: InputMaybe<Scalars['Boolean']['input']>
    canExecute_internalSendHashedResidentPhones?: InputMaybe<Scalars['Boolean']['input']>
    canExecute_internalSendHashedResidentPhones_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppAccessRightSets?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppAccessRightSets_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppAccessRights?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppAccessRights_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppContexts?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppContexts_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppNewsSharingConfigs?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppNewsSharingConfigs_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppPermissions?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppPermissions_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppPromoBlocks?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BAppPromoBlocks_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BApps?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2BApps_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2CAppAccessRights?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2CAppAccessRights_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2CAppBuilds?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2CAppBuilds_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2CAppProperties?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2CAppProperties_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2CApps?: InputMaybe<Scalars['Boolean']['input']>
    canManageB2CApps_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageMessageBatches?: InputMaybe<Scalars['Boolean']['input']>
    canManageMessageBatches_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageOidcClients?: InputMaybe<Scalars['Boolean']['input']>
    canManageOidcClients_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageOrganizationIsApprovedField?: InputMaybe<Scalars['Boolean']['input']>
    canManageOrganizationIsApprovedField_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageOrganizations?: InputMaybe<Scalars['Boolean']['input']>
    canManageOrganizations_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketAutoAssignments?: InputMaybe<Scalars['Boolean']['input']>
    canManageTicketAutoAssignments_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageTickets?: InputMaybe<Scalars['Boolean']['input']>
    canManageTickets_not?: InputMaybe<Scalars['Boolean']['input']>
    canManageUserRightsSets?: InputMaybe<Scalars['Boolean']['input']>
    canManageUserRightsSets_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppAccessRightSets?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppAccessRightSets_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppAccessRights?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppAccessRights_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppContexts?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppContexts_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppNewsSharingConfigs?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppNewsSharingConfigs_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppPermissions?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppPermissions_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppPromoBlocks?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BAppPromoBlocks_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BApps?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2BApps_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2CAppAccessRights?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2CAppAccessRights_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2CAppBuilds?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2CAppBuilds_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2CAppProperties?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2CAppProperties_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2CApps?: InputMaybe<Scalars['Boolean']['input']>
    canReadB2CApps_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadBillingOrganizationIntegrationContexts?: InputMaybe<Scalars['Boolean']['input']>
    canReadBillingOrganizationIntegrationContexts_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadBillingReceipts?: InputMaybe<Scalars['Boolean']['input']>
    canReadBillingReceipts_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadMessageBatches?: InputMaybe<Scalars['Boolean']['input']>
    canReadMessageBatches_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadMessages?: InputMaybe<Scalars['Boolean']['input']>
    canReadMessages_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadOidcClients?: InputMaybe<Scalars['Boolean']['input']>
    canReadOidcClients_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadOrganizations?: InputMaybe<Scalars['Boolean']['input']>
    canReadOrganizations_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadPayments?: InputMaybe<Scalars['Boolean']['input']>
    canReadPayments_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadTicketAutoAssignments?: InputMaybe<Scalars['Boolean']['input']>
    canReadTicketAutoAssignments_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadTickets?: InputMaybe<Scalars['Boolean']['input']>
    canReadTickets_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadUserEmailField?: InputMaybe<Scalars['Boolean']['input']>
    canReadUserEmailField_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadUserRightsSets?: InputMaybe<Scalars['Boolean']['input']>
    canReadUserRightsSets_not?: InputMaybe<Scalars['Boolean']['input']>
    canReadUsers?: InputMaybe<Scalars['Boolean']['input']>
    canReadUsers_not?: InputMaybe<Scalars['Boolean']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type UserRightsSetWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type UserRightsSetsCreateInput = {
    data?: InputMaybe<UserRightsSetCreateInput>
}

export type UserRightsSetsUpdateInput = {
    data?: InputMaybe<UserRightsSetUpdateInput>
    id: Scalars['ID']['input']
}

/**  Time when a comment from a resident was last read by a specific user in a specific ticket  */
export type UserTicketCommentReadTime = {
    __typename?: 'UserTicketCommentReadTime'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the UserTicketCommentReadTime List config, or
   *  2. As an alias to the field set on 'labelField' in the UserTicketCommentReadTime List config, or
   *  3. As an alias to a 'name' field on the UserTicketCommentReadTime List (if one exists), or
   *  4. As an alias to the 'id' field on the UserTicketCommentReadTime List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['String']['output']>
    /**  Time when the last comment was last read by the user  */
    readCommentAt?: Maybe<Scalars['String']['output']>
    /**  Time when the last comment from a resident was last read by the user  */
    readResidentCommentAt?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  Ticket in which the user read the comment  */
    ticket?: Maybe<Ticket>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    /**  The user who read the comment  */
    user?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type UserTicketCommentReadTimeCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    readCommentAt?: InputMaybe<Scalars['String']['input']>
    readResidentCommentAt?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    ticket?: InputMaybe<TicketRelateToOneInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type UserTicketCommentReadTimeHistoryRecord = {
    __typename?: 'UserTicketCommentReadTimeHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the UserTicketCommentReadTimeHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the UserTicketCommentReadTimeHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the UserTicketCommentReadTimeHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the UserTicketCommentReadTimeHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<UserTicketCommentReadTimeHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    newId?: Maybe<Scalars['JSON']['output']>
    readCommentAt?: Maybe<Scalars['String']['output']>
    readResidentCommentAt?: Maybe<Scalars['String']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    ticket?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    user?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type UserTicketCommentReadTimeHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<UserTicketCommentReadTimeHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    readCommentAt?: InputMaybe<Scalars['String']['input']>
    readResidentCommentAt?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    ticket?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum UserTicketCommentReadTimeHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type UserTicketCommentReadTimeHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<UserTicketCommentReadTimeHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    readCommentAt?: InputMaybe<Scalars['String']['input']>
    readResidentCommentAt?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    ticket?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type UserTicketCommentReadTimeHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<UserTicketCommentReadTimeHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<UserTicketCommentReadTimeHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<UserTicketCommentReadTimeHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<UserTicketCommentReadTimeHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<UserTicketCommentReadTimeHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<UserTicketCommentReadTimeHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    readCommentAt?: InputMaybe<Scalars['String']['input']>
    readCommentAt_gt?: InputMaybe<Scalars['String']['input']>
    readCommentAt_gte?: InputMaybe<Scalars['String']['input']>
    readCommentAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    readCommentAt_lt?: InputMaybe<Scalars['String']['input']>
    readCommentAt_lte?: InputMaybe<Scalars['String']['input']>
    readCommentAt_not?: InputMaybe<Scalars['String']['input']>
    readCommentAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    readResidentCommentAt?: InputMaybe<Scalars['String']['input']>
    readResidentCommentAt_gt?: InputMaybe<Scalars['String']['input']>
    readResidentCommentAt_gte?: InputMaybe<Scalars['String']['input']>
    readResidentCommentAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    readResidentCommentAt_lt?: InputMaybe<Scalars['String']['input']>
    readResidentCommentAt_lte?: InputMaybe<Scalars['String']['input']>
    readResidentCommentAt_not?: InputMaybe<Scalars['String']['input']>
    readResidentCommentAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    ticket?: InputMaybe<Scalars['String']['input']>
    ticket_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    ticket_not?: InputMaybe<Scalars['String']['input']>
    ticket_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user?: InputMaybe<Scalars['String']['input']>
    user_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user_not?: InputMaybe<Scalars['String']['input']>
    user_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type UserTicketCommentReadTimeHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type UserTicketCommentReadTimeHistoryRecordsCreateInput = {
    data?: InputMaybe<UserTicketCommentReadTimeHistoryRecordCreateInput>
}

export type UserTicketCommentReadTimeHistoryRecordsUpdateInput = {
    data?: InputMaybe<UserTicketCommentReadTimeHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type UserTicketCommentReadTimeUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    readCommentAt?: InputMaybe<Scalars['String']['input']>
    readResidentCommentAt?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    ticket?: InputMaybe<TicketRelateToOneInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type UserTicketCommentReadTimeWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<UserTicketCommentReadTimeWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<UserTicketCommentReadTimeWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    readCommentAt?: InputMaybe<Scalars['String']['input']>
    readCommentAt_gt?: InputMaybe<Scalars['String']['input']>
    readCommentAt_gte?: InputMaybe<Scalars['String']['input']>
    readCommentAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    readCommentAt_lt?: InputMaybe<Scalars['String']['input']>
    readCommentAt_lte?: InputMaybe<Scalars['String']['input']>
    readCommentAt_not?: InputMaybe<Scalars['String']['input']>
    readCommentAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    readResidentCommentAt?: InputMaybe<Scalars['String']['input']>
    readResidentCommentAt_gt?: InputMaybe<Scalars['String']['input']>
    readResidentCommentAt_gte?: InputMaybe<Scalars['String']['input']>
    readResidentCommentAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    readResidentCommentAt_lt?: InputMaybe<Scalars['String']['input']>
    readResidentCommentAt_lte?: InputMaybe<Scalars['String']['input']>
    readResidentCommentAt_not?: InputMaybe<Scalars['String']['input']>
    readResidentCommentAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    ticket?: InputMaybe<TicketWhereInput>
    ticket_is_null?: InputMaybe<Scalars['Boolean']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    user?: InputMaybe<UserWhereInput>
    user_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type UserTicketCommentReadTimeWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type UserTicketCommentReadTimesCreateInput = {
    data?: InputMaybe<UserTicketCommentReadTimeCreateInput>
}

export type UserTicketCommentReadTimesUpdateInput = {
    data?: InputMaybe<UserTicketCommentReadTimeUpdateInput>
    id: Scalars['ID']['input']
}

export enum UserTypeType {
    Resident = 'resident',
    Service = 'service',
    Staff = 'staff',
}

export type UserUpdateInput = {
    avatar?: InputMaybe<Scalars['Upload']['input']>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    customAccess?: InputMaybe<CustomAccessInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    email?: InputMaybe<Scalars['String']['input']>
    isAdmin?: InputMaybe<Scalars['Boolean']['input']>
    isEmailVerified?: InputMaybe<Scalars['Boolean']['input']>
    isPhoneVerified?: InputMaybe<Scalars['Boolean']['input']>
    isSupport?: InputMaybe<Scalars['Boolean']['input']>
    locale?: InputMaybe<UserLocaleType>
    meta?: InputMaybe<Scalars['JSON']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    password?: InputMaybe<Scalars['String']['input']>
    phone?: InputMaybe<Scalars['String']['input']>
    rightsSet?: InputMaybe<UserRightsSetRelateToOneInput>
    sender?: InputMaybe<SenderFieldInput>
    showGlobalHints?: InputMaybe<Scalars['Boolean']['input']>
    type?: InputMaybe<UserTypeType>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type UserWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<UserWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<UserWhereInput>>>
    avatar?: InputMaybe<Scalars['String']['input']>
    avatar_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    avatar_not?: InputMaybe<Scalars['String']['input']>
    avatar_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    customAccess?: InputMaybe<CustomAccessInput>
    customAccess_in?: InputMaybe<Array<InputMaybe<CustomAccessInput>>>
    customAccess_not?: InputMaybe<CustomAccessInput>
    customAccess_not_in?: InputMaybe<Array<InputMaybe<CustomAccessInput>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    email?: InputMaybe<Scalars['String']['input']>
    email_contains?: InputMaybe<Scalars['String']['input']>
    email_contains_i?: InputMaybe<Scalars['String']['input']>
    email_ends_with?: InputMaybe<Scalars['String']['input']>
    email_ends_with_i?: InputMaybe<Scalars['String']['input']>
    email_i?: InputMaybe<Scalars['String']['input']>
    email_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    email_not?: InputMaybe<Scalars['String']['input']>
    email_not_contains?: InputMaybe<Scalars['String']['input']>
    email_not_contains_i?: InputMaybe<Scalars['String']['input']>
    email_not_ends_with?: InputMaybe<Scalars['String']['input']>
    email_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    email_not_i?: InputMaybe<Scalars['String']['input']>
    email_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    email_not_starts_with?: InputMaybe<Scalars['String']['input']>
    email_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    email_starts_with?: InputMaybe<Scalars['String']['input']>
    email_starts_with_i?: InputMaybe<Scalars['String']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    isAdmin?: InputMaybe<Scalars['Boolean']['input']>
    isAdmin_not?: InputMaybe<Scalars['Boolean']['input']>
    isEmailVerified?: InputMaybe<Scalars['Boolean']['input']>
    isEmailVerified_not?: InputMaybe<Scalars['Boolean']['input']>
    isPhoneVerified?: InputMaybe<Scalars['Boolean']['input']>
    isPhoneVerified_not?: InputMaybe<Scalars['Boolean']['input']>
    isSupport?: InputMaybe<Scalars['Boolean']['input']>
    isSupport_not?: InputMaybe<Scalars['Boolean']['input']>
    locale?: InputMaybe<UserLocaleType>
    locale_in?: InputMaybe<Array<InputMaybe<UserLocaleType>>>
    locale_not?: InputMaybe<UserLocaleType>
    locale_not_in?: InputMaybe<Array<InputMaybe<UserLocaleType>>>
    meta?: InputMaybe<Scalars['JSON']['input']>
    meta_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    meta_not?: InputMaybe<Scalars['JSON']['input']>
    meta_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    password_is_set?: InputMaybe<Scalars['Boolean']['input']>
    phone?: InputMaybe<Scalars['String']['input']>
    phone_contains?: InputMaybe<Scalars['String']['input']>
    phone_contains_i?: InputMaybe<Scalars['String']['input']>
    phone_ends_with?: InputMaybe<Scalars['String']['input']>
    phone_ends_with_i?: InputMaybe<Scalars['String']['input']>
    phone_i?: InputMaybe<Scalars['String']['input']>
    phone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phone_not?: InputMaybe<Scalars['String']['input']>
    phone_not_contains?: InputMaybe<Scalars['String']['input']>
    phone_not_contains_i?: InputMaybe<Scalars['String']['input']>
    phone_not_ends_with?: InputMaybe<Scalars['String']['input']>
    phone_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    phone_not_i?: InputMaybe<Scalars['String']['input']>
    phone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    phone_not_starts_with?: InputMaybe<Scalars['String']['input']>
    phone_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    phone_starts_with?: InputMaybe<Scalars['String']['input']>
    phone_starts_with_i?: InputMaybe<Scalars['String']['input']>
    rightsSet?: InputMaybe<UserRightsSetWhereInput>
    rightsSet_is_null?: InputMaybe<Scalars['Boolean']['input']>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    showGlobalHints?: InputMaybe<Scalars['Boolean']['input']>
    showGlobalHints_not?: InputMaybe<Scalars['Boolean']['input']>
    type?: InputMaybe<UserTypeType>
    type_in?: InputMaybe<Array<InputMaybe<UserTypeType>>>
    type_not?: InputMaybe<UserTypeType>
    type_not_in?: InputMaybe<Array<InputMaybe<UserTypeType>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type UserWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type UsersCreateInput = {
    data?: InputMaybe<UserCreateInput>
}

export type UsersUpdateInput = {
    data?: InputMaybe<UserUpdateInput>
    id: Scalars['ID']['input']
}

export type ValidateQrCodeFeesOutput = {
    __typename?: 'ValidateQRCodeFeesOutput'
    explicitFee?: Maybe<Scalars['String']['output']>
    explicitServiceCharge?: Maybe<Scalars['String']['output']>
}

export type ValidateQrCodeInput = {
    dv: Scalars['Int']['input']
    qrCode: Scalars['String']['input']
    sender: SenderFieldInput
}

export type ValidateQrCodeLastReceiptDataOutput = {
    __typename?: 'ValidateQRCodeLastReceiptDataOutput'
    id: Scalars['ID']['output']
    period: Scalars['String']['output']
    toPay: Scalars['String']['output']
}

export type ValidateQrCodeOutput = {
    __typename?: 'ValidateQRCodeOutput'
    acquiringIntegrationHostUrl: Scalars['String']['output']
    amount: Scalars['String']['output']
    currencyCode: Scalars['String']['output']
    explicitFees: ValidateQrCodeFeesOutput
    lastReceiptData?: Maybe<ValidateQrCodeLastReceiptDataOutput>
    qrCodeFields: Scalars['JSON']['output']
}

/** Technical map of the 'village' type Property object. We assume that there will be different maps for different property types.  */
export type VillageMap = {
    __typename?: 'VillageMap'
    dv: Scalars['Int']['output']
    type?: Maybe<VillageMapType>
}

export enum VillageMapType {
    Village = 'village',
}

/**  Webhooks are a way that the APP can send automated web callback with some messages to other apps or system to inform them about any updates. How does it work: 1. When objects are created or changed, we make requests to the GraphQL API to get data on behalf of the specified user; 2. Then we send the data to remote url. Webhook model contains basic configuration of integration, such as external server url, name, encryption parameters and so on.  */
export type Webhook = {
    __typename?: 'Webhook'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the Webhook List config, or
   *  2. As an alias to the field set on 'labelField' in the Webhook List config, or
   *  3. As an alias to a 'name' field on the Webhook List (if one exists), or
   *  4. As an alias to the 'id' field on the Webhook List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Any other details that reveal the purpose of this hook  */
    description?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    /**  Short name used to distinguish this hook from others. Usually it's the name of the integration  */
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    /**  Webhook target URL to which requests will be sent  */
    url?: Maybe<Scalars['String']['output']>
    /**  The user on whose behalf a request is being made to the GraphQL API to prepare webhook data  */
    user?: Maybe<User>
    v?: Maybe<Scalars['Int']['output']>
}

export type WebhookCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    description?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    url?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

/**  A keystone list  */
export type WebhookHistoryRecord = {
    __typename?: 'WebhookHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the WebhookHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the WebhookHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the WebhookHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the WebhookHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    description?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    history_action?: Maybe<WebhookHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    name?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    url?: Maybe<Scalars['String']['output']>
    user?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
}

export type WebhookHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    description?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<WebhookHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    url?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export enum WebhookHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type WebhookHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    description?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    history_action?: InputMaybe<WebhookHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    url?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type WebhookHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<WebhookHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<WebhookHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    description?: InputMaybe<Scalars['String']['input']>
    description_contains?: InputMaybe<Scalars['String']['input']>
    description_contains_i?: InputMaybe<Scalars['String']['input']>
    description_ends_with?: InputMaybe<Scalars['String']['input']>
    description_ends_with_i?: InputMaybe<Scalars['String']['input']>
    description_i?: InputMaybe<Scalars['String']['input']>
    description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    description_not?: InputMaybe<Scalars['String']['input']>
    description_not_contains?: InputMaybe<Scalars['String']['input']>
    description_not_contains_i?: InputMaybe<Scalars['String']['input']>
    description_not_ends_with?: InputMaybe<Scalars['String']['input']>
    description_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    description_not_i?: InputMaybe<Scalars['String']['input']>
    description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    description_not_starts_with?: InputMaybe<Scalars['String']['input']>
    description_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    description_starts_with?: InputMaybe<Scalars['String']['input']>
    description_starts_with_i?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    history_action?: InputMaybe<WebhookHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<WebhookHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<WebhookHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<WebhookHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    url?: InputMaybe<Scalars['String']['input']>
    url_contains?: InputMaybe<Scalars['String']['input']>
    url_contains_i?: InputMaybe<Scalars['String']['input']>
    url_ends_with?: InputMaybe<Scalars['String']['input']>
    url_ends_with_i?: InputMaybe<Scalars['String']['input']>
    url_i?: InputMaybe<Scalars['String']['input']>
    url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    url_not?: InputMaybe<Scalars['String']['input']>
    url_not_contains?: InputMaybe<Scalars['String']['input']>
    url_not_contains_i?: InputMaybe<Scalars['String']['input']>
    url_not_ends_with?: InputMaybe<Scalars['String']['input']>
    url_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    url_not_i?: InputMaybe<Scalars['String']['input']>
    url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    url_not_starts_with?: InputMaybe<Scalars['String']['input']>
    url_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    url_starts_with?: InputMaybe<Scalars['String']['input']>
    url_starts_with_i?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<Scalars['String']['input']>
    user_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    user_not?: InputMaybe<Scalars['String']['input']>
    user_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type WebhookHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type WebhookHistoryRecordsCreateInput = {
    data?: InputMaybe<WebhookHistoryRecordCreateInput>
}

export type WebhookHistoryRecordsUpdateInput = {
    data?: InputMaybe<WebhookHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export type WebhookRelateToOneInput = {
    connect?: InputMaybe<WebhookWhereUniqueInput>
    create?: InputMaybe<WebhookCreateInput>
    disconnect?: InputMaybe<WebhookWhereUniqueInput>
    disconnectAll?: InputMaybe<Scalars['Boolean']['input']>
}

/**  Determines which models the WebHook will be subscribed to. When model changes subscription task will be triggered to resolve changed data and send a webhook  */
export type WebhookSubscription = {
    __typename?: 'WebhookSubscription'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the WebhookSubscription List config, or
   *  2. As an alias to the field set on 'labelField' in the WebhookSubscription List config, or
   *  3. As an alias to a 'name' field on the WebhookSubscription List (if one exists), or
   *  4. As an alias to the 'id' field on the WebhookSubscription List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    createdBy?: Maybe<User>
    deletedAt?: Maybe<Scalars['String']['output']>
    /**  Data structure Version  */
    dv?: Maybe<Scalars['Int']['output']>
    /**  The number of consecutive failures to send webhooks to a remote server. Field value is automatically incremented when the specified url is unavailable or the server response was not ok, but no more than once per hour. Field value is automatically reset to 0 when the remote server is successfully reached (syncedAt or syncedAmount changed), or can be manually reset by support. As soon as the counter reaches the value 10, which is interpreted as the unavailability of the external service for at least 10 hours, the webhook will stop being sent to this url. In this case, you will need to manually reset the counter via support to resume sending.  */
    failuresCount?: Maybe<Scalars['Int']['output']>
    /**  String representing list of model fields in graphql-query format. Exactly the fields specified here will be sent by the webhook. Correct examples: "field1 field2 { subfield }", "{ field1 relation { subfield } }"  */
    fields?: Maybe<Scalars['String']['output']>
    /**  Filters which is stored in JSON and used to filter models sent by the webhook. Examples of filters can be found in ModelWhereInput GQL type, where Model is name of your model  */
    filters?: Maybe<Scalars['JSON']['output']>
    id: Scalars['ID']['output']
    /**  The maximum number of objects that the server can send in one request. The default is 100, and maxPackSize cannot be set beyond this value. In most cases, you do not need to override this field, but it is recommended to lower this value for requests with a large number of related fields or in case of external restrictions of the server accepting webhooks.  */
    maxPackSize?: Maybe<Scalars['Int']['output']>
    /**  The data model (schema) that the webhook is subscribed to  */
    model?: Maybe<WebhookSubscriptionModelType>
    newId?: Maybe<Scalars['String']['output']>
    /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
    sender?: Maybe<SenderField>
    /**  The number of objects successfully delivered by webhooks. On successful synchronization, the syncedAt field is updated and syncedAmount becomes 0. If the remote server fails, syncedAt will not be updated, and syncedAmount will increment to the number of successfully delivered objects.  */
    syncedAmount?: Maybe<Scalars['Int']['output']>
    /**  The time was the data was last synced. At the next synchronization, only objects that have changed since that time will be sent.  */
    syncedAt?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
    updatedBy?: Maybe<User>
    /**  Webhook target URL to which requests will be sent. Overrides url from webhook relation. Used in case when you need to send specific model to a separate url  */
    url?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
    /**  Link to a webhook containing information about integration  */
    webhook?: Maybe<Webhook>
}

export type WebhookSubscriptionCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    failuresCount?: InputMaybe<Scalars['Int']['input']>
    fields?: InputMaybe<Scalars['String']['input']>
    filters?: InputMaybe<Scalars['JSON']['input']>
    maxPackSize?: InputMaybe<Scalars['Int']['input']>
    model?: InputMaybe<WebhookSubscriptionModelType>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    syncedAmount?: InputMaybe<Scalars['Int']['input']>
    syncedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    url?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    webhook?: InputMaybe<WebhookRelateToOneInput>
}

/**  A keystone list  */
export type WebhookSubscriptionHistoryRecord = {
    __typename?: 'WebhookSubscriptionHistoryRecord'
    /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the WebhookSubscriptionHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the WebhookSubscriptionHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the WebhookSubscriptionHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the WebhookSubscriptionHistoryRecord List.
   */
    _label_?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['String']['output']>
    createdBy?: Maybe<Scalars['String']['output']>
    deletedAt?: Maybe<Scalars['String']['output']>
    dv?: Maybe<Scalars['Int']['output']>
    failuresCount?: Maybe<Scalars['Int']['output']>
    fields?: Maybe<Scalars['String']['output']>
    filters?: Maybe<Scalars['JSON']['output']>
    history_action?: Maybe<WebhookSubscriptionHistoryRecordHistoryActionType>
    history_date?: Maybe<Scalars['String']['output']>
    history_id?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    maxPackSize?: Maybe<Scalars['Int']['output']>
    model?: Maybe<Scalars['String']['output']>
    newId?: Maybe<Scalars['JSON']['output']>
    sender?: Maybe<Scalars['JSON']['output']>
    syncedAmount?: Maybe<Scalars['Int']['output']>
    syncedAt?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['String']['output']>
    updatedBy?: Maybe<Scalars['String']['output']>
    url?: Maybe<Scalars['String']['output']>
    v?: Maybe<Scalars['Int']['output']>
    webhook?: Maybe<Scalars['String']['output']>
}

export type WebhookSubscriptionHistoryRecordCreateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    failuresCount?: InputMaybe<Scalars['Int']['input']>
    fields?: InputMaybe<Scalars['String']['input']>
    filters?: InputMaybe<Scalars['JSON']['input']>
    history_action?: InputMaybe<WebhookSubscriptionHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    maxPackSize?: InputMaybe<Scalars['Int']['input']>
    model?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    syncedAmount?: InputMaybe<Scalars['Int']['input']>
    syncedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    url?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    webhook?: InputMaybe<Scalars['String']['input']>
}

export enum WebhookSubscriptionHistoryRecordHistoryActionType {
    C = 'c',
    D = 'd',
    U = 'u',
}

export type WebhookSubscriptionHistoryRecordUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<Scalars['String']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    failuresCount?: InputMaybe<Scalars['Int']['input']>
    fields?: InputMaybe<Scalars['String']['input']>
    filters?: InputMaybe<Scalars['JSON']['input']>
    history_action?: InputMaybe<WebhookSubscriptionHistoryRecordHistoryActionType>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_id?: InputMaybe<Scalars['String']['input']>
    maxPackSize?: InputMaybe<Scalars['Int']['input']>
    model?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    sender?: InputMaybe<Scalars['JSON']['input']>
    syncedAmount?: InputMaybe<Scalars['Int']['input']>
    syncedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    url?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    webhook?: InputMaybe<Scalars['String']['input']>
}

export type WebhookSubscriptionHistoryRecordWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<WebhookSubscriptionHistoryRecordWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<WebhookSubscriptionHistoryRecordWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<Scalars['String']['input']>
    createdBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy_not?: InputMaybe<Scalars['String']['input']>
    createdBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    failuresCount?: InputMaybe<Scalars['Int']['input']>
    failuresCount_gt?: InputMaybe<Scalars['Int']['input']>
    failuresCount_gte?: InputMaybe<Scalars['Int']['input']>
    failuresCount_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    failuresCount_lt?: InputMaybe<Scalars['Int']['input']>
    failuresCount_lte?: InputMaybe<Scalars['Int']['input']>
    failuresCount_not?: InputMaybe<Scalars['Int']['input']>
    failuresCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    fields?: InputMaybe<Scalars['String']['input']>
    fields_contains?: InputMaybe<Scalars['String']['input']>
    fields_contains_i?: InputMaybe<Scalars['String']['input']>
    fields_ends_with?: InputMaybe<Scalars['String']['input']>
    fields_ends_with_i?: InputMaybe<Scalars['String']['input']>
    fields_i?: InputMaybe<Scalars['String']['input']>
    fields_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    fields_not?: InputMaybe<Scalars['String']['input']>
    fields_not_contains?: InputMaybe<Scalars['String']['input']>
    fields_not_contains_i?: InputMaybe<Scalars['String']['input']>
    fields_not_ends_with?: InputMaybe<Scalars['String']['input']>
    fields_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    fields_not_i?: InputMaybe<Scalars['String']['input']>
    fields_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    fields_not_starts_with?: InputMaybe<Scalars['String']['input']>
    fields_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    fields_starts_with?: InputMaybe<Scalars['String']['input']>
    fields_starts_with_i?: InputMaybe<Scalars['String']['input']>
    filters?: InputMaybe<Scalars['JSON']['input']>
    filters_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    filters_not?: InputMaybe<Scalars['JSON']['input']>
    filters_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    history_action?: InputMaybe<WebhookSubscriptionHistoryRecordHistoryActionType>
    history_action_in?: InputMaybe<Array<InputMaybe<WebhookSubscriptionHistoryRecordHistoryActionType>>>
    history_action_not?: InputMaybe<WebhookSubscriptionHistoryRecordHistoryActionType>
    history_action_not_in?: InputMaybe<Array<InputMaybe<WebhookSubscriptionHistoryRecordHistoryActionType>>>
    history_date?: InputMaybe<Scalars['String']['input']>
    history_date_gt?: InputMaybe<Scalars['String']['input']>
    history_date_gte?: InputMaybe<Scalars['String']['input']>
    history_date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_date_lt?: InputMaybe<Scalars['String']['input']>
    history_date_lte?: InputMaybe<Scalars['String']['input']>
    history_date_not?: InputMaybe<Scalars['String']['input']>
    history_date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id?: InputMaybe<Scalars['String']['input']>
    history_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    history_id_not?: InputMaybe<Scalars['String']['input']>
    history_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    maxPackSize?: InputMaybe<Scalars['Int']['input']>
    maxPackSize_gt?: InputMaybe<Scalars['Int']['input']>
    maxPackSize_gte?: InputMaybe<Scalars['Int']['input']>
    maxPackSize_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    maxPackSize_lt?: InputMaybe<Scalars['Int']['input']>
    maxPackSize_lte?: InputMaybe<Scalars['Int']['input']>
    maxPackSize_not?: InputMaybe<Scalars['Int']['input']>
    maxPackSize_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    model?: InputMaybe<Scalars['String']['input']>
    model_contains?: InputMaybe<Scalars['String']['input']>
    model_contains_i?: InputMaybe<Scalars['String']['input']>
    model_ends_with?: InputMaybe<Scalars['String']['input']>
    model_ends_with_i?: InputMaybe<Scalars['String']['input']>
    model_i?: InputMaybe<Scalars['String']['input']>
    model_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    model_not?: InputMaybe<Scalars['String']['input']>
    model_not_contains?: InputMaybe<Scalars['String']['input']>
    model_not_contains_i?: InputMaybe<Scalars['String']['input']>
    model_not_ends_with?: InputMaybe<Scalars['String']['input']>
    model_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    model_not_i?: InputMaybe<Scalars['String']['input']>
    model_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    model_not_starts_with?: InputMaybe<Scalars['String']['input']>
    model_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    model_starts_with?: InputMaybe<Scalars['String']['input']>
    model_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['JSON']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    newId_not?: InputMaybe<Scalars['JSON']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender?: InputMaybe<Scalars['JSON']['input']>
    sender_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    sender_not?: InputMaybe<Scalars['JSON']['input']>
    sender_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    syncedAmount?: InputMaybe<Scalars['Int']['input']>
    syncedAmount_gt?: InputMaybe<Scalars['Int']['input']>
    syncedAmount_gte?: InputMaybe<Scalars['Int']['input']>
    syncedAmount_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    syncedAmount_lt?: InputMaybe<Scalars['Int']['input']>
    syncedAmount_lte?: InputMaybe<Scalars['Int']['input']>
    syncedAmount_not?: InputMaybe<Scalars['Int']['input']>
    syncedAmount_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    syncedAt?: InputMaybe<Scalars['String']['input']>
    syncedAt_gt?: InputMaybe<Scalars['String']['input']>
    syncedAt_gte?: InputMaybe<Scalars['String']['input']>
    syncedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    syncedAt_lt?: InputMaybe<Scalars['String']['input']>
    syncedAt_lte?: InputMaybe<Scalars['String']['input']>
    syncedAt_not?: InputMaybe<Scalars['String']['input']>
    syncedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<Scalars['String']['input']>
    updatedBy_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy_not?: InputMaybe<Scalars['String']['input']>
    updatedBy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    url?: InputMaybe<Scalars['String']['input']>
    url_contains?: InputMaybe<Scalars['String']['input']>
    url_contains_i?: InputMaybe<Scalars['String']['input']>
    url_ends_with?: InputMaybe<Scalars['String']['input']>
    url_ends_with_i?: InputMaybe<Scalars['String']['input']>
    url_i?: InputMaybe<Scalars['String']['input']>
    url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    url_not?: InputMaybe<Scalars['String']['input']>
    url_not_contains?: InputMaybe<Scalars['String']['input']>
    url_not_contains_i?: InputMaybe<Scalars['String']['input']>
    url_not_ends_with?: InputMaybe<Scalars['String']['input']>
    url_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    url_not_i?: InputMaybe<Scalars['String']['input']>
    url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    url_not_starts_with?: InputMaybe<Scalars['String']['input']>
    url_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    url_starts_with?: InputMaybe<Scalars['String']['input']>
    url_starts_with_i?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    webhook?: InputMaybe<Scalars['String']['input']>
    webhook_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    webhook_not?: InputMaybe<Scalars['String']['input']>
    webhook_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type WebhookSubscriptionHistoryRecordWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type WebhookSubscriptionHistoryRecordsCreateInput = {
    data?: InputMaybe<WebhookSubscriptionHistoryRecordCreateInput>
}

export type WebhookSubscriptionHistoryRecordsUpdateInput = {
    data?: InputMaybe<WebhookSubscriptionHistoryRecordUpdateInput>
    id: Scalars['ID']['input']
}

export enum WebhookSubscriptionModelType {
    AcquiringIntegrationContext = 'AcquiringIntegrationContext',
    B2BApp = 'B2BApp',
    B2BAppContext = 'B2BAppContext',
    Contact = 'Contact',
    Organization = 'Organization',
    Ticket = 'Ticket',
    TicketComment = 'TicketComment',
    TicketCommentFile = 'TicketCommentFile',
    TicketFile = 'TicketFile',
    User = 'User',
    UserHelpRequest = 'UserHelpRequest',
}

export type WebhookSubscriptionUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    failuresCount?: InputMaybe<Scalars['Int']['input']>
    fields?: InputMaybe<Scalars['String']['input']>
    filters?: InputMaybe<Scalars['JSON']['input']>
    maxPackSize?: InputMaybe<Scalars['Int']['input']>
    model?: InputMaybe<WebhookSubscriptionModelType>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    syncedAmount?: InputMaybe<Scalars['Int']['input']>
    syncedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    url?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    webhook?: InputMaybe<WebhookRelateToOneInput>
}

export type WebhookSubscriptionWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<WebhookSubscriptionWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<WebhookSubscriptionWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    failuresCount?: InputMaybe<Scalars['Int']['input']>
    failuresCount_gt?: InputMaybe<Scalars['Int']['input']>
    failuresCount_gte?: InputMaybe<Scalars['Int']['input']>
    failuresCount_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    failuresCount_lt?: InputMaybe<Scalars['Int']['input']>
    failuresCount_lte?: InputMaybe<Scalars['Int']['input']>
    failuresCount_not?: InputMaybe<Scalars['Int']['input']>
    failuresCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    fields?: InputMaybe<Scalars['String']['input']>
    fields_contains?: InputMaybe<Scalars['String']['input']>
    fields_contains_i?: InputMaybe<Scalars['String']['input']>
    fields_ends_with?: InputMaybe<Scalars['String']['input']>
    fields_ends_with_i?: InputMaybe<Scalars['String']['input']>
    fields_i?: InputMaybe<Scalars['String']['input']>
    fields_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    fields_not?: InputMaybe<Scalars['String']['input']>
    fields_not_contains?: InputMaybe<Scalars['String']['input']>
    fields_not_contains_i?: InputMaybe<Scalars['String']['input']>
    fields_not_ends_with?: InputMaybe<Scalars['String']['input']>
    fields_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    fields_not_i?: InputMaybe<Scalars['String']['input']>
    fields_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    fields_not_starts_with?: InputMaybe<Scalars['String']['input']>
    fields_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    fields_starts_with?: InputMaybe<Scalars['String']['input']>
    fields_starts_with_i?: InputMaybe<Scalars['String']['input']>
    filters?: InputMaybe<Scalars['JSON']['input']>
    filters_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    filters_not?: InputMaybe<Scalars['JSON']['input']>
    filters_not_in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    maxPackSize?: InputMaybe<Scalars['Int']['input']>
    maxPackSize_gt?: InputMaybe<Scalars['Int']['input']>
    maxPackSize_gte?: InputMaybe<Scalars['Int']['input']>
    maxPackSize_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    maxPackSize_lt?: InputMaybe<Scalars['Int']['input']>
    maxPackSize_lte?: InputMaybe<Scalars['Int']['input']>
    maxPackSize_not?: InputMaybe<Scalars['Int']['input']>
    maxPackSize_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    model?: InputMaybe<WebhookSubscriptionModelType>
    model_in?: InputMaybe<Array<InputMaybe<WebhookSubscriptionModelType>>>
    model_not?: InputMaybe<WebhookSubscriptionModelType>
    model_not_in?: InputMaybe<Array<InputMaybe<WebhookSubscriptionModelType>>>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    syncedAmount?: InputMaybe<Scalars['Int']['input']>
    syncedAmount_gt?: InputMaybe<Scalars['Int']['input']>
    syncedAmount_gte?: InputMaybe<Scalars['Int']['input']>
    syncedAmount_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    syncedAmount_lt?: InputMaybe<Scalars['Int']['input']>
    syncedAmount_lte?: InputMaybe<Scalars['Int']['input']>
    syncedAmount_not?: InputMaybe<Scalars['Int']['input']>
    syncedAmount_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    syncedAt?: InputMaybe<Scalars['String']['input']>
    syncedAt_gt?: InputMaybe<Scalars['String']['input']>
    syncedAt_gte?: InputMaybe<Scalars['String']['input']>
    syncedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    syncedAt_lt?: InputMaybe<Scalars['String']['input']>
    syncedAt_lte?: InputMaybe<Scalars['String']['input']>
    syncedAt_not?: InputMaybe<Scalars['String']['input']>
    syncedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    url?: InputMaybe<Scalars['String']['input']>
    url_contains?: InputMaybe<Scalars['String']['input']>
    url_contains_i?: InputMaybe<Scalars['String']['input']>
    url_ends_with?: InputMaybe<Scalars['String']['input']>
    url_ends_with_i?: InputMaybe<Scalars['String']['input']>
    url_i?: InputMaybe<Scalars['String']['input']>
    url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    url_not?: InputMaybe<Scalars['String']['input']>
    url_not_contains?: InputMaybe<Scalars['String']['input']>
    url_not_contains_i?: InputMaybe<Scalars['String']['input']>
    url_not_ends_with?: InputMaybe<Scalars['String']['input']>
    url_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    url_not_i?: InputMaybe<Scalars['String']['input']>
    url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    url_not_starts_with?: InputMaybe<Scalars['String']['input']>
    url_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    url_starts_with?: InputMaybe<Scalars['String']['input']>
    url_starts_with_i?: InputMaybe<Scalars['String']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    webhook?: InputMaybe<WebhookWhereInput>
    webhook_is_null?: InputMaybe<Scalars['Boolean']['input']>
}

export type WebhookSubscriptionWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type WebhookSubscriptionsCreateInput = {
    data?: InputMaybe<WebhookSubscriptionCreateInput>
}

export type WebhookSubscriptionsUpdateInput = {
    data?: InputMaybe<WebhookSubscriptionUpdateInput>
    id: Scalars['ID']['input']
}

export type WebhookUpdateInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdBy?: InputMaybe<UserRelateToOneInput>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    description?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    sender?: InputMaybe<SenderFieldInput>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedBy?: InputMaybe<UserRelateToOneInput>
    url?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<UserRelateToOneInput>
    v?: InputMaybe<Scalars['Int']['input']>
}

export type WebhookWhereInput = {
    AND?: InputMaybe<Array<InputMaybe<WebhookWhereInput>>>
    OR?: InputMaybe<Array<InputMaybe<WebhookWhereInput>>>
    createdAt?: InputMaybe<Scalars['String']['input']>
    createdAt_gt?: InputMaybe<Scalars['String']['input']>
    createdAt_gte?: InputMaybe<Scalars['String']['input']>
    createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdAt_lt?: InputMaybe<Scalars['String']['input']>
    createdAt_lte?: InputMaybe<Scalars['String']['input']>
    createdAt_not?: InputMaybe<Scalars['String']['input']>
    createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    createdBy?: InputMaybe<UserWhereInput>
    createdBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    deletedAt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gt?: InputMaybe<Scalars['String']['input']>
    deletedAt_gte?: InputMaybe<Scalars['String']['input']>
    deletedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    deletedAt_lt?: InputMaybe<Scalars['String']['input']>
    deletedAt_lte?: InputMaybe<Scalars['String']['input']>
    deletedAt_not?: InputMaybe<Scalars['String']['input']>
    deletedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    description?: InputMaybe<Scalars['String']['input']>
    description_contains?: InputMaybe<Scalars['String']['input']>
    description_contains_i?: InputMaybe<Scalars['String']['input']>
    description_ends_with?: InputMaybe<Scalars['String']['input']>
    description_ends_with_i?: InputMaybe<Scalars['String']['input']>
    description_i?: InputMaybe<Scalars['String']['input']>
    description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    description_not?: InputMaybe<Scalars['String']['input']>
    description_not_contains?: InputMaybe<Scalars['String']['input']>
    description_not_contains_i?: InputMaybe<Scalars['String']['input']>
    description_not_ends_with?: InputMaybe<Scalars['String']['input']>
    description_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    description_not_i?: InputMaybe<Scalars['String']['input']>
    description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    description_not_starts_with?: InputMaybe<Scalars['String']['input']>
    description_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    description_starts_with?: InputMaybe<Scalars['String']['input']>
    description_starts_with_i?: InputMaybe<Scalars['String']['input']>
    dv?: InputMaybe<Scalars['Int']['input']>
    dv_gt?: InputMaybe<Scalars['Int']['input']>
    dv_gte?: InputMaybe<Scalars['Int']['input']>
    dv_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    dv_lt?: InputMaybe<Scalars['Int']['input']>
    dv_lte?: InputMaybe<Scalars['Int']['input']>
    dv_not?: InputMaybe<Scalars['Int']['input']>
    dv_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id_not?: InputMaybe<Scalars['ID']['input']>
    id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    name?: InputMaybe<Scalars['String']['input']>
    name_contains?: InputMaybe<Scalars['String']['input']>
    name_contains_i?: InputMaybe<Scalars['String']['input']>
    name_ends_with?: InputMaybe<Scalars['String']['input']>
    name_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_i?: InputMaybe<Scalars['String']['input']>
    name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not?: InputMaybe<Scalars['String']['input']>
    name_not_contains?: InputMaybe<Scalars['String']['input']>
    name_not_contains_i?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with?: InputMaybe<Scalars['String']['input']>
    name_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    name_not_i?: InputMaybe<Scalars['String']['input']>
    name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    name_not_starts_with?: InputMaybe<Scalars['String']['input']>
    name_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    name_starts_with?: InputMaybe<Scalars['String']['input']>
    name_starts_with_i?: InputMaybe<Scalars['String']['input']>
    newId?: InputMaybe<Scalars['String']['input']>
    newId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    newId_not?: InputMaybe<Scalars['String']['input']>
    newId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    sender?: InputMaybe<SenderFieldInput>
    sender_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    sender_not?: InputMaybe<SenderFieldInput>
    sender_not_in?: InputMaybe<Array<InputMaybe<SenderFieldInput>>>
    updatedAt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gt?: InputMaybe<Scalars['String']['input']>
    updatedAt_gte?: InputMaybe<Scalars['String']['input']>
    updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedAt_lt?: InputMaybe<Scalars['String']['input']>
    updatedAt_lte?: InputMaybe<Scalars['String']['input']>
    updatedAt_not?: InputMaybe<Scalars['String']['input']>
    updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    updatedBy?: InputMaybe<UserWhereInput>
    updatedBy_is_null?: InputMaybe<Scalars['Boolean']['input']>
    url?: InputMaybe<Scalars['String']['input']>
    url_contains?: InputMaybe<Scalars['String']['input']>
    url_contains_i?: InputMaybe<Scalars['String']['input']>
    url_ends_with?: InputMaybe<Scalars['String']['input']>
    url_ends_with_i?: InputMaybe<Scalars['String']['input']>
    url_i?: InputMaybe<Scalars['String']['input']>
    url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    url_not?: InputMaybe<Scalars['String']['input']>
    url_not_contains?: InputMaybe<Scalars['String']['input']>
    url_not_contains_i?: InputMaybe<Scalars['String']['input']>
    url_not_ends_with?: InputMaybe<Scalars['String']['input']>
    url_not_ends_with_i?: InputMaybe<Scalars['String']['input']>
    url_not_i?: InputMaybe<Scalars['String']['input']>
    url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    url_not_starts_with?: InputMaybe<Scalars['String']['input']>
    url_not_starts_with_i?: InputMaybe<Scalars['String']['input']>
    url_starts_with?: InputMaybe<Scalars['String']['input']>
    url_starts_with_i?: InputMaybe<Scalars['String']['input']>
    user?: InputMaybe<UserWhereInput>
    user_is_null?: InputMaybe<Scalars['Boolean']['input']>
    v?: InputMaybe<Scalars['Int']['input']>
    v_gt?: InputMaybe<Scalars['Int']['input']>
    v_gte?: InputMaybe<Scalars['Int']['input']>
    v_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    v_lt?: InputMaybe<Scalars['Int']['input']>
    v_lte?: InputMaybe<Scalars['Int']['input']>
    v_not?: InputMaybe<Scalars['Int']['input']>
    v_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type WebhookWhereUniqueInput = {
    id: Scalars['ID']['input']
}

export type WebhooksCreateInput = {
    data?: InputMaybe<WebhookCreateInput>
}

export type WebhooksUpdateInput = {
    data?: InputMaybe<WebhookUpdateInput>
    id: Scalars['ID']['input']
}

export type _ListAccess = {
    __typename?: '_ListAccess'
    /**
   * Access Control settings for the currently logged in (or anonymous)
   * user when performing 'auth' operations.
   */
    auth?: Maybe<Scalars['JSON']['output']>
    /**
   * Access Control settings for the currently logged in (or anonymous)
   * user when performing 'create' operations.
   * NOTE: 'create' can only return a Boolean.
   * It is not possible to specify a declarative Where clause for this
   * operation
   */
    create?: Maybe<Scalars['Boolean']['output']>
    /**
   * Access Control settings for the currently logged in (or anonymous)
   * user when performing 'delete' operations.
   */
    delete?: Maybe<Scalars['JSON']['output']>
    /**
   * Access Control settings for the currently logged in (or anonymous)
   * user when performing 'read' operations.
   */
    read?: Maybe<Scalars['JSON']['output']>
    /**
   * Access Control settings for the currently logged in (or anonymous)
   * user when performing 'update' operations.
   */
    update?: Maybe<Scalars['JSON']['output']>
}

export type _ListInputTypes = {
    __typename?: '_ListInputTypes'
    /** Create mutation input type name */
    createInput?: Maybe<Scalars['String']['output']>
    /** Create many mutation input type name */
    createManyInput?: Maybe<Scalars['String']['output']>
    /** Update mutation name input */
    updateInput?: Maybe<Scalars['String']['output']>
    /** Update many mutation name input */
    updateManyInput?: Maybe<Scalars['String']['output']>
    /** Input type for matching multiple items */
    whereInput?: Maybe<Scalars['String']['output']>
    /** Input type for matching a unique item */
    whereUniqueInput?: Maybe<Scalars['String']['output']>
}

export type _ListMeta = {
    __typename?: '_ListMeta'
    /** Access control configuration for the currently authenticated request */
    access?: Maybe<_ListAccess>
    /** The list's user-facing description */
    description?: Maybe<Scalars['String']['output']>
    /** The Keystone list key */
    key?: Maybe<Scalars['String']['output']>
    /** The list's display name in the Admin UI */
    label?: Maybe<Scalars['String']['output']>
    /**
   * The Keystone List name
   * @deprecated Use `key` instead
   */
    name?: Maybe<Scalars['String']['output']>
    /** The list's data path */
    path?: Maybe<Scalars['String']['output']>
    /** The list's plural display name */
    plural?: Maybe<Scalars['String']['output']>
    /** Information on the generated GraphQL schema */
    schema?: Maybe<_ListSchema>
    /** The list's singular display name */
    singular?: Maybe<Scalars['String']['output']>
}

export type _ListMutations = {
    __typename?: '_ListMutations'
    /** Create mutation name */
    create?: Maybe<Scalars['String']['output']>
    /** Create many mutation name */
    createMany?: Maybe<Scalars['String']['output']>
    /** Delete mutation name */
    delete?: Maybe<Scalars['String']['output']>
    /** Delete many mutation name */
    deleteMany?: Maybe<Scalars['String']['output']>
    /** Update mutation name */
    update?: Maybe<Scalars['String']['output']>
    /** Update many mutation name */
    updateMany?: Maybe<Scalars['String']['output']>
}

export type _ListQueries = {
    __typename?: '_ListQueries'
    /** Single-item query name */
    item?: Maybe<Scalars['String']['output']>
    /** All-items query name */
    list?: Maybe<Scalars['String']['output']>
    /** List metadata query name */
    meta?: Maybe<Scalars['String']['output']>
}

export type _ListSchema = {
    __typename?: '_ListSchema'
    /** Information about fields defined on this list */
    fields?: Maybe<Array<Maybe<_ListSchemaFields>>>
    /** Top-level GraphQL input types */
    inputTypes?: Maybe<_ListInputTypes>
    /** Top-level GraphQL mutation names */
    mutations?: Maybe<_ListMutations>
    /**
   * Top level GraphQL query names which either return this type, or
   * provide aggregate information about this type
   */
    queries?: Maybe<_ListQueries>
    /**
   * Information about fields on other types which return this type, or
   * provide aggregate information about this type
   */
    relatedFields?: Maybe<Array<Maybe<_ListSchemaRelatedFields>>>
    /** The typename as used in GraphQL queries */
    type?: Maybe<Scalars['String']['output']>
}


export type _ListSchemaFieldsArgs = {
    where?: InputMaybe<_ListSchemaFieldsInput>
}

export type _ListSchemaFields = {
    __typename?: '_ListSchemaFields'
    /**
   * The name of the field in its list
   * @deprecated Use `path` instead
   */
    name?: Maybe<Scalars['String']['output']>
    /** The path of the field in its list */
    path?: Maybe<Scalars['String']['output']>
    /** The field type (ie, Checkbox, Text, etc) */
    type?: Maybe<Scalars['String']['output']>
}

export type _ListSchemaFieldsInput = {
    type?: InputMaybe<Scalars['String']['input']>
}

export type _ListSchemaRelatedFields = {
    __typename?: '_ListSchemaRelatedFields'
    /** A list of GraphQL field names */
    fields?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    /** The typename as used in GraphQL queries */
    type?: Maybe<Scalars['String']['output']>
}

export type _QueryMeta = {
    __typename?: '_QueryMeta'
    count?: Maybe<Scalars['Int']['output']>
}

export type _InternalDeleteMeterAndMeterReadingsInput = {
    dv: Scalars['Int']['input']
    organizationId: Scalars['ID']['input']
    propertyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    sender: SenderFieldInput
}

export type _InternalDeleteMeterAndMeterReadingsOutput = {
    __typename?: '_internalDeleteMeterAndMeterReadingsOutput'
    deletedMeters: Scalars['Int']['output']
    metersToDelete: Scalars['Int']['output']
    status: Status
}

export type _InternalDeleteMeterReadingsInput = {
    dv: Scalars['Int']['input']
    endDateTime: Scalars['String']['input']
    organizationId: Scalars['ID']['input']
    propertyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    sender: SenderFieldInput
    startDateTime: Scalars['String']['input']
}

export type _InternalDeleteMeterReadingsOutput = {
    __typename?: '_internalDeleteMeterReadingsOutput'
    deleted: Scalars['Int']['output']
    status: Status
    toDelete: Scalars['Int']['output']
}

export type _InternalScheduleTaskByNameInput = {
    dv: Scalars['Int']['input']
    sender: Scalars['JSON']['input']
    taskArgs?: InputMaybe<Scalars['JSON']['input']>
    taskName: Scalars['String']['input']
}

export type _InternalScheduleTaskByNameOutput = {
    __typename?: '_internalScheduleTaskByNameOutput'
    id: Scalars['String']['output']
}

export type _InternalSendHashedResidentPhonesInput = {
    dv: Scalars['Int']['input']
    sender: SenderFieldInput
}

export type _InternalSendHashedResidentPhonesOutput = {
    __typename?: '_internalSendHashedResidentPhonesOutput'
    taskId: Scalars['ID']['output']
}

export type _InternalSendNotificationNewMobileAppVersionInput = {
    app: MobileApp
    body?: InputMaybe<Scalars['String']['input']>
    buildVersion: Scalars['String']['input']
    dv: Scalars['Int']['input']
    organizationIds?: InputMaybe<Array<Scalars['ID']['input']>>
    platform: MobilePlatform
    sender: SenderFieldInput
    title?: InputMaybe<Scalars['String']['input']>
}

export type _InternalSendNotificationNewMobileAppVersionOutput = {
    __typename?: '_internalSendNotificationNewMobileAppVersionOutput'
    messageBatchId: Scalars['ID']['output']
}

export type _InternalSyncContactsWithResidentsForOrganizationInput = {
    dv: Scalars['Int']['input']
    organization: OrganizationWhereUniqueInput
    sender: SenderFieldInput
}

export type _InternalSyncContactsWithResidentsForOrganizationOutputRow = {
    __typename?: '_internalSyncContactsWithResidentsForOrganizationOutputRow'
    address: Scalars['String']['output']
    email?: Maybe<Scalars['String']['output']>
    isVerified?: Maybe<Scalars['Boolean']['output']>
    name: Scalars['String']['output']
    phone: Scalars['String']['output']
    unitName?: Maybe<Scalars['String']['output']>
    unitType?: Maybe<Scalars['String']['output']>
}

export type _KsListsMetaInput = {
    /** Whether this is an auxiliary helper list */
    auxiliary?: InputMaybe<Scalars['Boolean']['input']>
    key?: InputMaybe<Scalars['String']['input']>
}

export type AuthenticateUserOutput = {
    __typename?: 'authenticateUserOutput'
    /**  Retrieve information on the newly authenticated User here.  */
    item?: Maybe<User>
    /**  Used to make subsequent authenticated requests by setting this token in a header: 'Authorization: Bearer <token>'.  */
    token?: Maybe<Scalars['String']['output']>
}

export type ResidentOrganization = {
    __typename?: 'residentOrganization'
    country?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    name?: Maybe<Scalars['String']['output']>
    tin?: Maybe<Scalars['String']['output']>
}

export type UnauthenticateUserOutput = {
    __typename?: 'unauthenticateUserOutput'
    /**
   * `true` when unauthentication succeeds.
   * NOTE: unauthentication always succeeds when the request has an invalid or missing authentication token.
   */
    success?: Maybe<Scalars['Boolean']['output']>
}
